import { createSelector } from '@reduxjs/toolkit';

import { getAVCurrentSelectionBetsByTypeForWhatIf } from 'redux/modules/asianViewCurrentBets/selectors';
import { getSelectedBetsForWhatIf } from 'redux/modules/betslip/selectors';
import { getCurrentSelectionBetsByTypeForWhatIf } from 'redux/modules/currentBets/selectors';
import { getInlineSelectedBets } from 'redux/modules/inlinePlacement/selectors';
import { AppState } from 'redux/reducers';
import { MatchTypes } from 'types/bets';

import {
  mapperInlinePlacementToWhatIfValue,
  mapperSelectedBetsToWhatIfValue,
  mapperUnMatchToWhatIfValue
} from './helpers';
import { TNewWhatValue } from './type';

const getCurrentWhatIf = ({ whatIf }: AppState) => whatIf.currentValues;
export const getInlinePlacementLoading = ({ whatIf }: AppState) => whatIf.hideInlinePlacementLoading;

export const getCurrentWhatIfById = (marketId: string) =>
  createSelector(getCurrentWhatIf, currentValues => currentValues[marketId] || []);

export const getNewWhatIfById = (marketId: string) =>
  createSelector(getSelectedBetsForWhatIf, getInlineSelectedBets, (selectedBets, inlinePlacement): TNewWhatValue[] => {
    const selected = mapperSelectedBetsToWhatIfValue(selectedBets, marketId);
    const inline = mapperInlinePlacementToWhatIfValue(inlinePlacement, marketId);

    return [...selected, ...inline];
  });

export const getNewWhatIf = ({
  marketId,
  handicap = 0,
  numberOfWinners = 1,
  isAsianView = false
}: {
  marketId: string;
  selectionId: number;
  handicap?: number;
  numberOfWinners?: number;
  isAsianView?: boolean;
}) =>
  createSelector(
    getSelectedBetsForWhatIf,
    getInlineSelectedBets,
    isAsianView
      ? getAVCurrentSelectionBetsByTypeForWhatIf(marketId, handicap)
      : getCurrentSelectionBetsByTypeForWhatIf(marketId, handicap, MatchTypes.UNMATCHED),
    (selectedBets, inlinePlacement, unMatchBets): TNewWhatValue[] => {
      const selected = mapperSelectedBetsToWhatIfValue(selectedBets, marketId);
      const inline = mapperInlinePlacementToWhatIfValue(inlinePlacement, marketId);
      const unMatch = mapperUnMatchToWhatIfValue(unMatchBets, marketId, numberOfWinners);

      return [...selected, ...inline, ...unMatch];
    }
  );
