import { Dispatch, memo, SetStateAction, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { MOBILE_LIST_VIEW_MAX_TABS } from 'constants/app';
import { SPORT_BASE_URL } from 'constants/locations';
import { getListViewMarkets, getListViewTopMarket } from 'redux/modules/listViewTabs/selectors';
import { TListViewTabs } from 'types';

import styles from '../../styles.module.scss';

interface MobileListProps {
  onSelectAllMarketsTab: Dispatch<SetStateAction<boolean>>;
  mobileListViewTabs: TListViewTabs[];
  sportId: string;
  marketId: string;
}

const MobileList = ({
  onSelectAllMarketsTab,
  mobileListViewTabs,
  sportId,
  marketId: selectedMarketId
}: MobileListProps) => {
  const { t } = useTranslation();

  const marketCatalogues = useSelector(getListViewMarkets);
  const topMarket = useSelector(getListViewTopMarket);

  const listViewWrapperRef = useRef<HTMLDivElement>(null);
  const hiddenListViewRef = useRef<HTMLUListElement>(null);
  const scrollTabRef = useRef<HTMLLIElement>(null);

  const isMarketActive = (marketId: string) => {
    if (selectedMarketId) {
      return selectedMarketId === marketId;
    } else {
      return marketId === topMarket?.marketId;
    }
  };

  const scrollToCenter = (target: HTMLLIElement | Element | undefined) => {
    target?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });
  };

  useEffect(() => {
    if (mobileListViewTabs.length) {
      const marketIndex = mobileListViewTabs.findIndex(market => market.id === selectedMarketId);

      scrollToCenter(hiddenListViewRef?.current?.children[marketIndex]);
    }
  }, [mobileListViewTabs, selectedMarketId]);

  const getUrl = (id: string) => {
    if (sportId.includes('tc-')) {
      return `${SPORT_BASE_URL}/${sportId}/market/tc-${id}`;
    }
    return `${SPORT_BASE_URL}/${sportId}/market/${id}`;
  };

  return (
    <div className={classNames('biab_list-view-tabs-wrapper', styles.mobile__listViewWrapper)} ref={listViewWrapperRef}>
      <div className={styles.mobile__scroll__listViewWrapper}>
        <ul
          className={classNames('biab_list-view-tabs', styles.listView, styles.mobile__listView)}
          ref={hiddenListViewRef}
        >
          {mobileListViewTabs.map(({ id, name }) => (
            <li
              key={id}
              className={classNames(styles.listView__tab, styles.mobile__listView__tab, {
                [`${[styles.listView__tab__active]} ${[styles.mobile__listView__tab__active]} biab_active`]:
                  isMarketActive(id)
              })}
              ref={scrollTabRef}
              onClick={event => scrollToCenter(event.target as HTMLLIElement)}
            >
              <NavLink
                to={getUrl(id)}
                className={classNames(styles.listView__tab__links, styles.mobile__listView__tab__links)}
              >
                {name}
              </NavLink>
            </li>
          ))}
          {marketCatalogues.length > MOBILE_LIST_VIEW_MAX_TABS && (
            <li
              className={classNames(
                'biab_more-tab',
                styles.listView__tab,
                styles.mobile__listView__tab,
                styles.listView__tab__more
              )}
              onClick={() => onSelectAllMarketsTab(true)}
            >
              <span className={classNames(styles.listView__tab__links, styles.mobile__listView__tab__links)}>
                {t('market.listView.allMarkets')}
              </span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default memo(MobileList);
