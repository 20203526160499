import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedAllLeagues } from 'redux/modules/asianViewLeaguesFilter/index';
import { getIsAllLeaguesSelected } from 'redux/modules/asianViewLeaguesFilter/selectors';

import styles from './styles.module.scss';

const LeaguesSelectAll = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAllSelected = useSelector(getIsAllLeaguesSelected);

  const selectAllHandler = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedAllLeagues(e.target.checked));
  };

  return (
    <div className={styles.selectAll}>
      <label>
        <input type="checkbox" checked={isAllSelected} onChange={selectAllHandler} />
        <span>{t('asianView.labels.leaguesFilter.selectAll')}</span>
      </label>
    </div>
  );
};

export default LeaguesSelectAll;
