import Skeleton from 'components/Skeleton';

import styles from './AVBetListMobileSkeleton.module.scss';

const AVBetListMobileSkeleton = () => {
  return (
    <div className={styles.container}>
      <Skeleton className={styles.container__top} />
      <div className={styles.container__middle}>
        <Skeleton className={styles.container__middle__left} />
        <Skeleton className={styles.container__middle__right} />
      </div>
      <div className={styles.container__bottom}>
        <Skeleton className={styles.container__bottom__item} />
        <Skeleton className={styles.container__bottom__item} />
        <Skeleton className={styles.container__bottom__item} />
        <Skeleton className={styles.container__bottom__item} />
      </div>
    </div>
  );
};

export default AVBetListMobileSkeleton;
