import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { TGameDataProperties } from 'redux/modules/games/type';

import styles from './styles.module.scss';

interface IGameHiloCardItem {
  info?: TGameDataProperties;
  index: number;
}

const GameHiloCardItem = ({ info, index }: IGameHiloCardItem) => {
  const { t } = useTranslation();
  const startText = t('games.hilo.start');
  const latestText = t('games.hilo.last');
  const getBoardItemText = () => {
    switch (index) {
      case 0:
        return startText;
      case 12:
        return latestText;
      default:
        return index;
    }
  };

  if (!info) {
    return (
      <div
        className={classNames(styles.cartItem, {
          [styles.margin51]: index === 7
        })}
      >
        <div className={classNames(styles.notAvailable, 'biab_not-available-card')} />
        <div className={styles.index}>{getBoardItemText()}</div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        styles.cartItem,
        {
          [styles.margin51]: index === 7
        },
        'biab_hilo-cards-board-item'
      )}
    >
      <img src={require(`assets/images/games/cardsBig/card${info.value}.gif`)} alt={'card image'} />
      <div className={classNames(styles.index, 'biab_hilo-cards-board-item-text')}>{getBoardItemText()}</div>
    </div>
  );
};

export default GameHiloCardItem;
