import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { AV_BET_SLIP_ODDS_HIGHLIGHT_TIMEOUT } from 'constants/asianView';
import { getUserAsianViewAutoRefresh } from 'redux/modules/user/selectors';

type Params = {
  hasAutoRefresh: boolean;
  isDisabledBet: boolean;
  odds?: number;
};

const useAsianViewPlacementHighlighting = ({ hasAutoRefresh, odds, isDisabledBet }: Params) => {
  const userAsianViewAutoRefresh = useSelector(getUserAsianViewAutoRefresh);

  const [areOddsHighlighted, setAreOddsHighlighted] = useState(false);

  const highlightTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (hasAutoRefresh && !isDisabledBet) {
      setAreOddsHighlighted(true);

      highlightTimeout.current = setTimeout(() => {
        setAreOddsHighlighted(false);
      }, AV_BET_SLIP_ODDS_HIGHLIGHT_TIMEOUT);
    }

    return () => {
      if (highlightTimeout.current) {
        clearTimeout(highlightTimeout.current);
      }
    };
  }, [odds, userAsianViewAutoRefresh]);

  return { areOddsHighlighted };
};

export default useAsianViewPlacementHighlighting;
