import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { TNavigationResponse } from '../competitions/type';

import { failureGetSports, fetchSports, successGetSports } from './index';

function* getSportsWorker() {
  try {
    const response: TNavigationResponse[] = yield call(api.sports.getSports);
    yield put(successGetSports(response));
  } catch (error: any) {
    yield put(failureGetSports(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchSports.type, getSportsWorker)]);
}
