import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import NavSkeleton from 'components/Skeleton/NavSkeleton';
import AllSportsButton from 'components/SportsSection/partials/AllSportsButton';
import ListItem from 'components/SportsSection/partials/ListItem';
import CollapseSection from 'components/SportsSection/SportView/Partials/CollapseSection';
import { TIME_FILTERS } from 'constants/app';
import { TYPE_TO_URL } from 'constants/competitions';
import {
  getAppDevice,
  getDesktopSettingsCompetitionViewSports,
  getIsCompetitionViewEnabled,
  getLanguage,
  getTimezone,
  getTranslation
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  SPORT_INNER_COLLAPSE_MORE,
  SPORT_INNER_COLLAPSE_TIME_FILTER,
  SPORT_INNER_COLLAPSE_TOP_COMPETITIONS,
  SPORT_INNER_COLLAPSE_TOP_COUNTRY
} from 'redux/modules/collapse/constants';
import {
  fetchGetSportCompetition,
  fetchGetSports,
  getCompetitionAndCountry,
  getSportDataForTCAndRedirect,
  resetSportData,
  setRace
} from 'redux/modules/competitions';
import {
  getIsAllSoccer,
  getRaceLoading,
  getSportAvailableFilters,
  getSportData,
  getSportLoading,
  getSportMore,
  getSportNameHighlighted,
  getSportNameTranslations,
  getSportParents,
  getSportTopCompetitions,
  getSportTopCountries
} from 'redux/modules/competitions/selectors';
import { SportId } from 'types';
import { filterCompetitionViewParents } from 'utils/navigation';

import NavItemName from '../partials/NavItemName';
import ParentItem from '../partials/ParentItem';

const SportView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sportId, competitionId, countryId, eventId, marketId, groupId } = useParams();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isAllSoccer = useSelector(getIsAllSoccer);
  const sportData = useSelector(getSportData);
  const sportNameTranslations = useSelector(getSportNameTranslations);
  const sportParents = useSelector(getSportParents);
  const sportMore = useSelector(getSportMore);
  const sportLoading = useSelector(getSportLoading);
  const raceLoading = useSelector(getRaceLoading);
  const sportTopCountries = useSelector(getSportTopCountries);
  const sportTopCompetitions = useSelector(getSportTopCompetitions);
  const sportNameHighlighted = useSelector(getSportNameHighlighted);
  const sportAvailableFilters = useSelector(getSportAvailableFilters);
  const domainLanguage = useSelector(getTranslation);
  const language = useSelector(getLanguage);
  const timezone = useSelector(getTimezone);
  const device = useSelector(getAppDevice);
  const isCompetitionViewEnabled = useSelector(getIsCompetitionViewEnabled);
  const competitionViewSports = useSelector(getDesktopSettingsCompetitionViewSports);

  const isTennis = sportId === SportId.TENNIS;
  const isDesktop = device === Devices.DESKTOP;
  const isMobile = device === Devices.MOBILE;

  useEffect(() => {
    if (
      sportId &&
      (!competitionId || TIME_FILTERS.includes(competitionId)) &&
      !countryId &&
      !eventId &&
      !marketId &&
      !groupId
    ) {
      if (isCompetitionViewEnabled && competitionViewSports.includes(sportId)) {
        if (sportId === SportId.SOCCER) {
          dispatch(getCompetitionAndCountry(sportId));
          return;
        }
        dispatch(fetchGetSportCompetition({ eventId: sportId, showGroups: isTennis }));
        return;
      } else {
        dispatch(fetchGetSports({ sportId }));
      }
    }
  }, [
    sportId,
    competitionId,
    countryId,
    eventId,
    marketId,
    groupId,
    isCompetitionViewEnabled,
    competitionViewSports,
    timezone,
    isLoggedIn,
    language
  ]);

  useEffect(() => {
    if (sportId?.startsWith('tc') && sportData?.[0]?.id) {
      dispatch(getSportDataForTCAndRedirect({ id: sportId, navigate }));
    }
  }, [sportId, sportData]);

  useEffect(() => {
    return () => {
      dispatch(setRace(null));
      dispatch(resetSportData());
    };
  }, []);

  return (
    <>
      {(sportLoading || raceLoading) && <NavSkeleton withDelay={150} itemsCount={40} />}
      <div className={classNames({ hidden: sportLoading || raceLoading })}>
        {device === Devices.MOBILE && !(sportLoading || raceLoading) && <AllSportsButton />}
        {filterCompetitionViewParents(sportParents, competitionViewSports, sportId, isCompetitionViewEnabled).map(
          (parent, index, array) => (
            <ParentItem key={parent.id} item={parent} isLast={array.length - 1 === index} />
          )
        )}
        {!isEmpty(sportNameTranslations) && (
          <NavItemName
            type={TYPE_TO_URL.EVENT_TYPE}
            highlighted={sportNameHighlighted}
            nameTranslations={sportNameTranslations}
          />
        )}
        {isMobile &&
          !!sportAvailableFilters.length &&
          isCompetitionViewEnabled &&
          sportId &&
          !isAllSoccer &&
          competitionViewSports.includes(sportId) && (
            <CollapseSection
              collapseName={SPORT_INNER_COLLAPSE_TIME_FILTER}
              isFilterList
              filterData={sportAvailableFilters}
            />
          )}
        {(isDesktop || isAllSoccer) &&
          sportData.map(s => <ListItem textLeft key={s.id} item={s} domainLanguage={domainLanguage} />)}
        {isMobile &&
          !isAllSoccer &&
          sportId &&
          (competitionViewSports.includes(sportId) ? (
            <>
              {!!sportTopCompetitions.length && (
                <CollapseSection collapseName={SPORT_INNER_COLLAPSE_TOP_COMPETITIONS} data={sportTopCompetitions} />
              )}
              {!!sportTopCountries.length && (
                <CollapseSection collapseName={SPORT_INNER_COLLAPSE_TOP_COUNTRY} data={sportTopCountries} />
              )}
            </>
          ) : (
            sportData.map(s => <ListItem key={s.id} item={s} domainLanguage={domainLanguage} />)
          ))}
        {!!sportMore.length && <CollapseSection collapseName={SPORT_INNER_COLLAPSE_MORE} data={sportMore} isMore />}
      </div>
    </>
  );
};

export default SportView;
