import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { keys } from 'lodash';

import sectionStyles from 'components/AsianSportSection/styles.module.scss';
import { ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME } from 'constants/asianView';
import { asianViewBetslipBranding as branding } from 'constants/branding';
import { useAppThunkDispatch } from 'redux/configureStore';
import { removeAllSelectedBets } from 'redux/modules/asianViewBetslip';
import { setActiveSelectedBetsFromStorage } from 'redux/modules/asianViewBetslip';
import { getAsianViewBetslipSelectedTab } from 'redux/modules/asianViewBetslip/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { ASIAN_VIEW_BETSLIP_COLLAPSE, LEFT_PANEL_COLLAPSE_GROUP_ASIAN } from 'redux/modules/collapse/constants';
import { getOpenedStateByGroup } from 'redux/modules/collapse/selectors';
import { getIsUserInfoLoaded } from 'redux/modules/user/selectors';
import { EAsianBetslipTabs } from 'types/asianView';

import AVBetSlipTabs from './components/AVBetSlipTabs/AVBetSlipTabs';
import BetListTabContent from './components/BetListTabContent';
import BetSlipTabContent from './components/BetSlipTabContent';
import CashOutTabContent from './components/CashOutTabContent';
import LimitPopup from './components/LimitPopup';

import styles from './styles.module.scss';

const CONTENT_COMPONENTS = {
  [EAsianBetslipTabs.BET_SLIP]: <BetSlipTabContent />,
  [EAsianBetslipTabs.BET_LIST]: <BetListTabContent />,
  [EAsianBetslipTabs.CASH_OUT]: <CashOutTabContent />
};

const AsianViewBetslip = () => {
  const dispatch = useDispatch();
  const thunkDispatch = useAppThunkDispatch();

  const selectedTab = useSelector(getAsianViewBetslipSelectedTab);
  const openedState = useSelector(getOpenedStateByGroup(LEFT_PANEL_COLLAPSE_GROUP_ASIAN, ASIAN_VIEW_BETSLIP_COLLAPSE));
  const isUserInfoLoaded = useSelector(getIsUserInfoLoaded);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  useEffect(() => {
    if (isUserInfoLoaded) {
      const selectedBetsStorageString = localStorage.getItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME);
      const selectedBetsFromStorage = selectedBetsStorageString ? JSON.parse(selectedBetsStorageString) : {};

      if (keys(selectedBetsFromStorage).length) {
        if (isLoggedIn) {
          thunkDispatch(setActiveSelectedBetsFromStorage(selectedBetsFromStorage || {}));
        } else {
          localStorage.removeItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME);
        }
      }

      if (!isLoggedIn) {
        dispatch(removeAllSelectedBets());
      }
    }
  }, [isLoggedIn, isUserInfoLoaded]);

  return (
    <>
      <div className={classNames(sectionStyles.main, styles.mainBetslip, branding.BETSLIP)}>
        <AVBetSlipTabs />
        {openedState && CONTENT_COMPONENTS[selectedTab]}
      </div>
      <LimitPopup />
    </>
  );
};

export default AsianViewBetslip;
