import { MouseEvent, useRef } from 'react';
import BodyClassName from 'react-body-classname';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';

import TourModalContainer from 'components/HowToSection/components/TourModalContainer';
import { ROUTER_BASE_URL } from 'constants/app';
import { AsianViewMarketLink, AsianViewTimeFilter } from 'constants/asianView';
import { ASIAN_VIEW, CASH_OUT_PAGE, HOW_TO, IN_PLAY } from 'constants/icons';
import { HOME_BASE_URL } from 'constants/locations';
import { useOverLayFadeOutTimer } from 'hooks/useOverlayFadeOutTimer';
import useWebTours from 'hooks/useWebTours';
import {
  getInPlayDefTabHighlights,
  getIsIframeEnabled,
  getMobileSettingsClassicNavigation,
  getMobileSettingsClassicNavigationLinks,
  getMobileSettingsSwipeMenuLinks,
  getOperator
} from 'redux/modules/appConfigs/selectors';
import { NavigationType } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getFavorites } from 'redux/modules/favorites/selectors';
import { SportId } from 'types';
import { InPlayFilter } from 'types/inPlay';
import { getClassicIconName, getDefaultLinkTarget } from 'utils/navigation';

import styles from './styles.module.scss';

interface MobileNavigationItemProps {
  /**
   * Subheader navigation link for mobile view
   */
  navigationType: NavigationType;
  onClick: (params: { offsetLeft: number; event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent> }) => void;
}

const MobileNavigationItem = ({ navigationType, onClick }: MobileNavigationItemProps) => {
  const location = useLocation();
  const { sportId: sportIdParam } = useParams();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const favorites = useSelector(getFavorites);
  const operator = useSelector(getOperator);
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const inPlayDefTabHighlights = useSelector(getInPlayDefTabHighlights);
  const classicNavigation = useSelector(getMobileSettingsClassicNavigation);
  const classicNavigationLinks = useSelector(getMobileSettingsClassicNavigationLinks);
  const swipeMenuLinks = useSelector(getMobileSettingsSwipeMenuLinks);

  const {
    selectedTour,
    openTourModal,
    setSelectedTour,
    setOpenTourModal,
    changeSelectedTour,
    handleStartTour,
    tourLoading,
    hideTours,
    setHideTours
  } = useWebTours();

  const { showOverlay: showOverlayBlock, setShowOverLay: setShowOverlayBlock } = useOverLayFadeOutTimer(1400);
  const { iconName, url, label, openInNewTab, sportId, modifiable, authUserVisible, page, absolutePath } =
    navigationType;

  const useNavRef = useRef<HTMLLIElement>(null);

  const navigation =
    classicNavigation && location.pathname.replace(/\/\/*/g, '/').replace(/\/+$/, '') === ROUTER_BASE_URL
      ? classicNavigationLinks
      : swipeMenuLinks;

  const classicIconName = getClassicIconName(
    {
      iconName,
      sportId,
      modifiable,
      url,
      page
    },
    operator,
    !!favorites.length
  );
  const dataNavAtr = classicIconName?.split(' ')?.at(-1);

  const getNavigationClassName = (linkURL: string) => {
    let isActive = false;
    if (location.pathname === HOME_BASE_URL && linkURL === HOME_BASE_URL) {
      isActive = true;
    }
    if (navigationType.modifiable) {
      const [, , id] = linkURL.split('/');
      isActive = !!id && id === sportIdParam;
    } else {
      if (location.pathname !== HOME_BASE_URL && linkURL !== HOME_BASE_URL) {
        const sportLinkExists = navigation.some(l => {
          const [, , id] = l.url.split('/');
          return id === sportIdParam;
        });

        isActive = (!sportIdParam || !sportLinkExists) && location.pathname?.includes(linkURL);
      }
    }

    const isInPlayLink = url === IN_PLAY;

    return classNames(styles.headerNavigationMobileItem__link, 'biab_mobile-top-nav-link', {
      biab_active: isActive && !isInPlayLink,
      [styles.active]: isActive,
      'biab_in-play-btn': isInPlayLink
    });
  };

  let linkURL = url;

  if (url === IN_PLAY) {
    linkURL = `${url}/${inPlayDefTabHighlights ? InPlayFilter.HIGHLIGHTS : InPlayFilter.ALL}`;
  } else if (url === ASIAN_VIEW) {
    linkURL = `${url}/sport/${SportId.SOCCER}/timeFilter/${AsianViewTimeFilter.Today}/marketLink/${AsianViewMarketLink.HDP_AND_OU}`;
  }

  const handleHowToModal = (e: MouseEvent) => {
    e.preventDefault();
    if (!openTourModal && !showOverlayBlock) {
      handleStartTour();
    }
  };

  const handleClose = () => {
    setHideTours(false);
    setSelectedTour(undefined);
    setShowOverlayBlock(true);
  };

  return (
    <>
      {(!isLoggedIn || (isLoggedIn && authUserVisible)) && (
        <li className={styles.headerNavigationMobileItem} ref={useNavRef}>
          {linkURL === HOW_TO ? (
            <a
              onClick={handleHowToModal}
              className={classNames(
                'biab_mobile-top-nav-link',
                styles.headerNavigationMobileItem__link,
                styles.headerNavigationMobileItem__howToLink
              )}
            >
              <TourModalContainer
                tourLoading={tourLoading}
                onClose={handleClose}
                tourHeader={selectedTour?.tourHeader as string}
                tourId={selectedTour?.id as number}
                openTourModal={openTourModal}
                setOpenTourModal={setOpenTourModal}
                changeSelectedTour={changeSelectedTour}
                fromWelcomePopup={hideTours}
              />
              <i
                data-nav-icon={dataNavAtr}
                className={classNames(styles.headerNavigationMobileItem__icon, classicIconName, 'biab_icon', {
                  [styles.headerNavigationMobileItem__cacheOut]: CASH_OUT_PAGE === page
                })}
              >
                <span className="path1 biab_icon" />
                <span className="path2 biab_icon-inner" />
              </i>
              <span className={styles.headerNavigationMobileItem__label}>{label}</span>
              <BodyClassName className={classNames({ disableScroll: openTourModal })} />
              {showOverlayBlock && <div className={styles.backdrop} />}
            </a>
          ) : (
            <>
              {absolutePath ? (
                <a
                  href={linkURL}
                  target={getDefaultLinkTarget({
                    url,
                    absolutePath,
                    isIframeEnabled,
                    currentTarget: openInNewTab ? '_blank' : '',
                    openInNewTab: openInNewTab
                  })}
                  title={label}
                  onClick={e => onClick({ offsetLeft: useNavRef.current?.offsetLeft || 0, event: e })}
                  className={classNames('biab_mobile-top-nav-link', styles.headerNavigationMobileItem__link)}
                >
                  <i
                    data-nav-icon={dataNavAtr}
                    className={classNames(styles.headerNavigationMobileItem__icon, classicIconName, 'biab_icon', {
                      [styles.headerNavigationMobileItem__cacheOut]: CASH_OUT_PAGE === page
                    })}
                  >
                    <span
                      className={classNames('path1', {
                        biab_icon: linkURL === HOW_TO
                      })}
                    />
                    <span
                      className={classNames('path2', {
                        'biab_icon-inner': linkURL === HOW_TO
                      })}
                    />
                  </i>
                  <span className={styles.headerNavigationMobileItem__label}>{label}</span>
                </a>
              ) : (
                <NavLink
                  className={getNavigationClassName(linkURL)}
                  end
                  to={linkURL}
                  onClick={e => onClick({ offsetLeft: useNavRef.current?.offsetLeft || 0, event: e })}
                  target={getDefaultLinkTarget({
                    url,
                    absolutePath,
                    isIframeEnabled,
                    currentTarget: openInNewTab ? '_blank' : '',
                    openInNewTab
                  })}
                  title={label}
                >
                  <i
                    data-nav-icon={dataNavAtr}
                    className={classNames(styles.headerNavigationMobileItem__icon, classicIconName, 'biab_icon', {
                      [styles.headerNavigationMobileItem__cacheOut]: CASH_OUT_PAGE === page
                    })}
                  >
                    <span
                      className={classNames('path1', {
                        biab_icon: linkURL === HOW_TO
                      })}
                    />
                    <span
                      className={classNames('path2', {
                        'biab_icon-inner': linkURL === HOW_TO
                      })}
                    />
                  </i>
                  <span className={styles.headerNavigationMobileItem__label}>{label}</span>
                </NavLink>
              )}
            </>
          )}
        </li>
      )}
    </>
  );
};

export default MobileNavigationItem;
