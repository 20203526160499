import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { setSelectedLeague } from 'redux/modules/asianViewLeaguesFilter/index';
import { TAsianViewLeaguesFilterData } from 'redux/modules/asianViewLeaguesFilter/type';

import styles from './styles.module.scss';

interface LeagueItemProps {
  league: TAsianViewLeaguesFilterData;
}

const LeagueItem = ({ league }: LeagueItemProps) => {
  const dispatch = useDispatch();

  const translation = useSelector(getTranslation);
  const isMobileAsianView = useSelector(getIsMobileAsianView);

  const getName = () => {
    if (league.translations) {
      return league.translations[translation];
    }

    return league.name;
  };

  const onCheckedHandler = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedLeague({ ...league, isSelected: e.target.checked }));
  };

  return (
    <li
      key={league.id}
      className={classNames(styles.item, {
        [styles.item__mobile]: isMobileAsianView
      })}
    >
      <label>
        <input type="checkbox" checked={league.isSelected} onChange={onCheckedHandler} />
        <span>{getName()}</span>
      </label>
    </li>
  );
};

export default LeagueItem;
