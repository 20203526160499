import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { TFetchAsianViewListResponse } from 'redux/modules/asianView/type';

import {
  failureFavouritesMobileEventsList,
  failureFetchAsianViewFavourites,
  failureSaveRemoveAsianViewFavourite,
  fetchAsianViewFavourites,
  fetchFavouritesMobileEventsList,
  removeAsianViewFavourite,
  saveAsianViewFavourite,
  successFetchAsianViewFavourites,
  successFetchFavouritesMobileEventsList,
  successSaveRemoveAsianViewFavourite
} from './index';
import { AsianViewFavouriteResponse } from './type';

function* getAsianViewFavoritesWorker() {
  try {
    const response: AsianViewFavouriteResponse[] = yield call(api.asianViewFavourites.getAsianViewFavourites);
    yield put(successFetchAsianViewFavourites(response));
  } catch (error: any) {
    yield put(failureFetchAsianViewFavourites(error));
  }
}

function* getSaveRemoveAsianViewFavoriteWorker(
  action: ReturnType<typeof saveAsianViewFavourite | typeof removeAsianViewFavourite>
) {
  try {
    const response: AsianViewFavouriteResponse[] = yield call(
      api.asianViewFavourites.saveRemoveAsianViewFavorite,
      action.payload.favourite
    );

    yield put(successSaveRemoveAsianViewFavourite(response));

    if (action.payload.onSuccess) {
      action.payload.onSuccess(action.payload.favourite);
    }
  } catch (error: any) {
    yield put(failureSaveRemoveAsianViewFavourite(error));
  }
}

function* getAsianViewFavoritesMobileEventsListWorker(action: ReturnType<typeof fetchFavouritesMobileEventsList>) {
  try {
    let response: TFetchAsianViewListResponse = yield call(
      api.asianViewFavourites.getFavouritesEventsList,
      action.payload
    );

    // This condition needs if page from payload is bigger than last available page (totalPages) in response, so we need to do one more request with valid page
    if (
      !action.payload.isInfiniteScrollEnabled &&
      action.payload.page &&
      action.payload.changePage &&
      action.payload.page + 1 > response.totalPages
    ) {
      response = yield call(api.asianViewFavourites.getFavouritesEventsList, {
        ...action.payload,
        page: response.totalPages - 1
      });
      action.payload.changePage(response.totalPages - 1);
    }

    yield put(
      successFetchFavouritesMobileEventsList({
        ...response,
        isInfiniteScrollEnabled: action.payload.isInfiniteScrollEnabled
      })
    );
  } catch (error: any) {
    yield put(failureFavouritesMobileEventsList(error));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchAsianViewFavourites.type, getAsianViewFavoritesWorker),
    takeLatest(saveAsianViewFavourite.type, getSaveRemoveAsianViewFavoriteWorker),
    takeLatest(removeAsianViewFavourite.type, getSaveRemoveAsianViewFavoriteWorker),
    takeLatest(fetchFavouritesMobileEventsList.type, getAsianViewFavoritesMobileEventsListWorker)
  ]);
}
