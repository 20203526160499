import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import AsianFooter from 'components/Footer/AsianFooter';
import Pagination from 'components/Pagination';
import {
  AV_BET_LIST_MOBILE_SORTING_VALUES,
  AV_MOBILE_BET_LIST_PAGE_PARAM,
  AV_MOBILE_BET_LIST_SEARCH_PARAM_MIN_LENGTH
} from 'constants/betList';
import { asianViewMiddleSection as branding } from 'constants/branding';
import { ACCOUNT_BASE_URL, ASIAN_BASE_URL, MY_BETS_BASE_URL } from 'constants/locations';
import usePagination from 'hooks/usePagination';
import { setBackendContent } from 'redux/modules/appConfigs';
import {
  getAsianViewMobileBetListPaginationSize,
  getIsAsianViewMobileBetListPaginationEnabled,
  getIsIframeEnabled
} from 'redux/modules/appConfigs/selectors';
import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import { resetAsianViewCashOut } from 'redux/modules/asianViewCashOut';
import {
  getAreAsianCurrentBetsLoaded,
  getAsianCurrentBetsGroupedByEventOrCompetitionId,
  getAVMatchedCurrentBetsCountByFilter
} from 'redux/modules/asianViewCurrentBets/selectors';
import { getLoggedInLoading, getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { resetEventsUpdatedData } from 'redux/modules/marketsPrices';
import { PagesFromBackend } from 'redux/modules/pages/types';
import { CookieNames } from 'types';
import { AvBetListMobileSorting } from 'types/asianView';
import { getDefaultLinkTarget } from 'utils/navigation';

import AVBetListMobileEmptyMessage from './components/AVBetListMobileEmptyMessage/AVBetListMobileEmptyMessage';
import AVBetListMobileEventCompetitionCard from './components/AVBetListMobileEventCompetitionCard/AVBetListMobileEventCompetitionCard';
import AVBetListMobileHeader from './components/AVBetListMobileHeader/AVBetListMobileHeader';
import AVBetListMobileIntervalsInjection from './components/AVBetListMobileIntervalsInjection/AVBetListMobileIntervalsInjection';
import AVBetListMobileSkeleton from './components/AVBetListMobileSkeleton/AVBetListMobileSkeleton';

import styles from './AsianViewBetListMobilePage.module.scss';

const tabs = [
  { value: 'all', label: 'asianView.labels.all' },
  { value: 'live', label: 'asianView.labels.timeFilter.live' },
  { value: 'future', label: 'asianView.labels.future' }
];

const AsianViewBetListMobilePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.ASIAN_VIEW_BET_LIST_SORTING]);
  const [searchParams] = useSearchParams();

  const sorting: AvBetListMobileSorting =
    cookies.ASIAN_VIEW_BET_LIST_SORTING || AV_BET_LIST_MOBILE_SORTING_VALUES.placementTime;

  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  const [searchValue, setSearchValue] = useState('');

  const isLandscapeView = useSelector(getIsLandscapeAsianView);
  const areAsianCurrentBetsLoaded = useSelector(getAreAsianCurrentBetsLoaded);
  const isPaginationEnabled = useSelector(getIsAsianViewMobileBetListPaginationEnabled);
  const currentBetsCount = useSelector(getAVMatchedCurrentBetsCountByFilter(selectedTab));
  const itemsCountOnPage = useSelector(getAsianViewMobileBetListPaginationSize);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const authStateLoading = useSelector(getLoggedInLoading);
  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const displayPagination =
    isPaginationEnabled &&
    searchValue.length < AV_MOBILE_BET_LIST_SEARCH_PARAM_MIN_LENGTH &&
    currentBetsCount > itemsCountOnPage;
  const page = Number(searchParams.get(AV_MOBILE_BET_LIST_PAGE_PARAM));

  const { groupedCurrentBets, totalPages } = useSelector(
    getAsianCurrentBetsGroupedByEventOrCompetitionId(sorting, searchValue, selectedTab, page, displayPagination)
  );

  const matchedBetsUrl = `${ASIAN_BASE_URL}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/matched/1`;

  const { onClickNextPage, onClickPrevPage, onClickPage, changePage } = usePagination({
    isPaginationEnabled,
    pageParamName: AV_MOBILE_BET_LIST_PAGE_PARAM,
    totalPages
  });

  const resetPage = () => {
    if (page !== 0) {
      changePage(0);
    }
  };

  const handleSelectTab = (newTab: string) => {
    if (newTab !== selectedTab) {
      setSelectedTab(newTab);
      resetPage();
    }
  };

  useEffect(() => {
    if (!isLoggedIn && !authStateLoading) {
      dispatch(setBackendContent(PagesFromBackend.UNAUTHORIZED));
    }
  }, [isLoggedIn, authStateLoading]);

  useEffect(() => {
    if (page + 1 > totalPages && totalPages > 0) {
      changePage(totalPages - 1, true);
    }
  }, [totalPages, page]);

  useEffect(() => {
    return () => {
      dispatch(resetAsianViewCashOut());
      dispatch(resetEventsUpdatedData());
    };
  }, []);

  return (
    <>
      <AVBetListMobileIntervalsInjection />
      {areAsianCurrentBetsLoaded ? (
        <>
          <div>
            <AVBetListMobileHeader searchValue={searchValue} setSearchValue={setSearchValue} resetPage={resetPage} />
            <section role="tablist" className={styles.mobileBetList__tabs}>
              {tabs.map(({ value, label }) => {
                return (
                  <button
                    key={value}
                    role="tab"
                    onClick={() => handleSelectTab(value)}
                    className={classNames(styles.mobileBetList__tab, branding.BET_LIST_TAB, {
                      [styles.mobileBetList__tab__selected]: value === selectedTab,
                      [branding.SELECTED]: value === selectedTab
                    })}
                  >
                    {t(label)}
                  </button>
                );
              })}
            </section>
            {!!groupedCurrentBets.length ? (
              <section
                className={classNames(styles.mobileBetList__cards, {
                  [styles.mobileBetList__cards__landscape]: isLandscapeView
                })}
              >
                {groupedCurrentBets.map(([id, currentBetsByEventCompetition]) => {
                  return <AVBetListMobileEventCompetitionCard key={id} betsGroup={currentBetsByEventCompetition} />;
                })}
              </section>
            ) : (
              <AVBetListMobileEmptyMessage
                isSearchValue={searchValue.length >= AV_MOBILE_BET_LIST_SEARCH_PARAM_MIN_LENGTH}
              />
            )}
            {(!displayPagination || page + 1 === totalPages) && (
              <div className={styles.mobileBetList__viewSettledBets}>
                <p className={styles.mobileBetList__viewSettledBets__text}>
                  {t('asianView.labels.betList.viewSettledBetsOnAccountPages')}
                </p>
                <Link
                  to={matchedBetsUrl}
                  target={getDefaultLinkTarget({
                    url: matchedBetsUrl,
                    absolutePath: false,
                    isIframeEnabled,
                    currentTarget: '_blank'
                  })}
                  className={classNames(styles.mobileBetList__viewSettledBets__link, branding.BET_LIST_BOTTOM_LINK)}
                >
                  {t('mobileMenu.myBets')}
                </Link>
              </div>
            )}
            {displayPagination && totalPages > 1 && (
              <Pagination
                page={page}
                totalPages={totalPages}
                onClickNextPage={onClickNextPage}
                onClickPage={onClickPage}
                onClickPrevPage={onClickPrevPage}
              />
            )}
          </div>
          <AsianFooter />
        </>
      ) : (
        <div>
          <AVBetListMobileHeader searchValue={searchValue} setSearchValue={setSearchValue} resetPage={resetPage} />
          <AVBetListMobileSkeleton />
        </div>
      )}
    </>
  );
};

export default AsianViewBetListMobilePage;
