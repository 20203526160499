import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BetStatuses, lineBettingType } from 'constants/myBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import {
  getMobileSettingsAlternativeLayOdds,
  getMobileSettingsLineMarketsSwitchBackLayOnCricket,
  getMobileSettingsReplaceBackLayWithUnderOver,
  getMobileSettingsTotalWinnings,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { betDetails } from 'redux/modules/myBets/selectors';
import { OddsType } from 'types';
import { alternativeBackOddsValue, alternativeLayOddsValue, betsSideValue, oddsValue } from 'utils/myBetsValues';
import convertOdds from 'utils/oddsConverter';

import MobileBetDescription from './components/MobileBetDescription';
import MobileDetailsContainer from './components/MobileDetailsContainer';
import MobileDetailsField from './components/MobileDetailsField';

interface MobileBetDetailProps {
  currentStatus?: BetStatuses;
}

const MobileBetDetail = ({ currentStatus }: MobileBetDetailProps) => {
  const { t } = useTranslation();

  const alternativeLayOdds = useSelector(getMobileSettingsAlternativeLayOdds);
  const totalWinnings = useSelector(getMobileSettingsTotalWinnings);
  const lineMarketsSwitchBackLayOnCricket = useSelector(getMobileSettingsLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getMobileSettingsReplaceBackLayWithUnderOver);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const bet = useSelector(betDetails);

  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  const isCurrentStatus = currentStatus === BetStatuses.Unmatched || currentStatus === BetStatuses.Matched;
  const isPastStatus = currentStatus !== BetStatuses.Unmatched && currentStatus !== BetStatuses.Matched;
  const profitValue = currentStatus === BetStatuses.Settled ? bet!.profit : bet!.potentialProfit;
  const liabilityValue = currentStatus === BetStatuses.Settled ? bet!.pastTotalLiability : bet!.liability;

  const getDateByStatus = () => {
    if (currentStatus === BetStatuses.Cancelled) {
      return bet!.cancelledDate;
    }

    return isPastStatus ? bet!.settledDate : bet!.placedDate;
  };
  const { date } = useParseMyBetsDates({
    dateToParse: getDateByStatus()
  });

  const formatCurrencySetting = { noRounding: true, isCheckIndian: true, noSymbol: !isMultiCurrencySupported };

  const { noFormattedAmount: winnings } = useFormatCurrency(
    bet?.totalWinnings || 0,
    bet?.currency,
    formatCurrencySetting
  );
  const { noFormattedAmount: profit } = useFormatCurrency(profitValue, bet?.currency, formatCurrencySetting);
  const { noFormattedAmount: liability } = useFormatCurrency(liabilityValue, bet?.currency, formatCurrencySetting);
  const { noFormattedAmount: stake } = useFormatCurrency(bet?.size || 0, bet?.currency, formatCurrencySetting);

  const statusValue = () => {
    if (isPNCEnabled && currentStatus === BetStatuses.Matched) {
      return t('pnc.account.mybets.labels.types.matched');
    }
    if (currentStatus === BetStatuses.Settled) {
      return t(`account.mybets.labels.${bet!.offerState.toLowerCase()}`);
    }
    if (currentStatus) {
      return t(`account.mybets.labels.types.${currentStatus.toLowerCase()}`);
    }
    return '';
  };

  const isMatchedOrSettled = currentStatus === BetStatuses.Matched || currentStatus === BetStatuses.Settled;
  const oddsTypeTooltip = bet?.asian ? ` ${t(`asianView.labels.oddsType.short.${OddsType.DEC}`)}` : '';

  return (
    <MobileDetailsContainer bet={bet!}>
      <MobileDetailsField
        name={
          isCurrentStatus
            ? t('account.mybets.labels.placed')
            : currentStatus === BetStatuses.Cancelled
            ? t('account.mybets.labels.cancelled')
            : t('account.mybets.labels.settled')
        }
        value={date}
      />
      <MobileDetailsField isSingleRow>
        <MobileBetDescription bet={bet!} isCurrentStatus={isCurrentStatus} currentStatus={currentStatus} />
      </MobileDetailsField>
      <MobileDetailsField
        name={t('account.mybets.labels.type')}
        value={t(betsSideValue(bet!, lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver))}
        capitalise
      />
      {bet?.asian && (
        <MobileDetailsField
          name={t('account.mybets.labels.oddsType')}
          value={t(`asianView.labels.oddsType.short.${bet?.oddsType}`)}
        />
      )}
      {!bet?.asian && alternativeLayOdds ? (
        <>
          <MobileDetailsField
            isWithTooltip={currentStatus === BetStatuses.Matched || currentStatus === BetStatuses.Settled}
            tooltipValue={alternativeBackOddsValue(bet!, { isRounded: false })}
            name={t('account.mybets.labels.backOdds')}
            value={alternativeBackOddsValue(bet!)}
          />
          <MobileDetailsField
            isWithTooltip={currentStatus === BetStatuses.Matched || currentStatus === BetStatuses.Settled}
            tooltipValue={alternativeLayOddsValue(bet!, { isRounded: false })}
            name={t(
              `account.mybets.labels.${bet!.triggeredByCashOut && bet!.disabledLayOdds ? 'cashedOut' : 'layOdds'}`
            )}
            value={alternativeLayOddsValue(bet!)}
          />
        </>
      ) : (
        <MobileDetailsField
          isWithTooltip={bet?.bettingType !== lineBettingType && isMatchedOrSettled}
          tooltipValue={`${oddsValue(bet!, { isRounded: false })}${oddsTypeTooltip}`}
          name={t('account.mybets.labels.odds')}
          value={bet?.asian ? convertOdds(oddsValue(bet) || '', bet.oddsType) : oddsValue(bet!)}
          tooltipHTMLValue={
            bet?.asian ? `${oddsValue(bet!)} ${t(`asianView.labels.oddsType.short.${OddsType.DEC}`)}` : ''
          }
        />
      )}
      <MobileDetailsField name={t('account.mybets.labels.stake')} value={stake} />

      {bet?.asian && <MobileDetailsField name={t('account.mybets.labels.risk')} value={liability || '--'} />}
      {!bet?.asian && totalWinnings && (
        <>
          <MobileDetailsField name={t('account.mybets.labels.liability')} value={liability || '--'} />
          <MobileDetailsField
            name={t('account.mybets.labels.totalWinnings')}
            value={bet?.totalWinnings ? winnings : ''}
          />
        </>
      )}
      <MobileDetailsField
        name={
          currentStatus === BetStatuses.Settled
            ? t('account.mybets.labels.profit.past')
            : t('account.mybets.labels.profit.current')
        }
        value={profitValue ? profit : ''}
      />
      <MobileDetailsField name={t('account.mybets.labels.status')} capitalise value={statusValue()} />
    </MobileDetailsContainer>
  );
};
export default MobileBetDetail;
