import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { add, subtract } from 'lodash';

import StepperInput from 'components/StepperInput';
import { mobileComponents } from 'constants/branding';
import { useStakeRegexPattern } from 'hooks/usePrecision';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import {
  getCurrency,
  getCurrencySteps,
  getMobileSettingsVirtualKeyboardBetslip
} from 'redux/modules/appConfigs/selectors';
import { EBetFocusFields } from 'redux/modules/betslip/type';
import { valueToNumber } from 'utils/betslip';
import { formatFloatNumber } from 'utils/betValidation';

import inputStyles from '../inputs.module.scss';

type LiabilityInputProps = {
  liabilityValue: string | number;
  onChangeLiability: (newLiability: number | string) => void;
  setFocused: () => void;
  validateSizeInput: (isLiabilityField?: boolean) => void;
  currentFocus: EBetFocusFields | null;
};

const LiabilityInput = ({
  liabilityValue,
  onChangeLiability,
  setFocused,
  validateSizeInput,
  currentFocus
}: LiabilityInputProps) => {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const { t } = useTranslation();

  const virtualKeyboardBetslip = useSelector(getMobileSettingsVirtualKeyboardBetslip);
  const currencySteps = useSelector(getCurrencySteps);
  const currency = useSelector(getCurrency);

  const stakeRegexPattern = useStakeRegexPattern();

  const isPlusBtnEnabled = +(liabilityValue ?? 0) < currency.maxBetSize;
  const isMinusBtnEnabled = +(liabilityValue ?? 0) > currency.minBetSize;
  const isFocused = currentFocus === EBetFocusFields.LIABILITY;

  useUpdateEffect(() => {
    if (!isTouched) {
      setIsTouched(true);
    }
  }, [liabilityValue]);

  const { minusStep, plusStep } = useMemo(() => {
    let minusSizeStep = 0,
      plusSizeStep = 0;

    currencySteps.forEach(curStep => {
      if (+liabilityValue >= curStep.min && (curStep.max == null || +liabilityValue < curStep.max)) {
        plusSizeStep = currency.step > curStep.step ? currency.step : curStep.step;
      }
      if (+liabilityValue > curStep.min && (curStep.max == null || +liabilityValue <= curStep.max)) {
        minusSizeStep = currency.step > curStep.step ? currency.step : curStep.step;
      }
    });

    return { minusStep: minusSizeStep, plusStep: plusSizeStep };
  }, [currencySteps, liabilityValue, currency.step]);

  const onLiabilityChange = (value: string) => {
    const changedValue = valueToNumber(value);

    if (changedValue === '' || stakeRegexPattern.test(changedValue)) {
      onChangeLiability(changedValue);
    }
  };

  const onBlur = () => {
    if (isTouched && liabilityValue !== '') {
      validateSizeInput(true);
    }
  };

  const onFocus = () => {
    if (!isFocused) {
      setFocused();
    }
  };

  const onAdd = () => {
    if (isPlusBtnEnabled && plusStep) {
      if (!liabilityValue) {
        onChangeLiability(currency.minBetSize);
      } else {
        onChangeLiability(formatFloatNumber(add(parseFloat(liabilityValue.toString()), plusStep)));
      }
    }
  };

  const onSubtract = () => {
    if (isMinusBtnEnabled && minusStep) {
      onChangeLiability(formatFloatNumber(subtract(parseFloat(liabilityValue.toString()), minusStep)));
    }
  };

  return (
    <StepperInput
      value={liabilityValue}
      label={t('betslip.labels.liability')}
      id="mobile-input-liability"
      name="liability"
      onFocus={onFocus}
      onDecrease={onSubtract}
      onIncrease={onAdd}
      onInputChange={onLiabilityChange}
      setFocused={setFocused}
      onBlur={onBlur}
      className={inputStyles.container}
      commonClassName={classNames(mobileComponents.INPUT, {
        [mobileComponents.FOCUS]: isFocused
      })}
      isInputReadOnly={virtualKeyboardBetslip}
      isDecreaseBtnDisabled={!isMinusBtnEnabled}
      isIncreaseBtnDisabled={!isPlusBtnEnabled}
      isFocused={isFocused}
    />
  );
};

export default LiabilityInput;
