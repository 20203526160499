import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { asianViewMiddleSection as branding } from 'constants/branding';

import styles from './styles.module.scss';

const SingleLineHeader = () => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'biab_asian-view-single-line-header',
        styles.singleLineHeader,
        branding.SINGLE_LINE_MARKET_HEADER
      )}
    >
      <div className={classNames(styles.singleLineHeader__col, styles.singleLineHeader__fullTime)}>
        {t('asianView.labels.column.fullTime')}
      </div>
      <div className={classNames(styles.singleLineHeader__col, styles.singleLineHeader__firstHalf)}>
        {t('asianView.labels.column.firstHalf')}
      </div>
      <div className={classNames(styles.singleLineHeader__col, styles.singleLineHeader__streaming)} />
    </div>
  );
};

export default SingleLineHeader;
