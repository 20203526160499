import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from 'components/SportsSection/styles.module.scss';
import { OPEN_ALL_SPORT } from 'constants/app';
import { POKER_STAR } from 'constants/icons';
import { HOME_BASE_URL, SPORT_BASE_URL } from 'constants/locations';
import { getAppDevice, getOperator } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getArrowLeftByOperator } from 'utils/operator';

const AllSportsButton = () => {
  const { t } = useTranslation();

  const operator = useSelector(getOperator);
  const device = useSelector(getAppDevice);

  const parentIcon = getArrowLeftByOperator(operator);

  return (
    <div>
      <li
        data-test-id={'all-sports'}
        className={classNames('biab_sport-item', styles.sportsList__item, styles.allSport)}
      >
        <Link
          className={classNames('biab_all-sports', styles.sportsList__link, styles.sportsList__allSports)}
          to={device === Devices.MOBILE ? SPORT_BASE_URL : HOME_BASE_URL}
          state={OPEN_ALL_SPORT}
        >
          {device === Devices.MOBILE && (
            <i
              className={classNames('biab_back-arrow-icon', styles.arrowContainer, parentIcon, {
                [styles.rotate]: operator === POKER_STAR,
                [styles.arrowContainer__default]: operator !== POKER_STAR
              })}
            />
          )}
          {t('account.profitandloss.labels.allSports')}
        </Link>
      </li>
    </div>
  );
};

export default AllSportsButton;
