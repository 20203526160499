import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import BetMax from 'components/AsianViewBetslip/components/AVSelectedBet/components/BetMax';
import BetMin from 'components/AsianViewBetslip/components/AVSelectedBet/components/BetMin';
import ActionButtons from 'components/AsianViewMobileBetslip/components/MobileSelectedBet/components/ActionButtons';
import EditButton from 'components/AsianViewMobileBetslip/components/MobileSelectedBet/components/EditButton';
import MobileInputControl from 'components/AsianViewMobileBetslip/components/MobileSelectedBet/components/InputControl';
import MobileMessages from 'components/AsianViewMobileBetslip/components/MobileSelectedBet/components/MessagesList';
import useAsianOdds from 'hooks/useAsianOdds';
import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import {
  AsianViewMobileBetActions,
  TAsianMobileSelectedBet,
  TAsianMobileUpdateData
} from 'redux/modules/asianViewMobileBetslip/type';
import { BetTypes } from 'types/bets';
import { EInputFieldTypes } from 'types/betslip';

import styles from './styles.module.scss';

type MobilePlacementProps = {
  bet: TAsianMobileSelectedBet;
  updateMobileBet: ({ size, profit, focusedField }: TAsianMobileUpdateData) => void;
};

const MobilePlacementForm = ({ bet, updateMobileBet }: MobilePlacementProps) => {
  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);

  const betPrice = useAsianOdds({ bet });

  const isBack = bet.betType === BetTypes.BACK;
  const isNew = bet.action === AsianViewMobileBetActions.NEW;
  const isValidation = bet.action === AsianViewMobileBetActions.VALIDATION;
  const isProgress = bet.action === AsianViewMobileBetActions.PROGRESS;
  const isConfirm = bet.action === AsianViewMobileBetActions.CONFIRM;

  useEffect(() => {
    if (betPrice && !isProgress && !isConfirm) {
      updateMobileBet({ price: betPrice });
    }
  }, [isProgress, betPrice, isConfirm]);

  useEffect(() => {
    if (bet.focusedField !== EInputFieldTypes.PROFIT) {
      updateMobileBet({ size: bet.size, action: bet.action });
    }
  }, [bet.focusedField]);

  return (
    <>
      <div className={classNames(styles.wrap, { [styles.wrap__landscape]: isLandscapeAsianView })}>
        <div className={classNames(styles.row, { [styles.row__2]: isLandscapeAsianView })}>
          <div className={styles.row__col}>
            <MobileInputControl
              bet={bet}
              type={isBack ? EInputFieldTypes.SIZE : EInputFieldTypes.PROFIT}
              updateMobileBet={updateMobileBet}
            />
          </div>
          <div className={styles.row__col}>
            <MobileInputControl
              bet={bet}
              type={isBack ? EInputFieldTypes.PROFIT : EInputFieldTypes.SIZE}
              updateMobileBet={updateMobileBet}
            />
          </div>
          {isLandscapeAsianView && isConfirm && <EditButton bet={bet} />}
        </div>
        {(isNew || isValidation) && (
          <div className={classNames(styles.row, { [styles.row__1]: isLandscapeAsianView })}>
            <div className={classNames(styles.row__col, { [styles.row__col__inner]: !isLandscapeAsianView })}>
              <BetMax bet={bet} updateBet={updateMobileBet} betMaxClassName={styles.betMax} />
              <BetMin marketId={bet.marketId} />
            </div>
          </div>
        )}
      </div>
      <MobileMessages bet={bet} />
      <ActionButtons bet={bet} />
    </>
  );
};

export default MobilePlacementForm;
