import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { TPrice } from 'types/bets';

import styles from './SelectedBetOddsNotificationContent.module.scss';

type SelectedBetOddsNotificationMessageProps = {
  initPrice: TPrice;
  isGoingUp: boolean;
  isLineMarket: boolean;
  odds?: number;
};

const SelectedBetOddsNotificationMessage = ({
  initPrice,
  odds,
  isGoingUp,
  isLineMarket
}: SelectedBetOddsNotificationMessageProps) => {
  const { t } = useTranslation();

  return (
    <p className={styles.notification}>
      <span>{t(isLineMarket ? 'betslip.labels.lineChanged' : 'betslip.labels.oddsChanged')} </span>
      <span className={styles.notification__oddsValues}>
        <span className={styles.notification__price}>{initPrice}</span>
        <i className={classNames('biab_custom-icon-right-arrow', styles.notification__arrow)} />
        <span
          className={classNames(styles.notification__price, {
            [styles.notification__price__more]: isGoingUp,
            [styles.notification__price__less]: !isGoingUp
          })}
        >
          {odds}
        </span>
        <i
          className={classNames(styles.notification__icon, `fa2 fa2-sort-${isGoingUp ? 'up' : 'down'} biab_indicator`, {
            [styles.notification__icon__more]: isGoingUp,
            [styles.notification__icon__less]: !isGoingUp
          })}
        />
      </span>
    </p>
  );
};

export default SelectedBetOddsNotificationMessage;
