import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GamesBaccaratResultsContent from 'components/GamesBaccaratResultsContent';
import GamesBlackJackResultsContent from 'components/GamesBlackJackResultsContent';
import GamesDerbyRacingResultsContent from 'components/GamesDerbyRacingResultsContent';
import GamesHiLoResultsContent from 'components/GamesHiLoResultsContent';
import PokerAndOmaha from 'components/ResultsScreen/components/PokerAndOmaha';
import { fetchResultsHistory } from 'redux/modules/games';
import { getGameType, getHistory } from 'redux/modules/games/selectors';
import { EGameTypes } from 'redux/modules/games/type';

interface GameResultPopupProps {
  channelId: number;
  gameId: string;
}

const GameResultPopup = ({ channelId, gameId }: GameResultPopupProps) => {
  const dispatch = useDispatch();
  const gameType = useSelector(getGameType);
  const resultsHistory = useSelector(getHistory);

  useEffect(() => {
    if (channelId) {
      dispatch(fetchResultsHistory({ channelId: `${channelId}`, page: 0, gameId }));
    }
  }, [channelId]);

  if (gameType && resultsHistory) {
    switch (gameType) {
      case EGameTypes.POKER:
        return <PokerAndOmaha resultsHistory={resultsHistory} isPopup />;
      case EGameTypes.OMAHA_POKER:
        return <PokerAndOmaha resultsHistory={resultsHistory} isPopup />;
      case EGameTypes.BLACKJACK:
        return <GamesBlackJackResultsContent resultsHistory={resultsHistory} />;
      case EGameTypes.BACCARAT:
        return <GamesBaccaratResultsContent resultsHistory={resultsHistory} />;
      case EGameTypes.HILO:
        return <GamesHiLoResultsContent resultsHistory={resultsHistory} />;
      case EGameTypes.CARD_RACER:
        return <GamesDerbyRacingResultsContent resultsHistory={resultsHistory} />;
    }
  }

  return null;
};

export default GameResultPopup;
