import { useSelector } from 'react-redux';

import Header from 'components/Header';
import HeaderNavigation from 'components/HeaderNavigation';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { getIsTimeOutEnabled } from 'redux/modules/responsibleBetting/selectors';

import styles from './styles.module.scss';

interface AsianHeaderProps {
  isSelfExcluded?: boolean;
}

const AsianHeader = ({ isSelfExcluded = false }: AsianHeaderProps) => {
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);

  return (
    <>
      <Header isAsianView isMobile={isMobileAsianView} isSelfExcluded={isSelfExcluded} />
      {!isMobileAsianView && !isSelfExcluded && !isTimeOutEnabled && (
        <div className={styles.headerMainWrapper}>
          <div className={styles.navigationWrapper}>
            <HeaderNavigation isMobile={isMobileAsianView} />
          </div>
        </div>
      )}
    </>
  );
};

export default AsianHeader;
