import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { PLBetType, plDefaultSportTypeValues } from 'constants/myBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { getDomain, getLanguage } from 'redux/modules/appConfigs/selectors';
import {
  getBetsTypeList,
  getMyBetsMultiCurrencyValue,
  getPLTotalData,
  getPLTotalEventData
} from 'redux/modules/myBets/selectors';
import { SelectDropdownValue } from 'types/myBets';

import Select from '../Select/Select';

import styles from './FiltersContainer.module.scss';

interface FiltersContainerProps {
  activePLTab: PLBetType;
  eventType: SelectDropdownValue;
  setEventType: (value: SelectDropdownValue) => void;
  dataExists: boolean;
}

const FiltersContainer = ({ activePLTab, eventType, setEventType, dataExists }: FiltersContainerProps) => {
  const { t } = useTranslation();

  const language = useSelector(getLanguage);
  const domain = useSelector(getDomain);
  const total = useSelector(getPLTotalData);
  const totalForEvent = useSelector(getPLTotalEventData);
  const betsTypeList = useSelector(getBetsTypeList(activePLTab));
  const multiCurrencyValue = useSelector(getMyBetsMultiCurrencyValue);

  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  const handleChangeSportValue = (value: SelectDropdownValue) => {
    setEventType(value);
  };

  const getName = () => {
    if (eventType?.translationKey) {
      return t(eventType.translationKey);
    }
    if (eventType?.translations) {
      return eventType?.translations[`${domain.toLowerCase()}|${language}`];
    }
    return eventType?.name;
  };

  const defaultValues =
    activePLTab === PLBetType.Sports ? [plDefaultSportTypeValues.sports] : [plDefaultSportTypeValues.games];
  const currencyCode = isMultiCurrencySupported && multiCurrencyValue ? multiCurrencyValue : '';

  const { noFormattedAmount: totalValue } = useFormatCurrency(total, currencyCode, {
    noRounding: true,
    isCheckIndian: true
  });
  const { noFormattedAmount: totalForEventValue } = useFormatCurrency(totalForEvent, currencyCode, {
    noRounding: true,
    isCheckIndian: true
  });

  return (
    <div className={classNames('biab_table-form-holder', styles.formHolder)}>
      <form className="biab_table-form">
        <fieldset>
          <div className={classNames('biab_form-row', styles.formRow)}>
            {activePLTab !== PLBetType.AsianView && (
              <div className={classNames('biab_form-col', styles.formColumn)}>
                <Select
                  defaultValues={defaultValues}
                  {...{ [activePLTab === PLBetType.Sports ? 'sportValues' : 'gameValues']: betsTypeList }}
                  handleChange={handleChangeSportValue}
                  name={eventType}
                />
              </div>
            )}
          </div>
        </fieldset>
      </form>
      {dataExists && (
        <div className={classNames('biab_total-block', styles.totalBlock)}>
          <span>
            {t('account.profitandloss.labels.totalPL')}: <strong>{totalValue}</strong>
          </span>
          {(eventType.id || eventType.gameId) && (
            <span className={styles.sportPL}>
              {getName()} {t('PLTable.PL')}: <strong>{totalForEventValue}</strong>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default FiltersContainer;
