import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { TCurrentBet } from 'redux/modules/currentBets/type';

import { failureGetAsianCurrentBets, fetchAsianCurrentBets, successGetAsianCurrentBets } from './index';

function* getAsianCurrentBetsWorker() {
  try {
    const response: TCurrentBet[] = yield call(api.asianPlacement.getAsianCurrentBets);

    yield put(successGetAsianCurrentBets({ bets: response, isWebSocketResponse: false }));
  } catch (error: any) {
    yield put(failureGetAsianCurrentBets(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchAsianCurrentBets.type, getAsianCurrentBetsWorker)]);
}
