import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import { asianViewDropdown } from 'constants/branding';
import { setSettings } from 'redux/modules/user';
import { getAccountSettings, getIsUserAccountSettings } from 'redux/modules/user/selectors';
import { CookieNames, DropdownItem } from 'types';
import { ELineViewTypes } from 'types/asianView';
import { convertLineViewFilterToDropdown } from 'utils/asianView';

import styles from '../filter.module.scss';

const LineViewFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([CookieNames.ASIAN_VIEW_LINE_VIEW_FILTER]);
  const accountSettings = useSelector(getAccountSettings);
  const isAccountSettings = useSelector(getIsUserAccountSettings);

  const currentSortingFilter =
    (isAccountSettings && accountSettings?.asianViewViewType) ||
    (cookies[CookieNames.ASIAN_VIEW_LINE_VIEW_FILTER] as ELineViewTypes) ||
    ELineViewTypes.DOUBLE;
  const convertedData = convertLineViewFilterToDropdown(t);

  const selectedItem = convertedData.find(item => item.value === currentSortingFilter);

  const onSelectOddsType = (dropdownItem: DropdownItem) => {
    if (isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            asianViewViewType: dropdownItem.value
          }
        })
      );
    } else {
      setCookie(CookieNames.ASIAN_VIEW_LINE_VIEW_FILTER, dropdownItem.value, {
        path: '/'
      });
    }
  };

  return (
    <>
      <Dropdown
        classes={{
          ...styles,
          placeholder: classNames(styles.placeholder, 'biab_line-view-filter'),
          container: 'biab_line-view-filter-wrapper'
        }}
        brandingClasses={asianViewDropdown}
        data={convertedData}
        defaultValue={selectedItem}
        onSelectOption={onSelectOddsType}
        title={false}
      />
    </>
  );
};

export default LineViewFilter;
