import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import GameHiloCardItem from 'components/GameHiloCardItem';
import GameResultsPlayersStatusesTable from 'components/GameResultsPlayersStatusesTable';
import GamesResultsPagination from 'components/GamesResultsPagination';
import { MyBetsLocations } from 'constants/myBets';
import {
  getIsAmericanDateFormatEnabled,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { getSelectedIndex } from 'redux/modules/games/selectors';
import { THistory } from 'redux/modules/games/type';
import { timeStampToStartDate } from 'utils/date';
import { generateArray } from 'utils/general';

import styles from '../GameCartsTableHiLo/styles.module.scss';

interface IGamesHiLoResultsContent {
  resultsHistory: THistory;
}

const GamesHiLoResultsContent = ({ resultsHistory }: IGamesHiLoResultsContent) => {
  const location = useLocation();
  const { t } = useTranslation();

  const selectedIndex = useSelector(getSelectedIndex);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);

  const currentItem = resultsHistory.content[selectedIndex];
  const startDate = currentItem.startDate;
  const cartsPlaces = generateArray(13);
  const startedParsedDate = timeStampToStartDate(startDate, americanDateFormatEnabled, timezoneCookieEnabled, timezone);
  const isMyBetsPage = location.pathname.includes(MyBetsLocations.MyBets);

  if (!resultsHistory) {
    return null;
  }

  return (
    <>
      <div
        className={classNames({
          [styles.myBetsTopSection]: isMyBetsPage
        })}
      >
        <div className={styles.startDate}>
          {t('games.results.started')}: {isMyBetsPage ? <strong>{startedParsedDate}</strong> : startedParsedDate}
        </div>
        <div className={styles.cards}>
          {cartsPlaces.map((item, index) => (
            <GameHiloCardItem
              index={index}
              info={resultsHistory.content[selectedIndex].gameData[0].properties[index]}
              key={index}
            />
          ))}
        </div>
      </div>
      {!isMyBetsPage && (
        <>
          <GamesResultsPagination history={resultsHistory} />
          <hr />
        </>
      )}

      <div className={styles.resultsWrapper}>
        <GameResultsPlayersStatusesTable markets={currentItem.markets} />
      </div>
    </>
  );
};

export default GamesHiLoResultsContent;
