import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { DESKTOP_NAVIGATION_HEIGHT } from 'constants/asianView';
import { asianViewBetslipBranding as branding } from 'constants/branding';
import { getElementHeight, getScrollContentHeight } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getAVMatchedCurrentBetsSortedByPlacedDate } from 'redux/modules/asianViewCurrentBets/selectors';

import OpenBet from './OpenBet';

import styles from './styles.module.scss';

const BetListTabContent = () => {
  const { t } = useTranslation();

  const openBets = useSelector(getAVMatchedCurrentBetsSortedByPlacedDate);
  const scrollContentHeight = useSelector(getScrollContentHeight);
  const favouritesHeight = useSelector(getElementHeight(EElementNames.ASIAN_FAVOURITES_NAVIGATION));
  const sportsNavHeight = useSelector(getElementHeight(EElementNames.ASIAN_NAVIGATION_SPORTS_HEIGHT));
  const timezoneHeight = useSelector(getElementHeight(EElementNames.ASIAN_TIMEZONE));
  const balanceHeight = useSelector(getElementHeight(EElementNames.ASIAN_BALANCE));
  const betSlipCollapseHeight = useSelector(getElementHeight(EElementNames.ASIAN_BET_SLIP_COLLAPSE));
  const betSlipTabsHeight = useSelector(getElementHeight(EElementNames.ASIAN_BET_SLIP_TABS));

  return (
    <div
      className={classNames('biab_open-bets-wrapper', styles.openBets, branding.BACKGROUND)}
      style={{
        maxHeight:
          scrollContentHeight -
          DESKTOP_NAVIGATION_HEIGHT -
          favouritesHeight -
          sportsNavHeight -
          timezoneHeight -
          balanceHeight -
          betSlipCollapseHeight -
          betSlipTabsHeight
      }}
    >
      {openBets && openBets.length === 0 && (
        <div className={styles.empty}>{t('asianView.labels.betslip.betListEmpty')}</div>
      )}
      {openBets.map(bet => (
        <OpenBet key={bet.offerId} bet={bet} />
      ))}
    </div>
  );
};

export default BetListTabContent;
