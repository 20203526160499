import { AsianViewTimeFrame } from 'constants/asianView';
import { AV_BET_LIST_MOBILE_SORTING_VALUES } from 'constants/betList';
import { TAsianViewRunner } from 'redux/modules/asianView/type';

export enum ESortingTypes {
  NORMAL = 'NORMAL',
  BY_TIME = 'BY_TIME'
}

export enum ELineViewTypes {
  SINGLE = 'singleLine',
  DOUBLE = 'doubleLine'
}

export enum ECorrectScoreMarkets {
  CORRECT_SCORE = 'CORRECT_SCORE',
  HALF_TIME_SCORE = 'HALF_TIME_SCORE'
}

export enum ECorrectScoreRunners {
  HOME = 'HOME',
  DRAW = 'DRAW',
  AWAY = 'AWAY',
  ANY_OTHER = 'ANY_OTHER',
  OTHER = 'OTHER'
}

export enum ECorrectScoreAnyOther {
  AOH = 'Any Other Home Win',
  AOA = 'Any Other Away Win',
  AOD = 'Any Other Draw'
}

export type TCorrectScoreRunners = {
  type: ECorrectScoreRunners;
  runners: TAsianViewRunner[];
};

export enum EAsianBetslipTabs {
  BET_SLIP = 'BET_SLIP',
  BET_LIST = 'BET_LIST',
  CASH_OUT = 'CASH_OUT'
}

export enum EAsianEventsCounter {
  numberOfInPlayEvents = 'numberOfInPlayEvents',
  numberOfTodayEvents = 'numberOfTodayEvents',
  numberOfOutrights = 'numberOfOutrights',
  numberOfEarlyEvents = 'numberOfEarlyEvents'
}

export enum EAsianMobileNav {
  LIVE = 'live',
  TODAY = 'today',
  OUTRIGHTS = 'outrights',
  EARLY = 'early'
}

export type TAsianMobileNavigationItem = {
  type: EAsianMobileNav;
  timeFrame?: AsianViewTimeFrame;
  label: string;
  counter: EAsianEventsCounter;
  counterValue?: number;
  subNavItems?: TAsianMobileNavigationItem[];
};

export enum EAsianPlacedBetMessageType {
  ERROR = 'ERROR',
  PLACED = 'PLACED',
  PLACED_WITH_BETTER_ODDS = 'PLACED_WITH_BETTER_ODDS'
}

export type AvBetListMobileSorting = keyof typeof AV_BET_LIST_MOBILE_SORTING_VALUES;
