import { all, call, put, takeLatest } from 'redux-saga/effects';

import { TYPE_TO_URL } from 'constants/competitions';
import { SPORT_BASE_URL } from 'constants/locations';
import api from 'redux/api/methods';

import {
  failureCompetitionByCountryAndSport,
  failureCompetitionDetails,
  failureFetchTimeFilters,
  failureGetCompetitionAndCountry,
  failureGetEvents,
  failureGetGroup,
  failureGetMarket,
  failureGetSportCompetition,
  failureGetSports,
  fetchCompetitionByCountryAndSport,
  fetchCompetitionDetails,
  fetchGetEvents,
  fetchGetGroup,
  fetchGetMarket,
  fetchGetSportCompetition,
  fetchGetSports,
  fetchTimeFilters,
  getCompetitionAndCountry,
  getSportDataForTCAndRedirect,
  setTodayCardLoading,
  successCompetitionByCountryAndSport,
  successCompetitionDetails,
  successFetchTimeFilters,
  successGetCompetitionAndCountry,
  successGetEvents,
  successGetGroup,
  successGetMarket,
  successGetSportCompetition,
  successGetSports
} from './index';
import {
  ETimeFilters,
  ICompetitionDetails,
  TCompetitionData,
  TCountryCompetitions,
  TMarketResponse,
  TNavigationResponse
} from './type';

export default function* saga() {
  function* fetchCompetitionDetailsWatcher(action: ReturnType<typeof fetchCompetitionDetails>) {
    try {
      const response: ICompetitionDetails = yield call(api.competitions.getCompetitionDetails, action.payload);
      yield put(successCompetitionDetails(response));
    } catch (error: any) {
      yield put(failureCompetitionDetails(error));
    }
  }

  function* getCompetitionAndCountryWatcher(action: ReturnType<typeof getCompetitionAndCountry>) {
    try {
      const response: TCompetitionData = yield call(api.competitions.getCountriesAndCompetitions, action.payload);
      yield put(successGetCompetitionAndCountry(response));
    } catch (error: any) {
      yield put(failureGetCompetitionAndCountry(error));
    }
  }

  function* getCountryCompetitionBySportWatcher(action: ReturnType<typeof fetchCompetitionByCountryAndSport>) {
    try {
      const response: TCountryCompetitions = yield call(
        api.competitions.getCountryCompetitionsBySportId,
        action.payload
      );
      yield put(successCompetitionByCountryAndSport(response));
    } catch (error: any) {
      yield put(failureCompetitionByCountryAndSport(error));
    }
  }

  function* getEventWatcher(action: ReturnType<typeof fetchGetEvents>) {
    try {
      const response: TNavigationResponse = yield call(api.competitions.getEvent, action.payload);
      yield put(successGetEvents({ ...response, updateParents: action.payload.updateParents }));
    } catch (error: any) {
      yield put(failureGetEvents(error));
    }
  }

  function* getMarketWatcher(action: ReturnType<typeof fetchGetMarket>) {
    try {
      const response: TMarketResponse = yield call(api.competitions.getMarket, action.payload);
      yield put(successGetMarket(response));
    } catch (error: any) {
      yield put(failureGetMarket(error));
    }
  }

  function* getSportCompetitionWatcher(action: ReturnType<typeof fetchGetSportCompetition>) {
    try {
      const response: TCompetitionData = yield call(api.competitions.getSportCompetition, action.payload);
      yield put(
        successGetSportCompetition({
          response,
          id: action.payload.eventId,
          allSoccer: action.payload.allSoccer || false
        })
      );
    } catch (error: any) {
      yield put(failureGetSportCompetition(error));
    }
  }

  function* getGroupWatcher(action: ReturnType<typeof fetchGetGroup>) {
    try {
      const response: TNavigationResponse = yield call(api.competitions.getGroup, action.payload);
      yield put(successGetGroup(response));
    } catch (error: any) {
      yield put(failureGetGroup(error));
    }
  }

  function* getSportWatcher(action: ReturnType<typeof fetchGetSports>) {
    try {
      const response: TNavigationResponse[] = yield call(api.competitions.getSports, action.payload.sportId);
      yield put(successGetSports(response));
    } catch (error: any) {
      yield put(failureGetSports(error));
    }
  }

  function* getTimeFilterWatcher(action: ReturnType<typeof fetchTimeFilters>) {
    try {
      const response: ETimeFilters[] = yield call(api.competitions.getTimeFilters, action.payload);
      yield put(successFetchTimeFilters(response));
    } catch (error: any) {
      yield put(failureFetchTimeFilters(error));
    }
  }

  function* getSportDataForTCAndRedirectWatcher(action: ReturnType<typeof getSportDataForTCAndRedirect>) {
    try {
      const sports: TNavigationResponse[] = yield call(api.competitions.getSports, action.payload.id);
      const races: TNavigationResponse = yield call(api.competitions.getRace, sports[0].id);
      yield put(setTodayCardLoading(false));
      action.payload.navigate(`${SPORT_BASE_URL}/${action.payload.id}/${TYPE_TO_URL.MARKET}/${races.children[0].id}`);
    } catch (e) {
      yield put(setTodayCardLoading(false));
    }
  }

  yield all([
    takeLatest(getCompetitionAndCountry.type, getCompetitionAndCountryWatcher),
    takeLatest(fetchCompetitionDetails.type, fetchCompetitionDetailsWatcher),
    takeLatest(fetchCompetitionByCountryAndSport.type, getCountryCompetitionBySportWatcher),
    takeLatest(fetchGetEvents.type, getEventWatcher),
    takeLatest(fetchGetMarket.type, getMarketWatcher),
    takeLatest(fetchGetSportCompetition.type, getSportCompetitionWatcher),
    takeLatest(fetchGetGroup.type, getGroupWatcher),
    takeLatest(fetchGetSports.type, getSportWatcher),
    takeLatest(fetchTimeFilters.type, getTimeFilterWatcher),
    takeLatest(getSportDataForTCAndRedirect.type, getSportDataForTCAndRedirectWatcher)
  ]);
}
