import { MutableRefObject, useEffect, useRef, useState } from 'react';

import useElementSize from './useElementSize';

export default function useHideLabelsOnCollision(isSoccer?: boolean): {
  sportWrapperRef: MutableRefObject<HTMLDivElement | null>;
  isIntersect: boolean;
  scoreWrapperRef: MutableRefObject<HTMLDivElement | null>;
  scoreWrapperWidth: number;
  rightSideContainerRef: MutableRefObject<HTMLDivElement | null>;
  centerWrapperRef: MutableRefObject<HTMLDivElement | null>;
  teamNameRef: MutableRefObject<HTMLDivElement | null>;
} {
  const [cachedRightSideWidth, setCachedRightSideWidth] = useState<number>(0);

  const teamNameRef = useRef<HTMLDivElement>(null);
  const centerWrapperRef = useRef<HTMLDivElement>(null);
  const sportWrapperRef = useRef<HTMLDivElement>(null);
  const rightSideContainerRef = useRef<HTMLDivElement>(null);

  const [scoreWrapperRef, { width: scoreWrapperWidth }] = useElementSize<HTMLDivElement>();

  const isIntersectRef = useRef(false);

  if (centerWrapperRef.current && rightSideContainerRef.current) {
    const widgetContainerRightEdge = centerWrapperRef.current.getBoundingClientRect().right;
    const { left: rightSideContainerLeftEdge, width: rightSideCurrentWidth } =
      rightSideContainerRef.current.getBoundingClientRect();
    if (!isSoccer) {
      if (widgetContainerRightEdge >= rightSideContainerLeftEdge && !isIntersectRef.current) {
        isIntersectRef.current = true;
      }
      if (
        widgetContainerRightEdge < rightSideContainerLeftEdge - cachedRightSideWidth + rightSideCurrentWidth &&
        isIntersectRef.current
      ) {
        isIntersectRef.current = false;
      }
    }
    if (isSoccer && teamNameRef.current && sportWrapperRef.current) {
      const teamNameRightEdge = teamNameRef.current.getBoundingClientRect().right;
      const wholeLiveScoreWidgetRightEdge = sportWrapperRef.current?.getBoundingClientRect().right;
      if (teamNameRightEdge >= rightSideContainerLeftEdge - 20 && !isIntersectRef.current) {
        isIntersectRef.current = true;
      }
      if (wholeLiveScoreWidgetRightEdge - teamNameRightEdge > cachedRightSideWidth + 30 && isIntersectRef.current) {
        isIntersectRef.current = false;
      }
    }
  }

  useEffect(() => {
    if (rightSideContainerRef.current) {
      const rightSideWidth = rightSideContainerRef.current?.getBoundingClientRect().width;
      setCachedRightSideWidth(rightSideWidth);
    }
  }, []);

  return {
    sportWrapperRef,
    isIntersect: isIntersectRef.current,
    scoreWrapperRef,
    scoreWrapperWidth,
    rightSideContainerRef,
    centerWrapperRef,
    teamNameRef
  };
}
