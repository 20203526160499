import { BannerNamesKeys } from 'constants/app';
import { AppState } from 'redux/reducers';

import { BannersContentOptions } from './type';

export const getBannerByName =
  (bannerName: BannerNamesKeys) =>
  ({ banners }: AppState) =>
    banners[bannerName];

export const getIsBannerImagesByName =
  (bannerName: BannerNamesKeys) =>
  ({ banners }: AppState) =>
    !!banners[bannerName]?.bannerImages?.length;

export const getIsDisplayedBannerByName =
  (bannerName: BannerNamesKeys) =>
  ({ banners }: AppState) => {
    const banner = banners[bannerName];

    if (!banner) {
      return false;
    }

    return banner.activeContentOption === BannersContentOptions.IMAGES
      ? !!banner.bannerImages.length
      : !!banner.bannerHtmlContent.content;
  };
