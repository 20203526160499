import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import NavSkeleton from 'components/Skeleton/NavSkeleton';
import ListItem from 'components/SportsSection/partials/ListItem';
import NavItemName from 'components/SportsSection/partials/NavItemName';
import ParentItem from 'components/SportsSection/partials/ParentItem';
import { TYPE_TO_URL } from 'constants/competitions';
import {
  getDesktopSettingsCompetitionViewSports,
  getIsCompetitionViewEnabled,
  getLanguage,
  getTimezone,
  getTranslation
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchGetEvents, resetSportData, setRace } from 'redux/modules/competitions';
import {
  getEventData,
  getEventDataLoading,
  getEventNameHighlighted,
  getEventNameTranslations,
  getEventParents
} from 'redux/modules/competitions/selectors';
import { SportId } from 'types';
import { filterCompetitionViewParents } from 'utils/navigation';

const FancyView = () => {
  const { sportId, eventId, fancyId } = useParams();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const eventParents = useSelector(getEventParents);
  const eventData = useSelector(getEventData);
  const eventNameTranslations = useSelector(getEventNameTranslations);
  const eventLoading = useSelector(getEventDataLoading);
  const eventNameHighlighted = useSelector(getEventNameHighlighted);
  const domainLanguage = useSelector(getTranslation);
  const language = useSelector(getLanguage);
  const timezone = useSelector(getTimezone);
  const isCompetitionViewEnabled = useSelector(getIsCompetitionViewEnabled);
  const competitionViewSports = useSelector(getDesktopSettingsCompetitionViewSports);

  const isTennis = sportId === SportId.TENNIS;

  useEffect(() => {
    if (eventId && sportId) {
      dispatch(fetchGetEvents({ eventId, showGroups: isTennis, updateParents: true }));
    }
  }, [eventId, fancyId, sportId, isTennis, timezone, isLoggedIn, language]);

  useEffect(() => {
    return () => {
      dispatch(setRace(null));
      dispatch(resetSportData());
    };
  }, []);

  return (
    <>
      {eventLoading && <NavSkeleton withDelay={150} itemsCount={40} />}
      <div className={classNames({ hidden: eventLoading })}>
        {filterCompetitionViewParents(eventParents, competitionViewSports, sportId, isCompetitionViewEnabled).map(
          parent => (
            <ParentItem key={parent.id} item={parent} />
          )
        )}
        {eventNameTranslations && (
          <NavItemName
            type={TYPE_TO_URL.FANCY_MARKET}
            highlighted={eventNameHighlighted}
            nameTranslations={eventNameTranslations}
          />
        )}
        {eventData.map(d => (
          <ListItem textLeft eventId={eventId} item={d} domainLanguage={domainLanguage} key={d.id} />
        ))}
      </div>
    </>
  );
};

export default FancyView;
