import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import Localization from 'components/Localization/Localization';
import MobileAccountNavigation from 'components/MobileAccountNavigation';
import Timezone from 'components/Timezone/Timezone';
import {
  ACCOUNT_BASE_URL,
  ACCOUNT_RESPONSIBLE_BETTING_URL,
  ACCOUNT_STATEMENT_BASE_URL,
  ASIAN_BASE_URL,
  BETTING_PROFIT_LOSS_BASE_URL,
  GAMES_BASE_URL,
  MOBILE_ACCOUNT_SETTINGS_BASE_URL,
  MOBILE_LOGIN_BASE_URL,
  MY_BETS_BASE_URL
} from 'constants/locations';
import { firstPageIndex } from 'constants/myBets';
import {
  ACCOUNT_PAGE_ACCOUNT_STATUS_LINK,
  ACCOUNT_PAGE_BETTING_PL_LINK,
  ACCOUNT_PAGE_SETTINGS_LINK
} from 'constants/tooltip';
import useElementSize from 'hooks/useElementSize';
import {
  getIsIframeEnabled,
  getIsMobileTimezoneDropdownEnabled,
  getIsResponsibleBettingMenuEnabled,
  getMobileLangDropdownAuth,
  getMobileSettingsConfirmBetsBeforePlace,
  getMobileSettingsDefStake,
  getMobileSettingsDepositBtn,
  getMobileSettingsFillOrKill,
  getMobileSettingsLoginHeaderButtons,
  getMobileSettingsPwdChangeBtn,
  getMobileSettingsQuickstakeBetslip,
  getPNCEnabledSetting,
  getPwdLink
} from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getAppSettings } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { logoutUser } from 'redux/modules/auth';

import AccountLinkWithTooltip from './components/AccountItemWithTooltip';
import AccountLink from './components/AccountLink';

import styles from './styles.module.scss';

const MobileAccount = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const mobileTimezoneDropdownEnabled = useSelector(getIsMobileTimezoneDropdownEnabled);
  const depositBtn = useSelector(getMobileSettingsDepositBtn);
  const isShowChangePasswordIcon = useSelector(getMobileSettingsPwdChangeBtn);
  const pwdUrl = useSelector(getPwdLink);
  const settings = useSelector(getAppSettings);
  const langDropdownAuth = useSelector(getMobileLangDropdownAuth);
  const showResponsibleBettingLink = useSelector(getIsResponsibleBettingMenuEnabled);
  const loginHeaderButtons = useSelector(getMobileSettingsLoginHeaderButtons);
  const quickstakeBetslip = useSelector(getMobileSettingsQuickstakeBetslip);
  const defStake = useSelector(getMobileSettingsDefStake);
  const confirmBetsBeforePlace = useSelector(getMobileSettingsConfirmBetsBeforePlace);
  const fillOrKill = useSelector(getMobileSettingsFillOrKill);

  const isShowDepositLink = depositBtn && settings.length;

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height }));
    }
  });

  const isSettingsEnabled = quickstakeBetslip || fillOrKill || confirmBetsBeforePlace || defStake;

  const isAsianViewPage = pathname.includes(ASIAN_BASE_URL);
  const isGamesPage = pathname.includes(GAMES_BASE_URL);

  const exchangePage = (isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || '';

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <div ref={componentRef}>
      <MobileAccountNavigation hasDropdownMenu={false} hasBalance={false} />
      <ul className={classNames('biab_account-mobile-home-navigation-buttons-list', styles.accountButtonsList)}>
        <AccountLink
          url={`${exchangePage}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/${
            isPNCEnabled ? 'matched' : 'unmatched'
          }/${firstPageIndex}`}
          title={'account.mybets.title'}
          isIframeEnabled={isIframeEnabled}
        />
        <AccountLinkWithTooltip
          isIframeEnabled={isIframeEnabled}
          url={`${exchangePage}${ACCOUNT_BASE_URL}${BETTING_PROFIT_LOSS_BASE_URL}/${firstPageIndex}`}
          title={'accountNavigation.profitandloss'}
          tooltipKey={ACCOUNT_PAGE_BETTING_PL_LINK}
        />
        <AccountLinkWithTooltip
          isIframeEnabled={isIframeEnabled}
          url={`${exchangePage}${ACCOUNT_BASE_URL}${ACCOUNT_STATEMENT_BASE_URL}/${firstPageIndex}`}
          title={'account.statement.title'}
          tooltipKey={ACCOUNT_PAGE_ACCOUNT_STATUS_LINK}
        />
        {isSettingsEnabled && (
          <AccountLinkWithTooltip
            isIframeEnabled={isIframeEnabled}
            url={`${exchangePage}${MOBILE_ACCOUNT_SETTINGS_BASE_URL}`}
            title={'account.settings.title'}
            tooltipKey={ACCOUNT_PAGE_SETTINGS_LINK}
          />
        )}
        {isShowDepositLink && (
          <AccountLink
            url={settings[0]?.url}
            title={t('accountNavigation.deposit')}
            openInNewTab
            externalLink
            isIframeEnabled={isIframeEnabled}
          />
        )}
        {mobileTimezoneDropdownEnabled && (
          <li className={classNames('biab_account-mobile-home-navigation-button', styles.accountButtonsList__item)}>
            <div className={classNames('biab_timezone-container', styles.accountButtonsList__block)}>
              <label>{t('timezone.label')}</label>
              <Timezone />
            </div>
          </li>
        )}
        {langDropdownAuth && (
          <li className={classNames('biab_account-mobile-home-navigation-button', styles.accountButtonsList__item)}>
            <div className={classNames('biab_locale-container', styles.accountButtonsList__block)}>
              <label>{t('locale.label')}</label>
              <Localization title />
            </div>
          </li>
        )}
        {isShowChangePasswordIcon && (
          <AccountLink
            isIframeEnabled={isIframeEnabled}
            url={pwdUrl}
            title={'navigation.settings.label'}
            externalLink
          />
        )}
        {showResponsibleBettingLink && (
          <AccountLink
            isIframeEnabled={isIframeEnabled}
            url={`${exchangePage}${ACCOUNT_RESPONSIBLE_BETTING_URL}`}
            title={'responsibleBetting.labels.responsibleBetting'}
          />
        )}
        {loginHeaderButtons && !isIframeEnabled && (
          <li className={classNames('biab_account-mobile-home-navigation-button', styles.accountButtonsList__item)}>
            <button onClick={() => dispatch(logoutUser(`${exchangePage}${MOBILE_LOGIN_BASE_URL}`))}>
              {t('header.logout')}
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default MobileAccount;
