import { useSelector } from 'react-redux';

import {
  STARTING_DAY,
  STARTING_IN_MIN,
  STARTING_MONTH,
  STARTING_ONE_MIN,
  STARTING_SOON,
  STARTING_TODAY,
  STARTING_TOMORROW
} from 'constants/date';
import { getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { applyTimezone, getBettingDayTimezone } from 'utils/date';

const useConvertTimeToString = ({
  startDate,
  isAsianView,
  isBettingDay
}: {
  startDate: number;
  isAsianView?: boolean;
  isBettingDay?: boolean;
}) => {
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);

  const currentDate = new Date().getTime();
  const timezoneOffset = isBettingDay ? getBettingDayTimezone() : timezone;
  const startTimeDate = applyTimezone(new Date(startDate), timezoneOffset, isBettingDay || timezoneCookieEnabled);
  const startDateHours = `0${startTimeDate.getHours()}`.slice(-2);
  const startDateMinutes = `0${startTimeDate.getMinutes()}`.slice(-2);
  const startDateWeekDay = startTimeDate.getDay();
  const startDateMonth = startTimeDate.getMonth();
  const startDateMonthDay = startTimeDate.getDate();
  const year = startTimeDate.getFullYear();
  const timeInfo = {
    key: '',
    time: 0,
    showHoursAndMinutes: false,
    monthDay: startDateMonthDay,
    weekDay: startDateWeekDay,
    hoursAndMinutes: `${startDateHours}:${startDateMinutes}`,
    month: startDateMonth,
    year,
    showDescription: true,
    showMonthDay: false,
    showWeekDay: false,
    isNotToday: false
  };
  const diff = startDate - currentDate;

  if (startDate < currentDate) {
    timeInfo.key = STARTING_SOON;
  } else if (diff < 120000) {
    if (isAsianView) {
      timeInfo.key = STARTING_TODAY;
      timeInfo.showDescription = false;
      timeInfo.showHoursAndMinutes = true;
    } else {
      // starting in 1 minute
      timeInfo.key = STARTING_ONE_MIN;
    }
  } else if (diff < 900000) {
    if (isAsianView) {
      timeInfo.key = STARTING_TODAY;
      timeInfo.showDescription = false;
      timeInfo.showHoursAndMinutes = true;
    } else {
      // starting within 14 minutes
      timeInfo.key = STARTING_IN_MIN;
      timeInfo.time = Math.floor(diff / 60000);
    }
  } else {
    const today = new Date().setHours(0, 0, 0, 0);
    const eventStartDay = startTimeDate.setHours(0, 0, 0, 0);
    const timeInterval = eventStartDay - today;

    if (timeInterval === 0) {
      // starting today
      timeInfo.key = STARTING_TODAY;
      timeInfo.showDescription = false;
      timeInfo.showHoursAndMinutes = true;
    } else if (timeInterval <= 86400000) {
      // starting tomorrow
      timeInfo.key = STARTING_TOMORROW;
      timeInfo.showHoursAndMinutes = true;
      timeInfo.isNotToday = true;
    } else if (timeInterval > 86400000 && timeInterval <= 604800000) {
      // starting within next 7 days
      timeInfo.key = `${STARTING_DAY}${startDateWeekDay}`;
      timeInfo.showHoursAndMinutes = true;
      timeInfo.showWeekDay = true;
      timeInfo.isNotToday = true;
    } else if (timeInterval > 604800000) {
      // starting after 7 days
      timeInfo.key = `${STARTING_MONTH}${startDateMonth}`;
      timeInfo.showHoursAndMinutes = true;
      timeInfo.showMonthDay = true;
      timeInfo.isNotToday = true;
    }
  }

  return timeInfo;
};

export default useConvertTimeToString;
