import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import TooltipContext from 'contexts/tooltipContext';
import useTooltip from 'hooks/useTooltip';

import styles from '../../styles.module.scss';

interface AccountItemProps {
  title: string;
}

const AccountItem = ({ title }: AccountItemProps) => {
  const { t } = useTranslation();
  const { tooltipKey = '' } = useContext(TooltipContext);
  const { isEnabled, translationKey, isShowIcon } = useTooltip(tooltipKey);

  return (
    <div>
      <li className={classNames('biab_account-mobile-home-navigation-button', styles.accountButtonsList__item)}>
        <div className={styles.accountButtonsList__block}>
          <div className={styles.accountButtonsList__labelBlock}>
            <label>{t(title)}</label>
            {isEnabled && translationKey && isShowIcon && (
              <i
                onClick={e => e.preventDefault()}
                data-tooltip-id={'tooltip'}
                data-tooltip-html={unescape(t(translationKey))}
                className="fa2 fa2-info-circle tooltip-icon cursor-help"
              >
                <span className="path1 biab_promoted-tooltip-bg biab_mobile-tooltip-bg biab_tooltip-bg" />
                <span className="path2" />
              </i>
            )}
          </div>
          <i className={classNames('biab_arrow-icon fa2 fa2-arrow-right', styles.accountButtonsList__linkIcon)} />
        </div>
      </li>
    </div>
  );
};

export default AccountItem;
