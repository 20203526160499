import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useDevice from 'hooks/useDevice';

import styles from './styles.module.scss';

const PopularLinksHeader = () => {
  const { t } = useTranslation();

  const { isMobile } = useDevice();

  return (
    <div
      className={classNames(styles.popularLinksHeader, {
        'biab_popular-links-section-title': !isMobile,
        'biab_popular-links-title': isMobile
      })}
    >
      <span className={styles.popularLinksTitle}>{t('navigation.popularLinks')}</span>
    </div>
  );
};

export default PopularLinksHeader;
