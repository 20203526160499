import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import { MyBetsLocations, NavigationButtons, PLBetType, SEARCH_CURRENCY_KEY, TExchangeTypes } from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import { getAppDevice, getCurrenciesDropdownItems } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { fetchProfitGames, fetchProfitSports, setMultiCurrencyValue } from 'redux/modules/myBets';
import { getMyBetsMultiCurrencyValue } from 'redux/modules/myBets/selectors';
import { DropdownItem } from 'types';
import { TFetchDataWithCurrencyHandlers } from 'types/myBets';

import styles from './styles.module.scss';

interface MultiCurrencyDropdownProps {
  fetchDataWithCurrencyHandlers?: TFetchDataWithCurrencyHandlers;
  activePLTab?: PLBetType | TExchangeTypes;
}

const MultiCurrencyDropdown = ({ fetchDataWithCurrencyHandlers, activePLTab }: MultiCurrencyDropdownProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { page = '' } = useParams<{ page?: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const device = useSelector(getAppDevice);
  const currenciesDropdownItems = useSelector(getCurrenciesDropdownItems);

  const { selectedTab } = useAccountUrlParams();
  const multiCurrencyValue = useSelector(getMyBetsMultiCurrencyValue);

  const searchCurrency = searchParams.get(SEARCH_CURRENCY_KEY);
  const isDesktop = device === Devices.DESKTOP;
  const isMyBetsPage = location.pathname.includes(`${MyBetsLocations.MyBets}/`);
  const currenciesItems = isMyBetsPage
    ? (
        [{ id: 'all', value: 'all', label: 'account.currencies.all', isLabelTranslatable: true }] as DropdownItem[]
      ).concat(currenciesDropdownItems)
    : currenciesDropdownItems;
  const defaultValue = currenciesItems.find(({ value }) =>
    searchCurrency ? value === searchCurrency : value === multiCurrencyValue
  );

  const handleSelectCurrency = ({ id, value }: DropdownItem) => {
    if (selectedTab === NavigationButtons.MyBets) {
      if (fetchDataWithCurrencyHandlers?.fetchBetDataHandler) {
        fetchDataWithCurrencyHandlers.fetchBetDataHandler({ currency: id === 'all' ? undefined : value, number: 0 });
      }
    } else if (selectedTab === NavigationButtons.ProfitAndLoss) {
      if (fetchDataWithCurrencyHandlers?.fetchPLDataHandler) {
        fetchDataWithCurrencyHandlers.fetchPLDataHandler({ currency: value, number: 0 });
      }

      if (activePLTab) {
        if (activePLTab === PLBetType.Sports) {
          dispatch(fetchProfitSports(value));
        } else if (activePLTab === PLBetType.Games) {
          dispatch(fetchProfitGames(value));
        }
      }
    } else if (selectedTab === NavigationButtons.Statement) {
      if (fetchDataWithCurrencyHandlers?.fetchStatementDataHandler) {
        fetchDataWithCurrencyHandlers.fetchStatementDataHandler({ currency: value, number: 0 });
      }
    }

    searchParams.set(SEARCH_CURRENCY_KEY, value);
    setSearchParams(searchParams);
    dispatch(setMultiCurrencyValue(id === 'all' ? '' : value));

    if (page !== '1') {
      const urlWithoutPageIndex = location.pathname.substring(0, location.pathname.lastIndexOf('/'));

      navigate(`${urlWithoutPageIndex}/1`);
    }
  };

  return (
    <div className={classNames('biab_account-page ui-selectmenu-menu', styles.multiCurrencyDropdown)}>
      {isDesktop && <span>{t('account.currencies.currency')}</span>}
      <Dropdown
        defaultValue={defaultValue}
        data={currenciesItems}
        onSelectOption={handleSelectCurrency}
        classes={{
          placeholder: classNames('biab_currencies-dropdown-placeholder', styles.multiCurrencyDropdown__placeholder)
        }}
      />
    </div>
  );
};

export default MultiCurrencyDropdown;
