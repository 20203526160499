import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { unescape } from 'lodash';

import Loader, { CircleColors } from 'components/Loader';
import Modal from 'components/Modal';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getHomeMarketsRulesLoading } from 'redux/modules/home/selectors';
import { getHomeMarketsRules } from 'redux/modules/home/selectors';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

interface RulesModalProps {
  /**
   * Event Type ID
   */
  eventTypeId: string;
  /**
   * Market ID
   */
  marketId: string;
  /**
   * Market start time
   */
  marketStartTime?: number;
  /**
   * Market start time
   */
  marketName: string;
  /**
   * Is open
   */
  isOpen: boolean;
  /**
   * On close function
   */
  onClose: (event?: SyntheticEvent) => void;
}

const RulesModal = ({ eventTypeId, marketId, marketName, marketStartTime, isOpen, onClose }: RulesModalProps) => {
  const { t } = useTranslation();

  const marketsRules = useSelector(getHomeMarketsRules(eventTypeId));
  const rulesLoading = useSelector(getHomeMarketsRulesLoading);

  const marketTime = useLongDateFormat({
    time: marketStartTime ?? 0,
    hasYear: true,
    divider: ', '
  });

  return (
    <Modal
      clickableBackground
      open={isOpen}
      onClose={onClose}
      title={rulesLoading ? t('marketRules.loading') : `${marketName || ''} - ${t('rules.title')}`}
    >
      {rulesLoading ? (
        <Loader circleColor={CircleColors.BLACK} />
      ) : (
        <div className={styles.marketModal}>
          <div>
            {t('marketRules.eventStartTime')}:
            <br />
            {marketTime}
          </div>
          <div
            className={styles.marketModal__text}
            dangerouslySetInnerHTML={{
              __html: unescape(
                marketsRules?.[marketId]?.rules.replace(/CONTEXT_ROOT_PATH/g, getEnvironmentRootPath()) ?? ''
              )
            }}
          />
        </div>
      )}
    </Modal>
  );
};

export default RulesModal;
