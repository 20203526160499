import { POKER_STAR } from 'constants/icons';

export const getArrowDownByOperator = (operator: string) => {
  switch (operator) {
    case POKER_STAR: {
      return 'fa biab_general-icon ps-icon-down-chevron';
    }
    default: {
      return 'fa2 fa2-arrow-down';
    }
  }
};

export const getArrowLeftByOperator = (operator: string) => {
  switch (operator) {
    case POKER_STAR: {
      return 'biab_general-icon ps-icon-up-chevron';
    }
    default: {
      return 'fa2 fa2-arrow-left';
    }
  }
};

export const getArrowUpByOperator = (operator: string) => {
  switch (operator) {
    case POKER_STAR: {
      return 'biab_general-icon ps-icon-up-chevron';
    }
    default: {
      return 'fa2 fa2-arrow-up';
    }
  }
};
