import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getFavorites } from 'redux/modules/favorites/selectors';

import CollapsibleList from '../CollapsibleList';

import styles from './styles.module.scss';

const Content = () => {
  const favorites = useSelector(getFavorites);

  return (
    <div className={classNames('biab_fav-content', styles.favorite__content)}>
      <CollapsibleList favorites={favorites} customClassName={styles.favorite__collapseRow} />
    </div>
  );
};

export default Content;
