import { useTranslation } from 'react-i18next';

import styles from './AVBetListMobileEmptyMessage.module.scss';

type AVBetListMobileEmptyMessageProps = {
  isSearchValue: boolean;
};

const AVBetListMobileEmptyMessage = ({ isSearchValue }: AVBetListMobileEmptyMessageProps) => {
  const { t } = useTranslation();

  return (
    <section className={styles.emptyMessage__container}>
      {isSearchValue ? (
        <>
          <p className={styles.emptyMessage}>{t('asianView.labels.betList.noResults')}</p>
          <p className={styles.emptyMessage__tryAgain}>{t('asianView.labels.betList.trySearchAgain')}</p>
        </>
      ) : (
        <p className={styles.emptyMessage}>{t('asianView.labels.betList.noOpenBets')}</p>
      )}
    </section>
  );
};

export default AVBetListMobileEmptyMessage;
