import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';
import { IMarket } from 'types/markets';

import { FeaturedMarket, TFeaturedMarkets } from './type';

const initialState: TFeaturedMarkets = {
  error: null,
  loading: false,
  markets: [],
  stringifiedMarkets: ''
};

const slice = createSlice({
  name: SLICES_NAMES.FEATURED_MARKETS,
  initialState,
  reducers: {
    fetchFeatureMarkets: (state, _: PayloadAction<boolean | undefined>) => {
      state.loading = true;
    },
    successFeatureMarkets: (state, { payload }: PayloadAction<FeaturedMarket[]>) => {
      state.loading = false;

      const stringifiedMarketsFromResponse = JSON.stringify(payload);

      if (state.stringifiedMarkets !== stringifiedMarketsFromResponse) {
        const markets: IMarket[] = [];
        payload.forEach(item => {
          item.markets.forEach(market => {
            markets.push(market);
          });
        });

        state.stringifiedMarkets = stringifiedMarketsFromResponse;
        state.markets = markets;
      }
    },
    failureFeatureMarkets: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
    }
  }
});

export const { fetchFeatureMarkets, successFeatureMarkets, failureFeatureMarkets } = slice.actions;

export default slice.reducer;
