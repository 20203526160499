import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { SPORT_BASE_URL } from 'constants/locations';
import { IMarket } from 'types/markets';

import styles from './styles.module.scss';

interface IShowFullMarket {
  market: IMarket;
}

const ShowFullMarket = ({ market }: IShowFullMarket) => {
  const { t } = useTranslation();

  return (
    <div className={styles.main}>
      <Link
        to={`${SPORT_BASE_URL}/${market.eventType.id}/market/${market.marketId}`}
        className={classNames('biab_market-link', styles.link)}
      >
        {t('cashout.page.labels.viewFullMarket')}
        <i className="fa2 fa2-arrow-right" />
      </Link>
    </div>
  );
};

export default ShowFullMarket;
