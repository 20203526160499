import { TFailureActionPayload } from 'types';

export type TBetStatus = {
  [offerId: number]: BetsStatusesTypes;
};

export type TBetStatusByOfferIdItem = {
  status?: BetsStatusesTypes;
  loading?: boolean;
  error?: TBetStatusError | null;
};

export type TBetStatusByOfferId = {
  [offerIds: string]: TBetStatusByOfferIdItem;
};

export type TBetStatusError = {
  id?: string;
  code?: string;
  message?: string;
};

export type TBetsStatuses = {
  statuses: TBetStatusByOfferId;
};

export type TBetStatusPayload = {
  offerIds: number[];
  onSuccess?: (offersStatuses: TBetStatus) => void;
};

export type TBetStatusPayloadError = {
  offerIds: number[];
  error: TFailureActionPayload;
};

export enum BetsStatusesTypes {
  PLACED = 'PLACED',
  MATCHED = 'MATCHED',
  PENDING = 'PENDING',
  LAPSED = 'LAPSED',
  VOIDED = 'VOIDED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  UNMATCHED = 'UNMATCHED',
  PARTIALLY_MATCHED = 'PARTIALLY_MATCHED'
}
