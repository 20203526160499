import { TCurrentPL } from 'redux/modules/plTable/type';

export const getValueForTable = (data: TCurrentPL[]) => {
  const result: { [key: string]: string } = {};
  let currentProfit: string | null = null;
  let startPoint: number | null = null;
  let endPoint: number | null = null;

  for (const entry of data) {
    const { profitOrLoss, virtualPoints } = entry;

    if (profitOrLoss !== currentProfit) {
      // If profit value changes, add the range to the result
      if (currentProfit !== null) {
        if (startPoint === endPoint) {
          result[currentProfit] = startPoint!.toString();
        } else {
          if (startPoint === endPoint! - 1) {
            result[currentProfit] = `${startPoint} - ${endPoint}`;
          } else {
            result[currentProfit] = `${startPoint} - ${endPoint}`;
          }
        }
      }

      // Update current profit and start point
      currentProfit = profitOrLoss;
      startPoint = virtualPoints;
    }

    // Update end point as long as the profit remains the same
    endPoint = virtualPoints;
  }

  // Add the last range to the result
  if (currentProfit !== null) {
    result[currentProfit] = `${startPoint}+`;
  }

  return result;
};
