import { TCurrentBet } from 'redux/modules/currentBets/type';

export const getPaginatedGroupedEventsAndCompetitions = ({
  groupedBets,
  page,
  itemsCountOnPage
}: {
  groupedBets: [string, TCurrentBet[]][];
  page: number;
  itemsCountOnPage: number;
}) => {
  let totalPages = 1;
  let itemsCounter = 0;

  const groupedBetsByPage = groupedBets.reduce<[string, TCurrentBet[]][][]>((acc, group) => {
    const prevItemsCounter = itemsCounter;
    itemsCounter += group[1].length;

    if (itemsCounter < itemsCountOnPage || prevItemsCounter < itemsCountOnPage) {
      if (!acc[totalPages - 1]) {
        acc[totalPages - 1] = [];
      }

      acc[totalPages - 1].push(group);
    } else {
      itemsCounter = group[1].length;

      if (!acc[totalPages]) {
        acc[totalPages] = [];
      }

      acc[totalPages].push(group);

      totalPages += 1;
    }

    return acc;
  }, []);

  return {
    totalPages: page < 0 ? 0 : totalPages,
    groupedCurrentBets: page + 1 > totalPages || page < 0 ? [] : groupedBetsByPage[page]
  };
};
