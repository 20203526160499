import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { IRaceDetails } from 'types/markets';

import styles from './styles.module.scss';

interface HeaderDetailsProps {
  details: IRaceDetails;
  isCloseMarket?: boolean;
}

const HeaderDetails = ({ details, isCloseMarket }: HeaderDetailsProps) => {
  const device = useSelector(getAppDevice);

  const { going, raceType, distance } = details;
  const isMobile = device === Devices.MOBILE;

  return (
    <div
      className={classNames('biab_timeform-details', styles.timeForm__details, {
        'biab_timeform-event-info-xs': isMobile
      })}
    >
      {isMobile && !isCloseMarket && <i className="fa fa-circle biab_timeform-details-dot" aria-hidden="true" />}
      {raceType}
      {distance && (
        <>
          <i className="fa fa-circle biab_timeform-details-dot" aria-hidden="true" />
          {distance}
        </>
      )}
      {going?.name && (
        <>
          <i className="fa fa-circle biab_timeform-details-dot" aria-hidden="true" />
          {going?.name}
        </>
      )}
    </div>
  );
};

export default HeaderDetails;
