import classNames from 'classnames';

import { asianViewMiddleSection } from 'constants/branding';

import styles from '../styles.module.scss';

type ListItemProps = {
  selected: boolean;
  label: string;
  value: string;
  icon?: string;
  onSelect: (value: string) => void;
};

const ListItem = ({ selected, label, value, icon, onSelect }: ListItemProps) => {
  return (
    <button
      className={classNames(styles.listItem, asianViewMiddleSection.MOBILE_LIST_ITEM, {
        biab_active: selected,
        [styles.selectedLIstItem]: selected
      })}
      onClick={() => onSelect(value)}
    >
      {icon && <i className={classNames(styles.leftIcon, icon)} />}
      <span>{label}</span>
      {selected && <i className={classNames('av-icon av-icon-checkmark', styles.selectedIcon)} />}
    </button>
  );
};

export default ListItem;
