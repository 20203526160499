import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ExchangeTypeTabButtons, TExchangeTypes } from 'constants/myBets';
import Tab from 'pages/MyExchangeBetsPage/components/Tab';

import styles from './styles.module.scss';

interface ExchangeTypeTabsProps {
  setActiveTab: (tabValue: TExchangeTypes) => void;
  activeTab?: string;
  isAsianViewActive: boolean;
  isSportsGamesActive: boolean;
  isMobile: boolean;
  customClassName?: string;
}
const ExchangeTypeTabs = ({
  setActiveTab,
  activeTab,
  isAsianViewActive,
  isSportsGamesActive,
  isMobile,
  customClassName = ''
}: ExchangeTypeTabsProps) => {
  const { t } = useTranslation();

  return isAsianViewActive && isSportsGamesActive ? (
    <div
      className={classNames(
        styles.tabs,
        styles.tabs__exchangeType,
        customClassName,
        'biab_exchange-types biab_table-tabs',
        {
          [styles.mobileTabs]: isMobile,
          [styles.mobileTabs__asianView]: isMobile && isAsianViewActive
        }
      )}
    >
      {ExchangeTypeTabButtons.map((el, index) => (
        <ul key={el} className={classNames(styles.tabButtons, 'biab_tab-btns')}>
          <Tab
            tabName={t(`asianView.labels.account.exchangeType.${el}`)}
            tab={el}
            setActiveTabButton={() => setActiveTab(el)}
            activeTabButton={activeTab}
            classname={{
              [styles.firstTab]: index === 0,
              [styles.lastTab]: index === ExchangeTypeTabButtons.length - 1
            }}
          />
        </ul>
      ))}
    </div>
  ) : null;
};

export default ExchangeTypeTabs;
