import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import PopularMarket from 'components/PopularMarket';
import SEO from 'components/SEO';
import SportPageModule from 'components/SportPageModule';
import SportsSection from 'components/SportsSection';
import { TIME_FILTERS } from 'constants/app';
import { HOME_BASE_URL } from 'constants/locations';
import { getAppDevice, getIsShowMarketOdd } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { MetaPage } from 'redux/modules/meta/type';
import { fetchPopularMarkets } from 'redux/modules/popularMarket';
import { SportPageContextFilter, SportPageType } from 'redux/modules/sportPage/type';
import { PlacementPage } from 'types';

const CompetitionSportPage = () => {
  const { competitionId = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isSingleCompetitionPage = !TIME_FILTERS.includes(competitionId);

  const device = useSelector(getAppDevice);
  const isShowMarketOdds = useSelector(getIsShowMarketOdd);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  useEffect(() => {
    if (device === Devices.DESKTOP && !isSingleCompetitionPage) {
      navigate(HOME_BASE_URL);
    }
  }, [device, isSingleCompetitionPage]);

  useEffect(() => {
    if (isShowMarketOdds) {
      dispatch(fetchPopularMarkets());
    }
  }, [isLoggedIn, dispatch, isShowMarketOdds]);

  return (
    <>
      <SEO page={MetaPage.COMPETITION} id={competitionId} />
      {device === Devices.MOBILE && <SportsSection />}
      {device === Devices.DESKTOP && (
        <>
          <PopularMarket page={PlacementPage.COMPETITION_ODDS_BANNER} />
          <SportPageModule
            pageDetailsId={competitionId ?? ''}
            contextFilter={SportPageContextFilter.COMPETITION}
            pageType={SportPageType.COMPETITION}
            placementPage={PlacementPage.COMPETITION}
          />
        </>
      )}
    </>
  );
};

export default CompetitionSportPage;
