import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSettings } from '../redux/modules/user';
import { getIsUserAccountSettings, getUserAccountSettingsBetSlipPlacement } from '../redux/modules/user/selectors';
import { SettingsBettingInlinePlacementType } from '../types/navigation';

const usePlacementSetting = () => {
  const dispatch = useDispatch();

  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const settingsBetSlipPlacement = useSelector(getUserAccountSettingsBetSlipPlacement);

  const [inlinePlacementType, setInlinePlacementType] = useState<SettingsBettingInlinePlacementType>(
    SettingsBettingInlinePlacementType.DEFAULT
  );

  useEffect(() => {
    if (isAccountSettings) {
      if (inlinePlacementType === SettingsBettingInlinePlacementType.DEFAULT) {
        if (settingsBetSlipPlacement) {
          setInlinePlacementType(SettingsBettingInlinePlacementType.BETSLIP);
        } else {
          setInlinePlacementType(SettingsBettingInlinePlacementType.MIDDLE_SECTION);
        }
      }
    }
  }, [isAccountSettings]);

  const handleChangePlacementType = (newType: SettingsBettingInlinePlacementType) => {
    setInlinePlacementType(newType);

    if (isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            betslipPlacement: newType === SettingsBettingInlinePlacementType.BETSLIP
          }
        })
      );
    }
  };

  return { handleChangePlacementType, inlinePlacementType };
};

export default usePlacementSetting;
