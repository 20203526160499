import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  getIsAmericanDateFormatEnabled,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { timeStampToStartDate } from 'utils/date';

import styles from './styles.module.scss';

interface GamesResultsDateProps {
  startDate: number;
}

const GamesResultsDate = ({ startDate }: GamesResultsDateProps) => {
  const { t } = useTranslation();

  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);

  const staredParsedDate = timeStampToStartDate(startDate, americanDateFormatEnabled, timezoneCookieEnabled, timezone);

  return (
    <div className={classNames('biab_games-start-date', styles.startDate)}>
      {t('games.results.started')}: <strong>{staredParsedDate}</strong>
    </div>
  );
};

export default GamesResultsDate;
