import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { asianViewMiddleSection as branding } from 'constants/branding';
import { MINUTE_SYMBOL } from 'constants/inPlay';
import useConvertTimeToString from 'hooks/useConvertTimeToString';
import { useIsVisible } from 'hooks/useIsVisible';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { getAsianViewEventByEventId } from 'redux/modules/asianView/selectors';
import {
  getIsMarketInPlayByMarketPricesId,
  getMarketPricesAddedRegularTime,
  getMarketPricesAsianViewElapsedRegularTime,
  getMarketPricesAwayScore,
  getMarketPricesHomeScore,
  getMarketPricesMatchStatus,
  getMarketPricesRegularTime
} from 'redux/modules/marketsPrices/selectors';
import { MatchStatus } from 'types';
import { addZero } from 'utils/session';

import styles from './styles.module.scss';

interface StickyEventProps {
  marketId: string;
  eventId: string;
}

const StickyEvent = ({ marketId, eventId }: StickyEventProps) => {
  const { t } = useTranslation();

  const translation = useSelector(getTranslation);
  const event = useSelector(getAsianViewEventByEventId(eventId));

  const homeScore = useSelector(getMarketPricesHomeScore(marketId));
  const awayScore = useSelector(getMarketPricesAwayScore(marketId));
  const elapsedRegularTime = useSelector(getMarketPricesAsianViewElapsedRegularTime(marketId));
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketId));
  const matchStatus = useSelector(getMarketPricesMatchStatus(marketId));
  const regularTime = useSelector(getMarketPricesRegularTime(marketId));
  const addedTime = useSelector(getMarketPricesAddedRegularTime(marketId));

  const { ref: componentRef, isIntersecting } = useIsVisible<HTMLDivElement>();

  const startTimeInfo = useConvertTimeToString({ startDate: event.startTime, isAsianView: true });

  const isLiveScoreData =
    isInPlay &&
    homeScore !== undefined &&
    awayScore !== undefined &&
    (elapsedRegularTime !== undefined || regularTime !== undefined);
  const eventName = event.translations[translation];
  const isHalfTime = matchStatus === MatchStatus.HALF_TIME;
  const isFinished = matchStatus === MatchStatus.END;
  const isFirstHalf = matchStatus === MatchStatus.FIRST_HALF;
  const isSecondHalf = matchStatus === MatchStatus.SECOND_HALF;

  const getContent = () => {
    if (isLiveScoreData) {
      return (
        <>
          <p className={classNames(styles.score, branding.EVENT_STICKY_HEADER_SCORE)}>
            {`${homeScore} - ${awayScore}`}
          </p>
          {isHalfTime && (
            <span className={classNames(styles.time, branding.EVENT_STICKY_HEADER_DATE_TIME)}>
              {t('asianView.labels.inPlayScore.HT')}
            </span>
          )}
          {isFinished && (
            <span className={classNames(styles.time, branding.EVENT_STICKY_HEADER_DATE_TIME)}>
              {t('asianView.labels.inPlayScore.END')}
            </span>
          )}
          {(isFirstHalf || isSecondHalf) && (
            <span className={classNames(styles.time, branding.EVENT_STICKY_HEADER_DATE_TIME)}>
              {t(`asianView.labels.inPlayScore.${isFirstHalf ? '1H' : '2H'}`)} {elapsedRegularTime ?? 0}
              {MINUTE_SYMBOL}
              {addedTime ? `+${addedTime}${MINUTE_SYMBOL}` : ''}
            </span>
          )}
        </>
      );
    }

    if (!isInPlay) {
      return (
        <div className={classNames(styles.time, branding.EVENT_STICKY_HEADER_DATE_TIME)}>
          {startTimeInfo.showDescription && (
            <span>
              {startTimeInfo.isNotToday
                ? `${startTimeInfo.monthDay}/${addZero(startTimeInfo.month + 1)}${startTimeInfo.month + 1}`
                : t(startTimeInfo.key, '', { min: startTimeInfo.time })}
            </span>
          )}
          {startTimeInfo.showHoursAndMinutes && !startTimeInfo.showDescription && (
            <span>{startTimeInfo.hoursAndMinutes}</span>
          )}
        </div>
      );
    }
  };

  return (
    <>
      <div ref={componentRef} />
      <div
        className={classNames(styles.container, branding.EVENT_STICKY_HEADER, {
          [styles.container__hidden]: isIntersecting
        })}
      >
        {isInPlay && (
          <p className={classNames(styles.live, branding.EVENT_STICKY_HEADER_LIVE)}>
            {t('asianView.labels.timeFilter.live')}
          </p>
        )}
        {getContent()}
        {eventName}
        {!isLiveScoreData && isInPlay && <i className={classNames('av-icon av-icon-timer', styles.timerIcon)} />}
      </div>
    </>
  );
};

export default StickyEvent;
