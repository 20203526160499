import classNames from 'classnames';

import Skeleton from 'components/Skeleton';
import useAsianLayBetting from 'hooks/useAsianLayBetting';

import styles from './styles.module.scss';

interface SelectionOddsSkeletonProps {
  isMobileCell?: boolean;
  isSmallCell?: boolean;
}

const SelectionOddsSkeleton = ({ isMobileCell = false, isSmallCell = false }: SelectionOddsSkeletonProps) => {
  const isLayBetting = useAsianLayBetting();

  return (
    <div className={classNames('biab_asian-view-market-selection-skeleton', styles.skeleton)}>
      <Skeleton
        className={classNames(styles.skeleton__odds, {
          [styles.skeleton__odds__small]: isSmallCell,
          [styles.skeleton__odds__mobile]: isMobileCell
        })}
      />
      {isLayBetting && (
        <Skeleton
          className={classNames(styles.skeleton__odds, {
            [styles.skeleton__odds__small]: isSmallCell,
            [styles.skeleton__odds__mobile]: isMobileCell
          })}
        />
      )}
    </div>
  );
};

export default SelectionOddsSkeleton;
