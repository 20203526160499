import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { asianViewIcons } from 'constants/branding';
import { getOddsType } from 'redux/modules/appSettings/selectors';
import { TPrice } from 'types/bets';
import convertOdds from 'utils/oddsConverter';

import styles from './AVChangedOddsNotification.module.scss';

type AVChangedOddsNotificationProps = {
  initPrice: TPrice;
  isGoingUp: boolean;
  odds?: number;
};

const AVChangedOddsNotification = ({ initPrice, odds, isGoingUp }: AVChangedOddsNotificationProps) => {
  const { t } = useTranslation();

  const oddsType = useSelector(getOddsType);

  const initConvertedOdds = convertOdds(initPrice || '', oddsType);
  const convertedOdds = convertOdds(odds || '', oddsType);
  const displayedInitConvertedOdds =
    initConvertedOdds < 0 ? `${Math.abs(Number(initConvertedOdds))}` : initConvertedOdds;
  const displayedConvertedOdds = convertedOdds < 0 ? `${Math.abs(Number(convertedOdds))}` : convertedOdds;

  return (
    <p className={styles.notification}>
      <span>{t('asianView.labels.selectedBet.oddsChanged')}: </span>
      <span className={styles.notification__oddsValues}>
        <span className={styles.notification__price}>
          {initConvertedOdds < 0 && <span>&minus;</span>}
          {displayedInitConvertedOdds} &minus;&gt;{' '}
        </span>
        <span
          className={classNames(styles.notification__price, {
            [asianViewIcons.PRICE_MORE_ICON]: isGoingUp,
            [asianViewIcons.PRICE_LESS_ICON]: !isGoingUp
          })}
        >
          {convertedOdds < 0 && <span>&minus;</span>}
          {displayedConvertedOdds}
        </span>
        <i
          className={classNames(styles.notification__icon, `fa2 fa2-sort-${isGoingUp ? 'up' : 'down'} biab_indicator`, {
            [asianViewIcons.PRICE_MORE_ICON]: isGoingUp,
            [asianViewIcons.PRICE_LESS_ICON]: !isGoingUp
          })}
        />
      </span>
    </p>
  );
};

export default AVChangedOddsNotification;
