import { RefObject, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Collapse from 'components/Collapse';
import useElementSize from 'hooks/useElementSize';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { FAVORITES_COLLAPSE, LEFT_PANEL_COLLAPSE_GROUP } from 'redux/modules/collapse/constants';
import { cleanFavorites, fetchFavorites } from 'redux/modules/favorites';
import { getFavorites } from 'redux/modules/favorites/selectors';

import Content from './Content';
import FavouritesHeader from './FavouritesHeader';
import ManageRow from './ManageRow';
import NoFavorites from './NoFavorites';

import styles from './styles.module.scss';

interface FavoritesSectionProps {
  favoritesWrapperRef: RefObject<HTMLDivElement>;
}

const FavoritesSection = ({ favoritesWrapperRef }: FavoritesSectionProps) => {
  const dispatch = useDispatch();

  const favorites = useSelector(getFavorites);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const favoritesContentHeight = useSelector(getElementHeightByName(EElementNames.FAVORITES_CONTENT_HEIGHT));

  const isFavourites = !!favorites.length;

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.FAVORITES_MENU_TITLE_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.FAVORITES_MENU_TITLE_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    dispatch(isLoggedIn ? fetchFavorites() : cleanFavorites());
  }, [dispatch, isLoggedIn]);

  return (
    <>
      {isLoggedIn && (
        <div className="biab_favourites-wrap biab_favourites-nav" ref={favoritesWrapperRef}>
          <div className={classNames({ biab_active: isFavourites })}>
            <Collapse
              customClass={styles.navigationCollapse}
              childHeightKey={EElementNames.FAVORITES_MENU_HEIGHT}
              group={LEFT_PANEL_COLLAPSE_GROUP}
              collapse={FAVORITES_COLLAPSE}
              title={<FavouritesHeader isFavourites={isFavourites} />}
              componentRef={componentRef}
            >
              <div className={styles.favorites__wrapper} style={{ maxHeight: favoritesContentHeight }}>
                <ManageRow />
                {favorites.length ? <Content /> : <NoFavorites />}
              </div>
            </Collapse>
          </div>
        </div>
      )}
    </>
  );
};

export default FavoritesSection;
