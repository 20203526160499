import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import AccountMobileLayout from 'components/ResponsibleBetting/components/AccountMobileLayout';
import SEO from 'components/SEO';
import { ASIAN_BASE_URL, CUSTOM_PAGES_BASE_URL } from 'constants/locations';
import useDevice from 'hooks/useDevice';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useElementSize from 'hooks/useElementSize';
import { urls } from 'redux/api/urls';
import { setBackendContent } from 'redux/modules/appConfigs';
import {
  getAppDevice,
  getBackendContentPage,
  getBackendPages,
  getIsIframeEnabled,
  getIsPropertiesLoaded
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { MetaPage } from 'redux/modules/meta/type';
import { fetchPageData } from 'redux/modules/pages';
import { Pages, PagesFromBackend } from 'redux/modules/pages/types';

import BackendContent from './components/BackendContent';
import PageLinks from './components/PageLinks';
import ResponsibleBettingSelfExclusionTemplate from './components/ResponsibleBettingTemplates/ResponsibleBettingSelfExclusionContent';
import ResponsibleBettingSelfExclusionSuspendedTemplate from './components/ResponsibleBettingTemplates/ResponsibleBettingSelfExclusionSuspendedTemplate';
import ResponsibleBettingTimeOutTemplate from './components/ResponsibleBettingTemplates/ResponsibleBettingTimeOutTemplate';

const BackendContentPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMobile } = useDevice();

  const device = useSelector(getAppDevice);
  const pages = useSelector(getBackendPages);
  const backendContentPage = useSelector(getBackendContentPage);
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const headerHeight = useSelector(getElementHeightByName(EElementNames.HEADER_HEIGHT));
  const footerHeight = useSelector(getElementHeightByName(EElementNames.FOOTER_HEIGHT));
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);

  const { pageNotFoundBackGroundId, pageNotFoundLinks } = useDeviceSettings();

  const isCustomPage =
    location.pathname.startsWith(CUSTOM_PAGES_BASE_URL) ||
    location.pathname.startsWith(`${ASIAN_BASE_URL}${CUSTOM_PAGES_BASE_URL}`);

  const isSelfExclusionPage =
    backendContentPage === PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_START ||
    backendContentPage === PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_END ||
    location.pathname.includes(pages[PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_START]) ||
    location.pathname.includes(pages[PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_END]);

  const backendPageEndpointUrl = location.pathname.split('/pages/')[1];

  const hasMinHeightStyle = !(device == Devices.MOBILE && isIframeEnabled);
  const minHeightStyle = hasMinHeightStyle ? { minHeight: `calc(100vh - ${headerHeight + footerHeight}px)` } : {};

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (!backendContentPage && isPropertiesLoaded) {
      if (isSelfExclusionPage) {
        dispatch(setBackendContent(PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_START));
      } else if (isCustomPage) {
        dispatch(setBackendContent(backendPageEndpointUrl));
      } else {
        dispatch(setBackendContent(PagesFromBackend.PAGE_NOT_FOUND));
      }
    }
  }, [isCustomPage, isPropertiesLoaded, isSelfExclusionPage]);

  useEffect(() => {
    let link = pages[PagesFromBackend.PAGE_NOT_FOUND] as string;
    if (backendContentPage && backendContentPage !== PagesFromBackend.PAGE_NOT_FOUND) {
      link =
        isCustomPage && !isSelfExclusionPage
          ? backendPageEndpointUrl
          : (pages[backendContentPage as keyof Pages] as string);
    }

    if (link) {
      dispatch(fetchPageData(link));
    }
  }, [backendContentPage, pages]);

  if (backendContentPage === PagesFromBackend.RG_TOOLS_TIME_LIMIT_END) {
    return isMobile ? (
      <AccountMobileLayout>
        <ResponsibleBettingTimeOutTemplate />
      </AccountMobileLayout>
    ) : (
      <ResponsibleBettingTimeOutTemplate />
    );
  }

  if (backendContentPage === PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_START) {
    return <ResponsibleBettingSelfExclusionTemplate />;
  }
  if (backendContentPage === PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_END) {
    return <ResponsibleBettingSelfExclusionSuspendedTemplate />;
  }

  if (backendContentPage && backendContentPage !== PagesFromBackend.PAGE_NOT_FOUND) {
    const contentLink = isCustomPage ? backendPageEndpointUrl : pages[backendContentPage as keyof Pages];

    let seoPageId = null;

    if (backendContentPage === PagesFromBackend.FORBIDDEN) {
      seoPageId = '403';
    } else if (backendContentPage === PagesFromBackend.UNAUTHORIZED) {
      seoPageId = '401';
    } else if (isCustomPage) {
      const pathnameParams = location.pathname.split('/');
      seoPageId = pathnameParams[pathnameParams.length - 1];
    }

    return (
      <div ref={componentRef} style={minHeightStyle}>
        {seoPageId && <SEO page={MetaPage.CUSTOM} id={seoPageId} pageId={seoPageId} />}
        {contentLink && <BackendContent isShowTitle />}
      </div>
    );
  }

  if (!isPropertiesLoaded) {
    return null;
  }

  return (
    <div ref={componentRef}>
      <div
        className="notFoundPage-wrapper biab_static-content biab_not-found-page"
        style={{
          ...{
            background: pageNotFoundBackGroundId
              ? `url(
              '${
                window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL
              }/customer/api/${urls.app.customerImagesEndpoint(pageNotFoundBackGroundId)}'
            ) center center / cover no-repeat fixed`
              : ''
          },
          ...minHeightStyle
        }}
      >
        <SEO page={MetaPage.CUSTOM} id="404" pageId="404" />
        <BackendContent />
        {!!pageNotFoundLinks.length && <PageLinks data={pageNotFoundLinks} isIframeEnabled={isIframeEnabled} />}
      </div>
    </div>
  );
};

export default memo(BackendContentPage);
