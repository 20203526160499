import { HTMLAttributes } from 'react';
import classNames from 'classnames';

import { mobileBetslip as branding } from 'constants/branding';
import useWindowSize from 'hooks/useWindowSize';
import { generateArray } from 'utils/general';

import styles from './NewVirtualKeyboard.module.scss';

type NewVirtualKeyboardProps = {
  onKeyClick: (key: string) => void;
  onBackSpace: () => void;
  isDisabledKeys?: boolean;
  containerClassName?: HTMLAttributes<HTMLDivElement>['className'];
  shouldIgnoreBlurEvent?: boolean;
};

const NewVirtualKeyboard = ({
  onKeyClick,
  onBackSpace,
  isDisabledKeys = false,
  containerClassName = '',
  shouldIgnoreBlurEvent
}: NewVirtualKeyboardProps) => {
  const { isLandscape } = useWindowSize();

  return (
    <div className={classNames(styles.keyboard, containerClassName)}>
      {isLandscape ? (
        <>
          {(generateArray(9, 1) as (string | number)[]).concat(['0', '00', '.']).map(value => {
            return (
              <button
                key={value}
                className={classNames(styles.keyboard__key, branding.KEYBOARD_KEY, {
                  [branding.DISABLED]: isDisabledKeys
                })}
                disabled={isDisabledKeys}
                onClick={() => onKeyClick(value.toString())}
                onMouseDown={ev => shouldIgnoreBlurEvent && ev.preventDefault()}
              >
                {value}
              </button>
            );
          })}
        </>
      ) : (
        <div
          className={classNames(styles.keyboard__digitsAndDot, {
            [styles.keyboard__digitsAndDot__landscape]: isLandscape
          })}
        >
          <div className={styles.keyboard__firstRow}>
            {generateArray(6, 1).map(value => {
              return (
                <button
                  key={value}
                  className={classNames(styles.keyboard__key, branding.KEYBOARD_KEY, {
                    [branding.DISABLED]: isDisabledKeys
                  })}
                  disabled={isDisabledKeys}
                  onClick={() => onKeyClick(value.toString())}
                  onMouseDown={ev => shouldIgnoreBlurEvent && ev.preventDefault()}
                >
                  {value}
                </button>
              );
            })}
          </div>
          <div className={styles.keyboard__secondRow}>
            {generateArray(3, 7).map(value => {
              return (
                <button
                  key={value}
                  className={classNames(styles.keyboard__key, branding.KEYBOARD_KEY, {
                    [branding.DISABLED]: isDisabledKeys
                  })}
                  disabled={isDisabledKeys}
                  onClick={() => onKeyClick(value.toString())}
                  onMouseDown={ev => shouldIgnoreBlurEvent && ev.preventDefault()}
                >
                  {value}
                </button>
              );
            })}
            {['0', '00', '.'].map(value => {
              return (
                <button
                  key={value}
                  className={classNames(styles.keyboard__key, branding.KEYBOARD_KEY, {
                    [branding.DISABLED]: isDisabledKeys
                  })}
                  disabled={isDisabledKeys}
                  onClick={() => onKeyClick(value)}
                  onMouseDown={ev => shouldIgnoreBlurEvent && ev.preventDefault()}
                >
                  {value}
                </button>
              );
            })}
          </div>
        </div>
      )}
      <button
        className={classNames(styles.keyboard__key, styles.keyboard__clearBtn, branding.KEYBOARD_KEY, {
          [styles.keyboard__clearBtn__landscape]: isLandscape,
          [branding.DISABLED]: isDisabledKeys
        })}
        disabled={isDisabledKeys}
        onClick={onBackSpace}
        onMouseDown={ev => shouldIgnoreBlurEvent && ev.preventDefault()}
      >
        <i className="ion-backspace" />
      </button>
    </div>
  );
};

export default NewVirtualKeyboard;
