import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

import { ECancelActionStatuses } from './type';

const getActions = (state: AppState) => state.cancelActions;
export const getCancelActions = createSelector(getActions, cancelActions => cancelActions.actions);
export const getIsCancelActionsInProgress = createSelector(getActions, cancelActions => cancelActions.inProgress);
export const getLoading = createSelector(getActions, cancelActions => cancelActions.loading);

export const getCancelActionStatus = ({ cancelActions }: AppState) => cancelActions.cancelActionStatus;

export const getCancelledBetsActualNumber = ({ cancelActions }: AppState) =>
  cancelActions.cancelActionStatus?.actualNumberCancelled ?? 0;
export const getCancelledBetsExpectedNumber = ({ cancelActions }: AppState) =>
  cancelActions.cancelActionStatus?.expectedNumberToCancel ?? 0;

export const getIsCancelActionMessage = ({ cancelActions }: AppState) =>
  cancelActions.cancelActionStatus?.status === ECancelActionStatuses.SUCCESSFUL ||
  cancelActions.cancelActionStatus?.status === ECancelActionStatuses.SUCCESSFUL_WITH_LESS_CANCELLED;

export const getCancelAllBtnState = ({ cancelActions }: AppState) => cancelActions.cancelAllBtnState;
