import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import {
  AutoCashOut,
  CashOutMarket,
  CashOutQuote,
  CashOutStatuses,
  MarketCashOutResponse,
  TResponseCashOutStatus
} from 'redux/modules/cashOut/type';
import { IMarketRules } from 'types/markets';

import {
  deleteEventAutoCashOut,
  eventCashOutRemovePendingCashOut,
  eventCreateAutoCashOut,
  failureDeleteEventAutoCashOut,
  failureEventCreateAutoCashOut,
  failureFetchEventAutoCashOutMarkets,
  failureFetchEventCashOutMarket,
  failureFetchEventCashOutMarketRules,
  failureFetchEventCashOutMarkets,
  failureFetchEventCashOutQuotes,
  failureFetchEventCashOutStatus,
  fetchEventAutoCashOutMarkets,
  fetchEventCashOutMarket,
  fetchEventCashOutMarketRules,
  fetchEventCashOutMarkets,
  fetchEventCashOutQuotes,
  fetchEventCashOutStatus,
  successDeleteEventAutoCashOut,
  successEventCreateAutoCashOut,
  successFetchEventAutoCashOutMarkets,
  successFetchEventCashOutMarket,
  successFetchEventCashOutMarketRules,
  successFetchEventCashOutMarkets,
  successFetchEventCashOutQuotes,
  successFetchEventCashOutStatus
} from './index';
import { getEventCashOutStringifiedAutoCashOutMarkets } from './selectors';

function* fetchEventCashOutQuotesWorker(action: ReturnType<typeof fetchEventCashOutQuotes>) {
  try {
    const response: CashOutQuote[] = yield call(api.cashOut.getCashOut, { ids: action.payload.ids, isAsianView: true });

    yield put(
      successFetchEventCashOutQuotes({
        quotes: response,
        firstLoaded: action.payload.firstLoading,
        isWebSocketResponse: false
      })
    );
  } catch (error: any) {
    yield put(failureFetchEventCashOutQuotes(error.data));
  }
}

function* fetchEventCashOutMarketsWorker(action: ReturnType<typeof fetchEventCashOutMarkets>) {
  try {
    let response: MarketCashOutResponse = yield call(api.cashOut.getMarkets, { ...action.payload, isAsianView: true });

    // This condition needs if page from payload is bigger than last available page (totalPages) in response, so we need to do one more request with valid page
    if (
      action.payload.isPaginationEnabled &&
      action.payload.page > 0 &&
      action.payload.changePage &&
      action.payload.page + 1 > response.markets.totalPages
    ) {
      response = yield call(api.cashOut.getMarkets, {
        ...action.payload,
        page: response.markets.totalPages - 1,
        isAsianView: true
      });
      action.payload.changePage(response.markets.totalPages - 1);
    }

    yield put(
      successFetchEventCashOutMarkets({
        ...response,
        withLoader: action.payload.withLoader,
        isUpdated: !action.payload.isPaginationEnabled
      })
    );
  } catch (error: any) {
    yield put(
      failureFetchEventCashOutMarkets({
        error: error.data,
        withLoader: action.payload.withLoader
      })
    );
  }
}

function* eventCreateAutoCashOutWorker(action: ReturnType<typeof eventCreateAutoCashOut>) {
  try {
    const response: AutoCashOut = yield call(api.cashOut.createAutoCashOut, action.payload, true);
    yield put(successEventCreateAutoCashOut(response));
  } catch (error: any) {
    yield put(failureEventCreateAutoCashOut(error.data));
  }
}

function* eventDeleteAutoCashOutWorker(action: ReturnType<typeof deleteEventAutoCashOut>) {
  try {
    yield call(api.cashOut.deleteAutoCashOut, action.payload.id, true);
    yield put(successDeleteEventAutoCashOut(action.payload.marketId));
  } catch (error: any) {
    yield put(failureDeleteEventAutoCashOut(error));
  }
}

function* eventAutoCashOutMarketsWorker(action: ReturnType<typeof fetchEventAutoCashOutMarkets>) {
  try {
    const response: AutoCashOut[] = yield call(api.cashOut.getAutoCashOutMarkets, action.payload, true);
    const prevStringifiedAutoCashOutMarkets: string = yield select(getEventCashOutStringifiedAutoCashOutMarkets);
    const stringifiedResponse = JSON.stringify(response);

    if (prevStringifiedAutoCashOutMarkets !== stringifiedResponse) {
      yield put(
        successFetchEventAutoCashOutMarkets({
          autoCashOuts: response,
          isWebSocketResponse: false,
          stringifiedAutoCashOuts: stringifiedResponse
        })
      );
    }
  } catch (error: any) {
    yield put(failureFetchEventAutoCashOutMarkets(error.data));
  }
}

function* fetchEventCashOutMarketWorker(action: ReturnType<typeof fetchEventCashOutMarket>) {
  try {
    const response: CashOutMarket = yield call(api.cashOut.setCashOut, action.payload, true);
    if (response.offers?.length && response.id) {
      yield put(successFetchEventCashOutMarket({ ...response, marketId: action.payload.marketId }));
    } else {
      yield put(
        eventCashOutRemovePendingCashOut({
          marketId: action.payload.marketId,
          status: response.status
        })
      );
    }
  } catch (error: any) {
    yield put(failureFetchEventCashOutMarket(error.data));
  }
}

function* fetchEventCashOutStatusWorker(action: ReturnType<typeof fetchEventCashOutStatus>) {
  try {
    const response: TResponseCashOutStatus = yield call(api.cashOut.getCashOutStatus, action.payload.statusId, true);
    yield put(
      successFetchEventCashOutStatus({
        statusId: action.payload.statusId,
        status: response.status,
        marketId: response.marketId
      })
    );

    if (
      (response.status === CashOutStatuses.SUCCESS || response.status === CashOutStatuses.SUCCESS_WITH_BETTER_VALUE) &&
      action.payload.onSuccessStatus
    ) {
      action.payload.onSuccessStatus();
    }
  } catch (error: any) {
    yield put(failureFetchEventCashOutStatus({ error: error, statusId: action.payload.statusId }));
  }
}

function* fetchEventCashOutMarketRulesWorker(action: ReturnType<typeof fetchEventCashOutMarketRules>) {
  try {
    const response: IMarketRules = yield call(api.app.marketRules, action.payload);
    yield put(successFetchEventCashOutMarketRules({ marketId: action.payload, rules: response }));
  } catch (error: any) {
    yield put(failureFetchEventCashOutMarketRules(error.data));
  }
}

export default function* saga() {
  yield all([
    takeEvery(fetchEventCashOutQuotes.type, fetchEventCashOutQuotesWorker),
    takeEvery(fetchEventCashOutMarkets.type, fetchEventCashOutMarketsWorker),
    takeEvery(eventCreateAutoCashOut.type, eventCreateAutoCashOutWorker),
    takeEvery(deleteEventAutoCashOut.type, eventDeleteAutoCashOutWorker),
    takeEvery(fetchEventAutoCashOutMarkets.type, eventAutoCashOutMarketsWorker),
    takeEvery(fetchEventCashOutMarket.type, fetchEventCashOutMarketWorker),
    takeEvery(fetchEventCashOutStatus.type, fetchEventCashOutStatusWorker),
    takeLatest(fetchEventCashOutMarketRules.type, fetchEventCashOutMarketRulesWorker)
  ]);
}
