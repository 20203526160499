import { useSelector } from 'react-redux';

import { ODDS_REGEX_PATTERN } from 'constants/placement';
import { getPrecisionType } from 'redux/modules/user/selectors';
import { PrecisionType } from 'types';

export const getCurrencyPrecisionValue = (precisionType?: PrecisionType) => {
  switch (precisionType) {
    case PrecisionType.UNITS:
      return 0;
    case PrecisionType.TENTHS:
      return 1;
    case PrecisionType.HUNDREDTHS:
      return 2;
    default:
      return 2;
  }
};

export const useStakeRegexPattern = () => {
  const precision = useSelector(getPrecisionType);

  let precisionPattern = ODDS_REGEX_PATTERN;
  const precisionValue = getCurrencyPrecisionValue(precision);

  if (precisionValue === 0) {
    precisionPattern = /^(([1-9][0-9]*)|(0))$/;
  } else if (precisionValue === 1) {
    precisionPattern = /^(([1-9][0-9]*)|(0))([.,][0-9]{0,1})?$/;
  }

  return precisionPattern;
};
