import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM,
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_POPULAR_LINK_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  AsianViewMarketLink,
  AsianViewTimeFilter
} from 'constants/asianView';
import { ASIAN_BASE_URL } from 'constants/locations';
import { SEARCH_QUERY_LENGTH } from 'constants/search';
import { PARAMS_ASIAN_SEARCH_KEY } from 'constants/urlParams';
import useWindowSize from 'hooks/useWindowSize';
import { resetContent } from 'redux/modules/asianView';
import { setSectionSize } from 'redux/modules/asianView';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { SportId } from 'types';

import CompetitionSections from './components/CompetitionSections';
import Heading from './components/Heading';
import MarketLinkOutRight from './components/MarketLinkOutRight';
import OddView from './components/OddView';

import styles from './styles.module.scss';

const CONTENT: Record<string, () => JSX.Element> = {
  [AsianViewMarketLink.HDP_AND_OU]: () => <CompetitionSections />,
  [AsianViewMarketLink.MATCH_ODDS_HALF_TIME]: () => <CompetitionSections />,
  [AsianViewMarketLink.OUTRIGHT]: () => <MarketLinkOutRight />,
  [AsianViewMarketLink.ODD_EVEN]: () => <OddView />,
  [AsianViewMarketLink.CORRECT_SCORE]: () => <CompetitionSections />,
  [AsianViewMarketLink.HALF_TIME_FULL_TIME]: () => <CompetitionSections />,
  [AsianViewMarketLink.TOTAL_GOALS]: () => <CompetitionSections />
};

type AsianViewPageModuleProps = {
  noRedirect?: boolean;
};

const AsianViewPageModule = ({ noRedirect = false }: AsianViewPageModuleProps) => {
  const dispatch = useDispatch();
  const { marketLink = '', timeFilter, sportId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isMobileAsianView = useSelector(getIsMobileAsianView);

  let contentType = marketLink as AsianViewMarketLink;
  const query = searchParams.get(PARAMS_ASIAN_SEARCH_KEY) || '';
  const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
  const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
  const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);
  const popularLinkCompetitionId = searchParams.get(ASIAN_POPULAR_LINK_COMPETITION_ID);
  const singleViewCompetitionId = searchParams.get(ASIAN_SINGLE_VIEW_COMPETITION_ID);
  const singleViewEventId = searchParams.get(ASIAN_SINGLE_VIEW_EVENT_ID);
  const singleViewSportId = searchParams.get(ASIAN_SINGLE_VIEW_SPORT_ID);
  const isPopularLinkView = !!popularLinkCompetitionId;
  const isFavouriteView = !!(favouriteType && favouriteId && favouriteSportId);
  const isSingleView = (!!singleViewCompetitionId || !!singleViewEventId) && !!singleViewSportId;
  const isMainView = query.length >= SEARCH_QUERY_LENGTH || isFavouriteView || isPopularLinkView || isSingleView;
  const outrightCompetitionId = searchParams.get(ASIAN_OUTRIGHT_COMPETITION_ID);
  const isSingleOutrightView = !!outrightCompetitionId;

  if (isMainView) {
    contentType = AsianViewMarketLink.HDP_AND_OU;
  } else if (isSingleOutrightView) {
    contentType = AsianViewMarketLink.OUTRIGHT;
  }
  const sectionRef = useRef<HTMLDivElement>(null);
  const { windowWidth } = useWindowSize(100);

  useEffect(() => {
    dispatch(resetContent());
  }, [dispatch, marketLink]);

  useEffect(() => {
    if (!noRedirect && (!sportId || !timeFilter || !marketLink)) {
      navigate(
        `${ASIAN_BASE_URL}/sport/${SportId.SOCCER}/timeFilter/${AsianViewTimeFilter.Today}/marketLink/${AsianViewMarketLink.HDP_AND_OU}`,
        { replace: true }
      );
    }
  }, [marketLink, timeFilter, sportId, noRedirect]);

  useEffect(() => {
    dispatch(setSectionSize(sectionRef.current?.getBoundingClientRect()));
  }, [sectionRef.current?.clientWidth, windowWidth]);

  return (
    <div
      className={classNames('biab_asian-view-page-wrapper', {
        [styles.pageWrapper]: !isMobileAsianView,
        [styles.mobilePageWrapper]: isMobileAsianView,
        [styles.noPadding]: isMainView || marketLink === AsianViewMarketLink.HDP_AND_OU
      })}
      ref={sectionRef}
    >
      {!isMobileAsianView && <Heading />}
      {CONTENT[contentType]?.()}
    </div>
  );
};

export default AsianViewPageModule;
