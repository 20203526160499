type TLanguage = { license: string; languages: { lang: string; aliases: string[] }[]; default: string };

const locales: {
  eu: TLanguage;
  com: TLanguage;
} = {
  eu: {
    license: 'eu',
    default: 'en-us',
    languages: [
      { lang: 'en-us', aliases: ['en'] },
      { lang: 'de-de', aliases: ['de'] },
      { lang: 'fi-fi', aliases: ['fi'] },
      { lang: 'fr-fr', aliases: ['fr'] },
      { lang: 'hr-hr', aliases: ['hr'] },
      { lang: 'hu-hu', aliases: ['hu'] },
      { lang: 'lt-lt', aliases: ['lt'] },
      { lang: 'lv-lv', aliases: ['lv'] },
      { lang: 'pl-pl', aliases: ['pl'] },
      { lang: 'ru-ru', aliases: ['ru'] },
      { lang: 'si-si', aliases: ['sl'] }
    ]
  },
  com: {
    license: 'com',
    default: 'en-us',
    languages: [
      { lang: 'en-us', aliases: ['en'] },
      { lang: 'de-de', aliases: ['de'] },
      { lang: 'es-la', aliases: ['es-419', 'es'] },
      { lang: 'fr-fr', aliases: ['fr'] },
      { lang: 'is-is', aliases: ['is'] },
      { lang: 'it-it', aliases: ['it'] },
      { lang: 'ja-jp', aliases: ['ja'] },
      { lang: 'nl-nl', aliases: ['nl'] },
      { lang: 'no-no', aliases: ['no'] },
      { lang: 'pt-br', aliases: ['pt-BR'] },
      { lang: 'ru-ru', aliases: ['ru'] },
      { lang: 'ua-ua', aliases: ['uk'] },
      { lang: 'zh-cn', aliases: ['zh-CN'] },
      { lang: 'zh-tw', aliases: ['zh-tw'] }
    ]
  }
};

export const getPSLocale = (curLocale: string, licence: 'com' | 'eu') => {
  let alias;
  locales[licence]?.languages.forEach(elem => {
    elem.aliases.forEach(el => {
      if (el === curLocale || el === curLocale.replace('_', '-')) {
        alias = elem.lang;
      }
    });
  });
  return alias || locales[licence]?.default;
};
