import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';

import {
  TFetchHomeMarketRulesPayload,
  TFetchHomeMarketsDataPayload,
  THome,
  TSuccessFetchHomeMarketsData,
  TSuccessHomeMarketRules
} from './type';

const initialState: THome = {
  marketsData: {},
  error: null,
  loading: false,
  rules: {},
  rulesLoading: false,
  rulesError: null
};

const slice = createSlice({
  name: SLICES_NAMES.HOME,
  initialState,
  reducers: {
    fetchHomeMarketsData: (state, _: PayloadAction<TFetchHomeMarketsDataPayload>) => {
      state.loading = true;
    },
    successHomeMarketsData: (state, { payload }: PayloadAction<TSuccessFetchHomeMarketsData>) => {
      state.loading = false;

      state.marketsData[payload.sportId] = {
        sportInfo: payload.sportInfo,
        marketCatalogueList: payload.marketCatalogueList
      };
    },
    failureHomeMarketsData: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = payload;
    },
    resetTabMarketData: (state, { payload }: PayloadAction<string>) => {
      delete state.marketsData[payload];
      delete state.rules[payload];
    },
    fetchHomeMarketRules: (state, _: PayloadAction<TFetchHomeMarketRulesPayload>) => {
      state.rulesLoading = true;
    },
    successHomeMarketRules: (state, { payload }: PayloadAction<TSuccessHomeMarketRules>) => {
      state.rulesLoading = false;

      if (!state.rules[payload.sportId]) {
        state.rules[payload.sportId] = {};
      }

      state.rules[payload.sportId][payload.marketId] = payload.rules;
    },
    failureHomeMarketRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.rulesLoading = false;
      state.rulesError = payload;
    }
  }
});

export const {
  fetchHomeMarketsData,
  resetTabMarketData,
  failureHomeMarketsData,
  fetchHomeMarketRules,
  successHomeMarketsData,
  successHomeMarketRules,
  failureHomeMarketRules
} = slice.actions;

export default slice.reducer;
