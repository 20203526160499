import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { EXCHANGE, GAME } from 'constants/app';
import { betslipBranding as branding } from 'constants/branding';
import { CANCEL_ACTION_FETCHING_TIMEOUT, HIDE_MOBILE_CANCEL_ALL_BETS_TIMEOUT } from 'constants/placement';
import useDevice from 'hooks/useDevice';
import { getBalanceWsEnabled, getGeneralWsEnabled, getIsPropertiesLoaded } from 'redux/modules/appConfigs/selectors';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { fetchCancelActionStatus, removeCancelActionStatus, setCancelAllBtnState } from 'redux/modules/cancelActions';
import { getCancelActionStatus, getCancelAllBtnState, getLoading } from 'redux/modules/cancelActions/selectors';
import { ECancelActionStatuses } from 'redux/modules/cancelActions/type';
import { getCurrentBetsByType } from 'redux/modules/currentBets/selectors';
import { setCancelAllUnmatchedBetsActionId } from 'redux/modules/myBets';
import { cancelAllBets, successCancelAllBets } from 'redux/modules/placement';
import { getCancelledBetsStatusId } from 'redux/modules/placement/selectors';
import { fetchBalance } from 'redux/modules/user';
import { MatchTypes } from 'types/bets';
import { ECancelAllBtnState } from 'types/betslip';

import styles from './styles.module.scss';

const CancelAllButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isMobile } = useDevice();
  const cancelAllBtnState = useSelector(getCancelAllBtnState);
  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const unmatchedBets = useSelector(
    getCurrentBetsByType({ type: MatchTypes.UNMATCHED, isGameType: isGameBetSlip, ignoreCancelled: true })
  );
  const cancelledBetsStatusId = useSelector(getCancelledBetsStatusId);
  const cancelActionStatus = useSelector(getCancelActionStatus);
  const loading = useSelector(getLoading);
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const isActive = cancelAllBtnState === ECancelAllBtnState.ACTIVE;
  const isConfirm = cancelAllBtnState === ECancelAllBtnState.CONFIRM;
  const isDisabled = cancelAllBtnState === ECancelAllBtnState.CANCELLING;
  const isHidden = cancelAllBtnState === ECancelAllBtnState.HIDDEN;

  const statusesInterval = useRef<NodeJS.Timer>();

  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  const betTypes = isGameBetSlip ? GAME : EXCHANGE;

  const cancelAllBetsData = { betTypes: [betTypes] };

  const clearStatusInterval = () => {
    if (statusesInterval.current) {
      clearInterval(statusesInterval.current);
    }
  };

  useEffect(() => {
    dispatch(setCancelAllBtnState(unmatchedBets.length ? ECancelAllBtnState.ACTIVE : ECancelAllBtnState.HIDDEN));
  }, [unmatchedBets.length]);

  useEffect(() => {
    if (cancelledBetsStatusId) {
      if ((!generalWsEnabled || !balanceWsEnabled) && arePropertiesLoaded) {
        dispatch(fetchBalance());
      }

      statusesInterval.current = setInterval(() => {
        if (!loadingRef.current) {
          dispatch(
            fetchCancelActionStatus({
              cancelActionId: cancelledBetsStatusId
            })
          );
        }
      }, CANCEL_ACTION_FETCHING_TIMEOUT);
    }
  }, [cancelledBetsStatusId, arePropertiesLoaded]);

  useEffect(() => {
    if (cancelActionStatus) {
      if (
        cancelActionStatus.status === ECancelActionStatuses.SUCCESSFUL ||
        cancelActionStatus.status === ECancelActionStatuses.SUCCESSFUL_WITH_LESS_CANCELLED
      ) {
        if (isMobile) {
          setTimeout(() => {
            dispatch(removeCancelActionStatus());
          }, HIDE_MOBILE_CANCEL_ALL_BETS_TIMEOUT);
        }

        if (cancelledBetsStatusId) {
          dispatch(setCancelAllUnmatchedBetsActionId(cancelledBetsStatusId));
          dispatch(successCancelAllBets(null));
        }

        dispatch(setCancelAllBtnState(ECancelAllBtnState.HIDDEN));

        clearStatusInterval();
      } else if (cancelActionStatus.status === ECancelActionStatuses.FAILED) {
        clearStatusInterval();
      }
    }
  }, [cancelActionStatus?.id, cancelActionStatus?.status]);

  useEffect(() => {
    return () => {
      clearStatusInterval();
    };
  }, []);

  const cancelAllHandler = () => {
    if (isActive) {
      dispatch(setCancelAllBtnState(ECancelAllBtnState.CONFIRM));
    } else if (isConfirm) {
      dispatch(setCancelAllBtnState(ECancelAllBtnState.CANCELLING));
      dispatch(cancelAllBets({ data: cancelAllBetsData }));
    }
  };

  if (isHidden || isDisabled) {
    return null;
  }

  return (
    <div className={classNames(styles.cancelAllBtnWrap, branding.CANCEL_ALL_OFFERS_BTN_WRAP)}>
      <button
        className={classNames(branding.BTN, styles.cancelAllBtnWrap__btn, {
          [branding.CANCEL_ALL_OFFERS_BTN]: isActive,
          [branding.CONFIRM_CANCEL_ALL_OFFERS_BTN]: isConfirm,
          [branding.DISABLED]: isDisabled
        })}
        type="button"
        disabled={isDisabled}
        onClick={cancelAllHandler}
      >
        {t(`betslip.labels.btn.${isActive ? 'cancelAllBets' : 'confirmCancellation'}`)}
      </button>
    </div>
  );
};

export default CancelAllButton;
