import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { BODY_ID } from 'constants/app';
import { DEFAULT_GOOGLE_FONT_WEIGHT, GOOGLE_FONTS, GOOGLE_FONTS_API } from 'constants/customFonts';
import { getAppDevice, getBrandingCSSLoaded, getLanguage } from 'redux/modules/appConfigs/selectors';

const FontsInjection = () => {
  const device = useSelector(getAppDevice);
  const language = useSelector(getLanguage);
  const isBrandingCSSLoaded = useSelector(getBrandingCSSLoaded);

  const [fontName, setFontName] = useState<string | null>(null);

  const checkAppFont = () => {
    const element: HTMLElement | null = document.getElementById(BODY_ID);
    const style = element ? window.getComputedStyle(element, null) : null;
    const fontFamily = style?.fontFamily.replace(/"/g, '') ?? '';

    if (element && fontFamily && GOOGLE_FONTS.includes(fontFamily)) {
      const fontStyles = fontFamily.split(':');
      const fontFamilyName = fontStyles?.[0]?.replace(/[+|:]/g, ' ') ?? '';
      const fontWeight = fontStyles?.[1] ?? DEFAULT_GOOGLE_FONT_WEIGHT;

      if (fontFamilyName) {
        element.style.fontFamily = fontFamilyName;
      }

      if (fontWeight) {
        element.style.fontWeight = fontWeight;
      }

      setFontName(fontFamily);
    }
  };

  const clearAppFont = () => {
    const element: HTMLElement | null = document.getElementById(BODY_ID);

    if (element) {
      /** Remove font family if it was set up for other device type or locale */
      element.style.fontFamily = '';
      element.style.fontWeight = '';
    }
  };

  useEffect(() => {
    if (isBrandingCSSLoaded) {
      clearAppFont();
      checkAppFont();
    }
  }, [isBrandingCSSLoaded, device, language]);

  return fontName ? (
    <link rel="stylesheet" type="text/css" href={`${GOOGLE_FONTS_API + encodeURIComponent(fontName)}`} />
  ) : null;
};

export default FontsInjection;
