import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useResizeObserver } from 'usehooks-ts';

import { LIST_VIEW_TAB_WIDTH } from 'constants/app';
import { SPORT_BASE_URL } from 'constants/locations';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { getListViewTopMarket } from 'redux/modules/listViewTabs/selectors';
import { TListViewTabs } from 'types';

import styles from '../../styles.module.scss';

interface DesktopListProps {
  tabs: TListViewTabs[];
  sportId: string;
  marketId: string;
}

const DesktopList = ({ tabs, sportId, marketId: selectedMarketId }: DesktopListProps) => {
  const { t } = useTranslation();

  const topMarket = useSelector(getListViewTopMarket);

  const listViewRef = useRef<HTMLDivElement>(null);
  const hiddenListViewRef = useRef<HTMLLIElement>(null);

  const [isMoreOpened, setIsMoreOpened] = useState(false);

  useOnClickOutside(hiddenListViewRef, () => setIsMoreOpened(false));
  const { width } = useResizeObserver({ ref: listViewRef, box: 'border-box' });

  const marketId = selectedMarketId || topMarket?.marketId;

  const listViewTabs = useMemo(() => {
    if (width) {
      const tabsCount = Math.floor(width / LIST_VIEW_TAB_WIDTH) - 1;

      if (tabs.length * LIST_VIEW_TAB_WIDTH > width) {
        const visibleTabs = tabs.slice(0, tabsCount);
        const hiddenTabs = tabs.slice(tabsCount);

        return { visibleTabs, hiddenTabs };
      }

      return { visibleTabs: tabs, hiddenTabs: [] };
    }

    return { visibleTabs: [], hiddenTabs: [] };
  }, [tabs, width]);

  const hiddenMarket = useMemo(() => {
    return listViewTabs.hiddenTabs.find(tab => tab.id === marketId);
  }, [listViewTabs, marketId]);

  useEffect(() => {
    setIsMoreOpened(false);
  }, [selectedMarketId, listViewTabs]);

  const getUrl = (id: string) => {
    if (sportId.includes('tc-')) {
      return `${SPORT_BASE_URL}/${sportId}/market/tc-${id}`;
    }
    return `${SPORT_BASE_URL}/${sportId}/market/${id}`;
  };

  return (
    <div className={classNames('biab_list-view-tabs-wrapper', styles.listView__wrapper)} ref={listViewRef}>
      <ul className={classNames('biab_list-view-tabs', styles.listView)}>
        {!!tabs.length && (
          <>
            {!!listViewTabs.visibleTabs.length &&
              listViewTabs.visibleTabs.map(({ id, name }) => (
                <li
                  key={id}
                  className={classNames(styles.listView__tab, {
                    [`${styles.listView__tab__active} biab_active`]: marketId === id
                  })}
                >
                  <NavLink to={getUrl(id)} className={styles.listView__tab__links}>
                    {name}
                  </NavLink>
                </li>
              ))}
            {!!listViewTabs.hiddenTabs.length && (
              <li
                className={classNames('biab_more-tab', styles.listView__tab, styles.listView__tab__more, {
                  [`${styles.listView__tab__more_active} biab_open`]: isMoreOpened,
                  [`${styles.listView__tab__more_active} biab_active`]: hiddenMarket
                })}
                onClick={() => setIsMoreOpened(!isMoreOpened)}
                ref={hiddenListViewRef}
              >
                <a className={classNames(styles.listView__tab__links, styles.listView__tab__moreLink)}>
                  {hiddenMarket ? hiddenMarket.name : t('market.listView.more')}
                </a>

                {isMoreOpened && (
                  <ul className={classNames('biab_list-view-more-tabs', styles.listView, styles.listView_hidden)}>
                    {listViewTabs.hiddenTabs.map(({ id, name }) => (
                      <li
                        key={id}
                        className={classNames(
                          styles.listView__tab,
                          styles.listView__tab_hidden,
                          styles.listView__fromStart
                        )}
                      >
                        <NavLink to={getUrl(id)} className={styles.listView__tab__links}>
                          {name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
          </>
        )}
      </ul>
    </div>
  );
};

export default memo(DesktopList);
