import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import FancyMarket from 'components/FancyMarket';
import { SPORT_BASE_URL } from 'constants/locations';
import useLayColumn from 'hooks/useLayColumn';
import useMarketDepth from 'hooks/useMarketDepth';
import { getEventData, getEventDataLoading } from 'redux/modules/competitions/selectors';
import { fetchFancyEventDetails } from 'redux/modules/fancyMarket';
import { getFancyMarketAllMarkets, getFancyMarketData } from 'redux/modules/fancyMarket/selectors';
import { FancyMarketTypes } from 'redux/modules/fancyMarket/type';
import { PageBlocks } from 'types';
import { EMarketDepthView } from 'types/markets';

const FancyMarketPage = () => {
  const dispatch = useDispatch();
  const { fancyId = '', eventId = '', sportId = '' } = useParams();
  const navigate = useNavigate();

  const {
    loading,
    eventInfo,
    startDate,
    inningsFancy,
    inningsFours,
    inningsSixes,
    totalInningsRuns,
    batsmen,
    otherLineMarkets,
    fallOfWicket
  } = useSelector(getFancyMarketData);
  const isEventDataLoader = useSelector(getEventDataLoading);
  const allMarkets = useSelector(getFancyMarketAllMarkets);
  const eventData = useSelector(getEventData);

  const { isDepthEnabled } = useMarketDepth(EMarketDepthView.FANCY_MARKET);
  const { isLayColumnEnabled } = useLayColumn({
    sportId: allMarkets[0]?.eventType.id,
    bettingType: allMarkets[0]?.description.bettingType
  });

  useEffect(() => {
    if (eventData.length) {
      const isFirstInningFancy = eventData.some(({ id }) => id === FancyMarketTypes.FIRST_INNINGS_FANCY);

      if (!isFirstInningFancy && fancyId === FancyMarketTypes.FIRST_INNINGS_FANCY) {
        navigate(`${SPORT_BASE_URL}/${sportId}/fancyEvent/${eventId}/${FancyMarketTypes.SECOND_INNINGS_FANCY}`);
      } else {
        if (fancyId) {
          dispatch(fetchFancyEventDetails({ eventId, fancyMarketTypes: fancyId as FancyMarketTypes }));
        } else {
          if (isFirstInningFancy) {
            dispatch(fetchFancyEventDetails({ eventId, fancyMarketTypes: FancyMarketTypes.FIRST_INNINGS_FANCY }));
          } else {
            dispatch(fetchFancyEventDetails({ eventId, fancyMarketTypes: FancyMarketTypes.SECOND_INNINGS_FANCY }));
          }
        }
      }
    }
  }, [fancyId, eventId, eventData, dispatch, sportId]);

  return (
    <FancyMarket
      marketId={allMarkets?.[0]?.marketId ?? ''}
      loading={loading || isEventDataLoader}
      eventInfo={eventInfo}
      inningsFancy={inningsFancy}
      inningsFours={inningsFours}
      inningsSixes={inningsSixes}
      totalInningsRuns={totalInningsRuns}
      batsmen={batsmen}
      otherLineMarkets={otherLineMarkets}
      fallOfWicket={fallOfWicket}
      startDate={startDate}
      isLayColumnDisabled={!isLayColumnEnabled}
      isDepthEnabled={isDepthEnabled}
      pageBlock={PageBlocks.FANCY_VIEW}
    />
  );
};

export default FancyMarketPage;
