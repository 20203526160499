import { createSlice } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';

import { IGetTooltipsTranslations, TTooltip } from './type';

export const initialState: TTooltip = {
  isContextualHelpEnabled: false,
  tooltips: {},
  loading: false
};

const slice = createSlice({
  name: SLICES_NAMES.TOOLTIP,
  initialState,
  reducers: {
    setContextualHelp: (state, action: { payload: boolean }) => {
      state.isContextualHelpEnabled = action.payload;
    },
    fetchTooltips: (state, _: { payload: IGetTooltipsTranslations }) => {
      state.loading = true;
    },
    successFetchTooltips: (state, action: { payload: Record<string, boolean> }) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state.tooltips[key] = value;
      });

      state.loading = false;
    }
  }
});

export const { setContextualHelp, successFetchTooltips, fetchTooltips } = slice.actions;

export default slice.reducer;
