import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const carts = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];

const SuitPositionRow = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.main}>
      <div>{t('games.labels.suitPosition')}</div>
      <div className={styles.cartCells}>
        {carts.map(c => (
          <div key={c} className={styles.cartCell}>
            {c}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuitPositionRow;
