import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DEFAULT_MARKET_UNITS } from 'constants/placement';
import { useMarketUnits } from 'hooks/useMarketUnits';
import {
  getLineMarketsSwitchBackLayOnCricket,
  getReplaceBackLayWithUnderOver
} from 'redux/modules/appConfigs/selectors';
import { SportId } from 'types';
import { BetTypes } from 'types/bets';
import { TLabels } from 'types/betslip';
import { InputTypes } from 'types/inlinePlacement';
import { BettingType } from 'types/markets';

export const usePlacementInputLabels = (
  betType: BetTypes,
  isLineMarket: boolean,
  marketUnit: string = DEFAULT_MARKET_UNITS
) => {
  const { t } = useTranslation();

  return {
    [InputTypes.ODDS]: isLineMarket ? marketUnit : t('inlinePlacement.labels.odds'),
    [InputTypes.STAKE]:
      betType === BetTypes.BACK ? t('inlinePlacement.labels.stake') : t('inlinePlacement.labels.stake.lay'),
    liability: t(`inlinePlacement.labels.${betType === BetTypes.BACK ? 'profit' : 'liability'}`)
  };
};

export const useBetslipLabels = ({
  eventTypeId,
  bettingType,
  marketUnit,
  isMobilePlacement = false,
  isInlinePlacement = false
}: {
  eventTypeId: string;
  bettingType?: string;
  marketType?: string;
  marketUnit?: string | null;
  isMobilePlacement?: boolean;
  isInlinePlacement?: boolean;
}) => {
  const { t } = useTranslation();

  const lineBackLayToNoYes = useSelector(getLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getReplaceBackLayWithUnderOver);

  const marketUnitTranslated = useMarketUnits(marketUnit || 'points');

  const isLineMarket = bettingType === BettingType.LINE;
  const isCricketMarket = eventTypeId === SportId.CRICKET;

  const getLabelType = (type: string) => {
    return !isCricketMarket && isLineMarket && replaceBackLayWithUnderOver
      ? type === 'back'
        ? 'under'
        : 'over'
      : isCricketMarket && isLineMarket && lineBackLayToNoYes
      ? type === 'back'
        ? 'no'
        : 'yes'
      : type;
  };

  const labels: TLabels = {};
  const backBetType = BetTypes.BACK.toLowerCase();

  [BetTypes.BACK, BetTypes.LAY].forEach(type => {
    const betType = type.toLowerCase();
    const placementType = isInlinePlacement ? 'inlinePlacement' : 'betslip';

    labels[type] = {
      bet: t('betslip.labels.type.' + getLabelType(betType)),
      odds: isLineMarket
        ? /** Use betslip.labels.units.back for both types if market is LINE */
          t(`betslip.labels.units.${backBetType}`, { UNITS: marketUnitTranslated })
        : t(`betslip.labels.price.${betType}`),
      stake: t(`${placementType}.labels.size.${betType}`),
      profit: t(`${placementType}.labels.profit.${betType}`)
    };
  });

  return labels;
};
