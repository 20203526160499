import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getMobileSwipeMenu } from 'redux/modules/appConfigs/selectors';

import DesktopNavigation from './components/DesktopNavigation';
import MobileNavigation from './components/MobileNavigation';

import styles from './styles.module.scss';

interface HeaderNavigationProps {
  isMobile: boolean;
}

const HeaderNavigation = ({ isMobile }: HeaderNavigationProps) => {
  const swipeMenu = useSelector(getMobileSwipeMenu);

  return (
    <div
      className={classNames('biab_header-submenu', styles.headerNavigation, {
        [styles.headerNavigation__sticky]: isMobile && swipeMenu,
        [styles.headerNavigation__mobile]: isMobile
      })}
    >
      {isMobile ? <MobileNavigation /> : <DesktopNavigation />}
    </div>
  );
};

export default HeaderNavigation;
