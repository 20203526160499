import { memo } from 'react';

import { useFormatCurrency } from 'hooks/useFormatCurrency';

import styles from './styles.module.scss';

interface AHNetPLValueProps {
  isPL: boolean;
  isWhatIf: boolean;
  currency: string;
  plValue?: string | number;
  whatIfValue?: string | number;
  plStyle?: string;
  whatIfStyle?: string;
}

const AHNetPLValue = ({
  isPL,
  isWhatIf,
  currency,
  plValue = 0,
  whatIfValue = 0,
  plStyle,
  whatIfStyle
}: AHNetPLValueProps) => {
  const isPositivePL = parseFloat(plValue.toString()) >= 0;
  const isPositiveWhatIfPL = parseFloat(whatIfValue.toString()) >= 0;
  const { noFormattedAmount: formatPlValue } = useFormatCurrency(plValue, currency, {
    isCheckIndian: true,
    noRounding: true
  });
  const { noFormattedAmount: formatWhatIfValue } = useFormatCurrency(whatIfValue, currency, {
    isCheckIndian: true,
    noRounding: true
  });

  return (
    <>
      {isPL && (
        <span className={styles[plStyle || '']}>
          {isPositivePL && '+'}
          {formatPlValue}
        </span>
      )}
      {isPL && isWhatIf && <span className="biab_pl-separator">&rsaquo;&rsaquo;</span>}
      {isWhatIf && (
        <span className={styles[whatIfStyle || '']}>
          {isPositiveWhatIfPL && '+'}
          {formatWhatIfValue}
        </span>
      )}
    </>
  );
};

export default memo(AHNetPLValue);
