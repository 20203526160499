import { memo } from 'react';
import { useSelector } from 'react-redux';

import Skeleton from 'components/Skeleton';
import { getSingleMarketInfo } from 'redux/modules/market/selectors';

import styles from './styles.module.scss';

const RaceCardSkeleton = () => {
  const market = useSelector(getSingleMarketInfo);

  return (
    <div className={styles.skeleton}>
      <div className={styles.skeleton__tab__container}>
        {new Array(5).fill(0).map((_, idx) => (
          <Skeleton key={idx} className={styles.skeleton__tab} noRounded />
        ))}
      </div>
      <Skeleton className={styles.skeleton__info} />
      <Skeleton className={styles.skeleton__selections} />
      <Skeleton className={styles.skeleton__runner} style={{ minHeight: (market?.runners.length || 3) * 32 }} />
    </div>
  );
};

export default memo(RaceCardSkeleton);
