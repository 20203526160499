import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/Modal';
import { BETSLIP_SELECTED_MARKETS_LIMIT } from 'constants/betslip';
import { setSelectedMarketsLimitNotification } from 'redux/modules/betslip';
import { getIsMarketsLimitNotification } from 'redux/modules/betslip/selectors';

import styles from './styles.module.scss';

const LimitPopup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAmountLimitReached = useSelector(getIsMarketsLimitNotification);

  const closePopup = () => dispatch(setSelectedMarketsLimitNotification(false));

  return (
    <Modal
      clickableBackground
      open={isAmountLimitReached}
      onClose={closePopup}
      title={t('betslip.labels.marketsLimit')}
      customClassNames={{ dialog: styles.dialog }}
    >
      <span>{t('betslip.labels.marketsLimitPopup', { n: BETSLIP_SELECTED_MARKETS_LIMIT })}</span>
    </Modal>
  );
};

export default LimitPopup;
