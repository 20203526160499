import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import useDeviceSettings from 'hooks/useDeviceSettings';
import { getMobileSettingsAlternativeLayOdds } from 'redux/modules/appConfigs/selectors';
import { hideMobileBetDetails } from 'redux/modules/myBets';
import { EBetSide, TMyBetsContentItem } from 'redux/modules/myBets/type';
import { shouldSwapColors } from 'utils/myBetsValues';

import styles from '../../styles.module.scss';

interface MobileDetailsContainerProps {
  bet: TMyBetsContentItem;
  children: ReactNode;
}
const MobileDetailsContainer = ({ children, bet }: MobileDetailsContainerProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const alternativeLayOdds = useSelector(getMobileSettingsAlternativeLayOdds);
  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();

  const handleGoBack = () => {
    dispatch(hideMobileBetDetails());
  };

  const betIndicatorClassName = (item: TMyBetsContentItem) => {
    if (shouldSwapColors(item, swapColorsFancyMarketsOnCricket)) {
      return item.side === EBetSide.Lay ? 'biab_bet-side-back' : 'biab_bet-side-lay';
    }

    return item.side === EBetSide.Lay ? 'biab_bet-side-lay' : 'biab_bet-side-back';
  };

  return (
    <div className="biab_table-holder">
      {!bet.asian && alternativeLayOdds && (
        <div className={classNames(styles.infoMsg, 'biab_info-msg')}>
          {t('account.mybets.messages.alternativeLayOdds')}
        </div>
      )}
      <table>
        <tbody>
          <tr>
            <td
              className={classNames(
                styles.betDetail__betIndicator,
                `biab_bet-indicator biab_active-bet`,
                betIndicatorClassName(bet)
              )}
            >
              <div className={classNames(styles.betDetail, 'biab_bet-detail')}>
                <table>
                  <tbody>{children}</tbody>
                </table>
                <div className={classNames('biab_account-control-holder', styles.betDetail__controlHolder)}>
                  <button
                    onClick={handleGoBack}
                    type="button"
                    className={classNames(styles.betDetail__controlHolder__goBackBtn, 'biab_account-go-back-bnt')}
                  >
                    {t('account.mybets.labels.goBack')}
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MobileDetailsContainer;
