import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import FeaturedMarkets from 'components/FeaturedMarkets';
import NextRaces from 'components/NextRaces';
import PopularLinks from 'components/PopularLinks';
import PopularMarket from 'components/PopularMarket';
import SEO from 'components/SEO';
import MiddleSectionPageSkeleton from 'components/Skeleton/MiddleSectionPageSkeleton';
import { ROUTER_BASE_URL } from 'constants/app';
import useElementSize from 'hooks/useElementSize';
import {
  getAppDevice,
  getHomePageType,
  getIsShowMarketOdd,
  getNextHorseEnabled
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getLoggedInLoading, getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { MetaPage } from 'redux/modules/meta/type';
import { getIsLoadingPopularLinks } from 'redux/modules/popular/selectors';
import { fetchPopularMarkets } from 'redux/modules/popularMarket';
import { getLoadingPopularMarkets } from 'redux/modules/popularMarket/selectors';
import { getDefaultLandingPage } from 'redux/modules/user/selectors';
import { HomePageType, PlacementPage } from 'types';

import FeaturedSports from './components/FeaturedSports';

const HOME_PAGE_TYPES = {
  [HomePageType.FEATURED_MARKETS]: () => <FeaturedMarkets />,
  [HomePageType.FEATURED_SPORTS]: () => <FeaturedSports />,
  [HomePageType.LINK]: () => null
};

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const device = useSelector(getAppDevice);
  const homePageType = useSelector(getHomePageType);
  const nextHorseEnabled = useSelector(getNextHorseEnabled);
  const isShowMarketOdds = useSelector(getIsShowMarketOdd);
  const defaultLandingPage = useSelector(getDefaultLandingPage);
  const loading = useSelector(getLoggedInLoading);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isPopularLinkLoading = useSelector(getIsLoadingPopularLinks);
  const isPopularMarketsLoading = useSelector(getLoadingPopularMarkets);

  const placementPage =
    homePageType === HomePageType.FEATURED_SPORTS
      ? PlacementPage.FEATURED_SPORTS_ODDS_BANNER
      : homePageType === HomePageType.FEATURED_MARKETS
      ? PlacementPage.FEATURED_MARKETS_ODDS_BANNER
      : undefined;

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height }));
    }
  });

  useEffect(() => {
    if (homePageType === HomePageType.LINK && defaultLandingPage) {
      const redirectUrl = defaultLandingPage.replace(window.environmentConfig?.rootPath || ROUTER_BASE_URL, '');
      navigate(redirectUrl, { replace: true, state: null });
    }
  }, [homePageType, defaultLandingPage]);

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (!loading && isShowMarketOdds) {
      dispatch(fetchPopularMarkets());
    }
  }, [loading, isLoggedIn, isShowMarketOdds]);

  return (
    <div ref={componentRef} className="skeleton_page_wrapper">
      {(isPopularLinkLoading || isPopularMarketsLoading) && (
        <MiddleSectionPageSkeleton itemsCount={12} withDelay={150} />
      )}
      <SEO page={MetaPage.HOME} />
      {device === Devices.DESKTOP && nextHorseEnabled && <NextRaces />}
      {device === Devices.MOBILE && <PopularLinks />}
      <PopularMarket page={placementPage} />
      {homePageType && HOME_PAGE_TYPES[homePageType]()}
    </div>
  );
};

export default HomePage;
