import { useDispatch } from 'react-redux';
import { Action, configureStore as createStore, ThunkDispatch } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import { Store } from 'redux';

import { rootReducer } from './reducers';
import { rootSaga } from './sagas';

const sagaMiddleware = createSagaMiddleware();

const configureStore = (): Store => {
  const store: Store = createStore({
    reducer: rootReducer,
    devTools: true,
    middleware: [thunk, sagaMiddleware]
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();
