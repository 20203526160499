import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { failureFetchPopularLinks, fetchPopularLinks, successFetchPopularLinks } from './index';
import { TPopularLinks } from './type';

function* getPopularLinks() {
  try {
    const response: TPopularLinks[] = yield call(api.popular.getPopularLinks);
    yield put(successFetchPopularLinks(response));
  } catch (error: any) {
    yield put(failureFetchPopularLinks(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchPopularLinks.type, getPopularLinks)]);
}
