import {
  DEFAULT_MAX_PRICE,
  DEFAULT_STEP_PRICE,
  getDefaultGamePriceFloor,
  getDefaultGamePriceIncrement,
  getDefaultGamePriceMax,
  getDefaultPriceFloor,
  getDefaultPriceIncrement,
  getDefaultPriceMax,
  VALIDATION_ERROR_BET_INVALID_PRICE,
  VALIDATION_ERROR_BET_INVALID_PRICE_LINE_MARKETS,
  VALIDATION_ERROR_BET_MAX_PRICE,
  VALIDATION_ERROR_BET_MAX_PRICE_LINE_MARKETS,
  VALIDATION_ERROR_BET_MIN_PRICE,
  VALIDATION_ERROR_BET_MIN_PRICE_LINE_MARKETS
} from 'constants/placement';
import { TPriceValidation } from 'redux/modules/betslip/type';
import { BetTypes, TPrice } from 'types/bets';
import { EActionTypes } from 'types/betslip';
import { EPriceLadderDescription, IMarket, TMarketLineRangeInfo, TPriceLadderDescription } from 'types/markets';
import {
  isCombinedTotalMarket,
  isCombinedTotalMarketType,
  isGameMarketType,
  isHandicapBettingType,
  isLineBettingType,
  isTotalPointsLine
} from 'utils/betslip';
import {
  adjustLineMarketValue,
  adjustValue,
  formatMinLineValue,
  getLineRangeParams,
  isValidValue,
  isValidValueInRange,
  round
} from 'utils/betValidation';

/**
 * Get min, max, step, unit values for price validation depending on market and betting types
 *
 * @param market
 */
export const getRangeInfo = (market: IMarket) => {
  const isCombinedTotal = isCombinedTotalMarket(market);
  const { lineRangeInfo, bettingType } = market.description;
  const { min, max, interval, isLineMarket: isLine } = getLineRangeParams({ lineRangeInfo, bettingType });
  const marketUnit = isLine || isCombinedTotal ? lineRangeInfo?.marketUnit : '';

  return {
    min: min,
    max: max,
    step: interval,
    marketUnit: marketUnit
  };
};

/**
 * Check if price ladder has CLASSIC type
 *
 * @param priceLadderDescription
 */
export const isClassicPriceLadder = (
  priceLadderDescription: EPriceLadderDescription = EPriceLadderDescription.CLASSIC
) => {
  return priceLadderDescription === EPriceLadderDescription.CLASSIC;
};

/**
 * Get price step depending on market and betting types, price ladder and line range information
 *
 * @param value
 * @param marketType
 * @param bettingType
 * @param priceLadderDescription
 * @param lineRangeInfo
 */
export const getPriceStepByType = (
  value: number,
  marketType: string,
  bettingType: string,
  lineRangeInfo?: TMarketLineRangeInfo | null,
  priceLadderDescription?: TPriceLadderDescription | null
) => {
  if (
    isHandicapBettingType(bettingType) &&
    !isCombinedTotalMarketType(marketType) &&
    !isClassicPriceLadder(priceLadderDescription?.type)
  ) {
    return DEFAULT_STEP_PRICE;
  } else if (isLineBettingType(bettingType)) {
    return lineRangeInfo?.interval ?? 0;
  } else if (isGameMarketType(marketType)) {
    return getDefaultGamePriceIncrement(value);
  } else {
    return getDefaultPriceIncrement(value);
  }
};

/**
 * Get min price value depending on betting type.
 *
 * @param value
 * @param marketType
 * @param bettingType
 * @param lineRangeInfo
 */
export const getPriceMinByType = (
  value: number,
  marketType: string,
  bettingType: string,
  lineRangeInfo?: TMarketLineRangeInfo | null
) => {
  if (isLineBettingType(bettingType)) {
    return formatMinLineValue(lineRangeInfo?.minUnitValue ?? 0);
  } else if (isGameMarketType(marketType)) {
    return getDefaultGamePriceFloor(value);
  } else {
    return getDefaultPriceFloor(value);
  }
};

/**
 * Get max price value depending on betting type
 *
 * @param value
 * @param marketType
 * @param bettingType
 * @param lineRangeInfo
 */
export const getPriceMaxByType = (
  value: number,
  marketType: string,
  bettingType: string,
  lineRangeInfo?: TMarketLineRangeInfo | null
) => {
  if (isLineBettingType(bettingType)) {
    return lineRangeInfo?.maxUnitValue ?? 0;
  } else if (isHandicapBettingType(bettingType)) {
    return DEFAULT_MAX_PRICE;
  } else if (isGameMarketType(marketType)) {
    return getDefaultGamePriceMax(value);
  } else {
    return getDefaultPriceMax(value);
  }
};

/**
 * Round market prices for line market that is not total points line.
 *
 * @param prices
 * @param marketType
 * @param bettingType
 */

const THOUSAND = 1000;
const ONE_HUNDRED_THOUSANDS = 100000;
const MILLION = 1000000;
const TEN_MILLIONS = 10000000;
const BILLION = 1000000000;

export const getPricesByMarketType = (prices: number | undefined, marketType: string, bettingType: string) => {
  return prices && isLineBettingType(bettingType) && !isTotalPointsLine(marketType) ? prices || 0 : prices;
};

export const getDisplayFormatPrice = ({
  displayFormat,
  price,
  indianNumberSystemEnabled,
  ignoreFlexibleDisplayFormat
}: {
  displayFormat?: number | null;
  price: number;
  indianNumberSystemEnabled: boolean;
  ignoreFlexibleDisplayFormat?: boolean;
}) => {
  if (typeof displayFormat === 'number' && displayFormat > 1) {
    return { price: Number((price / displayFormat).toFixed(1)), formatLabelKey: null, isDivided: true };
  }

  if (displayFormat === null && !ignoreFlexibleDisplayFormat) {
    if (indianNumberSystemEnabled) {
      if (price >= BILLION) {
        return {
          price: Number((price / BILLION).toFixed(1)),
          formatLabelKey: 'displayFormat.symbol.arab',
          isDivided: true
        };
      }

      if (price >= TEN_MILLIONS) {
        return {
          price: Number((price / TEN_MILLIONS).toFixed(1)),
          formatLabelKey: 'displayFormat.symbol.crore',
          isDivided: true
        };
      }

      if (price >= ONE_HUNDRED_THOUSANDS) {
        return {
          price: Number((price / ONE_HUNDRED_THOUSANDS).toFixed(1)),
          formatLabelKey: 'displayFormat.symbol.lakh',
          isDivided: true
        };
      }

      if (price >= THOUSAND) {
        return {
          price: Number((price / THOUSAND).toFixed(1)),
          formatLabelKey: 'displayFormat.symbol.thousands',
          isDivided: true
        };
      }
    }

    if (price >= BILLION) {
      return {
        price: Number((price / BILLION).toFixed(1)),
        formatLabelKey: 'displayFormat.symbol.billions',
        isDivided: true
      };
    }

    if (price >= MILLION) {
      const value = Number((price / MILLION).toFixed(1));

      if (value === THOUSAND) {
        return {
          price: 1,
          formatLabelKey: 'displayFormat.symbol.billions',
          isDivided: true
        };
      }

      return {
        price: value,
        formatLabelKey: 'displayFormat.symbol.millions',
        isDivided: true
      };
    }

    if (price >= THOUSAND) {
      const value = Number((price / THOUSAND).toFixed(1));

      if (value === THOUSAND) {
        return {
          price: 1,
          formatLabelKey: 'displayFormat.symbol.millions',
          isDivided: true
        };
      }

      return {
        price: value,
        formatLabelKey: 'displayFormat.symbol.thousands',
        isDivided: true
      };
    }
  }

  return { price, formatLabelKey: null, isDivided: false };
};

export const addSubstrPricePrice = (type: string, priceVal: TPrice = 0, stepVal: number | string) =>
  round(type === EActionTypes.ADD ? +(priceVal || 0) + +stepVal : +(priceVal || 0) - +stepVal);

/**
 * Add or substr price value depending on step price ladder
 *
 * @param price
 * @param type
 * @param marketType
 * @param bettingType
 * @param lineRangeInfo
 * @param priceLadderDescription
 */
export const recalculatePriceByStep = ({
  price,
  type,
  marketType,
  bettingType,
  lineRangeInfo,
  priceLadderDescription
}: {
  price: TPrice;
  type: EActionTypes;
  marketType: string;
  bettingType: string;
  lineRangeInfo?: TMarketLineRangeInfo | null;
  priceLadderDescription?: TPriceLadderDescription | null;
}) => {
  const step =
    getPriceStepByType(+(price || 0), marketType, bettingType, lineRangeInfo, priceLadderDescription) ??
    DEFAULT_STEP_PRICE;
  const newPrice = addSubstrPricePrice(type, price, step);
  const newStep =
    getPriceStepByType(newPrice, marketType, bettingType, lineRangeInfo, priceLadderDescription) ?? DEFAULT_STEP_PRICE;
  const newMin = getPriceMinByType(newStep, marketType, bettingType, lineRangeInfo) ?? DEFAULT_STEP_PRICE;

  let updatedPrice: TPrice = addSubstrPricePrice(type, price, newStep);

  if (!isValidValue(updatedPrice, newStep, newMin)) {
    updatedPrice = adjustValue(price || 0, newStep, getPriceMinByType(newStep, marketType, bettingType, lineRangeInfo));
  }

  return +(updatedPrice || 0);
};

export const getPriceMetadata = ({
  price,
  marketType,
  bettingType,
  lineRangeInfo,
  priceLadderDescription
}: {
  price: TPrice;
  marketType: string;
  bettingType: string;
  lineRangeInfo?: TMarketLineRangeInfo | null;
  priceLadderDescription?: TPriceLadderDescription | null;
}) => {
  const value = price !== undefined && price !== '' ? Number(price) : 0;
  const step = getPriceStepByType(value, marketType, bettingType, lineRangeInfo, priceLadderDescription);
  const min = getPriceMinByType(step, marketType, bettingType, lineRangeInfo);
  const max = getPriceMaxByType(step, marketType, bettingType, lineRangeInfo);

  return {
    value,
    step,
    min,
    max
  };
};

export const validatePrice = ({
  price,
  betType,
  bettingType,
  marketType,
  marketUnits = '',
  lineRangeInfo,
  priceLadderDescription
}: {
  price: TPrice;
  betType: BetTypes;
  bettingType: string;
  marketType: string;
  marketUnits?: string;
  lineRangeInfo?: TMarketLineRangeInfo | null;
  priceLadderDescription?: TPriceLadderDescription | null;
}): TPriceValidation => {
  const { value, step, min, max } = getPriceMetadata({
    price,
    marketType,
    bettingType,
    lineRangeInfo,
    priceLadderDescription
  });
  const isLineMarket = isLineBettingType(bettingType);
  const isValidBet = isLineMarket ? isValidValueInRange(value, min, step) : isValidValue(value, step, min);
  const isRound = false;
  const isEmptyValue = price === '';

  if (isEmptyValue) {
    return { validValue: '', errorMessage: null, isValid: false };
  } else if (!isValidBet) {
    if ((value && value < min) || !value) {
      return {
        validValue: min,
        errorMessage: {
          text: `${isLineMarket ? VALIDATION_ERROR_BET_MIN_PRICE_LINE_MARKETS : VALIDATION_ERROR_BET_MIN_PRICE}`,
          params: { units: marketUnits, min }
        },
        isValid: false
      };
    } else {
      const adjustedValue = isLineMarket
        ? adjustLineMarketValue({ value, min, max, step, betType, isRound })
        : adjustValue(value, step, min, betType);

      return {
        validValue: Number(adjustedValue) > max ? max : Number(adjustedValue) < min ? min : adjustedValue,
        errorMessage: {
          text: `${
            isLineMarket ? VALIDATION_ERROR_BET_INVALID_PRICE_LINE_MARKETS : VALIDATION_ERROR_BET_INVALID_PRICE
          }`,
          params: { step: step, units: marketUnits, min, max }
        },
        isValid: false
      };
    }
  } else if (value > max) {
    return {
      validValue: max,
      errorMessage: {
        text: `${isLineMarket ? VALIDATION_ERROR_BET_MAX_PRICE_LINE_MARKETS : VALIDATION_ERROR_BET_MAX_PRICE}`,
        params: { units: marketUnits, max: max }
      },
      isValid: false
    };
  } else {
    return { validValue: value, errorMessage: null, isValid: true };
  }
};
