import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { ASIAN_VIEW_PAGE_PARAM } from 'constants/asianView';
import useDevice from 'hooks/useDevice';
import { getIsPropertiesLoaded } from 'redux/modules/appConfigs/selectors';

interface Params {
  onChangePage?: (page: number) => void;
  totalPages: number;
  isPaginationEnabled: boolean;
  pageParamName?: string;
  resetPageOnFirstRender?: boolean;
  resetPageDependency?: number | string | boolean;
}
const history = createBrowserHistory();

const usePagination = ({
  onChangePage = () => {},
  totalPages,
  isPaginationEnabled,
  pageParamName = ASIAN_VIEW_PAGE_PARAM,
  resetPageOnFirstRender = false,
  resetPageDependency
}: Params) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);

  const { isMobile } = useDevice();

  const resetPageDependencyRef = useRef(resetPageDependency);

  const pageParam = searchParams.get(pageParamName);
  const paginationPage = Number(pageParam);

  const scrollToTop = () => {
    if (isMobile) {
      window.scrollTo({ top: 0 });
    }
  };

  useEffect(() => {
    if (isPropertiesLoaded) {
      if (!isPaginationEnabled) {
        if (pageParam) {
          searchParams.delete(pageParamName);
          setSearchParams(searchParams, { replace: true });
        }
      } else if (pageParam === null) {
        searchParams.set(pageParamName, '0');
        setSearchParams(searchParams, { replace: true });
      }
    }
  }, [isPaginationEnabled, isPropertiesLoaded]);

  useEffect(() => {
    if (resetPageOnFirstRender && isPaginationEnabled) {
      searchParams.set(pageParamName, '0');
      setSearchParams(searchParams, { replace: true });
    }
  }, [isPaginationEnabled, resetPageOnFirstRender]);

  useEffect(() => {
    if (
      resetPageDependency !== undefined &&
      paginationPage !== 0 &&
      isPaginationEnabled &&
      resetPageDependencyRef.current !== resetPageDependency
    ) {
      searchParams.set(pageParamName, '0');
      setSearchParams(searchParams);

      resetPageDependencyRef.current = resetPageDependency;
    }
  }, [resetPageDependency]);

  useEffect(() => {
    if (isPaginationEnabled) {
      history.listen(update => {
        if (update.action === 'POP') {
          const params = new URLSearchParams(update.location.search);
          const page = params.get(pageParamName);

          if (page) {
            onChangePage(Number(page));
            scrollToTop();
          }
        }
      });
    }
  }, [isPaginationEnabled]);

  const changePage = (newPage: number, replace?: boolean) => {
    searchParams.set(pageParamName, String(newPage));
    setSearchParams(searchParams, { replace });
    scrollToTop();
  };

  const handleClickPage = (newPage: number) => {
    changePage(newPage);
    onChangePage(newPage);
  };

  const handleClickNextPage = () => {
    if (paginationPage + 1 !== totalPages) {
      changePage(paginationPage + 1);
      onChangePage(paginationPage + 1);
    }
  };

  const handleClickPrevPage = () => {
    if (paginationPage !== 0) {
      changePage(paginationPage - 1);
      onChangePage(paginationPage - 1);
    }
  };

  const handleClickFirstPage = () => {
    changePage(0);
    onChangePage(0);
  };

  const handleClickLastPage = () => {
    changePage(totalPages - 1);
    onChangePage(totalPages - 1);
  };

  return {
    onClickPage: handleClickPage,
    onClickNextPage: handleClickNextPage,
    onClickPrevPage: handleClickPrevPage,
    onClickFirstPage: handleClickFirstPage,
    onClickLastPage: handleClickLastPage,
    page: paginationPage,
    changePage
  };
};

export default usePagination;
