import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ResponsibleBettingAlert from 'components/ResponsibleBetting/components/ResponsibleBettingAlert';
import ResponsibleBettingBtn from 'components/ResponsibleBetting/components/ResponsibleBettingBtn';
import ResponsibleBettingDropdown from 'components/ResponsibleBetting/components/ResponsibleBettingDropdown';
import ResponsibleBettingInputField from 'components/ResponsibleBetting/components/ResponsibleBettingInputField';
import ResponsibleBettingMobileDropdown from 'components/ResponsibleBetting/components/ResponsibleBettingMobileDropdown';
import commonStyles from 'components/ResponsibleBetting/styles.module.scss';
import { CombinedResponsibleBettingTimeUnit } from 'redux/modules/responsibleBetting/type';
import { DropdownItem } from 'types';
import { ResponsibleBettingAlertTypes, ResponsibleBettingButtonTypes } from 'types/responsibleBetting';

interface ResponsibleBettingCreateLimitProps {
  isMobile: boolean;
  error: string | null;
  selectedTimeUnit: CombinedResponsibleBettingTimeUnit;
  selectedLimit: number | null;
  inputLabel: string;
  inputPlaceholder: string;
  dropdownLabel: string;
  data: DropdownItem[];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  onApply: () => void;
  onSelectOption: (option: DropdownItem) => void;
}

const ResponsibleBettingCreateLimit = ({
  isMobile,
  error,
  selectedLimit,
  selectedTimeUnit,
  inputLabel,
  inputPlaceholder,
  dropdownLabel,
  data,
  onChange,
  onCancel,
  onApply,
  onSelectOption
}: ResponsibleBettingCreateLimitProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(commonStyles.content, {
        [commonStyles.content__mobile]: isMobile
      })}
    >
      <div
        className={classNames(commonStyles.inputs, {
          [commonStyles.inputs__with_error]: !!error,
          [commonStyles.inputs__mobile]: isMobile
        })}
      >
        <ResponsibleBettingInputField
          value={selectedLimit}
          label={inputLabel}
          placeholder={inputPlaceholder}
          onChange={onChange}
          isMobile={isMobile}
          error={!!error}
        />
        {isMobile && error && (
          <div
            className={classNames(commonStyles.validationAlertWrapper, {
              [commonStyles.validationAlertWrapper__mobile]: isMobile
            })}
          >
            <ResponsibleBettingAlert type={ResponsibleBettingAlertTypes.Error}>
              <span dangerouslySetInnerHTML={{ __html: unescape(error) }} />
            </ResponsibleBettingAlert>
          </div>
        )}
        {isMobile ? (
          <ResponsibleBettingMobileDropdown
            data={data}
            onSelectOption={onSelectOption}
            selectedValue={selectedTimeUnit}
            label={dropdownLabel}
          />
        ) : (
          <div className={commonStyles.container}>
            <label className={commonStyles.label}>{t('responsibleBetting.labels.limitPeriod')}</label>
            <ResponsibleBettingDropdown onChangeOption={onSelectOption} data={data} value={selectedTimeUnit} />
          </div>
        )}
      </div>
      {!isMobile && error && (
        <ResponsibleBettingAlert type={ResponsibleBettingAlertTypes.Error}>
          <span dangerouslySetInnerHTML={{ __html: unescape(error) }} />
        </ResponsibleBettingAlert>
      )}

      <div className={commonStyles.divider} />

      <div
        className={classNames(commonStyles.controllersContainer, {
          [commonStyles.container__mobile]: isMobile
        })}
      >
        <div
          className={classNames(commonStyles.controllers, {
            [commonStyles.controllers__mobile]: isMobile
          })}
        >
          <ResponsibleBettingBtn
            label={t('responsibleBetting.buttons.cancel')}
            onClick={onCancel}
            isMobile={isMobile}
            type={ResponsibleBettingButtonTypes.Secondary}
          />
          <ResponsibleBettingBtn
            label={t('responsibleBetting.buttons.apply')}
            onClick={onApply}
            isMobile={isMobile}
            disabled={!selectedLimit || !selectedTimeUnit || !!error}
            type={ResponsibleBettingButtonTypes.Primary}
          />
        </div>
      </div>
    </div>
  );
};

export default ResponsibleBettingCreateLimit;
