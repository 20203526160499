import { useState } from 'react';
import BodyClassName from 'react-body-classname';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import Modal from 'components/Modal';
import Rules from 'components/Rules';
import { fetchGameRules } from 'redux/modules/games';
import { getCurrentGameName, getGameRules, getGameRulesLoading } from 'redux/modules/games/selectors';

import styles from '../../styles.module.scss';

const MarketRules = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { gameId = '' } = useParams();

  const gameRules = useSelector(getGameRules);
  const loading = useSelector(getGameRulesLoading);
  const gameName = useSelector(getCurrentGameName);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenRulesModal = () => {
    setIsOpen(true);
    if (!gameRules) {
      dispatch(fetchGameRules(gameId));
    }
  };

  return (
    <>
      <Rules onClick={handleOpenRulesModal} />
      <Modal
        customClassNames={{ dialog: styles.rulesModalDialog, body: styles.rulesModalBody }}
        clickableBackground
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={loading ? t('marketRules.loading') : `${gameName || ''} - ${t('rules.title')}`}
      >
        {gameRules && <div dangerouslySetInnerHTML={{ __html: gameRules.rules }} />}
      </Modal>
      <BodyClassName className={classNames({ disableScroll: isOpen })} />
    </>
  );
};

export default MarketRules;
