import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { THeaderNavigation } from 'redux/modules/headerNavigation/type';

const initialState: THeaderNavigation = {
  rightMenuWidth: 0,
  leftMenuItemsWidth: {},
  asianSearchWidth: 0,
  moreBtnWidth: 0
};

const slice = createSlice({
  name: SLICES_NAMES.OBSERVER,
  initialState,
  reducers: {
    setElementWidth: (state, { payload }: PayloadAction<{ key: number; width: number }>) => {
      state.leftMenuItemsWidth[payload.key] = payload.width;
    },
    setRightMenuWidth: (state, { payload }: PayloadAction<number>) => {
      state.rightMenuWidth = payload;
    },
    setAsianSearchWidth: (state, { payload }: PayloadAction<number>) => {
      state.asianSearchWidth = payload;
    },
    setMoreBtnWidth: (state, { payload }: PayloadAction<number>) => {
      state.moreBtnWidth = payload;
    }
  }
});

export const { setElementWidth, setRightMenuWidth, setAsianSearchWidth, setMoreBtnWidth } = slice.actions;
export default slice.reducer;
