import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import CashOutRulesModal from 'components/CashOutRulesModal';
import { asianViewComponents as branding } from 'constants/branding';
import { PagesFromBackend } from 'redux/modules/pages/types';

import styles from './styles.module.scss';

interface PartialTabProps {
  partial: number | undefined;
  minPercentage: number; // TODO check why this value isn't used
  maxPercentage: number; // TODO check why this value isn't used
  isDisabled: boolean;
  setPartial: (partial?: number) => void;
  onHover: () => void;
  onLeave: () => void;
  isAsianBetSlip?: boolean;
  isAVMobileCashOutPage?: boolean;
  isAVMobileBetList?: boolean;
  isAsianView?: boolean;
  isMobile?: boolean;
  isEvent?: boolean;
}

const PartialTab = ({
  setPartial,
  isDisabled,
  onHover,
  onLeave,
  partial,
  isAsianBetSlip = false,
  isAVMobileCashOutPage = false,
  isAVMobileBetList,
  isMobile,
  isEvent,
  isAsianView
}: PartialTabProps) => {
  const { t } = useTranslation();

  const [openPartialCashOutModal, setOpenPartialCashOutModal] = useState(false);

  const handler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isDisabled) {
      setPartial(parseFloat(parseInt(e.target.value).toFixed(2)));
    }
  };

  if (isAsianBetSlip) {
    return (
      <>
        <div className={styles.betslip}>
          <div className={styles.betslip__labelLine}>
            <span className="biab_min">{t('cashout.page.labels.min')}</span>
            <span className="biab_max">{t('cashout.page.labels.max')}</span>
          </div>
          <div className={styles.betslip__controlLine}>
            <input
              type="range"
              id="partial"
              name="partial"
              value={partial}
              min={1}
              max={100}
              className={classNames(styles.input, styles.betslip__input, branding.INPUT)}
              onChange={handler}
              onMouseMove={onHover}
              onMouseLeave={onLeave}
              style={{
                backgroundSize: `${partial}% 100%`
              }}
            />
            <i
              className={classNames(
                'fa fas fa-question-circle biab_help-icon',
                styles.questionIcon,
                styles.betslip__questionIcon
              )}
              onClick={() => setOpenPartialCashOutModal(true)}
            />
          </div>
        </div>
        <CashOutRulesModal
          link={PagesFromBackend.PARTIAL_CASH_OUT_RULES}
          openModal={openPartialCashOutModal}
          setOpenModal={setOpenPartialCashOutModal}
        />
      </>
    );
  }

  const isCoMobPageStyles = isAVMobileCashOutPage || isAVMobileBetList || (isAsianView && isEvent && isMobile);

  return (
    <div
      className={classNames(styles.container, {
        [styles.coMobPage]: isCoMobPageStyles
      })}
    >
      <span
        className={classNames('biab_min', {
          [styles.coMobPage__min]: isCoMobPageStyles
        })}
      >
        {t('cashout.page.labels.min')}
      </span>

      <div
        className={classNames('biab_partial-cashout-slider', styles.slider, {
          [styles.coMobPage__range__container]: isCoMobPageStyles
        })}
      >
        <input
          type="range"
          id="partial"
          name="partial"
          value={partial}
          min={1}
          max={100}
          className={classNames(styles.input, {
            [styles.coMobPage__range]: isCoMobPageStyles
          })}
          onChange={handler}
          onMouseMove={onHover}
          onMouseLeave={onLeave}
          style={{
            backgroundSize: `${partial}% 100%`
          }}
        />
        {isDisabled && <div className={styles.disabled} />}
      </div>
      <span
        className={classNames('biab_max', {
          [styles.coMobPage__max]: isCoMobPageStyles
        })}
      >
        {t('cashout.page.labels.max')}
      </span>

      <i
        className={classNames('fa fas fa-question-circle biab_help-icon', styles.questionIcon)}
        onClick={() => setOpenPartialCashOutModal(true)}
      />
      <CashOutRulesModal
        link={PagesFromBackend.PARTIAL_CASH_OUT_RULES}
        openModal={openPartialCashOutModal}
        setOpenModal={setOpenPartialCashOutModal}
      />
    </div>
  );
};

export default PartialTab;
