import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';

import { LoginPopup, TPopup } from './type';

const initialState: TPopup = {
  loginPopup: {
    type: ''
  },
  isShowingCustomLinkPopup: false
};

const slice = createSlice({
  name: SLICES_NAMES.LOGIN_POP_UP,
  initialState,
  reducers: {
    setLoginPopup: (state, { payload }: PayloadAction<LoginPopup>) => {
      state.loginPopup = payload;
    },
    setShowingCustomPopularLink: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowingCustomLinkPopup = payload;
    }
  }
});

export const { setLoginPopup, setShowingCustomPopularLink } = slice.actions;

export default slice.reducer;
