import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { pagination as branding } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import { generateArray } from 'utils/general';

import styles from './styles.module.scss';

const DEFAULT_MAX_PAGES = 11;

const getPageList = (page: number, totalPages: number, maxPages: number) => {
  const list = generateArray(totalPages, 1);

  if (list.length < maxPages + 1) {
    return list;
  }

  if (page < maxPages / 2) {
    return list.slice(0, maxPages);
  }

  if (totalPages - page > Math.floor(maxPages / 2)) {
    return list.slice(
      page - Math.floor(maxPages / 2),
      page + Math.ceil(maxPages / 2) > totalPages ? totalPages : page + Math.ceil(maxPages / 2)
    );
  }

  return list.slice(
    totalPages - maxPages,
    page + Math.ceil(maxPages / 2) > totalPages ? totalPages : page + Math.ceil(maxPages / 2)
  );
};

const MOBILE_LEFT_AND_RIGHT_PADDINGS = 48;
const MOBILE_LEFT_AND_RIGHT_BORDER_WIDTHS = 2;
const MOBILE_LEFT_AND_RIGHT_DISTANCE_BETWEEN_ARROWS = 70;
const MOBILE_LEFT_AND_RIGHT_EXTERNAL_PADDINGS = 12;
const SPACE_BETWEEN_PAGES_NUMBERS = 5;
const MOBILE_PAGE_NUMBER_WIDTH = 42;

const getMaxPagesValueByDecreasingSpace = (availableSpace: number, maxPages: number): number => {
  let maxPagesValue = maxPages;

  do {
    maxPagesValue -= 1;
  } while (
    maxPagesValue * MOBILE_PAGE_NUMBER_WIDTH + SPACE_BETWEEN_PAGES_NUMBERS * (maxPagesValue - 1) >
    availableSpace
  );

  return maxPagesValue;
};

const getMaxPagesValueByIncreasingSpace = (availableSpace: number) => {
  let additionalPages = 0;

  while (
    (additionalPages + 1) * MOBILE_PAGE_NUMBER_WIDTH + SPACE_BETWEEN_PAGES_NUMBERS * additionalPages <
    availableSpace
  ) {
    additionalPages += 1;
  }

  return additionalPages;
};

interface PaginationProps {
  totalPages: number;
  /**
   * The value of the first page should be 0
   */
  page: number;
  onClickPage: (page: number) => void;
  onClickNextPage: () => void;
  onClickPrevPage: () => void;
  /**
   * Requires only if page or component has desktop version
   */
  onClickLastPage?: () => void;
  /**
   * Requires only if page or component has desktop version
   */
  onClickFirstPage?: () => void;
  mobileMode?: boolean;
  paginationClassName?: string;
  leftIcon?: string;
  rightIcon?: string;
  leftIconClassName?: string;
  rightIconClassName?: string;
  maxPages?: number;
}

const Pagination = ({
  totalPages,
  page,
  onClickLastPage,
  onClickNextPage,
  onClickPrevPage,
  onClickFirstPage,
  onClickPage,
  mobileMode = false,
  paginationClassName = '',
  leftIcon = '',
  rightIcon = '',
  leftIconClassName = '',
  rightIconClassName,
  maxPages
}: PaginationProps) => {
  const { t } = useTranslation();

  const [maxPagesValue, setMaxPagesValue] = useState(maxPages ?? DEFAULT_MAX_PAGES);

  const paginationRef = useRef<HTMLDivElement>(null);
  const pagesRef = useRef<HTMLDivElement>(null);
  const pagesContainerRef = useRef<HTMLDivElement>(null);
  const leftActionsRef = useRef<HTMLDivElement>(null);
  const rightActionsRef = useRef<HTMLDivElement>(null);
  const maxPagesRef = useRef<number>(maxPages ?? DEFAULT_MAX_PAGES);

  const { isMobile } = useDevice();

  const isMobileMode = mobileMode || isMobile;
  const isLastFade = totalPages > maxPagesValue && page < totalPages - Math.ceil(maxPagesValue / 2);
  const isFirstFade = totalPages > maxPagesValue && page > Math.floor(maxPagesValue / 2);
  const pageList = useMemo(() => getPageList(page, totalPages, maxPagesValue), [page, totalPages, maxPagesValue]);

  useEffect(() => {
    if (typeof maxPages === 'number' && maxPages !== maxPagesValue) {
      setMaxPagesValue(maxPages);
    }
  }, [maxPages]);

  useEffect(() => {
    if (isMobile) {
      const maxPagesByDefault = maxPages ?? DEFAULT_MAX_PAGES;

      const handleMaxPagesValue = () => {
        if (
          pagesRef.current &&
          leftActionsRef.current &&
          rightActionsRef.current &&
          paginationRef.current &&
          pagesContainerRef.current
        ) {
          const isNotEnoughSpace =
            pagesRef.current.clientWidth +
              leftActionsRef.current.clientWidth +
              rightActionsRef.current.clientWidth +
              MOBILE_LEFT_AND_RIGHT_PADDINGS +
              MOBILE_LEFT_AND_RIGHT_BORDER_WIDTHS +
              MOBILE_LEFT_AND_RIGHT_DISTANCE_BETWEEN_ARROWS +
              MOBILE_LEFT_AND_RIGHT_EXTERNAL_PADDINGS >
            paginationRef.current.clientWidth;

          if (isNotEnoughSpace) {
            const availableSpaceForPagesNumbers =
              paginationRef.current.clientWidth -
              MOBILE_LEFT_AND_RIGHT_EXTERNAL_PADDINGS -
              MOBILE_LEFT_AND_RIGHT_BORDER_WIDTHS -
              MOBILE_LEFT_AND_RIGHT_PADDINGS -
              rightActionsRef.current.clientWidth -
              leftActionsRef.current.clientWidth -
              MOBILE_LEFT_AND_RIGHT_DISTANCE_BETWEEN_ARROWS;

            const maxPagesValueBySpace = getMaxPagesValueByDecreasingSpace(
              availableSpaceForPagesNumbers,
              maxPagesByDefault
            );

            if (maxPagesValueBySpace !== maxPagesRef.current) {
              const newMaxPagesValue =
                maxPagesValueBySpace > maxPagesByDefault ? maxPagesByDefault : maxPagesValueBySpace;

              setMaxPagesValue(newMaxPagesValue);
              maxPagesRef.current = newMaxPagesValue;
            }
          } else {
            const currentPagesNumbersSpace =
              maxPagesRef.current * MOBILE_PAGE_NUMBER_WIDTH + SPACE_BETWEEN_PAGES_NUMBERS * (maxPagesRef.current - 1);
            const availableSpaceToAddMorePages =
              pagesContainerRef.current.clientWidth -
              MOBILE_LEFT_AND_RIGHT_DISTANCE_BETWEEN_ARROWS -
              currentPagesNumbersSpace;

            if (
              availableSpaceToAddMorePages >= MOBILE_PAGE_NUMBER_WIDTH + SPACE_BETWEEN_PAGES_NUMBERS &&
              maxPagesRef.current < maxPagesByDefault
            ) {
              const additionalPages = getMaxPagesValueByIncreasingSpace(availableSpaceToAddMorePages);

              setMaxPagesValue(prevState => {
                const newValue = prevState + additionalPages;

                return newValue > maxPagesByDefault ? maxPagesByDefault : newValue;
              });

              if (maxPagesRef.current + additionalPages > maxPagesByDefault) {
                maxPagesRef.current = maxPagesByDefault;
              } else {
                maxPagesRef.current += additionalPages;
              }
            }
          }
        }
      };

      handleMaxPagesValue();

      window.addEventListener('resize', handleMaxPagesValue);

      return () => {
        window.removeEventListener('resize', handleMaxPagesValue);
      };
    }
  }, [isMobile, maxPages]);

  const isFirstPageDisabled = page === 0;
  const isLastPageDisabled = page === totalPages - 1;

  return (
    <div
      ref={paginationRef}
      className={classNames({
        [styles.pagination__mobile__wrapper]: isMobile
      })}
    >
      {!isMobileMode && <div className={styles.pagination__padding} />}
      <div
        className={classNames(styles.pagination, paginationClassName, branding.PAGINATION, {
          [styles.pagination__mobile]: isMobile
        })}
      >
        <div className={styles.pagination__leftActions} ref={leftActionsRef}>
          {!isMobileMode && (
            <button
              onClick={onClickFirstPage}
              disabled={isFirstPageDisabled}
              className={classNames(styles.pagination__action, styles.pagination__action__first, {
                [branding.INACTIVE_LINK]: isFirstPageDisabled
              })}
            >
              <i className={classNames('av-icon av-icon-union-chevron-left', styles.pagination__action__icon)} />
              <span className={styles.pagination__action__text}>{t('pagination.first')}</span>
            </button>
          )}
          <button
            onClick={onClickPrevPage}
            disabled={isFirstPageDisabled}
            className={classNames(styles.pagination__action, { [branding.INACTIVE_LINK]: isFirstPageDisabled })}
          >
            <i
              className={classNames(
                styles.pagination__action__icon,
                {
                  'fa2 fa2-arrow-left': !leftIcon,
                  [leftIcon]: !!leftIcon,
                  [styles.pagination__action__icon__mobile]: isMobile
                },
                leftIconClassName
              )}
            />
            {!isMobileMode && <span className={styles.pagination__action__text}>{t('pagination.previous')}</span>}
          </button>
        </div>
        <div className={styles.pagination__pagesContainer} ref={pagesContainerRef}>
          <div
            ref={pagesRef}
            className={classNames(styles.pagination__pagesWrapper, {
              [styles.pagination__pagesWrapper__lastFade]: isLastFade,
              [styles.pagination__pagesWrapper__firstFade]: isFirstFade,
              [styles.pagination__pagesWrapper__mobile]: isMobile,
              [styles.pagination__pagesWrapper__lastFade__mobile]: isMobile && isLastFade,
              [styles.pagination__pagesWrapper__firstFade__mobile]: isMobile && isFirstFade
            })}
          >
            {pageList.map(value => {
              return (
                <button
                  key={value}
                  className={classNames(styles.pagination__page, branding.PAGINATION_BTN, {
                    [styles.pagination__page__active]: value - 1 === page,
                    [styles.pagination__page__mobile]: isMobile,
                    [branding.SELECTED]: value - 1 === page
                  })}
                  onClick={() => onClickPage(value - 1)}
                >
                  {value}
                </button>
              );
            })}
          </div>
        </div>
        <div className={styles.pagination__rightActions} ref={rightActionsRef}>
          <button
            onClick={onClickNextPage}
            disabled={isLastPageDisabled}
            className={classNames(styles.pagination__action, { [branding.INACTIVE_LINK]: isLastPageDisabled })}
          >
            {!isMobileMode && <span className={styles.pagination__action__text}>{t('pagination.next')}</span>}
            <i
              className={classNames(
                styles.pagination__action__icon,
                {
                  'fa2 fa2-arrow-right': !rightIcon,
                  [rightIcon]: !!rightIcon,
                  [styles.pagination__action__icon__mobile]: isMobile
                },
                rightIconClassName
              )}
            />
          </button>
          {!isMobileMode && (
            <button
              onClick={onClickLastPage}
              disabled={isLastPageDisabled}
              className={classNames(styles.pagination__action, styles.pagination__action__last, {
                [branding.INACTIVE_LINK]: isLastPageDisabled
              })}
            >
              <span className={styles.pagination__action__text}>{t('pagination.last')}</span>
              <i className={classNames('av-icon av-icon-union-chevron-right', styles.pagination__action__icon)} />
            </button>
          )}
        </div>
      </div>
      {!isMobileMode && <div className={styles.pagination__padding} />}
    </div>
  );
};

export default Pagination;
