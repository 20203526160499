import { all, put, takeLatest } from 'redux-saga/effects';
import { call } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { failureFetchRaces, fetchRaces, successFetchRaces } from './index';
import { TRace } from './type';

function* getRaceWorker({ payload }: ReturnType<typeof fetchRaces>) {
  try {
    const response: TRace[] = yield call(api.racing.horseRacing, payload.sportId, payload.timeRange);
    yield put(successFetchRaces(response));
  } catch (error: any) {
    yield put(failureFetchRaces(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchRaces.type, getRaceWorker)]);
}
