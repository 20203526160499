import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getCurrentEventBetsByType } from 'redux/modules/currentBets/selectors';
import { MatchTypes } from 'types/bets';

import styles from './styles.module.scss';

interface SearchTotalBetsProps {
  eventId: string;
}

function SearchTotalBets({ eventId }: SearchTotalBetsProps) {
  const unmatchedBets = useSelector(getCurrentEventBetsByType(eventId, MatchTypes.UNMATCHED));
  const matchedBets = useSelector(getCurrentEventBetsByType(eventId, MatchTypes.MATCHED));
  const { t } = useTranslation();
  const numberOfBetsByType = [];

  if (matchedBets.length > 0) {
    numberOfBetsByType.push(matchedBets.length + ' ' + t('search.matched'));
  }

  if (unmatchedBets.length > 0) {
    numberOfBetsByType.push(unmatchedBets.length + ' ' + t('search.unmatched'));
  }

  return (
    <div className={classNames('biab_total-bets', styles.search__totalBets)}>
      {t('search.totalBets') + ': ' + numberOfBetsByType.join(' - ')}
    </div>
  );
}

export default SearchTotalBets;
