import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import api from 'redux/api/methods';
import { TFailureActionPayload } from 'types';

import { PageContentType, TPage } from './types';

const initialState: TPage = {
  loading: false,
  error: null,
  data: null
};

const slice = createSlice({
  name: SLICES_NAMES.BACKEND_PAGE,
  initialState,
  reducers: {
    fetchPageData: (state, _: PayloadAction<string>) => {
      state.loading = true;
    },
    successFetchPageData: (state, { payload }: PayloadAction<PageContentType>) => {
      state.loading = false;
      state.data = payload;
    },
    failureFetchPageData: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export const { fetchPageData, successFetchPageData, failureFetchPageData } = slice.actions;

export const fetchPageContent = createAsyncThunk(
  '@backendPage/FETCH_PAGE_CONTENT',
  async (pageLink: string, { rejectWithValue }) => {
    try {
      return await api.app.getPageContent(pageLink);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default slice.reducer;
