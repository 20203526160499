import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface BackButtonProps {
  classes?: {
    container?: string;
  };
}

const BackButton = ({ classes }: BackButtonProps) => {
  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <button onClick={handleClickBack} className={classNames(styles.goBack__button, classes?.container ?? '')}>
      <i className={classNames('biab_left-arrow fa2 fa2-arrow-left', styles.goBack__icon)} />
    </button>
  );
};

export default BackButton;
