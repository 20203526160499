import { useFormatCurrency } from 'hooks/useFormatCurrency';

import styles from '../../styles.module.scss';

interface QuickBetsValueProps {
  value: string | number;
  noSymbol?: boolean;
}

const QuickBetsValue = ({ value, noSymbol = false }: QuickBetsValueProps) => {
  const { noFormattedAmount: quickBetsValue } = useFormatCurrency(value || 0, '', {
    noCommas: true,
    isCheckIndian: true,
    noRounding: true,
    ignorePrecision: true,
    noSymbol: noSymbol
  });

  return <span className={styles.quickBets__table__column__cell__value}>{value ? quickBetsValue : '-'}</span>;
};
export default QuickBetsValue;
