import classNames from 'classnames';

import useAsianLayBetting from 'hooks/useAsianLayBetting';

import AsianViewBackLayLabels from '../MarketCell/AsianViewBackLayLabels';

import styles from '../MarketCell/styles.module.scss';

interface EmptyMarketCellProps {
  isFirstEvent: boolean;
  isSingleLine?: boolean;
}

const EmptyMarketCell = ({ isFirstEvent, isSingleLine = false }: EmptyMarketCellProps) => {
  const isLayBetting = useAsianLayBetting();
  const hasBackLay = true;
  const hasBackLayBtns = hasBackLay && isFirstEvent && isLayBetting;

  return isFirstEvent ? (
    <div className={classNames('biab_asian-view-market-cell', styles.marketCell)}>
      {hasBackLayBtns && isSingleLine && (
        <div className={classNames(styles.marketCell__market__backLay__wrapper)}>
          <AsianViewBackLayLabels containerClassName={styles.marketCell__backLay} isSmall={isSingleLine} />
          {isSingleLine && (
            <AsianViewBackLayLabels containerClassName={styles.marketCell__backLay} isSmall={isSingleLine} />
          )}
        </div>
      )}
      {hasBackLayBtns && !isSingleLine && (
        <div
          className={classNames('biab_asian-view-market', styles.marketCell__market, {
            [styles.marketCell__market__hasBackLay]: hasBackLay && isFirstEvent,
            [styles.marketCell__market__main]: true
          })}
        >
          {hasBackLayBtns && <AsianViewBackLayLabels containerClassName={styles.marketCell__backLay} />}
        </div>
      )}
    </div>
  ) : null;
};

export default EmptyMarketCell;
