import { MouseEvent, TouchEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { throttle } from 'lodash';

import { leftArrow, rightArrow, spaceKey, SWIPE_DIRECTION_LEFT } from 'constants/webTours';
import { getAppDevice, getLanguage } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { fetchStepData } from 'redux/modules/tours';
import { getTourList } from 'redux/modules/tours/selectors';
import { StepOrderType } from 'types/tours';

import Carousel from './component/Carousel';
import Pagination from './component/Pagination';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './styles.module.scss';

interface TourModalComponentProps {
  tourId: number;
}

const TourModalComponent = ({ tourId }: TourModalComponentProps) => {
  const dispatch = useDispatch();

  const device = useSelector(getAppDevice);
  const language = useSelector(getLanguage);
  const webtoursImageHeight = useSelector(getElementHeightByName(EElementNames.WEBTOURS_IMAGE));
  const tourList = useSelector(getTourList);

  const [disableSlideAnimation, setDisableSlideAnimation] = useState(false);
  const [stepOrder, setStepOrder] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(0);
  const [nextStep, setNextStep] = useState<StepOrderType>({ id: 0, order: 0 });
  const [prevStep, setPrevStep] = useState<StepOrderType>({ id: 0, order: 0 });

  const sliderRef = useRef<Slider>(null);
  const sliderContainerRef = useRef<HTMLDivElement>(null);
  const swipeRef = useRef<{ isDown: boolean; touchStart: number; touchEnd: number }>({
    isDown: false,
    touchStart: 0,
    touchEnd: 0
  });

  const currentTour = tourList.find(item => item.id === tourId);
  const currentTourSteps = currentTour!.steps.filter(step => step.enabled);

  const throttledSlideTo = throttle((newValue, dontAnimate) => handleGoto(newValue, dontAnimate), 300);

  const isMobile = device === Devices.MOBILE;

  const handleGoto = (newValue: number, dontAnimate: boolean) => {
    sliderRef.current?.slickGoTo(newValue, dontAnimate);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === rightArrow || e.key === spaceKey) {
        handleClickNext();
      }
      if (e.key === leftArrow) {
        handleClickPrev();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [stepOrder]);

  useEffect(() => {
    if (!currentTourSteps[stepOrder].content) {
      dispatch(fetchStepData({ id: currentTourSteps[stepOrder].id, platform: device, locale: language }));
    }
    setNextStep({ id: currentTourSteps[stepOrder].id, order: stepOrder });
  }, [stepOrder, device, language]);

  useEffect(() => {
    setPrevStep({ id: 0, order: 0 });
    if (stepOrder === 0) {
      setNextStep({ id: currentTourSteps[0].id, order: 0 });
    }
    setStepOrder(0);
  }, [tourId]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    const nextStepData = currentTourSteps.find(item => item.id === nextStep.id);
    if (nextStepData?.content) {
      timer = setTimeout(() => {
        throttledSlideTo(stepOrder, disableSlideAnimation);
      }, 100);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [tourList, nextStep]);

  const handleSetPrevStep = () => {
    setPrevStep({ id: currentTourSteps[stepOrder].id, order: stepOrder });
  };

  const handleClickNext = () => {
    if (stepOrder + 1 < currentTourSteps.length) {
      setDisableSlideAnimation(false);
      handleSetPrevStep();
      setStepOrder(stepOrder + 1);
    }
  };
  const handleClickPrev = () => {
    if (stepOrder - 1 > -1) {
      setDisableSlideAnimation(false);
      handleSetPrevStep();
      return setStepOrder(stepOrder - 1);
    }
  };
  const handleGoToSlide = (slide: number) => {
    if (slide - 1 - stepOrder === 1 || stepOrder - (slide - 1) === 1) {
      setDisableSlideAnimation(false);
    } else {
      setDisableSlideAnimation(true);
    }
    handleSetPrevStep();
    setStepOrder(slide - 1);
  };
  const handleSwipe = (swipeDirection: string) => {
    handleSetPrevStep();
    if (swipeDirection === SWIPE_DIRECTION_LEFT) {
      return setStepOrder(stepOrder + 1);
    }
    return setStepOrder(stepOrder - 1);
  };

  const descriptionContent = () => {
    if (nextStep && currentTourSteps.find(item => item.id === nextStep.id)?.content) {
      return currentTourSteps.find(item => item.id === nextStep.id)?.content as string;
    }
    if (prevStep && currentTourSteps.find(item => item.id === prevStep.id)?.content) {
      return currentTourSteps.find(item => item.id === prevStep.id)?.content as string;
    }
    return '';
  };

  const afterChange = (curr: number) => {
    setSelectedOrder(curr);
  };

  const mouseDown = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (sliderContainerRef.current) {
      swipeRef.current.isDown = true;
      swipeRef.current.touchStart = e.clientX;
    }
  };

  const mouseLeave = () => {
    if (sliderContainerRef.current) {
      swipeRef.current.isDown = false;
    }
  };

  const mouseMove = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    if (sliderContainerRef.current) {
      if (!swipeRef.current.isDown) return;
      swipeRef.current.touchEnd = e.clientX;
    }
  };

  const mouseUp = () => {
    if (sliderContainerRef.current) {
      swipeRef.current.isDown = false;
      const { touchStart, touchEnd } = swipeRef.current;
      if (touchStart - touchEnd > 100) {
        handleClickNext();
      }
      if (touchEnd - touchStart > 100) {
        handleClickPrev();
      }
    }
  };

  const swipeStart = (e: TouchEvent<HTMLDivElement>) => {
    if (sliderContainerRef.current) {
      swipeRef.current.isDown = true;
      swipeRef.current.touchStart = e.touches[0].clientX;
    }
  };

  const swipeMove = (e: TouchEvent<HTMLDivElement>) => {
    if (sliderContainerRef.current) {
      if (!swipeRef.current.isDown) return;
      swipeRef.current.touchEnd = e.touches[0].clientX;
    }
  };

  const swipeEnd = () => {
    if (sliderContainerRef.current) {
      swipeRef.current.isDown = false;
      const { touchStart, touchEnd } = swipeRef.current;
      if (touchStart - touchEnd > 100) {
        handleClickNext();
      }
      if (touchEnd - touchStart > 100) {
        handleClickPrev();
      }
    }
  };

  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  return (
    <div className={styles.sliderWrapper}>
      <div>
        <div
          ref={sliderContainerRef}
          onTouchStart={isTouchDevice() ? swipeStart : () => {}}
          onTouchMove={isTouchDevice() ? swipeMove : () => {}}
          onTouchEnd={isTouchDevice() ? swipeEnd : () => {}}
          onMouseDown={!isTouchDevice() ? mouseDown : () => {}}
          onMouseLeave={!isTouchDevice() ? mouseLeave : () => {}}
          onMouseUp={!isTouchDevice() ? mouseUp : () => {}}
          onMouseMove={!isTouchDevice() ? mouseMove : () => {}}
          style={{ height: isMobile ? webtoursImageHeight : 'initial' }}
          className={styles.sliderContainer}
        >
          <Slider
            swipe={false}
            afterChange={afterChange}
            ref={sliderRef}
            dots={false}
            arrows={false}
            slidesToShow={1}
            onSwipe={handleSwipe}
            speed={200}
          >
            {currentTourSteps.map((step, idx) => (
              <Carousel key={step.id} step={step} selected={idx === selectedOrder} />
            ))}
          </Slider>
        </div>
        <div
          className={styles.stepDescription}
          dangerouslySetInnerHTML={{
            __html: descriptionContent()
          }}
        />
        <Pagination
          handleClickNext={handleClickNext}
          handleClickPrev={handleClickPrev}
          handleGoToSlide={handleGoToSlide}
          stepOrder={stepOrder}
          currentTourSteps={currentTourSteps}
          nextStep={nextStep.order}
          prevStep={prevStep.order}
        />
      </div>
    </div>
  );
};

export default TourModalComponent;
