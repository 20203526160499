import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import AccountNavigation from 'components/AccountNavigation';
import Balance from 'components/AccountNavigation/components/Balance';
import ResponsibleBettingTimeOutAlert from 'components/ResponsibleBetting/components/ResponsibleBettingTimeOutAlert';
import { ExchangeTypes, PLBetType, TExchangeTypes } from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import {
  getDesktopSettingsBalanceToggle,
  getDesktopSettingsDepositBtn,
  getIsIframeEnabled
} from 'redux/modules/appConfigs/selectors';
import { getAppSettings } from 'redux/modules/appSettings/selectors';
import { getIsTimeOutEnabled } from 'redux/modules/responsibleBetting/selectors';
import { TFetchDataWithCurrencyHandlers } from 'types/myBets';
import { getDefaultLinkTarget } from 'utils/navigation';

import MultiCurrencyDropdown from '../MultiCurrencyDropdown';

import styles from './DesktopAccountPagesHeader.module.scss';

interface DesktopAccountPagesHeaderProps {
  isMultiCurrencySupported: boolean;
  fetchDataWithCurrencyHandlers: MutableRefObject<TFetchDataWithCurrencyHandlers>;
  activePLTab: PLBetType | TExchangeTypes;
}
const DesktopAccountPagesHeader = ({
  isMultiCurrencySupported,
  fetchDataWithCurrencyHandlers,
  activePLTab
}: DesktopAccountPagesHeaderProps) => {
  const { t } = useTranslation();

  const settings = useSelector(getAppSettings);
  const iframeEnabled = useSelector(getIsIframeEnabled);
  const depositBtn = useSelector(getDesktopSettingsDepositBtn);
  const balanceToggle = useSelector(getDesktopSettingsBalanceToggle);
  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);

  const isAsianViewTab = activePLTab !== ExchangeTypes.AsianView && activePLTab !== PLBetType.AsianView;

  const { selectedTab } = useAccountUrlParams();
  return (
    <>
      {isTimeOutEnabled && (
        <div className={styles.accountTopWrapper__alert}>
          <ResponsibleBettingTimeOutAlert />
        </div>
      )}
      <div className={styles.accountTopWrapper}>
        <div className={styles.accountTopWrapper__navigationAndDropdown}>
          <AccountNavigation selectedTab={selectedTab} />
          {isMultiCurrencySupported && isAsianViewTab && (
            <MultiCurrencyDropdown
              fetchDataWithCurrencyHandlers={fetchDataWithCurrencyHandlers.current}
              activePLTab={activePLTab}
            />
          )}
        </div>
        <div className={classNames('biab_account-navigation-right-block', styles.accountTopWrapper__rightBlock)}>
          {balanceToggle && <Balance />}

          {depositBtn && settings.length && (
            <div>
              <a
                className={classNames(styles.deposit, 'biab_btn')}
                href={settings[0].url}
                target={getDefaultLinkTarget({
                  url: settings[0].url,
                  absolutePath: true,
                  isIframeEnabled: iframeEnabled,
                  currentTarget: '_blank'
                })}
              >
                {t('accountNavigation.deposit')}
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DesktopAccountPagesHeader;
