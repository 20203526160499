import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { ASIAN_VIEW_LEAGUES_FILTER } from 'constants/asianView';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';

const AsianLeagueFilterInjection = () => {
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const isLoggedInRef = useRef<undefined | boolean>(undefined);

  useEffect(() => {
    if (isLoggedInRef?.current && !isLoggedIn) {
      localStorage.removeItem(ASIAN_VIEW_LEAGUES_FILTER);
    }
    isLoggedInRef.current = isLoggedIn;
  }, [isLoggedIn]);

  return null;
};

export default AsianLeagueFilterInjection;
