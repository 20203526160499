import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { AsianViewMarketLink, AsianViewTimeFilter } from 'constants/asianView';
import { componentsBranding } from 'constants/branding';
import {
  ACCOUNT_RESPONSIBLE_BETTING_URL,
  ASIAN_BASE_URL,
  GAMES_BASE_URL,
  HOME_BASE_URL,
  SPORT_BASE_URL
} from 'constants/locations';
import { ACCOUNT_SUB_HEADER_NAVIGATION } from 'constants/navigation';
import useOnClickOutside from 'hooks/useOnClickOutside';
import {
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getIsIframeEnabled,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { logoutUser } from 'redux/modules/auth';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getIsTimeOutEnabled } from 'redux/modules/responsibleBetting/selectors';
import { SportId } from 'types';

import AccountPagesSubHeaderNavigationItem from './components/AccountPagesSubHeaderNavigationItem';

import styles from './styles.module.scss';

const AccountPagesSubheader = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const asianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);

  const isAsianPage = pathname.includes(ASIAN_BASE_URL);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const accountSubHeaderItems = ACCOUNT_SUB_HEADER_NAVIGATION(isPNCEnabled);

  const isShowLogout = isLoggedIn;

  const [isOpen, setIsOpen] = useState(false);

  const productsDropdownRef = useRef<HTMLAnchorElement>(null);

  useOnClickOutside(productsDropdownRef, () => setIsOpen(false));

  const handleLogout = () => {
    let redirectUrl =
      isAsianPage && asianViewEnabled
        ? `${ASIAN_BASE_URL}/sport/${SportId.SOCCER}/timeFilter/${AsianViewTimeFilter.Today}/marketLink/${AsianViewMarketLink.HDP_AND_OU}`
        : HOME_BASE_URL;

    if (!asianViewEnabled && !isExchangeSportsEnabled) {
      redirectUrl = GAMES_BASE_URL;
    }

    dispatch(logoutUser(redirectUrl));
  };

  const handleOnHome = () => {
    const homeUrl = pathname.replace(ACCOUNT_RESPONSIBLE_BETTING_URL, '') || '/';
    navigate(homeUrl, { replace: true });
  };

  const displayBackToIcon =
    [isAsianViewEnabled, isExchangeSportsEnabled, isExchangeGamesEnabled].filter(Boolean).length > 1;

  return (
    <div className={classNames(styles.container, 'biab_header-submenu')}>
      <div className={classNames(styles.navigationContainer, 'biab_navigation-links')}>
        <div className={styles.iconsContainer}>
          {!isTimeOutEnabled && (
            <div className={classNames(styles.iconContainer, 'biab_navigation-link')}>
              <a className={styles.iconLink} onClick={handleOnHome}>
                <i className={classNames(styles.icon, 'fa2 fa2-home-icon biab_fav-manage-icon')} />
              </a>
            </div>
          )}
          {!isTimeOutEnabled && displayBackToIcon ? (
            <>
              <i className={classNames(styles.icon, 'fa2 fa2-header-divider-icon biab_fav-manage-icon')} />
              <a
                ref={productsDropdownRef}
                className={classNames(styles.iconContainer, styles.dropdownIcon, 'biab_navigation-link')}
                onClick={() => setIsOpen(!isOpen)}
              >
                <i
                  className={classNames(styles.icon, 'fa2', 'fa2-chevron-down-icon', 'biab_fav-manage-icon', {
                    [styles.rotatedIcon]: isOpen
                  })}
                />
                {isOpen && (
                  <div className={styles.dropdownContainer}>
                    {isAsianViewEnabled && (
                      <NavLink
                        to={`${ASIAN_BASE_URL}/sport/${SportId.SOCCER}/timeFilter/${AsianViewTimeFilter.Today}/marketLink/${AsianViewMarketLink.HDP_AND_OU}`}
                        className={classNames(styles.navLink, componentsBranding.DROPDOWN_ITEM)}
                      >
                        {t('header.navigation.asianView')}
                      </NavLink>
                    )}
                    {isExchangeSportsEnabled && (
                      <NavLink
                        to={SPORT_BASE_URL}
                        className={classNames(styles.navLink, componentsBranding.DROPDOWN_ITEM)}
                      >
                        {t('header.navigation.sports')}
                      </NavLink>
                    )}
                    {isExchangeGamesEnabled && (
                      <NavLink
                        to={GAMES_BASE_URL}
                        className={classNames(styles.navLink, componentsBranding.DROPDOWN_ITEM)}
                      >
                        {t('header.navigation.games')}
                      </NavLink>
                    )}
                  </div>
                )}
              </a>
            </>
          ) : null}
        </div>
        {accountSubHeaderItems.map(item => (
          <AccountPagesSubHeaderNavigationItem item={item} key={item.title} />
        ))}
      </div>
      {isShowLogout && !isIframeEnabled && (
        <div className="biab_navigation-links">
          <a onClick={handleLogout} className={classNames(styles.logoutBtn, 'biab_navigation-link')}>
            <span className={classNames(styles.logoutBtnText)}>{t('header.logout')}</span>
            <i className={classNames(styles.icon, styles.logoutIcon, 'fa2 fa2-logout-icon biab_fav-manage-icon')} />
          </a>
        </div>
      )}
    </div>
  );
};

export default AccountPagesSubheader;
