import { useSelector } from 'react-redux';
import classNames from 'classnames';

import AsianViewBackLayLabels from 'components/AsianViewPageModule/components/Event/MarketCell/AsianViewBackLayLabels';
import SelectionOdds from 'components/AsianViewPageModule/components/Event/MarketCell/SelectionOdds';
import SelectionOddsSkeleton from 'components/AsianViewPageModule/components/Event/MarketCell/SelectionOddsSkeleton';
import { asianViewMiddleSection as branding } from 'constants/branding';
import useAsianLayBetting from 'hooks/useAsianLayBetting';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { TAsianViewMarket } from 'redux/modules/asianView/type';
import { getMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';

import styles from '../../styles.module.scss';

interface MatchOddsHTMarketRunnersProps {
  market: TAsianViewMarket;
  competitionId: string;
  eventId: string;
  isFirst: boolean;
}

const MatchOddsHTMarketRunners = ({ market, competitionId, eventId, isFirst }: MatchOddsHTMarketRunnersProps) => {
  const marketPricesId = useSelector(getMarketPricesId(market.id));

  const isLayBetting = useAsianLayBetting();
  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: market.id,
    eventId,
    section: MarketsPricesSocketParamsSections.AsianViewMiddleSection,
    observerOptions: { rootMargin: '200px' }
  });

  return (
    <div
      ref={ref}
      key={market.id}
      className={styles.matchOddsHTEvent__prices__group}
      data-market-id={market.id}
      data-event-id={eventId}
      data-market-prices="true"
    >
      {[market.runners[0], market.runners[2], market.runners[1]].map(runner => {
        if (!runner) {
          return null;
        }

        return (
          <div
            className={classNames(styles.matchOddsHTEvent__prices, branding.MAIN_CONTAINER_BORDER, {
              [styles.matchOddsHTEvent__prices__first]: isFirst
            })}
            key={`${runner.id}_${runner.handicap}`}
          >
            {isFirst && isLayBetting && <AsianViewBackLayLabels />}
            <div>
              {marketPricesId ? (
                <SelectionOdds competitionId={competitionId} eventId={eventId} marketId={market.id} runner={runner} />
              ) : (
                <SelectionOddsSkeleton />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MatchOddsHTMarketRunners;
