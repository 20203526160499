import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { without } from 'lodash';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';
import { TFetchMarketRulesResponse } from 'types/markets';

import { TCollapsedSports, TContent, TFetchSearchPayload, TSearches, TSetSearchResults } from './type';

const initialState: TSearches = {
  searchList: {
    content: {},
    resultsBySport: {},
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: null,
    totalElements: 0,
    totalPages: 0
  },
  collapsedSports: {
    pathname: '',
    sportIds: []
  },
  loading: false,
  isLoaded: false,
  error: null,
  rules: {},
  rulesLoading: false,
  rulesError: null,
  intersectingEventIds: []
};

const slice = createSlice({
  name: SLICES_NAMES.SEARCH,
  initialState,
  reducers: {
    fetchSearch: (state, _: PayloadAction<TFetchSearchPayload>) => {
      state.loading = true;
      state.isLoaded = false;
    },
    successFetchSearch: (state, { payload }: PayloadAction<TSetSearchResults>) => {
      const newContent = payload.newQuery ? {} : { ...state.searchList.content };

      payload.searchResult.content.forEach((event: TContent) => {
        // Key should include string with space, because js object makes automatically sorting by numeric keys. Don't remove!!!
        const sportIdKey = ` ${event.eventTypeId}`;

        if (!newContent[sportIdKey]) {
          newContent[sportIdKey] = [event];
        } else {
          newContent[sportIdKey] = [...newContent[sportIdKey], event];
        }
      });

      state.loading = false;
      state.isLoaded = true;
      state.searchList = {
        ...payload.searchResult,
        content: newContent
      };
    },
    failureFetchSearch: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.isLoaded = true;
      state.error = payload;
    },
    setCollapsedSports: (state, { payload }: PayloadAction<TCollapsedSports>) => {
      state.collapsedSports = payload;
    },
    resetCollapsedSports: state => {
      state.collapsedSports = initialState.collapsedSports;
    },
    resetSearch: () => initialState,
    fetchSearchMarketRules: (state, _: PayloadAction<string>) => {
      state.rulesLoading = true;
    },
    successFetchSearchMarketRules: (state, { payload }: PayloadAction<TFetchMarketRulesResponse>) => {
      state.rulesLoading = false;
      state.rules[payload.marketId] = payload.rules;
    },
    failureFetchSearchMarketRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.rulesLoading = false;
      state.rulesError = payload;
    },
    setIntersectingEventId: (state, { payload }: PayloadAction<string>) => {
      state.intersectingEventIds = [...state.intersectingEventIds, payload];
    },
    removeIntersectingEventId: (state, { payload }: PayloadAction<string>) => {
      state.intersectingEventIds = without(state.intersectingEventIds, payload);
    }
  }
});

export const {
  fetchSearch,
  fetchSearchMarketRules,
  failureFetchSearch,
  failureFetchSearchMarketRules,
  successFetchSearch,
  successFetchSearchMarketRules,
  resetSearch,
  setCollapsedSports,
  resetCollapsedSports,
  setIntersectingEventId,
  removeIntersectingEventId
} = slice.actions;

export default slice.reducer;
