import { useDispatch, useSelector } from 'react-redux';

import { PopupTypes, PostMessageTypes } from 'constants/app';
import useDevice from 'hooks/useDevice';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { getIsIframeEnabled, getNonAuthLoginNotificationEnabled } from 'redux/modules/appConfigs/selectors';
import { setLoginPopup } from 'redux/modules/popup';
import { dispatchEvent, sendIframeMessages } from 'utils/iframe';

const usePostMessage = () => {
  const dispatch = useDispatch();

  const { loginHeaderButtons } = useDeviceSettings();
  const iframeEnabled = useSelector(getIsIframeEnabled);
  const nonAuthLoginNotificationEnabled = useSelector(getNonAuthLoginNotificationEnabled);
  const { isMobile } = useDevice();

  const placeBetLogin = () => {
    if (nonAuthLoginNotificationEnabled) {
      if (isMobile && loginHeaderButtons && !iframeEnabled) {
        dispatch(setLoginPopup({ type: PopupTypes.LOGIN_POPUP }));
      } else {
        dispatch(setLoginPopup({ type: PostMessageTypes.LOGIN_TO_PLACE_BET }));
      }
    }

    if (iframeEnabled) {
      sendIframeMessages({ type: PostMessageTypes.LOGIN_TO_PLACE_BET });
    } else {
      dispatchEvent({ type: PostMessageTypes.LOGIN_TO_PLACE_BET });
    }
  };

  return {
    placeBetLogin
  };
};

export default usePostMessage;
