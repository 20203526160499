import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import AsianViewFavouriteStar from 'components/AsianViewFavouriteStar';
import { FAVORITES_TYPES } from 'constants/app';
import { AsianViewFavouritePayload } from 'redux/modules/asianViewFavourites/type';
import { SportId } from 'types';

import styles from './styles.module.scss';

interface MobileCompetitionTitleProps {
  competitionId: string;
  name: string;
  isOpen: boolean;
  onCollapse: (isOpen: boolean) => void;
  isCollapseEnabled?: boolean;
  onRemoveFavorite?: (favourite?: AsianViewFavouritePayload) => void;
  competitionTotalMatched?: number;
}

const MobileCompetitionTitle = ({
  competitionId,
  name,
  isOpen,
  onCollapse,
  isCollapseEnabled = true,
  onRemoveFavorite,
  competitionTotalMatched
}: MobileCompetitionTitleProps) => {
  const { sportId = SportId.SOCCER } = useParams();

  return (
    <div
      {...(competitionTotalMatched !== undefined ? { 'data-competition-total-matched': competitionTotalMatched } : {})}
      className={classNames('biab_asian-view-competition-title', styles.competition__title, {
        [styles.competition__withOutBorder]: !isOpen
      })}
    >
      <div
        className={classNames('biab_asian-view-competition-actions', styles.competition__actions, {
          [styles.competition__actionsTab]: !isCollapseEnabled
        })}
      >
        {isCollapseEnabled && (
          <i
            onClick={() => onCollapse(!isOpen)}
            className={classNames('av-icon biab_expand', styles.competition__expand, {
              'av-icon-arrow-up': isOpen,
              'av-icon-arrow-down': !isOpen
            })}
          />
        )}
      </div>
      <div className={classNames('biab_asian-view-competition-name-wrapper', styles.competition__name__wrapper)}>
        <span title={name} className={classNames('biab_asian-view-competition-name', styles.competition__name)}>
          {name}
        </span>
      </div>
      <div className={classNames('biab_asian-view-competition-star-wrapper', styles.competition__star)}>
        <AsianViewFavouriteStar
          favourite={{
            entryName: name,
            entryId: competitionId,
            entryType: FAVORITES_TYPES.competition,
            sportId
          }}
          onRemoveFavorite={onRemoveFavorite}
        />
      </div>
    </div>
  );
};

export default MobileCompetitionTitle;
