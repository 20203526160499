import classNames from 'classnames';

import { BetTypes } from 'types/bets';

import styles from './AVBetListMobileSideIndicator.module.scss';

type AVBetListMobileSideIndicatorProps = {
  /**
   * Only for group
   */
  betSides?: string[];
  /**
   * Only for group
   */
  betGroupsCount?: number;
  /**
   * Only for group
   */
  isExpanded?: boolean;
  /**
   * Only for single bet
   */
  isSingleBet?: boolean;
  /**
   * Side only for single bet
   */
  side?: BetTypes;
};

const AVBetListMobileSideIndicator = ({
  betSides,
  betGroupsCount,
  isExpanded = false,
  isSingleBet = false,
  side
}: AVBetListMobileSideIndicatorProps) => {
  const isBothBetSides = betSides?.length === 2;
  const isBackSide =
    side && isSingleBet ? side === BetTypes.BACK : betSides?.length === 1 && betSides[0] === BetTypes.BACK;
  const isLaySide =
    side && isSingleBet ? side === BetTypes.LAY : betSides?.length === 1 && betSides[0] === BetTypes.LAY;

  if (!isSingleBet && isBothBetSides && betGroupsCount && betGroupsCount > 1) {
    if (!isExpanded) {
      return (
        <div className={classNames(styles.indicator__double, styles.indicator__height)}>
          <div className={classNames(styles.indicator, styles.indicator__back)} />
          <div className={classNames(styles.indicator, styles.indicator__lay)} />
        </div>
      );
    }

    return null;
  }

  return (
    <div
      className={classNames(styles.indicator, styles.indicator__height, {
        [styles.indicator__back]: isBackSide,
        [styles.indicator__lay]: isLaySide
      })}
    />
  );
};

export default AVBetListMobileSideIndicator;
