import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import MarketRow from 'components/ActiveMarketsSection/components/MarketRow';
import CollapsibleList from 'components/FavoritesSection/CollapsibleList';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useElementSize from 'hooks/useElementSize';
import { getAppDevice, getLanguage } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { getSortedCurrentBets } from 'redux/modules/currentBets/selectors';
import { fetchFavorites } from 'redux/modules/favorites';
import { getFavorites } from 'redux/modules/favorites/selectors';
import { CookieNames } from 'types';
import { getBooleanValue } from 'utils';
import { getFilteredCurrentBets } from 'utils/market';

import Tabs from './components/Tabs';

import styles from './styles.module.scss';

const FavoritesMobile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([CookieNames.BIAB_MOBILE_FAV_TAB_IS_ACTIVE]);

  const favorites = useSelector(getFavorites);
  const device = useSelector(getAppDevice);
  const language = useSelector(getLanguage);
  const isGameBetslip = useSelector(getIsGameBetSlip);
  const sortedCurrentBets = useSelector(getSortedCurrentBets(isGameBetslip));

  const { activeMarkets } = useDeviceSettings();

  const [isFavorites, setIsFavorites] = useState(true);

  const isDesktop = device === Devices.DESKTOP;
  const filteredCurrentBets = getFilteredCurrentBets(sortedCurrentBets);

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height }));
    }
  });

  const onChangeTab = (isFavTabActive: boolean) => {
    setIsFavorites(isFavTabActive);
    setCookie(CookieNames.BIAB_MOBILE_FAV_TAB_IS_ACTIVE, isFavTabActive);
  };

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (isDesktop) {
      navigate('/');
    }
  }, [isDesktop]);

  useEffect(() => {
    dispatch(fetchFavorites());
  }, [dispatch, language]);

  useEffect(() => {
    if (cookies.BIAB_MOBILE_FAV_TAB_IS_ACTIVE) {
      setIsFavorites(getBooleanValue(cookies.BIAB_MOBILE_FAV_TAB_IS_ACTIVE));
    }
  }, [cookies.BIAB_MOBILE_FAV_TAB_IS_ACTIVE]);

  return (
    <div ref={isDesktop ? null : componentRef}>
      <div className={classNames('biab_fav-page-wrapper', styles.tabsWrapper)}>
        {activeMarkets ? (
          <Tabs isFavorites={isFavorites} onChangeTab={onChangeTab} marketsCount={filteredCurrentBets.length} />
        ) : (
          <div className={classNames('biab_mobile-favourites-title', styles.favorites__mobileTitle)}>
            {t('navigation.favouritesMobileTitle')}
          </div>
        )}

        {activeMarkets && !isFavorites ? (
          <div className={classNames('biab_mobile-active-markets-page', styles.activeMarkets__container)}>
            {filteredCurrentBets.length ? (
              <ul className={styles.activeMarkets__list}>
                {filteredCurrentBets.map((bet, index) => (
                  <MarketRow activeMarket={bet} key={`${bet.marketId}-${index}`} />
                ))}
              </ul>
            ) : (
              <p className={classNames('biab_active-markets-message', styles.activeMarkets__emptyMessage)}>
                {t('navigation.noActiveMarkets')}
              </p>
            )}
          </div>
        ) : (
          <div className="biab_mobile-favourites-page">
            {favorites.length ? (
              <CollapsibleList favorites={favorites} />
            ) : (
              <div className={styles.favorites__noFavorites}>{t('navigation.noFavourites')}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FavoritesMobile;
