import { PageBlocks } from 'types';

export const INLINE_PLACEMENT_PROGRESS_INTERVAL = 1000;

export const INLINE_PLACEMENT_AVAILABLE = [
  PageBlocks.HOME,
  PageBlocks.IN_PLAY,
  PageBlocks.POPULAR,
  PageBlocks.FEATURE_MARKET,
  PageBlocks.EVENT,
  PageBlocks.SPORT,
  PageBlocks.SEARCH,
  PageBlocks.MULTI_MARKET_VIEW,
  PageBlocks.MULTI_MARKET_VIEW_TOP_MARKET,
  PageBlocks.TOP_5_VIEW,
  PageBlocks.TOP_5_VIEW_TOP_MARKET,
  PageBlocks.CASH_OUT,
  PageBlocks.MARKET_ODDS,
  PageBlocks.FANCY_VIEW,
  PageBlocks.FANCY_TOP_VIEW
];

export const INLINE_PLACEMENT_TWO_COLUMNS_VIEW = [
  PageBlocks.CASH_OUT,
  PageBlocks.EVENT,
  PageBlocks.FEATURE_MARKET,
  PageBlocks.SEARCH,
  PageBlocks.FANCY_VIEW
];

export const MOBILE_PLACEMENT_SUCCESS_NOTIFICATION_REMOVE_TIME_OUT = 5000;
export const MOBILE_PLACEMENT_ERROR_NOTIFICATION_REMOVE_TIME_OUT = 10000;

export const MobilePlacementTypes = {
  Place: 'Place',
  Edit: 'Edit',
  Cancel: 'Cancel',
  MatchedBySystem: 'MatchedBySystem'
} as const;

export const INLINE_PLACEMENT_PROCESS_MESSAGE_TIMEOUT = 10000;
export const INLINE_PLACEMENT_BET_WAS_NOT_PLACED_MESSAGE_TIMEOUT = 300000;

export const INLINE_PLACEMENT_POPULAR_FULL_WIDTH = 1366;
