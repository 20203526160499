import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

export const getFancyMarketData = (state: AppState) => state.fancyMarket;
export const getFancyMarketLoading = ({ fancyMarket }: AppState) => fancyMarket.loading;

export const getFancyDropdownMarkets = ({ fancyMarket }: AppState) => fancyMarket.dropdownMarkets;

export const getFancyMarketAllMarkets = createSelector(
  getFancyMarketData,
  ({ inningsFancy, inningsFours, inningsSixes, batsmen, otherLineMarkets, fallOfWicket, totalInningsRuns }) =>
    inningsFancy
      .concat(inningsFours)
      .concat(inningsSixes)
      .concat(batsmen)
      .concat(otherLineMarkets)
      .concat(fallOfWicket)
      .concat(totalInningsRuns)
);
