import { useSelector } from 'react-redux';
import classNames from 'classnames';

import AsianSportSection from 'components/AsianSportSection';
import { DESKTOP_NAVIGATION_HEIGHT } from 'constants/asianView';
import { asianViewNavigation as branding } from 'constants/branding';
import { SEPARATE_WALLET } from 'constants/myBets';
import {
  getDesktopSettingsBalanceToggle,
  getIsOperatorBalanceEnabled,
  getWalletIntegrationType
} from 'redux/modules/appConfigs/selectors';
import { getScrollContentHeight } from 'redux/modules/appSettings/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';

import AsianBalanceWrapper from './components/AsianBalanceWrapper/AsianBalanceWrapper';
import AsianBetslip from './components/AsianBetslip';
import AsianFavourites from './components/AsianFavourites';
import AsianTimeZone from './components/AsianTimeZone';

import styles from './styles.module.scss';

type AsianLeftNavigationProps = {
  onScroll: () => void;
  isCashOutPage?: boolean;
};

const AsianLeftNavigation = ({ onScroll, isCashOutPage = false }: AsianLeftNavigationProps) => {
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const height = useSelector(getScrollContentHeight);
  const balanceToggle = useSelector(getDesktopSettingsBalanceToggle);
  const walletIntegrationType = useSelector(getWalletIntegrationType);
  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);

  const maxHeight = height - DESKTOP_NAVIGATION_HEIGHT;
  const hasBalance =
    isLoggedIn && (balanceToggle || walletIntegrationType !== SEPARATE_WALLET || isOperatorBalanceEnabled);

  return (
    <aside
      className={classNames(styles.asianLeftBar, branding.SIDEBAR)}
      style={{ height: maxHeight, maxHeight }}
      onScroll={onScroll}
    >
      <AsianTimeZone />
      {hasBalance && <AsianBalanceWrapper />}
      <AsianBetslip />
      {isLoggedIn && <AsianFavourites />}
      <AsianSportSection isCashOutPage={isCashOutPage} />
    </aside>
  );
};

export default AsianLeftNavigation;
