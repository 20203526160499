import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SpeedTester from 'browser-speed-test';
import classNames from 'classnames';
import { unescape } from 'lodash';

import {
  getConnectionFilePath,
  getConnectionFileSize,
  getConnectionInterval,
  getConnectionMaxSpeed,
  getConnectionMinSpeed
} from 'redux/modules/appConfigs/selectors';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

const NetworkStatuses = {
  GOOD: 'good',
  NORMAL: 'normal',
  BAD: 'bad'
} as const;

type NetworkStatusesKeys = typeof NetworkStatuses[keyof typeof NetworkStatuses];

const InternetSpeedMeter = () => {
  const { t } = useTranslation();

  const filePath = useSelector(getConnectionFilePath);
  const fileSize = useSelector(getConnectionFileSize);
  const connectionInterval = useSelector(getConnectionInterval);
  const maxSpeed = useSelector(getConnectionMaxSpeed);
  const minSpeed = useSelector(getConnectionMinSpeed);

  const [networkSpeed, setNetworkSpeed] = useState<number>(maxSpeed);

  const networkSpeedRef = useRef(networkSpeed);

  const rootPath = getEnvironmentRootPath();

  let networkStatus: NetworkStatusesKeys = NetworkStatuses.GOOD;

  if (networkSpeed < minSpeed) {
    networkStatus = NetworkStatuses.BAD;
  }
  if (networkSpeed > minSpeed && networkSpeed < maxSpeed) {
    networkStatus = NetworkStatuses.NORMAL;
  }

  useEffect(() => {
    const tester = new SpeedTester({});
    const interval = setInterval(async () => {
      try {
        const res = await tester.start();
        if (networkSpeedRef.current !== res) {
          setNetworkSpeed(res);
          networkSpeedRef.current = res;
        }
      } catch (e) {
        const testImageURL = `${location.origin}${rootPath}/${filePath}`;
        const startTime = performance.now();
        const image = new Image();
        image.onload = () => {
          const endTime = performance.now();
          const duration = endTime - startTime;
          const speedKbps = fileSize / (duration / 1000);
          const newNetworkSpeed = parseFloat(speedKbps.toFixed(2));

          if (networkSpeedRef.current !== newNetworkSpeed) {
            setNetworkSpeed(newNetworkSpeed);
            networkSpeedRef.current = newNetworkSpeed;
          }
        };
        image.src = `${testImageURL}?t=${Date.now()}`;
      }
    }, connectionInterval);

    return () => clearInterval(interval);
  }, [connectionInterval, filePath, fileSize, rootPath]);

  return (
    <span
      data-tooltip-id="tooltip"
      data-tooltip-html={unescape(
        `${t(`connection.status.${networkStatus}`)} ${networkSpeed} ${t('connection.speed.kbps')}`
      )}
      data-tooltip-place="left"
      className={classNames('cursor-help', styles.connection, styles[networkStatus])}
    />
  );
};

export default InternetSpeedMeter;
