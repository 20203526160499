import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { difference, groupBy, intersection } from 'lodash';

import {
  BET_SLIP_UNMATCHED_BET_PROCESS_MESSAGE_TIMEOUT,
  BET_SLIP_UNMATCHED_BET_WAS_NOT_PLACED_MESSAGE_TIMEOUT,
  BETSLIP_NOTIFICATIONS_STORAGE_NAME
} from 'constants/betslip';
import { betslipBranding, componentsBranding } from 'constants/branding';
import { VALIDATION_ERROR_BET_OUTDATED_LINE, VALIDATION_ERROR_BET_OUTDATED_ODDS } from 'constants/placement';
import { usePlacementNotifications } from 'hooks/usePlacementNotifications';
import { usePreviousValue } from 'hooks/usePrevious';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import {
  addErrorForPlacementNotifications,
  addOffersForPlacementNotifications,
  removeAllPlacementNotifications,
  setSelectedBets
} from 'redux/modules/betslip';
import { getIsGameBetSlip, getSelectedBets } from 'redux/modules/betslip/selectors';
import { SelectedBetState } from 'redux/modules/betslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { BettingType } from 'types/markets';

import MultiBetsNotification from './MultiBetsNotification';
import SingleBetNotification from './SingleBetNotification';

import styles from './styles.module.scss';

const PlacementNotifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isTimeoutError, setIsTimeoutError] = useState(false);
  const [isLongPlacement, setIsLongPlacement] = useState(false);
  const [isPlacedWithBetterOdds, setIsPlacedWithBetterOdds] = useState(false);

  const selectedBets = useSelector(getSelectedBets);
  const prevSelectedBets = usePreviousValue(selectedBets);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isGameBetSLip = useSelector(getIsGameBetSlip);

  const {
    notifications,
    betsStatuses,
    numberOfNotifications,
    numberOfPendingNotifications,
    numberOfErrors,
    hasPending,
    offers
  } = usePlacementNotifications();
  const timeOutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const cancelledNotifications = notifications.filter(
    notification =>
      notification.offerId &&
      !notification.error &&
      betsStatuses[notification.offerId] &&
      betsStatuses[notification.offerId] === BetsStatusesTypes.CANCELLED
  );
  const isError = numberOfErrors > 0 || (hasPending && isTimeoutError) || cancelledNotifications.length > 0;
  const firstBet = notifications[0]?.offer || notifications[0]?.bet || null;
  const stringifiedOffers = JSON.stringify(offers);
  const stringifiedNotifications = JSON.stringify(notifications);
  const isSuccess = !isError && !hasPending;
  const isPending = useRef(hasPending);
  isPending.current = hasPending;

  const notificationsOffers = useMemo(() => {
    return notifications.filter(({ offer }) => !!offer).map(({ offer }) => offer) as TCurrentBet[];
  }, [stringifiedNotifications]);

  const isAllBetsMatched = useMemo(
    () =>
      notificationsOffers.length > 0 && notificationsOffers.every(offer => !!offer.sizeMatched && !offer.sizeRemaining),
    [notificationsOffers]
  );

  useEffect(() => {
    if (Object.values(betsStatuses).every(status => !!status && status !== BetsStatusesTypes.PENDING)) {
      localStorage.removeItem(BETSLIP_NOTIFICATIONS_STORAGE_NAME);
    }
  }, [betsStatuses]);

  useEffect(() => {
    const payload: { betUuid: string; offer: TCurrentBet }[] = [];

    notifications.forEach(({ betUuid, offerId, offer }) => {
      const currentOffer = offers.find(currentBet => currentBet.offerId === offerId);

      if (offerId && !offer && currentOffer) {
        payload.push({ betUuid, offer: currentOffer });
      }
    });

    if (payload.length) {
      dispatch(addOffersForPlacementNotifications(payload));
    }
  }, [stringifiedOffers]);

  useEffect(() => {
    if (!hasPending) {
      if (isPNCEnabled && !isGameBetSLip) {
        const cancelledBets = notifications.filter(
          ({ offerId }) => offerId && betsStatuses[offerId] === BetsStatusesTypes.CANCELLED
        );

        const cancelledBetsByMarketId = groupBy(cancelledBets, ({ bet }) => bet.marketId);
        if (Object.keys(cancelledBetsByMarketId).length) {
          Object.values(cancelledBetsByMarketId).forEach(bets => {
            if (bets.length) {
              dispatch(
                setSelectedBets({
                  selectedBets: bets.map(({ bet }) => {
                    return {
                      ...bet,
                      state: SelectedBetState.ERROR,
                      isCancelled: true
                    };
                  })
                })
              );
              dispatch(
                addErrorForPlacementNotifications(
                  bets.map(({ betUuid, bet }) => {
                    return {
                      betUuid,
                      error:
                        bet.bettingType === BettingType.LINE
                          ? VALIDATION_ERROR_BET_OUTDATED_LINE
                          : VALIDATION_ERROR_BET_OUTDATED_ODDS
                    };
                  })
                )
              );
            }
          });
        }
      }

      const betsWithError = notifications.filter(({ error }) => !!error);
      const betsWithErrorByMarketId = groupBy(betsWithError, ({ bet }) => bet.marketId);

      if (Object.keys(betsWithErrorByMarketId).length) {
        Object.values(betsWithErrorByMarketId).forEach(bets => {
          if (bets.length) {
            dispatch(
              setSelectedBets({
                selectedBets: bets.map(({ bet }) => {
                  return {
                    ...bet,
                    state: SelectedBetState.ERROR
                  };
                })
              })
            );
          }
        });
      }
    }
  }, [hasPending]);

  useEffect(() => {
    if (isTimeoutError) {
      setIsTimeoutError(false);
      setIsLongPlacement(false);
    }
  }, [stringifiedNotifications]);

  useEffect(() => {
    if (hasPending) {
      if (!timeOutRef.current) {
        timeOutRef.current = setTimeout(() => {
          setTimeout(() => {
            setIsTimeoutError(true);
            setIsLongPlacement(false);

            if (timeOutRef.current) {
              clearTimeout(timeOutRef.current);
              timeOutRef.current = null;
            }
          }, BET_SLIP_UNMATCHED_BET_WAS_NOT_PLACED_MESSAGE_TIMEOUT - BET_SLIP_UNMATCHED_BET_PROCESS_MESSAGE_TIMEOUT);
          setIsLongPlacement(true);
        }, BET_SLIP_UNMATCHED_BET_PROCESS_MESSAGE_TIMEOUT);
      }
    } else {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
        timeOutRef.current = null;

        if (isLongPlacement) {
          setIsLongPlacement(false);
        }
      }
    }
  }, [hasPending]);

  useEffect(() => {
    if (notifications.length !== 0 && !isPending.current) {
      dispatch(removeAllPlacementNotifications());
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      !notifications.length ||
      isPending.current ||
      !prevSelectedBets ||
      prevSelectedBets.length === selectedBets.length
    ) {
      return;
    }

    const notificationBetsUuids = notifications.map(notification => notification.betUuid);
    const selectedBetsUuids = selectedBets.map(selectedBet => selectedBet.betUuid);
    const prevSelectedBetsUuids = prevSelectedBets.map(selectedBet => selectedBet.betUuid);
    const newBetsUuids = difference(selectedBetsUuids, prevSelectedBetsUuids);
    const hasNotification = !!intersection(notificationBetsUuids, newBetsUuids).length;

    if (!hasNotification) {
      dispatch(removeAllPlacementNotifications());
    }
  }, [selectedBets]);

  if (!numberOfNotifications) {
    return null;
  }

  return (
    <div
      className={classNames(styles.notification, componentsBranding.NOTIFICATION, {
        [componentsBranding.SUCCESS]: !hasPending && isSuccess,
        [componentsBranding.ERROR]: !hasPending && (isError || (isSuccess && !isAllBetsMatched)),
        [styles.notification__success]: !hasPending && isSuccess,
        [styles.notification__error]: !hasPending && (isError || (isSuccess && !isAllBetsMatched))
      })}
    >
      <div className={styles.notification__body}>
        <div className={styles.notification__icon}>
          {hasPending && (
            <i
              className={classNames(
                'fa fa-spinner fa-pulse fa-fw',
                styles.notification__icon__loading,
                betslipBranding.SPINNER_ICON
              )}
            />
          )}
          {!hasPending && isError && (
            <i className={classNames('biab_custom-icon-warning-circle', betslipBranding.WARNING_ICON)} />
          )}
          {isSuccess &&
            isAllBetsMatched &&
            (isPlacedWithBetterOdds ? (
              <i className={classNames('biab_custom-icon-success-star-circle', betslipBranding.STAR_ICON)}>
                <span className={classNames('path1', betslipBranding.BG_COLOR)} />
                <span className="path2" />
              </i>
            ) : (
              <i className={classNames('biab_custom-icon-success-circle', betslipBranding.CHECKMARK_ICON)} />
            ))}
          {isSuccess && !isAllBetsMatched && (
            <i
              className={classNames(
                'fa2 fa2-clock-icon',
                styles.notification__icon__unmatched,
                betslipBranding.CLOCK_ICON,
                betslipBranding.ERROR
              )}
            />
          )}
        </div>
        {numberOfNotifications === 1 && firstBet ? (
          <SingleBetNotification
            isLoading={hasPending}
            bet={firstBet}
            offer={notifications[0].offer}
            isError={isTimeoutError || isError}
            isPlacedSingleWithBetterOdds={isPlacedWithBetterOdds}
            setIsPlacedWithBetterOdds={setIsPlacedWithBetterOdds}
          />
        ) : (
          <MultiBetsNotification
            isLoading={hasPending}
            offers={notificationsOffers}
            numberOfBets={numberOfNotifications}
            numberOfErrors={numberOfErrors}
            numberOfPendingBets={numberOfPendingNotifications}
            isError={isError}
            setIsPlacedWithBetterOdds={setIsPlacedWithBetterOdds}
            isPlacedWithBetterOdds={isPlacedWithBetterOdds}
          />
        )}

        {!hasPending && (
          <div className={classNames(styles.notification__close, styles.close)}>
            <i
              onClick={() => dispatch(removeAllPlacementNotifications())}
              className={classNames('biab_custom-icon-close', styles.notification__close__icon)}
            />
          </div>
        )}
      </div>
      {isLongPlacement && <div className={styles.timeMsg}>{t('betslip.labels.processingBet')}</div>}
    </div>
  );
};

export default PlacementNotifications;
