import classNames from 'classnames';

import styles from './styles.module.scss';

const InfoCircle = () => {
  return (
    <i className={classNames('fa2 fa2-info-circle', styles.infoIcon)}>
      <span className="path1" />
      <span className="path2" />
    </i>
  );
};

export default InfoCircle;
