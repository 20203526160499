import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FAVORITES_TYPES } from 'constants/app';
import { ASIAN_OUTRIGHT_MARKET_ID } from 'constants/asianView';
import useAsianSingleView from 'hooks/useAsianSingleView';
import useLeaguesParam from 'hooks/useLeaguesParam';

const useLeaguesFilters = () => {
  const [searchParams] = useSearchParams();

  const competitions = useLeaguesParam();
  const { isAsianSingleView, isFavouriteView, favouriteType, favouriteId, singleViewEventId } = useAsianSingleView();

  const marketId = searchParams.get(ASIAN_OUTRIGHT_MARKET_ID);

  return useMemo(() => {
    if (!isAsianSingleView) {
      return {};
    }

    if (marketId && !competitions.length) {
      return { markets: [marketId] };
    }

    if (singleViewEventId) {
      return { coupons: [singleViewEventId] };
    }

    if (isFavouriteView && favouriteType === FAVORITES_TYPES.event) {
      return { coupons: [favouriteId] };
    }

    return competitions.length ? { competitions } : {};
  }, [isAsianSingleView, marketId, competitions, singleViewEventId, isFavouriteView, favouriteType, favouriteId]);
};

export default useLeaguesFilters;
