import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from '../../styles.module.scss';

interface FavouritesMobileProps {
  /**
   * Determine which tab is selected
   */
  isFavorites: boolean;
  /**
   * Toggle tabs
   */
  onChangeTab: (isActiveMarkets: boolean) => void;
  /**
   * Determine active markets count
   */
  marketsCount: number;
}

const Tabs = ({ isFavorites, onChangeTab, marketsCount }: FavouritesMobileProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('biab_fav-tabs-wrapper', styles.favorites__tabsWrapper)}>
      <div className={classNames('biab_tab-wrapper', styles.favorites__tabWrapper)}>
        <div
          className={classNames('biab_tab', styles.favorites__tab, {
            biab_active: isFavorites
          })}
          onClick={() => onChangeTab(!isFavorites)}
        >
          {t('mobileMenu.markets.tabs.favourites')}
        </div>
      </div>
      <div className={classNames('biab_tab-wrapper', styles.favorites__tabWrapper)}>
        <div
          className={classNames('biab_tab', styles.favorites__tab, {
            biab_active: !isFavorites
          })}
          onClick={() => onChangeTab(!isFavorites)}
        >
          {t('mobileMenu.markets.tabs.activeMarkets')}
          {!!marketsCount && ` (${marketsCount})`}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
