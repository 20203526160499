import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import ReloadBtn from 'components/ReloadBtn';
import { asianViewMiddleSection as branding } from 'constants/branding';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { getEventCashOutMarketsContent, getEventCashOutQuotesMarketsIds } from 'redux/modules/eventCashOut/selectors';

import Content from '../Content';

import styles from './styles.module.scss';

type EventCashOutListProps = {
  eventId: string;
  onReload: () => void;
};

const EventCashOutList = ({ eventId, onReload }: EventCashOutListProps) => {
  const eventContent = useSelector(getEventCashOutMarketsContent);
  const isMobile = useSelector(getIsMobileAsianView);
  const quotesMarketsIds = useSelector(getEventCashOutQuotesMarketsIds);

  const cashOutMarkets = useMemo(() => {
    return eventContent.filter(({ marketId }) => quotesMarketsIds.includes(marketId));
  }, [eventContent, quotesMarketsIds]);

  if (!cashOutMarkets.length) {
    return null;
  }

  return (
    <>
      <ReloadBtn
        isShowTitle
        containerClassName={classNames(styles.eventCashOutList__reloadBtn, branding.COUPON_BUTTON, {
          [styles.eventCashOutList__reloadBtn__mobile]: isMobile
        })}
        labelClassName={classNames({ [styles.eventCashOutList__reloadBtn__labelMobile]: isMobile })}
        iconClassName={classNames({ [styles.eventCashOutList__reloadBtn__iconMobile]: isMobile })}
        eventId={eventId}
        onReload={onReload}
      />
      <Content markets={cashOutMarkets} isTab />
    </>
  );
};

export default EventCashOutList;
