import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Notification from 'components/Notification';
import {
  BET_WAS_NOT_PLACED,
  VALIDATION_ERROR_BET_OUTDATED_LINE,
  VALIDATION_ERROR_BET_OUTDATED_ODDS
} from 'constants/placement';
import { useMarketUnits } from 'hooks/useMarketUnits';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { updateSelectedBet } from 'redux/modules/betslip';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { TSelectedBet } from 'redux/modules/betslip/type';
import { getPlacedBetStatusByOfferId } from 'redux/modules/betsStatuses/selectors';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { removePlacedSelectedBet } from 'redux/modules/placement';
import { getPlacedSelectedBetErrorMessage, getPlacedSelectedBetOfferId } from 'redux/modules/placement/selectors';
import { isLineBettingType } from 'utils/betslip';

type TSelectedBetErrorProps = {
  bet: TSelectedBet;
};

const SelectedBetError = ({ bet }: TSelectedBetErrorProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const placedSelectedBetOfferId = useSelector(getPlacedSelectedBetOfferId(bet.betUuid ?? ''));
  const placedSelectedBetErrorMessage = useSelector(getPlacedSelectedBetErrorMessage(bet.betUuid ?? ''));
  const placedSelectedBetStatus = useSelector(getPlacedBetStatusByOfferId(placedSelectedBetOfferId));
  const marketUnitTranslated = useMarketUnits((bet?.marketUnit || bet?.lineRangeInfo?.marketUnit) ?? 'points');

  const resetErrorMessage = () => {
    if (bet.validationMessage?.text) {
      dispatch(updateSelectedBet({ marketId, betUuid, type, validationMessage: null }));
    }
  };

  let message = '';
  let onClose = () => {
    dispatch(removePlacedSelectedBet({ betUuid: bet.betUuid ?? '' }));
  };

  const { marketId, betUuid, type, isCancelled, bettingType } = bet;

  if (isCancelled && isPNCEnabled && !isGameBetSlip) {
    const isLineMarket = isLineBettingType(bettingType ?? '');
    message = isLineMarket
      ? t(VALIDATION_ERROR_BET_OUTDATED_LINE, { unit: marketUnitTranslated })
      : t(VALIDATION_ERROR_BET_OUTDATED_ODDS);
    onClose = () => {
      dispatch(
        updateSelectedBet({
          type: bet.type,
          marketId: bet.marketId,
          betUuid: bet.betUuid,
          isCancelled: false
        })
      );
    };
  } else if (bet.validationMessage?.text) {
    message = bet.validationMessage?.text;
    onClose = () => {
      dispatch(updateSelectedBet({ marketId, betUuid, type, validationMessage: null }));
    };
  } else if (placedSelectedBetErrorMessage) {
    message = placedSelectedBetErrorMessage;
  } else if (placedSelectedBetStatus === BetsStatusesTypes.EXPIRED) {
    message = t(BET_WAS_NOT_PLACED);
  } else {
    return null;
  }

  return (
    <div>
      <Notification type="warning" message={message} onClose={onClose} onClickOutside={resetErrorMessage} />
    </div>
  );
};

export default SelectedBetError;
