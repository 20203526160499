export const MARKET_TYPES = {
  altTotalGoals: 'ALT_TOTAL_GOALS',
  allTotalGoals: 'ALL_TOTAL_GOALS',
  totalGoals: 'TOTAL_GOALS',
  combinedTotal: 'COMBINED_TOTAL',
  handicap: 'HANDICAP',
  matchOdds: 'MATCH_ODDS',
  asianHandicap: 'ASIAN_HANDICAP',
  firstHalfGoals: 'FIRST_HALF_GOALS',
  asianHandicapUnManaged: 'ASIAN_HCAP_UNMANAGED',
  asianHandicapDoubleLine: 'ASIAN_HANDICAP_DOUBLE_LINE',
  asianHandicapSingleLine: 'ASIAN_HANDICAP_SINGLE_LINE',
  eachWay: 'EACH_WAY',
  bothTeamsToScore: 'BOTH_TEAMS_TO_SCORE',
  oddOrEven: 'ODD_OR_EVEN',
  drawNoBet: 'DRAW_NO_BET',
  halfTime: 'HALF_TIME',
  anyNumberOfWinners: 'ANY_NUMBER_OF_WINNERS',
  variableHandicap: 'VARIABLE_HANDICAP',
  firstQuarterHandicap: 'FIRST_QUARTER_HANDICAP',
  halfTimeHandicap: 'HALF_TIME_HANDICAP',
  tournamentWinner: 'TOURNAMENT_WINNER',
  outrightWinner: 'OUTRIGHT_WINNER',
  winner: 'WINNER',
  undifferentiated: 'UNDIFFERENTIATED',
  unused: 'UNUSED',
  topNFinish: 'TOP_N_FINISH',
  topGoalscorer: 'TOP_GOALSCORER',
  topBatsman: 'TOP_BATSMAN',
  special: 'SPECIAL',
  rockBottom: 'ROCK_BOTTOM',
  nonSport: 'NONSPORT',
  firstGoalScorer: 'FIRST_GOAL_SCORER',
  firstInnOverTotal4: '1_INN_4_OVR_TOTAL',
  firstInnOverTotal6: '1_INN_6_OVR_TOTAL',
  firstInnOverTotal10: '1_INN_10_OVR_TOTAL',
  firstInnOverTotal15: '1_INN_15_OVR_TOTAL',
  firstInnOverTotal20: '1_INN_20_OVR_TOTAL',
  secondInnOverTotal4: '2_INN_4_OVR_TOTAL',
  secondInnOverTotal6: '2_INN_6_OVR_TOTAL',
  secondInnOverTotal10: '2_INN_10_OVR_TOTAL',
  secondInnOverTotal15: '2_INN_15_OVR_TOTAL',
  secondInnOverTotal20: '2_INN_20_OVR_TOTAL',
  totalPointsLine: 'TOTAL_POINTS_LINE',
  matchOddsHandicap: 'MATCH_ODDS_HANDICAP',
  matchShots: 'MATCH_SHOTS',
  matchShotsTarget: 'MATCH_SHOTS_TARGET',
  toScoreHattrick: 'TO_SCORE_HATTRICK',
  anytimeAssist: 'ANYTIME_ASSIST',
  shotsOnTargetP1: 'SHOTS_ON_TARGET_P1',
  shotsOnTargetP2: 'SHOTS_ON_TARGET_P2',
  shotsOnTargetP3: 'SHOTS_ON_TARGET_P3',
  shownACard: 'SHOWN_A_CARD',
  toScore: 'TO_SCORE',
  toScore2OrMore: 'TO_SCORE_2_OR_MORE',
  dailyGoals: 'DAILY_GOALS',
  forecast: 'FORECAST',
  reverseForecast: 'REV_FORECAST',
  antepostWin: 'ANTEPOST_WIN',
  teambInnRuns: 'TEAMB_1ST_INN_RUNS',
  halfTimeScore: 'HALF_TIME_SCORE',
  halfTimeFullTime: 'HALF_TIME_FULL_TIME',
  matchOddsAndOu: 'MATCH_ODDS_AND_OU_25',
  firstGoalScore: 'FIRST_GOAL_SCORER'
} as const;

export const dontRaceRequest = [MARKET_TYPES.antepostWin, MARKET_TYPES.dailyGoals];

export const longSelectionsList = [
  'EACH_WAY',
  'TOURNAMENT_WINNER',
  'OUTRIGHT_WINNER',
  'WINNER',
  'UNDIFFERENTIATED',
  'UNUSED',
  'TOP_N_FINISH',
  'TOP_5_FINISH',
  'TOP_8_FINISH',
  'TOP_10_FINISH',
  'TOP_20_FINISH',
  'TOP_40_FINISH',
  'TOP_GOALSCORER',
  'TOP_BATSMAN',
  'SPECIAL',
  'ROCK_BOTTOM',
  'NONSPORT',
  'FIRST_GOAL_SCORER',
  'TEAM_A_TOP_BATTER',
  'TEAM_B_TOP_BATTER'
];

export const dontShowWhatIf = [
  MARKET_TYPES.handicap,
  MARKET_TYPES.totalPointsLine,
  MARKET_TYPES.asianHandicap,
  MARKET_TYPES.teambInnRuns
];

export const NumberOfPairs = {
  default: {
    pairs: 1,
    group: 1,
    step: 2
  },
  [MARKET_TYPES.asianHandicapUnManaged]: {
    pairs: 2,
    group: 2,
    step: 1
  },
  [MARKET_TYPES.asianHandicap]: {
    pairs: 2,
    group: 2,
    step: 1
  },
  [MARKET_TYPES.handicap]: {
    pairs: 2,
    group: 2,
    step: 2
  },
  [MARKET_TYPES.combinedTotal]: {
    pairs: 0,
    group: 2,
    step: 1
  },
  [MARKET_TYPES.altTotalGoals]: {
    pairs: 3,
    group: 2,
    step: 1
  }
};

export const COMBINED_DEFAULT_UNIT = 'points';

export const inningTypeMarket = 'inning';

export const inningsMarket = (marketType: string) => marketType.toLowerCase().includes(inningTypeMarket);
