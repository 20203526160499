import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';

import { TGetPSFooterPayload, TPokerstars } from './types';

const initialState: TPokerstars = {
  loading: false,
  error: null,
  psFooter: null
};

const slice = createSlice({
  name: SLICES_NAMES.POKER_STAR,
  initialState,
  reducers: {
    fetchPSFooter: (state, _: PayloadAction<TGetPSFooterPayload>) => {
      state.loading = true;
    },
    successFetchPSFooter: (state, { payload }: PayloadAction<string>) => {
      state.psFooter = payload;
      state.loading = false;
    },
    failureFetchPSFooter: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
    }
  }
});

export const { failureFetchPSFooter, fetchPSFooter, successFetchPSFooter } = slice.actions;

export default slice.reducer;
