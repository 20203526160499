import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useResizeObserver } from 'usehooks-ts';

import { OPERATOR } from 'constants/app';
import { getAppDevice, getOperator } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getElementHeightByName, getScrollContentHeight } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';

export default function useAddHeightForFooter<T extends HTMLElement = HTMLDivElement>() {
  const device = useSelector(getAppDevice);
  const footerHeight = useSelector(getElementHeightByName(EElementNames.FOOTER_HEIGHT));
  const totalHeight = useSelector(getScrollContentHeight);
  const operator = useSelector(getOperator);

  const target = useRef<T>(null);
  const { height: targetHeight = 0 } = useResizeObserver({ ref: target, box: 'border-box' });

  const isPokerStars = operator === OPERATOR.POKER_STAR;
  const isAdjara = operator === OPERATOR.AB;
  const calcAddedHeight = totalHeight - footerHeight - targetHeight;
  const addedHeight =
    device === Devices.DESKTOP && calcAddedHeight > 0 && !isPokerStars && !isAdjara ? calcAddedHeight : 0;

  return { target, addedHeight, targetHeight };
}
