import { TBetslipType } from 'redux/modules/placement/type';
import { TContentParent } from 'redux/modules/search/type';
import { BetTypes, THandicap, TPrice, TProfit, TSize } from 'types/bets';
import { ERunnerStatuses, TKeyLineDefinition, TMarketLineRangeInfo, TPriceLadderDescription } from 'types/markets';

import { MarketStatus } from './index';

export enum EBetslipTypes {
  EXCHANGE = 'EXCHANGE',
  GAME = 'GAME'
}

export enum EInputFieldTypes {
  PRICE = 'PRICE',
  SIZE = 'SIZE',
  PROFIT = 'PROFIT',
  NONE = 'NONE'
}

export enum EActionTypes {
  ADD = 'ADD',
  SUBSTR = 'SUBSTR'
}

export enum EPlacementType {
  PLACE = 'PLACE',
  UPDATE = 'UPDATE',
  CANCEL = 'CANCEL'
}

export enum EPlacementStatus {
  INIT = 'INIT',
  GET_STATUSES = 'GET_STATUSES',
  GET_PLACED_BET = 'GET_PLACED_BET',
  CANCEL = 'CANCEL'
}

export enum EFormActions {
  NONE = 'NONE',
  VALIDATE = 'VALIDATE',
  PROFIT_EDIT = 'PROFIT_EDIT'
}

export type TBetLiability = {
  marketId: string;
  selectionId: number;
  handicap?: number | string | null;
  type: BetTypes;
  price: TPrice | undefined;
  size?: TSize;
  profit?: TProfit;
};

export type BetMessageType = 'success' | 'warning' | 'info' | null;

export type TSelectionLiability = TBetslipMarketRunner & {
  backLiability: number;
  layLiability: number;
};

export type TLiabilityBySelection = {
  selectionId: number;
  handicap: number | string | null;
  liability: number;
};

export type TLineMarketPL = {
  line: number;
  backProfit?: number;
  backLoss?: number;
  layProfit?: number;
  layLoss?: number;
  total?: number;
};

export enum ELineSide {
  UNDER = 'UNDER',
  OVER = 'OVER'
}

export enum EAHVirtualOutcome {
  WIN = 'WIN',
  HALF_WIN = 'HALF_WIN',
  STAKE_REFUND = 'STAKE_REFUND',
  HALF_LOSS = 'HALF_LOSS',
  LOSS = 'LOSS'
}

export enum ECancelAllBtnState {
  ACTIVE = 'ACTIVE',
  CONFIRM = 'CONFIRM',
  CANCELLING = 'CANCELLING',
  HIDDEN = 'HIDDEN'
}

export enum EBetTooltips {
  ODDS = 'ODDS',
  SIZE = 'SIZE',
  PROFIT = 'PROFIT'
}

export enum EBetActions {
  NONE = 'NONE',
  PROGRESS = 'PROGRESS',
  UPDATED = 'UPDATED',
  CANCELLED = 'CANCELLED'
}

export type TBetLabelDataItem = {
  betType: BetTypes;
  label?: string;
  profitLoss?: number | null;
  PLFormatted?: string;
  className?: string;
  isEnabled?: boolean;
  currency?: string;
  isHtml?: boolean;
  isSwapColors?: boolean;
};

export type TBetslipMarket = {
  marketId: string;
  marketName?: string;
  marketNameTranslations?: Record<string, string>;
  marketStartTime?: number;
  description: {
    marketType: string;
    bettingType: string;
    eachWayDivisor?: number | null;
    lineRangeInfo?: TMarketLineRangeInfo | null;
    priceLadderDescription?: TPriceLadderDescription | null;
  };
  runners: TBetslipMarketRunner[];
  eventType?: {
    id?: string;
    name?: string;
  };
  event?: {
    id: string;
    name: string;
    nameTranslations?: Record<string, string>;
  };
  parents?: TContentParent[];
  numberOfWinners?: number;
  cashOutEnabled?: boolean;
  partialCashOutEnabled?: boolean;
  inPlay?: boolean;
  commission?: number;
  commissionRate?: number;
  fancyView?: boolean;
  gameId?: string;
  gameName?: string;
  gameType?: string;
  round?: number;
};

export type TBetslipMarketRunner = {
  selectionId: number;
  runnerName?: string;
  runnerNameTranslations?: Record<string, string>;
  handicap: number | null;
  lineSide?: ELineSide | null;
  status?: ERunnerStatuses | string;
};

export type TBetslipMarketDefinition = {
  marketType: string;
  numberOfWinners?: number;
  inPlay?: boolean;
  bettingType?: string;
  numberOfActiveRunners?: number;
  eventId?: string;
  persistenceEnabled?: boolean;
  runners?: TBetslipMarketPricesRunner[];
  eventTypeId?: string;
  complete?: boolean;
  status?: MarketStatus | string;
  keyLineDefinition?: TKeyLineDefinition;
};

export type TBetslipMarketPrices = {
  marketDefinition: TBetslipMarketDefinition;
  rc: TBetslipMarketPricesRC[];
  currency: string;
};

export type TBetslipMarketPricesRunner = {
  runnerName?: string;
  sortPriority?: number;
  removalDate?: number;
  id?: number;
  hc?: null;
  adjustmentFactor?: number;
  bsp?: null;
  status?: ERunnerStatuses | string;
  selectionId: number;
  handicap: number | null;
};

export type TBetslipMarketPricesRC = {
  id: number;
  hc?: null;
  tv?: number;
  locked?: boolean;
  bdatb: TBetslipMarketPrice[];
  bdatl: TBetslipMarketPrice[];
};

export type TBetslipMarketPrice = {
  index: number;
  odds: number;
  amount: number;
};

export type TLabels = {
  [key in BetTypes]?: {
    bet: string;
    odds: string;
    stake: string;
    profit: string;
  };
};

export type TLiabilityBet = {
  marketId: string;
  selectionId: number;
  handicap?: THandicap | null;
  betType: BetTypes;
  price?: TPrice;
  size?: TSize;
  profit?: TProfit;
  isDisabled?: boolean;
};

export type TSelectedBetKeyFields = {
  marketId: string;
  selectionId: number;
  handicap?: string | number | null;
  betslipType?: TBetslipType;
};

export type TCalculateLiabilityOptions = {
  /** Market types from market description */
  marketType?: string;
  /** Betting type from market description */
  bettingType?: string;
  /** Each way divider from market description */
  eachWayDivisor?: number | null;
  /** BACK or LAY type */
  betType?: BetTypes;
};

export type TFormLabels = { bet: string; odds: string; stake: string; profit: string };

export type TFormCustomClassNames = {
  form?: string;
  price?: string;
  size?: string;
  profit?: string;
  priceWrap?: string;
  betPriceWrap?: string;
  priceIndicator?: string;
  pncIndicator?: string;
};
