import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';

import { WebSocketInitState } from './type';

const initialState: WebSocketInitState = {
  subscribeToGeneralMessages: null,
  isConnectedGeneralWebSocket: false,
  onCloseGeneralWebSocket: null,
  subscribeToAsianViewGeneralMessages: null,
  isConnectedAsianViewGeneralWebSocket: false,
  onCloseAsianViewGeneralWebSocket: null
};

const slice = createSlice({
  name: SLICES_NAMES.WEB_SOCKET,
  initialState,
  reducers: {
    setSubscribeToGeneralMessages: (state, { payload }: PayloadAction<(<T>(params: T) => void) | null>) => {
      state.subscribeToGeneralMessages = payload;
    },
    setIsConnectedGeneralWebSocket: (state, { payload }: PayloadAction<boolean>) => {
      state.isConnectedGeneralWebSocket = payload;
    },
    setSubscribeToAsianViewGeneralMessages: (state, { payload }: PayloadAction<(<T>(params: T) => void) | null>) => {
      state.subscribeToAsianViewGeneralMessages = payload;
    },
    setIsConnectedAsianViewGeneralWebSocket: (state, { payload }: PayloadAction<boolean>) => {
      state.isConnectedAsianViewGeneralWebSocket = payload;
    }
  }
});

export const {
  setSubscribeToGeneralMessages,
  setSubscribeToAsianViewGeneralMessages,
  setIsConnectedAsianViewGeneralWebSocket,
  setIsConnectedGeneralWebSocket
} = slice.actions;

export default slice.reducer;
