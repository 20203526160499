import { AppState } from 'redux/reducers';

export const getProfitLossHalfTimeTableData = ({ profitLossTablePopUp }: AppState) =>
  profitLossTablePopUp.profitLossTableData?.htpl;
export const getProfitLossFullTimeTableData = ({ profitLossTablePopUp }: AppState) =>
  profitLossTablePopUp.profitLossTableData?.ftpl;
export const getProfitLossTablePopUpEventId = ({ profitLossTablePopUp }: AppState) =>
  profitLossTablePopUp.profitLossTablePopUpEventId;
export const getProfitLossTablePopUpMarketId = ({ profitLossTablePopUp }: AppState) =>
  profitLossTablePopUp.profitLossTablePopUpMarketId;
export const getProfitLossTableEventName = ({ profitLossTablePopUp }: AppState) =>
  profitLossTablePopUp.profitLossTableData?.eventName;
export const getProfitLossTableDataError = ({ profitLossTablePopUp }: AppState) =>
  profitLossTablePopUp.profitLossTableDataError;
export const getProfitLossTableDataLoading = ({ profitLossTablePopUp }: AppState) =>
  profitLossTablePopUp.profitLossTableDataLoading;
export const getIsProfitLossTableDataFirstLoaded = ({ profitLossTablePopUp }: AppState) =>
  profitLossTablePopUp.profitLossTableDataFirstLoaded;
export const getStringifiedProfitLossTableData = ({ profitLossTablePopUp }: AppState) =>
  profitLossTablePopUp.stringifiedProfitLossTableData;
export const getPLHTMarkets = ({ profitLossTablePopUp }: AppState) => profitLossTablePopUp.profitLossTableHTMarkets;
export const getPLFTMarkets = ({ profitLossTablePopUp }: AppState) => profitLossTablePopUp.profitLossTableFTMarkets;
