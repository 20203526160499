import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PopularMarket from 'components/PopularMarket';
import SEO from 'components/SEO';
import SportPageModule from 'components/SportPageModule';
import SportsSection from 'components/SportsSection';
import { getAppDevice, getIsShowMarketOdd, getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { MetaPage } from 'redux/modules/meta/type';
import { fetchPopularMarkets } from 'redux/modules/popularMarket';
import { SportPageContextFilter, SportPageType } from 'redux/modules/sportPage/type';
import { fetchSports } from 'redux/modules/sports';
import { PlacementPage } from 'types';

const CountrySportPage = () => {
  const { countryId } = useParams();
  const dispatch = useDispatch();

  const timezone = useSelector(getTimezone);
  const device = useSelector(getAppDevice);
  const language = useSelector(getLanguage);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isShowMarketOdds = useSelector(getIsShowMarketOdd);

  useEffect(() => {
    dispatch(fetchSports());
  }, [timezone, isLoggedIn, language]);

  useEffect(() => {
    if (isShowMarketOdds) {
      dispatch(fetchPopularMarkets());
    }
  }, [isLoggedIn, dispatch, isShowMarketOdds]);

  return (
    <>
      <SEO page={MetaPage.COUNTRY} id={countryId} />
      {device === Devices.DESKTOP && <PopularMarket page={PlacementPage.COUNTRY_ODDS_BANNER} />}
      {device === Devices.MOBILE && <SportsSection />}
      {device === Devices.DESKTOP && (
        <SportPageModule
          pageDetailsId={countryId ?? ''}
          contextFilter={SportPageContextFilter.COUNTRY}
          pageType={SportPageType.COUNTRY}
          placementPage={PlacementPage.COUNTRY}
        />
      )}
    </>
  );
};

export default CountrySportPage;
