import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import AsianViewMobileMenuButton from 'components/AsianViewMobileHeader/partials/AsianViewMobileMenuButton';
import ListItem from 'components/AsianViewPageModule/components/CouponSettingsContent/partials/ListItem';
import AsianToolbarWrapper from 'components/MobileToolbar/components/AsianToolbarWrapper';
import Modal from 'components/Modal';
import {
  AV_BET_LIST_MOBILE_SEARCH_MAX_LENGTH,
  AV_BET_LIST_MOBILE_SORTING_VALUES,
  AV_MOBILE_BET_LIST_SEARCH_PARAM_MIN_LENGTH
} from 'constants/betList';
import { asianViewComponents, asianViewMiddleSection as branding } from 'constants/branding';
import useElementSize from 'hooks/useElementSize';
import { getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import { CookieNames } from 'types';

import styles from './AVBetListMobileHeader.module.scss';

const sortingItems = [
  {
    id: 'placement',
    value: AV_BET_LIST_MOBILE_SORTING_VALUES.placementTime,
    icon: 'av-icon av-icon-sorting-placement-time',
    label: 'asianView.labels.betList.sorting.byPlacementTime'
  },
  {
    id: 'event-start',
    value: AV_BET_LIST_MOBILE_SORTING_VALUES.eventStartTime,
    icon: 'av-icon av-icon-sorting-by-time',
    label: 'asianView.labels.betList.sorting.byEventStartTime'
  }
];

type AVBetListMobileHeaderProps = {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  resetPage: () => void;
};

const AVBetListMobileHeader = ({ searchValue, setSearchValue, resetPage }: AVBetListMobileHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies, setCookies] = useCookies([CookieNames.ASIAN_VIEW_BET_LIST_SORTING]);

  const isLandscapeView = useSelector(getIsLandscapeAsianView);
  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const initSortingValue = cookies.ASIAN_VIEW_BET_LIST_SORTING || AV_BET_LIST_MOBILE_SORTING_VALUES.placementTime;

  const [isSortingModalOpened, setIsSortingModalOpened] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [selectedSort, setSelectedSort] = useState(initSortingValue);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const prevSearchValue = useRef('');

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.MOBILE_ASIAN_SUB_HEADER, height }));
    }
  });

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.length >= AV_MOBILE_BET_LIST_SEARCH_PARAM_MIN_LENGTH) {
      resetPage();

      if (prevSearchValue.current.length < AV_MOBILE_BET_LIST_SEARCH_PARAM_MIN_LENGTH) {
        window.scrollTo({ top: 0 });
      }
    }

    if (
      value.length < AV_MOBILE_BET_LIST_SEARCH_PARAM_MIN_LENGTH &&
      prevSearchValue.current.length >= AV_MOBILE_BET_LIST_SEARCH_PARAM_MIN_LENGTH
    ) {
      window.scrollTo({ top: 0 });
    }

    if (value.length <= AV_BET_LIST_MOBILE_SEARCH_MAX_LENGTH) {
      setSearchValue(e.target.value);
      prevSearchValue.current = value;
    }
  };

  const handleChangeSorting = (newSorting: string) => {
    if (newSorting !== selectedSort) {
      setSelectedSort(newSorting);
      resetPage();
    }
  };

  const handleApply = () => {
    if (selectedSort !== initSortingValue) {
      setCookies(CookieNames.ASIAN_VIEW_BET_LIST_SORTING, selectedSort, { path: '/' });
      setIsSortingModalOpened(false);
    }
  };

  const handleCloseSearch = () => {
    setIsSearchExpanded(false);
    setSearchValue('');

    if (prevSearchValue.current.length >= AV_MOBILE_BET_LIST_SEARCH_PARAM_MIN_LENGTH) {
      window.scrollTo({ top: 0 });
    }

    prevSearchValue.current = '';
  };

  const handleClearSearch = () => {
    setSearchValue('');

    if (prevSearchValue.current.length >= AV_MOBILE_BET_LIST_SEARCH_PARAM_MIN_LENGTH) {
      window.scrollTo({ top: 0 });
    }

    prevSearchValue.current = '';
  };

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.MOBILE_ASIAN_SUB_HEADER, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (isSearchExpanded && searchInputRef.current) {
      searchInputRef.current?.focus();
    }
  }, [isSearchExpanded]);

  return (
    <>
      <AsianToolbarWrapper>
        <div
          ref={componentRef}
          className={classNames(styles.header, branding.MOBILE_SUB_HEADER, {
            [styles.header__expand]: isSearchExpanded
          })}
        >
          {!isLandscapeView && isSearchExpanded && <AsianViewMobileMenuButton />}
          <div
            className={classNames(styles.header__left, branding.MOBILE_SEARCH, {
              [styles.header__left__expand]: isSearchExpanded,
              [branding.MOBILE_SEARCH_EXPANDED]: isSearchExpanded
            })}
          >
            {!isLandscapeView && !isSearchExpanded && <AsianViewMobileMenuButton />}
            {isSearchExpanded ? (
              <>
                <i className={classNames('fa2 fa2-search', styles.header__btn__icon)} />
                <input
                  type="text"
                  placeholder={t('asianView.labels.betList.search')}
                  onChange={handleChangeSearch}
                  value={searchValue}
                  className={classNames(styles.header__search, branding.SEARCH_INPUT)}
                  maxLength={AV_BET_LIST_MOBILE_SEARCH_MAX_LENGTH}
                  ref={searchInputRef}
                />
                {!!searchValue && (
                  <button onClick={handleClearSearch} className={styles.header__clear}>
                    {t('asianView.labels.search.clear')}
                  </button>
                )}
              </>
            ) : (
              <div className={styles.header__noExpandedMiddle}>
                <button
                  className={classNames(styles.header__btn, branding.MOBILE_SUB_HEADER_BTN, {
                    [styles.header__btn__noLandscape]: !isLandscapeView
                  })}
                  onClick={() => setIsSearchExpanded(true)}
                >
                  <i className={classNames('fa2 fa2-search', styles.header__btn__icon)} />
                  {isLandscapeView && <span>{t('asianView.labels.search')}</span>}
                </button>
                <div className={styles.header__betList}>
                  <i className={classNames('av-icon av-icon-list', styles.header__listIcon)} />
                  <h3 className={styles.header__title}>{t('asianView.labels.betList')}</h3>
                </div>
              </div>
            )}
          </div>
          {isSearchExpanded ? (
            <button className={styles.header__closeIcon} onClick={handleCloseSearch}>
              <i className={classNames('biab_tour-icon-close')} />
            </button>
          ) : (
            <button
              className={classNames(styles.header__btn, branding.MOBILE_SUB_HEADER_BTN, {
                [styles.header__btn__noLandscape]: !isLandscapeView
              })}
              onClick={() => setIsSortingModalOpened(true)}
            >
              <i className={classNames('av-icon av-icon-sorting', styles.header__btn__icon)} />
              {isLandscapeView && <span>{t('asianView.labels.betList.sorting')}</span>}
            </button>
          )}
        </div>
      </AsianToolbarWrapper>
      {isSortingModalOpened && !isSearchExpanded && (
        <Modal
          open={isSortingModalOpened}
          clickableBackground
          noCloseIcon
          title={t('asianView.labels.betList.settings')}
          customClassNames={{
            header: styles.modal__header,
            body: styles.modal__body,
            title: styles.modal__title,
            dialog: classNames(styles.modal__dialog, {
              [styles.modal__dialog__standalone]: !isIframeEnabled,
              [styles.modal__dialog__iframe]: isIframeEnabled
            }),
            content: styles.modal__content
          }}
          onClose={() => setIsSortingModalOpened(false)}
        >
          <div className={styles.modal__body__content}>
            <h6 className={styles.modal__sortingTitle}>{t('asianView.labels.betList.sorting')}</h6>
            {sortingItems.map(({ id, value, label, icon }) => (
              <ListItem
                key={id}
                value={value}
                label={t(label)}
                icon={icon}
                selected={selectedSort === value}
                onSelect={handleChangeSorting}
              />
            ))}
          </div>
          <div className={styles.modal__actions}>
            <button
              className={classNames(
                styles.modal__actions__btn,
                styles.modal__actions__btn__cancel,
                asianViewComponents.SECONDARY_BTN
              )}
              onClick={() => setIsSortingModalOpened(false)}
            >
              {t('asianView.labels.leaguesFilter.cancel')}
            </button>
            <button
              className={classNames(
                styles.modal__actions__btn,
                styles.modal__actions__btn__apply,
                asianViewComponents.PRIMARY_BTN,
                {
                  [styles.modal__actions__btn__disabled]: selectedSort === initSortingValue
                }
              )}
              onClick={handleApply}
              disabled={selectedSort === initSortingValue}
            >
              {t('asianView.labels.leaguesFilter.apply')}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AVBetListMobileHeader;
