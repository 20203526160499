import { MutableRefObject, useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import BettingProfitAndLoss from 'components/BettingProfitAndLoss';
import Footer from 'components/Footer';
import PokerStarsFooter from 'components/PokerStarsFooter';
import { AB, POKER_STAR } from 'constants/icons';
import {
  ACCOUNT_BASE_URL,
  ACCOUNT_STATEMENT_BASE_URL,
  BETTING_PROFIT_LOSS_BASE_URL,
  MY_BETS_BASE_URL
} from 'constants/locations';
import {
  BetTypeValue,
  HEADER_BOTTOM_MARGIN,
  MIN_AVAILABLE_ITEMS_COUNT_WITHOUT_ADDITIONAL_HEIGHT,
  MY_BETS_IFRAME_ADDITIONAL_HARDCODED_HEIGHT,
  NavigationButtons,
  PL_DESKTOP_IFRAME_ADDITIONAL_HARDCODED_HEIGHT,
  PL_STATEMENT_MOBILE_IFRAME_ADDITIONAL_HARDCODED_HEIGHT,
  PLBetType,
  STATEMENT_MOBILE_IFRAME_ADDITIONAL_HARDCODED_HEIGHT
} from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import useElementSize from 'hooks/useElementSize';
import AccountStatementPage from 'pages/AccountStatementPage/AccountStatementPage';
import MyExchangeBetsPage from 'pages/MyExchangeBetsPage/MyExchangeBetsPage';
import { getIsIframeEnabled, getOperator } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getMyBetsGamesCount, getMyBetsSportsCount, getTableDataCountByPage } from 'redux/modules/myBets/selectors';
import { CookieNames } from 'types';
import { TFetchDataWithCurrencyHandlers, TGetBetAndPageDataParams, TGetPLData, TGetStatementData } from 'types/myBets';
import { iosBundleRedirect } from 'utils';

import styles from './MyBetsPagesContent.module.scss';

type MyBetsPagesContentProps = {
  fetchDataWithCurrencyHandlers: MutableRefObject<TFetchDataWithCurrencyHandlers>;
  onChangeBetType: MutableRefObject<((betType: BetTypeValue) => void) | undefined>;
  isAsianViewEnabled: boolean;
  selectedTab: string;
  activePLTab: PLBetType;
  headerHeight: number;
};

const MyBetsPagesContent = ({
  fetchDataWithCurrencyHandlers,
  onChangeBetType,
  isAsianViewEnabled,
  selectedTab,
  activePLTab,
  headerHeight
}: MyBetsPagesContentProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [cookies] = useCookies([CookieNames.PROFITANDLOSS_BETTYPE]);

  const isMyBetsPage = location.pathname.includes(`${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}`);
  const isProfitLossPage = location.pathname.includes(`${ACCOUNT_BASE_URL}${BETTING_PROFIT_LOSS_BASE_URL}`);
  const isStatementPage = location.pathname.includes(`${ACCOUNT_BASE_URL}${ACCOUNT_STATEMENT_BASE_URL}`);

  const operator = useSelector(getOperator);
  const headerHeightFromStore = useSelector(getElementHeightByName(EElementNames.HEADER_HEIGHT));
  const footerHeight = useSelector(getElementHeightByName(EElementNames.FOOTER_HEIGHT));
  const iframeEnabled = useSelector(getIsIframeEnabled);
  const dataCount = useSelector(getTableDataCountByPage({ isStatementPage, isMyBetsPage, isProfitLossPage }));
  const plSportsCount = useSelector(getMyBetsSportsCount);
  const plGamesCount = useSelector(getMyBetsGamesCount);

  const { isDesktop, isMobile } = useDevice();
  const [scrollableContentRef, { height }] = useElementSize<HTMLDivElement>();

  const showPokerStarFooter = operator === POKER_STAR && iosBundleRedirect;
  let additionalHeight = 0;

  if (iframeEnabled && operator === AB) {
    if (isDesktop) {
      if (selectedTab === NavigationButtons.ProfitAndLoss) {
        if (dataCount === 0) {
          additionalHeight = MY_BETS_IFRAME_ADDITIONAL_HARDCODED_HEIGHT;
        } else if (dataCount < 6) {
          additionalHeight = PL_DESKTOP_IFRAME_ADDITIONAL_HARDCODED_HEIGHT;
        }
      } else if (selectedTab === NavigationButtons.Statement) {
        if (dataCount === 2) {
          additionalHeight = MY_BETS_IFRAME_ADDITIONAL_HARDCODED_HEIGHT + 2;
        } else if (dataCount < 2) {
          additionalHeight = MY_BETS_IFRAME_ADDITIONAL_HARDCODED_HEIGHT;
        }
      } else if (dataCount < 2) {
        additionalHeight = MY_BETS_IFRAME_ADDITIONAL_HARDCODED_HEIGHT;
      }
    } else if (isMobile) {
      if (selectedTab === NavigationButtons.ProfitAndLoss) {
        const allSportsItemsCount = activePLTab === PLBetType.Sports ? plSportsCount : plGamesCount;

        if (
          cookies.PROFITANDLOSS_BETTYPE !== PLBetType.AsianView &&
          allSportsItemsCount > MIN_AVAILABLE_ITEMS_COUNT_WITHOUT_ADDITIONAL_HEIGHT
        ) {
          if (dataCount < 2) {
            additionalHeight =
              PL_STATEMENT_MOBILE_IFRAME_ADDITIONAL_HARDCODED_HEIGHT +
              (allSportsItemsCount - MIN_AVAILABLE_ITEMS_COUNT_WITHOUT_ADDITIONAL_HEIGHT - dataCount) *
                PL_STATEMENT_MOBILE_IFRAME_ADDITIONAL_HARDCODED_HEIGHT;
          }
        } else if (dataCount === 0) {
          additionalHeight = PL_STATEMENT_MOBILE_IFRAME_ADDITIONAL_HARDCODED_HEIGHT;
        }
      } else if (selectedTab === NavigationButtons.Statement) {
        if (dataCount === 0) {
          additionalHeight = STATEMENT_MOBILE_IFRAME_ADDITIONAL_HARDCODED_HEIGHT;
        } else if (dataCount === 1) {
          additionalHeight = PL_STATEMENT_MOBILE_IFRAME_ADDITIONAL_HARDCODED_HEIGHT;
        }
      }
    }
  }

  const contentStyles = isMobile
    ? { minHeight: `calc(100vh - ${footerHeight}px)` }
    : { height: `calc(100vh - (${headerHeightFromStore}px + 100px))` };

  const setOnChangeBetType = (handler: (betType: BetTypeValue) => void) => {
    onChangeBetType.current = handler;
  };

  const collectHandlers = useCallback(
    (
      name: 'fetchBetDataHandler' | 'fetchPLDataHandler' | 'fetchStatementDataHandler',
      handler: (params: TGetBetAndPageDataParams | TGetPLData | TGetStatementData) => void
    ) => {
      fetchDataWithCurrencyHandlers.current[name] = handler;
    },
    []
  );

  useEffect(() => {
    dispatch(
      setElementHeight({
        name: EElementNames.CONTENT_HEIGHT,
        height: height + headerHeight + HEADER_BOTTOM_MARGIN + additionalHeight
      })
    );
  }, [height, headerHeight, additionalHeight]);

  return (
    <main
      className={classNames(styles.contentWrapWithoutScroll, {
        [styles.contentWrapWithoutScroll__mobile]: isMobile
      })}
      style={contentStyles}
    >
      <div
        className={classNames(styles.scrollableContentWrapper, {
          [styles.scrollableContentWrapper__mobile]: isMobile
        })}
        ref={scrollableContentRef}
      >
        {selectedTab == NavigationButtons.MyBets && (
          <MyExchangeBetsPage asianViewEnabled={isAsianViewEnabled} collectHandlers={collectHandlers} />
        )}
        {selectedTab === NavigationButtons.Statement && (
          <AccountStatementPage asianViewEnabled={isAsianViewEnabled} collectHandlers={collectHandlers} />
        )}
        {selectedTab === NavigationButtons.ProfitAndLoss && (
          <BettingProfitAndLoss
            asianViewEnabled={isAsianViewEnabled}
            activePLTab={activePLTab}
            setOnChangeBetType={setOnChangeBetType}
            collectHandlers={collectHandlers}
          />
        )}
      </div>
      {showPokerStarFooter ? (
        <PokerStarsFooter />
      ) : (
        <div className={styles.contentWrapWithoutScroll__footer}>
          <Footer />
        </div>
      )}
    </main>
  );
};

export default MyBetsPagesContent;
