import { HTMLAttributes } from 'react';
import classNames from 'classnames';

import Notification from 'components/Notification';
import { mobileComponents, mobileIcons } from 'constants/branding';

type MobilePlacementValidationMessageProps = {
  type: 'info' | 'warning';
  message: string;
  resetErrorMessage?: () => void;
  className?: HTMLAttributes<HTMLDivElement>['className'];
};

const MobilePlacementValidationMessage = ({
  message,
  type,
  resetErrorMessage,
  className = ''
}: MobilePlacementValidationMessageProps) => {
  return (
    <Notification
      type={type}
      message={message}
      className={classNames(className, mobileComponents.NOTIFICATION, {
        [mobileComponents.INFO]: type === 'info',
        [mobileComponents.ERROR]: type === 'warning'
      })}
      iconClassName={classNames({
        [mobileIcons.INFO_ICON]: type === 'info',
        [mobileIcons.WARNING_ICON]: type === 'warning'
      })}
      onClose={resetErrorMessage}
      onClickOutside={resetErrorMessage}
    />
  );
};

export default MobilePlacementValidationMessage;
