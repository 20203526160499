import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { entries, isUndefined, keys, union, values } from 'lodash';

import { SLICES_NAMES } from 'constants/app';
import {
  ASIAN_SELECTED_BETS_AMOUNT_LIMIT,
  ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME,
  DEFAULT_NUMBER_OF_PLACEMENT_ATTEMPTS
} from 'constants/asianView';
import { ASIAN_VIEW_PLACE_BET_ERRORS_IDS } from 'constants/betslip';
import api from 'redux/api/methods';
import { EAsianBettingActions } from 'redux/modules/asianViewQuickBetting/type';
import { EPlaceBetsStates } from 'redux/modules/betslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { EAsianBetslipTabs } from 'types/asianView';
import { getAsianViewBetUuid, getSelectedBetIdentifier, isAVResponsibleGamblingError } from 'utils/asianView';

import {
  AsianViewBetSlipValidationMessage,
  AsianViewPlacedBetsByMarket,
  TAsianPlaceBet,
  TAsianSelectedBet,
  TAsianSelectedBets,
  TAsianUpdateBetPayload,
  TAsianValidationMessagePayload,
  TAsianViewBetslipState,
  TPlacedBetException
} from './type';

const initialState: TAsianViewBetslipState = {
  selectedTab: EAsianBetslipTabs.BET_SLIP,
  selectedBets: {},
  placeBetsState: EPlaceBetsStates.SELECT,
  placedBetsByMarket: {},
  placeBetsLoading: false,
  statuses: {},
  statusesError: null,
  statusesLoading: false,
  statusesOfferIds: [],
  areStatusesLoaded: false,
  isAtLeastOnePendingStatus: false,
  isSelectedBetsLimitNotification: false,
  liabilityByMarket: {},
  rgErrorMessage: null
};

const slice = createSlice({
  name: SLICES_NAMES.ASIAN_VIEW_BETSLIP,
  initialState,
  reducers: {
    setSelectedTab: (state, { payload }: PayloadAction<EAsianBetslipTabs>) => {
      state.selectedTab = payload;
    },
    setSelectedBet: (state, { payload }: PayloadAction<TAsianSelectedBet>) => {
      const identifier = getSelectedBetIdentifier(payload);
      const selectedBetsStorageString = localStorage.getItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME);
      const selectedBetsFromStorage = selectedBetsStorageString ? JSON.parse(selectedBetsStorageString) : {};
      const selectedBetsAmount = Object.keys(state.selectedBets).length;

      if (state.selectedBets[identifier]) {
        delete state.selectedBets[identifier];
        delete selectedBetsFromStorage[identifier];

        const offerId =
          state.placedBetsByMarket[payload.marketId]?.offerIds?.[
            getAsianViewBetUuid({
              marketId: payload.marketId,
              selectionId: payload.selectionId,
              handicap: payload.handicap,
              betType: payload.betType
            })
          ];

        if (offerId) {
          delete state.statuses[offerId];
        }

        state.isSelectedBetsLimitNotification = false;
        localStorage.setItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME, JSON.stringify(selectedBetsFromStorage));
      } else {
        if (selectedBetsAmount >= ASIAN_SELECTED_BETS_AMOUNT_LIMIT) {
          state.isSelectedBetsLimitNotification = true;
          return;
        }

        const newSelectedBet = { ...payload, initPrice: payload.price, identifier, order: new Date().getTime() };

        const newStorageSelectedBets = {
          ...selectedBetsFromStorage,
          [identifier]: newSelectedBet
        };

        localStorage.setItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME, JSON.stringify(newStorageSelectedBets));

        state.selectedBets[identifier] = newSelectedBet;
        state.selectedTab = EAsianBetslipTabs.BET_SLIP;
      }
    },
    updateSelectedBet: (state, { payload }: PayloadAction<TAsianUpdateBetPayload>) => {
      if (payload.identifier && state.selectedBets[payload.identifier]) {
        state.selectedBets[payload.identifier] = { ...state.selectedBets[payload.identifier], ...payload.data };

        if (payload.updateLocalStorageBets) {
          const selectedBetsStorageString = localStorage.getItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME);
          const selectedBetsFromStorage = selectedBetsStorageString ? JSON.parse(selectedBetsStorageString) : {};

          localStorage.setItem(
            ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME,
            JSON.stringify({
              ...selectedBetsFromStorage,
              [payload.identifier]: { ...selectedBetsFromStorage[payload.identifier], ...payload.data }
            })
          );
        }
      }
    },
    removeSelectedBets: (state, { payload }: PayloadAction<(string | undefined)[]>) => {
      const selectedBetsStorageString = localStorage.getItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME);
      const selectedBetsFromStorage = selectedBetsStorageString ? JSON.parse(selectedBetsStorageString) : {};

      payload.forEach(key => {
        if (key && state.selectedBets[key]) {
          const bet = state.selectedBets[key];

          delete state.selectedBets[key];
          delete selectedBetsFromStorage[key];

          const offerId =
            state.placedBetsByMarket[bet.marketId]?.offerIds?.[
              getAsianViewBetUuid({
                marketId: bet.marketId,
                selectionId: bet.selectionId,
                handicap: bet.handicap,
                betType: bet.betType
              })
            ];

          if (offerId) {
            delete state.statuses[offerId];
          }
        }
      });

      state.isSelectedBetsLimitNotification = false;

      localStorage.setItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME, JSON.stringify(selectedBetsFromStorage));
    },
    setBetSlipValidationMessage: (state, { payload }: PayloadAction<TAsianValidationMessagePayload>) => {
      if (payload.identifier && state.selectedBets[payload.identifier]) {
        state.selectedBets[payload.identifier].validationMessage = payload.message;

        if (!isUndefined(payload.sizeValidationType)) {
          state.selectedBets[payload.identifier].sizeValidationType = payload.sizeValidationType;
        }

        if (!isUndefined(payload.messageId)) {
          state.selectedBets[payload.identifier].validationMessageId = payload.messageId;
          if (payload.messageId == ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX026) {
            state.selectedBets[payload.identifier].placementAttempt =
              (state.selectedBets[payload.identifier].placementAttempt || DEFAULT_NUMBER_OF_PLACEMENT_ATTEMPTS) + 1;
          }
        }
      }
    },
    setPlaceBetsState: (state, action) => {
      state.placeBetsState = action.payload;
    },
    placeAllSelectedBets: (
      state,
      _: PayloadAction<{
        data: { [key: string]: TAsianPlaceBet[] };
        onSuccessCallback: (offerIds: number[]) => void;
        onErrorCallback: () => void;
      }>
    ) => {
      state.placeBetsLoading = true;

      keys(state.selectedBets).forEach(identifier => {
        if (!state.selectedBets[identifier].offerId) {
          state.selectedBets[identifier].bettingAction = EAsianBettingActions.PROGRESS;
        }
      });
    },
    successPlaceAllSelectedBets: (state, { payload }: PayloadAction<AsianViewPlacedBetsByMarket>) => {
      state.placedBetsByMarket = payload;
      state.placeBetsLoading = false;

      entries(payload).forEach(([marketId, { status, exception, offerIds }]) => {
        if (status === 'FAIL' && exception) {
          const failedSelectedBets = keys(state.selectedBets).filter(identifier => identifier.startsWith(marketId));

          if (isAVResponsibleGamblingError(exception)) {
            state.rgErrorMessage = exception;
          }

          failedSelectedBets.forEach(identifier => {
            if (exception.id == ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX026) {
              state.selectedBets[identifier].placementAttempt =
                (state.selectedBets[identifier].placementAttempt || DEFAULT_NUMBER_OF_PLACEMENT_ATTEMPTS) + 1;
            }

            state.selectedBets[identifier].validationMessage = exception.message;
            state.selectedBets[identifier].validationMessageId = exception.id;
            delete state.selectedBets[identifier].bettingAction;
          });
        } else if (status === 'OK' && offerIds) {
          Object.entries(offerIds).forEach(([identifier, offerId]) => {
            state.selectedBets[identifier].offerId = offerId;
          });

          localStorage.setItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME, JSON.stringify(state.selectedBets));
        }
      });
    },
    failurePlaceAllSelectedBets: state => {
      state.placeBetsLoading = false;
    },
    removeAllSelectedBets: state => {
      state.selectedBets = {};
      state.statuses = {};

      localStorage.removeItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME);
    },
    setSelectedBets: (state, { payload }: PayloadAction<TAsianSelectedBets>) => {
      if (!keys(state.selectedBets).length || JSON.stringify(payload) !== JSON.stringify(state.selectedBets)) {
        state.selectedBets = payload;
      }
    },
    setStatusesOfferIds: (state, { payload }: PayloadAction<number[]>) => {
      state.statusesOfferIds = union(payload, [...state.statusesOfferIds]);
    },
    fetchAsianViewBetStatuses: (
      state,
      _: PayloadAction<{ offerIds: number[]; onErrorCallback: () => void; onSuccessCallback?: () => void }>
    ) => {
      state.statusesLoading = true;
    },
    successFetchAsianViewBetsStatuses: (state, { payload }: PayloadAction<Record<number, BetsStatusesTypes>>) => {
      const notPendingOfferIds: number[] = [];
      entries(payload).forEach(([offerId, status]) => {
        if (!state.statuses[offerId]) {
          state.statuses[offerId] = {};
        }

        state.statuses[offerId].status = status;
        if (status !== BetsStatusesTypes.PENDING) {
          notPendingOfferIds.push(+offerId);
        }
      });

      if (!state.areStatusesLoaded) {
        state.areStatusesLoaded = true;
      }

      state.statusesOfferIds = [...state.statusesOfferIds].filter(offerId => !notPendingOfferIds?.includes(offerId));
      state.statusesLoading = false;
      state.isAtLeastOnePendingStatus = values(payload).some(status => status === BetsStatusesTypes.PENDING);
      if (!state.isAtLeastOnePendingStatus) {
        keys(state.selectedBets).forEach(identifier => {
          const offerId =
            state.placedBetsByMarket[state.selectedBets[identifier].marketId]?.offerIds?.[
              getAsianViewBetUuid({
                marketId: state.selectedBets[identifier].marketId,
                selectionId: state.selectedBets[identifier].selectionId,
                handicap: state.selectedBets[identifier].handicap,
                betType: state.selectedBets[identifier].betType
              })
            ];

          if (
            offerId &&
            state.statuses[offerId].status !== BetsStatusesTypes.EXPIRED &&
            state.statuses[offerId].status !== BetsStatusesTypes.CANCELLED
          ) {
            state.selectedBets[identifier].offerId = offerId;
          }
        });
      }
      localStorage.setItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME, JSON.stringify({ ...state.selectedBets }));
    },
    failureFetchAsianViewBetStatuses: (
      state,
      { payload }: PayloadAction<{ offersIds: number[]; error: TPlacedBetException }>
    ) => {
      const notPendingOfferIds: number[] = [];
      payload.offersIds.forEach(offerId => {
        if (!state.statuses[offerId]) {
          state.statuses[offerId] = {};
        }
        notPendingOfferIds.push(offerId);

        state.statuses[offerId].error = payload.error;
        delete state.statuses[offerId].status;
      });

      state.statusesOfferIds = [...state.statusesOfferIds].filter(offerId => !notPendingOfferIds?.includes(offerId));
      state.statusesLoading = false;
      state.isAtLeastOnePendingStatus = values(state.statuses).some(
        ({ status }) => status === BetsStatusesTypes.PENDING
      );
    },
    removeBetStatus: (state, { payload }: PayloadAction<number>) => {
      if (state.statuses[payload]?.error) {
        delete state.statuses[payload];
      }
    },
    setBetSlipValidationMessageForAllSelectedBets: (
      state,
      { payload }: PayloadAction<AsianViewBetSlipValidationMessage>
    ) => {
      keys(state.selectedBets).forEach(identifier => {
        state.selectedBets[identifier].validationMessage = payload.message;
        state.selectedBets[identifier].sizeValidationType = payload.sizeValidationType;
        state.selectedBets[identifier].validationMessageId = payload.messageId;
      });
    },
    setIsSelectedBetsLimitNotification: (state, { payload }: PayloadAction<boolean>) => {
      state.isSelectedBetsLimitNotification = payload;
    },
    setLiabilityByMarket: (state, { payload }: PayloadAction<{ marketId: string; liability: number }>) => {
      state.liabilityByMarket[payload.marketId] = payload.liability;
    },
    setBetslipRGErrorMessage: (state, { payload }: PayloadAction<TPlacedBetException | null>) => {
      state.rgErrorMessage = payload;
    }
  }
});

export const {
  removeSelectedBets,
  setSelectedBet,
  setSelectedTab,
  setBetSlipValidationMessage,
  updateSelectedBet,
  setPlaceBetsState,
  placeAllSelectedBets,
  successPlaceAllSelectedBets,
  failurePlaceAllSelectedBets,
  removeAllSelectedBets,
  setSelectedBets,
  setStatusesOfferIds,
  fetchAsianViewBetStatuses,
  successFetchAsianViewBetsStatuses,
  failureFetchAsianViewBetStatuses,
  setBetSlipValidationMessageForAllSelectedBets,
  setIsSelectedBetsLimitNotification,
  removeBetStatus,
  setLiabilityByMarket,
  setBetslipRGErrorMessage
} = slice.actions;

export const setActiveSelectedBetsFromStorage = createAsyncThunk(
  `${SLICES_NAMES.ASIAN_VIEW_BETSLIP}/CHECK_IF_MARKETS_ARE_CLOSED`,
  async (selectedBetsFromStorage: TAsianSelectedBets, { dispatch, rejectWithValue }) => {
    try {
      const marketsIds = values(selectedBetsFromStorage).map(({ marketId }) => marketId);

      const marketsStatuses: Record<string, boolean> = await api.checkMarkets(marketsIds);

      const selectedBets = entries(selectedBetsFromStorage)
        .filter(([, { marketId }]) => marketsStatuses[marketId])
        .reduce((acc, [identifier, bet]) => {
          return {
            ...acc,
            [identifier]: bet
          };
        }, {});

      dispatch(setSelectedBets(selectedBets));
      localStorage.setItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME, JSON.stringify(selectedBets));
    } catch (err: any) {
      rejectWithValue(err);
    }
  }
);

export default slice.reducer;
