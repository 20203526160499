import classNames from 'classnames';

import Notification from 'components/Notification';
import { componentsBranding } from 'constants/branding';

type TSelectedMarketError = {
  errorMessage: string;
  onClose: () => void;
};

const SelectedMarketError = ({ errorMessage, onClose }: TSelectedMarketError) => {
  return (
    <Notification
      message={errorMessage}
      className={classNames(componentsBranding.NOTIFICATION, componentsBranding.ERROR)}
      type="warning"
      onClose={onClose}
      isInline
      onClickOutside={onClose}
    />
  );
};

export default SelectedMarketError;
