import { useSelector } from 'react-redux';

import ResponsibleBettingReminderModal from 'components/ResponsibleBetting/components/ResponsibleBettingReminderModal';
import ResponsibleBettingTimeLimitModal from 'components/ResponsibleBetting/components/ResponsibleBettingTimeLimitModal';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getShowReminder } from 'redux/modules/responsibleBetting/selectors';
import { getUserSessionInfoStartSessionDate } from 'redux/modules/user/selectors';

const ResponsibleBettingModals = () => {
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const startSessionDate = useSelector(getUserSessionInfoStartSessionDate);
  const showReminder = useSelector(getShowReminder);

  if (isLoggedIn) {
    return (
      <>
        <ResponsibleBettingTimeLimitModal />
        {startSessionDate && showReminder && <ResponsibleBettingReminderModal />}
      </>
    );
  }

  return null;
};

export default ResponsibleBettingModals;
