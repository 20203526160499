import { BannerImage } from 'redux/modules/banners/type';

interface BannerImageComponentProps {
  banner: BannerImage;
}

const BannerImageComponent = ({ banner }: BannerImageComponentProps) => {
  return (
    <img
      alt={banner.title}
      src={`${window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL}/customer${banner.imageUrl}`}
      srcSet={`${window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL}/customer${
        banner.retinaImageUrl || banner.imageUrl
      } 2x`}
      className="biab_banner-img"
      title={banner.title}
    />
  );
};

export default BannerImageComponent;
