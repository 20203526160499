import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { getIsIframeEnabled, getTranslation } from 'redux/modules/appConfigs/selectors';
import { TPopularLinks } from 'redux/modules/popular/type';
import { getDefaultLinkTarget } from 'utils/navigation';

import PopularLinkContent from '../PopularLinkContent';

import styles from '../../styles.module.scss';

interface CustomPopularLinkProps {
  value: TPopularLinks;
}

const CustomPopularLink = ({ value }: CustomPopularLinkProps) => {
  const domainLanguage = useSelector(getTranslation);
  const iframeEnabled = useSelector(getIsIframeEnabled);

  return (
    <li className={classnames('biab_sport-item', styles.popularLink)}>
      <a
        target={getDefaultLinkTarget({
          url: value.name,
          absolutePath: true,
          isIframeEnabled: !!iframeEnabled
        })}
        href={value.name}
      >
        <PopularLinkContent
          type={value.type}
          icon={value.iconName || ''}
          name={value.translations[domainLanguage]}
          videoStreamingEnabled={value.videoStreamingEnabled}
          eventId={value.id!}
          matchStatEnabled={value.matchStatEnabled}
          sportId={value.eventTypeId}
        />
      </a>
    </li>
  );
};

export default CustomPopularLink;
