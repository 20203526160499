import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';
import { useResizeObserver } from 'usehooks-ts';

import { resetMetaContent } from 'redux/modules/meta';
import { getMetaPageId, getPageMetaContent } from 'redux/modules/meta/selectors';

import styles from './styles.module.scss';

const SEOFooter = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const pageId = useSelector(getMetaPageId);
  const metaData = useSelector(getPageMetaContent(pageId));

  const isFooterData = !!metaData?.contentHeader || !!metaData?.content;

  useEffect(() => {
    dispatch(resetMetaContent());
  }, [location.pathname]);

  if (!isFooterData) {
    return null;
  }

  return (
    <div className={classNames('biab_seo-settings', styles.seoSettings)}>
      {metaData?.contentHeader && (
        <h1 className={classNames('biab_seo-settings-header', styles.seoSettings__header)}>{metaData.contentHeader}</h1>
      )}
      {metaData?.content && <SEOFooterContent content={metaData.content} />}
    </div>
  );
};

export default SEOFooter;

function SEOFooterContent({ content }: { content: string }) {
  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(true);

  const contentRef = useRef<HTMLDivElement>(null);

  const { height = 0 } = useResizeObserver({ ref: contentRef, box: 'border-box' });

  const displayShowMoreBtn = useMemo(() => {
    const contentFontSize = contentRef.current
      ? parseInt(getComputedStyle(contentRef.current, null).getPropertyValue('font-size'))
      : 0;
    const contentMaxHeight = contentFontSize * 1.7 * 5; // 5 - number of lines, 1.7 - line height of each line

    return height > contentMaxHeight;
  }, [height, content]);

  return (
    <div className={styles.seoSettings__content__wrapper}>
      <div
        ref={contentRef}
        className={classNames('biab_seo-settings-content', styles.seoSettings__content, {
          [styles.seoSettings__content__hidden]: showMore,
          [styles.seoSettings__content__visible]: !showMore,
          [styles.seoSettings__content__fade]: showMore && displayShowMoreBtn
        })}
        dangerouslySetInnerHTML={{ __html: unescape(content) }}
      />
      {displayShowMoreBtn && (
        <button
          onClick={() => setShowMore(prevState => !prevState)}
          className={classNames('biab_seo-settings-toggle', styles.seoSettings__toggle)}
        >
          {t(`seoSettings.show${showMore ? 'More' : 'Less'}`)}
        </button>
      )}
    </div>
  );
}
