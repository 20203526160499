import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useLongDateFormat from 'hooks/useLongDateFormat';
import {
  getMarketPricesRunnerAdjustmentFactorBySelectionId,
  getMarketPricesRunnerRemovalDateBySelectionId
} from 'redux/modules/marketsPrices/selectors';

import styles from './styles.module.scss';

interface NonRunnerProps {
  isShowTime?: boolean;
  marketId: string;
  selectionId: number;
  handicap: number;
}

const NonRunner = ({ marketId, selectionId, handicap, isShowTime }: NonRunnerProps) => {
  const { t } = useTranslation();

  const removalDate = useSelector(getMarketPricesRunnerRemovalDateBySelectionId(marketId, selectionId, handicap));
  const adjustmentFactor = useSelector(
    getMarketPricesRunnerAdjustmentFactorBySelectionId(marketId, selectionId, handicap)
  );

  const formattedDate = useLongDateFormat({
    isTimeFirst: false,
    isShortYear: false,
    hasWeekDay: true,
    time: removalDate ?? 0,
    hasYear: false,
    divider: ' ',
    dateDivider: ' '
  });

  const percent = adjustmentFactor?.toFixed(1) || '';

  return (
    <p className={styles.container}>
      <span className={styles.firstBlock}>
        <span className={styles.container__title}>{t('market.nonRunner.nonRunner')}</span>
        <span>&nbsp;{`${percent}`}</span>
      </span>
      <span>%{isShowTime ? `, ${formattedDate}` : ''}</span>
    </p>
  );
};

export default NonRunner;
