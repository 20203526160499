import { createSelector } from '@reduxjs/toolkit';
import { isEmpty, sortBy } from 'lodash';

import { AppState } from 'redux/reducers';
import { getSelectedBetIdentifier } from 'utils/asianView';

import { EAsianPlacedQuickBettingStatus, TAsianQuickBetIdentifierParams } from './type';

export const getAsianQuickBettingPlacedBets = (state: AppState) => state.asianViewQuickBetting.placedBets;

export const getAsianQuickBettingItem = (params: TAsianQuickBetIdentifierParams) => (state: AppState) =>
  state.asianViewQuickBetting.quickBets[getSelectedBetIdentifier(params)];

export const getAsianQuickPlacedBetsList = createSelector(getAsianQuickBettingPlacedBets, placedBets =>
  sortBy(Object.values(placedBets), 'order')
    .filter(
      bet => bet.status === EAsianPlacedQuickBettingStatus.PLACED || bet.status === EAsianPlacedQuickBettingStatus.ERROR
    )
    .reverse()
);

export const getAsianQuickPlacedBetsProgressList = createSelector(getAsianQuickBettingPlacedBets, placedBets =>
  sortBy(
    Object.values(placedBets).filter(
      bet => bet.status !== EAsianPlacedQuickBettingStatus.PLACED && bet.status !== EAsianPlacedQuickBettingStatus.ERROR
    ),
    'order'
  ).reverse()
);

export const getAsianActiveQuickPlacedBetIndex = (state: AppState) => state.asianViewQuickBetting.activePlacedBetIndex;

export const getAsianActiveQuickPlacedBet = createSelector(
  getAsianQuickPlacedBetsList,
  getAsianActiveQuickPlacedBetIndex,
  (bets, activePlacedBetIndex) => bets[activePlacedBetIndex]
);

export const getAsianQuickPlacedBetsAmount = createSelector(getAsianQuickPlacedBetsList, bets => bets.length);
export const getIsAsianQuickPlacedBets = createSelector(getAsianQuickPlacedBetsList, bets => !!bets.length);
export const getIsAsianQuickProgressBets = createSelector(getAsianQuickPlacedBetsProgressList, bets => !!bets.length);

export const getAsianIsPlacedBetsLoading = (state: AppState) => state.asianViewQuickBetting.isPlacedBetsLoading;

export const getIsAsianQuickPlacedBetsEmpty = (state: AppState) => isEmpty(state.asianViewQuickBetting.placedBets);
