import { MouseEvent } from 'react';

import styles from './styles.module.scss';

interface TourListItemProps {
  tourName: string;
  id: number;
  startTour: (id: number) => void;
}
const TourListItem = ({ tourName, id, startTour }: TourListItemProps) => {
  const handleStartTour = (e: MouseEvent) => {
    e.preventDefault();
    startTour(id);
  };

  return (
    <li className={styles.listItem}>
      <a onClick={handleStartTour} className="biab_navigation-list-item" data-id={id} href="#">
        {tourName}
      </a>
    </li>
  );
};

export default TourListItem;
