import { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { asianViewMiddleSection } from 'constants/branding';
import {
  getAsianViewMobileNavigationMenu,
  getPositionOfAsianViewMobileNavigationMenu
} from 'redux/modules/appConfigs/selectors';
import { setIsAsianViewMenuOpen } from 'redux/modules/appSettings';
import { getIsAsianViewMenuOpen } from 'redux/modules/appSettings/selectors';
import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';

import styles from './styles.module.scss';

type AsianViewMobileMenuButtonProps = {
  containerClassName?: string;
};

const AsianViewMobileMenuButton = ({ containerClassName = '' }: AsianViewMobileMenuButtonProps) => {
  const dispatch = useDispatch();

  const isAsianViewMenuOpen = useSelector(getIsAsianViewMenuOpen);
  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);
  const positionOfAsianViewMobileNavigationMenu = useSelector(getPositionOfAsianViewMobileNavigationMenu);
  const asianViewMobileNavigationMenu = useSelector(getAsianViewMobileNavigationMenu);

  const hasMenu = positionOfAsianViewMobileNavigationMenu && asianViewMobileNavigationMenu;
  const hasMenuIcon = !isLandscapeAsianView && hasMenu;

  const onMenuIconClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(setIsAsianViewMenuOpen(!isAsianViewMenuOpen));
  };

  if (!hasMenuIcon) {
    return null;
  }

  return (
    <button
      onClick={onMenuIconClick}
      className={classNames(styles.menuBtn, asianViewMiddleSection.MOBILE_MENU_BTN, containerClassName)}
    >
      <i
        className={classNames('av-icon', {
          'av-icon-mobile-menu': !isAsianViewMenuOpen,
          'av-icon-mobile-menu-close': isAsianViewMenuOpen
        })}
      />
    </button>
  );
};

export default AsianViewMobileMenuButton;
