import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';

import styles from './styles.module.scss';

const HomeEmpty = () => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);

  return (
    <p className={styles.homeEmpty}>{t(`${device === Devices.MOBILE ? 'mobile.' : ''}home.noMarketsFeaturedSport`)}</p>
  );
};

export default HomeEmpty;
