import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { AsianViewMarketLink, AsianViewTimeFilter } from 'constants/asianView';
import { subHeader as branding } from 'constants/branding';
import { ASIAN_BASE_URL, GAMES_BASE_URL, HOME_BASE_URL } from 'constants/locations';
import useOnClickOutside from 'hooks/useOnClickOutside';
import {
  getDisplayIconsInSubHeader,
  getIsAsianViewEnabled,
  getIsExchangeSportsEnabled,
  getIsIframeEnabled
} from 'redux/modules/appConfigs/selectors';
import { logoutUser } from 'redux/modules/auth';
import { SportId } from 'types';
import { TAccountNavigation } from 'types/navigation';

import DesktopNavigationMyAccountItem from '../DesktopNavigationMyAccountItem';

import styles from './styles.module.scss';

interface SubmenuProps {
  title: string;
  rightIcon: string;
  isShowLogout: boolean;
  data: Array<TAccountNavigation>;
  isSettingsEnabled: boolean;
}

const DesktopNavigationMyAccount = ({ rightIcon, data, title, isShowLogout, isSettingsEnabled }: SubmenuProps) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const displayIconsInSubHeader = useSelector(getDisplayIconsInSubHeader);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const asianViewEnabled = useSelector(getIsAsianViewEnabled);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const isAsianPage = pathname.includes(ASIAN_BASE_URL);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const closeSubMenuOnItemClick = () => {
    setIsOpen(false);
  };

  const handleLogout = () => {
    let redirectUrl =
      isAsianPage && asianViewEnabled
        ? `${ASIAN_BASE_URL}/sport/${SportId.SOCCER}/timeFilter/${AsianViewTimeFilter.Today}/marketLink/${AsianViewMarketLink.HDP_AND_OU}`
        : HOME_BASE_URL;

    if (!asianViewEnabled && !isExchangeSportsEnabled) {
      redirectUrl = GAMES_BASE_URL;
    }

    dispatch(logoutUser(redirectUrl));
  };

  return (
    <div ref={dropdownRef} className={classNames(styles.headerNavigationItem, branding.SUBHEADER_WRAPPER)}>
      <button
        className={classNames('biab_navigation-link', styles.title, branding.SUBHEADER_HOLDER, { biab_active: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
      >
        {displayIconsInSubHeader && rightIcon && <i className={classNames(rightIcon, styles.rightIcon)} />}
        {t(title)}
        <i className={classNames(`fa2 fa2-arrow-${isOpen ? 'up' : 'down'}`, styles.headerNavigationItem__icon)} />
      </button>

      <div className={styles.itemContainer}>
        {isOpen && (
          <ul
            className={classNames('biab_account-nav', styles.submenu, {
              [styles.submenu__noSettings]: !isSettingsEnabled
            })}
          >
            {data.map(item => (
              <DesktopNavigationMyAccountItem key={item.code} item={item} onClose={closeSubMenuOnItemClick} />
            ))}
            {isShowLogout && !isIframeEnabled && (
              <li>
                <a onClick={handleLogout} className={classNames(styles.logout, branding.SUBHEADER_ITEM)}>
                  {t('header.logout')}
                  <i className="biab_custom-icon-logout" />
                </a>
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DesktopNavigationMyAccount;
