import { createContext } from 'react';

interface ITooltipContext {
  isEnabled: boolean;
  translationKey: string;
  tooltipKey?: string;
  component?: string;
}

const TooltipContext = createContext<ITooltipContext>({
  isEnabled: false,
  translationKey: '',
  tooltipKey: '',
  component: ''
});

export const TooltipProvider = TooltipContext.Provider;

export default TooltipContext;
