import classNames from 'classnames';

import { asianViewMiddleSection as branding } from 'constants/branding';
import { TAsianViewMarket } from 'redux/modules/asianView/type';
import { TMarketPricesRunner } from 'types/markets';
import { getAVRunnerName, getAVRunnersByIndex } from 'utils/avMarketCell';

import AsianViewBackLayLabels from '../AsianViewBackLayLabels';
import SelectionOdds from '../SelectionOdds';
import SelectionOddsSkeleton from '../SelectionOddsSkeleton';

import styles from '../styles.module.scss';

type AVMarketSelectionsByIndexProps = {
  hasBackLayBtns: boolean;
  isSingleLine: boolean;
  index: number;
  isFirstEvent: boolean;
  market: TAsianViewMarket;
  runnerStatuses: TMarketPricesRunner[];
  eventId: string;
  hideRunners?: boolean;
  competitionId: string;
  hasSelectionName: boolean;
  marketPricesId?: string;
  firstKeyLineId?: number;
  secondKeyLineId?: number;
  isKeyLine: boolean;
  firstKeyLineAdjHandicap?: number | null;
  secondKeyLineAdjHandicap?: number | null;
  firstKeyLineHandicap?: number;
  secondKeyLineHandicap?: number;
  isGoalLines: boolean;
  isAsianHandicap: boolean;
  isClosed: boolean;
};

const AVMarketSelectionsByIndex = ({
  hasBackLayBtns,
  isSingleLine,
  index,
  isFirstEvent,
  market,
  runnerStatuses,
  eventId,
  hideRunners = false,
  competitionId,
  hasSelectionName,
  marketPricesId,
  secondKeyLineId,
  isKeyLine,
  firstKeyLineAdjHandicap,
  secondKeyLineAdjHandicap,
  firstKeyLineHandicap,
  firstKeyLineId,
  secondKeyLineHandicap,
  isGoalLines,
  isAsianHandicap,
  isClosed
}: AVMarketSelectionsByIndexProps) => {
  const runnersByIndex = getAVRunnersByIndex({
    index,
    market,
    runnerStatuses,
    isClosed,
    isGoalLines,
    isAsianHandicap,
    isKeyLine,
    firstKeyLineHandicap,
    marketPricesId,
    firstKeyLineId,
    secondKeyLineId
  });

  return (
    <div
      data-market-type={market.marketType}
      className={classNames('biab_asian-view-market', styles.marketCell__market, {
        [styles.marketCell__market__hasBackLay]: index === 0 && hasBackLayBtns && isFirstEvent,
        [styles.marketCell__market__main]: index === 0,
        [styles.marketCell__market__additional]: index > 0,
        [styles.marketCell__market__singleLine]: isSingleLine,
        [branding.MAIN_CONTAINER_BORDER]: index > 0
      })}
    >
      {index === 0 && hasBackLayBtns && !isSingleLine && (
        <AsianViewBackLayLabels containerClassName={styles.marketCell__backLay} />
      )}

      {marketPricesId &&
        !hideRunners &&
        runnersByIndex.length > 0 &&
        runnersByIndex.map((runner, runnerIndex) => (
          <SelectionOdds
            key={`${runner.id}_${runner.handicap}_${index}_${runnerIndex}`}
            competitionId={competitionId}
            eventId={eventId}
            marketId={market.id}
            name={
              hasSelectionName
                ? getAVRunnerName({
                    runner,
                    index: runnerIndex,
                    market,
                    isSingleLine,
                    firstKeyLineId,
                    secondKeyLineId,
                    isKeyLine,
                    firstKeyLineAdjHandicap,
                    secondKeyLineAdjHandicap,
                    firstKeyLineHandicap,
                    secondKeyLineHandicap
                  })
                : undefined
            }
            runner={runner}
            isSingleLine={isSingleLine}
            runnerIndex={runnerIndex}
          />
        ))}
      {!marketPricesId &&
        (runnersByIndex.length <= 3 ? (
          runnersByIndex.map((runner, runnerIndex) => (
            <SelectionOddsSkeleton isSmallCell={isSingleLine} key={runnerIndex} />
          ))
        ) : isSingleLine ? (
          <>
            <SelectionOddsSkeleton isSmallCell={isSingleLine} />
            <SelectionOddsSkeleton isSmallCell={isSingleLine} />
          </>
        ) : (
          <div className={styles.marketCell__skeletons}>
            <SelectionOddsSkeleton />
            <SelectionOddsSkeleton />
          </div>
        ))}

      {marketPricesId &&
        isSingleLine &&
        hideRunners &&
        runnersByIndex.map(() => <div className={branding.MAIN_CONTAINER_BORDER} />)}
    </div>
  );
};

export default AVMarketSelectionsByIndex;
