import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getIsLandscapeAsianView, getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { IMarket } from 'types/markets';

import ContentItem from './ContentItem';

import styles from './styles.module.scss';

interface ContentProps {
  markets: IMarket[];
  isTab?: boolean;
}

const Content = ({ markets, isTab }: ContentProps) => {
  const isMobile = useSelector(getIsMobileAsianView);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);

  const isTwoColumns = (!isMobile || (isTab && isLandscapeView)) && markets.length >= 2;
  const firstColumn: IMarket[] = [];
  const secondColumn: IMarket[] = [];
  markets.forEach((item, index) => {
    if (isTwoColumns) {
      if (index % 2 === 1) {
        secondColumn.push(item);
      } else {
        firstColumn.push(item);
      }
    } else {
      firstColumn.push(item);
    }
  });

  return (
    <div
      className={classNames(styles.market__marketWrapper, {
        [styles.market__tabWrapper]: isTab,
        [styles.market__tabWrapper__mobile]: isTab && isMobile && !isLandscapeView
      })}
    >
      {[firstColumn, secondColumn].map((column, index) => {
        if (isMobile && column.length === 0) {
          return null;
        }

        return (
          <div
            key={index}
            className={classNames(styles.market__line, {
              [styles.market__resetMargin]: isTab
            })}
          >
            {column.map(market => (
              <ContentItem market={market} isTab={isTab} key={market.marketId} />
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default Content;
