import { TFailureActionPayload, ViewBy } from 'types';
import { IMarket, IMarketRules, TMarketSportInfo } from 'types/markets';

import { ETimeFilters } from '../competitions/type';

export type TSportPage = {
  sportInfo: TMarketSportInfo | null;
  marketCatalogueList: TSportPageMarketCatalogueList | null;
  loading: boolean;
  error: TFailureActionPayload;
  rules: Record<string, IMarketRules>;
  rulesLoading: boolean;
  rulesError: TFailureActionPayload;
};

export type TSuccessFetchSportPageDetails = {
  concatMarkets?: boolean;
} & TSportPageDetailsResponse;

export enum SportPageContextFilter {
  COUNTRY = 'COUNTRY',
  COMPETITION = 'COMPETITION',
  EVENT_TYPE = 'EVENT_TYPE'
}

export type TSportPageDetailsPayload = {
  page: number;
  size?: number;
  data: {
    contextFilter: SportPageContextFilter;
    id: string;
    timeFilter: ETimeFilters;
    viewBy: ViewBy;
  };
  concatMarkets?: boolean;
};

export type TSportPageMarketCatalogueList = {
  content: IMarket[];
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: null;
  numberOfElements: number;
  first: boolean;
};

export type TSportPageDetailsResponse = {
  sportInfo: TMarketSportInfo;
  marketCatalogueList: TSportPageMarketCatalogueList;
};

export enum SportPageType {
  SPORT = 'SPORT',
  COUNTRY = 'COUNTRY',
  COMPETITION = 'COMPETITION',
  EVENT_TYPE = 'EVENT_TYPE',
  EVENT = 'EVENT'
}
