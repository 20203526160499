import { NavigateFunction } from 'react-router-dom';

import { EventViewType, TFailureActionPayload } from 'types';

export enum ETimeFilters {
  ALL = 'ALL',
  FUTURE = 'FUTURE',
  IN_PLAY = 'IN_PLAY',
  TOMORROW = 'TOMORROW',
  TODAY = 'TODAY'
}

export type TTimeFilterContext = {
  label: string;
  value: ETimeFilters;
};

export type ICountryCompetitionBySportPayload = { countryId: string | number; sportId: string | number };
export type IGetEventPayload = { eventId: string; showGroups?: boolean; updateParents?: boolean; allSoccer?: boolean };

export type TNavigationResponse = {
  children: TNavigationResponse[];
  closedDate: string;
  countryCode: string;
  countryCompetitions: string;
  marketCount: number;
  displayMarketStartTime: boolean;
  eventTypeId: string | number;
  exchangeId: string | number;
  fancyMarketsEnabled: boolean;
  fancyView: boolean;
  hasMatchOdds: boolean;
  highlighted: boolean;
  iconName: string | null;
  id: string;
  inPlay: boolean;
  liveScoreEnabled: boolean;
  marketOddsAvailable: boolean;
  marketStartTime?: number;
  marketType: string;
  name: string;
  numberOfWinners: number;
  outrightMarket: boolean;
  parents: [];
  realEvent: boolean;
  runningBallEnabled: boolean;
  startTime: string;
  translations: { [key: string]: string };
  type: string;
  venue: string;
  updateParents?: boolean;
  continueLoading?: boolean;
  videoStreamingEnabled: boolean;
  matchStatEnabled: boolean;
};

export type TTopCountryItem = {
  children: [];
  closedDate: string;
  countryCode: string;
  countryCompetitions: string;
  marketCount: number;
  displayMarketStartTime: string;
  eventTypeId: string | number;
  exchangeId: string | number;
  fancyMarketsEnabled: boolean;
  fancyView: boolean;
  hasMatchOdds: boolean;
  highlighted: boolean;
  iconName: string | null;
  id: string;
  inPlay: boolean;
  liveScoreEnabled: boolean;
  marketOddsAvailable: boolean;
  marketStartTime: string;
  marketType: string;
  name: string;
  numberOfWinners: number;
  outrightMarket: boolean;
  parents: [];
  realEvent: boolean;
  runningBallEnabled: boolean;
  startTime: string;
  translations: { [key: string]: string };
  type: string;
  venue: string;
};

export type TCompetitionData = {
  availableTimeFilters: ETimeFilters[];
  more: TNavigationResponse[];
  moreCompetitions: [];
  parents: TNestedItem[];
  sportHasHighlightedCompetition: boolean;
  topCompetitions: TNavigationResponse[];
  topCountries: TTopCountryItem[];
  translations?: ITranslation;
  highlighted?: boolean;
};

export type TNestedItem = {
  children: TNestedItem[];
  closedDate: Date;
  countryCode: string;
  displayMarketStartTime: string;
  displaySettings?: {
    id: number;
    entryId: string;
    order: number;
    enabled: boolean;
    newSportEnabled: true;
    walletGroupId: number;
  };
  eventTypeId: string;
  exchangeId: string;
  fancyMarketsEnabled: boolean;
  fancyView: boolean;
  hasMatchOdds: boolean;
  highlighted: boolean;
  iconName: string;
  id: string;
  inPlay: boolean;
  liveScoreEnabled: boolean;
  marketOddsAvailable: boolean;
  marketStartTime: string;
  marketType: string;
  name: string;
  numberOfWinners: number;
  outrightMarket: boolean;
  parents: TNestedItem[];
  realEvent: boolean;
  runningBallEnabled: boolean;
  startTime: string;
  translations: { [key: string]: string };
  type: string;
  venue: string;
};

export type ICompetitionDetails = {
  children: TNestedItem[];
  closedDate: string;
  countryCode: string;
  competitions: TCompetitionListItem[];
  displayMarketStartTime: boolean;
  displaySettings: {
    id: number | string;
    entryId: string;
    order: number;
    enabled: boolean;
    newSportEnabled: boolean;
    walletGroupId: number | string;
    bettingEnabled: boolean;
    cashoutEnabled: boolean;
    childrenOrder: number;
    competitionMarketsCount: number;
    competitionViewOrder: number;
    context: string;
    disabledDate: string;
    eventTypeId: string;
    hasHighlightedCompetition: boolean;
    highlights: boolean;
    iconName: string;
    inPlaySport: boolean;
    inPlaySportsOrder: number;
    lastMarketStartDate: string;
    layEnabled: boolean;
    listViewDefaultEvent: boolean;
    marketOddsEnabled: boolean;
    name: string;
    navBarEnabled: boolean;
    promoted: boolean;
    promotionOrder: number;
    sportView: string;
    totalMatched: number;
    updateDate: string;
  };
  eventTypeId: string;
  exchangeId: string;
  fancyMarketsEnabled: boolean;
  fancyView: boolean;
  hasMatchOdds: boolean;
  highlighted: boolean;
  iconName: string;
  id: string;
  inPlay: boolean;
  liveScoreEnabled: boolean;
  marketOddsAvailable: boolean;
  marketStartTime: string;
  marketType: string;
  name: string;
  numberOfWinners: number;
  outrightMarket: boolean;
  parents: TNestedItem[];
  realEvent: boolean;
  runningBallEnabled: boolean;
  startTime: string;
  translations: { [key: string]: string };
  type: string;
  venue: string;
};

export type TCountryCompetitions = {
  children: TNestedItem[];
  closedDate: string;
  countryCode: string;
  countryCompetitions: TNavigationResponse[];
  displayMarketStartTime: boolean;
  eventTypeId: string;
  exchangeId: string;
  fancyMarketsEnabled: boolean;
  fancyView: boolean;
  hasMatchOdds: boolean;
  highlighted: boolean;
  iconName: string;
  id: string;
  inPlay: boolean;
  liveScoreEnabled: boolean;
  marketCount: number;
  marketOddsAvailable: boolean;
  marketStartTime: string;
  marketType: string;
  name: string;
  numberOfWinners: number;
  outrightMarket: boolean;
  parents: TNestedItem[];
  realEvent: boolean;
  runningBallEnabled: boolean;
  startTime: string;
  translations: { [key: string]: string };
  type: string;
  venue: string;
};

export type TMarketResponse = {
  cashOutEnabled: boolean;
  closedDate?: number;
  commission: number;
  competition: { id: string };
  description: {
    marketType: string;
  };
  event: { id: string; name: string; countryCode: string; timezone: string; venue: null; openDate: number };
  eventType: { id: string; name: string };
  fancyView: boolean;
  liveScoreEnabled: boolean;
  marketId: string;
  marketName: string;
  marketStartTime: number;
  numberOfWinners: number;
  outright: boolean;
  parents: TNestedItem[];
  partialCashOutEnabled: boolean;
  runners: [];
  totalMatched: number;
};

export type ITranslation = { [key: string]: string } | string;

export type TCompetitionListItem = {
  id: string;
  name: string;
  translations: { [key: string]: string };
};

export type TCompetition = {
  competitionData: TNavigationResponse[];
  competitions: TCompetitionListItem[];
  sportData: TNavigationResponse[];
  sportTopCompetitions: TNavigationResponse[];
  sportTopCountries: TTopCountryItem[];
  groupData: TNavigationResponse[];
  countryData: TNavigationResponse[];
  eventData: TNavigationResponse[];
  sportMore: TNavigationResponse[];
  nameTranslations: ITranslation;
  sportNameTranslations: ITranslation;
  countryNameTranslations: ITranslation;
  eventNameTranslations: ITranslation;
  groupNameTranslations: ITranslation;
  groupName: string;
  competitionNameTranslations: Record<string, string>;
  loading: boolean;
  market: TMarketResponse;
  competitionParents: TNestedItem[];
  countryParents: TNestedItem[];
  eventParents: TNestedItem[];
  marketParents: TNestedItem[];
  groupParents: TNestedItem[];
  sportParents: TNestedItem[];
  race: TNavigationResponse | null;
  sportLoading: boolean;
  sportNameHighlighted: boolean;
  eventLoading: boolean;
  eventNameHighlighted: boolean;
  competitionLoading: boolean;
  competitionNameHighlighted: boolean;
  marketLoading: boolean;
  groupLoading: boolean;
  groupNameHighlighted: boolean;
  raceLoading: boolean;
  countryLoading: boolean;
  countryNameHighlighted: boolean;
  isAllSoccer: boolean;
  sportAvailableFilters: ETimeFilters[];
  todayCardPageLoading: boolean;
  eventDetails: { id: string; type: string };
  competitionDetails: { id: string; type: string };
  error: TFailureActionPayload;
};

export enum ENavigationType {
  COMPETITION = 'COMPETITION',
  EVENT = 'EVENT',
  COUNTRY = 'COUNTRY',
  RACE = 'RACE'
}

export type TFetchSportsPayload = {
  sportId: string;
  raceId?: string;
  noReset?: boolean;
};

export type TCompetitionPayload = {
  response: TCompetitionData;
  id: string;
  allSoccer: boolean;
};

export type TFetchTimeFiltersPayload = {
  contextFilter: string;
  id: string;
  timeFilter: string;
  viewBy: string;
};

export type IGetTCMarketAndRedirectPayload = {
  id: string;
  navigate: NavigateFunction;
};

export type TMultiMarketResponse = {
  eventTypeId: string;
  fixPosition: boolean;
  topMarketId: string;
  twoColumnView: boolean;
  viewType: EventViewType;
};
