import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { TAsianBetStatusesPayload } from 'redux/modules/asianViewBetslip/type';

import {
  failureFetchQuickBetStatuses,
  failurePlaceQuickBet,
  fetchQuickBetStatuses,
  placeQuickBet,
  successFetchQuickBetStatuses,
  successPlaceQuickBet
} from './index';
import { TPlacedQuickBetsByMarket } from './type';

function* getPlaceQuickBetWorker(action: ReturnType<typeof placeQuickBet>) {
  try {
    const response: TPlacedQuickBetsByMarket = yield call(api.asianPlacement.placeQuickBets, action.payload);

    Object.keys(response).forEach(marketId => {
      response[marketId].identifier = action.payload[marketId][0].betUuid;
    });

    yield put(successPlaceQuickBet(response));
  } catch (error: any) {
    yield put(failurePlaceQuickBet({ data: action.payload, error }));
  }
}

function* getQuickBetStatusesWorker(action: ReturnType<typeof fetchQuickBetStatuses>) {
  try {
    const response: TAsianBetStatusesPayload = yield call(
      api.asianPlacement.getBetsStatuses,
      action.payload.offerIds.join()
    );

    yield put(successFetchQuickBetStatuses(response));
  } catch (error: any) {
    yield put(failureFetchQuickBetStatuses({ offerIds: action.payload.offerIds, error: error?.response?.data ?? '' }));
  }
}

export default function* saga() {
  yield all([
    takeEvery(placeQuickBet.type, getPlaceQuickBetWorker),
    takeEvery(fetchQuickBetStatuses.type, getQuickBetStatusesWorker)
  ]);
}
