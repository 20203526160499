import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { componentsBranding } from 'constants/branding';
import { ACCOUNT_RESET_TO_DEFAULT_STATE } from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';

import styles from './DesktopSettingsActions.module.scss';

type DesktopSettingsActionsProps = {
  isEditMode: boolean;
  onCancel: () => void;
  onReset?: () => void;
  onSave: () => void;
  onEdit: () => void;
  isSaveBtnDisabled: boolean;
  isSaveLoading: boolean;
};

const DesktopSettingsActions = ({
  isEditMode,
  onSave,
  onReset,
  onCancel,
  onEdit,
  isSaveBtnDisabled,
  isSaveLoading
}: DesktopSettingsActionsProps) => {
  const { t } = useTranslation();
  const { translationKey, isEnabled } = useTooltip(ACCOUNT_RESET_TO_DEFAULT_STATE);

  if (isEditMode) {
    return (
      <div className={styles.actions}>
        <button onClick={onCancel} className={classNames(styles.actions__action, componentsBranding.SECONDARY_BTN)}>
          {t('account.settings.quickBets.buttons.cancel')}
        </button>
        {onReset && (
          <button
            data-tooltip-id="tooltip"
            data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
            onClick={onReset}
            className={classNames(styles.actions__action, componentsBranding.SECONDARY_BTN)}
          >
            {t('account.settings.quickBets.buttons.reset')}
          </button>
        )}
        <button
          onClick={onSave}
          className={classNames(styles.actions__action, styles.actions__save, componentsBranding.PRIMARY_BTN, {
            [componentsBranding.DISABLED]: isSaveBtnDisabled || isSaveLoading
          })}
          disabled={isSaveBtnDisabled || isSaveLoading}
        >
          {isSaveLoading ? (
            <i className={classNames('fa fa-spinner fa-pulse fa-fw', styles.actions__save__loading)} />
          ) : (
            t('account.settings.quickBets.buttons.save')
          )}
        </button>
      </div>
    );
  }

  return (
    <button
      onClick={onEdit}
      className={classNames(styles.actions__action, styles.actions__edit, componentsBranding.SECONDARY_BTN)}
    >
      {t('account.settings.quickBets.buttons.edit')}
    </button>
  );
};

export default DesktopSettingsActions;
