import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_CURRENCY } from 'constants/app';
import { getCurrencies, getMultiCurrencySupported } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getUserCurrency } from 'redux/modules/user/selectors';

const useMultiCurrencySupporting = () => {
  const multiCurrencySupported = useSelector(getMultiCurrencySupported);
  const currencies = useSelector(getCurrencies);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const userCurrency = useSelector(getUserCurrency);

  const stringifiedCurrencies = JSON.stringify(currencies);

  return useMemo(() => {
    return {
      multiCurrencySupported,
      currencies,
      isMultiCurrencySupported: multiCurrencySupported && currencies.length > 1,
      isMultiCurrencyChanged: (marketCurrency: string = DEFAULT_CURRENCY) =>
        isLoggedIn && !!marketCurrency && !!userCurrency && userCurrency !== marketCurrency
    };
  }, [stringifiedCurrencies, isLoggedIn, multiCurrencySupported, userCurrency]);
};

export default useMultiCurrencySupporting;
