import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { PREV_PAGE_BEFORE_LOGIN } from 'constants/app';
import { ACCOUNT_BASE_URL, ASIAN_BASE_URL, GAMES_BASE_URL, MOBILE_LOGIN_BASE_URL } from 'constants/locations';
import { SEPARATE_WALLET } from 'constants/myBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getMobileSettingsLoginHeaderButtons, getWalletIntegrationType } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getAVBalance, getBalance } from 'redux/modules/user/selectors';

import styles from './styles.module.scss';

const MobileTopMenu = () => {
  const { t } = useTranslation();
  const prevLocation = useLocation();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const balance = useSelector(getBalance);
  const avBalance = useSelector(getAVBalance);
  const loginHeaderButtons = useSelector(getMobileSettingsLoginHeaderButtons);
  const walletIntegrationType = useSelector(getWalletIntegrationType);

  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);
  const isGamesPage = location.pathname.includes(GAMES_BASE_URL);
  const isAccountPage = location.pathname.includes(ACCOUNT_BASE_URL);

  const exchangePage = (isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || '';
  const loginUrl = isLoggedIn ? ACCOUNT_BASE_URL : MOBILE_LOGIN_BASE_URL;

  const { noFormattedAmount: userBalance } = useFormatCurrency((isAsianViewPage ? avBalance : balance) || 0, '', {
    noRounding: true,
    isCheckIndian: true
  });

  if (!isLoggedIn && isAsianViewPage) {
    localStorage.setItem(PREV_PAGE_BEFORE_LOGIN, prevLocation.pathname);
  }

  const hasAccountLabel = isAccountPage && walletIntegrationType === SEPARATE_WALLET;

  const label = isLoggedIn ? (hasAccountLabel ? t('mobileMenu.account') : userBalance) : t('mobileMenu.login');

  return (
    <div id="biab_mobileTopMenu" className={styles.mobileTopMenu}>
      <Link
        to={`${exchangePage}${loginUrl}`}
        className={classNames('biab_mobile-top-menu-link', styles.mobileTopMenuLink)}
        state={{ referer: location.pathname }}
      >
        <i className="fa2 fa2-person biab_icon" />
        {(loginHeaderButtons || isLoggedIn) && <span>{label}</span>}
      </Link>
    </div>
  );
};

export default MobileTopMenu;
