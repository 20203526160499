import { Dispatch, memo, SetStateAction } from 'react';
import { useSelector } from 'react-redux';

import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getEventTabs } from 'redux/modules/competitions/selectors';
import { getListViewMarketsTabs } from 'redux/modules/listViewTabs/selectors';
import { getRaceTabs } from 'redux/modules/market/selectors';
import { TListViewTabs } from 'types';
import { IMarket } from 'types/markets';

import DesktopList from './components/DesktopList';
import MobileList from './components/MobileList';

interface ListViewTabsProps {
  onSelectTabMobile: Dispatch<SetStateAction<boolean>>;
  mobileListViewTabs: IMarket[];
  marketId: string;
  sportId: string;
  isHorseTab?: boolean;
}

const ListViewTabs = ({ onSelectTabMobile, mobileListViewTabs, isHorseTab, sportId, marketId }: ListViewTabsProps) => {
  const device = useSelector(getAppDevice);
  const contentSelector = isHorseTab ? getRaceTabs : getListViewMarketsTabs;
  const eventTabs: TListViewTabs[] = useSelector(getEventTabs);
  const tabs: TListViewTabs[] = useSelector(contentSelector);

  if (device === Devices.DESKTOP) {
    return (
      <DesktopList tabs={isHorseTab && tabs.length === 0 ? eventTabs : tabs} sportId={sportId} marketId={marketId} />
    );
  }

  const mobileTabs = isHorseTab ? tabs : mobileListViewTabs.map(item => ({ id: item.marketId, name: item.marketName }));
  return (
    <MobileList
      marketId={marketId}
      sportId={sportId}
      onSelectAllMarketsTab={onSelectTabMobile}
      mobileListViewTabs={mobileTabs}
    />
  );
};

export default memo(ListViewTabs);
