import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { failureFetchPageData, fetchPageData, successFetchPageData } from './index';
import { PageContentType } from './types';

export function* getPageWatcher(action: ReturnType<typeof fetchPageData>) {
  try {
    const response: PageContentType = yield call(api.app.getPageContent, action.payload);
    yield put(successFetchPageData(response));
  } catch (error: any) {
    yield put(failureFetchPageData(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchPageData.type, getPageWatcher)]);
}
