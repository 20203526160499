import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';

import { TPLTable, TRequestCurrentPL, TResponsePLTable } from './type';

const initialState: TPLTable = {
  awayTeam: {},
  currentPL: [],
  homeTeam: {},
  worstCasePL: [],
  loading: null,
  error: null,
  stringifiedPLTable: '',
  isFirstLoaded: false
};

const slice = createSlice({
  name: SLICES_NAMES.PL_TABLE,
  initialState,
  reducers: {
    fetchPLTable: (state, _: PayloadAction<TRequestCurrentPL>) => {
      state.loading = true;
    },
    successFetchPLTable: (state, { payload }: PayloadAction<TResponsePLTable>) => {
      state.loading = false;

      if (!state.isFirstLoaded) {
        state.isFirstLoaded = true;
      }

      const stringifiedPLTableDataFromResponse = JSON.stringify(payload);

      if (state.stringifiedPLTable !== stringifiedPLTableDataFromResponse) {
        state.awayTeam = payload.awayTeam || initialState.awayTeam;
        state.currentPL = payload.currentPL || initialState.currentPL;
        state.homeTeam = payload.homeTeam || initialState.homeTeam;
        state.worstCasePL = payload.worstCasePL || initialState.worstCasePL;
        state.stringifiedPLTable = stringifiedPLTableDataFromResponse;
      }
    },
    failureFetchPLTable: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = payload;
    },
    cleanPLTable: () => initialState
  }
});

export const { fetchPLTable, successFetchPLTable, failureFetchPLTable, cleanPLTable } = slice.actions;

export default slice.reducer;
