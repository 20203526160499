import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getMatchStatUrl, getNonAuthMatchStatisticsEnabled } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';

import styles from './styles.module.scss';

interface MatchStatisticsWidgetContentProps {
  eventId: string;
}

const MatchStatisticsWidgetContent = ({ eventId }: MatchStatisticsWidgetContentProps) => {
  const { t } = useTranslation();

  const nonAuthMatchStatisticsEnabled = useSelector(getNonAuthMatchStatisticsEnabled);
  const matchStatUrl = useSelector(getMatchStatUrl);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const getMatchStatisticsContent = () => {
    if (nonAuthMatchStatisticsEnabled || isLoggedIn) {
      const src = matchStatUrl ? `${matchStatUrl}${eventId}` : window.location.href;

      return <iframe src={src} height={500} width="100%" className={styles.matchStatisticsWidgetContent__frame} />;
    }

    return <p className={styles.matchStatisticsWidgetContent__error}>{t('match.statistics.messages.login')}</p>;
  };

  return getMatchStatisticsContent();
};

export default MatchStatisticsWidgetContent;
