import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import {
  getPLTableAwayTeam,
  getPLTableCurrentPL,
  getPLTableHomeTeam,
  getPLTableWorstCasePL
} from 'redux/modules/plTable/selectors';
import { generateArray } from 'utils/general';

import CurrentPL from '../CurrentPL';
import { getTableValue } from '../helpers';

import styles from './styles.module.scss';

interface HandicapTableProps {
  currencyCode: string;
}

const HandicapTable = ({ currencyCode }: HandicapTableProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const currentPL = useSelector(getPLTableCurrentPL);
  const worstCasePL = useSelector(getPLTableWorstCasePL);
  const awayTeam = useSelector(getPLTableAwayTeam);
  const homeTeam = useSelector(getPLTableHomeTeam);

  const isMobile = device === Devices.MOBILE;
  const [[homeKey, homeName]] = Object.entries(homeTeam);
  const [[awayKey, awayName]] = Object.entries(awayTeam);
  const { currentValues, header, awayLength, homeLength, curPLHomeValue, curPLAwayValue } = getTableValue(
    currentPL,
    worstCasePL,
    homeKey,
    awayKey
  );
  const cols = generateArray(header.length + 1);

  if (isMobile) {
    return (
      <div className="biab_pl-table-wrapper js-pl-table-wrapper">
        <div className="biab_pl-table-wrapper">
          <table className="biab_pl-table">
            <colgroup>
              <col width="300" />
              <col width="150" />
              <col width="300" />
            </colgroup>
            <thead>
              <tr>
                <th className={styles.table__col} />
                <th className={styles.table__col} />
                <th className={styles.table__col}>{t('PLTable.currentPL')}</th>
              </tr>
            </thead>
            <tbody>
              {curPLHomeValue.map((item, index) => (
                <tr key={`${item}-${index}`}>
                  {index === 0 && (
                    <td
                      className={classnames(
                        'biab_points-col',
                        styles.table__col,
                        styles.mobileTeamName,
                        styles.table__colBackground
                      )}
                      rowSpan={homeLength}
                    >
                      {`${homeName} ${t('PLTable.winBy')}`}
                    </td>
                  )}
                  <td className={classnames('biab_points-col', styles.mobileCol)}>{header[index]}</td>
                  <td className={classnames('biab_pl-col', styles.mobileCol, styles.table__mobileColBackground)}>
                    <CurrentPL value={item} currencyCode={currencyCode} wightNormal />
                  </td>
                </tr>
              ))}
              <tr>
                <th className={classnames('biab_draw-col', styles.table__col)} colSpan={2}>
                  {t('PLTable.draw')}
                </th>
              </tr>
              {curPLAwayValue.map((item, index) => (
                <tr key={`${item}-${index}`}>
                  {index === 0 && (
                    <td
                      className={classnames(
                        'biab_points-col',
                        styles.table__col,
                        styles.mobileTeamName,
                        styles.table__colBackground
                      )}
                      rowSpan={awayLength}
                    >
                      {`${awayName} ${t('PLTable.winBy')}`}
                    </td>
                  )}
                  <td className={classnames('biab_points-col', styles.mobileCol)}>
                    {header[curPLHomeValue.length + index]}
                  </td>
                  <td className={classnames('biab_pl-col', styles.mobileCol, styles.table__mobileColBackground)}>
                    <CurrentPL value={item} currencyCode={currencyCode} wightNormal />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className={classnames('biab_pl-table-wrapper', styles.contentContainer)}>
      <table className={classnames('biab_pl-table', styles.table)}>
        <colgroup>
          {cols.map(value => (
            <col key={value} width="50" />
          ))}
        </colgroup>
        <thead>
          <tr>
            <th className={classnames('biab_team-col', styles.table__col)} colSpan={homeLength}>
              {`${homeName} ${t('PLTable.winBy')}`}
            </th>
            <th className={classnames('biab_draw-col', styles.table__normalText)} colSpan={1} rowSpan={2}>
              {t('PLTable.draw')}
            </th>
            <th className={classnames('biab_team-col', styles.table__col)} colSpan={awayLength}>
              {`${awayName} ${t('PLTable.winBy')}`}
            </th>
          </tr>

          <tr>
            {header.map((item, index) => (
              <td key={`${index}-${item}`} className={styles.table__col}>
                {item}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentValues.length > 1 && (
            <>
              <tr>
                <th className={classnames(styles.table__col, styles.table__normalText)} colSpan={11}>
                  {t('PLTable.currentPL')}
                </th>
              </tr>
              <tr>
                {currentValues.map((item, index) => (
                  <td key={`${index}${item}`} className={styles.table__colBackground}>
                    <CurrentPL value={item} currencyCode={currencyCode} />
                  </td>
                ))}
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default HandicapTable;
