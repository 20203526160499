import { useTranslation } from 'react-i18next';
import { unescape } from 'lodash';

import rotateDeviceImg from 'assets/images/rotateDevice.gif';

import styles from './styles.module.scss';

const RotateDevice = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.main}>
      <img src={rotateDeviceImg} alt="" />
      <div dangerouslySetInnerHTML={{ __html: unescape(t('games.unsupportedOrientationMessage')) }} />
    </div>
  );
};

export default RotateDevice;
