import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { ROOT_URL } from 'constants/locations';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { urls } from 'redux/api/urls';
import { getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { getIsLandscapeAsianView, getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { getBackendPageData } from 'redux/modules/pages/selectors';
import { getDefaultLinkTarget } from 'utils/navigation';

import styles from './styles.module.scss';

const AsianViewNotFoundView = () => {
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const data = useSelector(getBackendPageData);
  const isMobile = useSelector(getIsMobileAsianView);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);

  const { pageNotFoundBackGroundId, pageNotFoundLinks } = useDeviceSettings();

  return (
    <div
      className={classNames('biab_static-content biab_not-found-page asian_view-not-found-page', styles.notFound, {
        [styles.notFound__mobile]: isMobile && !isLandscapeView
      })}
      style={{
        background: pageNotFoundBackGroundId
          ? `url(
              '${
                window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL
              }/customer/api/${urls.app.customerImagesEndpoint(pageNotFoundBackGroundId)}'
            ) center center / cover no-repeat fixed`
          : ''
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: unescape(data?.content ?? '') }} />
      {!!pageNotFoundLinks.length && (
        <div
          className={classNames(styles.links, {
            [styles.links__mobile]: isMobile && !isLandscapeView
          })}
        >
          {pageNotFoundLinks.map(({ orderNumber, url, openInNewTab, label, absolutePath }) => {
            const target = getDefaultLinkTarget({
              url: `${!absolutePath ? ROOT_URL : ''}${url}`,
              absolutePath,
              isIframeEnabled,
              currentTarget: openInNewTab ? '_blank' : '',
              openInNewTab
            });

            if (absolutePath) {
              return (
                <a
                  key={orderNumber}
                  href={url}
                  target={target}
                  className={classNames(styles.link, {
                    [styles.link__mobile]: isMobile && !isLandscapeView
                  })}
                >
                  {label}
                </a>
              );
            }

            return (
              <Link
                key={orderNumber}
                to={url}
                target={target}
                className={classNames(styles.link, {
                  [styles.link__mobile]: isMobile && !isLandscapeView
                })}
              >
                {label}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AsianViewNotFoundView;
