import { ButtonHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

import { componentsBranding } from 'constants/branding';

import styles from './styles.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: 'primary' | 'secondary';
  className?: string;
}

const Button = ({ children, className, variant = 'primary', ...props }: ButtonProps): JSX.Element => {
  return (
    <button
      className={classNames(styles.button, className, {
        [componentsBranding.PRIMARY_BTN]: variant === 'primary',
        [`${componentsBranding.SECONDARY_BTN} ${styles.button__secondary}`]: variant === 'secondary'
      })}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
