import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import Dropdown from 'components/SportsSection/Dropdown';
import styles from 'components/SportsSection/styles.module.scss';
import { TIME_FILTERS } from 'constants/app';
import { SPORT_BASE_URL } from 'constants/locations';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { getScrollContentHeight } from 'redux/modules/appSettings/selectors';
import { getCompetitions } from 'redux/modules/competitions/selectors';
import { TCompetitionListItem } from 'redux/modules/competitions/type';
import { SportId } from 'types';

import FavouriteInDropdown from './partials/FavouriteInDropdown';

const NonTennisAndSMPDropdown = () => {
  const { sportId = '', competitionId = '' } = useParams();
  const navigate = useNavigate();

  const competitions = useSelector(getCompetitions);
  const scrollContentHeight = useSelector(getScrollContentHeight);
  const domainLanguage = useSelector(getTranslation);

  const isSingleCompetitionPage = !TIME_FILTERS.includes(competitionId);
  const isTennis = sportId === SportId.TENNIS;

  const handleCompetitionClick = (id: string) => {
    navigate(`${SPORT_BASE_URL}/${sportId}/competition/${id}`);
  };

  return (
    <>
      {isSingleCompetitionPage && !isTennis && !!competitions?.length && (
        <Dropdown icon={<i className={'fas fa-ellipsis-h'} />}>
          <ul
            className={classNames('biab_competition-dropdown-container', styles.nonTennisDropList)}
            style={{
              maxHeight:
                scrollContentHeight < 800
                  ? `${scrollContentHeight - 230 < 525 ? scrollContentHeight - 230 : 525}px`
                  : '525px'
            }}
          >
            {competitions.map((c: TCompetitionListItem, index) => (
              <li
                data-test-id={index}
                onClick={() => handleCompetitionClick(c.id)}
                key={c.id}
                className={classNames(
                  styles.dropdownItem,
                  styles.dropdownItemCompetition,
                  'biab_competition-dropdown-item biab_nav-filters-dropdown-item'
                )}
              >
                {c.translations[domainLanguage] || c.name}
                <FavouriteInDropdown sportId={sportId} item={c} />
              </li>
            ))}
          </ul>
        </Dropdown>
      )}
    </>
  );
};

export default NonTennisAndSMPDropdown;
