import { useSelector } from 'react-redux';

import { getIsSportLayOddsDisabled } from 'redux/modules/appConfigs/selectors';
import { isLineBettingType } from 'utils/betslip';

const useLayColumn = ({ sportId, bettingType }: { sportId?: string; bettingType?: string }) => {
  const isDisabledLayOdds = useSelector(getIsSportLayOddsDisabled(sportId));

  const isLineMarket = !!bettingType && isLineBettingType(bettingType);

  return {
    isLayColumnEnabled: !isDisabledLayOdds || isLineMarket,
    isLineMarket
  };
};

export default useLayColumn;
