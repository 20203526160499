import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { MOBILE_PL_TABLE_HEADER, PL_TABLE_HEADER } from 'constants/app';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import {
  getPLTableAwayTeam,
  getPLTableCurrentPL,
  getPLTableHomeTeam,
  getPLTableWorstCasePL
} from 'redux/modules/plTable/selectors';
import { generateArray } from 'utils/general';

import CurrentPL from '../CurrentPL';
import { getTeamName } from '../helpers';

import styles from './styles.module.scss';

interface AsianHandicapTableProps {
  currencyCode: string;
}

const AsianHandicapTable = ({ currencyCode }: AsianHandicapTableProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const currentPL = useSelector(getPLTableCurrentPL);
  const worstCasePL = useSelector(getPLTableWorstCasePL);
  const awayTeam = useSelector(getPLTableAwayTeam);
  const homeTeam = useSelector(getPLTableHomeTeam);

  const isMobile = device === Devices.MOBILE;
  const cols = generateArray(11);
  const homeName = getTeamName(homeTeam);
  const awayName = getTeamName(awayTeam);

  if (isMobile) {
    return (
      <div className="biab_modal-body biab_pl-table-modal-body">
        <div className={classNames('biab_pl-table-wrapper', styles.mobileContainer)}>
          <div className="biab_pl-table-wrapper">
            <table className="biab_pl-table">
              <colgroup>
                <col width="300" />
                <col width="150" />
                {currentPL.length && <col width="300" />}
                {worstCasePL.length && <col width="300" />}
              </colgroup>
              <thead>
                <tr>
                  <th className={classNames('biab_points-col', styles.table__mobile__col)} />
                  <th className={classNames('biab_points-col', styles.table__mobile__col)} />
                  {!!currentPL.length && (
                    <th className={classNames('biab_points-col', styles.table__mobile__col)}>
                      {t('PLTable.currentPL')}
                    </th>
                  )}
                  {!!worstCasePL.length && (
                    <th className={classNames('biab_points-col', styles.table__mobile__col)}>
                      {t('PLTable.worstCasePL')}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {MOBILE_PL_TABLE_HEADER.map((item, index) => (
                  <tr key={`${item}-${index}`}>
                    {(index === 0 || index === 6) && (
                      <th
                        className={classNames(
                          'biab_team-col',
                          styles.table__mobile__title,
                          styles.mobileContainer__name
                        )}
                        rowSpan={5}
                      >
                        {index === 0 ? `${homeName} ${t('PLTable.winBy')}` : `${awayName} ${t('PLTable.winBy')}`}
                      </th>
                    )}
                    {index === 5 ? (
                      <th className={classNames('biab_draw-col', styles.table__mobile__col)} colSpan={2}>
                        {t('PLTable.draw')}
                      </th>
                    ) : (
                      <td className={classNames('biab_points-col', styles.table__mobile__col)}>{item}</td>
                    )}
                    {currentPL[index] && (
                      <td className={styles.table__mobile__colBackground}>
                        <CurrentPL value={currentPL[index]?.profitOrLoss} currencyCode={currencyCode} wightNormal />
                      </td>
                    )}
                    {worstCasePL[index] && (
                      <td className={styles.table__mobile__colBackground}>
                        <CurrentPL value={worstCasePL[index].profitOrLoss} currencyCode={currencyCode} wightNormal />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('biab_pl-table-wrapper', styles.contentContainer)}>
      <table className={classNames('biab_pl-table', styles.table)}>
        <colgroup>
          {cols.map(value => (
            <col key={value} width="50" />
          ))}
        </colgroup>
        <thead>
          <tr>
            <th className={classNames('biab_team-col', styles.table__col)} colSpan={5}>
              {`${homeName} ${t('PLTable.winBy')}`}
            </th>
            <th className={classNames('biab_draw-col', styles.table__col, styles.table__normalText)} rowSpan={2}>
              {t('PLTable.draw')}
            </th>
            <th className={classNames('biab_team-col', styles.table__col)} colSpan={5}>
              {`${awayName} ${t('PLTable.winBy')}`}
            </th>
          </tr>

          <tr>
            {PL_TABLE_HEADER.map((item, index) => (
              <td key={`${index}-${item}`} className={styles.table__col}>
                {item}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentPL.length > 1 && (
            <>
              <tr>
                <th className={classNames(styles.table__col, styles.table__normalText)} colSpan={11}>
                  {t('PLTable.currentPL')}
                </th>
              </tr>
              <tr>
                {currentPL.map((item, index) => (
                  <td key={`${index}${item}`} className={styles.table__colBackground}>
                    <CurrentPL value={item.profitOrLoss} currencyCode={currencyCode} />
                  </td>
                ))}
              </tr>
            </>
          )}

          {worstCasePL.length > 1 && (
            <>
              <tr>
                <th className={classNames(styles.table__col, styles.table__normalText)} colSpan={11}>
                  {t('PLTable.worstCasePL')}
                </th>
              </tr>
              <tr>
                {worstCasePL.map((item, index) => (
                  <td key={`${index}${item}`} className={styles.table__colBackground}>
                    <CurrentPL value={item.profitOrLoss} currencyCode={currencyCode} />
                  </td>
                ))}
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AsianHandicapTable;
