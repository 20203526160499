import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import ArrowScroll from 'components/ArrowScroll';
import MarketSelectionsRow from 'components/MarketSport/components/MarketSelectionsRow';
import StatusOverlay from 'components/MarketsTable/components/MarketsTableRow/components/StatusOverlay';
import { CELLS_ON_MARKET_PAGE } from 'constants/tooltip';
import { TooltipProvider } from 'contexts/tooltipContext';
import useMarketStatusAndLockIcon from 'hooks/useMarketStatusAndLockIcon';
import useTooltip from 'hooks/useTooltip';
import { TMarketLines } from 'redux/modules/market/type';
import {
  getMarketPricesBettingType,
  getMarketPricesIsBettingEnabled,
  getMarketPricesMarketType,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import {
  setMultiMarketLinesResetLinesText,
  setMultiMarketReset,
  setMultiMarketShowAll
} from 'redux/modules/multiMarket';
import { setTop5ViewLinesResetLinesText, setTop5ViewReset, setTop5ViewShowAll } from 'redux/modules/top5View';
import { PageBlocks, PlacementPage } from 'types';
import { DesktopTopMarketViewType, IMarket } from 'types/markets';
import { getMarketTypes } from 'utils/market';

import styles from './styles.module.scss';

interface DesktopTopMarketProps {
  topMarket: IMarket;
  viewType: DesktopTopMarketViewType;
  showAll?: boolean;
  linesText: string;
  lines: TMarketLines;
  reset?: boolean;
  pageBlock: PageBlocks;
  redirectToSingleMarketPage?: boolean;
  /**
   * Whether market depth is enabled
   */
  isDepthEnabled: boolean;
  /**
   * Whether is landscape mode
   */
  isLandscape: boolean;
  page: PlacementPage;
}

const DesktopTopMarket = ({
  topMarket,
  viewType,
  reset,
  lines,
  linesText,
  showAll,
  pageBlock,
  redirectToSingleMarketPage,
  isDepthEnabled,
  isLandscape,
  page
}: DesktopTopMarketProps) => {
  const dispatch = useDispatch();

  const bettingEnabled = useSelector(getMarketPricesIsBettingEnabled(topMarket.marketId));
  const marketPricesStatus = useSelector(getStatusByMarketPricesId(topMarket.marketId));
  const marketType = useSelector(getMarketPricesMarketType(topMarket.marketId));
  const bettingType = useSelector(getMarketPricesBettingType(topMarket.marketId));

  const { status, showStatus } = useMarketStatusAndLockIcon(bettingEnabled, marketPricesStatus);
  const { translationKey, isEnabled } = useTooltip(CELLS_ON_MARKET_PAGE);

  const { isHandicap } = getMarketTypes(marketType, bettingType);

  const handleShowAllRunners = useCallback(() => {
    if (viewType === DesktopTopMarketViewType.TOP_5) {
      dispatch(setTop5ViewShowAll(true));
    } else if (viewType === DesktopTopMarketViewType.MULTI_MARKET) {
      dispatch(setMultiMarketShowAll(true));
    }
  }, [dispatch, viewType]);

  const handleMinimize = useCallback(() => {
    if (viewType === DesktopTopMarketViewType.TOP_5) {
      dispatch(setTop5ViewShowAll(false));
    } else if (viewType === DesktopTopMarketViewType.MULTI_MARKET) {
      dispatch(setMultiMarketShowAll(false));
    }
  }, []);

  const handleResetView = useCallback(() => {
    if (!showAll) {
      if (viewType === DesktopTopMarketViewType.TOP_5) {
        dispatch(setTop5ViewReset(true));
      } else if (viewType === DesktopTopMarketViewType.MULTI_MARKET) {
        dispatch(setMultiMarketReset(true));
      }
    }
  }, [showAll]);

  const handleSetGroupByIndex = (
    index: number,
    step: number,
    defaultLine: number,
    totalLines: number,
    newLinesText: string
  ) => {
    const newLines = {
      from: index,
      to: index + step,
      currentLine: index,
      defaultLine,
      totalLines
    };

    if (viewType === DesktopTopMarketViewType.TOP_5) {
      dispatch(setTop5ViewLinesResetLinesText({ lines: newLines, reset: false, linesText: newLinesText }));
    } else if (viewType === DesktopTopMarketViewType.MULTI_MARKET) {
      dispatch(setMultiMarketLinesResetLinesText({ lines: newLines, reset: false, linesText: newLinesText }));
    }
  };

  return (
    <>
      <MarketSelectionsRow
        market={topMarket}
        showAll={showAll}
        linesText={linesText}
        lines={lines}
        onMinimize={handleMinimize}
        onShowAllRunners={handleShowAllRunners}
        onResetView={handleResetView}
        showDeepPrices
        isDepthEnabled={isDepthEnabled}
        page={page}
      />
      <div
        className={classNames(styles.topMarket__runnersContainer, {
          ['biab_market']: isHandicap,
          ['biab_handicap-market']: isHandicap
        })}
      >
        <TooltipProvider value={{ isEnabled, translationKey }}>
          <ArrowScroll
            market={topMarket}
            onSetGroupByIndex={handleSetGroupByIndex}
            showAll={showAll}
            reset={reset}
            pageBlock={pageBlock}
            redirectToSingleMarketPage={redirectToSingleMarketPage}
            showDeepPrices
            isDepthEnabled={isDepthEnabled}
            isLandscape={isLandscape}
            page={page}
          />
        </TooltipProvider>
        {showStatus && <StatusOverlay status={status} inMiddle />}
      </div>
    </>
  );
};

export default DesktopTopMarket;
