import { ReactNode } from 'react';

interface TabPanelProps {
  /**
   * The content of TabPanel
   */
  children: ReactNode;

  /**
   * The value of the currently selected tab
   */
  value: number;

  /**
   * The index of the corresponding tab
   */
  index: number;
}

const TabPanel = ({ children, value, index, ...rest }: TabPanelProps) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...rest}>
      {value === index && children}
    </div>
  );
};

export default TabPanel;
