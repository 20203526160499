import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { countOfferStatuses } from 'utils/betslip';

import styles from './styles.module.scss';

type MultiBetsNotificationProps = {
  offers: TCurrentBet[];
  numberOfBets: number;
  numberOfErrors: number;
  numberOfPendingBets: number;
  isError: boolean;
  isPlacedWithBetterOdds: boolean;
  setIsPlacedWithBetterOdds: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
};

const MultiBetsNotification = ({
  offers,
  numberOfBets,
  numberOfErrors,
  numberOfPendingBets,
  isError,
  isPlacedWithBetterOdds,
  setIsPlacedWithBetterOdds,
  isLoading
}: MultiBetsNotificationProps) => {
  const { t } = useTranslation();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isGameBetSlip = useSelector(getIsGameBetSlip);

  const stringifiedOffers = JSON.stringify(offers);

  const countStatuses = useMemo(
    () => countOfferStatuses({ offers, numberOfErrors, isPNCEnabled, isGameBetSlip }),
    [stringifiedOffers, numberOfErrors, isPNCEnabled, isGameBetSlip]
  );
  const statusMessages = useMemo(() => {
    const messages = [];
    if (countStatuses.numberOfCancelled && (!isPNCEnabled || isGameBetSlip)) {
      messages.push(t('betslip.labels.betsCancelled', { n: countStatuses.numberOfCancelled }));
    }
    if (countStatuses.numberOfPartiallyMatched) {
      messages.push(t('betslip.labels.betsPartiallyMatched', { n: countStatuses.numberOfPartiallyMatched }));
    }
    if (countStatuses.numberOfPlacedWithBetterOdds) {
      messages.push(
        t('betslip.labels.pnc.betsPlacedWithBetterOdds', { n: countStatuses.numberOfPlacedWithBetterOdds })
      );
    }
    if (countStatuses.numberOfUnmatched) {
      messages.push(t('betslip.labels.betsUnmatched', { n: countStatuses.numberOfUnmatched }));
    }
    if (countStatuses.numberOfMatched) {
      if (isPNCEnabled && !isGameBetSlip) {
        messages.push(t('betslip.labels.pnc.betsPlaced', { n: countStatuses.numberOfMatched }));
      } else {
        messages.push(t('betslip.labels.betsMatched', { n: countStatuses.numberOfMatched }));
      }
    }
    if (numberOfErrors || (isError ? numberOfPendingBets : false)) {
      messages.push(t('betslip.labels.errors', { n: countStatuses.numberOfBetErrors }));
    }
    return messages;
  }, [countStatuses, numberOfPendingBets, isError, isPNCEnabled, numberOfErrors, isGameBetSlip]);

  useEffect(() => {
    const currentIsPlacedWithBetterOdds = countStatuses.numberOfPlacedWithBetterOdds > 0;

    if (isPlacedWithBetterOdds !== currentIsPlacedWithBetterOdds) {
      setIsPlacedWithBetterOdds(currentIsPlacedWithBetterOdds);
    }
  }, [countStatuses.numberOfPlacedWithBetterOdds]);

  return (
    <div>
      {isLoading && <div>{t('betslip.labels.placingBets', { n: numberOfBets })}</div>}
      {statusMessages.length === 1 && <div>{statusMessages[0]}</div>}
      {statusMessages.length > 1 && (
        <ul className={classNames({ [styles.list]: !isLoading })}>
          {statusMessages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultiBetsNotification;
