import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import { getAppDevice, getOperator } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { DropdownClasses, DropdownItem, ViewBy } from 'types';
import { getArrowDownByOperator } from 'utils/operator';

import styles from './styles.module.scss';

interface ViewByDropdownProps {
  /**
   * 'View by' sort value
   */
  viewBy: ViewBy | string;

  /**
   * Callback that is called when a new option is selected
   * @param newViewBy
   */
  onSelectViewBy: (newViewBy: DropdownItem) => void;

  /**
   * Dropdown options
   */
  options: DropdownItem[];

  /**
   * Customize dropdown with classes
   */
  classes?: DropdownClasses;
  /**
   * Key to find default value
   */
  findByKey?: 'id' | 'value';
  disabled?: boolean;
}

const ViewByDropdown = ({
  disabled,
  viewBy,
  options,
  onSelectViewBy,
  classes,
  findByKey = 'value'
}: ViewByDropdownProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const operator = useSelector(getOperator);
  const defaultValue = options.find(item => item[findByKey] === viewBy);

  const getDropdownIcon = (isOpen: boolean) => {
    return device === Devices.MOBILE ? (
      <i
        className={classNames(getArrowDownByOperator(operator), styles.viewBy__dropdown__icon, {
          [styles.viewBy__dropdown__icon__rotate]: isOpen
        })}
      />
    ) : null;
  };

  if (!options.length) {
    return null;
  }

  return (
    <div
      className={classNames(styles.viewBy__container, {
        'biab_dropbtn-container': device === Devices.MOBILE
      })}
    >
      {device === Devices.DESKTOP && <p className={styles.viewBy__title}>{t('inplay.filters.viewBy')}:</p>}
      <Dropdown
        disabled={disabled}
        defaultValue={defaultValue}
        data={options}
        onSelectOption={onSelectViewBy}
        icon={getDropdownIcon}
        dropdownListIncludesSelected={device === Devices.DESKTOP}
        classes={{
          container: styles.viewBy__dropdown__container,
          placeholder: styles.viewBy__dropdown__placeholder,
          dropdown: classNames(styles.viewBy__dropdown__dropdown, {
            'biab_tabs-nav': device === Devices.MOBILE,
            [classes?.dropdown ?? '']: classes?.dropdown
          }),
          option: classNames(styles.viewBy__dropdown__option, { biab_tab: device === Devices.MOBILE })
        }}
        withBackgroundOverlay={device === Devices.MOBILE}
      />
    </div>
  );
};

export default ViewByDropdown;
