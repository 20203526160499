import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useIntersectionObserver } from 'usehooks-ts';

import BackLayHeader from 'components/AsianViewPageModule/components/BackLayHeader';
import CompetitionTitle from 'components/AsianViewPageModule/components/CompetitionTitle';
import CompetitionTitleMobile from 'components/AsianViewPageModule/components/CompetitionTitleMobile';
import Runners from 'components/AsianViewPageModule/components/Runners';
import { asianViewMiddleSection } from 'constants/branding';
import useAsianLayBetting from 'hooks/useAsianLayBetting';
import useCustomResizeObserver from 'hooks/useCustomResizeObserver';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { TAsianViewRunner } from 'redux/modules/asianView/type';
import { getIsAVCashOutOutrightEnabled } from 'redux/modules/asianViewCashOutCounter/selectors';
import { SportId } from 'types';

import styles from './styles.module.scss';

interface OutRightMarketProps {
  competitionId: string;
  eventId: string;
  startTime: number;
  eventTranslations: Record<string, string>;
  sectionTranslations: Record<string, string>;
  sectionId: string;
  sectionRunners: TAsianViewRunner[];
  width: number;
  cashOutEnabled: boolean;
}

const OutRightMarket = ({
  competitionId,
  eventId,
  startTime,
  eventTranslations,
  sectionRunners,
  sectionId,
  sectionTranslations,
  width,
  cashOutEnabled
}: OutRightMarketProps) => {
  const { sportId = SportId.SOCCER } = useParams();

  const translation = useSelector(getTranslation);
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isCashOutEnabled = useSelector(getIsAVCashOutOutrightEnabled(sportId, competitionId, sectionId));

  const [isOpen, setIsOpen] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);

  const isLayBetting = useAsianLayBetting();
  const { ref, isIntersecting } = useIntersectionObserver({ rootMargin: '300px' });
  const { height } = useCustomResizeObserver({ ref: containerRef });

  useEffect(() => {
    ref(containerRef.current);
  }, []);

  return (
    <div
      ref={containerRef}
      className={classNames(
        styles.outRightMarket,
        asianViewMiddleSection.OUTRIGHT_MARKET,
        asianViewMiddleSection.COUPON_BORDER,
        {
          [styles.outRightMarketMobile]: isMobileAsianView && isIntersecting,
          [styles.outRightMarket__border]: isIntersecting
        }
      )}
    >
      {!isIntersecting && <div style={{ height }} />}
      {isIntersecting && (
        <>
          {isMobileAsianView ? (
            <CompetitionTitleMobile
              competitionId={sectionId}
              name={`${eventTranslations[translation]} - ${sectionTranslations[translation]}`}
              isOpen={isOpen}
              onCollapse={setIsOpen}
              date={startTime}
              isHideFavorite
              showCashOut={cashOutEnabled}
              cashOutEnabled={isCashOutEnabled}
            />
          ) : (
            <CompetitionTitle
              competitionId={sectionId}
              name={`${eventTranslations[translation]} - ${sectionTranslations[translation]}`}
              isOpen={isOpen}
              onCollapse={setIsOpen}
              date={startTime}
              isHideFavorite
              showCashOut={cashOutEnabled}
              cashOutEnabled={isCashOutEnabled}
            />
          )}
          {isOpen && (
            <>
              {isLayBetting && <BackLayHeader width={width} runnerLength={sectionRunners.length} isOutRight />}
              <Runners
                isOutRight
                sectionId={sectionId}
                runners={sectionRunners}
                competitionId={competitionId}
                eventId={eventId}
                marketId={sectionId}
                width={width}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OutRightMarket;
