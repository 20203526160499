export enum WithCollapsePosition {
  LEFT = 'left',
  RIGHT = 'right'
}

export interface WithCollapseClasses {
  container?: string;
  toggle?: string;
  arrow?: string;
}
