import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { removeSelectedMarket } from 'redux/modules/betslip';
import { getBetslipActiveTab } from 'redux/modules/betslip/selectors';
import { EBetslipTabs } from 'redux/modules/betslip/type';
import { getStatusByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { MarketStatus } from 'types';

const BetslipMarket = ({ marketId, eventId }: { marketId: string; eventId: string }) => {
  const dispatch = useDispatch();

  const activeTab = useSelector(getBetslipActiveTab);
  const marketStatus = useSelector(getStatusByMarketPricesId(marketId));
  const isClosed = marketStatus === MarketStatus.CLOSED;
  const isOpenedBetsTab = activeTab === EBetslipTabs.OPEN_BETS;

  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId,
    eventId,
    section: MarketsPricesSocketParamsSections.SportsDesktopSelectedBets
  });

  useEffect(() => {
    if (isClosed && isOpenedBetsTab) {
      dispatch(removeSelectedMarket({ marketId }));
    }
  }, [isClosed]);

  return <div ref={ref} data-market-id={marketId} data-event-id={eventId} data-market-prices="true" />;
};

export default BetslipMarket;
