import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface WelcomePopupProps {
  onLearnMore: () => void;
  isLandscape: boolean;
}

const WelcomePopup = ({ onLearnMore, isLandscape }: WelcomePopupProps) => {
  const { t } = useTranslation();

  return (
    <>
      {isLandscape ? (
        <div className={styles.welcomePopup__body__landscapeWarning}>
          <div className={styles.welcomePopup__body__text}>
            <>{t('tours.mobile.switchToVerticalView')}</>
          </div>
          <i className="biab_tour-icon-rotate" />
        </div>
      ) : (
        <>
          <div className={styles.welcomePopup__body__text}>{t('dialog.welcome.text')}</div>
          <div className={styles.welcomePopup__body__buttonContainer}>
            <button onClick={onLearnMore}> {t('dialog.welcome.continue')}</button>
          </div>
        </>
      )}
    </>
  );
};

export default WelcomePopup;
