import { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Modal from 'components/Modal';
import { useCurrency } from 'hooks/useCurrency';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getCurrency, getIsOperatorBettingLimitsEnabled } from 'redux/modules/appConfigs/selectors';
import { getIsLandscapeAsianView, getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import {
  AsianViewSizeValidationType,
  TAsianSelectedBet,
  TAsianViewPlaceBetErrorMessageId
} from 'redux/modules/asianViewBetslip/type';
import { getBetAmount, getMarketPricesCurrency } from 'redux/modules/marketsPrices/selectors';
import { BetDatabaseName, BetTypes, TPrice, TSize } from 'types/bets';
import { validateSize } from 'utils/size';

import styles from './styles.module.scss';

type BetMaxProps = {
  bet: TAsianSelectedBet;
  sizeInputRef?: RefObject<HTMLInputElement>;
  setIsHovered?: (value: boolean) => void;
  setValidationMessage?: ({
    message,
    validationType,
    messageId
  }: {
    message: string;
    validationType: AsianViewSizeValidationType;
    messageId: null | TAsianViewPlaceBetErrorMessageId;
  }) => void;
  updateBet: ({ price, size }: { price: TPrice; size?: TSize }) => void;
  betMaxClassName?: string;
  displayType?: 'column' | 'row';
};

const BetMax = ({
  bet,
  sizeInputRef,
  setIsHovered = () => {},
  setValidationMessage = () => {},
  updateBet,
  betMaxClassName,
  displayType = 'row'
}: BetMaxProps) => {
  const { t } = useTranslation();

  const handicap = +(bet.handicap || 0);
  const isBack = bet.betType === BetTypes.BACK;
  const betName = isBack ? BetDatabaseName.BACK : BetDatabaseName.LAY;

  const currencyCode = useSelector(getMarketPricesCurrency(bet.marketId));
  const amount = useSelector(getBetAmount(bet.marketId, bet.selectionId, handicap, betName, 0));
  const defaultCurrency = useSelector(getCurrency);
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);
  const isOperatorBettingLimitsEnabled = useSelector(getIsOperatorBettingLimitsEnabled);

  const currency = useCurrency(currencyCode);

  const maxBetSize = currency?.maxBetSize || defaultCurrency.maxBetSize || 0;
  const maxBet = (amount || 0) > maxBetSize ? maxBetSize : amount || 0;

  const { formattedAmount: amountFormatted } = useFormatCurrency(maxBet, currencyCode, {
    isCheckIndian: true,
    noRounding: true,
    ignoreFlexibleDisplayFormat: true,
    ignorePrecision: true
  });

  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);

  const isRow = displayType === 'row';

  const setMaxBet = (value?: number) => {
    const validation = validateSize({
      size: value,
      betType: bet.betType,
      currency,
      defaultCurrency,
      currencyCode,
      isOperatorBettingLimitsEnabled
    });

    if (!validation.isValid && validation.errorMessage?.text) {
      if (sizeInputRef?.current) {
        sizeInputRef.current.focus();
      }

      setValidationMessage({
        message: t(validation.errorMessage.text, (validation.errorMessage.params as object) ?? {}),
        validationType: validation.type,
        messageId: validation.messageId
      });
    }

    updateBet({ price: bet.price, size: value });
  };

  return (
    <>
      <span className={styles.betMaxWrap}>
        <a
          className={classNames(styles.betMax, {
            [styles.betMax__col]: !isRow
          })}
          onClick={() => setMaxBet(maxBet)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <span className={styles.betMax__label}>{t('asianView.labels.betslip.betMax')}:</span>
          <span className={classNames(styles.betMax__value, betMaxClassName)}>
            {isRow && <span>&nbsp;</span>}
            {amountFormatted}
          </span>
        </a>

        <i
          className={classNames('av-icon av-icon-info', styles.infoIcon)}
          onClick={() => setIsPopupOpened(!isPopupOpened)}
        >
          <span className="path1" />
          <span className="path2" />
        </i>
      </span>
      <Modal
        clickableBackground
        open={isPopupOpened}
        onClose={() => setIsPopupOpened(false)}
        title={t('asianView.labels.betslip.betMax')}
        customClassNames={{
          dialog: styles.modal__dialog,
          header: classNames({ [styles.modal__header]: isMobileAsianView }),
          body: classNames({ [styles.modal__body]: isMobileAsianView }),
          title: classNames({ [styles.modal__title]: isMobileAsianView }),
          content: classNames({
            [styles.modal__content__landscape]: isLandscapeView,
            [styles.modal__content]: isMobileAsianView,
            [styles.modal__content__desktop]: !isMobileAsianView
          })
        }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: t('asianView.labels.betslip.betMaxPopup')
          }}
        />
      </Modal>
    </>
  );
};

export default BetMax;
