import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import ArrowScroll from 'components/ArrowScroll';
import CashOutButton from 'components/CashOutButton';
import Loader, { CircleColors } from 'components/Loader';
import MobileInfoRow from 'components/MarketInfoRow/components/MobileInfoRow';
import MarketSelectionsRow from 'components/MarketSport/components/MarketSelectionsRow';
import StatusOverlay from 'components/MarketsTable/components/MarketsTableRow/components/StatusOverlay';
import Modal from 'components/Modal';
import TimeForm from 'components/TimeForm';
import { TODAY_CARD_PREFIX, tooltipStatus } from 'constants/app';
import { RACE_CARDS_COLLAPSED_TIME_FORMS } from 'constants/raceCard';
import { CELLS_ON_MARKET_PAGE, TOOLTIP_COMPONENT } from 'constants/tooltip';
import { TooltipProvider } from 'contexts/tooltipContext';
import useLongDateFormat from 'hooks/useLongDateFormat';
import useMarketDepth from 'hooks/useMarketDepth';
import useMarketStatusAndLockIcon from 'hooks/useMarketStatusAndLockIcon';
import useTooltip from 'hooks/useTooltip';
import { RACE_SEARCH_PARAM } from 'pages/mobile/MobileTodayCardPage';
import { getTimeformStatisticsEnabled } from 'redux/modules/appConfigs/selectors';
import { getIsAllCurrentBetsCancelling } from 'redux/modules/currentBets/selectors';
import {
  fetchMarket,
  fetchMarketRules,
  setMarketLines,
  setMarketLinesText,
  setMarketReset,
  setMarketShowAll
} from 'redux/modules/market';
import {
  getMarketLines,
  getMarketLinesText,
  getMarketReset,
  getMarketShowAll,
  getSingleMarketInfo,
  getSingleMarketRules,
  getSingleMarketRulesLoading
} from 'redux/modules/market/selectors';
import { getMarketPricesIsBettingEnabled, getStatusByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { MarketStatus, PageBlocks, PlacementPage, SportId } from 'types';
import { EMarketDepthView } from 'types/markets';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

const RaceContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sportId = '', marketId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const curMarketId = marketId.toString().replace(TODAY_CARD_PREFIX, '');

  const timeformStatisticsEnabled = useSelector(getTimeformStatisticsEnabled);
  const market = useSelector(getSingleMarketInfo);
  const bettingEnabled = useSelector(getMarketPricesIsBettingEnabled(curMarketId));
  const marketStatus = useSelector(getStatusByMarketPricesId(curMarketId));
  const rules = useSelector(getSingleMarketRules);
  const rulesLoading = useSelector(getSingleMarketRulesLoading);
  const isCancelActionsInProgress = useSelector(getIsAllCurrentBetsCancelling(marketId));
  const lines = useSelector(getMarketLines);
  const showAll = useSelector(getMarketShowAll);
  const linesText = useSelector(getMarketLinesText);
  const reset = useSelector(getMarketReset);

  const { translationKey, isEnabled } = useTooltip(CELLS_ON_MARKET_PAGE);
  const { isDepthEnabled, isLandscape } = useMarketDepth(EMarketDepthView.SINGLE_MARKET);
  const { status, showClosedStatus, showStatus } = useMarketStatusAndLockIcon(bettingEnabled, marketStatus);
  const marketTime = useLongDateFormat({
    time: market?.marketStartTime ?? 0,
    hasYear: true,
    divider: ', '
  });

  const [isRulesModalOpened, setIsRulesModalOpened] = useState(false);

  const selectedRaceId = searchParams.get(RACE_SEARCH_PARAM);
  const isMarketClosed = marketStatus === MarketStatus.CLOSED;
  const isTimeFormEnabled = timeformStatisticsEnabled && !!market?.timeformStatistics && !isMarketClosed;
  const collapsedTimeFormsString = localStorage.getItem(RACE_CARDS_COLLAPSED_TIME_FORMS);
  const collapsedTimeForms: string[] = useMemo(
    () => (collapsedTimeFormsString ? JSON.parse(collapsedTimeFormsString) : []),
    [collapsedTimeFormsString]
  );
  const isTimeFormCollapsed = useMemo(() => {
    return !!collapsedTimeForms.find(item => item === `${selectedRaceId}/${marketId}`);
  }, [collapsedTimeForms, selectedRaceId, marketId]);

  const handleFetchMarket = () => {
    if (market) {
      dispatch(fetchMarket({ marketId: market.marketId }));
    }
  };

  const handleFetchMarketRules = () => {
    if (market) {
      dispatch(fetchMarketRules(market.marketId));

      setIsRulesModalOpened(true);
    }
  };

  const handleSetGroupByIndex = (
    index: number,
    step: number,
    defaultLine: number,
    totalLines: number,
    newLinesText: string
  ) => {
    dispatch(
      setMarketLines({
        from: index,
        to: index + step,
        currentLine: index,
        defaultLine,
        totalLines
      })
    );
    dispatch(setMarketReset(false));
    dispatch(setMarketLinesText(newLinesText));
  };

  const handleShowAllRunners = useCallback(() => {
    dispatch(setMarketShowAll(true));
  }, []);

  const handleMinimize = useCallback(() => {
    dispatch(setMarketShowAll(false));
  }, []);

  const handleResetView = useCallback(() => {
    if (!showAll) {
      dispatch(setMarketReset(true));
    }
  }, [showAll]);

  const handleToggleExpandTimeForm = (value: boolean) => {
    if (value) {
      const result = collapsedTimeForms.filter(raceId => `${raceId}/${marketId}` !== `${selectedRaceId}/${marketId}`);
      localStorage.setItem(RACE_CARDS_COLLAPSED_TIME_FORMS, JSON.stringify(result));
    } else {
      const result = [...collapsedTimeForms, `${selectedRaceId}/${marketId}`];
      localStorage.setItem(RACE_CARDS_COLLAPSED_TIME_FORMS, JSON.stringify(result));
    }
  };

  if (!market) {
    return null;
  }

  return (
    <div className={styles.raceContent}>
      <div className="biab_market-info">
        {!isMarketClosed && (
          <MobileInfoRow
            marketName={market.marketName}
            sportId={market.eventType.id}
            marketId={market.marketId}
            isCashOutEnabled={market.cashOutEnabled}
            onOpenRules={handleFetchMarketRules}
            onRefreshMarket={handleFetchMarket}
            marketType={market.description.marketType}
          />
        )}
      </div>
      {market.cashOutEnabled && !tooltipStatus[status || ''] && (
        <CashOutButton marketId={market.marketId} partialCashOutEnabled={market.partialCashOutEnabled} />
      )}
      <MarketSelectionsRow
        market={market}
        showDeepPrices
        isDepthEnabled={isDepthEnabled}
        lines={lines}
        linesText={linesText}
        showAll={showAll}
        onShowAllRunners={handleShowAllRunners}
        onResetView={handleResetView}
        onMinimize={handleMinimize}
        page={PlacementPage.MARKET}
      />
      <div
        className={classNames('runnersStatusWrapper', styles.raceContent__runnersStatusWrapper, {
          [styles.raceContent__runnersStatusWrapper__greyhoundRacing]: sportId === SportId.TODAY_GRAY_HOUND_RACING
        })}
      >
        {isCancelActionsInProgress && (
          <div className="biab_market-loading">
            <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
          </div>
        )}
        <div className={classNames('runnersWrapper', styles.raceContent__runnersWrapper)}>
          <TooltipProvider value={{ isEnabled, translationKey, component: TOOLTIP_COMPONENT.MARKET_IN_PLAY }}>
            <ArrowScroll
              market={market}
              showAll={showAll}
              reset={reset}
              onSetGroupByIndex={handleSetGroupByIndex}
              pageBlock={PageBlocks.MARKET_ODDS}
              showDeepPrices
              isLandscape={isLandscape}
              isDepthEnabled={isDepthEnabled}
              classes={{ scrollWrapper: styles.raceContent__arrowScroll__scrollWrapper, runnerRow: styles.runnerRow }}
              racingGameTitleClasses={{
                container: isLandscape ? undefined : styles.raceContent__gameTitleContainer,
                gameImage: styles.raceContent__gameImage,
                gameTitle: styles.raceContent__gameTitle
              }}
              page={PlacementPage.MARKET}
              mobilePlacementContainerClassName={classNames(styles.mobilePlacement, {
                [styles.mobilePlacement__greyhound]: sportId.includes('4339')
              })}
            />
          </TooltipProvider>
        </div>
        {!market.closedDate && (showClosedStatus || showStatus) && <StatusOverlay status={status} inMiddle />}
      </div>
      {isTimeFormEnabled && (
        <TimeForm
          market={market}
          headerClasses={{ collapsed: styles.raceContent__timeForm }}
          onToggleExpand={handleToggleExpandTimeForm}
          defaultIsExpanded={!isTimeFormCollapsed}
        />
      )}
      <Modal
        clickableBackground
        open={isRulesModalOpened}
        onClose={() => setIsRulesModalOpened(false)}
        title={
          rulesLoading
            ? t('marketRules.loading')
            : `${rules?.[market.marketId]?.marketName || ''} - ${t('rules.title')}`
        }
      >
        {rulesLoading ? (
          <Loader circleColor={CircleColors.BLACK} />
        ) : (
          <div className={styles.marketDetailInfo__marketModal}>
            <div>
              {t('marketRules.eventStartTime')}:
              <br />
              {marketTime}
            </div>
            <div
              className={styles.marketDetailInfo__modalText}
              dangerouslySetInnerHTML={{
                __html: unescape(
                  rules?.[market.marketId]?.rules.replace(/CONTEXT_ROOT_PATH/g, getEnvironmentRootPath()) ?? ''
                )
              }}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default RaceContent;
