import { useSelector } from 'react-redux';
import classNames from 'classnames';

import AsianViewBackLayLabels from 'components/AsianViewPageModule/components/Event/MarketCell/AsianViewBackLayLabels';
import { MOBILE_MAX_DISPLAY_SIZE } from 'constants/app';
import {
  AV_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT_2,
  AV_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT_3,
  AV_MOBILE_MARKET_SELECTIONS_COLUMNS_COUNT,
  AV_OUTRIGHT_DESKTOP_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT,
  MIN_WIDTH_RUNNER,
  TAB_COLUMN_COUNTER
} from 'constants/asianView';
import { asianViewMiddleSection as branding } from 'constants/branding';
import useWindowSize from 'hooks/useWindowSize';
import { getIsLandscapeAsianView, getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { generateArray } from 'utils/general';

import styles from './styles.module.scss';

interface BackLayHeaderProps {
  runnerLength: number;
  width: number;
  isOutRight?: boolean;
  isTab?: boolean;
  marketType?: string;
}

const BackLayHeader = ({ runnerLength, width, isOutRight, isTab, marketType }: BackLayHeaderProps) => {
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);

  const { windowWidth } = useWindowSize(100);

  const maxItemInLine = TAB_COLUMN_COUNTER[marketType || ''] || Math.floor(width / MIN_WIDTH_RUNNER);
  let countColumn;

  if (!isOutRight && isLandscapeView) {
    if (windowWidth > MOBILE_MAX_DISPLAY_SIZE) {
      countColumn = AV_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT_3;
    } else {
      countColumn = AV_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT_2;
    }
  } else if (isOutRight) {
    countColumn =
      isMobileAsianView && !isLandscapeView
        ? AV_MOBILE_MARKET_SELECTIONS_COLUMNS_COUNT
        : AV_OUTRIGHT_DESKTOP_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT;
  } else if (isTab && isMobileAsianView && !isLandscapeView) {
    countColumn = AV_MOBILE_MARKET_SELECTIONS_COLUMNS_COUNT;
  } else {
    countColumn = maxItemInLine > runnerLength ? runnerLength : maxItemInLine;
  }

  const columns = generateArray(countColumn);
  const numberOfColumns = columns.length;

  return (
    <div
      style={{ gridTemplateColumns: `repeat(${countColumn}, 1fr)` }}
      className={classNames(styles.container, {
        [branding.MARKET_BORDER_BG]: isMobileAsianView && !isTab,
        [branding.COUPON_CONTAINER]: isMobileAsianView
      })}
    >
      {columns.map((value, index) => (
        <div
          className={classNames(styles.itemContainer, branding.MARKET_BODY, branding.MARKET_BORDER, {
            [styles.itemContainer__hasBorder]: numberOfColumns !== index + 1,
            [styles.itemContainer__mobile]: isMobileAsianView,
            [branding.MARKET_BORDER_BG]: isMobileAsianView
          })}
          key={value}
        >
          <AsianViewBackLayLabels containerClassName={styles.backLayLabels} />
        </div>
      ))}
    </div>
  );
};

export default BackLayHeader;
