import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import CashOutCard from 'components/CashOutCard';
import CashOutMarketRulesModal from 'components/CashoutMarketRulesModal';
import MiddleSectionPageSkeleton from 'components/Skeleton/MiddleSectionPageSkeleton';
import useAddHeightForFooter from 'hooks/useAddHeightForFooter';
import Pagination from 'pages/CashOutPages/partials/Pagination';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { fetchCashOutMarketRules, resetCashOut } from 'redux/modules/cashOut';
import {
  getCashOutContent,
  getCashOutMarketRules,
  getCashOutMarketRulesLoading,
  getCashOutTotalPages,
  getIsCashOutQuoteFirstLoaded,
  getMarketCashOutById,
  getPaginationLoader
} from 'redux/modules/cashOut/selectors';
import { IMarket } from 'types/markets';

import CashOutPagesIntervalRequestsInjection from './partials/CashOutPagesIntervalRequestsInjection/CashOutPagesIntervalRequestsInjection';

import styles from './styles.module.scss';

const CashOutPages = () => {
  const dispatch = useDispatch();

  const device = useSelector(getAppDevice);
  const paginationLoader = useSelector(getPaginationLoader);
  const isFirstLoaded = useSelector(getIsCashOutQuoteFirstLoaded);

  const isMobile = device === Devices.MOBILE;
  const isShowSkeleton = !isFirstLoaded || paginationLoader;

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
      dispatch(resetCashOut());
    };
  }, []);

  return (
    <div
      className={classNames('skeleton_page_wrapper', {
        [styles.showSkeleton]: !isMobile && isShowSkeleton
      })}
    >
      <CashOutPagesIntervalRequestsInjection />
      {isShowSkeleton ? (
        <MiddleSectionPageSkeleton itemsCount={12} withDelay={150} isAbsolute={isMobile} />
      ) : (
        <CashOutPageContent isMobile={isMobile} />
      )}
    </div>
  );
};

export default CashOutPages;

function CashOutPageContent({ isMobile }: { isMobile: boolean }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const content = useSelector(getCashOutContent);
  const totalPages = useSelector(getCashOutTotalPages);
  const cashOutHeaderHeight = useSelector(getElementHeightByName(EElementNames.CASHOUT_HEADER_HEIGHT));
  const marketsRules = useSelector(getCashOutMarketRules);
  const marketsRulesLoading = useSelector(getCashOutMarketRulesLoading);

  const [rulesMarketId, setRulesMarketId] = useState('');
  const [openRulesModal, setOpenRulesModal] = useState(false);

  const marketCashOutById = useSelector(getMarketCashOutById(rulesMarketId));

  const { target, targetHeight, addedHeight } = useAddHeightForFooter();

  const columns = useMemo(() => {
    if (content.length) {
      const isTwoColumns = !isMobile && content.length > 2;
      const firstColumn: IMarket[] = [];
      const secondColumn: IMarket[] = [];

      content.forEach((item, index) => {
        if (isTwoColumns) {
          if (index % 2 === 0) {
            firstColumn.push(item);
          } else {
            secondColumn.push(item);
          }
        } else {
          firstColumn.push(item);
        }
      });
      return [firstColumn, secondColumn];
    }
  }, [content, isMobile]);

  const openMarketRulesModal = (marketId: string) => {
    if (!marketsRules?.[marketId]) {
      dispatch(fetchCashOutMarketRules(marketId));
    }

    setRulesMarketId(marketId);
    setOpenRulesModal(true);
  };

  useEffect(() => {
    dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: targetHeight + cashOutHeaderHeight }));
  }, [targetHeight, cashOutHeaderHeight]);

  return (
    <>
      <div ref={target} className="biab_cash-out-page-wrapper">
        {!content.length ? (
          <div className={classNames(styles.empty, 'biab_cash-out-page-empty')}>{t('cashout.page.labels.empty')}</div>
        ) : (
          <div className={styles.listsMain}>
            {columns?.map((markets, columnIndex) => {
              if (!markets.length) {
                return null;
              }

              return (
                <div key={columnIndex} className={classNames('biab_cash-out-page-market', styles.cashOutList)}>
                  {markets.map((market, index) => (
                    <CashOutCard
                      key={`${market.marketId}-${index}`}
                      market={market}
                      isMobile={isMobile}
                      openMarketRulesModal={openMarketRulesModal}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        )}
        {totalPages > 1 && <Pagination itemsCount={totalPages} />}
      </div>
      {addedHeight > 0 && <div style={{ height: addedHeight }} />}
      {openRulesModal && (
        <CashOutMarketRulesModal
          market={marketCashOutById}
          openModal={openRulesModal}
          setOpenModal={setOpenRulesModal}
          marketsRules={marketsRules}
          marketsRulesLoading={marketsRulesLoading}
        />
      )}
    </>
  );
}
