import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import useElementSize from 'hooks/useElementSize';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';

import styles from '../styles.module.scss';

export const COLLAPSE_NAVIGATION_COOKIE_KEY = 'COLLAPSE_NAVIGATION';

const HideMenuButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [, setCookie] = useCookies([COLLAPSE_NAVIGATION_COOKIE_KEY]);

  const handleHideMenuClick = () => {
    setCookie(COLLAPSE_NAVIGATION_COOKIE_KEY, true);
  };

  const [componentRef] = useElementSize<HTMLButtonElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.NAVIGATION_HIDE_NAVBAR_BUTTON, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.NAVIGATION_HIDE_NAVBAR_BUTTON, height: 0 }));
    };
  }, []);

  return (
    <button
      ref={componentRef}
      onClick={handleHideMenuClick}
      className={classNames('biab_toggle-nav biab_max-nav-item', styles.hideMenuMain)}
    >
      {t('navigation.hideMenu')} <i className="fas fa2 fa2-arrow-left biab_expand" />
    </button>
  );
};

export default HideMenuButton;
