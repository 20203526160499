import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { TFailureActionPayload } from 'types';

import { TAsianCurrentBetsState } from './type';

const initialState: TAsianCurrentBetsState = {
  offers: {},
  loading: false,
  areCurrentBetsLoaded: false,
  stringifiedCurrentBets: '',
  currentBets: []
};

const slice = createSlice({
  name: SLICES_NAMES.ASIAN_CURRENT_BETS,
  initialState,
  reducers: {
    fetchAsianCurrentBets: state => {
      state.loading = true;
    },
    successGetAsianCurrentBets: (
      state,
      { payload }: PayloadAction<{ bets: TCurrentBet[]; isWebSocketResponse: boolean }>
    ) => {
      state.loading = false;

      if (payload.isWebSocketResponse) {
        payload.bets.forEach(offer => {
          state.offers[offer.offerId] = offer;
        });
        state.currentBets = payload.bets;
      } else {
        const stringifiedBets = JSON.stringify(payload.bets);

        if (state.stringifiedCurrentBets !== stringifiedBets) {
          payload.bets.forEach(offer => {
            state.offers[offer.offerId] = offer;
          });
          state.currentBets = payload.bets;
          state.stringifiedCurrentBets = stringifiedBets;
        }
      }

      if (!state.areCurrentBetsLoaded) {
        state.areCurrentBetsLoaded = true;
      }
    },
    failureGetAsianCurrentBets: (state, _: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;

      if (!state.areCurrentBetsLoaded) {
        state.areCurrentBetsLoaded = true;
      }
    },
    resetAsianCurrentBets: () => initialState
  }
});

export const { failureGetAsianCurrentBets, successGetAsianCurrentBets, fetchAsianCurrentBets, resetAsianCurrentBets } =
  slice.actions;

export default slice.reducer;
