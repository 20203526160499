interface ImageProps {
  /**
   * Name of image which should be loaded
   */
  name: string;

  /**
   * Image className
   */
  className?: string;

  /**
   * Width of image which should be loaded
   */
  width?: string | number;

  /**
   * Height of image which should be loaded
   */
  height?: string | number;

  /**
   * Height of image which should be loaded
   */
  path: string;
}

const Image = ({ name, width, height, className, path, ...props }: ImageProps) => {
  let loadedImage;
  try {
    loadedImage = require(`assets/icons/${path}`);
  } catch (e) {
    console.error(e);
  }

  return <img src={loadedImage} className={className} width={width} height={height} alt={name} {...props} />;
};

export default Image;
