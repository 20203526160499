import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ResponsibleBettingAppliedField from 'components/ResponsibleBetting/components/ResponsibleBettingAppliedField';
import ResponsibleBettingDropdown from 'components/ResponsibleBetting/components/ResponsibleBettingDropdown';
import ResponsibleBettingInputField from 'components/ResponsibleBetting/components/ResponsibleBettingInputField';
import ResponsibleBettingMobileDropdown from 'components/ResponsibleBetting/components/ResponsibleBettingMobileDropdown';
import commonStyles from 'components/ResponsibleBetting/styles.module.scss';
import { CombinedResponsibleBettingTimeUnit } from 'redux/modules/responsibleBetting/type';
import { DropdownItem } from 'types';
import { ResponsibleBettingAlertTypes, ResponsibleBettingButtonTypes } from 'types/responsibleBetting';
import { getTimeUnitKey } from 'utils/responsibleBetting';

import ResponsibleBettingAlert from '../ResponsibleBettingAlert';
import ResponsibleBettingBtn from '../ResponsibleBettingBtn';
import ResponsibleBettingCompareLimitField from '../ResponsibleBettingCompareLimitField';

interface ResponsibleBettingUpdateLimitProps {
  isMobile: boolean;
  isApplyingMode: boolean;
  error: string | null;
  currentTimeUnit: CombinedResponsibleBettingTimeUnit;
  currentLimit: number | null;
  newLimit: number | null;
  newLimitTimeUnit: CombinedResponsibleBettingTimeUnit;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  onApply: () => void;
  onSelectOption: (option: DropdownItem) => void;
  confirmationMessage: string | undefined;
  inputLabel: string;
  newLimitAppliedFieldValue: string;
  currentLimitComparedFieldLabel: string;
  newLimitComparedFieldLabel: string;
  data: DropdownItem[];
}

const ResponsibleBettingUpdateLimit = ({
  isMobile,
  isApplyingMode,
  error,
  currentLimit,
  currentTimeUnit,
  newLimit,
  newLimitTimeUnit,
  onChange,
  onCancel,
  onApply,
  onSelectOption,
  confirmationMessage,
  inputLabel,
  newLimitAppliedFieldValue,
  currentLimitComparedFieldLabel,
  newLimitComparedFieldLabel,
  data
}: ResponsibleBettingUpdateLimitProps) => {
  const { t } = useTranslation();

  const limitPeriodLabel = t('responsibleBetting.labels.limitPeriod');
  const applyBtnLabel = isApplyingMode ? 'responsibleBetting.buttons.apply' : 'responsibleBetting.buttons.continue';
  const cancelBtnLabel = isApplyingMode ? 'responsibleBetting.buttons.back' : 'responsibleBetting.buttons.cancel';

  const disableApplyBtn =
    !newLimit || !newLimitTimeUnit || (newLimit === currentLimit && newLimitTimeUnit === currentTimeUnit);

  return (
    <div
      className={classNames(commonStyles.content, {
        [commonStyles.content__mobile]: isMobile
      })}
    >
      {isApplyingMode && currentTimeUnit && currentLimit ? (
        <div
          className={classNames(commonStyles.appliedInputs, {
            [commonStyles.appliedInputs__mobile]: isMobile
          })}
        >
          <ResponsibleBettingAppliedField value={newLimitAppliedFieldValue} label={inputLabel} isMobile={isMobile} />
          <ResponsibleBettingAppliedField
            value={t(getTimeUnitKey(newLimitTimeUnit))}
            label={limitPeriodLabel}
            isMobile={isMobile}
          />
        </div>
      ) : (
        <div
          className={classNames(commonStyles.inputs, {
            [commonStyles.inputs__with_error]: !!error,
            [commonStyles.inputs__mobile]: isMobile
          })}
        >
          <ResponsibleBettingInputField
            value={newLimit}
            label={inputLabel}
            placeholder={t('responsibleBetting.placeholders.enterAmount')}
            onChange={onChange}
            isMobile={isMobile}
            error={!!error}
          />
          {isMobile && error && (
            <div
              className={classNames(commonStyles.validationAlertWrapper, {
                [commonStyles.validationAlertWrapper__mobile]: isMobile
              })}
            >
              <ResponsibleBettingAlert type={ResponsibleBettingAlertTypes.Error}>
                <span dangerouslySetInnerHTML={{ __html: unescape(error) }} />
              </ResponsibleBettingAlert>
            </div>
          )}
          {isMobile ? (
            <ResponsibleBettingMobileDropdown
              data={data}
              onSelectOption={onSelectOption}
              selectedValue={newLimitTimeUnit}
              label={limitPeriodLabel}
            />
          ) : (
            <div className={commonStyles.container}>
              <label className={commonStyles.label}>{limitPeriodLabel}</label>
              <ResponsibleBettingDropdown onChangeOption={onSelectOption} data={data} value={newLimitTimeUnit} />
            </div>
          )}
        </div>
      )}
      {!isMobile && error && (
        <ResponsibleBettingAlert type={ResponsibleBettingAlertTypes.Error}>
          <span dangerouslySetInnerHTML={{ __html: unescape(error) }} />
        </ResponsibleBettingAlert>
      )}
      <div className={commonStyles.divider} />
      <div
        className={classNames(commonStyles.sectionContainer, {
          [commonStyles.sectionContainer__mobile]: isMobile
        })}
      >
        <ResponsibleBettingCompareLimitField
          title={t('responsibleBetting.labels.currentLimit')}
          value={currentLimitComparedFieldLabel}
          isMobile={isMobile}
        />
        <i
          className={classNames('fa2', 'fa2-caret-icon', 'biab_fav-manage-icon', commonStyles.sectionIcon, {
            [commonStyles.sectionIcon__mobile]: isMobile
          })}
        />
        <ResponsibleBettingCompareLimitField
          title={t('responsibleBetting.labels.newLimit')}
          value={newLimitComparedFieldLabel}
          isMobile={isMobile}
        />
      </div>
      {confirmationMessage && isApplyingMode && (
        <div className={commonStyles.sectionUpdateMessageAlert}>
          <ResponsibleBettingAlert type={ResponsibleBettingAlertTypes.Info} description={confirmationMessage} />
        </div>
      )}
      <div
        className={classNames(commonStyles.sectionContainer, {
          [commonStyles.sectionContainer__mobile_reverse]: isMobile,
          [commonStyles.sectionContainer__no_margin]: !isMobile
        })}
      >
        {isApplyingMode && (
          <span
            className={classNames(commonStyles.sectionLabel, {
              [commonStyles.sectionLabel__mobile]: isMobile
            })}
          >
            {t('responsibleBetting.messages.confirmDecision')}
          </span>
        )}
        <div
          className={classNames(commonStyles.sectionControllers, {
            [commonStyles.sectionControllers__mobile]: isMobile,
            [commonStyles.sectionControllers__applied]: isApplyingMode
          })}
        >
          <ResponsibleBettingBtn
            label={t(cancelBtnLabel)}
            onClick={onCancel}
            isMobile={isMobile}
            type={ResponsibleBettingButtonTypes.Secondary}
          />
          <ResponsibleBettingBtn
            label={t(applyBtnLabel)}
            onClick={onApply}
            isMobile={isMobile}
            disabled={disableApplyBtn || !!error}
            type={isApplyingMode ? ResponsibleBettingButtonTypes.Applied : ResponsibleBettingButtonTypes.Primary}
          />
        </div>
      </div>
    </div>
  );
};

export default ResponsibleBettingUpdateLimit;
