import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loader, { CircleColors } from 'components/Loader';
import Modal from 'components/Modal';
import Rules from 'components/Rules';
import { IMarketRules } from 'types/markets';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

interface MarketCollapseRowRulesProps {
  /**
   * Market ID
   */
  marketId: string;
  /**
   * Market sport id
   */
  sportId: string;
  /**
   * Method for requesting rules for a particular market
   */
  onFetchMarketRules?: (marketId: string, sportId: string) => void;
  /**
   * Markets rules collection
   */
  marketsRules?: Record<string, IMarketRules> | null;
  /**
   * Determines if rules are loaded or not
   */
  rulesLoading?: boolean;
  /**
   * Market start time
   */
  marketTime: string;
  /**
   * Market name
   */
  marketName: string;
}

const MarketCollapseRowRules = ({
  marketId,
  sportId,
  onFetchMarketRules,
  marketsRules,
  rulesLoading,
  marketTime,
  marketName
}: MarketCollapseRowRulesProps) => {
  const { t } = useTranslation();

  const [openRulesModal, setOpenRulesModal] = useState<boolean>(false);

  const handleCloseRulesModal = (event?: SyntheticEvent) => {
    event?.stopPropagation();
    setOpenRulesModal(false);
  };

  const handleOpenRulesModal = (event: SyntheticEvent) => {
    event.stopPropagation();

    if (onFetchMarketRules) {
      onFetchMarketRules(marketId, sportId);
    }

    setOpenRulesModal(true);
  };

  return (
    <>
      <span onClick={handleOpenRulesModal}>
        <Rules isHideText />
      </span>
      <Modal
        clickableBackground
        open={openRulesModal}
        onClose={handleCloseRulesModal}
        title={rulesLoading ? t('marketRules.loading') : `${marketName || ''} - ${t('rules.title')}`}
      >
        {rulesLoading ? (
          <Loader circleColor={CircleColors.BLACK} />
        ) : (
          <div className={styles.rules__modal}>
            <div>
              {t('marketRules.eventStartTime')}:
              <br />
              {marketTime}
            </div>
            <div
              className={styles.rules__modal__text}
              dangerouslySetInnerHTML={{
                __html: marketsRules?.[marketId]?.rules.replace(/CONTEXT_ROOT_PATH/g, getEnvironmentRootPath()) ?? ''
              }}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default MarketCollapseRowRules;
