import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import { bettingHistoryPagesDropdown as dropdownBranding } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import { setIsCustomPeriod } from 'redux/modules/myBets';
import { getIsCustomPeriod } from 'redux/modules/myBets/selectors';
import { DropdownItem, Periods, TPeriods } from 'types';
import { convertPeriodsToDropdown } from 'utils';
import { getPeriod } from 'utils/date';

import styles from './styles.module.scss';

interface PeriodDropdownProps {
  startDate: Date;
  endDate: Date;
  onChange: (period: TPeriods) => void;
  dayShift: number;
}

const PeriodDropdown = ({ startDate, endDate, onChange = () => {}, dayShift = 0 }: PeriodDropdownProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const convertedData = convertPeriodsToDropdown(t);
  const [currentPeriod, setCurrentPeriod] = useState<TPeriods | undefined>();
  const { isMobile } = useDevice();
  const isCustomPeriod = useSelector(getIsCustomPeriod);

  const currentTimeFormat =
    isCustomPeriod && currentPeriod === Periods.CUSTOM
      ? currentPeriod
      : getPeriod(startDate, endDate, currentPeriod, dayShift) || Periods.CUSTOM;

  const selectedItem = convertedData.find(item => item.value === `${currentTimeFormat}`);

  const onSelectOption = (period: DropdownItem) => {
    const isCustom = period.value === Periods.CUSTOM;

    setCurrentPeriod(period.value as TPeriods);

    dispatch(setIsCustomPeriod(isCustom));

    if (!isCustom) {
      onChange(period.value as TPeriods);
    }
  };

  const classes = {
    dropdown: classNames(styles.dropdown, { [styles.dropdown__mobile]: isMobile }),
    placeholder: styles.placeholder,
    activePlaceholder: styles.activePlaceholder,
    option: styles.option,
    selectedOption: styles.selectedOption
  };

  return (
    <div
      className={classNames('biab_period-dropdown', styles.period, {
        [styles.period__desktop]: !isMobile,
        [styles.period__mobile]: isMobile
      })}
    >
      {!isMobile && <span className={styles.label}>{t('account.labels.period')}</span>}
      <Dropdown
        classes={classes}
        brandingClasses={dropdownBranding}
        data={convertedData}
        defaultValue={selectedItem}
        onSelectOption={onSelectOption}
      />
    </div>
  );
};

export default memo(PeriodDropdown);
