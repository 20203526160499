import UpdatedMobileAccountBackBtn from './components/UpdatedMobileAccountBackBtn';

import styles from './styles.module.scss';

interface UpdatedMobileAccountNavigationProps {
  title: string;
}

const UpdatedMobileAccountNavigation = ({ title }: UpdatedMobileAccountNavigationProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.backBtnContainer}>
        <UpdatedMobileAccountBackBtn />
      </div>
      <h4 className={styles.title}>{title}</h4>
    </div>
  );
};

export default UpdatedMobileAccountNavigation;
