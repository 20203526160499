import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import { TPLContentItem } from 'redux/modules/myBets/type';
import { BettingType } from 'types/markets';

import styles from './styles.module.scss';

interface MobileDescriptionProps {
  item: TPLContentItem;
}
const MobileDescription = ({ item }: MobileDescriptionProps) => {
  const { t } = useTranslation();

  const { date: parsedSettledDate } = useParseMyBetsDates({
    dateToParse: item.settledDate,
    yearHyphen: true
  });
  const { date: startDate } = useParseMyBetsDates({
    dateToParse: item.startTime,
    yearHyphen: true
  });

  return (
    <div className={styles.plMobileCell}>
      <span className={styles.eventName}>{item.eventName}:</span>
      <table>
        <tbody>
          <tr>
            <td>
              <span className={classNames(styles.marketName, 'biab_market-name')}>
                {item.betType === BettingType.GAME ? `${t('games.gameId')}: ${item.marketId}` : item.marketName}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <span className={styles.startTime}>
        <span className={styles.timeLabel}>{t('account.profitandloss.labels.start')}&nbsp;:</span>
        {startDate}
      </span>
      <span className={styles.settledTime}>
        <span className={styles.timeLabel}>{t('account.profitandloss.labels.settled')}&nbsp;:</span>
        {parsedSettledDate}
      </span>
    </div>
  );
};

export default MobileDescription;
