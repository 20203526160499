import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import {
  failureFetchFavorites,
  fetchFavorites,
  saveRemoveFavorite,
  successFetchFavorites,
  successSaveRemoveFavorite
} from './index';
import { TFavorite } from './type';

function* getFavoriteWorker() {
  try {
    const response: TFavorite[] = yield call(api.favorites.getFavorites);
    yield put(successFetchFavorites(response));
  } catch (error: any) {
    yield put(failureFetchFavorites(error));
  }
}

function* saveRemoveFavoriteWorker(action: ReturnType<typeof saveRemoveFavorite>) {
  try {
    const response: TFavorite[] = yield call(api.favorites.saveRemoveFavorite, action.payload);
    yield put(successSaveRemoveFavorite(response));
  } catch (error: any) {
    yield put(failureFetchFavorites(error));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchFavorites.type, getFavoriteWorker),
    takeLatest(saveRemoveFavorite.type, saveRemoveFavoriteWorker)
  ]);
}
