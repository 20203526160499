import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import sectionStyles from 'components/AsianSportSection/styles.module.scss';
import { ASIAN_BETSLIP_TABS } from 'constants/asianView';
import useAsianCashOut from 'hooks/useAsianCashOut';
import useElementSize from 'hooks/useElementSize';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getAsianSelectedBetsAmount, getAsianViewBetslipSelectedTab } from 'redux/modules/asianViewBetslip/selectors';
import { getAsianViewCashOutsAmount } from 'redux/modules/asianViewCashOutCounter/selectors';
import { getAVMatchedCurrentBetsCount } from 'redux/modules/asianViewCurrentBets/selectors';
import { ASIAN_VIEW_BETSLIP_COLLAPSE, LEFT_PANEL_COLLAPSE_GROUP_ASIAN } from 'redux/modules/collapse/constants';
import { getOpenedStateByGroup } from 'redux/modules/collapse/selectors';
import { EAsianBetslipTabs } from 'types/asianView';

import Tab from '../Tab';

const AVBetSlipTabs = () => {
  const dispatch = useDispatch();

  const selectedTab = useSelector(getAsianViewBetslipSelectedTab);
  const openedState = useSelector(getOpenedStateByGroup(LEFT_PANEL_COLLAPSE_GROUP_ASIAN, ASIAN_VIEW_BETSLIP_COLLAPSE));
  const openBetsCount = useSelector(getAVMatchedCurrentBetsCount);
  const selectedBetsAmount = useSelector(getAsianSelectedBetsAmount);
  const cashOutCounter = useSelector(getAsianViewCashOutsAmount);

  const hasCashOut = useAsianCashOut();
  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.ASIAN_BET_SLIP_TABS, height }));
    }
  });

  const getCounterValue = (tab: EAsianBetslipTabs) => {
    if (tab === EAsianBetslipTabs.BET_LIST) {
      return { counter: openBetsCount };
    }
    if (tab === EAsianBetslipTabs.BET_SLIP) {
      return { counter: selectedBetsAmount };
    }
    if (tab === EAsianBetslipTabs.CASH_OUT) {
      return { counter: cashOutCounter };
    }
    return {};
  };

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.ASIAN_BET_SLIP_TABS, height: 0 }));
    };
  }, []);

  return (
    <div className={sectionStyles.tabs} ref={componentRef}>
      {ASIAN_BETSLIP_TABS.map(
        tab =>
          (tab.key !== EAsianBetslipTabs.CASH_OUT || hasCashOut) && (
            <Tab
              key={tab.key}
              tabKey={tab.key}
              selected={openedState && tab.key === selectedTab}
              opened={!!(openedState && selectedTab)}
              title={tab.title}
              {...getCounterValue(tab.key)}
            />
          )
      )}
    </div>
  );
};

export default AVBetSlipTabs;
