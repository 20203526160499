import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { BetTypes, defaultTimeRange } from 'constants/myBets';
import { FailureActionPayload } from 'types';
import { getTodayDate, getTodayStartDate } from 'utils/date';

import {
  ICancelBetPayload,
  IGetPLPayload,
  IGetPLTotalPayload,
  MyBets,
  SuccessAccountProduct,
  TAccountProductsPayload,
  TCancelBetResponse,
  TChargesResponse,
  TFetchStatementPayload,
  TGetBetPayload,
  TimeRange,
  TMyBetsContentItem,
  TMyBetsResponse,
  TPLResponse,
  TPLTotalResponse,
  TProfitGamesResponse,
  TProfitSportsResponse,
  TStatementContentItem,
  TStatementResponse
} from './type';

const initialState: MyBets = {
  data: {
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 1,
    content: []
  },
  statementData: {
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 1,
    content: []
  },
  PLData: {
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 1,
    content: []
  },
  isProfitLossFirstLoaded: false,
  isMyBetsFirstLoaded: false,
  isStatementFirstLoaded: false,
  PLTotal: 0,
  loadingPLTotal: false,
  PLTotalEvent: 0,
  loadingPLTotalEvent: false,
  isOnMyBetsPage: false,
  loading: false,
  cancelAllUnmatchedBets: false,
  cancelAllUnmatchedBetsActionId: null,
  showBetDetails: false,
  showStatementDetails: false,
  bet: undefined,
  statement: undefined,
  betUpdated: false,
  cancelledBet: undefined,
  startDate: getTodayStartDate(),
  endDate: getTodayDate(),
  timeRange: defaultTimeRange,
  pageSize: 10,
  pageNumber: 0,
  charges: [],
  sportsList: [],
  loadingSportsList: false,
  gamesList: [],
  loadingGamesList: false,
  multiCurrencyValue: null,
  loadingProducts: false,
  accountProducts: {
    allMyBets: null,
    allProfitAndLoss: null,
    allAccountStatement: null
  },
  isCustomPeriod: false,
  error: null
};

const slice = createSlice({
  name: SLICES_NAMES.MY_BETS,
  initialState,
  reducers: {
    setStartDate: (state, { payload }: PayloadAction<Date>) => {
      state.startDate = payload;
      state.pageNumber = 0;
    },
    setTimeRange: (state, { payload }: PayloadAction<TimeRange>) => {
      state.timeRange = payload;
      state.pageNumber = 0;
    },
    setEndDate: (state, { payload }: PayloadAction<Date>) => {
      state.endDate = payload;
      state.pageNumber = 0;
    },
    setPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.pageNumber = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
    resetPageData: state => {
      state.pageSize = 10;
      state.pageNumber = 0;
      state.startDate = getTodayStartDate();
      state.endDate = getTodayDate();
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    fetchMyBets: (state, { payload }: PayloadAction<TGetBetPayload>) => {
      state.loading = true;

      if (payload.betsType === BetTypes.Unmatched) {
        state.betUpdated = false;
      } else {
        state.cancelAllUnmatchedBets = false;
        state.cancelledBet = undefined;
      }
    },
    successFetchMyBets: (state, { payload }: PayloadAction<TMyBetsResponse>) => {
      state.loading = false;
      state.data = payload;

      if (!state.isMyBetsFirstLoaded) {
        state.isMyBetsFirstLoaded = true;
      }
    },
    fetchStatement: (state, _: PayloadAction<TFetchStatementPayload>) => {
      state.loading = true;
    },
    successStatement: (state, { payload }: PayloadAction<TStatementResponse>) => {
      state.loading = false;
      state.statementData = payload;

      if (!state.isStatementFirstLoaded) {
        state.isStatementFirstLoaded = true;
      }
    },
    fetchPL: (state, _: PayloadAction<IGetPLPayload>) => {
      state.loading = true;
    },
    successPL: (state, { payload }: PayloadAction<TPLResponse>) => {
      state.loading = false;
      state.PLData = payload;

      if (!state.isProfitLossFirstLoaded) {
        state.isProfitLossFirstLoaded = true;
      }
    },
    fetchPLTotal: (state, _: PayloadAction<IGetPLTotalPayload>) => {
      state.loadingPLTotal = true;
    },
    successPLTotal: (state, { payload }: PayloadAction<TPLTotalResponse>) => {
      state.loadingPLTotal = false;
      state.PLTotal = payload.totalProfit;
    },
    fetchPLTotalForEvent: (state, _: PayloadAction<IGetPLTotalPayload>) => {
      state.loadingPLTotalEvent = true;
    },
    resetPLTotalForEvent: state => {
      state.PLTotalEvent = 0;
    },
    successPLTotalForEvent: (state, { payload }: PayloadAction<TPLTotalResponse>) => {
      state.loadingPLTotalEvent = false;
      state.PLTotalEvent = payload.totalProfit;
    },
    fetchProfitSports: (state, _: PayloadAction<string | undefined>) => {
      state.loadingSportsList = true;
    },
    successProfitSports: (state, { payload }: PayloadAction<TProfitSportsResponse>) => {
      state.loadingSportsList = false;
      state.sportsList = payload;
    },
    fetchProfitGames: (state, _: PayloadAction<string | undefined>) => {
      state.loadingGamesList = true;
    },
    successProfitGames: (state, { payload }: PayloadAction<TProfitGamesResponse>) => {
      state.loadingSportsList = false;
      state.gamesList = payload;
    },
    showMobileBetDetails: (state, { payload }: PayloadAction<TMyBetsContentItem>) => {
      state.bet = payload;
      state.showBetDetails = true;
    },
    hideMobileBetDetails: state => {
      state.bet = undefined;
      state.showBetDetails = false;
    },
    showMobileStatementDetails: (state, { payload }: PayloadAction<TStatementContentItem>) => {
      state.statement = payload;
      state.showStatementDetails = true;
    },
    hideMobileStatementDetails: state => {
      state.statement = undefined;
      state.showStatementDetails = false;
    },
    setCancelAllUnmatchedBetsActionId: (state, { payload }: PayloadAction<number | null>) => {
      state.cancelAllUnmatchedBetsActionId = payload;
    },
    cancelBet: (state, { payload }: PayloadAction<ICancelBetPayload>) => {
      state.loading = true;
      state.cancelledBet = payload.bet;
    },
    successCancelBet: (state, _: PayloadAction<TCancelBetResponse>) => {
      state.betUpdated = true;
      state.loading = false;
    },
    fetchCharges: state => {
      state.loading = true;
    },
    successCharges: (state, { payload }: PayloadAction<TChargesResponse>) => {
      state.charges = payload;
      state.loading = false;
    },
    failureGetMyBetsData: (state, { payload }: PayloadAction<FailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
    },
    fetchAccountProducts: (state, _: PayloadAction<TAccountProductsPayload>) => {
      state.loadingProducts = true;
    },
    successAccountProducts: (state, { payload }: PayloadAction<SuccessAccountProduct>) => {
      Object.assign(state.accountProducts, payload);
      state.loadingProducts = false;
    },
    failureAccountProducts: (state, _: PayloadAction<FailureActionPayload>) => {
      state.loadingProducts = false;
    },
    resetAccountProducts: state => {
      state.accountProducts.allMyBets = null;
      state.accountProducts.allProfitAndLoss = null;
      state.accountProducts.allAccountStatement = null;
      state.loadingProducts = false;
    },
    setMultiCurrencyValue: (state, { payload }: PayloadAction<string>) => {
      state.multiCurrencyValue = payload;
    },
    setIsCustomPeriod: (state, { payload }: PayloadAction<boolean>) => {
      state.isCustomPeriod = payload;
    }
  }
});

export const {
  cancelBet,
  failureAccountProducts,
  failureGetMyBetsData,
  fetchAccountProducts,
  fetchCharges,
  fetchMyBets,
  fetchPL,
  fetchPLTotal,
  fetchPLTotalForEvent,
  fetchProfitGames,
  fetchProfitSports,
  fetchStatement,
  hideMobileBetDetails,
  hideMobileStatementDetails,
  resetAccountProducts,
  resetPageData,
  resetPLTotalForEvent,
  setCancelAllUnmatchedBetsActionId,
  setEndDate,
  setIsCustomPeriod,
  setMultiCurrencyValue,
  setPageNumber,
  setPageSize,
  setStartDate,
  setTimeRange,
  setLoading,
  showMobileBetDetails,
  showMobileStatementDetails,
  successAccountProducts,
  successCancelBet,
  successCharges,
  successFetchMyBets,
  successPL,
  successPLTotal,
  successPLTotalForEvent,
  successProfitGames,
  successProfitSports,
  successStatement
} = slice.actions;

export default slice.reducer;
