import { ReactNode } from 'react';
import classNames from 'classnames';

interface TableWrapperProps {
  classname?: string;
  children: ReactNode;
  tdClassname?: string;
}
const TableWrapper = ({ classname, children, tdClassname }: TableWrapperProps) => {
  return (
    <table className={classNames('biab_quick-bets-table', classname)}>
      <tbody>
        <tr>
          <td className={tdClassname}>{children}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default TableWrapper;
