import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { IS_VISITED_APP_SESSION_STORAGE_KEY } from 'constants/app';
import { AsianViewMarketLink, AsianViewTimeFilter } from 'constants/asianView';
import { ASIAN_BASE_URL, GAMES_BASE_URL, HOME_BASE_URL } from 'constants/locations';
import {
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getIsPropertiesLoaded
} from 'redux/modules/appConfigs/selectors';
import { SportId } from 'types';

const LandingPageRedirect = () => {
  const { pathname } = useLocation();

  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);

  const isVisited = sessionStorage.getItem(IS_VISITED_APP_SESSION_STORAGE_KEY);

  useEffect(() => {
    if (isVisited === null) {
      sessionStorage.setItem(IS_VISITED_APP_SESSION_STORAGE_KEY, 'true');
    }
  }, []);

  if (arePropertiesLoaded && isAsianViewEnabled && pathname === HOME_BASE_URL && isVisited === null) {
    return (
      <Navigate
        to={`${ASIAN_BASE_URL}/sport/${SportId.SOCCER}/timeFilter/${AsianViewTimeFilter.Today}/marketLink/${AsianViewMarketLink.HDP_AND_OU}`}
        replace
      />
    );
  }

  if (
    arePropertiesLoaded &&
    !isAsianViewEnabled &&
    !isExchangeSportsEnabled &&
    isExchangeGamesEnabled &&
    !pathname.includes(GAMES_BASE_URL)
  ) {
    return <Navigate to={GAMES_BASE_URL} replace />;
  }

  return null;
};

export default memo(LandingPageRedirect);
