import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import Collapse from 'components/Collapse';
import ListItem from 'components/SportsSection/partials/ListItem';
import AllSoccer from 'components/SportsSection/SportView/Partials/AllSoccer';
import FilterItem from 'components/SportsSection/SportView/Partials/FilterItem';
import styles from 'components/SportsSection/styles.module.scss';
import useTimeFiltersSortingAndTranslation from 'hooks/useTimeFiltersSortingAndTranslation';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { SPORT_INNER_COLLAPSE } from 'redux/modules/collapse/constants';
import { ETimeFilters, TNavigationResponse, TTopCountryItem } from 'redux/modules/competitions/type';
import { SportId } from 'types';

interface ICollapseSectionProps {
  data?: TNavigationResponse[] | TTopCountryItem[];
  filterData?: ETimeFilters[];
  isFilterList?: boolean;
  collapseName: string;
  isMore?: boolean;
}

const CollapseSection = ({
  data = [],
  collapseName,
  isMore = false,
  isFilterList = false,
  filterData = []
}: ICollapseSectionProps) => {
  const { sportId } = useParams();
  const { t } = useTranslation();

  const domainLanguage = useSelector(getTranslation);

  const modifiedFilters = useTimeFiltersSortingAndTranslation(filterData);

  return (
    <Collapse
      customClass={classNames(
        'biab_navigation-competition-category-name biab_countries biab_date-more biab_opened',
        styles.navigationCollapse,
        {
          'biab_time time': isFilterList
        }
      )}
      title={isFilterList ? t('customer.navigation.time') : data[0]?.translations[domainLanguage] || data[0].name}
      group={SPORT_INNER_COLLAPSE}
      collapse={`${collapseName}-${sportId}`}
      isMore={isMore}
    >
      {isFilterList ? (
        <div className="biab_navigation-competition-category-time">
          {modifiedFilters?.map(f => (
            <FilterItem collapseName={collapseName} key={f.value} filter={f} />
          ))}
        </div>
      ) : (
        <>
          {sportId === SportId.SOCCER && isMore && <AllSoccer />}
          {data.slice(1, data.length).map(d => (
            <ListItem collapseName={collapseName} key={d.id} item={d} domainLanguage={domainLanguage} />
          ))}
        </>
      )}
    </Collapse>
  );
};

export default CollapseSection;
