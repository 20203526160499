import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import {
  getMobileSettingsLineMarketsSwitchBackLayOnCricket,
  getMobileSettingsReplaceBackLayWithUnderOver
} from 'redux/modules/appConfigs/selectors';
import { TStatementContentItem } from 'redux/modules/myBets/type';
import { statementSideValue } from 'utils/myBetsValues';

import styles from './styles.module.scss';

interface MobileDescriptionProps {
  item: TStatementContentItem;
}

const MobileDescription = ({ item }: MobileDescriptionProps) => {
  const { t } = useTranslation();

  const lineMarketsSwitchBackLayOnCricket = useSelector(getMobileSettingsLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getMobileSettingsReplaceBackLayWithUnderOver);

  const { date } = useParseMyBetsDates({
    dateToParse: item.settledDate
  });

  const placementLabel = t(`account.mybets.labels.${item.inPlay ? 'live' : 'prePlay'}Placement`);

  return (
    <div className={classNames('biab_statement-mobile-headline', styles.description)}>
      {item.description}
      {item.eachWayDivisor !== null &&
        item.numberOfWinners !== null &&
        t('account.statement.each.way.terms', { odds: item.eachWayDivisor, places: item.numberOfWinners })}
      {item.triggeredByCashOut && ` | ${t('account.statement.labels.triggeredByCashOut')}`}
      <span>
        {item.side
          ? `, ${t(statementSideValue(item, lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver))}`
          : ''}
      </span>
      <br />
      {item.side && item.asian && <span>{placementLabel} | </span>}
      <span className="biab_settled">
        {t('account.statement.labels.settled')} : {date}
      </span>
    </div>
  );
};

export default MobileDescription;
