import { ChangeEvent, KeyboardEvent, ReactNode } from 'react';
import classNames from 'classnames';

import { componentsBranding } from 'constants/branding';

import styles from './styles.module.scss';

interface CheckboxProps {
  children: ReactNode;
  checked: boolean;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (ev: KeyboardEvent) => void;
  name?: string;
  id?: string;
  tabIndex?: number;
  className?: string;
  labelClassName?: string;
}

const Checkbox = ({
  children,
  checked,
  onChange,
  onKeyDown,
  name,
  id,
  tabIndex,
  className,
  labelClassName,
  ...containerProps
}: CheckboxProps) => {
  return (
    <label
      className={classNames(styles.checkboxField, className, componentsBranding.CHECKBOX_LABEL)}
      {...containerProps}
    >
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        onKeyDown={onKeyDown}
        tabIndex={tabIndex}
      />
      <span
        id={id}
        className={classNames(styles.checkboxField__checkbox, {
          'fa2 fa2-tick': checked,
          [componentsBranding.CHECKBOX]: checked
        })}
      />
      <span className={labelClassName}>{children}</span>
    </label>
  );
};

export default Checkbox;
