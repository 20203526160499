import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import MobileToolbar from 'components/MobileToolbar';
import {
  getAsianViewMobileNavigationMenu,
  getPositionOfAsianViewMobileNavigationMenu
} from 'redux/modules/appConfigs/selectors';
import { getIsLandscapeAsianView, getIsMobileAsianView } from 'redux/modules/asianView/selectors';

import styles from './styles.module.scss';

type AsianToolbarWrapperProps = {
  noLandscapeMargin?: boolean;
};

const AsianToolbarWrapper = ({ children, noLandscapeMargin = false }: PropsWithChildren<AsianToolbarWrapperProps>) => {
  const isMobile = useSelector(getIsMobileAsianView);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);

  const positionOfAsianViewMobileNavigationMenu = useSelector(getPositionOfAsianViewMobileNavigationMenu);
  const asianViewMobileNavigationMenu = useSelector(getAsianViewMobileNavigationMenu);

  const hasMenu =
    isMobile && asianViewMobileNavigationMenu && (isLandscapeView || positionOfAsianViewMobileNavigationMenu);

  return (
    <div className={classNames({ [styles.wrapper]: isMobile })}>
      {hasMenu && isLandscapeView && (
        <div className={classNames(styles.menu, { [styles.menu__hasMargin]: !noLandscapeMargin })}>
          <MobileToolbar />
        </div>
      )}
      {children}
      {hasMenu && !isLandscapeView && (
        <div className={styles.menu}>
          <MobileToolbar />
        </div>
      )}
    </div>
  );
};

export default AsianToolbarWrapper;
