import { useFormatCurrency } from 'hooks/useFormatCurrency';

import styles from '../../styles.module.scss';

interface CurrenciesCellValueProps {
  value: number;
}

const CurrenciesCellValue = ({ value }: CurrenciesCellValueProps) => {
  const { noFormattedAmount: parsedValue } = useFormatCurrency(value || 0, '', {
    noCommas: true,
    isCheckIndian: true,
    noRounding: true,
    ignorePrecision: true,
    noSymbol: true
  });

  return <span className={styles.quickBets__table__column__cell}>{value ? parsedValue : ''}</span>;
};

export default CurrenciesCellValue;
