import { useSelector } from 'react-redux';

import { getProperties } from 'redux/modules/appConfigs/selectors';

import useDevice from './useDevice';

export default function useDeviceSettings() {
  const properties = useSelector(getProperties);

  const { isMobile } = useDevice();

  return properties[isMobile ? 'mobileSettings' : 'desktopSettings'];
}
