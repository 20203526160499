import { ReactNode, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EventHeaderBasketball from 'components/EventHeaderBasketball';
import EventHeaderCricket from 'components/EventHeaderCricket';
import EventHeaderSoccer from 'components/EventHeaderSoccer';
import EventHeaderTennis from 'components/EventHeaderTennis';
import { FAVORITES_TYPES, IS_SPECIAL_HEADER_ON_EVENT } from 'constants/app';
import {
  getEventUpdatesWsEnabled,
  getGeneralWsEnabled,
  getHideLiveScoreInHeader,
  getIsPropertiesLoaded,
  getLanguage,
  getRunningBallInterval,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import { getIsUserScrolling } from 'redux/modules/appSettings/selectors';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { fetchEventsUpdatedDataByEventId } from 'redux/modules/marketsPrices';
import {
  getEventsUpdatedDataLoading,
  getIsEventsUpdatedDataUpdateDetailsByEventId
} from 'redux/modules/marketsPrices/selectors';
import { PageBlocks, SportId } from 'types';

interface EventLiveScoreProps {
  sportId: string;
  eventId: string;
  title: string;
  inPlay?: boolean;
  pageBlock?: PageBlocks;
  children: ReactNode;
  matchStatEnabled: boolean;
  isInViewPort?: boolean;
}

const EventLiveScore = ({
  sportId,
  eventId,
  inPlay,
  pageBlock,
  title,
  children,
  matchStatEnabled,
  isInViewPort = false
}: EventLiveScoreProps) => {
  const isFavorite = useSelector(getFavoriteById(eventId));
  const isUpdateDetails = useSelector(getIsEventsUpdatedDataUpdateDetailsByEventId(eventId));
  const hideLiveScoreInHeader = useSelector(getHideLiveScoreInHeader);

  const favoriteData = {
    entryId: eventId,
    entryName: title,
    entryType: FAVORITES_TYPES.event,
    starred: !isFavorite,
    sportId
  };

  const getSpecialEventsHeaders = () => {
    if (IS_SPECIAL_HEADER_ON_EVENT[sportId] && isUpdateDetails && !(matchStatEnabled && hideLiveScoreInHeader)) {
      const commonProps = {
        pageBlock,
        favoriteData,
        eventId,
        sportId
      };

      switch (sportId) {
        case SportId.TENNIS:
          return <EventHeaderTennis {...commonProps} />;
        case SportId.CRICKET:
          return <EventHeaderCricket {...commonProps} />;
        case SportId.SOCCER:
          return <EventHeaderSoccer {...commonProps} />;
        case SportId.BASKETBALL:
          return <EventHeaderBasketball {...commonProps} />;
      }
    }
    return children;
  };

  return (
    <>
      <EventLiveScoreEventUpdatesInjection
        isInViewPort={isInViewPort}
        inPlay={inPlay}
        eventId={eventId}
        sportId={sportId}
      />
      {getSpecialEventsHeaders()}
    </>
  );
};

export default EventLiveScore;

type EventLiveScoreEventUpdatesInjectionProps = {
  isInViewPort: boolean;
  inPlay?: boolean;
  eventId: string;
  sportId: string;
};

function EventLiveScoreEventUpdatesInjection({
  isInViewPort,
  inPlay,
  eventId,
  sportId
}: EventLiveScoreEventUpdatesInjectionProps) {
  const dispatch = useDispatch();

  const isUserScrolling = useSelector(getIsUserScrolling);
  const timezone = useSelector(getTimezone);
  const runningBallInterval = useSelector(getRunningBallInterval);
  const language = useSelector(getLanguage);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const eventUpdatesWsEnabled = useSelector(getEventUpdatesWsEnabled);
  const loading = useSelector(getEventsUpdatedDataLoading);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  const eventUpdatesInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const eventUpdatesTimout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const isEventUpdatesIntervalAvailable =
    arePropertiesLoaded && !!eventId && (!generalWsEnabled || !eventUpdatesWsEnabled);

  const clearIntervals = () => {
    if (eventUpdatesTimout.current) {
      clearTimeout(eventUpdatesTimout.current);
    }
    if (eventUpdatesInterval.current) {
      clearInterval(eventUpdatesInterval.current);
    }
  };

  useEffect(() => {
    if (isEventUpdatesIntervalAvailable) {
      if (isUserScrolling) {
        clearIntervals();
      } else if (isInViewPort) {
        eventUpdatesTimout.current = setTimeout(() => {
          if (IS_SPECIAL_HEADER_ON_EVENT[sportId] && eventId) {
            dispatch(fetchEventsUpdatedDataByEventId(eventId));
            eventUpdatesInterval.current = setInterval(() => {
              if (!loadingRef.current) {
                dispatch(fetchEventsUpdatedDataByEventId(eventId));
              }
            }, runningBallInterval);
          }
        }, 300);
      } else {
        clearIntervals();
      }
    }

    return () => {
      clearIntervals();
    };
  }, [eventId, sportId, inPlay, isInViewPort, isUserScrolling, language, timezone, isEventUpdatesIntervalAvailable]);

  return null;
}
