import { useSelector } from 'react-redux';
import { isUndefined } from 'lodash';

import { INLINE_PLACEMENT_POPULAR_FULL_WIDTH, INLINE_PLACEMENT_TWO_COLUMNS_VIEW } from 'constants/inlinePlacement';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useOddsDisplayFormat from 'hooks/useOddsDisplayFormat';
import useWindowSize from 'hooks/useWindowSize';
import {
  getAppDevice,
  getIsMultiMarket,
  getIsTop5View,
  getIsTwoColumnView,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import {
  getIsBettingEnabledByMarketPricesId,
  getMarketPricesRunnerLockedBySelectionId,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { getAccountSettings } from 'redux/modules/user/selectors';
import { MarketStatus, PageBlocks } from 'types';
import { getPricesByMarketType } from 'utils/price';

const useInlinePlacement = () => {
  const device = useSelector(getAppDevice);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const accountSettings = useSelector(getAccountSettings);

  const { inlineBetting } = useDeviceSettings();

  if (device === Devices.DESKTOP) {
    if (!isLoggedIn) {
      return false;
    } else {
      return inlineBetting && !accountSettings?.betslipPlacement;
    }
  } else {
    return true;
  }
};

export const useInlineSelectedBetView = ({ sportId, pageBlock }: { sportId: string; pageBlock?: PageBlocks }) => {
  const isMultiMarketView = useSelector(getIsMultiMarket(sportId));
  const isTop5ViewView = useSelector(getIsTop5View(sportId));
  const isTwoColumnView = useSelector(getIsTwoColumnView(sportId));

  const { windowWidth } = useWindowSize(100);

  const isTop5TopMarket = pageBlock === PageBlocks.TOP_5_VIEW_TOP_MARKET;
  const isMultiMarketTopMarket = pageBlock === PageBlocks.MULTI_MARKET_VIEW_TOP_MARKET;
  const isPopularMarket = pageBlock === PageBlocks.POPULAR;

  const isShortView =
    (((isMultiMarketView && !isMultiMarketTopMarket && pageBlock === PageBlocks.MULTI_MARKET_VIEW) ||
      (isTop5ViewView && !isTop5TopMarket && pageBlock === PageBlocks.TOP_5_VIEW)) &&
      isTwoColumnView) ||
    (isPopularMarket && windowWidth > INLINE_PLACEMENT_POPULAR_FULL_WIDTH) ||
    (pageBlock && INLINE_PLACEMENT_TWO_COLUMNS_VIEW.includes(pageBlock));

  return { isShortView };
};

export default useInlinePlacement;

export const useIsDisabledInlineSelectedBet = (bet: TInlineSelectedBet) => {
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const marketStatus = useSelector(getStatusByMarketPricesId(bet.marketId));
  const isBettingEnabled = useSelector(getIsBettingEnabledByMarketPricesId(bet.marketId));
  const isLocked = useSelector(
    getMarketPricesRunnerLockedBySelectionId(bet.marketId, bet.selectionId, +(bet.handicap ?? 0))
  );

  const odds = useOddsDisplayFormat({ ...bet, betType: bet.type });
  const oddsByType = getPricesByMarketType(odds, bet.marketType, bet.type);

  const isSuspended = marketStatus === MarketStatus.SUSPENDED;
  const isClosed = marketStatus === MarketStatus.CLOSED;
  const isPNCLock = isPNCEnabled && (oddsByType === 0 || oddsByType === null);

  return isSuspended || isClosed || isLocked || (!isUndefined(isBettingEnabled) && !isBettingEnabled) || isPNCLock;
};
