import { TFunction } from 'i18next';

import { Periods, TPeriods } from 'types';

import { formatValue } from './index';

export const getStartDateByPeriod = (period: TPeriods, startDate: Date, dayShift?: number) => {
  switch (period) {
    case Periods.TODAY:
      return getTodayStartDate();
    case Periods.YESTERDAY:
      return getYesterdayDate();
    case Periods.THIS_WEEK:
      return getThisMondayDate(dayShift);
    case Periods.LAST_WEEK:
      return getLastMondayDate(dayShift);
    case Periods.LAST_30_DAYS:
      return getThirtyDaysAgo();
    case Periods.LAST_3_MONTHS:
      return getThreeMonthsAgo();
  }
  return startDate;
};

export const getEndDateByPeriod = (period: TPeriods, endDate: Date, dayShift?: number) => {
  switch (period) {
    case Periods.TODAY:
      return getTodayDate();
    case Periods.YESTERDAY:
      return getYesterdayEndDate();
    case Periods.THIS_WEEK:
      return getTodayDate();
    case Periods.LAST_WEEK:
      return getLastSundayEndDate(dayShift);
    case Periods.LAST_30_DAYS:
      return getTodayDate();
    case Periods.LAST_3_MONTHS:
      return getTodayDate();
  }
  return endDate;
};

export const getPeriod = (startDate: Date, endDate: Date, currentPeriod?: TPeriods, dayShift?: number) => {
  const todayDate = getTodayDate();
  const todayStartDate = getTodayStartDate();
  const thisMondayDate = getThisMondayDate(dayShift);
  const thirtyDaysAgo = getThirtyDaysAgo();
  const threeMonthsAgo = getThreeMonthsAgo();
  const yesterdayDate = getYesterdayDate();
  const yesterdayEndDate = getYesterdayEndDate();
  const lastMondayDate = getLastMondayDate(dayShift);
  const lastSundayEndDate = getLastSundayEndDate(dayShift);
  const isThisWeek = startDate.toString() === thisMondayDate.toString();

  if (endDate.toString() === todayDate.toString()) {
    if (startDate.toString() === todayStartDate.toString() && !(currentPeriod === Periods.THIS_WEEK && isThisWeek)) {
      return Periods.TODAY;
    } else if (isThisWeek) {
      return Periods.THIS_WEEK;
    } else if (startDate.toString() === thirtyDaysAgo.toString()) {
      return Periods.LAST_30_DAYS;
    } else if (startDate.toString() === threeMonthsAgo.toString()) {
      return Periods.LAST_3_MONTHS;
    }
  } else if (startDate.toString() === yesterdayDate.toString() && endDate.toString() === yesterdayEndDate.toString()) {
    return Periods.YESTERDAY;
  } else if (
    startDate.toString() === lastMondayDate.toString() &&
    endDate.toString() === lastSundayEndDate.toString()
  ) {
    return Periods.LAST_WEEK;
  }

  return Periods.CUSTOM;
};

export const getYesterdayDate = () => {
  const date = new Date();

  date.setDate(date.getDate() - 1);
  date.setMinutes(0);
  date.setHours(0);
  date.setSeconds(0, 0);

  return date;
};

export const getTodayStartDate = () => {
  const date = new Date();

  date.setMinutes(0);
  date.setHours(0);
  date.setSeconds(0, 0);

  return date;
};

export const getYesterdayEndDate = () => {
  const date = new Date();

  date.setDate(date.getDate() - 1);
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59, 999);

  return date;
};

export const getThisMondayDate = (dayShift = 0) => {
  const date = addDays(new Date(), dayShift);
  const daysUntilMonday = (date.getDay() + 6) % 7;

  date.setDate(date.getDate() - daysUntilMonday);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0, 0);

  return addDays(date, -1 * dayShift);
};

export const getLastMondayDate = (dayShift = 0) => {
  const date = addDays(new Date(), dayShift);
  const daysUntilMonday = (date.getDay() + 6) % 7;

  date.setDate(date.getDate() - daysUntilMonday - 7);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0, 0);

  return addDays(date, -1 * dayShift);
};

export const getLastSundayEndDate = (dayShift = 0) => {
  const date = addDays(new Date(), dayShift);
  const daysUntilSunday = (date.getDay() + 7) % 7;

  date.setDate(date.getDate() - daysUntilSunday);
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59, 999);

  return addDays(date, -1 * dayShift);
};

export const getSevenDaysAgo = () => {
  const date = new Date();

  date.setDate(date.getDate() - 7);
  date.setMinutes(0);
  date.setHours(0);
  date.setSeconds(0, 0);

  return date;
};
export const getThirtyDaysAgo = () => {
  const date = new Date();

  date.setDate(date.getDate() - 30);
  date.setMinutes(0);
  date.setHours(0);
  date.setSeconds(0, 0);

  return date;
};
export const getThreeMonthsAgo = () => {
  const date = new Date();

  date.setMonth(date.getMonth() - 3);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0, 0);

  return date;
};
export const getTodayDate = () => {
  const date = new Date();

  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59, 999);

  return date;
};

export const getTodayEndDate = (timezone: string, timezoneCookieEnabled: boolean) => {
  const date = applySelectedTimezoneToDate(new Date(), timezone, timezoneCookieEnabled);
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59, 999);

  return date;
};

export const addDay = (date: Date) => {
  const resultDate = new Date(date);

  resultDate.setDate(resultDate.getDate() + 1);

  return resultDate;
};

export const addHour = (date: Date) => {
  const resultDate = new Date(date);

  resultDate.setHours(resultDate.getHours() + 1);

  return resultDate;
};

export const setHoursMinutesToZero = (date: Date) => new Date(new Date(date.setMinutes(0)).setHours(0));
export const applyTimezone = (date: Date, timezone: string, timezoneCookieEnabled: boolean): Date => {
  if (timezoneCookieEnabled) {
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset() - parseInt(timezone));
  }

  return date;
};

export const applySelectedTimezoneToDate = (date: Date | number, timezone: string, timezoneCookieEnabled: boolean) => {
  const resultDate = new Date(date);

  if (timezoneCookieEnabled) {
    resultDate.setMinutes(resultDate.getMinutes() + resultDate.getTimezoneOffset() - parseInt(timezone));
  }

  return resultDate;
};

export const subtractSelectedTimezoneToDate = (
  date: Date | number,
  timezone: string,
  timezoneCookieEnabled: boolean
) => {
  const resultDate = new Date(date);

  if (timezoneCookieEnabled) {
    resultDate.setMinutes(resultDate.getMinutes() - resultDate.getTimezoneOffset() + parseInt(timezone));
  }

  return resultDate;
};

export const applySelectedTimezoneToUTCDate = (
  date: Date | number,
  timezone: string,
  timezoneCookieEnabled: boolean
) => {
  const resultDate = new Date(date);

  if (timezoneCookieEnabled) {
    resultDate.setMinutes(resultDate.getMinutes() - parseInt(timezone));
  }

  return resultDate;
};

export const resetSelectedTimezoneToUTC = (
  date: Date | number,
  timezone: string,
  timezoneCookieEnabled: boolean
): Date => {
  const resultDate = new Date(date);

  if (timezoneCookieEnabled) {
    resultDate.setMinutes(resultDate.getMinutes() + parseInt(timezone));
  }

  return resultDate;
};

export const parseMillisecondsToTime = (milliseconds: number, timezone: string, timezoneCookieEnabled: boolean) => {
  const data = applyTimezone(new Date(milliseconds), timezone, timezoneCookieEnabled);

  return formatValue(data.getHours()) + ':' + formatValue(data.getMinutes());
};

export const parseDateToTime = (date: Date, hasSeconds?: boolean) => {
  return (
    formatValue(date.getHours()) +
    ':' +
    formatValue(date.getMinutes()) +
    (hasSeconds ? `:${formatValue(date.getSeconds())}` : '')
  );
};

export const getDefaultTimezoneOffset = (): string => {
  return `${new Date().getTimezoneOffset()}`;
};

export const getTimezoneCookieName = (): string => {
  return window.environmentConfig?.tzCookieName || (process.env.REACT_APP_TZ_COOKIE_NAME as string);
};

export const timeStampToStartDate = (
  date: number | undefined,
  americanDateFormatEnabled: boolean,
  timezoneCookieEnabled: boolean,
  timezone: string
) => {
  const currentDate = applyTimezone(new Date(date || 0), timezone, timezoneCookieEnabled);
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  if (americanDateFormatEnabled) {
    return `${formatValue(month)}-${formatValue(day)}-${year} ${formatValue(hours)}:${formatValue(minutes)}`;
  }
  return `${formatValue(day)}-${formatValue(month)}-${year} ${formatValue(hours)}:${formatValue(minutes)}`;
};

export const isExpiredPeriod = (startDate: Date | null, endDate: Date | null) => {
  const currentTime = new Date().getTime();
  const startTime = startDate?.getTime() || 0;
  const endTime = endDate?.getTime() || 0;
  return startTime < currentTime && endTime < currentTime;
};

export const addDays = (date: Date, daysCountToAdd: number) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + daysCountToAdd);

  return newDate;
};

export const getDaysDifference = (oldDate: Date, newDate: Date) => {
  return Math.ceil((newDate.getTime() - oldDate.getTime()) / (1000 * 60 * 60 * 24));
};

export const getBettingDayTimezone = () => {
  const todayDate = applySelectedTimezoneToDate(new Date(), '0', true);
  // if it's over 12:00 in GMT +8
  if (todayDate.getHours() >= 20) {
    // GMT +20
    return '-1200';
  }
  // GMT -4
  return '240';
};

export const getLongDateFormat = (
  currentDate: Date | undefined,
  t: TFunction,
  timezone: string,
  timezoneCookieEnabled: boolean,
  americanDateFormatEnabled: boolean
) => {
  if (currentDate) {
    const month = new Date(currentDate).getMonth();
    const shortMonth = t(`dates.shortMonth.${month}`);
    const date = applyTimezone(new Date(currentDate), timezone, timezoneCookieEnabled).getDate();
    const year = new Date(currentDate).getFullYear();

    return americanDateFormatEnabled ? `${shortMonth} ${date}, ${year}` : `${date} ${shortMonth} ${year}`;
  }
};
