import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface DesktopOtherMarketsTitleProps {
  onToggleOtherMarkets: () => void;
  isOpenedTopMarkets: boolean;
  titleKey: string;
}

const DesktopOtherMarketsTitle = ({
  onToggleOtherMarkets,
  isOpenedTopMarkets,
  titleKey
}: DesktopOtherMarketsTitleProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('biab_multi-markets-title', styles.otherMarketsTitle)} onClick={onToggleOtherMarkets}>
      <span>{t(titleKey)}</span>
      <i
        className={classNames('fa2 biab_expand', {
          'fa2-arrow-down': !isOpenedTopMarkets,
          'fa2-arrow-up': isOpenedTopMarkets
        })}
      />
    </div>
  );
};

export default DesktopOtherMarketsTitle;
