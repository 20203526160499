import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { camelCase } from 'lodash';

import { getLanguage } from 'redux/modules/appConfigs/selectors';
import { ETimeFilters } from 'redux/modules/competitions/type';

const useTimeFiltersSortingAndTranslation = (filters: ETimeFilters[], withoutCurrentFilter = false) => {
  const { t } = useTranslation();
  const { competitionId = '', timeFilter = '' } = useParams();

  const language = useSelector(getLanguage);

  return useMemo(() => {
    if (filters?.length) {
      const futureFilter = filters.filter(filter => filter === ETimeFilters.FUTURE) ?? [];
      let resultFilters = filters
        .filter(filter => filter !== ETimeFilters.FUTURE)
        .sort()
        .concat(futureFilter)
        .map(f => {
          const name = ETimeFilters.IN_PLAY ? camelCase(f.toLowerCase()) : f.toLowerCase();

          return { label: t(`competition.view.tabs.${name}`), value: f };
        });

      if (withoutCurrentFilter && (timeFilter || competitionId)) {
        resultFilters = resultFilters.filter(({ value }) => value !== (timeFilter || competitionId).toUpperCase());
      }

      return resultFilters;
    }

    return [];
  }, [filters, language, withoutCurrentFilter, competitionId, timeFilter]);
};

export default useTimeFiltersSortingAndTranslation;
