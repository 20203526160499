import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import GameBoxItem from 'components/GameBoxItem';
import styles from 'components/GameBoxItem/styles.module.scss';
import RotateDevice from 'components/RotateDevice';
import { HOME_BASE_URL } from 'constants/locations';
import useAddHeightForFooter from 'hooks/useAddHeightForFooter';
import useWindowSize from 'hooks/useWindowSize';
import { getAppDevice, getTranslation } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchGames } from 'redux/modules/games';
import { getGameList, getGamesFetchFinished, getGamesLoading } from 'redux/modules/games/selectors';
import { serializeGamesData } from 'utils/games';

const GamesPage = () => {
  const dispatch = useDispatch();
  const { isLandscape } = useWindowSize(100);
  const navigate = useNavigate();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const device = useSelector(getAppDevice);
  const domainLanguage = useSelector(getTranslation);
  const gameList = useSelector(getGameList);
  const loading = useSelector(getGamesLoading);
  const gamesFetchFinished = useSelector(getGamesFetchFinished);

  const { target, targetHeight, addedHeight } = useAddHeightForFooter();

  const groupedGames = useMemo(() => {
    return serializeGamesData(gameList);
  }, [gameList]);

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    dispatch(fetchGames());
  }, [device, domainLanguage, isLoggedIn]);

  useEffect(() => {
    if (!loading && gamesFetchFinished && !gameList.length) {
      navigate(HOME_BASE_URL);
    }
  }, [loading, gameList, gamesFetchFinished]);

  useEffect(() => {
    dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: targetHeight }));
  }, [targetHeight]);

  return (
    <>
      <div className={classNames({ 'biab_games-tiles-wrapper': isLandscape })} ref={target}>
        {isLandscape ? (
          <div className={classNames(styles.gamesContainer, 'biab_games-tiles-content')}>
            {groupedGames.map((game, index) => (
              <GameBoxItem key={index} game={game} />
            ))}
          </div>
        ) : (
          <RotateDevice />
        )}
      </div>
      {isLandscape && addedHeight > 0 && <div className="biab_games-tiles-wrapper" style={{ height: addedHeight }} />}
    </>
  );
};

export default GamesPage;
