import { TFunction } from 'react-i18next';

export const YET_TO_BAT = 'yetToBat';
const ALL_OUT = 'ALL_OUT';
const DEC = 'DEC';

export const getInningByTeam = ({
  isInning,
  runs,
  overs,
  wickets
}: {
  isInning: boolean;
  runs?: string;
  wickets?: string;
  overs?: string;
}): string => {
  if (!isInning) {
    return '-';
  }
  let scope = runs;
  if (wickets !== ALL_OUT) {
    scope = `${scope}/`;
  }
  if (wickets !== DEC && wickets !== ALL_OUT) {
    scope = `${scope}${wickets?.toLowerCase()}`;
  }
  scope = `${scope} (${overs})`;
  return scope;
};

export const getInning = ({
  isHomeInning,
  isAwayInning,
  isCurrentDayEqualInning,
  homeInningRuns,
  awayInningRuns,
  homeInningWickets,
  awayInningWickets,
  homeInningOvers,
  awayInningOvers
}: {
  isHomeInning: boolean;
  isAwayInning: boolean;
  isCurrentDayEqualInning: boolean;
  homeInningRuns?: string;
  awayInningRuns?: string;
  homeInningWickets?: string;
  awayInningWickets?: string;
  homeInningOvers?: string;
  awayInningOvers?: string;
}) => {
  const isYetToBatHome = !isHomeInning && isAwayInning && isCurrentDayEqualInning;
  const isYetToBatAway = isHomeInning && !isAwayInning && isCurrentDayEqualInning;

  const homeScore = isYetToBatHome
    ? '0/0 (0)'
    : getInningByTeam({
        isInning: isHomeInning,
        runs: homeInningRuns,
        wickets: homeInningWickets,
        overs: homeInningOvers
      });
  const awayScore = isYetToBatAway
    ? '0/0 (0)'
    : getInningByTeam({
        isInning: isAwayInning,
        runs: awayInningRuns,
        wickets: awayInningWickets,
        overs: awayInningOvers
      });

  return [homeScore, awayScore];
};

export const getMobileScope = (set: string) => (set === YET_TO_BAT ? '-' : set);
export const getDesktopScope = (set: string, t: TFunction) =>
  set === YET_TO_BAT ? t(`runningBall.cricket.${YET_TO_BAT}`) : set;
