import { useCallback, useEffect, useState } from 'react';

const getNormalizedCashOutPercentage = ({ partial = 100, min }: { partial?: number; min: number | undefined }) => {
  return min ? (Math.round((100 - min) * partial * 0.01) + min) * 0.01 : undefined;
};

interface IUseCashOutPercentage {
  minPartialPercentage: number | undefined;
  maxPartialPercentage: number | undefined;
  profit: number | undefined;
  liability: number | undefined;
}

const useCashOutPercentage = ({
  minPartialPercentage,
  maxPartialPercentage,
  profit,
  liability
}: IUseCashOutPercentage) => {
  const [initValue, setInitValue] = useState({
    minPartialPercentage,
    maxPartialPercentage,
    profit,
    liability
  });
  const [partial, setPartial] = useState<number | undefined>(undefined);
  const [cashOutPercentage, setCashOutPercentage] = useState<number | undefined>(
    getNormalizedCashOutPercentage({ min: initValue?.minPartialPercentage })
  );

  let profitData;
  let cashOut;

  if (initValue.profit != undefined && initValue.liability != undefined && cashOutPercentage != undefined) {
    const minMatchedProfit = -initValue.liability;
    profitData = (+initValue.profit - minMatchedProfit) * cashOutPercentage + minMatchedProfit;
    cashOut = initValue.liability + profitData;
  }

  const handler = useCallback(
    (updatedPartial = 100) => {
      setCashOutPercentage(getNormalizedCashOutPercentage({ partial: updatedPartial, min: minPartialPercentage }));
      setPartial(updatedPartial);
    },
    [minPartialPercentage]
  );

  const initValues = (values: IUseCashOutPercentage) => {
    setInitValue(values);
  };

  useEffect(() => {
    if (!partial && maxPartialPercentage) {
      setPartial(maxPartialPercentage);
    }
  }, [maxPartialPercentage, partial]);

  useEffect(() => {
    handler();
  }, [handler]);

  return {
    cashOutPercentage: cashOutPercentage,
    quotePercentage: cashOutPercentage ? cashOutPercentage * 100 : 0,
    profit: profitData != undefined ? parseFloat(profitData.toFixed(2)) : undefined,
    cashOut: cashOut,
    partial: partial,
    handlerCashOutPercentage: handler,
    initValues
  };
};

export default useCashOutPercentage;
