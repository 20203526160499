import { RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import Loader, { CircleColors } from 'components/Loader';
import Modal from 'components/Modal';
import SortingBy from 'components/SortingBy';
import { EXTRA_SMALL_SCREE_SIZE } from 'constants/app';
import { BET_GROUPS_WITH_DRAW, BET_GROUPS_WITHOUT_DRAW } from 'constants/marketsTable';
import { COLUMN_DRAW, COLUMN_NO_DRAW, MATCHED_TOTAL } from 'constants/tooltip';
import useCellConfigs from 'hooks/useCellConfigs';
import useLayColumn from 'hooks/useLayColumn';
import useLongDateFormat from 'hooks/useLongDateFormat';
import useTooltip from 'hooks/useTooltip';
import useWindowSize from 'hooks/useWindowSize';
import { getAppDevice, getPNCEnabledSetting, getTimezone } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { PageBlocks, PlacementPage, SportId, TEventUpdatedData, ViewBy } from 'types';
import {
  IMarket,
  IMarketRules,
  MarketsTableClasses,
  MarketsTableColumn,
  MarketsTableEventWidgetsOptions,
  MarketsTableHeaderType,
  TMarketSportInfo,
  ViewType
} from 'types/markets';
import { getTableViewType } from 'utils/market';
import { getEnvironmentRootPath } from 'utils/navigation';

import MatchStatIcon from '../StatisticsIcons/MatchStaticon';
import VideoIcon from '../StatisticsIcons/VideoIcon';

import MarketsTableRow from './components/MarketsTableRow';

import styles from './styles.module.scss';

interface MarketsTableProps {
  /**
   * Time section of table
   */
  timeSection?: string;
  /**
   * Count of bet groups (1 group = back and lay cells) for prices
   */
  betGroupsCount: number;

  /**
   * Title for the table
   */
  headerTitle?: string;

  /**
   * Tooltip for title of the table
   */
  tooltipHeaderTitle?: string;

  /**
   * Markets data for the table (marketId, runners, etc.)
   */
  markets: IMarket[];

  /**
   * Data about score and elapsed time for markets
   */
  eventsUpdatedData?: Record<string, TEventUpdatedData> | null;

  /**
   * Information about sport for markets (id, name, etc.)
   */
  sportInfo?: TMarketSportInfo | null;

  /**
   * Market rules for displaying in Modal
   */
  marketsRules?: Record<string, IMarketRules> | null;

  /**
   * Method for requesting rules for a particular market
   * @param marketId
   */
  onFetchMarketRules?: (marketId: string) => void;

  /**
   * Determines if rules are loaded or not
   */
  rulesLoading?: boolean;

  /**
   * Place where component was added (Home, Market odds, Competition, Event)
   */
  pageBlock: PageBlocks;

  /**
   * Hide redundant columns
   */
  hiddenColumns: MarketsTableColumn[];

  /**
   * Hide redundant header columns
   */
  hiddenHeaderColumns?: MarketsTableColumn[];

  /**
   * The view type determines what should be displayed in a row if there is not enough place (screen width)
   */
  staticViewType?: ViewType;

  /**
   * Customizing table styles
   */
  classes?: MarketsTableClasses;

  /**
   * This ref is used if you need the same behavior for columns in several tables, so you must assign this ref to the
   * closest tables parent (container), f.e. Look at component pages/InPlaySportSection
   */
  containerRef?: RefObject<Element>;

  /**
   * Header type is used to define branding (biab_) className for table header
   */
  headerType?: MarketsTableHeaderType;

  /**
   * Show or hide header for mobile
   */
  showHeaderForMobile?: boolean;

  /**
   * This callback is used with RenderIfVisible component and infinite scroll functionality, when you need to subscribe
   * new messages when table row is rendered. Should be created with useCallback
   */
  onRenderRow?: () => void;

  /**
   * Date for popular market mobile version
   */
  popularMarketTime?: string;

  /**
   * Show bet cells for mobile and hide matched cell
   */
  mobileBetting?: boolean;

  /**
   * is Table for Popular markets or not
   */
  isPopularMarketsOdds?: boolean;
  eventWidgetsOptions?: MarketsTableEventWidgetsOptions;
  sortBy?: ViewBy;
  onChangeSorting?: (value: ViewBy) => void;
  notHighlighted?: boolean;
  page?: PlacementPage;
  firstMarketId?: string;
}

const MarketsTable = ({
  timeSection,
  betGroupsCount,
  headerTitle = '',
  tooltipHeaderTitle = '',
  markets,
  sportInfo = null,
  marketsRules = null,
  onFetchMarketRules = () => {},
  rulesLoading,
  pageBlock,
  hiddenColumns,
  hiddenHeaderColumns = [],
  staticViewType,
  classes,
  containerRef,
  headerType = MarketsTableHeaderType.DEFAULT,
  showHeaderForMobile = false,
  popularMarketTime,
  mobileBetting = false,
  isPopularMarketsOdds = false,
  eventWidgetsOptions,
  sortBy,
  onChangeSorting,
  notHighlighted = false,
  page,
  firstMarketId
}: MarketsTableProps) => {
  const { t } = useTranslation();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const device = useSelector(getAppDevice);
  const timezone = useSelector(getTimezone);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);

  const [viewType, setViewType] = useState<ViewType>(staticViewType || ViewType.DEFAULT);
  const [rulesModal, setRulesModal] = useState({ open: false, marketId: '' });

  const { isLandscape } = useWindowSize(100);
  const { translationKey: translationKeyDraw, isEnabled: isEnabledDraw } = useTooltip(
    betGroupsCount === 2 ? COLUMN_NO_DRAW : COLUMN_DRAW
  );
  const { translationKey: translationKeyTotal, isEnabled: isEnabledTotal } = useTooltip(MATCHED_TOTAL);
  const { isLayColumnEnabled } = useLayColumn({
    sportId: markets[0]?.eventType.id,
    bettingType: markets[0]?.description.bettingType
  });

  const rowsRef = useRef<HTMLDivElement>(null);

  const rowsContainerRef = containerRef ?? rowsRef;
  const isMobile = device === Devices.MOBILE;
  const mobileBettingMode = mobileBetting && isMobile;
  const mobileBettingModeSwipe = mobileBettingMode && !isLandscape;

  const isShowSort = sortBy && onChangeSorting;

  const { desktopCellsContainerWidth, mobileCellsContainerWidth, cellWidthMobileNumber } = useCellConfigs({
    cellGroupsCount: betGroupsCount,
    noDepth: true,
    addMobileExtraSpace: true
  });
  const marketTime = useLongDateFormat({
    time: marketsRules?.[rulesModal.marketId]?.marketStartTime ?? 0,
    hasYear: true,
    divider: ', '
  });

  const betContentWidth = isMobile ? mobileCellsContainerWidth : desktopCellsContainerWidth;
  const mobileCellWidth =
    !mobileBettingModeSwipe && !isLayColumnEnabled ? cellWidthMobileNumber * 2 : cellWidthMobileNumber;
  const emptyCellWidth = (cellWidthMobileNumber + 3) / 2 - (window.innerWidth <= EXTRA_SMALL_SCREE_SIZE ? 10 : 0); // 3 - border width + horizontal padding

  useEffect(() => {
    if (!staticViewType && rowsContainerRef.current) {
      if (isMobile) {
        if (viewType !== ViewType.MOBILE) {
          setViewType(ViewType.MOBILE);
        }
      } else {
        const rows = Array.from(rowsContainerRef.current.getElementsByClassName('row rowMarket'));
        const isBetIndicatorsRendered = rows.some(row => {
          return Array.from(row.children).some(cell => cell.className.includes('bet-indicators-cell'));
        });
        const newViewType = getTableViewType(rows, viewType, isLoggedIn, isBetIndicatorsRendered);

        if (newViewType !== viewType) {
          setViewType(newViewType);
        }
      }
    }
  }, [staticViewType, isMobile, isLoggedIn, rowsContainerRef.current?.clientWidth]);

  const handleOpenRulesModal = (marketId: string) => {
    onFetchMarketRules(marketId);
    setRulesModal({ open: true, marketId });
  };

  const isHeaderDisplayed = showHeaderForMobile || !isMobile;

  if (!markets?.length) {
    return null;
  }

  return (
    <>
      <div className="biab_group-markets-table" role="table" data-time-section={timeSection}>
        {!isHeaderDisplayed && isShowSort && (
          <div className={classNames('biab_sorting-header', styles.tableHeader__sortBy)}>
            <SortingBy sortBy={ViewBy.TIME} selectedSort={sortBy} setSortBy={onChangeSorting} />
            <SortingBy sortBy={ViewBy.POPULARITY} selectedSort={sortBy} setSortBy={onChangeSorting} />
          </div>
        )}

        {isHeaderDisplayed && (
          <div
            className={classNames('tableHeaderMarket', styles.tableHeader, {
              'biab_group-markets-table-header':
                headerType === MarketsTableHeaderType.DEFAULT && device === Devices.DESKTOP,
              'biab_inplay-market-item-title': headerType === MarketsTableHeaderType.COMPETITION,
              [classes?.header ?? '']: classes?.header
            })}
            role="rowheader"
          >
            {isShowSort && <SortingBy sortBy={ViewBy.TIME} selectedSort={sortBy} setSortBy={onChangeSorting} />}
            <span
              className={classNames('titleMarket', styles.title, {
                [classes?.headerCell ?? '']: classes?.headerCell
              })}
            >
              <div className={styles.matchedTooltip}>
                <span
                  data-tooltip-id={'tooltip'}
                  data-tooltip-html={
                    isEnabledTotal
                      ? unescape(t(translationKeyTotal, { amount: tooltipHeaderTitle || headerTitle }))
                      : ''
                  }
                  className={isEnabledTotal ? 'cursor-help' : ''}
                >
                  {headerTitle ?? ''}
                </span>
                {eventWidgetsOptions?.videoStreamingEnabled && <VideoIcon isLeftSpace />}
                {eventWidgetsOptions?.matchStatEnabled && (
                  <MatchStatIcon isLeftSpace isSoccer={eventWidgetsOptions.sportId === SportId.SOCCER} />
                )}
              </div>
            </span>

            {!hiddenColumns?.includes(MarketsTableColumn.MATCHED) &&
              !hiddenHeaderColumns?.includes(MarketsTableColumn.MATCHED) && (
                <span
                  className={classNames(styles.matchedColumn, {
                    [styles.matchedSort]: isShowSort,
                    [styles.hiddenMatched]:
                      !isShowSort &&
                      (viewType === ViewType.SMALL ||
                        viewType === ViewType.MEDIUM ||
                        viewType === ViewType.EXTRA_SMALL),
                    [classes?.headerCell ?? '']: classes?.headerCell
                  })}
                >
                  {isShowSort ? (
                    <SortingBy sortBy={ViewBy.POPULARITY} selectedSort={sortBy} setSortBy={onChangeSorting} />
                  ) : (
                    t(`${isPNCEnabled ? 'pnc.' : ''}inplay.labels.matched`)
                  )}
                </span>
              )}
            {!hiddenColumns.includes(MarketsTableColumn.BET_CONTENT) &&
              !hiddenHeaderColumns.includes(MarketsTableColumn.BET_CONTENT) && (
                <div
                  className={classNames('betContentColumnsMarket', styles.betContentColumns)}
                  style={{ width: mobileBettingMode ? 'auto' : betContentWidth }}
                >
                  {(betGroupsCount === 2 ? BET_GROUPS_WITHOUT_DRAW : BET_GROUPS_WITH_DRAW).map(value => (
                    <div
                      key={value}
                      className={classNames('betContentColumnMarket', styles.betContentColumn, {
                        [classes?.headerCell ?? '']: classes?.headerCell,
                        [styles.betContentColumn__swipe__noFlexGrow]: mobileBettingModeSwipe && isLayColumnEnabled,
                        'cursor-help': isEnabledDraw
                      })}
                      style={{
                        width: getBetHeaderCellWidth({
                          mobileCellWidth,
                          mobileBettingMode,
                          mobileBettingModeSwipe,
                          cellWidthMobileNumber,
                          isLayColumnHidden: !isLayColumnEnabled
                        })
                      }}
                      data-tooltip-id="tooltip"
                      data-tooltip-html={isEnabledDraw ? unescape(t(translationKeyDraw)) : ''}
                    >
                      {value}
                    </div>
                  ))}
                </div>
              )}
            {!hiddenColumns?.includes(MarketsTableColumn.RULES) &&
              !hiddenHeaderColumns?.includes(MarketsTableColumn.RULES) && <div className={styles.rulesColumn} />}
            {mobileBettingModeSwipe && isLayColumnEnabled && <div style={{ width: emptyCellWidth }} />}
          </div>
        )}
        <div className="rowsContainer" ref={rowsRef}>
          {markets.map((market, index) => {
            return (
              <MarketsTableRow
                key={market.marketId}
                market={market}
                notHighlighted={notHighlighted}
                isPopularMarketsOdds={isPopularMarketsOdds}
                betGroupsCount={betGroupsCount}
                betContentWidth={betContentWidth}
                sportInfo={sportInfo}
                viewType={viewType}
                onOpenRulesModal={handleOpenRulesModal}
                pageBlock={pageBlock}
                hiddenColumns={hiddenColumns}
                popularMarketTime={popularMarketTime}
                classes={{
                  lastRow:
                    index === markets.length - 1
                      ? classNames(styles.lastRow, { [styles.mobileBettingMode]: mobileBettingMode })
                      : undefined,
                  firstRow: index === 0 ? styles.firstRow : undefined
                }}
                mobileBettingMode={mobileBettingMode}
                mobileBettingModeSwipe={mobileBettingModeSwipe}
                mobileCellWidth={mobileCellWidth}
                eventWidgetsOptions={eventWidgetsOptions}
                page={page}
                firstMarketId={firstMarketId || markets[0]?.marketId}
              />
            );
          })}
        </div>
      </div>
      {rulesModal.open && (
        <Modal
          clickableBackground
          open={rulesModal.open}
          onClose={() => setRulesModal({ open: false, marketId: '' })}
          title={
            rulesLoading
              ? t('marketRules.loading')
              : `${marketsRules?.[rulesModal.marketId]?.marketName || ''} - ${t('market.rules')}`
          }
        >
          {rulesLoading ? (
            <Loader circleColor={CircleColors.BLACK} />
          ) : (
            <>
              {t('marketRules.eventStartTime')}:
              <br />
              {timezone && marketTime}
              <br />
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    marketsRules?.[rulesModal.marketId]?.rules.replace(
                      /CONTEXT_ROOT_PATH/g,
                      getEnvironmentRootPath()
                    ) ?? ''
                }}
              />
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default MarketsTable;

function getBetHeaderCellWidth({
  mobileCellWidth,
  cellWidthMobileNumber,
  mobileBettingMode,
  mobileBettingModeSwipe,
  isLayColumnHidden
}: {
  mobileBettingMode: boolean;
  mobileCellWidth: number;
  cellWidthMobileNumber: number;
  mobileBettingModeSwipe: boolean;
  isLayColumnHidden: boolean;
}) {
  if (mobileBettingMode) {
    if (isLayColumnHidden) {
      return mobileCellWidth + 3; // 3 = border width + horizontal padding
    }

    if (mobileBettingModeSwipe) {
      return cellWidthMobileNumber + 3;
    }

    return (cellWidthMobileNumber + 3) * 2;
  }

  return 'initial';
}
