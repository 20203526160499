import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { getMultiMarketsEventRules } from 'redux/modules/multiMarket/selectors';
import { getTop5ViewEventRules } from 'redux/modules/top5View/selectors';
import { IMarket } from 'types/markets';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

interface MobileRulesViewProps {
  setIsRulesView: Dispatch<SetStateAction<boolean>>;
  markets: IMarket[];
}

const MobileRulesView = ({ setIsRulesView, markets }: MobileRulesViewProps) => {
  const { t } = useTranslation();
  const MMVEventRules = useSelector(getMultiMarketsEventRules);
  const top5EventRules = useSelector(getTop5ViewEventRules);
  const [collapsedMarkets, setCollapsedMarkets] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (markets.length) {
      const initCollapsedMarkets = markets.reduce(
        (acc, market) => ({
          ...acc,
          [market.marketId]: true
        }),
        {}
      );

      setCollapsedMarkets(initCollapsedMarkets);
    }
  }, [markets]);

  const handleCollapseMarket = (marketId: string) => {
    setCollapsedMarkets(prevState => ({
      ...prevState,
      [marketId]: !prevState[marketId]
    }));
  };

  const getMarketRules = (marketId: string) => {
    return (
      (MMVEventRules?.[marketId]?.rules.replace(/CONTEXT_ROOT_PATH/g, getEnvironmentRootPath()) ?? '') ||
      (top5EventRules?.[marketId]?.rules.replace(/CONTEXT_ROOT_PATH/g, getEnvironmentRootPath()) ?? '')
    );
  };

  return (
    <div className={styles.rulesView}>
      <p className={classNames('biab_event-rules-header', styles.rulesView__header)}>
        <span>{t('rules.title')}</span>
        <button
          className={classNames('biab_event-rules-close-btn', styles.rulesView__header__button)}
          onClick={() => setIsRulesView(false)}
        >
          <i className={classNames('io ion-close', styles.rulesView__header__button__icon)} />
        </button>
      </p>
      {markets.map(({ marketId, description, marketName }) => (
        <div key={marketId}>
          <div
            className={classNames('biab_event-rule-market-title', styles.rulesView__collapse__title)}
            onClick={() => handleCollapseMarket(marketId)}
          >
            <i
              className={classNames('fa2', {
                'fa2-arrow-down': collapsedMarkets[marketId],
                'fa2-arrow-up': !collapsedMarkets[marketId]
              })}
            />
            <span>{marketName}</span>
          </div>
          {!collapsedMarkets[marketId] && (
            <div
              className={classNames('biab_event-rule-market-content', styles.rulesView__collapse__content)}
              dangerouslySetInnerHTML={{
                __html: unescape(description.rules || getMarketRules(marketId))
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default MobileRulesView;
