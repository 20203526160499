import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import FavoriteStar from 'components/FavoriteStar';
import LiveScoreName from 'components/LiveScoreName';
import MarketDropdown from 'components/MarketDropdown';
import MatchStatistics from 'components/MatchStatistics';
import MobileEventWidgets from 'components/MobileEventWidgets';
import VideoStream from 'components/VideoStream';
import useHideLabelsOnCollision from 'hooks/useHideLabelsOnCollision';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { TFavorite } from 'redux/modules/favorites/type';
import {
  getAwayScoreByEventId,
  getEventsUpdatedDataBasketballAwayQuarterByQuarter,
  getEventsUpdatedDataBasketballHomeQuarterByQuarter,
  getEventsUpdatedDataEventTypeId,
  getEventsUpdatedDataInPlayMatchStatus,
  getEventsUpdatedDataStringifiedUpdateDetails,
  getEventsUpdatedDataTimeElapsed,
  getEventUpdatedDataLocalizedAwayName,
  getEventUpdatedDataLocalizedHomeName,
  getHomeScoreByEventId
} from 'redux/modules/marketsPrices/selectors';
import { BasketballPlayStatus, PageBlocks, SportId, TEventExtraData } from 'types';
import { isWinner } from 'utils/market';

import { getTimeLabel } from './helper';

import styles from './styles.module.scss';

type EventHeaderBasketballProps = {
  pageBlock?: PageBlocks;
  isCloseMarket?: boolean;
  /**
   * Favorite data
   */
  favoriteData: TFavorite;
  videoStreamingEnabled?: boolean;
  eventId: string;
  matchStatEnabled?: boolean;
  sportId: string;
};

const EventHeaderBasketball = ({
  pageBlock,
  isCloseMarket,
  favoriteData,
  videoStreamingEnabled = false,
  eventId,
  matchStatEnabled = false,
  sportId
}: EventHeaderBasketballProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const inPlayMatchStatus = useSelector(getEventsUpdatedDataInPlayMatchStatus(eventId));
  const eventTypeId = useSelector(getEventsUpdatedDataEventTypeId(eventId));
  const homeName = useSelector(getEventUpdatedDataLocalizedHomeName(eventId));
  const awayName = useSelector(getEventUpdatedDataLocalizedAwayName(eventId));
  const homeQuarterByQuarter = useSelector(getEventsUpdatedDataBasketballHomeQuarterByQuarter(eventId));
  const awayQuarterByQuarter = useSelector(getEventsUpdatedDataBasketballAwayQuarterByQuarter(eventId));
  const homeScore = useSelector(getHomeScoreByEventId(eventId));
  const awayScore = useSelector(getAwayScoreByEventId(eventId));
  const timeElapsed = useSelector(getEventsUpdatedDataTimeElapsed(eventId));
  const stringifiedUpdateDetails = useSelector(getEventsUpdatedDataStringifiedUpdateDetails(eventId));

  const updateDetails = useMemo<TEventExtraData[]>(
    () => (stringifiedUpdateDetails ? JSON.parse(stringifiedUpdateDetails) : []),
    [stringifiedUpdateDetails]
  );

  const scoreBlockRef = useRef<HTMLDivElement>(null);

  const isMobile = device === Devices.MOBILE;

  const label = getTimeLabel({ timeElapsed, homeQuarterByQuarter, inPlayMatchStatus, updateDetails, t });

  const homePoints = homeQuarterByQuarter?.length ? [...homeQuarterByQuarter] : [];
  const awayPoints = awayQuarterByQuarter?.length ? [...awayQuarterByQuarter] : [];

  const homePoint = Array.from(Array(4)).map((_, index) => homePoints[index] ?? '-');
  const awayPoint = Array.from(Array(4)).map((_, index) => awayPoints[index] ?? '-');

  const extraHomeTime = homePoints.splice(4);
  const extraAwayTime = awayPoints.splice(4);

  const isSearchType = pageBlock === PageBlocks.SEARCH;
  const isShowLeftFavorite = (isSearchType && !isMobile && !isCloseMarket) || (isMobile && !isCloseMarket);
  const currentSet = [...homePoints, ...extraHomeTime].length - 1;

  const { sportWrapperRef, isIntersect, rightSideContainerRef, centerWrapperRef } = useHideLabelsOnCollision();

  const isFinished = inPlayMatchStatus === BasketballPlayStatus.FINISH;

  const renderMatch = (index: number, isExtraTeam?: boolean) => {
    const homeRenderPoint = isExtraTeam ? extraHomeTime : homePoint;
    const awayRenderPoint = isExtraTeam ? extraAwayTime : awayPoint;
    const curSet = isExtraTeam ? currentSet - 4 : currentSet;
    return (
      <div className={classNames('biab_running-ball-score-wrapper', styles.eventHeader__scope)}>
        <div className={classNames('biab_points-wrapper')}>
          <div
            className={classNames('biab_points-value biab_score', styles.eventHeader__point, {
              biab_active: !isCloseMarket && !isFinished && curSet === index
            })}
          >
            <div
              className={classNames('biab_point', styles.eventHeader__currentPoint, {
                [isWinner(homePoint[index], awayPoint[index])]: curSet > index || isCloseMarket || isFinished
              })}
            >
              {homeRenderPoint[index] ?? '-'}
            </div>
            <div
              className={classNames('biab_point', styles.eventHeader__currentPoint, {
                [isWinner(awayPoint[index], homePoint[index])]: curSet > index || isCloseMarket || isFinished
              })}
            >
              {awayRenderPoint[index] ?? '-'}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classNames({ biab_market: isSearchType })} data-inplay-widget={'basketball'}>
      <div
        className={classNames(
          'biab_running-ball-widget biab_basketball biab_event-title biab_market-title biab_has-icon',
          styles.basketball
        )}
      >
        <div className={classNames(styles.basketball__container)} ref={sportWrapperRef}>
          <div
            className={classNames(styles.eventHeader, {
              [styles.eventHeader__mobile]: isMobile,
              [styles.eventHeader__favorite]: isShowLeftFavorite
            })}
            ref={centerWrapperRef}
          >
            {isShowLeftFavorite && (
              <div className={classNames(styles.eventHeader__favouriteIcon, styles.eventHeader__leftFavorite)}>
                <FavoriteStar entryData={favoriteData} />
              </div>
            )}
            <div className={classNames('biab_teams', styles.basketball__name)}>
              <div
                className={classNames('biab_teams', styles.basketball__names)}
                style={{
                  minHeight: scoreBlockRef.current?.clientHeight || 40
                }}
              >
                <h2 className={classNames('biab_team-name', styles.eventHeader__title, styles.eventHeader__left)}>
                  <LiveScoreName
                    isSearchType={isSearchType}
                    eventTypeId={eventTypeId}
                    name={homeName}
                    eventId={eventId}
                  />
                  @
                </h2>
                <h2 className={classNames('biab_team-name', styles.eventHeader__title, styles.eventHeader__left)}>
                  <LiveScoreName
                    isSearchType={isSearchType}
                    eventTypeId={eventTypeId}
                    name={awayName}
                    eventId={eventId}
                  />
                </h2>
              </div>

              {isMobile ? (
                <div className={classNames('biab_time-label-wrap', styles.basketball__timeWrapper)}>
                  <div className={classNames('biab_time-label', styles.basketball__timeLabel)}>{label}</div>
                </div>
              ) : (
                <div />
              )}
            </div>

            <div className={styles.basketball__set}>
              <div ref={scoreBlockRef} className={classNames(styles.eventHeader__scope)}>
                <div className={classNames('biab_score biab_inplay', styles.eventHeader__point)}>
                  <div
                    className={classNames(styles.pointDesktopSize, styles.basketball__score, {
                      [isWinner(+homeScore, +awayScore)]: isCloseMarket || isFinished
                    })}
                  >
                    {homeScore}
                  </div>
                  <div
                    className={classNames(styles.pointDesktopSize, styles.basketball__score, {
                      [isWinner(+awayScore, +homeScore)]: isCloseMarket || isFinished
                    })}
                  >
                    {awayScore}
                  </div>
                </div>
              </div>
              <div className={classNames('biab_point-index', styles.set)}>{` `}</div>
            </div>
            {(homePoint.length > awayPoint.length ? homePoint : awayPoint).map((value, index: number) => (
              <div className={styles.basketball__set} key={`${value}-${index}`}>
                {renderMatch(index)}
                <div className={classNames('biab_label', styles.set, { [styles.set__mobile]: isMobile })}>
                  {t(`runningBall.basketball.Q${index + 1}`)}
                </div>
              </div>
            ))}
            {(extraHomeTime.length > extraAwayTime.length ? extraHomeTime : extraAwayTime).map(
              (value, index: number) => (
                <div className={styles.basketball__set} key={`${value}-${index}`}>
                  {renderMatch(index, true)}
                  <div className={classNames('biab_points-wrapper')}>
                    <div className={classNames('biab_point-index', styles.set)}>{t(`runningBall.basketball.OT`)}</div>
                  </div>
                </div>
              )
            )}
            <div>
              {!isMobile && (
                <div
                  className={classNames('biab_time-label-wrap', styles.basketball__timeWrapper)}
                  style={{ minHeight: scoreBlockRef.current?.clientHeight || 40 }}
                >
                  <div className={classNames('biab_time-label', styles.basketball__timeLabel)}>{label}</div>
                </div>
              )}
              <div />
            </div>
          </div>
          <div className={styles.rightSide} ref={rightSideContainerRef}>
            {!isMobile && !isCloseMarket && (videoStreamingEnabled || matchStatEnabled) && (
              <div
                className={classNames(styles.widgetIconsWrapper, {
                  [styles.widgetIconsWrapper__vertical]: isIntersect
                })}
              >
                {videoStreamingEnabled && (
                  <VideoStream hideLabel={isIntersect} visible={videoStreamingEnabled} isInHeader eventId={eventId} />
                )}
                {matchStatEnabled && (
                  <MatchStatistics
                    hideLabel={isIntersect}
                    isSoccer={sportId === SportId.SOCCER}
                    visible={matchStatEnabled}
                    isInHeader
                    eventId={eventId}
                  />
                )}
              </div>
            )}
            {!isMobile && !isCloseMarket && !isSearchType && (
              <div className={styles.eventHeader__favouriteIcon}>
                <FavoriteStar entryData={favoriteData} iconClassName={styles.eventHeader__favouriteIcon__right} />
              </div>
            )}
            {isMobile && <MarketDropdown topPosition={80} />}
          </div>
        </div>
      </div>
      {isMobile && !isCloseMarket && (videoStreamingEnabled || matchStatEnabled) && (
        <MobileEventWidgets
          videoStreamingEnabled={videoStreamingEnabled}
          matchStatEnabled={matchStatEnabled}
          eventId={eventId}
          sportId={sportId}
        />
      )}
    </div>
  );
};

export default EventHeaderBasketball;
