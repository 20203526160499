import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { camelCase } from 'lodash';

import NavSkeleton from 'components/Skeleton/NavSkeleton';
import SportPageModule from 'components/SportPageModule';
import styles from 'components/SportsSection/styles.module.scss';
import { TIME_FILTERS } from 'constants/app';
import { TYPE_TO_URL } from 'constants/competitions';
import {
  getAppDevice,
  getDesktopSettingsCompetitionViewSports,
  getIsCompetitionViewEnabled,
  getLanguage,
  getTimezone,
  getTranslation
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchCompetitionDetails, resetSportData, setRace } from 'redux/modules/competitions';
import {
  getCompetitionData,
  getCompetitionLoading,
  getCompetitionNameHighlighted,
  getCompetitionParents,
  getCompetitionsNameTranslations
} from 'redux/modules/competitions/selectors';
import { ETimeFilters, TNavigationResponse } from 'redux/modules/competitions/type';
import { SportPageContextFilter } from 'redux/modules/sportPage/type';
import { PlacementPage, SportId } from 'types';
import { filterCompetitionViewParents } from 'utils/navigation';

import ListItem from '../partials/ListItem';
import NavItemName from '../partials/NavItemName';
import ParentItem from '../partials/ParentItem';

type TMoreItem = { id: string; name: string; translations: { [key: string]: string }; type?: string };

const CompetitionView = () => {
  const { sportId = '', competitionId = '' } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const competitionViewSports = useSelector(getDesktopSettingsCompetitionViewSports);
  const isCompetitionViewEnabled = useSelector(getIsCompetitionViewEnabled);
  const timezone = useSelector(getTimezone);
  const device = useSelector(getAppDevice);
  const language = useSelector(getLanguage);
  const domainLanguage = useSelector(getTranslation);
  const competitionParents = useSelector(getCompetitionParents);
  const competitionData = useSelector(getCompetitionData);
  const competitionNameTranslations = useSelector(getCompetitionsNameTranslations);
  const competitionLoading = useSelector(getCompetitionLoading);
  const competitionNameHighlighted = useSelector(getCompetitionNameHighlighted);

  const isSingleCompetitionPage = !TIME_FILTERS.includes(competitionId);
  const competitionName = t(`competition.view.tabs.${ETimeFilters.IN_PLAY ? camelCase(competitionId) : competitionId}`);
  const isTennis = sportId === SportId.TENNIS;

  const formattedCompetitionData = useMemo(() => {
    let data: Array<TNavigationResponse | TMoreItem> = competitionData;
    if ((isTennis || device === Devices.MOBILE) && competitionData?.length) {
      const hasMarketChildren = competitionData.some(i => TYPE_TO_URL[i.type] === TYPE_TO_URL.MARKET);
      const moreIndex = competitionData.findIndex(i => i.id === '#date_More');
      if (moreIndex === -1 && competitionData[0].id.indexOf('#') !== 0 && hasMarketChildren) {
        const moreItem: TMoreItem = {
          id: '#date_More',
          name: t('customer.navigation.more.items'),
          translations: { [domainLanguage]: t('customer.navigation.more.items') }
        };
        data = [moreItem, ...competitionData.map(i => (!i.type ? { ...i, type: 'date' } : i))];
      }
    }
    const moreIndex = data.findIndex(i => i.id === '#date_More');
    let result: Array<TNavigationResponse | TMoreItem> = [];
    if (moreIndex !== -1) {
      result = data.reduce(function (current: Array<TNavigationResponse | TMoreItem>, item, index: number) {
        if (index > moreIndex && (item.type === 'date' || item.type === 'MARKET')) {
          current.push(item);
        }
        return current;
      }, []);
    }
    return result;
  }, [isTennis, competitionData, device, domainLanguage, sportId, competitionId]);

  useEffect(() => {
    if (competitionId && isSingleCompetitionPage) {
      dispatch(fetchCompetitionDetails({ eventId: competitionId, showGroups: isTennis }));
    }
  }, [competitionId, isTennis, isLoggedIn, timezone, language, isSingleCompetitionPage, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setRace(null));
      dispatch(resetSportData());
    };
  }, []);

  return (
    <>
      {competitionLoading && <NavSkeleton withDelay={150} itemsCount={40} />}
      <div className={classNames({ hidden: competitionLoading })}>
        {device === Devices.DESKTOP &&
          filterCompetitionViewParents(
            competitionParents,
            competitionViewSports,
            sportId,
            isCompetitionViewEnabled
          ).map(parent => <ParentItem key={parent.id} item={parent} />)}
        {(competitionNameTranslations || competitionName) && (
          <NavItemName
            isSubheader
            type={TYPE_TO_URL.COMPETITION}
            highlighted={competitionNameHighlighted}
            nameTranslations={
              device === Devices.MOBILE && !isSingleCompetitionPage ? competitionName : competitionNameTranslations
            }
          />
        )}
        {device === Devices.MOBILE && (!isSingleCompetitionPage || sportId !== SportId.TENNIS) && (
          <SportPageModule
            pageDetailsId={competitionId && !isSingleCompetitionPage ? sportId : competitionId}
            contextFilter={
              competitionId && !isSingleCompetitionPage
                ? SportPageContextFilter.EVENT_TYPE
                : SportPageContextFilter.COMPETITION
            }
            placementPage={PlacementPage.COMPETITION}
          />
        )}
        {device === Devices.DESKTOP &&
          competitionData.map(d => <ListItem key={d.id} item={d} domainLanguage={domainLanguage} />)}
        {device === Devices.MOBILE && isSingleCompetitionPage && !!formattedCompetitionData.length && !isTennis && (
          <div
            className={classNames('biab_navigation-items-more-title', styles.sportsList__item, styles.moreMobileItem)}
          >
            {t('customer.navigation.more.items')}
          </div>
        )}
        {device === Devices.MOBILE &&
          isSingleCompetitionPage &&
          isTennis &&
          competitionData.map((d: any) => <ListItem key={d.id} item={d} domainLanguage={domainLanguage} />)}
        {device === Devices.MOBILE &&
          isSingleCompetitionPage &&
          !isTennis &&
          formattedCompetitionData.map((d: any) => (
            <ListItem key={d.id} item={d} domainLanguage={domainLanguage} />
          ))}{' '}
      </div>
    </>
  );
};

export default CompetitionView;
