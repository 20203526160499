import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import RowRunner from 'components/RowRunner';
import { RACING_SPORT } from 'constants/app';
import { AB, POKER_STAR } from 'constants/icons';
import useCellConfigs from 'hooks/useCellConfigs';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import {
  getAppDevice,
  getIsIframeEnabled,
  getIsMarketSelectionsScroll,
  getNumberOfMarketSelectionsScroll,
  getOperator
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getScrollContentHeight } from 'redux/modules/appSettings/selectors';
import { setMarketScroll } from 'redux/modules/market';
import {
  getMarketPricesFirstKeyLineHandicap,
  getMarketPricesFirstKeyLineId,
  getMarketPricesSecondKeyLineHandicap,
  getMarketPricesSecondKeyLineId
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { PageBlocks, PlacementPage } from 'types';
import { IMarket, TMarketRunner } from 'types/markets';
import { RacingGameTitleClasses } from 'types/raceCard';
import { getLinePlusNumber } from 'utils';
import { getMarketTypes, groupRunners, marketScrollData } from 'utils/market';

import styles from './styles.module.scss';

interface ArrowScrollProps {
  /**
   * Market
   */
  market: IMarket;
  /**
   * Reset?
   */
  reset?: boolean;
  /**
   * Show all runners?
   */
  showAll?: boolean;
  /**
   * Callback fired to set settings
   * @param index
   * @param step
   * @param defaultLine
   * @param totalLines
   * @param newLinesText
   */
  onSetGroupByIndex: (
    index: number,
    step: number,
    defaultLine: number,
    totalLines: number,
    newLinesText: string
  ) => void;
  /**
   * Page block for betting
   */
  pageBlock: PageBlocks;
  /**
   * Redirect to single market page if inline placement is disabled
   */
  redirectToSingleMarketPage?: boolean;
  /**
   * Always show cells for deep prices, even if market depth is disabled
   */
  showDeepPrices?: boolean;
  /**
   * Whether market depth is enabled
   */
  isDepthEnabled: boolean;
  /**
   * Whether is landscape mode
   */
  isLandscape: boolean;
  /**
   * Used to always show betslip placement for certain markets
   */
  classes?: {
    scrollWrapper?: string;
    runnerRow?: string;
  };
  racingGameTitleClasses?: RacingGameTitleClasses;
  page: PlacementPage;
  mobilePlacementContainerClassName?: string;
}

const ArrowScroll = ({
  market,
  reset,
  showAll,
  onSetGroupByIndex,
  pageBlock,
  redirectToSingleMarketPage,
  showDeepPrices = false,
  isLandscape,
  isDepthEnabled,
  classes,
  racingGameTitleClasses,
  page,
  mobilePlacementContainerClassName = ''
}: ArrowScrollProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { runners, description } = market;

  const height = useSelector(getScrollContentHeight);
  const device = useSelector(getAppDevice);
  const numberOfMarketSelectionsScroll = useSelector(getNumberOfMarketSelectionsScroll);
  const isMarketSelectionsScroll = useSelector(getIsMarketSelectionsScroll);
  const firstKeyLineId = useSelector(getMarketPricesFirstKeyLineId(market.marketId));
  const firstKeyLineHandicap = useSelector(getMarketPricesFirstKeyLineHandicap(market.marketId));
  const secondKeyLineId = useSelector(getMarketPricesSecondKeyLineId(market.marketId));
  const secondKeyLineHandicap = useSelector(getMarketPricesSecondKeyLineHandicap(market.marketId));
  const iframeEnabled = useSelector(getIsIframeEnabled);
  const operator = useSelector(getOperator);

  const { desktopCellsContainerWidth, mobileCellsContainerWidth } = useCellConfigs({
    cellGroupsCount: isDepthEnabled ? 3 : 1,
    isMarketView: true,
    showDepth: showDeepPrices,
    addMobileExtraSpace: true
  });

  const [groupedRunners, setGroupedRunners] = useState<TMarketRunner[][]>([[]]);

  const defaultLineRef = useRef<number>();
  const defaultStateRef = useRef<boolean>(true);
  const currentLineRef = useRef<number>(1);
  const keyLineRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: market?.marketId,
    eventId: market?.event?.id,
    section: MarketsPricesSocketParamsSections.SportsMiddleSection,
    observerOptions: { rootMargin: '200px' }
  });

  const isRacing = RACING_SPORT[market.eventType.id];
  const { isCombinedTotal, isHandicap, isTotalGoals, isHandicapDoubleLine, isHandicapSingleLine } = getMarketTypes(
    description.marketType,
    description.bettingType
  );
  const { pairs, group, step, isLongList } = marketScrollData(description.marketType);
  const isDesktop = device === Devices.DESKTOP;

  const serializedRunners = useMemo(() => {
    if (firstKeyLineId && secondKeyLineId && !isLongList) {
      const findKeyLineRunners = runners.map((runner, index) => {
        if (
          (runner.selectionId === firstKeyLineId && (firstKeyLineHandicap || 0) === +(runner.handicap || 0)) ||
          (runner.selectionId === secondKeyLineId && (secondKeyLineHandicap || 0) === +(runner.handicap || 0))
        ) {
          const lineIndex = Math.floor(index / 2);
          const numberOfLines = Math.floor(runners.length / 2);
          defaultLineRef.current = lineIndex >= numberOfLines - 1 ? lineIndex - 1 : lineIndex;
          return { ...runner, keyLine: true };
        }

        return { ...runner, keyLine: false };
      });

      return groupRunners(findKeyLineRunners, group);
    }

    return groupRunners(runners, group);
  }, [
    runners,
    firstKeyLineId,
    secondKeyLineId,
    firstKeyLineHandicap,
    secondKeyLineHandicap,
    market.description.marketType,
    isLongList,
    group
  ]);

  const isArrowVisible =
    !showAll &&
    (numberOfMarketSelectionsScroll <= runners.length ||
      (isHandicap && isHandicapDoubleLine && groupedRunners.length <= serializedRunners.length));

  const hasScroll = showAll && !(iframeEnabled && (operator === AB || operator === POKER_STAR));

  const isDisabledTopScroll = currentLineRef.current - (isCombinedTotal ? 0 : 1) === 0;
  const isDisabledBottomScroll =
    isLongList || isHandicapSingleLine
      ? currentLineRef.current - 1 >= serializedRunners.length - numberOfMarketSelectionsScroll
      : currentLineRef.current + (pairs || 1) >= serializedRunners.length ||
        (isRacing && serializedRunners.length < currentLineRef.current + numberOfMarketSelectionsScroll);

  const getSelectionsFromList = (index: number) => {
    if (isCombinedTotal) {
      return serializedRunners.slice(index, index + 1);
    } else if (isScrollable() && !showAll) {
      if (index + numberOfMarketSelectionsScroll - 1 >= serializedRunners.length) {
        return serializedRunners.slice(
          serializedRunners.length - numberOfMarketSelectionsScroll,
          serializedRunners.length
        );
      }
      return serializedRunners.slice(index - 1, index + numberOfMarketSelectionsScroll - 1);
    } else if (isTotalGoals || isHandicapDoubleLine) {
      const from = index > 0 ? index - 1 : 1;
      const to = index > 0 ? index + pairs : pairs + 1;
      return serializedRunners.slice(from, to);
    } else {
      return serializedRunners;
    }
  };

  const isScrollable = () => {
    const runnersLength = runners.length || 0;

    return (
      isMarketSelectionsScroll &&
      !isHandicapDoubleLine &&
      !isTotalGoals &&
      !isCombinedTotal &&
      runnersLength >= numberOfMarketSelectionsScroll
    );
  };

  const setGroupByIndex = (index: number) => {
    const partialRunners = getSelectionsFromList(index);
    setGroupedRunners(partialRunners);

    currentLineRef.current = index;

    onSetGroupByIndex(index, step, defaultLineRef.current || 0, serializedRunners.length, setLinesText(index));
  };

  const scrollToTop = () => {
    let index = currentLineRef.current - step;
    defaultStateRef.current = false;

    if (!isCombinedTotal) {
      if (!isHandicap && isDisabledBottomScroll) {
        index = index - step + 2;
      }

      if (index <= 0) {
        index = 1;
      }
    }

    setGroupByIndex(index);
  };

  const scrollToDown = () => {
    let index = currentLineRef.current + step;
    defaultStateRef.current = false;

    if (index >= serializedRunners.length - 1) {
      index = serializedRunners.length - step;
    }

    setGroupByIndex(index);
  };

  const setLinesText = (index: number) => {
    const totalLines = serializedRunners.length;
    let from = index;
    let to = index + pairs;

    if (isCombinedTotal) {
      return `${index + 1} (${t('market.lines.of')} ${totalLines})`;
    } else if (isTotalGoals && to > serializedRunners.length) {
      from -= 1;
      to -= 1;
    }

    return `${from}-${to} (${t('market.lines.of')} ${totalLines})`;
  };

  useEffect(() => {
    if (reset) {
      setGroupByIndex(defaultLineRef.current ?? 1);
    }
  }, [reset]);

  useEffect(() => {
    if (showAll !== undefined) {
      if (showAll) {
        setGroupedRunners(serializedRunners);
      } else {
        if (defaultLineRef.current !== undefined) {
          setGroupByIndex(defaultLineRef.current);
          currentLineRef.current = defaultLineRef.current;
        }
      }
    }
  }, [showAll, serializedRunners]);

  useEffect(() => {
    if (!showAll) {
      defaultLineRef.current = defaultLineRef.current ?? 1;

      setGroupByIndex(
        defaultLineRef.current && defaultStateRef.current ? defaultLineRef.current : currentLineRef.current
      );
    }
  }, [serializedRunners]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      let scrollWidth = container.offsetWidth - container.clientWidth - 4;
      scrollWidth = scrollWidth > 0 ? scrollWidth : 0;

      dispatch(setMarketScroll(scrollWidth));
    }
  }, [showAll, window.innerWidth]);

  useEffect(() => {
    ref(scrollContainerRef.current);
  }, []);

  return (
    <div
      className={classNames({
        'biab_market-combined-total': isCombinedTotal,
        [styles.runnersWrapper__scrollContent]: hasScroll
      })}
      style={{ maxHeight: hasScroll ? `${height - 140}px` : '' }}
      ref={scrollContainerRef}
      data-market-id={market?.marketId}
      data-event-id={market?.event.id}
      data-market-prices={true}
    >
      <div
        className={classNames(
          styles.scrollWrapper,
          {
            [styles.scrollWrapper__hidden]: !isArrowVisible
          },
          classes?.scrollWrapper ?? ''
        )}
      >
        <span
          onClick={scrollToTop}
          className={classNames('biab_scroll-button', {
            [styles.scrollWrapper__disabled]: isDisabledTopScroll
          })}
        >
          ▲
        </span>
      </div>
      {!!groupedRunners[0]?.length &&
        groupedRunners.map((groupedRunner, lineIndex) => (
          <Fragment key={`${groupedRunner[0].selectionId}-${lineIndex}`}>
            {(isTotalGoals || isCombinedTotal) && (
              <div
                className={classNames('biab_atg', styles.runnersWrapper__liner)}
                style={{ width: isDesktop ? desktopCellsContainerWidth : '' }}
              >
                <span className={styles.runnersWrapper__linerNumber}>
                  {getLinePlusNumber(groupedRunner[0].handicap, !isTotalGoals)}
                </span>
              </div>
            )}
            <div
              className={classNames({
                [`${styles.runnersWrapper__firstRunner} runnersWrapper__firstRunner`]:
                  (isDisabledTopScroll || !isArrowVisible) && lineIndex === 0,
                [styles.runnersHandicap]: isHandicapDoubleLine || isTotalGoals,
                [styles.combinedTotalWrapper]: isCombinedTotal
              })}
            >
              {groupedRunner.map(runner => (
                <RowRunner
                  key={runner.runnerName}
                  market={market}
                  runner={runner}
                  pageBlock={pageBlock}
                  keyLineRef={keyLineRef}
                  classes={{
                    market: group !== 1 ? styles.runnersWrapper__nonBorder : '',
                    runnerRow: classes?.runnerRow
                  }}
                  redirectToSingleMarketPage={redirectToSingleMarketPage}
                  isLandscape={isLandscape}
                  isDepthEnabled={isDepthEnabled}
                  racingGameTitleClasses={racingGameTitleClasses}
                  page={page}
                  desktopCellsContainerWidth={desktopCellsContainerWidth}
                  mobileCellsContainerWidth={mobileCellsContainerWidth}
                  mobilePlacementContainerClassName={mobilePlacementContainerClassName}
                />
              ))}
            </div>
          </Fragment>
        ))}
      <div
        className={classNames(
          styles.scrollWrapper,
          {
            [styles.scrollWrapper__hidden]: !isArrowVisible
          },
          classes?.scrollWrapper ?? ''
        )}
      >
        <span
          onClick={scrollToDown}
          className={classNames('biab_scroll-button', styles.scrollWrapper__down, {
            [styles.scrollWrapper__disabled]: isDisabledBottomScroll
          })}
        >
          ▼
        </span>
      </div>
    </div>
  );
};

export default ArrowScroll;
