import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useResizeObserver } from 'usehooks-ts';

import DesktopNavigationItem from 'components/HeaderNavigation/components/DesktopNavigationItem';
import { subHeader as branding } from 'constants/branding';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { NavigationType } from 'redux/modules/appConfigs/type';
import { setMoreBtnWidth } from 'redux/modules/headerNavigation';

import styles from './styles.module.scss';

type ShowMoreDesktopNavItemsBtnProps = {
  navbarItems: number[];
  headerNavigation: NavigationType[];
};

const ShowMoreDesktopNavItemsBtn = ({ navbarItems, headerNavigation }: ShowMoreDesktopNavItemsBtnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const moreBtnRef = useRef<HTMLButtonElement>(null);

  const moreItems = headerNavigation.filter(hn => !navbarItems.includes(hn.orderNumber));
  const isSomeMoreItemActive = moreItems.some(({ url }) => `${location.pathname}${location.search}`.includes(url));

  useOnClickOutside(containerRef, () => setIsOpen(false));

  useResizeObserver({
    ref: moreBtnRef,
    box: 'border-box',
    onResize: ({ width }) => {
      dispatch(setMoreBtnWidth(Math.ceil(width ?? 0)));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setMoreBtnWidth(0));
    };
  }, []);

  return (
    <div className={classNames(styles.main, branding.SUBHEADER_WRAPPER)} ref={containerRef}>
      <button
        ref={moreBtnRef}
        onClick={() => setIsOpen(prevState => !prevState)}
        className={classNames(styles.more, 'biab_navigation-link', branding.SUBHEADER_HOLDER, {
          [branding.ACTIVE]: isSomeMoreItemActive || isOpen
        })}
      >
        {t('asianView.labels.navigation.more')}
        <i className={`fa2 fa2-arrow-${isOpen ? 'up' : 'down'}`} />
      </button>
      {isOpen && !!moreItems.length && (
        <div className={styles.list}>
          {moreItems.map(navigationType => {
            return (
              <DesktopNavigationItem
                isMoreDropdownItem
                key={navigationType.orderNumber}
                navigationType={navigationType}
                pathname={location.pathname}
                customClassName={classNames(branding.SUBHEADER_ITEM, styles.list__item)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ShowMoreDesktopNavItemsBtn;
