import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { TourStep } from '../appConfigs/type';

import { failureFetchTourData, fetchStepData, successFetchStepData } from './index';

export function* getToursStepWatcher(action: ReturnType<typeof fetchStepData>) {
  try {
    const response: TourStep = yield call(api.tours.getStep, action.payload);
    yield put(successFetchStepData(response));
  } catch (error: any) {
    yield put(failureFetchTourData(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchStepData, getToursStepWatcher)]);
}
