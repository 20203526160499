import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { betslipBranding, componentsBranding } from 'constants/branding';
import { BetMessageType } from 'types/betslip';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

type BetMessageProps = {
  type: BetMessageType;
  message: string;
  displayMoreInformation?: boolean;
  displayCloseBtn?: boolean;
  onClose: () => void;
  isBetCanBeRemoved?: boolean;
};

const BetMessage = ({
  type,
  message,
  displayMoreInformation = false,
  onClose,
  displayCloseBtn = false,
  isBetCanBeRemoved = false
}: BetMessageProps) => {
  const { t } = useTranslation();

  const moreInformationText = t('openBets.messages.moreInformation', { rootPath: getEnvironmentRootPath() });

  const showCloseBtn = type === 'warning' && !isBetCanBeRemoved;

  return (
    <div
      className={classNames(styles.betMessage, componentsBranding.NOTIFICATION, {
        [styles.betMessage__success]: type === 'success',
        [styles.betMessage__info]: type === 'info',
        [styles.betMessage__warning]: type === 'warning',
        [componentsBranding.SUCCESS]: type === 'success',
        [componentsBranding.ERROR]: type === 'warning'
      })}
    >
      <i
        className={classNames(`biab_custom-icon-${type}-circle`, styles.betMessage__icon, {
          [betslipBranding.INFO_ICON]: type === 'info',
          [betslipBranding.WARNING_ICON]: type === 'warning',
          [betslipBranding.CHECKMARK_ICON]: type === 'success'
        })}
        aria-hidden="true"
      />
      <div className={styles.betMessage__right}>
        <p className={styles.betMessage__text} dangerouslySetInnerHTML={{ __html: unescape(message) }} />
        {displayMoreInformation && (
          <p className={styles.moreInfo} dangerouslySetInnerHTML={{ __html: unescape(moreInformationText) }} />
        )}
      </div>
      {(displayCloseBtn || showCloseBtn) && (
        <button className={styles.betMessage__closeBtn} onClick={onClose}>
          <i className="biab_custom-icon-close" />
        </button>
      )}
    </div>
  );
};

export default BetMessage;
