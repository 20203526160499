import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import FavoriteStar from 'components/FavoriteStar';
import BackButton from 'components/Header/components/BackButton';
import NonTennisAndSMPDropdown from 'components/SportsSection/partials/NavItemName/NonTennisAndSMPDropdown';
import TennisAndNonSingleCMPDropdown from 'components/SportsSection/partials/NavItemName/TennisAndNonSingleCMPDropdown';
import { FAVORITES_TYPES, TIME_FILTERS } from 'constants/app';
import { TYPE_TO_URL } from 'constants/competitions';
import { SPORT_BASE_URL } from 'constants/locations';
import useBackButtonInIFrame from 'hooks/useBackButtonInIFrame';
import { getAppDevice, getTranslation } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { fetchTimeFilters } from 'redux/modules/competitions';
import { ETimeFilters } from 'redux/modules/competitions/type';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { SportPageContextFilter } from 'redux/modules/sportPage/type';
import { CookieNames, PopularEventType, SportId, ViewBy } from 'types';

import styles from '../../styles.module.scss';

interface INavItemName {
  nameTranslations: { [key: string]: string } | string;
  hasFavoritesIcon?: boolean;
  highlighted?: boolean;
  type?: string;
  isSubheader?: boolean;
  details?: { id: string; type: string };
}
const NavItemName = ({
  nameTranslations,
  hasFavoritesIcon = true,
  highlighted = false,
  type = '',
  isSubheader = false,
  details
}: INavItemName) => {
  const dispatch = useDispatch();
  const { sportId = '', competitionId = '', eventId = '', countryId = '', groupId = '', marketId } = useParams();
  const [cookies] = useCookies([CookieNames.COMPETITION_VIEW_BY]);
  const navigate = useNavigate();
  const location = useLocation();

  const domainLanguage = useSelector(getTranslation);
  const device = useSelector(getAppDevice);

  const entryId = eventId || competitionId || countryId || groupId || details?.id || '';
  const entryType = eventId
    ? FAVORITES_TYPES.event
    : competitionId
    ? FAVORITES_TYPES.competition
    : countryId
    ? FAVORITES_TYPES.country
    : groupId
    ? FAVORITES_TYPES.group
    : FAVORITES_TYPES.event;

  const isMobile = device === Devices.MOBILE;
  const isDesktop = device === Devices.DESKTOP;
  const isCountry = type === TYPE_TO_URL.COUNTRY;
  const isFavorite = useSelector(getFavoriteById(entryId));

  const showIFrameBackButton = useBackButtonInIFrame();

  const isSingleCompetitionPage = !TIME_FILTERS.includes(competitionId);
  const isShowFavorites = type !== TYPE_TO_URL.MARKET && isSingleCompetitionPage && entryId && hasFavoritesIcon;
  const showMobileNotGroupFavorite = isShowFavorites && !isDesktop && entryType !== PopularEventType.GROUP;
  const showMobileGroupFavorite = isShowFavorites && !isDesktop && entryType === PopularEventType.GROUP;
  const isActive = location.pathname.includes(type);

  const categoryName = typeof nameTranslations === 'string' ? nameTranslations : nameTranslations[domainLanguage];
  const favoriteData = {
    starred: !isFavorite,
    entryId: entryId,
    entryName: categoryName,
    entryType: entryType,
    sportId: sportId
  };

  useEffect(() => {
    if (
      ((isSingleCompetitionPage && sportId === SportId.TENNIS) || !isSingleCompetitionPage) &&
      device === Devices.MOBILE
    ) {
      dispatch(
        fetchTimeFilters({
          contextFilter:
            competitionId && !isSingleCompetitionPage
              ? SportPageContextFilter.EVENT_TYPE
              : SportPageContextFilter.COMPETITION,
          id: competitionId && !isSingleCompetitionPage ? sportId : competitionId,
          timeFilter:
            isSingleCompetitionPage && sportId === SportId.TENNIS ? ETimeFilters.ALL : competitionId.toUpperCase(),
          viewBy: isSingleCompetitionPage && sportId === SportId.TENNIS ? ViewBy.TIME : cookies.BIAB_COMPETITION_VIEW_BY
        })
      );
    }
  }, [competitionId, competitionId, device, isSingleCompetitionPage]);

  const withWrapper = () => {
    if (
      device === Devices.DESKTOP ||
      (sportId === SportId.TENNIS && !isSubheader) ||
      (!competitionId && !isSubheader)
    ) {
      return (
        <a
          className={classNames('biab_href biab_item', styles.category__link, {
            'biab_navigation-competition-category-name': sportId !== SportId.TENNIS && competitionId,
            biab_competition: competitionId && isDesktop,
            'biab_c-highlighted': highlighted && isDesktop,
            'biab_navigation-competition-item-name biab_active': isMobile && sportId === SportId.TENNIS
          })}
        >
          {showIFrameBackButton && <BackButton />}
          {device === Devices.DESKTOP ? (
            <>{categoryName}</>
          ) : (
            <span className={styles.category__link__text}>{categoryName}</span>
          )}
          {isShowFavorites && (
            <div
              className={classNames(styles.sportsList__favoriteItem, {
                [styles.sportsList__favoriteHidden]: !isFavorite && isDesktop
              })}
            >
              <FavoriteStar entryData={favoriteData} isNavigation />
            </div>
          )}
        </a>
      );
    } else {
      return (
        <>
          <span
            className={classNames({
              biab_competition: competitionId && isDesktop,
              'biab_c-highlighted': highlighted && isDesktop
            })}
          >
            <span className={styles.flexContainer}>
              {showIFrameBackButton && <BackButton />}
              {typeof nameTranslations === 'string' ? nameTranslations : nameTranslations[domainLanguage]}
              {showMobileNotGroupFavorite && (
                <div className={styles.favoriteItemInline}>
                  <FavoriteStar
                    className={styles.collapsibleRow__starIcon}
                    entryData={favoriteData}
                    isNavigation={true}
                  />
                </div>
              )}
            </span>
            <TennisAndNonSingleCMPDropdown />
            <NonTennisAndSMPDropdown />
          </span>
          {showMobileGroupFavorite && (
            <div className={styles.favoriteItemInlineGroup}>
              <FavoriteStar className={styles.collapsibleRow__starIcon} entryData={favoriteData} isNavigation={true} />
            </div>
          )}
        </>
      );
    }
  };

  const handleClick = () => {
    if (details?.id && isDesktop) {
      navigate(`${SPORT_BASE_URL}/${sportId}/${TYPE_TO_URL[details.type]}/${details.id}`);
    }
  };

  return (
    <li
      onClick={handleClick}
      datatype={TYPE_TO_URL[type]}
      data-nav-menu-type={isCountry ? 'country' : ''}
      data-navigation-type={details && details.type}
      data-navigation-id={details && details.id}
      className={classNames(styles.name, styles.sportsList__item, `biab_${type}_item`, {
        [`biab_c-highlighted ${styles.highlighted}`]: highlighted && isDesktop,
        [styles.eventItem]: type === TYPE_TO_URL.EVENT && isDesktop,
        [`biab_sub-navigation-header ${styles.subNavigationHeader}`]: isSubheader && isMobile,
        [`biab_active ${styles.active}`]: isActive,
        'biab_sport-item': !isSubheader && sportId !== SportId.TENNIS,
        [styles.countryItem]: type === TYPE_TO_URL.COUNTRY && isDesktop,
        'cursor-pointer': details && isDesktop,
        [`${styles.bold} biab_active`]: marketId
      })}
    >
      {withWrapper()}
    </li>
  );
};

export default NavItemName;
