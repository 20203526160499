import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { IMarketRules } from 'types/markets';

import {
  failureHomeMarketRules,
  failureHomeMarketsData,
  fetchHomeMarketRules,
  fetchHomeMarketsData,
  successHomeMarketRules,
  successHomeMarketsData
} from './index';
import { TFetchHomeMarketsDataResponse } from './type';

export function* getHomeMarketsData(action: ReturnType<typeof fetchHomeMarketsData>) {
  try {
    const response: TFetchHomeMarketsDataResponse = yield call(
      api.home.marketsSection,
      action.payload.sportId,
      action.payload.isMobile,
      action.payload.sortBy
    );
    yield put(successHomeMarketsData({ ...response, sportId: action.payload.sportId }));
  } catch (error: any) {
    yield put(failureHomeMarketsData(error.data));
  }
}

export function* getHomeMarketRules(action: ReturnType<typeof fetchHomeMarketRules>) {
  try {
    const response: IMarketRules = yield call(api.app.marketRules, action.payload.marketId);
    yield put(
      successHomeMarketRules({ sportId: action.payload.sportId, marketId: action.payload.marketId, rules: response })
    );
  } catch (error: any) {
    yield put(failureHomeMarketRules(error.data));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchHomeMarketsData.type, getHomeMarketsData),
    takeEvery(fetchHomeMarketRules.type, getHomeMarketRules)
  ]);
}
