import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import CashOutRulesModal from 'components/CashOutRulesModal';
import useDevice from 'hooks/useDevice';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { deleteAsianBetSlipAutoCashOut } from 'redux/modules/asianViewBetSlipCashOut';
import {
  getAsianBetSlipAutoCashOutByMarketId,
  getAsianBetSlipCashOutPossibleProfitByMarketId
} from 'redux/modules/asianViewBetSlipCashOut/selectors';
import { deleteAsianViewAutoCashOut } from 'redux/modules/asianViewCashOut';
import {
  getAsianViewAutoCashOutByMarketId,
  getAsianViewCashOutPossibleProfitByMarketId
} from 'redux/modules/asianViewCashOut/selectors';
import { deleteAutoCashOut } from 'redux/modules/cashOut';
import { getAutoCashOut, getAutoCashOutProfitDataByMarketId } from 'redux/modules/cashOut/selectors';
import { deleteEventAutoCashOut } from 'redux/modules/eventCashOut';
import {
  getEventAutoCashOutByMarketId,
  getEventCashOutPossibleProfitByMarketId
} from 'redux/modules/eventCashOut/selectors';
import { PagesFromBackend } from 'redux/modules/pages/types';

import styles from './styles.module.scss';

interface IExistingCashOutState {
  marketId: string;
  isAsianBetSlip?: boolean;
  isEvent?: boolean;
  isAVMobileCashOutPage?: boolean;
  isAsianView?: boolean;
  isAVMobileBetList?: boolean;
  ignoreFlexibleDisplayFormat?: boolean;
}

const ExistingCashOutState = ({
  marketId,
  isAsianBetSlip = false,
  isEvent = false,
  isAVMobileCashOutPage = false,
  isAsianView = false,
  isAVMobileBetList = false,
  ignoreFlexibleDisplayFormat = false
}: IExistingCashOutState) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const autoCashOutSelector = isAsianBetSlip
    ? getAsianBetSlipAutoCashOutByMarketId
    : isEvent
    ? getEventAutoCashOutByMarketId
    : isAsianView
    ? getAsianViewAutoCashOutByMarketId
    : getAutoCashOut;
  const profitSelector = isAsianBetSlip
    ? getAsianBetSlipCashOutPossibleProfitByMarketId
    : isEvent
    ? getEventCashOutPossibleProfitByMarketId
    : isAsianView
    ? getAsianViewCashOutPossibleProfitByMarketId
    : getAutoCashOutProfitDataByMarketId;

  const autoCashOut = useSelector(autoCashOutSelector(marketId));
  const profit = useSelector(profitSelector(marketId));

  const [isModalOpened, setIsModalOpened] = useState(false);

  const { formattedAmount: formattedProfit } = useFormatCurrency(autoCashOut?.profit || profit?.value || 0, '', {
    noRounding: true,
    isCheckIndian: true,
    ignoreFlexibleDisplayFormat
  });
  const { isDesktop, isMobile } = useDevice();

  const isAsianMobile = isAVMobileCashOutPage || isAVMobileBetList || (isEvent && isAsianView && isMobile);

  const handleRemoveAutoCashOut = () => {
    if (isAsianBetSlip) {
      dispatch(deleteAsianBetSlipAutoCashOut({ marketId, id: autoCashOut.id }));
    } else if (isEvent) {
      dispatch(deleteEventAutoCashOut({ marketId, id: autoCashOut.id }));
    } else if (isAsianView) {
      dispatch(deleteAsianViewAutoCashOut({ marketId, id: autoCashOut.id }));
    } else {
      dispatch(deleteAutoCashOut({ marketId, id: autoCashOut.id }));
    }
  };

  return (
    <>
      <div
        className={classNames(styles.main, {
          [styles.main__betslip]: isAsianBetSlip,
          [styles.coMobPage]: isAsianMobile
        })}
      >
        <span className={styles.profitWrapper}>
          <span>{t('cashout.page.labels.ifProfitReaches')}</span>
          <strong className={classNames('biab_cash-out-auto-value')}>{formattedProfit}</strong>
        </span>
        <div
          className={classNames(styles.main__wrapperBtn, {
            [styles.coMobPage__bottom]: isAsianMobile
          })}
        >
          <button
            onClick={handleRemoveAutoCashOut}
            className={classNames('biab_cash-out-auto-btn biab_remove-btn', styles.button, {
              [styles.main__betslipBtn]: isAsianBetSlip,
              [styles.coMobPage__removeBtn]: isAsianMobile
            })}
          >
            {t('cashout.page.button.removeAutoCashOut')}
          </button>
          {(isDesktop || isAsianMobile) && (
            <i
              onClick={() => setIsModalOpened(true)}
              className={classNames('fa fas fa-question-circle biab_help-icon', styles.icon)}
            />
          )}
        </div>
      </div>
      <CashOutRulesModal
        link={PagesFromBackend.AUTO_CASH_OUT}
        openModal={isModalOpened}
        setOpenModal={setIsModalOpened}
      />
    </>
  );
};

export default ExistingCashOutState;
