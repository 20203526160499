import { useEffect, useRef } from 'react';

import { NOT_DISPLAYING_STATUSES } from 'constants/marketsTable';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { MarketStatus } from 'types';

const useMarketStatusAndLockIcon = (bettingEnabled?: boolean, status?: MarketStatus) => {
  const { displayLocksForRestrictedMarkets } = useDeviceSettings();

  const marketClosedStatusRef = useRef<undefined | string>();

  const isBettingDisabled = typeof bettingEnabled === 'boolean' && !bettingEnabled;
  const isOpeningSoonStatus = !displayLocksForRestrictedMarkets && isBettingDisabled && !!status;
  const displayStatus = !!status && !NOT_DISPLAYING_STATUSES.includes(status);
  const showLockIcon = displayLocksForRestrictedMarkets && isBettingDisabled;

  const isShowClosedStatusPopup = status && status !== marketClosedStatusRef.current;

  useEffect(() => {
    if (status && !marketClosedStatusRef.current) {
      marketClosedStatusRef.current = status;
    }
  }, [status]);

  return {
    showStatus: isOpeningSoonStatus || displayStatus,
    showClosedStatus: isShowClosedStatusPopup || isOpeningSoonStatus || displayStatus,
    status: !isOpeningSoonStatus ? status : '',
    showLockIcon,
    isOpeningSoonStatus,
    displayStatus
  };
};

export default useMarketStatusAndLockIcon;
