import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Checkbox from 'components/Checkbox';
import RadioButton from 'components/RadioButton';
import Tooltip from 'components/Tooltip';
import { GAMES_BASE_URL } from 'constants/locations';
import { BETSLIP_CONFIRM_CHECKBOX } from 'constants/tooltip';
import useImperativeTooltip from 'hooks/useImperativeTooltip';
import usePlacementSetting from 'hooks/usePlacementSetting';
import {
  getDesktopSettingsConfirmBetsBeforePlace,
  getDesktopSettingsFillOrKill,
  getDesktopSettingsInlineBetting,
  getDesktopSettingsPlaceBetWithEnterKey,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { setSettings } from 'redux/modules/user';
import {
  getAccountSettingsConfirmBetsBeforePlacement,
  getIsUserAccountSettings,
  getUserAccountSettingsAsianViewAutoRefresh,
  getUserAccountSettingsFillOrKillEnabled,
  getUserAccountSettingsPlaceBetWithEnterKey
} from 'redux/modules/user/selectors';
import { SettingsBettingCheckbox, SettingsBettingInlinePlacementType } from 'types/navigation';

import styles from './DesktopSettingsPreferences.module.scss';

const DesktopSettingsPreferences = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const { tooltipRef, tooltipHtml, tooltipMouseEvents, tooltipAnchorId } =
    useImperativeTooltip(BETSLIP_CONFIRM_CHECKBOX);
  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const confirmBetsBeforePlacement = useSelector(getAccountSettingsConfirmBetsBeforePlacement);
  const placeBetWithEnterKeySetting = useSelector(getUserAccountSettingsPlaceBetWithEnterKey);
  const asianViewAutoRefresh = useSelector(getUserAccountSettingsAsianViewAutoRefresh);
  const fillOrKill = useSelector(getDesktopSettingsFillOrKill);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const confirmBetsBeforePlace = useSelector(getDesktopSettingsConfirmBetsBeforePlace);
  const placeBetWithEnterKey = useSelector(getDesktopSettingsPlaceBetWithEnterKey);
  const fillOrKillEnabled = useSelector(getUserAccountSettingsFillOrKillEnabled);
  const inlineBetting = useSelector(getDesktopSettingsInlineBetting);

  const [checkboxesValues, setCheckboxesValues] = useState<
    Record<Exclude<SettingsBettingCheckbox, SettingsBettingCheckbox.DEFAULT_STAKE>, boolean>
  >({
    [SettingsBettingCheckbox.CONFIRM_BETS_FOR_PLACEMENT]: confirmBetsBeforePlacement,
    [SettingsBettingCheckbox.PLACE_BET_WITH_ENTER_KEY]: placeBetWithEnterKeySetting,
    [SettingsBettingCheckbox.AUTO_REFRESH]: asianViewAutoRefresh,
    [SettingsBettingCheckbox.FILL_OR_KILL]: fillOrKillEnabled
  });

  const { inlinePlacementType, handleChangePlacementType } = usePlacementSetting();

  const isGamesPage = location.pathname.includes(GAMES_BASE_URL);

  const confirmCheckboxId = 'betslipConfirmCheckbox';

  const checkboxes = useMemo(() => {
    const checkboxesList: {
      isVisible: boolean;
      label: string;
      name: Exclude<SettingsBettingCheckbox, SettingsBettingCheckbox.DEFAULT_STAKE>;
      id: string;
      checked: boolean;
    }[] = [];

    checkboxesList.push({
      isVisible: confirmBetsBeforePlace,
      label: 'betslip.actions.confirmBets',
      name: SettingsBettingCheckbox.CONFIRM_BETS_FOR_PLACEMENT,
      id: confirmCheckboxId,
      checked: !!checkboxesValues?.confirmBetsBeforePlacement
    });

    checkboxesList.push({
      isVisible: placeBetWithEnterKey,
      label: 'betslip.actions.placeBetWithEnter',
      name: SettingsBettingCheckbox.PLACE_BET_WITH_ENTER_KEY,
      id: 'placeBetWithEnterKeyCheckbox',
      checked: !!checkboxesValues?.placeBetWithEnterKey
    });

    if (isPNCEnabled && !isGamesPage) {
      checkboxesList.push({
        isVisible: true,
        label: 'asianView.labels.betslip.autoRefresh',
        name: SettingsBettingCheckbox.AUTO_REFRESH,
        id: 'autoRefreshCheckbox',
        checked: !!checkboxesValues?.asianViewAutoRefresh
      });
    }

    checkboxesList.push({
      isVisible: fillOrKill && !isPNCEnabled,
      label: 'account.settings.fillOrKill.enable',
      name: SettingsBettingCheckbox.FILL_OR_KILL,
      id: 'fillOrKillEnabled',
      checked: !!checkboxesValues?.fillOrKillEnabled
    });

    return checkboxesList;
  }, [placeBetWithEnterKey, fillOrKill, confirmBetsBeforePlace, checkboxesValues, isPNCEnabled, isGamesPage]);

  const handleChangeCheckbox =
    (name: Exclude<SettingsBettingCheckbox, SettingsBettingCheckbox.DEFAULT_STAKE>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setCheckboxesValues(prevState => {
        return {
          ...prevState,
          [name]: event.target.checked
        };
      });

      if (isAccountSettings) {
        dispatch(
          setSettings({
            settings: {
              [name]: event.target.checked
            }
          })
        );
      }
    };

  return (
    <>
      <h4 className={styles.preferences__title}>{t('account.settings.preferences.title')}</h4>
      {inlineBetting && (
        <>
          <p className={styles.preferences__subtitle}>{t('account.settings.preferences.betPlacingUsing')}:</p>
          <RadioButton
            name="placement"
            value="betslip"
            checked={inlinePlacementType === SettingsBettingInlinePlacementType.BETSLIP}
            onChange={() => handleChangePlacementType(SettingsBettingInlinePlacementType.BETSLIP)}
            className={styles.preferences__radio__wrapper}
            labelClassName={styles.preferences__radio__label}
          >
            {t('account.settings.preferences.regularBetslip')}
          </RadioButton>
          <RadioButton
            name="placement"
            value="middleSection"
            checked={inlinePlacementType === SettingsBettingInlinePlacementType.MIDDLE_SECTION}
            onChange={() => handleChangePlacementType(SettingsBettingInlinePlacementType.MIDDLE_SECTION)}
            className={styles.preferences__radio__wrapper}
            labelClassName={styles.preferences__radio__label}
          >
            {t('account.settings.preferences.middleSection')}
          </RadioButton>
        </>
      )}
      {checkboxes.some(({ isVisible }) => isVisible) && (
        <p className={styles.preferences__subtitle}>{t('account.settings.preferences.betslipSettings')}:</p>
      )}
      {checkboxes.map(({ isVisible, name, id, label, checked }) => {
        if (!isVisible) {
          return null;
        }

        return (
          <Checkbox
            key={id}
            id={id === confirmCheckboxId ? tooltipAnchorId : id}
            checked={checked}
            className={styles.preferences__checkbox__wrapper}
            labelClassName={styles.preferences__checkbox__label}
            onChange={handleChangeCheckbox(name)}
            {...(id === confirmCheckboxId && { ...tooltipMouseEvents })}
          >
            {t(label)}
            {id === confirmCheckboxId && (
              <Tooltip ref={tooltipRef} anchorSelect={`#${tooltipAnchorId}`} html={tooltipHtml} imperativeModeOnly />
            )}
          </Checkbox>
        );
      })}
    </>
  );
};

export default DesktopSettingsPreferences;
