import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { QUICK_BETTING_PLACED_BET_LOADING } from 'constants/asianView';
import { setActivePlacedBetIndex, setIsPlacedBetsLoading } from 'redux/modules/asianViewQuickBetting';
import {
  getAsianActiveQuickPlacedBet,
  getAsianIsPlacedBetsLoading
} from 'redux/modules/asianViewQuickBetting/selectors';

import PlacedQuickBet from '../PlacedQuickBet';

import styles from './styles.module.scss';

const PlacedQuickBetPopover = () => {
  const dispatch = useDispatch();

  const quickBet = useSelector(getAsianActiveQuickPlacedBet);
  const isLoading = useSelector(getAsianIsPlacedBetsLoading);

  const loadingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (isLoading) {
      if (loadingTimeout.current) {
        clearTimeout(loadingTimeout.current);
      }

      loadingTimeout.current = setTimeout(() => {
        dispatch(setActivePlacedBetIndex(0));
        dispatch(setIsPlacedBetsLoading(false));
      }, QUICK_BETTING_PLACED_BET_LOADING);
    }

    return () => {
      if (loadingTimeout.current) {
        clearTimeout(loadingTimeout.current);
      }
    };
  }, [isLoading]);

  return (
    <div className={styles.overlay}>
      <PlacedQuickBet quickBet={quickBet} />
    </div>
  );
};

export default PlacedQuickBetPopover;
