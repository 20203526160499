import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import Pagination from 'components/Pagination';
import ReloadBtn from 'components/ReloadBtn';
import { ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM } from 'constants/asianView';
import { asianViewMiddleSection as branding } from 'constants/branding';
import { ASIAN_BASE_URL } from 'constants/locations';
import useAsianCashOut from 'hooks/useAsianCashOut';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import usePagination from 'hooks/usePagination';
import {
  getDesktopCashoutPageSize,
  getIsPropertiesLoaded,
  getLanguage,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import { setScrollUpdate } from 'redux/modules/appSettings';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { fetchAsianViewCashOutMarkets, resetAsianViewCashOut } from 'redux/modules/asianViewCashOut';
import {
  getAsianViewCashOutIsFirstMarketsLoaded,
  getAsianViewCashOutMarketsLoading,
  getAsianViewCashOutMarketsTotalElements,
  getAsianViewCashOutMarketsTotalPages,
  getAsianViewCashOutPaginationLoading,
  getAsianViewCashOutQuotesLength,
  getIsAsianViewCashOutFirstQuotesLoaded,
  getIsAsianViewCashOutMarketsContent,
  getIsAsianViewCashOutMarketsLastPage
} from 'redux/modules/asianViewCashOut/selectors';
import { getAsianViewCashOutMarketIds } from 'redux/modules/asianViewCashOutCounter/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchEventCashOutMarkets, resetEventCashOut } from 'redux/modules/eventCashOut';
import {
  getEventCashOutIsFirstMarketsLoaded,
  getEventCashOutIsLastPage,
  getEventCashOutMarketsLoading,
  getEventCashOutMarketsTotalElements,
  getEventCashOutMarketsTotalPages,
  getEventCashOutPaginationLoading,
  getEventCashOutQuotesLength,
  getIsEventCashOutFirstQuotesLoaded,
  getIsEventCashOutMarketsContent
} from 'redux/modules/eventCashOut/selectors';

import AsianCashOutPageHeader from './components/AsianCashOutPageHeader';
import CashOutSectionIntervalRequestsInjection from './components/CashOutSectionIntervalRequestsInjection';
import CashOutSkeleton from './components/CashOutSkeleton';
import DesktopCashOutList from './components/DesktopCashOutList';
import EventCashOutList from './components/EventCashOutList';
import MobileCashOutList from './components/MobileCashOutList';

import styles from './styles.module.scss';

type CashOutSectionProps = {
  isTab?: boolean;
  eventId?: string;
  sportId?: string;
};

const CashOutSection = ({ isTab, eventId, sportId }: CashOutSectionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const isContent = useSelector(isTab ? getIsEventCashOutMarketsContent : getIsAsianViewCashOutMarketsContent);
  const totalPages = useSelector(isTab ? getEventCashOutMarketsTotalPages : getAsianViewCashOutMarketsTotalPages);
  const totalElements = useSelector(
    isTab ? getEventCashOutMarketsTotalElements : getAsianViewCashOutMarketsTotalElements
  );
  const isFirstLoaded = useSelector(
    isTab ? getEventCashOutIsFirstMarketsLoaded : getAsianViewCashOutIsFirstMarketsLoaded
  );
  const loading = useSelector(isTab ? getEventCashOutMarketsLoading : getAsianViewCashOutMarketsLoading);
  const isLast = useSelector(isTab ? getEventCashOutIsLastPage : getIsAsianViewCashOutMarketsLastPage);
  const paginationLoader = useSelector(isTab ? getEventCashOutPaginationLoading : getAsianViewCashOutPaginationLoading);
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const desktopCashOutPageSize = useSelector(getDesktopCashoutPageSize);
  const isMobile = useSelector(getIsMobileAsianView);
  const quotesCount = useSelector(isTab ? getEventCashOutQuotesLength : getAsianViewCashOutQuotesLength);
  const isFirstQuotesLoaded = useSelector(
    isTab ? getIsEventCashOutFirstQuotesLoaded : getIsAsianViewCashOutFirstQuotesLoaded
  );
  const cashOutMarketIds = useSelector(getAsianViewCashOutMarketIds(sportId, eventId));

  const [page, setPage] = useState(0);

  const isAsianCashOutEnabled = useAsianCashOut();
  const { asianViewCashOutPaginationButtons } = useDeviceSettings();

  const handleFetchCashOutMarkets = (nextPage: number, keepPrev?: boolean) => {
    if (isLoggedIn) {
      if (isTab && eventId && sportId) {
        dispatch(
          fetchEventCashOutMarkets({
            page: nextPage,
            size: desktopCashOutPageSize,
            sportId,
            withLoader: true,
            resetPrev: !keepPrev,
            eventId,
            isPaginationEnabled: true,
            resetSettingsTabs: true
          })
        );
      } else {
        dispatch(
          fetchAsianViewCashOutMarkets({
            page: nextPage,
            size: desktopCashOutPageSize,
            withLoader: true,
            resetPrev: !keepPrev,
            isPaginationEnabled: true,
            resetSettingsTabs: true
          })
        );
      }
    }

    dispatch(setScrollUpdate({ top: 0, trigger: true, offset: 0 }));
  };

  const {
    page: paginationPage,
    onClickLastPage,
    onClickNextPage,
    onClickPrevPage,
    onClickFirstPage,
    onClickPage,
    changePage
  } = usePagination({
    onChangePage: handleFetchCashOutMarkets,
    totalPages,
    isPaginationEnabled: asianViewCashOutPaginationButtons,
    pageParamName: isTab ? ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM : undefined,
    resetPageOnFirstRender: isTab
  });

  const infiniteScrollCallback = useCallback(() => {
    if (isLoggedIn && !loading && !isLast && !asianViewCashOutPaginationButtons && isLoggedIn) {
      setPage(prevState => prevState + 1);
    }
  }, [loading, isLast, asianViewCashOutPaginationButtons]);

  const { lastElementRef } = useInfiniteScroll<HTMLDivElement>({ callback: infiniteScrollCallback });

  const currentPage = asianViewCashOutPaginationButtons ? paginationPage : page;

  useEffect(() => {
    const diff = cashOutMarketIds.length - totalElements;
    if (diff > 0 && totalElements) {
      handleFetchCashOutMarkets(0, true);
    }
  }, [cashOutMarketIds, totalElements]);

  useEffect(() => {
    if (!isAsianCashOutEnabled) {
      navigate(ASIAN_BASE_URL);
    }
  }, [isAsianCashOutEnabled]);

  useEffect(() => {
    if (isLoggedIn && isPropertiesLoaded) {
      if (isTab && eventId && sportId) {
        dispatch(
          fetchEventCashOutMarkets({
            page: currentPage,
            sportId,
            size: desktopCashOutPageSize,
            withLoader: true,
            isPaginationEnabled: asianViewCashOutPaginationButtons,
            changePage,
            eventId,
            resetSettingsTabs: true
          })
        );
      } else {
        dispatch(
          fetchAsianViewCashOutMarkets({
            page: currentPage,
            size: desktopCashOutPageSize,
            withLoader: true,
            isPaginationEnabled: asianViewCashOutPaginationButtons,
            changePage,
            resetSettingsTabs: true
          })
        );
      }
    }
  }, [isPropertiesLoaded, isLoggedIn, desktopCashOutPageSize, language, timezone, asianViewCashOutPaginationButtons]);

  useEffect(() => {
    if (isLoggedIn && page !== 0 && !isLast && !asianViewCashOutPaginationButtons) {
      if (eventId && isTab && sportId) {
        dispatch(
          fetchEventCashOutMarkets({
            page,
            size: desktopCashOutPageSize,
            sportId,
            isPaginationEnabled: false,
            eventId
          })
        );
      } else {
        dispatch(
          fetchAsianViewCashOutMarkets({
            page,
            size: desktopCashOutPageSize,
            isPaginationEnabled: false
          })
        );
      }
    }
  }, [page, isLast]);

  useEffect(() => {
    return () => {
      if (isTab) {
        dispatch(resetEventCashOut());
      } else {
        dispatch(resetAsianViewCashOut());
      }
    };
  }, []);

  const handleReload = () => {
    setPage(0);
  };

  const renderCashOuts = () => {
    if (isTab && eventId) {
      return <EventCashOutList eventId={eventId} onReload={handleReload} />;
    }

    if (isMobile) {
      return <MobileCashOutList />;
    }

    return <DesktopCashOutList />;
  };

  if ((!isFirstLoaded || paginationLoader) && isLoggedIn) {
    return (
      <div>
        {!isTab && <AsianCashOutPageHeader onReload={handleReload} />}
        {isTab && (
          <ReloadBtn
            onReload={handleReload}
            isShowTitle
            containerClassName={classNames(styles.reloadBtn, branding.COUPON_BUTTON, {
              [styles.tabReloadBtn__mobile]: isMobile
            })}
            labelClassName={classNames({ [styles.tabReloadBtn__mobile__label]: isMobile })}
            iconClassName={classNames({ [styles.tabReloadBtn__mobile__icon]: isMobile })}
            eventId={eventId}
          />
        )}
        <CashOutSkeleton />
      </div>
    );
  }

  return (
    <div>
      <CashOutSectionIntervalRequestsInjection isTab={isTab} />
      {!isTab && <AsianCashOutPageHeader onReload={handleReload} />}
      {!isLoggedIn || (!isContent && !(loading || paginationLoader)) || (!quotesCount && isFirstQuotesLoaded) ? (
        <>
          {isTab && isLoggedIn && (
            <ReloadBtn
              isShowTitle
              containerClassName={classNames(styles.reloadBtn, styles.tabReloadBtn, branding.COUPON_BUTTON, {
                [styles.tabReloadBtn__mobile]: isMobile
              })}
              eventId={eventId}
              labelClassName={classNames({ [styles.tabReloadBtn__mobile__label]: isMobile })}
              iconClassName={classNames({ [styles.tabReloadBtn__mobile__icon]: isMobile })}
              onReload={handleReload}
            />
          )}
          <div
            className={classNames(styles.containerEmpty, {
              [styles.containerEmpty__mobile]: isMobile
            })}
          >
            <p
              className={classNames(styles.containerEmpty__text, {
                [styles.containerEmpty__text__mobile]: isMobile
              })}
            >
              {t('cashout.page.labels.empty')}
            </p>
          </div>
        </>
      ) : (
        <div className={classNames({ [styles.cashOut__mobile]: isMobile && !isTab })}>
          {!paginationLoader && renderCashOuts()}
          {paginationLoader ? (
            <CashOutSkeleton />
          ) : (
            !asianViewCashOutPaginationButtons && isContent && <div ref={lastElementRef} />
          )}
          {asianViewCashOutPaginationButtons &&
            totalElements > desktopCashOutPageSize &&
            !paginationLoader &&
            totalElements > 0 && (
              <Pagination
                page={paginationPage}
                totalPages={totalPages}
                onClickFirstPage={onClickFirstPage}
                onClickLastPage={onClickLastPage}
                onClickNextPage={onClickNextPage}
                onClickPage={onClickPage}
                onClickPrevPage={onClickPrevPage}
              />
            )}
        </div>
      )}
    </div>
  );
};

export default CashOutSection;
