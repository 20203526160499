import classNames from 'classnames';

import styles from './styles.module.scss';

interface WidgetTitleProps {
  isMobile?: boolean;
}

// TODO we don't have translation for "Featured Markets"
const WidgetTitle = ({ isMobile }: WidgetTitleProps) => {
  return (
    <div className={classNames('biab_featured-market-title', styles.featureMarket__headerContainer)}>
      <p
        className={classNames('biab_featured_market-title-name', styles.featureMarket__title, {
          [styles.featureMarket__headerMobile]: isMobile
        })}
      >
        Featured Markets
      </p>
    </div>
  );
};

export default WidgetTitle;
