import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsAmericanDateFormatEnabled } from 'redux/modules/appConfigs/selectors';

interface ISportSectionTitle {
  parsedStartTime: string;
}

const SportSectionTitle = ({ parsedStartTime }: ISportSectionTitle) => {
  const { t } = useTranslation();

  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);

  const [weekDay, month, day] = parsedStartTime.split('-');

  return (
    <span>
      {americanDateFormatEnabled
        ? `${t(`dates.shortDay.${weekDay}`)} ${t(`dates.shortMonth.${month}`)} ${day}`
        : `${t(`dates.shortDay.${weekDay}`)} ${day} ${t(`dates.shortMonth.${month}`)}`}
    </span>
  );
};

export default SportSectionTitle;
