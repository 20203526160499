import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';

import { TNextRace, TNextRaces } from './type';

const initialState: TNextRaces = {
  races: [],
  loading: false,
  error: null,
  stringifiedRaces: ''
};

const slice = createSlice({
  name: SLICES_NAMES.NEXT_RACES,
  initialState,
  reducers: {
    fetchNextRaces: state => {
      state.loading = true;
    },
    successGetNextRaces: (state, { payload }: PayloadAction<TNextRace[]>) => {
      state.loading = false;

      const stringifiedRacesFromResponse = JSON.stringify(payload);

      if (state.stringifiedRaces !== stringifiedRacesFromResponse) {
        state.races = payload;
        state.stringifiedRaces = stringifiedRacesFromResponse;
      }
    },
    failureGetNextRaces: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export const { fetchNextRaces, successGetNextRaces, failureGetNextRaces } = slice.actions;

export default slice.reducer;
