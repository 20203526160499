import { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { KEY_CODES } from 'constants/betslip';
import { componentsBranding } from 'constants/branding';
import { useCurrency } from 'hooks/useCurrency';
import { useStakeRegexPattern } from 'hooks/usePrecision';
import { getCurrency } from 'redux/modules/appConfigs/selectors';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { TBetslipProfitChangeProps, TSizeValidation } from 'redux/modules/betslip/type';
import { BetTypes, TProfit, TSize } from 'types/bets';
import { EInputFieldTypes } from 'types/betslip';
import { validateSize } from 'utils/size';

import styles from './styles.module.scss';

type TBetProfitProps = {
  betUuid?: string;
  betProfit: TProfit;
  betSize: TSize;
  betType: BetTypes;
  currencyCode?: string;
  fieldOrder?: number;
  onChanged: ({ profit, size, isValid, errorMessage, focusedField }: TBetslipProfitChangeProps) => void;
  onEnterClick: () => void;
  onTabNavigate?: (fieldType: EInputFieldTypes) => void | null;
  label?: string;
  customClassName?: string;
  isReadOnly?: boolean;
  isFocus?: boolean;
};

const BetProfit = ({
  betUuid,
  betProfit,
  betSize,
  betType,
  currencyCode,
  fieldOrder = 0,
  onChanged,
  onEnterClick,
  onTabNavigate,
  label,
  customClassName = '',
  isReadOnly,
  isFocus
}: TBetProfitProps) => {
  const { t } = useTranslation();

  const currency = useCurrency(currencyCode);
  const defaultCurrency = useSelector(getCurrency);
  const isGameBetSlip = useSelector(getIsGameBetSlip);

  const pattern = useStakeRegexPattern();

  const inputId = `${betUuid}_PROFIT`;

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isFocus && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [isFocus, inputRef.current]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const changedValue = event.target.value
      .trim()
      .replace(/[^0-9.]/g, '')
      .replace(/\.\./g, '.');

    if (changedValue === '' || pattern.test(changedValue)) {
      onChanged({ profit: changedValue });
    }
  };

  const keyDownHandler = (e: KeyboardEvent) => {
    if (e.key === KEY_CODES.ENTER) {
      e.preventDefault();
      onEnterClick();
    } else if (e.key === KEY_CODES.TAB && onTabNavigate) {
      e.preventDefault();
      onTabNavigate(EInputFieldTypes.PROFIT);
    }
  };

  const onBlurHandler = () => {
    const validation: TSizeValidation = validateSize({
      size: betSize || (betProfit ? 0 : ''),
      betType,
      currency,
      defaultCurrency,
      currencyCode,
      isOperatorBettingLimitsEnabled: false,
      isGame: isGameBetSlip
    });

    onChanged({
      size: validation.validValue,
      isValid: !!validation.validValue,
      ...(validation.errorMessage
        ? { errorMessage: t(validation.errorMessage.text, validation.errorMessage.params ?? {}) }
        : {}),
      focusedField: null
    });
  };

  return (
    <div className={styles.container}>
      <input
        id={inputId}
        className={classNames(styles.inputField, customClassName, componentsBranding.FORM_INPUT)}
        type="text"
        value={betProfit ?? ''}
        onChange={changeHandler}
        onKeyDown={keyDownHandler}
        onBlur={onBlurHandler}
        data-field-type={EInputFieldTypes.PROFIT}
        tabIndex={fieldOrder}
        placeholder=""
        readOnly={isReadOnly}
        ref={inputRef}
      />
      {!!label && (
        <label className={styles.inputField__label} htmlFor={inputId}>
          {label}
        </label>
      )}
    </div>
  );
};

export default BetProfit;
