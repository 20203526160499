import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { IN_PLAY_DEFAULT_FILTERS } from 'constants/inPlay';
import { IN_PLAY_BASE_URL } from 'constants/locations';
import { getAppDevice, getInPlayPaginationSize, getTranslation } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { fetchInPlayMarkets, fetchInPlaySports } from 'redux/modules/inPlay';
import { getInPlaySports } from 'redux/modules/inPlay/selectors';
import { ViewBy } from 'types';
import { InPlayFilter } from 'types/inPlay';

import styles from './styles.module.scss';

interface InPlayFiltersProps {
  /**
   * 'View by' sort value
   */
  viewBy: ViewBy;

  /**
   * Current page for infinite scroll
   */
  page: number;

  /**
   * Set state action for current page
   */
  setPage: Dispatch<SetStateAction<number>>;
}

const InPlayFilters = ({ viewBy, page, setPage }: InPlayFiltersProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { filter } = useParams();

  const inPlaySportList = useSelector(getInPlaySports);
  const device = useSelector(getAppDevice);
  const translation = useSelector(getTranslation);
  const inPlayPaginationSize = useSelector(getInPlayPaginationSize);

  const filtersRef = useRef<HTMLDivElement>(null);

  const sportIds = inPlaySportList?.map(sport => sport.id) ?? [];
  const filtersList = IN_PLAY_DEFAULT_FILTERS.concat(sportIds);

  useEffect(() => {
    dispatch(fetchInPlaySports());
  }, []);

  useEffect(() => {
    if (filtersRef.current?.children && device === Devices.MOBILE) {
      const allFilters = filtersRef.current.children;

      const active = Array.from(allFilters).find(element => {
        return element.classList.contains('biab_active');
      });

      active?.scrollIntoView({ inline: 'end', block: 'nearest' });
    }
  }, [filtersRef.current?.children, inPlaySportList, filter, device]);

  const handleChangeFilter = (newFilter: string) => {
    if (newFilter !== filter) {
      if (page !== 0) {
        setPage(0);
      }

      const tab = newFilter === InPlayFilter.HIGHLIGHTS ? InPlayFilter.HIGHLIGHTS : InPlayFilter.NOW;
      const sportIdObj = tab === InPlayFilter.NOW && newFilter !== InPlayFilter.ALL ? { sportId: newFilter } : {};

      dispatch(
        fetchInPlayMarkets({
          id: tab,
          page: 0,
          size: inPlayPaginationSize,
          payload: { from: new Date().getTime(), viewBy, ...sportIdObj }
        })
      );
    }
  };

  return (
    <div className={classNames('biab_inplay-tabs-list', styles.filters)}>
      {device === Devices.DESKTOP && (
        <h2 className={classNames('biab_inplay-title', styles.filters__title)}>{t('inplay.labels.inPlay')}</h2>
      )}
      <div role="tablist" className={styles.filters__list} ref={filtersRef}>
        {filtersList.map(filterItem => {
          let label: string;

          if (filterItem === InPlayFilter.ALL || filterItem === InPlayFilter.HIGHLIGHTS) {
            label = t(`inplay.labels.${filterItem}`);
          } else {
            const sport = inPlaySportList?.find(sportItem => sportItem.id === filterItem);
            label = t(sport?.translations?.[translation] ?? '');
          }

          return (
            <NavLink
              key={filterItem}
              to={`${IN_PLAY_BASE_URL}/${filterItem}`}
              replace
              end
              className={({ isActive }) =>
                classNames('biab_inplay-tabs-item', styles.filters__filter, {
                  biab_active: isActive,
                  [styles.filters__filter__active]: isActive
                })
              }
              onClick={() => handleChangeFilter(filterItem)}
            >
              {label}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default InPlayFilters;
