import { DropdownItem, ViewBy } from 'types';
import { IMarket } from 'types/markets';

import { applyTimezone } from './date';

export const parseMarketStartTime = (startTime: number, timezone: string, timezoneCookieEnabled: boolean) => {
  const dateTimezoneApplied = applyTimezone(new Date(startTime), timezone, timezoneCookieEnabled);

  const weekDay = dateTimezoneApplied.getDay();
  const month = dateTimezoneApplied.getMonth();
  const day = dateTimezoneApplied.getDate();
  const year = dateTimezoneApplied.getFullYear();

  return `${weekDay}-${month}-${day}-${year}`;
};

export const getTimeSections = (marketsWithParsedDate: IMarket[] | null) => {
  const dates = marketsWithParsedDate?.map(({ parsedMarketStartTime }) => String(parsedMarketStartTime));

  return Array.from(new Set(dates));
};

export const getCompetitionSections = (markets: IMarket[] | null) => {
  return Array.from(new Set(markets?.map(({ competition }) => competition?.name)));
};

export const getViewByOptions = (isPNCEnabled: boolean): DropdownItem[] => [
  {
    id: 'view-by-time',
    value: ViewBy.TIME,
    label: 'competition.view.filters.viewBy.time',
    isLabelTranslatable: true
  },
  {
    id: 'view-by-popularity',
    value: ViewBy.POPULARITY,
    label: `${isPNCEnabled ? 'pnc.' : ''}competition.view.filters.viewBy.matchedAmount`,
    isLabelTranslatable: true
  },
  {
    id: 'view-by-competition',
    value: ViewBy.COMPETITION,
    label: 'competition.view.filters.viewBy.competition',
    isLabelTranslatable: true
  }
];

export const getTableMarketsData = (markets: IMarket[], inPlayMarketIds: string[], index: number) => {
  if (index === 0) {
    return markets.filter(({ marketId }) => inPlayMarketIds.includes(marketId));
  }

  return markets.filter(({ marketId }) => !inPlayMarketIds.includes(marketId));
};
