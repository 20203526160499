import { useLocation } from 'react-router-dom';

import { ACCOUNT_BASE_URL } from 'constants/locations';
import { BetStatusesMap } from 'constants/myBets';

const useAccountUrlParams = () => {
  const { pathname } = useLocation();
  const pathnameParts = pathname.split(ACCOUNT_BASE_URL);
  const exchangePageType = pathnameParts[0];
  const selectedTab = pathnameParts[1]?.split('/')?.[1] || '';
  const urlWithoutPageIndex = pathname.substring(0, pathname.lastIndexOf('/')).replace(exchangePageType, '');

  const activeStatusTab = BetStatusesMap[urlWithoutPageIndex];

  return { exchangePageType, selectedTab, activeStatusTab };
};

export default useAccountUrlParams;
