import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import commonStyles from 'components/ResponsibleBetting/styles.module.scss';
import { ResponsibleBettingAlertTypes, ResponsibleBettingButtonTypes } from 'types/responsibleBetting';

import ResponsibleBettingAlert from '../ResponsibleBettingAlert';
import ResponsibleBettingBtn from '../ResponsibleBettingBtn';
import ResponsibleBettingCompareLimitField from '../ResponsibleBettingCompareLimitField';

interface ResponsibleBettingRemoveLimitProps {
  isMobile: boolean;
  onCancel: () => void;
  onApply: () => void;
  confirmationMessage: string | undefined;
  currentLimitValue: string;
}

const ResponsibleBettingRemoveLimit = ({
  isMobile,
  onCancel,
  onApply,
  confirmationMessage,
  currentLimitValue
}: ResponsibleBettingRemoveLimitProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(commonStyles.content, {
        [commonStyles.content__mobile]: isMobile
      })}
    >
      <div
        className={classNames(commonStyles.sectionContainer, {
          [commonStyles.sectionContainer__mobile]: isMobile
        })}
      >
        <ResponsibleBettingCompareLimitField
          title={t('responsibleBetting.labels.currentLimit')}
          value={currentLimitValue}
          isMobile={isMobile}
        />
        <i
          className={classNames('fa2', 'fa2-caret-icon', 'biab_fav-manage-icon', commonStyles.sectionIcon, {
            [commonStyles.sectionIcon__mobile]: isMobile
          })}
        />
        <ResponsibleBettingCompareLimitField value={t('responsibleBetting.labels.limitRemoved')} isMobile={isMobile} />
      </div>
      {confirmationMessage && (
        <div className={commonStyles.sectionUpdateMessageAlert}>
          <ResponsibleBettingAlert type={ResponsibleBettingAlertTypes.Info} description={confirmationMessage} />
        </div>
      )}
      <div
        className={classNames(commonStyles.sectionContainer, {
          [commonStyles.sectionContainer__mobile_reverse]: isMobile,
          [commonStyles.sectionContainer__no_margin]: !isMobile
        })}
      >
        <span
          className={classNames(commonStyles.sectionLabel, {
            [commonStyles.sectionLabel__mobile]: isMobile
          })}
        >
          {t('responsibleBetting.messages.confirmDecision')}
        </span>
        <div
          className={classNames(commonStyles.sectionControllers, commonStyles.sectionControllers__applied, {
            [commonStyles.sectionControllers__mobile]: isMobile
          })}
        >
          <ResponsibleBettingBtn
            label={t('responsibleBetting.buttons.back')}
            onClick={onCancel}
            isMobile={isMobile}
            type={ResponsibleBettingButtonTypes.Secondary}
          />
          <ResponsibleBettingBtn
            label={t('responsibleBetting.buttons.apply')}
            onClick={onApply}
            isMobile={isMobile}
            type={ResponsibleBettingButtonTypes.Applied}
          />
        </div>
      </div>
    </div>
  );
};

export default ResponsibleBettingRemoveLimit;
