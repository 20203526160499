import { useSelector } from 'react-redux';

import { getAVBalance } from 'redux/modules/user/selectors';

import AsianBalance from '../AsianBalance/AsianBalance';

/* This component is required because if move condition `if (!balance) { return null }` to AsianBalance height of root
  element will be calculated not correctly and will return 0
 */

const AsianBalanceWrapper = () => {
  const balance = useSelector(getAVBalance);

  if (!balance) {
    return null;
  }

  return <AsianBalance />;
};

export default AsianBalanceWrapper;
