import { useSelector } from 'react-redux';

import { MARKET_TYPES } from 'constants/marketTypes';
import {
  getMarketPricesFirstKeyLineAdjustedHandicap,
  getMarketPricesFirstKeyLineHandicap,
  getMarketPricesSecondKeyLineAdjustedHandicap,
  getMarketPricesSecondKeyLineHandicap
} from 'redux/modules/marketsPrices/selectors';
import { THandicap } from 'types/bets';

const useAdjHandicap = ({
  marketId,
  marketType = '',
  handicap,
  runnerIndex
}: {
  marketId?: string;
  marketType?: string;
  handicap?: THandicap | null;
  runnerIndex?: number;
}) => {
  const asianHandicapFirstKeyLineHandicap = useSelector(getMarketPricesFirstKeyLineHandicap(marketId));
  const asianHandicapFirstKeyLineAdjHandicap = useSelector(getMarketPricesFirstKeyLineAdjustedHandicap(marketId));
  const asianHandicapSecondKeyLineHandicap = useSelector(getMarketPricesSecondKeyLineHandicap(marketId));
  const asianHandicapSecondKeyLineAdjHandicap = useSelector(getMarketPricesSecondKeyLineAdjustedHandicap(marketId));

  const isAsianHandicap = marketType === MARKET_TYPES.asianHandicap;

  if (!isAsianHandicap) {
    return null;
  }

  const handicapInt = +(handicap ?? 0);

  const ahc = runnerIndex === 0 ? asianHandicapFirstKeyLineAdjHandicap : asianHandicapSecondKeyLineAdjHandicap;

  let adjHandicap = handicapInt;

  if (ahc !== undefined && ahc !== null) {
    const handicapByIndex =
      (runnerIndex == 0 ? asianHandicapFirstKeyLineHandicap : asianHandicapSecondKeyLineHandicap) || 0;

    adjHandicap = handicapInt + (ahc - handicapByIndex);
  }

  return adjHandicap;
};

export default useAdjHandicap;
