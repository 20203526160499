import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { IMarketRules } from 'types/markets';

import {
  failureFetchFancyEventDetails,
  failureFetchFancyMarketRules,
  fetchFancyEventDetails,
  fetchFancyMarketRules,
  successFetchFancyEventDetails,
  successFetchFancyMarketRules
} from './index';
import { TFetchFancyEventDetailsResponse } from './type';

export function* getFancyEventDetails(action: ReturnType<typeof fetchFancyEventDetails>) {
  try {
    const response: TFetchFancyEventDetailsResponse = yield call(
      api.event.getFancyEventDetails,
      action.payload.eventId,
      action.payload.fancyMarketTypes
    );
    yield put(successFetchFancyEventDetails(response));
  } catch (error: any) {
    yield put(failureFetchFancyEventDetails(error.data));
  }
}

export function* getFancyMarketRules(action: ReturnType<typeof fetchFancyMarketRules>) {
  try {
    const response: IMarketRules = yield call(api.app.marketRules, action.payload);
    yield put(successFetchFancyMarketRules({ marketId: action.payload, rules: response }));
  } catch (error: any) {
    yield put(failureFetchFancyMarketRules(error.data));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchFancyEventDetails.type, getFancyEventDetails),
    takeLatest(fetchFancyMarketRules.type, getFancyMarketRules)
  ]);
}
