import { RefObject, useEffect } from 'react';

import { Handler } from 'types';

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler?: Handler,
  callback?: Handler
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const el = ref?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }

      if (handler) {
        handler(event); // Call the handler only if the click is outside of the element passed.
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    if (callback) {
      document.addEventListener('click', callback);
    }

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
      if (callback) {
        document.removeEventListener('click', callback);
      }
    };
  }, [ref, handler, callback]); // Reload only if ref or handler changes
};

export default useOnClickOutside;
