import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getExchangeGamesInterval, getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { fetchCurrentGameData } from 'redux/modules/games';
import { getCurrentGameDataLoading } from 'redux/modules/games/selectors';

type GamePageRequestIntervalInjectionProps = {
  isLandscape: boolean;
  isDesktop: boolean;
};

const GamePageRequestIntervalInjection = ({ isLandscape, isDesktop }: GamePageRequestIntervalInjectionProps) => {
  const dispatch = useDispatch();
  const { gameId = '' } = useParams();

  const loading = useSelector(getCurrentGameDataLoading);
  const exchangeGamesInterval = useSelector(getExchangeGamesInterval);
  const language = useSelector(getLanguage);
  const timezone = useSelector(getTimezone);

  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  useEffect(() => {
    if (gameId && (isLandscape || isDesktop)) {
      intervalRef.current = setInterval(() => {
        if (!loadingRef.current) {
          dispatch(fetchCurrentGameData(gameId));
        }
      }, exchangeGamesInterval || 3000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [gameId, exchangeGamesInterval, language, timezone, isDesktop, isLandscape]);

  return null;
};

export default GamePageRequestIntervalInjection;
