import { memo, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy, keys } from 'lodash';

import { AUTO_CASH_OUT_GET_MARKETS_INTERVAL, GeneralWebSocketSubscriptionTypes } from 'constants/app';
import { useDocumentVisible } from 'hooks/useDocumentVisible';
import {
  getAutoCashOutEnabled,
  getAutoCashOutWsEnabled,
  getCashOutGetQuotesInterval,
  getCashOutQuoteWsEnabled,
  getEventUpdatesWsEnabled,
  getGeneralWsEnabled,
  getIsPropertiesLoaded,
  getLanguage,
  getRunningBallInterval,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import {
  fetchAsianViewAutoCashOutMarkets,
  fetchAsianViewCashOutQuotes,
  resetAsianViewCashOut
} from 'redux/modules/asianViewCashOut';
import { getASianViewCashOutQuotesLoading } from 'redux/modules/asianViewCashOut/selectors';
import { getAsianMatchedCurrentBets } from 'redux/modules/asianViewCurrentBets/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchEventsUpdatedData } from 'redux/modules/marketsPrices';
import {
  getIsConnectedAsianViewGeneral,
  getSubscribeToAsianViewGeneralMessages
} from 'redux/modules/webSocket/selectors';

const AVBetListMobileIntervalsInjection = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isAutoCashOutEnabled = useSelector(getAutoCashOutEnabled);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const matchedCurrentBets = useSelector(getAsianMatchedCurrentBets);
  const quotesLoading = useSelector(getASianViewCashOutQuotesLoading);
  const autoCashOutWsEnabled = useSelector(getAutoCashOutWsEnabled);
  const cashOutQuoteWsEnabled = useSelector(getCashOutQuoteWsEnabled);
  const cashOutGetQuotesInterval = useSelector(getCashOutGetQuotesInterval);
  const isConnectedAsianViewGeneralWebSocket = useSelector(getIsConnectedAsianViewGeneral);
  const subscribeAsianViewGeneralWebSocketMessages = useSelector(getSubscribeToAsianViewGeneralMessages);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);
  const eventUpdatesWsEnabled = useSelector(getEventUpdatesWsEnabled);
  const runningBallInterval = useSelector(getRunningBallInterval);
  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);

  const eventUpdatesInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const fetchAutoCashOutMarketsInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const fetchCashOutQuotesInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const quotesLoadingRef = useRef(quotesLoading);
  const generalWsDataRef = useRef<{
    subscribeAsianViewGeneralWebSocketMessages: (<F>(params: F) => void) | null;
    shouldSubscribeToQuotes: boolean;
    shouldSubscribeToAutoCashOuts: boolean;
  }>({
    subscribeAsianViewGeneralWebSocketMessages,
    shouldSubscribeToQuotes: false,
    shouldSubscribeToAutoCashOuts: false
  });

  const isActiveBrowserTab = useDocumentVisible();

  quotesLoadingRef.current = quotesLoading;

  const stringifiedMatchedCurrentBets = JSON.stringify(matchedCurrentBets);
  const isSubscriptionAvailable =
    isConnectedAsianViewGeneralWebSocket && !!subscribeAsianViewGeneralWebSocketMessages && isLoggedIn;
  const shouldSubscribeToQuotes =
    isSubscriptionAvailable && cashOutQuoteWsEnabled && arePropertiesLoaded && generalWsEnabled;
  const shouldSubscribeToAutoCashOuts =
    isSubscriptionAvailable && autoCashOutWsEnabled && arePropertiesLoaded && generalWsEnabled;
  const isEventUpdatesIntervalAvailable = arePropertiesLoaded && (!generalWsEnabled || !eventUpdatesWsEnabled);

  generalWsDataRef.current = {
    subscribeAsianViewGeneralWebSocketMessages,
    shouldSubscribeToQuotes,
    shouldSubscribeToAutoCashOuts
  };

  const { quoteMarketIds, eventIds, autoCashOutMarketIds } = useMemo(() => {
    const quoteMarketIdsVal = Array.from(
      new Set(matchedCurrentBets.filter(({ cashOutEnabled }) => cashOutEnabled).map(({ marketId }) => marketId))
    );
    const autoCashOutMarketIdsVal = Array.from(
      new Set(matchedCurrentBets.filter(({ autoCashOutEnabled }) => autoCashOutEnabled).map(({ marketId }) => marketId))
    );

    const eventIdsVal = keys(
      groupBy(
        matchedCurrentBets.filter(({ isOutright, eventInPlay }) => !isOutright && eventInPlay),
        ({ eventId }) => eventId
      )
    );

    return { quoteMarketIds: quoteMarketIdsVal, eventIds: eventIdsVal, autoCashOutMarketIds: autoCashOutMarketIdsVal };
  }, [stringifiedMatchedCurrentBets]);

  const isQuoteIntervalAvailable =
    arePropertiesLoaded && (!generalWsEnabled || !cashOutQuoteWsEnabled) && isActiveBrowserTab;
  const isAutoCashOutIntervalAvailable =
    isAutoCashOutEnabled &&
    isLoggedIn &&
    arePropertiesLoaded &&
    (!generalWsEnabled || !autoCashOutWsEnabled) &&
    isActiveBrowserTab;

  useEffect(() => {
    if (shouldSubscribeToQuotes && quoteMarketIds.length) {
      subscribeAsianViewGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.cashOutQuote]: {
          subscribe: true,
          marketIds: quoteMarketIds,
          showOnlyAvailable: true
        }
      });
    }
  }, [shouldSubscribeToQuotes, quoteMarketIds]);

  useEffect(() => {
    if (shouldSubscribeToAutoCashOuts && autoCashOutMarketIds.length) {
      subscribeAsianViewGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.autoCashOut]: {
          subscribe: true,
          marketIds: autoCashOutMarketIds
        }
      });
    }
  }, [shouldSubscribeToAutoCashOuts, autoCashOutMarketIds]);

  useEffect(() => {
    if (isSubscriptionAvailable) {
      subscribeAsianViewGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.eventUpdates]: {
          subscribe: true,
          eventIds
        }
      });
    }
  }, [isSubscriptionAvailable, eventIds]);

  useEffect(() => {
    return () => {
      const {
        subscribeAsianViewGeneralWebSocketMessages: subscribeFunc,
        shouldSubscribeToAutoCashOuts: isAutoCashOutsAvailable,
        shouldSubscribeToQuotes: isQuotesAvailable
      } = generalWsDataRef.current;

      if (subscribeFunc && isQuotesAvailable) {
        subscribeFunc({
          [GeneralWebSocketSubscriptionTypes.cashOutQuote]: { subscribe: false }
        });
      }

      if (subscribeFunc && isAutoCashOutsAvailable) {
        subscribeFunc({
          [GeneralWebSocketSubscriptionTypes.autoCashOut]: { subscribe: false }
        });
      }

      if (subscribeFunc) {
        subscribeFunc({
          [GeneralWebSocketSubscriptionTypes.eventUpdates]: { subscribe: false }
        });
      }
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (quoteMarketIds.length && isQuoteIntervalAvailable) {
        dispatch(fetchAsianViewCashOutQuotes({ ids: quoteMarketIds, firstLoading: true }));

        fetchCashOutQuotesInterval.current = setInterval(() => {
          if (!quotesLoadingRef.current) {
            dispatch(fetchAsianViewCashOutQuotes({ ids: quoteMarketIds }));
          }
        }, cashOutGetQuotesInterval);
      }
    } else {
      dispatch(resetAsianViewCashOut());
    }

    return () => {
      if (fetchCashOutQuotesInterval.current) {
        clearInterval(fetchCashOutQuotesInterval.current);
      }
    };
  }, [quoteMarketIds, isLoggedIn, cashOutGetQuotesInterval, isQuoteIntervalAvailable]);

  useEffect(() => {
    if (autoCashOutMarketIds.length && isAutoCashOutIntervalAvailable) {
      dispatch(fetchAsianViewAutoCashOutMarkets(autoCashOutMarketIds));

      fetchAutoCashOutMarketsInterval.current = setInterval(() => {
        dispatch(fetchAsianViewAutoCashOutMarkets(autoCashOutMarketIds));
      }, AUTO_CASH_OUT_GET_MARKETS_INTERVAL);
    }

    return () => {
      if (fetchAutoCashOutMarketsInterval.current) {
        clearInterval(fetchAutoCashOutMarketsInterval.current);
      }
    };
  }, [autoCashOutMarketIds, isAutoCashOutIntervalAvailable]);

  useEffect(() => {
    if (isEventUpdatesIntervalAvailable) {
      dispatch(fetchEventsUpdatedData(eventIds));

      eventUpdatesInterval.current = setInterval(() => {
        dispatch(fetchEventsUpdatedData(eventIds));
      }, runningBallInterval);
    }

    return () => {
      if (eventUpdatesInterval.current) {
        clearInterval(eventUpdatesInterval.current);
      }
    };
  }, [eventIds, language, timezone, isEventUpdatesIntervalAvailable, runningBallInterval]);

  return null;
};

export default memo(AVBetListMobileIntervalsInjection);
