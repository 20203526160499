import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { unescape } from 'lodash';

import SplashScreenIosBundle from 'components/SplashScreenIosBundle';
import { IOS_BUNDLE } from 'constants/app';
import { getSplashScreenContent, getSplashScreenRefreshTimeout } from 'redux/modules/appConfigs/selectors';

const SplashScreen = () => {
  const splashScreenRefreshTimeout = useSelector(getSplashScreenRefreshTimeout);
  const splashScreenContent = useSelector(getSplashScreenContent);

  useEffect(() => {
    let timer: number | null = null;

    if (splashScreenRefreshTimeout && !isNaN(splashScreenRefreshTimeout)) {
      timer = setTimeout('location.reload()', splashScreenRefreshTimeout);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [splashScreenRefreshTimeout]);

  return splashScreenContent === IOS_BUNDLE ? (
    <SplashScreenIosBundle />
  ) : (
    <div dangerouslySetInnerHTML={{ __html: unescape(splashScreenContent) }} />
  );
};

export default SplashScreen;
