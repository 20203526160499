import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import LeaguesFilter from 'components/AsianViewPageModule/components/LeaguesFilter';
import { ASIAN_TABS_EARLY_PREFIX } from 'constants/app';
import { AsianViewMarketLink } from 'constants/asianView';
import { SEARCH_QUERY_LENGTH } from 'constants/search';
import { PARAMS_ASIAN_SEARCH_KEY } from 'constants/urlParams';
import useAsianSingleView from 'hooks/useAsianSingleView';

import DaysFilter from './DaysFilter';
import LayBetting from './LayBetting';
import LineViewFilter from './LineViewFilter';
import OddsTypeDropdown from './OddsTypeDropdown';
import QuickBetting from './QuickBetting';
import SortingFilter from './SortingFilter';

import styles from './styles.module.scss';

const Filters = () => {
  const { marketLink, timeFilter } = useParams();
  const [searchParams] = useSearchParams();

  const { isAsianSingleOutrightView, isAsianSingleEventView, isPopularLinkView, isFavouriteView } =
    useAsianSingleView();

  const isEarlyTab = timeFilter === ASIAN_TABS_EARLY_PREFIX;
  const query = searchParams.get(PARAMS_ASIAN_SEARCH_KEY) || '';
  const isSearchView = query.length >= SEARCH_QUERY_LENGTH;
  const isOutRightView =
    (marketLink === AsianViewMarketLink.OUTRIGHT || isAsianSingleOutrightView) &&
    !isPopularLinkView &&
    !isAsianSingleEventView &&
    !isSearchView &&
    !isFavouriteView;
  const showLineViewFilter =
    (isFavouriteView ||
      query ||
      marketLink == AsianViewMarketLink.HDP_AND_OU ||
      isPopularLinkView ||
      isAsianSingleEventView ||
      isSearchView) &&
    !isOutRightView;

  return (
    <div className={classNames('biab_asian-view-filters', styles.filters)}>
      <div className={styles.filters__typeWrapper}>
        {isEarlyTab &&
          !isFavouriteView &&
          !isSearchView &&
          !isOutRightView &&
          !isAsianSingleEventView &&
          !isPopularLinkView && <DaysFilter />}
        <LayBetting />
        {!isSearchView && (!isOutRightView || isPopularLinkView || isAsianSingleEventView || isFavouriteView) && (
          <SortingFilter />
        )}
        {!isSearchView && <LeaguesFilter />}
        {showLineViewFilter && <LineViewFilter />}
        <OddsTypeDropdown />
        <QuickBetting />
      </div>
    </div>
  );
};

export default Filters;
