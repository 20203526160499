import classNames from 'classnames';

import { IMarket, TMarketSportInfo } from 'types/markets';

import MarketScore from './components/MarketScore';
import NoInPlayContent from './components/NoInPlayContent';

import styles from './styles.module.scss';

interface InPlayCellProps {
  /**
   * Data for the market (marketId, runners, event data, startTime, etc.)
   */
  market: IMarket;
  /**
   * InPlay status
   */
  isInPlay: boolean;

  /**
   * Information about sport for markets (id, name, etc.)
   */
  sportInfo: TMarketSportInfo | null;
}

const InPlayCell = ({ isInPlay, market, sportInfo }: InPlayCellProps) => {
  return (
    <div className={classNames('biab_market-inplay-cell', styles.inPlayCell)}>
      <div
        className={classNames(styles.inPlayCell__content, {
          'biab_in-play': isInPlay
        })}
      >
        {isInPlay ? (
          <MarketScore sportId={sportInfo?.id} eventId={market.event.id} />
        ) : (
          <NoInPlayContent marketStartTime={market.marketStartTime} />
        )}
      </div>
    </div>
  );
};

export default InPlayCell;
