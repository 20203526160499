import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getLanguage } from 'redux/modules/appConfigs/selectors';
import { fetchPageMetaContent } from 'redux/modules/meta';
import { getPageMetaContent } from 'redux/modules/meta/selectors';
import { MetaPage } from 'redux/modules/meta/type';

interface SEOProps {
  page: MetaPage;
  id?: string;
  pageId?: MetaPage | string;
}

const SEO = ({ page, id, pageId }: SEOProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const language = useSelector(getLanguage);
  const metaData = useSelector(getPageMetaContent(pageId || page));

  const isMetaTags = !!metaData?.metaTitle || !!metaData?.metaDescription;

  useEffect(() => {
    dispatch(fetchPageMetaContent({ page, id }));
  }, [id, page, language]);

  useEffect(() => {
    const descriptions = document.querySelectorAll('meta[name=description]');

    if (descriptions.length) {
      descriptions.forEach(item => item.remove());
    }

    return () => {
      if (isMetaTags) {
        const metaTitle = document.querySelector('title');
        const metaDescription = document.querySelector('meta#custom-description');

        if (metaTitle) {
          metaTitle.innerText = t('general.page.title');
        }

        if (metaDescription) {
          metaDescription.remove();
        }
      }
    };
  }, [isMetaTags]);

  if (!isMetaTags) {
    return null;
  }

  return (
    <Helmet>
      {metaData?.metaTitle && <title>{metaData.metaTitle}</title>}
      {metaData?.metaDescription && (
        <meta name="description" content={metaData.metaDescription} id="custom-description" />
      )}
    </Helmet>
  );
};

export default SEO;
