import { getCurrencyPrecisionValue } from 'hooks/usePrecision';
import { PrecisionType } from 'types';

export const getStakeAmountLabelInfo = (
  stakeAmount: number,
  precisionType?: PrecisionType,
  indianNumberSystemEnabled?: boolean
) => {
  const fractionDigits = getCurrencyPrecisionValue(precisionType);

  if (indianNumberSystemEnabled) {
    if (stakeAmount >= 1000000000) {
      return {
        label: Math.floor(stakeAmount / 1000000000),
        translationKey: 'displayFormat.symbol.arab'
      };
    } else if (stakeAmount >= 10000000) {
      return {
        label: Math.floor(stakeAmount / 10000000),
        translationKey: 'displayFormat.symbol.crore'
      };
    } else if (stakeAmount >= 100000) {
      return {
        label: Math.floor(stakeAmount / 100000),
        translationKey: 'displayFormat.symbol.lakh'
      };
    } else if (stakeAmount >= 1000) {
      return {
        label: Math.floor(stakeAmount / 1000),
        translationKey: 'displayFormat.symbol.thousands'
      };
    }
  } else {
    if (stakeAmount >= 1000000000) {
      return {
        label: Math.floor(stakeAmount / 1000000000),
        translationKey: 'displayFormat.symbol.billions'
      };
    } else if (stakeAmount >= 1000000) {
      return {
        label: Math.floor(stakeAmount / 1000000),
        translationKey: 'displayFormat.symbol.millions'
      };
    } else if (stakeAmount >= 1000) {
      return {
        label: Math.floor(stakeAmount / 1000),
        translationKey: 'displayFormat.symbol.thousands'
      };
    }
  }

  return {
    label: stakeAmount.toFixed(fractionDigits)
  };
};

export const getAsianViewStakeAmountLabelInfo = ({ stakeAmount }: { stakeAmount: number }) => {
  if (stakeAmount >= 1000000) {
    return {
      label: Number((stakeAmount / 1000000).toFixed(1)),
      translationKey: 'displayFormat.symbol.millions'
    };
  } else if (stakeAmount >= 1000) {
    const value = Number((stakeAmount / 1000).toFixed(1));

    if (value === 1000) {
      return {
        label: 1,
        translationKey: 'displayFormat.symbol.millions'
      };
    }

    return {
      label: value,
      translationKey: 'displayFormat.symbol.thousands'
    };
  }

  return {
    label: stakeAmount
  };
};
