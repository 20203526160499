import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual, reduce, uniqBy } from 'lodash';

import { SLICES_NAMES } from 'constants/app';
import { TValidationMessage } from 'redux/modules/betslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { TFailureActionPayload, TSocketMarketParams } from 'types';
import { TPrice, TProfit, TSize } from 'types/bets';
import { parseCurrentBet } from 'utils/betslip';

import {
  ECurrentBetActions,
  TCurrentBet,
  TCurrentBetActionPayload,
  TCurrentBetsMap,
  TCurrentBetsPayload,
  TCurrentBetsState
} from './type';

const initialState: TCurrentBetsState = {
  offers: {},
  updatedOffers: {},
  placedBets: {},
  marketsToSubscribe: [],
  placementMessage: '',
  loading: false,
  error: null,
  isFirstLoad: true,
  currentBetsString: '',
  currentBetsList: [],
  areCurrentBetsLoaded: false,
  unmatchedOffersIdsToShowLapsed: {},
  closedUnmatchedOfferIds: {}
};

const slice = createSlice({
  name: SLICES_NAMES.CURRENT_BETS,
  initialState,
  reducers: {
    fetchCurrentBets: (state, _: PayloadAction<TCurrentBetsPayload | undefined>) => {
      state.loading = true;
    },
    successGetCurrentBets: (state, { payload }: PayloadAction<{ bets: TCurrentBet[]; stringifiedBets?: string }>) => {
      // Markets with unmatched bets. Will be subscribed to market prices in order to set take odds.
      const marketsToSubscribe = payload.bets.reduce((result: TSocketMarketParams[], bet: TCurrentBet) => {
        return [
          ...result,
          ...(bet.offerState === BetsStatusesTypes.PLACED ? [{ marketId: bet.marketId, eventId: bet.eventId }] : [])
        ];
      }, []);

      const payloadOffersById = payload.bets.reduce<Record<string, TCurrentBet>>((acc, bet) => {
        return {
          ...acc,
          [bet.offerId]: bet
        };
      }, {});

      const uniqMarketsToSubscribe = uniqBy(marketsToSubscribe, 'marketId');

      const receivedOldOffers = payload.bets.reduce((result: TCurrentBetsMap, bet) => {
        return {
          ...result,
          ...(bet.oldOfferId ? { [bet.oldOfferId]: parseCurrentBet(bet) } : {})
        };
      }, {});

      const isBetInProcess = (bet: TCurrentBet) =>
        !receivedOldOffers[bet.offerId] ||
        (receivedOldOffers[bet.offerId] && receivedOldOffers[bet.offerId].offerState === BetsStatusesTypes.CANCELLED);

      // Offers that are in updating or cancelling process do not remove from the list.
      const processingOffers = reduce(
        state.offers,
        (result: TCurrentBetsMap, bet) => {
          return {
            ...result,
            ...(bet.action && isBetInProcess(bet)
              ? {
                  [bet.offerId]: {
                    ...state.offers[bet.offerId],
                    ...bet
                  }
                }
              : {})
          };
        },
        {}
      );

      const receivedOffers = payload.bets.reduce((result: TCurrentBetsMap, bet: TCurrentBet) => {
        bet = parseCurrentBet(bet);

        const prevBet = state.offers[bet.offerId];
        const findBet = state.offers[bet.oldOfferId];

        const isMatchedOuter =
          bet.offerState === BetsStatusesTypes.MATCHED &&
          prevBet &&
          !prevBet.action &&
          prevBet.sizeRemaining !== bet.sizeRemaining;

        const isPlacedPartiallyMatched =
          ((+bet.sizePlaced > 0 || +bet.sizeMatched > 0) &&
            +bet.sizeRemaining > 0 &&
            !prevBet &&
            findBet?.action === ECurrentBetActions.EDITING) ||
          prevBet?.action === ECurrentBetActions.PLACED_PARTIALLY_MATCHED;

        const isMatched = !!(
          findBet &&
          (findBet.offerState === BetsStatusesTypes.PLACED || findBet.offerState === BetsStatusesTypes.CANCELLED) &&
          bet.offerState === BetsStatusesTypes.MATCHED &&
          (findBet.sizeMatched !== bet.sizeMatched ||
            findBet.sizePlaced !== bet.sizePlaced ||
            findBet.sizeCancelled !== bet.sizeCancelled)
        );

        if (isMatched && +bet.sizeRemaining > 0) {
          bet.action = ECurrentBetActions.PARTIALLY_MATCHED;
        } else if (isMatchedOuter) {
          bet.action = +bet.sizeRemaining > 0 ? ECurrentBetActions.PARTIALLY_MATCHED : ECurrentBetActions.FULLY_MATCHED;
        } else if (isPlacedPartiallyMatched) {
          bet.action = ECurrentBetActions.PLACED_PARTIALLY_MATCHED;
        }

        if ((isMatched || isMatchedOuter) && bet.action) {
          processingOffers[bet.offerId] = bet;
        }

        return {
          ...result,
          /**
           * Offer is in canceling or editing processes. Need to stay in the list in order to show information about
           * placement result.
           */
          ...(processingOffers[bet.offerId] && isBetInProcess(bet)
            ? {
                [bet.offerId]: {
                  ...state.offers[bet.offerId],
                  ...processingOffers[bet.offerId],
                  ...bet
                }
              }
            : /**
             * Updated offer with new offer ID is received. Old offer need to be replaced with the new one (if exists).
             * If offer is partially matched and then updated - keep matched part in offers list (it has oldOfferId).
             */
            !receivedOldOffers[bet.offerId] || bet.offerState === BetsStatusesTypes.MATCHED
            ? {
                [bet.offerId]: {
                  ...state.offers[bet.offerId],
                  ...bet
                }
              }
            : {})
        };
      }, {});

      const canBeRemovedOffers = reduce(
        state.offers,
        (result: TCurrentBetsMap, bet) => {
          const betFromPayload = payloadOffersById[bet.offerId];
          const newBet = receivedOldOffers[bet.offerId];
          const isFullyMatched =
            newBet &&
            newBet.offerState === BetsStatusesTypes.MATCHED &&
            (bet.offerState === BetsStatusesTypes.PLACED || bet.offerState === BetsStatusesTypes.CANCELLED);
          let action;

          if (isFullyMatched) {
            action = ECurrentBetActions.FULLY_MATCHED_AFTER_EDITING;
          } else if (state.offers[bet.offerId].action === ECurrentBetActions.CANCELLING) {
            action = ECurrentBetActions.CANCELLING;
          } else if (state.offers[bet.offerId].action === ECurrentBetActions.FULLY_MATCHED) {
            action = bet.action;
          }

          if (bet.canBeRemoved || isFullyMatched) {
            let updatedBetData = {};
            if (newBet) {
              const { sizeMatched, profitNet, liability } = newBet;
              updatedBetData = { action, sizeMatched, profitNet, liability };
            }

            return {
              ...result,
              [bet.offerId]: betFromPayload
                ? { ...betFromPayload, canBeRemoved: true, action, ...updatedBetData }
                : { ...bet, action, ...updatedBetData }
            };
          }

          return result;
        },
        {}
      );

      const unmatchedOffers = payload.bets.filter(({ offerState, sizeRemaining }) => {
        return (
          offerState === BetsStatusesTypes.PLACED || (offerState === BetsStatusesTypes.MATCHED && +sizeRemaining > 0)
        );
      });

      unmatchedOffers.forEach(({ offerId }) => {
        state.unmatchedOffersIdsToShowLapsed[offerId] = true;
      });

      state.offers = { ...processingOffers, ...receivedOffers, ...canBeRemovedOffers };
      state.updatedOffers = { ...state.updatedOffers, ...receivedOldOffers };
      state.loading = false;
      state.isFirstLoad = false;
      state.currentBetsList = payload.bets;

      if (payload.stringifiedBets) {
        state.currentBetsString = payload.stringifiedBets;
      }

      if (!isEqual(state.marketsToSubscribe, uniqMarketsToSubscribe)) {
        state.marketsToSubscribe = marketsToSubscribe;
      }

      if (!state.areCurrentBetsLoaded) {
        state.areCurrentBetsLoaded = true;
      }
    },
    failureGetCurrentBets: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
    },
    removeAllUnmatchedOffersIdsToShowLapsed: state => {
      state.unmatchedOffersIdsToShowLapsed = {};
    },
    cleanCurrentBets: state => {
      state.offers = {};
      state.currentBetsString = '';
      state.currentBetsList = [];
      state.areCurrentBetsLoaded = false;
      state.unmatchedOffersIdsToShowLapsed = {};
    },
    setCurrentBetAction: (state, { payload }: PayloadAction<TCurrentBetActionPayload>) => {
      if (state.offers[payload.offerId]) {
        state.offers[payload.offerId].action = payload.action;
      }
    },
    updateOfferPlacementData: (
      state,
      {
        payload
      }: PayloadAction<{
        offerId: number;
        changedPrice?: TPrice;
        changedSize?: TSize;
        changedProfit?: TProfit;
        isValid?: boolean;
        isPriceValid?: boolean;
        isSizeValid?: boolean;
        validationMessage?: TValidationMessage | null;
        hidePartiallyMatchedNotification?: boolean;
      }>
    ) => {
      if (state.offers[payload.offerId]) {
        Object.assign(state.offers[payload.offerId], payload);
      }
    },
    setCurrentBetActionForAll: (state, { payload }: PayloadAction<TCurrentBetActionPayload[]>) => {
      payload.forEach(({ offerId, action }) => {
        if (state.offers[offerId]) {
          state.offers[offerId].action = action;
        }
      });
    },
    setCurrentBetsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setCurrentBetCanBeRemoved: (state, { payload }: PayloadAction<{ offerId: number; canBeRemoved: boolean }>) => {
      if (state.offers[payload.offerId]) {
        state.offers[payload.offerId].canBeRemoved = payload.canBeRemoved;
      }
    },
    setCurrentBetsListCanBeRemoved: (
      state,
      { payload }: PayloadAction<{ offerId: number | string; canBeRemoved: boolean }[]>
    ) => {
      payload.forEach(({ offerId, canBeRemoved }) => {
        if (state.offers[offerId]) {
          state.offers[offerId].canBeRemoved = canBeRemoved;
        }
      });
    },
    removeUnmatchedOfferIdToShowLapsed: (state, { payload }: PayloadAction<number>) => {
      delete state.unmatchedOffersIdsToShowLapsed[payload];
    },
    closeAllCurrentBetsCanBeRemoved: state => {
      const canBeRemovedOffers = Object.entries(state.offers)
        .filter(([, bet]) => bet.canBeRemoved)
        .map(([offerId]) => offerId);

      canBeRemovedOffers.forEach(offerId => {
        state.closedUnmatchedOfferIds[offerId] = true;
      });
    },
    removeAllGamesOffersByGameId: (state, { payload }: PayloadAction<number | string>) => {
      Object.values(state.offers).forEach(({ betType, offerId, eventTypeId }) => {
        if (betType === 'GAME' && String(eventTypeId) === String(payload)) {
          delete state.offers[offerId];
        }
      });
    },
    setClosedUnmatchedOfferId: (state, { payload }: PayloadAction<number | string>) => {
      state.closedUnmatchedOfferIds[payload] = true;
    }
  }
});

export const {
  failureGetCurrentBets,
  setCurrentBetAction,
  successGetCurrentBets,
  setCurrentBetActionForAll,
  cleanCurrentBets,
  fetchCurrentBets,
  updateOfferPlacementData,
  setCurrentBetsLoading,
  setCurrentBetCanBeRemoved,
  setCurrentBetsListCanBeRemoved,
  removeUnmatchedOfferIdToShowLapsed,
  closeAllCurrentBetsCanBeRemoved,
  removeAllUnmatchedOffersIdsToShowLapsed,
  removeAllGamesOffersByGameId,
  setClosedUnmatchedOfferId
} = slice.actions;

export default slice.reducer;
