import { useSelector } from 'react-redux';

import { PLBetType, plDefaultSportTypeValues } from 'constants/myBets';
import { getGamesData, getSportsData } from 'redux/modules/myBets/selectors';
import { SelectDropdownValue } from 'types/myBets';

import Select from '../Select/Select';

import styles from './styles.module.scss';

interface EventTypeFilterProps {
  activePLTab: PLBetType;
  eventType: {
    translationKey?: string;
    value?: string;
    id?: string;
    name?: string;
    gameId?: string;
  };
  setEventType: (value: SelectDropdownValue) => void;
}

const EventTypeFilter = ({ activePLTab, eventType, setEventType }: EventTypeFilterProps) => {
  const games = useSelector(getGamesData);
  const sportsList = useSelector(getSportsData);

  const handleChangeSportValue = (value: SelectDropdownValue) => {
    setEventType(value);
  };

  const dropdownValues = activePLTab === PLBetType.Sports ? [...sportsList] : [...games];
  const defaultValues =
    activePLTab === PLBetType.Sports ? [plDefaultSportTypeValues.sports] : [plDefaultSportTypeValues.games];

  return (
    <div className={styles.container}>
      <Select
        defaultValues={defaultValues}
        {...{ [activePLTab === PLBetType.Sports ? 'sportValues' : 'gameValues']: dropdownValues }}
        handleChange={handleChangeSportValue}
        name={eventType}
      />
    </div>
  );
};
export default EventTypeFilter;
