import { isInteger, toNumber } from 'lodash';

import useBetLabels from 'hooks/useBetLabels';
import { useMarketUnits } from 'hooks/useMarketUnits';
import { BetTypes, THandicap, TPrice, TSize } from 'types/bets';
import { TMarketLineRangeInfo } from 'types/markets';
import { getCalculatedParams } from 'utils/betLabels';

import BetLabelsTpl from '../BetLabelsTpl';

type LineLabelProps = {
  price: TPrice;
  size: TSize;
  handicap?: THandicap | null;
  betType: BetTypes;
  eventTypeId?: string;
  lineRangeInfo: TMarketLineRangeInfo | null;
  currency?: string;
  isSwapColors?: boolean;
};

const LineLabel = ({ price, size, betType, lineRangeInfo, currency, isSwapColors }: LineLabelProps) => {
  const marketUnitTranslated = useMarketUnits(lineRangeInfo?.marketUnit ?? 'points', toNumber(price || 0));
  const { getLineLabels } = useBetLabels();

  const { odds, stake } = getCalculatedParams({ price, size, betType });

  const rangeInfoStep = lineRangeInfo?.interval || 1;

  const { labelW, labelS, labelL } = getLineLabels(odds, rangeInfoStep, marketUnitTranslated);

  const profitLossW = price && size ? (betType === BetTypes.BACK ? -1 * stake : stake) : null;
  const profitLossS = !isInteger(rangeInfoStep) && odds && stake ? 0 : null;
  const profitLossL = price && size ? (betType === BetTypes.BACK ? stake : -1 * stake) : null;

  return (
    <BetLabelsTpl
      betType={betType}
      labelW={labelW}
      labelS={labelS}
      labelL={labelL}
      profitLossW={profitLossW}
      profitLossS={profitLossS}
      profitLossL={profitLossL}
      currency={currency}
      isSwapColors={isSwapColors}
    />
  );
};

export default LineLabel;
