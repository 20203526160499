import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { asianViewComponents as branding } from 'constants/branding';
import { EXPORT_DELAY } from 'constants/myBets';
import { fetchRequest } from 'redux/api/request';
import { Method } from 'types';

import styles from './styles.module.scss';

interface ExportButtonProps {
  data: any;
  isDisabled: boolean;
}

const ExportButton = ({ data, isDisabled }: ExportButtonProps) => {
  const { t } = useTranslation();
  const [downloading, setDownloading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');

  const timeout = useRef<NodeJS.Timeout | null>(null);
  const isBtnDisabled = downloading || disabled || isDisabled;

  const handleDownload = async () => {
    setError('');
    setDisabled(true);
    setDownloading(true);
    timeout.current = setTimeout(() => {
      timeout.current = null;
      setDisabled(false);
    }, EXPORT_DELAY);

    try {
      const file = await fetchRequest(Method.GET, data?.url, data?.params, {}, true, 'blob' as ResponseType).then(
        res => res
      );

      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      const time = new Date().getTime();
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${data.filename}_${time}.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();

      document.body.removeChild(fileLink);
      URL.revokeObjectURL(fileURL);
    } catch (e) {
      setError(t('account.labels.exportError'));
    } finally {
      setDownloading(false);
    }
  };

  useEffect(() => {
    setDisabled(false);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return (
    <div className={styles.exportButton__container}>
      {error !== '' && (
        <div className={classNames('biab_error-msg', styles.error)}>
          <i className={classNames(styles.error__icon, 'fa2 fa2-removed')} aria-hidden="true">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
          </i>
          {error}
          <i onClick={() => setError('')} className={classNames('fa2 fa2-close', styles.error__closeIcon)} />
        </div>
      )}
      <button
        className={classNames('biab_btn-export', styles.exportButton, branding.PRIMARY_BTN, {
          [styles.exportButton__disabled]: isBtnDisabled
        })}
        onClick={handleDownload}
        disabled={isBtnDisabled}
      >
        {downloading ? (
          <i className={classNames('fa fa-spinner fa-pulse fa-2x fa-fw', styles.icon)} />
        ) : (
          t('account.labels.export')
        )}
      </button>
    </div>
  );
};

export default ExportButton;
