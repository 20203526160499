import classNames from 'classnames';

import styles from '../../styles.module.scss';

interface CloseListItemProps {
  marketName: string;
}

const CloseListItem = ({ marketName }: CloseListItemProps) => {
  return (
    <div>
      <div>
        <ul>
          <li
            className={classNames(
              'biab_market-item biab_has-arrow js-market-item  biab_race-market-item biab_active',
              styles.sportsList__item,
              styles.sportsList__selectedItem
            )}
          >
            <a className={classNames('biab_href  biab_market-item ', {})}>{marketName}</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CloseListItem;
