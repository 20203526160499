import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TODAY_CARD_PREFIX } from 'constants/app';
import { getEventViewSettings, getIsCompetitionViewEnabled } from 'redux/modules/appConfigs/selectors';
import { getEventData } from 'redux/modules/competitions/selectors';
import { EventViewType, SportId } from 'types';
import { NavigationId } from 'types/navigation';

import useDeviceSettings from './useDeviceSettings';

export const useEnabledView = (isSportPage = false) => {
  const params = useParams();

  const { listViewSports, competitionViewSports, fancyMarketsOnCricket } = useDeviceSettings();

  const eventData = useSelector(getEventData);
  const isCompetitionViewEnabled = useSelector(getIsCompetitionViewEnabled);
  const eventViewSettings = useSelector(getEventViewSettings);

  const sportId = params.sportId && params.sportId.toString().replace(TODAY_CARD_PREFIX, '');
  const eventSettings = eventViewSettings.find(event => event.eventTypeId === sportId);
  const isDefault = eventSettings?.viewType === EventViewType.DEFAULT;
  const isCompetitionView = sportId && isCompetitionViewEnabled && competitionViewSports.includes(sportId);
  const isHorseRacingView = sportId === SportId.HORSE_RACING || sportId === SportId.GRAY_HOUND_RACING;
  const isListView = sportId && listViewSports.includes(sportId);
  const isFancyView = fancyMarketsOnCricket && eventData[0]?.id === NavigationId.FANCY;
  const isTop5View = eventSettings?.viewType === EventViewType.TOP_5_VIEW;
  const isMultiMarketView = eventSettings?.viewType === EventViewType.MULTI_MARKET_VIEW;

  return {
    isListViewEnabled: (isDefault || isSportPage) && (isListView || (!isCompetitionView && !isHorseRacingView)),
    isDefaultView: isDefault,
    isCompetitionView,
    isFancyView,
    isTop5View,
    eventSettings,
    isMultiMarketView
  };
};
