import { useEffect, useRef, useState } from 'react';

interface OptimisticStateOptions<TState> {
  initialState: TState;
  onSetState: (newState: TState) => void | unknown;
  externalError?: Error | string | boolean;
}

const useOptimisticState = <TState>({ initialState, onSetState, externalError }: OptimisticStateOptions<TState>) => {
  const [state, setState] = useState<TState>(initialState);
  const revertedStateRef = useRef<TState>(initialState);

  useEffect(() => {
    if (externalError) {
      setState(revertedStateRef.current);
    }
  }, [externalError]);

  const updateState = (optimisticState: TState) => {
    revertedStateRef.current = state;
    setState(optimisticState);

    try {
      onSetState(optimisticState);
    } catch {
      setState(revertedStateRef.current);
    }
  };

  return [state, updateState] as const;
};

export default useOptimisticState;
