import { ReactNode, useRef, useState } from 'react';
import BodyClassName from 'react-body-classname';
import classNames from 'classnames';

import useOnClickOutside from 'hooks/useOnClickOutside';

import styles from './styles.module.scss';

interface IDropdown {
  children: ReactNode;
  icon: ReactNode;
  topPosition?: number;
}

const Dropdown = ({ children, icon, topPosition }: IDropdown) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => setVisible(false));

  return (
    <>
      <div
        ref={ref}
        className={classNames('biab_nav-filters-dropdown', styles.dropdown)}
        onClick={() => setVisible(!visible)}
      >
        <span className={'biab_filters-dropdown-component'}>{icon}</span>
        <div style={{ top: topPosition }} className={classNames(styles.dropdown_container, { invisible: !visible })}>
          {children}
        </div>
      </div>
      <BodyClassName className={classNames({ disableScroll: visible })} />
    </>
  );
};

export default Dropdown;
