import { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { FAVORITES_TYPES, PopupTypes } from 'constants/app';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { saveRemoveFavorite, setSportCollapse } from 'redux/modules/favorites';
import { TFavorite } from 'redux/modules/favorites/type';
import { setLoginPopup } from 'redux/modules/popup';
import { getCleanMarketId } from 'utils';

import styles from './styles.module.scss';

interface FavoriteStarProps {
  /**
   * Icon custom class name
   */
  className?: string;
  /**
   * Entry data (sport, event, market, competition, group)
   */
  entryData: TFavorite;
  /**
   * Is used in Navigation tree and favourites section
   */
  isNavigation?: boolean;
  iconClassName?: string;
}

const FavoriteStar = ({ className = '', entryData, isNavigation = false, iconClassName = '' }: FavoriteStarProps) => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const device = useSelector(getAppDevice);

  const { starred, sportId, entryType } = entryData;
  const isMobile = device === Devices.MOBILE;

  const onToggleFavorite = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (isLoggedIn) {
      if (entryType !== FAVORITES_TYPES.sport) {
        entryData = {
          ...entryData,
          sportId: getCleanMarketId(entryData.sportId),
          entryId: getCleanMarketId(entryData.entryId)
        };
      }

      dispatch(saveRemoveFavorite(entryData));

      if (starred && !isNavigation) {
        dispatch(
          setSportCollapse({
            sportId: getCleanMarketId(sportId),
            collapse: starred
          })
        );
      }
    } else {
      dispatch(setLoginPopup({ type: PopupTypes.FAVORITE }));
    }
  };

  const isActive = !starred;

  return (
    <div className={classNames(styles.favorite, className)} onClick={onToggleFavorite}>
      <i
        className={classNames(
          'biab_fav-icon',
          {
            ['biab_fav-icons-inactive']: !isActive,
            ['biab_fav-icons-active']: isActive,
            [styles.favorite__colored]: isActive,
            ['biab_fav-event-icon']: !isNavigation && entryType !== FAVORITES_TYPES.market,
            ['biab_fav-market-icon']: !isNavigation && entryType === FAVORITES_TYPES.market,
            ['biab_remove-fav']: isNavigation && isMobile,
            ['biab_active']: isActive
          },
          iconClassName
        )}
        aria-hidden="true"
      />
    </div>
  );
};

export default FavoriteStar;
