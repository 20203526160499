import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { SLICES_NAMES } from 'constants/app';
import { ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE } from 'constants/asianView';
import { TAsianBetStatusesPayload, TPlacedBetException } from 'redux/modules/asianViewBetslip/type';
import { AsianPlacementStatus } from 'redux/modules/asianViewQuickBetting/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { EInputFieldTypes } from 'types/betslip';
import { getMobileSelectedBetIdentifier, isAVResponsibleGamblingError, isPlacedBetStatusPlaced } from 'utils/asianView';

import {
  AsianViewMobileBetActions,
  AsianViewMobilePlacedBetStatuses,
  TAsianMobilePlacedBet,
  TAsianMobileSelectedBet,
  TAsianPlacedMobileBetPayload,
  TAsianPlaceMobileBetPayload,
  TAsianViewMobileBetslipState
} from './type';

const initialState: TAsianViewMobileBetslipState = {
  selectedBets: {},
  placedBets: {},
  rgErrorMessage: null
};

const slice = createSlice({
  name: SLICES_NAMES.ASIAN_VIEW_MOBILE_BETSLIP,
  initialState,
  reducers: {
    setMobileSelectedBet: (state, { payload }: PayloadAction<TAsianMobileSelectedBet>) => {
      const identifier = getMobileSelectedBetIdentifier(payload);
      const betslipId = payload.betslipId;
      const initPrice = payload.price;

      if (!betslipId) {
        return;
      }

      if (state.selectedBets[betslipId] && state.selectedBets[betslipId].identifier === identifier) {
        if (state.selectedBets[betslipId].action !== AsianViewMobileBetActions.PROGRESS) {
          delete state.selectedBets[betslipId];
        } else if (state.selectedBets[betslipId].action === AsianViewMobileBetActions.PROGRESS) {
          state.selectedBets[betslipId].action = AsianViewMobileBetActions.HIDDEN;
        }
      } else {
        Object.entries(state.selectedBets).forEach(([betId, bet]) => {
          if (bet.action !== AsianViewMobileBetActions.PROGRESS && bet.action !== AsianViewMobileBetActions.HIDDEN) {
            delete state.selectedBets[betId];
          } else if (bet.action === AsianViewMobileBetActions.PROGRESS) {
            state.selectedBets[betId].action = AsianViewMobileBetActions.HIDDEN;
          }
        });

        state.selectedBets[betslipId] = {
          ...payload,
          identifier,
          betslipId,
          initPrice,
          focusedField: EInputFieldTypes.SIZE,
          action: AsianViewMobileBetActions.NEW
        };
      }
    },
    updateMobileSelectedBet: (
      state,
      { payload }: PayloadAction<{ betslipId: string; data: Partial<TAsianMobileSelectedBet> }>
    ) => {
      const mobileSelectedBet = state.selectedBets[payload.betslipId];

      if (mobileSelectedBet) {
        Object.assign(mobileSelectedBet, payload.data);
      }
    },
    removeMobileSelectedBet: (state, { payload }: PayloadAction<string | undefined>) => {
      if (payload && state.selectedBets[payload]) {
        delete state.selectedBets[payload];
      }
    },
    placeMobileBet: (state, { payload }: PayloadAction<TAsianPlaceMobileBetPayload>) => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      Object.entries(payload).forEach(([, selectedBets]) => {
        selectedBets.forEach(selectedBet => {
          const findSelectedBet: TAsianMobileSelectedBet | undefined = Object.values(state.selectedBets).find(
            ({ identifier: selectedBetIdentifier }) => selectedBetIdentifier === selectedBet.betUuid
          );

          if (findSelectedBet) {
            state.placedBets[findSelectedBet.identifier] = {
              ...findSelectedBet,
              status: AsianViewMobilePlacedBetStatuses.PLACE
            };

            placedBetsFromStorage[findSelectedBet.identifier] = { ...state.placedBets[findSelectedBet.identifier] };
          }
        });
      });

      localStorage.setItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
    },
    successPlaceMobileBet: (state, { payload }: PayloadAction<TAsianPlacedMobileBetPayload>) => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      Object.entries(payload).forEach(([marketId, { status, exception, identifier: betIdentifier }]) => {
        const placedBet: TAsianMobilePlacedBet | null | undefined = betIdentifier
          ? state.placedBets[betIdentifier]
          : null;

        /** Remove placed bet that was created before offerId was received */
        if (betIdentifier && state.placedBets[betIdentifier]) {
          delete state.placedBets[betIdentifier];
        }

        if (betIdentifier && placedBetsFromStorage[betIdentifier]) {
          delete placedBetsFromStorage[betIdentifier];
        }

        if (status === AsianPlacementStatus.OK) {
          Object.entries(payload[marketId].offerIds ?? {}).forEach(([identifier, offerId]) => {
            const selectedBet: TAsianMobileSelectedBet | undefined = Object.values(state.selectedBets).find(
              ({ identifier: selectedBetIdentifier }) => selectedBetIdentifier === identifier
            );

            if (selectedBet?.betslipId) {
              state.selectedBets[selectedBet.betslipId].offerId = offerId;
            }

            state.placedBets[offerId] = {
              ...(selectedBet ?? placedBet ?? {}),
              identifier,
              offerId,
              status: AsianViewMobilePlacedBetStatuses.CREATED,
              order: 0
            };

            placedBetsFromStorage[offerId] = { ...state.placedBets[offerId] };
          });
        } else if (status === AsianPlacementStatus.FAIL) {
          const selectedBet: TAsianMobileSelectedBet | undefined = Object.values(state.selectedBets).find(
            ({ identifier: selectedBetIdentifier }) => selectedBetIdentifier === betIdentifier
          );

          if (selectedBet?.betslipId && state.selectedBets[selectedBet.betslipId]) {
            delete state.selectedBets[selectedBet.betslipId];
          }

          if (placedBet) {
            const placedBetIdentifier = `${betIdentifier}_${new Date().getTime()}`;

            /** Placed bets */
            state.placedBets[placedBetIdentifier] = {
              ...placedBet,
              identifier: placedBetIdentifier,
              status: AsianViewMobilePlacedBetStatuses.ERROR,
              placementError: exception?.message ?? '',
              placementErrorCode: exception?.code ?? '',
              placementErrorId: exception?.id ?? null,
              order: new Date().getTime()
            };

            if (exception && isAVResponsibleGamblingError(exception)) {
              state.rgErrorMessage = exception;
            }

            placedBetsFromStorage[placedBetIdentifier] = { ...state.placedBets[placedBetIdentifier] };
          }
        }
      });

      localStorage.setItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
    },
    failurePlaceMobileBet: (state, { payload }: PayloadAction<{ data: TAsianPlaceMobileBetPayload; error: any }>) => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      Object.values(payload.data).forEach(betsList => {
        betsList.forEach(bet => {
          const selectedBet: TAsianMobileSelectedBet | undefined = Object.values(state.selectedBets).find(
            ({ identifier }) => identifier === bet.betUuid
          );

          if (selectedBet?.betslipId) {
            state.placedBets[selectedBet.betslipId].placementError = payload.error?.message ?? '';
            state.placedBets[selectedBet.betslipId].placementErrorCode = payload.error?.code ?? '';
            state.placedBets[selectedBet.betslipId].placementErrorId = payload.error?.id ?? null;
            state.placedBets[selectedBet.betslipId].status = AsianViewMobilePlacedBetStatuses.ERROR;
          }
        });
      });

      localStorage.setItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
    },
    fetchMobileBetStatuses: (state, { payload }: PayloadAction<{ offerIds: number[] }>) => {
      payload.offerIds.forEach(offerId => {
        state.placedBets[offerId].isLoading = true;
      });
    },
    successFetchMobileBetStatuses: (state, { payload }: PayloadAction<TAsianBetStatusesPayload>) => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      Object.keys(payload).forEach(offerId => {
        state.placedBets[offerId].isLoading = false;

        if (isPlacedBetStatusPlaced(payload[offerId].state) || payload[offerId].exception) {
          if (state.placedBets[offerId]) {
            state.placedBets[offerId].order = new Date().getTime();

            const isFailed = payload[offerId].exception || payload[offerId].state === BetsStatusesTypes.EXPIRED;

            if (payload[offerId].exception) {
              state.placedBets[offerId].placementError = payload[offerId].exception?.message ?? '';
              state.placedBets[offerId].placementErrorCode = payload[offerId].exception?.code ?? '';
              state.placedBets[offerId].placementErrorId = payload[offerId].exception?.id ?? null;
              state.placedBets[offerId].status = AsianViewMobilePlacedBetStatuses.ERROR;
            } else if (payload[offerId].state === BetsStatusesTypes.EXPIRED) {
              state.placedBets[offerId].offerStatus = payload[offerId].state;
              state.placedBets[offerId].status = AsianViewMobilePlacedBetStatuses.ERROR;
            } else {
              state.placedBets[offerId].offerStatus = payload[offerId].state;
              state.placedBets[offerId].status = AsianViewMobilePlacedBetStatuses.PLACED;
            }

            if (isFailed) {
              const findBet = Object.values(state.selectedBets).find(bet => +(bet.offerId ?? 0) === +offerId);

              if (findBet && findBet.betslipId) {
                delete state.selectedBets[findBet.betslipId];
              }
            }

            placedBetsFromStorage[offerId] = { ...state.placedBets[offerId] };
          }
        }
      });

      localStorage.setItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
    },
    failureFetchMobileBetStatuses: (state, { payload }: PayloadAction<{ offerIds: number[]; error: any }>) => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      payload.offerIds.forEach(offerId => {
        if (state.placedBets[offerId]) {
          state.placedBets[offerId].placementError = payload.error?.message ?? '';
          state.placedBets[offerId].placementErrorCode = payload.error?.code ?? '';
          state.placedBets[offerId].placementErrorId = payload.error?.id ?? null;
          state.placedBets[offerId].status = AsianViewMobilePlacedBetStatuses.ERROR;

          placedBetsFromStorage[offerId] = state.placedBets[offerId];
        }
      });

      localStorage.setItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
    },
    updateMobilePlacedBet: (
      state,
      { payload }: PayloadAction<{ identifier: string; data: Partial<TAsianMobilePlacedBet> }>
    ) => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      const key = payload.data.offerId || payload.identifier;

      if (state.placedBets[key]) {
        Object.assign(state.placedBets[key], payload.data);
        placedBetsFromStorage[key] = state.placedBets[key];
      }

      localStorage.setItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
    },
    removeMobilePlacedBet: (state, { payload }: PayloadAction<number | string | null | undefined>) => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      if (payload) {
        delete state.placedBets[payload];
        delete placedBetsFromStorage[payload];
      }

      localStorage.setItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE, JSON.stringify(placedBetsFromStorage));
    },
    loadPlacedMobileBetsFromStorage: state => {
      const placedBetsFromStorageValue = localStorage.getItem(ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE);
      const placedBetsFromStorage = placedBetsFromStorageValue ? JSON.parse(placedBetsFromStorageValue) : {};

      if (isEmpty(state.placedBets) && !isEmpty(placedBetsFromStorage)) {
        state.placedBets = placedBetsFromStorage;
      }
    },
    setMobileRGErrorMessage: (state, { payload }: PayloadAction<TPlacedBetException | null>) => {
      state.rgErrorMessage = payload;
    }
  }
});

export const {
  setMobileSelectedBet,
  updateMobileSelectedBet,
  removeMobileSelectedBet,
  placeMobileBet,
  successPlaceMobileBet,
  failurePlaceMobileBet,
  fetchMobileBetStatuses,
  successFetchMobileBetStatuses,
  failureFetchMobileBetStatuses,
  updateMobilePlacedBet,
  removeMobilePlacedBet,
  loadPlacedMobileBetsFromStorage,
  setMobileRGErrorMessage
} = slice.actions;

export default slice.reducer;
