import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { asianViewDropdown } from 'constants/branding';
import { clearQuickBets } from 'redux/modules/asianViewQuickBetting';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setSettings } from 'redux/modules/user';
import { getIsUserAccountSettings, getUserAsianViewQuickBetting } from 'redux/modules/user/selectors';
import { CookieNames } from 'types';
import { getBooleanValue } from 'utils';

import styles from '../LayBetting/styles.module.scss';

const QuickBetting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const asianViewQuickBetting = useSelector(getUserAsianViewQuickBetting);

  const [cookies, setCookie] = useCookies([CookieNames.ASIAN_VIEW_QUICK_BETTING]);

  const isChecked = isLoggedIn ? asianViewQuickBetting : getBooleanValue(cookies.ASIAN_VIEW_QUICK_BETTING);

  const onQuickBettingHandler = () => {
    if (isLoggedIn && isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            asianViewQuickBetting: !isChecked
          }
        })
      );
    } else {
      setCookie(CookieNames.ASIAN_VIEW_QUICK_BETTING, !isChecked, {
        path: '/'
      });
    }

    if (isChecked) {
      dispatch(clearQuickBets());
    }
  };

  return (
    <div
      className={classNames(styles.placeholder, 'biab_quick-betting', asianViewDropdown.dropdownBtn)}
      onClick={onQuickBettingHandler}
    >
      <input type="checkbox" checked={isChecked} />
      <label className={styles.label}>
        <span>{t('asianView.labels.quickBetting')}</span>
      </label>
    </div>
  );
};

export default QuickBetting;
