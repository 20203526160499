import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import StepperInput from 'components/StepperInput';
import { mobileComponents } from 'constants/branding';
import { DEFAULT_MARKET_UNITS, DEFAULT_MAX_PRICE, DEFAULT_MIN_PRICE, ODDS_REGEX_PATTERN } from 'constants/placement';
import { useMarketUnits } from 'hooks/useMarketUnits';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import { getMobileSettingsVirtualKeyboardBetslip } from 'redux/modules/appConfigs/selectors';
import { EBetFocusFields } from 'redux/modules/betslip/type';
import { BetTypes, TPrice } from 'types/bets';
import { EActionTypes } from 'types/betslip';
import { TMarketLineRangeInfo, TPriceLadderDescription } from 'types/markets';
import { isLineBettingType, valueToNumber } from 'utils/betslip';
import { formatFloatNumber } from 'utils/betValidation';
import { getMarketUnitTranslation } from 'utils/market';
import { recalculatePriceByStep, validatePrice } from 'utils/price';

import inputStyles from '../inputs.module.scss';

interface PriceInputProps {
  priceValue: string | number | TPrice;
  setErrorMessage: (message: string) => void;
  setPriceValue: (sizeValue: string | number) => void;
  bettingType: string;
  marketType: string;
  betType: BetTypes;
  marketUnit: string;
  lineRangeInfo?: TMarketLineRangeInfo | null;
  priceLadderDescription?: TPriceLadderDescription | null;
  setFocused: () => void;
  currentFocus: EBetFocusFields | null;
  onChangeInvalidInputs: (input: Exclude<EBetFocusFields, EBetFocusFields.LIABILITY>, value: boolean) => void;
  isInvalid: boolean;
}

function PriceInput({
  priceValue,
  setPriceValue,
  setErrorMessage,
  lineRangeInfo,
  bettingType,
  marketType,
  betType,
  marketUnit,
  priceLadderDescription,
  setFocused,
  currentFocus,
  onChangeInvalidInputs,
  isInvalid
}: PriceInputProps) {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const { t } = useTranslation();

  const virtualKeyboardBetslip = useSelector(getMobileSettingsVirtualKeyboardBetslip);

  const marketUnits = useMarketUnits(lineRangeInfo?.marketUnit ?? DEFAULT_MARKET_UNITS);

  const isFocused = currentFocus === EBetFocusFields.PRICE;
  const isLineMarket = isLineBettingType(bettingType);
  const minValue = isLineMarket ? lineRangeInfo?.minUnitValue ?? DEFAULT_MIN_PRICE : DEFAULT_MIN_PRICE;
  const maxValue = isLineMarket ? lineRangeInfo?.maxUnitValue ?? DEFAULT_MAX_PRICE : DEFAULT_MAX_PRICE;
  const isMinusBtnEnabled = +(priceValue || 0) > minValue;
  const isPlusBtnEnabled = +(priceValue || 0) < maxValue;
  const unitsTranslation = t(getMarketUnitTranslation(marketUnit.toLocaleLowerCase()));
  const placeholder = isLineMarket
    ? t('betslip.labels.units.back', { UNITS: unitsTranslation })
    : t('betslip.labels.price.back');

  useUpdateEffect(() => {
    if (!isTouched) {
      setIsTouched(true);
    }
  }, [priceValue]);

  const changePriceValue = (type: EActionTypes) => {
    const updatedPrice = recalculatePriceByStep({
      type,
      price: priceValue,
      marketType,
      bettingType,
      lineRangeInfo,
      priceLadderDescription
    });

    setPriceValue(formatFloatNumber(updatedPrice));

    if (!isFocused) {
      setFocused();
    }
  };

  const onFocus = () => {
    if (!isFocused) {
      setFocused();
    }
  };

  const onBlur = () => {
    if (!isTouched || priceValue === '') return;

    const { isValid, errorMessage, validValue } = validatePrice({
      price: priceValue,
      betType,
      bettingType,
      marketType,
      marketUnits,
      lineRangeInfo,
      priceLadderDescription
    });

    if (!isValid) {
      onChangeInvalidInputs(EBetFocusFields.PRICE, true);
      setPriceValue(Number(validValue));

      if (errorMessage) {
        setErrorMessage(t(errorMessage.text, errorMessage.params));
      }
    }
  };

  const onPriceChange = (value: string) => {
    const changedValue = valueToNumber(value);

    if (changedValue === '' || ODDS_REGEX_PATTERN.test(changedValue)) {
      setPriceValue(changedValue);
    }
  };

  return (
    <StepperInput
      value={priceValue}
      label={placeholder}
      id="mobile-input-price"
      name="price"
      onFocus={onFocus}
      onDecrease={() => changePriceValue(EActionTypes.SUBSTR)}
      onIncrease={() => changePriceValue(EActionTypes.ADD)}
      onInputChange={onPriceChange}
      setFocused={setFocused}
      onBlur={onBlur}
      className={inputStyles.container}
      commonClassName={classNames(mobileComponents.INPUT, {
        [mobileComponents.FOCUS]: isFocused,
        [mobileComponents.ERROR]: isInvalid
      })}
      isInputReadOnly={virtualKeyboardBetslip}
      isDecreaseBtnDisabled={!isMinusBtnEnabled}
      isIncreaseBtnDisabled={!isPlusBtnEnabled}
      isInvalid={isInvalid}
      isFocused={isFocused}
    />
  );
}

export default PriceInput;
