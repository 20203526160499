import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { BET_SLIP_PNC_ODDS_INDICATOR_TIMEOUT } from 'constants/betslip';
import { TPrice } from 'types/bets';

import styles from './BFZeroPriceChangeIndicator.module.scss';

type BFZeroPriceChangeIndicatorProps = {
  isGoingUp: boolean;
  price: TPrice;
  customClassName?: string;
};

const BFZeroPriceChangeIndicator = ({ isGoingUp, price, customClassName }: BFZeroPriceChangeIndicatorProps) => {
  const [showIndicator, setShowIndicator] = useState(true);

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (!showIndicator) {
      setShowIndicator(true);
    }
  }, [isGoingUp, price]);

  useEffect(() => {
    if (showIndicator) {
      timeout.current = setTimeout(() => {
        setShowIndicator(false);
        timeout.current = null;
      }, BET_SLIP_PNC_ODDS_INDICATOR_TIMEOUT);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  }, [showIndicator, price]);

  if (!showIndicator) {
    return null;
  }

  return (
    <i
      className={classNames('fa2', styles.arrow, customClassName, {
        'fa2-sort-up': isGoingUp,
        'fa2-sort-down': !isGoingUp,
        [styles.arrow__up]: isGoingUp,
        [styles.arrow__down]: !isGoingUp
      })}
      aria-hidden="true"
    />
  );
};

export default BFZeroPriceChangeIndicator;
