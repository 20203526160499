import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';

import { TFetchRacesPayload, TRace, TRaces } from './type';

const initialState: TRaces = {
  races: [],
  loading: false,
  error: null,
  stringifiedRaces: ''
};

const slice = createSlice({
  name: SLICES_NAMES.RACING,
  initialState,
  reducers: {
    fetchRaces: (state, { payload }: PayloadAction<TFetchRacesPayload>) => {
      if (!payload.noUpdateLoading) {
        state.loading = true;
      }
    },
    successFetchRaces: (state, { payload }: PayloadAction<TRace[]>) => {
      state.loading = false;

      const stringifiedRacesFromResponse = JSON.stringify(payload);

      if (state.stringifiedRaces !== stringifiedRacesFromResponse) {
        state.races = payload;
        state.stringifiedRaces = stringifiedRacesFromResponse;
      }
    },
    failureFetchRaces: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export const { fetchRaces, successFetchRaces, failureFetchRaces } = slice.actions;

export default slice.reducer;
