import classNames from 'classnames';

import useLayColumn from 'hooks/useLayColumn';
import {
  BetContentCellClasses,
  BetContentCellStyles,
  BetContentGroupClasses,
  BetContentGroupStyles,
  PageBlocks,
  PlacementPage
} from 'types';
import { BetTypes } from 'types/bets';
import { BetContentGroupType, IMarket, IMarketStatusSettings, TMarketRunner } from 'types/markets';

import BetCellTooltip from '../BetCellTooltip';
import BetContentCell, { BetContentCellProps } from '../BetContentCell';

import styles from './styles.module.scss';

interface BetContentGroupProps {
  /**
   * Count of bet groups (1 group = back and lay cells) for prices
   */
  betGroupsCount: number;

  /**
   * Data for market runner
   */
  runner: TMarketRunner | null;

  /**
   * Place where component was added (Home, Market odds, Competition, Event, etc.)
   */
  pageBlock?: PageBlocks;

  /**
   * Data for the market (marketId, runners, event data, startTime, etc.)
   */
  market: IMarket;

  /**
   * Show bet cells for mobile
   */
  mobileBettingMode?: boolean;

  /**
   * Bet content group type (description see types/markets)
   */
  type?: BetContentGroupType;

  /**
   * Custom inline styles for bet group
   */
  groupStyles?: BetContentGroupStyles;

  /**
   * Custom classes for bet group
   */
  groupClasses?: BetContentGroupClasses;

  /**
   * Custom classes for bet content cell
   */
  cellClasses?: BetContentCellClasses;

  /**
   * Custom inline styles for cell
   */
  cellStyles?: BetContentCellStyles;

  /**
   * Redirect to single market page if inline placement is disabled
   */
  redirectToSingleMarketPage?: boolean;
  /**
   * URL search params for redirect to single market page
   */
  redirectToSingleMarketSearchParams?: string;
  /**
   * Callback fired when redirected to a single market page
   * @param market
   */
  onRedirectToSingleMarketPage?: (market: IMarket) => void;
  /**
   * Array of types for all cell groups
   */
  allGroupsTypes?: BetContentGroupType[];
  /**
   * Cell width for mobile
   */
  mobileCellWidth?: number;
  /**
   * Whether component inside MarketsTableRow component
   */
  insideTableRow?: boolean;
  /**
   * Whether lay column should be hidden
   */
  hideLayColumn?: boolean;
  marketStatusSettings: IMarketStatusSettings;
  isOtherSection?: boolean;
  showCellTooltip?: boolean;
  page?: PlacementPage;
  isPopularMarketsOdds?: boolean;
}

type CommonProps = Omit<BetContentCellProps, 'betType'>;

const getBetContentGroup = (commonProps: CommonProps, type: BetContentGroupType, isLayColumnHidden: boolean) => {
  if (type === BetContentGroupType.DEEP_BACK) {
    return (
      <>
        <BetContentCell betType={BetTypes.BACK} isDeepPrice depthValue={2} {...commonProps} />
        <BetContentCell betType={BetTypes.BACK} isDeepPrice depthValue={1} {...commonProps} />
      </>
    );
  } else if (type === BetContentGroupType.DEEP_LAY) {
    if (!isLayColumnHidden) {
      return (
        <>
          <BetContentCell betType={BetTypes.LAY} isDeepPrice depthValue={1} {...commonProps} />
          <BetContentCell betType={BetTypes.LAY} isDeepPrice depthValue={2} {...commonProps} />
        </>
      );
    }

    return null;
  }

  return (
    <>
      <BetContentCell betType={BetTypes.BACK} {...commonProps} />
      {!isLayColumnHidden && <BetContentCell betType={BetTypes.LAY} {...commonProps} />}
    </>
  );
};

const BetContentGroup = ({
  runner,
  betGroupsCount,
  pageBlock,
  market,
  mobileBettingMode = false,
  type = BetContentGroupType.DEFAULT,
  groupStyles,
  groupClasses,
  cellClasses,
  cellStyles,
  redirectToSingleMarketPage,
  redirectToSingleMarketSearchParams,
  onRedirectToSingleMarketPage,
  allGroupsTypes,
  mobileCellWidth,
  insideTableRow = false,
  hideLayColumn = false,
  marketStatusSettings,
  isOtherSection,
  showCellTooltip,
  page,
  isPopularMarketsOdds
}: BetContentGroupProps) => {
  const { isLayColumnEnabled } = useLayColumn({
    sportId: market.eventType.id,
    bettingType: market.description.bettingType
  });

  const isEmptyColumn = runner === null;
  const selectionId = isEmptyColumn ? null : runner.selectionId;
  const isLayColumnHidden = insideTableRow ? hideLayColumn : !isLayColumnEnabled;

  const commonProps: CommonProps = {
    isLayColumnHidden,
    mobileBettingMode,
    market,
    runner,
    pageBlock,
    classes: cellClasses,
    cellStyles,
    redirectToSingleMarketPage,
    redirectToSingleMarketSearchParams,
    onRedirectToSingleMarketPage,
    mobileCellWidth,
    marketStatusSettings,
    isOtherSection,
    page,
    isPopularMarketsOdds
  };

  const isCellTooltip =
    showCellTooltip &&
    pageBlock &&
    [PageBlocks.IN_PLAY, PageBlocks.HOME, PageBlocks.SPORT, PageBlocks.FANCY_TOP_VIEW].includes(pageBlock);

  const cellTooltipType = pageBlock === PageBlocks.FANCY_TOP_VIEW ? 'fancy' : 'cell';

  return (
    <div
      className={classNames('betContentContainer', styles.betContentContainer, {
        [groupClasses?.betContent ?? '']: groupClasses?.betContent,
        [styles.betContentContainer__deepBackNoLay]:
          type === BetContentGroupType.DEEP_BACK && isLayColumnHidden && betGroupsCount === 2,
        [styles.betContentContainer__defaultNoLay]:
          type === BetContentGroupType.DEFAULT &&
          isLayColumnHidden &&
          betGroupsCount === 2 &&
          allGroupsTypes?.includes(BetContentGroupType.DEEP_BACK)
      })}
      style={{
        width: `${100 / betGroupsCount}%`,
        maxWidth: groupStyles?.betContent?.maxWidth
      }}
      data-selection-id={selectionId}
    >
      {isCellTooltip && <BetCellTooltip type={cellTooltipType} />}
      {getBetContentGroup(commonProps, type, isLayColumnHidden)}
    </div>
  );
};

export default BetContentGroup;
