import classNames from 'classnames';

import styles from './styles.module.scss';

interface RefreshButtonProps {
  onRefresh: () => void;
}

const RefreshButton = ({ onRefresh }: RefreshButtonProps) => {
  return (
    <div className={styles.refreshButton__container}>
      <button className={classNames('biab_btn-refresh', styles.refreshButton)} onClick={onRefresh}>
        <i className="fa2 fa2-refresh" />
      </button>
    </div>
  );
};

export default RefreshButton;
