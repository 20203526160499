import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import MyBetsHeaderCell from 'components/MyBetsHeaderCell';
import { BetStatuses, ExchangeTypes } from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import {
  getDesktopSettingsAlternativeLayOdds,
  getDesktopSettingsTotalWinnings
} from 'redux/modules/appConfigs/selectors';
import { getCancelledBetsActualNumber, getIsCancelActionMessage } from 'redux/modules/cancelActions/selectors';
import { showMobileBetDetails } from 'redux/modules/myBets';
import { getAccountProductsByBetsType, getMyBets } from 'redux/modules/myBets/selectors';
import { BetsType, TMyBetsContentItem } from 'redux/modules/myBets/type';
import { CookieNames } from 'types';

import ContentItem from './components/ContentItem';
import MobileContentItem from './components/MobileContentItem';

import styles from './styles.module.scss';

interface BetsTableProps {
  currentStatus?: BetStatuses;
}

const BetsTable = ({ currentStatus }: BetsTableProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const alternativeLayOdds = useSelector(getDesktopSettingsAlternativeLayOdds);
  const totalWinnings = useSelector(getDesktopSettingsTotalWinnings);
  const { content } = useSelector(getMyBets);
  const cancelledBetsActualNumber = useSelector(getCancelledBetsActualNumber);
  const isCancelActionMessage = useSelector(getIsCancelActionMessage);
  const accountProducts = useSelector(getAccountProductsByBetsType(BetsType.All_MY_BETS));
  const { isMobile } = useDevice();

  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE_NEW]);

  const isCurrentStatus = currentStatus === BetStatuses.Unmatched || currentStatus === BetStatuses.Matched;
  const isAsianView = accountProducts?.asianView && cookies.EXCHANGE_TYPE_NEW === ExchangeTypes.AsianView;

  const dateHeaderValue = () => {
    if (isCurrentStatus) {
      return t('account.mybets.labels.placed');
    }
    if (currentStatus === BetStatuses.Cancelled) {
      return t('account.mybets.labels.cancelled');
    }
    if (currentStatus === BetStatuses.Voided && isAsianView) {
      return t('account.mybets.labels.types.voided');
    }
    return t('account.mybets.labels.settled');
  };

  const openMobileBetDetails = (bet: TMyBetsContentItem) => {
    dispatch(showMobileBetDetails(bet));
  };

  return (
    <div className="biab_table-holder">
      <>
        {content?.length ? (
          <>
            {!isAsianView && alternativeLayOdds && !isMobile && (
              <span className={styles.infoMsg}>{t('account.mybets.messages.alternativeLayOdds')}</span>
            )}
            {!isMobile && (
              <div className={classNames('biab_table biab_mybets', styles.table)}>
                <div className={classNames('biab_my-bets-table-header', styles.tableHeader)} role="rowheader">
                  <MyBetsHeaderCell className={styles.dateHeader} value={dateHeaderValue()} />
                  <div className={styles.dateHeaderWrapper}>
                    <MyBetsHeaderCell
                      className={styles.descriptionHeader}
                      value={t('account.mybets.labels.description')}
                    />
                    <div className={styles.dateHeaderWrapperContent}>
                      <MyBetsHeaderCell value={t('account.mybets.labels.type')} className={styles.type} />
                      {isAsianView && (
                        <MyBetsHeaderCell value={t('asianView.labels.account.oddsType')} className={styles.type} />
                      )}
                      {!isAsianView && alternativeLayOdds ? (
                        <>
                          <MyBetsHeaderCell value={t('account.mybets.labels.backOdds')} className={styles.odds} />
                          <MyBetsHeaderCell value={t('account.mybets.labels.layOdds')} className={styles.odds} />
                        </>
                      ) : (
                        <MyBetsHeaderCell value={t('account.mybets.labels.odds')} className={styles.odds} />
                      )}
                      <MyBetsHeaderCell value={t('account.mybets.labels.stake')} className={styles.odds} />
                      {isAsianView && (
                        <MyBetsHeaderCell value={t('account.mybets.labels.risk')} className={styles.liability} />
                      )}
                      {!isAsianView && totalWinnings && (
                        <>
                          <MyBetsHeaderCell
                            value={t(`account.mybets.labels.${isAsianView ? 'risk' : 'liability'}`)}
                            className={styles.liability}
                          />
                          <MyBetsHeaderCell
                            value={t('account.mybets.labels.totalWinnings')}
                            className={styles.winnings}
                          />
                        </>
                      )}
                      <MyBetsHeaderCell
                        value={
                          currentStatus === BetStatuses.Settled
                            ? t('account.mybets.labels.profit.past')
                            : t('account.mybets.labels.profit.current')
                        }
                        className={styles.profit}
                      />

                      <MyBetsHeaderCell value={t('account.mybets.labels.status')} className={styles.status} />
                    </div>
                  </div>
                </div>
                {content?.map((el: TMyBetsContentItem) => (
                  <ContentItem key={el.offerId} item={el} currentStatus={currentStatus} />
                ))}
              </div>
            )}
            {isMobile && (
              <table className={classNames('biab_table biab_mybets', styles.table)}>
                <tbody>
                  {content?.map((el: TMyBetsContentItem) => (
                    <MobileContentItem
                      openMobileBetDetails={openMobileBetDetails}
                      bet={el}
                      key={el.offerId}
                      currentStatus={currentStatus}
                    />
                  ))}
                </tbody>
              </table>
            )}
          </>
        ) : (
          <>
            {(isMobile || !isCancelActionMessage) && (
              <div className={classNames(styles.noData, { [styles.noData__mobile]: isMobile })}>
                {t('account.mybets.messages.noData')}
              </div>
            )}
          </>
        )}
      </>
      {!isMobile && isCancelActionMessage && (
        <div className={styles.noData}>
          {t('account.mybets.labels.cancelledAllMessage', { number: cancelledBetsActualNumber })}
        </div>
      )}
    </div>
  );
};

export default BetsTable;
