export enum BetTypes {
  BACK = 'BACK',
  LAY = 'LAY'
}

export enum MatchTypes {
  MATCHED = 'MATCHED',
  UNMATCHED = 'UNMATCHED'
}

export type TPrice = number | string | undefined;

export type TSize = number | string | undefined;

export type TProfit = number | string | null | undefined;

export type THandicap = number | string;

export enum EBetErrorMessageTypes {
  MAX_PRICE = 'MAX_PRICE',
  MIN_PRICE = 'MIN_PRICE',
  MAX_SIZE = 'MAX_SIZE',
  MIN_SIZE = 'MIN_SIZE',
  TAKE_OFFER = 'TAKE_OFFER'
}

export type TErrorMessage = {
  message: string;
  label?: string;
  type?: EBetErrorMessageTypes;
};

export enum EPersistenceTypes {
  LAPSE = 'LAPSE',
  PERSIST = 'PERSIST'
}

export enum BetDatabaseName {
  BACK = 'bdatb',
  LAY = 'bdatl'
}
