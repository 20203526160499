import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useCashOutProgressBar from 'hooks/useCashOutProgressBar';

import styles from './styles.module.scss';

const CashOutProgressBar = () => {
  const { t } = useTranslation();

  const { width } = useCashOutProgressBar();

  return (
    <div className="biab_cash-out-button">
      <div className={classNames(styles.main, 'biab_cash-out-message biab_pending')}>
        <div className={classNames(styles.progress, 'biab_progress-bg')} style={{ width: `${width}%` }} />
        <span className={classNames(styles.text, 'biab_cash-out-message-text')}>{t('cashout.statuses.PENDING')}</span>
      </div>
      <div className={styles.divider} />
    </div>
  );
};

export default CashOutProgressBar;
