import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { inlinePlacementBranding } from 'constants/branding';
import {
  INLINE_PLACEMENT_BET_WAS_NOT_PLACED_MESSAGE_TIMEOUT,
  INLINE_PLACEMENT_PROCESS_MESSAGE_TIMEOUT,
  INLINE_PLACEMENT_PROGRESS_INTERVAL
} from 'constants/inlinePlacement';
import { LINE_MARKET_ODDS, LOADING_TIMEOUT_MESSAGE } from 'constants/placement';
import { useFancySwapColors } from 'hooks/useFancySwap';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { useMarketUnits } from 'hooks/useMarketUnits';
import { updateInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { BetTypes } from 'types/bets';
import { Actions } from 'types/inlinePlacement';
import { BettingType } from 'types/markets';
import { formatBestPrice } from 'utils/betslip';
import { getBetIdentityData } from 'utils/inlinePlacement';

import InlineBetInfo from '../InlineBetInfo';

import styles from './styles.module.scss';

type TInlineBetProgressProps = {
  bet: TInlineSelectedBet;
};

const InlineBetProgress = ({ bet }: TInlineBetProgressProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLongPlacement = bet.action === Actions.LONG_PROGRESS;

  const [progress, setProgress] = useState(isLongPlacement ? 90 : 0);

  const { noFormattedAmount: sizeFormatted } = useFormatCurrency(bet.size ?? 0, bet.currency, {
    noCommas: true,
    noRounding: true
  });

  const isFancySwapColors = useFancySwapColors(bet);

  const isBack = bet.type === BetTypes.BACK;

  const odds = bet.bettingType === BettingType.LINE ? LINE_MARKET_ODDS : bet.price;

  const isCancellingProgress = bet.progressType === Actions.CANCEL;

  const placementLabel = t(
    isCancellingProgress
      ? 'inlinePlacement.messages.cancellingYourBet'
      : isLongPlacement
      ? 'inlinePlacement.messages.longPlacement'
      : 'inlinePlacement.messages.placingYourBet'
  );

  const progressInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const progressTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const errorTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const betIdData = getBetIdentityData(bet);

  const isLineMarket = bet.bettingType === BettingType.LINE;
  const marketUnitTranslated = useMarketUnits(bet.marketUnit ?? 'points');
  const priceFormatted = formatBestPrice(+(bet.price ?? 0), bet.marketType, bet.bettingType ?? '');
  const infoLabel = isLineMarket
    ? t('placement.labels.stakeForUnits', { stake: sizeFormatted, units: `${priceFormatted} ${marketUnitTranslated}` })
    : `${sizeFormatted} @${odds}`;

  useEffect(() => {
    if (!progressInterval.current) {
      progressInterval.current = setInterval(
        () => setProgress(prevState => prevState + 10),
        INLINE_PLACEMENT_PROGRESS_INTERVAL
      );
    }

    return () => {
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    if (progressInterval.current && progress === 90) {
      clearInterval(progressInterval.current);
    }
  }, [progress]);

  useEffect(() => {
    if (!progressTimeout.current) {
      progressTimeout.current = setTimeout(() => {
        dispatch(updateInlineSelectedBet({ ...betIdData, action: Actions.LONG_PROGRESS }));
      }, INLINE_PLACEMENT_PROCESS_MESSAGE_TIMEOUT);
    }

    if (!errorTimeout.current) {
      errorTimeout.current = setTimeout(() => {
        dispatch(
          updateInlineSelectedBet({ ...betIdData, action: Actions.SELECT, placementError: t(LOADING_TIMEOUT_MESSAGE) })
        );
      }, INLINE_PLACEMENT_BET_WAS_NOT_PLACED_MESSAGE_TIMEOUT - INLINE_PLACEMENT_PROCESS_MESSAGE_TIMEOUT);
    }

    return () => {
      if (progressTimeout.current) {
        clearTimeout(progressTimeout.current);
      }

      if (errorTimeout.current) {
        clearTimeout(errorTimeout.current);
      }
    };
  }, []);

  return (
    <div className={styles.inlineBetProgress}>
      <div
        className={classNames(styles.inlineBetProgress__wrapper, {
          [styles.inlineBetProgress__wrapper__back]: isFancySwapColors ? !isBack : isBack,
          [styles.inlineBetProgress__wrapper__lay]: isFancySwapColors ? isBack : !isBack
        })}
      >
        <div className={classNames(styles.inlineBetProgress__inner, inlinePlacementBranding.PROGRESS_WRAPPER)}>
          <div className={styles.inlineBetProgress__info}>
            <InlineBetInfo bet={bet} />
          </div>
          <p className={styles.inlineBetProgress__label}>
            <strong>{infoLabel}</strong> &ndash; {placementLabel}
          </p>
          <div
            className={classNames(styles.inlineBetProgress__progress, inlinePlacementBranding.PROGRESS_BAR)}
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export default InlineBetProgress;
