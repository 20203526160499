export const CURRENCY_REQUEST_INTERVAL = 60000;
export const OPEN_BETS_NOTIFICATION_TIME = 5000;
export const CANCEL_ACTION_FETCHING_TIMEOUT = 500;

/**
 * Hide component after N ms after placement if user doesn't interact with it.
 */
export const HIDE_INLINE_PLACEMENT_TIMEOUT = 3000;

export const HIDE_MOBILE_PLACEMENT_TIMEOUT = 5000;

export const HIDE_MOBILE_CANCEL_ALL_BETS_TIMEOUT = 120000;

export const LINE_MARKET_ODDS = 2;

export const DEFAULT_MIN_PRICE = 1.01;
export const DEFAULT_MAX_PRICE = 1000;
export const DEFAULT_STEP_PRICE = 0.01;
export const DEFAULT_MIN_LINE_PRICE = 1;
export const DEFAULT_MAX_LINE_PRICE = 400;
export const DEFAULT_STEP_LINE_PRICE = 1;
export const DEFAULT_MARKET_UNITS = 'points';
export const PRICE_MARKET_UNITS = 'price';
export const ODDS_REGEX_PATTERN = /^(([1-9][0-9]*)|(0))([.,][0-9]{0,2})?$/;
export const TOTAL_LIABILITY_PRECISION = 2;
export const DEFAULT_EACH_WAY_DIVISOR = 1;

/**
 * Validation error messages when place, edit or cancel bets.
 */
export const VALIDATION_ERROR_BET_MIN_STAKE = 'error.code.EX013.message';
export const VALIDATION_ERROR_BET_MAX_STAKE = 'error.code.EX014.message';
export const VALIDATION_ERROR_BET_BACK_LESS_LAY = 'error.code.EX015.message';
export const VALIDATION_ERROR_BET_INVALID_SIZE = 'error.code.EX016.message';
export const VALIDATION_ERROR_BET_MIN_PRICE = 'error.code.EX017.message';
export const VALIDATION_ERROR_BET_INVALID_PRICE = 'error.code.EX018.message';
export const VALIDATION_ERROR_BET_NOT_ENOUGH_MONEY = 'error.code.EX019.message';
export const LOADING_TIMEOUT_MESSAGE = 'error.code.EX020.message';
export const VALIDATION_ERROR_BET_MAX_PRICE = 'error.code.EX021.message';
export const VALIDATION_ERROR_BET_INVALID_PRICE_LINE_MARKETS = 'error.code.EX023.message';
export const VALIDATION_ERROR_BET_MAX_PRICE_LINE_MARKETS = 'error.code.EX024.message';
export const VALIDATION_ERROR_BET_MIN_PRICE_LINE_MARKETS = 'error.code.EX025.message';
export const VALIDATION_ERROR_BET_OUTDATED_ODDS = 'error.code.EX026.message';
export const VALIDATION_ERROR_RESTRICTED_MARKET = 'error.code.EX027.message';
export const VALIDATION_ERROR_DIFFERENT_CURRENCY_MARKET = 'error.code.EX028.message';
export const VALIDATION_ERROR_BET_OUTDATED_LINE = 'error.code.EX029.message';
export const VALIDATION_ERROR_DUTCHING_CALCULATOR_MIN_STAKE = 'error.code.EX033.message';
export const VALIDATION_ERROR_DUTCHING_CALCULATOR_MAX_STAKE = 'error.code.EX034.message';

export const MSA_INSUFFICIENT_FUNDS = 'EX004';

export const ERROR_BET_IS_EXPIRED = 'betslip.messages.betIsExpired';
export const BET_WAS_NOT_PLACED = 'betslip.labels.notPlaced';
export const BETSLIP_LABEL_MIN = 'betslip.labels.min';
export const BETSLIP_LABEL_MAX = 'betslip.labels.max';

export const AH_DOUBLE_LINE = 'ASIAN_HANDICAP_DOUBLE_LINE';
export const AH_SINGLE_LINE = 'ASIAN_HANDICAP_SINGLE_LINE';
export const VARIABLE_HANDICAP = 'VARIABLE_HANDICAP';
export const COMBINED_TOTAL = 'COMBINED_TOTAL';
export const ALT_TOTAL_GOALS = 'ALT_TOTAL_GOALS';
export const ALT_TOTAL_GOALS_NUMBER_OF_WINNERS_BY_LINE = 1;
export const COMBINED_NUMBER_OF_WINNERS_BY_LINE = 1;

export const EXPOSURE_LIMIT_ERROR_CODE = 'EX010';
export const LOSS_LIMIT_ERROR_CODE = 'EX011';
export const EXPOSURE_LIMIT_ERROR_TITLE = 'responsibleBetting.titles.exposureLimit';
export const LOSS_LIMIT_ERROR_TITLE = 'responsibleBetting.titles.lossLimit';

export const getDefaultPriceIncrement = (size: number) => {
  switch (true) {
    case size < 2:
      return 0.01;
    case size >= 2 && size < 3:
      return 0.02;
    case size >= 3 && size < 4:
      return 0.05;
    case size >= 4 && size < 6:
      return 0.1;
    case size >= 6 && size < 10:
      return 0.2;
    case size >= 10 && size < 20:
      return 0.5;
    case size >= 20 && size < 30:
      return 1;
    case size >= 30 && size < 50:
      return 2;
    case size >= 50 && size < 100:
      return 5;
    case size >= 100:
      return 10;
    default:
      return 0.01;
  }
};

export const getDefaultGamePriceIncrement = (size: number) => {
  switch (true) {
    case size < 2:
      return 0.01;
    case size >= 2 && size < 3:
      return 0.02;
    case size >= 3 && size < 5:
      return 0.05;
    case size >= 5 && size < 10:
      return 0.1;
    case size >= 10 && size < 20:
      return 0.2;
    case size >= 20 && size < 30:
      return 0.5;
    case size >= 30 && size < 50:
      return 1;
    case size >= 50 && size < 100:
      return 2;
    case size >= 100 && size < 500:
      return 5;
    case size >= 500:
      return 10;
    default:
      return 0.01;
  }
};

export const getDefaultPriceFloor = (step: number) => {
  switch (step) {
    case 0.01:
      return 1.01;
    case 0.02:
      return 2;
    case 0.05:
      return 3;
    case 0.1:
      return 4;
    case 0.2:
      return 6;
    case 0.5:
      return 10;
    case 1:
      return 20;
    case 2:
      return 30;
    case 5:
      return 50;
    case 10:
      return 100;
    default:
      return 1.01;
  }
};

export const getDefaultGamePriceFloor = (step: number) => {
  switch (step) {
    case 0.01:
      return 1.01;
    case 0.02:
      return 2;
    case 0.05:
      return 3;
    case 0.1:
      return 5;
    case 0.2:
      return 10;
    case 0.5:
      return 20;
    case 1:
      return 30;
    case 2:
      return 50;
    case 5:
      return 100;
    case 10:
      return 500;
    default:
      return 1.01;
  }
};

export const getDefaultPriceMax = (step: number) => {
  switch (step) {
    case 0.01:
      return 2;
    case 0.02:
      return 3;
    case 0.05:
      return 4;
    case 0.1:
      return 6;
    case 0.2:
      return 10;
    case 0.5:
      return 20;
    case 1:
      return 30;
    case 2:
      return 50;
    case 5:
      return 100;
    case 10:
      return 1000;
    default:
      return 2;
  }
};

export const getDefaultGamePriceMax = (step: number) => {
  switch (step) {
    case 0.01:
      return 2;
    case 0.02:
      return 3;
    case 0.05:
      return 5;
    case 0.1:
      return 10;
    case 0.2:
      return 20;
    case 0.5:
      return 30;
    case 1:
      return 50;
    case 2:
      return 100;
    case 5:
      return 500;
    case 10:
      return 1000;
    default:
      return 2;
  }
};

export const PlacementErrorsIds = {
  BET_MIN_STAKE: 'EX013',
  BET_MAX_STAKE: 'EX014',
  BET_BACK_LESS_LAY: 'EX015',
  BET_INVALID_SIZE: 'EX016',
  BET_MIN_PRICE: 'EX017',
  BET_INVALID_PRICE: 'EX018',
  BET_OUTDATED_ODDS: 'EX026',
  RESTRICTED_MARKET: 'EX027',
  DIFFERENT_CURRENCY_MARKET: 'EX028',
  BET_OUTDATED_LINE: 'EX029'
} as const;

export type TPlacementErrorIdType = typeof PlacementErrorsIds[keyof typeof PlacementErrorsIds];
