import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import {
  ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE,
  ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM,
  ASIAN_VIEW_PAGE_PARAM
} from 'constants/asianView';
import { asianViewComponents as branding } from 'constants/branding';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { getDesktopCashoutPageSize } from 'redux/modules/appConfigs/selectors';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { fetchAsianBetSlipCashOutMarkets } from 'redux/modules/asianViewBetSlipCashOut';
import { fetchAsianViewCashOutMarkets } from 'redux/modules/asianViewCashOut';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchEventCashOutMarkets } from 'redux/modules/eventCashOut';
import { SportId } from 'types';

import styles from './styles.module.scss';

interface ReloadBtnProps {
  isShowTitle?: boolean;
  containerClassName?: string;
  isAsianViewBetSlip?: boolean;
  eventId?: string;
  labelClassName?: string;
  iconClassName?: string;
  onReload: () => void;
}

const ReloadBtn = ({
  isShowTitle,
  containerClassName = '',
  isAsianViewBetSlip,
  eventId,
  labelClassName = '',
  iconClassName = '',
  onReload
}: ReloadBtnProps) => {
  const dispatch = useDispatch();
  const { sportId = SportId.SOCCER } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const desktopCashOutPageSize = useSelector(getDesktopCashoutPageSize);
  const isMobile = useSelector(getIsMobileAsianView);

  const { asianViewCashOutPaginationButtons } = useDeviceSettings();

  const pageParamName = isAsianViewBetSlip
    ? ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE
    : eventId
    ? ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM
    : ASIAN_VIEW_PAGE_PARAM;

  const fetchContent = () => {
    if (isLoggedIn) {
      if (!asianViewCashOutPaginationButtons) {
        onReload();
      }

      const changePage = (page: number) => {
        searchParams.set(pageParamName, String(page));
        setSearchParams(searchParams);
      };

      const handleChangePage = asianViewCashOutPaginationButtons ? changePage : undefined;
      const page = asianViewCashOutPaginationButtons ? Number(searchParams.get(pageParamName)) : 0;

      if (isAsianViewBetSlip) {
        dispatch(
          fetchAsianBetSlipCashOutMarkets({
            page,
            size: desktopCashOutPageSize,
            withLoader: true,
            resetPrev: true,
            changePage: handleChangePage,
            isPaginationEnabled: asianViewCashOutPaginationButtons,
            resetSettingsTabs: true
          })
        );
      } else if (eventId) {
        dispatch(
          fetchEventCashOutMarkets({
            page,
            size: desktopCashOutPageSize,
            withLoader: true,
            sportId,
            eventId,
            resetPrev: true,
            changePage: handleChangePage,
            isPaginationEnabled: asianViewCashOutPaginationButtons,
            resetSettingsTabs: true
          })
        );
      } else {
        dispatch(
          fetchAsianViewCashOutMarkets({
            page,
            size: desktopCashOutPageSize,
            withLoader: true,
            resetPrev: true,
            changePage: handleChangePage,
            isPaginationEnabled: asianViewCashOutPaginationButtons,
            resetSettingsTabs: true
          })
        );
      }
    }
  };

  return (
    <button
      className={classNames(styles.refresh, containerClassName, {
        [branding.SECONDARY_BTN]: !isMobile
      })}
      onClick={fetchContent}
    >
      {isShowTitle && <span className={labelClassName}>{t('asianView.labels.cashOut.refresh')}</span>}
      <i className={classNames('av-icon av-icon-refresh-page', styles.refresh__icon, iconClassName)} />
    </button>
  );
};

export default ReloadBtn;
