import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { BannerNames } from 'constants/app';
import { ASIAN_VIEW_MORE_URL } from 'constants/locations';
import {
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getIsMobileMenuButtonTextEnabled,
  getIsPropertiesLoaded,
  getLanguage,
  getPositionOfAsianViewMobileNavigationMenu
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setIsAsianViewMenuOpen } from 'redux/modules/appSettings';
import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import { fetchAsianViewPopularLinks } from 'redux/modules/asianViewPopularLinks';
import {
  getAsianViewPopularLinks,
  getIsAsianViewPopularLinksLoaded
} from 'redux/modules/asianViewPopularLinks/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchBanner } from 'redux/modules/banners';
import { getBannerByName } from 'redux/modules/banners/selectors';
import { BannerSections } from 'redux/modules/banners/type';
import { getIsUserInfoLoaded } from 'redux/modules/user/selectors';

import styles from './styles.module.scss';

type AsianMoreLinkProps = {
  className?: string;
};

const AsianMoreLink = ({ className = '' }: AsianMoreLinkProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const popularLinks = useSelector(getAsianViewPopularLinks);
  const isMobileMenuButtonTextEnabled = useSelector(getIsMobileMenuButtonTextEnabled);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const language = useSelector(getLanguage);
  const isUserInfoLoaded = useSelector(getIsUserInfoLoaded);
  const banner = useSelector(getBannerByName(BannerNames.homePage));
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isPopularLinksLoaded = useSelector(getIsAsianViewPopularLinksLoaded);
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);
  const positionOfAsianViewMobileNavigationMenu = useSelector(getPositionOfAsianViewMobileNavigationMenu);

  useEffect(() => {
    if (language && isUserInfoLoaded) {
      dispatch(fetchAsianViewPopularLinks());
      dispatch(
        fetchBanner({
          name: BannerNames.homePage,
          platform: Devices.MOBILE,
          section: BannerSections.HOME_PAGE_BANNER,
          locale: language
        })
      );
    }
  }, [isLoggedIn, language, isUserInfoLoaded]);

  if (
    isPopularLinksLoaded &&
    isPropertiesLoaded &&
    !popularLinks.length &&
    !isExchangeGamesEnabled &&
    !isExchangeSportsEnabled &&
    !banner?.bannerImages?.length &&
    !banner?.bannerHtmlContent?.content
  ) {
    return null;
  }

  return (
    <NavLink
      onClick={() => {
        dispatch(setIsAsianViewMenuOpen(false));
      }}
      to={ASIAN_VIEW_MORE_URL}
      className={({ isActive }) =>
        classNames(styles.asianMore, className, {
          biab_active: isActive,
          [styles.asianMore__active]: isActive,
          [styles.asianMore__bottom]: !isLandscapeAsianView && !positionOfAsianViewMobileNavigationMenu
        })
      }
    >
      <i className="av-icon av-more" data-class-nav-icon="ion-ios-more" />
      {isMobileMenuButtonTextEnabled && <span>{t('asianView.labels.navigation.more')}</span>}
    </NavLink>
  );
};

export default AsianMoreLink;
