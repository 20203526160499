import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SettingsBetting from 'components/HeaderNavigation/components/SettingsBetting';
import SettingsContextualHelp from 'components/HeaderNavigation/components/SettingsContextualHelp';
import SettingsFillOrKill from 'components/HeaderNavigation/components/SettingsFillOrKill';
import SettingsQuickBets from 'components/HeaderNavigation/components/SettingsQuickBets';
import MobileAccountNavigation from 'components/MobileAccountNavigation';
import { ExchangeTypes } from 'constants/myBets';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useElementSize from 'hooks/useElementSize';
import {
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled
} from 'redux/modules/appConfigs/selectors';
import { setElementHeight, setMobileSettingsActiveTab } from 'redux/modules/appSettings';
import { getMobileSettingsActiveTab } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';

import ProductsTabs from './components/ProductsTabs';

import styles from './styles.module.scss';

const MobileSettings = () => {
  const dispatch = useDispatch();

  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const settingsActiveTab = useSelector(getMobileSettingsActiveTab);
  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height }));
    }
  });
  const { fillOrKill, quickstakeBetslip, defStake, confirmBetsBeforePlace } = useDeviceSettings();
  const isBettingSettings = confirmBetsBeforePlace || defStake;
  const isSportsGamesTab = settingsActiveTab === ExchangeTypes.SportsGames;
  const isQuickStakesEnabled = quickstakeBetslip && (isExchangeSportsEnabled || isExchangeGamesEnabled);

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (!isAsianViewEnabled && (isExchangeSportsEnabled || isExchangeGamesEnabled)) {
      dispatch(setMobileSettingsActiveTab(ExchangeTypes.SportsGames));
    }

    return () => {
      if (isAsianViewEnabled && isSportsGamesTab) {
        dispatch(setMobileSettingsActiveTab(ExchangeTypes.AsianView));
      }
    };
  }, [isAsianViewEnabled, isExchangeGamesEnabled, isExchangeSportsEnabled, isSportsGamesTab, settingsActiveTab]);

  return (
    <div ref={componentRef} className="biab_account-page">
      <MobileAccountNavigation />
      <ProductsTabs />
      <div className={styles.settings__content}>
        {isSportsGamesTab && (
          <>
            {isQuickStakesEnabled && <SettingsQuickBets />}
            {fillOrKill && <SettingsFillOrKill />}
          </>
        )}
        {(!isSportsGamesTab || isBettingSettings) && <SettingsBetting />}
        {isSportsGamesTab && <SettingsContextualHelp />}
      </div>
    </div>
  );
};

export default MobileSettings;
