import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getAsianViewCashOutSportsEnabled } from 'redux/modules/appConfigs/selectors';
import { SportId } from 'types';

const useAsianCashOut = () => {
  const { sportId = SportId.SOCCER } = useParams();
  const asianViewCashOutSportsEnabled = useSelector(getAsianViewCashOutSportsEnabled);

  return asianViewCashOutSportsEnabled.includes(sportId);
};

export default useAsianCashOut;
