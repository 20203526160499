import classNames from 'classnames';

import styles from './styles.module.scss';

interface MyBetsHeaderCellProps {
  value: string;
  className?: string;
}

const MyBetsHeaderCell = ({ value, className }: MyBetsHeaderCellProps) => {
  return <div className={classNames(styles.myBetsTableHeaderCell, className)}>{value}</div>;
};

export default MyBetsHeaderCell;
