import { IMarket } from 'types/markets';

export const getCellGroupsCount = (
  alwaysThreeGroups: boolean,
  isTopItem: boolean,
  sectionFullWidth: boolean,
  twoColumnView: boolean,
  betGroupsLength: number
) => {
  if (alwaysThreeGroups) {
    return 3;
  } else if ((!isTopItem && !sectionFullWidth) || twoColumnView) {
    return 1;
  }

  return betGroupsLength;
};

export const getIsEventWidgetEnabled = ({
  inningsFancy,
  inningsFours,
  inningsSixes,
  batsmen,
  otherLineMarkets,
  totalInningsRuns,
  fallOfWicket,
  key
}: {
  inningsFancy: IMarket[];
  inningsFours: IMarket[];
  inningsSixes: IMarket[];
  totalInningsRuns: IMarket[];
  batsmen: IMarket[];
  otherLineMarkets: IMarket[];
  fallOfWicket: IMarket[];
  key: 'videoStreamingEnabled' | 'matchStatEnabled';
}) => {
  if (inningsFancy.length) {
    return inningsFancy[0].event[key];
  } else if (inningsFours.length) {
    return inningsFours[0].event[key];
  } else if (inningsSixes.length) {
    return inningsSixes[0].event[key];
  } else if (totalInningsRuns.length) {
    return totalInningsRuns[0].event[key];
  } else if (batsmen.length) {
    return batsmen[0].event[key];
  } else if (otherLineMarkets.length) {
    return otherLineMarkets[0].event[key];
  } else if (fallOfWicket.length) {
    return fallOfWicket[0].event[key];
  }

  return false;
};
