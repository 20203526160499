import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { setWhatIfCurrentValues } from 'redux/modules/whatIf';

import { failureGetCurrentBets, fetchCurrentBets, setCurrentBetsLoading, successGetCurrentBets } from './index';
import { getCurrentBetsString } from './selectors';
import { TCurrentBet } from './type';

function* getCurrentBetsWorker(action: ReturnType<typeof fetchCurrentBets>) {
  try {
    const response: TCurrentBet[] = yield call(api.placement.getCurrentBets);
    const prevCurrentBets: string = yield select(getCurrentBetsString);
    const stringifiedResponse = JSON.stringify(response);

    if (prevCurrentBets !== stringifiedResponse) {
      yield put(successGetCurrentBets({ bets: response, stringifiedBets: stringifiedResponse }));
    } else {
      yield put(setCurrentBetsLoading(false));
    }

    if (action.payload?.successCallback) {
      action.payload?.successCallback(response);
    }

    if (response.length) {
      yield put(setWhatIfCurrentValues(response));
    }
  } catch (error: any) {
    yield put(failureGetCurrentBets(error));
    if (action.payload?.errorCallback) {
      action.payload?.errorCallback(error);
    }
  }
}

export default function* saga() {
  yield all([takeLatest(fetchCurrentBets.type, getCurrentBetsWorker)]);
}
