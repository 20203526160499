import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

export const getAsianBetSlipCashOutMarketsTotalElements = ({ asianViewBetSlipCashOut }: AppState) =>
  asianViewBetSlipCashOut.marketsData.totalElements;
export const getAsianBetSlipCashOutMarketsTotalPages = ({ asianViewBetSlipCashOut }: AppState) =>
  asianViewBetSlipCashOut.marketsData.totalPages;
export const getAsianBetSlipCashOutMarketsContent = ({ asianViewBetSlipCashOut }: AppState) =>
  asianViewBetSlipCashOut.marketsData.content;
export const getAsianBetSlipCashOutMarketsContentMarketIds = createSelector(
  getAsianBetSlipCashOutMarketsContent,
  markets => markets?.map(({ marketId }) => marketId) ?? []
);
export const getAsianBetSlipCashOutPaginationLoading = ({ asianViewBetSlipCashOut }: AppState) =>
  asianViewBetSlipCashOut.paginationLoading;
export const getAsianBetSlipCashOutIsFirstMarketsLoaded = ({ asianViewBetSlipCashOut }: AppState) =>
  asianViewBetSlipCashOut.isFirstMarketsLoaded;
export const getAsianBetSlipCashOutMarketsLoading = ({ asianViewBetSlipCashOut }: AppState) =>
  asianViewBetSlipCashOut.marketsLoading;
export const getAsianBetSlipCashOutQuotesLoading = ({ asianViewBetSlipCashOut }: AppState) =>
  asianViewBetSlipCashOut.quotesLoading;
export const getAsianBetSlipCashOutQuoteByMarketId =
  (marketId: string) =>
  ({ asianViewBetSlipCashOut }: AppState) =>
    asianViewBetSlipCashOut.quotes[marketId];
export const getAsianBetSlipCashOutQuoteCurrencyByMarketId =
  (marketId: string) =>
  ({ asianViewBetSlipCashOut }: AppState) =>
    asianViewBetSlipCashOut.quotes[marketId]?.currency;
export const getAsianBetSlipQuoteLiabilityByMarketId =
  (marketId: string) =>
  ({ asianViewBetSlipCashOut }: AppState) =>
    asianViewBetSlipCashOut.quotes[marketId]?.currentLiability;
export const getAsianBetSlipPendingCashOutByMarketId =
  (marketId: string) =>
  ({ asianViewBetSlipCashOut }: AppState) =>
    asianViewBetSlipCashOut.pendingCashOuts[marketId];
export const getAsianBetSlipCashOutStatusByMarketId =
  (marketId: string) =>
  ({ asianViewBetSlipCashOut }: AppState) =>
    asianViewBetSlipCashOut.cashOutStatuses[marketId];
export const getAsianBetSlipAutoCashOutStatusByMarketId =
  (marketId: string) =>
  ({ asianViewBetSlipCashOut }: AppState) =>
    asianViewBetSlipCashOut.autoCashOutMarkets[marketId]?.status;
export const getAsianBetSlipCashOutSettingsTabByMarketId =
  (marketId: string) =>
  ({ asianViewBetSlipCashOut }: AppState) =>
    asianViewBetSlipCashOut.settingTabsStates[marketId];
export const getAsianBetSlipAutoCashOutByMarketId =
  (marketId: string) =>
  ({ asianViewBetSlipCashOut }: AppState) =>
    asianViewBetSlipCashOut.autoCashOutMarkets[marketId];
export const getAsianBetSlipCashOutPlaceIdByMarketId =
  (marketId: string) =>
  ({ asianViewBetSlipCashOut }: AppState) =>
    asianViewBetSlipCashOut.placedIds[marketId];
export const getAsianBetSlipCashOutPossibleProfitByMarketId =
  (marketId: string) =>
  ({ asianViewBetSlipCashOut }: AppState) =>
    asianViewBetSlipCashOut.possibleProfits[marketId];
export const getAsianBetSlipCashOutSuccessFullCreatedAutoCashOutByMarketId =
  (marketId: string) =>
  ({ asianViewBetSlipCashOut }: AppState) =>
    asianViewBetSlipCashOut.successFullCreatedAutoCashOuts[marketId];
export const getAsianBetSlipCashOutSuccessFullDeletedAutoCashOutByMarketId =
  (marketId: string) =>
  ({ asianViewBetSlipCashOut }: AppState) =>
    asianViewBetSlipCashOut.successFullDeletedAutoCashOuts[marketId];
export const getAsianBetSlipCashOutCashedValueByMarketId =
  (marketId: string) =>
  ({ asianViewBetSlipCashOut }: AppState) =>
    asianViewBetSlipCashOut.cashedValues[marketId];
export const getAsianBetSlipPendingCashOuts = ({ asianViewBetSlipCashOut }: AppState) =>
  asianViewBetSlipCashOut.pendingCashOuts;
export const getIsAsianBetSlipCashOutMarketsLastPage = ({ asianViewBetSlipCashOut }: AppState) =>
  asianViewBetSlipCashOut.marketsData.last;
export const getIsAsianBetSlipCashOutFirstQuotesLoaded = ({ asianViewBetSlipCashOut }: AppState) =>
  asianViewBetSlipCashOut.isFirstQuotesLoaded;
export const getAsianBetSlipCashOutQuotes = ({ asianViewBetSlipCashOut }: AppState) => asianViewBetSlipCashOut.quotes;
export const getAsianBetSlipCashOutQuotesMarketsIds = createSelector(getAsianBetSlipCashOutQuotes, quotes =>
  Object.keys(quotes)
);
export const getAsianViewBetSlipStringifiedAutoCashOutMarkets = ({ asianViewBetSlipCashOut }: AppState) =>
  asianViewBetSlipCashOut.stringifiedAutoCashOutMarkets;
