import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM,
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_POPULAR_LINK_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  AsianViewMarketLink,
  AsianViewPlacementPages
} from 'constants/asianView';
import { PARAMS_ASIAN_SEARCH_KEY } from 'constants/urlParams';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { EAsianMobileNav } from 'types/asianView';

import useAsianView from './useAsianView';

const useAsianPlacementPage = (betMarketLink = '') => {
  const { marketLink, timeFilter = EAsianMobileNav.TODAY } = useParams();
  const [searchParams] = useSearchParams();
  const { isAsianBetList, isCashOutUrl, isAsianFavouritesPage } = useAsianView();
  const isMobileAsianView = useSelector(getIsMobileAsianView);

  const searchQuery = searchParams.get(PARAMS_ASIAN_SEARCH_KEY) || '';
  const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
  const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
  const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);
  const popularLinkCompetitionId = searchParams.get(ASIAN_POPULAR_LINK_COMPETITION_ID);
  const singleViewCompetitionId = searchParams.get(ASIAN_SINGLE_VIEW_COMPETITION_ID);
  const singleViewEventId = searchParams.get(ASIAN_SINGLE_VIEW_EVENT_ID);
  const singleViewSportId = searchParams.get(ASIAN_SINGLE_VIEW_SPORT_ID);
  const isPopularLinkView = !!popularLinkCompetitionId;
  const isFavouriteView = !!(favouriteType && favouriteId && favouriteSportId) || isAsianFavouritesPage;
  const outrightCompetitionId = searchParams.get(ASIAN_OUTRIGHT_COMPETITION_ID);
  const isSingleOutrightView = !!outrightCompetitionId;
  const curMarketLinkPage = (marketLink || betMarketLink) as keyof typeof AsianViewPlacementPages;
  const timePart = isMobileAsianView ? '' : ` / ${timeFilter.toUpperCase()}`;

  if (isAsianBetList && isMobileAsianView) {
    return AsianViewPlacementPages.betList;
  }

  if (isCashOutUrl) {
    return `${AsianViewPlacementPages.cashOut}${timePart}`;
  }

  if (searchQuery?.length) {
    return `${AsianViewPlacementPages.search}${timePart}`;
  }

  if (isFavouriteView) {
    return `${AsianViewPlacementPages.favs}${timePart}`;
  }

  if (singleViewEventId && !!singleViewSportId) {
    return `${AsianViewPlacementPages.singleEvent}${timePart}`;
  }

  if ((singleViewCompetitionId && !!singleViewSportId) || isPopularLinkView || isSingleOutrightView) {
    return `${AsianViewPlacementPages.singleCompetition}${timePart}`;
  }

  if (isMobileAsianView) {
    if (curMarketLinkPage === AsianViewMarketLink.HDP_AND_OU) {
      return timeFilter.toUpperCase();
    }
  }

  return `${AsianViewPlacementPages[curMarketLinkPage]}${timePart}`;
};

export default useAsianPlacementPage;
