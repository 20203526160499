import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import {
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_OUTRIGHT_MARKET_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  AsianViewMarketLink,
  AsianViewTimeFilter
} from 'constants/asianView';
import { ASIAN_BASE_URL, GAMES_BASE_URL, SPORT_BASE_URL } from 'constants/locations';
import { MARKET_TYPES } from 'constants/marketTypes';
import { BetStatuses } from 'constants/myBets';
import { getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { TMyBetsContentItem } from 'redux/modules/myBets/type';
import { BettingType } from 'types/markets';
import { parseMillisecondsToTime } from 'utils/date';
import { roundedPriceLineBet } from 'utils/myBetsValues';

import GameInfoRow from '../GameInfoRow';

import styles from './styles.module.scss';

interface DescriptionCellProps {
  bet: TMyBetsContentItem;
  currentStatus?: BetStatuses;
  onClickEventName?: (value: boolean) => void;
}

const DescriptionCell = ({ bet, currentStatus, onClickEventName }: DescriptionCellProps) => {
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);

  const isCurrentStatus = currentStatus === BetStatuses.Matched || currentStatus === BetStatuses.Unmatched;
  const isGame = bet.betType === BettingType.GAME;
  const marketUrl = `/market/${bet!.marketId}`;
  const sportMarketUrl = `${SPORT_BASE_URL}/${bet.eventTypeId}${marketUrl}`;
  const sportUrl = `${SPORT_BASE_URL}/${bet.eventTypeId}${bet.raceName ? marketUrl : `/event/${bet!.eventId}`}`;
  const gameUrl = `${GAMES_BASE_URL}/${bet.eventTypeId}`;
  const asianViewEventUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilter.Today
  }/marketLink/${AsianViewMarketLink.HDP_AND_OU}?${ASIAN_SINGLE_VIEW_EVENT_ID}=${
    bet!.eventId
  }&${ASIAN_SINGLE_VIEW_SPORT_ID}=${bet.eventTypeId}`;
  const asianViewOutrightUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilter.Today
  }/marketLink/${AsianViewMarketLink.OUTRIGHT}?${ASIAN_OUTRIGHT_COMPETITION_ID}=${bet!.competitionId}&page=0`;
  const asianViewOutrightMarketUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilter.Today
  }/marketLink/${AsianViewMarketLink.OUTRIGHT}?${ASIAN_OUTRIGHT_COMPETITION_ID}=${
    bet!.competitionId
  }&${ASIAN_OUTRIGHT_MARKET_ID}=${bet!.marketId}&page=0`;
  const isUrlEnabled = isGame || bet.raceName || bet.eventId;
  const navUrl = isGame ? gameUrl : sportUrl;
  const asianUrl = bet?.outright ? asianViewOutrightUrl : asianViewEventUrl;
  const navName = bet.betType === BettingType.GAME ? bet.sportName : bet.eventName;
  const startTime = parseMillisecondsToTime(bet.marketStartDate || 0, timezone, timezoneCookieEnabled);
  const marketName = (bet.raceName ? startTime + ' ' + bet.raceName + ' - ' : '') + bet.marketName;
  const score = bet.marketType === MARKET_TYPES.asianHandicap && bet.score ? ` (${bet.score})` : '';

  const showGamePopup = () => {
    if (onClickEventName) {
      onClickEventName(true);
    }
  };

  return (
    <div className={styles.descriptionWrapper}>
      <table data-type="description">
        <tbody>
          <tr>
            <td>
              <span className={styles.myBetsDescriptionColumn}>
                {isCurrentStatus ? (
                  <>
                    {isUrlEnabled ? (
                      <Link
                        className={classNames('biab_eventName', styles.eventName)}
                        to={bet.asian ? asianUrl : navUrl}
                      >
                        <strong>{navName}</strong>
                      </Link>
                    ) : (
                      <strong className={classNames('biab_eventName', styles.eventName)}>{navName}</strong>
                    )}
                  </>
                ) : (
                  <span
                    className={classNames('biab_eventName', styles.eventName, {
                      [styles.eventName__game]: isGame
                    })}
                    onClick={isGame ? showGamePopup : () => null}
                  >
                    <strong>{bet.betType === BettingType.GAME ? bet.sportName : bet.eventName}</strong>
                  </span>
                )}
                <span className={classNames('biab_selectionName', styles.secondDescriptionLine)}>
                  <span className={styles.selectionLine}>
                    <strong className={styles.selectionName}>
                      {bet.selectionName}
                      {score}
                      {bet.bettingType === BettingType.LINE && ` ${roundedPriceLineBet(bet)}`}
                    </strong>
                  </span>{' '}
                  {!isGame && (
                    <>
                      {isCurrentStatus ? (
                        <>
                          {(isUrlEnabled && !bet.asian) || bet.outright ? (
                            <Link
                              className={styles.selectionName}
                              to={bet.asian && bet.outright ? asianViewOutrightMarketUrl : sportMarketUrl}
                            >
                              {marketName}
                            </Link>
                          ) : (
                            marketName
                          )}
                        </>
                      ) : (
                        <span className={styles.selectionName}>{marketName}</span>
                      )}
                    </>
                  )}
                </span>
                <span className={classNames('biab_groupName', styles.groupName)}>
                  <GameInfoRow bet={bet} currentStatus={currentStatus} />
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DescriptionCell;
