import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import NavSkeleton from 'components/Skeleton/NavSkeleton';
import AllSportsView from 'components/SportsSection/AllSportsView';
import CompetitionTimeFilterView from 'components/SportsSection/CompetitionTimeFilterView';
import CompetitionView from 'components/SportsSection/CompetitionView';
import CountryView from 'components/SportsSection/CountryView';
import EventView from 'components/SportsSection/EventView';
import FancyView from 'components/SportsSection/FancyView';
import GroupView from 'components/SportsSection/GroupView';
import MarketView from 'components/SportsSection/MarketView';
import AllSportsButton from 'components/SportsSection/partials/AllSportsButton';
import SportView from 'components/SportsSection/SportView';
import styles from 'components/SportsSection/styles.module.scss';
import { POKER_STAR } from 'constants/icons';
import { HOME_BASE_URL } from 'constants/locations';
import useElementSize from 'hooks/useElementSize';
import { getAppDevice, getOperator, getPropertiesTours } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  getCompetitionLoading,
  getCountryLoading,
  getEventDataLoading,
  getGroupLoading,
  getMarketLoading,
  getRaceLoading,
  getSportLoading,
  getTodayCardPageLoading
} from 'redux/modules/competitions/selectors';
import { getSportsLoading } from 'redux/modules/sports/selectors';
import { SportId } from 'types';
import { iosBundleRedirect } from 'utils';

const Content = () => {
  const dispatch = useDispatch();
  const { sportId, competitionId, countryId, eventId, marketId, groupId, fancyId, timeFilter } = useParams();

  const device = useSelector(getAppDevice);
  const operator = useSelector(getOperator);
  const tours = useSelector(getPropertiesTours);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const headerHeight = useSelector(getElementHeightByName(EElementNames.HEADER_HEIGHT));
  const howToSectionHeight = useSelector(getElementHeightByName(EElementNames.HOW_TO_SECTION_HEIGHT));
  const collapseHeight = useSelector(getElementHeightByName(EElementNames.COLLAPSE_HEIGHT));
  const activeMenuHeight = useSelector(getElementHeightByName(EElementNames.ACTIVE_MENU_HEIGHT));
  const navigationHideNavbarButton = useSelector(getElementHeightByName(EElementNames.NAVIGATION_HIDE_NAVBAR_BUTTON));
  const favoritesMenuHeight = useSelector(getElementHeightByName(EElementNames.FAVORITES_MENU_HEIGHT));
  const contextualHelp = useSelector(getElementHeightByName(EElementNames.CONTEXTUAL_HELP));
  const allSportsLoading = useSelector(getSportsLoading);
  const todayCardPageLoading = useSelector(getTodayCardPageLoading);
  const sportLoading = useSelector(getSportLoading);
  const raceLoading = useSelector(getRaceLoading);
  const eventLoading = useSelector(getEventDataLoading);
  const groupLoading = useSelector(getGroupLoading);
  const marketLoading = useSelector(getMarketLoading);
  const competitionLoading = useSelector(getCompetitionLoading);
  const countryLoading = useSelector(getCountryLoading);

  let contentHeight: string | number = '100%';

  if (device === Devices.DESKTOP) {
    let collapsesCount = 4;

    if (!isLoggedIn) collapsesCount -= 1;
    if (!tours.length) collapsesCount -= 1;

    const sumToMinus =
      headerHeight +
      (tours.length ? howToSectionHeight : 0) +
      (isLoggedIn ? favoritesMenuHeight : 0) +
      collapseHeight * collapsesCount +
      activeMenuHeight +
      navigationHideNavbarButton +
      contextualHelp;
    contentHeight = window.innerHeight - sumToMinus;
  }

  const isTodayCard = marketId?.startsWith('tc') || sportId?.startsWith('tc');
  const isNavigationLoading =
    todayCardPageLoading ||
    sportLoading ||
    raceLoading ||
    eventLoading ||
    groupLoading ||
    marketLoading ||
    competitionLoading ||
    countryLoading ||
    allSportsLoading;

  const showPokerstarFooter = operator === POKER_STAR && iosBundleRedirect;

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.NAVIGATION_ALL_SPORTS_CONTENT_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.NAVIGATION_ALL_SPORTS_CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [sportId, allSportsLoading]);

  const renderViewByParam = () => {
    if (timeFilter) {
      if (device === Devices.DESKTOP || sportId !== SportId.TENNIS) {
        return <Navigate to={HOME_BASE_URL} />;
      }
      return <CompetitionTimeFilterView />;
    }
    if (competitionId) {
      return <CompetitionView />;
    }
    if (countryId) {
      return <CountryView />;
    }
    if (eventId && !fancyId) {
      return <EventView />;
    }
    if (marketId) {
      return <MarketView />;
    }
    if (groupId) {
      return <GroupView />;
    }
    if (fancyId) {
      return <FancyView />;
    }
    if (sportId) {
      return <SportView />;
    }
    return <AllSportsView />;
  };

  return (
    <>
      {allSportsLoading && <NavSkeleton withDelay={150} itemsCount={40} />}
      <ul
        className={classNames(
          'biab_navigation-section',
          'biab_max-nav-item',
          'biab_navigation-tems',
          'js-navigation-items',
          'js-navigation-menu-region',
          'biab_favourites-wrap',
          styles.sportsList,
          {
            hidden: allSportsLoading
          }
        )}
        style={
          device === Devices.DESKTOP && !isTodayCard
            ? {
                height: showPokerstarFooter ? 'none' : contentHeight,
                overflowY: 'auto'
              }
            : {}
        }
      >
        <div ref={componentRef}>
          <ul
            id="biab_navigationItems"
            className={classNames(
              'biab_navigationItems biab_navigation-tems biab_a-to-z-sports',
              styles.navigationItems,
              {
                'biab_navigation-items': device === Devices.MOBILE
              }
            )}
          >
            {device === Devices.DESKTOP && sportId && !isNavigationLoading && <AllSportsButton />}
            {renderViewByParam()}
          </ul>
        </div>
      </ul>
    </>
  );
};

export default Content;
