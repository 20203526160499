import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import { SLICES_NAMES } from 'constants/app';
import { TCurrentBet } from 'redux/modules/currentBets/type';

import { mapperToWhatIf } from './helpers';
import { TWhatIf } from './type';

const initialState: TWhatIf = {
  currentValues: {},
  hideInlinePlacementLoading: null
};

const slice = createSlice({
  name: SLICES_NAMES.WHAT_IF,
  initialState,
  reducers: {
    setWhatIfCurrentValues: (state, action: PayloadAction<TCurrentBet[]>) => {
      const newCurrentValues = mapperToWhatIf(action.payload);
      const currentValues = current(state);
      if (!isEqual(newCurrentValues, currentValues.currentValues)) {
        state.currentValues = mapperToWhatIf(action.payload);
      }
    },
    hideInlinePlacementWhatIf: (state, action: PayloadAction<boolean | null>) => {
      state.hideInlinePlacementLoading = action.payload;
    },
    resetWhatIf: state => {
      state.currentValues = {};
      state.hideInlinePlacementLoading = null;
    }
  }
});

export const { setWhatIfCurrentValues, hideInlinePlacementWhatIf, resetWhatIf } = slice.actions;

export default slice.reducer;
