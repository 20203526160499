import { ReactNode } from 'react';
import classNames from 'classnames';
import { unescape } from 'lodash';

import styles from './styles.module.scss';

type InfoProps = {
  message: string | ReactNode;
  customClassName?: string;
};

const Info = ({ message, customClassName }: InfoProps) => {
  return (
    <div className={classNames(styles.info, customClassName)}>
      <span className={styles.icon}>
        <i className="av-icon av-icon-info">
          <span className="path1" />
          <span className="path2" />
        </i>
      </span>
      {typeof message === 'string' ? (
        <span className={styles.text} dangerouslySetInnerHTML={{ __html: unescape(message || '') }} />
      ) : (
        message
      )}
    </div>
  );
};

export default Info;
