import { useTranslation } from 'react-i18next';
import { Link, To } from 'react-router-dom';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface MobileAllMarketsLinkProps {
  to: To;
}

const MobileAllMarketsLink = ({ to }: MobileAllMarketsLinkProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('biab_all-markets-link biab_all-markets-link-bg', styles.allMarkets)}>
      <Link to={to} className={styles.allMarkets__link}>
        <span>{t('market.allMarkets')}</span>
        <i className="fa2 fa2-arrow-right biab_arrow-icon" />
      </Link>
    </div>
  );
};

export default MobileAllMarketsLink;
