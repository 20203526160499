import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unescape } from 'lodash';

import Modal from 'components/Modal';
import { getCustomPopUpEnabled } from 'redux/modules/appConfigs/selectors';
import { setIsCollapsedRightSide } from 'redux/modules/asianView';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { setIsOpenedAsianViewCustomPopUpLink } from 'redux/modules/asianViewPopularLinks';
import {
  getAsianViewCustomPopUpPopularLink,
  getAsianViewPopularLinks,
  getIsOpenedAsianViewCustomPopUpLink
} from 'redux/modules/asianViewPopularLinks/selectors';
import { AsianPopularLinkEntryType } from 'redux/modules/asianViewPopularLinks/type';

import PopularLinksHeader from '../PopularLinks/components/PopularLinksHeader';

import AsianViewCompetitionPopularLink from './components/AsianViewCompetitionPopularLink';
import AsianViewCustomPopUpPopularLink from './components/AsianViewCustomPopUpPopularLink';
import AsianViewRegularPopularLink from './components/AsianViewRegularPopularLink';

import styles from './styles.module.scss';

type AsianViewPopularLinksProps = {
  linkClassName?: string;
  hideHeader?: boolean;
  arrowClassName?: string;
  noTruncate?: boolean;
};

const AsianViewPopularLinks = ({
  linkClassName = '',
  hideHeader = false,
  arrowClassName = '',
  noTruncate = false
}: AsianViewPopularLinksProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const popularLinks = useSelector(getAsianViewPopularLinks);
  const customPopUpLink = useSelector(getAsianViewCustomPopUpPopularLink);
  const customPopUpEnabled = useSelector(getCustomPopUpEnabled);
  const isOpenedCustomPopUpLink = useSelector(getIsOpenedAsianViewCustomPopUpLink);
  const isMobile = useSelector(getIsMobileAsianView);

  const popupPrefix = isMobile ? 'mobile.' : '';

  const handleClose = () => {
    dispatch(setIsOpenedAsianViewCustomPopUpLink(false));
    dispatch(setIsCollapsedRightSide(true));
  };

  if (!popularLinks.length) {
    return null;
  }

  return (
    <>
      {!hideHeader && <PopularLinksHeader />}
      {popularLinks.map(link => {
        if (link.entryType === AsianPopularLinkEntryType.CUSTOM_POP_UP) {
          if (customPopUpEnabled) {
            return (
              <AsianViewCustomPopUpPopularLink
                key={link.id || link.entryType + link.entryName}
                link={link}
                linkClassName={linkClassName}
                arrowClassName={arrowClassName}
                noTruncate={noTruncate}
              />
            );
          }

          return null;
        }

        if (link.entryType === AsianPopularLinkEntryType.COMPETITION) {
          return (
            <AsianViewCompetitionPopularLink
              link={link}
              key={link.id || link.entryType + link.entryName}
              linkClassName={linkClassName}
              arrowClassName={arrowClassName}
              noTruncate={noTruncate}
            />
          );
        }

        return (
          <AsianViewRegularPopularLink
            link={link}
            key={link.id || link.entryType + link.entryName}
            linkClassName={linkClassName}
            arrowClassName={arrowClassName}
            noTruncate={noTruncate}
          />
        );
      })}
      {customPopUpLink && customPopUpEnabled && isOpenedCustomPopUpLink && (
        <Modal
          open={!!customPopUpLink && customPopUpEnabled && isOpenedCustomPopUpLink}
          onClose={handleClose}
          clickableBackground
          title={t(`${popupPrefix}custom.pop.up.title`)}
        >
          <>
            <div dangerouslySetInnerHTML={{ __html: unescape(t(`${popupPrefix}custom.pop.up.body`)) }} />
            {customPopUpLink?.settings?.customPopUpCloseButtonEnabled && (
              <div className={styles.modal__btnGroup}>
                <div onClick={handleClose} className={styles.modal__btnClose}>
                  {t(`${popupPrefix}custom.pop.up.button`)}
                </div>
              </div>
            )}
          </>
        </Modal>
      )}
    </>
  );
};

export default AsianViewPopularLinks;
