import classNames from 'classnames';

import useDevice from 'hooks/useDevice';

import styles from './styles.module.scss';

interface TabProps {
  tab: any;
  setActiveTabButton: any;
  activeTabButton?: string;
  classname?: string | { [x: string]: boolean };
  tabName?: string;
}

const Tab = ({ tab, setActiveTabButton, activeTabButton, classname, tabName }: TabProps) => {
  const { isMobile } = useDevice();

  return (
    <li
      onClick={() => setActiveTabButton(tab)}
      className={classNames(styles.tabItem, classname, {
        [styles.tabItem__active]: activeTabButton === tab,
        [styles.tabItem__mobile]: isMobile
      })}
    >
      <div
        className={classNames('biab_btn-tab', styles.tabWrapper, {
          [styles.active]: activeTabButton === tab,
          biab_active: activeTabButton === tab
        })}
      >
        <a className={classNames(styles.link, 'biab_btn-tab', { biab_active: activeTabButton === tab })}>
          {tabName || tab}
        </a>
      </div>
    </li>
  );
};

export default Tab;
