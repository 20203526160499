import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getCurrentGameRound } from 'redux/modules/games/selectors';

import styles from './styles.module.scss';

const GameDerbyRacingTitle = () => {
  const { t } = useTranslation();
  const round = useSelector(getCurrentGameRound);

  const getRoundText = () => {
    if (!round) {
      return '';
    }
    switch (round) {
      case 1:
        return t('games.preHandicapBetting');
      case 2:
        return t('games.handicapRound');
      default:
        return `${t('games.round')} ${round - 2}`;
    }
  };

  return (
    <ul className={classNames(styles.title, 'biab_games-rounds-list')}>
      <li className={'biab_games-round-label'}>{getRoundText()}</li>
    </ul>
  );
};

export default GameDerbyRacingTitle;
