import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import marketHeaderStyles from 'components/EventHeaderMobileInPlay/styles.module.scss';
import FavoriteStar from 'components/FavoriteStar';
import BackButton from 'components/Header/components/BackButton';
import MarketDropdown from 'components/MarketDropdown';
import MatchStatistics from 'components/MatchStatistics';
import HeaderDetails from 'components/TimeForm/components/HeaderDetails';
import VideoStream from 'components/VideoStream';
import { RACING_SPORT } from 'constants/app';
import { MARKET_TYPES } from 'constants/marketTypes';
import useBackButtonInIFrame from 'hooks/useBackButtonInIFrame';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getAppDevice, getOperator } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { TFavorite } from 'redux/modules/favorites/type';
import { getSingleMarketInfo } from 'redux/modules/market/selectors';
import { LiveWidgetHeaderType, SportId } from 'types';
import { getIconNameForMobileNavigation } from 'utils/navigation';

import styles from './styles.module.scss';

interface EventHeaderProps {
  marketId: string;
  /**
   * Passed Event name translations
   */
  title: string;
  /**
   * Passed Event date
   */
  startDate: number;
  /**
   * Title class name, f.e. market or event
   */
  type: LiveWidgetHeaderType;
  /**
   * Sport ID
   */
  sportId: string;
  /**
   * Favorite data
   */
  favoriteData: TFavorite;
  /**
   * Is market on play?
   */
  inPlay: boolean;
  isCloseMarket?: boolean;
  videoStreamingEnabled: boolean;
  eventId: string;
  matchStatEnabled: boolean;
  showFavoriteStar: boolean;
}

const EventHeader = ({
  marketId,
  title,
  startDate,
  sportId,
  type,
  inPlay,
  isCloseMarket,
  favoriteData,
  videoStreamingEnabled = false,
  eventId,
  matchStatEnabled,
  showFavoriteStar
}: EventHeaderProps) => {
  const { t } = useTranslation();

  const market = useSelector(getSingleMarketInfo);
  const device = useSelector(getAppDevice);
  const operator = useSelector(getOperator);

  const marketType = market?.description.marketType;

  const marketTime = useLongDateFormat({
    time: startDate,
    hasWeekDay: true
  });
  const showIFrameBackButton = useBackButtonInIFrame();

  const isRacing = RACING_SPORT[sportId];
  const isDailyGoals = marketType === MARKET_TYPES.dailyGoals;
  const isShowFavorite = !isCloseMarket && !isRacing && !isDailyGoals && showFavoriteStar;
  const isHorseRacing = sportId === SportId.HORSE_RACING;
  const isRaceDetails = isHorseRacing && market?.timeformStatistics?.raceDetails;
  const isMobile = device === Devices.MOBILE;

  return (
    <div className={classNames({ [`${marketHeaderStyles.marketHeader} biab_market biab_market-title`]: marketId })}>
      <div className={classNames(`biab_has-icon biab_${type}-title`, styles.eventHeader, styles.eventHeader__desktop)}>
        <div
          className={classNames(styles.eventHeader__leftSide, {
            [styles.eventHeader__leftSide__withBackButton]: showIFrameBackButton
          })}
        >
          {showIFrameBackButton ? (
            <div className={styles.eventHeader__leftSide__backButtonWrapper}>
              <BackButton />
            </div>
          ) : (
            <i className={classNames('biab_br-sport-icon', getIconNameForMobileNavigation(sportId, operator))} />
          )}
          <div className={styles.eventHeader__title__wrapper}>
            <h2
              className={classNames(styles.eventHeader__title, {
                [styles.eventHeader__title__name]: isRaceDetails
              })}
            >
              {title}
            </h2>
            {!isCloseMarket && (
              <div className={styles.eventHeader__subTitle}>
                {inPlay ? (
                  <span className="biab_inplay-label">{t('header.inPlay')}</span>
                ) : (
                  <div className="biab_market-title">
                    <div className="biab_market-date">{marketTime}</div>
                  </div>
                )}
              </div>
            )}
            {isRaceDetails && (
              <div className="biab_timeform-event-info">
                <HeaderDetails details={market?.timeformStatistics!.raceDetails} />
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames(styles.eventHeader__rightSide, {
            [styles.eventHeader__rightSide__withBackButton]: showIFrameBackButton
          })}
        >
          {!isCloseMarket && (
            <>
              {videoStreamingEnabled && <VideoStream visible={videoStreamingEnabled} isInHeader eventId={eventId} />}
              {matchStatEnabled && (
                <MatchStatistics
                  isSoccer={sportId === SportId.SOCCER}
                  visible={matchStatEnabled}
                  isInHeader
                  isLeftSpace={videoStreamingEnabled}
                  eventId={eventId}
                />
              )}
              {isShowFavorite && (
                <div className={styles.eventHeader__favouriteIcon}>
                  <FavoriteStar entryData={favoriteData} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {isMobile && <MarketDropdown />}
    </div>
  );
};

export default memo(EventHeader);
