import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { ACCOUNT_RESET_TO_DEFAULT_STATE } from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';

import styles from '../../styles.module.scss';

interface ActionButtonsProps {
  activeTab: {
    translationKey: string;
    value: string;
  };
  editMode: { [key: string]: boolean };
  setEditMode: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  handleSaveQuickBets: () => void;
  handleResetQuickBets: () => void;
  handleCancelQuickBets: () => void;
  isWithErrors: boolean;
  isValueChanged: boolean;
}
const ActionButtons = ({
  activeTab,
  editMode,
  handleSaveQuickBets,
  handleResetQuickBets,
  handleCancelQuickBets,
  setEditMode,
  isWithErrors,
  isValueChanged
}: ActionButtonsProps) => {
  const { t } = useTranslation();
  const { translationKey, isEnabled } = useTooltip(ACCOUNT_RESET_TO_DEFAULT_STATE);

  const isSaveBtnDisabled = isWithErrors || !isValueChanged;

  return (
    <>
      {editMode[activeTab.value] ? (
        <div className={styles.quickBets__editModeBtnWrapper}>
          <button
            onClick={handleSaveQuickBets}
            className={classNames('biab_btn-save', styles.quickBets__saveBtn, styles.quickBets__btn, {
              'btn-disabled': isSaveBtnDisabled
            })}
            disabled={isSaveBtnDisabled}
          >
            {t('account.settings.quickBets.buttons.save')}
          </button>

          <button
            data-tooltip-id={'tooltip'}
            data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
            onClick={handleResetQuickBets}
            className={classNames('biab_btn-reset', styles.quickBets__resetBtn, styles.quickBets__btn, {
              'cursor-help': isEnabled
            })}
          >
            {t('account.settings.quickBets.buttons.reset')}
          </button>

          <button
            onClick={handleCancelQuickBets}
            className={classNames('biab_btn-cancel', styles.quickBets__cancelBtn, styles.quickBets__btn)}
          >
            {t('account.settings.quickBets.buttons.cancel')}
          </button>
        </div>
      ) : (
        <button
          onClick={() => setEditMode({ ...editMode, [activeTab.value]: true })}
          className={classNames('biab_btn-edit', styles.quickBets__editBtn, styles.quickBets__btn)}
        >
          {t('account.settings.quickBets.buttons.edit')}
        </button>
      )}
    </>
  );
};
export default ActionButtons;
