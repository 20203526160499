import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { SPORT_BASE_URL } from 'constants/locations';
import { TTimeFilterContext } from 'redux/modules/competitions/type';

import styles from '../../styles.module.scss';

const FilterItem = ({
  filter: { label, value },
  collapseName = ''
}: {
  filter: TTimeFilterContext;
  collapseName?: string;
}) => {
  const { sportId } = useParams();
  const navigate = useNavigate();

  const handleFilterItemClick = () => {
    navigate(`${SPORT_BASE_URL}/${sportId}/competition/${value.toLowerCase()}`);
  };

  return (
    <div className="biab_navigation-competition-category-time">
      <div>
        <li
          data-test-collapse={`${collapseName}_ITEM`}
          onClick={handleFilterItemClick}
          className={classNames('biab_navigation-competition-item-name', styles.filterItem, styles.sportsList__item)}
        >
          <a className="biab_navigation-competition-item-name">{label}</a>
        </li>
      </div>
    </div>
  );
};

export default FilterItem;
