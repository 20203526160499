import { NavLink } from 'react-router-dom';

import { getDefaultLinkTarget } from 'utils/navigation';

import AccountItem from '../AccountItem';

interface AccountLinkProps {
  url: string;
  title: string;
  isIframeEnabled: boolean;
  openInNewTab?: boolean;
  externalLink?: boolean;
}

const AccountLink = ({ url, title, openInNewTab, externalLink, isIframeEnabled }: AccountLinkProps) => {
  const target = getDefaultLinkTarget({
    url,
    absolutePath: true,
    isIframeEnabled,
    currentTarget: openInNewTab ? '_blank' : '_self',
    openInNewTab
  });

  return externalLink ? (
    <a href={url} target={target}>
      <AccountItem title={title} />
    </a>
  ) : (
    <NavLink to={url} target={target}>
      <AccountItem title={title} />
    </NavLink>
  );
};

export default AccountLink;
