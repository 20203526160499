import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loader, { CircleColors } from 'components/Loader';
import Modal from 'components/Modal';
import Rules from 'components/Rules';
import { ASIAN_BASE_URL } from 'constants/locations';
import { fetchMarketRules } from 'redux/modules/asianView';
import { getMarketRules, getMarketRulesLoading } from 'redux/modules/asianView/selectors';
import { ModalClasses } from 'types';
import { getEnvironmentRootPath } from 'utils/navigation';

type MarketRulesProps = {
  marketId: string;
  modalClasses?: ModalClasses;
};

const MarketRules = ({ marketId, modalClasses }: MarketRulesProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const rulesLoading = useSelector(getMarketRulesLoading);
  const rules = useSelector(getMarketRules(marketId));

  const [openRulesModal, setOpenRulesModal] = useState<boolean>(false);

  const handleOpenRulesModal = (event?: SyntheticEvent) => {
    event?.stopPropagation();
    if (!rules) {
      dispatch(fetchMarketRules(marketId));
    }
    setOpenRulesModal(true);
  };

  return (
    <>
      <Rules isHideText isSearch={false} onClick={handleOpenRulesModal} />
      <Modal
        clickableBackground
        open={openRulesModal}
        onClose={(event?: SyntheticEvent) => {
          event?.stopPropagation();
          setOpenRulesModal(false);
        }}
        title={rulesLoading ? t('marketRules.loading') : t('rules.title')}
        customClassNames={modalClasses}
      >
        {rulesLoading ? (
          <Loader circleColor={CircleColors.BLACK} />
        ) : (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: rules?.rules.replace(/CONTEXT_ROOT_PATH/g, `${getEnvironmentRootPath()}${ASIAN_BASE_URL}`) ?? ''
              }}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default MarketRules;
