import {
  AsianViewSizeValidationType,
  TAsianPlaceBet,
  TAsianSelectedBet,
  TAsianViewPlaceBetErrorMessageId,
  TPlacedBetException
} from 'redux/modules/asianViewBetslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { OddsType } from 'types';
import { BetTypes, THandicap, TPrice, TProfit, TSize } from 'types/bets';
import { EInputFieldTypes } from 'types/betslip';
import { TMarketLineRangeInfo, TPriceLadderDescription } from 'types/markets';

export type TAsianViewMobileBetslipState = {
  selectedBets: Record<string, TAsianMobileSelectedBet>;
  placedBets: Record<string, TAsianMobilePlacedBet>;
  rgErrorMessage: TPlacedBetException | null;
};

export const AsianViewMobileBetActions = {
  NEW: 'NEW',
  CONFIRM: 'CONFIRM',
  PROGRESS: 'PROGRESS',
  PLACED: 'PLACED',
  HIDDEN: 'HIDDEN',
  VALIDATION: 'VALIDATION'
} as const;

export type TAsianViewMobileBetActions = typeof AsianViewMobileBetActions[keyof typeof AsianViewMobileBetActions];

export type TAsianMobileSelectedBet = TAsianSelectedBet & {
  oddsType?: OddsType;
  betUuid?: string;
  action?: TAsianViewMobileBetActions;
  priceLadderDescription?: TPriceLadderDescription | null;
  eachWayDivisor?: number | null;
  lineRangeInfo?: TMarketLineRangeInfo | null;
  betslipId?: string;
  bettingType?: string;
  focusedField?: EInputFieldTypes | null;
  offerId?: number;
};

export type TAsianMobileUpdatedBet = {
  price?: TPrice;
  size: TSize;
  profit: TProfit;
  isValid?: boolean;
  validationMessage?: string;
  sizeValidationType?: AsianViewSizeValidationType;
  validationMessageId?: null | TAsianViewPlaceBetErrorMessageId;
  validSizeValue?: TSize;
  focusedField?: EInputFieldTypes | null;
  action?: TAsianViewMobileBetActions;
};

export type TAsianMobileUpdateData = {
  price?: TPrice;
  size?: TSize;
  profit?: TProfit;
  focusedField?: EInputFieldTypes | null;
  action?: TAsianViewMobileBetActions;
};

export type TAsianPlaceMobileBetPayload = {
  [marketId: string]: TAsianPlaceBet[];
};

export type TPlacedMobileBetByMarket = {
  [marketId: string]: TPlacedMobileBetInfo;
};

export type TPlacedMobileBetInfo = {
  identifier?: string;
  offerIds?: Record<string, number>;
  status: string;
  error: string | null;
  exception?: TPlacedBetException;
};

export type TAsianPlacedMobileBetPayload = TPlacedMobileBetByMarket;

export const AsianViewMobilePlacedBetStatuses = {
  PLACE: 'PLACE',
  CREATED: 'CREATED',
  GET_STATUS: 'GET_STATUS',
  PLACED: 'PLACED',
  ERROR: 'ERROR',
  OFFER: 'OFFER'
} as const;

export type TAsianViewMobilePlacedBetStatuses =
  typeof AsianViewMobilePlacedBetStatuses[keyof typeof AsianViewMobilePlacedBetStatuses];

export type TAsianMobilePlacedBet = {
  identifier: string;
  offerId?: number | null;
  price?: TPrice;
  handicap?: THandicap | null;
  status: TAsianViewMobilePlacedBetStatuses;
  placementError?: string | null;
  placementErrorCode?: string | null;
  placementErrorId?: TAsianViewPlaceBetErrorMessageId | null;
  competitionName?: string;
  eventName?: string;
  marketName?: string;
  selectionName?: string;
  betType?: BetTypes;
  oddsType?: OddsType;
  score?: string;
  order?: number;
  offerStatus?: BetsStatusesTypes;
  isLoading?: boolean;
  betslipId?: string;
};
