import { MutableRefObject, useEffect, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export type ElementSizes = {
  width: number;
  height: number;
  x?: number;
  notRoundedHeight?: number;
};

type Params = {
  isElementVisible?: boolean;
  onChangeSizesHandler?: (sizes: ElementSizes) => void;
  currentX?: number;
};

export default function useElementSize<T extends HTMLElement>(
  parameters?: Params
): [MutableRefObject<T | null>, ElementSizes] {
  const { isElementVisible = true, onChangeSizesHandler, currentX = 0 } = parameters || {};

  const [size, setSize] = useState<ElementSizes>({
    width: 0,
    height: 0,
    x: 0,
    notRoundedHeight: 0
  });

  const target = useRef<T | null>(null);

  const setRoundedSize = ({ width, height, x }: ElementSizes) => {
    const newSizes = { width: Math.round(width), height: Math.round(height) };

    if (onChangeSizesHandler) {
      onChangeSizesHandler({ ...newSizes, x: x || currentX, notRoundedHeight: height });
    } else {
      setSize({ ...newSizes, x: x || size.x, notRoundedHeight: height });
    }
  };

  // TODO most likely this useEffect should be deleted, because resize observer returns actual width
  useEffect(() => {
    if (target.current) {
      setRoundedSize(target.current.getBoundingClientRect());
    }
  }, [target, isElementVisible]);

  useResizeObserver(target, entry => {
    const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
    setRoundedSize({ width, height });
  });

  return [target, size];
}
