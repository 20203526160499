import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { CASH_OUT_BASE_URL } from 'constants/locations';

import styles from './styles.module.scss';

interface IPagination {
  itemsCount: number;
}

const Pagination = ({ itemsCount }: IPagination) => {
  const { t } = useTranslation();
  const { page: currentPage } = useParams();
  const navigate = useNavigate();

  const onClickLast = () => {
    if (currentPage === itemsCount.toString()) {
      return;
    }
    navigate(`${CASH_OUT_BASE_URL}/${itemsCount}`);
  };

  const onClickNext = () => {
    if (currentPage === itemsCount.toString()) {
      return;
    }
    navigate(`${CASH_OUT_BASE_URL}/${currentPage ? parseInt(currentPage) + 1 : 2}`);
  };

  const onClickPrev = () => {
    if (currentPage === '1') {
      return;
    }
    navigate(`${CASH_OUT_BASE_URL}/${currentPage ? parseInt(currentPage) - 1 : 1}`);
  };

  const onClickFirst = () => {
    if (currentPage === '1') {
      return;
    }
    navigate(`${CASH_OUT_BASE_URL}/${1}`);
  };

  const onClickDot = (index: number) => {
    navigate(`${CASH_OUT_BASE_URL}/${index + 1}`);
  };

  return (
    <div className={classNames('biab_pagination-wrapper', styles.main)}>
      <div
        className={classNames('biab_pagination-link', styles.left, {
          [`disabled ${styles.disabled}`]: currentPage === '1'
        })}
      >
        <div onClick={onClickFirst}>
          <i className={'fa fa-angle-double-left'} />
          &nbsp;
          <span>{t('pagination.first')}</span>
        </div>
        <div onClick={onClickPrev}>
          <i className="fa fa-angle-left" />
          &nbsp;
          <span>{t('pagination.previous')}</span>
        </div>
      </div>
      <div className={styles.dots}>
        {[...new Array(itemsCount)].map((i, index) => (
          <div
            onClick={() => onClickDot(index)}
            key={index}
            className={classNames({
              [styles.active]: index + 1 === (currentPage ? parseInt(currentPage) : 1)
            })}
          />
        ))}
      </div>
      <div
        className={classNames('biab_pagination-link', styles.right, {
          [`disabled ${styles.disabled}`]: currentPage === itemsCount.toString()
        })}
      >
        <div onClick={onClickNext}>
          <span>{t('pagination.next')}</span>
          &nbsp;
          <i className={'fa fa-angle-right'} />
        </div>
        <div onClick={onClickLast}>
          <span>{t('pagination.last')}</span>
          &nbsp;
          <i className={'fa fa-angle-double-right'} />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
