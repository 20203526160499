import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { SPORT_BASE_URL } from 'constants/locations';
import { ViewAllMarketClasses } from 'types/markets';

import styles from './styles.module.scss';

interface ViewFullMarketProps {
  marketId: string;
  sportId: string;
  classes?: ViewAllMarketClasses;
}

const ViewFullMarket = ({ marketId, sportId, classes }: ViewFullMarketProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('viewAllContainer', styles.viewAllContainer, classes?.container ?? '')}>
      <NavLink to={`${SPORT_BASE_URL}/${sportId}/market/${marketId}`} className="biab_full-market-link">
        <>
          {t('events.viewFullMarket')} <i className="biab_arrow-icon fa2 fa2-arrow-right" />
        </>
      </NavLink>
    </div>
  );
};

export default ViewFullMarket;
