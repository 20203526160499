import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';
import { IMarket, TFetchMarketRulesResponse } from 'types/markets';

import { TSportPage, TSportPageDetailsPayload, TSuccessFetchSportPageDetails } from './type';

const initialState: TSportPage = {
  marketCatalogueList: null,
  sportInfo: null,
  loading: false,
  error: null,
  rules: {},
  rulesLoading: false,
  rulesError: null
};

const slice = createSlice({
  name: SLICES_NAMES.SPORT_PAGE,
  initialState,
  reducers: {
    fetchSportPageDetails: (state, _: PayloadAction<TSportPageDetailsPayload>) => {
      state.loading = true;
    },
    successFetchSportPageDetails: (state, { payload }: PayloadAction<TSuccessFetchSportPageDetails>) => {
      const prevMarkets = state.marketCatalogueList?.content ?? [];

      state.marketCatalogueList = {
        ...payload.marketCatalogueList,
        content: payload.concatMarkets
          ? prevMarkets.concat(payload.marketCatalogueList.content)
          : payload.marketCatalogueList.content
      };
      state.sportInfo = payload.sportInfo;
      state.loading = false;
    },
    failureFetchSportPageDetails: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = payload;
    },
    removeSportPageDetails: (state, { payload }: PayloadAction<IMarket[]>) => {
      if (state.marketCatalogueList) {
        state.marketCatalogueList.content = payload;
      }
    },
    fetchSportPageMarketRules: (state, _: PayloadAction<string>) => {
      state.rulesLoading = true;
    },
    successFetchSportPageMarketRules: (state, { payload }: PayloadAction<TFetchMarketRulesResponse>) => {
      state.rulesLoading = false;
      state.rules[payload.marketId] = payload.rules;
    },
    failureFetchSportPageMarketRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.rulesLoading = false;
      state.rulesError = payload;
    }
  }
});

export const {
  fetchSportPageDetails,
  fetchSportPageMarketRules,
  removeSportPageDetails,
  successFetchSportPageMarketRules,
  successFetchSportPageDetails,
  failureFetchSportPageMarketRules,
  failureFetchSportPageDetails
} = slice.actions;

export default slice.reducer;
