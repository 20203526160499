import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { asianViewIcons as branding } from 'constants/branding';
import { FAVOURITES } from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';
import { getIsAsianViewEnabled } from 'redux/modules/appConfigs/selectors';

import styles from './styles.module.scss';

interface FavouritesHeaderProps {
  isFavourites: boolean;
}

const FavouritesHeader = ({ isFavourites }: FavouritesHeaderProps) => {
  const { t } = useTranslation();
  const { translationKey } = useTooltip(FAVOURITES);

  const isAsianView = useSelector(getIsAsianViewEnabled);

  return (
    <div
      className={classNames(styles.favouritesHeader__tooltip, {
        'biab_fav-secton-title': !isAsianView
      })}
    >
      <i
        data-tooltip-id="tooltip"
        data-tooltip-html={unescape(t(translationKey))}
        className={classNames({
          'biab_fav-icon': !isAsianView,
          [styles.favouritesHeader__inactiveIcon]: !isFavourites,
          ['biab_fav-icons-inactive']: !isFavourites,
          ['biab_fav-icons-active']: isFavourites,
          biab_active: isFavourites && !isAsianView,
          [styles.favouritesHeader__asianViewIcon]: isAsianView,
          [styles.favouritesHeader__asianViewIcon__active]: isAsianView && isFavourites,
          [branding.FAVOURITE_ICON]: isAsianView,
          [branding.ENABLED]: isAsianView && isFavourites
        })}
        aria-hidden="true"
      />
      {t('navigation.favouritesTitle')}
    </div>
  );
};

export default FavouritesHeader;
