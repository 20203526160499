import { useSelector } from 'react-redux';

import { useCurrency } from 'hooks/useCurrency';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getCurrency } from 'redux/modules/appConfigs/selectors';
import { getMarketPricesCurrency } from 'redux/modules/marketsPrices/selectors';

const useMinMaxSize = ({ marketId }: { marketId: string }) => {
  const defaultCurrency = useSelector(getCurrency);
  const currencyCode = useSelector(getMarketPricesCurrency(marketId));

  const currency = useCurrency(currencyCode);

  const { noFormattedAmount: minSizeFormatted } = useFormatCurrency(
    currency?.minBetSize || defaultCurrency.minBetSize || 0,
    currencyCode,
    { ignorePrecision: true, isCheckIndian: true, noRounding: true }
  );

  const { noFormattedAmount: maxSizeFormatted } = useFormatCurrency(
    currency?.maxBetSize || defaultCurrency.maxBetSize || 0,
    currencyCode,
    { ignorePrecision: true, isCheckIndian: true, noRounding: true }
  );

  return { minSizeFormatted, maxSizeFormatted };
};

export default useMinMaxSize;
