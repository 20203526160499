import classNames from 'classnames';

import { asianViewIcons, asianViewNavigation as branding } from 'constants/branding';
import { IconsConfig } from 'constants/iconsConfig';

import styles from '../../../../styles.module.scss';

interface ITodayHeader {
  name: string;
  count: number;
}
const TodayHeader = ({ name, count }: ITodayHeader) => {
  return (
    <div className={classNames(styles.todayHeader, branding.SPORT_HEADER)}>
      <div className={styles.todayHeaderLeft}>
        <i className={classNames(styles.icon, IconsConfig.SPORT_ICONS[1], asianViewIcons.NAV_SPORT_ICON)} /> {name}
      </div>
      <div className={styles.todayHeaderRight}>{count}</div>
    </div>
  );
};

export default TodayHeader;
