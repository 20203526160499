import classNames from 'classnames';

import styles from './styles.module.scss';

interface ResponsibleBettingCompareLimitFieldProps {
  title?: string;
  value: string;
  isMobile: boolean;
}

const ResponsibleBettingCompareLimitField = ({ title, value, isMobile }: ResponsibleBettingCompareLimitFieldProps) => (
  <div
    className={classNames(styles.container, {
      [styles.container__mobile]: isMobile
    })}
  >
    {title && <span className={styles.title}>{title}:</span>}
    <span className={styles.value}>{value}</span>
  </div>
);

export default ResponsibleBettingCompareLimitField;
