import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isNaN, isNumber, unescape } from 'lodash';

import { betslipBranding } from 'constants/branding';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getCurrencyPrecisionValue } from 'hooks/usePrecision';
import { getPrecisionType } from 'redux/modules/user/selectors';
import { BetTypes } from 'types/bets';
import { TBetLabelDataItem } from 'types/betslip';
import { round } from 'utils/liability';

import styles from './styles.module.scss';

const BetLabelsItemTpl = ({ label, profitLoss, currency, betType, isSwapColors }: TBetLabelDataItem) => {
  const precisionType = useSelector(getPrecisionType);

  const profitLossRounded = round(profitLoss || 0, getCurrencyPrecisionValue(precisionType));
  const { noFormattedAmount: plFormatted } = useFormatCurrency(profitLossRounded, currency, {
    isCheckIndian: true,
    noRounding: true,
    isPositiveSign: true
  });

  const isBack = betType === BetTypes.BACK;

  if (!label) {
    return null;
  }

  return (
    <div
      className={classNames(styles.betLabel, betslipBranding.WHAT_IF, {
        [styles.betLabel__back]: isSwapColors ? !isBack : isBack,
        [styles.betLabel__lay]: isSwapColors ? isBack : !isBack
      })}
    >
      <span dangerouslySetInnerHTML={{ __html: unescape(label ?? '') }} />
      <span
        className={classNames(styles.betLabel__value, {
          [styles.betLabel__positive]: profitLossRounded >= 0,
          [styles.betLabel__negative]: profitLossRounded < 0
        })}
      >
        {isNumber(profitLoss) && !isNaN(profitLoss) ? plFormatted : ''}
      </span>
    </div>
  );
};

export default BetLabelsItemTpl;
