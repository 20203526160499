import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { DataType, ESelectionStatuses, TGameData } from 'redux/modules/games/type';

import styles from './styles.module.scss';

interface IPlayerBox {
  item: TGameData;
}

const PlayerBox = ({ item }: IPlayerBox) => {
  const { t } = useTranslation();

  const translation = useSelector(getTranslation);

  const segment = item.properties.find(p => p.name === DataType.SEGMENT);
  const place = item.properties.find(p => p.name === DataType.PLACE);

  return (
    <div className={styles.main}>
      <div
        className={classnames(styles.name, {
          [styles.winner]: item.status === ESelectionStatuses.WINNER
        })}
      >
        <span className={classnames('biab_games-player-name', styles.leftName)}>
          {t(`games.suits.${item.name.toLowerCase()}`)}
        </span>{' '}
        -{' '}
        <span className={classnames('biab_games-player-status', styles.status)}>
          {item.statusTranslations[translation]}
        </span>
      </div>
      <div className={classnames('biab_games-player-points', styles.points)}>
        {`${segment?.value} ${t('games.units.points')} - ${place?.value ? `${place?.value}nd` : `1st`}`}
      </div>
    </div>
  );
};

export default PlayerBox;
