import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import BetsIndicatorMarketPage from 'components/BetsIndicatorMarketPage';
import InlinePlacement from 'components/InlinePlacement';
import MobileOpenBets from 'components/MobileOpenBets';
import MobilePlacement from 'components/MobilePlacement';
import Rules from 'components/Rules';
import MatchStatIcon from 'components/StatisticsIcons/MatchStaticon';
import VideoIcon from 'components/StatisticsIcons/VideoIcon';
import { IconsConfig } from 'constants/iconsConfig';
import { SPORT_BASE_URL } from 'constants/locations';
import useLayColumn from 'hooks/useLayColumn';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import useMarketStatusAndLockIcon from 'hooks/useMarketStatusAndLockIcon';
import { getAppDevice, getIsMultiMarket, getMarketOddsBackOnly } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getIsCurrentBetsBySelection, getIsCurrentEventBetsByType } from 'redux/modules/currentBets/selectors';
import { getInlineSelectedBetByMarket, getInlineSelectedBetsByMarket } from 'redux/modules/inlinePlacement/selectors';
import {
  getIsBettingEnabledByMarketPricesId,
  getIsMarketInPlayByMarketPricesId,
  getIsMatchedAmountByMarketPricesId,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { PageBlocks, PlacementPage, SportId } from 'types';
import { MatchTypes } from 'types/bets';
import {
  IMarket,
  MarketsTableColumn,
  MarketsTableEventWidgetsOptions,
  MarketsTableRowClasses,
  TMarketSportInfo,
  ViewType
} from 'types/markets';

import BetContentCells from './components/BetContentCells';
import InPlayCell from './components/InPlayCell';
import MatchedAmountCellContent from './components/MatchedAmountCellContent';
import StatusOverlay from './components/StatusOverlay';

import styles from './styles.module.scss';

interface MarketsTableRowProps {
  /**
   * Data for the market (marketId, runners, event data, startTime, etc.)
   */
  market: IMarket;

  /**
   * Count of bet groups (1 group = back and lay cells) for prices
   */
  betGroupsCount: number;

  /**
   * Width in percents for all price column groups in a row
   */
  betContentWidth: string;

  /**
   * Information about sport for markets (id, name, etc.)
   */
  sportInfo?: TMarketSportInfo | null;

  /**
   * The view type determines what should be displayed in a row if there is not enough place (screen width)
   */
  viewType: ViewType;

  /**
   * Method for requesting rules for a particular market
   * @param marketId
   */
  onOpenRulesModal: (marketId: string) => void;

  /**
   * Hide redundant columns
   */
  hiddenColumns: MarketsTableColumn[];

  /**
   * Place where component was added (Home, Market odds, Competition, Event)
   */
  pageBlock: PageBlocks;

  /**
   * Date for popular market mobile version
   */
  popularMarketTime?: string;

  /**
   * Classnames for customizing row styles
   */
  classes?: MarketsTableRowClasses;

  /**
   * Show bet cells for mobile and hide matched cell
   */
  mobileBettingMode?: boolean;

  /**
   * Swipe mode for mobile (when window height more than window width)
   */
  mobileBettingModeSwipe?: boolean;

  /**
   * is Table for Popular markets or not
   */
  isPopularMarketsOdds?: boolean;
  /**
   * Cell width for mobile
   */
  mobileCellWidth: number;
  eventWidgetsOptions?: MarketsTableEventWidgetsOptions;
  notHighlighted?: boolean;
  page?: PlacementPage;
  firstMarketId?: string;
}

const MarketsTableRow = ({
  market,
  betGroupsCount,
  betContentWidth,
  sportInfo = null,
  viewType,
  hiddenColumns,
  onOpenRulesModal,
  pageBlock,
  popularMarketTime,
  classes,
  mobileBettingMode = false,
  mobileBettingModeSwipe = false,
  isPopularMarketsOdds = false,
  mobileCellWidth,
  eventWidgetsOptions,
  notHighlighted,
  page,
  firstMarketId
}: MarketsTableRowProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const marketOddsBackOnly = useSelector(getMarketOddsBackOnly);
  const isMultiMarketView = useSelector(getIsMultiMarket(market.eventType.id));
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const inlineSelectedBet = useSelector(getInlineSelectedBetByMarket(pageBlock, market.marketId));
  const inlineSelectedBets = useSelector(getInlineSelectedBetsByMarket(pageBlock, market.marketId));
  const isMatchedBetsByEventId = useSelector(getIsCurrentEventBetsByType(market.event.id, MatchTypes.MATCHED));
  const isUnmatchedBetsByEventId = useSelector(getIsCurrentEventBetsByType(market.event.id, MatchTypes.UNMATCHED));
  const isMarketInPlay = useSelector(getIsMarketInPlayByMarketPricesId(market.marketId));
  const isMarketMatchedAmount = useSelector(getIsMatchedAmountByMarketPricesId(market.marketId));
  const isBettingEnabled = useSelector(getIsBettingEnabledByMarketPricesId(market.marketId));
  const marketStatus = useSelector(getStatusByMarketPricesId(market.marketId));
  const isMatchedBetsByMarketId = useSelector(
    getIsCurrentBetsBySelection({
      type: MatchTypes.MATCHED,
      marketId: market.marketId
    })
  );
  const isUnmatchedBetsByMarketId = useSelector(
    getIsCurrentBetsBySelection({
      type: MatchTypes.UNMATCHED,
      marketId: market.marketId
    })
  );

  const [isBetslipClosing, setIsBetslipClosing] = useState(false);

  const isDesktop = device === Devices.DESKTOP;
  const isMobile = device === Devices.MOBILE;
  const isMobileOpenBetsVisible =
    isMobile &&
    isLoggedIn &&
    pageBlock === PageBlocks.SPORT &&
    (isBetslipClosing || !inlineSelectedBet) &&
    (isMatchedBetsByMarketId || isUnmatchedBetsByMarketId);
  const isMobileHideMatchedAmount = isMobile && pageBlock === PageBlocks.SPORT;

  const { status, showStatus, showLockIcon, displayStatus } = useMarketStatusAndLockIcon(
    isBettingEnabled,
    marketStatus
  );
  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: market.marketId,
    eventId: market.event.id,
    section: isPopularMarketsOdds
      ? MarketsPricesSocketParamsSections.SportsPopularMarkets
      : MarketsPricesSocketParamsSections.SportsMiddleSection,
    observerOptions: { rootMargin: '200px' }
  });
  const { isLayColumnEnabled } = useLayColumn({
    sportId: market.eventType.id,
    bettingType: market.description.bettingType
  });

  const marketLink = `${SPORT_BASE_URL}/${market.eventType.id}/${
    isPopularMarketsOdds || isMultiMarketView ? `market/${market.marketId}` : `event/${market.event.id}`
  }`;

  const isLayColumnHidden = isPopularMarketsOdds ? marketOddsBackOnly || !isLayColumnEnabled : !isLayColumnEnabled;
  const showEventWidgetsWithoutMatchedAmount =
    hiddenColumns?.includes(MarketsTableColumn.MATCHED) &&
    isMobile &&
    (market.event.videoStreamingEnabled || market.event.matchStatEnabled);
  const showEventWidgetsWithMatchedAmount =
    isDesktop || market.event.videoStreamingEnabled || market.event.matchStatEnabled;
  const stringifiedRunners = JSON.stringify(market.runners);

  const handleRunnersClick = () => {
    navigate(marketLink);
  };

  /**
   * 135 = 65 + 50 + 20
   * 65 - in-play cell static width
   * 50 - rules cell static width
   * 20 - participantsNames cell static inline padding
   */
  const participantsNamesContainerStyle =
    viewType === ViewType.EXTRA_SMALL ? { width: `calc(100% - ${betContentWidth} - 135px)` } : {};

  const participantsNames = useMemo(() => {
    return market.runners.filter((_, index: number) => index !== 2).map(({ runnerName }) => runnerName);
  }, [stringifiedRunners]);

  const isCellTooltipEnabled = firstMarketId === market.marketId;

  return (
    <>
      <div
        ref={ref}
        className={classNames('biab_group-markets-table-row row rowMarket', styles.row, {
          [styles.rowLargeView]: viewType === ViewType.LARGE,
          [styles.rowMediumView]: viewType === ViewType.MEDIUM,
          [styles.rowSmallView]: viewType === ViewType.SMALL,
          [styles.rowExtraSmallView]: viewType === ViewType.EXTRA_SMALL,
          [styles.rowMobileView]: viewType === ViewType.MOBILE,
          [classes?.firstRow ?? '']: classes?.firstRow,
          [classes?.lastRow ?? '']: classes?.lastRow,
          [styles.mobileBettingMode]: mobileBettingMode
        })}
        data-market-id={market.marketId}
        data-event-id={market.event.id}
        data-market-prices={true}
        role="row"
        data-is-inplay={isMarketInPlay}
      >
        {!hiddenColumns?.includes(MarketsTableColumn.MOBILE_SPORT_ICON) && isMobile && (
          <div
            className={classNames(styles.sportIconMobileWrapper, {
              'biab_c-events-highlighted': !notHighlighted && market.competition?.highlighted,
              'biab_market-title-cell': isPopularMarketsOdds
            })}
          >
            <i className={IconsConfig.SPORT_ICONS[market.eventType.id]} />
          </div>
        )}
        {!hiddenColumns?.includes(MarketsTableColumn.IN_PLAY) && (
          <InPlayCell market={market} isInPlay={isMarketInPlay} sportInfo={sportInfo} />
        )}
        {!hiddenColumns?.includes(MarketsTableColumn.PARTICIPANTS_NAMES) && (
          <div
            onClick={handleRunnersClick}
            className={classNames('biab_market-title-cell', styles.participantsNamesCell, {
              'biab_c-events-highlighted': !notHighlighted && market.competition?.highlighted,
              [styles.participantsNamesCell__swipeMode]: mobileBettingModeSwipe && !isLayColumnHidden
            })}
            style={participantsNamesContainerStyle}
          >
            <div className={classNames('biab_market-title-team-names', styles.participantsNames)}>
              {participantsNames.map(runnerName => (
                <p key={runnerName} title={runnerName}>
                  {runnerName}
                </p>
              ))}
            </div>
          </div>
        )}
        {isDesktop &&
          isLoggedIn &&
          !hiddenColumns?.includes(MarketsTableColumn.PLACEMENTS) &&
          (isMatchedBetsByEventId || isUnmatchedBetsByEventId) && (
            <div
              className={classNames('bet-indicators-cell', styles.placementLabelsCell, {
                'biab_c-events-highlighted': !notHighlighted && market.competition?.highlighted
              })}
            >
              <BetsIndicatorMarketPage
                eventTypeId={market.eventType.id}
                marketId={market.marketId}
                eventId={market.event.id}
              />
            </div>
          )}
        {!hiddenColumns?.includes(MarketsTableColumn.MATCHED) && (
          <div
            className={classNames('biab_market-total-matched-cell sportsMatchedCell', styles.matchedCell, {
              'biab_c-events-highlighted': !notHighlighted && market.competition?.highlighted,
              [styles.matchedCell__swipeMode]: mobileBettingModeSwipe && !isLayColumnHidden,
              [styles.matchedCell__row]:
                isDesktop || market.event.matchStatEnabled || market.event.videoStreamingEnabled,
              [styles.matchedCell__hide]: isMobileHideMatchedAmount
            })}
          >
            <MatchedAmountCellContent marketId={market.marketId} />
            {showEventWidgetsWithMatchedAmount && (
              <div className={styles.row__videoStreamAndMatchStats}>
                {market.event.videoStreamingEnabled && <VideoIcon onClick={() => navigate(marketLink)} />}
                {market.event.matchStatEnabled && (
                  <MatchStatIcon
                    isSoccer={market.eventType.id === SportId.SOCCER}
                    onClick={() => navigate(marketLink)}
                  />
                )}
              </div>
            )}
          </div>
        )}
        {showEventWidgetsWithoutMatchedAmount && (
          <div
            className={classNames('biab_market-total-matched-cell sportsMatchedCell', styles.matchedCell, {
              'biab_c-events-highlighted': !notHighlighted && market.competition?.highlighted,
              [styles.matchedCell__swipeMode]: mobileBettingModeSwipe && !isLayColumnHidden
            })}
          >
            {market.event.videoStreamingEnabled && <VideoIcon onClick={() => navigate(marketLink)} />}
            {market.event.matchStatEnabled && (
              <MatchStatIcon isSoccer={market.eventType.id === SportId.SOCCER} onClick={() => navigate(marketLink)} />
            )}
          </div>
        )}
        {(isDesktop || mobileBettingMode) && (
          <BetContentCells
            market={market}
            betContentWidth={betContentWidth}
            betGroupsCount={betGroupsCount}
            pageBlock={pageBlock}
            mobileBettingMode={mobileBettingMode}
            mobileBettingModeSwipe={mobileBettingModeSwipe && !isLayColumnHidden}
            isLayColumnEnabled={!isLayColumnHidden}
            mobileCellWidth={mobileCellWidth}
            page={page}
            showStatus={showStatus}
            status={status}
            showLockIcon={showLockIcon}
            displayStatus={displayStatus}
            showCellTooltip={isCellTooltipEnabled}
            isPopularMarketsOdds={isPopularMarketsOdds}
          />
        )}
        {!hiddenColumns?.includes(MarketsTableColumn.RULES) && (
          <div className={styles.rulesIconWrapper}>
            {isDesktop && (
              <>
                <span onClick={() => onOpenRulesModal(market.marketId)}>
                  <Rules isHideText />
                </span>
              </>
            )}
            {showLockIcon && isMobile && !mobileBettingMode && (
              <i className={classNames('biab_lock-icon fa2 fa2-lock', styles.lockIcon)} />
            )}
          </div>
        )}
        {!hiddenColumns?.includes(MarketsTableColumn.DATE_AND_MATCHED) && isMobile && (
          <div
            className={classNames('biab_market-total-matched-cell', styles.mobile__inPlayInfo, {
              'biab_c-events-highlighted': !notHighlighted && market.competition?.highlighted
            })}
          >
            {isMarketInPlay ? (
              <span className={classNames('biab_in-play-status', styles.mobile__inPlayInfo__time)}>
                {t('market.inPlay')}
              </span>
            ) : (
              <span className={classNames('biab_market-start-time', styles.mobile__inPlayInfo__time)}>
                {popularMarketTime}
              </span>
            )}
            {isMarketMatchedAmount && (
              <span className={classNames('biab_market-total-matched', styles.matchedAndVideoIcon)}>
                <MatchedAmountCellContent marketId={market.marketId} />
                {eventWidgetsOptions?.videoStreamingEnabled && <VideoIcon onClick={() => navigate(marketLink)} />}
                {eventWidgetsOptions?.matchStatEnabled && (
                  <MatchStatIcon
                    isSoccer={market.eventType.id === SportId.SOCCER}
                    onClick={() => navigate(marketLink)}
                  />
                )}
              </span>
            )}
          </div>
        )}
        {showStatus && isMobile && <StatusOverlay status={status} />}
      </div>
      {!!inlineSelectedBets.length && isDesktop && (
        <InlinePlacement
          bets={inlineSelectedBets}
          pageBlock={pageBlock}
          page={page}
          isPopularMarketsOdds={isPopularMarketsOdds}
        />
      )}
      {!!inlineSelectedBet && isMobile && (
        <MobilePlacement
          bet={inlineSelectedBet}
          pageBlock={pageBlock}
          onClosingBetslip={setIsBetslipClosing}
          page={page}
        />
      )}
      {isMobileOpenBetsVisible && (
        <MobileOpenBets marketId={market.marketId} pageBlock={pageBlock} page={page} sportId={market.eventType.id} />
      )}
    </>
  );
};

export default memo(MarketsTableRow);
