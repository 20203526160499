import { ReactNode } from 'react';
import classNames from 'classnames';

import { componentsBranding } from 'constants/branding';

import styles from './styles.module.scss';

type RadioButtonProps = {
  children: ReactNode;
  name: string;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
  id?: string;
  disabled?: boolean;
  className?: string;
  labelClassName?: string;
};

const RadioButton = ({
  children,
  name,
  value,
  checked,
  onChange,
  id,
  disabled,
  className,
  labelClassName
}: RadioButtonProps) => {
  return (
    <label className={classNames(styles.radioButton, className)}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        id={id}
        disabled={disabled}
        onChange={() => onChange(value)}
      />
      <span
        className={classNames('fa2 fa2-circle', styles.radioButton__button, {
          [componentsBranding.RADIO]: checked
        })}
      />
      <span className={labelClassName}>{children}</span>
    </label>
  );
};

export default RadioButton;
