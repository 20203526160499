import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import PopularMarkets from 'components/PopularMarkets';
import useElementSize from 'hooks/useElementSize';
import useShowMarketOddOnPage from 'hooks/useShowMarketOddOnPage';
import { getIsShowMarketOdd } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getLoggedInLoading, getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchPopularMarkets } from 'redux/modules/popularMarket';
import { CookieNames, PlacementPage } from 'types';

import styles from './styles.module.scss';

interface PopularMarketProps {
  className?: string;
  page?: PlacementPage;
  applyClassName?: boolean;
}

const PopularMarket = ({ className = '', page, applyClassName = false }: PopularMarketProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([CookieNames.MARKET_ODDS_COLLAPSED]);

  const loading = useSelector(getLoggedInLoading);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isShowMarketOdds = useSelector(getIsShowMarketOdd);

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.POPULAR_MARKETS_HEIGHT, height }));
    }
  });
  const isMarketOdds = useShowMarketOddOnPage();

  const cookieState = cookies[CookieNames.MARKET_ODDS_COLLAPSED];

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.POPULAR_MARKETS_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (!loading && isShowMarketOdds) {
      dispatch(fetchPopularMarkets());
    }
  }, [loading, isLoggedIn, dispatch, isShowMarketOdds]);

  const handleCollapse = (isOpened: boolean) => {
    setCookie(CookieNames.MARKET_ODDS_COLLAPSED, isOpened, { path: '/' });
  };

  return (
    <div ref={componentRef}>
      <div
        id="popularMarkets"
        className={classNames({
          [className]: isMarketOdds || applyClassName,
          [styles.popularMarkets]: isMarketOdds
        })}
        data-is-opened={cookieState ? cookieState === 'true' : true}
      >
        {isMarketOdds && (
          <PopularMarkets
            defaultIsOpened={cookieState ? cookieState === 'true' : true}
            onCollapse={handleCollapse}
            title={t('marketOdds.title')}
            className="biab_market-odds-header"
            collapseClasses={{ toggle: styles.popularMarketsCollapse }}
            page={page}
          />
        )}
      </div>
    </div>
  );
};

export default PopularMarket;
