import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { OPERATOR } from 'constants/app';
import useDevice from 'hooks/useDevice';
import { getOperator, getTokenTypeCookieName } from 'redux/modules/appConfigs/selectors';
import { authenticateUser } from 'redux/modules/auth';
import { getAuthErrorMessage, getIsAuthError } from 'redux/modules/auth/selectors';

import styles from './styles.module.scss';

interface ILoginFormProps {
  isModal?: boolean;
}

const LoginForm = ({ isModal }: ILoginFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const operator = useSelector(getOperator);
  const tokenTypeCookieName = useSelector(getTokenTypeCookieName);
  const isAuthError = useSelector(getIsAuthError);
  const authErrorMessage = useSelector(getAuthErrorMessage);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [validationError, setValidationError] = useState<string>('');

  const { isMobile } = useDevice();

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!username) {
      setValidationError(t('header.login.emptyUsername'));
    } else if (!password) {
      setValidationError(t('header.login.emptyPassword'));
    } else {
      setValidationError('');
      dispatch(authenticateUser({ username, password, cookieName: tokenTypeCookieName }));
    }
  };

  const handleChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames('biab_login-form', {
        [styles.loginForm__mobile]: isMobile
      })}
    >
      <div
        className={classNames(styles.loginForm__row, {
          [styles.loginForm__row__mobile]: isMobile
        })}
      >
        <input
          type="text"
          name="username"
          placeholder={t('header.login.username')}
          className={classNames('biab_username', styles.loginForm__input, {
            [styles.loginForm__usernameInputInModal]: isModal,
            [styles.loginForm__input__orbit]: operator === OPERATOR.ORBIT,
            [styles.loginForm__input__mobile]: isMobile
          })}
          value={username}
          onChange={handleChangeUsername}
        />
        <input
          type="password"
          name="password"
          className={classNames(styles.loginForm__input, {
            [styles.loginForm__pwInputInModal]: isModal,
            [styles.loginForm__input__orbit]: operator === OPERATOR.ORBIT,
            [styles.loginForm__input__mobile]: isMobile
          })}
          placeholder={t('header.login.password')}
          value={password}
          onChange={handleChangePassword}
        />
        <button
          type="submit"
          className={classNames('biab_login-submit', styles.loginForm__button, {
            [styles.loginForm__buttonInModal]: isModal,
            [styles.loginForm__button__mobile]: isMobile
          })}
        >
          {t('header.login')}
          {!isModal && <i className={classNames('fa2 fa2-lock', styles.loginForm__icon)} />}
        </button>
      </div>
      {(validationError || isAuthError) && (
        <p
          className={classNames('biab_login-form-row biab_error-msg', styles.loginForm__error, {
            [styles.loginForm__error__mobile]: isMobile
          })}
        >
          {validationError || authErrorMessage}
        </p>
      )}
    </form>
  );
};

export default LoginForm;
