import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BannerNames, SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';

import { TBannerPayload, TBanners, TSuccessFetchBanner } from './type';

const initialState: TBanners = {
  [BannerNames.homePage]: null,
  [BannerNames.subPageContent]: null,
  [BannerNames.badge]: null,
  [BannerNames.badgeSkyscraper]: null,
  [BannerNames.promotion]: null,
  [BannerNames.logo]: null,
  [BannerNames.footerLogo]: null,
  [BannerNames.favicon]: null,
  [BannerNames.notFoundBackground]: null,
  error: null
};

const slice = createSlice({
  name: SLICES_NAMES.BANNERS,
  initialState,
  reducers: {
    fetchBanner: (_, __: PayloadAction<TBannerPayload>) => {},
    successFetchBanner: (state, { payload }: PayloadAction<TSuccessFetchBanner>) => {
      state[payload.name] = payload.data;
    },
    failureFetchBanner: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
    }
  }
});

export const { fetchBanner, successFetchBanner, failureFetchBanner } = slice.actions;

export default slice.reducer;
