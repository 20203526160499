import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';

import { AsianViewPopularLink, AsianViewPopularLinksState } from './type';

const initialState: AsianViewPopularLinksState = {
  asianViewPopularLinks: [],
  error: null,
  loading: false,
  isOpenedCustomPopUpLink: false,
  asianViewPopularLinksLoaded: false
};

const slice = createSlice({
  name: SLICES_NAMES.ASIAN_VIEW_POPULAR_LINKS,
  initialState,
  reducers: {
    fetchAsianViewPopularLinks: state => {
      state.loading = true;
    },
    successFetchAsianViewPopularLinks: (state, { payload }: PayloadAction<AsianViewPopularLink[]>) => {
      state.asianViewPopularLinks = payload;

      if (!state.asianViewPopularLinksLoaded) {
        state.asianViewPopularLinksLoaded = true;
      }
    },
    failureFetchAsianViewPopularLinks: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.loading = false;

      if (!state.asianViewPopularLinksLoaded) {
        state.asianViewPopularLinksLoaded = true;
      }
    },
    setIsOpenedAsianViewCustomPopUpLink: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenedCustomPopUpLink = payload;
    }
  }
});

export const {
  fetchAsianViewPopularLinks,
  successFetchAsianViewPopularLinks,
  failureFetchAsianViewPopularLinks,
  setIsOpenedAsianViewCustomPopUpLink
} = slice.actions;

export default slice.reducer;
