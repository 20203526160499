import { CSSProperties } from 'react';
import classNames from 'classnames';

import { asianViewComponents as branding } from 'constants/branding';

import styles from './styles.module.scss';

interface SkeletonProps {
  circle?: boolean;
  className?: string;
  noRounded?: boolean;
  style?: CSSProperties;
}

const Skeleton = ({ circle, className, noRounded, style }: SkeletonProps) => {
  return (
    <div
      style={style}
      className={classNames(
        styles.skeleton,
        {
          [styles.skeleton__circle]: circle,
          [styles.skeleton__noRounded]: noRounded
        },
        className,
        branding.SKELETON
      )}
    />
  );
};

export default Skeleton;
