import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useElementSize from 'hooks/useElementSize';
import { TourStep } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';

import styles from './styles.module.scss';

interface CarouselProps {
  step: TourStep;
  selected: boolean;
}

const Carousel = ({ step, selected }: CarouselProps) => {
  const dispatch = useDispatch();

  const [stepRef, { height }] = useElementSize<HTMLDivElement>();

  useEffect(() => {
    if (selected) {
      dispatch(setElementHeight({ name: EElementNames.WEBTOURS_IMAGE, height }));
    }
  }, [dispatch, height, selected]);

  return (
    <div ref={stepRef} className={styles.imageWrapper}>
      <img src={step.imageFile?.content ? `data:image/gif;base64,${step.imageFile.content}` : ''} alt="carousel-img" />
    </div>
  );
};

export default Carousel;
