import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import BackLayHeader from 'components/AsianViewPageModule/components/BackLayHeader';
import CompetitionTitle from 'components/AsianViewPageModule/components/CompetitionTitle';
import Runners from 'components/AsianViewPageModule/components/Runners';
import { AsianViewMarketLink } from 'constants/asianView';
import { asianViewMiddleSection as branding } from 'constants/branding';
import useAsianLayBetting from 'hooks/useAsianLayBetting';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { handlerCollapse } from 'redux/modules/asianView';
import { getIsCollapseOpenById } from 'redux/modules/asianView/selectors';
import { AsianMarket } from 'redux/modules/asianView/type';

import styles from './styles.module.scss';

interface TabListItemProps {
  item: AsianMarket;
  index: number;
  width: number;
  eventId: string;
  activeTab: string;
}

const TabListItem = ({ item, index, width, eventId, activeTab }: TabListItemProps) => {
  const dispatch = useDispatch();
  const isLayBetting = useAsianLayBetting();

  const translation = useSelector(getTranslation);
  const tabId = `${item.id}-${activeTab}`;
  const isCollapsed = useSelector(getIsCollapseOpenById(tabId));

  const setCollapse = () => {
    dispatch(handlerCollapse(tabId));
  };

  return (
    <div className={classNames(styles.tabListItem, branding.MARKET_BORDER)}>
      <CompetitionTitle
        competitionId={item.id}
        name={item.translations?.[translation] ?? item.name ?? ''}
        isOpen={isCollapsed}
        onCollapse={setCollapse}
        isSingleLine
        isHideFavorite
        isTab
      />
      {isCollapsed && (
        <>
          {index === 0 && isLayBetting && (
            <BackLayHeader width={width} marketType={item.marketType} runnerLength={item.runners.length} isTab />
          )}
          <Runners
            competitionId={item.id}
            sectionId={item.id}
            runners={item.runners}
            marketType={item.marketType}
            eventId={eventId}
            marketId={item.id}
            width={width}
            marketLink={AsianViewMarketLink.HDP_AND_OU}
            isExpandedCoupon
          />
        </>
      )}
    </div>
  );
};

export default TabListItem;
