import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIsVisible } from 'hooks/useIsVisible';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getIsMarketInPlayByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { removeIntersectingEventId, setIntersectingEventId } from 'redux/modules/search';
import { TContent } from 'redux/modules/search/type';

import Content from '../Content';
import SearchItem from '../SearchItem';

interface ISearchListItem {
  item: TContent;
  isDepthEnabled: boolean;
}

const SearchListItem = ({ item, isDepthEnabled }: ISearchListItem) => {
  const dispatch = useDispatch();

  const device = useSelector(getAppDevice);
  const isMarketInPlay = useSelector(getIsMarketInPlayByMarketPricesId(item.topMarkets[0]?.marketId ?? ''));

  const { ref, isIntersecting } = useIsVisible<HTMLDivElement>();

  const isInPlay = item.inPlay || isMarketInPlay;
  const initialHeight = device === Devices.DESKTOP && ref?.current ? ref.current.clientHeight : 0;

  useEffect(() => {
    if (isIntersecting && isInPlay) {
      dispatch(setIntersectingEventId(item.id));
    } else {
      dispatch(removeIntersectingEventId(item.id));
    }
  }, [isIntersecting, isInPlay]);

  return (
    <div ref={ref}>
      {device === Devices.DESKTOP ? (
        isIntersecting ? (
          <>
            <SearchItem item={item} isInViewPort={isIntersecting} isInPlay={isInPlay} />
            <Content content={item} isDepthEnabled={isDepthEnabled} isInPlay={isInPlay} />
          </>
        ) : (
          <div style={{ height: initialHeight }} />
        )
      ) : (
        <>
          <SearchItem item={item} isInViewPort={isIntersecting} isInPlay={isInPlay} />
          <Content content={item} isDepthEnabled={isDepthEnabled} isInPlay={isInPlay} />
        </>
      )}
    </div>
  );
};

export default SearchListItem;
