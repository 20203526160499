import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { setPlacementNotificationsError } from '../betslip';
import { setMobilePlacementNotificationsError } from '../inlinePlacement';

import { failureGetBetsStatuses, fetchBetsStatuses, startGetBetsStatuses, successGetBetsStatuses } from './index';
import { getIsLoadingBetsStatusesByOfferIds } from './selectors';
import { TBetStatus } from './type';

function* getBetsStatusesWorker(action: ReturnType<typeof fetchBetsStatuses>) {
  const loading: boolean = yield select(getIsLoadingBetsStatusesByOfferIds(action.payload.offerIds));
  if (loading) {
    return;
  }

  try {
    yield put(startGetBetsStatuses(action.payload.offerIds));

    const response: TBetStatus = yield call(api.placement.getBetsStatuses, action.payload.offerIds.join());

    yield put(successGetBetsStatuses(response));

    if (action.payload.onSuccess) {
      action.payload.onSuccess(response);
    }
  } catch (error: any) {
    yield put(
      setMobilePlacementNotificationsError({ offerIds: action.payload.offerIds, error: error?.response?.data.message })
    );
    yield put(
      setPlacementNotificationsError({ offerIds: action.payload.offerIds, error: error?.response?.data.message })
    );
    yield put(failureGetBetsStatuses({ offerIds: action.payload.offerIds, error: error?.response?.data }));
  }
}

export default function* saga() {
  yield all([takeEvery(fetchBetsStatuses.type, getBetsStatusesWorker)]);
}
