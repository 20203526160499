import { useSelector } from 'react-redux';

import { getAsianSelectedBetsList } from 'redux/modules/asianViewBetslip/selectors';

import AVSelectedBet from '../AVSelectedBet';

const AVSelectedBets = () => {
  const selectedBets = useSelector(getAsianSelectedBetsList);

  return (
    <>
      {selectedBets.map((bet, index) => (
        <AVSelectedBet key={bet.identifier} bet={bet} index={index} />
      ))}
    </>
  );
};

export default AVSelectedBets;
