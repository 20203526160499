import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';
import { TFetchMarketRulesResponse } from 'types/markets';

import { TFancyMarket, TFetchFancyEventDetailsPayload, TFetchFancyEventDetailsResponse } from './type';

const initialState: TFancyMarket = {
  loading: false,
  error: null,
  inningsFancy: [],
  inningsFours: [],
  inningsSixes: [],
  totalInningsRuns: [],
  fallOfWicket: [],
  batsmen: [],
  otherLineMarkets: [],
  dropdownMarkets: null,
  startDate: 0,
  eventInfo: {
    id: '',
    name: ''
  },
  rulesLoading: false,
  rules: {},
  rulesError: null
};

const slice = createSlice({
  name: SLICES_NAMES.FANCY_MARKET,
  initialState,
  reducers: {
    fetchFancyEventDetails: (state, _: PayloadAction<TFetchFancyEventDetailsPayload>) => {
      state.loading = true;
    },
    successFetchFancyEventDetails: (state, { payload }: PayloadAction<TFetchFancyEventDetailsResponse>) => {
      state.loading = false;
      state.batsmen = payload.batsmen;
      state.dropdownMarkets = payload.dropdownMarkets;
      state.eventInfo = payload.eventInfo;
      state.fallOfWicket = payload.fallOfWicket;
      state.inningsFancy = payload.inningsFancy;
      state.inningsFours = payload.inningsFours;
      state.inningsSixes = payload.inningsSixes;
      state.otherLineMarkets = payload.otherLineMarkets;
      state.startDate = payload.startDate;
      state.totalInningsRuns = payload.totalInningsRuns;
    },
    failureFetchFancyEventDetails: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = payload;
    },
    fetchFancyMarketRules: (state, _: PayloadAction<string>) => {
      state.rulesLoading = true;
    },
    successFetchFancyMarketRules: (state, { payload }: PayloadAction<TFetchMarketRulesResponse>) => {
      state.rulesLoading = false;
      state.rules[payload.marketId] = payload.rules;
    },
    failureFetchFancyMarketRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.rulesLoading = false;
      state.rulesError = payload;
    }
  }
});

export const {
  fetchFancyEventDetails,
  fetchFancyMarketRules,
  successFetchFancyEventDetails,
  successFetchFancyMarketRules,
  failureFetchFancyMarketRules,
  failureFetchFancyEventDetails
} = slice.actions;

export default slice.reducer;
