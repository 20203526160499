import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { unescape } from 'lodash';

import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getMarketPricesCurrency, getMatchedAmountByMarketPricesId } from 'redux/modules/marketsPrices/selectors';

type MatchedAmountCellContentProps = {
  marketId: string;
};

const MatchedAmountCellContent = ({ marketId }: MatchedAmountCellContentProps) => {
  const { t } = useTranslation();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const marketCurrency = useSelector(getMarketPricesCurrency(marketId));
  const marketMatchedAmount = useSelector(getMatchedAmountByMarketPricesId(marketId));

  const { formattedAmount: matchedAmount, noFormattedAmount: tooltipMatchedAmount } = useFormatCurrency(
    marketMatchedAmount || 0,
    marketCurrency,
    {
      isCheckIndian: true
    }
  );

  return (
    <span
      data-tooltip-id="tooltip"
      data-tooltip-html={unescape(
        t(`${isPNCEnabled ? 'pnc.' : ''}tooltip.matchedTotal`, {
          amount: tooltipMatchedAmount
        })
      )}
      className="cursor-help"
    >
      {matchedAmount}
    </span>
  );
};

export default MatchedAmountCellContent;
