import Skeleton from 'components/Skeleton';

import styles from './styles.module.scss';

const CashOutSkeleton = () => {
  return (
    <div className={styles.containerLoading}>
      <Skeleton className={styles.containerLoading__topLine} />
      <div className={styles.containerLoading__cards}>
        <div className={styles.containerLoading__card}>
          <Skeleton className={styles.containerLoading__cardFirstLine} />
          <Skeleton className={styles.containerLoading__cardSecondLine} />
        </div>
        <div className={styles.containerLoading__card}>
          <Skeleton className={styles.containerLoading__cardFirstLine} />
          <Skeleton className={styles.containerLoading__cardSecondLine} />
        </div>
      </div>
    </div>
  );
};

export default CashOutSkeleton;
