import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { mobileComponents, mobileIcons } from 'constants/branding';
import { removeCancelActionStatus } from 'redux/modules/cancelActions';

import styles from '../MobilePlacementNotifications/MobilePlacementNotifications.module.scss';

interface ICancelAllBetsMessage {
  numberOfCancelled: number | string;
  numberOfAll: number | string;
}

const CancelAllBetsMessage = ({ numberOfCancelled, numberOfAll }: ICancelAllBetsMessage) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const detailedText = t('marketBetslip.messages.cancelledAllBets', {
    NUMBER_OF_CANCELLED: `<strong>${numberOfCancelled || ''}</strong>`,
    NUMBER_OF_ALL: `<strong>${numberOfAll || ''}</strong>`,
    interpolation: { escapeValue: false }
  });

  return (
    <div
      className={classNames(
        styles.notification,
        styles.notification__info,
        mobileComponents.NOTIFICATION,
        mobileComponents.INFO
      )}
    >
      <div className={styles.notification__body}>
        <div className={styles.notification__icon}>
          <i className={classNames('biab_custom-icon-info-circle', mobileIcons.INFO_ICON)} />
        </div>
        <div>
          <p className={styles.text} dangerouslySetInnerHTML={{ __html: detailedText }} />
        </div>
        <i
          onClick={() => dispatch(removeCancelActionStatus())}
          className={classNames('biab_custom-icon-close', styles.notification__closeIcon)}
        />
      </div>
    </div>
  );
};

export default CancelAllBetsMessage;
