import classNames from 'classnames';

import Skeleton from '../index';

import styles from './styles.module.scss';

interface MyBetsSkeletonProps {
  isShowHeader?: boolean;
  isShowTable?: boolean;
  isUsePadding?: boolean;
}

const MyBetsSkeleton = ({ isShowHeader = false, isShowTable = false, isUsePadding = false }: MyBetsSkeletonProps) => {
  return (
    <div
      data-skeleton={'myBetsSkeleton'}
      className={classNames(styles.container, {
        [styles.paddingWrapper]: isUsePadding
      })}
    >
      {isShowHeader && (
        <>
          <div className={styles.row}>
            <div className={styles.row__left}>
              <Skeleton className={styles.item} />
              <Skeleton className={styles.item} />
              <Skeleton className={styles.item} />
              <Skeleton className={styles.item} />
            </div>
            <div className={styles.row__right}>
              <Skeleton className={styles.item} />
              <Skeleton className={styles.item} />
            </div>
          </div>
          <Skeleton className={styles.subHeaderTitle} />
          <div className={styles.row}>
            <div className={styles.row__left}>
              <Skeleton className={styles.item} />
              <Skeleton className={styles.item} />
            </div>
            <div className={styles.row__right}>
              <Skeleton className={styles.itemIcon} />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.row__left}>
              <Skeleton className={styles.itemLarge} />
              <Skeleton className={styles.itemLarge} />
              <Skeleton className={styles.item} />
            </div>
          </div>
        </>
      )}

      {isShowTable && (
        <div className={styles.table}>
          <div className={styles.table__header} />
          <Skeleton className={styles.table__item} />
          <Skeleton className={styles.table__item} />
          <Skeleton className={styles.table__item} />
          <Skeleton className={styles.table__item} />
          <Skeleton className={styles.table__item} />
        </div>
      )}
    </div>
  );
};

export default MyBetsSkeleton;
