export const tabs = {
  sports: { translationKey: 'header.navigation.sports', value: 'sports' },
  games: { translationKey: 'header.navigation.games', value: 'games' }
};

export const quickBetsTableHeader = [
  { id: 'min-bet', labelKey: 'account.settings.quickBets.columns.minBet' },
  { id: 'max-bet', labelKey: 'account.settings.quickBets.columns.maxBet' },
  { id: 'step', labelKey: 'account.settings.quickBets.columns.step' }
] as const;

export const numberOfButtons = 6;
