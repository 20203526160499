import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { camelCase } from 'lodash';

import SportPageModule from 'components/SportPageModule';
import NavItemName from 'components/SportsSection/partials/NavItemName';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { resetSportData, setRace } from 'redux/modules/competitions';
import { ETimeFilters } from 'redux/modules/competitions/type';
import { getSportPageMarkets } from 'redux/modules/sportPage/selectors';
import { SportPageContextFilter } from 'redux/modules/sportPage/type';
import { PlacementPage } from 'types';

const CompetitionTimeFilterView = () => {
  const dispatch = useDispatch();
  const { competitionId = '', timeFilter = '' } = useParams();
  const { t } = useTranslation();

  const markets = useSelector(getSportPageMarkets);
  const domainLanguage = useSelector(getTranslation);

  const name = t(`competition.view.tabs.${ETimeFilters.IN_PLAY ? camelCase(timeFilter) : timeFilter}`);

  useEffect(() => {
    return () => {
      dispatch(setRace(null));
      dispatch(resetSportData());
    };
  }, []);

  return (
    <>
      <NavItemName
        nameTranslations={`${name} - ${markets?.[0]?.competition?.competitionNameTranslations?.[domainLanguage]}`}
      />
      <SportPageModule
        contextFilter={SportPageContextFilter.COMPETITION}
        pageDetailsId={competitionId}
        placementPage={PlacementPage.COMPETITION}
      />
    </>
  );
};

export default CompetitionTimeFilterView;
