import { memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { ALL_MARKETS_TAB_NAME } from 'constants/app';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getSingleMarketInfo } from 'redux/modules/market/selectors';
import { setExtraMultiMarketTab } from 'redux/modules/multiMarket';
import { getIsExtraTab, getMultiMarketTabs, getMultiMarketTop } from 'redux/modules/multiMarket/selectors';
import { TMultiMarketTabDetails } from 'redux/modules/multiMarket/type';

import styles from './styles.module.scss';

interface MarketsTabsProps {
  onChangeTab: (tab: TMultiMarketTabDetails) => void;
  activeTabId: string | number;
  activeTabName: string;
}

const MarketsTabs = ({ onChangeTab, activeTabId, activeTabName }: MarketsTabsProps) => {
  const dispatch = useDispatch();
  const { marketId } = useParams();
  const [searchParams] = useSearchParams();

  const device = useSelector(getAppDevice);
  const { desktopDisplayedTabs, allTabs } = useSelector(getMultiMarketTabs);
  const topMultiMarket = useSelector(getMultiMarketTop);
  const marketInfo = useSelector(getSingleMarketInfo);
  const isExtraTab = useSelector(getIsExtraTab);

  const tabsRef = useRef<HTMLDivElement>(null);

  const isDesktop = device === Devices.DESKTOP;
  const searchParamTab = searchParams.get('tab');
  const viewMarketSearchParam = searchParams.get('viewMarket');
  const tabs = isDesktop ? desktopDisplayedTabs : allTabs;

  useEffect(() => {
    if (
      topMultiMarket?.topMarketId &&
      marketId !== topMultiMarket.topMarketId &&
      !isDesktop &&
      !searchParamTab &&
      marketInfo &&
      activeTabName &&
      activeTabName !== ALL_MARKETS_TAB_NAME &&
      !isExtraTab &&
      viewMarketSearchParam
    ) {
      dispatch(setExtraMultiMarketTab(marketInfo));
    }
  }, [topMultiMarket, isDesktop, marketInfo, activeTabName]);

  useEffect(() => {
    if (!isDesktop && tabsRef.current?.children && tabs.length && activeTabId) {
      const allTabsElements = tabsRef.current.children;

      const activeTabElement = Array.from(allTabsElements).find(element => {
        return element.className.includes('biab_active');
      });

      activeTabElement?.scrollIntoView({ inline: 'end', block: 'nearest' });
    }
  }, [isDesktop, tabsRef.current?.children, tabs.length, activeTabId]);

  return (
    <div className={classNames('biab_multi-market-tabs', styles.marketsTabs)} ref={tabsRef}>
      {tabs
        .filter(({ marketCatalogues }) => {
          if (marketCatalogues.length === 1 && isDesktop) {
            return marketCatalogues[0].marketId !== marketId;
          }

          return true;
        })
        .map(tab => (
          <div
            key={tab.tabId}
            className={classNames('biab_multi-market-tab', styles.marketsTabs__tab, {
              biab_active: activeTabId === tab.tabId
            })}
            onClick={() => onChangeTab(tab)}
          >
            <span>{tab.tabName}</span>
          </div>
        ))}
    </div>
  );
};

export default memo(MarketsTabs);
