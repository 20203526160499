import { isNumber } from 'lodash';

import { OddsType } from 'types';

const fixFloatError = (n: number) => parseFloat(n.toPrecision(12));

const toMYOdds = (odds: number) => {
  if (odds <= 2.0) {
    return fixFloatError(odds - 1);
  }
  return parseFloat(fixFloatError(-1 / (odds - 1)).toFixed(3));
};

const toINDOdds = (odds: number) => {
  if (odds === 0) {
    throw new Error('Outside valid range.');
  }

  if (odds >= 2) {
    return fixFloatError(odds - 1);
  }
  return parseFloat(fixFloatError(-1 / (odds - 1)).toFixed(2));
};

const convertOdds = (decimalOdds: number | string, type: OddsType) => {
  if (isNumber(decimalOdds)) {
    switch (type) {
      case OddsType.DEC:
        const decOdds = floatFormat(decimalOdds);
        return decOdds < 1000 ? decOdds.toFixed(2) : decOdds;
      case OddsType.MY:
        return toMYOdds(decimalOdds);
      case OddsType.HK:
        return floatFormat(fixFloatError(decimalOdds - 1));
      case OddsType.IND:
        return toINDOdds(decimalOdds);
    }
  }
  return decimalOdds;
};

export const floatFormat = (odds: number | string, precision = 2) =>
  parseFloat(parseFloat((odds || '').toString()).toFixed(precision));

export default convertOdds;
