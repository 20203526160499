import { parse, ParsedQs } from 'qs';

export const parseDomainFromUrl = (hostname: string, pattern: string) => {
  if (pattern && pattern.trim().length > 0) {
    const hostnameParts = hostname.split('.');
    const patternParts = pattern.split('.');
    const startIndex = hostnameParts.length - patternParts.length;

    if (startIndex < hostnameParts.length) {
      let domain = hostnameParts[startIndex];

      for (let i = startIndex + 1; i < hostnameParts.length; i++) {
        domain += '.' + hostnameParts[i];
      }

      return domain;
    }
  }
};

export const parseURLSearch = (search: string): ParsedQs => {
  return parse(search.replace('?', ''));
};
