import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Image from 'components/Image/Image';
import Skeleton from 'components/Skeleton';
import { todayCardsBranding } from 'constants/branding';
import { SPORT_BASE_URL } from 'constants/locations';
import { RACE_CARDS_COLLAPSED_TIME_FORMS } from 'constants/raceCard';
import useElementSize from 'hooks/useElementSize';
import useWindowSize from 'hooks/useWindowSize';
import { useAppThunkDispatch } from 'redux/configureStore';
import { getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { fetchRace } from 'redux/modules/competitions';
import { getSportData } from 'redux/modules/competitions/selectors';
import { TNavigationResponse } from 'redux/modules/competitions/type';
import { getSingleMarketInfo, getSingleMarketLoading } from 'redux/modules/market/selectors';
import { applyTimezone } from 'utils/date';
import { addZero } from 'utils/session';

import LandscapeHeader from './components/LandscapeHeader';
import LandscapeRaceContent from './components/LandscapeRaceContent/LandscapeRaceContent';
import MobileTodayCardPageIntervalRequestsInjection from './components/MobileTodayCardPageIntervalRequestsInjection/MobileTodayCardPageIntervalRequestsInjection';
import RaceCard from './components/RaceCard';

import styles from './styles.module.scss';

export const RACE_SEARCH_PARAM = 'raceId';
export const RACE_INDEX_SEARCH_PARAM = 'raceIndex';

type MobileTodayCardPageProps = {
  isLoadedRaces: boolean;
  setIsLoadedRaces: Dispatch<SetStateAction<boolean>>;
};

const MobileTodayCardPage = ({ setIsLoadedRaces, isLoadedRaces }: MobileTodayCardPageProps) => {
  const dispatch = useDispatch();
  const appDispatch = useAppThunkDispatch();
  const { sportId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const sportData = useSelector(getSportData);
  const marketLoading = useSelector(getSingleMarketLoading);
  const market = useSelector(getSingleMarketInfo);

  const [raceData, setRaceData] = useState<TNavigationResponse | null>(null);
  const [height, setHeight] = useState<number | string>('calc(100vh - 191px)');

  const selectedRaceRef = useRef<HTMLButtonElement>(null);

  const { isLandscape } = useWindowSize();
  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height: componentHeight }) => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: componentHeight }));
    }
  });

  const stringifiedSportData = JSON.stringify(sportData);
  const selectedRaceId = searchParams.get(RACE_SEARCH_PARAM);
  const selectedRaceIndex = searchParams.get(RACE_INDEX_SEARCH_PARAM);
  const collapsedTimeFormsString = localStorage.getItem(RACE_CARDS_COLLAPSED_TIME_FORMS);
  const collapsedTimeForms: string[] = useMemo(
    () => (collapsedTimeFormsString ? JSON.parse(collapsedTimeFormsString) : []),
    [collapsedTimeFormsString]
  );

  const fetchRaceData = (raceId: string) => {
    appDispatch(fetchRace(raceId))
      .unwrap()
      .then(race => {
        setRaceData(race);
        if (!isLoadedRaces) {
          setIsLoadedRaces(true);
        }
      });
  };

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (sportData.length) {
      const newSelectedRaceIndex = String(sportData.findIndex(({ id }) => id === selectedRaceId));
      const isNotSelectedRaceIdInList = newSelectedRaceIndex === '-1';

      if (!selectedRaceId || isNotSelectedRaceIdInList) {
        searchParams.set(RACE_SEARCH_PARAM, sportData[0].id);
        searchParams.set(RACE_INDEX_SEARCH_PARAM, '0');
        setSearchParams(searchParams, { replace: true });
        fetchRaceData(sportData[0].id);
      } else if (newSelectedRaceIndex !== selectedRaceIndex) {
        searchParams.set(RACE_INDEX_SEARCH_PARAM, newSelectedRaceIndex);
        setSearchParams(searchParams, { replace: true });
      }
    }

    if (collapsedTimeForms.length && sportData.length) {
      collapsedTimeForms.forEach((collapsedId, index) => {
        const isActiveCollapsedId = sportData.some(({ id }) => collapsedId.includes(id));

        if (!isActiveCollapsedId) {
          const newCollapsedTimeForms = [...collapsedTimeForms];
          newCollapsedTimeForms.splice(index, 1);
          localStorage.setItem(RACE_CARDS_COLLAPSED_TIME_FORMS, JSON.stringify(newCollapsedTimeForms));
        }
      });
    }
  }, [stringifiedSportData]);

  useEffect(() => {
    const marketRaceId = `tc-${market?.parents?.[market?.parents?.length - 1].id}`;
    if (selectedRaceId && (!market?.closedDate || selectedRaceId !== marketRaceId)) {
      fetchRaceData(selectedRaceId);
    }
  }, [selectedRaceId, market?.closedDate]);

  useEffect(() => {
    if (raceData?.children.length) {
      navigate(
        { pathname: `${SPORT_BASE_URL}/${sportId}/market/${raceData.children[0].id}`, search: location.search },
        { replace: true }
      );
    }
  }, [raceData?.children]);

  useEffect(() => {
    if (selectedRaceRef.current) {
      if (isLandscape) {
        const parentNode = selectedRaceRef.current.parentNode as HTMLElement;

        parentNode.scrollTop = selectedRaceRef.current.offsetTop - parentNode.offsetTop;
      } else {
        selectedRaceRef.current?.scrollIntoView({ inline: 'center', block: 'nearest' });
      }
    }
  }, [stringifiedSportData, selectedRaceId, isLandscape]);

  const handleClickRace = (id: string, index: number) => {
    searchParams.set(RACE_SEARCH_PARAM, id);
    searchParams.set(RACE_INDEX_SEARCH_PARAM, String(index));
    setSearchParams(searchParams);
  };

  return (
    <div ref={componentRef} className="biab_market">
      <MobileTodayCardPageIntervalRequestsInjection
        sportId={sportId}
        marketId={market?.marketId}
        cashOutEnabled={market?.cashOutEnabled}
      />
      {isLandscape && <LandscapeHeader raceData={raceData} />}
      <div
        className={classNames(styles.mobileTodayCard__headerItems__container, {
          [styles.mobileTodayCard__headerItems__container__landscape]: isLandscape
        })}
      >
        <div
          style={{ maxHeight: isLandscape ? height : 'auto' }}
          className={classNames(styles.mobileTodayCard__headerItems, todayCardsBranding.SECTION, {
            [styles.mobileTodayCard__headerItems__landscape]: isLandscape
          })}
        >
          {sportData.map(({ marketStartTime, id, venue, countryCode }, index) => {
            const date = applyTimezone(new Date(marketStartTime ?? ''), timezone, timezoneCookieEnabled);
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const name = venue.length > 10 && !isLandscape ? `${venue.slice(0, 10)}...` : venue;

            return (
              <button
                key={id}
                id={id}
                ref={selectedRaceId === id ? selectedRaceRef : undefined}
                onClick={() => handleClickRace(id, index)}
                className={classNames(styles.mobileTodayCard__headerItems__tile, todayCardsBranding.TILE, {
                  [styles.mobileTodayCard__headerItems__tile__active]: selectedRaceId === id,
                  [styles.mobileTodayCard__headerItems__tile__landscape]: isLandscape,
                  [todayCardsBranding.ACTIVE]: selectedRaceId === id
                })}
              >
                <p
                  className={classNames(styles.mobileTodayCard__headerItems__tile__time, {
                    [styles.mobileTodayCard__headerItems__tile__time__landscape]: isLandscape
                  })}
                >
                  {addZero(hours)}
                  {hours}:{addZero(minutes)}
                  {minutes}
                </p>
                <span
                  className={classNames(styles.mobileTodayCard__headerItems__tile__venue, {
                    [styles.mobileTodayCard__headerItems__tile__venue__landscape]: isLandscape
                  })}
                >
                  <Image
                    name={countryCode}
                    path={`horse-racing/${countryCode}.svg`}
                    className={styles.mobileTodayCard__headerItems__tile__countryFlag}
                  />
                  <span className={styles.mobileTodayCard__headerItems__tile__venue__name}>{name}</span>
                </span>
              </button>
            );
          })}
        </div>
        {isLandscape && (
          <>
            {marketLoading ? (
              <div className={styles.mobileTodayCard__landscapeSkeletonContainer}>
                {new Array(6).fill(0).map(() => (
                  <Skeleton className={styles.mobileTodayCard__skeleton} />
                ))}
              </div>
            ) : (
              <LandscapeRaceContent setHeight={setHeight} />
            )}
          </>
        )}
      </div>
      {!isLandscape && (
        <div className={styles.mobileTodayCard__raceCardContainer}>
          <RaceCard raceData={raceData} />
        </div>
      )}
    </div>
  );
};

export default MobileTodayCardPage;
