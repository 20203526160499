import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';

import styles from '../../styles.module.scss';

interface SidebarHeaderProps {
  openRightMenu: () => void;
  showHowToSectionOnly?: boolean;
}

const SidebarHeader = ({ openRightMenu, showHowToSectionOnly }: SidebarHeaderProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);

  const text = t(device === Devices.DESKTOP ? 'navigation.tours.title.label' : 'how.to.mobile');

  return (
    <div className={styles.rightMenuOpen} onClick={openRightMenu}>
      <i className={classNames(styles.rightMenuOpen__icon, 'biab_tour-icon-burger-menu')} />
      {(device === Devices.DESKTOP || showHowToSectionOnly) && (
        <span className={styles.rightMenuOpen__text}>{text}</span>
      )}
    </div>
  );
};

export default SidebarHeader;
