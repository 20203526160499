import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import CollapseButtons from 'components/AsianViewPageModule/components/CollapseButtons';
import TabListItem from 'components/AsianViewPageModule/components/TabListItem';
import {
  ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM,
  ASIAN_VIEW_MIDDLE_SECTION_CLASS,
  ASIAN_VIEW_TABS,
  ASIAN_VIEW_TABS_LABELS,
  AsianViewMarketLink,
  AsianViewSection
} from 'constants/asianView';
import { asianViewMiddleSection, asianViewMiddleSection as branding } from 'constants/branding';
import useAsianCashOut from 'hooks/useAsianCashOut';
import useAsianViewLineType from 'hooks/useAsianViewLineType';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useElementSize from 'hooks/useElementSize';
import { setScrollUpdate } from 'redux/modules/appSettings';
import { fetchTab, setIsCashOutTabOpened } from 'redux/modules/asianView';
import { getAsianViewMarkets, getIsMobileAsianView, getSelectedTabLoading } from 'redux/modules/asianView/selectors';
import { getAsianViewCashOutCounterByEventId } from 'redux/modules/asianViewCashOutCounter/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';

import CashOutSection from '../CashOutSection';

import StickyEvent from './StickyEvent';

import styles from './styles.module.scss';

interface AsianViewMiddleSectionProps {
  eventId: string;
  sportId: string;
  scoreMarketId?: string;
}

const AsianViewTabs = ({ eventId, sportId, scoreMarketId }: AsianViewMiddleSectionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isLoading = useSelector(getSelectedTabLoading);
  const markets = useSelector(getAsianViewMarkets);
  const cashOutCounter = useSelector(getAsianViewCashOutCounterByEventId(sportId, eventId));
  const { asianViewCashOutPaginationButtons } = useDeviceSettings();
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isCashOutEnabled = useAsianCashOut();

  const viewType = useAsianViewLineType();

  const [activeTab, setActiveTab] = useState(ASIAN_VIEW_TABS[0]);

  const [componentRef, { width, height }] = useElementSize<HTMLDivElement>();

  useEffect(() => {
    if (activeTab !== AsianViewSection.CASH_OUT) {
      dispatch(
        fetchTab({
          sportId,
          marketLink: AsianViewMarketLink.HDP_AND_OU,
          viewType,
          itemId: eventId,
          sections: [activeTab]
        })
      );
    }
  }, [dispatch, eventId, sportId, activeTab, viewType]);

  useEffect(() => {
    if (height) {
      const parent = componentRef.current?.closest(`.${ASIAN_VIEW_MIDDLE_SECTION_CLASS}`);

      dispatch(setScrollUpdate({ top: parent?.scrollTop || 0, offset: height, trigger: false }));
    }
  }, [height]);

  const renderContent = () => {
    if (activeTab === AsianViewSection.CASH_OUT) {
      return <CashOutSection isTab eventId={eventId} sportId={sportId} />;
    }

    if (!markets.length) {
      return <div className={styles.emptyContainer}>{t('asianView.labels.emptyTab')}</div>;
    }

    return (
      <>
        <CollapseButtons />
        {markets.map((item, index) => (
          <TabListItem key={item.id} item={item} index={index} width={width} eventId={eventId} activeTab={activeTab} />
        ))}
      </>
    );
  };

  const handleClickTab = (item: AsianViewSection) => {
    setActiveTab(item);

    if (item === AsianViewSection.CASH_OUT) {
      dispatch(setIsCashOutTabOpened(true));

      if (asianViewCashOutPaginationButtons) {
        searchParams.set(ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM, '0');
        setSearchParams(searchParams);
      }
    } else {
      dispatch(setIsCashOutTabOpened(false));
    }
  };

  return (
    <div className={classNames(styles.container, branding.COUPON_CONTAINER)} ref={componentRef}>
      {scoreMarketId && <StickyEvent marketId={scoreMarketId} eventId={eventId} />}
      <div className={styles.tabContainer}>
        {ASIAN_VIEW_TABS.map(
          (item, index) =>
            (item !== AsianViewSection.CASH_OUT || isCashOutEnabled) && (
              <div
                key={item}
                onClick={() => handleClickTab(item)}
                className={classNames(styles.tab, branding.COUPON_TAB, {
                  [styles.tab__mobile]: isMobileAsianView,
                  [styles.tab__active]: activeTab === item,
                  [styles.tab__lastItem]: ASIAN_VIEW_TABS.length - 1 === index,
                  [branding.SELECTED]: activeTab === item
                })}
              >
                {t(ASIAN_VIEW_TABS_LABELS[item])}
                {item === AsianViewSection.CASH_OUT && isLoggedIn && cashOutCounter > 0 && (
                  <div
                    className={classNames(
                      styles.cashOutCounter,
                      asianViewMiddleSection.COUPON_COUNTER,
                      asianViewMiddleSection.TAB_COUNTER
                    )}
                  >
                    {cashOutCounter}
                  </div>
                )}
              </div>
            )
        )}
      </div>

      <div className={styles.content}>{!isLoading && renderContent()}</div>
    </div>
  );
};

export default AsianViewTabs;
