import { TTimezone } from 'types';

export const timezones: TTimezone = {
  '-12:00': 720,
  '-11:00': 660,
  '-10:00': 600,
  '-09:30': 570,
  '-09:00': 540,
  '-08:00': 480,
  '-07:00': 420,
  '-06:00': 360,
  '-05:00': 300,
  '-04:00': 240,
  '-03:00': 180,
  '-02:00': 120,
  '-01:00': 60,
  '+00:00': 0,
  '+01:00': -60,
  '+02:00': -120,
  '+03:00': -180,
  '+04:00': -240,
  '+04:30': -270,
  '+05:00': -300,
  '+05:30': -330,
  '+05:45': -345,
  '+06:00': -360,
  '+06:30': -390,
  '+07:00': -420,
  '+08:00': -480,
  '+08:45': -525,
  '+09:00': -540,
  '+09:30': -570,
  '+10:00': -600,
  '+11:00': -660,
  '+12:00': -720,
  '+13:00': -780,
  '+14:00': -840
};
