import { toNumber } from 'lodash';

import useBetLabels from 'hooks/useBetLabels';
import { BetTypes, THandicap, TPrice, TSize } from 'types/bets';
import { ELineSide } from 'types/betslip';
import { getAltTotalGoalsProfitLoss } from 'utils/betLabels';

import BetLabelsTpl from '../BetLabelsTpl';

type AltTotalGoalsLabelProps = {
  price: TPrice;
  size: TSize;
  handicap?: THandicap | null;
  betType: BetTypes;
  handicapType?: ELineSide | null;
  currency?: string;
};

const AltTotalGoalsLabel = ({ price, size, handicap, betType, handicapType, currency }: AltTotalGoalsLabelProps) => {
  const { getAltTotalGoalsLabels } = useBetLabels();

  const handicapValue = toNumber(handicap || 0);

  const { labelW, labelS, labelL } = getAltTotalGoalsLabels(handicapValue);

  const { profitLossW, profitLossS, profitLossL } = getAltTotalGoalsProfitLoss({
    price,
    size,
    handicap,
    betType,
    handicapType
  });

  return (
    <BetLabelsTpl
      betType={betType}
      labelW={labelW}
      labelS={labelS}
      labelL={labelL}
      profitLossW={profitLossW}
      profitLossS={profitLossS}
      profitLossL={profitLossL}
      currency={currency}
    />
  );
};

export default AltTotalGoalsLabel;
