import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { IMarketRules } from 'types/markets';

import {
  failureFetchTop5ViewDetails,
  failureFetchTop5ViewEventRules,
  failureFetchTop5ViewMarketRules,
  fetchTop5ViewDetails,
  fetchTop5ViewEventRules,
  fetchTop5ViewMarketRules,
  successFetchTop5ViewDetails,
  successFetchTop5ViewEventRules,
  successFetchTop5ViewMarketRules
} from './index';
import { TFetchTop5ViewDetailsResponse } from './type';

export function* getTop5ViewDetailsWorker(action: ReturnType<typeof fetchTop5ViewDetails>) {
  try {
    const response: TFetchTop5ViewDetailsResponse = yield call(api.event.getTop5ViewDetails, action.payload);
    yield put(successFetchTop5ViewDetails(response));
  } catch (error: any) {
    yield put(failureFetchTop5ViewDetails(error.data));
  }
}

export function* getTop5ViewEventRulesWorker(action: ReturnType<typeof fetchTop5ViewEventRules>) {
  try {
    const response: IMarketRules[] = yield call(api.app.eventRules, action.payload);
    yield put(successFetchTop5ViewEventRules(response));
  } catch (error: any) {
    yield put(failureFetchTop5ViewEventRules(error.data));
  }
}

export function* getTop5ViewMarketRulesWorker(action: ReturnType<typeof fetchTop5ViewMarketRules>) {
  try {
    const response: IMarketRules = yield call(api.app.marketRules, action.payload);
    yield put(successFetchTop5ViewMarketRules({ marketId: action.payload, rules: response }));
  } catch (error: any) {
    yield put(failureFetchTop5ViewMarketRules(error.data));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchTop5ViewDetails.type, getTop5ViewDetailsWorker),
    takeLatest(fetchTop5ViewEventRules.type, getTop5ViewEventRulesWorker),
    takeLatest(fetchTop5ViewMarketRules.type, getTop5ViewMarketRulesWorker)
  ]);
}
