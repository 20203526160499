import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { asianViewBetslipBranding as branding } from 'constants/branding';
import useElementSize from 'hooks/useElementSize';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';

import AsianBetSlipTotalLiabilityAndActions from '../AsianBetSlipTotalLiabilityAndActions';
import Settings from '../Settings';

import styles from './styles.module.scss';

const TOP_AND_BOTTOM_PADDINGS = 20;
const BORDER_WIDTH = 1;

const AVTotalLiabilityAndActionsAndSettings = () => {
  const dispatch = useDispatch();

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(
        setElementHeight({
          name: EElementNames.ASIAN_BET_SLIP_SETTINGS_AND_TOTAL_LIABILITY,
          height: height + TOP_AND_BOTTOM_PADDINGS + BORDER_WIDTH
        })
      );
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.ASIAN_BET_SLIP_SETTINGS_AND_TOTAL_LIABILITY, height: 0 }));
    };
  }, []);

  return (
    <div
      className={classNames(styles.totalLiability, branding.ACTIONS_CONTAINER, branding.ACTIONS_CONTAINER_BORDER)}
      ref={componentRef}
    >
      <AsianBetSlipTotalLiabilityAndActions />
      <Settings />
    </div>
  );
};

export default AVTotalLiabilityAndActionsAndSettings;
