export enum TypeFields {
  home = 'home',
  sport = 'sport',
  game = 'game',
  search = 'search',
  myBets = 'myBets',
  cashOut = 'cashOut',
  favourites = 'favourites',
  more = 'more',
  betList = 'betList'
}

export type FieldSetting = {
  [key in TypeFields]: boolean;
};
