import { useEffect, useState } from 'react';

export const useSetHighlightedRow = (
  pageNumber: number,
  pageSize: number,
  optional: {
    plStartDate?: Date;
    startDate?: Date;
    endDate?: Date;
    activePLTab?: string;
    eventType?: {
      translationKey?: string | undefined;
      value?: string | undefined;
      id?: string | undefined;
      name?: string | undefined;
    };
  }
) => {
  const [rowHighlighted, setRowHighLighted] = useState<number | undefined>();
  const { plStartDate, startDate, endDate, eventType, activePLTab } = optional;

  useEffect(() => {
    setRowHighLighted(undefined);
  }, [pageNumber, pageSize, plStartDate, startDate, endDate, eventType, activePLTab]);

  return { rowHighlighted, setRowHighLighted };
};
