import { createSelector } from '@reduxjs/toolkit';

import { TContent } from 'redux/modules/search/type';
import { AppState } from 'redux/reducers';
import { PageBlocks } from 'types';
import { TBetslipMarket } from 'types/betslip';
import { IMarket } from 'types/markets';
import { mapMarketGameData, mapMarketGamePrices } from 'utils/games';

import { getMarketCashOut } from '../cashOut/selectors';
import { getEventMarkets } from '../event/selectors';
import { getFancyMarketAllMarkets } from '../fancyMarket/selectors';
import { getFeaturedMarkets } from '../featuredMarkets/selectors';
import { getCurrentGameData } from '../games/selectors';
import { getHomeMarketData } from '../home/selectors';
import { getInPlayMarkets } from '../inPlay/selectors';
import { getListViewTopMarket } from '../listViewTabs/selectors';
import { getSingleMarketInfo } from '../market/selectors';
import { getMarketPricesById as pricesById } from '../marketsPrices/selectors';
import { getAllMultiMarkets } from '../multiMarket/selectors';
import { getPopularMarkets } from '../popularMarket/selectors';
import { getSearchContentList } from '../search/selectors';
import { getSportPageMarkets } from '../sportPage/selectors';
import { getAllTop5ViewMarkets } from '../top5View/selectors';

export const getPlacement = (state: AppState) => state.placement;

export const getMarketDataById = (pageBlock: PageBlocks, marketId: string, sportId?: string) =>
  createSelector(
    getHomeMarketData(sportId ?? ''),
    getInPlayMarkets,
    getPopularMarkets,
    getFeaturedMarkets,
    getEventMarkets,
    getSportPageMarkets,
    getSingleMarketInfo,
    getSearchContentList,
    getFancyMarketAllMarkets,
    getAllTop5ViewMarkets,
    getAllMultiMarkets,
    getCurrentGameData,
    getMarketCashOut,
    getListViewTopMarket,
    (
      homeMarketData,
      inPlayMarkets,
      popularMarkets,
      featuredMarkets,
      eventMarkets,
      sportPageMarkets,
      singleMarket,
      searchList,
      allFancyMarkets,
      top5Markets,
      multiMarkets,
      currentGameData,
      marketCashOut,
      listViewTopMarket
    ) => {
      switch (pageBlock) {
        case PageBlocks.HOME:
          return sportId
            ? homeMarketData?.marketCatalogueList?.find(market => market.marketId === marketId) ?? null
            : null;
        case PageBlocks.IN_PLAY:
          return inPlayMarkets?.find(market => market.marketId === marketId) ?? null;
        case PageBlocks.POPULAR:
          return popularMarkets?.find(market => market.marketId === marketId) ?? null;
        case PageBlocks.FEATURE_MARKET:
          return featuredMarkets?.find(market => market.marketId === marketId) ?? null;
        case PageBlocks.EVENT:
          return eventMarkets?.find(market => market.marketId === marketId) ?? null;
        case PageBlocks.SPORT:
          return sportPageMarkets?.find(market => market.marketId === marketId) ?? null;
        case PageBlocks.MARKET_ODDS:
          return singleMarket || listViewTopMarket;
        case PageBlocks.SEARCH:
          return (
            searchList
              .reduce((res: IMarket[], event: TContent) => {
                return [
                  ...res,
                  ...event.topMarkets.map(market => {
                    return {
                      ...market,
                      ...{
                        event: {
                          id: event.id,
                          name: event.name,
                          openDate: event.openDate,
                          countryCode: event.countryCode,
                          videoStreamingEnabled: event.videoStreamingEnabled,
                          matchStatEnabled: event.matchStatEnabled
                        },
                        eventType: { id: event.eventTypeId, name: '' }
                      }
                    };
                  })
                ];
              }, [])
              ?.find(market => market.marketId === marketId) ?? null
          );
        case PageBlocks.FANCY_VIEW:
        case PageBlocks.FANCY_TOP_VIEW:
          return allFancyMarkets.find(market => market.marketId === marketId) ?? null;
        case PageBlocks.TOP_5_VIEW:
          return top5Markets.find(market => market.marketId === marketId) ?? null;
        case PageBlocks.MULTI_MARKET_VIEW:
        case PageBlocks.FANCY_MULTI_MARKET_VIEW:
          return multiMarkets.find(market => market.marketId === marketId) ?? null;
        case PageBlocks.CASH_OUT:
          return marketCashOut.content.find(market => market.marketId === marketId) ?? null;
        case PageBlocks.GAME:
          const gameMarket = currentGameData?.markets.find(market => String(market.id) === String(marketId)) ?? null;
          return mapMarketGameData(currentGameData, gameMarket) as TBetslipMarket;
        default:
          return null;
      }
    }
  );

export const getMarketPricesById = (pageBlock: PageBlocks = PageBlocks.HOME, marketId: string) =>
  createSelector(pricesById(marketId), getCurrentGameData, (marketPrices, currentGameData) => {
    switch (pageBlock) {
      case PageBlocks.HOME:
      case PageBlocks.IN_PLAY:
      case PageBlocks.SPORT:
      case PageBlocks.POPULAR:
      case PageBlocks.EVENT:
      case PageBlocks.FEATURE_MARKET:
      case PageBlocks.SEARCH:
      case PageBlocks.TOP_5_VIEW:
      case PageBlocks.MULTI_MARKET_VIEW:
      case PageBlocks.FANCY_MULTI_MARKET_VIEW:
      case PageBlocks.MARKET_ODDS:
      case PageBlocks.CASH_OUT:
      case PageBlocks.FANCY_VIEW:
      case PageBlocks.FANCY_TOP_VIEW:
        return marketPrices ?? null;
      case PageBlocks.GAME:
        const gameMarket = currentGameData?.markets.find(market => String(market.id) === String(marketId)) ?? null;
        return mapMarketGamePrices(currentGameData, gameMarket);
      default:
        return null;
    }
  });

export const getCancelledBetsStatusId = ({ placement }: AppState) => placement.cancelledBetsStatusId;

export const getPlacedSelectedBetErrorMessage =
  (betKey: string) =>
  ({ placement }: AppState) =>
    placement.placedSelectedBets[betKey]?.exception?.message;

export const getPlacedSelectedBetOfferId =
  (betKey: string) =>
  ({ placement }: AppState) =>
    placement.placedSelectedBets[betKey]?.offerId;

export const getPlacedSelectedBetStatus =
  (betKey: string) =>
  ({ placement }: AppState) =>
    placement.placedSelectedBets[betKey]?.status;

export const getPlacedSelectedMarketStatus =
  (marketId: string) =>
  ({ placement }: AppState) =>
    placement.placedSelectedMarkets[marketId]?.status;

export const getPlacedSelectedMarketErrorMessage =
  (marketId: string) =>
  ({ placement }: AppState) =>
    !placement.placedSelectedMarkets[marketId]?.isInlinePlacement &&
    placement.placedSelectedMarkets[marketId]?.exception?.message;

export const getPlacedSelectedMarketOfferIdsUuids = (marketId: string) =>
  createSelector(getPlacement, placement => placement.placedSelectedMarkets[marketId]?.offerIdsUuids ?? {});

export const getRGErrorMessage = ({ placement }: AppState) => placement.rgErrorMessage;
export const getIsPlaceBetsLoading = ({ placement }: AppState) => placement.loading;
