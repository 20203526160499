import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { componentsBranding, mobileComponents } from 'constants/branding';
import { QUICK_BETS_HOVER_MESSAGE } from 'constants/tooltip';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useTooltip from 'hooks/useTooltip';
import { getIsMobile } from 'redux/modules/appConfigs/selectors';
import { getStakeAmountLabelInfo } from 'utils/settings';

import styles from '../styles.module.scss';

interface QuickBetProps {
  value: string | number;
  handler: (value: string | number) => void;
  byCenter?: boolean;
  isDisabled?: boolean;
  onFocusChange?: (value: boolean) => void;
  className?: HTMLAttributes<HTMLButtonElement>['className'];
  shouldIgnoreBlurEvent?: boolean;
}

const QuickBet = ({
  value,
  handler,
  byCenter,
  isDisabled,
  onFocusChange = () => {},
  className = '',
  shouldIgnoreBlurEvent
}: QuickBetProps) => {
  const { t } = useTranslation();
  const { isEnabled } = useTooltip(QUICK_BETS_HOVER_MESSAGE);
  const isMobile = useSelector(getIsMobile);

  const { label, translationKey } = getStakeAmountLabelInfo(+value, undefined, true);
  const stake = useFormatCurrency(label, '', {
    noSymbol: true,
    noRounding: true,
    ignorePrecision: true,
    fractionDigits: translationKey ? t(translationKey) : ''
  }).noFormattedAmount.replace('.00', '');

  return (
    <button
      className={classNames(
        styles.stake,
        componentsBranding.TERTIARY_BTN,
        {
          [componentsBranding.DISABLED]: isDisabled,
          [styles.stake__byCenter]: byCenter,
          [styles.stake__mobile]: isMobile,
          'cursor-help': isEnabled && !isMobile,
          [mobileComponents.BUTTON]: isMobile,
          [mobileComponents.TERTIARY]: isMobile
        },
        className
      )}
      data-stake={stake}
      onClick={() => handler(value)}
      onMouseEnter={() => onFocusChange(true)}
      onMouseLeave={() => onFocusChange(false)}
      onMouseDown={ev => shouldIgnoreBlurEvent && ev.preventDefault()}
      disabled={isDisabled}
    >
      +{stake}
    </button>
  );
};

export default QuickBet;
