import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';
import { InPlaySport } from 'types/inPlay';
import { IMarket, IMarketPrices, TFetchMarketRulesResponse } from 'types/markets';

import { TFetchInPLayMarketsPayload, TInPlay, TInPlayMarketCatalogueList, TSuccessFetchInPLayMarkets } from './type';

const initialState: TInPlay = {
  marketCatalogueList: null,
  loading: false,
  error: null,
  rules: null,
  rulesLoading: false,
  rulesError: null,
  sportList: null,
  sportListError: null,
  sportListLoading: false
};

const slice = createSlice({
  name: SLICES_NAMES.IN_PLAY,
  initialState,
  reducers: {
    fetchInPlayMarkets: (state, _: PayloadAction<TFetchInPLayMarketsPayload>) => {
      state.loading = true;
    },
    successFetchInPlayMarkets: (state, { payload }: PayloadAction<TSuccessFetchInPLayMarkets>) => {
      const prevMarkets = state.marketCatalogueList?.content ?? [];

      state.loading = false;
      state.marketCatalogueList = {
        ...payload.marketCatalogueList,
        content: payload.concatMarkets
          ? prevMarkets.concat(payload.marketCatalogueList.content)
          : payload.marketCatalogueList.content
      };
    },
    failureFetchInPlayMarkets: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = payload;
    },
    removeInPlayMarketsData: (state, { payload }: PayloadAction<IMarket[]>) => {
      if (!state.marketCatalogueList) {
        state.marketCatalogueList = {} as TInPlayMarketCatalogueList;
      }

      state.marketCatalogueList.content = payload;
    },
    fetchInPlaySports: state => {
      state.sportListLoading = true;
    },
    successFetchInPlaySports: (state, { payload }: PayloadAction<InPlaySport[]>) => {
      state.sportListLoading = false;
      state.sportList = payload;
    },
    failureFetchInPlaySports: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.sportListLoading = false;
      state.sportListError = payload;
    },
    fetchInPlayMarketRules: (state, _: PayloadAction<string>) => {
      state.rulesLoading = true;
    },
    successFetchInPlayMarketRules: (state, { payload }: PayloadAction<TFetchMarketRulesResponse>) => {
      if (!state.rules) {
        state.rules = {};
      }

      state.rulesLoading = false;
      state.rules[payload.marketId] = payload.rules;
    },
    failureFetchInPlayMarketRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.rulesLoading = false;
      state.rulesError = payload;
    },
    marketPricesUpdated: (state, { payload }: PayloadAction<IMarketPrices>) => {
      const find = ({ marketId, inPlay }: IMarket) =>
        marketId === payload.id && inPlay !== payload.marketDefinition.inPlay;

      const marketWithChangedStatus = state.marketCatalogueList?.content?.find(find);
      const marketIndexWithChangedStatus = state.marketCatalogueList?.content?.findIndex(find);

      if (
        marketWithChangedStatus &&
        state.marketCatalogueList?.content &&
        marketIndexWithChangedStatus &&
        state.marketCatalogueList.content[marketIndexWithChangedStatus]
      ) {
        state.marketCatalogueList.content[marketIndexWithChangedStatus].inPlay = payload.marketDefinition.inPlay;
      }
    }
  }
});

export const {
  failureFetchInPlaySports,
  fetchInPlayMarketRules,
  failureFetchInPlayMarkets,
  failureFetchInPlayMarketRules,
  successFetchInPlayMarkets,
  successFetchInPlaySports,
  removeInPlayMarketsData,
  successFetchInPlayMarketRules,
  fetchInPlayMarkets,
  fetchInPlaySports,
  marketPricesUpdated
} = slice.actions;

export default slice.reducer;
