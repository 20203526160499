import { OddsType, TOddsTypes } from 'types';

export const oddsTypeSettingsName = 'asianViewOddsType';

export const oddsTypes: TOddsTypes = {
  [OddsType.DEC]: 'DEC (Decimal)',
  [OddsType.MY]: 'MY (Malay)',
  [OddsType.HK]: 'HK (Hong Kong)',
  [OddsType.IND]: 'IND (Indonesian)'
};
