import { useDispatch, useSelector } from 'react-redux';

import ExchangeTypeTabs from 'components/BettingProfitAndLoss/components/ExchangeTypeTabs';
import { TExchangeTypes } from 'constants/myBets';
import {
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled
} from 'redux/modules/appConfigs/selectors';
import { setMobileSettingsActiveTab } from 'redux/modules/appSettings';
import { getMobileSettingsActiveTab } from 'redux/modules/appSettings/selectors';

import styles from './styles.module.scss';

const ProductsTabs = () => {
  const dispatch = useDispatch();

  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const settingsActiveTab = useSelector(getMobileSettingsActiveTab);

  const handleChangeExchangeType = (tabValue: TExchangeTypes) => {
    if (settingsActiveTab !== tabValue) {
      dispatch(setMobileSettingsActiveTab(tabValue));
    }
  };

  return (
    <ExchangeTypeTabs
      isAsianViewActive={isAsianViewEnabled}
      isSportsGamesActive={isExchangeSportsEnabled || isExchangeGamesEnabled}
      activeTab={settingsActiveTab}
      setActiveTab={handleChangeExchangeType}
      customClassName={styles.wrap}
      isMobile
    />
  );
};

export default ProductsTabs;
