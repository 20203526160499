import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { CELLS_ON_MARKET_PAGE } from 'constants/tooltip';
import useDevice from 'hooks/useDevice';
import useTooltip from 'hooks/useTooltip';

import styles from './styles.module.scss';

interface BetCellTooltipProps {
  isLayColumnEnabled?: boolean;
  type?: 'market' | 'cell' | 'fancy';
}

const BetCellTooltip = ({ isLayColumnEnabled, type = 'market' }: BetCellTooltipProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useDevice();

  const { translationKey, isEnabled, isShowIcon } = useTooltip(CELLS_ON_MARKET_PAGE);

  const isMarket = type === 'market';
  const isCell = type === 'cell';
  const isFancy = type === 'fancy';

  return (
    <>
      {isEnabled && isShowIcon && (
        <div
          className={classNames('betCellTooltip', styles.betCellTooltip, {
            [styles.betCellTooltip__market]: isMarket,
            [styles.isLayColumnEnabled]: isMarket && !isLayColumnEnabled && isDesktop,
            [styles.betCellTooltip__cell]: isCell,
            [styles.betCellTooltip__fancy]: isFancy
          })}
        >
          <i
            data-tooltip-id={'tooltip'}
            data-tooltip-html={unescape(t(translationKey))}
            className={classNames('fa2 fa2-info-circle tooltip-icon cursor-help', styles.betCellTooltip__icon)}
          >
            <span className="path1 biab_promoted-tooltip-bg biab_mobile-tooltip-bg biab_tooltip-bg" />
            <span className="path2" />
          </i>
        </div>
      )}
    </>
  );
};

export default memo(BetCellTooltip);
