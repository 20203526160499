import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface DesktopShowMoreProps {
  showMore: boolean;
  onToggleShowMore: () => void;
}

const DesktopShowMore = ({ showMore, onToggleShowMore }: DesktopShowMoreProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.showMore__wrapper}>
      <a className={styles.showMore} onClick={onToggleShowMore}>
        <span>{`${t(`market.show${showMore ? 'Less' : 'More'}`)} `}</span>
        <i
          className={classNames('fa2 biab_expand', {
            'fa2-arrow-down': !showMore,
            'fa2-arrow-up': showMore
          })}
        />
      </a>
    </div>
  );
};

export default DesktopShowMore;
