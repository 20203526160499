import { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import PopularMarket from 'components/PopularMarket';
import ViewByDropdown from 'components/ViewByDropdown';
import { IN_PLAY_BASE_URL } from 'constants/locations';
import useElementSize from 'hooks/useElementSize';
import {
  getAppDevice,
  getInPlayDefTabHighlights,
  getInPlayPaginationSize,
  getIsPropertiesLoaded,
  getIsShowMarketOdd,
  getPageDefaultSorting
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchInPlayMarkets } from 'redux/modules/inPlay';
import { fetchPopularMarkets } from 'redux/modules/popularMarket';
import { CookieNames, DropdownItem, PlacementPage, ViewBy } from 'types';
import { InPlayFilter } from 'types/inPlay';
import { getTab } from 'utils/inPlayPage';

import InPlayFilters from './components/InPlayFilters';
import InPlaySportsSections from './components/InPlaySportsSections';

import styles from './styles.module.scss';

const viewByOptions: DropdownItem[] = [
  { id: 'view-by-time', value: ViewBy.TIME, label: 'inplay.filters.viewBy.time', isLabelTranslatable: true },
  {
    id: 'view-by-popularity',
    value: ViewBy.POPULARITY,
    label: 'inplay.filters.viewBy.popularity',
    isLabelTranslatable: true
  },
  {
    id: 'view-by-competition',
    value: ViewBy.COMPETITION,
    label: 'inplay.filters.viewBy.competition',
    isLabelTranslatable: true
  }
];

const InPlayPage = () => {
  const dispatch = useDispatch();
  const { filter } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([CookieNames.IN_PLAY_VIEW_BY]);

  const device = useSelector(getAppDevice);
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const inPlayPaginationSize = useSelector(getInPlayPaginationSize);
  const inPlayDefTabHighlights = useSelector(getInPlayDefTabHighlights);
  const defaultSorting = useSelector(getPageDefaultSorting);
  const isShowMarketOdds = useSelector(getIsShowMarketOdd);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const [viewBy, setViewBy] = useState<ViewBy>(cookies.BIAB_INPLAY_VIEW_BY || defaultSorting);
  const [page, setPage] = useState<number>(0);

  const tab = getTab(filter, inPlayDefTabHighlights);

  const sportIdObj = useMemo(() => {
    return tab === InPlayFilter.NOW && filter !== InPlayFilter.ALL ? { sportId: filter } : {};
  }, [filter, inPlayDefTabHighlights]);

  const handleSelectViewBy = (newViewBy: DropdownItem) => {
    if (newViewBy.value !== viewBy) {
      if (page !== 0) {
        setPage(0);
      }

      dispatch(
        fetchInPlayMarkets({
          id: tab ?? '',
          page: 0,
          size: inPlayPaginationSize,
          payload: { from: new Date().getTime(), viewBy: newViewBy.value as ViewBy, ...sportIdObj }
        })
      );
      setCookie(CookieNames.IN_PLAY_VIEW_BY, newViewBy.value, { path: '/' });
      setViewBy(newViewBy.value as ViewBy);
    }
  };

  const [inPlayComponentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (!filter && isPropertiesLoaded) {
      navigate(`${IN_PLAY_BASE_URL}/${inPlayDefTabHighlights ? InPlayFilter.HIGHLIGHTS : InPlayFilter.ALL}`, {
        replace: true
      });
    }
  }, [filter, isPropertiesLoaded]);

  useEffect(() => {
    if (isShowMarketOdds) {
      dispatch(fetchPopularMarkets());
    }
  }, [isLoggedIn, dispatch, isShowMarketOdds]);

  return (
    <div ref={inPlayComponentRef} className="biab_inplay-page">
      {device === Devices.DESKTOP && <PopularMarket page={PlacementPage.IN_PLAY_ODDS_BANNER} />}
      {device === Devices.MOBILE && (
        <p className={classNames('biab_page-title', styles.mobileTitle)}>{t('inplay.labels.inPlay')}</p>
      )}
      <div className={styles.filtersAndSort}>
        <InPlayFilters viewBy={viewBy} page={page} setPage={setPage} />
        <ViewByDropdown viewBy={viewBy} onSelectViewBy={handleSelectViewBy} options={viewByOptions} />
      </div>
      <InPlaySportsSections tab={tab} sportIdObj={sportIdObj} viewBy={viewBy} page={page} setPage={setPage} />
    </div>
  );
};

export default InPlayPage;
