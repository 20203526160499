import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getOperator } from 'redux/modules/appConfigs/selectors';

const CssOperatorInjection = () => {
  const operator = useSelector(getOperator);

  useEffect(() => {
    if (operator) {
      try {
        require(`styles/operators/${operator}/index.scss`);
      } catch (e) {}
    }
  }, [operator]);

  return null;
};

export default CssOperatorInjection;
