import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import MarketsTable from 'components/MarketsTable';
import { timeSections } from 'constants/sportPage';
import withCollapse from 'hoc/withCollapse';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getInPlayMarketsIds } from 'redux/modules/marketsPrices/selectors';
import {
  getSportPageMarketsRules,
  getSportPageRulesLoading,
  getSportPageSportInfo
} from 'redux/modules/sportPage/selectors';
import { PageBlocks, PlacementPage } from 'types';
import { IMarket, MarketsTableColumn } from 'types/markets';
import { getTableMarketsData } from 'utils/sportPage';

interface SportPageSportSectionProps {
  /**
   * List of markets
   */
  markets: IMarket[];

  /**
   * Callback to subscribe on new messages when there is new visible
   */
  onRenderRow?: () => void;

  /**
   * Default 2 tables for section (In Play and Coming up)
   */
  tables: string[];

  /**
   * Callback for fetching market rules
   * @param marketId
   */
  onFetchMarketRules: (marketId: string) => void;

  /**
   * Hidden header columns in Markets table for mobile
   */
  mobileHiddenHeaderColumns: MarketsTableColumn[];

  /**
   * List of hidden columns for Markets table
   */
  hiddenColumns: MarketsTableColumn[];

  /**
   * Count of bet groups (1 group = back and lay cells) for prices
   */
  betGroupsCount: number;
  page: PlacementPage;
  firstMarketId?: string;
}

const SportPageSportSection = ({
  markets,
  tables,
  onFetchMarketRules,
  mobileHiddenHeaderColumns,
  hiddenColumns,
  betGroupsCount,
  page,
  firstMarketId
}: SportPageSportSectionProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const rulesLoading = useSelector(getSportPageRulesLoading);
  const marketsRules = useSelector(getSportPageMarketsRules);
  const sportInfo = useSelector(getSportPageSportInfo);
  const inPlayMarketIds = useSelector(getInPlayMarketsIds);

  const tablesContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={tablesContainerRef}>
      {tables.map((title, index) => {
        const tableMarkets = getTableMarketsData(markets, inPlayMarketIds, index);

        return (
          <MarketsTable
            timeSection={timeSections[index]}
            key={title}
            headerTitle={t(title)}
            markets={tableMarkets}
            onFetchMarketRules={onFetchMarketRules}
            marketsRules={marketsRules}
            betGroupsCount={betGroupsCount}
            rulesLoading={rulesLoading}
            sportInfo={sportInfo}
            pageBlock={PageBlocks.SPORT}
            hiddenColumns={hiddenColumns}
            hiddenHeaderColumns={mobileHiddenHeaderColumns}
            showHeaderForMobile
            containerRef={tablesContainerRef}
            mobileBetting
            classes={{ header: classNames({ 'biab_group-markets-table-header': device === Devices.MOBILE }) }}
            page={page}
            firstMarketId={firstMarketId || tableMarkets[0]?.marketId}
          />
        );
      })}
    </div>
  );
};

export default withCollapse(SportPageSportSection);
