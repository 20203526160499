import { ReactNode, RefObject, SyntheticEvent, useCallback, useEffect } from 'react';
import BodyClassName from 'react-body-classname';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { KEY_EVENT_TYPE, KEY_NAME_ESC } from 'constants/app';
import { asianViewPopUps as branding, generalBranding } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import { ModalClasses } from 'types';

import styles from './styles.module.scss';

interface ModalProps {
  /**
   * Modal body content
   */
  children: ReactNode;

  /**
   * Modal should be displayed or not
   */
  open: boolean;

  /**
   * Close modal handler
   */
  onClose?: (event?: SyntheticEvent) => void;

  /**
   * Header title
   */
  title?: string;
  /**
   * Header subtitle
   */
  subTitle?: string;

  /**
   * Modal custom class names
   */
  customClassNames?: ModalClasses;

  /**
   * Add ability to close popup, when user clicks to background
   */
  clickableBackground?: boolean;

  /**
   * Add ability to hide title
   */
  hasTitle?: boolean;
  isDisableScroll?: boolean;
  noCloseIcon?: boolean;
  containerRef?: RefObject<HTMLDivElement>;
}

const Modal = ({
  children,
  open,
  onClose,
  title = '',
  subTitle = '',
  customClassNames,
  clickableBackground = false,
  hasTitle = true,
  isDisableScroll = true,
  noCloseIcon = false,
  containerRef
}: ModalProps) => {
  const { isMobile, isAsianViewPage } = useDevice();

  const handleEscKey: (e: globalThis.KeyboardEvent) => void = useCallback(
    (e: globalThis.KeyboardEvent) => {
      if (e.key === KEY_NAME_ESC && onClose && open) {
        onClose();
      }
    },
    [onClose, open]
  );

  useEffect(() => {
    document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
    };
  }, [handleEscKey]);

  if (!open) {
    return null;
  }

  return (
    <>
      <BodyClassName className={classNames({ disableScroll: isDisableScroll })} />
      {createPortal(
        <div
          id="biab_modal"
          className={classNames('biab_modal', styles.modal, customClassNames?.container ?? '', branding.POP_UP)}
          role="dialog"
          ref={containerRef}
        >
          <div
            className={classNames(styles.modal__backdrop, customClassNames?.backdrop ?? '')}
            onClick={clickableBackground ? onClose : undefined}
          />
          <div
            className={classNames('biab_modal-dialog', styles.modal__dialog, customClassNames?.dialog ?? '', {
              [styles.modal__dialog__mobile]: isMobile
            })}
          >
            <div
              className={classNames(styles.modal__content, customClassNames?.content ?? '', {
                [styles.modal__content__mobile]: isMobile
              })}
            >
              {hasTitle && (
                <div
                  className={classNames(
                    generalBranding.MODAL_HEADER,
                    styles.modal__header,
                    customClassNames?.header ?? '',
                    {
                      [styles.modal__headerNoTitle]: !title && isMobile,
                      [styles.modal__header__mobile]: isMobile,
                      [branding.POP_UP_HEADER]: isAsianViewPage,
                      [branding.POP_UP_BORDER]: isAsianViewPage
                    }
                  )}
                >
                  <div className={customClassNames?.headerContent ?? ''}>
                    <h4 className={classNames('biab_modal-title', styles.modal__title, customClassNames?.title ?? '')}>
                      {title || ''}
                    </h4>
                    {subTitle && (
                      <h6 className={classNames('biab_modal-subTitle', styles.modal__subTitle)}>{subTitle}</h6>
                    )}
                  </div>
                  {!noCloseIcon ? (
                    <i
                      onClick={onClose}
                      className={classNames('biab_custom-icon-close', styles.modal__closeIcon, [
                        customClassNames?.closeIcon ?? ''
                      ])}
                    />
                  ) : null}
                </div>
              )}
              <div
                className={classNames('biab_modal-body', styles.modal__body, customClassNames?.body ?? '', {
                  [branding.POP_UP_BODY]: isAsianViewPage,
                  [branding.POP_UP_BORDER]: isAsianViewPage,
                  [styles.modal__body__noTitle]: !hasTitle
                })}
              >
                {children}
              </div>
            </div>
          </div>
        </div>,
        document.getElementById('biab_body')!
      )}
    </>
  );
};

export default Modal;
