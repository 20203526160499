import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { GeneralWebSocketSubscriptionTypes, GeneralWebSocketSubscriptionTypesKeys } from 'constants/app';
import {
  getEventUpdatesWsEnabled,
  getGeneralWsEnabled,
  getIsPropertiesLoaded,
  getLanguage,
  getRunningBallInterval,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import { fetchEventsUpdatedData } from 'redux/modules/marketsPrices';
import {
  getIsConnectedAsianViewGeneral,
  getIsConnectedGeneral,
  getSubscribeToAsianViewGeneralMessages,
  getSubscribeToGeneralMessages
} from 'redux/modules/webSocket/selectors';

type EventsUpdatesInjectionProps = {
  eventIds: string[];
  subscriptionType?: GeneralWebSocketSubscriptionTypesKeys;
  isAsianView?: boolean;
};

const EventsUpdatesInjection = ({
  eventIds,
  subscriptionType = GeneralWebSocketSubscriptionTypes.eventUpdates,
  isAsianView = false
}: EventsUpdatesInjectionProps) => {
  const dispatch = useDispatch();
  const { sportId } = useParams();

  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const runningBallInterval = useSelector(getRunningBallInterval);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const eventUpdatesWsEnabled = useSelector(getEventUpdatesWsEnabled);
  const isConnectedGeneralWebSocket = useSelector(getIsConnectedGeneral);
  const subscribeGeneralWebSocketMessages = useSelector(getSubscribeToGeneralMessages);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);
  const isConnectedAsianViewGeneralWebSocket = useSelector(getIsConnectedAsianViewGeneral);
  const subscribeAsianViewGeneralWebSocketMessages = useSelector(getSubscribeToAsianViewGeneralMessages);

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const eventUpdatesInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const generalWsDataRef = useRef<{
    subscribeGeneralWebSocketMessages: (<F>(params: F) => void) | null;
    isEventUpdatesSubscriptionAvailable: boolean;
    subscribeAsianViewGeneralWebSocketMessages: (<F>(params: F) => void) | null;
  }>({
    subscribeGeneralWebSocketMessages,
    isEventUpdatesSubscriptionAvailable: false,
    subscribeAsianViewGeneralWebSocketMessages
  });

  const isEventUpdatesAvailable = arePropertiesLoaded && !!eventIds.length;
  const isEventUpdatesIntervalAvailable = isEventUpdatesAvailable && (!generalWsEnabled || !eventUpdatesWsEnabled);
  const isConnectedWS = isAsianView ? isConnectedAsianViewGeneralWebSocket : isConnectedGeneralWebSocket;
  const isSubscribeFunc = isAsianView
    ? !!subscribeAsianViewGeneralWebSocketMessages
    : !!subscribeGeneralWebSocketMessages;
  const isEventUpdatesSubscriptionAvailable =
    isEventUpdatesAvailable && eventUpdatesWsEnabled && generalWsEnabled && isConnectedWS && isSubscribeFunc;

  generalWsDataRef.current = {
    subscribeGeneralWebSocketMessages,
    isEventUpdatesSubscriptionAvailable,
    subscribeAsianViewGeneralWebSocketMessages
  };

  useEffect(() => {
    if (isEventUpdatesIntervalAvailable) {
      dispatch(fetchEventsUpdatedData(eventIds));

      eventUpdatesInterval.current = setInterval(() => {
        dispatch(fetchEventsUpdatedData(eventIds));
      }, runningBallInterval);
    }
    return () => {
      if (eventUpdatesInterval.current) {
        clearInterval(eventUpdatesInterval.current);
      }
    };
  }, [eventIds, language, sportId, timezone, isEventUpdatesIntervalAvailable, runningBallInterval]);

  useEffect(() => {
    return () => {
      const {
        subscribeGeneralWebSocketMessages: subscribeFuncSports,
        isEventUpdatesSubscriptionAvailable: isEventUpdatesAvailableWS,
        subscribeAsianViewGeneralWebSocketMessages: subscribeFuncAV
      } = generalWsDataRef.current;

      const subscribeFunc = isAsianView ? subscribeFuncAV : subscribeFuncSports;

      if (subscribeFunc && isEventUpdatesAvailableWS) {
        subscribeFunc({
          [subscriptionType]: { subscribe: false }
        });
      }
    };
  }, [eventIds]);

  useEffect(() => {
    const subscribeFunc = isAsianView ? subscribeAsianViewGeneralWebSocketMessages : subscribeGeneralWebSocketMessages;

    if (isEventUpdatesSubscriptionAvailable && subscribeFunc) {
      timeout.current = setTimeout(() => {
        if (generalWsDataRef.current.isEventUpdatesSubscriptionAvailable) {
          subscribeFunc({
            [subscriptionType]: {
              subscribe: true,
              eventIds
            }
          });
        }
      }, 500);
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  }, [isEventUpdatesSubscriptionAvailable, eventIds]);

  return null;
};

export default EventsUpdatesInjection;
