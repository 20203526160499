import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';
import { ECancelAllBtnState } from 'types/betslip';

import { ECancelActionStatuses, TCancelActionData, TCancelActionsState, TCancelActionStatusPayload } from './type';

const initialState: TCancelActionsState = {
  actions: {},
  loading: false,
  inProgress: false,
  error: null,
  cancelActionStatus: null,
  cancelAllBtnState: ECancelAllBtnState.HIDDEN
};

const slice = createSlice({
  name: SLICES_NAMES.CANCEL_ACTIONS,
  initialState,
  reducers: {
    fetchCancelActionStatus: (state, _: PayloadAction<TCancelActionStatusPayload>) => {
      state.loading = true;
      state.inProgress = true;
    },
    successGetCancelActionStatus: (
      state,
      { payload }: PayloadAction<{ cancelActionId: number; data: TCancelActionData }>
    ) => {
      const status = payload.data.status;

      state.actions[payload.cancelActionId] = payload.data;
      state.loading = false;
      state.inProgress =
        status !== ECancelActionStatuses.SUCCESSFUL && status !== ECancelActionStatuses.SUCCESSFUL_WITH_LESS_CANCELLED;
      state.cancelActionStatus = payload.data;
    },
    failureGetCancelActionStatus: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
      state.inProgress = false;
    },
    removeCancelActionStatus: state => {
      state.cancelActionStatus = null;
    },
    setCancelAllBtnState: (state, { payload }: PayloadAction<ECancelAllBtnState>) => {
      state.cancelAllBtnState = payload;
    }
  }
});

export const {
  fetchCancelActionStatus,
  successGetCancelActionStatus,
  failureGetCancelActionStatus,
  removeCancelActionStatus,
  setCancelAllBtnState
} = slice.actions;

export default slice.reducer;
