import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { EXCHANGE, GAME } from 'constants/app';
import { betslipBranding as branding } from 'constants/branding';
import { MobilePlacementTypes } from 'constants/inlinePlacement';
import { CANCEL_ACTION_FETCHING_TIMEOUT, HIDE_MOBILE_PLACEMENT_TIMEOUT } from 'constants/placement';
import useDevice from 'hooks/useDevice';
import {
  getBalanceWsEnabled,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled
} from 'redux/modules/appConfigs/selectors';
import { setBetslipLoading } from 'redux/modules/betslip';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { fetchCancelActionStatus, removeCancelActionStatus, setCancelAllBtnState } from 'redux/modules/cancelActions';
import { getCancelActionStatus, getCancelAllBtnState, getLoading } from 'redux/modules/cancelActions/selectors';
import { ECancelActionStatuses } from 'redux/modules/cancelActions/type';
import { setCurrentBetActionForAll } from 'redux/modules/currentBets';
import { getCurrentBetsByType } from 'redux/modules/currentBets/selectors';
import { ECurrentBetActions } from 'redux/modules/currentBets/type';
import { setMobilePlacementNotificationsError } from 'redux/modules/inlinePlacement';
import { cancelAllBets, successCancelAllBets } from 'redux/modules/placement';
import { getCancelledBetsStatusId } from 'redux/modules/placement/selectors';
import { TCancelBet, TPlacementError } from 'redux/modules/placement/type';
import { fetchBalance } from 'redux/modules/user';
import { PageBlocks } from 'types';
import { MatchTypes } from 'types/bets';
import { ECancelAllBtnState } from 'types/betslip';

import styles from './styles.module.scss';

type CancelAllButtonProps = {
  marketId?: string;
};
const CancelAllButton = ({ marketId }: CancelAllButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isMobile } = useDevice();
  const cancelAllBtnState = useSelector(getCancelAllBtnState);
  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const unmatchedBets = useSelector(
    getCurrentBetsByType({ type: MatchTypes.UNMATCHED, isGameType: isGameBetSlip, ignoreCancelled: true })
  );
  const cancelledBetsStatusId = useSelector(getCancelledBetsStatusId);
  const cancelActionStatus = useSelector(getCancelActionStatus);
  const loading = useSelector(getLoading);
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);
  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);

  const isActive = cancelAllBtnState === ECancelAllBtnState.ACTIVE;
  const isConfirm = cancelAllBtnState === ECancelAllBtnState.CONFIRM;
  const isCancelling = cancelAllBtnState === ECancelAllBtnState.CANCELLING;
  const isHidden = cancelAllBtnState === ECancelAllBtnState.HIDDEN;

  const isCancelAllBtnVisible = useMemo<boolean>(
    () =>
      (unmatchedBets.length > 0 && !marketId) ||
      (!!marketId && unmatchedBets.filter(bet => bet.marketId == marketId).length > 0),
    [unmatchedBets, marketId]
  );

  const isVisible = !isHidden && !isCancelling;

  const statusesInterval = useRef<NodeJS.Timer>();

  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  const betTypes = isGameBetSlip ? GAME : EXCHANGE;

  const cancelAllBetsData = marketId ? { betTypes: [betTypes], marketId } : { betTypes: [betTypes] };

  const clearStatusInterval = () => {
    if (statusesInterval.current) {
      clearInterval(statusesInterval.current);
    }
  };

  const handleCancelAllError = (error: string) => {
    const data: Record<string, TCancelBet[]> = {};
    unmatchedBets.forEach(bet => {
      data[bet.marketId] = [{ offerId: bet.offerId }];
    });

    dispatch(
      setMobilePlacementNotificationsError({
        error: error as TPlacementError,
        bets: data,
        pageBlock: PageBlocks.MARKET_ODDS,
        placementType: MobilePlacementTypes.Cancel
      })
    );
    dispatch(setCancelAllBtnState(ECancelAllBtnState.ACTIVE));
    dispatch(
      setCurrentBetActionForAll(
        unmatchedBets.map(({ offerId }) => {
          return {
            offerId,
            action: null
          };
        })
      )
    );
  };

  const cancelAllHandler = () => {
    if (isActive) {
      dispatch(setCancelAllBtnState(ECancelAllBtnState.CONFIRM));
    } else if (isConfirm) {
      clearStatusInterval();
      dispatch(successCancelAllBets(null));
      dispatch(setCancelAllBtnState(ECancelAllBtnState.CANCELLING));

      dispatch(
        cancelAllBets({
          data: cancelAllBetsData,
          errorCallback: handleCancelAllError
        })
      );
    }
  };

  useEffect(() => {
    dispatch(setCancelAllBtnState(isCancelAllBtnVisible ? ECancelAllBtnState.ACTIVE : ECancelAllBtnState.HIDDEN));
  }, [isCancelAllBtnVisible]);

  useEffect(() => {
    dispatch(setBetslipLoading(isCancelling));

    if (isCancelling) {
      dispatch(
        setCurrentBetActionForAll(
          unmatchedBets.map(({ offerId }) => {
            return { offerId, action: isMobile ? ECurrentBetActions.CANCELLING_ALL : ECurrentBetActions.CANCELLING };
          })
        )
      );
    }
  }, [isCancelling]);

  useEffect(() => {
    if (cancelledBetsStatusId) {
      if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
        dispatch(fetchBalance());
      }

      statusesInterval.current = setInterval(() => {
        if (!loadingRef.current) {
          dispatch(
            fetchCancelActionStatus({
              cancelActionId: cancelledBetsStatusId
            })
          );
        }
      }, CANCEL_ACTION_FETCHING_TIMEOUT);
    }
  }, [cancelledBetsStatusId]);

  useEffect(() => {
    if (cancelActionStatus) {
      if (
        cancelActionStatus.status === ECancelActionStatuses.SUCCESSFUL ||
        cancelActionStatus.status === ECancelActionStatuses.SUCCESSFUL_WITH_LESS_CANCELLED
      ) {
        if (isMobile) {
          setTimeout(() => {
            dispatch(removeCancelActionStatus());
          }, HIDE_MOBILE_PLACEMENT_TIMEOUT);
        } else {
          dispatch(removeCancelActionStatus());
        }

        clearStatusInterval();
        dispatch(successCancelAllBets(null));
        dispatch(setCancelAllBtnState(ECancelAllBtnState.HIDDEN));
      } else if (cancelActionStatus.status === ECancelActionStatuses.FAILED) {
        clearStatusInterval();

        if (isMobile) {
          dispatch(
            setCurrentBetActionForAll(
              unmatchedBets.map(({ offerId }) => {
                return { offerId, action: null };
              })
            )
          );
        }
      }
    }
  }, [cancelActionStatus?.id, cancelActionStatus?.status]);

  useEffect(() => {
    return () => {
      clearStatusInterval();
      dispatch(successCancelAllBets(null));
      dispatch(removeCancelActionStatus());
    };
  }, []);

  return (
    <>
      {isVisible && (
        <div className={classNames(styles.cancelAllBtnWrap, branding.CANCEL_ALL_OFFERS_BTN_WRAP)}>
          <button
            className={classNames(branding.BTN, styles.cancelAllBtnWrap__btn, {
              [branding.CANCEL_ALL_OFFERS_BTN]: isActive,
              [branding.CONFIRM_CANCEL_ALL_OFFERS_BTN]: isConfirm,
              [branding.DISABLED]: isCancelling
            })}
            type="button"
            disabled={isCancelling}
            onClick={cancelAllHandler}
          >
            {t(`betslip.labels.btn.${isActive ? 'cancelAllBets' : 'confirmCancellation'}`)}
          </button>
        </div>
      )}
    </>
  );
};

export default CancelAllButton;
