import { TCurrentPL } from 'redux/modules/plTable/type';

export const getTeamName = (team: Record<number, string>) => Object.values(team)[0] || '';

interface IInitValue {
  homeLine: Record<string, string>;
  zero: string;
  awayLine: Record<string, string>;
}

const getData = (data: TCurrentPL[] = [], homeId: string, awayId: string) => {
  let startHome = '';
  let startAway = '';
  const lastProfitOrLoss = data[data.length - 1]?.profitOrLoss;
  return data.reduce(
    (acc: IInitValue, item) => {
      if (item.virtualWinnerSelectionId == homeId) {
        if (!acc.homeLine[item.profitOrLoss]) {
          startHome = `${item.virtualPoints}`;
          return {
            ...acc,
            homeLine: {
              ...acc.homeLine,
              [item.profitOrLoss]: `${item.virtualPoints}`
            }
          };
        } else {
          return {
            ...acc,
            homeLine: {
              ...acc.homeLine,
              [item.profitOrLoss]:
                Object.keys(acc.homeLine)[0] === item.profitOrLoss
                  ? `${item.virtualPoints}+`
                  : `${startHome} - ${item.virtualPoints}`
            }
          };
        }
      }

      if (!item.virtualWinnerSelectionId) {
        return {
          ...acc,
          zero: item.profitOrLoss
        };
      }

      if (item.virtualWinnerSelectionId == awayId) {
        if (!acc.awayLine[item.profitOrLoss]) {
          startAway = `${item.virtualPoints}`;
          return {
            ...acc,
            awayLine: {
              ...acc.awayLine,
              [item.profitOrLoss]:
                lastProfitOrLoss === item.profitOrLoss ? `${item.virtualPoints}+` : `${item.virtualPoints}`
            }
          };
        } else if (lastProfitOrLoss !== item.profitOrLoss) {
          return {
            ...acc,
            awayLine: {
              ...acc.awayLine,
              [item.profitOrLoss]: acc.awayLine[item.profitOrLoss]
                ? `${startAway} - ${item.virtualPoints}`
                : `${acc.awayLine[item.profitOrLoss]}`
            }
          };
        }
      }

      return acc;
    },
    {
      homeLine: {},
      zero: '',
      awayLine: {}
    }
  );
};

export const getTableValue = (current: TCurrentPL[], worst: TCurrentPL[], homeId: string, awayId: string) => {
  const curPLValue = getData(current, homeId, awayId);
  const worstPLValue = getData(worst, homeId, awayId);

  const currentHeader = [...Object.values(curPLValue.homeLine), ...Object.values(curPLValue.awayLine)];
  const currentValues = [...Object.keys(curPLValue.homeLine), curPLValue.zero, ...Object.keys(curPLValue.awayLine)];

  const awayHeader = [...Object.values(worstPLValue.homeLine), ...Object.values(worstPLValue.awayLine)];
  const awayValues = [...Object.keys(worstPLValue.homeLine), worstPLValue.zero, ...Object.keys(worstPLValue.awayLine)];

  return {
    header: currentHeader.length ? currentHeader : awayHeader,
    curPLHomeValue: Object.keys(curPLValue.homeLine).length
      ? Object.keys(curPLValue.homeLine)
      : Object.keys(worstPLValue.homeLine),
    curPLAwayValue: Object.keys(curPLValue.awayLine).length
      ? Object.keys(curPLValue.awayLine)
      : Object.keys(worstPLValue.awayLine),
    curPLValue,
    worstPLValue,
    currentValues,
    awayValues,
    homeLength: Object.keys(curPLValue.homeLine).length || Object.keys(worstPLValue.homeLine).length,
    awayLength: Object.keys(curPLValue.awayLine).length || Object.keys(worstPLValue.awayLine).length
  };
};
