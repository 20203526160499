import { useCallback, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import BannersContainer from 'components/BannersContainer';
import MarketsTable from 'components/MarketsTable';
import MiddleSectionPageSkeleton from 'components/Skeleton/MiddleSectionPageSkeleton';
import { BannerNames, GeneralWebSocketSubscriptionTypes } from 'constants/app';
import EventsUpdatesInjection from 'injections/EventsUpdatesInjection';
import {
  getAppDevice,
  getMarketSuspendedTimeToHideSeconds,
  getPageDefaultSorting
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getIsDisplayedBannerByName } from 'redux/modules/banners/selectors';
import { BannerSections } from 'redux/modules/banners/type';
import { fetchHomeMarketRules, fetchHomeMarketsData } from 'redux/modules/home';
import {
  getHomeMarketData,
  getHomeMarketsEventsIds,
  getHomeMarketsLoading,
  getHomeMarketsRules,
  getHomeMarketsRulesLoading
} from 'redux/modules/home/selectors';
import { resetSuspendedOrClosedMarketPrices } from 'redux/modules/marketsPrices';
import { getSuspendedOrClosedMarketPrices } from 'redux/modules/marketsPrices/selectors';
import { CookieNames, MarketStatus, PageBlocks, PlacementPage, ViewBy } from 'types';
import { MarketsTableColumn } from 'types/markets';

import HomeEmpty from '../HomeEmpty';

import styles from './styles.module.scss';

type HomeMarketsTableProps = {
  /**
   * ID of the currently displayed sport for which the markets will be shown
   */
  sportId: string;

  /**
   * The name of the currently displayed sport for links below the table
   */
  sportName: string;
};

const HomeMarketsTable = ({ sportId, sportName }: HomeMarketsTableProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([CookieNames.COMPETITION_VIEW_BY, CookieNames.COMPETITION_TIME_FILTER]);

  const device = useSelector(getAppDevice);
  const defaultSorting = useSelector(getPageDefaultSorting);
  const marketSuspendedTimeToHideSeconds = useSelector(getMarketSuspendedTimeToHideSeconds);
  const loading = useSelector(getHomeMarketsLoading);
  const rulesLoading = useSelector(getHomeMarketsRulesLoading);
  const marketsData = useSelector(getHomeMarketData(sportId));
  const marketsRules = useSelector(getHomeMarketsRules(sportId));
  const suspendedOrClosedMarketPrices = useSelector(getSuspendedOrClosedMarketPrices);
  const isBannerDisplayed = useSelector(getIsDisplayedBannerByName(BannerNames.homePage));
  const eventIds = useSelector(getHomeMarketsEventsIds(sportId));

  const competitionViewBy =
    cookies?.BIAB_COMPETITION_VIEW_BY === ViewBy.COMPETITION ? defaultSorting : cookies?.BIAB_COMPETITION_VIEW_BY;
  const [viewBy, setViewBy] = useState<ViewBy>(competitionViewBy || defaultSorting);

  const timeouts = useRef<Record<string, NodeJS.Timeout | null>>({});

  /**
   * If there are three runners in at least one market we must display three
   * price group columns (group - one price for back and one price for lay)
   */
  const betGroupsCount = marketsData?.marketCatalogueList?.some(({ runners }) => runners.length === 3) ? 3 : 2 ?? 0;

  useEffect(() => {
    if (suspendedOrClosedMarketPrices) {
      /**
       * If we received a SUSPENDED status for a market we should start timer and if we didn't receive
       * different status for this market within specified time we need to update the data for all markets after the
       * timer ends, otherwise just clear created timer
       */
      const isSuspended = suspendedOrClosedMarketPrices.marketDefinition?.status === MarketStatus.SUSPENDED;
      const isClosedStatus = suspendedOrClosedMarketPrices.marketDefinition?.status === MarketStatus.CLOSED;
      const currentTimeout = timeouts.current[suspendedOrClosedMarketPrices.id];

      if (isSuspended && !currentTimeout) {
        const timeout = setTimeout(() => {
          dispatch(resetSuspendedOrClosedMarketPrices());
          dispatch(fetchHomeMarketsData({ sportId, isMobile: device === Devices.MOBILE, sortBy: viewBy }));
          timeouts.current = {};
        }, marketSuspendedTimeToHideSeconds * 1000);

        timeouts.current = { ...timeouts.current, [suspendedOrClosedMarketPrices.id]: timeout };
      } else if (!isSuspended && currentTimeout) {
        clearTimeout(currentTimeout);
        timeouts.current[suspendedOrClosedMarketPrices.id] = null;
      }

      if (isClosedStatus) {
        dispatch(resetSuspendedOrClosedMarketPrices());
        dispatch(fetchHomeMarketsData({ sportId, isMobile: device === Devices.MOBILE, sortBy: viewBy }));
      }
    }
  }, [suspendedOrClosedMarketPrices, device]);

  const handleFetchMarketRules = useCallback((marketId: string) => {
    dispatch(fetchHomeMarketRules({ sportId, marketId }));
  }, []);

  const onChangeSorting = useCallback(
    (selectedSort: ViewBy) => {
      setViewBy(selectedSort);
      setCookie(CookieNames.COMPETITION_VIEW_BY, selectedSort, { path: '/' });
      dispatch(fetchHomeMarketsData({ sportId, isMobile: device === Devices.MOBILE, sortBy: selectedSort }));
    },
    [device, dispatch, sportId, viewBy]
  );

  useEffect(() => {
    if (sportId) {
      dispatch(fetchHomeMarketsData({ sportId, isMobile: device === Devices.MOBILE, sortBy: viewBy }));
    }
  }, [sportId, device]);

  if (!loading && marketsData && !marketsData.marketCatalogueList.length) {
    return <HomeEmpty />;
  }

  if (loading) {
    return <MiddleSectionPageSkeleton itemsCount={10} withDelay={150} isAbsolute={false} />;
  }

  return (
    <div className="biab_inplay-sports-container">
      <EventsUpdatesInjection
        eventIds={eventIds}
        subscriptionType={GeneralWebSocketSubscriptionTypes.eventInfoUpdates}
      />
      <div className={classNames('biab_group-markets', styles.marketsTableContainer)}>
        <MarketsTable
          markets={marketsData?.marketCatalogueList || []}
          betGroupsCount={betGroupsCount}
          sportInfo={marketsData?.sportInfo ?? null}
          marketsRules={marketsRules}
          onFetchMarketRules={handleFetchMarketRules}
          rulesLoading={rulesLoading}
          pageBlock={PageBlocks.HOME}
          hiddenColumns={[MarketsTableColumn.MOBILE_SPORT_ICON, MarketsTableColumn.DATE_AND_MATCHED]}
          sortBy={viewBy}
          onChangeSorting={onChangeSorting}
          page={PlacementPage.FEATURED_SPORTS}
        />
        {device === Devices.MOBILE && isBannerDisplayed && (
          <BannersContainer bannerType={BannerSections.BADGE_BANNER} />
        )}
        <div className={classNames('biab_group-table-bottom', styles.tableBottom)}>
          <Link to={`/inplay/${sportId}`} className="biab_more-in-play">
            <span>
              {t('group.more.inplay')} {sportName}
            </span>
            {device === Devices.DESKTOP && <i className={classNames('fa2 fa2-arrow-right', styles.chevronRight)} />}
          </Link>
          <Link to={`/sport/${sportId}`} className="biab_more-sport">
            <span>
              {t('group.more')} {sportName}
            </span>
            {device === Devices.DESKTOP && <i className={classNames('fa2 fa2-arrow-right', styles.chevronRight)} />}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeMarketsTable;
