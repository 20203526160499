export const translations: Record<string, Record<string, string>> = {
  en: {
    title: 'Online Sports Betting - PokerStars Sports - Back Soon',
    description:
      'PokerStars Sports is currently undergoing maintenance. Come back soon for an improved sports betting experience.',
    h1: 'HAD TO CALL A TIMEOUT.',
    h2: 'BACK AFTER MAINTENANCE.',
    firstDescription: 'You can still use our online Help Center.',
    secondDescription: 'FAQs and support can be found',
    here: 'here',
    link: 'https://www.pokerstarssports.com/help/'
  },
  da_dk: {
    title: 'Spil på sport online – PokerStars Sports – vi er snart tilbage',
    description:
      'PokerStars Sports er i øjeblikket ved at blive opdateret. Vend snart tilbage og få en endnu bedre oplevelse med at spille på sport.',
    h1: 'VI VAR NØDT TIL AT TAGE EN TIMEOUT.',
    h2: 'VI ER TILBAGE EFTER OPDATERING.',
    firstDescription: 'Du kan stadig bruge vores hjælpcenter.',
    secondDescription: 'Du kan finde en FAQ og kundeservice ',
    here: 'her',
    link: 'https://www.pokerstarssports.dk/help/'
  },
  de_de: {
    title: 'Online-Sportwetten - PokerStars Sports - Wir sind gleich wieder da',
    description:
      'PokerStars Sports wird gerade gewartet. Schauen Sie in Kürze wieder vorbei und erleben Sie unser verbessertes Sportwettenangebot.',
    h1: 'WIR NEHMEN EINE AUSZEIT.',
    h2: 'ZURÜCK NACH EINER WARTUNG.',
    firstDescription: 'Nutzen Sie solange unser Online-Hilfecenter.',
    secondDescription: 'Die Seite Häufige Fragen (FAQ) und den Support finden Sie ',
    here: 'hier',
    link: 'https://www.pokerstarssports.com/de/help/'
  },
  es_419: {
    title: 'Apuestas deportivas online - PokerStars Sports - Volvemos en breve',
    description:
      'PokerStars Sports está actualmente en mantenimiento. Vuelve pronto para disfrutar de una mejor experiencia de apuestas deportivas.',
    h1: 'TUVIMOS QUE IMPLEMENTAR UN TIEMPO DE INACTIVIDAD.',
    h2: 'REGRESAREMOS TRAS EL MANTENIMIENTO.',
    firstDescription: 'Puedes continuar usando nuestro Centro de ayuda online',
    thirdDescription: 'encontrarás soporte y preguntas frecuentes.',
    here: 'Aquí',
    link: 'https://www.pokerstarssports.com/es_419/help/'
  },
  fi: {
    title: 'Nettiurheiluvedonlyönti – PokerStars Sports – palaamme pian',
    description:
      'PokerStars Sportsilla suoritetaan tällä hetkellä ylläpitoa. Tule takaisin pian ja koe parannettu urheiluvedonlyöntikokemus.',
    h1: 'JOUDUMME PITÄMÄÄN TAUON.',
    h2: 'PALAAMME TAKAISIN HUOLTOTYÖN PÄÄTYTTYÄ.',
    firstDescription: 'Voit vielä käyttää tukikeskustamme netissä',
    secondDescription: 'Usein kysytyt kysymykset ja asiakastuki löytyvät ',
    here: 'tästä',
    link: 'https://www.pokerstarssports.com/fi/help/'
  },
  fr_fr: {
    title: 'Paris sportifs en ligne - PokerStars Sports - De retour prochainement',
    description:
      'PokerStars Sports est en cours de maintenance. Revenez très bientôt pour profiter d’une expérience de paris sportifs améliorée.',
    h1: 'TEMPS MORT.',
    h2: 'NOUS SERONS DE RETOUR APRÈS LA MAINTENANCE.',
    firstDescription: "Vous pouvez toujours vous rendre dans notre centre d'aide.",
    secondDescription: 'Vous trouverez notre FAQ et notre support clientèle ',
    here: 'ici',
    link: 'https://www.pokerstarssports.com/fr/help/'
  },
  hr_hr: {
    title: 'Online sportsko klađenje - PokerStars Sports - Vraćamo se ubrzo',
    description:
      'U tijeku je održavanje PokerStars Sports stranice. Dođite uskoro za poboljšano iskustvo sportskog klađenja.',
    h1: 'NA PAUZI SMO.',
    h2: 'VRAĆAMO SE NAKON ODRŽAVANJA.',
    firstDescription: 'Ako želite, možete posjetiti naš centar za pomoć. ',
    thirdDescription: 'ćete pronaći sve odgovore na često postavljana pitanja i podršku korisnicima.',
    here: 'Ovdje',
    link: 'https://www.pokerstarssports.com/hr/help/'
  },
  hu_hu: {
    title: 'Online sportfogadás – PokerStars Sports – Hamarosan visszatérünk',
    description:
      'A PokerStars Sportson jelenleg karbantartás folyik. Hamarosan visszatérünk egy tökéletesített sportfogadási élménnyel.',
    h1: 'MUSZÁJ IDŐT KÉRNÜNK.',
    h2: 'A KARBANTARTÁS UTÁN VISSZATÉRÜNK.',
    firstDescription: 'Továbbra is használható online Ügyfélközpontunk.',
    secondDescription: 'A GYIK és az ügyfélszolgálat ',
    here: 'itt',
    link: 'https://www.pokerstarssports.com/hu/help/'
  },
  is: {
    title: 'Íþróttagetraunir á netinu - PokerStars Sports - Afsakið hlé',
    description:
      'Viðhald er í gangi á PokerStars Sports eins og er. Komdu fljótt aftur og fáðu betri upplifun í íþróttagetraunum.',
    h1: 'ÞURFTUM AÐ TAKA LEIKHLÉ.',
    h2: 'KOMUM AFTUR EFTIR AÐ VIÐHALDI LÝKUR.',
    firstDescription: 'Þú getur enn notað Hjálparmiðstöðina okkar á netinu.',
    secondDescription: 'Algengar spurningar og stuðning er hægt að finna',
    here: 'hér',
    link: 'https://www.pokerstarssports.com/is/help/'
  },
  is_it: {
    title: 'Internetinės sporto lažybos – PokerStars Sports – netrukus sugrįš',
    description:
      'PokerStars Sports šiuo metu vyksta techninės priežiūros darbai. Netrukus grįžkite ir mėgaukitės dar geresnėmis sporto lažybų paslaugomis.',
    h1: 'TURĖJOME PADARYTI PERTRAUKĄ.',
    h2: 'GRĮŠIME PASIBAIGUS TECHNINĖS PRIEŽIŪROS DARBAMS.',
    firstDescription: 'Vis tiek galite naudotis mūsų internetiniu pagalbos centru.',
    secondDescription: 'DUK ir palaikymą rasite',
    here: 'čia',
    link: 'https://www.pokerstarssports.com/ln/help/'
  },
  ja_jp: {
    title: 'オンライン スポーツベッティング - PokerStars Sports - まもなく再開します',
    description:
      'PokerStars Sports は現在メンテナンス中です。まもなくサービスを再開し、さらに向上したスポーツベッティング体験をお届けします。',
    h1: 'タイムアウト中です。',
    h2: 'メンテナンスが済んだら戻ります。',
    firstDescription: 'オンラインのヘルプ センターをご利用になれます。',
    secondDescription: 'くある質問やサポートは ',
    here: 'こちら。',
    link: 'https://www.pokerstarssports.com/ja/help/'
  },
  it_it: {
    title: 'Scommesse sportive online - PokerStars Sports - Torneremo presto',
    description:
      'PokerStars Sports è attualmente in fase di manutenzione. Torna a trovarci presto per un&#x27;esperienza di scommesse sportive ancora migliore.',
    h1: 'CHIAMIAMO TIME OUT.',
    h2: 'TORNEREMO AL TERMINE DELLA MANUTENZIONE.',
    firstDescription: 'Nel frattempo, puoi continuare a usare il nostro Centro assistenza.',
    secondDescription: 'Le domande frequenti e il Servizio clienti sono disponibili cliccando',
    here: 'qui',
    link: 'https://www.pokerstarssports.com/it/help/'
  },
  lv_lv: {
    title: 'Tiešsaistes sporta likmes – PokerStars Sports – darbība drīzumā tiks atjaunota',
    description:
      'PokerStars Sports pašreiz tiek veikti uzturēšanas darbi. Atgriezieties jau drīzumā, lai izbaudītu uzlabotus sporta totalizatora pakalpojumus.',
    h1: 'MUMS NĀCĀS PAŅEMT PĀRTRAUKUMU.',
    h2: 'ATGRIEZĪSIMIES PĒC UZTURĒŠANAS DARBU BEIGĀM.',
    firstDescription: 'Jūs joprojām varat izmantot mūsu tiešsaistes palīdzības centru.',
    secondDescription: 'FBieži uzdotos jautājumus un palīdzību Jūs atradīsiet',
    here: 'šeit',
    link: 'https://www.pokerstarssports.com/lv/help/'
  },
  no_no: {
    title: 'Oddsspill på nett – PokerStars Sports – Straks tilbake',
    description:
      'PokerStars Sports undergår for øyeblikket vedlikehold. Kom tilbake om litt for en enda bedre oddsspillopplevelse.',
    h1: 'VI MÅTTE TA EN KORT PAUSE.',
    h2: 'TILBAKE ETTER VEDLIKEHOLD',
    firstDescription: 'Du kan fortsatt bruke hjelpesenteret på nett.',
    secondDescription: 'Du finner ofte stilte spørsmål (FAQ) og kundeservice',
    here: 'her',
    link: 'https://www.pokerstarssports.com/no/help/'
  },
  pl_pl: {
    title: 'Internetowe zakłady bukmacherskie – PokerStars Sports – przerwa',
    description:
      'PokerStars Sports jest w trakcie konserwacji. Zapraszamy już niedługo do ulepszonej wersji naszego serwisu bukmacherskiego.',
    h1: 'TRENER POPROSIŁ O CZAS.',
    h2: 'PO PRACACH SERWISOWYCH WRACAMY DO GRY.',
    firstDescription: 'Centrum pomocy działa bez zakłóceń.',
    secondDescription: ' Możesz również korzystać z ',
    here: 'FAQ i artykułów',
    link: 'https://www.pokerstarssports.com/pl/help/'
  },
  pt_br: {
    title: 'Apostas Esportivas Online - PokerStars Sports - Voltamos Logo',
    description:
      'O PokerStars Sports está em manutenção no momento. Volte em breve para uma experiência melhorada de aposta esportivas.',
    h1: 'TIVEMOS QUE DAR UM TEMPO.',
    h2: 'VOLTAMOS DEPOIS DA MANUTENÇÃO.',
    firstDescription: 'Você ainda pode usar nossa Central de Ajuda.',
    secondDescription: 'Perguntas Frequentes e suporte podem ser encontrados',
    here: 'aqui',
    link: 'https://www.pokerstarssports.com/pt-BR/help/'
  },
  ru_ru: {
    title: 'Спортивные ставки онлайн - PokerStars Sports - Техническое обслуживание',
    description:
      'В настоящий момент проводится техническое обслуживание PokerStars Sports. Сервис возобновит свою работу в ближайшее время',
    h1: 'МЫ ВЫНУЖДЕНЫ ВЗЯТЬ ТАЙМ-АУТ.',
    h2: 'НО МЫ ВЕРНЕМСЯ ПОСЛЕ ЗАВЕРШЕНИЯ ТЕХНИЧЕСКОГО ОБСЛУЖИВАНИЯ.',
    firstDescription: 'Тем не менее, наш справочный центр по-прежнему доступен.',
    secondDescription: 'Нажмите',
    thirdDescription: 'чтобы получить ответы на свои вопросы или обратиться в службу поддержки.',
    here: 'здесь,',
    link: 'https://www.pokerstarssports.com/de/help/'
  },
  sl_sl: {
    title: 'Spletne športne stave – PokerStars Sports – Kmalu spet na voljo',
    description:
      'Na spletnem mestu PokerStars Sports trenutno potekajo vzdrževalna dela. Kmalu se vrni za še boljšo izkušnjo s športnimi stavami.',
    h1: 'IMAMO KRAJŠI ODMOR.',
    h2: 'SE VIDIMO PO ZAKLJUČKU VZDRŽEVANJA.',
    firstDescription: 'Če želiš, lahko uporabiš naš spletni center za pomoč.',
    secondDescription: 'Pogosta vprašanja in podporo najdeš ',
    here: 'tukaj',
    link: 'https://www.pokerstarssports.com/sl/help/'
  },
  uk_ua: {
    title: 'Спортивні ставки онлайн - PokerStars Sports - Повертайтеся незабаром',
    description:
      'Наразі на PokerStars Sports триває технічне обслуговування. Повертайтеся незабаром для отримання покращеного досвіду спортивних ставок.',
    h1: 'ВИМУШЕНИЙ ТАЙМ-АУТ.',
    h2: 'ПОВЕРНЕМОСЯ ПІСЛЯ ТЕХНІЧНОГО ОБСЛУГОВУВАННЯ.',
    firstDescription: 'Ви можете використовувати наш центр допомоги.',
    secondDescription: 'Відповіді на найчастіші запитання можна знайти',
    here: 'тут',
    link: 'https://www.pokerstarssports.com/ua/help/'
  },
  zh_cn: {
    title: '在线体育博彩 – PokerStars扑克之星体彩 – 很快回来',
    description: 'PokerStars扑克之星体彩目前正在进行维护。很快就会回来，为用户提供更为出色的体育博彩体验。',
    h1: '暂停服务。',
    h2: '维护结束后，即刻回归。',
    firstDescription: '您仍可使用线上帮助中心',
    here: '查看常见问答集和客服信息。',
    link: 'https://www.pokerstarssports.com/zh-CN/help/'
  },
  zh_tw: {
    title: '線上體育博彩投注 - PokerStars Sports撲克之星體育博彩 - 很快回來',
    description: 'PokerStars Sports撲克之星體育博彩目前正在進行維護。我們很快就會回來，為您提供更佳的的體育博彩體驗。',
    h1: '必須在此喊聲暫停。',
    h2: '維護之後就會回來。',
    firstDescription: '您仍然可以使用我們的線上說明中心。',
    secondDescription: '您可',
    here: '點此瀏覽',
    link: 'https://www.pokerstarssports.com/zh-TW/help/'
  }
};
