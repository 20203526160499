export const HOW_TO_LABEL = 'How To?';

export const SWIPE_DIRECTION_LEFT = 'left';

export const mobileSidebarItemHeight = 36;

export const welcomeFadeAnimation = 900;

export const spaceKey = ' ';

export const leftArrow = 'ArrowLeft';

export const rightArrow = 'ArrowRight';

export const maxMobileSlidesToShow = 6;

export const CLOSE_BACKDROP_BEHAVIOUR = 'backdrop';

export const USUAL_CLOSE_BEHAVIOUR = 'close';
