import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { MATCH_STATISTICS } from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';

import styles from '../../MatchStatistics/styles.module.scss';

interface IMatchStatIcon {
  onClick?: () => void;
  isSoccer: boolean;
  isLeftSpace?: boolean;
}
const MatchStatIcon = ({ onClick = () => {}, isSoccer, isLeftSpace }: IMatchStatIcon) => {
  const { t } = useTranslation();
  const { translationKey, isEnabled } = useTooltip(MATCH_STATISTICS);

  return (
    <span
      data-tooltip-id={'tooltip'}
      data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
      className={classNames(styles.matchStatistics__tooltip, {
        'cursor-help': isEnabled,
        'cursor-pointer': !isEnabled,
        [styles.leftSpace]: isLeftSpace
      })}
      onClick={onClick}
    >
      <i
        onClick={onClick}
        className={classNames('biab_match-statistics-icon', {
          'fa2 fa2-match-statistics-icon': isSoccer,
          'fa2 fa2-statistics': !isSoccer,
          biab_soccer: isSoccer
        })}
      >
        <span className="path1 biab_icon-bg" />
        <span
          className={classNames('path2', {
            'biab_icon-bg': isSoccer
          })}
        />
        <span className="path3" />
        <span className="path4" />
        <span
          className={classNames('path5', {
            'biab_icon-bg': isSoccer
          })}
        />
        <span className="path6" />
        <span className="path7" />
        <span className="path8" />
        <span className="path9" />
        <span className="path10" />
      </i>
    </span>
  );
};

export default MatchStatIcon;
