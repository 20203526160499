import classNames from 'classnames';

interface RatingStarsProps {
  /**
   * How many stars the Jokey have
   */
  stars?: number;
}
import styles from './styles.module.scss';

const RatingStars = ({ stars = 0 }: RatingStarsProps) => {
  const starRepeatCount = [...Array(5)];

  return (
    <span className={classNames('biab_timeform-runner-rating-stars', styles.timeFormRating__stars)}>
      {starRepeatCount.map((num, index) => (
        <i
          key={index}
          className={classNames('fa fa-star', {
            biab_active: index + 1 <= stars
          })}
        />
      ))}
    </span>
  );
};

export default RatingStars;
