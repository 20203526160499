export const FIRST_INNINGS_FANCY_MARKET_TYPES = [
  '1ST_INNINGS_RUNS',
  '1_INNING_2_OVR_LINE',
  '1_INNING_4_OVR_LINE',
  '1_INNING_3_OVR_LINE',
  '1_INNING_6_OVR_LINE',
  '1_INNING_8_OVR_LINE',
  '1_INNING_10_OVR_LINE',
  '1_INNING_12_OVR_LINE',
  '1_INNING_15_OVR_LINE',
  '1_INNING_18_OVR_LINE',
  '1_INNING_20_OVR_LINE',
  '1_INNING_25_OVR_LINE',
  '1_INNING_30_OVR_LINE',
  '1_INNING_35_OVR_LINE',
  '1_INNING_40_OVR_LINE',
  '1_INNING_45_OVR_LINE',
  '1_INNING_50_OVR_LINE'
];

export const TOTAL_INNINGS_RUNS_FIRST_INNING_MARKET_TYPES = ['INNINGS_RUNS', '1ST_INNINGS_RUNS', '1_INNINGS_RUNS_LINE'];

export const SECOND_INNINGS_FANCY_MARKET_TYPES = [
  '2ND_INNINGS_RUNS',
  '2_INNING_2_OVR_LINE',
  '2_INNING_3_OVR_LINE',
  '2_INNING_4_OVR_LINE',
  '2_INNING_6_OVR_LINE',
  '2_INNING_8_OVR_LINE',
  '2_INNING_10_OVR_LINE',
  '2_INNING_12_OVR_LINE',
  '2_INNING_15_OVR_LINE',
  '2_INNING_18_OVR_LINE',
  '2_INNING_20_OVR_LINE',
  '2_INNING_25_OVR_LINE',
  '2_INNING_30_OVR_LINE',
  '2_INNING_35_OVR_LINE',
  '2_INNING_40_OVR_LINE',
  '2_INNING_45_OVR_LINE',
  '2_INNING_50_OVR_LINE'
];

export const TOTAL_INNINGS_RUNS_SECOND_INNING_MARKET_TYPES = [
  'INNINGS_RUNS',
  '2ND_INNINGS_RUNS',
  '2_INNINGS_RUNS_LINE'
];

export const TEAM_A_BATSMAN_RUNS_MARKET_TYPES = [
  'PLAYER_RUNS_TEAM_A',
  '1ST_INNINGS_RUNS_A',
  '2ND_INNINGS_RUNS_A',
  'A_1ST_INN_RUNS_LINE',
  'A_2ND_INN_RUNS_LINE'
];

export const TEAM_B_BATSMAN_RUNS_MARKET_TYPES = [
  'PLAYER_RUNS_TEAM_B',
  '1ST_INNINGS_RUNS_B',
  '2ND_INNINGS_RUNS_B',
  'B_1ST_INN_RUNS_LINE',
  'B_2ND_INN_RUNS_LINE'
];

export const OTHER_LINE_MARKETS_FIRST_INNING_MARKET_TYPES = [
  '1_INN_25_BALL_LINE',
  '1_INN_50_BALL_LINE',
  '1_INN_75_BALL_LINE',
  '1_INN_100_BALL_LINE',
  'A_1_INN_5_OVR_LINE',
  'A_1_INN_10_OVR_LINE',
  'A_1_INN_15_OVR_LINE',
  'A_1_INN_20_OVR_LINE',
  'A_1_INN_25_OVR_LINE',
  'A_1_INN_30_OVR_LINE',
  'A_1_INN_35_OVR_LINE',
  'A_1_INN_40_OVR_LINE',
  'A_1_INN_45_OVR_LINE',
  'A_1_INN_50_OVR_LINE',
  'A_1_INN_55_OVR_LINE',
  'A_1_INN_60_OVR_LINE',
  'A_1_INN_65_OVR_LINE',
  'A_1_INN_70_OVR_LINE',
  'A_1_INN_75_OVR_LINE',
  'A_1_INN_80_OVR_LINE',
  'A_1_INN_85_OVR_LINE',
  'A_1_INN_90_OVR_LINE',
  'A_1_INN_95_OVR_LINE',
  'A_1_INN_100_OVR_LINE',
  'A_1_INN_105_OVR_LINE',
  'A_1_INN_110_OVR_LINE',
  'A_1_INN_115_OVR_LINE',
  'A_1_INN_120_OVR_LINE',
  'A_1_INN_125_OVR_LINE',
  'A_1_INN_130_OVR_LINE',
  'A_1_INN_135_OVR_LINE',
  'A_1_INN_140_OVR_LINE',
  'A_1_INN_145_OVR_LINE',
  'A_1_INN_150_OVR_LINE',
  'B_1_INN_5_OVR_LINE',
  'B_1_INN_10_OVR_LINE',
  'B_1_INN_15_OVR_LINE',
  'B_1_INN_20_OVR_LINE',
  'B_1_INN_25_OVR_LINE',
  'B_1_INN_30_OVR_LINE',
  'B_1_INN_35_OVR_LINE',
  'B_1_INN_40_OVR_LINE',
  'B_1_INN_45_OVR_LINE',
  'B_1_INN_50_OVR_LINE',
  'B_1_INN_55_OVR_LINE',
  'B_1_INN_60_OVR_LINE',
  'B_1_INN_65_OVR_LINE',
  'B_1_INN_70_OVR_LINE',
  'B_1_INN_75_OVR_LINE',
  'B_1_INN_80_OVR_LINE',
  'B_1_INN_85_OVR_LINE',
  'B_1_INN_90_OVR_LINE',
  'B_1_INN_95_OVR_LINE',
  'B_1_INN_100_OVR_LINE',
  'B_1_INN_105_OVR_LINE',
  'B_1_INN_110_OVR_LINE',
  'B_1_INN_115_OVR_LINE',
  'B_1_INN_120_OVR_LINE',
  'B_1_INN_125_OVR_LINE',
  'B_1_INN_130_OVR_LINE',
  'B_1_INN_135_OVR_LINE',
  'B_1_INN_140_OVR_LINE',
  'B_1_INN_145_OVR_LINE',
  'B_1_INN_150_OVR_LINE'
];

export const OTHER_LINE_MARKETS_SECOND_INNING_MARKET_TYPES = [
  '2_INN_25_BALL_LINE',
  '2_INN_50_BALL_LINE',
  '2_INN_75_BALL_LINE',
  '2_INN_100_BALL_LINE',
  'A_2_INN_5_OVR_LINE',
  'A_2_INN_10_OVR_LINE',
  'A_2_INN_15_OVR_LINE',
  'A_2_INN_20_OVR_LINE',
  'A_2_INN_25_OVR_LINE',
  'A_2_INN_30_OVR_LINE',
  'A_2_INN_35_OVR_LINE',
  'A_2_INN_40_OVR_LINE',
  'A_2_INN_45_OVR_LINE',
  'A_2_INN_50_OVR_LINE',
  'A_2_INN_55_OVR_LINE',
  'A_2_INN_60_OVR_LINE',
  'A_2_INN_65_OVR_LINE',
  'A_2_INN_70_OVR_LINE',
  'A_2_INN_75_OVR_LINE',
  'A_2_INN_80_OVR_LINE',
  'A_2_INN_85_OVR_LINE',
  'A_2_INN_90_OVR_LINE',
  'A_2_INN_95_OVR_LINE',
  'A_2_INN_100_OVR_LINE',
  'A_2_INN_105_OVR_LINE',
  'A_2_INN_110_OVR_LINE',
  'A_2_INN_115_OVR_LINE',
  'A_2_INN_120_OVR_LINE',
  'A_2_INN_125_OVR_LINE',
  'A_2_INN_130_OVR_LINE',
  'A_2_INN_135_OVR_LINE',
  'A_2_INN_140_OVR_LINE',
  'A_2_INN_145_OVR_LINE',
  'A_2_INN_150_OVR_LINE',
  'B_2_INN_5_OVR_LINE',
  'B_2_INN_10_OVR_LINE',
  'B_2_INN_15_OVR_LINE',
  'B_2_INN_20_OVR_LINE',
  'B_2_INN_25_OVR_LINE',
  'B_2_INN_30_OVR_LINE',
  'B_2_INN_35_OVR_LINE',
  'B_2_INN_40_OVR_LINE',
  'B_2_INN_45_OVR_LINE',
  'B_2_INN_50_OVR_LINE',
  'B_2_INN_55_OVR_LINE',
  'B_2_INN_60_OVR_LINE',
  'B_2_INN_65_OVR_LINE',
  'B_2_INN_70_OVR_LINE',
  'B_2_INN_75_OVR_LINE',
  'B_2_INN_80_OVR_LINE',
  'B_2_INN_85_OVR_LINE',
  'B_2_INN_90_OVR_LINE',
  'B_2_INN_95_OVR_LINE',
  'B_2_INN_100_OVR_LINE',
  'B_2_INN_105_OVR_LINE',
  'B_2_INN_110_OVR_LINE',
  'B_2_INN_115_OVR_LINE',
  'B_2_INN_120_OVR_LINE',
  'B_2_INN_125_OVR_LINE',
  'B_2_INN_130_OVR_LINE',
  'B_2_INN_135_OVR_LINE',
  'B_2_INN_140_OVR_LINE',
  'B_2_INN_145_OVR_LINE',
  'B_2_INN_150_OVR_LINE'
];

export const FALL_OF_WICKET_MARKET_TYPE = 'NEXT_MAN_OUT';

export const INNINGS_FOURS_MARKET_TYPE = 'TOTAL_FOURS';

export const INNINGS_SIXES_MARKET_TYPE = 'TOTAL_SIXES';

export const FIRST_INNINGS_FANCY = 'FIRST_INNINGS_FANCY';

export const SECOND_INNINGS_FANCY = 'SECOND_INNINGS_FANCY';

// TODO back here after changing compiler target (es2015) for typescript
export const ALL_FANCY_MARKET_TYPES = Array.from(
  new Set(
    [FALL_OF_WICKET_MARKET_TYPE, INNINGS_FOURS_MARKET_TYPE, INNINGS_SIXES_MARKET_TYPE]
      .concat(FIRST_INNINGS_FANCY_MARKET_TYPES)
      .concat(TOTAL_INNINGS_RUNS_FIRST_INNING_MARKET_TYPES)
      .concat(SECOND_INNINGS_FANCY_MARKET_TYPES)
      .concat(TOTAL_INNINGS_RUNS_SECOND_INNING_MARKET_TYPES)
      .concat(TEAM_A_BATSMAN_RUNS_MARKET_TYPES)
      .concat(TEAM_B_BATSMAN_RUNS_MARKET_TYPES)
      .concat(OTHER_LINE_MARKETS_FIRST_INNING_MARKET_TYPES)
      .concat(OTHER_LINE_MARKETS_SECOND_INNING_MARKET_TYPES)
  )
);
