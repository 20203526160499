import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import EventLiveScore from 'components/EventLiveScore';
import FavoriteStar from 'components/FavoriteStar';
import { FAVORITES_TYPES } from 'constants/app';
import { SPORT_BASE_URL } from 'constants/locations';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getAppDevice, getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { TContent } from 'redux/modules/search/type';
import { PageBlocks } from 'types';
import { parseMillisecondsToTime } from 'utils/date';

import styles from './styles.module.scss';

interface SearchItemProps {
  item: TContent;
  isInViewPort?: boolean;
  isInPlay: boolean;
}

const Wrapper = ({ children }: PropsWithChildren) => {
  const device = useSelector(getAppDevice);

  if (device === Devices.MOBILE) {
    return <div className="biab_market">{children}</div>;
  }

  return <>{children}</>;
};

const SearchItem = ({
  item: { eventTypeId, openDate, id, name, parents, raceMarketId, matchStatEnabled },
  isInViewPort = false,
  isInPlay
}: SearchItemProps) => {
  const { t } = useTranslation();

  const favoriteEntryId = parents.length ? raceMarketId : id;
  const favoriteEntryType = parents.length && !raceMarketId ? FAVORITES_TYPES.market : FAVORITES_TYPES.event;

  const isFavorite = useSelector(getFavoriteById(favoriteEntryId));
  const device = useSelector(getAppDevice);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);

  const eventTime = useLongDateFormat({
    time: openDate,
    hasWeekDay: true
  });

  const favoriteData = {
    entryId: favoriteEntryId,
    entryName: name,
    entryType: favoriteEntryType,
    starred: !isFavorite,
    sportId: eventTypeId
  };

  return (
    <EventLiveScore
      eventId={id}
      isInViewPort={isInViewPort}
      sportId={eventTypeId}
      inPlay={isInPlay}
      title={name}
      pageBlock={PageBlocks.SEARCH}
      matchStatEnabled={matchStatEnabled}
    >
      <Wrapper>
        <div
          className={classNames(
            `biab_search-item-wrapper biab_${device === Devices.MOBILE ? 'market' : 'event'}-title`,
            styles.searchItem
          )}
        >
          <div className={styles.searchItem__headerWrapper}>
            <div className={styles.searchItem__favouriteIcon}>
              <FavoriteStar entryData={favoriteData} />
            </div>
            <div>
              <span className={styles.wrapper}>
                {parents.length ? (
                  <span>
                    <Link
                      className={classNames(styles.searchItem__link, 'biab_search-item-wrapper')}
                      to={`${SPORT_BASE_URL}/${eventTypeId}/event/${parents[0].id}`}
                    >
                      {parents[0].name}
                    </Link>
                    {' - '}
                    <Link
                      className={classNames(styles.searchItem__link, 'biab_search-item-wrapper')}
                      to={`${SPORT_BASE_URL}/${eventTypeId}/market/${raceMarketId}`}
                    >
                      {parseMillisecondsToTime(openDate, timezone, timezoneCookieEnabled)} {name}
                    </Link>
                  </span>
                ) : (
                  <Link
                    className={classNames(styles.searchItem__link, 'biab_search-item-wrapper')}
                    to={`${SPORT_BASE_URL}/${eventTypeId}/event/${id}`}
                  >
                    {name}
                  </Link>
                )}
              </span>
              {isInPlay ? (
                <div
                  className={classNames(
                    `biab_search-item-in-play biab_${device === Devices.MOBILE ? 'market' : 'event'}-date`,
                    styles.searchItem__startTime
                  )}
                >
                  {t('market.inPlay')}
                </div>
              ) : (
                <div
                  className={classNames(
                    `biab_search-item-start-time biab_${device === Devices.MOBILE ? 'market' : 'event'}-date`,
                    styles.searchItem__startTime
                  )}
                >
                  {eventTime}
                </div>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </EventLiveScore>
  );
};

export default SearchItem;
