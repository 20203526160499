import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { statementFilterValues } from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import useOnClickOutside from 'hooks/useOnClickOutside';

import styles from './SelectStatementFilter.module.scss';

interface SelectStatementFilterProps {
  statementFilterValue?: string;
  handleChangeStatementFilterValue: (value: string) => void;
}

const SelectStatementFilter = ({
  statementFilterValue,
  handleChangeStatementFilterValue
}: SelectStatementFilterProps) => {
  const { t } = useTranslation();

  const [openSelect, setOpenSelect] = useState<boolean>(false);

  const selectRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useDevice();

  const selectStatementFilterName =
    statementFilterValue === statementFilterValues.all.value
      ? t(statementFilterValues.all.translationKey)
      : t(statementFilterValues.withdrawal.translationKey);

  const selectFilterValue = (value: string) => {
    setOpenSelect(false);
    handleChangeStatementFilterValue(value);
  };

  useOnClickOutside(selectRef, () => setOpenSelect(false));

  return (
    <div ref={selectRef}>
      <div
        className={classNames('biab_deposit-filter', styles.selectControl)}
        onClick={() => setOpenSelect(prevState => !prevState)}
      >
        <span className={styles.selectValue}>{selectStatementFilterName}</span>
        {openSelect ? <i className="fa2 fa2-arrow-up" /> : <i className="fa2 fa2-arrow-down" />}
      </div>
      {openSelect && (
        <ul
          className={classNames(styles.listOfValues, {
            [styles.listOfValues__mobile]: isMobile
          })}
        >
          {Object.values(statementFilterValues).map(({ value, translationKey }) => (
            <li key={value} onClick={() => selectFilterValue(value)}>
              {t(translationKey)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectStatementFilter;
