import useDeviceSettings from 'hooks/useDeviceSettings';
import { SportId } from 'types';
import { BettingType } from 'types/markets';

export const useFancySwapColors = ({ sportId, bettingType = '' }: { sportId: string; bettingType?: string }) => {
  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();
  const isCricket = sportId === SportId.CRICKET;
  const isLineMarket = bettingType === BettingType.LINE;

  return isCricket && swapColorsFancyMarketsOnCricket && isLineMarket;
};
