import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ResponsibleBettingBtn from 'components/ResponsibleBetting/components/ResponsibleBettingBtn';
import { ResponsibleBettingButtonTypes } from 'types/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingConfirmLimitProps {
  isMobile: boolean;
  onCancel: () => void;
  onConfirm?: () => void;
  confirmationLimitTypeLabel: string;
  confirmationLimitTypeIcon: string;
  confirmationLimitTypeValue: string;
  confirmationLimitDateLabel: string;
  confirmationLimitDateValue: string;
  operatorsLossLimit: number | undefined;
  showConfirmationBtn: boolean;
}

const ResponsibleBettingConfirmLimit = ({
  isMobile,
  onCancel,
  onConfirm = () => {},
  confirmationLimitTypeLabel,
  confirmationLimitTypeIcon,
  confirmationLimitTypeValue,
  confirmationLimitDateLabel,
  confirmationLimitDateValue,
  operatorsLossLimit,
  showConfirmationBtn
}: ResponsibleBettingConfirmLimitProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.container, {
        [styles.container__mobile]: isMobile
      })}
    >
      {operatorsLossLimit ? (
        <div className={styles.infoAlertContainer}>
          <i className={classNames('fa2', 'fa2-info-icon', 'biab_fav-manage-icon', styles.icon)} />
          <div>
            {t('responsibleBetting.messages.limitApplied')
              .split('.')
              .filter(Boolean)
              .map((text, idx) => (
                <span key={idx}>
                  {text + '.'}
                  <br />
                </span>
              ))}
          </div>
        </div>
      ) : (
        <>
          <div
            className={classNames(styles.content, {
              [styles.content__mobile]: isMobile
            })}
          >
            <i className={classNames('fa2', confirmationLimitTypeIcon, 'biab_fav-manage-icon', styles.icon)} />
            <div className={classNames(styles.rows)}>
              <div className={styles.row}>
                <span className={styles.label}>{confirmationLimitTypeLabel}:</span>
                <span className={classNames(styles.value, styles.value__bold)}>{confirmationLimitTypeValue}</span>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>{confirmationLimitDateLabel}:</span>
                <span className={styles.value}>{confirmationLimitDateValue}</span>
              </div>
            </div>
          </div>
          <div
            className={classNames(styles.controllers, {
              [styles.controllers__mobile]: isMobile
            })}
          >
            <ResponsibleBettingBtn
              label={t('responsibleBetting.buttons.cancel')}
              onClick={onCancel}
              isMobile={isMobile}
              type={ResponsibleBettingButtonTypes.Outlined}
              customClass={!showConfirmationBtn && isMobile ? styles.cancelBtn : ''}
            />
            {showConfirmationBtn && (
              <ResponsibleBettingBtn
                label={t('responsibleBetting.buttons.confirm')}
                onClick={onConfirm}
                isMobile={isMobile}
                type={ResponsibleBettingButtonTypes.Primary}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ResponsibleBettingConfirmLimit;
