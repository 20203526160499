import classNames from 'classnames';

import { TimeFormHeaderClasses } from 'types/raceCard';

import Verdict from '../Verdict';

import styles from './styles.module.scss';

interface HeaderProps {
  /**
   * Is time form content expanded
   * @return boolean
   */
  isExpanded: boolean;
  /**
   * Toggle expanding icon
   * @return void
   */
  onToggleExpand: () => void;
  classes?: TimeFormHeaderClasses;
}

const Header = ({ isExpanded, onToggleExpand, classes }: HeaderProps) => {
  return (
    <div
      className={classNames('biab_timeform-header', styles.timeForm__header, {
        [classes?.collapsed ?? '']: !isExpanded
      })}
    >
      <div className="biab_timeform-header-logo">
        <Verdict />
      </div>
      <span
        className={classNames('biab_timeform-expand', styles.timeForm__expand, {
          [`biab_expand ${styles.timeForm__expanded}`]: isExpanded
        })}
        onClick={onToggleExpand}
      >
        <i className="fa2 fa2-arrow-down" />
      </span>
    </div>
  );
};

export default Header;
