import { useState } from 'react';
import { useSelector } from 'react-redux';

import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { IMarket } from 'types/markets';
import { TimeFormHeaderClasses } from 'types/raceCard';

import Content from './components/Content';
import Header from './components/Header';

interface TimeFormProps {
  /**
   * Market data
   */
  market: IMarket;
  headerClasses?: TimeFormHeaderClasses;
  onToggleExpand?: (value: boolean) => void;
  defaultIsExpanded?: boolean;
}

const TimeForm = ({ market, headerClasses, onToggleExpand, defaultIsExpanded }: TimeFormProps) => {
  const device = useSelector(getAppDevice);

  const [isExpended, setIsExpended] = useState(defaultIsExpanded ?? device === Devices.MOBILE);

  const handleToggleExpanded = () => {
    setIsExpended(prevState => {
      if (onToggleExpand) {
        onToggleExpand(!prevState);
      }

      return !prevState;
    });
  };

  return (
    <div>
      <Header isExpanded={isExpended} onToggleExpand={handleToggleExpanded} classes={headerClasses} />
      {isExpended && <Content market={market} />}
    </div>
  );
};

export default TimeForm;
