import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  TIME_FORM_HISTORY_COLUMNS,
  TIME_FORM_HISTORY_MAX_COUNT,
  TIME_FORM_HISTORY_MOBILE_MAX_COLUMNS,
  TIME_FORM_KEY_GOING,
  TIME_FORM_RUNNER_DETAILS
} from 'constants/app';
import useWindowSize from 'hooks/useWindowSize';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { IRunnersStatistics } from 'types/markets';

import RatingStars from '../RatingStars';
import Verdict from '../Verdict';

import styles from './styles.module.scss';

interface TimeFormRunnerProps {
  runnerStatistic: IRunnersStatistics;
}

const TimeFormRunner = ({ runnerStatistic }: TimeFormRunnerProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);

  const { isLandscape } = useWindowSize(100);

  const isMobile = device === Devices.MOBILE;
  const isShowPartialTable = isMobile && !isLandscape;
  const isFullView = !isMobile || isLandscape;

  return (
    <div className={classNames('biab_timeform-runner-content', styles.timeFormRunner__container)}>
      <div
        className={classNames(styles.timeFormRunner__infoWrapper, {
          [styles.timeFormRunner__infoWrapper__vertical]: !isFullView
        })}
      >
        <div className={styles.timeFormRunner__description}>
          <ul className={styles.timeFormRunner__info}>
            {TIME_FORM_RUNNER_DETAILS.map(field => (
              <li key={field}>
                <span className={classNames('biab_tf-header', styles.timeFormRunner__descriptionLabel)}>
                  {t('timeform.' + field)}:
                </span>
                <span className="biab_tf-value">{runnerStatistic[field] || t('timeform.empty')}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className={classNames('biab_timeform-runner-comments', styles.timeFormRunner__comments)}>
          <span className={classNames('biab_tf-header', styles.timeFormRunner__header)}>
            {t('timeform.runnerComments')}:
          </span>
          <p>{runnerStatistic.runnerComments}</p>
          <div className={classNames('biab_timeform-runner-verdict', styles.timeFormRunner__verdict)}>
            <p className="biab_timeform-verdict-logo">
              <Verdict />
            </p>
            <p>
              <RatingStars stars={runnerStatistic.timeformRating} />
            </p>
            <p className="biab_timeform-runner-verdict-info">{runnerStatistic.verdictDescription}</p>
          </div>
        </div>
      </div>

      {!!runnerStatistic.history.length && (
        <div className={classNames('biab_timeform-runner-table-wrap', styles.timeFormRunner__history__wrapper)}>
          <table
            className={classNames('biab_timeform-runner-table biab_market-table', styles.timeFormRunner__history)}
            cellSpacing="0"
          >
            <thead>
              <tr>
                {TIME_FORM_HISTORY_COLUMNS.map(
                  (column, index) =>
                    ((isShowPartialTable && index < TIME_FORM_HISTORY_MOBILE_MAX_COLUMNS) || isFullView) && (
                      <th
                        className={classNames('biab_tf-header', styles.timeFormRunner__history__column)}
                        key={column.key}
                      >
                        {t(column.label)}
                      </th>
                    )
                )}
              </tr>
            </thead>
            <tbody>
              {runnerStatistic.history.map(
                (historyItem, index) =>
                  index + 1 <= TIME_FORM_HISTORY_MAX_COUNT && (
                    <tr key={`${historyItem.selectionId}-${index}`}>
                      {TIME_FORM_HISTORY_COLUMNS.map(
                        ({ key }, indexTd) =>
                          ((isShowPartialTable && indexTd < TIME_FORM_HISTORY_MOBILE_MAX_COLUMNS) || isFullView) && (
                            <td key={key}>
                              {key === TIME_FORM_KEY_GOING ? (
                                <>{historyItem.going?.shortName || '-'}</>
                              ) : (
                                <>{(historyItem && historyItem[key]) || '-'}</>
                              )}
                            </td>
                          )
                      )}
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TimeFormRunner;
