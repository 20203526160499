export const AV_BET_LIST_MOBILE_SEARCH_MAX_LENGTH = 125;
export const AV_BET_LIST_MOBILE_SORTING_VALUES = {
  placementTime: 'placementTime',
  eventStartTime: 'eventStartTime'
} as const;

export const AV_OUTRIGHT_FROM_BET_LIST_MOBILE_QUERY_PARAM = 'fromBetListMobile';

export const AV_MOBILE_BET_LIST_SEARCH_PARAM_MIN_LENGTH = 3;

export const AV_MOBILE_BET_LIST_PAGE_PARAM = 'mobileBetListPage';
