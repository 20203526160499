import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { HOME_BASE_URL } from 'constants/locations';
import { getIsShowMarketOdd, getMarketOddsBannerLocation } from 'redux/modules/appConfigs/selectors';
import { getPopularMarkets, getPopularMarketsAndFeatureMarketsSport } from 'redux/modules/popularMarket/selectors';
import { MarketOddsBannerLocationType } from 'types/markets';

const useShowMarketOddOnPage = () => {
  const { sportId } = useParams();
  const location = useLocation();

  const markets = useSelector(getPopularMarkets);
  const popularMarketsAndFeatureMarketsSport = useSelector(getPopularMarketsAndFeatureMarketsSport);
  const marketOdds = useSelector(getIsShowMarketOdd);
  const marketOddsBannerLocation = useSelector(getMarketOddsBannerLocation);

  const marketOddId = markets[0]?.eventType.id;
  let isShowOnPage = marketOddsBannerLocation === MarketOddsBannerLocationType.ALL;

  if (marketOddsBannerLocation === MarketOddsBannerLocationType.HOME) {
    isShowOnPage = location.pathname === HOME_BASE_URL;
  }

  if (marketOddsBannerLocation === MarketOddsBannerLocationType.SPORT) {
    isShowOnPage =
      location.pathname === HOME_BASE_URL
        ? popularMarketsAndFeatureMarketsSport.includes(marketOddId)
        : marketOddId === sportId;
  }

  return !!markets.length && marketOdds && isShowOnPage;
};

export default useShowMarketOddOnPage;
