import { useSelector } from 'react-redux';

import { getAsianQuickPlacedBetsProgressList } from 'redux/modules/asianViewQuickBetting/selectors';

import ProgressQuickBet from '../ProgressQuickBet';

const ProgressQuickBetList = () => {
  const progressQuickBetsList = useSelector(getAsianQuickPlacedBetsProgressList);

  return (
    <>
      {progressQuickBetsList.map(quickBet => (
        <ProgressQuickBet quickBet={quickBet} key={quickBet.identifier} />
      ))}
    </>
  );
};

export default ProgressQuickBetList;
