import { createSelector } from '@reduxjs/toolkit';
import { values } from 'lodash';

import { AppState } from 'redux/reducers';

const getLeaguesFilter = (state: AppState) => state.asianViewLeaguesFilter;

export const getLeaguesLoading = createSelector(getLeaguesFilter, state => state.loading);

export const getLeaguesCurParams = createSelector(getLeaguesFilter, state => state.currentParams);

export const getAsianViewLeaguesFilter = createSelector(getLeaguesFilter, state => state.leagues);

export const getIsAllLeaguesSelected = createSelector(getAsianViewLeaguesFilter, leagues =>
  values(leagues).every(({ isSelected }) => isSelected)
);

export const getIsLeaguesChanged = createSelector(getAsianViewLeaguesFilter, leagues =>
  values(leagues).some(({ isSelected, isSelectedInit }) => isSelected !== isSelectedInit)
);

export const getLeaguesAmounts = createSelector(getAsianViewLeaguesFilter, leagues => {
  const leaguesList = values(leagues);
  const selectedList = [];
  const selectedInitList = [];
  const changedList = [];

  leaguesList.forEach(item => {
    if (item.isSelected) {
      selectedList.push(item);
    }

    if (item.isSelectedInit) {
      selectedInitList.push(item);
    }

    if (item.isSelected !== item.isSelectedInit) {
      changedList.push(item);
    }
  });

  return {
    total: leaguesList.length,
    selected: selectedList.length,
    selectedInit: selectedInitList.length,
    changed: changedList.length
  };
});

export const getLeaguesIsLoaded = createSelector(getLeaguesFilter, ({ isLeaguesLoaded }) => isLeaguesLoaded);

export const getSavedSelectedLeagues = createSelector(getAsianViewLeaguesFilter, leagues =>
  values(leagues).map(({ isSelectedInit }) => isSelectedInit)
);

export const getLeaguesOpened = createSelector(getLeaguesFilter, state => state.isOpened);
