import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import MyBetsSkeleton from 'components/Skeleton/MyBetsSkeleton';
import {
  BetTypeValue,
  MyBetsLocations,
  MyBetsPage,
  NavigationButtons,
  Period,
  PLBetType,
  SEARCH_CURRENCY_KEY
} from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import { useSetHighlightedRow } from 'hooks/useSetHighlightedRow';
import DatePickers from 'pages/MyBetsPages/components/DatePickers/MyBetsDatePickers';
import ExportButton from 'pages/MyBetsPages/components/ExportButton';
import Pagination from 'pages/MyBetsPages/components/Pagination';
import RefreshButton from 'pages/MyBetsPages/components/RefreshButton';
import TimeFormatDropdown from 'pages/MyExchangeBetsPage/components/TimeFormatDropdown';
import { getCurrency } from 'redux/modules/appConfigs/selectors';
import { setMultiCurrencyValue } from 'redux/modules/myBets';
import { getPLData, getPLTotalEventData, myBetsLoading } from 'redux/modules/myBets/selectors';
import { CookieNames } from 'types';
import { TGetPLData } from 'types/myBets';
import { getThreeMonthsAgo } from 'utils/date';

import EventTypeFilter from './components/EventTypeFilter';
import FiltersContainer from './components/FiltersContainer/FiltersContainer';
import FiltersContainerMobile from './components/FiltersContainerMobile';
import PLTable from './components/PLTable';

import styles from './styles.module.scss';

interface BettingProfitAndLossProps {
  asianViewEnabled: boolean;
  activePLTab: PLBetType;
  setOnChangeBetType: (handler: (betType: BetTypeValue) => void) => void;
  collectHandlers: (name: 'fetchPLDataHandler', handler: (params: TGetPLData) => void) => void;
}

const BettingProfitAndLoss = ({
  asianViewEnabled,
  activePLTab,
  setOnChangeBetType,
  collectHandlers
}: BettingProfitAndLossProps) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [cookies] = useCookies([CookieNames.PROFITANDLOSS_BETTYPE]);

  const isLoading = useSelector(myBetsLoading);
  const plData = useSelector(getPLData);
  const currency = useSelector(getCurrency);

  const minDate = getThreeMonthsAgo();

  const {
    timezoneOffset,
    bettingDay,
    startDate,
    endDate,
    activePeriodTab,
    handleSetEndDate,
    handleSetStartDate,
    handlePeriodDropdown,
    handleResetDates,
    refreshData,
    eventType,
    handleSetEventType,
    pageSize,
    handleSetPageSize,
    handleSetPageSizeAndNumber,
    handleSetPageNumber,
    pageNumber,
    handleChangeBetType,
    getPLData: fetchPLData,
    getExportParams
  } = useMyBetsPagesNavigation(MyBetsPage.ProfitLoss, activePLTab);

  const { isMultiCurrencySupported } = useMultiCurrencySupporting();
  const totalForEvent = useSelector(getPLTotalEventData);

  collectHandlers('fetchPLDataHandler', fetchPLData);
  const { rowHighlighted, setRowHighLighted } = useSetHighlightedRow(pageNumber, pageSize, {
    startDate,
    eventType,
    activePLTab
  });

  const searchCurrency = searchParams.get(SEARCH_CURRENCY_KEY);
  const { content, totalElements } = plData;
  const { isMobile } = useDevice();

  const exportData = getExportParams(searchCurrency || currency.currencyCode);
  const hasTotalForEvent = (eventType.id || eventType.gameId) && !!content.length;

  const { noFormattedAmount: totalForEventValue } = useFormatCurrency(
    totalForEvent,
    searchCurrency || currency.currencyCode,
    {
      noRounding: true,
      isCheckIndian: true
    }
  );

  setOnChangeBetType(handleChangeBetType);

  useEffect(() => {
    if (isMultiCurrencySupported && currency.currencyCode) {
      dispatch(setMultiCurrencyValue(searchCurrency || currency.currencyCode));
    }
  }, [currency.currencyCode, isMultiCurrencySupported]);

  return (
    <div>
      {!isMobile && (
        <>
          {asianViewEnabled && cookies.PROFITANDLOSS_BETTYPE === PLBetType.AsianView && <TimeFormatDropdown />}
          <FiltersContainer
            eventType={eventType}
            setEventType={handleSetEventType}
            activePLTab={activePLTab}
            dataExists={!!content.length}
          />
          <div className={styles.refreshWrapper}>
            <RefreshButton onRefresh={refreshData} />
          </div>
          <ExportButton data={exportData} isDisabled={totalElements === 0} />
          <DatePickers
            timezoneOffset={timezoneOffset}
            bettingDay={bettingDay}
            startDate={startDate}
            endDate={endDate}
            {...(activePeriodTab !== Period.Current ? { minDate: minDate } : {})}
            setStartDate={handleSetStartDate}
            setEndDate={handleSetEndDate}
            handlePeriodDropdown={handlePeriodDropdown}
            handleResetDates={handleResetDates}
            selectedTab={NavigationButtons.MyBets}
          />
        </>
      )}
      {isMobile && (
        <>
          <FiltersContainerMobile activePLTab={activePLTab} dataExists={!!content.length} fetchPLData={fetchPLData} />
          <DatePickers
            timezoneOffset={timezoneOffset}
            bettingDay={bettingDay}
            startDate={startDate}
            endDate={endDate}
            {...(activePeriodTab !== Period.Current ? { minDate: minDate } : {})}
            setStartDate={handleSetStartDate}
            setEndDate={handleSetEndDate}
            handlePeriodDropdown={handlePeriodDropdown}
            handleResetDates={handleResetDates}
            selectedTab={NavigationButtons.MyBets}
            customClass={hasTotalForEvent ? styles.hasTotal : ''}
          >
            {cookies.PROFITANDLOSS_BETTYPE !== PLBetType.AsianView && (
              <EventTypeFilter eventType={eventType} setEventType={handleSetEventType} activePLTab={activePLTab} />
            )}
            {hasTotalForEvent && <span className={styles.totalForEvent}>{totalForEventValue}</span>}
          </DatePickers>
        </>
      )}
      <div
        className={classNames(styles.skeletonContainer, {
          [styles.skeletonContainer__active]: !isMobile && isLoading
        })}
      >
        {!isMobile && isLoading && <MyBetsSkeleton isShowTable />}
        {!isLoading && (
          <PLTable content={content} rowHighlighted={rowHighlighted} setRowHighLighted={setRowHighLighted} />
        )}
      </div>

      {!isLoading && totalElements > 10 && (
        <Pagination
          source={plData}
          pageSize={pageSize}
          setPageSize={handleSetPageSize}
          pageNumber={pageNumber}
          setPageNumber={handleSetPageNumber}
          pageLink={MyBetsLocations.ProfitAndLoss}
          setPageSizeAndNumber={handleSetPageSizeAndNumber}
        />
      )}
    </div>
  );
};

export default BettingProfitAndLoss;
