import { PropsWithChildren } from 'react';

import PromotableTooltip from 'components/PromotableTooltip';
import {
  BACK_ODDS,
  BACK_ODDS_MATCHED,
  BACK_PROFIT,
  BACK_SIZE,
  LAY_ODDS,
  LAY_ODDS_MATCHED,
  LAY_PROFIT,
  LAY_SIZE,
  LAY_SIZE_MATCHED
} from 'constants/tooltip';
import { BetTypes, MatchTypes } from 'types/bets';
import { EBetTooltips } from 'types/betslip';

type BetTooltipProps = {
  side: BetTypes;
  matchType?: MatchTypes;
  tooltipType: EBetTooltips;
};

const BetTooltip = ({ matchType, side, tooltipType, children }: PropsWithChildren<BetTooltipProps>) => {
  let key = '';
  const isBack = side === BetTypes.BACK;
  const isMatched = matchType === MatchTypes.MATCHED;

  if (tooltipType === EBetTooltips.ODDS) {
    if (isMatched) {
      key = isBack ? BACK_ODDS_MATCHED : LAY_ODDS_MATCHED;
    } else {
      key = isBack ? BACK_ODDS : LAY_ODDS;
    }
  } else if (tooltipType === EBetTooltips.SIZE) {
    if (isMatched) {
      key = isBack ? BACK_SIZE : LAY_SIZE_MATCHED;
    } else {
      key = isBack ? BACK_SIZE : LAY_SIZE;
    }
  } else if (tooltipType === EBetTooltips.PROFIT) {
    key = isBack ? BACK_PROFIT : LAY_PROFIT;
  }

  return (
    <PromotableTooltip tooltipKey={key}>
      <span dangerouslySetInnerHTML={{ __html: children as string }} />
    </PromotableTooltip>
  );
};

export default BetTooltip;
