import { SyntheticEvent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import FavoriteStar from 'components/FavoriteStar';
import { FAVORITES_TYPES } from 'constants/app';
import { getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { setCollapseNewState } from 'redux/modules/collapse';
import { COLLAPSE_ROW_NAME, LEFT_PANEL_COLLAPSE_GROUP, SPORT_COLLAPSE } from 'redux/modules/collapse/constants';
import { setSportCollapse } from 'redux/modules/favorites';
import { TFavorite } from 'redux/modules/favorites/type';
import { getFavoriteNavigation } from 'utils';
import { getDefaultLinkTarget } from 'utils/navigation';
import { getArrowDownByOperator } from 'utils/operator';

import CollapsibleList from '../CollapsibleList';

import styles from './styles.module.scss';

interface CollapsibleListProps {
  /**
   * Favorites list item that should be rendered
   */
  favorite: TFavorite;
  /**
   * Operator type
   */
  operator: string;
  /**
   * Determine the calling direction of component
   */
  isPopup: boolean;
  /**
   * Determine is row collapsed or not
   */
  isCollapsed?: boolean;
}

const CollapsibleRow = ({ favorite, operator, isPopup, isCollapsed = false }: CollapsibleListProps) => {
  const dispatch = useDispatch();
  const [, setCookie] = useCookies([COLLAPSE_ROW_NAME]);

  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const [isOpened, setIsOpened] = useState(false);

  const { entryId, entryName, entryType, starred, sportId, children } = favorite;

  const onCollapse = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isPopup) {
      dispatch(
        setSportCollapse({
          sportId: entryId,
          collapse: !isOpened
        })
      );
    }
    setIsOpened(!isOpened);
  };

  const favoriteData = {
    starred: !starred,
    entryId,
    entryName,
    entryType,
    sportId
  };

  const onClickFavoriteLink = () => {
    if (!isPopup) {
      dispatch(setCollapseNewState({ group: LEFT_PANEL_COLLAPSE_GROUP, collapse: SPORT_COLLAPSE, openedState: true }));
      setCookie(COLLAPSE_ROW_NAME, true);
    }
  };

  useEffect(() => {
    setIsOpened(isCollapsed);
  }, [isCollapsed]);

  return (
    <li className={styles.collapsibleRow}>
      <div
        className={classNames(styles.collapsibleRow__favoriteWrapper, {
          biab_active: starred,
          ['biab_fav-subheader']: favoriteData.entryType === FAVORITES_TYPES.sport,
          ['biab_fav-item']: favoriteData.entryType !== FAVORITES_TYPES.sport
        })}
      >
        <NavLink
          to={getFavoriteNavigation(favorite)}
          onClick={onClickFavoriteLink}
          target={getDefaultLinkTarget({
            url: getFavoriteNavigation(favorite),
            currentTarget: isPopup ? '_blank' : '',
            isIframeEnabled
          })}
          className={classNames(
            'biab_add-to-fav',
            {
              [styles.collapsibleRow__popupLinkContainer]: isPopup
            },
            styles.collapsibleRow__link
          )}
        >
          <FavoriteStar className={styles.collapsibleRow__starIcon} entryData={favoriteData} isNavigation />
          <span
            className={classNames(styles.collapsibleRow__sportName, {
              [styles.collapsibleRow__popupLink]: isPopup
            })}
          >
            {entryName}
          </span>
          {!!children?.length && (
            <i
              className={classNames(styles.collapsibleRow__icon, getArrowDownByOperator(operator), {
                [styles.collapsibleRow__icon_opened]: isOpened
              })}
              onClick={onCollapse}
            />
          )}
        </NavLink>
      </div>

      {isOpened && children && (
        <CollapsibleList favorites={children} customClassName={styles.subCollapsibleRow} isPopup={isPopup} />
      )}
    </li>
  );
};

export default CollapsibleRow;
