import { MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { OPERATOR } from 'constants/app';
import { ASIAN_BASE_URL, GAMES_BASE_URL } from 'constants/locations';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { getIsUpdatedResponsibleBettingPageEnabled, getOperator } from 'redux/modules/appConfigs/selectors';
import { TMobileAccountDropdownValue } from 'types';
import { getArrowDownByOperator, getArrowUpByOperator } from 'utils/operator';

import styles from './styles.module.scss';

interface MobileAccountDropdownProps {
  value: TMobileAccountDropdownValue;
  onChange?: (newValue: TMobileAccountDropdownValue) => void;
  options: TMobileAccountDropdownValue[];
}

const MobileAccountDropdown = ({ value, onChange = () => {}, options }: MobileAccountDropdownProps) => {
  const { t } = useTranslation();
  const operator = useSelector(getOperator);
  const { pathname } = useLocation();

  const isResponsibleBettingEnabled = useSelector(getIsUpdatedResponsibleBettingPageEnabled);

  const [open, setOpen] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setOpen(false));

  const isAsianViewPage = pathname.includes(ASIAN_BASE_URL);
  const isGamesPage = pathname.includes(GAMES_BASE_URL);

  const exchangePage = (isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || '';

  const handleClickNavItem = (
    e: MouseEvent<HTMLAnchorElement | HTMLLIElement>,
    newValue: TMobileAccountDropdownValue
  ) => {
    if (value.name === newValue.name) {
      e.preventDefault();
    } else {
      setOpen(false);
      onChange(newValue);
    }
  };

  return (
    <div
      ref={ref}
      id="biab_account-navigation"
      onClick={() => setOpen(!open)}
      className={classNames(styles.accountNav, 'biab_account-navigation')}
    >
      <div>
        <div className={classNames(styles.accountTabs, 'biab_account-tabs', { [styles.overlayNav]: open })}>
          <div className={classNames(styles.dropBtnContainer, 'biab_visible-xs biab_dropbtn-container')}>
            <div className={styles.dropBtn__wrapper} onClick={() => setOpen(prevState => !prevState)}>
              <button className={classNames(styles.dropBtn, 'biab_btn', 'biab_dropbtn')}>
                {t(value.labelTranslationKey)}
                <i
                  className={classNames(
                    open ? getArrowUpByOperator(operator) : getArrowDownByOperator(operator),
                    styles.dropIcon,
                    {
                      [styles.dropIcon__ps]: operator === OPERATOR.POKER_STAR
                    }
                  )}
                />
              </button>
            </div>
          </div>
          {open && (
            <ul className="biab_tabs-nav">
              {options.map(item => {
                if (
                  (!isResponsibleBettingEnabled &&
                    item.labelTranslationKey === 'responsibleBetting.labels.responsibleBetting') ||
                  (typeof item.isVisible === 'boolean' && !item.isVisible)
                ) {
                  return null;
                }

                return (
                  <li
                    key={item.name}
                    onClick={!item.url ? e => handleClickNavItem(e, item) : undefined}
                    className={classNames('biab_tabs-nav-item', {
                      biab_active: item.name === value.name,
                      [styles.accountTabs__item]: !item.url,
                      [styles.accountTabs__item__active]: item.name === value.name
                    })}
                  >
                    {item.url ? (
                      <Link onClick={e => handleClickNavItem(e, item)} to={`${exchangePage}${item.url}`}>
                        {t(item.labelTranslationKey)}
                      </Link>
                    ) : (
                      <>{t(item.labelTranslationKey)}</>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        {open && <div className={classNames(styles.overlay, 'biab_overlay')} />}
      </div>
    </div>
  );
};

export default MobileAccountDropdown;
