import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface ToggleSwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  containerClassName?: string;
}

const ToggleSwitch = ({ checked, onChange, containerClassName = '' }: ToggleSwitchProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.toggleSwitch, containerClassName, 'biab_switch-wrapper')}>
      <input
        type="checkbox"
        name="toggle-switch"
        className={styles.toggleSwitch__checkbox}
        id="toggle-contextual"
        checked={checked}
        onChange={e => onChange(e.target.checked)}
      />
      <label
        className={classNames(styles.toggleSwitch__label, 'biab_switch', { biab_on: checked, biab_off: !checked })}
        htmlFor="toggle-contextual"
      >
        <span className={styles.toggleSwitch__inner} data-yes={t('switch.state.on')} data-no={t('switch.state.off')} />
        <span className={classNames(styles.toggleSwitch__switch, 'biab_cursor')} />
      </label>
    </div>
  );
};

export default ToggleSwitch;
