import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getDesktopSettingsLineMarketsSwitchBackLayOnCricket,
  getDesktopSettingsReplaceBackLayWithUnderOver
} from 'redux/modules/appConfigs/selectors';
import { TStatementContentItem } from 'redux/modules/myBets/type';
import { statementSideValue } from 'utils/myBetsValues';

import styles from './styles.module.scss';

interface DescriptionCellProps {
  bet: TStatementContentItem;
}

const DescriptionCell = ({ bet }: DescriptionCellProps) => {
  const { t } = useTranslation();

  const lineMarketsSwitchBackLayOnCricket = useSelector(getDesktopSettingsLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getDesktopSettingsReplaceBackLayWithUnderOver);

  const oddsAndPlaces = bet.eachWayDivisor !== null && bet.numberOfWinners !== null;
  const placementLabel = t(`account.mybets.labels.${bet.inPlay ? 'live' : 'prePlay'}Placement`);

  return (
    <div className={styles.myBetsDescriptionColumn}>
      {bet.description}
      {oddsAndPlaces && (
        <>{t('account.statement.each.way.terms', { odds: bet.eachWayDivisor, places: bet.numberOfWinners })}</>
      )}
      {bet.triggeredByCashOut && ` | ${t('account.statement.labels.triggeredByCashOut')}`}
      <div className={styles.betTypeRow}>
        {bet.side && (
          <strong className={styles.capitalise}>
            {bet.side ? t(statementSideValue(bet, lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver)) : ''}
          </strong>
        )}
        {bet.side && bet.asian && ` | ${placementLabel}`}
      </div>
    </div>
  );
};

export default DescriptionCell;
