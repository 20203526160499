import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { updateQuickBet } from 'redux/modules/asianViewQuickBetting';
import { EAsianBettingActions } from 'redux/modules/asianViewQuickBetting/type';

import styles from './styles.module.scss';

interface IMessage {
  identifier: string | undefined;
  message: string;
  customClassName?: string;
}
const Message = ({ identifier, message, customClassName = '' }: IMessage) => {
  const dispatch = useDispatch();
  const closeHandler = () => {
    if (identifier) {
      dispatch(
        updateQuickBet({
          identifier,
          data: { validationMessage: '', action: EAsianBettingActions.PLACE }
        })
      );
    }
  };

  if (!message) {
    return null;
  }

  return (
    <div className={classNames(styles.wrap, styles.wrap__error, customClassName)}>
      <span className={styles.errorIconWrap}>
        <i className={classNames(styles.errorIcon, 'fa2 fa2-removed')} aria-hidden="true">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
        </i>
      </span>
      <span dangerouslySetInnerHTML={{ __html: unescape(message) }} />
      <i onClick={closeHandler} className={classNames('biab_tour-icon-close', styles.closeIcon)} />
    </div>
  );
};

export default Message;
