import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { BannerNamesKeys, CHANGE_BANNER_INTERVAL, MAX_BANNERS_ITEMS } from 'constants/app';
import { getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { getBannerByName } from 'redux/modules/banners/selectors';
import { BannersContentOptions } from 'redux/modules/banners/type';

import BannerComponent from '../BannerComponent';

interface BannerContentProps {
  bannerName: BannerNamesKeys;
  isIframeEnabled: boolean;
  containerClassName?: string;
}

const BannerContent = ({ bannerName, isIframeEnabled, containerClassName = '' }: BannerContentProps) => {
  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const content = useSelector(getBannerByName(bannerName));

  const [index, setIndex] = useState(0);

  const indexRef = useRef(0);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  let bannersCount: number;

  if (content?.activeContentOption === BannersContentOptions.IMAGES && !!content?.bannerImages?.length) {
    if (content.bannerImages.length > 5) {
      bannersCount = MAX_BANNERS_ITEMS;
    } else {
      bannersCount = content.bannerImages.length;
    }
  } else {
    bannersCount = 0;
  }

  useEffect(() => {
    if (bannersCount > 1) {
      intervalRef.current = setInterval(() => {
        if (indexRef.current === bannersCount - 1) {
          setIndex(0);
          indexRef.current = 0;
        } else {
          setIndex(i => i + 1);
          indexRef.current += 1;
        }
      }, CHANGE_BANNER_INTERVAL);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [language, timezone, bannersCount]);

  return (
    <ul className={containerClassName}>
      {content?.activeContentOption === BannersContentOptions.IMAGES && (
        <>
          {content?.bannerImages?.length === 1 && (
            <BannerComponent banner={content.bannerImages[0]} isIframeEnabled={isIframeEnabled} />
          )}
          {!!content?.bannerImages?.length &&
            content?.bannerImages?.length > 1 &&
            index <= content.bannerImages.length && (
              <BannerComponent banner={content.bannerImages[index]} isIframeEnabled={isIframeEnabled} />
            )}
        </>
      )}
      {content?.activeContentOption === BannersContentOptions.HTML && (
        <div dangerouslySetInnerHTML={{ __html: content?.bannerHtmlContent.content }} />
      )}
    </ul>
  );
};

export default BannerContent;
