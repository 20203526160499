import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import {
  getAsianViewCashOutMarketsContent,
  getAsianViewCashOutQuotesMarketsIds
} from 'redux/modules/asianViewCashOut/selectors';

import BetSlipCashOutItem from '../Content/BetSlipCashOutItem';

import styles from './styles.module.scss';

const MobileCashOutList = () => {
  const markets = useSelector(getAsianViewCashOutMarketsContent);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);
  const quotesMarketsIds = useSelector(getAsianViewCashOutQuotesMarketsIds);

  const cashOutMarkets = useMemo(() => {
    return markets.filter(({ marketId }) => quotesMarketsIds.includes(marketId));
  }, [markets, quotesMarketsIds]);

  if (!cashOutMarkets.length) {
    return null;
  }

  return (
    <div
      className={classNames(styles.mobCOList, {
        [styles.mobCOList__landscape]: isLandscapeView
      })}
    >
      {cashOutMarkets.map(market => {
        return <BetSlipCashOutItem key={market.marketId} market={market} isMobileCashOutPage />;
      })}
    </div>
  );
};

export default MobileCashOutList;
