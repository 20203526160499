import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import GameCartsTableBlackjack from 'components/GameCartsTableBlackjack';
import GameResultsPlayersStatusesTable from 'components/GameResultsPlayersStatusesTable';
import GamesResultsPagination from 'components/GamesResultsPagination';
import styles from 'components/ResultsScreen/styles.module.scss';
import { MyBetsLocations } from 'constants/myBets';
import { getSelectedIndex } from 'redux/modules/games/selectors';
import { THistory } from 'redux/modules/games/type';

interface IGamesBlackJackResultContent {
  resultsHistory: THistory;
}

const GamesBlackJackResultsContent = ({ resultsHistory }: IGamesBlackJackResultContent) => {
  const selectedIndex = useSelector(getSelectedIndex);
  const currentItem = resultsHistory.content[selectedIndex];
  const location = useLocation();
  const isMyBetPage = location.pathname.includes(MyBetsLocations.MyBets);

  return (
    <>
      <GameCartsTableBlackjack gameData={currentItem.gameData} startDate={currentItem.startDate} />
      {!isMyBetPage && (
        <>
          <GamesResultsPagination history={resultsHistory} />
          <hr className={styles.hr} />
        </>
      )}

      <GameResultsPlayersStatusesTable markets={currentItem.markets} />
    </>
  );
};

export default GamesBlackJackResultsContent;
