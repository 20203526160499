import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { setActivePlacedBetIndex } from 'redux/modules/asianViewQuickBetting';
import {
  getAsianActiveQuickPlacedBetIndex,
  getAsianQuickPlacedBetsAmount
} from 'redux/modules/asianViewQuickBetting/selectors';

import styles from './styles.module.scss';

const PlacedBetsNav = () => {
  const dispatch = useDispatch();
  const quickPlacedBetsAmount = useSelector(getAsianQuickPlacedBetsAmount);
  const activeQuickPlacedBet = useSelector(getAsianActiveQuickPlacedBetIndex);

  const isPrevDisabled = activeQuickPlacedBet <= 0;
  const isNextDisabled = activeQuickPlacedBet === quickPlacedBetsAmount - 1;

  return (
    <div className={styles.navWrap}>
      <button
        className={classNames(styles.navBtn, { [styles.navBtn__disabled]: isPrevDisabled })}
        onClick={() => dispatch(setActivePlacedBetIndex(activeQuickPlacedBet - 1))}
        disabled={isPrevDisabled}
      >
        <i className="biab_arrow-icon fa2 fa2-arrow-left" />
      </button>
      <button
        className={classNames(styles.navBtn, { [styles.navBtn__disabled]: isNextDisabled })}
        onClick={() => dispatch(setActivePlacedBetIndex(activeQuickPlacedBet + 1))}
        disabled={isNextDisabled}
      >
        <i className="biab_arrow-icon fa2 fa2-arrow-right" />
      </button>
    </div>
  );
};

export default PlacedBetsNav;
