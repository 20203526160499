import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { DEFAULT_NUMBER_OF_PLACEMENT_ATTEMPTS } from 'constants/asianView';
import { asianViewComponents as branding } from 'constants/branding';
import useAsianPlacementPage from 'hooks/useAsianPlacementPage';
import useConfirmBets from 'hooks/useConfirmBets';
import { usePlacementParams } from 'hooks/usePlacementParams';
import usePostMessage from 'hooks/usePostMessage';
import { placeQuickBet, updateQuickBet } from 'redux/modules/asianViewQuickBetting';
import {
  EAsianBettingActions,
  TAsianPlaceQuickBetPayload,
  TAsianQuickBet
} from 'redux/modules/asianViewQuickBetting/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { EPersistenceTypes } from 'types/bets';

import styles from './styles.module.scss';

interface IQuickBettingActionProps {
  bet: TAsianQuickBet;
}
const QuickBettingActions = ({ bet }: IQuickBettingActionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoggedIn = useSelector(getLoggedInStatusState);

  const { isConfirmBetsBeforePlacement } = useConfirmBets();
  const placementParams = usePlacementParams();
  const { placeBetLogin } = usePostMessage();

  const asianViewPage = useAsianPlacementPage(bet ? bet.marketLink : '');

  const isConfirm = bet.action === EAsianBettingActions.CONFIRM;
  const isValidation = bet.action === EAsianBettingActions.VALIDATION;
  const isDisabled = !bet.size || isValidation || !!(!bet.isValid && bet.sizeValidationType);

  const placeBet = () => {
    const {
      selectionId,
      handicap,
      price,
      size,
      betType,
      score,
      marketId,
      oddsType,
      betUuid,
      adjHandicap,
      isExpandedCoupon
    } = bet;
    const adjHandicapParam = !isUndefined(adjHandicap) ? { adjHandicap } : {};
    const payloadData: TAsianPlaceQuickBetPayload = {
      [marketId]: [
        {
          selectionId,
          handicap,
          price,
          size,
          score,
          side: betType,
          page: asianViewPage,
          persistenceType: EPersistenceTypes.LAPSE,
          placedUsingEnterKey: false,
          numberOfPlacementAttempts: DEFAULT_NUMBER_OF_PLACEMENT_ATTEMPTS,
          isAsian: true,
          oddsType,
          placedOnMainCoupon: !isExpandedCoupon,
          placedOnExpandedCoupon: isExpandedCoupon,
          betUuid: betUuid ?? '',
          ...adjHandicapParam,
          ...placementParams
        }
      ]
    };

    dispatch(updateQuickBet({ identifier: bet.identifier, data: { action: EAsianBettingActions.PROGRESS } }));
    dispatch(placeQuickBet(payloadData));
  };

  const onSubmitHandler = () => {
    if (!isLoggedIn) {
      placeBetLogin();
      return;
    }

    if (isConfirmBetsBeforePlacement && !isConfirm) {
      dispatch(
        updateQuickBet({
          identifier: bet.identifier,
          data: {
            action:
              isUndefined(bet.isValid) || bet.isValid ? EAsianBettingActions.CONFIRM : EAsianBettingActions.VALIDATION
          }
        })
      );
    } else if (!isUndefined(bet.isValid) && !bet.isValid) {
      dispatch(
        updateQuickBet({
          identifier: bet.identifier,
          data: { action: EAsianBettingActions.VALIDATION }
        })
      );
    } else {
      placeBet();
    }
  };

  const onEditHandler = () => {
    dispatch(updateQuickBet({ identifier: bet.identifier, data: { action: EAsianBettingActions.PLACE } }));
  };

  return (
    <div className={styles.actions__wrap}>
      {isConfirm && (
        <button
          type="button"
          className={classNames(branding.SECONDARY_BTN, styles.actions__btn, styles.actions__btn__edit)}
          onClick={onEditHandler}
        >
          {t('asianView.labels.placementActions.editBet')}
        </button>
      )}
      <button
        type="button"
        className={classNames(branding.PRIMARY_BTN, styles.actions__btn, styles.actions__btn__place, {
          [styles.actions__btn__confirm]: isConfirm,
          [styles.actions__btn__disabled]: isDisabled,
          [styles.actions__btn__lowerPrice]: bet.isLowerPrice
        })}
        disabled={isDisabled}
        onClick={onSubmitHandler}
      >
        {isConfirm
          ? t('asianView.labels.placementActions.confirmBet')
          : bet.isLowerPrice
          ? t('asianView.labels.placementActions.acceptLowerPrices')
          : t('asianView.labels.placementActions.placeBet')}
      </button>
    </div>
  );
};

export default QuickBettingActions;
