import classNames from 'classnames';

import styles from '../../../../styles.module.scss';

interface SlideProps {
  handleGoToSlide: (slide: number) => void;
  slideNumber?: number;
  index: number;
}

const Slide = ({ handleGoToSlide, slideNumber, index }: SlideProps) => (
  <div
    onClick={() => handleGoToSlide(index + 1)}
    className={classNames(styles.pagination__slide, {
      [styles.pagination__slide__selected]: index === slideNumber
    })}
  >
    {index + 1}
  </div>
);

export default Slide;
