import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { PostMessageTypes, USER_ACTIVITY_TIMEOUT } from 'constants/app';
import { getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { sendIframeMessages } from 'utils/iframe';

const IframeUserActivityInjection = () => {
  const iframeEnabled = useSelector(getIsIframeEnabled);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isLoggedInRef = useRef<boolean>(false);

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const events = iframeEnabled
      ? ['mousemove', 'keydown', 'wheel', 'mousewheel', 'mousedown', 'touchstart', 'touchmove']
      : [];

    const handleEvent = () => {
      if (timerRef.current) {
        return;
      }

      timerRef.current = setTimeout(() => {
        sendIframeMessages({ type: PostMessageTypes.USER_ACTIVITY });

        timerRef.current = null;
      }, USER_ACTIVITY_TIMEOUT);
    };

    events.forEach(event => {
      window.addEventListener(event, handleEvent);
    });

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, handleEvent);
      });
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isLoggedInRef.current && !isLoggedIn) {
      if (iframeEnabled) {
        sendIframeMessages({ type: PostMessageTypes.LOGOUT });
      }
    }
    isLoggedInRef.current = isLoggedIn;
  }, [isLoggedIn]);

  return null;
};

export default IframeUserActivityInjection;
