import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import Modal from 'components/Modal';
import { AsianViewMarketLink, AsianViewTimeFilter } from 'constants/asianView';
import useAsianSingleView from 'hooks/useAsianSingleView';
import useLeaguesFilters from 'hooks/useLeaguesFilters';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { getIsAllAsianViewCompetitionsClosed } from 'redux/modules/asianView/selectors';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import {
  fetchLeagues,
  setCurrentParams,
  setIsLeaguesLoaded,
  setIsLeaguesOpened
} from 'redux/modules/asianViewLeaguesFilter';
import { getLeaguesOpened } from 'redux/modules/asianViewLeaguesFilter/selectors';
import { clearQuickBets } from 'redux/modules/asianViewQuickBetting';
import { getClosedMarketsIds } from 'redux/modules/marketsPrices/selectors';
import { SportId } from 'types';
import { getIsValidMarketLink, getIsValidTimeFilter } from 'utils/asianView';

import LeaguesContent from './components/LeaguesContent';
import LeaguesPlaceholder from './components/LeaguesPlaceholder';

import styles from './styles.module.scss';

const LeaguesFilter = () => {
  const dispatch = useDispatch();
  const { sportId = SportId.SOCCER, timeFilter, marketLink } = useParams();

  const singleViewFilters = useLeaguesFilters();
  const closedMarketIds = useSelector(getClosedMarketsIds);
  const isAllLeaguesClosed = useSelector(getIsAllAsianViewCompetitionsClosed(closedMarketIds));
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isOpened = useSelector(getLeaguesOpened);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const stringifiedSingleViewFilters = JSON.stringify(singleViewFilters);

  const { isPopularLinkView, isAsianSingleView, isAsianSingleEventView, isFavouriteView } = useAsianSingleView();

  const marketLinkParam =
    isPopularLinkView || isAsianSingleView || isAsianSingleEventView || isFavouriteView
      ? AsianViewMarketLink.HDP_AND_OU
      : (marketLink as AsianViewMarketLink);

  useEffect(() => {
    // TODO shouldn't make request if close competitions dropdown (no isOpened dependency)
    if (
      marketLinkParam &&
      sportId &&
      timeFilter &&
      getIsValidTimeFilter(timeFilter) &&
      getIsValidMarketLink(marketLinkParam)
    ) {
      dispatch(setCurrentParams(`${sportId}-${marketLinkParam}-${timeFilter}`));

      if (isAllLeaguesClosed) {
        dispatch(setIsLeaguesLoaded(false));
      }

      dispatch(
        fetchLeagues({
          id: sportId,
          payload: {
            marketLink: marketLinkParam,
            timeFilter: timeFilter as AsianViewTimeFilter,
            ...singleViewFilters
          }
        })
      );
    }

    if (isOpened) {
      dispatch(clearQuickBets());
    }
  }, [isOpened, marketLinkParam, sportId, timeFilter, isAllLeaguesClosed, stringifiedSingleViewFilters]);

  useOnClickOutside(dropdownRef, () => {
    if (!isMobileAsianView) {
      dispatch(setIsLeaguesOpened(false));
    }
  });

  return (
    <>
      <div className={classNames(styles.wrapper, 'biab_leagues-filter')} ref={dropdownRef}>
        <LeaguesPlaceholder />
        {isOpened && !isMobileAsianView && <LeaguesContent />}
        {isOpened && isMobileAsianView && (
          <Modal
            open={isOpened}
            hasTitle={false}
            clickableBackground
            noCloseIcon
            customClassNames={{ body: styles.modalBody }}
            onClose={() => dispatch(setIsLeaguesOpened(!isOpened))}
          >
            <LeaguesContent />
          </Modal>
        )}
      </div>
    </>
  );
};

export default LeaguesFilter;
