import classNames from 'classnames';

import GameDerbyRacingCartItem from 'components/GameDerbyRacingCartItem';
import SegmentLine from 'components/GameDerbyRacingSecondSection/partials/SegmentLine';
import SegmentNumbers from 'components/GameDerbyRacingSecondSection/partials/SegmentNumbers';
import { TGameData } from 'redux/modules/games/type';

import styles from './styles.module.scss';

const MAX_POSSIBLE_OPENING_CARTS_COUNT = 3;

interface IGameDerbyRacingSecondSection {
  data?: TGameData;
  segments: TGameData[];
}

const GameDerbyRacingSecondSection = ({ data, segments }: IGameDerbyRacingSecondSection) => {
  const getCarts = () => {
    const carts = data?.properties?.[2]?.value?.split(',') || [];
    const cartsLength = carts?.length || 0;
    if (cartsLength === MAX_POSSIBLE_OPENING_CARTS_COUNT) {
      return carts;
    }
    return [...carts, ...new Array(3 - cartsLength).fill(null)];
  };

  return (
    <div className={styles.secondSection}>
      <div className={styles.carts}>
        {getCarts().map((v, index) => (
          <GameDerbyRacingCartItem key={`${v}-${index}`} value={v} />
        ))}
      </div>
      <div className={classNames(styles.segments, 'biab_games-segments-wrapper')}>
        {segments.map((s, index) => (
          <SegmentLine data={s} lineClassName={`biab_suit-${s.name.toLowerCase()}`} key={index} />
        ))}
        <SegmentNumbers activeIndexes={segments.map(s => parseInt(s.properties[0]?.value))} />
      </div>
    </div>
  );
};

export default GameDerbyRacingSecondSection;
