import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ASIAN_BASE_URL } from 'constants/locations';
import api from 'redux/api/methods';

import {
  failureFetchAsianCounter,
  failureFetchAsianSports,
  failureFetchEventsCounter,
  fetchAsianCounter,
  fetchAsianSports,
  fetchEventsCounter,
  successFetchAsianCounter,
  successFetchAsianSports,
  successFetchEventsCounter
} from './index';
import { IAsianSport, ISportCounter, ISportEventsCounter } from './type';

function* fetchAsianSportsWorker(action: ReturnType<typeof fetchAsianSports>) {
  try {
    const response: IAsianSport[] = yield call(api.asianNavigation.getAsianSports, action.payload);
    yield put(successFetchAsianSports(response));
  } catch (e: any) {
    yield put(failureFetchAsianSports(e));
  }
}

function* fetchAsianCounterWorker(action: ReturnType<typeof fetchAsianCounter>) {
  try {
    const response: ISportCounter = yield call(api.asianNavigation.getAsianSportCounter, {
      sportId: action.payload.sportId,
      payload: action.payload.requestPayload
    });

    if (action.payload.navigate) {
      const listOfMarketLinks = Object.keys(response.marketLinksCounters);

      if (!listOfMarketLinks.includes(action.payload.requestPayload.marketLink)) {
        action.payload.navigate(
          `${ASIAN_BASE_URL}/sport/${action.payload.sportId}/timeFilter/${action.payload.requestPayload.timeFilter}/marketLink/${listOfMarketLinks[0]}`,
          {
            replace: true
          }
        );
      }
    }

    yield put(successFetchAsianCounter({ counter: response, sportId: action.payload.sportId }));
  } catch (e: any) {
    yield put(failureFetchAsianCounter({ error: e, sportId: action.payload.sportId }));
  }
}

function* fetchEventsCounterWorker(action: ReturnType<typeof fetchEventsCounter>) {
  try {
    const response: ISportEventsCounter = yield call(api.asianNavigation.getAsianSportEventsCounter, {
      sportId: action.payload.sportId
    });
    yield put(successFetchEventsCounter({ counter: response, sportId: action.payload.sportId }));
  } catch (e: any) {
    yield put(failureFetchEventsCounter({ error: e, sportId: action.payload.sportId }));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchAsianSports.type, fetchAsianSportsWorker),
    takeLatest(fetchAsianCounter.type, fetchAsianCounterWorker),
    takeLatest(fetchEventsCounter.type, fetchEventsCounterWorker)
  ]);
}
