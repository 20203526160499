import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Subheader from 'components/AsianSportSection/components/Content/components/Subheader';
import ListItem from 'components/AsianSportSection/components/ListItem';
import styles from 'components/AsianSportSection/styles.module.scss';
import NavSkeleton from 'components/Skeleton/NavSkeleton';
import { ASIAN_VIEW_CASH_OUT_LINK_NAME, AsianViewMarketLink } from 'constants/asianView';
import useAsianCashOut from 'hooks/useAsianCashOut';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import {
  getNavigationCountersBySportId,
  getNavigationCountersLoadingBySportId,
  getNavigationSportsLoading,
  getSportBySportId
} from 'redux/modules/asianSportsNavigation/selectors';
import { getAsianViewCashOutsAmount } from 'redux/modules/asianViewCashOutCounter/selectors';

type ListProps = {
  sportId: string;
};

const List = ({ sportId }: ListProps) => {
  const translation = useSelector(getTranslation);
  const counters = useSelector(getNavigationCountersBySportId(sportId));
  const countersLoading = useSelector(getNavigationCountersLoadingBySportId(sportId));
  const sportsLoading = useSelector(getNavigationSportsLoading);
  const sport = useSelector(getSportBySportId(sportId));
  const cashOutCounter = useSelector(getAsianViewCashOutsAmount);

  const isCashOutEnabled = useAsianCashOut();

  const marketLinksCounters = useMemo(() => {
    return counters?.marketLinksCounters ? Object.entries(counters.marketLinksCounters) : [];
  }, [counters?.marketLinksCounters]);

  return (
    <div className={styles.tabContent}>
      {sportsLoading || countersLoading ? (
        <NavSkeleton withDelay={150} itemsCount={10} />
      ) : (
        <>
          <Subheader count={counters?.totalNumberOfEvents || 0} name={sport?.translations[translation] || ''} />
          {!!marketLinksCounters.length &&
            marketLinksCounters.map(([key, value]) => (
              <ListItem sportId={sportId} marketLink={key as AsianViewMarketLink} key={key} name={key} count={value} />
            ))}
          {isCashOutEnabled && (
            <ListItem name={ASIAN_VIEW_CASH_OUT_LINK_NAME} count={cashOutCounter ?? 0} isCashOutLink />
          )}
        </>
      )}
    </div>
  );
};

export default List;
