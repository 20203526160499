import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { entries, uniq, values } from 'lodash';

import { SLICES_NAMES } from 'constants/app';

import { CashOutCounter, CashOutCounterState } from './type';

const initialState: CashOutCounterState = {
  cashOutCountersBySportId: {},
  cashOuts: {},
  eventCashOutCountersEventId: {},
  eventMarketIds: {},
  marketIds: [],
  allCashOutsAmount: 0
};

const slice = createSlice({
  name: SLICES_NAMES.CASH_OUT_COUNTER,
  initialState,
  reducers: {
    setCashOutCounters: (state, { payload }: PayloadAction<CashOutCounter[]>) => {
      let marketIds = [] as string[];
      state.allCashOutsAmount = 0;
      state.cashOutCountersBySportId = {};
      state.eventCashOutCountersEventId = {};
      state.eventMarketIds = {};

      payload.forEach(({ sportId, eventCashOuts, outrightCashOuts }) => {
        state.cashOuts[sportId] = {
          eventCashOuts,
          outrightCashOuts
        };

        const eventCashOutMarketIds = values(eventCashOuts).flat();
        const outrightCashOutsMarketIds = values(outrightCashOuts).flat();
        const eventCashOutsAmount = eventCashOutMarketIds.length;
        const outrightCashOutsAmount = outrightCashOutsMarketIds.length;

        const cashOutsAmount = eventCashOutsAmount + outrightCashOutsAmount;

        marketIds = [...marketIds, ...eventCashOutMarketIds, ...outrightCashOutsMarketIds];

        state.cashOutCountersBySportId[sportId] = cashOutsAmount;
        state.allCashOutsAmount = state.allCashOutsAmount + cashOutsAmount;

        entries(eventCashOuts).forEach(([eventId, marketsIds]) => {
          if (!state.eventCashOutCountersEventId[sportId]) {
            state.eventCashOutCountersEventId[sportId] = {};
          }

          if (!state.eventMarketIds[sportId]) {
            state.eventMarketIds[sportId] = {};
          }

          state.eventMarketIds[sportId][eventId] = marketsIds;
          state.eventCashOutCountersEventId[sportId][eventId] = marketsIds.length;
        });
      });
      state.marketIds = uniq(marketIds);
    },
    resetCashOutCounters: state => {
      state.allCashOutsAmount = 0;
      state.cashOutCountersBySportId = {};
      state.cashOuts = {};
      state.eventCashOutCountersEventId = {};
    }
  }
});

export const { setCashOutCounters, resetCashOutCounters } = slice.actions;

export default slice.reducer;
