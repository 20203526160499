import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { unescape } from 'lodash';

import WhatIfValue from 'components/WhatIf/components/WhatIfValue';
import { getCurrentWhatIf } from 'components/WhatIf/helpers';
import styles from 'components/WhatIf/styles.module.scss';
import useNetOfCommission from 'hooks/useNetOfCommission';
import useWhatIfTooltip from 'hooks/useWhatIfTooltip';
import { getBetslipLoading } from 'redux/modules/betslip/selectors';
import { getCurrentWhatIfById, getNewWhatIf } from 'redux/modules/whatIf/selectors';

interface WhatIfGamesProps {
  selectionId: number;
  tieSelectionId?: number;
  marketId: string;
  commissionRate: number | undefined;
  currency: string;
  marketType: string;
}

const WhatIfGames = ({
  selectionId,
  marketId,
  commissionRate = 0,
  currency,
  marketType,
  tieSelectionId
}: WhatIfGamesProps) => {
  const isNetOfCommissionChecked = useNetOfCommission();
  const isBetslipLoading = useSelector(getBetslipLoading);

  const currentBets = useSelector(getCurrentWhatIfById(marketId));
  const newBets = useSelector(getNewWhatIf({ marketId, selectionId }));

  const { currentPL, updateBet } = useMemo(() => {
    let currentPlVal: string[] = [];
    let updateBetVal: string[] = [];

    if (!isBetslipLoading) {
      const isNew = !!(currentBets.length && newBets.length);
      const isTie = tieSelectionId === selectionId;

      currentPlVal = getCurrentWhatIf({
        bets: currentBets.length ? currentBets : [],
        numberOfWinners: 1,
        selectionId,
        isTie
      });

      updateBetVal = getCurrentWhatIf({
        bets: isNew ? [...newBets, ...currentBets] : newBets,
        selectionId,
        isNew,
        isTie
      });
    }

    return { currentPL: currentPlVal, updateBet: updateBetVal };
  }, [isBetslipLoading, currentBets, newBets, tieSelectionId, selectionId]);

  const tooltip = useWhatIfTooltip({
    currentPL,
    updateBet,
    marketType
  });

  return (
    <div data-tooltip-id="tooltip" data-tooltip-html={unescape(tooltip)} className={styles.container}>
      <div className={styles.wrapBlock}>
        {!!currentPL.length && (
          <div className={styles.itemContainer}>
            {currentPL.map((item, index) => (
              <WhatIfValue
                key={`${index}_${item}`}
                item={item}
                isProfitNetOfCommissionChecked={isNetOfCommissionChecked}
                commission={commissionRate}
                currency={currency}
                isFinish={index < currentPL.length - 1}
                index={index}
              />
            ))}
          </div>
        )}
        {!!updateBet.length && (
          <div className={styles.itemContainer}>
            <div className={styles.arrow}>»</div>
            <div className={styles.itemContainer}>
              {updateBet.map((item, index) => (
                <WhatIfValue
                  key={`${index}_${item}`}
                  item={item}
                  isProfitNetOfCommissionChecked={isNetOfCommissionChecked}
                  commission={commissionRate}
                  currency={currency}
                  isFinish={index < updateBet.length - 1}
                  index={index}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WhatIfGames;
