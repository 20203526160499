import { useTranslation } from 'react-i18next';

import styles from './OpenedBetsTabEmpty.module.scss';

type OpenedBetsTabEmptyProps = {
  type: 'unmatched' | 'matched' | 'login';
};

const OpenedBetsTabEmpty = ({ type }: OpenedBetsTabEmptyProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <p className={styles.container__text}>
        {t(type === 'login' ? 'betslip.messages.logInToViewBets' : `betslip.openBets.${type}.empty`)}
      </p>
    </div>
  );
};

export default OpenedBetsTabEmpty;
