import { TCurrentPL } from 'redux/modules/plTable/type';

interface ITotalGoal {
  min: number;
  max: number;
  current: string;
  worst: string;
  profitOrLoss: string;
}

export const mapperPlToTable = (arr: TCurrentPL[]) => {
  const answer: ITotalGoal[] = [];

  arr.forEach((item, index, array) => {
    if (array[index - 1] && item.profitOrLoss === array[index - 1].profitOrLoss) {
      const preItem = answer.pop()!;
      preItem.max = item.virtualPoints;
      answer.push(preItem);
    } else {
      answer.push({
        min: item.virtualPoints,
        max: item.virtualPoints,
        current: arr[index]?.profitOrLoss || '0.00',
        worst: arr[index]?.profitOrLoss || '0.00',
        profitOrLoss: item.profitOrLoss
      });
    }
  });

  return answer;
};
