import { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from 'components/Checkbox';
import useOptimisticState from 'hooks/useOptimisticState';
import { getConsolidateBets } from 'redux/modules/appConfigs/selectors';
import { setConsolidateBets } from 'redux/modules/betslip';
import { setSettings } from 'redux/modules/user';
import { getIsUpdateSettingsError, getUserAccountSettingsConsolidateBets } from 'redux/modules/user/selectors';

import styles from './styles.module.scss';

const ConsolidateBets = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isUpdateSettingsError = useSelector(getIsUpdateSettingsError);
  const isConsolidateBetsEnabled = useSelector(getConsolidateBets);
  const consolidateBets = useSelector(getUserAccountSettingsConsolidateBets);

  useEffect(() => {
    dispatch(setConsolidateBets(consolidateBets && isConsolidateBetsEnabled));
  }, [consolidateBets, isConsolidateBetsEnabled, dispatch]);

  const [isChecked, setIsChecked] = useOptimisticState({
    initialState: consolidateBets,
    onSetState: value => dispatch(setSettings({ settings: { consolidateBets: value } })),
    externalError: isUpdateSettingsError
  });

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(ev.target.checked);
  };

  return (
    <div className={styles.consolidateBets}>
      <Checkbox checked={isChecked} onChange={handleChange}>
        {t('betslip.labels.openBetsHeader.consolidateBets')}
      </Checkbox>
    </div>
  );
};

export default ConsolidateBets;
