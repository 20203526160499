import classNames from 'classnames';

import { TAsianViewMarket } from 'redux/modules/asianView/type';

import EmptyMarketCell from '../EmptyMarketCell';
import MarketCell from '../MarketCell';
import EmptyMarket from '../MarketCell/EmptyMarket';

import styles from './styles.module.scss';

interface MarketsCellProps {
  competitionId: string;
  eventId: string;
  markets: TAsianViewMarket[];
  isFirstEvent: boolean;
  isExpanded?: boolean;
  isSingleLine?: boolean;
}

const MarketsCell = ({
  competitionId,
  eventId,
  markets,
  isExpanded,
  isFirstEvent,
  isSingleLine = false
}: MarketsCellProps) => {
  const commonProps = {
    competitionId,
    eventId,
    isFirstEvent,
    isSingleLine
  };

  return (
    <div
      className={classNames('biab_asian-view-markets-cell', styles.marketsCell, {
        [styles.marketsCell__expanded]: isExpanded
      })}
    >
      {markets.length > 0 && (
        <>
          {markets[0] && <MarketCell market={markets[0]} marketIndex={0} {...commonProps} />}
          {!markets[0] && <div className={classNames('biab_asian-view-market-cell', styles.marketCell__empty)} />}
          {isExpanded && (
            <>
              {markets[1] && <MarketCell market={markets[1]} marketIndex={1} {...commonProps} />}
              {!markets[1] && <EmptyMarket isSingleLine={isSingleLine} />}
              {markets[2] && <MarketCell market={markets[2]} marketIndex={2} {...commonProps} />}
              {!markets[2] && <EmptyMarket isSingleLine={isSingleLine} />}
            </>
          )}
        </>
      )}
      {isFirstEvent && markets.length === 0 && <EmptyMarketCell {...commonProps} />}
    </div>
  );
};

export default MarketsCell;
