import classnames from 'classnames';

import styles from './styles.module.scss';

interface IGameDerbyRacingCartItem {
  value?: string | number | null;
  withOpacity?: boolean;
}

const GameDerbyRacingCartItem = ({ value, withOpacity }: IGameDerbyRacingCartItem) => {
  const getCardImage = () => {
    return require(`assets/images/games/cards/card${value}.gif`);
  };

  return (
    <div
      className={classnames(styles.main, {
        [styles.opacity]: withOpacity
      })}
    >
      {value || value === 0 ? (
        <img src={getCardImage()} alt="card image" />
      ) : (
        <div className={classnames(styles.notAvailable, 'biab_not-available-card')} />
      )}
    </div>
  );
};

export default GameDerbyRacingCartItem;
