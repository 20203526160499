import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { betslipBranding } from 'constants/branding';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { useMarketUnits } from 'hooks/useMarketUnits';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getCurrentBetByOfferId } from 'redux/modules/currentBets/selectors';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { BettingType } from 'types/markets';
import { formatBestPrice } from 'utils/betslip';
import { getInlinePlacedOfferStatuses } from 'utils/inlinePlacement';

import styles from './styles.module.scss';

type TInlinePlacedBetProps = { bet: TInlineSelectedBet };

const InlinePlacedBetMessage = ({ bet }: TInlinePlacedBetProps) => {
  const { t } = useTranslation();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);

  const currentBet = useSelector(getCurrentBetByOfferId(bet?.placedBet?.offerId || 0));

  const { isFullyMatched, isUnmatched, isPartiallyMatched, isCancelled, isLapsed, isPlacedWithBetterOdds, odds } =
    getInlinePlacedOfferStatuses(currentBet ?? bet.placedBet, isPNCEnabled);

  const currency = bet?.placedBet?.currency ?? '';
  const currencyOptions = { noCommas: true, noRounding: true };

  const sizeMatched = currentBet?.sizeMatched ?? bet?.placedBet?.sizeMatched ?? 0;
  const sizePlaced = currentBet?.sizePlaced ?? bet?.placedBet?.sizePlaced ?? 0;
  const sizeRemaining = currentBet?.sizeRemaining ?? bet?.placedBet?.sizeRemaining ?? 0;
  const sizeCancelled = currentBet?.sizeCancelled ?? bet?.placedBet?.sizeCancelled ?? 0;
  const sizeLapsed = currentBet?.sizeLapsed ?? bet?.placedBet?.sizeLapsed ?? 0;

  const { noFormattedAmount: sizeMatchedFormatted } = useFormatCurrency(sizeMatched, currency, currencyOptions);
  const { noFormattedAmount: sizePlacedFormatted } = useFormatCurrency(sizePlaced, currency, currencyOptions);
  const { noFormattedAmount: sizeRemainingFormatted } = useFormatCurrency(sizeRemaining, currency, currencyOptions);
  const { noFormattedAmount: sizeCancelledFormatted } = useFormatCurrency(sizeCancelled, currency, currencyOptions);
  const { noFormattedAmount: sizeLapsedFormatted } = useFormatCurrency(sizeLapsed, currency, currencyOptions);

  const isLineMarket = bet.bettingType === BettingType.LINE;
  const marketUnitTranslated = useMarketUnits(bet.marketUnit ?? 'points');
  const priceFormatted = formatBestPrice(+(bet.placedBet?.handicap ?? 0), bet.marketType, bet.bettingType ?? '');
  const getLabel = (stake: string) =>
    isLineMarket
      ? t('placement.labels.stakeForUnits', { stake, units: `${priceFormatted} ${marketUnitTranslated}` })
      : `${stake} @${odds}`;

  return (
    <p className={styles.message}>
      {isPlacedWithBetterOdds && (
        <>
          <strong>{getLabel(sizeMatchedFormatted)}</strong> &ndash; {t('betslip.labels.pnc.placedWithBetterOdds')}
        </>
      )}
      {!isPlacedWithBetterOdds && isFullyMatched && (
        <>
          <strong>{getLabel(sizeMatchedFormatted)}</strong> &ndash;{' '}
          {t(`${isPNCEnabled ? 'pnc.' : ''}inlinePlacement.messages.${isPNCEnabled ? 'placed' : 'fullyMatched'}`)}
        </>
      )}
      {isUnmatched && (
        <>
          <strong>{getLabel(sizePlacedFormatted)}</strong> &ndash; {t('inlinePlacement.messages.unmatched')}
        </>
      )}
      {isPartiallyMatched && (
        <>
          <span className={styles.message__line}>
            <i
              className={classNames(
                'biab_custom-icon-success-circle',
                styles.icon,
                styles.icon__matched,
                betslipBranding.CHECKMARK_ICON
              )}
            />
            <strong>{getLabel(sizeMatchedFormatted)}</strong> &ndash; {t('inlinePlacement.messages.partiallyMatched')}
          </span>
          <span className={styles.message__line}>
            <i
              className={classNames(
                'fa2 fa2-clock-icon',
                styles.icon,
                styles.icon__unmatched,
                betslipBranding.CLOCK_ICON,
                betslipBranding.ERROR
              )}
            />
            <strong>{getLabel(sizeRemainingFormatted)}</strong> &ndash; {t('inlinePlacement.messages.unmatched')}
          </span>
        </>
      )}
      {isCancelled && (
        <>
          <strong>{getLabel(sizeCancelledFormatted)}</strong> &ndash; {t('inlinePlacement.messages.cancelled')}
        </>
      )}
      {isLapsed && (
        <>
          <strong>{getLabel(sizeLapsedFormatted)}</strong> &ndash; {t('inlinePlacement.messages.lapsed')}
        </>
      )}
    </p>
  );
};

export default InlinePlacedBetMessage;
