import { createSelector } from '@reduxjs/toolkit';
import { sortBy, values } from 'lodash';

import { getAreAsianCurrentBetsLoaded, getAVCurrentBetsOffers } from 'redux/modules/asianViewCurrentBets/selectors';
import { EAsianBettingActions } from 'redux/modules/asianViewQuickBetting/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { AppState } from 'redux/reducers';
import { EAsianBetslipTabs } from 'types/asianView';
import { getAsianViewBetUuid, getSelectedBetIdentifier } from 'utils/asianView';

import { TAsianSelectedBetIdentifierParams } from './type';

const getAsianViewBetslip = (state: AppState) => state.asianViewBetslip;

export const getAsianViewBetslipSelectedTab = ({ asianViewBetslip }: AppState) => asianViewBetslip.selectedTab;
export const getIsAsianViewBetSlipCashOutTabOpened = ({ asianViewBetslip }: AppState) =>
  asianViewBetslip.selectedTab === EAsianBetslipTabs.CASH_OUT;

export const getAsianSelectedBets = ({ asianViewBetslip }: AppState) => asianViewBetslip.selectedBets;

export const getAsianSelectedBetsList = createSelector(getAsianViewBetslip, ({ selectedBets }) =>
  sortBy(Object.values(selectedBets), 'order').reverse()
);

export const getAsianSelectedBetsAmount = createSelector(
  getAsianSelectedBets,
  selectedBets => Object.keys(selectedBets).length
);

export const getPlaceBetsState = ({ asianViewBetslip }: AppState) => asianViewBetslip.placeBetsState;

export const getIsAsianSelectedBetExist =
  (params: TAsianSelectedBetIdentifierParams) =>
  ({ asianViewBetslip }: AppState) =>
    asianViewBetslip.selectedBets.hasOwnProperty(getSelectedBetIdentifier(params));

export const getAsianViewIsAtLeastOnePendingStatus = ({ asianViewBetslip }: AppState) =>
  asianViewBetslip.isAtLeastOnePendingStatus;
export const getAreAsianViewBetsStatusesLoaded = ({ asianViewBetslip }: AppState) => asianViewBetslip.areStatusesLoaded;
export const getAsianViewBetsStatusesLoading = ({ asianViewBetslip }: AppState) => asianViewBetslip.statusesLoading;
export const getAsianViewOfferIdsToGetBetStatuses = createSelector(
  getAsianSelectedBets,
  getAreAsianCurrentBetsLoaded,
  getAVCurrentBetsOffers,
  (selectedBets, areCurrentBetsLoaded, offers) => {
    if (areCurrentBetsLoaded && Object.keys(selectedBets).length && Object.keys(offers).length) {
      return Object.values(selectedBets)
        .map(({ offerId }) => offerId)
        .filter(offerId => {
          return typeof offerId === 'number' && !offers[offerId];
        }) as number[];
    }

    return [];
  }
);
export const getAsianViewBetStatusByOfferId =
  (offerId?: number) =>
  ({ asianViewBetslip }: AppState) =>
    offerId ? asianViewBetslip.statuses[offerId]?.status : undefined;
export const getAsianViewBetStatusErrorByOfferId =
  (offerId?: number) =>
  ({ asianViewBetslip }: AppState) =>
    offerId ? asianViewBetslip.statuses[offerId]?.error : undefined;
export const getAsianViewBetOfferIdByBetUuid =
  (marketId: string, betUuid: string) =>
  ({ asianViewBetslip }: AppState) =>
    asianViewBetslip.placedBetsByMarket[marketId]?.offerIds?.[betUuid];

export const getAsianViewActiveSelectedBetsAmount = createSelector(
  getAsianViewBetslip,
  ({ selectedBets, placedBetsByMarket, statuses }) => {
    const activeSelectedBets = values(selectedBets).filter(
      ({ isDisabled, marketId, selectionId, betType, bettingAction, handicap }) => {
        const offerId =
          placedBetsByMarket[marketId]?.offerIds?.[
            getAsianViewBetUuid({
              marketId,
              selectionId,
              handicap,
              betType
            })
          ];
        const statusData = offerId ? statuses[offerId] : undefined;

        return (
          !isDisabled &&
          (!statusData?.status || statusData?.status !== BetsStatusesTypes.MATCHED) &&
          bettingAction !== EAsianBettingActions.PROGRESS
        );
      }
    );

    return activeSelectedBets.length;
  }
);

export const getAsianViewMatchedSelectedBetsIdentifiers = createSelector(
  getAsianViewBetslip,
  ({ selectedBets, placedBetsByMarket, statuses }) => {
    return values(selectedBets)
      .filter(({ marketId, selectionId, betType, handicap, offerId }) => {
        const betOfferId =
          placedBetsByMarket[marketId]?.offerIds?.[
            getAsianViewBetUuid({
              marketId,
              selectionId,
              handicap,
              betType
            })
          ];
        const statusData = betOfferId ? statuses[betOfferId] : undefined;
        return statusData?.status === BetsStatusesTypes.MATCHED || (!statusData && offerId);
      })
      .map(({ identifier }) => identifier);
  }
);

export const getIsBetslipSelectedTab = ({ asianViewBetslip }: AppState) =>
  asianViewBetslip.selectedTab === EAsianBetslipTabs.BET_SLIP;

export const getIsSelectedBetsLimitNotification = ({ asianViewBetslip }: AppState) =>
  asianViewBetslip.isSelectedBetsLimitNotification;

export const getPlaceBetsLoading = ({ asianViewBetslip }: AppState) => asianViewBetslip.placeBetsLoading;

export const getLiabilityByMarket = ({ asianViewBetslip }: AppState) => asianViewBetslip.liabilityByMarket;

export const getBetslipRGErrorMessage = ({ asianViewBetslip }: AppState) => asianViewBetslip.rgErrorMessage;
