export const ErrorStatusCodes = {
  UNKNOWN_ERROR: 0,
  VALIDATION_ERROR: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CUSTOM_SPECIFIC_ERROR: 409,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  PRECONDITION_FAILED: 412
} as const;

export type ErrorStatusCodesType = typeof ErrorStatusCodes[keyof typeof ErrorStatusCodes];

export const ignoreShowingForbiddenContent = ['currentBets', 'profitAndLossTable', 'code'];
