import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import MatchStatistics from 'components/MatchStatistics';
import VideoStream from 'components/VideoStream';
import { BACK_COLUMN, LAY_COLUMN, MATCHED_TOTAL } from 'constants/tooltip';
import useCellConfigs from 'hooks/useCellConfigs';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useLayColumn from 'hooks/useLayColumn';
import useTooltip from 'hooks/useTooltip';
import { getAppDevice, getHomePageColumnsNumber, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getMarketPricesCurrencyById, getMatchedAmountByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { CurrencySettingsPlacement, SportId } from 'types';
import { IMarket, MarketSelectionsClasses } from 'types/markets';

import styles from './styles.module.scss';

interface MarketTableProps {
  /**
   * Market
   */
  market: IMarket;
  /**
   * Show deep prices or not (even if enabled)
   */
  showDeepPrices?: boolean;
  /**
   * Classes for customizing a component
   */
  classes?: MarketSelectionsClasses;
  /**
   * Determines where it was called from
   */
  isHomePage?: boolean;
  /**
   * Whether to apply market view to the bet cell
   */
  betCellsMarketView?: boolean;
  /**
   * Double cell container width for desktop
   */
  doubleCellsWidth?: boolean;
  /**
   * Show empty columns on desktop if market depth is disabled
   */
  showEmptyColumns?: boolean;
  showEventWidgetsIcons?: boolean;
}

const MarketSelections = ({
  market,
  showDeepPrices = false,
  classes,
  isHomePage,
  betCellsMarketView = false,
  doubleCellsWidth = false,
  showEmptyColumns = false,
  showEventWidgetsIcons = false
}: MarketTableProps) => {
  const { t } = useTranslation();
  const { eventType } = market;

  const device = useSelector(getAppDevice);
  const homePageColumnsNumber = useSelector(getHomePageColumnsNumber);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const totalMatched = useSelector(getMatchedAmountByMarketPricesId(market.marketId));
  const currency = useSelector(getMarketPricesCurrencyById(market.marketId));

  const { translationKey: translationKeyBack, isEnabled: isEnabledBack } = useTooltip(BACK_COLUMN);
  const { translationKey: translationKeyLay, isEnabled: isEnabledLay } = useTooltip(LAY_COLUMN);
  const { translationKey: translationKeyTotal, isEnabled: isEnabledTotal } = useTooltip(MATCHED_TOTAL);
  const { isLayColumnEnabled, isLineMarket } = useLayColumn({
    sportId: market.eventType.id,
    bettingType: market.description.bettingType
  });

  const defineCellGroupsCount = () => {
    if (isHomePage) {
      return homePageColumnsNumber === 1 ? 3 : 1;
    }

    return showDeepPrices ? 3 : 1;
  };

  const { lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver } = useDeviceSettings();
  const { formattedAmount: matchedAmount, noFormattedAmount: tooltipMatchedAmount } = useFormatCurrency(
    totalMatched ?? 0,
    currency ?? '',
    {
      isCheckIndian: true,
      placement: CurrencySettingsPlacement.MATCHED_AMOUNT
    }
  );

  const cellGroupsCount = defineCellGroupsCount();

  const { desktopCellsContainerWidth, mobileCellsContainerWidth } = useCellConfigs({
    cellGroupsCount,
    isMarketView: betCellsMarketView,
    isHomePage,
    showDepth: (isHomePage && homePageColumnsNumber === 1) || showEmptyColumns,
    doubleWidth: (isHomePage && homePageColumnsNumber === 2) || doubleCellsWidth,
    addMobileExtraSpace: true
  });

  const isDesktop = device === Devices.DESKTOP;
  const isCricket = eventType.id === SportId.CRICKET;

  const getCellHeaderTranslation = (label: string) => {
    const hasLineBackLayToNoYes = isCricket && lineMarketsSwitchBackLayOnCricket && isLineMarket;

    const replaceWithOverUnder = !isCricket && replaceBackLayWithUnderOver && isLineMarket;

    if (label === 'back') {
      return t(
        hasLineBackLayToNoYes ? 'market.column.no' : replaceWithOverUnder ? 'market.column.under' : 'market.back'
      );
    }

    return t(hasLineBackLayToNoYes ? 'market.column.yes' : replaceWithOverUnder ? 'market.column.over' : 'market.lay');
  };

  return (
    <div
      className={classNames(styles.marketTable__content__header, {
        [classes?.header ?? '']: classes?.header
      })}
    >
      <div className={styles.marketTable__totalMatched}>
        <span
          data-tooltip-id={'tooltip'}
          data-tooltip-html={isEnabledTotal ? unescape(t(translationKeyTotal, { amount: tooltipMatchedAmount })) : ''}
          className={isEnabledTotal ? 'cursor-help' : ''}
        >
          {t(`${isPNCEnabled ? 'pnc.market.matched' : 'account.mybets.btns.matched'}`)}: {matchedAmount}
        </span>
        {showEventWidgetsIcons && (
          <>
            {market.event.videoStreamingEnabled && (
              <VideoStream visible={market.event.videoStreamingEnabled} eventId={market.event.id} />
            )}
            {market.event.matchStatEnabled && (
              <MatchStatistics
                isSoccer={market.eventType.id === SportId.SOCCER}
                visible={market.event.matchStatEnabled}
                eventId={market.event.id}
              />
            )}
          </>
        )}
      </div>
      <div
        className={classNames(styles.marketTable__totalMatchedCell, {
          [styles.marketTable__totalMatchedCell__withoutLay]: !isLayColumnEnabled
        })}
        style={{ width: isDesktop ? desktopCellsContainerWidth : mobileCellsContainerWidth }}
      >
        <div
          style={{ width: `${100 / (showEmptyColumns ? 3 : cellGroupsCount)}%` }}
          className={styles.marketTable__totalMatchedCell__content}
        >
          <span
            data-tooltip-id="tooltip"
            data-tooltip-html={isEnabledBack ? unescape(t(translationKeyBack)) : ''}
            className={isEnabledBack ? 'cursor-help' : ''}
          >
            {getCellHeaderTranslation('back')}
          </span>
          {isLayColumnEnabled && (
            <span
              data-tooltip-id="tooltip"
              data-tooltip-html={isEnabledLay ? unescape(t(translationKeyLay)) : ''}
              className={isEnabledLay ? 'cursor-help' : ''}
            >
              {getCellHeaderTranslation('lay')}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketSelections;
