import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import Skeleton from 'components/Skeleton';
import { AsianViewMarketLink, TOTAL_GOALS_HEADER } from 'constants/asianView';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { generateArray } from 'utils/general';

import styles from './styles.module.scss';

const LoadingSkeleton = () => {
  const { marketLink } = useParams();

  const isMobileAsianView = useSelector(getIsMobileAsianView);

  const isTotalGoals = marketLink === AsianViewMarketLink.TOTAL_GOALS;
  const isOddEven = marketLink === AsianViewMarketLink.ODD_EVEN;
  const isOutRight = marketLink === AsianViewMarketLink.OUTRIGHT;

  if (isOutRight) {
    const skeletonCount = generateArray(4);

    if (isMobileAsianView) {
      return (
        <div
          className={classNames(
            'biab_asian-view-skeleton-wrapper',
            styles.skeleton,
            styles.outRight,
            styles.outRight__mobile
          )}
        >
          {skeletonCount.map(item => (
            <div className={classNames(styles.outRight__line, styles.outRight__lineMobile)} key={item}>
              <Skeleton className={styles.outRight__name} />
              <div className={styles.outRight__mobileWrapLay}>
                <Skeleton className={styles.outRight__layMobile} />
                <Skeleton className={styles.outRight__layMobile} />
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className={classNames('biab_asian-view-skeleton-wrapper', styles.skeleton, styles.outRight)}>
        {skeletonCount.map(item => (
          <div className={styles.outRight__line} key={item}>
            <Skeleton className={styles.outRight__name} />
            <Skeleton className={styles.outRight__lay} />
          </div>
        ))}
      </div>
    );
  }

  if (isTotalGoals) {
    return (
      <div className={classNames('biab_asian-view-skeleton-wrapper', styles.skeleton, styles.totalGoals)}>
        <div className={styles.totalGoals__firstLine}>
          <Skeleton className={styles.totalGoals__firstLineItem1} />
          <Skeleton className={styles.totalGoals__firstLineItem2} />
        </div>
        <div className={styles.totalGoals__secondLine}>
          {TOTAL_GOALS_HEADER.map(item => (
            <Skeleton key={item} className={styles.totalGoals__secondItem} />
          ))}
        </div>
      </div>
    );
  }

  if (isOddEven) {
    return (
      <div className={classNames('biab_asian-view-skeleton-wrapper', styles.skeleton, styles.oddEven)}>
        <div>
          <Skeleton className={styles.skeleton__title} />
          <Skeleton className={styles.skeleton__filter} />
          <Skeleton className={styles.skeleton__filter} />
          <Skeleton className={styles.skeleton__competition} />
        </div>

        <div className={styles.oddEven__line}>
          <Skeleton className={styles.oddEven__first} />

          <div className={styles.oddEven__secondContainer}>
            <Skeleton className={styles.oddEven__second} />
            <Skeleton className={styles.oddEven__second} />
          </div>
        </div>
        <div className={styles.oddEven__line}>
          <Skeleton className={styles.oddEven__first} />

          <div className={styles.oddEven__secondContainer}>
            <Skeleton className={styles.oddEven__second} />
            <Skeleton className={styles.oddEven__second} />
          </div>
        </div>
      </div>
    );
  }

  if (isMobileAsianView) {
    return (
      <div className={classNames('biab_asian-view-skeleton-wrapper', styles.skeleton)}>
        <Skeleton className={styles.skeleton__competition__mobile} />
        <Skeleton className={styles.skeleton__lineMobile} />
        <div className={styles.skeleton__markets__mobile}>
          <Skeleton className={styles.skeleton__lineMobile} />
          <Skeleton className={styles.skeleton__lineMobile} />
        </div>
        <div className={styles.skeleton__selections__mobile}>
          <Skeleton className={styles.skeleton__oddsMobile} />
          <Skeleton className={styles.skeleton__oddsMobile} />
          <Skeleton className={styles.skeleton__oddsMobile} />
          <Skeleton className={styles.skeleton__oddsMobile} />
          <Skeleton className={styles.skeleton__oddsMobile} />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('biab_asian-view-skeleton-wrapper', styles.skeleton)}>
      <Skeleton className={styles.skeleton__title} />
      <Skeleton className={styles.skeleton__filter} />
      <Skeleton className={styles.skeleton__filter} />
      <Skeleton className={styles.skeleton__competition} />
      <Skeleton className={styles.skeleton__score} />
      <div className={styles.skeleton__selections}>
        <Skeleton className={styles.skeleton__selection} />
        <Skeleton className={styles.skeleton__selection} />
      </div>
      <div className={styles.skeleton__markets}>
        <Skeleton className={styles.skeleton__market} />
        <Skeleton className={styles.skeleton__market} />
      </div>
    </div>
  );
};

export default LoadingSkeleton;
