import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import GameInfoRow from 'components/BetsTable/components/GameInfoRow';
import {
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_OUTRIGHT_MARKET_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  AsianViewMarketLink,
  AsianViewTimeFilter
} from 'constants/asianView';
import { ASIAN_BASE_URL, GAMES_BASE_URL, SPORT_BASE_URL } from 'constants/locations';
import { MARKET_TYPES } from 'constants/marketTypes';
import { BetStatuses } from 'constants/myBets';
import { TMyBetsContentItem } from 'redux/modules/myBets/type';
import { BettingType } from 'types/markets';
import { isLineBettingType } from 'utils/betslip';
import { getRaceStartTime, roundedPriceLineBet } from 'utils/myBetsValues';

import styles from '../../styles.module.scss';

interface MobileBetDescriptionProps {
  bet: TMyBetsContentItem;
  currentStatus?: BetStatuses;
  isCurrentStatus: boolean;
}

const MobileBetDescription = ({ bet, isCurrentStatus, currentStatus }: MobileBetDescriptionProps) => {
  const { t } = useTranslation();
  const isGame = bet.betType === BettingType.GAME;
  const isLineMarket = isLineBettingType(bet.bettingType);

  const sportUrl = `${SPORT_BASE_URL}/${bet.eventTypeId}${
    bet.raceName ? `/market/${bet!.marketId}` : `/event/${bet!.eventId}`
  }`;

  const marketUrl = `${SPORT_BASE_URL}/${bet.eventTypeId}/market/${bet.marketId}`;

  const gameUrl = `${GAMES_BASE_URL}/${bet.eventTypeId}`;

  const isUrlEnabled = !!(bet.raceName || bet.eventId);

  const asianViewEventUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilter.Today
  }/marketLink/${AsianViewMarketLink.HDP_AND_OU}?${ASIAN_SINGLE_VIEW_EVENT_ID}=${
    bet!.eventId
  }&${ASIAN_SINGLE_VIEW_SPORT_ID}=${bet.eventTypeId}`;
  const asianViewOutrightUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilter.Today
  }/marketLink/${AsianViewMarketLink.OUTRIGHT}?${ASIAN_OUTRIGHT_COMPETITION_ID}=${bet!.competitionId}&page=0`;
  const asianViewOutrightMarketUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilter.Today
  }/marketLink/${AsianViewMarketLink.OUTRIGHT}?${ASIAN_OUTRIGHT_COMPETITION_ID}=${
    bet!.competitionId
  }&${ASIAN_OUTRIGHT_MARKET_ID}=${bet!.marketId}&page=0`;

  const navUrl = isGame ? gameUrl : sportUrl;
  const asianUrl = bet?.outright ? asianViewOutrightUrl : asianViewEventUrl;

  const eventNameTpl = bet.betType === BettingType.GAME ? bet!.sportName : bet.eventName;
  const score = bet.marketType === MARKET_TYPES.asianHandicap && bet.score ? ` (${bet.score})` : '';
  const selectionNameTpl = `${bet.selectionName} ${isLineMarket ? roundedPriceLineBet(bet) : ''}${score}`;

  return (
    <>
      {t('account.mybets.labels.description')}
      <div className={classNames(styles.descriptionField, 'biab_description-field-value')}>
        {isCurrentStatus ? (
          <>
            {isUrlEnabled ? (
              <Link className={classNames('biab_eventName', styles.eventName)} to={bet.asian ? asianUrl : navUrl}>
                <strong>{eventNameTpl}</strong>
              </Link>
            ) : (
              <strong className={classNames('biab_eventName', styles.eventName)}>{eventNameTpl}</strong>
            )}
          </>
        ) : (
          <>
            {isGame ? (
              <Link className={classNames('biab_eventName biab_gameName', styles.eventName)} to={gameUrl}>
                {bet!.sportName}
              </Link>
            ) : (
              <span className={classNames('biab_eventName', styles.eventName)}>
                <strong>{bet.eventName}</strong>
              </span>
            )}
          </>
        )}
        <span className={classNames('biab_selectionName', styles.secondDescriptionLine)}>
          <span className={styles.selectionLine}>
            <strong className={styles.selectionName}>
              {isCurrentStatus ? (
                <>
                  {isGame ? (
                    bet.selectionName
                  ) : (
                    <Link
                      className={styles.selectionName}
                      to={bet.asian && bet.outright ? asianViewOutrightMarketUrl : marketUrl}
                    >
                      {selectionNameTpl}
                    </Link>
                  )}
                </>
              ) : (
                selectionNameTpl
              )}
            </strong>
          </span>
          <span className={classNames(styles.selectionName, 'biab_marketName')}>
            {bet.raceName && <>{` ${getRaceStartTime(bet.marketStartDate)} ${bet.raceName}`}</>}
            {!isGame && <span className={styles.marketNameDetails}> - {bet!.marketName}</span>}
          </span>
        </span>
        <span className={classNames('biab_groupName', styles.groupName)}>
          <GameInfoRow bet={bet} currentStatus={currentStatus} />
          {currentStatus === BetStatuses.Unmatched && (
            <Link
              to={`${SPORT_BASE_URL}/${bet.eventTypeId}/market/${bet.marketId}?action=edit&offerId=${bet.offerId}`}
              className={classNames('biab_edit-bet', styles.editBtn)}
            >
              {t('account.mybets.btns.edit')}
            </Link>
          )}
        </span>
      </div>
    </>
  );
};

export default MobileBetDescription;
