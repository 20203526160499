import { NavigateFunction } from 'react-router-dom';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';

import {
  IAsianSport,
  IAsianSports,
  IGetSportCounterPayload,
  IGetSportsPayload,
  ISportCounter,
  ISportEventsCounter
} from './type';

const initialState: IAsianSports = {
  sports: [],
  countersBySportId: {},
  stringifiedCountersBySportId: '',
  sportsLoading: false,
  initialLoading: true,
  error: null,
  countersLoading: {},
  eventsCountersBySportId: {},
  eventsCountersLoading: {}
};

const slice = createSlice({
  name: SLICES_NAMES.ASIAN_SPORTS,
  initialState,
  reducers: {
    fetchAsianSports: (state, _: PayloadAction<IGetSportsPayload>) => {
      state.sportsLoading = true;
    },
    successFetchAsianSports: (state, { payload }: PayloadAction<IAsianSport[]>) => {
      state.sports = payload;
      state.sportsLoading = false;
      state.initialLoading = false;
    },
    failureFetchAsianSports: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.sportsLoading = false;
      state.initialLoading = false;
      state.error = payload;
    },
    fetchAsianCounter: (
      state,
      {
        payload
      }: PayloadAction<{ sportId: string; requestPayload: IGetSportCounterPayload; navigate?: NavigateFunction }>
    ) => {
      if (payload.requestPayload.withLoader) {
        state.countersLoading[payload.sportId] = true;
      }
    },
    successFetchAsianCounter: (state, { payload }: PayloadAction<{ counter: ISportCounter; sportId: string }>) => {
      state.countersLoading[payload.sportId] = false;

      const stringifiedCounter = JSON.stringify(payload.counter);

      if (state.stringifiedCountersBySportId !== stringifiedCounter) {
        state.countersBySportId[payload.sportId] = payload.counter;
        state.stringifiedCountersBySportId = stringifiedCounter;
      }
    },
    failureFetchAsianCounter: (
      state,
      { payload }: PayloadAction<{ error: TFailureActionPayload; sportId: string }>
    ) => {
      state.countersLoading[payload.sportId] = false;
      state.error = payload.error;
    },
    fetchEventsCounter: (state, { payload }: PayloadAction<{ sportId: string }>) => {
      state.eventsCountersLoading[payload.sportId] = true;
    },
    successFetchEventsCounter: (
      state,
      { payload }: PayloadAction<{ counter: ISportEventsCounter; sportId: string }>
    ) => {
      state.eventsCountersLoading[payload.sportId] = false;
      state.eventsCountersBySportId[payload.sportId] = payload.counter;
    },
    failureFetchEventsCounter: (
      state,
      { payload }: PayloadAction<{ error: TFailureActionPayload; sportId: string }>
    ) => {
      state.eventsCountersLoading[payload.sportId] = false;
      state.error = payload.error;
    }
  }
});

export const {
  fetchAsianSports,
  successFetchAsianSports,
  failureFetchAsianSports,
  fetchAsianCounter,
  successFetchAsianCounter,
  failureFetchAsianCounter,
  fetchEventsCounter,
  successFetchEventsCounter,
  failureFetchEventsCounter
} = slice.actions;

export default slice.reducer;
