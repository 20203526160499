import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { TODAY_CARD_PREFIX } from 'constants/app';
import { componentsBranding } from 'constants/branding';
import { getBetslipFilterTooltip } from 'constants/tooltip';
import { useEnabledView } from 'hooks/useEnabledView';
import { getConsolidateBets } from 'redux/modules/appConfigs/selectors';
import { getConsolidatedBets, getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { EBetslipFilters } from 'redux/modules/betslip/type';
import { getCurrentBetsByType, getCurrentBetsLengthBySideType } from 'redux/modules/currentBets/selectors';
import { getMultiMarketEventId } from 'redux/modules/multiMarket/selectors';
import { CookieNames } from 'types';
import { BetTypes, MatchTypes } from 'types/bets';

import ConsolidateBets from '../ConsolidateBets';
import OpenedBetsMatchedBySide from '../OpenedBetsMatchedBySide/OpenedBetsMatchedBySide';
import OpenedBetsMatchedSorted from '../OpenedBetsMatchedSorted/OpenedBetsMatchedSorted';
import OpenedBetsTabEmpty from '../OpenedBetsTabEmpty/OpenedBetsTabEmpty';

import styles from './OpenedBetsMatched.module.scss';

const filters = [
  {
    label: 'betslip.openBets.filter.allEvents',
    appliedLabel: 'betslip.openBets.filter.currentEvent',
    secondaryLabel: 'betslip.openBets.filter.allMarkets',
    secondaryAppliedLabel: 'betslip.openBets.filter.currentMarket',
    id: EBetslipFilters.EVENT,
    icon: 'fa2 fa2-filter'
  },
  {
    label: 'betslip.openBets.sorting.default',
    appliedLabel: 'betslip.openBets.sorting.time',
    id: EBetslipFilters.SORTING,
    icon: 'av-icon av-icon-normal-sorting'
  }
];

const OpenedBetsMatched = () => {
  const { t } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies([
    CookieNames.BETSLIP_FILTER_MARKET,
    CookieNames.BETSLIP_FILTER_SORTING,
    CookieNames.BETSLIP_FILTER_EVENT
  ]);
  const { eventId = '', marketId: urlMarketId = '' } = useParams();
  const marketId = urlMarketId.toString().replace(TODAY_CARD_PREFIX, '');

  const { isMultiMarketView } = useEnabledView();

  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const matchedBets = useSelector(getCurrentBetsByType({ type: MatchTypes.MATCHED, isGameType: isGameBetSlip }));
  const isConsolidateBetsEnabled = useSelector(getConsolidateBets);
  const consolidateBets = useSelector(getConsolidatedBets);
  const multiMarketEventId = useSelector(getMultiMarketEventId);
  const filteredMatchedBackCurrentBets = useSelector(
    getCurrentBetsLengthBySideType(
      BetTypes.BACK,
      MatchTypes.MATCHED,
      isMultiMarketView ? undefined : cookies.FILTER_BETS_BY_MARKET_ID,
      cookies.FILTER_BETS_BY_EVENT_ID,
      consolidateBets,
      isGameBetSlip
    )
  );
  const filteredMatchedLayCurrentBets = useSelector(
    getCurrentBetsLengthBySideType(
      BetTypes.LAY,
      MatchTypes.MATCHED,
      isMultiMarketView ? undefined : cookies.FILTER_BETS_BY_MARKET_ID,
      cookies.FILTER_BETS_BY_EVENT_ID,
      consolidateBets,
      isGameBetSlip
    )
  );

  const displayConsolidateBets = !isGameBetSlip && isConsolidateBetsEnabled;

  useEffect(() => {
    const curEventId = isMultiMarketView ? multiMarketEventId : eventId;

    if (cookies.FILTER_BETS_BY_EVENT_ID !== undefined && curEventId && cookies.FILTER_BETS_BY_EVENT_ID !== curEventId) {
      setCookie(CookieNames.BETSLIP_FILTER_EVENT, curEventId, { path: '/' });
    }

    if (cookies.FILTER_BETS_BY_MARKET_ID !== undefined && marketId && cookies.FILTER_BETS_BY_MARKET_ID !== marketId) {
      setCookie(CookieNames.BETSLIP_FILTER_MARKET, marketId, { path: '/' });
    }

    if (!curEventId && cookies.FILTER_BETS_BY_EVENT_ID) {
      setCookie(CookieNames.BETSLIP_FILTER_EVENT, '', { path: '/' });
    }

    if (!marketId && cookies.FILTER_BETS_BY_MARKET_ID) {
      setCookie(CookieNames.BETSLIP_FILTER_MARKET, '', { path: '/' });
    }
  }, [
    eventId,
    marketId,
    cookies.FILTER_BETS_BY_MARKET_ID,
    cookies.FILTER_BETS_BY_EVENT_ID,
    isMultiMarketView,
    multiMarketEventId
  ]);

  if (!matchedBets.length) {
    return <OpenedBetsTabEmpty type="matched" />;
  }

  const handleClick = (type: EBetslipFilters) => {
    const cookieValue = cookies[CookieNames[`BETSLIP_FILTER_${type}`]];
    const prevValue = type === EBetslipFilters.SORTING ? cookieValue === 'true' : cookieValue;

    if (type === EBetslipFilters.SORTING) {
      setCookie(CookieNames.BETSLIP_FILTER_SORTING, !prevValue, { path: '/' });
    } else {
      if (prevValue !== undefined) {
        if (type === EBetslipFilters.EVENT) {
          removeCookie(CookieNames.BETSLIP_FILTER_EVENT, { path: '/' });
        } else {
          removeCookie(CookieNames.BETSLIP_FILTER_MARKET, { path: '/' });
        }
      } else {
        if (type === EBetslipFilters.EVENT) {
          setCookie(CookieNames.BETSLIP_FILTER_EVENT, isMultiMarketView ? multiMarketEventId : eventId, { path: '/' });
        } else {
          setCookie(CookieNames.BETSLIP_FILTER_MARKET, marketId, { path: '/' });
        }
      }
    }
  };

  return (
    <>
      {!isGameBetSlip && (
        <div
          className={classNames(styles.filterAndSorting, {
            [styles.filterAndSorting__margin]: !displayConsolidateBets
          })}
        >
          {filters.map(({ label, appliedLabel, id, icon, secondaryLabel, secondaryAppliedLabel }) => {
            let filterId = id;

            if (!isMultiMarketView && marketId && id === EBetslipFilters.EVENT) {
              filterId = EBetslipFilters.MARKET;
            }

            const cookieValue = cookies[CookieNames[`BETSLIP_FILTER_${filterId}`]];
            const isActive = filterId === EBetslipFilters.SORTING ? cookieValue === 'true' : cookieValue !== undefined;
            let displayedLabel = label;

            if (id === EBetslipFilters.EVENT && secondaryLabel && secondaryAppliedLabel) {
              if (marketId && !isMultiMarketView) {
                if (isActive) {
                  displayedLabel = secondaryAppliedLabel;
                } else {
                  displayedLabel = secondaryLabel;
                }
              } else if (isActive) {
                displayedLabel = appliedLabel;
              }
            } else {
              if (isActive) {
                displayedLabel = appliedLabel;
              }
            }

            return (
              <button
                key={id}
                onClick={() => handleClick(filterId)}
                data-tooltip-id="tooltip"
                data-tooltip-html={unescape(t(getBetslipFilterTooltip(filterId, isActive)))}
                className={classNames(styles.filter, componentsBranding.TERTIARY_BTN, {
                  [styles.filter__applied]: isActive,
                  [componentsBranding.ACTIVE]: isActive
                })}
              >
                <i
                  className={classNames(isActive ? 'biab_custom-icon-close' : icon, styles.filter__icon, {
                    [styles.filter__icon__applied]: isActive
                  })}
                />
                <span
                  className={classNames(styles.filter__text, {
                    [styles.filter__text__applied]: isActive
                  })}
                >
                  {t(displayedLabel)}
                </span>
              </button>
            );
          })}
        </div>
      )}
      {displayConsolidateBets && <ConsolidateBets />}
      {filteredMatchedBackCurrentBets > 0 || filteredMatchedLayCurrentBets > 0 ? (
        <>
          {cookies.SORTED_BETS === 'true' ? (
            <OpenedBetsMatchedSorted />
          ) : (
            <>
              <OpenedBetsMatchedBySide side={BetTypes.BACK} isMultiMarketView={isMultiMarketView} />
              <OpenedBetsMatchedBySide side={BetTypes.LAY} isMultiMarketView={isMultiMarketView} />
            </>
          )}
        </>
      ) : (
        <OpenedBetsTabEmpty type="matched" />
      )}
    </>
  );
};

export default OpenedBetsMatched;
