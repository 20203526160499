import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import EventHeaderHandler from 'components/EventHeaderHandler';
import MobileAllMarketsLink from 'components/MobileAllMarketsLink';
import PopularMarket from 'components/PopularMarket';
import MiddleSectionPageSkeleton from 'components/Skeleton/MiddleSectionPageSkeleton';
import { SPORT_BASE_URL } from 'constants/locations';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useElementSize from 'hooks/useElementSize';
import { getAppDevice, getIsShowMarketOdd } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchGetEvents } from 'redux/modules/competitions';
import { getEventData } from 'redux/modules/competitions/selectors';
import { getIsMarketInPlayByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { fetchPopularMarkets } from 'redux/modules/popularMarket';
import { LiveWidgetHeaderType, PageBlocks, PlacementPage } from 'types';
import { IMarket, TMarketSportInfo } from 'types/markets';
import { getIsEventWidgetEnabled } from 'utils/fancy';
import { getParticipantsBatsmenFancyMarkets, splitMarketNameIntoParticipants } from 'utils/market';

import FancyMarketDesktopInfo from './components/FancyMarketDesktopInfo';
import FancyMarketItem from './components/FancyMarketItem';
import FancyMarketMobileInfo from './components/FancyMarketMobileInfo';
import FancyMarketSection from './components/FancyMarketSection';
import MobileFancyNavigation from './components/MobileFancyNavigation';
import ViewAllLines from './components/ViewAllLines';

import styles from './styles.module.scss';

interface FancyMarketProps {
  /**
   * Is multi market view enabled or not
   */
  isMultiMarketView?: boolean;
  /**
   * Market ID
   */
  marketId: string;
  /**
   * Is data loading?
   */
  loading?: boolean;
  /**
   * Info about event
   */
  eventInfo: TMarketSportInfo;
  /**
   * Event start date
   */
  startDate?: number;
  /**
   * Markets for one of the fancy data types
   */
  inningsFancy: IMarket[];
  /**
   * Markets for one of the fancy data types
   */
  inningsFours: IMarket[];
  /**
   * Markets for one of the fancy data types
   */
  inningsSixes: IMarket[];
  /**
   * Markets for one of the fancy data types
   */
  totalInningsRuns: IMarket[];
  /**
   * Markets for one of the fancy data types
   */
  batsmen: IMarket[];
  /**
   * Markets for one of the fancy data types
   */
  otherLineMarkets: IMarket[];
  /**
   * Markets for one of the fancy data types
   */
  fallOfWicket: IMarket[];
  /**
   * Section occupies all available space instead of half width (ex.: for fancy and multi market view)
   */
  sectionFullWidth?: boolean;
  /**
   * Is twoColumnView turned on on multi market page?
   */
  twoColumnView?: boolean;
  /**
   * Whether lay column is disabled
   */
  isLayColumnDisabled: boolean;
  /**
   * Whether market depth is enabled
   */
  isDepthEnabled: boolean;
  /**
   * Hide rules and collapse icons from collapse row in section
   */
  hideRulesAndCollapseAbility?: boolean;
  /**
   * Page block for betting
   */
  pageBlock: PageBlocks;
}

const FancyMarket = ({
  isMultiMarketView = false,
  marketId,
  inningsFancy,
  inningsFours,
  inningsSixes,
  totalInningsRuns,
  batsmen,
  otherLineMarkets,
  fallOfWicket,
  eventInfo,
  startDate,
  loading = false,
  sectionFullWidth = false,
  twoColumnView = false,
  isLayColumnDisabled,
  isDepthEnabled,
  hideRulesAndCollapseAbility = false,
  pageBlock
}: FancyMarketProps) => {
  const dispatch = useDispatch();
  const { eventId = '', sportId = '' } = useParams();
  const { t } = useTranslation();

  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();

  const eventData = useSelector(getEventData);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const device = useSelector(getAppDevice);
  const isShowMarketOdds = useSelector(getIsShowMarketOdd);
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketId));

  const [showAllInningsFancy, setShowAllInningsFancy] = useState<boolean>(false);

  const isDesktop = device === Devices.DESKTOP;
  const displayedInningsFancy = showAllInningsFancy ? inningsFancy : inningsFancy.slice(0, 3);
  const { leftBatsmen, rightBatsmen } = useMemo(() => getParticipantsBatsmenFancyMarkets(batsmen), [batsmen]);
  const [leftTeamName, rightTeamName] = splitMarketNameIntoParticipants(eventInfo.name);
  const sections = [
    { title: `${leftTeamName} ${t('fancy.markets.batsmen')}`, markets: leftBatsmen, defaultDisplayedCount: 2 },
    { title: `${rightTeamName} ${t('fancy.markets.batsmen')}`, markets: rightBatsmen, defaultDisplayedCount: 2 },
    { title: t('fancy.markets.fallOfWicket'), markets: fallOfWicket },
    { title: t('fancy.markets.inningsFours'), markets: inningsFours },
    { title: t('fancy.markets.inningsSixes'), markets: inningsSixes },
    { title: t('fancy.markets.otherLineMarkets'), markets: otherLineMarkets, defaultDisplayedCount: 2 },
    { title: t('fancy.markets.totalInningsRuns'), markets: totalInningsRuns }
  ];
  const showMobileInfo = !isDesktop && isLoggedIn;
  const showSections =
    !!leftBatsmen.length ||
    !!rightBatsmen.length ||
    !!fallOfWicket.length ||
    !!inningsFours.length ||
    !!inningsSixes.length ||
    !!otherLineMarkets.length ||
    !!totalInningsRuns.length;

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (!isDesktop && eventId) {
      dispatch(fetchGetEvents({ eventId }));
    }
  }, [isDesktop, dispatch, eventId]);

  useEffect(() => {
    if (isShowMarketOdds) {
      dispatch(fetchPopularMarkets());
    }
  }, [isLoggedIn, dispatch, isShowMarketOdds]);

  return (
    <div ref={componentRef} className="skeleton_page_wrapper">
      {loading ? (
        <MiddleSectionPageSkeleton itemsCount={12} withDelay={150} />
      ) : (
        <div className="biab_fancy-page">
          {isDesktop && !isMultiMarketView && <PopularMarket page={PlacementPage.FANCY_PAGE_ODDS_BANNER} />}
          <div className="biab_market">
            {!isMultiMarketView && (
              <>
                <EventHeaderHandler
                  title={eventInfo.name}
                  eventId={eventId}
                  startDate={startDate ?? 0}
                  type={LiveWidgetHeaderType.MARKET}
                  sportId={eventInfo?.eventTypeId ?? ''}
                  inPlay={isInPlay}
                  videoStreamingEnabled={getIsEventWidgetEnabled({
                    key: 'videoStreamingEnabled',
                    inningsFancy,
                    inningsFours,
                    inningsSixes,
                    totalInningsRuns,
                    fallOfWicket,
                    batsmen,
                    otherLineMarkets
                  })}
                  matchStatEnabled={getIsEventWidgetEnabled({
                    key: 'matchStatEnabled',
                    inningsFancy,
                    inningsFours,
                    inningsSixes,
                    totalInningsRuns,
                    fallOfWicket,
                    batsmen,
                    otherLineMarkets
                  })}
                />
                {!isDesktop && !!eventData.length && <MobileFancyNavigation />}
                {isDesktop ? (
                  <FancyMarketDesktopInfo isInPlay={isInPlay} marketId={marketId} />
                ) : showMobileInfo ? (
                  <FancyMarketMobileInfo />
                ) : null}
              </>
            )}
            <div className={classNames({ 'biab_fancy-swap': swapColorsFancyMarketsOnCricket })}>
              {displayedInningsFancy.map((market, index, markets) => (
                <FancyMarketItem
                  isMultiMarketView={isMultiMarketView}
                  key={market.marketId}
                  name={market.marketName}
                  hasMarketDepth
                  market={market}
                  isLastItem={index === markets.length - 1}
                  isTopItem
                  isFirstItem={index === 0}
                  twoColumnView={twoColumnView}
                  isLayColumnDisabled={isLayColumnDisabled}
                  isDepthEnabled={isDepthEnabled}
                  showCellTooltip={index === 0}
                  pageBlock={pageBlock === PageBlocks.FANCY_VIEW ? PageBlocks.FANCY_TOP_VIEW : pageBlock}
                />
              ))}
              {inningsFancy.length > 3 && (
                <ViewAllLines showAllLines={showAllInningsFancy} setShowAllLines={setShowAllInningsFancy} />
              )}
              {showSections && (
                <div className={styles.fancyMarket__sections}>
                  {sections.map(({ title, markets, defaultDisplayedCount }) => {
                    if (!markets.length) {
                      return null;
                    }

                    return (
                      <FancyMarketSection
                        isMultiMarketView={isMultiMarketView}
                        key={title}
                        title={title}
                        markets={markets}
                        startDate={startDate}
                        defaultDisplayedCount={defaultDisplayedCount}
                        sectionFullWidth={sectionFullWidth}
                        twoColumnView={twoColumnView}
                        isLayColumnDisabled={isLayColumnDisabled}
                        isDepthEnabled={isDepthEnabled}
                        hideRulesAndCollapseAbility={hideRulesAndCollapseAbility}
                        pageBlock={pageBlock}
                      />
                    );
                  })}
                </div>
              )}
            </div>
            {!isDesktop && !isMultiMarketView && (
              <MobileAllMarketsLink
                to={{ pathname: `${SPORT_BASE_URL}/${sportId}/event/${eventId}`, search: 'showEvent=true' }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FancyMarket;
