import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { difference, entries, isUndefined } from 'lodash';

import { SLICES_NAMES } from 'constants/app';
import {
  AutoCashOut,
  CashOutMarket,
  CashOutQuote,
  CashOutStatuses,
  ProfitPayload,
  TSetCashOut
} from 'redux/modules/cashOut/type';
import { TFailureActionPayload } from 'types';
import { TFetchMarketRulesResponse } from 'types/markets';
import { getContentCashOutPage } from 'utils/cashOut';

import {
  AsianViewBetSlipCashOutState,
  FetchCashOutMarketsError,
  FetchCashOutMarketsPayload,
  FetchCashOutMarketsResponse,
  FetchCashOutQuotesPayload,
  FetchCashOutQuotesResponse,
  FetchCashOutStatusResponse
} from './type';

const initialState: AsianViewBetSlipCashOutState = {
  quotesLoading: false,
  statusLoading: {},
  isFirstQuotesLoaded: false,
  isFirstMarketsLoaded: false,
  quotesError: null,
  marketsError: null,
  marketsData: {
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: null,
    totalElements: 0,
    totalPages: 0,
    content: []
  },
  quotes: {},
  stringifiedQuotes: '',
  possibleProfits: {},
  cashedValues: {},
  autoCashOutMarkets: {},
  stringifiedAutoCashOutMarkets: '',
  placedIds: {},
  successFullCreatedAutoCashOuts: {},
  successFullDeletedAutoCashOuts: {},
  pendingCashOuts: {},
  cashOutStatuses: {},
  paginationLoading: false,
  settingTabsStates: {},
  rules: {},
  rulesLoading: false,
  rulesError: null,
  cashOutLoading: false,
  autoCashOutLoading: false,
  marketsLoading: false,
  autoCashOutMarketsError: null,
  cashOutMarketLoading: false,
  cashOutMarketError: null,
  cashOutStatusLoading: false,
  cashOutStatusError: null,
  createAutoCashOutError: null,
  deleteAutoCashOutError: null
};

const slice = createSlice({
  name: SLICES_NAMES.ASIAN_VIEW_BET_SLIP_CASH_OUT,
  initialState,
  reducers: {
    fetchAsianBetSlipCashOutQuotes: (state, { payload }: PayloadAction<FetchCashOutQuotesPayload>) => {
      state.quotesLoading = true;

      if (payload.firstLoading) {
        state.isFirstQuotesLoaded = false;
      }
    },
    successFetchAsianBetSlipCashOutQuotes: (state, { payload }: PayloadAction<FetchCashOutQuotesResponse>) => {
      state.quotesLoading = false;

      const stringifiedPayloadQuotes = payload.isWebSocketResponse ? '' : JSON.stringify(payload.quotes);

      if (
        payload.isWebSocketResponse ||
        !state.stringifiedQuotes ||
        state.stringifiedQuotes !== stringifiedPayloadQuotes
      ) {
        if (!payload.isWebSocketResponse) {
          state.stringifiedQuotes = stringifiedPayloadQuotes;
        }

        const statusMarketIds = Object.keys(state.cashOutStatuses);
        const newQuotes = payload.quotes.reduce<Record<string, CashOutQuote>>(
          (acc, item) => ({
            ...acc,
            [item.marketId]: item
          }),
          {}
        );
        const oldQuoteMarketIds = difference(statusMarketIds, Object.keys(newQuotes));
        const oldQuotes = oldQuoteMarketIds.reduce<Record<string, CashOutQuote>>(
          (acc: Record<string, CashOutQuote>, marketId: string) =>
            state.quotes[marketId]
              ? {
                  ...acc,
                  [marketId]: state.quotes[marketId]
                }
              : acc,
          {}
        );

        state.quotes = { ...oldQuotes, ...newQuotes };
      }

      if (payload.firstLoaded) {
        state.isFirstQuotesLoaded = true;
      }
    },
    failureFetchAsianBetSlipCashOutQuotes: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.quotesLoading = false;
      state.quotesError = payload;
    },
    fetchAsianBetSlipCashOutMarkets: (state, { payload }: PayloadAction<FetchCashOutMarketsPayload>) => {
      if (payload.resetPrev) {
        state.marketsData = initialState.marketsData;
        state.isFirstMarketsLoaded = false;
      }

      if (payload.withLoader) {
        state.paginationLoading = true;
      }

      if (payload.resetSettingsTabs) {
        state.settingTabsStates = initialState.settingTabsStates;
      }

      state.marketsLoading = true;
    },
    successFetchAsianBetSlipCashOutMarkets: (state, { payload }: PayloadAction<FetchCashOutMarketsResponse>) => {
      const isMarketsNotUpdated = JSON.stringify(current(state.marketsData)) === JSON.stringify(payload.markets);

      state.marketsLoading = false;

      if (!state.isFirstMarketsLoaded) {
        state.isFirstMarketsLoaded = true;
      }

      if (payload.withLoader) {
        state.paginationLoading = false;
      }

      if (!isMarketsNotUpdated) {
        const content = getContentCashOutPage(state.marketsData, payload);

        state.marketsData = {
          ...payload.markets,
          content
        };
      }
    },
    failureFetchAsianBetSlipCashOutMarkets: (state, { payload }: PayloadAction<FetchCashOutMarketsError>) => {
      state.marketsError = payload.error;
      state.marketsLoading = false;

      if (payload.withLoader) {
        state.paginationLoading = false;
      }
    },
    setAsianBetSlipCashOutPossibleProfit: (state, { payload }: PayloadAction<ProfitPayload>) => {
      if (!state.possibleProfits[payload.marketId]) {
        state.possibleProfits[payload.marketId] = {} as ProfitPayload;
      }

      state.possibleProfits[payload.marketId].marketId = payload.marketId;

      if (!isUndefined(payload.value)) {
        state.possibleProfits[payload.marketId].value = payload.value;
      }

      if (!isUndefined(payload.error)) {
        state.possibleProfits[payload.marketId].error = payload.error;
      }

      if (!isUndefined(payload.hideError)) {
        state.possibleProfits[payload.marketId].hideError = payload.hideError;
      }
    },
    asianBetSlipCreateAutoCashOut: (_, __: PayloadAction<{ marketId: string; profit: number }>) => {},
    successAsianBetSlipCreateAutoCashOut: (state, { payload }: PayloadAction<AutoCashOut>) => {
      state.successFullCreatedAutoCashOuts[payload.marketId] = true;
    },
    failureAsianBetSlipCreateAutoCashOut: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.createAutoCashOutError = payload;
    },
    fetchAsianBetSlipAutoCashOutMarkets: (_, __: PayloadAction<string[]>) => {},
    successFetchAsianBetSlipAutoCashOutMarkets: (
      state,
      {
        payload
      }: PayloadAction<{ autoCashOuts: AutoCashOut[]; isWebSocketResponse: boolean; stringifiedAutoCashOuts?: string }>
    ) => {
      state.autoCashOutMarkets = payload.autoCashOuts.reduce(
        (acc, autoCashOut) => ({
          ...acc,
          [autoCashOut.marketId]: autoCashOut
        }),
        {}
      );
      if (!payload.isWebSocketResponse && payload.stringifiedAutoCashOuts) {
        state.stringifiedAutoCashOutMarkets = payload.stringifiedAutoCashOuts;
      }
    },
    failureFetchAsianBetSlipAutoCashOutMarkets: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.autoCashOutMarketsError = payload;
    },
    deleteAsianBetSlipAutoCashOut: (_, __: PayloadAction<{ marketId: string; id: number }>) => {},
    successDeleteAsianBetSlipAutoCashOut: (state, { payload }: PayloadAction<string>) => {
      delete state.possibleProfits[payload];
      state.successFullDeletedAutoCashOuts[payload] = true;
    },
    failureDeleteAsianBetSlipAutoCashOut: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.deleteAutoCashOutError = payload;
    },
    asianBetSlipAutoCashOutAutoDelete: (state, { payload }: PayloadAction<string>) => {
      delete state.possibleProfits[payload];
    },
    asianBetSlipRemoveSuccessFullDeletedAutoCashOut: (state, { payload }: PayloadAction<string>) => {
      delete state.successFullDeletedAutoCashOuts[payload];
      delete state.autoCashOutMarkets[payload];
    },
    asianBetSlipRemoveSuccessFullCreatedAutoCashOut: (state, { payload }: PayloadAction<string>) => {
      delete state.successFullCreatedAutoCashOuts[payload];

      entries(state.autoCashOutMarkets).forEach(([marketId, value]) => {
        if (marketId === payload && value) {
          state.cashedValues[marketId] = value.profit;
        } else {
          delete state.cashedValues[marketId];
        }
      });
    },
    fetchAsianBetSlipCashOutMarket: (state, _: PayloadAction<TSetCashOut>) => {
      state.cashOutMarketLoading = true;
    },
    successFetchAsianBetSlipCashOutMarket: (state, { payload }: PayloadAction<CashOutMarket>) => {
      state.cashOutMarketLoading = false;
      state.placedIds[payload.marketId] = payload.offers[0];
      state.pendingCashOuts[payload.marketId] = payload.id;
      state.cashOutStatuses[payload.marketId] = CashOutStatuses.SUCCESS;
    },
    asianBetSlipCashOutRemovePendingCashOut: (
      state,
      {
        payload
      }: PayloadAction<{
        status: string;
        marketId: string;
      }>
    ) => {
      delete state.pendingCashOuts[payload.marketId];
      state.cashOutStatuses[payload.marketId] = payload.status;
    },
    failureFetchAsianBetSlipCashOutMarket: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.cashOutMarketLoading = false;
      state.cashOutMarketError = payload;
    },
    asianBetSlipCashOutCleanPlacedId: (state, { payload }: PayloadAction<string>) => {
      delete state.placedIds[payload];
    },
    fetchAsianViewBetSlipCashOutStatus: (
      state,
      { payload }: PayloadAction<{ statusId: number; onSuccessStatus?: () => void }>
    ) => {
      state.cashOutStatusLoading = true;
      state.statusLoading[payload.statusId] = true;
    },
    asianBetSlipCashOutCleanStatus: (state, { payload }: PayloadAction<string>) => {
      delete state.cashOutStatuses[payload];
    },
    successFetchAsianViewBetSlipCashOutStatus: (state, { payload }: PayloadAction<FetchCashOutStatusResponse>) => {
      state.cashOutStatusLoading = false;
      state.statusLoading[payload.statusId] = false;

      if (payload.status !== CashOutStatuses.PENDING) {
        state.cashOutStatuses[payload.marketId] = payload.status;
        delete state.pendingCashOuts[payload.marketId];
      }
    },
    setAsianViewBetSlipCashOutStatus: (
      state,
      { payload }: PayloadAction<{ marketId: string; status: CashOutStatuses }>
    ) => {
      state.cashOutStatuses[payload.marketId] = payload.status;
    },
    failureFetchAsianViewBetSlipCashOutStatus: (
      state,
      { payload }: PayloadAction<{ error: TFailureActionPayload; statusId: number }>
    ) => {
      state.statusLoading[payload.statusId] = false;
      state.cashOutStatusLoading = false;
      state.cashOutStatusError = payload.error;
    },
    asianBetSlipCashOutOpenSettingsTab: (state, { payload }: PayloadAction<string>) => {
      state.settingTabsStates[payload] = true;
      entries(state.autoCashOutMarkets).forEach(([marketId, value]) => {
        if (marketId === payload && value) {
          state.cashedValues[marketId] = value.profit;
        } else {
          delete state.cashedValues[marketId];
        }
      });
    },
    asianBetSlipCashOutCloseSettingsTab: (state, { payload }: PayloadAction<string>) => {
      delete state.settingTabsStates[payload];
    },
    fetchAsianViewBetSlipCashOutMarketRules: (state, _: PayloadAction<string>) => {
      state.rulesLoading = true;
    },
    successFetchAsianViewBetSlipCashOutMarketRules: (state, { payload }: PayloadAction<TFetchMarketRulesResponse>) => {
      state.rulesLoading = false;
      state.rules[payload.marketId] = payload.rules;
    },
    failureFetchAsianViewBetSlipCashOutMarketRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.rulesLoading = false;
      state.rulesError = payload;
    },
    removeAsianViewBetSlipAutoCashOutMarket: (state, { payload }: PayloadAction<string>) => {
      delete state.autoCashOutMarkets[payload];
    },
    resetAsianViewBetSlipCashOut: () => initialState
  }
});

export const {
  fetchAsianBetSlipCashOutQuotes,
  asianBetSlipCashOutCleanPlacedId,
  asianBetSlipCashOutCloseSettingsTab,
  asianBetSlipAutoCashOutAutoDelete,
  asianBetSlipCashOutRemovePendingCashOut,
  asianBetSlipCashOutOpenSettingsTab,
  removeAsianViewBetSlipAutoCashOutMarket,
  asianBetSlipRemoveSuccessFullDeletedAutoCashOut,
  asianBetSlipRemoveSuccessFullCreatedAutoCashOut,
  failureFetchAsianBetSlipAutoCashOutMarkets,
  failureFetchAsianBetSlipCashOutMarket,
  deleteAsianBetSlipAutoCashOut,
  failureFetchAsianViewBetSlipCashOutMarketRules,
  asianBetSlipCashOutCleanStatus,
  failureFetchAsianViewBetSlipCashOutStatus,
  fetchAsianBetSlipAutoCashOutMarkets,
  fetchAsianBetSlipCashOutMarket,
  fetchAsianBetSlipCashOutMarkets,
  fetchAsianViewBetSlipCashOutMarketRules,
  setAsianBetSlipCashOutPossibleProfit,
  fetchAsianViewBetSlipCashOutStatus,
  resetAsianViewBetSlipCashOut,
  successAsianBetSlipCreateAutoCashOut,
  successDeleteAsianBetSlipAutoCashOut,
  successFetchAsianBetSlipAutoCashOutMarkets,
  successFetchAsianBetSlipCashOutMarket,
  successFetchAsianViewBetSlipCashOutMarketRules,
  successFetchAsianBetSlipCashOutMarkets,
  successFetchAsianBetSlipCashOutQuotes,
  successFetchAsianViewBetSlipCashOutStatus,
  failureFetchAsianBetSlipCashOutQuotes,
  failureFetchAsianBetSlipCashOutMarkets,
  asianBetSlipCreateAutoCashOut,
  failureDeleteAsianBetSlipAutoCashOut,
  failureAsianBetSlipCreateAutoCashOut,
  setAsianViewBetSlipCashOutStatus
} = slice.actions;

export default slice.reducer;
