import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { TEventDetails } from '../event/type';

import { failureFetchListViewMarkets, fetchListViewMarkets, successFetchListViewMarkets } from './index';

function* geListViewMarketsWorker(action: ReturnType<typeof fetchListViewMarkets>) {
  try {
    const response: TEventDetails = yield call(api.market.getListViewMarkets, action.payload);
    yield put(successFetchListViewMarkets(response.marketCatalogues));
  } catch (error: any) {
    yield put(failureFetchListViewMarkets(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchListViewMarkets.type, geListViewMarketsWorker)]);
}
