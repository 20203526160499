export const TYPE_TO_URL: { [key: string]: string } = {
  EVENT_TYPE: 'sport',
  COUNTRY: 'country',
  COMPETITION: 'competition',
  EVENT: 'event',
  GROUP: 'group',
  MARKET: 'market',
  RACE: 'race',
  FANCY_MARKET: 'fancyEvent'
};
