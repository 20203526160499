import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { asianViewBetslipBranding as branding } from 'constants/branding';

import AsianViewBetSlipSettings from '../AsianViewBetSlipSettings';

import styles from './styles.module.scss';

const Settings = () => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={classNames(styles.settings, branding.ACTIONS_CONTAINER_BORDER)}>
      <button
        className={classNames(styles.settings__toggle, branding.ACTIONS_CONTAINER_BORDER, {
          [styles.settings__toggle__expanded]: isExpanded
        })}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {t('asianView.labels.betslip.settings')}
      </button>
      {isExpanded && (
        <div className={styles.settings__content}>
          <AsianViewBetSlipSettings />
        </div>
      )}
    </div>
  );
};

export default Settings;
