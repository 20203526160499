import {
  DEFAULT_MAX_LINE_PRICE,
  DEFAULT_MAX_PRICE,
  DEFAULT_MIN_LINE_PRICE,
  DEFAULT_MIN_PRICE,
  DEFAULT_STEP_LINE_PRICE,
  DEFAULT_STEP_PRICE
} from 'constants/placement';
import { BetTypes } from 'types/bets';
import { BettingType, TMarketLineRangeInfo } from 'types/markets';
import { isLineBettingType } from 'utils/betslip';

const PRECISION = 2;
const DEFAULT_STEP = 1;

export const isValidValue = (value: number | string, step: number, floor: number) => {
  const multiply = Math.pow(10, PRECISION);

  if (+value < floor) {
    return false;
  }

  value = +value - floor;
  value = parseFloat(value.toFixed(PRECISION));

  return step >= 1
    ? (value * multiply) % (step * multiply) === 0
    : (value / step).toFixed(PRECISION) === Math.floor(+(value / step).toFixed(PRECISION)).toFixed(PRECISION);
};

export const adjustValue = (value: number | string, step: number, floor: number, betType: BetTypes = BetTypes.BACK) => {
  let adjustedValue;

  if (+value <= 0) {
    return floor;
  }

  value = +value - floor;
  value = parseFloat(value.toFixed(PRECISION));

  const share = value / step;

  if (share.toFixed(PRECISION) === Math.floor(+share.toFixed(PRECISION)).toFixed(PRECISION)) {
    if (betType === BetTypes.LAY) {
      adjustedValue = step >= 1 ? parseFloat((Math.floor(share) * step + floor).toFixed(PRECISION)) : value - floor;
    } else {
      adjustedValue =
        step >= 1 ? parseFloat((Math.floor(share) * step + floor + step).toFixed(PRECISION)) : value + floor;
    }
  } else {
    adjustedValue = parseFloat(
      (Math.floor(share) * step + floor + (betType === BetTypes.BACK ? step : 0)).toFixed(PRECISION)
    );
  }

  if (Math.floor(step) !== step) {
    adjustedValue = adjustedValue.toFixed(PRECISION);
  }

  return adjustedValue;
};

export const roundRangeParams = (value: number, step = DEFAULT_STEP) => {
  return Math.ceil(value / step) * step;
};

export const formatFloatNumber = (value: number) => {
  return parseFloat((Math.round(value * Math.pow(10, PRECISION)) / Math.pow(10, PRECISION)).toFixed(PRECISION));
};

export const isValidValueInRange = (value: number, min: number, step = DEFAULT_STEP) => {
  return value >= min && round((value - min) / step, 10) % 1 === 0;
};

/**
 * Check if number of runs is valid for lineRangeInfo. If no return valid value
 */
export const adjustLineMarketValue = ({
  value,
  min,
  max,
  step,
  betType,
  isRound
}: {
  value: number;
  min: number;
  max: number;
  step: number;
  betType: BetTypes;
  isRound: boolean;
}) => {
  let adjustedValue;

  const { min: minRounded, max: maxRounded } = getLineRangeParams({
    lineRangeInfo: {
      minUnitValue: min,
      maxUnitValue: max,
      interval: step,
      marketUnit: ''
    },
    bettingType: BettingType.LINE
  });

  if (isRound) {
    value = roundRangeParams(value, step);
    min = minRounded;
    max = maxRounded;
  }

  if (value < min) {
    adjustedValue = min;
  } else if (value > max) {
    adjustedValue = max;
  } else {
    if (!isValidValueInRange(value, min, step)) {
      adjustedValue = adjustRangeValue(value, min, step, betType);
    } else {
      adjustedValue = value;
    }
  }

  return adjustedValue;
};

export const adjustRangeValue = (value: number, min: number, step = DEFAULT_STEP, betType = BetTypes.BACK) => {
  return round(Math[betType === BetTypes.BACK ? 'ceil' : 'floor']((value - min) / step) * step + min, PRECISION);
};

export const round = (value = 0, decimals = PRECISION) =>
  Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);

export const getDecimal = (num: number) => (num > 0 ? num % 1 : -num % 1);

export const getLineRangeParams = ({
  lineRangeInfo,
  bettingType = ''
}: {
  lineRangeInfo?: TMarketLineRangeInfo | null;
  bettingType?: string;
}) => {
  const isLineMarket = isLineBettingType(bettingType);
  const lineStep = lineRangeInfo?.interval || DEFAULT_STEP_LINE_PRICE;
  const minLineValue = formatMinLineValue(
    (lineRangeInfo?.minUnitValue === 0 ? lineRangeInfo?.interval : lineRangeInfo?.minUnitValue) ||
      DEFAULT_MIN_LINE_PRICE
  );
  const maxLineValue = lineRangeInfo?.maxUnitValue || DEFAULT_MAX_LINE_PRICE;

  const min = isLineMarket ? minLineValue : DEFAULT_MIN_PRICE;
  const max = isLineMarket ? maxLineValue : DEFAULT_MAX_PRICE;
  const interval = isLineMarket ? lineStep : DEFAULT_STEP_PRICE;

  return { min, max, interval, isLineMarket };
};

export const roundPriceForLineBet = ({ value, interval }: { value: number; interval: number | null }) =>
  interval === 1 ? Math.round(value) : value;

/**
 * Format min values that are < 0 as positive value
 * @param value
 */
export const formatMinLineValue = (value?: number) => {
  return Math.abs(value ?? 0);
};
