import { ReactNode } from 'react';
import classNames from 'classnames';

import Tooltip from 'components/Tooltip';
import useDevice from 'hooks/useDevice';
import useImperativeTooltip from 'hooks/useImperativeTooltip';

import styles from './styles.module.scss';

interface PromotableTooltipProps {
  children?: ReactNode;
  tooltipKey: string;
  translationOptions?: Record<string, string>;
  className?: string;
  isIconLarge?: boolean;
  isIconFirst?: boolean;
}

const PromotableTooltip = ({
  children,
  tooltipKey,
  translationOptions,
  className,
  isIconLarge,
  isIconFirst
}: PromotableTooltipProps) => {
  const { isMobile } = useDevice();
  const { tooltipRef, tooltipHtml, tooltipAnchorId, tooltipMouseEvents, isEnabled, isShowIcon } = useImperativeTooltip(
    tooltipKey,
    { translationOptions }
  );

  return (
    <div
      className={classNames(styles.promotableTooltip, className, { 'cursor-help': isEnabled })}
      {...tooltipMouseEvents}
    >
      {children && <div id={isShowIcon ? undefined : tooltipAnchorId}>{children}</div>}
      {isEnabled && (
        <>
          {isShowIcon && (
            <i
              id={tooltipAnchorId}
              className={classNames('fa2 fa2-info-circle biab_promoted-tooltip', styles.promotableTooltip__icon, {
                [styles.promotableTooltip__icon__large]: isIconLarge || isMobile,
                [styles.promotableTooltip__icon__reversed]: isIconFirst
              })}
            >
              <span className="path1 biab_promoted-tooltip-bg biab_mobile-tooltip-bg biab_tooltip-bg" />
              <span className="path2" />
            </i>
          )}
          <Tooltip ref={tooltipRef} anchorSelect={`#${tooltipAnchorId}`} html={tooltipHtml} imperativeModeOnly />
        </>
      )}
    </div>
  );
};

export default PromotableTooltip;
