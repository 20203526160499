import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { mobileComponents, mobileIcons } from 'constants/branding';
import { removeInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';

import styles from './MobilePlacementClosedMessage.module.scss';

type MobilePlacemenClosedMessageProps = {
  bet: TInlineSelectedBet;
};

const MobilePlacementClosedMessage = ({ bet }: MobilePlacemenClosedMessageProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(removeInlineSelectedBet(bet));
  };

  useEffect(() => {
    return () => {
      removeInlineSelectedBet(bet);
    };
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={classNames(styles.message, mobileComponents.NOTIFICATION, mobileComponents.INFO_SECONDARY)}>
        <i className={classNames('biab_custom-icon-info-circle', mobileIcons.INFO_ICON)} />
        <div className={styles.message__text}>
          <p>
            <span>{bet.eventName}</span> - {bet.marketName}
          </p>
          <p>{t('betslip.labels.removedDueToMarketClosure')}</p>
        </div>
        <button onClick={onClose} className={styles.message__closeBtn}>
          <i className="biab_custom-icon-close" />
        </button>
      </div>
    </div>
  );
};

export default MobilePlacementClosedMessage;
