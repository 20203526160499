import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { IMarketRules } from 'types/markets';

import {
  failureFetchEventMarketRules,
  failureGetEventDetails,
  failureGetEventGroup,
  fetchEventDetails,
  fetchEventGroup,
  fetchEventMarketRules,
  successFetchEventMarketRules,
  successGetEventDetails,
  successGetEventGroup
} from './index';
import { TEventDetails, TEventGroup } from './type';

function* getEventDetailsWorker(action: ReturnType<typeof fetchEventDetails>) {
  try {
    const response: TEventDetails = yield call(api.event.getEventDetails, action.payload);
    yield put(successGetEventDetails(response));
  } catch (error: any) {
    yield put(failureGetEventDetails(error));
  }
}

function* getEventGroupWorker(action: ReturnType<typeof fetchEventGroup>) {
  try {
    const response: TEventGroup = yield call(api.event.getEventGroup, action.payload);
    yield put(successGetEventGroup(response));
  } catch (error: any) {
    yield put(failureGetEventGroup(error));
  }
}

function* getEventMarketRulesWorker(action: ReturnType<typeof fetchEventMarketRules>) {
  try {
    const response: IMarketRules = yield call(api.app.marketRules, action.payload);
    yield put(successFetchEventMarketRules({ marketId: action.payload, rules: response }));
  } catch (error: any) {
    yield put(failureFetchEventMarketRules(error.data));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchEventDetails.type, getEventDetailsWorker),
    takeLatest(fetchEventGroup.type, getEventGroupWorker),
    takeLatest(fetchEventMarketRules.type, getEventMarketRulesWorker)
  ]);
}
