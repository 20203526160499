import { toNumber } from 'lodash';

import useBetLabels from 'hooks/useBetLabels';
import { BetTypes, TPrice, TSize } from 'types/bets';

import BetLabelsTpl from '../BetLabelsTpl';

type VariableHandicapLabelProps = {
  price: TPrice;
  size: TSize;
  betType: BetTypes;
  currency?: string;
  selectionName?: string;
};

const VariableHandicapLabel = ({ price, size, betType, currency, selectionName }: VariableHandicapLabelProps) => {
  const { ifCardNOrFurther, ifNotAsFarAsCardN } = useBetLabels();

  const handicap = selectionName?.replace(/\D+/g, '') ?? null;
  const handicapValue = handicap ? Math.abs(toNumber(handicap || 0)) : '';

  const odds = toNumber(price || 0);
  const stake = toNumber(size || 0);
  const coefficient = betType === BetTypes.LAY ? -1 : 1;

  const profitLossW = price && size ? (odds - 1) * stake * coefficient : null;
  const profitLossL = price && size ? -stake * coefficient : null;

  return (
    <BetLabelsTpl
      betType={betType}
      labelW={ifCardNOrFurther(handicapValue)}
      labelL={ifNotAsFarAsCardN(handicapValue)}
      profitLossW={profitLossW}
      profitLossL={profitLossL}
      currency={currency}
    />
  );
};

export default VariableHandicapLabel;
