import { ChangeEvent, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { componentsBranding } from 'constants/branding';
import { quickBetsTableHeader } from 'constants/quickBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { useQuickBets } from 'hooks/useQuickBets';
import { getCurrency, getIsExchangeGamesEnabled, getIsExchangeSportsEnabled } from 'redux/modules/appConfigs/selectors';
import { removeUpdateSettingsError, setSettings } from 'redux/modules/user';
import {
  getIsQuickStakesEnabled,
  getIsUpdateSettingsError,
  getIsUserAccountSettings
} from 'redux/modules/user/selectors';

import DesktopSettingsActions from '../DesktopSettingsActions/DesktopSettingsActions';
import DesktopSettingsError from '../DesktopSettingsError/DesktopSettingsError';
import DesktopSettingsToggleableHeader from '../DesktopSettingsToggleableHeader/DesktopSettingsToggleableHeader';

import styles from './DesktopSettingsQuickBets.module.scss';

const DesktopSettingsQuickBets = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isQuickBetsEnabled = useSelector(getIsQuickStakesEnabled);
  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const { gamesMaxBetSize, gamesMinBetSize, gamesStep, step, minBetSize, maxBetSize } = useSelector(getCurrency);
  const exchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const exchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isUpdateSettingsError = useSelector(getIsUpdateSettingsError);

  const {
    tabs,
    activeTab,
    setActiveTab,
    editMode,
    setEditMode,
    formState,
    handleSetInputValue,
    handleSaveQuickBets,
    handleResetQuickBets,
    handleCancelQuickBets
  } = useQuickBets({ isDesktopSettings: true });

  const [isQuickBetsEnabledState, setIsQuickBetsEnabledState] = useState(isQuickBetsEnabled);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const isWithErrors = formState[activeTab.value].errors.some(el => el.translationKey);
  const isTabsEnabled = exchangeSportsEnabled && exchangeGamesEnabled;
  const isSaveBtnDisabled = isWithErrors || !formState[activeTab.value].valuesChanged;

  const handleSwitchQuickBets = (value: boolean) => {
    setIsQuickBetsEnabledState(value);

    if (isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            quickStakesEnabled: value
          }
        })
      );
    }
  };

  const headerValuesMap = {
    'min-bet': minBetSize,
    'max-bet': maxBetSize,
    step: step,
    'games-min-bet': gamesMinBetSize,
    'games-max-bet': gamesMaxBetSize,
    'games-step': gamesStep
  };

  const handleSave = () => {
    setIsSaveLoading(true);
    handleSaveQuickBets({
      onFinish: () => {
        setIsSaveLoading(false);
      }
    });
  };

  return (
    <>
      <DesktopSettingsToggleableHeader
        isChecked={isQuickBetsEnabledState}
        onToggle={handleSwitchQuickBets}
        title={t('account.settings.quickBets.newTitle')}
      />
      {isTabsEnabled && (
        <div className={classNames(styles.quickBets__tabs, componentsBranding.TAB_CONTAINER)}>
          {Object.values(tabs).map(tab => {
            return (
              <button
                key={tab.value}
                className={classNames(styles.quickBets__tabs__tab, componentsBranding.TAB, {
                  [componentsBranding.ACTIVE]: tab.value === activeTab.value,
                  [styles.quickBets__tabs__tab__active]: tab.value === activeTab.value
                })}
                onClick={() => setActiveTab(tab)}
              >
                {t(tab.translationKey)}
              </button>
            );
          })}
        </div>
      )}
      <div className={styles.quickBets__bets__header}>
        {quickBetsTableHeader.map(({ id, labelKey }) => (
          <div key={id} className={styles.quickBets__bets__header__col}>
            <p className={styles.quickBets__bets__header__title}>{t(labelKey)}</p>
            <QuickBetsHeaderValue
              value={headerValuesMap[`${activeTab.value === tabs.games?.value ? 'games-' : ''}${id}`]}
            />
            {id !== 'step' && <hr className={styles.quickBets__bets__header__col__divider} />}
          </div>
        ))}
      </div>
      {[
        [1, 4],
        [2, 5],
        [3, 6]
      ].map((group, groupIndex) => {
        const leftButtonError = formState[activeTab.value].errors[group[0] - 1];
        const rightButtonError = formState[activeTab.value].errors[group[1] - 1];

        return (
          <Fragment key={groupIndex}>
            <div className={styles.quickBets__buttons}>
              {group.map((value, index) => {
                return (
                  <div key={index} className={styles.quickBets__button__wrapper}>
                    <p className={styles.quickBets__button__label}>
                      {t(`account.settings.quickBets.columns.button${value}`)}
                    </p>
                    <QuickBetsInput
                      name={(value - 1).toString()}
                      className={classNames(styles.quickBets__button__input, componentsBranding.FORM_INPUT, {
                        [styles.quickBets__button__input__error]:
                          formState[activeTab.value].errors[value - 1]?.translationKey,
                        [componentsBranding.ERROR]: formState[activeTab.value].errors[value - 1]?.translationKey
                      })}
                      disabled={!editMode[activeTab.value]}
                      value={formState[activeTab.value].values[value - 1] ?? ''}
                      onChange={event => handleSetInputValue(event, true)}
                    />
                  </div>
                );
              })}
            </div>
            {leftButtonError?.translationKey && (
              <DesktopSettingsError
                error={
                  leftButtonError.options
                    ? t(leftButtonError.translationKey, leftButtonError.options)
                    : t(leftButtonError.translationKey)
                }
              />
            )}
            {rightButtonError?.translationKey && (
              <DesktopSettingsError
                error={
                  rightButtonError.options
                    ? t(rightButtonError.translationKey, rightButtonError.options)
                    : t(rightButtonError.translationKey)
                }
              />
            )}
          </Fragment>
        );
      })}
      <DesktopSettingsActions
        isEditMode={editMode[activeTab.value]}
        onCancel={handleCancelQuickBets}
        onSave={handleSave}
        onEdit={() => setEditMode({ ...editMode, [activeTab.value]: true })}
        onReset={handleResetQuickBets}
        isSaveBtnDisabled={isSaveBtnDisabled}
        isSaveLoading={isSaveLoading}
      />
      {isUpdateSettingsError && (
        <DesktopSettingsError
          error={t('account.settings.changesNotSaved')}
          onClose={() => dispatch(removeUpdateSettingsError())}
          containerClassName={styles.quickBets__notSavedError}
        />
      )}
    </>
  );
};

export default DesktopSettingsQuickBets;

function QuickBetsHeaderValue({ value }: { value: number }) {
  const { noFormattedAmount: parsedValue } = useFormatCurrency(value || 0, '', {
    noCommas: true,
    isCheckIndian: true,
    noRounding: true,
    ignorePrecision: true
  });

  return <p className={styles.quickBets__bets__header__value}>{value ? parsedValue : ''}</p>;
}

function QuickBetsInput({
  name,
  className,
  disabled,
  onChange,
  value
}: {
  name: string;
  className: string;
  disabled: boolean;
  value: string | number;
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
}) {
  const { noFormattedAmount: parsedValue } = useFormatCurrency(value, '', {
    noCommas: true,
    isCheckIndian: true,
    noRounding: true,
    ignorePrecision: true
  });

  return (
    <input
      name={name}
      className={className}
      disabled={disabled}
      value={disabled ? parsedValue : value}
      onChange={onChange}
    />
  );
}
