import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { IS_SPECIAL_HEADER_ON_EVENT } from 'constants/app';
import { STARTING_IN_MIN, STARTING_MONTH, STARTING_ONE_MIN, STARTING_SOON, STARTING_TOMORROW } from 'constants/date';
import useConvertTimeToString from 'hooks/useConvertTimeToString';

interface HeaderTimeProps {
  date: number;
  isShortVariant?: boolean;
  sportId: string;
  inPlay: boolean;
  isShowAlways?: boolean;
}

const HeaderTime = ({ date, isShortVariant, sportId, inPlay, isShowAlways }: HeaderTimeProps) => {
  const { t } = useTranslation();

  const {
    hoursAndMinutes,
    monthDay: day,
    month,
    showMonthDay,
    showWeekDay,
    key,
    time: timeBeforeStart
  } = useConvertTimeToString({ startDate: date });

  const isHideStartingSoon = isShowAlways ? true : IS_SPECIAL_HEADER_ON_EVENT[sportId];

  if (key === STARTING_SOON) {
    return (
      <div
        className={classNames({
          'biab_cash-out-page-market-header-cell biab_start-time-cell biab_in-play': inPlay
        })}
      >
        {isHideStartingSoon && inPlay ? t('header.inPlay') : unescape(t(key))}
      </div>
    );
  }

  if (key === STARTING_ONE_MIN) {
    return <div>{unescape(t(STARTING_ONE_MIN, { min: timeBeforeStart }))}</div>;
  }

  if (key === STARTING_IN_MIN) {
    return <div>{unescape(t(STARTING_IN_MIN, { min: timeBeforeStart }))}</div>;
  }

  const getTime = () => {
    let time = '';

    if (key === STARTING_TOMORROW || showWeekDay) {
      time = t(key);
    }

    if (showMonthDay) {
      time = `${t(`${STARTING_MONTH}${month}`)} ${day}`;
    }

    time = unescape(time);

    return isShortVariant ? time : `${time} ${hoursAndMinutes}`;
  };

  return (
    <>
      {isShortVariant ? (
        <>
          <div>{hoursAndMinutes}</div>
          <div>{getTime()}</div>
        </>
      ) : (
        <>{getTime()}</>
      )}
    </>
  );
};

export default HeaderTime;
