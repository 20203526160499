import classNames from 'classnames';

import { betslipBranding } from 'constants/branding';

import styles from './styles.module.scss';

type TInlineBetNotificationProps = {
  message: string;
};
const InlineBetNotification = ({ message }: TInlineBetNotificationProps) => {
  return (
    <div className={styles.notification}>
      <div className={styles.notification__inner}>
        <i
          className={classNames('biab_custom-icon-info-circle', styles.notification__icon, betslipBranding.INFO_ICON)}
        />
        <p className={styles.notification__text}>{message}</p>
      </div>
    </div>
  );
};

export default InlineBetNotification;
