import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { asianViewNavigation as branding } from 'constants/branding';
import useElementSize from 'hooks/useElementSize';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getAVBalance } from 'redux/modules/user/selectors';

import styles from './styles.module.scss';

const TOP_AND_BOTTOM_PADDINGS = 6;

const AsianBalance = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const balance = useSelector(getAVBalance);

  const { noFormattedAmount: userBalance } = useFormatCurrency(balance, '', {
    noRounding: true,
    isCheckIndian: true
  });
  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.ASIAN_BALANCE, height: height + TOP_AND_BOTTOM_PADDINGS }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.ASIAN_BALANCE, height: 0 }));
    };
  }, []);

  return (
    <div className={classNames(styles.main, branding.NAVIGATION_HEADER)} ref={componentRef}>
      <div className={classNames(styles.content, branding.BALANCE_BLOCK)}>
        <p>{t('asianView.labels.balance')}</p>
        <p>{userBalance}</p>
      </div>
    </div>
  );
};

export default AsianBalance;
