import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { ASIAN_VIEW_LEAGUES_FILTER } from 'constants/asianView';
import {
  TAsianViewLeaguesFilterData,
  TAsianViewLeaguesFilterState,
  TFetchAsianViewLeaguesFilterPayload
} from 'redux/modules/asianViewLeaguesFilter/type';
import { TFailureActionPayload } from 'types';

const initialState: TAsianViewLeaguesFilterState = {
  currentParams: '',
  leagues: {},
  error: null,
  loading: false,
  isLeaguesLoaded: false,
  isOpened: false
};

const slice = createSlice({
  name: SLICES_NAMES.ASIAN_VIEW,
  initialState,
  reducers: {
    setCurrentParams: (state, action: PayloadAction<string>) => {
      state.currentParams = action.payload;
    },
    fetchLeagues: (state, _: PayloadAction<TFetchAsianViewLeaguesFilterPayload>) => {
      state.loading = true;
    },
    successGetLeagues: (state, action: PayloadAction<TAsianViewLeaguesFilterData[]>) => {
      const leagues: Record<string, TAsianViewLeaguesFilterData> = {};
      const fetchLeaguesIds = action.payload.map(({ id }) => String(id));
      const savedLeaguesToStore = JSON.parse(localStorage.getItem(ASIAN_VIEW_LEAGUES_FILTER) ?? '{}') ?? {};
      const savedLeaguesIds = savedLeaguesToStore?.[state.currentParams]?.ids ?? [];
      const isSavedLeaguesOutdated =
        savedLeaguesIds.length && savedLeaguesIds.every((id: string) => !fetchLeaguesIds.includes(String(id)));

      action.payload.forEach(league => {
        const isLeagueSelected =
          !isSavedLeaguesOutdated && savedLeaguesIds.length ? savedLeaguesIds.includes(league.id) : true;
        leagues[league.id] = { ...league, isSelected: isLeagueSelected, isSelectedInit: isLeagueSelected };
      });

      if (isSavedLeaguesOutdated) {
        const { [state.currentParams]: curSavedLeagues, ...restSavedLeagues } = savedLeaguesToStore;
        localStorage.setItem(ASIAN_VIEW_LEAGUES_FILTER, JSON.stringify(restSavedLeagues));
      }

      if (!state.isLeaguesLoaded) {
        state.isLeaguesLoaded = true;
      }

      state.leagues = leagues;
      state.loading = false;
    },
    failureGetLeagues: (state, action: PayloadAction<TFailureActionPayload>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setSelectedLeague: (state, action: PayloadAction<TAsianViewLeaguesFilterData>) => {
      state.leagues[action.payload.id] = action.payload;
    },
    setSelectedAllLeagues: (state, action: PayloadAction<boolean>) => {
      Object.keys(state.leagues).forEach(leagueId => {
        state.leagues[leagueId].isSelected = action.payload;
      });
    },
    saveSelectedLeagues: state => {
      Object.keys(state.leagues).forEach(leagueId => {
        state.leagues[leagueId].isSelectedInit = state.leagues[leagueId].isSelected;
      });
    },
    setIsLeaguesLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLeaguesLoaded = action.payload;
    },
    setIsLeaguesOpened: (state, action: PayloadAction<boolean>) => {
      state.isOpened = action.payload;
    }
  }
});

export const {
  fetchLeagues,
  successGetLeagues,
  failureGetLeagues,
  setSelectedLeague,
  setSelectedAllLeagues,
  saveSelectedLeagues,
  setCurrentParams,
  setIsLeaguesLoaded,
  setIsLeaguesOpened
} = slice.actions;

export default slice.reducer;
