import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { concat, without } from 'lodash';

import { IconsConfig } from 'constants/iconsConfig';
import { getIsSportIconsEnabled } from 'redux/modules/appConfigs/selectors';
import { setCollapsedSports } from 'redux/modules/search';
import { getCollapsedSports, getResultsBySportId } from 'redux/modules/search/selectors';

import styles from './styles.module.scss';

interface SportHeaderProps {
  isCollapsed: boolean;
  eventTypeId: string;
  sportName: string;
  lastItemRef?: (node: HTMLDivElement) => void;
}

function SportHeader({ eventTypeId, isCollapsed, sportName, lastItemRef }: SportHeaderProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const numberOfResults = useSelector(getResultsBySportId(eventTypeId));
  const collapsedSports = useSelector(getCollapsedSports);
  const isSportIconsEnabled = useSelector(getIsSportIconsEnabled);

  const handleOnCollapseClick = () => {
    dispatch(
      setCollapsedSports({
        pathname: location.pathname,
        sportIds: isCollapsed
          ? without(collapsedSports.sportIds, eventTypeId)
          : concat(collapsedSports.sportIds, eventTypeId)
      })
    );
  };

  return (
    <div
      className={classNames('biab_search-header', styles.searchSportHeader)}
      ref={lastItemRef}
      data-last={!!lastItemRef}
    >
      <div>
        {isSportIconsEnabled && (
          <i className={classNames('biab_br-sport-icon', IconsConfig.SPORT_ICONS[eventTypeId])} />
        )}
        <span className={classNames('biab_search-sport-name', styles.searchSportName)}>{sportName}</span>
        <span className={classNames('biab_results-found', styles.searchResultsFound)}>
          {t('search.resultsInfo', { N: numberOfResults })}
        </span>
      </div>
      <i
        onClick={handleOnCollapseClick}
        className={classNames(
          `fa2 fa2-arrow-${!isCollapsed ? 'up' : 'down'} biab_collapse-icon`,
          styles.searchCollapseIcon
        )}
      />
    </div>
  );
}

export default SportHeader;
