import { useDispatch } from 'react-redux';

import { updateInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { Actions, ButtonActionTypes } from 'types/inlinePlacement';
import { getBetIdentityData, getInlinePlacedOfferStatuses } from 'utils/inlinePlacement';

import InlineBetButton from '../InlineBetButton';

type TInlineBetEditProps = {
  bet: TInlineSelectedBet;
};
const InlineBetEdit = ({ bet }: TInlineBetEditProps) => {
  const dispatch = useDispatch();

  const { isUnmatched } = getInlinePlacedOfferStatuses(bet.placedBet);

  const betIdData = getBetIdentityData(bet);

  const onEditBtnClick = () => {
    dispatch(updateInlineSelectedBet({ ...betIdData, action: isUnmatched ? Actions.EDIT : Actions.SELECT }));
  };

  return <InlineBetButton bet={bet} buttonType={ButtonActionTypes.EDIT} onClickCallback={onEditBtnClick} />;
};

export default InlineBetEdit;
