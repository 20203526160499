import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useWindowSize } from 'usehooks-ts';

import DesktopSettingsDefaultStake from 'components/HeaderNavigation/components/DesktopSettingsDefaultStake/DesktopSettingsDefaultStake';
import DesktopSettingsPreferences from 'components/HeaderNavigation/components/DesktopSettingsPreferences/DesktopSettingsPreferences';
import DesktopSettingsQuickBets from 'components/HeaderNavigation/components/DesktopSettingsQuickBets/DesktopSettingsQuickBets';
import { header as branding, subHeader } from 'constants/branding';
import useOnClickOutside from 'hooks/useOnClickOutside';
import {
  getDesktopSettingsConfirmBetsBeforePlace,
  getDesktopSettingsDefStake,
  getDesktopSettingsFillOrKill,
  getDesktopSettingsInlineBetting,
  getDesktopSettingsPlaceBetWithEnterKey,
  getDesktopSettingsQuickstakeBetslip,
  getDisplayIconsInSubHeader,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { getElementHeight } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { SettingsDisplayedContent } from 'types/navigation';

import styles from './styles.module.scss';

const CONTENT_COMPONENTS = {
  [SettingsDisplayedContent.PREFERENCES]: () => <DesktopSettingsPreferences />,
  [SettingsDisplayedContent.QUICK_BETS]: () => <DesktopSettingsQuickBets />,
  [SettingsDisplayedContent.DEFAULT_STAKE]: () => <DesktopSettingsDefaultStake />,
  [SettingsDisplayedContent.NO_ITEMS]: () => null
};

const DesktopNavigationSettings = () => {
  const { t } = useTranslation();

  const displayIconsInSubHeader = useSelector(getDisplayIconsInSubHeader);
  const fillOrKill = useSelector(getDesktopSettingsFillOrKill);
  const quickstakeBetslip = useSelector(getDesktopSettingsQuickstakeBetslip);
  const inlineBetting = useSelector(getDesktopSettingsInlineBetting);
  const defStake = useSelector(getDesktopSettingsDefStake);
  const placeBetWithEnterKey = useSelector(getDesktopSettingsPlaceBetWithEnterKey);
  const confirmBetsBeforePlace = useSelector(getDesktopSettingsConfirmBetsBeforePlace);
  const exchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const exchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isQuickStakesEnabled = quickstakeBetslip && (exchangeSportsEnabled || exchangeGamesEnabled);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const headerHeight = useSelector(getElementHeight(EElementNames.HEADER_HEIGHT));

  const [isOpen, setIsOpen] = useState(false);
  const [displayedContent, setDisplayedContent] = useState<SettingsDisplayedContent>(SettingsDisplayedContent.NO_ITEMS);

  const { height: windowHeight } = useWindowSize();

  const showPreferences =
    inlineBetting || (fillOrKill && !isPNCEnabled) || confirmBetsBeforePlace || placeBetWithEnterKey || isPNCEnabled;

  useEffect(() => {
    if (showPreferences) {
      setDisplayedContent(SettingsDisplayedContent.PREFERENCES);
    } else if (isQuickStakesEnabled) {
      setDisplayedContent(SettingsDisplayedContent.QUICK_BETS);
    } else if (defStake) {
      setDisplayedContent(SettingsDisplayedContent.DEFAULT_STAKE);
    }
  }, [fillOrKill, showPreferences, isQuickStakesEnabled, defStake]);

  const items = [
    {
      label: 'account.settings.preferences.title',
      value: SettingsDisplayedContent.PREFERENCES,
      isVisible: showPreferences
    },
    {
      label: 'account.settings.quickBets.newTitle',
      value: SettingsDisplayedContent.QUICK_BETS,
      isVisible: isQuickStakesEnabled
    },
    {
      label: 'account.settings.defaultStake.title',
      value: SettingsDisplayedContent.DEFAULT_STAKE,
      isVisible: defStake
    }
  ];

  const rootRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(rootRef, () => setIsOpen(false));

  return (
    <div ref={rootRef} className={classNames('biab_settings-wrapper', styles.settings, subHeader.SUBHEADER_WRAPPER)}>
      <button
        className={classNames('biab_navigation-link', styles.settings__title, subHeader.SUBHEADER_HOLDER, {
          biab_active: isOpen
        })}
        onClick={() => setIsOpen(prevState => !prevState)}
      >
        {displayIconsInSubHeader && <i className="biab_navigation-submenu-icon fa2 fa2-cog" aria-hidden="true" />}
        <span>{t('header.settings')}</span>
        <i className={classNames(`fa2 fa2-arrow-${isOpen ? 'up' : 'down'}`)} />
      </button>
      {isOpen && (
        <div className={styles.settings__window}>
          <div className={styles.settings__window__content}>
            {items.filter(({ isVisible }) => isVisible).length > 1 && (
              <aside className={styles.settings__window__menu}>
                {items.map(({ label, value, isVisible }) => {
                  if (!isVisible) {
                    return null;
                  }

                  return (
                    <span
                      key={value}
                      onClick={() => setDisplayedContent(value)}
                      className={classNames(styles.settings__window__menu__item, branding.SETTINGS_MENU, {
                        biab_active: value === displayedContent,
                        [styles.settings__window__menu__item__active]: value === displayedContent,
                        [branding.SELECTED]: value === displayedContent
                      })}
                    >
                      {t(label)}
                    </span>
                  );
                })}
              </aside>
            )}
            <section
              className={classNames('biab_settings-content', styles.settings__window__displayedContent)}
              style={{ maxHeight: windowHeight - headerHeight - 4 }}
            >
              {CONTENT_COMPONENTS[displayedContent]()}
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopNavigationSettings;
