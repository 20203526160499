import { useEffect, useState } from 'react';

import { useAppThunkDispatch } from 'redux/configureStore';

interface UseAsyncEffectProps {
  asyncCallback: any;
  params: string | number | object;
}

const useAsyncEffect = ({ asyncCallback, params }: UseAsyncEffectProps) => {
  const [response, setResponse] = useState<ReturnType<typeof asyncCallback>>();
  const [loading, setLoading] = useState<boolean>(false);
  const appDispatch = useAppThunkDispatch();

  useEffect(() => {
    let didCancel = false;

    async function fetchData() {
      setLoading(true);

      const asyncResponse: ReturnType<typeof asyncCallback> = await appDispatch(asyncCallback(params)).unwrap();

      setLoading(false);
      if (!didCancel) {
        setResponse(asyncResponse);
      }
    }

    fetchData().catch(() => {
      setLoading(false);
    });

    return () => {
      didCancel = true;
    };
  }, []);

  return { loading, response };
};

export default useAsyncEffect;
