import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Skeleton from 'components/Skeleton';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';

import styles from './styles.module.scss';

const LoadingSkeleton = () => {
  const isMobileAsianView = useSelector(getIsMobileAsianView);

  return (
    <div className={classNames(styles.skeleton, { [styles.skeleton__mobile]: isMobileAsianView })}>
      <Skeleton className={styles.skeleton__league} />
      <Skeleton className={styles.skeleton__league} />
      <Skeleton className={styles.skeleton__leagueSm} />
    </div>
  );
};

export default LoadingSkeleton;
