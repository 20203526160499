import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

const getFav = (state: AppState) => state.favorites;
export const getFavorites = createSelector(getFav, favorites => favorites.favoritesList);
export const getFavoritesLength = createSelector(getFav, favorites => favorites.favoritesList.length);
export const getFavoriteById = (id?: string) =>
  createSelector(getFav, favorites => (id ? favorites.favoritesIds[id] : false));
export const getCollapsableSports = createSelector(getFav, favorites => favorites.collapsableSports);
