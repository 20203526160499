import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { AsianViewMarketLink, AsianViewTimeFilter } from 'constants/asianView';
import { ASIAN_BASE_URL, GAMES_BASE_URL, HOME_BASE_URL } from 'constants/locations';
import { GET_ACCOUNT_NAVIGATION_BUTTONS, NavigationButtons } from 'constants/myBets';
import {
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { SportId } from 'types';

import styles from './styles.module.scss';

interface AccountNavigationProps {
  selectedTab?: string;
}

const AccountNavigation = ({ selectedTab }: AccountNavigationProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);

  const accountNavigationButtons = GET_ACCOUNT_NAVIGATION_BUTTONS(isPNCEnabled);
  const isAsianViewPage = pathname.includes(ASIAN_BASE_URL);
  const isGamesPage = pathname.includes(GAMES_BASE_URL);

  const exchangePage = (isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || '';

  return (
    <div id="biab_account-navigation" className={classNames(styles.accountNavigation, 'biab_account-navigation')}>
      <ul className={styles.accountNavigation__list}>
        {accountNavigationButtons.map(({ name, url, translationKey, holderClassName = '', iconName }) => (
          <li
            key={name}
            className={classNames(holderClassName, {
              biab_current: selectedTab === name
            })}
          >
            {name === NavigationButtons.Back && (
              <div
                className={classNames(
                  styles.accountNavigation__list__item,
                  'biab_btn-back',
                  styles.accountNavigation__list__item__backBtn
                )}
              >
                {iconName && <i className={`fa ${iconName}`} />}
                {t(translationKey)}
                {isAsianViewEnabled && (
                  <NavLink
                    to={`${ASIAN_BASE_URL}/sport/${SportId.SOCCER}/timeFilter/${AsianViewTimeFilter.Today}/marketLink/${AsianViewMarketLink.HDP_AND_OU}`}
                    className={styles.accountNavigation__list__item__backBtn__link}
                  >
                    {t('header.navigation.asianView')}
                  </NavLink>
                )}
                {isExchangeSportsEnabled && (
                  <NavLink to={HOME_BASE_URL} className={styles.accountNavigation__list__item__backBtn__link}>
                    {t('header.navigation.sports')}
                  </NavLink>
                )}
                {isExchangeGamesEnabled && (
                  <NavLink to={GAMES_BASE_URL} className={styles.accountNavigation__list__item__backBtn__link}>
                    {t('header.navigation.games')}
                  </NavLink>
                )}
              </div>
            )}
            {name !== NavigationButtons.Back && (
              <NavLink to={`${exchangePage}${url}`} className={classNames(styles.accountNavigation__list__item, {})}>
                <>
                  {iconName && <i className={classNames('fa', iconName)} />}
                  {t(translationKey)}
                </>
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AccountNavigation;
