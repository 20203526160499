import styles from './styles.module.scss';

const BetGroupEmptyCells = () => {
  return (
    <>
      <span className={styles.emptyCell} />
      <span className={styles.emptyCell} />
    </>
  );
};

export default BetGroupEmptyCells;
