import { ComponentType, ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { WithCollapseClasses, WithCollapsePosition } from 'types/withCollapse';

import styles from './styles.module.scss';

interface WithCollapseProps {
  /**
   * Section title
   */
  title?: string | JSX.Element;

  /**
   * Can be toggled?
   */
  isCollapsable?: boolean;

  /**
   * Arrow display side
   */
  arrowPosition?: WithCollapsePosition;

  /**
   * BIAB className for container
   */
  className?: string;

  /**
   * Classes for customizing a component
   */
  collapseClasses?: WithCollapseClasses;

  /**
   * Should be opened by default?
   */
  defaultIsOpened: boolean;

  /**
   * Toggle handler
   * @param isOpened
   */
  onCollapse: (isOpened: boolean) => void;

  /**
   * Collapse icon for mobile
   */
  mobileCollapseIcon?: ReactNode;
}

/**
 * HOC that allow to attach collapsable area for given component
 * @param WrappedComponent component to be selectively hidden
 * @returns new component with collapsable area
 */
const withCollapse =
  <P extends object>(WrappedComponent: ComponentType<P>) =>
  ({
    title = '',
    arrowPosition = WithCollapsePosition.LEFT,
    isCollapsable = true,
    className = '',
    collapseClasses,
    defaultIsOpened,
    onCollapse,
    mobileCollapseIcon,
    ...props
  }: P & WithCollapseProps) => {
    const device = useSelector(getAppDevice);

    const [open, setOpen] = useState<boolean>(defaultIsOpened);

    const handleOnCollapseClick = () => {
      if (isCollapsable && device === Devices.DESKTOP) {
        onCollapse(!open);
        setOpen(!open);
      }
    };

    return (
      <div
        className={classNames('biab_group-markets biab_market-odds-wrapper', {
          [collapseClasses?.container ?? '']: collapseClasses?.container
        })}
      >
        <div
          className={classNames(styles.collapseToggle, className, {
            [styles.collapseToggle__right]: arrowPosition === WithCollapsePosition.RIGHT,
            [collapseClasses?.toggle ?? '']: collapseClasses?.toggle
          })}
          onClick={handleOnCollapseClick}
        >
          {mobileCollapseIcon && device === Devices.MOBILE ? mobileCollapseIcon : null}
          {isCollapsable && device === Devices.DESKTOP && (
            <i
              className={classNames('fa2 fa2-arrow-up', styles.collapseToggle__chevron, {
                [styles.collapseToggle__chevron__opened]: open,
                [collapseClasses?.arrow ?? '']: collapseClasses?.arrow
              })}
            />
          )}
          <span className={styles.title}>{title}</span>
        </div>
        {(open || device === Devices.MOBILE) && <WrappedComponent {...(props as P)} />}
      </div>
    );
  };

export default withCollapse;
