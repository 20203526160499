import classNames from 'classnames';

import { asianViewMiddleSection as branding } from 'constants/branding';

import styles from './styles.module.scss';

interface EmptyMarketProps {
  isSingleLine?: boolean;
  isFirst?: boolean;
}

const EmptyMarket = ({ isSingleLine = false, isFirst = false }: EmptyMarketProps) => {
  return (
    <div
      className={classNames('biab_asian-view-market-empty', styles.emptyMarket, branding.MAIN_CONTAINER_BORDER, {
        [styles.emptyMarket__singleLine]: isSingleLine,
        [styles.emptyMarket__first]: isFirst
      })}
    />
  );
};

export default EmptyMarket;
