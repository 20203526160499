import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { ASIAN_BETSLIP_PLACEHOLDERS } from 'constants/asianView';
import { asianViewBetslipBranding } from 'constants/branding';
import useMinMaxSize from 'hooks/useMinMaxSize';
import { getCurrency, getCurrencySteps } from 'redux/modules/appConfigs/selectors';
import { updateMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip';
import {
  AsianViewMobileBetActions,
  TAsianMobileSelectedBet,
  TAsianMobileUpdateData
} from 'redux/modules/asianViewMobileBetslip/type';
import { BetTypes } from 'types/bets';
import { EInputFieldTypes } from 'types/betslip';
import { getSizeStepValue } from 'utils/asianView';
import { round } from 'utils/liability';

import styles from './styles.module.scss';

export interface IMobileInputControl {
  type: EInputFieldTypes;
  bet: TAsianMobileSelectedBet;
  updateMobileBet: ({ size, profit, focusedField }: TAsianMobileUpdateData) => void;
}
const MobileInputControl = ({ bet, type, updateMobileBet }: IMobileInputControl) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currencySteps = useSelector(getCurrencySteps);
  const defaultCurrency = useSelector(getCurrency);

  const placeholder = ASIAN_BETSLIP_PLACEHOLDERS[type]?.[bet.betType] ?? '';
  const isSizeField = type === EInputFieldTypes.SIZE;
  const inputProp = isSizeField ? 'size' : 'profit';
  const inputValue = (isSizeField ? bet.size : bet.profit) ?? '';
  const inputValueInt = +(inputValue || 0);
  const { minBetSize: minSize, maxBetSize: maxSize } = defaultCurrency;
  const { minSizeFormatted, maxSizeFormatted } = useMinMaxSize({ marketId: bet.marketId });
  const { subStep, addStep } = getSizeStepValue({ currencySteps, sizeValue: inputValue, step: defaultCurrency.step });
  const isFilled = !isUndefined(inputValue) && inputValue !== '';
  const isConfirm = bet.action === AsianViewMobileBetActions.CONFIRM;
  const isBack = bet.betType === BetTypes.BACK;
  const isValidationError = !bet.isValid && isSizeField && bet.sizeValidationType;
  const isValidationLabel = bet.sizeValidationType === 'min' || bet.sizeValidationType === 'max';
  const isFocused = bet.focusedField === type;

  const subStepHandler = () =>
    updateMobileBet({
      [inputProp]: inputValueInt > subStep ? round(inputValueInt - subStep) : '',
      focusedField: type
    });

  const addStepHandler = () =>
    updateMobileBet({ [inputProp]: inputValueInt ? round(inputValueInt + addStep) : minSize, focusedField: type });

  const inputClickHandler = () => {
    if (bet.betslipId) {
      dispatch(updateMobileSelectedBet({ betslipId: bet.betslipId, data: { focusedField: type } }));
    }
  };

  return (
    <div
      className={classNames({
        [asianViewBetslipBranding.ERROR_INPUT]: isValidationError,
        [styles.errorWrap]: isValidationError
      })}
    >
      <div
        className={classNames(styles.inputControl, {
          [styles.inputControl__disabled]: isConfirm,
          [styles.inputControl__error]: isValidationError,
          [styles.inputControl__focused]: isFocused && !isConfirm
        })}
      >
        {!isConfirm && (
          <button
            type="button"
            className={styles.inputControl__btn}
            disabled={inputValueInt <= minSize}
            onClick={subStepHandler}
          >
            <i className={classNames('fa2 fa2-minus', styles.inputControl__icon)} aria-hidden="true" />
          </button>
        )}
        <span
          className={classNames(styles.inputControl__inputWrap, {
            [styles.inputControl__inputWrap__disabled]: isConfirm
          })}
        >
          <input
            className={classNames(styles.inputControl__input, {
              [styles.inputControl__input__filled]: isFilled,
              [styles.inputControl__input__readOnly__back]: isConfirm && isBack,
              [styles.inputControl__input__readOnly__lay]: isConfirm && !isBack,
              [styles.inputControl__input__focused]: isFocused && !isConfirm
            })}
            id={`${type}_${bet.identifier}`}
            type="text"
            placeholder=""
            value={inputValue || ''}
            readOnly
            onClick={inputClickHandler}
          />
          <label
            htmlFor={`${type}_${bet.identifier}`}
            className={classNames(styles.inputControl__label, {
              [styles.inputControl__label__focused]: isFocused && !isConfirm
            })}
          >
            {t(placeholder)}
          </label>
        </span>
        {!isConfirm && (
          <button
            type="button"
            className={styles.inputControl__btn}
            disabled={inputValueInt >= maxSize}
            onClick={addStepHandler}
          >
            <i className={classNames('fa2 fa2-plus', styles.inputControl__icon)} aria-hidden="true" />
          </button>
        )}
      </div>
      {isValidationError && isValidationLabel && (
        <span className={classNames(styles.errorInfo, asianViewBetslipBranding.ERROR_INPUT)}>
          <span>{t(`asianView.labels.betslip.validation.${bet.sizeValidationType}`)}</span>
          <span>{bet.sizeValidationType === 'min' ? minSizeFormatted : maxSizeFormatted}</span>
        </span>
      )}
    </div>
  );
};

export default MobileInputControl;
