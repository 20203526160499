import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import MarketRules from 'components/AsianViewPageModule/components/CompetitionTitle/MarketRules';
import Filters from 'components/AsianViewPageModule/components/Filters';
import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM,
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_POPULAR_LINK_COMPETITION_ID,
  ASIAN_POPULAR_LINK_SPORT_ID,
  ASIAN_SINGLE_VIEW_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  AsianViewMarketLink
} from 'constants/asianView';
import { asianViewIcons, asianViewMiddleSection as branding } from 'constants/branding';
import { IconsConfig } from 'constants/iconsConfig';
import { SEARCH_QUERY_LENGTH } from 'constants/search';
import { PARAMS_ASIAN_SEARCH_KEY } from 'constants/urlParams';
import { getIsSportIconsEnabled, getTranslation } from 'redux/modules/appConfigs/selectors';
import { getSportBySportId } from 'redux/modules/asianSportsNavigation/selectors';
import {
  getAsianViewCompetitionKeys,
  getAsianViewError,
  getAsianViewFirstMarketId,
  getAsianViewLoading,
  getAsianViewTotalElements,
  getIsAsianViewNotFoundView,
  getIsSearchResults
} from 'redux/modules/asianView/selectors';
import { SportId } from 'types';
import { getHeadingSportId } from 'utils/asianView';

import styles from './styles.module.scss';

const Heading = () => {
  const { t } = useTranslation();
  const { sportId = SportId.SOCCER, timeFilter, marketLink = '' } = useParams();
  const [searchParams] = useSearchParams();

  const isSportIconsEnabled = useSelector(getIsSportIconsEnabled);
  const totalElements = useSelector(getAsianViewTotalElements);
  const translation = useSelector(getTranslation);
  const firstMarketId = useSelector(getAsianViewFirstMarketId);
  const loading = useSelector(getAsianViewLoading);
  const isSearchResults = useSelector(getIsSearchResults);
  const competitionKeys = useSelector(getAsianViewCompetitionKeys);
  const isAsianViewNotFoundView = useSelector(getIsAsianViewNotFoundView);
  const isError = useSelector(getAsianViewError);

  const isOutRight = marketLink === AsianViewMarketLink.OUTRIGHT;
  const isTotalGoals = marketLink === AsianViewMarketLink.TOTAL_GOALS;
  const isOddEven = marketLink === AsianViewMarketLink.ODD_EVEN;
  const isHalfTimeFullTime = marketLink === AsianViewMarketLink.HALF_TIME_FULL_TIME;
  const query = searchParams.get(PARAMS_ASIAN_SEARCH_KEY) || '';
  const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);
  const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
  const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
  const isFavouriteView = !!(favouriteId && favouriteType && favouriteSportId);
  const isShowRulesIcon = isHalfTimeFullTime || isTotalGoals || isOddEven;
  const popularLinkCompetitionId = searchParams.get(ASIAN_POPULAR_LINK_COMPETITION_ID);
  const popularLinkSportId = searchParams.get(ASIAN_POPULAR_LINK_SPORT_ID);
  const isPopularLinkView = !!popularLinkCompetitionId && !!popularLinkSportId;
  const singleViewCompetitionId = searchParams.get(ASIAN_SINGLE_VIEW_COMPETITION_ID);
  const singleViewEventId = searchParams.get(ASIAN_SINGLE_VIEW_EVENT_ID);
  const singleViewSportId = searchParams.get(ASIAN_SINGLE_VIEW_SPORT_ID);
  const isSingleView = (!!singleViewCompetitionId || !!singleViewEventId) && (!!singleViewSportId || isOutRight);
  const outrightCompetitionId = searchParams.get(ASIAN_OUTRIGHT_COMPETITION_ID);
  const isSingleOutrightMarketView = !!outrightCompetitionId;
  const selectorSportId = getHeadingSportId({
    favouriteSportId,
    popularLinkSportId,
    isPopularLinkView,
    singleViewSportId,
    isSingleView,
    isFavouriteView
  });

  const sport = useSelector(getSportBySportId(selectorSportId || sportId || ''));

  if (isAsianViewNotFoundView) {
    return null;
  }

  const timeLabel = t(`asianView.labels.timeFilter.${timeFilter}`);
  const title = t(`asianView.labels.marketLinks.${marketLink}`);
  let displayTitle;

  if (isFavouriteView || isPopularLinkView || isSingleView || isSingleOutrightMarketView) {
    displayTitle = sport?.translations[translation];
  } else if (isOutRight || isTotalGoals) {
    displayTitle = t(`asianView.labels.marketLinks.${marketLink}`);
  } else if (isTotalGoals || isOddEven) {
    displayTitle = `${timeLabel} - ${title}`;
  } else {
    displayTitle = `${timeLabel} ${title ? ` - ${title}` : ''}`;
  }

  return (
    <div className={classNames('biab_asian-view-header', styles.heading, branding.HEADER)}>
      {query.length >= SEARCH_QUERY_LENGTH ? (
        <div className={styles.heading__searchContainer}>
          <p className={styles.search}>
            <i className="fa2 fa2-search" />
            <span> {t('asianView.labels.search')}</span>
          </p>
          {!loading && isSearchResults && (
            <p className={styles.results}>
              {totalElements && !isError
                ? t('asianView.labels.search.resultsFoundNumber', { number: totalElements })
                : t('asianView.labels.search.desktop.noResultsFound')}
            </p>
          )}
        </div>
      ) : (
        <div className={styles.heading__title}>
          {isSportIconsEnabled && (
            <i
              className={classNames(
                styles.heading__title__icon,
                'biab_br-sport-icon',
                IconsConfig.SPORT_ICONS[sportId],
                asianViewIcons.MIDDLE_SECTION_SPORT_ICON
              )}
            />
          )}
          <h2>{displayTitle}</h2>
          {isShowRulesIcon && <MarketRules marketId={firstMarketId} />}
        </div>
      )}
      {!(!loading && query && !competitionKeys.length) && !(query && loading) && <Filters />}
    </div>
  );
};

export default Heading;
