import { useSelector } from 'react-redux';

import { getIsCustomPopUpEnabled } from 'redux/modules/appConfigs/selectors';
import { TPopularLinks } from 'redux/modules/popular/type';
import { PopularEventType } from 'types';

import BasicPopularLink from '../BasicPopularLink';
import CustomPopularLink from '../CustomPopularLink';
import ModalPopularLink from '../ModalPopularLink';

interface PopularLinkProps {
  value: TPopularLinks;
}

const PopularLink = ({ value }: PopularLinkProps) => {
  const customPopUpEnabled = useSelector(getIsCustomPopUpEnabled);

  const { type } = value;

  if (type === undefined) {
    return <CustomPopularLink value={value} />;
  }

  if (type === PopularEventType.CUSTOM_POP_UP) {
    return <>{customPopUpEnabled && <ModalPopularLink value={value} />}</>;
  }

  return <BasicPopularLink value={value} />;
};

export default PopularLink;
