import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/Modal';
import { EXPOSURE_LIMIT_ERROR_CODE, EXPOSURE_LIMIT_ERROR_TITLE, LOSS_LIMIT_ERROR_TITLE } from 'constants/placement';
import useDevice from 'hooks/useDevice';
import { setBetslipRGErrorMessage } from 'redux/modules/asianViewBetslip';
import { getBetslipRGErrorMessage } from 'redux/modules/asianViewBetslip/selectors';
import { setMobileRGErrorMessage } from 'redux/modules/asianViewMobileBetslip';
import { getMobileRGErrorMessage } from 'redux/modules/asianViewMobileBetslip/selectors';

const AsianViewRGModalMessage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  const betslipRGErrorMessage = useSelector(getBetslipRGErrorMessage);
  const mobileRGErrorMessage = useSelector(getMobileRGErrorMessage);

  const rgErrorMessage = isMobile ? mobileRGErrorMessage : betslipRGErrorMessage;

  const title =
    rgErrorMessage?.id === EXPOSURE_LIMIT_ERROR_CODE ? t(EXPOSURE_LIMIT_ERROR_TITLE) : t(LOSS_LIMIT_ERROR_TITLE);

  const closeModalHandler = () => {
    if (isMobile) {
      dispatch(setMobileRGErrorMessage(null));
    } else {
      dispatch(setBetslipRGErrorMessage(null));
    }
  };

  if (!rgErrorMessage) {
    return null;
  }

  return (
    <Modal title={title} open={!!rgErrorMessage} onClose={closeModalHandler}>
      <div
        dangerouslySetInnerHTML={{
          __html: typeof rgErrorMessage === 'object' ? rgErrorMessage?.message ?? '' : ''
        }}
      />
    </Modal>
  );
};

export default AsianViewRGModalMessage;
