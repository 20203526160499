import { ChangeEvent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Loader, { CircleColors } from 'components/Loader';
import { TFA_ACTIVATION_TIMEOUT, TFA_CODE_LENGTH } from 'constants/app';
import { getTokenTypeCookieName } from 'redux/modules/appConfigs/selectors';
import { failureLoginUser, failureVerifyUser, loginUser, verifyUser } from 'redux/modules/auth';
import { getAuthIsVerified, getIsAuthError, getLoggedInLoading } from 'redux/modules/auth/selectors';
import { setLoginPopup } from 'redux/modules/popup';

import styles from './styles.module.scss';

interface TwoFactorAuthFormProps {
  username: string;
}

const TwoFactorAuthForm = ({ username }: TwoFactorAuthFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector(getLoggedInLoading);
  const isAuthError = useSelector(getIsAuthError);
  const isVerified = useSelector(getAuthIsVerified);
  const tokenTypeCookieName = useSelector(getTokenTypeCookieName);

  const [verificationCode, setVerificationCode] = useState('');
  const [timeOutError, setTimeOutError] = useState(false);
  const [validationError, setValidationError] = useState<boolean>(false);

  const [cookies, , removeCookie] = useCookies([tokenTypeCookieName]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const changedValue = event.target.value.trim().replace(/[^\d]+/g, '');

    if (validationError) {
      setValidationError(false);
    }

    if (!!changedValue || changedValue.length <= TFA_CODE_LENGTH) {
      setVerificationCode(changedValue);
    }
  };

  const onVerifyCode = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(verifyUser({ username, password: verificationCode }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeOutError(true);
      clearTimeout(timer);
    }, TFA_ACTIVATION_TIMEOUT);

    return () => {
      clearTimeout(timer);

      if (cookies[tokenTypeCookieName]) {
        removeCookie(tokenTypeCookieName, {
          path: '/'
        });
      }
    };
  }, []);

  useEffect(() => {
    if (isAuthError) {
      setValidationError(true);
    }

    return () => {
      dispatch(failureVerifyUser(undefined));
    };
  }, [isAuthError]);

  useEffect(() => {
    if (isVerified) {
      dispatch(setLoginPopup({ type: '' }));
      dispatch(loginUser());
    }

    return () => {
      dispatch(failureLoginUser(null));
    };
  }, [dispatch, isVerified, username]);

  if (loading) {
    return <Loader circleColor={CircleColors.DARK_GRAY} />;
  }

  return (
    <form onSubmit={onVerifyCode}>
      <div className={styles.auth__content}>
        {timeOutError ? (
          <div className={classNames('biab_error-msg', styles.auth__errMessage)}>
            {t('authentication.errors.verificationPeriodExpired')}
          </div>
        ) : (
          <>
            <h4 className={styles.auth__message}>{t('authentication.labels.enterAuthenticationCode')}</h4>
            <div>
              <input
                autoFocus
                className={classNames('biab_b-form-input', styles.auth__input, {
                  [styles.auth__input__error]: validationError
                })}
                maxLength={TFA_CODE_LENGTH}
                name="token"
                autoComplete="off"
                placeholder="XXXXXX"
                onChange={changeHandler}
                value={verificationCode}
              />
            </div>
            {validationError && (
              <div className={classNames('biab_error-msg', styles.auth__errMessage)}>
                {t('authentication.errors.invalidCode')}
              </div>
            )}
            <div>
              <button
                type="submit"
                className={classNames('biab_b-form-button', styles.auth__verify, {
                  biab_disabled: !verificationCode
                })}
                disabled={!verificationCode}
              >
                {t('authentication.btns.verify')}
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default TwoFactorAuthForm;
