import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { AVG_PRICE } from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';

import styles from './styles.module.scss';

interface MobileDetailsFieldProps {
  value?: string | number | null | boolean;
  name?: string;
  children?: ReactNode;
  capitalise?: boolean;
  classname?: string;
  isWithTooltip?: boolean;
  tooltipValue?: number | string | null;
  tooltipHTMLValue?: string;
  isSingleRow?: boolean;
  statementDetails?: boolean;
}

const MobileDetailsField = ({
  name,
  value,
  isSingleRow,
  statementDetails,
  children,
  isWithTooltip,
  tooltipValue,
  tooltipHTMLValue,
  capitalise
}: MobileDetailsFieldProps) => {
  const { t } = useTranslation();
  const { translationKey } = useTooltip(AVG_PRICE);

  return (
    <tr>
      {isSingleRow ? (
        <>
          {statementDetails ? (
            <>
              <td className={classNames(styles.fieldName, 'biab_field-name')}>{children}</td>
              <td />
            </>
          ) : (
            <td className={classNames(styles.fieldName, 'biab_field-name')} colSpan={2}>
              {children}
            </td>
          )}
        </>
      ) : (
        <>
          <td className={classNames(styles.fieldName, 'biab_field-name')}>{name}</td>
          <td className={classNames(styles.fieldValue, 'biab_field-value', { [styles.capitalise]: capitalise })}>
            {isWithTooltip && tooltipValue && tooltipValue !== '--' ? (
              <div className={styles.withTooltip}>
                {value || '--'}
                <i
                  onClick={e => e.preventDefault()}
                  data-tooltip-id={'tooltip'}
                  data-tooltip-html={tooltipHTMLValue || unescape(t(translationKey, { N: tooltipValue }))}
                  className={'fa2 fa2-info-circle tooltip-icon biab_odds-tooltip-info'}
                >
                  <span className="path1 biab_promoted-tooltip-bg biab_mobile-tooltip-bg biab_tooltip-bg" />
                  <span className="path2" />
                </i>
              </div>
            ) : (
              <>{value || '--'}</>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

export default MobileDetailsField;
