import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { last } from 'lodash';

import { SLICES_NAMES } from 'constants/app';
import { COMPETITION_KEY_DIVIDER } from 'constants/asianView';
import { SetAsianViewListPayload, TAsianViewEvent, TFetchAsianViewListPayload } from 'redux/modules/asianView/type';
import { TFailureActionPayload } from 'types';
import { getCompetitionIdFromKey } from 'utils/asianView';

import { AsianViewFavouriteResponse, AsianViewFavouritesState, AsianViewSaveRemoveFavouritePayload } from './type';

const getFavouritesIds = (favourites: AsianViewFavouriteResponse[]) => {
  return favourites.reduce((acc, { entryId }) => {
    return {
      ...acc,
      [entryId]: true
    };
  }, {});
};

const initialState: AsianViewFavouritesState = {
  favourites: [],
  loading: false,
  error: null,
  favouritesIds: {},
  isFavouritesLoaded: false,
  favouritesMobileEventsList: {
    content: {},
    competitionIds: [],
    resultsByCompetition: {},
    first: true,
    last: false,
    totalElements: 0,
    totalPages: 0,
    number: 0,
    sort: null,
    size: 0,
    numberOfElements: 0
  },
  favouritesMobileEventsLoading: false,
  favouritesMobileEventsError: null
};

const slice = createSlice({
  name: SLICES_NAMES.ASIAN_VIEW_FAVOURITES,
  initialState,
  reducers: {
    fetchAsianViewFavourites: state => {
      state.loading = true;
    },
    successFetchAsianViewFavourites: (state, action: PayloadAction<AsianViewFavouriteResponse[]>) => {
      state.favourites = action.payload;
      state.favouritesIds = getFavouritesIds(action.payload);
      state.loading = false;

      if (!state.isFavouritesLoaded) {
        state.isFavouritesLoaded = true;
      }
    },
    failureFetchAsianViewFavourites: (state, action: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = action.payload;
    },
    saveAsianViewFavourite: (_, __: PayloadAction<AsianViewSaveRemoveFavouritePayload>) => {},
    removeAsianViewFavourite: (_, __: PayloadAction<AsianViewSaveRemoveFavouritePayload>) => {},
    successSaveRemoveAsianViewFavourite: (state, action: PayloadAction<AsianViewFavouriteResponse[]>) => {
      state.favourites = action.payload;
      state.favouritesIds = getFavouritesIds(action.payload);
    },
    failureSaveRemoveAsianViewFavourite: (state, action: PayloadAction<TFailureActionPayload>) => {
      state.error = action.payload;
    },
    fetchFavouritesMobileEventsList: (state, { payload }: PayloadAction<Omit<TFetchAsianViewListPayload, 'id'>>) => {
      if (!payload.isInfiniteScrollEnabled && !!Object.keys(state.favouritesMobileEventsList.content).length) {
        state.favouritesMobileEventsList.content = {};
      }

      state.favouritesMobileEventsLoading = true;
    },
    successFetchFavouritesMobileEventsList: (state, action: PayloadAction<SetAsianViewListPayload>) => {
      const isFirstPage = action.payload.first;
      const content: Record<string, TAsianViewEvent[]> =
        (!isFirstPage && action.payload.isInfiniteScrollEnabled && { ...state.favouritesMobileEventsList.content }) ||
        {};
      const competitionIds: Array<string> =
        (!isFirstPage &&
          action.payload.isInfiniteScrollEnabled && [...state.favouritesMobileEventsList.competitionIds]) ||
        [];
      const competitionKeysByOrder = Object.keys(state.favouritesMobileEventsList.content) ?? [];
      const competitionIdsByOrder =
        competitionKeysByOrder.map(competitionIdByOrder => getCompetitionIdFromKey(competitionIdByOrder)) ?? [];

      let prevCompetitionId: string = last(competitionIdsByOrder) ?? '';
      let prevCompetitionKey: string = last(competitionKeysByOrder) ?? '';

      action.payload.content.forEach((event, index) => {
        const competitionKey =
          event.competitionId !== prevCompetitionId
            ? `${event.competitionId}${COMPETITION_KEY_DIVIDER}${index}`
            : prevCompetitionKey;

        prevCompetitionId = event.competitionId;
        prevCompetitionKey = competitionKey;

        if (!content[competitionKey]) {
          content[competitionKey] = [];
        }
        if (!competitionIds.includes(event.competitionId)) {
          competitionIds.push(event.competitionId);
        }
        content[competitionKey].push(event);
      });

      state.favouritesMobileEventsList = { ...action.payload, content, competitionIds };
      state.favouritesMobileEventsLoading = false;
      state.favouritesMobileEventsError = null;
    },
    failureFavouritesMobileEventsList: (state, action) => {
      state.favouritesMobileEventsError = action.payload;
      state.favouritesMobileEventsLoading = false;
    },
    resetAsianViewMobileFavouritesState: state => {
      state.favouritesMobileEventsList = initialState.favouritesMobileEventsList;
    }
  }
});

export const {
  fetchAsianViewFavourites,
  successFetchAsianViewFavourites,
  failureFetchAsianViewFavourites,
  removeAsianViewFavourite,
  successSaveRemoveAsianViewFavourite,
  failureSaveRemoveAsianViewFavourite,
  saveAsianViewFavourite,
  fetchFavouritesMobileEventsList,
  successFetchFavouritesMobileEventsList,
  failureFavouritesMobileEventsList,
  resetAsianViewMobileFavouritesState
} = slice.actions;

export default slice.reducer;
