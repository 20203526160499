import { Dispatch, SetStateAction, useState } from 'react';

import DefaultStakeForm from 'components/HeaderNavigation/components/SettingsBetting/components/DefaultStakeForm';
import SettingsCheckboxes from 'components/HeaderNavigation/components/SettingsBetting/components/SettingsCheckboxes';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { AsianViewBetSlipCheckboxes, Stake } from 'redux/modules/user/type';

import styles from './styles.module.scss';

type AsianViewBetSlipSettingsProps = {
  isCouponSettings?: boolean;
  betSlipCheckboxesValues?: AsianViewBetSlipCheckboxes;
  setBetSlipCheckboxesValues?: Dispatch<SetStateAction<AsianViewBetSlipCheckboxes>>;
  couponSettingsDefaultStakes?: (Stake | null)[];
  setCouponSettingsDefaultStakes?: Dispatch<SetStateAction<(Stake | null)[]>>;
};

const AsianViewBetSlipSettings = ({
  isCouponSettings,
  betSlipCheckboxesValues,
  setBetSlipCheckboxesValues,
  couponSettingsDefaultStakes,
  setCouponSettingsDefaultStakes
}: AsianViewBetSlipSettingsProps) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const { defStake } = useDeviceSettings();

  return (
    <>
      {isCouponSettings ? (
        <div className={styles.checkboxesContainerCouponSettings}>
          <SettingsCheckboxes
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            isAsianView
            isPopUpMode
            isCouponSettings
            setBetSlipCheckboxesValues={setBetSlipCheckboxesValues}
            betSlipCheckboxesValues={betSlipCheckboxesValues}
          />
        </div>
      ) : (
        <SettingsCheckboxes isEditMode={isEditMode} setIsEditMode={setIsEditMode} isAsianView />
      )}

      {defStake && (
        <DefaultStakeForm
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          isAsianView
          isCouponSettings={isCouponSettings}
          couponSettingsDefaultStakes={couponSettingsDefaultStakes}
          setCouponSettingsDefaultStakes={setCouponSettingsDefaultStakes}
        />
      )}
    </>
  );
};

export default AsianViewBetSlipSettings;
