import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TabPanel from 'components/TabPanel';
import Tabs, { Tab } from 'components/Tabs';
import { getFeaturedSports, getTranslation } from 'redux/modules/appConfigs/selectors';
import { resetTabMarketData } from 'redux/modules/home';
import { fetchSports } from 'redux/modules/sports';
import { getSportsList, getSportsLoading } from 'redux/modules/sports/selectors';

import HomeEmpty from '../HomeEmpty';
import HomeMarketsTable from '../HomeMarketsTable';

const FeaturedSports = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [tabsValue, setTabsValue] = useState<number>(0);

  const sportList = useSelector(getSportsList);
  const sportListLoading = useSelector(getSportsLoading);
  const translation = useSelector(getTranslation);
  const featuredSports = useSelector(getFeaturedSports);

  useEffect(() => {
    if (!sportList.length) {
      dispatch(fetchSports());
    }
  }, []);

  const sportSections: Tab[] = featuredSports?.length
    ? sportList
        ?.filter(({ id }) => {
          return featuredSports.includes(id);
        })
        ?.sort((a, b) => featuredSports.indexOf(a.id) - featuredSports.indexOf(b.id))
        ?.map(sport => ({
          id: sport.id,
          label: t(sport.translations[translation])
        })) ?? []
    : [];

  const handleChangeTabsValue = (event: SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);

    if (tabsValue !== newValue) {
      dispatch(resetTabMarketData(sportSections[tabsValue].id));
    }
  };

  return (
    <>
      {!sportListLoading && !sportList.length ? (
        <HomeEmpty />
      ) : (
        <div className="middle-section">
          <Tabs tabs={sportSections} value={tabsValue} onChange={handleChangeTabsValue} />
          {sportSections.map(({ id, label }, index) => (
            <TabPanel value={tabsValue} index={index} key={id}>
              <HomeMarketsTable sportId={id} sportName={label} />
            </TabPanel>
          ))}
        </div>
      )}
    </>
  );
};

export default FeaturedSports;
