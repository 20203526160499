import classnames from 'classnames';

import styles from 'components/GameHiLoCounter/styles.module.scss';

interface IBlockItem {
  isActive?: boolean;
  value: string | number;
  bold?: boolean;
  isPlayed?: boolean;
  classes?: string[];
}

const BlockItem = ({ isActive, value, bold, isPlayed, classes = [] }: IBlockItem) => {
  return (
    <div className={classnames(classes?.join(' '), 'biab_hilo-cards-counter-item')}>
      <div
        className={classnames(styles.content, 'biab_hilo-cards-counter-item-content biab_card', {
          [styles.active]: isActive,
          [styles.bold]: bold,
          [styles.isPlayed]: isPlayed
        })}
      >
        {value}
      </div>
    </div>
  );
};

export default BlockItem;
