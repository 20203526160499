import { useSelector } from 'react-redux';

import { useCurrency } from 'hooks/useCurrency';
import { getMinAmountToShowOdds } from 'redux/modules/appConfigs/selectors';
import { getBetAmount, getBetOdds, getMarketPricesCurrency } from 'redux/modules/marketsPrices/selectors';
import { BetDatabaseName, BetTypes, THandicap } from 'types/bets';

const useOddsDisplayFormat = ({
  marketId,
  selectionId,
  handicap,
  betType,
  depthValue = 0
}: {
  marketId: string;
  selectionId: number;
  handicap?: THandicap | null;
  betType: BetTypes;
  depthValue?: 0 | 1 | 2;
}) => {
  const currencyCode = useSelector(getMarketPricesCurrency(marketId));
  const currency = useCurrency(currencyCode);
  const minAmountToShowOdds = useSelector(getMinAmountToShowOdds);
  const isDefaultFormat = currency?.displayFormat === 1;
  const handicapInt = +(handicap || 0);
  const isBack = betType === BetTypes.BACK;
  const betName = isBack ? BetDatabaseName.BACK : BetDatabaseName.LAY;
  const amount = useSelector(getBetAmount(marketId, selectionId, handicapInt, betName, depthValue));
  let odds = useSelector(getBetOdds(marketId, selectionId, handicapInt, betName, depthValue));
  const roundedAmount = isDefaultFormat ? Math.floor(amount ?? 0) : Math.ceil(amount ?? 0);

  if (roundedAmount <= minAmountToShowOdds) {
    odds = 0;
  }

  return odds;
};

export default useOddsDisplayFormat;
