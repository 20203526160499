import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import FancyMarket from 'components/FancyMarket';
import MarketCollapseRow from 'components/MarketCollapseRow';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getIsMarketsPrices } from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import {
  getMultiMarketRulesLoading,
  getMultiMarketsRules,
  getMultiMarketTop
} from 'redux/modules/multiMarket/selectors';
import { PageBlocks } from 'types';
import { TFancyMultiMarketData } from 'types/markets';

import styles from '../../styles.module.scss';

interface FancyMultiMarketProps {
  data: TFancyMultiMarketData;
  onFetchMarketRules: (marketId: string) => void;
  isLayColumnDisabled: boolean;
  /**
   * Whether market depth is enabled
   */
  isDepthEnabled: boolean;
  /**
   * Whether last item in the list
   */
  isLastItem: boolean;
}

const FancyMultiMarket = ({
  data: {
    eventInfo,
    inningsFancy,
    inningsFours,
    inningsSixes,
    totalInningsRuns,
    otherLineMarkets,
    batsmen,
    fallOfWicket,
    type
  },
  onFetchMarketRules,
  isLayColumnDisabled,
  isDepthEnabled,
  isLastItem
}: FancyMultiMarketProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const topMultiMarket = useSelector(getMultiMarketTop);
  const isMarketsPrices = useSelector(getIsMarketsPrices);
  const rules = useSelector(getMultiMarketsRules);
  const rulesLoading = useSelector(getMultiMarketRulesLoading);

  const [collapsed, setCollapsed] = useState(false);

  const isDesktop = device === Devices.DESKTOP;
  const lastMarket = inningsFancy[inningsFancy.length - 1];

  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: lastMarket.marketId,
    eventId: lastMarket.event.id,
    section: MarketsPricesSocketParamsSections.SportsMiddleSection,
    observerOptions: { rootMargin: '200px' }
  });

  const handleCollapse = () => {
    setCollapsed(prevState => !prevState);
  };

  if (!isMarketsPrices) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={classNames(styles.multiMarket__otherMarkets__sections__item, {
        [styles.multiMarket__otherMarkets__sections__item__2columns]: topMultiMarket?.twoColumnView && isDesktop,
        [styles.multiMarket__otherMarkets__market]: !isLastItem
      })}
    >
      <MarketCollapseRow
        marketId={lastMarket.marketId}
        inPlay={lastMarket.inPlay}
        marketStartTime={lastMarket.marketStartTime}
        marketName={lastMarket.marketName}
        sportId={lastMarket.eventType.id}
        onFetchMarketRules={onFetchMarketRules}
        marketsRules={rules}
        rulesLoading={rulesLoading}
        onMarketCollapse={handleCollapse}
        className="biab_multi-market-title"
        classes={{
          header: classNames(styles.multiMarket__otherMarkets__collapseHeader, {
            [styles.multiMarket__otherMarkets__collapseHeader__mobile]: !isDesktop
          })
        }}
        rightIcon={
          <i
            className={classNames('biab_collapse-toggler fa2', {
              'fa2-arrow-up': !collapsed,
              'fa2-arrow-down': collapsed
            })}
          />
        }
        inPlayClassName="biab_multi-market-inplay"
        isMarketCashOutEnabled={lastMarket.cashOutEnabled}
        inPlayStatusFirst
      >
        {t(`navigation.fancy.markets.${type}`)}
      </MarketCollapseRow>
      {!collapsed && (
        <FancyMarket
          marketId={lastMarket.marketId}
          eventInfo={eventInfo}
          inningsFancy={inningsFancy}
          inningsFours={inningsFours}
          inningsSixes={inningsSixes}
          totalInningsRuns={totalInningsRuns}
          batsmen={batsmen}
          otherLineMarkets={otherLineMarkets}
          fallOfWicket={fallOfWicket}
          isMultiMarketView
          sectionFullWidth
          twoColumnView={topMultiMarket?.twoColumnView}
          isLayColumnDisabled={isLayColumnDisabled}
          isDepthEnabled={isDepthEnabled}
          hideRulesAndCollapseAbility
          pageBlock={PageBlocks.MULTI_MARKET_VIEW}
        />
      )}
    </div>
  );
};

export default FancyMultiMarket;
