import { useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import SelectedMarket from 'components/Betslip/components/SelectedMarket';
import { betslipBranding } from 'constants/branding';
import {
  getIsBetslipConfirmationStep,
  getSelectedMarkets,
  getSelectedMarketsWithActiveBets
} from 'redux/modules/betslip/selectors';

import styles from './styles.module.scss';

const SelectedMarkets = () => {
  const selectedMarkets = useSelector(getSelectedMarkets);
  const isBetslipConfirmationStep = useSelector(getIsBetslipConfirmationStep);

  const renderContent = () => {
    if (isBetslipConfirmationStep) {
      return <ConfirmationMarkets />;
    }

    return (
      <>
        {selectedMarkets.map(marketId => (
          <SelectedMarket key={`b-market-${marketId}`} marketId={marketId} />
        ))}
      </>
    );
  };

  return <div className={classNames(styles.selectedMarkets, betslipBranding.SELECTED_MARKETS)}>{renderContent()}</div>;
};

const ConfirmationMarkets = () => {
  const selectedMarketsWithActiveBets = useSelector(getSelectedMarketsWithActiveBets);
  const activeMarketsRef = useRef(selectedMarketsWithActiveBets);

  return (
    <>
      {activeMarketsRef.current.map(marketId => (
        <SelectedMarket key={`b-market-${marketId}`} marketId={marketId} />
      ))}
    </>
  );
};

export default SelectedMarkets;
