import { useTranslation } from 'react-i18next';

import RadioButton from 'components/RadioButton';
import usePlacementSetting from 'hooks/usePlacementSetting';
import { SettingsBettingInlinePlacementType } from 'types/navigation';

import styles from './styles.module.scss';

const PlacementSetting = () => {
  const { t } = useTranslation();

  const { inlinePlacementType, handleChangePlacementType } = usePlacementSetting();

  return (
    <div className={styles.inlinePlacement}>
      <span>{t('account.settings.betting.inlinePlacement')}</span>
      <RadioButton
        name="inlinePlacement"
        value="betslip"
        checked={inlinePlacementType === SettingsBettingInlinePlacementType.BETSLIP}
        onChange={() => handleChangePlacementType(SettingsBettingInlinePlacementType.BETSLIP)}
      >
        {t('account.settings.betting.usingBetslip')}
      </RadioButton>
      <RadioButton
        name="inlinePlacement"
        value="middleSection"
        checked={inlinePlacementType === SettingsBettingInlinePlacementType.MIDDLE_SECTION}
        onChange={() => handleChangePlacementType(SettingsBettingInlinePlacementType.MIDDLE_SECTION)}
      >
        {t('account.settings.betting.middleSection')}
      </RadioButton>
    </div>
  );
};

export default PlacementSetting;
