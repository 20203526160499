import { useSelector } from 'react-redux';

import { getAccountSettings } from 'redux/modules/user/selectors';

import useDeviceSettings from './useDeviceSettings';

export const usePlaceBetWithEnterKey = () => {
  const accountSettings = useSelector(getAccountSettings);
  const { placeBetWithEnterKey } = useDeviceSettings();

  return placeBetWithEnterKey && accountSettings?.placeBetWithEnterKey;
};
