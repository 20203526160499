import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useWindowSize } from 'usehooks-ts';

import { MOBILE_MAX_DISPLAY_SIZE } from 'constants/app';
import { ASIAN_VIEW_MOBILE_WIDTH } from 'constants/asianView';
import { ASIAN_BASE_URL, CASH_OUT_BASE_URL } from 'constants/locations';
import { getLanguage, getPassiveSessionPositionHeader, getTimezone } from 'redux/modules/appConfigs/selectors';
import { getUserSessionInfo } from 'redux/modules/user/selectors';
import { getParsedSessionTime } from 'utils/session';

import styles from './styles.module.scss';

type SessionTimeProps = {
  isMobile: boolean;
};

const SessionTime = ({ isMobile }: SessionTimeProps) => {
  const location = useLocation();

  const { width } = useWindowSize();

  const isCashOutPage = location.pathname.includes(CASH_OUT_BASE_URL);
  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);
  const maxDisplaySize = isAsianViewPage ? ASIAN_VIEW_MOBILE_WIDTH : MOBILE_MAX_DISPLAY_SIZE;

  return isCashOutPage || (width > maxDisplaySize && width < 1411) ? null : <SessionTimeContent isMobile={isMobile} />;
};

export default SessionTime;

function SessionTimeContent({ isMobile }: SessionTimeProps) {
  const { t } = useTranslation();

  const sessionInfo = useSelector(getUserSessionInfo);
  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const passiveSessionPositionHeader = useSelector(getPassiveSessionPositionHeader);

  const [sessionTime, setSessionTime] = useState(getParsedSessionTime(sessionInfo?.startSessionDate ?? 0));

  useEffect(() => {
    if (sessionInfo?.startSessionDate) {
      const timer = setInterval(() => {
        setSessionTime(getParsedSessionTime(sessionInfo.startSessionDate));
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [sessionInfo?.startSessionDate, language, timezone]);

  return (
    <div
      className={classNames('biab_rg-passive-session', styles.sessionTime__container, {
        [styles.sessionTime__inHeader]: !isMobile && passiveSessionPositionHeader,
        [styles.sessionTime__container__mobile]: isMobile
      })}
    >
      <span className={styles.sessionTime__label}>{t('responsibleBetting.labels.currentSessionTime')}</span>
      <span className={styles.sessionTime__time}>{sessionTime}</span>
    </div>
  );
}
