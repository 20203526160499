import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

import { EElementNames } from './type';

const getSettings = (state: AppState) => state.appSettings;
export const getAppSettings = createSelector(getSettings, appSettings => appSettings.settings);
export const getParentDomain = createSelector(getSettings, appSettings => appSettings.parentDomain);
export const getAccountPageReferer = createSelector(getSettings, appSettings => appSettings.accountPageReferer);
export const getOddsType = createSelector(getSettings, appSettings => appSettings.oddsType);
export const getElementHeightByName =
  (elementName: EElementNames) =>
  ({ appSettings }: AppState) =>
    appSettings.elementsHeights[elementName];
export const isShowSearch = createSelector(getSettings, appSettings => appSettings.isShowingSearch);
export const getIsAsianViewMenuOpen = createSelector(getSettings, appSettings => appSettings.isAsianViewMenuOpen);
export const getScrollContentHeight = ({ appSettings }: AppState) => appSettings.scrollContentHeight;
export const getIsUserScrolling = ({ appSettings }: AppState) => appSettings.isUserScrolling;
export const getScrollUpdate = createSelector(getSettings, appSettings => appSettings.scrollUpdate);
export const getMobileHeaderHeight = ({ appSettings }: AppState) =>
  appSettings.elementsHeights[EElementNames.MOBILE_HEADER_HEIGHT];
export const getMobileSettingsActiveTab = (state: AppState) => state.appSettings.mobileSettingsActiveTab;
export const getElementHeight =
  (element: EElementNames) =>
  ({ appSettings }: AppState) =>
    appSettings.elementsHeights[element];
