import { useRef } from 'react';
import classNames from 'classnames';

import { ITimeFormComments } from 'types/markets';
import { getSerializedComments } from 'utils/market';

import styles from '../Content/styles.module.scss';

interface CommentsProps {
  /**
   * Time form comments
   */
  timeformComments: ITimeFormComments[];
}

const Comments = ({ timeformComments }: CommentsProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const commentsColumns = getSerializedComments(timeformComments);

  const commentRow = ({ runnerName, comment, number }: ITimeFormComments) => {
    return (
      <div className={classNames('biab_timeform-comment', styles.timeForm__comment)} key={number}>
        <span className={styles.timeForm__runnerName}>{`${number}. ${runnerName}`}</span>
        <p className={styles.timeForm__commentText}>{comment}</p>
      </div>
    );
  };

  return (
    <div className={classNames('biab_timeform-comments-wrap', styles.timeForm__commentsWrapper)} ref={contentRef}>
      <div className={styles.timeForm__rightSide}>{commentsColumns.left.map(runner => commentRow(runner))}</div>
      <div className={styles.timeForm__leftSide}>{commentsColumns.right.map(runner => commentRow(runner))}</div>
    </div>
  );
};

export default Comments;
