import classNames from 'classnames';

import { generateArray } from 'utils/general';

import styles from './styles.module.scss';

interface ISegmentNumbers {
  activeIndexes?: number[];
}

const SegmentNumbers = ({ activeIndexes }: ISegmentNumbers) => {
  const numbers = generateArray(32);
  return (
    <div className={styles.main}>
      {numbers.map((n, index) => (
        <div
          key={n}
          className={classNames(styles.number, {
            [`biab_active ${styles.activeNumber}`]: activeIndexes?.includes(index + 1)
          })}
        >
          {n + 1}
        </div>
      ))}
    </div>
  );
};

export default SegmentNumbers;
