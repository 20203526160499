import { useResponsibleBettingDeactivationCounter } from 'hooks/useResponsibleBetting';
import {
  BettingDisplaySettingEnabled,
  ResponsibleBettingDisplayedContent,
  ResponsibleBettingEndDate,
  ResponsibleBettingLimitEnabled,
  ResponsibleBettingOperatorLimit
} from 'types/responsibleBetting';

const ResponsibleBettingCountersInjection = () => {
  useResponsibleBettingDeactivationCounter({
    endDateKey: ResponsibleBettingEndDate.ExposureLimit,
    limitEnabledKey: ResponsibleBettingLimitEnabled.ExposureLimit,
    operatorLimitKey: ResponsibleBettingOperatorLimit.ExposureLimit,
    displayEnabledKey: BettingDisplaySettingEnabled.ExposureLimit,
    type: ResponsibleBettingDisplayedContent.ExposureLimit
  });
  useResponsibleBettingDeactivationCounter({
    endDateKey: ResponsibleBettingEndDate.LossLimit,
    limitEnabledKey: ResponsibleBettingLimitEnabled.LossLimit,
    operatorLimitKey: ResponsibleBettingOperatorLimit.LossLimit,
    displayEnabledKey: BettingDisplaySettingEnabled.LossLimit,
    type: ResponsibleBettingDisplayedContent.LossLimit
  });
  useResponsibleBettingDeactivationCounter({
    endDateKey: ResponsibleBettingEndDate.SelfExclusion,
    limitEnabledKey: ResponsibleBettingLimitEnabled.SelfExclusion,
    operatorLimitKey: ResponsibleBettingOperatorLimit.SelfExcluded,
    displayEnabledKey: BettingDisplaySettingEnabled.SelfExclusion,
    type: ResponsibleBettingDisplayedContent.SelfExclusion
  });

  return null;
};

export default ResponsibleBettingCountersInjection;
