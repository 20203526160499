import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import marketHeaderStyles from 'components/EventHeaderMobileInPlay/styles.module.scss';
import FavoriteStar from 'components/FavoriteStar';
import BackButton from 'components/Header/components/BackButton';
import LiveScoreName from 'components/LiveScoreName';
import MarketDropdown from 'components/MarketDropdown';
import MatchStatistics from 'components/MatchStatistics';
import MobileEventWidgets from 'components/MobileEventWidgets';
import VideoStream from 'components/VideoStream';
import useBackButtonInIFrame from 'hooks/useBackButtonInIFrame';
import useHideLabelsOnCollision from 'hooks/useHideLabelsOnCollision';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { TFavorite } from 'redux/modules/favorites/type';
import {
  getEventsUpdatedDataEventTypeId,
  getEventsUpdatedDataInPlayMatchStatus,
  getEventsUpdatedDataStringifiedUpdateDetails,
  getEventUpdatedDataCricketAwayFirstInningOvers,
  getEventUpdatedDataCricketAwayFirstInningRuns,
  getEventUpdatedDataCricketAwayFirstInningWickets,
  getEventUpdatedDataCricketAwayHighlight,
  getEventUpdatedDataCricketAwaySecondInningOvers,
  getEventUpdatedDataCricketAwaySecondInningRuns,
  getEventUpdatedDataCricketAwaySecondInningWickets,
  getEventUpdatedDataCricketHomeFirstInningOvers,
  getEventUpdatedDataCricketHomeFirstInningRuns,
  getEventUpdatedDataCricketHomeFirstInningWickets,
  getEventUpdatedDataCricketHomeHighlight,
  getEventUpdatedDataCricketHomeSecondInningOvers,
  getEventUpdatedDataCricketHomeSecondInningRuns,
  getEventUpdatedDataCricketHomeSecondInningWickets,
  getEventUpdatedDataLocalizedAwayName,
  getEventUpdatedDataLocalizedHomeName,
  getIsEventUpdatedDataCricketAwayFirstInning,
  getIsEventUpdatedDataCricketAwaySecondInning,
  getIsEventUpdatedDataCricketHomeFirstInning,
  getIsEventUpdatedDataCricketHomeSecondInning
} from 'redux/modules/marketsPrices/selectors';
import { MatchStatus, PageBlocks, SportId, TEventExtraData } from 'types';
import { isWinner } from 'utils/market';

import { getDesktopScope, getInning, getMobileScope, YET_TO_BAT } from './helpers';

import styles from './styles.module.scss';

type EventHeaderCricketProps = {
  pageBlock?: PageBlocks;
  isCloseMarket?: boolean;
  /**
   * Favorite data
   */
  favoriteData: TFavorite;
  videoStreamingEnabled?: boolean;
  eventId: string;
  matchStatEnabled?: boolean;
  sportId: string;
};

const EventHeaderCricket = ({
  pageBlock,
  isCloseMarket,
  favoriteData,
  videoStreamingEnabled = false,
  eventId,
  matchStatEnabled = false,
  sportId
}: EventHeaderCricketProps) => {
  const { t } = useTranslation();
  const { marketId } = useParams();

  const device = useSelector(getAppDevice);
  const inPlayMatchStatus = useSelector(getEventsUpdatedDataInPlayMatchStatus(eventId));
  const homeName = useSelector(getEventUpdatedDataLocalizedHomeName(eventId));
  const awayName = useSelector(getEventUpdatedDataLocalizedAwayName(eventId));
  const eventTypeId = useSelector(getEventsUpdatedDataEventTypeId(eventId));
  const stringifiedUpdateDetails = useSelector(getEventsUpdatedDataStringifiedUpdateDetails(eventId));
  const isHomeFirstInning = useSelector(getIsEventUpdatedDataCricketHomeFirstInning(eventId));
  const isHomeSecondInning = useSelector(getIsEventUpdatedDataCricketHomeSecondInning(eventId));
  const isAwayFirstInning = useSelector(getIsEventUpdatedDataCricketAwayFirstInning(eventId));
  const isAwaySecondInning = useSelector(getIsEventUpdatedDataCricketAwaySecondInning(eventId));
  const homeFirstInningRuns = useSelector(getEventUpdatedDataCricketHomeFirstInningRuns(eventId));
  const homeSecondInningRuns = useSelector(getEventUpdatedDataCricketHomeSecondInningRuns(eventId));
  const awayFirstInningRuns = useSelector(getEventUpdatedDataCricketAwayFirstInningRuns(eventId));
  const awaySecondInningRuns = useSelector(getEventUpdatedDataCricketAwaySecondInningRuns(eventId));
  const homeHighlight = useSelector(getEventUpdatedDataCricketHomeHighlight(eventId));
  const awayHighlight = useSelector(getEventUpdatedDataCricketAwayHighlight(eventId));
  const awayFirstInningWickets = useSelector(getEventUpdatedDataCricketAwayFirstInningWickets(eventId));
  const awaySecondInningWickets = useSelector(getEventUpdatedDataCricketAwaySecondInningWickets(eventId));
  const homeFirstInningWickets = useSelector(getEventUpdatedDataCricketHomeFirstInningWickets(eventId));
  const homeSecondInningWickets = useSelector(getEventUpdatedDataCricketHomeSecondInningWickets(eventId));
  const homeFirstInningOvers = useSelector(getEventUpdatedDataCricketHomeFirstInningOvers(eventId));
  const homeSecondInningOvers = useSelector(getEventUpdatedDataCricketHomeSecondInningOvers(eventId));
  const awayFirstInningOvers = useSelector(getEventUpdatedDataCricketAwayFirstInningOvers(eventId));
  const awaySecondInningOvers = useSelector(getEventUpdatedDataCricketAwaySecondInningOvers(eventId));

  const updateDetails = useMemo<TEventExtraData[]>(
    () => (stringifiedUpdateDetails ? JSON.parse(stringifiedUpdateDetails) : []),
    [stringifiedUpdateDetails]
  );

  const showIFrameBackButton = useBackButtonInIFrame();

  const isMobile = device === Devices.MOBILE;

  const isFinished = inPlayMatchStatus === MatchStatus.END || isCloseMarket;

  const currentDay = updateDetails?.[0]?.eventExtraData?.currentDay;

  const [homeSet1, awaySet1] = getInning({
    isHomeInning: isHomeFirstInning,
    isAwayInning: isAwayFirstInning,
    isCurrentDayEqualInning: Number(currentDay) === 1,
    homeInningRuns: homeFirstInningRuns,
    awayInningRuns: awayFirstInningRuns,
    homeInningWickets: homeFirstInningWickets,
    awayInningWickets: awayFirstInningWickets,
    homeInningOvers: homeFirstInningOvers,
    awayInningOvers: awayFirstInningOvers
  });
  const [homeSet2, awaySet2] = getInning({
    isHomeInning: isHomeSecondInning,
    isAwayInning: isAwaySecondInning,
    isCurrentDayEqualInning: Number(currentDay) === 2,
    homeInningRuns: homeSecondInningRuns,
    awayInningRuns: awaySecondInningRuns,
    homeInningWickets: homeSecondInningWickets,
    awayInningWickets: awaySecondInningWickets,
    homeInningOvers: homeSecondInningOvers,
    awayInningOvers: awaySecondInningOvers
  });

  const isSearchType = pageBlock === PageBlocks.SEARCH;
  const isShowFavorite = !isCloseMarket && (isSearchType || isMobile);
  const hasBackButton = isMobile && !isSearchType && showIFrameBackButton;
  const homeRuns = +(homeFirstInningRuns || 0) + +(homeSecondInningRuns || 0);
  const awayRuns = +(awayFirstInningRuns || 0) + +(awaySecondInningRuns || 0);

  const { sportWrapperRef, isIntersect, rightSideContainerRef, centerWrapperRef } = useHideLabelsOnCollision();

  return (
    <div className={classNames({ biab_market: isSearchType })} data-inplay-widget={'soccer'}>
      <div
        className="biab_running-ball-cricket biab_market-title biab_has-icon biab_market-title"
        ref={sportWrapperRef}
      >
        <div
          className={classNames('biab_running-ball-cricket-wrapper', styles.eventHeader, {
            [`${marketHeaderStyles.marketHeader}`]: isMobile && marketId
          })}
          ref={centerWrapperRef}
        >
          <div className={styles.eventHeader__leftSide}>
            {hasBackButton && (
              <div className={styles.eventHeader__leftSide__backButtonWrapper}>
                <BackButton />
              </div>
            )}
            {!hasBackButton && isShowFavorite && (
              <div className={styles.eventHeader__leftFavorite}>
                <FavoriteStar entryData={favoriteData} />
              </div>
            )}
          </div>

          <div className={styles.eventHeader__centerContentWrapper}>
            <div
              className={classNames('biab_running-ball-teams-wrapper biab_has-icon', styles.eventHeader__nameContainer)}
            >
              <div>
                <div className={styles.eventHeader__name}>
                  <span
                    className={classNames('biab_running-ball-home-team', {
                      [styles[isWinner(homeRuns, awayRuns)]]: isFinished
                    })}
                  >
                    <LiveScoreName
                      isSearchType={isSearchType}
                      eventTypeId={eventTypeId}
                      name={homeName}
                      eventId={eventId}
                    />
                  </span>
                  <div className={styles.eventHeader__highlightTeam}>
                    {!isFinished && homeHighlight && <i className="biab_running-ball-icon biab_rb-cricket-icon" />}
                  </div>{' '}
                </div>
                <div className={styles.eventHeader__name}>
                  <span
                    className={classNames('biab_running-ball-away-team', {
                      [styles[isWinner(awayRuns, homeRuns)]]: isFinished
                    })}
                  >
                    <LiveScoreName
                      isSearchType={isSearchType}
                      eventTypeId={eventTypeId}
                      name={awayName}
                      eventId={eventId}
                    />
                  </span>
                  <div className={styles.eventHeader__highlightTeam}>
                    {!isFinished && awayHighlight && <i className="biab_running-ball-icon biab_rb-cricket-icon" />}
                  </div>
                </div>
              </div>
              <div className={classNames('biab_running-ball-current-day', styles.eventHeader__days)}>
                {isFinished ? (
                  <div className={classNames({ ['biab_running-ball-current-set-info']: !isMobile })}>
                    <div className="biab-running-ball-status">{t('runningBall.status.finished')}</div>
                  </div>
                ) : (
                  <div className="biab_running-ball-current-day">{`${t('runningBall.day')} ${currentDay}`}</div>
                )}
                <div className={styles.eventHeader__highlightTeam} />
              </div>
            </div>

            <div className={classNames('biab_running-ball-both-innings-wrapper', styles.eventHeader__inningsWrapper)}>
              <div
                className={classNames('biab_running-ball-innings-wrapper', styles.eventHeader__scope, {
                  ['biab_active']: !isFinished && currentDay === '1'
                })}
              >
                <div className={classNames('biab_score-wrapper', styles.eventHeader__points)}>
                  <span
                    className={classNames('biab_running-ball-home-score', styles.eventHeader__setPoint, {
                      'biab_yet-to-bat': homeSet1 === YET_TO_BAT,
                      [isWinner(+(homeFirstInningRuns || 0), +(awayFirstInningRuns || 0))]:
                        currentDay === '2' || isFinished
                    })}
                  >
                    {isMobile ? getMobileScope(homeSet1) : getDesktopScope(homeSet1, t)}
                  </span>
                  <span
                    className={classNames('biab_running-ball-away-score', styles.eventHeader__setPoint, {
                      'biab_yet-to-bat': awaySet1 === YET_TO_BAT,
                      [isWinner(+(awayFirstInningRuns || 0), +(homeFirstInningRuns || 0))]:
                        currentDay === '2' || isFinished
                    })}
                  >
                    {isMobile ? getMobileScope(awaySet1) : getDesktopScope(awaySet1, t)}
                  </span>
                </div>

                <div className={classNames('biab_running-ball-innings', styles.eventHeader__inning)}>
                  {`${t('runningBall.cricket.innings')} 1`}
                </div>
              </div>

              <div
                className={classNames('biab_running-ball-innings-wrapper', styles.eventHeader__scope, {
                  ['biab_active']: !isFinished && currentDay === '2'
                })}
              >
                <div className={classNames('biab_score-wrapper', styles.eventHeader__points)}>
                  <span
                    className={classNames('biab_running-ball-home-score', styles.eventHeader__setPoint, {
                      'biab_yet-to-bat': homeSet2 === YET_TO_BAT,
                      [isWinner(+(homeSecondInningRuns || 0), +(awaySecondInningRuns || 0))]: isFinished
                    })}
                  >
                    {isMobile ? getMobileScope(homeSet2) : getDesktopScope(homeSet2, t)}
                  </span>
                  <span
                    className={classNames('biab_running-ball-away-score', styles.eventHeader__setPoint, {
                      'biab_yet-to-bat': awaySet2 === YET_TO_BAT,
                      [isWinner(+(awaySecondInningRuns || 0), +(homeSecondInningRuns || 0))]: isFinished
                    })}
                  >
                    {isMobile ? getMobileScope(awaySet2) : getDesktopScope(awaySet2, t)}
                  </span>
                </div>

                <div className={classNames('biab_running-ball-innings', styles.eventHeader__inning)}>
                  {`${t('runningBall.cricket.innings')} 2`}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.eventHeader__rightSide} ref={rightSideContainerRef}>
            {!isMobile && !isCloseMarket && (
              <div className={styles.eventHeader__rightSide__rightBlock}>
                <div
                  className={classNames(styles.eventHeader__rightSide__widgets, {
                    [styles.eventHeader__rightSide__widgets__vertical]: isIntersect
                  })}
                >
                  {videoStreamingEnabled && (
                    <VideoStream hideLabel={isIntersect} visible={videoStreamingEnabled} isInHeader eventId={eventId} />
                  )}
                  {matchStatEnabled && (
                    <MatchStatistics
                      hideLabel={isIntersect}
                      isSoccer={sportId === SportId.SOCCER}
                      visible={matchStatEnabled}
                      isInHeader
                      eventId={eventId}
                    />
                  )}
                </div>
                {!isSearchType && (
                  <FavoriteStar entryData={favoriteData} iconClassName={styles.eventHeader__favouriteIcon__right} />
                )}
              </div>
            )}

            <div className={styles.marketDropdown}>{isMobile && <MarketDropdown topPosition={60} />}</div>
          </div>
        </div>
        {isMobile && !isCloseMarket && (videoStreamingEnabled || matchStatEnabled) && (
          <MobileEventWidgets
            videoStreamingEnabled={videoStreamingEnabled}
            matchStatEnabled={matchStatEnabled}
            eventId={eventId}
            sportId={sportId}
          />
        )}
      </div>
    </div>
  );
};

export default EventHeaderCricket;
