import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { TContentItemMarket } from 'redux/modules/games/type';

import styles from './styles.module.scss';

interface IGameResultsPlayersStatusesTable {
  markets: TContentItemMarket[];
}

const GameResultsPlayersStatusesTable = ({ markets }: IGameResultsPlayersStatusesTable) => {
  const { t } = useTranslation();

  return (
    <div className={styles.playerStats}>
      {markets.map(market => (
        <div key={market.id} className={classNames('biab_game-results-total-table', styles.playerStats__main)}>
          <div className={classNames(styles.title)}>
            {t('games.gameId')}: {market.id}
          </div>
          <div>
            {market.selections.map((data, index) => (
              <div key={index} className={classNames(styles.row)}>
                <div className={styles.rowName}>{data.name}</div>
                <div className={styles.rowStatus}>{t(`market.status.game.${data.status.toLowerCase()}`)}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default GameResultsPlayersStatusesTable;
