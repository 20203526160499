import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SockJS from 'sockjs-client';
import { v4 as uuidv4 } from 'uuid';

import { ASIAN_BASE_URL } from 'constants/locations';
import { urls } from 'redux/api/urls';
import {
  getIsAsianViewEnabled,
  getIsMarketPricesWSEnabled,
  getIsPropertiesLoaded,
  getReconnectAttempts,
  getReconnectTimeout
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInLoading, getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getOperatorCurrency } from 'redux/modules/user/selectors';
import { ApplicationTypes, CookieNames } from 'types';
import { iosBundleRedirect } from 'utils';

type Params<T> = {
  setData: (data: T) => void;
  url: string;
  enabled?: boolean;
  withOutAdditionalParams?: boolean;
  reconnectDependencies?: any[];
};

export default function useSocketClient<T>({
  setData,
  url,
  enabled = true,
  withOutAdditionalParams,
  reconnectDependencies = []
}: Params<T>) {
  const [cookies] = useCookies([CookieNames.TOKEN]);
  const location = useLocation();

  const marketPricesWSEnabled = useSelector(getIsMarketPricesWSEnabled);
  const reconnectAttempts = useSelector(getReconnectAttempts);
  const reconnectTimeout = useSelector(getReconnectTimeout);
  const operatorCurrency = useSelector(getOperatorCurrency);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const authStateLoading = useSelector(getLoggedInLoading);
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);

  const currentReconnectAttempts = useRef<number | null>(null);
  const sessionId = useRef<string>('');

  const [isConnected, setConnected] = useState(false);
  const [isClosing, setClosing] = useState(false);
  const [isClosed, setClosed] = useState(true);
  const sockRef = useRef<WebSocket>();

  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);
  const useHeadersInsteadOfCookies = window.environmentConfig?.useHeadersInsteadOfCookies;
  const authToken = cookies[CookieNames.TOKEN];
  const additionalParams: {
    applicationType?: string;
    authToken?: string;
    type?: string;
  } = { applicationType: ApplicationTypes.WEB };
  const hasAdditionalParams = !withOutAdditionalParams || iosBundleRedirect || useHeadersInsteadOfCookies;

  if ((iosBundleRedirect || useHeadersInsteadOfCookies) && authToken) {
    additionalParams.authToken = authToken;
  }

  if (isAsianViewEnabled && isAsianViewPage && url === urls.ws.marketPrices) {
    additionalParams.type = 'AVS';
  }

  const initSocket = () => {
    sessionId.current = uuidv4();
    const options = {
      transports: marketPricesWSEnabled ? ['websocket', 'xhr-polling'] : ['xhr-polling'],
      sessionId: () => sessionId.current
    };

    if (!!sockRef.current && sockRef.current?.readyState !== WebSocket.CLOSED) {
      setClosing(true);
      sockRef.current?.close();
    }

    sockRef.current = new SockJS(
      `${window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL}/customer/ws/${url}`,
      null,
      options
    );

    sockRef.current.onopen = () => {
      setClosed(sockRef.current?.readyState === WebSocket.CLOSED);
      setConnected(sockRef.current?.readyState === WebSocket.OPEN);
      setClosing(false);
    };

    sockRef.current.onmessage = message => {
      const data = JSON.parse(message.data);
      setData(data);
    };

    sockRef.current.onclose = e => {
      if (currentReconnectAttempts.current === null) {
        currentReconnectAttempts.current = reconnectAttempts ?? 0;
      }

      setClosed(sockRef.current?.readyState === WebSocket.CLOSED);
      setConnected(sockRef.current?.readyState === WebSocket.OPEN);
      setClosing(false);

      const doReconnect =
        !!reconnectAttempts &&
        reconnectAttempts > 0 &&
        typeof currentReconnectAttempts.current === 'number' &&
        currentReconnectAttempts.current !== 0;

      if (
        ((e && !e.wasClean && (e.code === 1006 || e.code === 1011 || e.code === 3000)) ||
          e.code === 1001 ||
          e.code === 1002 ||
          e.code === 1008 ||
          e.code === 2000) &&
        doReconnect
      ) {
        setTimeout(() => {
          if (typeof currentReconnectAttempts.current === 'number' && currentReconnectAttempts.current > 0) {
            currentReconnectAttempts.current--;
          }
          console.error(
            'Websocket reconnecting attempt',
            currentReconnectAttempts.current,
            e.code,
            e.type,
            e.reason,
            `sessionId - ${sessionId.current}`
          );
          initSocket();
        }, reconnectTimeout);
      } else if (e && e.code !== 1000) {
        console.error('Websocket error', e.code, e.type, e.reason, `sessionId - ${sessionId.current}`);
      }
    };

    sockRef.current.onerror = e => {
      console.error('Websocket onerror event triggered', e);
    };
  };

  useEffect(() => {
    setConnected(sockRef.current?.readyState === WebSocket.OPEN);
  }, [sockRef.current?.readyState]);

  useEffect(() => {
    return () => {
      if (sockRef.current?.readyState == WebSocket.OPEN && !!sockRef.current?.close) {
        setClosing(true);
        sockRef.current.close();
      }
    };
  }, [operatorCurrency, isLoggedIn, isAsianViewPage, enabled, ...reconnectDependencies]);

  useEffect(() => {
    if (
      !authStateLoading &&
      (sockRef.current?.readyState == WebSocket.CLOSED || !sockRef.current) &&
      enabled &&
      isPropertiesLoaded
    ) {
      initSocket();
    }
  }, [
    isClosed,
    operatorCurrency,
    isLoggedIn,
    authStateLoading,
    isAsianViewPage,
    enabled,
    isPropertiesLoaded,
    ...reconnectDependencies
  ]);

  const subscribeToMessages = <F>(params: F) => {
    let hasSent = false;
    try {
      if (sockRef.current?.readyState == WebSocket.OPEN && enabled) {
        let dataToSend: string;

        if (
          (url === urls.ws.general || url === urls.ws.asianGeneral) &&
          typeof params === 'object' &&
          params !== null
        ) {
          dataToSend = JSON.stringify(
            Object.entries(params).reduce((acc, [type, payload]) => {
              return {
                ...acc,
                [type]: { ...payload, ...(hasAdditionalParams && additionalParams) }
              };
            }, {})
          );
        } else if (Array.isArray(params)) {
          dataToSend = JSON.stringify(
            params?.map(item => {
              return {
                ...item,
                ...(hasAdditionalParams && additionalParams)
              };
            })
          );
        } else {
          dataToSend = JSON.stringify({ ...params, ...(hasAdditionalParams && additionalParams) });
        }

        sockRef.current?.send(dataToSend);
        hasSent = true;
      }
    } catch (e) {
      console.warn('Use isConnected before sending message to socket');
      console.error(e);
      return false;
    }

    return hasSent;
  };

  return {
    subscribeToMessages,
    isConnected: isConnected && !isClosing,
    onClose: sockRef.current ? sockRef.current.close : () => {}
  };
}
