import classNames from 'classnames';

import Notification from 'components/Notification';
import { componentsBranding } from 'constants/branding';

type TInlineBetErrorProps = {
  message: string;
  onClose: () => void;
};
const InlineBetError = ({ message, onClose }: TInlineBetErrorProps) => {
  return (
    <Notification
      type="warning"
      message={message}
      className={classNames(componentsBranding.NOTIFICATION, componentsBranding.ERROR)}
      onClose={onClose}
      onClickOutside={onClose}
    />
  );
};

export default InlineBetError;
