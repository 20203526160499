import { createSelector } from '@reduxjs/toolkit';

import { PLBetType } from 'constants/myBets';
import { AppState } from 'redux/reducers';

import { TBetsType } from './type';

const getMyBet = (state: AppState) => state.myBets;

export const getMyBets = createSelector(getMyBet, myBets => myBets.data);

export const getStatementData = createSelector(getMyBet, myBets => myBets.statementData);

export const getCancelAllUnmatchedBetsActionId = (state: AppState) => state.myBets.cancelAllUnmatchedBetsActionId;

export const myBetsLoading = createSelector(getMyBet, myBets => myBets.loading);

export const getLoadingProducts = ({ myBets }: AppState) => myBets.loadingProducts;

export const isShowMobileBetDetails = ({ myBets }: AppState) => myBets.showBetDetails;

export const isShowMobileStatementDetails = ({ myBets }: AppState) => myBets.showStatementDetails;

export const betDetails = ({ myBets }: AppState) => myBets.bet;

export const statementDetails = ({ myBets }: AppState) => myBets.statement;

export const isBetUpdated = ({ myBets }: AppState) => myBets.betUpdated;

export const cancelledBet = ({ myBets }: AppState) => myBets.cancelledBet;

export const getStartDate = ({ myBets }: AppState) => myBets.startDate;

export const getEndDate = ({ myBets }: AppState) => myBets.endDate;

export const getPageNumber = ({ myBets }: AppState) => myBets.pageNumber;

export const getPageSize = ({ myBets }: AppState) => myBets.pageSize;

export const charges = createSelector(getMyBet, myBets => myBets.charges);

export const getPLData = createSelector(getMyBet, myBets => myBets.PLData);

export const getPLTotalData = createSelector(getMyBet, myBets => myBets.PLTotal);

export const getPLTotalEventData = createSelector(getMyBet, myBets => myBets.PLTotalEvent);

export const getSportsData = createSelector(getMyBet, myBets => myBets.sportsList);
export const getMyBetsSportsCount = ({ myBets }: AppState) => myBets.sportsList?.length ?? 0;
export const getMyBetsGamesCount = ({ myBets }: AppState) => myBets.gamesList?.length ?? 0;

export const getGamesData = createSelector(getMyBet, myBets => myBets.gamesList);

export const getTimeRange = createSelector(getMyBet, myBets => myBets.timeRange);
export const getMyBetsMultiCurrencyValue = createSelector(getMyBet, myBets => myBets.multiCurrencyValue);

export const getBetsTypeList = (activePLBetType?: PLBetType) => (state: AppState) => {
  if (activePLBetType) {
    return activePLBetType === PLBetType.Sports ? state.myBets.sportsList : state.myBets.gamesList;
  }

  return [];
};

export const getAccountProductsByBetsType = (betsType?: TBetsType) => (state: AppState) => {
  if (betsType) {
    return state.myBets.accountProducts[betsType];
  }

  return null;
};

export const getIsCustomPeriod = ({ myBets }: AppState) => myBets.isCustomPeriod;

export const getTableDataCountByPage =
  ({
    isStatementPage,
    isMyBetsPage,
    isProfitLossPage
  }: {
    isMyBetsPage: boolean;
    isProfitLossPage: boolean;
    isStatementPage: boolean;
  }) =>
  ({ myBets }: AppState) => {
    switch (true) {
      case isMyBetsPage:
        return myBets.data.totalElements;
      case isProfitLossPage:
        return myBets.PLData.totalElements;
      case isStatementPage:
        return myBets.statementData.totalElements;
      default:
        return 0;
    }
  };

export const getSelectedTabIsFirstLoaded =
  (selectedTab: string) =>
  ({ myBets }: AppState) => {
    switch (selectedTab) {
      case 'mybets':
        return myBets.isMyBetsFirstLoaded;

      case 'profitandloss':
        return myBets.isProfitLossFirstLoaded;

      case 'statement':
        return myBets.isStatementFirstLoaded;

      default:
        return myBets.isMyBetsFirstLoaded;
    }
  };
