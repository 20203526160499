import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CURRENCY_REQUEST_INTERVAL } from 'constants/placement';
import { fetchCurrencies, fetchCurrency } from 'redux/modules/appConfigs';
import {
  getCurrencyLoading,
  getIsPropertiesLoaded,
  getLanguage,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchResponsibleBettingSettings } from 'redux/modules/responsibleBetting';
import { getOperatorCurrency } from 'redux/modules/user/selectors';

const CurrencyAndResponsibleBettingSettingsInjection = () => {
  const dispatch = useDispatch();

  const timezone = useSelector(getTimezone);
  const currencyLoading = useSelector(getCurrencyLoading);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const language = useSelector(getLanguage);
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const operatorCurrency = useSelector(getOperatorCurrency);

  const currencyInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const currencyLoaderRef = useRef(currencyLoading);

  currencyLoaderRef.current = currencyLoading;

  useEffect(() => {
    if (isPropertiesLoaded) {
      dispatch(fetchCurrency());
      dispatch(fetchCurrencies());
    }
  }, [dispatch, isLoggedIn, operatorCurrency, isPropertiesLoaded]);

  useEffect(() => {
    if (isLoggedIn) {
      currencyInterval.current = setInterval(() => {
        if (!currencyLoaderRef.current) {
          dispatch(fetchCurrency());
        }
      }, CURRENCY_REQUEST_INTERVAL);
      dispatch(fetchResponsibleBettingSettings());
    }

    return () => {
      if (currencyInterval.current) {
        clearInterval(currencyInterval.current);
      }
    };
  }, [isLoggedIn, dispatch, timezone, language]);

  return null;
};

export default CurrencyAndResponsibleBettingSettingsInjection;
