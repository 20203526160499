import Loader, { CircleColors } from 'components/Loader';

import styles from './styles.module.scss';

const LoadingWithOverlay = () => {
  return (
    <div className={styles.overlay}>
      <Loader circleColor={CircleColors.BLACK} />
    </div>
  );
};

export default LoadingWithOverlay;
