import { useSelector } from 'react-redux';

import PricesCells from 'components/GameMarketTable/partials/PricesCells';
import { PricesCellsType } from 'constants/games';
import { getCurrentGameIsLayOddsDisabled } from 'redux/modules/games/selectors';
import { TAvailablePrice } from 'redux/modules/games/type';

interface IPriceRow {
  selectionId: number;
  bestAvailableToBackPrices: TAvailablePrice[];
  bestAvailableToLayPrices: TAvailablePrice[];
  name: string;
  isFirstSelection: boolean;
}

const PricesRow = ({
  selectionId,
  bestAvailableToBackPrices,
  bestAvailableToLayPrices,
  name,
  isFirstSelection
}: IPriceRow) => {
  const isDisabledLayOdds = useSelector(getCurrentGameIsLayOddsDisabled(true));

  return (
    <>
      <PricesCells
        selectionId={selectionId}
        isFirstSelection={isFirstSelection}
        name={name}
        type={PricesCellsType.BACK}
        prices={bestAvailableToBackPrices}
      />
      {!isDisabledLayOdds && (
        <PricesCells
          selectionId={selectionId}
          name={name}
          type={PricesCellsType.LAY}
          prices={bestAvailableToLayPrices}
        />
      )}
    </>
  );
};

export default PricesRow;
