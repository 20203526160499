import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined, unescape } from 'lodash';

import { BETTING_TYPES } from 'constants/app';
import { tabulation } from 'constants/betslip';
import { betslipBranding as branding } from 'constants/branding';
import { BETSLIP_FULL_AVG_PRICE, BETSLIP_LM_BACKMATCHED, BETSLIP_LM_LAYMATCHED } from 'constants/tooltip';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getCurrencyPrecisionValue } from 'hooks/usePrecision';
import { usePreviousValue } from 'hooks/usePrevious';
import useTooltip from 'hooks/useTooltip';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getIsGameBetSlip, getSelectedBetValidationError } from 'redux/modules/betslip/selectors';
import {
  EBetErrors,
  EBetFocusFields,
  EPlaceBetsStates,
  TBetslipFormProps,
  TBetslipPriceChangeProps,
  TBetslipProfitChangeProps,
  TBetslipSizeChangeProps,
  TValidationMessage
} from 'redux/modules/betslip/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { getPrecisionType, getUserAsianViewAutoRefresh } from 'redux/modules/user/selectors';
import { BetTypes, MatchTypes, TPrice, TSize } from 'types/bets';
import { EInputFieldTypes, TFormCustomClassNames, TFormLabels } from 'types/betslip';
import { TMarketLineRangeInfo, TPriceLadderDescription } from 'types/markets';
import { roundFloat } from 'utils/liability';

import BetIndicator from './components/BetIndicator';
import BetPrice from './components/BetPrice';
import BetProfit from './components/BetProfit';
import BetSize from './components/BetSize';

import styles from './styles.module.scss';

interface IBetFormProps {
  mode: EPlaceBetsStates;
  betUuid?: string;
  betPrice: TPrice;
  betPrevPrice?: TPrice;
  betAveragePrice?: TPrice;
  betSize: TSize;
  betProfit?: number | string | null;
  betType: BetTypes;
  currency?: string;
  bettingType: string;
  marketId: string;
  marketType: string;
  betError?: EBetErrors | null;
  bestPrice?: number;
  lineRangeInfo: TMarketLineRangeInfo | null;
  eachWayDivisor: number | null;
  priceLadderDescription: TPriceLadderDescription | null;
  totalTabFields?: number;
  betIndex?: number;
  focusedField?: EBetFocusFields | null;
  onFormChanged: ({
    price,
    size,
    profit,
    isPriceDirty,
    isPriceValid,
    isSizeValid,
    priceErrorMessage,
    sizeErrorMessage
  }: TBetslipFormProps) => void;
  onEnterClick: () => void;
  onTabNavigate?: (fieldType: EInputFieldTypes) => void;
  isCombined?: boolean;
  isUnmatchedOpenedBetTab?: boolean;
  isHighlighted?: boolean;
  isPriceDirty?: boolean;
  offerId?: number;
  showOddsChangedIndicators?: boolean;
  isSelectedBet?: boolean;
  labels?: TFormLabels;
  customClassNames?: TFormCustomClassNames;
  betActualPrice?: TPrice;
  isTakeOfferState?: boolean;
  isOddsRefreshEnabled?: boolean;
  isInlineBetslip?: boolean;
  validationMessage?: TValidationMessage | null;
  isSizeHighlighted?: boolean;
  matchType?: MatchTypes;
  bet?: TCurrentBet;
  isDisabled?: boolean;
}

const BetForm = ({
  mode,
  betUuid,
  betPrice,
  betPrevPrice,
  betAveragePrice,
  betSize,
  betProfit,
  betType,
  betError,
  currency,
  bettingType,
  marketId,
  marketType,
  bestPrice,
  lineRangeInfo,
  priceLadderDescription,
  totalTabFields = 0,
  betIndex = 0,
  focusedField,
  onFormChanged,
  onEnterClick,
  onTabNavigate,
  isCombined = false,
  isUnmatchedOpenedBetTab = false,
  isHighlighted = false,
  isPriceDirty = false,
  offerId,
  showOddsChangedIndicators = false,
  isSelectedBet = false,
  labels,
  customClassNames,
  betActualPrice,
  isTakeOfferState,
  isOddsRefreshEnabled,
  isInlineBetslip,
  validationMessage,
  isSizeHighlighted,
  matchType,
  bet,
  isDisabled = false
}: IBetFormProps) => {
  const { t } = useTranslation();

  const precisionType = useSelector(getPrecisionType);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isGameBetslip = useSelector(getIsGameBetSlip);
  const validationError = useSelector(getSelectedBetValidationError({ marketId, betType, betUuid: betUuid ?? '' }));
  const autoRefreshIsEnabled = useSelector(getUserAsianViewAutoRefresh);

  let priceValue;

  if (isPNCEnabled && autoRefreshIsEnabled && !isGameBetslip && isSelectedBet && !isDisabled) {
    if (mode !== EPlaceBetsStates.CONFIRM) {
      priceValue = bestPrice;
    } else {
      priceValue = betActualPrice;
    }
  } else {
    priceValue = betPrice;
  }

  const [prevPrice, setPrevPrice] = useState(priceValue);

  const precision = getCurrencyPrecisionValue(precisionType);
  const isTakeOffer = isPNCEnabled && mode === EPlaceBetsStates.TAKE_OFFER;
  const isPriceIndicatorEnabled = (!isPNCEnabled || isGameBetslip) && !isInlineBetslip;
  const isPriceError =
    validationError?.field === 'price' || betError === EBetErrors.EX015 || validationMessage?.field === 'price';
  const isSizeError = validationError?.field === 'size' || validationMessage?.field === 'size';
  const isReadOnlyMode = mode === EPlaceBetsStates.READONLY;

  const tooltipText =
    bettingType === BETTING_TYPES.odds
      ? BETSLIP_FULL_AVG_PRICE
      : betType === BetTypes.BACK
      ? BETSLIP_LM_BACKMATCHED
      : BETSLIP_LM_LAYMATCHED;

  const { translationKey } = useTooltip(tooltipText);
  const prevBestPrice = usePreviousValue(bestPrice);

  useEffect(() => {
    if (bestPrice !== prevBestPrice) {
      setPrevPrice(prevBestPrice);
    }
  }, [bestPrice, prevBestPrice]);

  const setChangedPrice = ({
    changedPrice,
    isValid,
    errorMessage,
    focusedField: focusedPrice
  }: TBetslipPriceChangeProps) => {
    onFormChanged({
      price: changedPrice,
      isPriceValid: isValid,
      ...(errorMessage ? { priceErrorMessage: errorMessage } : {}),
      ...(!isUndefined(focusedPrice) ? { focusedField: focusedPrice } : {})
    });
  };

  const setChangedSize = ({
    changedSize,
    isValid,
    errorMessage,
    focusedField: focusedSize
  }: TBetslipSizeChangeProps) => {
    onFormChanged({
      size: changedSize,
      isSizeValid: isValid,
      ...(errorMessage ? { sizeErrorMessage: errorMessage } : {}),
      ...(!isUndefined(focusedSize) ? { focusedField: focusedSize } : {})
    });
  };

  const onProfitChanged = ({
    size,
    profit,
    errorMessage,
    isValid,
    focusedField: focusedProfit
  }: TBetslipProfitChangeProps) => {
    onFormChanged({
      size,
      profit,
      isSizeValid: isValid,
      ...(errorMessage ? { sizeErrorMessage: errorMessage } : {}),
      ...(!isUndefined(focusedProfit) ? { focusedField: focusedProfit } : {})
    });
  };

  const currencyFormatOptions = { noCommas: true, noRounding: true, noSymbol: isSelectedBet };

  const { noFormattedAmount: sizeFormatted } = useFormatCurrency(
    roundFloat(betSize ?? 0, precision),
    currency ?? '',
    currencyFormatOptions
  );

  const { noFormattedAmount: profitFormatted } = useFormatCurrency(
    roundFloat(betProfit ?? 0, precision),
    currency ?? '',
    currencyFormatOptions
  );

  const sizeToDisplay = !isUndefined(betSize) && betSize !== '' ? sizeFormatted : '';
  const profitToDisplay = !isUndefined(betProfit) && betProfit !== '' ? profitFormatted : '';

  const tooltipParams =
    bettingType === BETTING_TYPES.odds
      ? { N: betAveragePrice || priceValue || '' }
      : {
          R: betAveragePrice || priceValue || '',
          UNITS: lineRangeInfo?.marketUnit || 'runs'
        };

  const getFieldOrder = (order = 0) => betIndex * totalTabFields + order;

  return (
    <div
      className={classNames(styles.betForm, customClassNames?.form, {
        [styles.betForm__confirm]: mode === EPlaceBetsStates.CONFIRM,
        [branding.OPENED_BET_HEADER]: mode === EPlaceBetsStates.CONFIRM,
        [styles.betForm__select]: mode === EPlaceBetsStates.SELECT || isHighlighted,
        [styles.betForm__readonly]: mode === EPlaceBetsStates.READONLY
      })}
    >
      {mode === EPlaceBetsStates.SELECT || mode === EPlaceBetsStates.READONLY ? (
        <>
          <div className={classNames(styles.betPriceWrap, customClassNames?.betPriceWrap)}>
            {isPriceIndicatorEnabled && !isReadOnlyMode && (
              <div className={classNames(styles.priceIndicatorWrap, customClassNames?.priceIndicator)}>
                <BetIndicator
                  price={priceValue}
                  betType={betType}
                  bestPrice={bestPrice}
                  onChange={() => onFormChanged({ price: bestPrice })}
                />
              </div>
            )}
            <BetPrice
              betUuid={betUuid}
              price={priceValue}
              betType={betType}
              bettingType={bettingType}
              marketType={marketType}
              bestPrice={bestPrice}
              lineRangeInfo={lineRangeInfo}
              priceLadderDescription={priceLadderDescription}
              onChangePrice={setChangedPrice}
              onEnterClick={onEnterClick}
              onTabNavigate={onTabNavigate}
              fieldOrder={getFieldOrder(tabulation.PRICE_ORDER)}
              isDirty={isPriceDirty}
              isError={isPriceError}
              isFocus={focusedField === EBetFocusFields.PRICE}
              isUnmatchedOpenedBetTab={isUnmatchedOpenedBetTab}
              offerId={offerId}
              showOddsChangedIndicators={showOddsChangedIndicators}
              prevPrice={betPrevPrice}
              customClassNames={customClassNames}
              isReadOnly={isReadOnlyMode}
              isTakeOfferState={isTakeOfferState}
              isOddsRefreshEnabled={isOddsRefreshEnabled}
            />
          </div>
          <BetSize
            betUuid={betUuid}
            value={betSize}
            betType={betType}
            currencyCode={currency}
            onChangeSize={setChangedSize}
            onEnterClick={onEnterClick}
            onTabNavigate={onTabNavigate}
            fieldOrder={getFieldOrder(tabulation.SIZE_ORDER)}
            isError={isSizeError}
            isFocus={focusedField === EBetFocusFields.SIZE}
            isUnmatchedOpenedBetTab={isUnmatchedOpenedBetTab}
            offerId={offerId}
            isHighlighted={isHighlighted || isSizeHighlighted}
            label={labels?.stake}
            customClassName={customClassNames?.size}
            isReadOnly={isReadOnlyMode}
            matchType={matchType}
            bet={bet}
          />
          <BetProfit
            betUuid={betUuid}
            betProfit={betProfit}
            betSize={betSize}
            betType={betType}
            currencyCode={currency}
            onChanged={onProfitChanged}
            onEnterClick={onEnterClick}
            onTabNavigate={onTabNavigate}
            fieldOrder={getFieldOrder(tabulation.PROFIT_ORDER)}
            label={labels?.profit}
            customClassName={customClassNames?.profit}
            isReadOnly={isReadOnlyMode}
            isFocus={focusedField === EBetFocusFields.LIABILITY}
          />
        </>
      ) : (
        <>
          <div
            className={classNames(
              styles.betPriceWrap,
              styles.betPriceWrap__center,
              customClassNames?.betPriceWrap ?? ''
            )}
          >
            {isTakeOffer && <BetIndicator price={prevPrice} betType={betType} bestPrice={bestPrice} />}
            <span
              data-tooltip-id={isCombined ? undefined : 'tooltip'}
              data-tooltip-html={isCombined ? undefined : unescape(t(translationKey, { ...tooltipParams }))}
              data-field-type={EInputFieldTypes.PRICE}
              className={classNames(styles.betForm__price, {
                'cursor-help': !isCombined
              })}
            >
              {matchType === MatchTypes.MATCHED ? bet?.averagePriceRounded : priceValue || ''}
            </span>
          </div>
          <span className={styles.size} data-field-type={EInputFieldTypes.SIZE}>
            {sizeToDisplay}
          </span>
          <span className={styles.profit} data-field-type={EInputFieldTypes.PROFIT}>
            {profitToDisplay}
          </span>
        </>
      )}
    </div>
  );
};

export default memo(BetForm);
