import { SyntheticEvent } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Icon from 'components/Icon';
import { MAX_DOUBLE_DIGIT_NUMBER } from 'constants/app';
import { betslipBranding } from 'constants/branding';
import {
  getIsGameBetSlip,
  getIsMarketsLimitNotification,
  getSelectedBetsAmount
} from 'redux/modules/betslip/selectors';
import { CookieNames } from 'types';

import styles from './styles.module.scss';

const CollapsedRightNavigation = ({ hoveredIconClass = '' }: { hoveredIconClass: string }) => {
  const [, setCookie] = useCookies([CookieNames.COLLAPSE_SIDEBAR]);

  const selectedBetsAmount = useSelector(getSelectedBetsAmount);
  const isAmountLimitReached = useSelector(getIsMarketsLimitNotification);
  const isGameBetSlip = useSelector(getIsGameBetSlip);

  const onToggleSidebar = (event: SyntheticEvent) => {
    if (!isAmountLimitReached) {
      event.preventDefault();
      event.stopPropagation();
      setCookie(CookieNames.COLLAPSE_SIDEBAR, false, { path: '/' });
    }
  };

  return (
    <div className={classNames(styles.minNav)}>
      <button className={classNames(styles.button, styles.sidebarToggle, hoveredIconClass)} onClick={onToggleSidebar}>
        <Icon iconClass="av-icon-union-chevron-left" fontFamily="av-icon" size="sm" color="inherit" />
      </button>
      <button className={classNames(styles.button, styles.betlist, betslipBranding.MIN_NAV_ICONS)}>
        {selectedBetsAmount > 0 && (
          <span
            className={classNames(styles.betlist__counter, betslipBranding.BETSLIP_COUNTER, {
              [styles.betlist__counter__moreThanDoubleDigitNumber]: selectedBetsAmount > MAX_DOUBLE_DIGIT_NUMBER
            })}
          >
            {selectedBetsAmount}
          </span>
        )}
        <Icon iconClass="fa2-union" fontFamily="fa2" size="sm" color="gray" />
      </button>
      {!isGameBetSlip && (
        <button className={classNames(styles.button, betslipBranding.MIN_NAV_ICONS)}>
          <Icon iconClass="av-icon-link" fontFamily="av-icon" className={styles.linkIcon} color="gray" />
        </button>
      )}
    </div>
  );
};

export default CollapsedRightNavigation;
