import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import useNetOfCommission from 'hooks/useNetOfCommission';
import useWhatIfTooltip from 'hooks/useWhatIfTooltip';
import { getAsianViewCashOutWhatIfBySelectionId } from 'redux/modules/asianViewCashOut/selectors';
import { getBetslipLoading } from 'redux/modules/betslip/selectors';
import { getWhatIfCashOut } from 'redux/modules/cashOut/selectors';
import { getEventCashOutWhatIfBySelectionId } from 'redux/modules/eventCashOut/selectors';
import { getIsAtLeastOneMobileBetInProcess } from 'redux/modules/inlinePlacement/selectors';
import { getMarketPricesCurrencyById, getMarketPricesNumberOfWinners } from 'redux/modules/marketsPrices/selectors';
import { getCurrentWhatIfById, getInlinePlacementLoading, getNewWhatIf } from 'redux/modules/whatIf/selectors';
import { TNewWhatValue } from 'redux/modules/whatIf/type';

import WhatIfValue from './components/WhatIfValue';
import { getCurrentWhatIf } from './helpers';

import styles from './styles.module.scss';

const getCashOutProfit = (currentPL: string[], cashOutProfit: { profit: number; ratio: number }) => {
  const matchedPL = parseFloat(currentPL[0]);

  return `${(cashOutProfit.profit - matchedPL) * cashOutProfit.ratio! + matchedPL}`;
};

interface WhatIfProps {
  selectionId: number;
  marketId: string;
  commission: number;
  eachWayDivisor: number | null;
  marketType: string;
  handicap: number;
  classes?: Record<string, string>;
  isAsianView?: boolean;
  isEvent?: boolean;
}

const WhatIf = ({
  selectionId,
  handicap,
  marketId,
  commission,
  eachWayDivisor,
  marketType,
  classes,
  isAsianView = false,
  isEvent = false
}: WhatIfProps) => {
  const cashOutProfitSelector = isEvent
    ? getEventCashOutWhatIfBySelectionId
    : isAsianView
    ? getAsianViewCashOutWhatIfBySelectionId
    : getWhatIfCashOut;

  const isBetslipLoading = useSelector(getBetslipLoading);
  const isMobilePlacementLoading = useSelector(getIsAtLeastOneMobileBetInProcess);
  const currentBets: TNewWhatValue[] = useSelector(getCurrentWhatIfById(marketId));
  const numberOfWinners = useSelector(getMarketPricesNumberOfWinners(marketId));
  const currency = useSelector(getMarketPricesCurrencyById(marketId));
  const inlinePlacementLoading = useSelector(getInlinePlacementLoading);
  const newBets = useSelector(getNewWhatIf({ marketId, selectionId, handicap, numberOfWinners, isAsianView }));
  const cashOutProfit = useSelector(cashOutProfitSelector(marketId, selectionId));

  const { isMultiCurrencySupported, isMultiCurrencyChanged } = useMultiCurrencySupporting();
  const isNetOfCommissionChecked = useNetOfCommission();

  const isCurrencyChanged = isMultiCurrencyChanged(newBets[0]?.currency);
  const isMultiCurrencyMessage = isMultiCurrencySupported && isCurrencyChanged;

  const { currentPL, updateBet } = useMemo(() => {
    let currentPlVal: string[] = [];
    let updateBetVal: string[] = [];

    if (!isBetslipLoading && !inlinePlacementLoading && !isMobilePlacementLoading) {
      const isEachWayDivisor = eachWayDivisor ? currentBets[0]?.selectionId === newBets[0]?.selectionId : true;
      const isNew = !!(currentBets.length && newBets.length && isEachWayDivisor);

      currentPlVal = getCurrentWhatIf({
        bets: currentBets.length ? currentBets : [],
        eachWayDivisor,
        numberOfWinners,
        selectionId,
        handicap
      });

      if (!isMultiCurrencyMessage) {
        updateBetVal =
          inlinePlacementLoading === false
            ? []
            : getCurrentWhatIf({
                bets: isNew ? [...newBets, ...currentBets] : newBets,
                eachWayDivisor,
                numberOfWinners,
                selectionId,
                handicap,
                isNew
              });
      }
    }

    return { currentPL: currentPlVal, updateBet: updateBetVal };
  }, [
    isBetslipLoading,
    inlinePlacementLoading,
    isMobilePlacementLoading,
    eachWayDivisor,
    currentBets,
    newBets,
    numberOfWinners,
    selectionId,
    handicap,
    isMultiCurrencyMessage
  ]);

  const tooltip = useWhatIfTooltip({
    currentPL,
    updateBet,
    numberOfWinners,
    marketType,
    currency
  });

  if (!currentPL.length && !updateBet.length && !cashOutProfit) {
    return null;
  }

  return (
    <div
      data-tooltip-id="tooltip"
      data-tooltip-html={unescape(tooltip)}
      className={classNames(styles.container, classes?.container ?? '')}
    >
      <div className={styles.wrapBlock}>
        {currentPL.map((item, index) => (
          <WhatIfValue
            key={`${index}_${item}`}
            item={item}
            commission={commission}
            currency={currency}
            isProfitNetOfCommissionChecked={isNetOfCommissionChecked}
            isFinish={index < currentPL.length - 1}
            index={index}
          />
        ))}

        {(!!updateBet.length || (cashOutProfit && currentPL.length === 1)) && (
          <div className={styles.itemContainer}>
            <div className={styles.arrow}>»</div>
            <div className={styles.itemContainer}>
              {cashOutProfit ? (
                <WhatIfValue
                  item={getCashOutProfit(currentPL, cashOutProfit)}
                  commission={commission}
                  currency={currency}
                  isFutureProfit
                />
              ) : (
                <>
                  {updateBet.map((item, index) => (
                    <WhatIfValue
                      key={`${index}_${item}`}
                      item={item}
                      isProfitNetOfCommissionChecked={isNetOfCommissionChecked}
                      commission={commission || 0}
                      currency={currency}
                      isFinish={index < updateBet.length - 1}
                      isFutureProfit
                      index={index}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(WhatIf);
