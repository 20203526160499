import { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { COMPETITION_BASE_URL, EVENT_BASE_URL, MARKET_BASE_URL, SPORT_BASE_URL } from 'constants/locations';
import { useEnabledView } from 'hooks/useEnabledView';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setCollapseNewState } from 'redux/modules/collapse';
import { COLLAPSE_ROW_NAME, LEFT_PANEL_COLLAPSE_GROUP, SPORT_COLLAPSE } from 'redux/modules/collapse/constants';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { getInningsType } from 'utils/fancyMultiMarket';

import styles from './styles.module.scss';

interface MarketRowProps {
  /**
   * Active market item that should be rendered
   */
  activeMarket: TCurrentBet;
}

const MarketRow = ({ activeMarket }: MarketRowProps) => {
  const dispatch = useDispatch();
  const [, setCookie] = useCookies([COLLAPSE_ROW_NAME]);

  const { raceName, marketId, eventTypeId, competitionId, eventId, marketName, eventName, fancyView, type } =
    activeMarket;

  const device = useSelector(getAppDevice);

  const { isCompetitionView } = useEnabledView();
  const { isFancyView } = useEnabledView();

  const isDesktop = device === Devices.DESKTOP;
  const isCompetitionLinkEnabled = isDesktop || (!isDesktop && isCompetitionView);

  const eventUrl = useMemo(() => {
    if (raceName) {
      return `${SPORT_BASE_URL}/${eventTypeId}${MARKET_BASE_URL}/${marketId}`;
    } else if (competitionId && eventTypeId && isCompetitionLinkEnabled) {
      return `${SPORT_BASE_URL}/${eventTypeId}${COMPETITION_BASE_URL}/${competitionId}`;
    } else {
      return `${SPORT_BASE_URL}/${eventTypeId}${EVENT_BASE_URL}/${eventId}`;
    }
  }, [activeMarket]);

  const marketUrl =
    fancyView && isFancyView && type && marketName
      ? `${SPORT_BASE_URL}/${eventTypeId}/fancyEvent/${eventId}/${getInningsType(type, marketName)}`
      : `${SPORT_BASE_URL}/${eventTypeId}${MARKET_BASE_URL}/${marketId}`;

  const onClickActiveMarket = () => {
    if (isDesktop) {
      dispatch(setCollapseNewState({ group: LEFT_PANEL_COLLAPSE_GROUP, collapse: SPORT_COLLAPSE, openedState: true }));
      setCookie(COLLAPSE_ROW_NAME, true);
    }
  };

  return (
    <>
      {eventId ? (
        <li className={styles.marketRow} onClick={onClickActiveMarket}>
          <NavLink to={eventUrl} className={classNames('biab_active-event-link', styles.marketRow__link)}>
            {eventName}
          </NavLink>
          <NavLink to={marketUrl} className={classNames('biab_active-market-link', styles.marketRow__link)}>
            {marketName}
          </NavLink>
        </li>
      ) : (
        <>
          <li className={classNames(styles.marketRow, styles.marketRow__inactive)}>
            <a className={classNames('biab_active-event-link', styles.marketRow__title)}>{eventName}</a>
          </li>
          <li className={styles.marketRow} onClick={onClickActiveMarket}>
            <NavLink to={marketUrl} className={classNames('biab_active-market-link', styles.marketRow__link)}>
              {marketName}
            </NavLink>
          </li>
        </>
      )}
    </>
  );
};

export default MarketRow;
