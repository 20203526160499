import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

export const getLoading = ({ inPlay }: AppState) => inPlay.loading;
export const getSportListLoading = ({ inPlay }: AppState) => inPlay.sportListLoading;
export const getRulesLoading = ({ inPlay }: AppState) => inPlay.rulesLoading;

export const getInPlayMarkets = ({ inPlay }: AppState) => inPlay.marketCatalogueList?.content ?? null;
export const getInPlayMarketsEventIds = createSelector(
  getInPlayMarkets,
  markets => markets?.map(({ event }) => event.id) || []
);

export const getInPlayMarketsSportIds = createSelector(
  getInPlayMarkets,
  markets => Array.from(new Set(markets?.map(({ eventType }) => eventType.id))) || []
);

export const getInPlayMarketsRules = ({ inPlay }: AppState) => inPlay.rules ?? null;

export const getInPlaySports = ({ inPlay }: AppState) => inPlay.sportList;

export const getInPlayMarketsInfoLast = ({ inPlay }: AppState) => inPlay.marketCatalogueList?.last;

export const getFirstInPlayMarketId = ({ inPlay }: AppState) => inPlay.marketCatalogueList?.content[0]?.marketId;
