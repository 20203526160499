import { DISABLED_CLASS_NAME, getTooltipMessage, SHOW_CLASS_NAME } from 'constants/tooltip';

export const getTranslationKeyByCommissionType = (isPNCEnabled: boolean, label: string) => {
  return isPNCEnabled ? 'pnc.' + label : label;
};

export const getTooltipInfo = (isDesktop: boolean, isPNCEnabled: boolean, key: string) => {
  const messages = getTooltipMessage(isDesktop, isPNCEnabled);

  const translationKey = messages[key];
  const resultClassName = `${SHOW_CLASS_NAME} ${translationKey ? DISABLED_CLASS_NAME : ''}`;

  return {
    tooltipKey: translationKey,
    tooltipClassName: resultClassName
  };
};
