import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

export const getHomeMarketData =
  (sportId: string) =>
  ({ home }: AppState) =>
    home.marketsData?.[sportId] ?? null;
export const getHomeMarketsRules =
  (sportId: string) =>
  ({ home }: AppState) =>
    home.rules?.[sportId] ?? null;
export const getHomeMarketsRulesLoading = ({ home }: AppState) => home.rulesLoading;
export const getHomeMarketsLoading = ({ home }: AppState) => home.loading;
export const getHomeMarketsEventsIds = (sportId: string) =>
  createSelector(getHomeMarketData(sportId), markets =>
    markets ? markets.marketCatalogueList.map(({ event }) => event.id) : []
  );
