import { useSelector } from 'react-redux';
import classnames from 'classnames';

import MatchStatIcon from 'components/StatisticsIcons/MatchStaticon';
import VideoIcon from 'components/StatisticsIcons/VideoIcon';
import { NONE } from 'constants/icons';
import { getAppDevice, getOperator } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { PopularEventType, SportId } from 'types';
import { getArrowUpByOperator } from 'utils/operator';

import styles from './styles.module.scss';

interface PopupContentProps {
  name: string;
  icon: string;
  type: string;
  videoStreamingEnabled?: boolean;
  eventId?: string;
  matchStatEnabled?: boolean;
  sportId?: string;
}

const PopularLinkContent = ({
  name,
  icon,
  type,
  videoStreamingEnabled,
  eventId,
  matchStatEnabled,
  sportId = ''
}: PopupContentProps) => {
  const device = useSelector(getAppDevice);
  const operator = useSelector(getOperator);

  const isMobile = device === Devices.MOBILE;
  const parentIcon = getArrowUpByOperator(operator);
  const hideArrow = type == PopularEventType.CUSTOM_POP_UP || isMobile;

  return (
    <>
      <div className={styles.name}>
        {icon && icon !== NONE && <i className={classnames('biab_sport-icon', icon, styles.icon)} />}
        <span>{name}</span>
        {videoStreamingEnabled && eventId && <VideoIcon isLeftSpace />}
        {matchStatEnabled && sportId && eventId && <MatchStatIcon isSoccer={sportId === SportId.SOCCER} isLeftSpace />}
      </div>
      {!hideArrow && <i className={classnames(parentIcon, styles.arrow_icon)} />}
    </>
  );
};

export default PopularLinkContent;
