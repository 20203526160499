export const LEFT_PANEL_COLLAPSE_GROUP = 'LEFT_PANEL_COLLAPSE_GROUP';
export const SPORT_COLLAPSE = 'SPORT_COLLAPSE';
export const FAVORITES_COLLAPSE = 'FAVORITES_COLLAPSE';
export const HOW_TO_COLLAPSE = 'HOW_TO_COLLAPSE';
export const ACTIVE_MARKETS_COLLAPSE = 'ACTIVE_MARKETS_COLLAPSE';
export const COLLAPSE_ROW_NAME = `COLLAPSE-${LEFT_PANEL_COLLAPSE_GROUP}-${SPORT_COLLAPSE}`;
export const SPORT_INNER_COLLAPSE = 'SPORT_INNER_COLLAPSE';
export const SPORT_INNER_COLLAPSE_MORE = 'SPORT_INNER_COLLAPSE_MORE';
export const SPORT_INNER_COLLAPSE_TOP_COMPETITIONS = 'SPORT_INNER_COLLAPSE_TOP_COMPETITIONS';
export const SPORT_INNER_COLLAPSE_TOP_COUNTRY = 'SPORT_INNER_COLLAPSE_TOP_COUNTRY';
export const SPORT_INNER_COLLAPSE_TIME_FILTER = 'SPORT_INNER_COLLAPSE_TIME_FILTER';
export const LEFT_PANEL_COLLAPSE_GROUP_ASIAN = 'LEFT_PANEL_COLLAPSE_GROUP_ASIAN';
export const ASIAN_VIEW_FAVORITES_COLLAPSE = 'ASIAN_VIEW_FAVORITES_COLLAPSE';
export const ASIAN_VIEW_BETSLIP_COLLAPSE = 'ASIAN_VIEW_BETSLIP_COLLAPSE';
