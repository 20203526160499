import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MarketSport from 'components/MarketSport';
import CashOutQuotesAndAutoCashOutsIntervalsInjection from 'injections/CashOutQuotesAndAutoCashOutsIntervalsInjection';
import { fetchListViewMarkets, resetListViewMarkets } from 'redux/modules/listViewTabs';
import { getListViewTopMarket } from 'redux/modules/listViewTabs/selectors';

const MarketSection = () => {
  const dispatch = useDispatch();
  const { sportId } = useParams();

  const topMarket = useSelector(getListViewTopMarket);

  useEffect(() => {
    if (sportId) {
      dispatch(resetListViewMarkets());
      dispatch(fetchListViewMarkets(sportId));
    }
  }, [sportId]);

  if (!topMarket?.marketId) {
    return null;
  }

  return (
    <>
      <CashOutQuotesAndAutoCashOutsIntervalsInjection
        marketId={topMarket.marketId}
        cashOutEnabled={topMarket.cashOutEnabled}
      />
      <MarketSport market={topMarket} />
    </>
  );
};

export default MarketSection;
