import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

import { getTranslation } from '../appConfigs/selectors';

const getMarket = (state: AppState) => state.market;
export const getSingleMarketInfo = createSelector(getMarket, ({ market }) => market);
export const getMarketsDropdownList = createSelector(getMarket, ({ dropdownMarkets }) => dropdownMarkets);
export const getMarketLines = createSelector(getMarket, ({ lines }) => lines);
export const getMarketLinesText = ({ market }: AppState) => market.linesText;
export const getMarketShowAll = ({ market }: AppState) => market.showAll;
export const getMarketReset = ({ market }: AppState) => market.reset;
export const getMarketScrollWidth = ({ market }: AppState) => market.scrollWidth;
export const getRaceChildren = createSelector(getMarket, ({ raceOptions }) => raceOptions?.children);
export const getRaceTabs = createSelector(getRaceChildren, getTranslation, (children = [], translation) => {
  return children.map(({ id, translations }) => ({ id, name: translations[translation] }));
});
export const getSingleMarketRules = createSelector(getMarket, ({ rules }) => rules);
export const getSingleMarketRulesLoading = ({ market }: AppState) => market.rulesLoading;
export const getSingleMarketLoading = ({ market }: AppState) => market.loading;
export const getSingleMarketEventId = ({ market }: AppState) => market.market?.event.id;
