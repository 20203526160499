import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { PREV_PAGE_BEFORE_LOGIN } from 'constants/app';
import { HOME_BASE_URL } from 'constants/locations';
import { getLoggedInLoading, getLoggedInStatusState } from 'redux/modules/auth/selectors';

interface PublicRouteProps {
  /**
   * The path where should be redirected if user is authenticated
   */
  redirectPath?: string;
}

const PublicRoute = ({ redirectPath = HOME_BASE_URL }: PublicRouteProps): ReactElement => {
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const loading = useSelector(getLoggedInLoading);

  if (isLoggedIn && !loading) {
    const redirect = localStorage.getItem(PREV_PAGE_BEFORE_LOGIN) || '';
    localStorage.removeItem(PREV_PAGE_BEFORE_LOGIN);

    return <Navigate replace to={redirect || redirectPath} />;
  }

  return <Outlet />;
};

export default PublicRoute;
