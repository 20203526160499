import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { ExchangeTypes, NavigationButtons, PLBetType } from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getIsAsianViewEnabled } from 'redux/modules/appConfigs/selectors';
import { getMobileSettingsActiveTab } from 'redux/modules/appSettings/selectors';
import { getAVBalance, getBalance } from 'redux/modules/user/selectors';
import { CookieNames } from 'types';

import styles from './styles.module.scss';

interface MobileAccountBalanceProps {
  className?: string;
}

const MobileAccountBalance = ({ className }: MobileAccountBalanceProps) => {
  const accountBalance = useSelector(getBalance);
  const accountAVBalance = useSelector(getAVBalance);
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const mobileSettingsActiveTab = useSelector(getMobileSettingsActiveTab);

  const [cookies] = useCookies([CookieNames.PROFITANDLOSS_BETTYPE, CookieNames.EXCHANGE_TYPE_NEW]);

  const { selectedTab } = useAccountUrlParams();
  const isSettingsPage = selectedTab === NavigationButtons.Settings;
  const isAsianView =
    selectedTab === NavigationButtons.ProfitAndLoss
      ? cookies.PROFITANDLOSS_BETTYPE === PLBetType.AsianView
      : cookies.EXCHANGE_TYPE_NEW === ExchangeTypes.AsianView;
  const isAsianMobileSettings = mobileSettingsActiveTab == ExchangeTypes.AsianView && isSettingsPage;

  const { noFormattedAmount: formattedBalance } = useFormatCurrency(
    (isAsianViewEnabled && (isAsianMobileSettings || (!isSettingsPage && isAsianView))
      ? accountAVBalance
      : accountBalance || accountAVBalance) || 0,
    '',
    {
      noRounding: true,
      isCheckIndian: true
    }
  );

  return (
    <span className={classNames(styles.balance, 'biab_btn', className)}>
      <i className={classNames('fa2 fa-wallet', styles.icon)} />
      {formattedBalance}
    </span>
  );
};

export default MobileAccountBalance;
