export enum InPlayFilter {
  ALL = 'all',
  HIGHLIGHTS = 'highlights',
  NOW = 'now'
}

export interface InPlaySport {
  id: string;
  name: string;
  type: string;
  parents: [];
  children: null;
  countryCode: null;
  startTime: null;
  venue: null;
  exchangeId: null;
  marketStartTime: null;
  marketType: null;
  numberOfWinners: number;
  displayMarketStartTime: boolean;
  liveScoreEnabled: boolean;
  runningBallEnabled: boolean;
  fancyMarketsEnabled: boolean;
  eventTypeId: null;
  closedDate: null;
  fancyView: boolean;
  hasMatchOdds: boolean;
  inPlay: boolean;
  marketOddsAvailable: boolean;
  highlighted: boolean;
  outrightMarket: boolean;
  iconName: null;
  realEvent: boolean;
  translations: Record<string, string>;
}
