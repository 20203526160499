import { TFunction } from 'react-i18next';

import { BASKETBALL_NORMAL_MATCH } from 'constants/app';
import { BasketballPlayStatus, TEventExtraData } from 'types';

export const getTimeLabel = ({
  inPlayMatchStatus,
  homeQuarterByQuarter,
  timeElapsed,
  updateDetails,
  t
}: {
  inPlayMatchStatus: string | null;
  homeQuarterByQuarter: number[];
  timeElapsed: number;
  updateDetails: TEventExtraData[];
  t: TFunction;
}) => {
  let label: string;

  const quartersLength = homeQuarterByQuarter?.length || 0;
  switch (inPlayMatchStatus) {
    case BasketballPlayStatus.FINISH:
      label = t('runningBall.basketball.finished');
      break;
    case BasketballPlayStatus.FINISH_HALF_END:
      label = t('runningBall.basketball.halfTime');
      break;
    case BasketballPlayStatus.STOP_QUARTER:
      label = t('runningBall.basketball.endOfQuarter');
      break;
    default:
      label = getTime({
        isOverTime: inPlayMatchStatus === 'Overtime',
        updateDetails,
        timeElapsed,
        homeQuarterByQuarter,
        t
      });
  }

  if (inPlayMatchStatus === BasketballPlayStatus.FINISH && quartersLength > BASKETBALL_NORMAL_MATCH) {
    label = t('runningBall.basketball.OTFinished');
  }

  return label;
};

const getTime = ({
  isOverTime,
  updateDetails = [],
  homeQuarterByQuarter,
  timeElapsed,
  t
}: {
  isOverTime: boolean;
  updateDetails: TEventExtraData[];
  homeQuarterByQuarter: number[];
  timeElapsed: number;
  t: TFunction;
}) => {
  const data = updateDetails.length
    ? updateDetails[updateDetails.length - 1].eventExtraData
    : { timeElapsedSeconds: 0 };
  const periodTime = data ? ':' + getTimeFormatted(data?.timeElapsedSeconds) : '';
  const label = isOverTime
    ? t('runningBall.basketball.OT')
    : getActiveQuarterLabel(homeQuarterByQuarter?.length || 0, t);

  return `${label} ${getTimeFormatted(timeElapsed) + periodTime}`;
};

const getTimeFormatted = (time: number) => ('0' + parseInt(time?.toString() || '0')).slice(-2);

export const getActiveQuarterLabel = (activeQuarter: number, t: TFunction) => {
  return activeQuarter > BASKETBALL_NORMAL_MATCH
    ? t('runningBall.basketball.OT')
    : activeQuarter
    ? t('runningBall.basketball.Q' + activeQuarter)
    : '';
};
