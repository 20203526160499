import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';

import { ProfitLossTablePopUpData, ProfitLossTablePopUpState } from './type';

const initialState: ProfitLossTablePopUpState = {
  profitLossTableData: null,
  profitLossTableDataLoading: false,
  profitLossTableDataError: null,
  profitLossTablePopUpEventId: null,
  stringifiedProfitLossTableData: '',
  profitLossTableDataFirstLoaded: false,
  profitLossTablePopUpMarketId: null,
  profitLossTableFTMarkets: [],
  profitLossTableHTMarkets: [],
  plMarketNamesLoading: false,
  plMarketNamesError: null
};

const slice = createSlice({
  name: SLICES_NAMES.PROFIT_LOSS_TABLE_POP_UP,
  initialState,
  reducers: {
    fetchProfitLossTableData: (
      state,
      { payload }: PayloadAction<{ eventId: string; marketId: string; isAsianView: boolean }>
    ) => {
      state.profitLossTableDataLoading = true;
      state.profitLossTablePopUpEventId = payload.eventId;
      state.profitLossTablePopUpMarketId = payload.marketId;
    },
    successFetchProfitLossTableData: (state, { payload }: PayloadAction<ProfitLossTablePopUpData>) => {
      state.profitLossTableDataLoading = false;
      state.profitLossTableData = payload;
      state.stringifiedProfitLossTableData = JSON.stringify(payload);
      state.profitLossTableDataError = null;

      if (!state.profitLossTableDataFirstLoaded) {
        state.profitLossTableDataFirstLoaded = true;
      }
    },
    failureFetchProfitLossTableData: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.profitLossTableDataLoading = false;
      state.profitLossTableDataError = payload;
      state.profitLossTableData = null;

      if (!state.profitLossTableDataFirstLoaded) {
        state.profitLossTableDataFirstLoaded = true;
      }
    },
    closeProfitLossTablePopUp: state => {
      state.profitLossTablePopUpEventId = null;
      state.profitLossTableData = null;
      state.stringifiedProfitLossTableData = '';
      state.profitLossTableDataError = null;
      state.profitLossTableDataLoading = false;
      state.profitLossTableDataFirstLoaded = false;
      state.profitLossTablePopUpMarketId = null;
      state.profitLossTableFTMarkets = [];
      state.profitLossTableHTMarkets = [];
      state.plMarketNamesLoading = false;
      state.plMarketNamesError = null;
    },
    setProfitLossTableLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.profitLossTableDataLoading = payload;
    },
    fetchProfitLossMarketNames: (state, _: PayloadAction<{ eventId: string; isAsianView: boolean }>) => {
      state.plMarketNamesLoading = true;
    },
    successFetchProfitLossMarketNames: (
      state,
      { payload }: PayloadAction<{ plHTMarkets: string[]; plFTMarkets: string[] }>
    ) => {
      state.plMarketNamesLoading = false;
      state.plMarketNamesError = null;
      state.profitLossTableHTMarkets = payload.plHTMarkets;
      state.profitLossTableFTMarkets = payload.plFTMarkets;
    },
    failureFetchProfitLossMarketNames: (state, { payload }) => {
      state.plMarketNamesLoading = false;
      state.plMarketNamesError = payload;
    }
  }
});

export const {
  fetchProfitLossTableData,
  successFetchProfitLossTableData,
  failureFetchProfitLossTableData,
  closeProfitLossTablePopUp,
  setProfitLossTableLoading,
  fetchProfitLossMarketNames,
  successFetchProfitLossMarketNames,
  failureFetchProfitLossMarketNames
} = slice.actions;

export default slice.reducer;
