import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { ASIAN_VIEW_LEAGUES_FILTER, ASIAN_VIEW_PAGE_PARAM } from 'constants/asianView';
import { asianViewComponents, asianViewMiddleSection as branding, asianViewPopUps } from 'constants/branding';
import {
  getIsDesktopInfiniteScrollEnabled,
  getIsMobileInfiniteScrollEnabled
} from 'redux/modules/appConfigs/selectors';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { saveSelectedLeagues, setIsLeaguesOpened } from 'redux/modules/asianViewLeaguesFilter/index';
import {
  getAsianViewLeaguesFilter,
  getIsLeaguesChanged,
  getLeaguesAmounts,
  getLeaguesCurParams
} from 'redux/modules/asianViewLeaguesFilter/selectors';
import { closeProfitLossTablePopUp } from 'redux/modules/profitLossTablePopUp';
import { mapAsianViewLeaguesToStore } from 'utils/asianView';

import LeaguesSelectAll from '../LeaguesSelectAll';

import styles from './styles.module.scss';

const LeaguesFooter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const leagues = useSelector(getAsianViewLeaguesFilter);
  const curParams = useSelector(getLeaguesCurParams);
  const isChanged = useSelector(getIsLeaguesChanged);
  const { selected } = useSelector(getLeaguesAmounts);
  const isDesktopInfiniteScrollEnabled = useSelector(getIsDesktopInfiniteScrollEnabled);
  const isMobileInfiniteScrollEnabled = useSelector(getIsMobileInfiniteScrollEnabled);
  const isMobileAsianView = useSelector(getIsMobileAsianView);

  const savedLeagues = localStorage.getItem(ASIAN_VIEW_LEAGUES_FILTER);
  const isInfiniteScrollEnabled = isMobileAsianView ? isMobileInfiniteScrollEnabled : isDesktopInfiniteScrollEnabled;

  const applyHandler = () => {
    if (curParams && leagues) {
      localStorage.setItem(
        ASIAN_VIEW_LEAGUES_FILTER,
        JSON.stringify({ ...JSON.parse(savedLeagues ?? '{}'), [curParams]: mapAsianViewLeaguesToStore(leagues) })
      );
    }

    dispatch(saveSelectedLeagues());
    dispatch(setIsLeaguesOpened(false));

    if (!isInfiniteScrollEnabled) {
      const page = searchParams.get(ASIAN_VIEW_PAGE_PARAM);

      if (page !== '0') {
        searchParams.set(ASIAN_VIEW_PAGE_PARAM, '0');
        setSearchParams(searchParams);
      }
    }

    dispatch(closeProfitLossTablePopUp());
  };

  const isApplyEnabled = isChanged && selected;

  return (
    <div
      className={classNames(styles.footer, asianViewPopUps.POP_UP_FOOTER, branding.LEAGUES_DROPDOWN_FOOTER, {
        [styles.footer__mobile]: isMobileAsianView
      })}
    >
      {!isMobileAsianView && <LeaguesSelectAll />}
      <div
        className={classNames(styles.buttonsGroup, {
          [styles.buttonsGroup__mobile]: isMobileAsianView
        })}
      >
        <button
          type="button"
          className={classNames(styles.button, asianViewComponents.SECONDARY_BTN, styles.button__cancel, {
            [styles.button__mobile]: isMobileAsianView
          })}
          onClick={() => dispatch(setIsLeaguesOpened(false))}
        >
          {t('asianView.labels.leaguesFilter.cancel')}
        </button>
        <button
          type="button"
          className={classNames(styles.button, styles.button__apply, asianViewComponents.PRIMARY_BTN, {
            [styles.button__disabled]: !isApplyEnabled,
            [styles.button__mobile]: isMobileAsianView
          })}
          disabled={!isApplyEnabled}
          onClick={applyHandler}
        >
          {t('asianView.labels.leaguesFilter.apply')}
        </button>
      </div>
    </div>
  );
};

export default LeaguesFooter;
