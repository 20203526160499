import { useSelector } from 'react-redux';
import { isUndefined } from 'lodash';

import useLayColumn from 'hooks/useLayColumn';
import { getCurrentGameIsLayOddsDisabled } from 'redux/modules/games/selectors';
import { TInlineSelectedBetStatusCheck } from 'redux/modules/inlinePlacement/type';
import {
  getIsBettingEnabledByMarketPricesId,
  getMarketPricesBettingTypeById,
  getMarketPricesIsSelectionNonRunner,
  getMarketPricesRunnerLockedBySelectionId,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { MarketStatus, PageBlocks } from 'types';
import { BetTypes } from 'types/bets';

export const useIsMobilePlacementDisabled = ({
  pageBlock,
  marketId,
  bettingType = '',
  selectionId,
  handicap,
  type,
  sportId
}: TInlineSelectedBetStatusCheck) => {
  const isGame = pageBlock === PageBlocks.GAME;

  const marketStatus = useSelector(getStatusByMarketPricesId(marketId));
  const bettingTypeById = useSelector(getMarketPricesBettingTypeById(marketId));

  const isLocked = useSelector(
    getMarketPricesRunnerLockedBySelectionId(marketId, +(selectionId ?? 0), +(handicap ?? 0))
  );

  const isBettingEnabled = useSelector(getIsBettingEnabledByMarketPricesId(marketId));
  const isGamesLayOddsDisabled = useSelector(getCurrentGameIsLayOddsDisabled(isGame && type === BetTypes.LAY));

  const isLay = type === BetTypes.LAY;

  const { isLayColumnEnabled } = useLayColumn({
    sportId: isLay && !isGame ? sportId : undefined,
    bettingType: isLay && !isGame ? bettingType || bettingTypeById : undefined
  });

  const isNonRunner = useSelector(getMarketPricesIsSelectionNonRunner(marketId, selectionId ?? 0, +(handicap ?? 0)));

  const isLayOddsDisabled = isGame ? isGamesLayOddsDisabled : !isLayColumnEnabled;
  const isSuspended = marketStatus === MarketStatus.SUSPENDED;

  const isDisabled =
    isSuspended ||
    isLocked ||
    (!isUndefined(isBettingEnabled) && !isBettingEnabled) ||
    isLayOddsDisabled ||
    isNonRunner;

  return { isDisabled, isBettingEnabled, isLayOddsDisabled, isLocked };
};
