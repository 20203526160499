import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { add, subtract } from 'lodash';

import { KEY_CODES } from 'constants/betslip';
import { useCurrency } from 'hooks/useCurrency';
import { usePlaceBetWithEnterKey } from 'hooks/usePlaceBetWithEnterKey';
import { useStakeRegexPattern } from 'hooks/usePrecision';
import { getCurrency, getCurrencySteps } from 'redux/modules/appConfigs/selectors';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { TBetslipSizeChangeProps, TSizeValidation } from 'redux/modules/betslip/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { getAccountSettings } from 'redux/modules/user/selectors';
import { BetTypes, MatchTypes, TSize } from 'types/bets';
import { EInputFieldTypes } from 'types/betslip';
import { formatFloatNumber } from 'utils/betValidation';
import { validateSize } from 'utils/size';

import InputField from '../InputField';

import styles from './styles.module.scss';

type BetSizeProps = {
  betUuid?: string;
  value: TSize;
  betType: BetTypes;
  currencyCode?: string;
  onChangeSize: ({ changedSize, isValid, errorMessage, focusedField }: TBetslipSizeChangeProps) => void;
  onEnterClick: () => void;
  onTabNavigate?: (fieldType: EInputFieldTypes) => void | null;
  fieldOrder?: number;
  isError?: boolean;
  isFocus?: boolean;
  isUnmatchedOpenedBetTab: boolean;
  isHighlighted?: boolean;
  offerId?: number;
  label?: string;
  customClassName?: string;
  isReadOnly?: boolean;
  matchType?: MatchTypes;
  bet?: TCurrentBet;
};

const BetSize = ({
  betUuid,
  value,
  betType,
  currencyCode,
  onChangeSize,
  onEnterClick,
  onTabNavigate,
  fieldOrder = 0,
  isError,
  isFocus,
  isUnmatchedOpenedBetTab,
  isHighlighted = false,
  offerId,
  label,
  customClassName,
  isReadOnly,
  matchType,
  bet
}: BetSizeProps) => {
  const { t } = useTranslation();

  const sizeRegexPattern = useStakeRegexPattern();

  const currency = useCurrency(currencyCode);
  const defaultCurrency = useSelector(getCurrency);
  const accountSettings = useSelector(getAccountSettings);
  const isGameBetSlip = useSelector(getIsGameBetSlip);

  const currencySteps = useSelector(getCurrencySteps);
  const inputRef = useRef<HTMLInputElement>(null);

  const isPlaceBetWithEnterKey = usePlaceBetWithEnterKey();

  const minSize = isGameBetSlip ? currency.gamesMinBetSize : currency.minBetSize;
  const maxSize = isGameBetSlip ? currency.gamesMaxBetSize : currency.maxBetSize;
  const isPlusBtnEnabled = +(value || 0) < maxSize;
  const isMinusBtnEnabled = +(value || 0) > minSize;
  const step = currency.step;

  const { minusStep, plusStep } = useMemo(() => {
    let minusSizeStep = 0,
      plusSizeStep = 0;

    currencySteps.forEach(curStep => {
      if (+(value || 0) >= curStep.min && (curStep.max == null || +(value || 0) < curStep.max)) {
        plusSizeStep = step > curStep.step ? step : curStep.step;
      }
      if (+(value || 0) > curStep.min && (curStep.max == null || +(value || 0) <= curStep.max)) {
        minusSizeStep = step > curStep.step ? step : curStep.step;
      }
    });

    return { minusStep: minusSizeStep, plusStep: plusSizeStep };
  }, [currencySteps, value, step]);

  const getValidation = (validateValue: TSize) => {
    return validateSize({
      size: validateValue,
      betType,
      currency,
      defaultCurrency,
      currencyCode,
      isOperatorBettingLimitsEnabled: false,
      isGame: isGameBetSlip
    });
  };

  const onChangeSizeHandler = (changedSize: TSize) => {
    const validation: TSizeValidation = getValidation(changedSize);

    onChangeSize({ changedSize, isValid: validation.isValid });
  };

  const onBlurSizeHandler = () => {
    const validation: TSizeValidation = getValidation(value);

    onChangeSize({
      changedSize: validation.validValue,
      isValid: !!validation.validValue,
      ...(validation.errorMessage
        ? { errorMessage: t(validation.errorMessage.text, validation.errorMessage.params ?? {}) }
        : {}),
      focusedField: null
    });
  };

  useEffect(() => {
    if (value === undefined && accountSettings?.defaultStake) {
      onChangeSizeHandler(accountSettings.defaultStakes?.find(i => i?.defaultValue)?.value || '');
    }
  }, [accountSettings?.defaultStake, value]);

  useEffect(() => {
    if (!value && matchType === MatchTypes.UNMATCHED && bet) {
      onChangeSize({ changedSize: bet.sizeRemaining });
    }
  }, []);

  useEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key === KEY_CODES.ARROW_UP && document.activeElement === inputRef.current && isPlusBtnEnabled) {
        event.preventDefault();
        onChangeSizeHandler(formatFloatNumber(add(parseFloat((value || 0).toString()), plusStep)));
      }
      if (event.key === KEY_CODES.ARROW_DOWN && document.activeElement === inputRef.current && isMinusBtnEnabled) {
        event.preventDefault();
        onChangeSizeHandler(formatFloatNumber(subtract(parseFloat((value || 0).toString()), minusStep)));
      }
    };
    if (isPlaceBetWithEnterKey) {
      window.addEventListener('keydown', onKeyPress);
    }

    return () => {
      if (isPlaceBetWithEnterKey) {
        window.removeEventListener('keydown', onKeyPress);
      }
    };
  }, [value, isPlaceBetWithEnterKey]);

  return (
    <div className={styles.bet}>
      <InputField
        betUuid={betUuid}
        value={value}
        inputPattern={sizeRegexPattern}
        onChange={onChangeSizeHandler}
        onBlur={onBlurSizeHandler}
        onEnterClick={onEnterClick}
        onTabNavigate={onTabNavigate}
        fieldType={EInputFieldTypes.SIZE}
        fieldTabIndex={fieldOrder}
        isError={isError}
        isFocus={isFocus}
        isUnmatchedOpenedBetTab={isUnmatchedOpenedBetTab}
        offerId={offerId}
        isHighlighted={isHighlighted}
        label={label}
        customClassName={customClassName}
        isReadOnly={isReadOnly}
        fieldRef={inputRef}
      />
    </div>
  );
};

export default BetSize;
