import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

export const getSearchLoading = ({ search }: AppState) => search.loading;
export const getSearchFinishLoaded = ({ search }: AppState) => search.isLoaded && !search.loading;
export const getCollapsedSports = ({ search }: AppState) => search.collapsedSports;
export const getSearchTotalElements = ({ search }: AppState) => search.searchList.totalElements;
export const getSearchResultsBySport = ({ search }: AppState) => search.searchList.resultsBySport;
export const getSearchSize = ({ search }: AppState) => search.searchList.size;
export const getSearchNumberOfElements = ({ search }: AppState) => search.searchList.numberOfElements;
export const getResultsBySportId =
  (id: string) =>
  ({ search }: AppState) =>
    search.searchList.resultsBySport[id];
export const getSearchResultsBySports = ({ search }: AppState) => search.searchList.resultsBySport;
export const getSearchContentByHeaders = ({ search }: AppState) => search.searchList.content;
export const getSearchIntersectingEventIds = ({ search }: AppState) => search.intersectingEventIds;
export const getSearchContentList = createSelector(getSearchContentByHeaders, content => Object.values(content).flat());
export const getSearchContentByHeadersKeys = createSelector(getSearchContentByHeaders, content => Object.keys(content));
export const getSearchResultBySportsKeys = createSelector(getSearchResultsBySports, results => Object.keys(results));
