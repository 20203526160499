import { useSelector } from 'react-redux';

import useAsianView from 'hooks/useAsianView';
import useDevice from 'hooks/useDevice';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { isShowTooltipHelper } from 'redux/modules/tooltip/selectors';
import { getContextualHelp } from 'redux/modules/tooltip/selectors';
import { getTooltipInfo } from 'utils/tooltip';

export default function useTooltip(key: string) {
  const { isAsianViewPage } = useAsianView();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const contextualHelp = useSelector(getContextualHelp);

  const { isMobile, isDesktop } = useDevice();
  const { tooltipKey } = getTooltipInfo(isDesktop, isPNCEnabled, key);

  const isTooltipIconEnabled = useSelector(isShowTooltipHelper(tooltipKey));

  const isContextualHelpEnabled = () => {
    if (isAsianViewPage) {
      return true;
    }

    return isLoggedIn ? contextualHelp : isMobile ? false : contextualHelp;
  };

  return {
    translationKey: tooltipKey,
    isEnabled: isContextualHelpEnabled(),
    isShowIcon: isTooltipIconEnabled
  };
}
