import { BetTypes, BetTypeValue } from 'constants/myBets';
import { FailureActionPayload, OddsType } from 'types';

export enum EBetSide {
  Lay = 'LAY',
  Back = 'BACK'
}

export type MyBets = {
  data: TMyBetsResponse;
  statementData: TStatementResponse;
  PLData: TPLResponse;
  isMyBetsFirstLoaded: boolean;
  isProfitLossFirstLoaded: boolean;
  isStatementFirstLoaded: boolean;
  PLTotal: number;
  loadingPLTotal: boolean;
  PLTotalEvent: number;
  loadingPLTotalEvent: boolean;
  isOnMyBetsPage: boolean;
  loading: boolean;
  cancelAllUnmatchedBets: boolean;
  cancelAllUnmatchedBetsActionId: number | null;
  showBetDetails: boolean;
  showStatementDetails: boolean;
  bet?: TMyBetsContentItem;
  statement?: TStatementContentItem;
  cancelledBet?: TMyBetsContentItem;
  betUpdated: boolean;
  startDate: Date;
  endDate: Date;
  timeRange: { translationKey: string; name: string };
  pageSize: number;
  pageNumber: number;
  charges: string[];
  sportsList: Sport[];
  loadingSportsList: boolean;
  gamesList: Game[];
  loadingGamesList: boolean;
  multiCurrencyValue: string | null;
  loadingProducts: boolean;
  accountProducts: TAccountProducts;
  isCustomPeriod: boolean;
  error: FailureActionPayload | null;
};

export type TAccountProducts = Record<TBetsType, TAccountProduct | null>;

export type TAccountProduct = Record<string, boolean>;

export type Game = {
  name: string;
  channelId: number;
  gameId: number;
  translations?: Record<string, string>;
};
export type Sport = {
  id: string;
  name: string;
  translations?: Record<string, string>;
};

export type TimeRange = { translationKey: string; name: string };

export const BetsType = {
  All_MY_BETS: 'allMyBets',
  ALL_PROFIT_AND_LOSS: 'allProfitAndLoss',
  ALL_ACCOUNT_STATEMENT: 'allAccountStatement'
} as const;

export type TBetsType = typeof BetsType[keyof typeof BetsType];

export type TAccountProductsPayload = {
  currency: string;
  betsType: TBetsType;
};

export type SuccessAccountProduct = Record<TBetsType, TAccountProduct>;

export type TGetBetPayload = {
  betsType: BetTypes;
  startDate: string | number | (() => number);
  endDate: string | number | (() => number);
  size: number;
  page: number;
  currency?: string;
  asianView?: boolean;
  bettingDay?: boolean;
};
export type TFetchStatementPayload = Omit<TGetBetPayload, 'betsType'> & { accountStatementOrigin: string };

export type IGetPLPayload = {
  startDate: string | number | (() => number);
  endDate: string | number | (() => number);
  size: number;
  page: number;
  eventTypeId?: string;
  betType: BetTypeValue;
  currency?: string;
  asianView?: boolean;
  bettingDay?: boolean;
};

export type IGetPLTotalPayload = {
  startDate: string | number | (() => number);
  endDate: string | number | (() => number);
  betType: string;
  eventTypeId?: string;
  currency?: string;
  asianView?: boolean;
  bettingDay?: boolean;
};

export type ICancelAllUnmatchedBetsPayload = { betTypes: string[] };

export type ICancelBetPayload = {
  params: {
    [key: string]: {
      offerId: number;
      size: string;
      handicap: string;
      price: number;
      betType: string;
      selectionId: string;
    }[];
  };
  bet: TMyBetsContentItem;
};

export type TCancelAllUnmatchedBetsResponse = number;

export type TCancelBetResponse = { status: string; error: Error | null };

export type TMyBetsResponse = {
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: TMyBetsContentItem[] | any[];
};

export type TStatementResponse = {
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: TStatementContentItem[];
};

export type TPLResponse = {
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: TPLContentItem[];
};

export type TPLTotalResponse = {
  totalProfit: number;
};

export type TProfitSportsResponse = Sport[];
export type TProfitGamesResponse = Game[];

export type TChargesResponse = string[];

export type TStatementContentItem = {
  alternativeBackOdds: string | number | null;
  alternativeBackOddsRounded: string | number | null;
  asian?: boolean;
  avgPrice: number;
  avgPriceRounded: number;
  balance: string;
  bettingType: string;
  commissionType: string;
  credit: string;
  currency: string;
  eachWayDivisor: number;
  eventTypeId: string;
  debit: string;
  description: string;
  disabledLayOdds: boolean;
  inPlay?: boolean;
  liability?: number;
  numberOfWinners: number;
  oddsType?: OddsType;
  placedDate: number;
  score?: string;
  settledDate: number;
  side: string;
  size: string;
  status: string;
  refId: string;
  triggeredByCashOut: boolean;
};

export type TPLContentItem = {
  betType: string;
  commissionSum: null;
  eventName: string;
  marketId: string;
  marketName: string;
  profit: string;
  raceName: string;
  settledDate: number;
  startTime: number;
};

export type TMyBetsContentItem = {
  asian?: boolean;
  alternativeBackOdds: string | number;
  alternativeBackOddsRounded: string | number;
  averagePrice: number;
  averagePriceRounded: number;
  betType: string;
  bettingType: string;
  cancelledByOperator: boolean;
  cancelledDate: number;
  competitionId?: number;
  commissionType: string;
  currency: string;
  currencyExRate: string;
  currencyRate: number;
  disabledLayOdds: boolean;
  eventId: string;
  eventName: string;
  eventTypeId: number;
  fancyView: boolean;
  groupName: string;
  handicap: string;
  inPlay?: boolean;
  interval: number;
  liability: string;
  numberOfWinners: number;
  marketId: string;
  marketName: string;
  marketStartDate: number;
  marketType: string;
  marketUnit: string;
  masterAccountId: number;
  masterCurrency: string;
  matchedDate: number;
  maxUnitValue: number;
  minUnitValue: number;
  oddsType: OddsType;
  offerId: number;
  offerState: string;
  outright?: boolean;
  pastTotalLiability: string;
  persistenceEnabled: boolean;
  persistenceType: string;
  placedDate: number;
  potentialProfit: string;
  price: number;
  profit: string;
  profitNet: string;
  raceName: string;
  score?: string;
  side: EBetSide;
  size: string;
  sizeRemaining: string;
  totalWinnings: string;
  selectionId: string;
  selectionName: string;
  settledDate: number;
  sportName: string;
  triggeredByCashOut: boolean;
};
