import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import AsianViewMobileMenuButton from 'components/AsianViewMobileHeader/partials/AsianViewMobileMenuButton';
import CashOut from 'components/CashOut';
import CashOutRulesModal from 'components/CashOutRulesModal';
import AsianToolbarWrapper from 'components/MobileToolbar/components/AsianToolbarWrapper';
import ReloadBtn from 'components/ReloadBtn';
import { asianViewMiddleSection } from 'constants/branding';
import useElementSize from 'hooks/useElementSize';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getIsLandscapeAsianView, getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { getIsAsianViewCashOutMarkets } from 'redux/modules/asianViewCashOut/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { PagesFromBackend } from 'redux/modules/pages/types';

import styles from './styles.module.scss';

type AsianCashOutPageHeaderProps = {
  onReload: () => void;
};

const AsianCashOutPageHeader = ({ onReload }: AsianCashOutPageHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isMobile = useSelector(getIsMobileAsianView);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);
  const isCashOutMarkets = useSelector(getIsAsianViewCashOutMarkets);

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.MOBILE_ASIAN_SUB_HEADER, height }));
    }
  });

  const [isModalOpened, setIsModalOpened] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.MOBILE_ASIAN_SUB_HEADER, height: 0 }));
    };
  }, []);

  return (
    <AsianToolbarWrapper>
      <div
        ref={componentRef}
        className={classNames(styles.header, asianViewMiddleSection.HEADER, asianViewMiddleSection.MOBILE_SUB_HEADER, {
          [styles.header__mobile]: isMobile
        })}
      >
        {isMobile && <AsianViewMobileMenuButton containerClassName={styles.header__menuBtn} />}
        <div className={classNames(styles.header__left, { [styles.header__left__mobile]: isMobile })}>
          <CashOut
            showLabel={false}
            showInfoIcon={false}
            className={{ icon: styles.cashOutIcon }}
            clickable={!isMobile || isCashOutMarkets}
            isAvPLTableAvailable
          />
          <h2 className={styles.header__label}>{t('asianView.labels.marketLinks.cashOut')}</h2>
        </div>
        {isLoggedIn && (
          <div className={styles.header__right}>
            <ReloadBtn
              isShowTitle={!isMobile || isLandscapeView}
              containerClassName={classNames(styles.header__reload, {
                [styles.header__reloadMobile]: isMobile,
                [styles.header__reloadMobile__landscape]: isLandscapeView,
                [asianViewMiddleSection.MOBILE_SUB_HEADER_BTN]: isMobile
              })}
              iconClassName={classNames({ [styles.header__reloadMobile__icon]: isMobile })}
              onReload={onReload}
            />
            {isMobile && (
              <button
                className={classNames(styles.header__rules, asianViewMiddleSection.MOBILE_SUB_HEADER_BTN, {
                  [styles.header__rules__landscape]: isLandscapeView
                })}
                onClick={() => setIsModalOpened(true)}
              >
                <i className={classNames('av-icon av-icon-info', styles.header__rules__icon)}>
                  <span className={classNames('path1', styles.path1)} />
                  <span className={classNames('path2', styles.path2)} />
                </i>
                {isLandscapeView && <span>{t('rules.title')}</span>}
              </button>
            )}
          </div>
        )}
      </div>
      {isMobile && isModalOpened && (
        <CashOutRulesModal
          link={PagesFromBackend.CASH_OUT_RULES}
          openModal={isModalOpened}
          setOpenModal={setIsModalOpened}
          modalClassNames={{
            header: styles.modal__header,
            body: styles.modal__body,
            content: classNames(styles.modal__content, {
              [styles.modal__content__landscape]: isLandscapeView
            }),
            title: styles.modal__title,
            closeIcon: styles.modal__closeIcon
          }}
        />
      )}
    </AsianToolbarWrapper>
  );
};

export default AsianCashOutPageHeader;
