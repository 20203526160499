import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { failureFetchPLTable, fetchPLTable, successFetchPLTable } from './index';
import { TResponsePLTable } from './type';

function* getPLTable(action: ReturnType<typeof fetchPLTable>) {
  try {
    const { marketId, isLine } = action.payload;
    const response: TResponsePLTable = yield call(
      isLine ? api.plTable.getLinePlTable : api.plTable.getAHPlTable,
      marketId
    );
    yield put(successFetchPLTable(response));
  } catch (error: any) {
    yield put(failureFetchPLTable(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchPLTable.type, getPLTable)]);
}
