import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getPwdLink } from 'redux/modules/appConfigs/selectors';

import styles from './styles.module.scss';

export const Setting = () => {
  const pwdUrl = useSelector(getPwdLink);

  return (
    <div className={classNames('biab_login-form biab_settings-btn-wrap', styles.container)}>
      <div className="biab_login-form-col">
        <a className={classNames('biab_login-submit', styles.iconContainer)} href={pwdUrl}>
          <i className={classNames('fa2 fa2-cog', styles.icon)} />
        </a>
      </div>
    </div>
  );
};

export default Setting;
