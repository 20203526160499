import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getOperator } from 'redux/modules/appConfigs/selectors';
import { getCollapsableSports } from 'redux/modules/favorites/selectors';
import { TFavorite } from 'redux/modules/favorites/type';

import CollapsibleRow from '../CollapsibleRow';

import styles from './styles.module.scss';

interface CollapsibleListProps {
  /**
   * Favorites that should be rendered
   */
  favorites: TFavorite[];
  /**
   * Custom class name
   */
  customClassName?: string;
  /**
   * Determine the calling direction of component
   */
  isPopup?: boolean;
}

const CollapsibleList = ({ favorites, customClassName = '', isPopup = false }: CollapsibleListProps) => {
  const operator = useSelector(getOperator);
  const collapsableSports = useSelector(getCollapsableSports);

  return (
    <ul
      className={classNames('biab_modal-favorite_dialog', styles.collapsibleRow__container, customClassName, {
        'biab_fav-content': isPopup
      })}
    >
      {favorites.map(favorite => (
        <CollapsibleRow
          favorite={favorite}
          key={favorite.entryId}
          operator={operator}
          isPopup={isPopup}
          isCollapsed={collapsableSports[favorite.entryId]}
        />
      ))}
    </ul>
  );
};

export default CollapsibleList;
