import { useEffect, useState } from 'react';

export const useOverLayFadeOutTimer = (delay: number) => {
  const [showOverlay, setShowOverLay] = useState<boolean>(false);
  useEffect(() => {
    if (showOverlay) {
      const timer = setTimeout(() => setShowOverLay(false), delay);

      return () => clearTimeout(timer);
    }
  }, [delay, showOverlay]);

  return { showOverlay, setShowOverLay };
};
