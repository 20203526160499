import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import useDevice from 'hooks/useDevice';
import { fetchAppConfigs } from 'redux/modules/appConfigs';
import { getLanguage, getSupportedLocales } from 'redux/modules/appConfigs/selectors';
import { DropdownItem } from 'types';
import { getLanguageCookieName } from 'utils/cookie';
import { parseDomainFromUrl } from 'utils/url';

import styles from './Localization.module.scss';

interface LocalizationProps {
  /**
   * How large should the button be?
   */
  title?: boolean;

  /**
   * Should be icon shown or not?
   */
  label?: boolean;
}

const Localization = ({ title = false, label = true }: LocalizationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const languageCookieName = getLanguageCookieName();
  const [cookies, setCookie] = useCookies([languageCookieName]);

  const language = useSelector(getLanguage);
  const supportedLocales = useSelector(getSupportedLocales);

  const { isMobile } = useDevice();

  const locales = supportedLocales.map((item, index) => ({
    id: index,
    value: item.code,
    label: t(`locale.${item.code}`),
    image: `locales/${item.code}.svg`
  }));
  const selectedLocale = locales.find(locale => locale.value === language);

  const languageCookie = cookies[languageCookieName];

  const setLanguageCookie = (value: string) => {
    setCookie(languageCookieName, value, {
      path: '/',
      domain: parseDomainFromUrl(
        window.location.hostname,
        window.environmentConfig?.langCookieDomainPattern ||
          (process.env.REACT_APP_LANG_COOKIE_DOMAIN_PATTERN as string)
      )
    });
  };

  const onSelectLocal = (local: DropdownItem) => {
    setLanguageCookie(local.value);
    dispatch(fetchAppConfigs({ changeLoadingState: false }));
    window.location.reload();
  };

  useEffect(() => {
    if (language && !languageCookie) {
      setLanguageCookie(language);
    }
  }, [language, languageCookie]);

  if (!locales.length || !selectedLocale) {
    return null;
  }

  return (
    <Dropdown
      data={locales}
      defaultValue={selectedLocale}
      label={label}
      title={title}
      isInHeader
      onSelectOption={onSelectLocal}
      classes={{ placeholder: classNames({ [styles.localization__placeholder]: isMobile }) }}
    />
  );
};

export default Localization;
