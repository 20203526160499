import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import useDeviceSettings from 'hooks/useDeviceSettings';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setContextualHelp } from 'redux/modules/tooltip';
import { setSettings } from 'redux/modules/user';
import {
  getIsAccountSettingsContextualHelpEnabled,
  getIsAccountSettingsContextualHelpEnabledMobile,
  getIsUserAccountSettings,
  getIsUserInfo
} from 'redux/modules/user/selectors';
import { CookieNames } from 'types';
import { getBooleanValue } from 'utils';

const useContextualHelp = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([CookieNames.SHOW_TOOLTIPS]);

  const { contextualHelp } = useDeviceSettings();
  const device = useSelector(getAppDevice);
  const isUserInfo = useSelector(getIsUserInfo);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const isContextualHelpEnabled = useSelector(getIsAccountSettingsContextualHelpEnabled);
  const isContextualHelpEnabledMobile = useSelector(getIsAccountSettingsContextualHelpEnabledMobile);

  const tooltipCookie = cookies[CookieNames.SHOW_TOOLTIPS];

  useEffect(() => {
    if (isLoggedIn && isUserInfo) {
      const contextualValue = device === Devices.MOBILE ? isContextualHelpEnabledMobile : isContextualHelpEnabled;

      dispatch(setContextualHelp(!!contextualValue));
      setCookie(CookieNames.SHOW_TOOLTIPS, contextualValue, { path: '/' });
    } else {
      if (contextualHelp) {
        const defaultValue = tooltipCookie ? getBooleanValue(tooltipCookie) || contextualHelp : contextualHelp;
        dispatch(setContextualHelp(defaultValue));
        setCookie(CookieNames.SHOW_TOOLTIPS, defaultValue, { path: '/' });
      }
    }
  }, [contextualHelp, isContextualHelpEnabled, isContextualHelpEnabledMobile, isLoggedIn, setCookie, dispatch]);

  const handleToggleSwitch = (checked: boolean) => {
    if (isLoggedIn && isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            contextualHelpEnabled: device === Devices.DESKTOP ? checked : !!isContextualHelpEnabled,
            contextualHelpEnabledMobile: device === Devices.MOBILE ? checked : !!isContextualHelpEnabledMobile
          }
        })
      );
    } else {
      dispatch(setContextualHelp(checked));
      setCookie(CookieNames.SHOW_TOOLTIPS, checked, { path: '/' });
    }
  };

  return {
    handleToggleSwitch
  };
};

export default useContextualHelp;
