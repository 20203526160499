import { ReactNode, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { myBetsDateFormat, myBetsDateFormatUS, NavigationButtons, SINGLE_WALLET } from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import useOnClickOutside from 'hooks/useOnClickOutside';
import CancelAllButton from 'pages/MyExchangeBetsPage/components/CancelAllButton';
import {
  getIsAmericanDateFormatEnabled,
  getTimezone,
  getTimezoneCookieEnabled,
  getWalletIntegrationType
} from 'redux/modules/appConfigs/selectors';
import { TPeriods } from 'types';
import { TGetStatementData } from 'types/myBets';
import { addDays, getDaysDifference, getTodayDate, getTodayEndDate } from 'utils/date';
import { getIsDateSelected } from 'utils/myBetsValues';

import CalendarCustomContainer from './components/CalendarCustomContainer';
import DatePickerCustomHeader from './components/DatePickerCustomHeader';
import PeriodDropdown from './components/PeriodDropdown';
import SelectStatementFilter from './components/SelectStatementFilter/SelectStatementFilter';

import styles from './MyBetsDatePickers.module.scss';

interface DatePickersProps {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  handleResetDates: () => void;
  selectedTab: string;
  minDate?: Date;
  statementFilterValue?: string;
  setStatementFilterValue?: (value: string) => void;
  showCancelUnmatchedBetsBtn?: boolean;
  onRefreshTableData?: (params: TGetStatementData) => void;
  handlePeriodDropdown?: (period: TPeriods) => void;
  children?: ReactNode;
  bettingDay?: boolean;
  timezoneOffset?: string;
  customClass?: string;
}

const DatePickers = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleResetDates,
  showCancelUnmatchedBetsBtn,
  selectedTab,
  minDate,
  statementFilterValue,
  setStatementFilterValue,
  handlePeriodDropdown = () => {},
  children,
  bettingDay,
  timezoneOffset,
  customClass
}: DatePickersProps) => {
  const { t } = useTranslation();

  const walletIntegrationType = useSelector(getWalletIntegrationType);
  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);

  const [startDatePickerIsOpen, setStartDatePickerIsOpen] = useState<boolean>(false);
  const [endDatePickerIsOpen, setEndDatePickerIsOpen] = useState<boolean>(false);

  const { isMobile } = useDevice();

  const startDatepickerRef = useRef<HTMLDivElement>(null);
  const endDatepickerRef = useRef<HTMLDivElement>(null);

  const todayDate = getTodayEndDate(timezoneOffset || timezone, timezoneCookieEnabled || !!bettingDay);
  const dayDifference = getDaysDifference(getTodayDate(), todayDate);
  const startDateAdjusted = addDays(startDate, dayDifference);
  const endDateAdjusted = addDays(endDate, dayDifference);

  const isSingleWallet = walletIntegrationType === SINGLE_WALLET;

  const handleChangeStartDate = (date: Date) => {
    setStartDate(addDays(date, -1 * dayDifference));
    setStartDatePickerIsOpen(false);
  };

  const handleChangeEndDate = (date: Date) => {
    setEndDate(addDays(date, -1 * dayDifference));
    setEndDatePickerIsOpen(false);
  };

  const handleClickStartDatePicker = () => setStartDatePickerIsOpen(!startDatePickerIsOpen);
  const handleClickEndDatePicker = () => setEndDatePickerIsOpen(!endDatePickerIsOpen);

  const handleChangeStatementFilterValue = (value: string) => {
    if (setStatementFilterValue) {
      setStatementFilterValue(value);
    }
  };

  useOnClickOutside(startDatepickerRef, () => setStartDatePickerIsOpen(false));
  useOnClickOutside(endDatepickerRef, () => setEndDatePickerIsOpen(false));

  const renderStartDateDayContents = (day: number, date: Date) => {
    const isDateSelected = getIsDateSelected(date, startDate);

    return (
      <div
        className={classNames({
          'ui-state-disabled': date > todayDate || (minDate && date < minDate)
        })}
      >
        <span
          data-month={isDateSelected ? date.getMonth() : ''}
          data-year={isDateSelected ? date.getFullYear() : ''}
          className={classNames({
            'ui-state-active': isDateSelected,
            'ui-state-default': !isDateSelected
          })}
        >
          {day}
        </span>
      </div>
    );
  };

  const renderEndDateDayContents = (day: number, date: Date) => {
    const isDateSelected = getIsDateSelected(date, endDate);

    return (
      <div
        className={classNames({
          'ui-state-disabled': date > todayDate || date < startDate
        })}
      >
        <span
          data-month={isDateSelected ? date.getMonth() : ''}
          data-year={isDateSelected ? date.getFullYear() : ''}
          className={classNames({
            'ui-state-active': isDateSelected,
            'ui-state-default': !isDateSelected
          })}
        >
          {day}
        </span>
      </div>
    );
  };

  const DatePickerBlock = (
    <>
      <div
        ref={startDatepickerRef}
        className={classNames(styles.datePickerWrapper, 'biab_datepicker-item', {
          [styles.wrapperFrom]: !isMobile,
          [styles.datePickerWrapper__mobile]: isMobile
        })}
      >
        <label className={classNames(styles.datepickerLabel, styles.from)} onClick={handleClickStartDatePicker}>
          {t('account.labels.from')}
        </label>
        <div
          className={classNames('biab_datepicker-wrapper', {
            [styles.inputWrapper]: isMobile
          })}
        >
          <DatePicker
            calendarStartDay={americanDateFormatEnabled ? 0 : 1}
            dateFormat={americanDateFormatEnabled ? myBetsDateFormatUS : myBetsDateFormat}
            selected={startDateAdjusted}
            onChange={handleChangeStartDate}
            {...(minDate ? { minDate: minDate, startDate: minDate } : {})}
            maxDate={todayDate}
            className="biab_datepicker-input from-input"
            calendarClassName={styles.calendarHolder}
            onInputClick={handleClickStartDatePicker}
            open={startDatePickerIsOpen}
            calendarContainer={CalendarCustomContainer}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled
            }) => (
              <DatePickerCustomHeader
                date={date}
                increaseMonth={increaseMonth}
                decreaseMonth={decreaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
              />
            )}
            dayClassName={() => styles.dayClassname}
            renderDayContents={renderStartDateDayContents}
            onKeyDown={e => {
              e.preventDefault();
            }}
          />
          <i className="fa2 fa2-calendar" onClick={handleClickStartDatePicker} />
        </div>
      </div>
      <div
        ref={endDatepickerRef}
        className={classNames(styles.datePickerWrapper, 'biab_datepicker-item', {
          [styles.wrapperTo]: !isMobile,
          [styles.datePickerWrapper__mobile]: isMobile
        })}
      >
        <label
          className={classNames(styles.datepickerLabel, {
            [styles.labelTo]: !isMobile
          })}
          onClick={handleClickEndDatePicker}
        >
          {t('account.labels.to')}
        </label>
        <div
          className={classNames('biab_datepicker-wrapper', {
            [styles.inputWrapper]: isMobile
          })}
        >
          <DatePicker
            calendarStartDay={americanDateFormatEnabled ? 0 : 1}
            dateFormat={americanDateFormatEnabled ? myBetsDateFormatUS : myBetsDateFormat}
            selected={endDateAdjusted}
            onChange={handleChangeEndDate}
            maxDate={todayDate}
            minDate={startDateAdjusted}
            className="biab_datepicker-input to-input"
            calendarClassName={styles.calendarHolder}
            open={endDatePickerIsOpen}
            onInputClick={handleClickEndDatePicker}
            calendarContainer={CalendarCustomContainer}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled
            }) => (
              <DatePickerCustomHeader
                date={date}
                increaseMonth={increaseMonth}
                decreaseMonth={decreaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
              />
            )}
            dayClassName={() => styles.dayClassname}
            renderDayContents={renderEndDateDayContents}
            onKeyDown={e => {
              e.preventDefault();
            }}
          />
          <i className="fa2 fa2-calendar" onClick={handleClickEndDatePicker} />
        </div>
      </div>
    </>
  );

  return (
    <>
      {selectedTab === NavigationButtons.Statement && isMobile ? (
        <div className={styles.accountStmntMobHolderWrapper}>
          <div
            className={classNames(styles.mobileAccStatementDatepickerHolder, 'biab_datepicker-holder', {
              [styles.mobileAccStatementDatepickerHolder__mobile]: isMobile
            })}
          >
            <div className={classNames('biab_datepicker-form', styles.datepickerForm)}>
              <div
                className={classNames(styles.datepickerItemHolder, {
                  [styles.datepickerItemHolder__mobile]: isMobile
                })}
              >
                <div className={styles.datepickerItemInner}>
                  <div className={styles.datepickerSettings}>
                    <PeriodDropdown
                      startDate={startDate}
                      endDate={endDate}
                      dayShift={dayDifference}
                      onChange={handlePeriodDropdown}
                    />
                    <div className="biab_datepicker-item">
                      <button
                        type="button"
                        onClick={handleResetDates}
                        className={classNames(styles.resetDatepicker, 'biab_reset-datepicker', {
                          [styles.resetDatepicker__mobile]: isMobile
                        })}
                      >
                        {t('account.labels.resetDates')}
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className={styles.mobileStatementDatepickerBlock}>{DatePickerBlock}</div>
                    <div className={classNames('biab_datepicker-item', styles.resetDatepickerHolder)} />
                  </div>
                  {!isSingleWallet && (
                    <div
                      className={classNames(styles.statementFilter, {
                        [styles.statementFilter__mobile]: isMobile
                      })}
                    >
                      <div
                        className={classNames(styles.statementFilter__label, 'biab_deposit-filter-label', {
                          [styles.statementFilter__mobile__label]: isMobile
                        })}
                      >
                        {t('account.statement.labels.filterBy')}
                      </div>
                      <div
                        className={classNames(styles.statementFilter__selectHolder, 'biab_deposit-filter', {
                          [styles.statementFilter__mobile__selectHolder]: isMobile
                        })}
                      >
                        <div className={classNames('biab_account-page ui-selectmenu-menu', styles.selectWrapper)}>
                          <SelectStatementFilter
                            handleChangeStatementFilterValue={handleChangeStatementFilterValue}
                            statementFilterValue={statementFilterValue}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={classNames(styles.datepickersAndCancelUnmatched, 'biab_datepicker-holder', customClass, {
            [styles.datepickersAndCancelUnmatched__accountStatement]: selectedTab === NavigationButtons.Statement,
            [styles.datepickersAndCancelUnmatched__mobile]: isMobile
          })}
        >
          {isMobile ? (
            <>
              {selectedTab === NavigationButtons.MyBets && (
                <div className={classNames('biab_datepicker-holder-page', styles.mobileDatepickersWrapper)}>
                  <div className={classNames('biab_datepicker-form', styles.datepickerForm)}>
                    <div className={styles.datepickerItemHolder}>
                      <div className={styles.myBetsMobileDatesTop}>
                        <div className={styles.periodHolder}>
                          <PeriodDropdown
                            startDate={startDate}
                            endDate={endDate}
                            dayShift={dayDifference}
                            onChange={handlePeriodDropdown}
                          />
                        </div>
                        <div className="biab_datepicker-item">
                          <button
                            type="button"
                            onClick={handleResetDates}
                            className={classNames(styles.resetDatepicker, 'biab_reset-datepicker')}
                          >
                            {t('account.labels.resetDates')}
                          </button>
                        </div>
                      </div>
                      <div className={styles.datepickers}>{DatePickerBlock}</div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className={classNames(styles.datepickers, 'biab_datepicker-form')}>
              <PeriodDropdown
                startDate={startDate}
                endDate={endDate}
                dayShift={dayDifference}
                onChange={handlePeriodDropdown}
              />
              {DatePickerBlock}
              <div className={classNames(styles.datepickerLabel, styles.resetDates, 'biab_datepicker-item')}>
                <span className="biab_reset-datepicker" onClick={handleResetDates}>
                  {t('account.labels.resetDates')}
                </span>
              </div>
            </div>
          )}
          {selectedTab === NavigationButtons.MyBets && isMobile && showCancelUnmatchedBetsBtn && (
            <div className={styles.cancelAllUnmatchedBtnWrapper}>
              <CancelAllButton />
            </div>
          )}
          {selectedTab === NavigationButtons.Statement && !isSingleWallet && (
            <div className={styles.statementFilter__andRefreshButton}>
              <div className={styles.statementFilter}>
                <div className={classNames(styles.statementFilter__label, 'biab_deposit-filter-label')}>
                  {t('account.statement.labels.filterBy')}
                </div>
                <div className={classNames(styles.statementFilter__selectHolder, 'biab_deposit-filter')}>
                  <div className="biab_account-page ui-selectmenu-menu">
                    <SelectStatementFilter
                      handleChangeStatementFilterValue={handleChangeStatementFilterValue}
                      statementFilterValue={statementFilterValue}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {children}
        </div>
      )}
    </>
  );
};

export default DatePickers;
