import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isString } from 'lodash';

import { SLICES_NAMES } from 'constants/app';
import { EBetFocusFields } from 'redux/modules/betslip/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import {
  BetslipType,
  TCancelBet,
  TEditBet,
  TPlaceBetsDataPayload,
  TPlacedBetsByMarket,
  TPlacementError
} from 'redux/modules/placement/type';
import { PageBlocks } from 'types';
import { TPrice, TSize } from 'types/bets';
import { Actions, MobilePlacementType } from 'types/inlinePlacement';
import { checkUniqSelectedBetKey, getUniqSelectedBetKey } from 'utils/betslip';

import { MobilePlacementNotificationPayload, TInlinePlacementState, TInlineSelectedBet } from './type';

const initialState: TInlinePlacementState = {
  bets: {},
  mobilePlacementNotifications: {},
  mobileInlineSelectedBetsToRestore: {},
  processingMobileBets: {},
  isMobilePlacementConfirmationStep: false
};

const slice = createSlice({
  name: SLICES_NAMES.INLINE_PLACEMENT,
  initialState,
  reducers: {
    setInlineSelectedBet: (
      state,
      { payload }: PayloadAction<{ selectedBet: TInlineSelectedBet; isMobilePlacement?: boolean }>
    ) => {
      const bet = { ...payload.selectedBet, isPristinePrice: true, betslipType: BetslipType.INLINE };
      const betKey = getUniqSelectedBetKey(bet);

      if (payload.isMobilePlacement) {
        state.bets = {};
      }

      const inlineSelectedBetsByMarket =
        state.bets[payload.selectedBet.pageBlock]?.[payload.selectedBet.marketId]?.bets ?? {};

      let hideInlineSelectedBet = false;

      if (bet.betslipType !== BetslipType.INLINE) {
        Object.entries(inlineSelectedBetsByMarket).forEach(([, item]) => {
          if (
            checkUniqSelectedBetKey({ betKey, ...item }) &&
            item.type === bet.type &&
            item.action !== Actions.SELECT
          ) {
            hideInlineSelectedBet = true;
          }
        });
      }

      if (!state.bets[payload.selectedBet.pageBlock]) {
        state.bets[payload.selectedBet.pageBlock] = {};
      }

      if (!state.bets[payload.selectedBet.pageBlock][payload.selectedBet.marketId]) {
        state.bets[payload.selectedBet.pageBlock][payload.selectedBet.marketId] = { bets: {} };
      }

      if (bet.betslipType === BetslipType.INLINE) {
        Object.entries(inlineSelectedBetsByMarket).forEach(([, item]) => {
          if (item.pageBlock === bet.pageBlock && item.marketId === bet.marketId && item.action === Actions.SELECT) {
            delete state.bets[item.pageBlock][item.marketId].bets[item.betUuid ?? ''];
          }
        });
      }

      // Hide component when click cell second time
      if (hideInlineSelectedBet) {
        delete state.bets[payload.selectedBet.pageBlock][payload.selectedBet.marketId].bets[bet.betUuid ?? ''];
      } else {
        if (bet.betslipType !== BetslipType.INLINE) {
          state.bets[payload.selectedBet.pageBlock][payload.selectedBet.marketId].bets = {};
        }
        state.bets[payload.selectedBet.pageBlock][payload.selectedBet.marketId].bets[betKey] = {
          ...bet,
          betUuid: betKey,
          price: payload.selectedBet.price,
          initPrice: payload.selectedBet.price,
          isPriceValid: payload.selectedBet.price !== '',
          isSizeValid: false,
          pageBlock: payload.selectedBet.pageBlock,
          action: payload.selectedBet.action || Actions.SELECT,
          focusedField: EBetFocusFields.SIZE
        };
      }
    },
    updateInlineSelectedBet: (state, { payload }: PayloadAction<Partial<TInlineSelectedBet>>) => {
      if (
        payload.betUuid &&
        payload.pageBlock &&
        payload.marketId &&
        state.bets[payload.pageBlock]?.[payload.marketId]?.bets?.[payload.betUuid]
      ) {
        Object.assign(state.bets[payload.pageBlock][payload.marketId].bets[payload.betUuid], payload);
      }
    },
    removeInlineSelectedBet: (state, { payload }: PayloadAction<TInlineSelectedBet>) => {
      if (payload.betUuid && state.bets[payload.pageBlock]?.[payload.marketId]?.bets?.[payload.betUuid]) {
        delete state.bets[payload.pageBlock][payload.marketId].bets[payload.betUuid];
      }
    },
    cleanInlineSelectedBets: state => {
      state.bets = {};
    },
    successInlinePlacedBet: (state, { payload }: PayloadAction<TInlineSelectedBet & { placedBet: TCurrentBet }>) => {
      const { betUuid, marketId, pageBlock, placedBet } = payload;

      if (betUuid && state.bets[pageBlock]?.[marketId]?.bets?.[betUuid]) {
        if (!state.bets[pageBlock]?.[marketId]?.bets?.[betUuid]?.offers) {
          state.bets[pageBlock][marketId].bets[betUuid].offers = {};
        }
      }

      /* TODO should be rewritten to state.bets[payload.pageBlock][payload.marketId].offers[payload.placedBet.offerId] = payload.placedBet,
        for now error typescript complains on error for expression above, need to upgrade typescript and related issues
       */
      if (payload.betUuid) {
        state.bets[payload.pageBlock][payload.marketId].bets[payload.betUuid].placedBet = placedBet;
        state.bets[payload.pageBlock][payload.marketId].bets[payload.betUuid].offers = {
          ...state.bets[payload.pageBlock][payload.marketId].bets[payload.betUuid].offers,
          [placedBet.offerId]: placedBet
        };
      }
    },
    failureInlinePlacedBet: (
      state,
      {
        payload
      }: PayloadAction<{
        betUuid?: string;
        pageBlock: PageBlocks;
        marketId: string;
        action?: Actions;
        error: TPlacementError | string;
      }>
    ) => {
      const { marketId, pageBlock, betUuid, action } = payload;

      if (betUuid && state.bets?.[pageBlock]?.[marketId]?.bets?.[betUuid]) {
        if (action) {
          state.bets[pageBlock][marketId].bets[betUuid].action = action;
        }

        state.bets[pageBlock][marketId].bets[betUuid].placementError = isString(payload.error)
          ? payload.error
          : payload.error?.response?.data?.message ?? '';
      }
    },
    setInlinePlacedSize: (
      state,
      {
        payload
      }: PayloadAction<{ price: TPrice; size: TSize; marketId: string; pageBlock: PageBlocks; betUuid?: string }>
    ) => {
      if (!state.bets[payload.pageBlock]) {
        state.bets[payload.pageBlock] = {};
      }

      if (!state.bets[payload.pageBlock][payload.marketId]) {
        state.bets[payload.pageBlock][payload.marketId] = { bets: {} };
      }

      if (payload.betUuid && state.bets[payload.pageBlock]?.[payload.marketId]?.bets?.[payload.betUuid]) {
        state.bets[payload.pageBlock][payload.marketId].bets[payload.betUuid].size = payload.size;
        state.bets[payload.pageBlock][payload.marketId].bets[payload.betUuid].price = payload.price;
        state.bets[payload.pageBlock][payload.marketId].bets[payload.betUuid].isPristinePrice = false;
      }
    },
    setMobilePlacementNotification: (
      state,
      {
        payload
      }: PayloadAction<{
        pageBlock: PageBlocks;
        data: MobilePlacementNotificationPayload;
        placementType: MobilePlacementType;
      }>
    ) => {
      state.mobilePlacementNotifications[payload.data.betUuid] = {
        offerId: payload.data.offerId,
        marketId: payload.data.marketId,
        error: null,
        betUuid: payload.data.betUuid,
        pageBlock: payload.pageBlock,
        placementType: payload.placementType,
        bet: payload.data.bet,
        sizeToShow: payload.data.sizeToShow,
        priceToShow: payload.data.priceToShow,
        displayOrder: new Date().getTime()
      };
    },
    updateMobilePlacementNotifications: (
      state,
      { payload }: PayloadAction<{ data: TPlacedBetsByMarket; placementType: MobilePlacementType }>
    ) => {
      Object.entries(payload.data).forEach(([, { betUuids = [], status, exception, offerIds }]) => {
        betUuids.forEach(betUuid => {
          if (status === 'FAIL' && exception && state.mobilePlacementNotifications[betUuid]) {
            Object.assign(state.mobilePlacementNotifications[betUuid], {
              error: exception.message
            });
          } else if (offerIds?.[betUuid]) {
            Object.assign(state.mobilePlacementNotifications[betUuid], {
              offerId: offerIds?.[betUuid]
            });
          }
        });
      });
    },
    setMobilePlacementNotificationsError: (
      state,
      {
        payload
      }: PayloadAction<{
        error: TPlacementError;
        offerIds?: number[];
        bets?: TPlaceBetsDataPayload | { [marketId: string]: TEditBet[] } | { [marketId: string]: TCancelBet[] };
        pageBlock?: PageBlocks;
        placementType?: MobilePlacementType;
      }>
    ) => {
      if (payload?.offerIds) {
        const betUuids = Object.keys(state.mobilePlacementNotifications);
        betUuids.forEach(betUuid => {
          const placeBet = state.mobilePlacementNotifications[betUuid];
          const offerId = placeBet?.offerId;
          if (payload.offerIds?.includes(+(offerId || 0))) {
            Object.assign(state.mobilePlacementNotifications[betUuid], {
              error: payload.error?.data?.message || payload.error.toString()
            });
          }
        });
      }

      if (payload?.bets) {
        Object.keys(payload.bets).forEach(marketId => {
          if (payload.bets?.[marketId]) {
            payload.bets[marketId].forEach(bet => {
              const betUuid = 'betUuid' in bet ? bet.betUuid : String(bet.offerId);
              if (betUuid) {
                state.mobilePlacementNotifications[betUuid] = {
                  ...state.mobilePlacementNotifications[betUuid],
                  offerId: 'offerId' in bet ? bet.offerId : null,
                  marketId,
                  error: payload.error?.data?.message || payload.error.toString(),
                  betUuid,
                  pageBlock: payload.pageBlock,
                  placementType: payload.placementType || state.mobilePlacementNotifications[betUuid].placementType
                };
              }
            });
          }
        });
      }
    },
    removeAllMobilePlacementNotifications: state => {
      state.mobilePlacementNotifications = {};
    },
    removeMobilePlacementNotificationsByIds: (state, { payload }: PayloadAction<string[]>) => {
      payload.forEach(id => {
        delete state.mobilePlacementNotifications[id];
      });
    },
    setMobileInlineSelectedBetToRestore: (state, { payload }: PayloadAction<TInlineSelectedBet>) => {
      if (payload.betUuid) {
        if (!state.mobileInlineSelectedBetsToRestore[payload.pageBlock]) {
          state.mobileInlineSelectedBetsToRestore[payload.pageBlock] = {};
        }

        if (!state.mobileInlineSelectedBetsToRestore[payload.pageBlock][payload.marketId]) {
          state.mobileInlineSelectedBetsToRestore[payload.pageBlock][payload.marketId] = { bets: {} };
        }

        state.mobileInlineSelectedBetsToRestore[payload.pageBlock][payload.marketId].bets[payload.betUuid] = payload;
      }
    },
    moveMobileInlineSelectedBetFromRestoreToActive: (
      state,
      { payload }: PayloadAction<{ pageBlock: PageBlocks; marketId: string; betUuid: string }>
    ) => {
      if (state.mobileInlineSelectedBetsToRestore[payload.pageBlock]?.[payload.marketId]?.bets?.[payload.betUuid]) {
        if (!state.bets[payload.pageBlock]) {
          state.bets[payload.pageBlock] = {};
        }

        if (!state.bets[payload.pageBlock][payload.marketId]) {
          state.bets[payload.pageBlock][payload.marketId] = { bets: {} };
        }

        state.bets[payload.pageBlock][payload.marketId].bets[payload.betUuid] =
          state.mobileInlineSelectedBetsToRestore[payload.pageBlock][payload.marketId].bets[payload.betUuid];
        delete state.mobileInlineSelectedBetsToRestore[payload.pageBlock][payload.marketId].bets[payload.betUuid];
      }
    },
    removeMobileInlineSelectedBetToRestore: (
      state,
      { payload }: PayloadAction<{ pageBlock: PageBlocks; marketId: string; betUuid: string }>
    ) => {
      if (state.mobileInlineSelectedBetsToRestore[payload.pageBlock]?.[payload.marketId]?.bets?.[payload.betUuid]) {
        delete state.mobileInlineSelectedBetsToRestore[payload.pageBlock][payload.marketId].bets[payload.betUuid];
      }
    },
    setProcessingMobileBet: (
      state,
      { payload }: PayloadAction<{ offerIdOrBetUuid: number | string; isLoading: boolean }>
    ) => {
      state.processingMobileBets[payload.offerIdOrBetUuid] = payload.isLoading;
    },
    removeProcessingMobileBet: (state, { payload }: PayloadAction<number | string>) => {
      delete state.processingMobileBets[payload];
    },
    setIsMobilePlacementConfirmationStep: (state, { payload }: PayloadAction<boolean>) => {
      state.isMobilePlacementConfirmationStep = payload;
    }
  }
});

export const {
  setInlineSelectedBet,
  successInlinePlacedBet,
  removeInlineSelectedBet,
  failureInlinePlacedBet,
  updateInlineSelectedBet,
  setInlinePlacedSize,
  cleanInlineSelectedBets,
  setMobilePlacementNotification,
  removeMobilePlacementNotificationsByIds,
  setMobilePlacementNotificationsError,
  removeAllMobilePlacementNotifications,
  setMobileInlineSelectedBetToRestore,
  moveMobileInlineSelectedBetFromRestoreToActive,
  removeMobileInlineSelectedBetToRestore,
  updateMobilePlacementNotifications,
  setProcessingMobileBet,
  removeProcessingMobileBet,
  setIsMobilePlacementConfirmationStep
} = slice.actions;

export default slice.reducer;
