import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { debounce } from 'lodash';

import NoResultsForSearch from 'components/NoResultsForSearch';
import Pagination from 'components/Pagination';
import { ASIAN_TABS_EARLY_PREFIX, FAVORITES_TYPES } from 'constants/app';
import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM,
  ASIAN_POPULAR_LINK_COMPETITION_ID,
  ASIAN_POPULAR_LINK_SPORT_ID,
  ASIAN_SINGLE_VIEW_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  ASIAN_VIEW_LIST_SIZE,
  ASIAN_VIEW_PAGE_PARAM,
  AsianViewMarketLink,
  AsianViewSorting,
  AsianViewTimeFilter,
  AsianViewTimeFrame,
  DEFAULT_ASIAN_VIEW_PAGINATION_PAGE,
  FETCH_COUNT_MARKETS_INTERVAL
} from 'constants/asianView';
import { SEARCH_DEBOUNCE_DELAY, SEARCH_QUERY_LENGTH } from 'constants/search';
import { PARAMS_ASIAN_SEARCH_KEY } from 'constants/urlParams';
import useAsianViewLineType from 'hooks/useAsianViewLineType';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import useLeaguesParam from 'hooks/useLeaguesParam';
import usePagination from 'hooks/usePagination';
import {
  getAsianViewMiddleSectionPageSize,
  getBackendPages,
  getIsDesktopInfiniteScrollEnabled,
  getIsMobileInfiniteScrollEnabled,
  getIsPropertiesLoaded,
  getTranslation
} from 'redux/modules/appConfigs/selectors';
import { setScrollUpdate } from 'redux/modules/appSettings';
import { getNavigationCountersBySportId } from 'redux/modules/asianSportsNavigation/selectors';
import {
  fetchAsianViewList,
  fetchCountMarkets,
  fetchSearchResults,
  resetClosedEventIds,
  setIsAsianViewNotFoundView
} from 'redux/modules/asianView/index';
import {
  getAreEventCouponsLoaded,
  getAsianViewClosedEventIds,
  getAsianViewCompetitionKeys,
  getAsianViewDaysValue,
  getAsianViewError,
  getAsianViewEventIds,
  getAsianViewLast,
  getAsianViewLoading,
  getAsianViewTotalElements,
  getAsianViewTotalPages,
  getIsAsianViewNotFoundView,
  getIsMobileAsianView,
  getIsSearchResults
} from 'redux/modules/asianView/selectors';
import { IFetchSearch } from 'redux/modules/asianView/type';
import { getLeaguesCurParams, getLeaguesIsLoaded } from 'redux/modules/asianViewLeaguesFilter/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getClosedMarketsIds } from 'redux/modules/marketsPrices/selectors';
import { fetchPageData } from 'redux/modules/pages';
import { getBackendPageContentLoading } from 'redux/modules/pages/selectors';
import { PagesFromBackend } from 'redux/modules/pages/types';
import { closeProfitLossTablePopUp } from 'redux/modules/profitLossTablePopUp';
import { CookieNames, SportId } from 'types';
import { ELineViewTypes, ESortingTypes } from 'types/asianView';

import CompetitionSection from '../CompetitionSection';
import LoadingSkeleton from '../LoadingSkeleton';
import MatchOddsHeader from '../MatchOddsHeader';
import SingleLineHeader from '../SingleLineHeader';

import styles from './styles.module.scss';

const CompetitionSections = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sportId = SportId.SOCCER, timeFilter, marketLink, timeFrame } = useParams();
  const [searchParams] = useSearchParams();
  const [cookies] = useCookies([CookieNames.ASIAN_VIEW_SORTING_FILTER]);

  const translation = useSelector(getTranslation);
  const loading = useSelector(getAsianViewLoading);
  const isLast = useSelector(getAsianViewLast);
  const competitionKeys = useSelector(getAsianViewCompetitionKeys);
  const eventIds = useSelector(getAsianViewEventIds);
  const curLeaguesParam = useSelector(getLeaguesCurParams);
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const totalPages = useSelector(getAsianViewTotalPages);
  const totalElements = useSelector(getAsianViewTotalElements);
  const isDesktopInfiniteScrollEnabled = useSelector(getIsDesktopInfiniteScrollEnabled);
  const isMobileInfiniteScrollEnabled = useSelector(getIsMobileInfiniteScrollEnabled);
  const itemsCountOnPage = useSelector(getAsianViewMiddleSectionPageSize);
  const daysValue = useSelector(getAsianViewDaysValue);
  const closedMarketIds = useSelector(getClosedMarketsIds);
  const isLeaguesLoaded = useSelector(getLeaguesIsLoaded);
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isSearchResults = useSelector(getIsSearchResults);
  const areEventCouponsLoaded = useSelector(getAreEventCouponsLoaded);
  const isLoadingBackendPage = useSelector(getBackendPageContentLoading);
  const pages = useSelector(getBackendPages);
  const isAsianViewNotFoundView = useSelector(getIsAsianViewNotFoundView);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isError = useSelector(getAsianViewError);
  const counters = useSelector(getNavigationCountersBySportId(sportId));
  const closedEventIds = useSelector(getAsianViewClosedEventIds);

  const [page, setPage] = useState(0);

  const fetchCountMarketsInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const prevSingleViewCompetitionId = useRef<string | null>(null);
  const prevSingleViewEventId = useRef<string | null>(null);
  const prevSingleViewSportId = useRef<string | null>(null);
  const infiniteScrollPageRef = useRef(0);
  const prevInPlayEventsCounter = useRef(0);

  const isInfiniteScrollEnabled = isMobileAsianView ? isMobileInfiniteScrollEnabled : isDesktopInfiniteScrollEnabled;
  const numberOfCompetitions = competitionKeys.length;
  const sortingFilter = (cookies[CookieNames.ASIAN_VIEW_SORTING_FILTER] as ESortingTypes) || ESortingTypes.NORMAL;
  const lineViewFilter = useAsianViewLineType();
  const isSingleLine = lineViewFilter === ELineViewTypes.SINGLE;
  const isMatchOddsHT = marketLink === AsianViewMarketLink.MATCH_ODDS_HALF_TIME;
  const isHandicapAndOU = marketLink === AsianViewMarketLink.HDP_AND_OU;
  const query = searchParams.get(PARAMS_ASIAN_SEARCH_KEY) || '';
  const querySearch = query.length >= SEARCH_QUERY_LENGTH && query; // this expression is required in this view to not trigger useEffect until query.length < 3 and for every change of search if query.length >= 3
  const isLoading = loading || (!isSearchResults && querySearch && !isError);
  const isEarlyTab = timeFilter === ASIAN_TABS_EARLY_PREFIX;
  const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
  const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
  const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);
  const isFavouriteView = !!(favouriteType && favouriteId && favouriteSportId);
  const popularLinkCompetitionId = searchParams.get(ASIAN_POPULAR_LINK_COMPETITION_ID);
  const popularLinkSportId = searchParams.get(ASIAN_POPULAR_LINK_SPORT_ID);
  const isPopularLinkView = !!popularLinkCompetitionId && !!popularLinkSportId;
  const singleViewCompetitionId = searchParams.get(ASIAN_SINGLE_VIEW_COMPETITION_ID);
  const singleViewEventId = searchParams.get(ASIAN_SINGLE_VIEW_EVENT_ID);
  const singleViewSportId = searchParams.get(ASIAN_SINGLE_VIEW_SPORT_ID);
  const isSingleView = (!!singleViewCompetitionId || !!singleViewEventId) && !!singleViewSportId;
  const timeFilterParam =
    isFavouriteView || isPopularLinkView || isSingleView
      ? AsianViewTimeFilter.ALL
      : (timeFilter as AsianViewTimeFilter);
  const marketLinkParam =
    isFavouriteView || isPopularLinkView || isSingleView
      ? AsianViewMarketLink.HDP_AND_OU
      : (marketLink as AsianViewMarketLink);
  const timeFrameParam = isEarlyTab
    ? { timeFrame: isMobileAsianView ? (timeFrame as AsianViewTimeFrame) ?? AsianViewTimeFrame.ALL : daysValue }
    : {};
  const sorting = AsianViewSorting[sortingFilter];
  const viewType =
    isHandicapAndOU || isFavouriteView || isPopularLinkView || isSingleView ? lineViewFilter : ELineViewTypes.DOUBLE;
  const show404Page =
    !isLoading &&
    areEventCouponsLoaded &&
    numberOfCompetitions === 0 &&
    isPropertiesLoaded &&
    isSingleView &&
    prevSingleViewCompetitionId.current === singleViewCompetitionId &&
    prevSingleViewEventId.current === singleViewEventId &&
    prevSingleViewSportId.current === singleViewSportId;

  prevSingleViewCompetitionId.current = singleViewCompetitionId;
  prevSingleViewEventId.current = singleViewEventId;
  prevSingleViewSportId.current = singleViewSportId;

  const leaguesParam = useLeaguesParam();

  const prevQuerySearch = useRef(querySearch);

  const couponsParam = useMemo(() => {
    if (singleViewEventId) {
      return { coupons: [singleViewEventId] };
    }

    if (isFavouriteView && favouriteType === FAVORITES_TYPES.event) {
      return { coupons: [favouriteId] };
    }

    return {};
  }, [isFavouriteView, favouriteType, favouriteId, singleViewEventId]);

  const handleFetchAsianViewListPagination = (nextPage: number) => {
    dispatch(
      fetchAsianViewList({
        id: sportId,
        page: nextPage,
        size: itemsCountOnPage,
        payload: {
          marketLink: marketLinkParam,
          timeFilter: timeFilterParam,
          competitions: leaguesParam,
          viewType,
          sections: [],
          sorting,
          ...timeFrameParam
        },
        isInfiniteScrollEnabled: false,
        resetPrev: true
      })
    );

    dispatch(setScrollUpdate({ top: 0, trigger: true, offset: 0 }));

    prevQuerySearch.current = querySearch;
  };

  const handleFetchSearchListPagination = (nextPage: number) => {
    dispatch(
      fetchSearchResults({
        page: nextPage,
        query,
        size: itemsCountOnPage,
        body: {
          viewType: lineViewFilter
        },
        isInfiniteScrollEnabled: false,
        resetPrev: true
      })
    );

    dispatch(setScrollUpdate({ top: 0, trigger: true, offset: 0 }));
  };

  const {
    page: paginationPage,
    onClickLastPage,
    onClickNextPage,
    onClickPrevPage,
    onClickFirstPage,
    onClickPage,
    changePage
  } = usePagination({
    onChangePage: querySearch ? handleFetchSearchListPagination : handleFetchAsianViewListPagination,
    totalPages,
    isPaginationEnabled: !isInfiniteScrollEnabled,
    resetPageDependency: querySearch
  });

  useEffect(() => {
    const { numberOfInPlayEvents } = counters || {};

    if (numberOfInPlayEvents > prevInPlayEventsCounter.current) {
      if (page !== 0) {
        setPage(0);
      } else {
        onClickPage(DEFAULT_ASIAN_VIEW_PAGINATION_PAGE);
      }
    }
    prevInPlayEventsCounter.current = numberOfInPlayEvents;
  }, [counters?.numberOfInPlayEvents]);

  const debouncedSearch = useRef(
    debounce((payload: IFetchSearch) => {
      dispatch(fetchSearchResults(payload));
    }, SEARCH_DEBOUNCE_DELAY)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    if (eventIds.length) {
      if (isHandicapAndOU || querySearch || isSingleView || isFavouriteView || isPopularLinkView) {
        dispatch(
          fetchCountMarkets({
            id: sportId,
            payload: {
              marketLink: AsianViewMarketLink.HDP_AND_OU,
              viewType: lineViewFilter,
              coupons: eventIds
            }
          })
        );

        fetchCountMarketsInterval.current = setInterval(() => {
          dispatch(
            fetchCountMarkets({
              id: sportId,
              payload: {
                marketLink: AsianViewMarketLink.HDP_AND_OU,
                viewType: lineViewFilter,
                coupons: eventIds
              }
            })
          );
        }, FETCH_COUNT_MARKETS_INTERVAL);
      }
    } else if (fetchCountMarketsInterval.current) {
      clearInterval(fetchCountMarketsInterval.current);
    }

    return () => {
      if (fetchCountMarketsInterval.current) {
        clearInterval(fetchCountMarketsInterval.current);
      }
    };
  }, [
    eventIds,
    isHandicapAndOU,
    sportId,
    closedMarketIds,
    !!querySearch,
    isSingleView,
    isPopularLinkView,
    isFavouriteView,
    lineViewFilter,
    dispatch
  ]);

  useEffect(() => {
    if (
      isPropertiesLoaded &&
      ((isLeaguesLoaded && curLeaguesParam === `${sportId}-${marketLinkParam}-${timeFilter}`) || querySearch)
    ) {
      if (isInfiniteScrollEnabled && page !== 0) {
        setPage(0);
        infiniteScrollPageRef.current = 0;
      }

      if (querySearch) {
        const searchOffsetOrPage = {} as { offset?: number; page?: number };

        if (isInfiniteScrollEnabled) {
          searchOffsetOrPage.offset = 0;
        } else if (prevQuerySearch.current !== querySearch) {
          searchOffsetOrPage.page = 0;
        } else {
          searchOffsetOrPage.page = paginationPage;
        }

        debouncedSearch({
          ...searchOffsetOrPage,
          query,
          size: isInfiniteScrollEnabled ? ASIAN_VIEW_LIST_SIZE : itemsCountOnPage,
          body: {
            viewType: lineViewFilter
          },
          isInfiniteScrollEnabled,
          changePage
        });

        prevQuerySearch.current = querySearch;
      } else {
        const offsetOrPage = isInfiniteScrollEnabled ? { offset: 0 } : { page: paginationPage };

        dispatch(
          fetchAsianViewList({
            ...offsetOrPage,
            id: sportId,
            size: isInfiniteScrollEnabled ? ASIAN_VIEW_LIST_SIZE : itemsCountOnPage,
            payload: {
              marketLink: marketLinkParam,
              timeFilter: timeFilterParam,
              viewType,
              competitions: leaguesParam,
              sections: [],
              sorting,
              ...timeFrameParam,
              ...couponsParam
            },
            isInfiniteScrollEnabled,
            changePage,
            resetPrev: true
          })
        );
      }
    }
  }, [
    querySearch,
    marketLinkParam,
    timeFilterParam,
    viewType,
    sorting,
    leaguesParam,
    sportId,
    isPropertiesLoaded,
    itemsCountOnPage,
    timeFrameParam?.timeFrame,
    couponsParam,
    isLeaguesLoaded,
    isLoggedIn
  ]);

  useEffect(() => {
    if (page !== 0 && infiniteScrollPageRef.current !== 0 && !isLast && isInfiniteScrollEnabled) {
      if (querySearch) {
        dispatch(
          fetchSearchResults({
            offset: page * ASIAN_VIEW_LIST_SIZE,
            query,
            size: ASIAN_VIEW_LIST_SIZE,
            body: {
              viewType: lineViewFilter
            },
            isInfiniteScrollEnabled: true
          })
        );
      } else {
        dispatch(
          fetchAsianViewList({
            id: sportId,
            offset: page * ASIAN_VIEW_LIST_SIZE,
            size: ASIAN_VIEW_LIST_SIZE,
            payload: {
              marketLink: marketLinkParam,
              timeFilter: timeFilterParam,
              viewType,
              competitions: leaguesParam,
              sections: [],
              sorting,
              ...timeFrameParam
            },
            isInfiniteScrollEnabled: true
          })
        );
      }
    }
  }, [page, isLast]);

  useEffect(() => {
    if (show404Page) {
      dispatch(fetchPageData(pages[PagesFromBackend.PAGE_NOT_FOUND] as string));
      dispatch(setIsAsianViewNotFoundView(true));
    } else if (isAsianViewNotFoundView) {
      dispatch(setIsAsianViewNotFoundView(false));
    }
  }, [show404Page]);

  useEffect(() => {
    return () => {
      if (isAsianViewNotFoundView) {
        dispatch(setIsAsianViewNotFoundView(false));
      }
    };
  }, []);

  useEffect(() => {
    dispatch(closeProfitLossTablePopUp());
  }, [isFavouriteView, isSingleView, isPopularLinkView]);

  useEffect(() => {
    if (closedEventIds.length) {
      if (isInfiniteScrollEnabled) {
        dispatch(
          fetchAsianViewList({
            id: sportId,
            offset: 0,
            size: ASIAN_VIEW_LIST_SIZE,
            payload: {
              marketLink: marketLinkParam,
              timeFilter: timeFilterParam,
              viewType,
              competitions: leaguesParam,
              sections: [],
              sorting,
              ...timeFrameParam
            },
            isInfiniteScrollEnabled: true
          })
        );

        if (page !== 0) {
          setPage(0);
        }
      } else {
        handleFetchAsianViewListPagination(DEFAULT_ASIAN_VIEW_PAGINATION_PAGE);

        if (Number(searchParams.get(ASIAN_VIEW_PAGE_PARAM)) !== DEFAULT_ASIAN_VIEW_PAGINATION_PAGE) {
          onClickPage(DEFAULT_ASIAN_VIEW_PAGINATION_PAGE);
        }
      }

      dispatch(resetClosedEventIds());
    }
  }, [closedEventIds, isInfiniteScrollEnabled]);

  const infiniteScrollCallback = useCallback(() => {
    if (!isLoading && !isLast && isInfiniteScrollEnabled) {
      setPage(prevPage => {
        infiniteScrollPageRef.current = prevPage + 1;

        return prevPage + 1;
      });
    }
  }, [isLoading, isLast, isInfiniteScrollEnabled]);

  const { lastElementRef } = useInfiniteScroll<HTMLDivElement>({ callback: infiniteScrollCallback });
  const isTotalLoaded =
    numberOfCompetitions === 0 &&
    !isLoading &&
    isPropertiesLoaded &&
    areEventCouponsLoaded &&
    (isLeaguesLoaded || querySearch);

  if (isAsianViewNotFoundView) {
    return null;
  }

  return translation ? (
    <>
      {!isLoading && querySearch && !competitionKeys.length && <NoResultsForSearch />}
      {isMatchOddsHT &&
        !isFavouriteView &&
        !isPopularLinkView &&
        !isSingleView &&
        !querySearch &&
        !isLoading &&
        numberOfCompetitions > 0 && <MatchOddsHeader />}
      {!isLoading &&
        numberOfCompetitions > 0 &&
        isSingleLine &&
        (isHandicapAndOU || isFavouriteView || isPopularLinkView || isSingleView) &&
        !isMobileAsianView && <SingleLineHeader />}
      {(isInfiniteScrollEnabled || !isLoading) &&
        numberOfCompetitions > 0 &&
        competitionKeys.map((competitionKey, index) => {
          return (
            <CompetitionSection
              key={competitionKey}
              competitionKey={competitionKey}
              isLast={index === numberOfCompetitions - 1}
              isSingleLine={isSingleLine}
            />
          );
        })}
      {isTotalLoaded && !query && (
        <div className={classNames(styles.noData, { [styles.mobile]: isMobileAsianView })}>
          {isMobileAsianView ? t('competition.view.labels.noData') : t('inplay.noData')}
        </div>
      )}
      {isLoading || (show404Page && isLoadingBackendPage) ? (
        <LoadingSkeleton />
      ) : (
        isInfiniteScrollEnabled && !!eventIds.length && <div ref={lastElementRef} />
      )}
      {!isInfiniteScrollEnabled &&
        totalElements > itemsCountOnPage &&
        !isLoading &&
        totalElements > 0 &&
        areEventCouponsLoaded && (
          <Pagination
            page={paginationPage}
            totalPages={totalPages}
            onClickFirstPage={onClickFirstPage}
            onClickLastPage={onClickLastPage}
            onClickNextPage={onClickNextPage}
            onClickPage={onClickPage}
            onClickPrevPage={onClickPrevPage}
          />
        )}
    </>
  ) : (
    <LoadingSkeleton />
  );
};

export default CompetitionSections;
