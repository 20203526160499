import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { asianViewMiddleSection as branding } from 'constants/branding';
import { getAsianViewFirstHalfMarketId, getAsianViewFirstMarketId } from 'redux/modules/asianView/selectors';

import MarketRules from '../CompetitionTitle/MarketRules';

import styles from './styles.module.scss';

const MatchOddsHeader = () => {
  const { t } = useTranslation();

  const fullMarketId = useSelector(getAsianViewFirstMarketId);
  const halfMarketId = useSelector(getAsianViewFirstHalfMarketId);

  return (
    <div className={classNames(styles.matchOddsHeader, branding.SINGLE_LINE_MARKET_HEADER)}>
      <div className={styles.matchOddsHeader__firstCol} />
      <div className={styles.matchOddsHeader__middleCol}>
        <span>{t('asianView.labels.column.fullTime')}</span>
        <MarketRules marketId={fullMarketId} />
      </div>
      <div className={styles.matchOddsHeader__middleCol}>
        <span>{t('asianView.labels.column.firstHalf')}</span>
        <MarketRules marketId={halfMarketId} />
      </div>
      <div className={styles.matchOddsHeader__lastCol} />
    </div>
  );
};

export default MatchOddsHeader;
