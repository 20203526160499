import { useSelector } from 'react-redux';

import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { getMarketPricesById } from 'redux/modules/marketsPrices/selectors';
import { getMarketPricesById as placementById } from 'redux/modules/placement/selectors';
import { PageBlocks } from 'types';
import { BetTypes, TSize } from 'types/bets';
import { calculateLiability } from 'utils/liability';
import { getPricesByMarketType } from 'utils/price';

type TUseTakeOddsProps = {
  marketId: string;
  selectionId: number;
  handicap?: number;
  betType: BetTypes;
  marketType?: string;
  bettingType?: string;
  size: TSize;
  currency?: string;
  eachWayDivisor?: number | null;
};
const useTakeOdds = ({
  marketId,
  selectionId,
  handicap,
  betType,
  marketType = '',
  bettingType = '',
  size,
  currency,
  eachWayDivisor
}: TUseTakeOddsProps) => {
  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const gameMarketPrices = useSelector(placementById(PageBlocks.GAME, marketId));
  const marketPrices = useSelector(getMarketPricesById(marketId));

  const selectionPrices = isGameBetSlip
    ? gameMarketPrices?.rc?.find(rc => rc.id === selectionId)
    : marketPrices?.rc?.find(rc => rc.id === selectionId && ((rc.hc || 0) === +(handicap || 0) || handicap === 0));

  const takeOdds = getPricesByMarketType(
    selectionPrices?.[betType === BetTypes.BACK ? 'bdatb' : 'bdatl']?.[0]?.odds,
    marketType,
    bettingType
  );

  const takeProfit = calculateLiability(takeOdds, size, { marketType, bettingType, eachWayDivisor, betType });

  const { noFormattedAmount: profit } = useFormatCurrency(takeProfit || 0, currency, {
    isCheckIndian: true,
    noRounding: true
  });

  return { profit, takeOdds };
};

export default useTakeOdds;
