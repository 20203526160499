import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface DatepickerCustomHeaderProps {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

const DatePickerCustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}: DatepickerCustomHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('ui-datepicker-header', styles.datepickerCustomheader)}>
      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        <i
          className={classNames(
            'ui-datepicker-arrow-icon fa2 fa2-arrow-left',
            styles.datepickerCustomheader__arrowLeft,
            {
              biab_disabled: prevMonthButtonDisabled
            }
          )}
        />
      </button>
      <div className="ui-datepicker-title">{t(`dates.month.${[date.getMonth()]}`)} &nbsp;</div>
      <div className="ui-datepicker-title">{date.getFullYear()}</div>
      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        <i
          className={classNames(
            'ui-datepicker-arrow-icon fa2 fa2-arrow-right',
            styles.datepickerCustomheader__arrowRight,
            {
              biab_disabled: nextMonthButtonDisabled
            }
          )}
        />
      </button>
    </div>
  );
};

export default DatePickerCustomHeader;
