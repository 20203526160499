import classNames from 'classnames';

import styles from './styles.module.scss';

interface IconProps {
  iconClass: string;
  color?: 'black' | 'gray' | 'white' | 'inherit';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  fontFamily?: 'fa2' | 'fas' | 'av-icon';
  className?: string;
}

const Icon = ({ iconClass, className, fontFamily, color = 'black', size = 'md' }: IconProps) => {
  return (
    <i
      className={classNames(styles.icon, fontFamily, iconClass, className, {
        [styles.icon__xs]: size === 'xs',
        [styles.icon__sm]: size === 'sm',
        [styles.icon__lg]: size === 'lg',
        [styles.icon__white]: color === 'white',
        [styles.icon__gray]: color === 'gray',
        [styles.icon__inherit]: color === 'inherit'
      })}
    />
  );
};

export default Icon;
