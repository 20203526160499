import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { failureFetchPSFooter, fetchPSFooter, successFetchPSFooter } from './index';

export function* getPsFooterWatcher(action: ReturnType<typeof fetchPSFooter>) {
  try {
    const response: string = yield call(api.app.getPsFooter, action.payload);
    yield put(successFetchPSFooter(response));
  } catch (error: any) {
    yield put(failureFetchPSFooter(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchPSFooter.type, getPsFooterWatcher)]);
}
