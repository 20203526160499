import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { asianViewIcons } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import { getIsAVActiveProfitLossTableForCurrentBet } from 'redux/modules/asianViewCurrentBets/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  closeProfitLossTablePopUp,
  fetchProfitLossMarketNames,
  fetchProfitLossTableData
} from 'redux/modules/profitLossTablePopUp';
import { getProfitLossTablePopUpEventId } from 'redux/modules/profitLossTablePopUp/selectors';

import styles from './ProfitLossTablePopUpButton.module.scss';

type ProfitLossTablePopUpButtonProps = {
  className?: string;
  eventId: string;
  isActive?: boolean;
  marketId?: string;
  iconClassName?: string;
  isBrandingStyles?: boolean;
  isAsianView?: boolean;
};

const ProfitLossTablePopUpButton = ({
  className = '',
  eventId,
  marketId,
  isActive = false,
  iconClassName = '',
  isBrandingStyles = true,
  isAsianView = true
}: ProfitLossTablePopUpButtonProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isActivePLTable = useSelector(
    getIsAVActiveProfitLossTableForCurrentBet(!isLoggedIn || isActive ? undefined : eventId)
  );
  const openedEventId = useSelector(getProfitLossTablePopUpEventId);

  const { isMobile } = useDevice();

  if (!isLoggedIn) {
    return null;
  }

  const handleFetchProfitLossTableData = () => {
    dispatch(closeProfitLossTablePopUp());

    // setTimeout is required here because previous pop up should be closed and new one should be opened, but there is
    // not enough time to close previous pop up (set eventId to null) and open new one (set new eventId)
    setTimeout(() => {
      if (marketId) {
        dispatch(fetchProfitLossTableData({ eventId, marketId, isAsianView }));
        dispatch(fetchProfitLossMarketNames({ eventId, isAsianView }));
      }
    }, 0);
  };

  return (
    <button
      {...(isMobile ? {} : { 'data-tooltip-id': 'tooltip', 'data-tooltip-html': t('betslip.tooltip.plTable') })}
      onClick={handleFetchProfitLossTableData}
      className={classNames(
        styles.button,
        {
          [styles.button__active]: isActive || isActivePLTable,
          [styles.button__opened]: !(isActive || isActivePLTable) && openedEventId === eventId,
          [styles.button__active__opened]: (isActive || isActivePLTable) && openedEventId === eventId,
          [styles.button__mobile]: isMobile,
          [asianViewIcons.PL_TABLE_ICON]: isBrandingStyles,
          [asianViewIcons.ACTIVE]: isBrandingStyles && (isActive || isActivePLTable),
          [asianViewIcons.SELECTED]: isBrandingStyles && openedEventId === eventId
        },
        className
      )}
    >
      <i
        className={classNames(
          'av-icon av-icon-dollar',
          styles.icon,
          {
            [asianViewIcons.PL_TABLE_ICON]: isBrandingStyles,
            [asianViewIcons.ACTIVE]: isBrandingStyles && (isActive || isActivePLTable),
            [asianViewIcons.SELECTED]: isBrandingStyles && openedEventId === eventId
          },
          iconClassName
        )}
      />
    </button>
  );
};

export default ProfitLossTablePopUpButton;
