import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import InPlayStatus from 'components/InPlayStatus';
import { getFancyMarketData } from 'redux/modules/fancyMarket/selectors';
import { getMarketPricesTurnInPlayEnabled } from 'redux/modules/marketsPrices/selectors';

import FancyMarketRules from '../FancyMarketRules';

import styles from './styles.module.scss';

type FancyMarketDesktopInfoProps = {
  isInPlay: boolean;
  marketId: string;
};

const FancyMarketDesktopInfo = ({ isInPlay, marketId }: FancyMarketDesktopInfoProps) => {
  const { t } = useTranslation();

  const { inningsFancy } = useSelector(getFancyMarketData);
  const isShowInPlay = useSelector(getMarketPricesTurnInPlayEnabled(marketId));

  return (
    <div className={classNames('biab_market-info', styles.fancyMarketInfo__container)}>
      <hr className={styles.fancyMarketInfo__divider} />
      <div className={styles.fancyMarketInfo}>
        <div className={styles.fancyMarketInfo__left}>
          {isShowInPlay && <InPlayStatus isInPlay={isInPlay} showLabel />}
          {inningsFancy.length > 0 && <FancyMarketRules />}
        </div>
        <button className={classNames('biab_market-refresh', styles.fancyMarketInfo__refresh)}>
          {t('market.refresh')}
        </button>
      </div>
    </div>
  );
};

export default FancyMarketDesktopInfo;
