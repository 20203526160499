import { memo, useEffect, useState, useTransition } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import AsianViewCashOutProgressBar from 'components/AsianViewPageModule/components/CashOutSection/components/Content/AsianViewCashOutProgressBar';
import BetSlipCashOutMessage from 'components/AsianViewPageModule/components/CashOutSection/components/Content/BetSlipCashOutMessage';
import Warning from 'components/CashOutButton/components/Warning';
import CashOutProgressBar from 'components/CashOutProgressBar';
import CashOutStatusMessage from 'components/CashOutStatusMessage';
import PlacementMessage from 'components/MobilePlacement/PlacementMessage';
import Notification from 'components/Notification';
import { AV_SUCCESS_STATUS_CASH_OUT_MARKETS_REQUEST_INTERVAL } from 'constants/app';
import {
  ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE,
  ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM,
  ASIAN_VIEW_PAGE_PARAM
} from 'constants/asianView';
import { cashOut as branding } from 'constants/branding';
import { ASIAN_VIEW_CASH_OUT_URL } from 'constants/locations';
import useCashOutPercentage from 'hooks/useCashOutPercentage';
import useDevice from 'hooks/useDevice';
import {
  getAutoCashOutEnabled,
  getDesktopCashoutPageSize,
  getDeviceAsianViewCashOutPaginationButtons
} from 'redux/modules/appConfigs/selectors';
import { getAsianViewIsCashOutTabOpened, getOpenEventId } from 'redux/modules/asianView/selectors';
import { getIsAsianViewBetSlipCashOutTabOpened } from 'redux/modules/asianViewBetslip/selectors';
import {
  asianBetSlipCashOutCleanPlacedId,
  asianBetSlipCashOutCleanStatus,
  asianBetSlipCashOutCloseSettingsTab,
  asianBetSlipCashOutOpenSettingsTab,
  fetchAsianBetSlipCashOutMarkets,
  fetchAsianViewBetSlipCashOutStatus,
  removeAsianViewBetSlipAutoCashOutMarket,
  setAsianViewBetSlipCashOutStatus
} from 'redux/modules/asianViewBetSlipCashOut';
import {
  getAsianBetSlipAutoCashOutByMarketId,
  getAsianBetSlipAutoCashOutStatusByMarketId,
  getAsianBetSlipCashOutPlaceIdByMarketId,
  getAsianBetSlipCashOutQuoteByMarketId,
  getAsianBetSlipCashOutSettingsTabByMarketId,
  getAsianBetSlipCashOutStatusByMarketId,
  getAsianBetSlipPendingCashOutByMarketId
} from 'redux/modules/asianViewBetSlipCashOut/selectors';
import {
  asianViewCashOutCleanPlacedId,
  asianViewCashOutCleanStatus,
  asianViewCashOutCloseSettingsTab,
  asianViewCashOutOpenSettingsTab,
  fetchAsianViewCashOutMarkets,
  fetchAsianViewCashOutStatus,
  hoverAsianViewCashOutBtn,
  leaveAsianViewCashOutBtn,
  removeAsianViewAutoCashOutMarket,
  removeAsianViewCashOutQuoteByMarketId,
  setAsianViewCashOutStatus
} from 'redux/modules/asianViewCashOut';
import {
  getAsianViewAutoCashOutByMarketId,
  getAsianViewAutoCashOutStatusByMarketId,
  getAsianViewCashOutPlaceIdByMarketId,
  getAsianViewCashOutQuoteByMarketId,
  getAsianViewCashOutSettingsTabByMarketId,
  getAsianViewCashOutStatusByMarketId,
  getAsianViewPendingCashOutByMarketId
} from 'redux/modules/asianViewCashOut/selectors';
import { getAVPlacedCashOut } from 'redux/modules/asianViewCurrentBets/selectors';
import {
  cleanCashOutPlacedId,
  cleanCashOutStatus,
  closeSettingsTab,
  fetchCashOutStatus,
  hoverCashOutBtn,
  leaveCashOutBtn,
  openSettingsTab,
  removeAutoCashOutMarket,
  resetAutoCashOutError
} from 'redux/modules/cashOut';
import {
  getAutoCashOut,
  getAutoCashOutError,
  getAutoCashOutStatus,
  getCashOutByMarketId,
  getCashOutPlacedId,
  getCashOutStatus,
  getPendingCashOut,
  getSettingTabStatus
} from 'redux/modules/cashOut/selectors';
import { AutoCashOutStatuses, CashOutStatuses, ProfitSelection } from 'redux/modules/cashOut/type';
import { getPlacedCashOut } from 'redux/modules/currentBets/selectors';
import {
  eventCashOutCleanPlacedId,
  eventCashOutCleanStatus,
  eventCashOutCloseSettingsTab,
  eventCashOutOpenSettingsTab,
  fetchEventCashOutMarkets,
  fetchEventCashOutStatus,
  hoverEventCashOutBtn,
  leaveEventCashOutBtn,
  removeEventAutoCashOutMarket,
  setEventCashOutStatus
} from 'redux/modules/eventCashOut';
import {
  getEventAutoCashOutByMarketId,
  getEventAutoCashOutStatusByMarketId,
  getEventCashOutPlaceIdByMarketId,
  getEventCashOutQuoteByMarketId,
  getEventCashOutSettingsTabByMarketId,
  getEventCashOutStatusByMarketId,
  getEventPendingCashOutByMarketId
} from 'redux/modules/eventCashOut/selectors';
import { CashOutStatus, SportId } from 'types';

import Button from './components/Button';
import CashOutSettingsContent from './components/CashOutSettingsContent';
import Liability from './components/Liability';

import styles from './styles.module.scss';

interface CashOutButtonProps {
  marketId: string;
  partialCashOutEnabled: boolean;
  isAsianBetSlip?: boolean;
  isEvent?: boolean;
  isAVMobileCashOutPage?: boolean;
  isAsianView?: boolean;
  isAVMobileBetList?: boolean;
  buttonWrapperClassName?: string;
  buttonContainerClassName?: string;
  buttonClassName?: string;
  cashOutTextClassName?: string;
  settingsBtnClassName?: string;
  settingsActiveBtnClassName?: string;
  tabsContainerClassName?: string;
  ignoreFlexibleDisplayFormat?: boolean;
}

const CashOutButton = ({
  marketId,
  partialCashOutEnabled,
  isAsianBetSlip = false,
  isEvent = false,
  isAVMobileCashOutPage = false,
  isAsianView = false,
  isAVMobileBetList = false,
  buttonWrapperClassName = '',
  buttonClassName = '',
  cashOutTextClassName = '',
  settingsBtnClassName = '',
  settingsActiveBtnClassName = '',
  tabsContainerClassName = '',
  ignoreFlexibleDisplayFormat = false
}: CashOutButtonProps) => {
  const dispatch = useDispatch();
  const [, transition] = useTransition();
  const location = useLocation();
  const { sportId = SportId.SOCCER } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const settingsTabSelector = isAsianBetSlip
    ? getAsianBetSlipCashOutSettingsTabByMarketId
    : isEvent
    ? getEventCashOutSettingsTabByMarketId
    : isAsianView
    ? getAsianViewCashOutSettingsTabByMarketId
    : getSettingTabStatus;
  const cashOutSelector = isAsianBetSlip
    ? getAsianBetSlipCashOutQuoteByMarketId
    : isEvent
    ? getEventCashOutQuoteByMarketId
    : isAsianView
    ? getAsianViewCashOutQuoteByMarketId
    : getCashOutByMarketId;
  const autoCashOutSelector = isAsianBetSlip
    ? getAsianBetSlipAutoCashOutByMarketId
    : isEvent
    ? getEventAutoCashOutByMarketId
    : isAsianView
    ? getAsianViewAutoCashOutByMarketId
    : getAutoCashOut;
  const autoCashOutStatusSelector = isAsianBetSlip
    ? getAsianBetSlipAutoCashOutStatusByMarketId
    : isEvent
    ? getEventAutoCashOutStatusByMarketId
    : isAsianView
    ? getAsianViewAutoCashOutStatusByMarketId
    : getAutoCashOutStatus;
  const cashOutPlacedIdSelector = isAsianBetSlip
    ? getAsianBetSlipCashOutPlaceIdByMarketId
    : isEvent
    ? getEventCashOutPlaceIdByMarketId
    : isAsianView
    ? getAsianViewCashOutPlaceIdByMarketId
    : getCashOutPlacedId;
  const statusSelector = isAsianBetSlip
    ? getAsianBetSlipCashOutStatusByMarketId
    : isEvent
    ? getEventCashOutStatusByMarketId
    : isAsianView
    ? getAsianViewCashOutStatusByMarketId
    : getCashOutStatus;
  const pendingCashOutSelector = isAsianBetSlip
    ? getAsianBetSlipPendingCashOutByMarketId
    : isEvent
    ? getEventPendingCashOutByMarketId
    : isAsianView
    ? getAsianViewPendingCashOutByMarketId
    : getPendingCashOut;
  const placedEventSelector = isAsianView ? getAVPlacedCashOut : getPlacedCashOut;

  const autoCashOutEnabled = useSelector(getAutoCashOutEnabled);
  const isOpenSettings = useSelector(settingsTabSelector(marketId));
  const currentCashOut = useSelector(cashOutSelector(marketId));
  const autoCashOut = useSelector(autoCashOutSelector(marketId));
  const autoCashOutStatus = useSelector(autoCashOutStatusSelector(marketId));
  const cashOutPlacedId = useSelector(cashOutPlacedIdSelector(marketId));
  const placedEvent = useSelector(placedEventSelector(cashOutPlacedId));
  const status = useSelector(statusSelector(marketId));
  const pendingCashOut = useSelector(pendingCashOutSelector(marketId));
  const desktopCashOutPageSize = useSelector(getDesktopCashoutPageSize);
  const asianViewOpenedTabsEventCouponId = useSelector(getOpenEventId);
  const isCashOutTabOpened = useSelector(getAsianViewIsCashOutTabOpened);
  const isAsianViewBetSlipTab = useSelector(getIsAsianViewBetSlipCashOutTabOpened);
  const autoCashOutError = useSelector(getAutoCashOutError(marketId));

  const [isHover, setIsHover] = useState(false);

  const { cashOutPercentage, profit, partial, cashOut, quotePercentage, handlerCashOutPercentage, initValues } =
    useCashOutPercentage({
      minPartialPercentage: currentCashOut?.minPartialPercentage,
      maxPartialPercentage: currentCashOut?.maxPartialPercentage,
      profit: currentCashOut?.profit,
      liability: currentCashOut?.currentLiability
    });
  const { isMobile, isDesktop } = useDevice();

  const asianViewCashOutPaginationButtons = useSelector(getDeviceAsianViewCashOutPaginationButtons(isMobile));

  const isHideCashOutContent = !currentCashOut || currentCashOut?.status == CashOutStatus.VOID;
  const isDisabled =
    currentCashOut?.status !== CashOutStatus.AVAILABLE ||
    (!currentCashOut.currentLiability && !currentCashOut.profit && !currentCashOut.value);
  const showCashOutStatusMessage =
    (status && status !== CashOutStatuses.PENDING) || autoCashOutStatus === AutoCashOutStatuses.COMPLETED;

  useEffect(() => {
    if (currentCashOut?.profit != undefined) {
      initValues({
        minPartialPercentage: currentCashOut?.minPartialPercentage,
        maxPartialPercentage: currentCashOut?.maxPartialPercentage,
        profit: currentCashOut?.profit,
        liability: currentCashOut?.currentLiability
      });
      handlerCashOutPercentage(100);
    }
  }, [
    currentCashOut?.profit,
    currentCashOut?.maxPartialPercentage,
    currentCashOut?.minPartialPercentage,
    currentCashOut?.currentLiability
  ]);

  const eventOrCashOutPageSuccessAutoCashOutCallback = () => {
    const asianViewBetSlipCashOutPage = searchParams.get(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE);

    setTimeout(() => {
      if (isAsianViewBetSlipTab) {
        dispatch(
          fetchAsianBetSlipCashOutMarkets({
            page: asianViewBetSlipCashOutPage ? Number(asianViewBetSlipCashOutPage) : 0,
            size: desktopCashOutPageSize,
            resetPrev: !asianViewBetSlipCashOutPage,
            changePage: asianViewCashOutPaginationButtons
              ? (page: number) => {
                  searchParams.set(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE, String(page));
                  setSearchParams(searchParams);
                }
              : undefined,
            isPaginationEnabled: asianViewCashOutPaginationButtons
          })
        );
      }
    }, AV_SUCCESS_STATUS_CASH_OUT_MARKETS_REQUEST_INTERVAL);
  };

  const asianBetSlipSuccessAutoCashOutCallBack = () => {
    const asianViewCashOutPage = searchParams.get(ASIAN_VIEW_PAGE_PARAM);
    const asianViewCashOutTabPage = searchParams.get(ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM);
    const isAsianViewCashOutPage = location.pathname.includes(ASIAN_VIEW_CASH_OUT_URL);

    setTimeout(() => {
      if (isAsianViewCashOutPage) {
        dispatch(
          fetchAsianViewCashOutMarkets({
            page: asianViewCashOutPage ? Number(asianViewCashOutPage) : 0,
            size: desktopCashOutPageSize,
            resetPrev: !asianViewCashOutPage,
            changePage: asianViewCashOutPaginationButtons
              ? (page: number) => {
                  searchParams.set(ASIAN_VIEW_PAGE_PARAM, String(page));
                  setSearchParams(searchParams);
                }
              : undefined,
            isPaginationEnabled: asianViewCashOutPaginationButtons
          })
        );
      } else if (isCashOutTabOpened && asianViewOpenedTabsEventCouponId) {
        dispatch(
          fetchEventCashOutMarkets({
            page: asianViewCashOutTabPage ? Number(asianViewCashOutTabPage) : 0,
            size: desktopCashOutPageSize,
            sportId,
            eventId: asianViewOpenedTabsEventCouponId,
            resetPrev: !asianViewCashOutTabPage,
            changePage: asianViewCashOutPaginationButtons
              ? (page: number) => {
                  searchParams.set(ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM, String(page));
                  setSearchParams(searchParams);
                }
              : undefined,
            isPaginationEnabled: asianViewCashOutPaginationButtons
          })
        );
      }
    }, AV_SUCCESS_STATUS_CASH_OUT_MARKETS_REQUEST_INTERVAL);
  };

  useEffect(() => {
    if (autoCashOutStatus === AutoCashOutStatuses.COMPLETED && pendingCashOut) {
      let eventOrCashOutPageOnSuccess: (() => void) | undefined = undefined;

      if (isEvent || (isAsianView && !isAVMobileCashOutPage)) {
        eventOrCashOutPageOnSuccess = eventOrCashOutPageSuccessAutoCashOutCallback;
      }

      if (isAsianBetSlip) {
        dispatch(
          fetchAsianViewBetSlipCashOutStatus({
            statusId: pendingCashOut,
            onSuccessStatus: isDesktop ? asianBetSlipSuccessAutoCashOutCallBack : undefined
          })
        );
      } else if (isEvent) {
        dispatch(
          fetchEventCashOutStatus({
            statusId: pendingCashOut,
            onSuccessStatus: isDesktop ? eventOrCashOutPageOnSuccess : undefined
          })
        );
      } else if (isAsianView) {
        dispatch(
          fetchAsianViewCashOutStatus({
            statusId: pendingCashOut,
            onSuccessStatus: isDesktop ? eventOrCashOutPageOnSuccess : undefined
          })
        );
      } else {
        dispatch(fetchCashOutStatus({ id: pendingCashOut }));
      }
    }
  }, [autoCashOutStatus, pendingCashOut]);

  useEffect(() => {
    if (autoCashOutStatus === AutoCashOutStatuses.COMPLETED) {
      if (isEvent || (isAsianView && !isAVMobileCashOutPage)) {
        if (isEvent) {
          dispatch(setEventCashOutStatus({ marketId, status: CashOutStatuses.SUCCESS }));
        } else {
          dispatch(setAsianViewCashOutStatus({ marketId, status: CashOutStatuses.SUCCESS }));
        }

        eventOrCashOutPageSuccessAutoCashOutCallback();
      } else if (isAsianBetSlip) {
        dispatch(setAsianViewBetSlipCashOutStatus({ marketId, status: CashOutStatuses.SUCCESS }));
        asianBetSlipSuccessAutoCashOutCallBack();
      }
    }
  }, [autoCashOutStatus]);

  useEffect(() => {
    if (isHover && !isDisabled && !isAsianBetSlip) {
      transition(() => {
        const profitSelections: ProfitSelection[] = currentCashOut.profitPerSelection.map((item: ProfitSelection) => {
          return {
            selectionId: item.selectionId,
            profit: item.profit,
            ratio: cashOutPercentage
          };
        });
        if (isEvent) {
          dispatch(hoverEventCashOutBtn({ marketId, selections: profitSelections }));
        } else if (isAsianView) {
          dispatch(hoverAsianViewCashOutBtn({ marketId, selections: profitSelections }));
        } else {
          dispatch(hoverCashOutBtn({ marketId, selections: profitSelections }));
        }
      });
    }
    if (showCashOutStatusMessage && isHover) {
      setIsHover(false);
      if (isEvent) {
        dispatch(leaveEventCashOutBtn());
      } else if (isAsianView) {
        dispatch(leaveAsianViewCashOutBtn());
      } else {
        dispatch(leaveCashOutBtn());
      }
    }
  }, [isHover, cashOutPercentage, currentCashOut?.profitPerSelection, isDisabled, showCashOutStatusMessage]);

  const onHover = () => {
    if (!isDisabled && !isMobile) {
      setIsHover(true);
    }
  };
  const onLeave = () => {
    setIsHover(false);
    if (!isDisabled && !isAsianBetSlip) {
      if (isEvent) {
        dispatch(leaveEventCashOutBtn());
      } else if (isAsianView) {
        dispatch(leaveAsianViewCashOutBtn());
      } else {
        dispatch(leaveCashOutBtn());
      }
    }
  };

  const cleanPassedBets = () => {
    if (isAsianBetSlip) {
      dispatch(asianBetSlipCashOutCleanPlacedId(marketId));
    } else if (isEvent) {
      dispatch(eventCashOutCleanPlacedId(marketId));
    } else if (isAsianView) {
      dispatch(asianViewCashOutCleanPlacedId(marketId));
    } else {
      dispatch(cleanCashOutPlacedId({ marketId }));
    }
  };

  const handleCleanCashOutStatus = (params?: { isSuccess: boolean }) => {
    if (isAsianBetSlip) {
      dispatch(asianBetSlipCashOutCleanStatus(marketId));

      const asianViewBetSlipCashOutPage = searchParams.get(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE);

      dispatch(
        fetchAsianBetSlipCashOutMarkets({
          page: asianViewBetSlipCashOutPage ? Number(asianViewBetSlipCashOutPage) : 0,
          size: desktopCashOutPageSize,
          resetPrev: !asianViewBetSlipCashOutPage,
          changePage: asianViewCashOutPaginationButtons
            ? (page: number) => {
                searchParams.set(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE, String(page));
                setSearchParams(searchParams);
              }
            : undefined,
          isPaginationEnabled: asianViewCashOutPaginationButtons
        })
      );
    } else if (isEvent) {
      dispatch(eventCashOutCleanStatus(marketId));

      const asianViewCashOutTabPage = searchParams.get(ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM);

      if (asianViewOpenedTabsEventCouponId) {
        dispatch(
          fetchEventCashOutMarkets({
            page: asianViewCashOutTabPage ? Number(asianViewCashOutTabPage) : 0,
            size: desktopCashOutPageSize,
            sportId,
            eventId: asianViewOpenedTabsEventCouponId,
            resetPrev: !asianViewCashOutTabPage,
            changePage: asianViewCashOutPaginationButtons
              ? (page: number) => {
                  searchParams.set(ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM, String(page));
                  setSearchParams(searchParams);
                }
              : undefined,
            isPaginationEnabled: asianViewCashOutPaginationButtons
          })
        );
      }
    } else if (isAsianView) {
      dispatch(asianViewCashOutCleanStatus(marketId));

      if (isAVMobileBetList && params?.isSuccess) {
        dispatch(removeAsianViewCashOutQuoteByMarketId(marketId));
      }

      const asianViewCashOutPage = searchParams.get(ASIAN_VIEW_PAGE_PARAM);

      dispatch(
        fetchAsianViewCashOutMarkets({
          page: asianViewCashOutPage ? Number(asianViewCashOutPage) : 0,
          size: desktopCashOutPageSize,
          resetPrev: !asianViewCashOutPage,
          changePage: asianViewCashOutPaginationButtons
            ? (page: number) => {
                searchParams.set(ASIAN_VIEW_PAGE_PARAM, String(page));
                setSearchParams(searchParams);
              }
            : undefined,
          isPaginationEnabled: asianViewCashOutPaginationButtons
        })
      );
    } else {
      dispatch(cleanCashOutStatus({ marketId }));
    }
    if (autoCashOutStatus === AutoCashOutStatuses.COMPLETED) {
      if (isAsianBetSlip) {
        dispatch(removeAsianViewBetSlipAutoCashOutMarket(marketId));
      } else if (isEvent) {
        dispatch(removeEventAutoCashOutMarket(marketId));
      } else if (isAsianView) {
        dispatch(removeAsianViewAutoCashOutMarket(marketId));
      } else {
        dispatch(removeAutoCashOutMarket({ marketId }));
      }
    }
  };

  const handleToggleSettingsTab = () => {
    if (!isOpenSettings) {
      if (isAsianBetSlip) {
        dispatch(asianBetSlipCashOutOpenSettingsTab(marketId));
      } else if (isEvent) {
        dispatch(eventCashOutOpenSettingsTab(marketId));
      } else if (isAsianView) {
        dispatch(asianViewCashOutOpenSettingsTab(marketId));
      } else {
        dispatch(openSettingsTab(marketId));
      }
    } else {
      if (isAsianBetSlip) {
        dispatch(asianBetSlipCashOutCloseSettingsTab(marketId));
      } else if (isEvent) {
        dispatch(eventCashOutCloseSettingsTab(marketId));
      } else if (isAsianView) {
        dispatch(asianViewCashOutCloseSettingsTab(marketId));
      } else {
        dispatch(closeSettingsTab(marketId));
      }
    }
  };

  const getSettingContent = () => {
    if (isOpenSettings && (autoCashOutEnabled || partialCashOutEnabled)) {
      return (
        <CashOutSettingsContent
          isAsianBetSlip={isAsianBetSlip}
          partialCashOutEnabled={partialCashOutEnabled}
          marketId={marketId}
          isEvent={isEvent}
          partial={partial}
          isDisabled={isDisabled}
          onSetPartial={handlerCashOutPercentage}
          currentCashOut={currentCashOut}
          onHover={onHover}
          onLeave={onLeave}
          isAVMobileCashOutPage={isAVMobileCashOutPage}
          isAsianView={isAsianView}
          tabsContainerClassName={tabsContainerClassName}
          isAVMobileBetList={isAVMobileBetList}
          ignoreFlexibleDisplayFormat={ignoreFlexibleDisplayFormat}
        />
      );
    }

    return null;
  };

  if (isAVMobileBetList || isAsianBetSlip || isAVMobileCashOutPage || (isEvent && isMobile)) {
    if (currentCashOut) {
      return (
        <div className={branding.CASH_OUT_BTN}>
          {!pendingCashOut && !showCashOutStatusMessage && (
            <>
              {isEvent && isMobile && (
                <Liability
                  liability={currentCashOut.currentLiability}
                  currency={currentCashOut.currency}
                  className={styles.eventTabLiability}
                  isAsianEventTab
                  ignoreFlexibleDisplayFormat={ignoreFlexibleDisplayFormat}
                />
              )}
              <div
                className={classNames(
                  styles.cashOutBetslip,
                  {
                    [styles.coAVMobPage]: isAVMobileCashOutPage,
                    [styles.avEventTab]: isEvent && isMobile
                  },
                  buttonWrapperClassName
                )}
              >
                <Button
                  marketId={marketId}
                  value={cashOut}
                  profit={profit}
                  currency={currentCashOut.currency}
                  quotePercentage={quotePercentage}
                  isAsianBetSlip={isAsianBetSlip}
                  isDisabled={isDisabled}
                  isAVMobileCashOutPage={isAVMobileCashOutPage}
                  isAsianView={isAsianView}
                  onHover={onHover}
                  onLeave={onLeave}
                  isEvent={isEvent}
                  buttonClassName={buttonClassName}
                  isAVMobileBetList={isAVMobileBetList}
                  cashOutTextClassName={cashOutTextClassName}
                  ignoreFlexibleDisplayFormat={ignoreFlexibleDisplayFormat}
                />
                <button
                  id="cashOutSetting"
                  className={classNames(
                    'biab_cash-out-settings-button-wrap',
                    styles.setting,
                    {
                      'biab_cash-out-settings-button': !autoCashOut,
                      'biab_cash-out-auto-created': !!autoCashOut,
                      [styles.activeSetting]: isOpenSettings,
                      [styles.setting__coMobPage]:
                        isAVMobileCashOutPage || isAVMobileBetList || (isEvent && isAsianView && isMobile),
                      [styles.settingAutoCashOutActive]: !!autoCashOut,
                      [settingsActiveBtnClassName]: isOpenSettings && settingsActiveBtnClassName // should be the last one
                    },
                    settingsBtnClassName
                  )}
                  onClick={handleToggleSettingsTab}
                >
                  <i className="fa fas fa-sliders-h" />
                  {!!autoCashOut && (
                    <i className={classNames('fa fa-circle biab_cash-out-auto-indicator', styles.indicator)} />
                  )}
                </button>
              </div>
            </>
          )}
          {((isEvent && isMobile) || isAVMobileBetList) && pendingCashOut && (
            <AsianViewCashOutProgressBar className={styles.avEventTab__progressBar} />
          )}
          {((isEvent && isMobile) || isAVMobileBetList) && !pendingCashOut && showCashOutStatusMessage && (
            <BetSlipCashOutMessage
              status={autoCashOutStatus === AutoCashOutStatuses.COMPLETED ? CashOutStatuses.SUCCESS : status}
              onClose={handleCleanCashOutStatus}
            />
          )}

          {getSettingContent()}
          {isAsianBetSlip && <Warning marketId={marketId} isAsianBetSlip={isAsianBetSlip} isAsianView={isAsianView} />}
        </div>
      );
    }

    return null;
  }

  return (
    <>
      {placedEvent && isMobile && isAsianView && (
        <PlacementMessage
          bet={placedEvent}
          onClose={cleanPassedBets}
          isAsianBetSlip={isAsianBetSlip}
          isAsianView={isAsianView}
        />
      )}
      {!isHideCashOutContent && (
        <>
          {!pendingCashOut && showCashOutStatusMessage && (
            <CashOutStatusMessage
              value={autoCashOutStatus === AutoCashOutStatuses.COMPLETED ? CashOutStatuses.SUCCESS : status}
              onClose={handleCleanCashOutStatus}
            />
          )}
          {pendingCashOut && <CashOutProgressBar />}
          <Warning marketId={marketId} isEvent={isEvent} isAsianView={isAsianView} />
          {autoCashOutError && (
            <Notification
              type="warning"
              message={autoCashOutError}
              onClose={() => dispatch(resetAutoCashOutError(marketId))}
              className={styles.errorNotification}
            />
          )}
          {!showCashOutStatusMessage && !pendingCashOut && (
            <div id="cashOutContainer" className={styles.container}>
              <div className={classNames('biab_cash-out-button-action-inner', styles.content)}>
                <Liability
                  liability={currentCashOut.currentLiability}
                  currency={currentCashOut.currency}
                  ignoreFlexibleDisplayFormat={ignoreFlexibleDisplayFormat}
                />
                <div className={styles.content__right}>
                  <Button
                    marketId={marketId}
                    value={cashOut}
                    profit={profit}
                    currency={currentCashOut.currency}
                    quotePercentage={quotePercentage}
                    onHover={onHover}
                    onLeave={onLeave}
                    isDisabled={isDisabled}
                    isEvent={isEvent}
                    isAsianView={isAsianView}
                    ignoreFlexibleDisplayFormat={ignoreFlexibleDisplayFormat}
                  />
                  <button
                    id="cashOutSetting"
                    className={classNames('biab_cash-out-settings-button-wrap', styles.setting, {
                      'biab_cash-out-settings-button': !autoCashOut,
                      'biab_cash-out-auto-created': !!autoCashOut,
                      [styles.activeSetting]: isOpenSettings,
                      [styles.settingAutoCashOutActive]: !!autoCashOut
                    })}
                    onClick={handleToggleSettingsTab}
                  >
                    <i className="fa fas fa-sliders-h" />
                    {!!autoCashOut && (
                      <i className={classNames('fa fa-circle biab_cash-out-auto-indicator', styles.indicator)} />
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}

          {getSettingContent()}
        </>
      )}
    </>
  );
};

export default memo(CashOutButton);
