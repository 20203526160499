import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { asianViewMiddleSection as branding } from 'constants/branding';
import { ASIAN_VIEW_BACK_LABEL, ASIAN_VIEW_LAY_LABEL } from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';

import styles from './styles.module.scss';

interface AsianViewBackLayLabelsProps {
  containerClassName?: string;
  isSmall?: boolean;
}

const AsianViewBackLayLabels = ({ containerClassName = '', isSmall = false }: AsianViewBackLayLabelsProps) => {
  const { t } = useTranslation();
  const isMobileAsianView = useSelector(getIsMobileAsianView);

  const { translationKey: backLabelKey, isEnabled: isBackLabelTooltipEnabled } = useTooltip(ASIAN_VIEW_BACK_LABEL);
  const { translationKey: layLabelKey, isEnabled: isLayLabelTooltipEnabled } = useTooltip(ASIAN_VIEW_LAY_LABEL);

  return (
    <div
      className={classNames(
        'biab_asian-view-market-backLay',
        styles.asianViewBackLayLabels,
        containerClassName ?? '',
        branding.MAIN_CONTAINER_BORDER,
        {
          [styles.asianViewBackLayLabels__mobileCondtainer]: isMobileAsianView,
          [styles.asianViewBackLayLabels__smallGap]: isSmall
        }
      )}
    >
      <div
        className={classNames(styles.asianViewBackLayLabels__back, branding.BACK_COLUMN, {
          [styles.asianViewBackLayLabels__small]: isSmall,
          [styles.asianViewBackLayLabels__mobile]: isMobileAsianView
        })}
        data-tooltip-id={'tooltip'}
        data-tooltip-html={isBackLabelTooltipEnabled ? t(backLabelKey) : ''}
      >
        {t('asianView.labels.back')}
      </div>
      <div
        className={classNames(styles.asianViewBackLayLabels__lay, branding.LAY_COLUMN, {
          [styles.asianViewBackLayLabels__small]: isSmall,
          [styles.asianViewBackLayLabels__mobile]: isMobileAsianView
        })}
        data-tooltip-id={'tooltip'}
        data-tooltip-html={isLayLabelTooltipEnabled ? t(layLabelKey) : ''}
      >
        {t('asianView.labels.lay')}
      </div>
    </div>
  );
};

export default AsianViewBackLayLabels;
