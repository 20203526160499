import { useSelector } from 'react-redux';
import classNames from 'classnames';

import GameLoader from 'components/GameLoader';
import GamesCommunitySection from 'components/GamesCommunitySection';
import GamesRoundsList from 'components/GamesRoundsList';
import { POKER_GAMES_ROUNDS } from 'constants/games';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { TCurrentGameData } from 'redux/modules/games/type';

import GamePlayers from './components/GamePlayers';

import styles from './styles.module.scss';

interface PokerAndOmahaGamesProps {
  /**
   * Selected game data (Poker)
   */
  game: TCurrentGameData;
}

const PokerAndOmahaGames = ({ game }: PokerAndOmahaGamesProps) => {
  const device = useSelector(getAppDevice);

  const { round, gameData, bettingWindowPercentageComplete, gameType } = game;
  const isMobile = device === Devices.MOBILE;

  return (
    <div className={classNames(styles.cardsSection)}>
      {round && (
        <div className={styles.cardsSection__roundsWrapper}>
          <GamesRoundsList gamesRounds={POKER_GAMES_ROUNDS} currentRound={round} />
          {isMobile && <GameLoader loaderPercentage={bettingWindowPercentageComplete} />}
        </div>
      )}
      <GamesCommunitySection game={game} />
      {!isMobile && round && <GameLoader loaderPercentage={bettingWindowPercentageComplete} />}
      <GamePlayers gameData={gameData} gameType={gameType} />
    </div>
  );
};

export default PokerAndOmahaGames;
