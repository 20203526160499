import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { CASH_OUT_BASE_URL } from 'constants/locations';
import useCellConfigs from 'hooks/useCellConfigs';
import { useCurrency } from 'hooks/useCurrency';
import useLayColumn from 'hooks/useLayColumn';
import useWindowSize from 'hooks/useWindowSize';
import {
  getAppDevice,
  getLineMarketsSwitchBackLayOnCricket,
  getMinAmountToShowOdds,
  getPNCEnabledSetting,
  getReplaceBackLayWithUnderOver
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setActiveTab, setSelectedBets } from 'redux/modules/betslip';
import { EBetslipTabs } from 'redux/modules/betslip/type';
import { getMarketScrollWidth } from 'redux/modules/market/selectors';
import { TMarketResponse } from 'redux/modules/market/type';
import {
  getMarketPricesById,
  getMarketPricesCurrency,
  getMarketPricesOverRound,
  getMarketPricesUnderRound,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { MarketStatus, PageBlocks, PlacementPage, PopularEventType, SportId } from 'types';
import { BetTypes } from 'types/bets';
import { IMarket } from 'types/markets';
import { getAllBetsFromMarketByType } from 'utils/betslip';
import { formatPercentages, getMarketTypes } from 'utils/market';

import styles from './styles.module.scss';

interface MarketBackLayRowProps {
  /**
   * Market
   */
  market: IMarket | TMarketResponse;
  /**
   * Is market depth enabled?
   */
  isDepthEnabled: boolean;
  /**
   * Show space for deep prices always even if market depth is hidden
   */
  showDeepPrices?: boolean;
  page: PlacementPage;
}

const MarketBackLayRow = ({ market, isDepthEnabled, showDeepPrices = false, page }: MarketBackLayRowProps) => {
  const { description, eventType } = market;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const device = useSelector(getAppDevice);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const scrollWidth = useSelector(getMarketScrollWidth);
  const marketStatus = useSelector(getStatusByMarketPricesId(market.marketId));
  const overround = useSelector(getMarketPricesOverRound(market.marketId));
  const underround = useSelector(getMarketPricesUnderRound(market.marketId));
  const marketPrices = useSelector(getMarketPricesById(market.marketId));
  const lineMarketsSwitchBackLayOnCricket = useSelector(getLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getReplaceBackLayWithUnderOver);
  const minAmountToShowOdds = useSelector(getMinAmountToShowOdds);
  const currencyCode = useSelector(getMarketPricesCurrency(market.marketId));

  const currency = useCurrency(currencyCode);
  const { isLandscape } = useWindowSize(100);
  const { desktopCellsContainerWidth, desktopCellsContainerWidthNumber, mobileCellsContainerWidthNumber } =
    useCellConfigs({
      cellGroupsCount: isDepthEnabled ? 3 : 1,
      isMarketView: true,
      showDepth: showDeepPrices,
      addMobileExtraSpace: true
    });
  const { isLayColumnEnabled } = useLayColumn({
    sportId: market.eventType.id,
    bettingType: market.description.bettingType
  });

  const isDesktop = device === Devices.DESKTOP;
  const isCashOutPage = location.pathname === CASH_OUT_BASE_URL;
  const isShowEmptyColumnsLandscape = !isDepthEnabled && isLandscape && !isCashOutPage;
  const { isHandicap, isCombinedTotal, isEachWay, isLineMarket, isHandicapDoubleLine } = getMarketTypes(
    description.marketType,
    description.bettingType
  );
  const isCricket = eventType.id === SportId.CRICKET;
  const isBackLayAll = !isHandicapDoubleLine && !isLineMarket && isDesktop;
  const hasLineBackLayToNoYes = isCricket && lineMarketsSwitchBackLayOnCricket && isLineMarket;
  const replaceWithOverUnder = !isCricket && replaceBackLayWithUnderOver && isLineMarket;

  let raceName = '';

  market?.parents?.forEach(({ type, name }) => {
    if (type === PopularEventType.RACE) {
      raceName = name;
    }
  });

  let back: string;
  let lay: string;

  if (isBackLayAll) {
    back = 'market.backAll';
  } else if (replaceWithOverUnder) {
    back = 'market.column.under';
  } else if (hasLineBackLayToNoYes) {
    back = 'market.column.no';
  } else {
    back = 'market.back';
  }

  if (isBackLayAll) {
    lay = 'market.layAll';
  } else if (replaceWithOverUnder) {
    lay = 'market.column.over';
  } else if (hasLineBackLayToNoYes) {
    lay = 'market.column.yes';
  } else {
    lay = 'market.lay';
  }

  const desktopCellContainerWidthPercent = !isLayColumnEnabled
    ? `${desktopCellsContainerWidthNumber * 0.5}%`
    : desktopCellsContainerWidth;

  const calculatedMobileCellContainerWidth = !isLayColumnEnabled
    ? `${isDepthEnabled ? mobileCellsContainerWidthNumber * 0.5 : mobileCellsContainerWidthNumber * 1.5}px`
    : `calc(${isShowEmptyColumnsLandscape ? '2 * ' : ''}${mobileCellsContainerWidthNumber}px)`;

  const isOverUnderRoundsEnabled =
    isLoggedIn &&
    isLandscape &&
    !isPNCEnabled &&
    !isHandicap &&
    !isCombinedTotal &&
    !isEachWay &&
    !isLineMarket &&
    (isDesktop || isDepthEnabled || isLandscape);
  const isShowBackLayDepthButtons = isDesktop && !isHandicapDoubleLine && !isLineMarket;

  const isDefaultFormat = currency?.displayFormat === 1;

  const onBetsAllClick = (type: BetTypes) => {
    if (isDesktop && marketStatus !== MarketStatus.SUSPENDED && marketPrices) {
      const { marketType, bettingType, eachWayDivisor, lineRangeInfo, priceLadderDescription } = market.description;

      dispatch(
        setSelectedBets({
          selectedBets: getAllBetsFromMarketByType({
            market,
            marketPrices,
            type,
            pageBlock: page === PlacementPage.TOP_5_VIEW ? PageBlocks.TOP_5_VIEW : PageBlocks.MARKET_ODDS,
            page,
            raceName,
            isDefaultFormat,
            minAmountToShowOdds
          }),
          marketInfo: {
            commission: market.commission,
            sportId: market.eventType.id,
            marketName: market.marketName,
            eventName: market.event.name,
            eventId: market.event.id,
            numberOfWinners: market.numberOfWinners,
            marketType,
            bettingType,
            eachWayDivisor,
            lineRangeInfo,
            priceLadderDescription,
            raceName,
            marketStartTime: market.marketStartTime,
            marketId: market.marketId,
            runners: market.runners
          }
        })
      );
      dispatch(setActiveTab(EBetslipTabs.PLACE_BETS));
    }
  };

  return (
    <div
      className={classNames(styles.marketBackLay__wrapper, {
        [styles.marketBackLay__wrapper__mobile]: !isDesktop,
        [styles.marketBackLay__wrapper__mobileDepth]: !isDesktop && isDepthEnabled && isLayColumnEnabled,
        [styles.marketBackLay__wrapper__withoutLay]: !isLayColumnEnabled
      })}
      style={{
        width: isDesktop ? desktopCellContainerWidthPercent : calculatedMobileCellContainerWidth,
        paddingRight: isDesktop ? `${scrollWidth}px` : ''
      }}
    >
      <div className={styles.marketBackLay}>
        {isOverUnderRoundsEnabled && (
          <div className={styles.marketBackLay__overRounds}>
            <span
              data-tooltip-id={'tooltip'}
              data-tooltip-html={unescape(t('tooltip.overrounds'))}
              className="biab_market-overrounds"
            >
              {formatPercentages(overround)}
            </span>
          </div>
        )}

        <div
          className={classNames(
            'biab_back-all biab_back-all-column',
            styles.marketBackLay__smallItem,
            styles.marketBackLay__back,
            {
              [styles.marketBackLay__hideBackLayDepth]: !isShowBackLayDepthButtons
            }
          )}
        >
          <span className={styles.marketBackLay__smallItem__title} onClick={() => onBetsAllClick(BetTypes.BACK)}>
            {t(back)}
          </span>
        </div>
      </div>

      {isLayColumnEnabled && (
        <div className={styles.marketBackLay}>
          <div
            className={classNames(
              'biab_lay-all biab_lay-all-column',
              styles.marketBackLay__smallItem,
              styles.marketBackLay__lay,
              {
                [styles.marketBackLay__hideBackLayDepth]: !isShowBackLayDepthButtons
              }
            )}
          >
            <span className={styles.marketBackLay__smallItem__title} onClick={() => onBetsAllClick(BetTypes.LAY)}>
              {t(lay)}
            </span>
          </div>

          {isOverUnderRoundsEnabled && (
            <div className={styles.marketBackLay__underRounds}>
              <span
                data-tooltip-id={'tooltip'}
                data-tooltip-html={unescape(t('tooltip.overrounds'))}
                className="biab_market-underrounds"
              >
                {formatPercentages(underround)}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MarketBackLayRow;
