import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';
import { IMarketRules, TFetchMarketRulesResponse } from 'types/markets';

import { TFetchTop5ViewDetailsResponse, TSetTop5ViewLinesResetLinesTextPayload, TTop5View } from './type';

const initialState: TTop5View = {
  loading: false,
  error: null,
  topMatchedMarket: null,
  otherTopMatchedMarkets: [],
  dropdownMarkets: [],
  eventRulesLoading: false,
  eventRules: {},
  eventRulesError: null,
  rulesLoading: false,
  rules: {},
  rulesError: null,
  lines: {
    from: 0,
    to: 0,
    currentLine: 1,
    defaultLine: 0,
    totalLines: 0
  },
  linesText: '',
  showAll: undefined,
  reset: false
};

const slice = createSlice({
  name: SLICES_NAMES.TOP_5_VIEW,
  initialState,
  reducers: {
    fetchTop5ViewDetails: (state, _: PayloadAction<string>) => {
      state.loading = true;
    },
    successFetchTop5ViewDetails: (state, { payload }: PayloadAction<TFetchTop5ViewDetailsResponse>) => {
      state.loading = false;
      state.topMatchedMarket = payload.topMatchedMarket;
      state.otherTopMatchedMarkets = payload.otherTopMatchedMarkets;
      state.dropdownMarkets = payload.dropdownMarkets;
    },
    failureFetchTop5ViewDetails: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = payload;
    },
    fetchTop5ViewEventRules: (state, _: PayloadAction<string>) => {
      state.eventRulesLoading = true;
    },
    successFetchTop5ViewEventRules: (state, { payload }: PayloadAction<IMarketRules[]>) => {
      payload.forEach(item => {
        state.eventRules[item.marketIds as string] = item;
      }, {});

      state.eventRulesLoading = false;
    },
    failureFetchTop5ViewEventRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.eventRulesLoading = false;
      state.eventRulesError = payload;
    },
    fetchTop5ViewMarketRules: (state, _: PayloadAction<string>) => {
      state.rulesLoading = true;
    },
    successFetchTop5ViewMarketRules: (state, { payload }: PayloadAction<TFetchMarketRulesResponse>) => {
      state.rulesLoading = false;
      state.rules[payload.marketId] = payload.rules;
    },
    failureFetchTop5ViewMarketRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.rulesLoading = false;
      state.rulesError = payload;
    },
    setTop5ViewReset: (state, { payload }: PayloadAction<boolean>) => {
      state.reset = payload;
    },
    setTop5ViewLinesResetLinesText: (state, { payload }: PayloadAction<TSetTop5ViewLinesResetLinesTextPayload>) => {
      state.lines = payload.lines;
      state.reset = payload.reset;
      state.linesText = payload.linesText;
    },
    setTop5ViewShowAll: (state, { payload }: PayloadAction<boolean>) => {
      state.showAll = payload;
    }
  }
});

export const {
  failureFetchTop5ViewMarketRules,
  fetchTop5ViewMarketRules,
  fetchTop5ViewEventRules,
  failureFetchTop5ViewEventRules,
  successFetchTop5ViewDetails,
  successFetchTop5ViewMarketRules,
  setTop5ViewReset,
  setTop5ViewShowAll,
  successFetchTop5ViewEventRules,
  setTop5ViewLinesResetLinesText,
  failureFetchTop5ViewDetails,
  fetchTop5ViewDetails
} = slice.actions;

export default slice.reducer;
