import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Loader, { CircleColors } from 'components/Loader';
import Modal from 'components/Modal';
import useDevice from 'hooks/useDevice';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { IMarket, IMarketRules } from 'types/markets';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

interface ICashOutMarketRulesModal {
  market: IMarket | undefined;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  marketsRules: Record<string, IMarketRules> | null;
  marketsRulesLoading: boolean;
}
const CashOutMarketRulesModal = ({
  market,
  openModal,
  setOpenModal,
  marketsRules,
  marketsRulesLoading
}: ICashOutMarketRulesModal) => {
  const { t } = useTranslation();
  const { isAsianViewPage } = useDevice();

  const marketTime = useLongDateFormat({
    time: market?.marketStartTime ?? 0,
    hasYear: true,
    divider: ', '
  });

  if (!market) {
    return null;
  }

  return (
    <>
      <Modal
        customClassNames={{ header: styles.modalHeader, closeIcon: !isAsianViewPage ? 'biab_close' : '' }}
        title={
          marketsRulesLoading
            ? t('marketRules.loading')
            : `${marketsRules?.[market?.marketId]?.marketName || ''} - ${t('rules.title')}`
        }
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        {marketsRulesLoading ? (
          <Loader circleColor={CircleColors.BLACK} />
        ) : (
          <div className={styles.rules__modal}>
            <div>
              {t('marketRules.eventStartTime')}:
              <br />
              {marketTime}
            </div>
            <div
              className={styles.rules__modal__text}
              dangerouslySetInnerHTML={{
                __html:
                  marketsRules?.[market?.marketId]?.rules.replace(/CONTEXT_ROOT_PATH/g, getEnvironmentRootPath()) ?? ''
              }}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default CashOutMarketRulesModal;
