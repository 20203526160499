import classNames from 'classnames';

import styles from './styles.module.scss';

interface GameLoaderProps {
  loaderPercentage: number;
  classes?: string[];
}

const GameLoader = ({ loaderPercentage, classes = [] }: GameLoaderProps) => {
  return (
    <div className={classNames(classes?.join(' '), 'biab_games-progress-wrapper', styles.progress__wrapper)}>
      <div
        className={classNames('biab_games-progress', styles.progress__bar)}
        style={{ width: `${loaderPercentage ?? 0}%` }}
      />
    </div>
  );
};

export default GameLoader;
