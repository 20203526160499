import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ReactComponent as DoubleChevron } from 'assets/icons/DoubleChevron.svg';
import { asianViewMiddleSection as branding } from 'constants/branding';
import { handlerAllCollapses } from 'redux/modules/asianView';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';

import styles from './styles.module.scss';

const CollapseButtons = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobileAsianView = useSelector(getIsMobileAsianView);

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.btn, branding.COUPON_BUTTON, {
          [styles.btn__mobile]: isMobileAsianView
        })}
        onClick={() => dispatch(handlerAllCollapses(true))}
      >
        {t('asianView.labels.btn.expandAll')}
        {!isMobileAsianView && <DoubleChevron className={styles.btn__chevronDown} />}
        {isMobileAsianView && <i className={classNames('av-icon biab_expand av-icon-arrows-down')} />}
      </div>
      <div
        className={classNames(styles.btn, branding.COUPON_BUTTON, {
          [styles.btn__mobile]: isMobileAsianView
        })}
        onClick={() => dispatch(handlerAllCollapses(false))}
      >
        {t('asianView.labels.btn.collapseAll')}
        {!isMobileAsianView && <DoubleChevron />}
        {isMobileAsianView && <i className={classNames('av-icon biab_expand av-icon-arrows-up')} />}
      </div>
    </div>
  );
};

export default CollapseButtons;
