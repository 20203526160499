import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { getPLTableCurrentPL, getPLTableWorstCasePL } from 'redux/modules/plTable/selectors';

import CurrentPL from '../CurrentPL';

import { getTitle, mapperPlToTable } from './helpers';

import styles from './styles.module.scss';

interface TotalGoalTableProps {
  currencyCode: string;
}

const TotalGoalTable = ({ currencyCode }: TotalGoalTableProps) => {
  const { sportId = '' } = useParams();
  const { t } = useTranslation();

  const currentPL = useSelector(getPLTableCurrentPL);
  const worstCasePL = useSelector(getPLTableWorstCasePL);

  const values = mapperPlToTable(currentPL, worstCasePL);

  return (
    <div className={classNames('biab_pl-table-wrapper', styles.contentContainer)}>
      <table className={classNames('biab_pl-table', styles.table)}>
        <colgroup>
          <col width="300" />
          <col width="150" />
          <col width="150" />
        </colgroup>
        <thead>
          <tr>
            <th className={classNames('biab_line-col', styles.table__col)}>{t('PLTable.line')}</th>
            <th className={classNames('biab_profit-loss-col', styles.table__col)}>{t('PLTable.profitLoss')}</th>
            <th className={classNames('biab_worst-case-col', styles.table__col)}>{t('PLTable.worstCase')}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(values).map(([key, { min, max, current, worst }], index, array) => (
            <tr key={key}>
              <td className={styles.table__title}>{getTitle({ index, min, max, length: array.length, t, sportId })}</td>
              <td className={classNames(styles.table__col, styles.table__colBackground)}>
                <CurrentPL value={current} currencyCode={currencyCode} />
              </td>
              <td className={classNames(styles.table__col, styles.table__colBackground)}>
                <CurrentPL value={worst} currencyCode={currencyCode} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TotalGoalTable;
