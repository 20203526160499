import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCashOutGetStatusInterval, getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';

const useCashOutProgressBar = () => {
  const cashOutGetStatusInterval = useSelector(getCashOutGetStatusInterval);
  const language = useSelector(getLanguage);
  const timezone = useSelector(getTimezone);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (width < 90) {
        setWidth(prevState => prevState + 10);
      }
    }, cashOutGetStatusInterval);

    if (width === 90) {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [width, cashOutGetStatusInterval, language, timezone]);

  return { width, language, timezone };
};

export default useCashOutProgressBar;
