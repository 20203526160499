import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import BlockItem from 'components/GameHiLoCounter/partials/BlockItem';
import { VALUES_MAP_TO_CARDS } from 'constants/games';
import { TGameDataProperties } from 'redux/modules/games/type';

import styles from './styles.module.scss';

interface IGameHiLoCounter {
  properties: TGameDataProperties[];
}

const GameHiLoCounter = ({ properties }: IGameHiLoCounter) => {
  const { t } = useTranslation();
  const current = VALUES_MAP_TO_CARDS.find(item => item.id === properties[properties.length - 1]?.value);
  let lowerItemsCount = 0;
  let higherItemsCount = 0;
  if (current) {
    lowerItemsCount = VALUES_MAP_TO_CARDS.reduce((result, item) => {
      if (current.order > item.order && !properties.some(p => p?.value === item.id)) {
        result = result + 1;
      }

      return result;
    }, 0);

    higherItemsCount = VALUES_MAP_TO_CARDS.reduce((result, item) => {
      if (current.order < item.order && !properties.some(p => p?.value === item.id)) {
        result = result + 1;
      }

      return result;
    }, 0);
  }

  return (
    <div className={classnames(styles.counterMain, 'biab_hilo-cards-counter-list')}>
      {!!properties.length && (
        <BlockItem
          classes={[styles.lowerCounter]}
          value={`${lowerItemsCount} ${t('games.hilo.lower')}`}
          isActive={lowerItemsCount > higherItemsCount}
        />
      )}
      {VALUES_MAP_TO_CARDS.map(item => (
        <BlockItem
          bold
          key={item.value}
          value={item.value}
          isActive={item.value === current?.value}
          isPlayed={item.value !== current?.value && properties.some(p => p.value === item.id)}
        />
      ))}
      {!!properties.length && (
        <BlockItem
          classes={[styles.higherCounter]}
          value={`${higherItemsCount} ${t('games.hilo.higher')}`}
          isActive={higherItemsCount > lowerItemsCount}
        />
      )}
    </div>
  );
};

export default GameHiLoCounter;
