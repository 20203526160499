import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { SPORT_BASE_URL } from 'constants/locations';

import styles from '../../styles.module.scss';

interface InlinePlacementTooltipWrapperProps {
  length: number;
  tooltipText: string;
  linkClasses: string;
  marketId: string;
  eventTypeId: string;
  inMarketsTableRow?: boolean;
}

const InlinePlacementTooltipWrapper = ({
  length,
  tooltipText,
  eventTypeId,
  marketId,
  linkClasses
}: InlinePlacementTooltipWrapperProps) => {
  return (
    <div className={styles.tooltip}>
      <span className={styles.tooltipHolder}>
        <span className={classNames(styles.tooltipContent, linkClasses)}>
          <span className={styles.tooltipString}>{`${length} ${tooltipText}`}</span>
        </span>
      </span>
      <NavLink to={`${SPORT_BASE_URL}/${eventTypeId}/market/${marketId}`} className={linkClasses}>
        {length}
      </NavLink>
    </div>
  );
};

export default InlinePlacementTooltipWrapper;
