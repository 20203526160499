import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';

import { FetchMetaPageContentPayload, MetaPage, SetMetaPageContent, TMeta } from './type';

const initialState: TMeta = {
  meta: {},
  loading: false,
  error: null,
  pageId: null
};

const slice = createSlice({
  name: SLICES_NAMES.META,
  initialState,
  reducers: {
    fetchPageMetaContent: (state, _: PayloadAction<FetchMetaPageContentPayload>) => {
      state.loading = true;
    },
    successFetchPageMetaContent: (state, { payload }: PayloadAction<SetMetaPageContent>) => {
      state.loading = false;
      state.meta[payload.page] = payload.content;
    },
    failureFetchPageMetaContent: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = payload;
    },
    setMetaPageId: (state, { payload }: PayloadAction<MetaPage | string | null>) => {
      state.pageId = payload;
    },
    resetMetaContent: () => initialState
  }
});

export const {
  fetchPageMetaContent,
  resetMetaContent,
  successFetchPageMetaContent,
  failureFetchPageMetaContent,
  setMetaPageId
} = slice.actions;

export default slice.reducer;
