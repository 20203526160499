import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AUTO_CASH_OUT_GET_MARKETS_INTERVAL, GeneralWebSocketSubscriptionTypes } from 'constants/app';
import {
  getAutoCashOutEnabled,
  getAutoCashOutWsEnabled,
  getCashOutGetQuotesInterval,
  getCashOutQuoteWsEnabled,
  getGeneralWsEnabled,
  getIsPropertiesLoaded,
  getLanguage,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import {
  fetchAsianBetSlipAutoCashOutMarkets,
  fetchAsianBetSlipCashOutQuotes
} from 'redux/modules/asianViewBetSlipCashOut';
import {
  getAsianBetSlipCashOutMarketsContentMarketIds,
  getAsianBetSlipCashOutQuotesLoading
} from 'redux/modules/asianViewBetSlipCashOut/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  getIsConnectedAsianViewGeneral,
  getSubscribeToAsianViewGeneralMessages
} from 'redux/modules/webSocket/selectors';

const BetSlipCashOutIntervalsInjection = () => {
  const dispatch = useDispatch();

  const quotesLoading = useSelector(getAsianBetSlipCashOutQuotesLoading);
  const marketIds = useSelector(getAsianBetSlipCashOutMarketsContentMarketIds);
  const language = useSelector(getLanguage);
  const timezone = useSelector(getTimezone);
  const cashOutGetQuotesInterval = useSelector(getCashOutGetQuotesInterval);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const cashOutQuoteWsEnabled = useSelector(getCashOutQuoteWsEnabled);
  const isConnectedAsianViewGeneralWebSocket = useSelector(getIsConnectedAsianViewGeneral);
  const subscribeAsianViewGeneralWebSocketMessages = useSelector(getSubscribeToAsianViewGeneralMessages);
  const autoCashOutWsEnabled = useSelector(getAutoCashOutWsEnabled);
  const autoCashOutEnabled = useSelector(getAutoCashOutEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const cashOutQuotesInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const quotesLoadingRef = useRef(quotesLoading);
  const autoCashOutIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const generalWsDataRef = useRef<{
    subscribeAsianViewGeneralWebSocketMessages: (<F>(params: F) => void) | null;
    isCashOutQuotesSubscriptionAvailable: boolean;
    isAutoCashOutSubscriptionAvailable: boolean;
  }>({
    subscribeAsianViewGeneralWebSocketMessages,
    isCashOutQuotesSubscriptionAvailable: false,
    isAutoCashOutSubscriptionAvailable: false
  });

  quotesLoadingRef.current = quotesLoading;

  const isCashOutAvailable = isLoggedIn && !!marketIds.length && arePropertiesLoaded;
  const isCashOutQuoteIntervalAvailable = isCashOutAvailable && (!generalWsEnabled || !cashOutQuoteWsEnabled);
  const isCashOutQuotesSubscriptionAvailable =
    isCashOutAvailable &&
    cashOutQuoteWsEnabled &&
    generalWsEnabled &&
    isConnectedAsianViewGeneralWebSocket &&
    !!subscribeAsianViewGeneralWebSocketMessages;
  const isAutoCashOutAvailable = isCashOutAvailable && autoCashOutEnabled;
  const isAutoCashOutIntervalAvailable = isAutoCashOutAvailable && (!generalWsEnabled || !autoCashOutWsEnabled);
  const isAutoCashOutSubscriptionAvailable =
    isAutoCashOutAvailable &&
    autoCashOutWsEnabled &&
    generalWsEnabled &&
    isConnectedAsianViewGeneralWebSocket &&
    !!subscribeAsianViewGeneralWebSocketMessages;

  generalWsDataRef.current = {
    subscribeAsianViewGeneralWebSocketMessages,
    isCashOutQuotesSubscriptionAvailable,
    isAutoCashOutSubscriptionAvailable
  };

  useEffect(() => {
    if (isCashOutQuoteIntervalAvailable) {
      dispatch(fetchAsianBetSlipCashOutQuotes({ ids: marketIds, firstLoading: true }));

      cashOutQuotesInterval.current = setInterval(() => {
        if (!quotesLoadingRef.current) {
          dispatch(fetchAsianBetSlipCashOutQuotes({ ids: marketIds }));
        }
      }, cashOutGetQuotesInterval);
    }

    return () => {
      if (cashOutQuotesInterval.current) {
        clearInterval(cashOutQuotesInterval.current);
      }
    };
  }, [marketIds, cashOutGetQuotesInterval, language, timezone, isCashOutQuoteIntervalAvailable]);

  useEffect(() => {
    if (isAutoCashOutIntervalAvailable) {
      const getAutoCashOutMarkets = () => {
        dispatch(fetchAsianBetSlipAutoCashOutMarkets(marketIds));
      };

      getAutoCashOutMarkets();
      autoCashOutIntervalRef.current = setInterval(getAutoCashOutMarkets, AUTO_CASH_OUT_GET_MARKETS_INTERVAL);
    }

    return () => {
      if (autoCashOutIntervalRef.current) {
        clearInterval(autoCashOutIntervalRef.current);
      }
    };
  }, [marketIds, language, timezone, isAutoCashOutIntervalAvailable]);

  useEffect(() => {
    if (isCashOutQuotesSubscriptionAvailable && marketIds.length) {
      subscribeAsianViewGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.cashOutQuoteAsianBetSlip]: {
          subscribe: true,
          marketIds
        }
      });
    }
  }, [isCashOutQuotesSubscriptionAvailable, marketIds]);

  useEffect(() => {
    if (isAutoCashOutSubscriptionAvailable && marketIds.length) {
      subscribeAsianViewGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.autoCashOutAsianBetSlip]: {
          subscribe: true,
          marketIds
        }
      });
    }
  }, [isAutoCashOutSubscriptionAvailable, marketIds]);

  useEffect(() => {
    return () => {
      const {
        subscribeAsianViewGeneralWebSocketMessages: subscribeFunc,
        isCashOutQuotesSubscriptionAvailable: isQuotesAvailable,
        isAutoCashOutSubscriptionAvailable: isAutoCashOutsAvailable
      } = generalWsDataRef.current;

      if (subscribeFunc && isQuotesAvailable) {
        subscribeFunc({
          [GeneralWebSocketSubscriptionTypes.cashOutQuoteAsianBetSlip]: {
            subscribe: false
          }
        });
      }

      if (subscribeFunc && isAutoCashOutsAvailable) {
        subscribeFunc({
          [GeneralWebSocketSubscriptionTypes.autoCashOutAsianBetSlip]: {
            subscribe: false
          }
        });
      }
    };
  }, []);

  return null;
};

export default BetSlipCashOutIntervalsInjection;
