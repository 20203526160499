import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import BetGroupEmptyCells from 'components/BetGroupEmptyCells';
import BetCellTooltip from 'components/MarketsTable/components/MarketsTableRow/components/BetCellTooltip';
import BetContentGroup from 'components/MarketsTable/components/MarketsTableRow/components/BetContentGroup';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useLayColumn from 'hooks/useLayColumn';
import useMarketStatusAndLockIcon from 'hooks/useMarketStatusAndLockIcon';
import { getAppDevice, getHomePageColumnsNumber } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getMarketPricesIsBettingEnabled, getStatusByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { MarketStatus, PageBlocks, PlacementPage, SportId } from 'types';
import { BetContentGroupType, BettingType, IMarket, RunnersStatuses, TMarketRunner } from 'types/markets';
import { getBetGroups, getMarketTypes } from 'utils/market';

import styles from '../../styles.module.scss';

interface CellsRowProps {
  /**
   * Market */
  market: IMarket;
  /**
   * Row container width */
  containerWidth: string;
  /**
   * Market runners */
  runner: TMarketRunner;
  /**
   * Redirect to single market page if inline placement is disabled
   */
  redirectToSingleMarketPage?: boolean;
  isHomePage?: boolean;
  pageBlock: PageBlocks;
  /**
   * Whether market depth is enabled
   */
  isDepthEnabled: boolean;
  /**
   * Used to always show betslip placement for certain markets
   */
  page: PlacementPage;
}

const CellsRow = ({
  market,
  isHomePage,
  containerWidth,
  runner,
  pageBlock,
  redirectToSingleMarketPage,
  isDepthEnabled,
  page
}: CellsRowProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const homePageColumnsNumber = useSelector(getHomePageColumnsNumber);
  const bettingEnabled = useSelector(getMarketPricesIsBettingEnabled(market.marketId));
  const marketPricesStatus = useSelector(getStatusByMarketPricesId(market.marketId));

  const { isLayColumnEnabled, isLineMarket } = useLayColumn({
    sportId: market.eventType.id,
    bettingType: market.description.bettingType
  });

  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();

  const { isTotalGoals } = getMarketTypes(market.description.marketType, market.description.bettingType);
  const isMobile = device === Devices.MOBILE;
  const isDesktop = device === Devices.DESKTOP;
  const isMarketClosed = marketPricesStatus === MarketStatus.CLOSED;
  const isShowWinnerStatus = isTotalGoals && runner.runnerStatus === RunnersStatuses.WINNER;
  const isShowEmptyColumns =
    !isDepthEnabled && (!isHomePage || homePageColumnsNumber !== 2) && isDesktop && pageBlock !== PageBlocks.CASH_OUT;

  const betGroups = getBetGroups(isDepthEnabled, isLayColumnEnabled, isLineMarket);
  const isDefault = betGroups.every(type => type === BetContentGroupType.DEFAULT);
  const isFancySwapEnabled =
    market.eventType.id === SportId.CRICKET &&
    market.description.bettingType === BettingType.LINE &&
    swapColorsFancyMarketsOnCricket;

  const { showStatus, status, showLockIcon, displayStatus } = useMarketStatusAndLockIcon(
    bettingEnabled,
    marketPricesStatus
  );

  return (
    <div
      style={{
        width: `calc(${containerWidth} ${!isMobile && !isDepthEnabled ? '+ 2px' : ''})`
      }}
      className={classNames(styles.cellBlock, 'cellBlock', {
        [styles.cellBlock__depth]: isDepthEnabled,
        [styles.cellBlock__depthMobile]: isMobile && !isDepthEnabled,
        [styles.cellBlock__homePageRow]: isHomePage,
        [styles.cellBlock__default]: isDefault,
        'biab_fancy-swap': isFancySwapEnabled
      })}
    >
      {isMarketClosed ? (
        isShowWinnerStatus && (
          <span className={classNames('biab_selection-status-value', styles.statusLabel)}>
            {t(`market.selection.status.${runner?.runnerStatus?.toLowerCase()}`)}
          </span>
        )
      ) : (
        <>
          <BetCellTooltip isLayColumnEnabled={isLayColumnEnabled} />
          {isShowEmptyColumns && <BetGroupEmptyCells />}
          {betGroups.map(type => (
            <BetContentGroup
              marketStatusSettings={{ showStatus, status, showLockIcon, displayStatus }}
              key={type}
              runner={runner}
              betGroupsCount={betGroups.length}
              market={market}
              pageBlock={pageBlock}
              type={type}
              redirectToSingleMarketPage={redirectToSingleMarketPage}
              allGroupsTypes={betGroups}
              groupClasses={
                isShowEmptyColumns
                  ? {
                      betContent: isLayColumnEnabled ? styles.market__flex__double : styles.market__flex
                    }
                  : undefined
              }
              cellClasses={{
                cell: isMobile ? styles.cellBlock__cell__mobile : styles.cellBlock__cell,
                content: isMobile ? styles.cellContent__mobile : styles.cellContent
              }}
              page={page}
            />
          ))}
          {isShowEmptyColumns && isLayColumnEnabled && <BetGroupEmptyCells />}
        </>
      )}
    </div>
  );
};

export default CellsRow;
