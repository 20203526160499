import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import styles from './styles.module.scss';

const SelectedMarketNotification = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.selectedMarketNotification}>
      <i className={classNames('biab_custom-icon-info-circle', styles.selectedMarketNotification__icon)} />
      <div
        className={styles.selectedMarketNotification__text}
        dangerouslySetInnerHTML={{ __html: unescape(t('betslip.labels.closedMarketNotification')) }}
      />
    </div>
  );
};

export default SelectedMarketNotification;
