import { ReactNode } from 'react';
import classNames from 'classnames';

import { betslipBranding, componentsBranding, mobileComponents } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import { ResponsibleBettingAlertTypeKeys, ResponsibleBettingAlertTypes } from 'types/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingAlertProps {
  type?: ResponsibleBettingAlertTypeKeys;
  description?: string;
  injection?: string;
  children?: ReactNode;
}

const ResponsibleBettingAlert = ({
  type = ResponsibleBettingAlertTypes.Error,
  description,
  children
}: ResponsibleBettingAlertProps) => {
  const { isMobile } = useDevice();

  return description || children ? (
    <div
      className={classNames(styles.container, {
        [styles.container__error]: type === ResponsibleBettingAlertTypes.Error,
        [styles.container__info]: type === ResponsibleBettingAlertTypes.Info,
        [styles.container__outline]: type === ResponsibleBettingAlertTypes.Outline,
        [componentsBranding.NOTIFICATION]: !isMobile,
        [componentsBranding.ERROR]: type === ResponsibleBettingAlertTypes.Error,
        [mobileComponents.NOTIFICATION]: isMobile,
        [mobileComponents.INFO]: isMobile && type === ResponsibleBettingAlertTypes.Info
      })}
    >
      {type === ResponsibleBettingAlertTypes.Error ? (
        <i className={classNames(styles.icon, 'biab_custom-icon-warning-circle', betslipBranding.WARNING_ICON)} />
      ) : (
        <i className={classNames(styles.icon, 'biab_custom-icon-info-circle', betslipBranding.INFO_ICON)} />
      )}
      {description ? <span className={styles.description}>{description}</span> : children}
    </div>
  ) : null;
};

export default ResponsibleBettingAlert;
