import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';
import { isResponsibleGamblingError } from 'utils/betslip';

import {
  BetslipType,
  PlacementStatus,
  TCancelAllBetsPayload,
  TCancelBetsPayload,
  TEditBetsPayload,
  TPlaceBetsPayload,
  TPlacedBetsByMarket,
  TPlacedBetsState,
  TPlacementError
} from './type';

const initialState: TPlacedBetsState = {
  placedSelectedMarkets: {},
  placedSelectedBets: {},
  loading: false,
  error: null,
  cancelledBetsStatusId: null,
  rgErrorMessage: null
};

const slice = createSlice({
  name: SLICES_NAMES.PLACEMENT,
  initialState,
  reducers: {
    placeBets: (state, { payload }: PayloadAction<TPlaceBetsPayload>) => {
      if (!payload.isInlinePlacement) {
        state.loading = true;
      }

      Object.entries(payload.data).forEach(([marketId, bets]) => {
        state.placedSelectedMarkets[marketId] = { isInlinePlacement: payload.isInlinePlacement };

        bets.forEach(({ betUuid }) => {
          state.placedSelectedBets[betUuid] = { isInlinePlacement: payload.isInlinePlacement };
        });
      });
    },
    successPlaceBets: (state, { payload }: PayloadAction<TPlacedBetsByMarket>) => {
      state.loading = false;

      Object.entries(payload).forEach(([marketId, { status, exception, offerIds, betUuids = [] }]) => {
        if (!state.placedSelectedMarkets[marketId]) {
          state.placedSelectedMarkets[marketId] = {};
        }

        if (status === PlacementStatus.FAIL && exception) {
          Object.keys(state.placedSelectedMarkets).forEach(id => {
            if (id === marketId) {
              state.placedSelectedMarkets[marketId].status = status;
              state.placedSelectedMarkets[marketId].exception = exception;
            }
          });
          betUuids.forEach(betUuid => {
            if (betUuid.includes(BetslipType.INLINE)) {
              state.placedSelectedBets[betUuid].status = status;
              state.placedSelectedBets[betUuid].exception = exception;
            }
          });
        } else {
          Object.entries(offerIds ?? {}).forEach(([betUuid, offerId]) => {
            state.placedSelectedBets[betUuid].status = status;
            state.placedSelectedBets[betUuid].offerId = offerId;
          });
          state.placedSelectedMarkets[marketId].offerIdsUuids = offerIds;
        }
      });
    },
    failurePlaceBets: (state, { payload }: PayloadAction<{ error: TPlacementError; betUuids: string[] }>) => {
      state.loading = false;

      const { error, betUuids } = payload;

      Object.keys(state.placedSelectedMarkets).forEach(marketId => {
        state.placedSelectedMarkets[marketId].exception = {
          id: error?.data?.id ?? '',
          code: error?.data?.code ?? '',
          message: error?.data?.message ?? ''
        };
      });

      betUuids.forEach(betUuid => {
        if (betUuid.includes(BetslipType.INLINE)) {
          if (!state.placedSelectedBets[betUuid]) {
            state.placedSelectedBets[betUuid] = {};
          }

          state.placedSelectedBets[betUuid].status = 'FAIL';
          state.placedSelectedBets[betUuid].exception = error?.data;
        }
      });

      if (isResponsibleGamblingError(payload.error)) {
        state.rgErrorMessage = payload.error;
      }
    },
    editBets: (_, __: PayloadAction<TEditBetsPayload>) => {},
    successEditBets: (_, __: PayloadAction<TPlacedBetsByMarket>) => {},
    failureEditBets: (state, { payload }: PayloadAction<{ error: TPlacementError; betUuids: string[] }>) => {
      payload.betUuids.forEach(betUuid => {
        if (betUuid.includes(BetslipType.INLINE)) {
          if (!state.placedSelectedBets[betUuid]) {
            state.placedSelectedBets[betUuid] = {};
          }

          state.placedSelectedBets[betUuid].status = 'FAIL';
          state.placedSelectedBets[betUuid].exception = payload.error?.data;
        }
      });
    },
    cancelBets: (_, __: PayloadAction<TCancelBetsPayload>) => {},
    successCancelBets: (_, __: PayloadAction<TPlacedBetsByMarket>) => {},
    failureCancelBets: (_, __: PayloadAction<TFailureActionPayload>) => {},
    cancelAllBets: (_, __: PayloadAction<TCancelAllBetsPayload>) => {},
    successCancelAllBets: (state, { payload }: PayloadAction<number | null>) => {
      state.cancelledBetsStatusId = payload;
    },
    failureCancelAllBets: (_, __: PayloadAction<TFailureActionPayload>) => {},
    removePlacedSelectedBet: (state, { payload }: PayloadAction<{ betUuid: string }>) => {
      if (state.placedSelectedBets[payload.betUuid]) {
        delete state.placedSelectedBets[payload.betUuid];
      }
    },
    removePlacedSelectedBetError: (state, { payload }: PayloadAction<{ betUuid: string }>) => {
      if (state.placedSelectedBets[payload.betUuid]?.exception) {
        delete state.placedSelectedBets[payload.betUuid].status;
        delete state.placedSelectedBets[payload.betUuid].exception;
      }
    },
    removePlacedSelectedMarket: (state, { payload }: PayloadAction<{ marketId: string }>) => {
      if (state.placedSelectedMarkets[payload.marketId]) {
        delete state.placedSelectedMarkets[payload.marketId];
      }
    },
    removeAllPlacedSelectedBets: state => {
      state.placedSelectedBets = {};
      state.placedSelectedMarkets = {};
    },
    setRGErrorMessage: (state, { payload }: PayloadAction<TPlacementError | null | string>) => {
      state.rgErrorMessage = payload;
    }
  }
});

export const {
  placeBets,
  successPlaceBets,
  failurePlaceBets,
  cancelBets,
  editBets,
  failureCancelAllBets,
  failureCancelBets,
  failureEditBets,
  successCancelAllBets,
  successCancelBets,
  successEditBets,
  cancelAllBets,
  removePlacedSelectedBet,
  removePlacedSelectedBetError,
  removePlacedSelectedMarket,
  removeAllPlacedSelectedBets,
  setRGErrorMessage
} = slice.actions;

export default slice.reducer;
