import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import FavoriteStar from 'components/FavoriteStar';
import BackButton from 'components/Header/components/BackButton';
import LiveScoreName from 'components/LiveScoreName';
import MarketDropdown from 'components/MarketDropdown';
import MatchStatistics from 'components/MatchStatistics';
import MobileEventWidgets from 'components/MobileEventWidgets';
import VideoStream from 'components/VideoStream';
import useBackButtonInIFrame from 'hooks/useBackButtonInIFrame';
import useHideLabelsOnCollision from 'hooks/useHideLabelsOnCollision';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { TFavorite } from 'redux/modules/favorites/type';
import {
  getAwayGameSequenceByEventId,
  getAwayScoreByEventId,
  getEventsUpdatedDataEventTypeId,
  getEventsUpdatedDataInPlayMatchStatus,
  getEventsUpdatedDataStringifiedUpdateDetails,
  getEventUpdatedDataCricketAwayHighlight,
  getEventUpdatedDataCricketHomeHighlight,
  getEventUpdatedDataLocalizedAwayName,
  getEventUpdatedDataLocalizedHomeName,
  getEventUpdatedDataTennisAwayGames,
  getEventUpdatedDataTennisHomeGames,
  getHomeGameSequenceByEventId,
  getHomeScoreByEventId
} from 'redux/modules/marketsPrices/selectors';
import { BasketballPlayStatus, PageBlocks, SportId, TEventExtraData } from 'types';

import styles from './styles.module.scss';

type EventHeaderTennisProps = {
  pageBlock?: PageBlocks;
  isCloseMarket?: boolean;
  /**
   * Favorite data
   */
  favoriteData: TFavorite;
  videoStreamingEnabled?: boolean;
  eventId: string;
  matchStatEnabled?: boolean;
  sportId: string;
};

const EventHeaderTennis = ({
  pageBlock,
  isCloseMarket,
  favoriteData,
  videoStreamingEnabled = false,
  eventId,
  matchStatEnabled = false,
  sportId
}: EventHeaderTennisProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const inPlayMatchStatus = useSelector(getEventsUpdatedDataInPlayMatchStatus(eventId));
  const homeName = useSelector(getEventUpdatedDataLocalizedHomeName(eventId));
  const awayName = useSelector(getEventUpdatedDataLocalizedAwayName(eventId));
  const eventTypeId = useSelector(getEventsUpdatedDataEventTypeId(eventId));
  const stringifiedUpdateDetails = useSelector(getEventsUpdatedDataStringifiedUpdateDetails(eventId));
  const homeHighlight = useSelector(getEventUpdatedDataCricketHomeHighlight(eventId));
  const awayHighlight = useSelector(getEventUpdatedDataCricketAwayHighlight(eventId));
  const homeScore = useSelector(getHomeScoreByEventId(eventId));
  const awayScore = useSelector(getAwayScoreByEventId(eventId));
  const homeGameSequence = useSelector(getHomeGameSequenceByEventId(eventId));
  const awayGameSequence = useSelector(getAwayGameSequenceByEventId(eventId));
  const homeGames = useSelector(getEventUpdatedDataTennisHomeGames(eventId));
  const awayGames = useSelector(getEventUpdatedDataTennisAwayGames(eventId));

  const updateDetails = useMemo<TEventExtraData[]>(
    () => (stringifiedUpdateDetails ? JSON.parse(stringifiedUpdateDetails) : []),
    [stringifiedUpdateDetails]
  );

  const showIFrameBackButton = useBackButtonInIFrame();
  useBackButtonInIFrame();

  const isMobile = device === Devices.MOBILE;

  const details = updateDetails?.[0];
  const currentSet = details?.eventExtraData?.currentSet - 1;

  const homePoints = [...(homeGameSequence || []), homeGames];
  const awayPoints = [...(awayGameSequence || []), awayGames];
  const isMoreThanThreeSet = homePoints.length > 3 || awayPoints.length > 3;

  const homePoint = Array.from(Array(isMoreThanThreeSet ? 5 : 3)).map((_, index) => homePoints[index] ?? '-');
  const awayPoint = Array.from(Array(isMoreThanThreeSet ? 5 : 3)).map((_, index) => awayPoints[index] ?? '-');

  const isSearchType = pageBlock === PageBlocks.SEARCH;
  const isShowLeftFavorite = (!isCloseMarket && isSearchType) || (!isCloseMarket && isMobile);
  const hasBackButton = isMobile && !isSearchType && showIFrameBackButton;

  const formatNumber = (number: number | string = '', size = 2) => ('0' + number).slice(-size);

  const { sportWrapperRef, isIntersect, scoreWrapperRef, scoreWrapperWidth, rightSideContainerRef, centerWrapperRef } =
    useHideLabelsOnCollision();

  const isFinished = inPlayMatchStatus === BasketballPlayStatus.FINISH;

  return (
    <div className={classNames({ biab_market: isSearchType })} data-inplay-widget="tennis">
      <div
        className={classNames(
          'biab_running-ball-tennis-wrapper biab_event-title biab_market-title biab_has-icon',
          styles.tennis,
          {
            [styles.tennis__mobile]: isMobile
          }
        )}
      >
        <div className={styles.tennis__container} ref={sportWrapperRef}>
          <div
            className={classNames(styles.eventHeader, {
              [styles.eventHeader__mobile]: isMobile,
              [styles.eventHeader__showFavorite]: isShowLeftFavorite
            })}
            ref={centerWrapperRef}
          >
            {hasBackButton && <BackButton />}
            {!hasBackButton && isShowLeftFavorite && (
              <div className={classNames(styles.eventHeader__favouriteIcon, styles.eventHeader__leftFavorite)}>
                <FavoriteStar entryData={favoriteData} />
              </div>
            )}

            <div
              className={classNames('biab_running-ball-teams-wrapper', styles.eventHeader__leftSide, {
                [styles.eventHeader__leftSide__search]: isSearchType
              })}
              style={{ width: `calc(100% - ${scoreWrapperWidth}px)` }}
            >
              <div className={styles.tennis__name}>
                <h2 className={classNames('biab_running-ball-team', styles.eventHeader__title)}>
                  <LiveScoreName
                    isSearchType={isSearchType}
                    eventTypeId={eventTypeId}
                    name={homeName}
                    eventId={eventId}
                    truncate={isIntersect}
                  />
                  <div
                    className={classNames(
                      'biab_serving',
                      'biab_active',
                      'biab_running-ball-icon',
                      styles.eventHeader__highlight,
                      {
                        [styles.tennis__ball]: homeHighlight
                      }
                    )}
                  >
                    {homeHighlight && !isCloseMarket && !isFinished && (
                      <i className="biab_running-ball-icon biab_rb-tennis-icon" />
                    )}
                  </div>
                </h2>
                <h2 className={classNames('biab_running-ball-team', styles.eventHeader__title)}>
                  <LiveScoreName
                    isSearchType={isSearchType}
                    eventTypeId={eventTypeId}
                    name={awayName}
                    eventId={eventId}
                    truncate={isIntersect}
                  />
                  <div
                    className={classNames(
                      'biab_serving',
                      'biab_active',
                      'biab_running-ball-icon',
                      styles.eventHeader__highlight,
                      {
                        [styles.tennis__ball]: awayHighlight
                      }
                    )}
                  >
                    {awayHighlight && !isCloseMarket && !isFinished && (
                      <i className="biab_running-ball-icon biab_rb-tennis-icon" />
                    )}
                  </div>
                </h2>
              </div>
              <div className={classNames('biab_running-ball-current-set-info', styles.eventHeader__setContainer)}>
                {isCloseMarket || isFinished ? (
                  <div className={classNames('biab-running-ball-status', styles.eventHeader__set)}>
                    {t('runningBall.finished')}
                  </div>
                ) : (
                  <>
                    {' '}
                    <div className={classNames('biab-running-ball-set', styles.eventHeader__set)}>
                      {`${t('market.units.sets_1')} ${details?.eventExtraData?.currentSet || ''}`}
                    </div>
                    <div className={classNames('biab-running-ball-separator', styles.eventHeader__line)}>|</div>
                    <div className={classNames('biab-running-ball-time', styles.eventHeader__set)}>
                      {`${details?.eventExtraData?.fullTimeElapsed?.hour || 0}h${formatNumber(
                        details?.eventExtraData?.fullTimeElapsed?.min || '00'
                      )}`}
                    </div>
                  </>
                )}
                <div className={classNames('biab_serving biab_active', styles.eventHeader__highlight)} />
              </div>
            </div>
            <div className={styles.scoreWrapper} ref={scoreWrapperRef}>
              {!isCloseMarket && (
                <div className={styles.tennis__setPoint}>
                  <div className={classNames('biab_running-ball-score-wrapper', styles.eventHeader__scope)}>
                    <div
                      className={classNames(
                        'biab_current-points-wrapper',
                        styles.eventHeader__point,
                        styles.eventHeader__mainScore
                      )}
                    >
                      <div className={styles.pointDesktopSize}>{homeScore}</div>
                      <div className={styles.pointDesktopSize}>{awayScore}</div>
                    </div>
                  </div>
                  <div className={classNames('biab_point-index', styles.set)}>{` `}</div>
                </div>
              )}
              {(homePoint.length > awayPoint.length ? homePoint : awayPoint).map(
                (value: string | number, index: number) => (
                  <div className={styles.tennis__set} key={`${value}-${index}`}>
                    <div className={classNames('biab_running-ball-score-wrapper', styles.eventHeader__scope)}>
                      <div className="biab_points-wrapper">
                        <div
                          className={classNames('biab_points-value', styles.eventHeader__point, {
                            'biab_active-set': !(isCloseMarket || isFinished) && currentSet === index,
                            [styles.eventHeader__futureSet]: index > currentSet
                          })}
                        >
                          <div
                            className={classNames('biab_point', styles.tennis__point, {
                              biab_winner: (currentSet !== index || isFinished) && homePoint[index] > awayPoint[index]
                            })}
                          >
                            {homePoint[index] ?? '-'}
                          </div>
                          <div
                            className={classNames('biab_point', {
                              biab_winner: (currentSet !== index || isFinished) && awayPoint[index] > homePoint[index]
                            })}
                          >
                            {awayPoint[index] ?? '-'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="biab_points-wrapper">
                      <div className={classNames('biab_point-index', styles.set)}>{`S${++index}`}</div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className={styles.rightSide} ref={rightSideContainerRef}>
            {!isMobile && !isCloseMarket && (videoStreamingEnabled || matchStatEnabled) && (
              <div
                className={classNames(styles.widgetIconsWrapper, {
                  [styles.widgetIconsWrapper__vertical]: isIntersect
                })}
              >
                {videoStreamingEnabled && (
                  <VideoStream visible={videoStreamingEnabled} hideLabel={isIntersect} isInHeader eventId={eventId} />
                )}
                {matchStatEnabled && (
                  <MatchStatistics
                    isSoccer={sportId === SportId.SOCCER}
                    visible={matchStatEnabled}
                    isInHeader
                    eventId={eventId}
                    hideLabel={isIntersect}
                  />
                )}
              </div>
            )}
            {!isMobile && !isCloseMarket && !isSearchType && (
              <div className={styles.eventHeader__favouriteIcon}>
                <FavoriteStar entryData={favoriteData} iconClassName={styles.eventHeader__favouriteIcon__right} />
              </div>
            )}
            {isMobile && <MarketDropdown topPosition={80} />}
          </div>
        </div>
      </div>
      {isMobile && !isCloseMarket && (videoStreamingEnabled || matchStatEnabled) && (
        <MobileEventWidgets
          videoStreamingEnabled={videoStreamingEnabled}
          matchStatEnabled={matchStatEnabled}
          eventId={eventId}
          sportId={sportId}
        />
      )}
    </div>
  );
};

export default EventHeaderTennis;
