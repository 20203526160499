import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

export const getEventCashOutMarketsTotalElements = ({ eventCashOut }: AppState) =>
  eventCashOut.marketsData.totalElements;
export const getEventCashOutMarketsTotalPages = ({ eventCashOut }: AppState) => eventCashOut.marketsData.totalPages;
export const getEventCashOutMarketsContent = ({ eventCashOut }: AppState) => eventCashOut.marketsData.content;
export const getIsEventCashOutMarketsContent = ({ eventCashOut }: AppState) =>
  !!eventCashOut.marketsData.content.length;
export const getEventCashOutMarketsContentMarketIds = createSelector(
  getEventCashOutMarketsContent,
  markets => markets?.map(({ marketId }) => marketId) ?? []
);
export const getEventCashOutPaginationLoading = ({ eventCashOut }: AppState) => eventCashOut.paginationLoading;
export const getEventCashOutIsFirstMarketsLoaded = ({ eventCashOut }: AppState) => eventCashOut.isFirstMarketsLoaded;
export const getEventCashOutMarketsLoading = ({ eventCashOut }: AppState) => eventCashOut.marketsLoading;
export const getEventCashOutIsLastPage = ({ eventCashOut }: AppState) => eventCashOut.marketsData.last;
const getEventCashOut = ({ eventCashOut }: AppState) => eventCashOut;
export const getEventCashOutQuoteByMarketId =
  (marketId: string) =>
  ({ eventCashOut }: AppState) =>
    eventCashOut.quotes[marketId];
export const getEventPendingCashOutByMarketId =
  (marketId: string) =>
  ({ eventCashOut }: AppState) =>
    eventCashOut.pendingCashOuts[marketId];
export const getEventCashOutStatusByMarketId =
  (marketId: string) =>
  ({ eventCashOut }: AppState) =>
    eventCashOut.cashOutStatuses[marketId];
export const getEventAutoCashOutStatusByMarketId =
  (marketId: string) =>
  ({ eventCashOut }: AppState) =>
    eventCashOut.autoCashOutMarkets[marketId]?.status;
export const getEventCashOutSettingsTabByMarketId =
  (marketId: string) =>
  ({ eventCashOut }: AppState) =>
    eventCashOut.settingTabsStates[marketId];
export const getEventAutoCashOutByMarketId =
  (marketId: string) =>
  ({ eventCashOut }: AppState) =>
    eventCashOut.autoCashOutMarkets[marketId];
export const getEventCashOutPlaceIdByMarketId =
  (marketId: string) =>
  ({ eventCashOut }: AppState) =>
    eventCashOut.placedIds[marketId];
export const getEventCashOutPossibleProfitByMarketId =
  (marketId: string) =>
  ({ eventCashOut }: AppState) =>
    eventCashOut.possibleProfits[marketId];
export const getEventCashOutSuccessFullCreatedAutoCashOutByMarketId =
  (marketId: string) =>
  ({ eventCashOut }: AppState) =>
    eventCashOut.successFullCreatedAutoCashOuts[marketId];
export const getEventCashOutSuccessFullDeletedAutoCashOutByMarketId =
  (marketId: string) =>
  ({ eventCashOut }: AppState) =>
    eventCashOut.successFullDeletedAutoCashOuts[marketId];
export const getEventCashOutCashedValueByMarketId =
  (marketId: string) =>
  ({ eventCashOut }: AppState) =>
    eventCashOut.cashedValues[marketId];
export const getEventPendingCashOuts = ({ eventCashOut }: AppState) => eventCashOut.pendingCashOuts;
export const getEventCashOutWhatIfBySelectionId = (marketId: string, selectionId: number) =>
  createSelector(getEventCashOut, ({ whatIf, whatIfMarketId }) =>
    whatIfMarketId === marketId ? whatIf[selectionId] : null
  );
export const getIsEventCashOutFirstQuotesLoaded = ({ eventCashOut }: AppState) => eventCashOut.isFirstQuotesLoaded;
export const getEventCashOutQuotesLength = ({ eventCashOut }: AppState) => Object.keys(eventCashOut.quotes).length;
export const getEventCashOutQuotes = ({ eventCashOut }: AppState) => eventCashOut.quotes;
export const getEventCashOutQuotesMarketsIds = createSelector(getEventCashOutQuotes, quotes => Object.keys(quotes));
export const getEventCashOutStringifiedAutoCashOutMarkets = ({ eventCashOut }: AppState) =>
  eventCashOut.stringifiedAutoCashOutMarkets;
