import { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import FavoriteStar from 'components/FavoriteStar';
import BackButton from 'components/Header/components/BackButton';
import MarketDropdown from 'components/MarketDropdown';
import MobileEventWidgets from 'components/MobileEventWidgets';
import HeaderDetails from 'components/TimeForm/components/HeaderDetails';
import { RACING_SPORT } from 'constants/app';
import { IconsConfig } from 'constants/iconsConfig';
import { MARKET_TYPES } from 'constants/marketTypes';
import useBackButtonInIFrame from 'hooks/useBackButtonInIFrame';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { TFavorite } from 'redux/modules/favorites/type';
import { getSingleMarketInfo } from 'redux/modules/market/selectors';
import { LiveWidgetHeaderType, SportId } from 'types';

import styles from './styles.module.scss';

interface EventHeaderMobileInPlayProps {
  eventTypeId: string;
  title: string;
  startDate: number;
  inPlay?: boolean;
  /**
   * Title class name, f.e. market or event
   */
  type: LiveWidgetHeaderType;
  /**
   * Sport ID
   */
  sportId: string;
  /**
   * Favorite data
   */
  favoriteData: TFavorite;
  isCloseMarket?: boolean;
  videoStreamingEnabled: boolean;
  eventId: string;
  matchStatEnabled: boolean;
  showFavoriteStar: boolean;
}

const EventHeaderMobileInPlay = ({
  eventTypeId,
  title,
  inPlay,
  startDate,
  type,
  sportId,
  isCloseMarket,
  favoriteData,
  videoStreamingEnabled = false,
  eventId,
  matchStatEnabled,
  showFavoriteStar
}: EventHeaderMobileInPlayProps) => {
  const { t } = useTranslation();
  const headerRef = useRef<HTMLDivElement>(null);
  const market = useSelector(getSingleMarketInfo);
  const marketType = market?.description.marketType;

  const marketTime = useLongDateFormat({
    time: startDate,
    hasWeekDay: true
  });
  const showIFrameBackButton = useBackButtonInIFrame();

  const isRacing = RACING_SPORT[sportId];
  const isDailyGoals = marketType === MARKET_TYPES.dailyGoals;
  const isShowFavorite = !isCloseMarket && !isRacing && !isDailyGoals && showFavoriteStar;
  const isHorseRacing = sportId === SportId.HORSE_RACING;

  return (
    <>
      <div className={classNames(`biab_market biab_${type}-title`, styles.marketHeader)}>
        <div ref={headerRef} className={styles.eventHeaderWrapper}>
          <div className={classNames('biab_has-icon', styles.eventHeader)}>
            {showIFrameBackButton ? (
              <BackButton />
            ) : (
              <i
                className={classNames(
                  IconsConfig.SPORT_ICONS[eventTypeId],
                  styles.eventHeader__icon,
                  'biab_br-sport-icon'
                )}
              />
            )}
            <div className={styles.eventHeader__title}>
              <div>
                <div className={classNames('biab_market-title-name', styles.eventHeader__titleBlock)}>
                  <div>{title}</div>
                  {isShowFavorite && <FavoriteStar entryData={favoriteData} />}
                </div>
                <div className={styles.eventHeader__statusRow}>
                  {!isCloseMarket && (
                    <>
                      {inPlay ? (
                        <div className="biab_market-in-play-icon">
                          <i className="fa2 fa2-inplay" title={t('header.inPlay')} />
                          <span className={classNames('biab_market-in-play-label', styles.eventHeader__inPlay)}>
                            {t('competition.view.tabs.inPlay')}
                          </span>
                        </div>
                      ) : (
                        <div className={classNames('biab_market-date biab_visible-xs', styles.eventHeader__date)}>
                          {marketTime}
                        </div>
                      )}
                    </>
                  )}
                  {isHorseRacing && market?.timeformStatistics && (
                    <HeaderDetails details={market?.timeformStatistics.raceDetails} isCloseMarket={isCloseMarket} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <MarketDropdown topPosition={headerRef?.current?.clientHeight || 42} />
      </div>
      {!isCloseMarket && (videoStreamingEnabled || matchStatEnabled) && (
        <MobileEventWidgets
          videoStreamingEnabled={videoStreamingEnabled}
          matchStatEnabled={matchStatEnabled}
          eventId={eventId}
          sportId={sportId}
        />
      )}
    </>
  );
};

export default memo(EventHeaderMobileInPlay);
