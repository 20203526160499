import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setSettings } from 'redux/modules/user';
import { getAccountSettings, getIsUserAccountSettings } from 'redux/modules/user/selectors';
import { CookieNames } from 'types';
import { getBooleanValue } from 'utils';

import useDeviceSettings from './useDeviceSettings';

const useBetslipNetPL = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([CookieNames.BIAB_BETSLIP_NET_PROFIT_LOSS]);

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const accountSettings = useSelector(getAccountSettings);
  const cookieValue = cookies[CookieNames.BIAB_BETSLIP_NET_PROFIT_LOSS];
  const { netOfCommissionBetslip, netOfCommissionBetslipDefaultState } = useDeviceSettings();
  const isEnabled = isLoggedIn && netOfCommissionBetslip;

  let isChecked: boolean;

  if (isLoggedIn) {
    isChecked = accountSettings?.netOfCommissionBetslipEnabledState || false;
  } else if (cookieValue !== undefined) {
    isChecked = getBooleanValue(cookieValue);
  } else if (!isEnabled) {
    isChecked = false;
  } else {
    isChecked = netOfCommissionBetslipDefaultState;
  }

  const onToggle = () => {
    if (isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            netOfCommissionBetslipEnabledState: !isChecked
          }
        })
      );
    }

    setCookie(CookieNames.BIAB_BETSLIP_NET_PROFIT_LOSS, !isChecked, { path: '/' });
  };

  return {
    isChecked,
    onToggle
  };
};

export default useBetslipNetPL;
