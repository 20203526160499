import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';

import styles from './MobileAccountBalance.module.scss';

interface MobileAccountBalanceProps {
  balance: string | number;
  currency: string;
}

const MobileAccountBalance = ({ balance, currency }: MobileAccountBalanceProps) => {
  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  const { noFormattedAmount: formattedBalance } = useFormatCurrency(balance || 0, currency, {
    noRounding: true,
    isCheckIndian: true,
    noSymbol: !isMultiCurrencySupported
  });

  return <div className={styles.balance}>{formattedBalance}</div>;
};

export default MobileAccountBalance;
