import { useSelector } from 'react-redux';

import useWindowSize from 'hooks/useWindowSize';
import {
  getAppDevice,
  getHomePageColumnsNumber,
  getIsHideMarketDepth,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { EMarketDepthView } from 'types/markets';

const useMarketDepth = (view?: EMarketDepthView) => {
  const device = useSelector(getAppDevice);
  const homePageColumnsNumber = useSelector(getHomePageColumnsNumber);
  const hideMarketDepth = useSelector(getIsHideMarketDepth);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);

  const { isLandscape } = useWindowSize();

  const isDesktop = device === Devices.DESKTOP;
  const isMobile = device === Devices.MOBILE;

  const isPrimaryMarketDepthEnabled =
    !hideMarketDepth && isLandscape && (view !== EMarketDepthView.FEATURED_MARKETS || homePageColumnsNumber === 1);

  const isPncDepthEnabled = () => {
    if (view) {
      if (
        isDesktop &&
        [
          EMarketDepthView.FEATURED_MARKETS,
          EMarketDepthView.SINGLE_MARKET,
          EMarketDepthView.MULTI_MARKET,
          EMarketDepthView.TOP_5,
          EMarketDepthView.FANCY_MARKET
        ].includes(view)
      ) {
        return false;
      }
      return !(
        isMobile &&
        [
          EMarketDepthView.SEARCH,
          EMarketDepthView.SINGLE_MARKET,
          EMarketDepthView.MULTI_MARKET,
          EMarketDepthView.TOP_5,
          EMarketDepthView.FANCY_MARKET
        ].includes(view)
      );
    }

    return isPrimaryMarketDepthEnabled;
  };

  return {
    isDepthEnabled: isPNCEnabled ? isPncDepthEnabled() : isPrimaryMarketDepthEnabled,
    isLandscape
  };
};

export default useMarketDepth;
