export const addZero = (value: number) => (value < 10 ? '0' : '');

export const getParsedSessionTime = (startSessionDate: number) => {
  const total = Date.now() - startSessionDate;
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / 1000 / 60 / 60) % 24);

  return `${addZero(hours)}${hours}:${addZero(minutes)}${minutes}:${addZero(seconds)}${seconds}`;
};

export const getParsedSessionTimeInMinutes = (milliseconds: number): number => {
  const currentTime = Date.now();
  const difference = currentTime - milliseconds;
  const minutes = Math.floor(difference / (1000 * 60));
  return minutes;
};
