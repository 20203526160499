import { generateArray } from 'utils/general';

import DelayedItem from '../DelayedItem';
import Skeleton from '../index';

import styles from './styles.module.scss';

interface INavSkeleton {
  itemsCount?: number;
  withDelay?: number;
}

const Content = () => (
  <div className={styles.navSkeletonItemWrapper}>
    <Skeleton className={styles.navSkeletonItem} />
  </div>
);

const NavSkeleton = ({ itemsCount = 10, withDelay = 0 }: INavSkeleton) => {
  const items = generateArray(itemsCount);

  return (
    <div className={styles.navSkeletonContainer} data-skeleton={'navSkeleton'}>
      {items.map((_, index) => {
        return withDelay ? (
          <DelayedItem delay={index * withDelay} key={index} item={<Content />} />
        ) : (
          <Content key={index} />
        );
      })}
    </div>
  );
};
export default NavSkeleton;
