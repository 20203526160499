import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import { SPORT_BASE_URL } from 'constants/locations';
import { getMobileSettingsSwipeMenu } from 'redux/modules/appConfigs/selectors';
import { TNavigationResponse } from 'redux/modules/competitions/type';
import { DropdownItem } from 'types';

import EventInfo from '../EventInfo';

import styles from './styles.module.scss';

interface LandscapeHeaderProps {
  raceData: TNavigationResponse | null;
}

const LandscapeHeader = ({ raceData }: LandscapeHeaderProps) => {
  const { sportId = '', marketId = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const swipeMenu = useSelector(getMobileSettingsSwipeMenu);

  const dropdownOptions = useMemo(() => {
    if (raceData?.children.length) {
      return raceData.children.map(({ name, id }) => {
        return {
          label: name,
          id,
          value: id
        };
      });
    }

    return [];
  }, [raceData?.children]);

  const defaultValue = useMemo(() => {
    return dropdownOptions.find(({ id }) => id === marketId);
  }, [dropdownOptions, marketId]);

  const handleSelectMarket = (option: DropdownItem) => {
    navigate(
      { pathname: `${SPORT_BASE_URL}/${sportId}/market/${option.id}`, search: location.search },
      { replace: true }
    );
  };

  return (
    <div
      className={classNames('biab_market-title', styles.landscapeHeader, {
        [styles.landscapeHeader__swipeMenu]: swipeMenu
      })}
    >
      <EventInfo />
      {!!dropdownOptions.length && (
        <Dropdown
          defaultValue={defaultValue}
          withBackgroundOverlay
          dropdownListIncludesSelected={false}
          data={dropdownOptions}
          onSelectOption={handleSelectMarket}
          classes={{
            container: styles.landscapeHeader__dropdown__container,
            placeholder: styles.landscapeHeader__dropdown__plaholder,
            dropdown: styles.landscapeHeader__dropdown__dropdown,
            option: styles.landscapeHeader__dropdown__option
          }}
        />
      )}
    </div>
  );
};

export default LandscapeHeader;
