import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import CashOut from 'components/CashOut';
import InPlayStatus from 'components/InPlayStatus';
import MarketsTable from 'components/MarketsTable';
import { IconsConfig } from 'constants/iconsConfig';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getAppDevice, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import {
  getIsMarketInPlayByMarketPricesId,
  getMarketPricesCurrency,
  getMatchedAmountByMarketPricesId,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { fetchPopularMarkets } from 'redux/modules/popularMarket';
import { CurrencySettingsPlacement, MarketStatus, PageBlocks, PlacementPage } from 'types';
import { IMarket, MarketsTableColumn, ViewType } from 'types/markets';

import styles from '../styles.module.scss';

interface MarketProps {
  /**
   * Market data
   */
  market: IMarket;
  notHighlighted?: boolean;
  page?: PlacementPage;
}

const Market = ({ market, notHighlighted = false, page }: MarketProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { competition, event, eventType, marketStartTime, runners, marketId, cashOutEnabled } = market;

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const device = useSelector(getAppDevice);
  const isMarketInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketId));
  const marketCurrency = useSelector(getMarketPricesCurrency(marketId));
  const marketMatchedAmount = useSelector(getMatchedAmountByMarketPricesId(marketId));
  const marketStatus = useSelector(getStatusByMarketPricesId(marketId));

  const { formattedAmount: matchedAmount, noFormattedAmount: tooltipMatchedAmount } = useFormatCurrency(
    marketMatchedAmount,
    marketCurrency,
    {
      isCheckIndian: true,
      placement: CurrencySettingsPlacement.MATCHED_AMOUNT
    }
  );
  const headerTitle = matchedAmount ? `${t(`${isPNCEnabled ? 'pnc.' : ''}market.matched`)}: ${matchedAmount}` : '';
  const tooltipHeaderTitle = tooltipMatchedAmount
    ? `${t(`${isPNCEnabled ? 'pnc.' : ''}market.matched`)}: ${tooltipMatchedAmount}`
    : '';

  const marketTime = useLongDateFormat({
    time: marketStartTime,
    hasWeekDay: true
  });

  useEffect(() => {
    if (marketStatus === MarketStatus.CLOSED) {
      dispatch(fetchPopularMarkets());
    }
  }, [marketStatus]);

  return (
    <>
      {device === Devices.DESKTOP ? (
        <div className={classNames('biab_market-odds-wrapper popularMarketSportBetContent', styles.popularMarketSport)}>
          <div className={styles.popularMarketSport__infoWrapper}>
            <div className={styles.popularMarketSport__title}>
              <i className={IconsConfig.SPORT_ICONS[eventType.id]} />
              <span>
                {competition.name} - {event.name}
              </span>
            </div>
            <div className={styles.headerRightSide}>
              {cashOutEnabled && <CashOut showLabel={false} showInfoIcon={false} />}
              <InPlayStatus isInPlay={isMarketInPlay} />
              <span className={styles.popularMarketSport__time}>{marketTime}</span>
            </div>
          </div>
          <MarketsTable
            headerTitle={headerTitle}
            tooltipHeaderTitle={tooltipHeaderTitle}
            markets={[market]}
            isPopularMarketsOdds
            betGroupsCount={runners.length}
            notHighlighted={notHighlighted}
            hiddenColumns={[
              MarketsTableColumn.RULES,
              MarketsTableColumn.IN_PLAY,
              MarketsTableColumn.MATCHED,
              MarketsTableColumn.PLACEMENTS,
              MarketsTableColumn.MOBILE_SPORT_ICON
            ]}
            staticViewType={ViewType.EXTRA_SMALL}
            classes={{ headerCell: styles.popularMarketSport__wrapper__header }}
            pageBlock={PageBlocks.POPULAR}
            eventWidgetsOptions={{
              videoStreamingEnabled: market.event.videoStreamingEnabled,
              eventId: market.event.id,
              sportId: market.eventType.id,
              matchStatEnabled: market.event.matchStatEnabled
            }}
            page={page}
          />
        </div>
      ) : (
        <MarketsTable
          markets={[market]}
          isPopularMarketsOdds
          betGroupsCount={runners.length}
          notHighlighted={notHighlighted}
          hiddenColumns={[
            MarketsTableColumn.RULES,
            MarketsTableColumn.IN_PLAY,
            MarketsTableColumn.MATCHED,
            MarketsTableColumn.PLACEMENTS
          ]}
          staticViewType={ViewType.EXTRA_SMALL}
          pageBlock={PageBlocks.POPULAR}
          popularMarketTime={marketTime}
          eventWidgetsOptions={{
            videoStreamingEnabled: market.event.videoStreamingEnabled,
            eventId: market.event.id,
            sportId: market.eventType.id,
            matchStatEnabled: market.event.matchStatEnabled
          }}
          page={page}
        />
      )}
    </>
  );
};

export default memo(Market);
