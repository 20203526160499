import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Modal from 'components/Modal';
import { getFavorites } from 'redux/modules/favorites/selectors';

import CollapsibleList from '../CollapsibleList';
import NoFavorites from '../NoFavorites';

import styles from './styles.module.scss';

const ManageRow = () => {
  const favorites = useSelector(getFavorites);

  const [openFavoritesModal, setOpenFavoritesModal] = useState(false);

  const { t } = useTranslation();

  return (
    <div className="biab_fav-content">
      {!!favorites.length && (
        <div className="biab_fav-manage">
          <a
            className={classNames(styles.favorite__manage, 'biab_nav-fav-subheader')}
            onClick={() => setOpenFavoritesModal(!openFavoritesModal)}
          >
            {t('navigation.manageFavourites')}
            <i className="fa2 fa2-cog biab_fav-manage-icon" />
          </a>
        </div>
      )}

      <Modal
        clickableBackground
        open={openFavoritesModal}
        onClose={() => setOpenFavoritesModal(false)}
        title={t('navigation.favouritesModalTitle')}
        subTitle={t('favourites.remove.hint')}
        customClassNames={{
          body: favorites.length ? styles.modal__body : styles.modal__body__empty,
          header: styles.modal__header,
          content: styles.modal__content,
          dialog: styles.modal__dialog,
          container: 'biab_fav-modal biab_favourites-wrap'
        }}
      >
        {favorites.length ? <CollapsibleList favorites={favorites} isPopup /> : <NoFavorites />}
      </Modal>
    </div>
  );
};

export default ManageRow;
