import { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import OddsCell, { OddsCellProps } from 'components/AsianViewPageModule/components/Event/MarketCell/OddsCell';
import Skeleton from 'components/Skeleton';
import { asianViewMiddleSection as branding } from 'constants/branding';
import useAsianLayBetting from 'hooks/useAsianLayBetting';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import {
  getIsMarketRunnerPricesById,
  getMarketPricesCurrencyById,
  getMarketPricesStatusById
} from 'redux/modules/marketsPrices/selectors';
import { BetTypes, THandicap } from 'types/bets';
import { getAsianRunnerName, isAsianFullName } from 'utils/asianView';

import styles from './styles.module.scss';

interface RunnerProps {
  competitionId: string;
  eventId: string;
  marketId: string;
  id: string;
  runnerId: string;
  runnerHandicap: THandicap;
  name: string;
  defaultName: string;
  translatedName: string;
  marketLink?: string;
  betslipId?: string;
  customClass?: string;
  children?: ReactNode;
  isExpandedCoupon?: boolean;
}

const Runner = ({
  competitionId,
  eventId,
  marketId,
  id,
  runnerId,
  runnerHandicap,
  name,
  defaultName,
  translatedName,
  betslipId,
  customClass,
  children,
  isExpandedCoupon
}: RunnerProps) => {
  const { t } = useTranslation();

  const isRunnerPrices = useSelector(getIsMarketRunnerPricesById(id, runnerId));
  const marketStatus = useSelector(getMarketPricesStatusById(id));
  const marketCurrency = useSelector(getMarketPricesCurrencyById(id));
  const isMobileAsianView = useSelector(getIsMobileAsianView);

  const isLayBetting = useAsianLayBetting();
  const commonProps: Omit<OddsCellProps, 'betType'> = {
    competitionId,
    eventId,
    marketId,
    selectionId: runnerId,
    handicap: runnerHandicap,
    marketStatus,
    marketCurrency,
    betslipId,
    isExpandedCoupon
  };

  const tooltip = isAsianFullName(defaultName) ? name : translatedName !== name ? translatedName : '';

  return (
    <>
      <div
        className={classNames(
          styles.runnerContainer,
          customClass,
          branding.MARKET_BODY,
          branding.MARKET_BORDER,
          branding.COUPON_BORDER,
          {
            [styles.runnerContainer__mobile]: isMobileAsianView
          }
        )}
      >
        {isRunnerPrices ? (
          <>
            <div className={styles.name}>
              <span data-tooltip-id={'tooltip'} data-tooltip-html={tooltip}>
                {getAsianRunnerName(name, t)}
              </span>
            </div>

            <div className={styles.backLay}>
              <OddsCell {...commonProps} betType={BetTypes.BACK} />
              {isLayBetting && <OddsCell {...commonProps} betType={BetTypes.LAY} />}
            </div>
          </>
        ) : (
          <div className={styles.skeleton}>
            <Skeleton className={styles.skeleton__name} />
            <div className={styles.skeleton__cellWrapper}>
              <Skeleton className={styles.skeleton__cell} />
              {isLayBetting && <Skeleton className={styles.skeleton__cell} />}
            </div>
          </div>
        )}
      </div>
      {children}
    </>
  );
};

export default memo(Runner);
