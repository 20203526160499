import { Link } from 'react-router-dom';
import classNames from 'classnames';

import ProfitLossTablePopUpButton from 'components/ProfitLossTablePopUp/components/ProfitLossTablePopUpButton/ProfitLossTablePopUpButton';
import { CASH_OUT_BASE_URL } from 'constants/locations';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { MatchTypes } from 'types/bets';

import styles from '../BetInfo/styles.module.scss';

type BetPLButtonAndCashOutProps = {
  matchType: MatchTypes;
  bet: TCurrentBet;
};

const BetPLButtonAndCashOut = ({ matchType, bet }: BetPLButtonAndCashOutProps) => {
  return (
    <>
      {matchType === MatchTypes.MATCHED && bet.isPNLAvailable && (
        <ProfitLossTablePopUpButton isActive eventId={bet.eventId} marketId={bet.marketId} isAsianView={false} />
      )}
      {bet.triggeredByCashOut && (
        <Link to={CASH_OUT_BASE_URL} className={styles.cashOutLink}>
          <i className={classNames('biab_custom-icon-cash-out', styles.cashOutIcon)} />
        </Link>
      )}
    </>
  );
};

export default BetPLButtonAndCashOut;
