import { useTranslation } from 'react-i18next';

import { isNoMoreOneWinners } from 'components/WhatIf/helpers';
import { MARKET_TYPES } from 'constants/marketTypes';

import { useFormatCurrency } from './useFormatCurrency';

interface IGetTooltip {
  currentPL: string[];
  updateBet: string[];
  marketType: string;
  currency?: string;
  numberOfWinners?: number;
}

const mapperToDisplayValue = (pattern: string, item: string) => {
  return pattern.replace('0.00', (+item || 0).toFixed(2)).replace('-', '');
};

const tooltipType: Record<number, string> = {
  0: 'win',
  1: 'place',
  2: 'lose'
};

const useWhatIfTooltip = ({ currentPL, updateBet, marketType, currency = '', numberOfWinners }: IGetTooltip) => {
  const { t } = useTranslation();
  const tooltip = '';
  const { noFormattedAmount: formatCurrency } = useFormatCurrency(0, currency, { noRounding: true });

  const isOneWinner = isNoMoreOneWinners({
    marketType,
    numberOfWinners: numberOfWinners || 0
  });

  if (marketType === MARKET_TYPES.eachWay) {
    if (!currentPL.length && updateBet.length) {
      return updateBet
        .map((item, index) => {
          if (item === '0.00' || item === '0') {
            return t(`tooltip.market.ew.${tooltipType[index]}.noProfit`);
          }
          return t(`tooltip.market.ew.${tooltipType[index]}.${item[0] === '-' ? t('loss') : t('profit')}`, {
            amount: mapperToDisplayValue(formatCurrency, item)
          });
        })
        .join('\n');
    } else if (currentPL.length && !updateBet.length) {
      return currentPL
        .map((item, index) => {
          if (item === '0.00' || item === '0') {
            return t(`tooltip.market.ew.${tooltipType[index]}.noProfit`);
          }
          return t(`tooltip.market.ew.${tooltipType[index]}.${item[0] === '-' ? t('lose') : t('win')}`, {
            amount: mapperToDisplayValue(formatCurrency, item)
          });
        })
        .join('\n');
    } else {
      return currentPL
        .map((item, index) => {
          if (item === '0.00' && !updateBet[index]) {
            return t('tooltip.market.ew.win.noProfit');
          }

          if (item === '0.00' && updateBet[index] !== '0.00') {
            return t(`tooltip.market.ew.${tooltipType[index]}.zeroPL`, {
              what_if_status:
                updateBet[index][0] === '-' ? t('tooltip.market.plWhatIf.lose') : t('tooltip.market.plWhatIf.win'),
              what_if_amount: mapperToDisplayValue(formatCurrency, updateBet[index])
            });
          } else {
            if (item === '0.00' || item === '0') {
              return t(`tooltip.market.ew.${updateBet[index][0] === '-' ? 'lose' : 'win'}.zeroPL`, {
                what_if_status:
                  updateBet[index][0] === '-' ? t('tooltip.market.plWhatIf.lose') : t('tooltip.market.plWhatIf.win'),
                what_if_amount: mapperToDisplayValue(formatCurrency, updateBet[index]),
                pl_status: item[0] === '-' ? t('tooltip.market.plWhatIf.losing') : t('tooltip.market.plWhatIf.winning'),
                pl_amount: mapperToDisplayValue(formatCurrency, item)
              });
            }
            if (updateBet[index] == '0.00' || updateBet[index] == '0') {
              return t(`tooltip.market.multi.plWhatIf.${item === '-' ? 'lose' : 'win'}.zeroWhatIf`, {
                what_if_status:
                  updateBet[index][0] === '-' ? t('tooltip.market.plWhatIf.lose') : t('tooltip.market.plWhatIf.win'),
                what_if_amount: mapperToDisplayValue(formatCurrency, updateBet[index]),
                pl_status: item[0] === '-' ? t('tooltip.market.plWhatIf.losing') : t('tooltip.market.plWhatIf.winning'),
                pl_amount: mapperToDisplayValue(formatCurrency, item)
              });
            }
            return t(`tooltip.market.ew.${tooltipType[index]}.notZero`, {
              what_if_status:
                updateBet[index][0] === '-' ? t('tooltip.market.plWhatIf.lose') : t('tooltip.market.plWhatIf.win'),
              what_if_amount: mapperToDisplayValue(formatCurrency, updateBet[index]),
              pl_status: item[0] === '-' ? t('tooltip.market.plWhatIf.losing') : t('tooltip.market.plWhatIf.winning'),
              pl_amount: mapperToDisplayValue(formatCurrency, item)
            });
          }
        })
        .join('\n');
    }
  }

  if (isOneWinner) {
    if (currentPL.length !== 0 && updateBet.length === 0) {
      return currentPL
        .map(item => {
          if (item === '0.00') {
            return t('tooltip.market.pl.zero');
          } else {
            return t('tooltip.market.pl.notZero', {
              status: item[0] === '-' ? t('tooltip.market.pl.lose') : t('tooltip.market.pl.win'),
              amount: mapperToDisplayValue(formatCurrency, item)
            });
          }
        })
        .join('\n');
    } else if (currentPL.length === 0 && updateBet.length !== 0) {
      return updateBet
        .map(item => {
          if (item === '0.00' || item === '0') {
            return t('tooltip.market.whatIf.zero');
          } else {
            return t('tooltip.market.whatIf.notZero', {
              status: item[0] === '-' ? t('tooltip.market.whatIf.loss') : t('tooltip.market.whatIf.profit'),
              amount: mapperToDisplayValue(formatCurrency, item)
            });
          }
        })
        .join('\n');
    } else {
      return currentPL
        .map((item, index) => {
          if (item !== '0.00' && updateBet[index] === '0') {
            return t(`tooltip.market.plWhatIf.zeroWhatIf`, {
              status:
                item[index][0] === '-' ? t('tooltip.market.plWhatIf.losing') : t('tooltip.market.plWhatIf.winning'),
              amount: mapperToDisplayValue(formatCurrency, updateBet[index])
            });
          } else if (item === '0' && updateBet[index] !== '0') {
            return t(`tooltip.market.plWhatIf.zeroPL`, {
              status:
                updateBet[index][0] === '-' ? t('tooltip.market.plWhatIf.lose') : t('tooltip.market.plWhatIf.win'),
              amount: mapperToDisplayValue(formatCurrency, updateBet[index])
            });
          } else {
            return t('tooltip.market.plWhatIf.notZero', {
              pl_status: item[0] === '-' ? t('tooltip.market.plWhatIf.losing') : t('tooltip.market.plWhatIf.winning'),
              pl_amount: mapperToDisplayValue(formatCurrency, item),
              what_if_status:
                updateBet[index][0] === '-' ? t('tooltip.market.plWhatIf.lose') : t('tooltip.market.plWhatIf.win'),
              what_if_amount: mapperToDisplayValue(formatCurrency, updateBet[index])
            });
          }
        })
        .join('\n');
    }
  }

  if (currentPL.length !== 0 && updateBet.length === 0) {
    return currentPL
      .map(item => {
        if (item === '0.00' || item === '0') {
          return t('tooltip.market.ew.win.noProfit');
        }

        const profite = item[0] === '-' ? t('lose') : t('win');
        return t(`tooltip.market.ew.win.${profite}`, {
          amount: mapperToDisplayValue(formatCurrency, item)
        });
      })
      .join('\n');
  }

  if (currentPL.length !== 0 && updateBet.length !== 0) {
    return currentPL
      .map((item, index) => {
        if (item === '0.00' || item === '0') {
          return t('tooltip.market.ew.win.noProfit');
        }
        return t('tooltip.market.plWhatIf.notZero', {
          pl_status: item[0] === '-' ? t('tooltip.market.plWhatIf.losing') : t('tooltip.market.plWhatIf.winning'),
          pl_amount: mapperToDisplayValue(formatCurrency, item),
          what_if_status:
            updateBet[index][0] === '-' ? t('tooltip.market.plWhatIf.lose') : t('tooltip.market.plWhatIf.win'),
          what_if_amount: mapperToDisplayValue(formatCurrency, updateBet[index])
        });
      })
      .join('\n');
  }

  if (currentPL.length === 0 && updateBet.length !== 0) {
    return updateBet
      .map(item => {
        if (item === '0.00' || item === '0') {
          return t('tooltip.market.ew.win.noProfit');
        }
        return t(`tooltip.market.ew.win.${item[0] === '-' ? t('lose') : t('win')}`, {
          amount: mapperToDisplayValue(formatCurrency, item)
        });
      })
      .join('\n');
  }

  return tooltip;
};

export default useWhatIfTooltip;
