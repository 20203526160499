import { AppState } from 'redux/reducers';

export const getCurrentGameData = ({ games }: AppState) => games.currentGameData;
export const getSelectedMarket = ({ games }: AppState) => games.selectedMarket;
export const getCurrentGameType = ({ games }: AppState) => games.currentGameData?.gameType;
export const getCurrentGameMarket = ({ games }: AppState) => games.currentGameData?.markets[games.selectedMarket];
export const getCurrentGameMarketCurrency = ({ games }: AppState) =>
  games.currentGameData?.markets[games.selectedMarket]?.currency;
export const getCurrentGameMarketStatus = ({ games }: AppState) =>
  games.currentGameData?.markets[games.selectedMarket]?.marketStatus;
export const getCurrentGameSelections = ({ games }: AppState) =>
  games.currentGameData?.markets[games.selectedMarket]?.selections;
export const getCurrentGameSideBetsSelections = ({ games }: AppState) => games.currentGameData?.markets[1]?.selections;
export const getCurrentGameMatchedAmount = ({ games }: AppState) =>
  games.currentGameData?.markets[games.selectedMarket]?.marketAmountMatched;
export const getCurrentGameName = ({ games }: AppState) => games.currentGameData?.name;
export const getCurrentGameRound = ({ games }: AppState) => games.currentGameData?.round;
export const getCurrentGameRoundPercent = ({ games }: AppState) =>
  games.currentGameData?.bettingWindowPercentageComplete;
export const getSelectedGamePage = ({ games }: AppState) => games.selectedGamePage;
export const getGameRules = ({ games }: AppState) => games.gameRules;
export const getGameRulesLoading = ({ games }: AppState) => games.gameRulesLoading;
export const getHistory = ({ games }: AppState) => games.resultsHistory;
export const getGameType = ({ games }: AppState) => games.resultsHistory?.content[0]?.gameType;
export const getCurrentGameDataLoading = ({ games }: AppState) => games.currentGameDataLoading;
export const getSelectedIndex = ({ games }: AppState) => games.selectedResultIndex;
export const getGameList = ({ games }: AppState) => games.gameList;
export const getGamesFetchFinished = ({ games }: AppState) => games.gamesFetchFinished;
export const getGamesLoading = ({ games }: AppState) => games.loading;
export const getCurrentGameMarketsAmount = ({ games }: AppState) => (games.currentGameData?.markets ?? []).length;
export const getCurrentGameIsLayOddsDisabled =
  (isGamesPageAndLayBet: boolean) =>
  ({ games }: AppState) =>
    isGamesPageAndLayBet ? !!games.currentGameData?.disabledLayOdds : false;
export const getCurrentGameChannelId = ({ games }: AppState) => games.currentGameData?.channelId;
export const getIsCurrentGameHasMarkets = ({ games }: AppState) => !!games.currentGameData?.markets?.length;
export const getIsCurrentGame = ({ games }: AppState) => !!games.currentGameData;
export const getCurrentGameSelectedMarketId = ({ games }: AppState) =>
  games.currentGameData?.markets[games.selectedMarket].id;
export const getGamesPricesCurrencies =
  (marketIds: string[]) =>
  ({ games }: AppState) =>
    JSON.stringify(
      marketIds.map(
        marketId => games.currentGameData?.markets?.find(({ id }) => String(marketId) === String(id))?.currency || null
      )
    );
