import { TFailureActionPayload } from 'types';

export type TMeta = {
  meta: Record<string, FetchMetaPageContentResponse>;
  loading: boolean;
  error: TFailureActionPayload;
  pageId: MetaPage | string | null;
};

export enum MetaPage {
  HOME = 'HOME',
  EVENT = 'EVENT',
  CUSTOM = 'CUSTOM',
  IN_PLAY = 'IN_PLAY',
  SPORT = 'SPORT',
  COMPETITION = 'COMPETITION',
  COUNTRY = 'COUNTRY',
  MARKET = 'MARKET'
}

export type FetchMetaPageContentResponse = {
  metaTitle: string | null;
  metaDescription: string | null;
  contentHeader: string | null;
  content: string | null;
};

export type FetchMetaPageContentPayload = {
  page: MetaPage | string;
  id?: string;
};

export type SetMetaPageContent = {
  page: MetaPage | string;
  content: FetchMetaPageContentResponse;
};
