import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';
import { IMarket } from 'types/markets';
import { getTopMarket } from 'utils/market';

import { TListView } from './type';

const initialState: TListView = {
  listViewMarkets: [],
  topMarket: {} as IMarket,
  loading: false,
  error: null
};

const slice = createSlice({
  name: SLICES_NAMES.LIST_VIEW_TABS,
  initialState,
  reducers: {
    fetchListViewMarkets: (state, _: PayloadAction<string>) => {
      state.loading = true;
    },
    successFetchListViewMarkets: (state, { payload }: PayloadAction<IMarket[]>) => {
      state.loading = false;
      state.topMarket = getTopMarket(payload, 'totalMatched');
      state.listViewMarkets = payload;
    },
    failureFetchListViewMarkets: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = payload;
    },
    resetListViewMarkets: () => initialState
  }
});

export const { fetchListViewMarkets, successFetchListViewMarkets, resetListViewMarkets, failureFetchListViewMarkets } =
  slice.actions;

export default slice.reducer;
