import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined, toNumber } from 'lodash';

import { MOBILE_PLACEMENT_MESSAGES_TIMEOUT, MOBILE_PLACEMENT_MESSAGES_VALIDATION_TIMEOUT } from 'constants/asianView';
import { ASIAN_VIEW_PLACE_BET_ERRORS_IDS, ASIAN_VIEW_PLACE_BET_ERRORS_WITH_INTERVAL } from 'constants/betslip';
import { LOADING_TIMEOUT_MESSAGE } from 'constants/placement';
import { getBetslipSpinnerTime, getBetsStatusesRequestInterval } from 'redux/modules/appConfigs/selectors';
import { fetchAsianCurrentBets } from 'redux/modules/asianViewCurrentBets';
import { getAsianCurrentBetsLoading, getAVCurrentBetByOfferId } from 'redux/modules/asianViewCurrentBets/selectors';
import {
  fetchMobileBetStatuses,
  removeMobilePlacedBet,
  removeMobileSelectedBet,
  updateMobilePlacedBet
} from 'redux/modules/asianViewMobileBetslip';
import { AsianViewMobilePlacedBetStatuses, TAsianMobilePlacedBet } from 'redux/modules/asianViewMobileBetslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';

import AsianViewMobilePlacedBetMessage from '../AsianViewMobilePlacedBetMessage';

interface IAsianViewMobilePlacedBet {
  bet: TAsianMobilePlacedBet;
}
const AsianViewMobilePlacedBet = ({ bet }: IAsianViewMobilePlacedBet) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const offer = useSelector(getAVCurrentBetByOfferId(toNumber(bet?.offerId)));
  const betsStatusesRequestInterval = useSelector(getBetsStatusesRequestInterval);
  const isCurrentBetsLoading = useSelector(getAsianCurrentBetsLoading);
  const totalPlacementTime = useSelector(getBetslipSpinnerTime);

  const fetchPlacedBetStatusInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const placedBetMessageTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const totalPlacementTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const isCreated = bet.status === AsianViewMobilePlacedBetStatuses.CREATED;
  const isPlaced = bet.status === AsianViewMobilePlacedBetStatuses.PLACED;
  const isOffer = bet.status === AsianViewMobilePlacedBetStatuses.OFFER;
  const isCancelled = bet.offerStatus === BetsStatusesTypes.CANCELLED;
  const isError = bet.status === AsianViewMobilePlacedBetStatuses.ERROR || isCancelled;
  const isMessage = ((isPlaced || isOffer) && !isUndefined(offer)) || isError;
  const isValidationErrorMessage =
    (bet.placementErrorId && ASIAN_VIEW_PLACE_BET_ERRORS_WITH_INTERVAL.includes(bet.placementErrorId)) || isCancelled;

  const loading = useRef(bet.isLoading);
  loading.current = bet.isLoading;

  const currentBetsLoading = useRef(isCurrentBetsLoading);
  currentBetsLoading.current = isCurrentBetsLoading;

  const clearStatusInterval = () => {
    if (fetchPlacedBetStatusInterval.current) {
      clearInterval(fetchPlacedBetStatusInterval.current);
    }
  };

  const clearTotalPlacementTimeout = () => {
    if (totalPlacementTimeout.current) {
      clearTimeout(totalPlacementTimeout.current);
    }
  };

  const clearPlacedBetMessageTimeout = () => {
    if (placedBetMessageTimeout.current) {
      clearTimeout(placedBetMessageTimeout.current);
    }
  };

  useEffect(() => {
    if (isCreated && bet.offerId && !fetchPlacedBetStatusInterval.current) {
      fetchPlacedBetStatusInterval.current = setInterval(() => {
        if (!loading.current) {
          dispatch(fetchMobileBetStatuses({ offerIds: [toNumber(bet.offerId)] }));
        }
      }, parseInt(betsStatusesRequestInterval));
    }
  }, [bet.offerId, isCreated, isPlaced, betsStatusesRequestInterval]);

  useEffect(() => {
    if (isPlaced || isError) {
      clearStatusInterval();
      dispatch(fetchAsianCurrentBets());
    }
  }, [isPlaced, isError]);

  useEffect(() => {
    if (isMessage) {
      clearTotalPlacementTimeout();

      dispatch(removeMobileSelectedBet(bet.betslipId));

      if (!isUndefined(offer)) {
        dispatch(
          updateMobilePlacedBet({
            identifier: bet.identifier,
            data: { offerId: bet.offerId, status: AsianViewMobilePlacedBetStatuses.OFFER }
          })
        );
      }

      if (!placedBetMessageTimeout.current) {
        placedBetMessageTimeout.current = setTimeout(
          () => {
            dispatch(removeMobilePlacedBet(bet.offerId ?? bet.identifier));
          },
          isValidationErrorMessage ? MOBILE_PLACEMENT_MESSAGES_VALIDATION_TIMEOUT : MOBILE_PLACEMENT_MESSAGES_TIMEOUT
        );
      }
    }
  }, [bet.betslipId, bet.identifier, bet.offerId, isMessage, isValidationErrorMessage]);

  useEffect(() => {
    if (!totalPlacementTimeout.current) {
      totalPlacementTimeout.current = setTimeout(() => {
        dispatch(removeMobileSelectedBet(bet.betslipId));
        dispatch(
          updateMobilePlacedBet({
            identifier: bet.identifier,
            data: {
              offerId: bet.offerId,
              placementError: t(LOADING_TIMEOUT_MESSAGE),
              placementErrorCode: ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX020,
              status: AsianViewMobilePlacedBetStatuses.ERROR
            }
          })
        );
      }, +totalPlacementTime * 1000);
    }
  }, [bet.identifier, bet.offerId]);

  useEffect(() => {
    return () => {
      clearStatusInterval();
      clearTotalPlacementTimeout();
      clearPlacedBetMessageTimeout();
    };
  }, []);

  return <>{isMessage && <AsianViewMobilePlacedBetMessage bet={bet} offer={offer} />}</>;
};

export default AsianViewMobilePlacedBet;
