import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { keys, unescape, without } from 'lodash';

import { MARKET_RUNNER } from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';
import {
  getAppDevice,
  getBetfairCdnImageFeedPath,
  getCountriesWithCustomClothIcon
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { TMarketRunner } from 'types/markets';
import { RacingGameTitleClasses } from 'types/raceCard';

import styles from './styles.module.scss';

interface RacingGameTitleProps {
  runner: TMarketRunner;
  isRacing: boolean;
  countryCode: string;
  classes?: RacingGameTitleClasses;
}

const RacingGameTitle = ({
  runner: { runnerName, metadata },
  isRacing,
  countryCode,
  classes
}: RacingGameTitleProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const countriesWithCustomClothIcon = useSelector(getCountriesWithCustomClothIcon);
  const betfairCdnImageFeedPath = useSelector(getBetfairCdnImageFeedPath);

  const { translationKey: translationMarketRunner, isEnabled } = useTooltip(MARKET_RUNNER);

  const isDesktop = device === Devices.DESKTOP;
  const isRunnerHaveMetadata = without(keys(metadata), 'runnerId').length;

  if (!isRacing || !isRunnerHaveMetadata) {
    return (
      <div
        className={classNames('biab_game-title matchedTitle', styles.matchedTitle, styles.matchedTitleRunner)}
        title={runnerName}
      >
        {runnerName}
      </div>
    );
  }

  const {
    JOCKEY_NAME = '',
    CLOTH_NUMBER_ALPHA = '',
    STALL_DRAW = '',
    COLOURS_FILENAME = '',
    CLOTH_NUMBER = '',
    AGE = '',
    DAYS_SINCE_LAST_RUN = '',
    WEARING = '',
    FORM = '',
    TRAINER_NAME = '',
    WEIGHT_VALUE = '',
    JOCKEY_CLAIM = '',
    COLOURS_DESCRIPTION = ''
  } = metadata;

  const hasCustomClothIcon = countriesWithCustomClothIcon.split(',').includes(countryCode);

  const onHandleBrokenImage = (event: any) => {
    if (event) {
      event.nativeEvent.target.style.display = 'none';
    }
  };

  return (
    <div className={classNames(styles.search__gameContainerTitle, classes?.container ?? '')}>
      {hasCustomClothIcon ? (
        <div
          data-tooltip-id={'tooltip'}
          data-tooltip-html={isDesktop ? unescape(CLOTH_NUMBER_ALPHA) : ''}
          className={classNames(
            'biab_saddle-cloth-alpha',
            'search__gameCounter',
            `biab_saddle-cloth-${CLOTH_NUMBER_ALPHA}`,
            `search__gameNumber__${CLOTH_NUMBER_ALPHA}`,
            styles.search__gameNumber,
            {
              'cursor-help': isDesktop
            }
          )}
        />
      ) : (
        <>
          <div
            className={classNames(styles.search__gameNumberContainer, {
              'biab_single-line': STALL_DRAW
            })}
          >
            <div className={classNames(styles.search__gameNumber, `search__gameNumber__${CLOTH_NUMBER}`)}>
              <div
                data-tooltip-id={'tooltip'}
                data-tooltip-html={isDesktop ? unescape(t('tooltip.market.runner.saddleclothNumber')) : ''}
                className={classNames('biab_saddle-cloth-alpha', `search__gameNumber__${CLOTH_NUMBER}`)}
              >
                {CLOTH_NUMBER_ALPHA}
              </div>
            </div>
            {STALL_DRAW && (
              <div
                data-tooltip-id={'tooltip'}
                data-tooltip-html={isDesktop ? unescape(t('tooltip.market.runner.stallDraw')) : ''}
                className={classNames('biab_stall-draw', styles.search__gameNumber)}
              >
                {`(${STALL_DRAW})`}
              </div>
            )}
          </div>
          {COLOURS_FILENAME && (
            <img
              data-tooltip-id={'tooltip'}
              data-tooltip-html={isDesktop ? unescape(COLOURS_DESCRIPTION) : ''}
              className={classNames(styles.search__gameImg, classes?.gameImage ?? '')}
              src={`${betfairCdnImageFeedPath}${COLOURS_FILENAME}`}
              onError={onHandleBrokenImage}
              alt=" "
            />
          )}
        </>
      )}

      <div className={classNames(styles.matchedTitle, styles.matchedTitleGame, classes?.gameTitle ?? '')}>
        <div
          className={classNames(styles.search__gameRunnerName__tooltip, {
            'cursor-help': isEnabled
          })}
          data-name={'runner-name'}
          data-tooltip-id={'tooltip'}
          data-tooltip-html={
            isEnabled
              ? unescape(
                  `<div class="tooltip-start">${t(translationMarketRunner, {
                    age: AGE ? AGE : 'N/A',
                    daysSinceLastRun: DAYS_SINCE_LAST_RUN ? DAYS_SINCE_LAST_RUN : 'N/A',
                    wearing: WEARING ? WEARING : 'N/A',
                    trainer: TRAINER_NAME ? TRAINER_NAME : 'N/A',
                    form: FORM ? FORM : 'N/A',
                    weight: WEIGHT_VALUE ? WEIGHT_VALUE : 'N/A',
                    jockeyClaim: JOCKEY_CLAIM ? JOCKEY_CLAIM : 'N/A'
                  })}</div>`
                )
              : ''
          }
        >
          <div className={styles.search__gameRunnerName}>{runnerName}</div>
        </div>

        <div data-name={'runner-jockey'} className={styles.search__gameJockeyName}>
          {JOCKEY_NAME}
        </div>
      </div>
    </div>
  );
};

export default RacingGameTitle;
