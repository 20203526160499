import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface ViewAllLinesProps {
  /**
   * Is all lines displayed?
   */
  showAllLines: boolean;

  /**
   * Set state action for showAllLines value
   */
  setShowAllLines: Dispatch<SetStateAction<boolean>>;
}

const ViewAllLines = ({ showAllLines, setShowAllLines }: ViewAllLinesProps) => {
  const { t } = useTranslation();

  const handleToggleShowAllLines = () => {
    setShowAllLines(prevState => !prevState);
  };
  return (
    <div className={styles.viewAllLines__container}>
      <span className={styles.viewAllLines__label} onClick={handleToggleShowAllLines}>
        {t(showAllLines ? 'fancy.markets.viewLessLines' : 'fancy.markets.viewAllLines')}
      </span>
      <i
        className={classNames('fa2 biab_expand', styles.viewAllLines__icon, {
          'fa2-arrow-down': !showAllLines,
          'fa2-arrow-up': showAllLines
        })}
        onClick={handleToggleShowAllLines}
      />
    </div>
  );
};

export default ViewAllLines;
