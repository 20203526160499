import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { setIsOpenedAsianViewCustomPopUpLink } from 'redux/modules/asianViewPopularLinks';
import { AsianViewPopularLink } from 'redux/modules/asianViewPopularLinks/type';

import AsianViewPopularLinkContent from '../AsianViewPopularLinkContent';

import styles from '../../styles.module.scss';

type AsianViewCustomPopUpPopularLinkProps = {
  link: AsianViewPopularLink;
  linkClassName?: string;
  arrowClassName?: string;
  noTruncate?: boolean;
};

const AsianViewCustomPopUpPopularLink = ({
  link,
  linkClassName = '',
  arrowClassName,
  noTruncate = false
}: AsianViewCustomPopUpPopularLinkProps) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setIsOpenedAsianViewCustomPopUpLink(true));
  };

  return (
    <button className={classNames(styles.link, linkClassName)} onClick={handleClick}>
      <AsianViewPopularLinkContent link={link} arrowClassName={arrowClassName} noTruncate={noTruncate} />
    </button>
  );
};

export default AsianViewCustomPopUpPopularLink;
