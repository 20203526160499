import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { unescape } from 'lodash';

import CancelAllButton from 'components/Betslip/components/CancelAllButton';
import GameMarketRow from 'components/GameMarketTable/partials/GameMarketRow';
import MarketRules from 'components/GameMarketTable/partials/MarketRules';
import MarketStatusOverlay from 'components/GameMarketTable/partials/MarketStatusOverlay';
import NetCommission from 'components/NetCommission';
import OpenBetsSetting from 'components/OpenBetsSetting';
import { MARKET_TYPES } from 'constants/marketTypes';
import { MarketContext } from 'contexts/gamesMarletContext';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getAppDevice, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import {
  getCurrentGameMarket,
  getCurrentGameMarketCurrency,
  getCurrentGameMarketStatus,
  getCurrentGameMatchedAmount,
  getCurrentGameSelections,
  getCurrentGameSideBetsSelections,
  getCurrentGameType
} from 'redux/modules/games/selectors';
import { EGameTypes, EMarketStatus, ESelectionStatuses } from 'redux/modules/games/type';
import { getToolTips } from 'redux/modules/tooltip/selectors';
import { CurrencySettingsPlacement } from 'types';

import MarketsTabs from './partials/MarketsTabs';

import styles from './styles.module.scss';

const GameMarketTable = () => {
  const { t } = useTranslation();

  const market = useSelector(getCurrentGameMarket);
  const selections = useSelector(getCurrentGameSelections);
  const sideBetsSelections = useSelector(getCurrentGameSideBetsSelections);
  const status = useSelector(getCurrentGameMarketStatus);
  const matchedAmount = useSelector(getCurrentGameMatchedAmount);
  const currency = useSelector(getCurrentGameMarketCurrency);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const device = useSelector(getAppDevice);
  const tooltips = useSelector(getToolTips);
  const gameType = useSelector(getCurrentGameType);
  const selectedMarket = useSelector(getCurrentGameMarket);

  const { formattedAmount: formatMatched, noFormattedAmount: tooltipFormatMatched } = useFormatCurrency(
    matchedAmount || 0,
    currency,
    {
      isCheckIndian: true,
      noCommas: true,
      placement: CurrencySettingsPlacement.MATCHED_AMOUNT
    }
  );

  const isBestOddTooltipEnabled = tooltips[`${isPNCEnabled ? 'pnc.' : ''}tooltip.all.pages.cells`];
  const isMobile = device === Devices.MOBILE;

  if (!selections) {
    return null;
  }

  return (
    <div className={styles.main}>
      <div className={styles.rulesAndMatched}>
        <div className={styles.rulesAndNetCommission}>
          <MarketRules />
          <NetCommission />
          {isMobile && <OpenBetsSetting />}
          {isMobile && <CancelAllButton marketId={market?.id.toString()} />}
        </div>
        <div className={styles.matched}>
          <span
            data-tooltip-id={'tooltip'}
            data-tooltip-html={unescape(
              t(`${isPNCEnabled ? 'pnc.' : ''}tooltip.matchedTotal`, { amount: tooltipFormatMatched })
            )}
            className={'cursor-help'}
          >
            {t(`${isPNCEnabled ? 'pnc.' : ''}market.matched`)}: <strong>{formatMatched}</strong>
          </span>

          <a className="biab_market-refresh">{t('market.refresh')}</a>
        </div>
      </div>
      {!!sideBetsSelections?.length && <MarketsTabs />}
      {!!selections.length && (
        <div className={styles.selectionsCount}>
          {selections.length} {t('market.selections')}
        </div>
      )}
      <div className={styles.marketTable}>
        {status !== EMarketStatus.ACTIVE && <MarketStatusOverlay />}
        <MarketContext.Provider value={{ isBestOddTooltipEnabled }}>
          {selections.map((s, index) => (
            <GameMarketRow
              hide={
                gameType === EGameTypes.HILO &&
                selectedMarket?.marketType === MARKET_TYPES.variableHandicap &&
                s.status === ESelectionStatuses.WINNER &&
                selections[index + 1]?.status === ESelectionStatuses.WINNER
              }
              isFirstSelection={index === 0}
              key={s.id}
              selection={s}
            />
          ))}
        </MarketContext.Provider>
      </div>
    </div>
  );
};

export default GameMarketTable;
