import { useSelector } from 'react-redux';

import Modal from 'components/Modal';
import useAsyncEffect from 'hooks/useAsyncEffect';
import { getBackendPage } from 'redux/modules/appConfigs/selectors';
import { fetchPageContent } from 'redux/modules/pages';
import { PagesFromBackend } from 'redux/modules/pages/types';

import styles from './styles.module.scss';

type LayBettingRulesModalProps = {
  isOpenModal: boolean;
  onClose: () => void;
};

const LayBettingRulesModal = ({ isOpenModal, onClose }: LayBettingRulesModalProps) => {
  const contentLink = useSelector(getBackendPage(PagesFromBackend.LAY_BETTING_RULES_PAGE));

  const { response } = useAsyncEffect({
    asyncCallback: fetchPageContent,
    params: contentLink
  });

  return (
    <Modal
      customClassNames={{
        header: styles.modal__header,
        body: styles.modal__body,
        dialog: styles.modal__dialog,
        content: styles.modal__content
      }}
      title={response?.name ?? ''}
      open={isOpenModal}
      onClose={onClose}
      clickableBackground
    >
      <div dangerouslySetInnerHTML={{ __html: response?.content ?? '' }} />
    </Modal>
  );
};

export default LayBettingRulesModal;
