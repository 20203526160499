import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { TFetchFancyEventDetailsResponse } from 'redux/modules/fancyMarket/type';
import { IMarket, IMarketRules } from 'types/markets';

import {
  failureFetchFancyMultiMarketHeaderDetails,
  failureFetchMultiMarketDetails,
  failureFetchMultiMarketEventRules,
  failureFetchMultiMarketKeyMarketDetails,
  failureFetchMultiMarketKeyMarketFancyDetails,
  failureFetchMultiMarketRules,
  failureFetchMultiMarketTopDetails,
  fetchFancyMultiMarketHeaderDetails,
  fetchMultiMarketDetails,
  fetchMultiMarketEventRules,
  fetchMultiMarketKeyMarketDetails,
  fetchMultiMarketKeyMarketFancyDetails,
  fetchMultiMarketRules,
  fetchMultiMarketTopDetails,
  successFetchFancyMultiMarketHeaderDetails,
  successFetchMultiMarketDetails,
  successFetchMultiMarketEventRules,
  successFetchMultiMarketKeyMarketDetails,
  successFetchMultiMarketKeyMarketFancyDetails,
  successFetchMultiMarketRules,
  successFetchMultiMarketTopDetails
} from './index';
import { TMultiMarketTabDetails, TMultiMarketTopDetails } from './type';

export function* getMultiMarketDetailsWorker(action: ReturnType<typeof fetchMultiMarketDetails>) {
  try {
    const response: TMultiMarketTabDetails[] = yield call(api.event.getMultiMarketDetails, action.payload);
    yield put(successFetchMultiMarketDetails(response));
  } catch (error: any) {
    yield put(failureFetchMultiMarketDetails(error.data));
  }
}

export function* getMultiMarketEventRulesWorker(action: ReturnType<typeof fetchMultiMarketEventRules>) {
  try {
    const response: IMarketRules[] = yield call(api.app.eventRules, action.payload);
    yield put(successFetchMultiMarketEventRules({ marketId: action.payload, rules: response }));
  } catch (error: any) {
    yield put(failureFetchMultiMarketEventRules(error.data));
  }
}

export function* getMultiMarketRulesWorker(action: ReturnType<typeof fetchMultiMarketRules>) {
  try {
    const response: IMarketRules = yield call(api.app.marketRules, action.payload);
    yield put(successFetchMultiMarketRules({ marketId: action.payload, rules: response }));
  } catch (error: any) {
    yield put(failureFetchMultiMarketRules(error.data));
  }
}

export function* getMultiMarketTopDetailsWorker(action: ReturnType<typeof fetchMultiMarketTopDetails>) {
  try {
    const response: TMultiMarketTopDetails = yield call(api.event.getMultiMarketTopDetails, action.payload);
    yield put(successFetchMultiMarketTopDetails(response));
  } catch (error: any) {
    yield put(failureFetchMultiMarketTopDetails(error.data));
  }
}

export function* getFancyMultiMarketHeaderDetails(action: ReturnType<typeof fetchFancyMultiMarketHeaderDetails>) {
  try {
    const response: TFetchFancyEventDetailsResponse = yield call(
      api.event.getFancyEventDetails,
      action.payload.eventId,
      action.payload.fancyMarketTypes
    );
    yield put(successFetchFancyMultiMarketHeaderDetails(response));
  } catch (error: any) {
    yield put(failureFetchFancyMultiMarketHeaderDetails(error.data));
  }
}

export function* getMultiMarketKeyMarketDetailsWorker(action: ReturnType<typeof fetchMultiMarketKeyMarketDetails>) {
  try {
    const response: IMarket = yield call(api.market.getMarketDetails, { marketId: action.payload });
    yield put(successFetchMultiMarketKeyMarketDetails(response));
  } catch (error: any) {
    yield put(failureFetchMultiMarketKeyMarketDetails(error.data));
  }
}

export function* getMultiMarketKeyMarketFancyDetailsWorker(
  action: ReturnType<typeof fetchMultiMarketKeyMarketFancyDetails>
) {
  try {
    const response: TFetchFancyEventDetailsResponse = yield call(
      api.event.getFancyEventDetails,
      action.payload.eventId,
      action.payload.fancyMarketTypes
    );
    yield put(successFetchMultiMarketKeyMarketFancyDetails(response));
  } catch (error: any) {
    yield put(failureFetchMultiMarketKeyMarketFancyDetails(error.data));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchMultiMarketDetails.type, getMultiMarketDetailsWorker),
    takeLatest(fetchMultiMarketEventRules.type, getMultiMarketEventRulesWorker),
    takeLatest(fetchMultiMarketRules.type, getMultiMarketRulesWorker),
    takeLatest(fetchMultiMarketTopDetails.type, getMultiMarketTopDetailsWorker),
    takeLatest(fetchFancyMultiMarketHeaderDetails.type, getFancyMultiMarketHeaderDetails),
    takeLatest(fetchMultiMarketKeyMarketDetails.type, getMultiMarketKeyMarketDetailsWorker),
    takeLatest(fetchMultiMarketKeyMarketFancyDetails.type, getMultiMarketKeyMarketFancyDetailsWorker)
  ]);
}
