import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { InPlaySport } from 'types/inPlay';
import { IMarketRules } from 'types/markets';

import {
  failureFetchInPlayMarketRules,
  failureFetchInPlayMarkets,
  failureFetchInPlaySports,
  fetchInPlayMarketRules,
  fetchInPlayMarkets,
  fetchInPlaySports,
  successFetchInPlayMarketRules,
  successFetchInPlayMarkets,
  successFetchInPlaySports
} from './index';
import { TFetchInPLayMarketsResponse } from './type';

export function* getInPlayMarkets(action: ReturnType<typeof fetchInPlayMarkets>) {
  try {
    const response: TFetchInPLayMarketsResponse = yield call(api.inpLay.inPlayMarkets, action.payload);
    const concatMarketsObj = action.payload.concatMarkets ? { concatMarkets: action.payload.concatMarkets } : {};

    yield put(successFetchInPlayMarkets({ marketCatalogueList: response.marketCatalogueList, ...concatMarketsObj }));
  } catch (error: any) {
    yield put(failureFetchInPlayMarkets(error.data));
  }
}

export function* getInPlayMarketRules(action: ReturnType<typeof fetchInPlayMarketRules>) {
  try {
    const response: IMarketRules = yield call(api.app.marketRules, action.payload);
    yield put(successFetchInPlayMarketRules({ marketId: action.payload, rules: response }));
  } catch (error: any) {
    yield put(failureFetchInPlayMarketRules(error.data));
  }
}

export function* getInPlaySports() {
  try {
    const response: InPlaySport[] = yield call(api.inpLay.sports);
    yield put(successFetchInPlaySports(response));
  } catch (error: any) {
    yield put(failureFetchInPlaySports(error.data));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchInPlayMarkets.type, getInPlayMarkets),
    takeEvery(fetchInPlayMarketRules.type, getInPlayMarketRules),
    takeLatest(fetchInPlaySports.type, getInPlaySports)
  ]);
}
