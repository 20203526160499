import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import MyBetsStandardCell from 'components/MyBetsStandardCell';
import { ExchangeTypes, SEPARATE_WALLET } from 'constants/myBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import { getDesktopSettingsAlternativeLayOdds, getWalletIntegrationType } from 'redux/modules/appConfigs/selectors';
import { getAccountProductsByBetsType } from 'redux/modules/myBets/selectors';
import { BetsType, EBetSide, TStatementContentItem } from 'redux/modules/myBets/type';
import { CookieNames, OddsType } from 'types';
import convertOdds, { floatFormat } from 'utils/oddsConverter';

import DescriptionCell from '../DescriptionCell';

import styles from './styles.module.scss';

interface ContentItemProps {
  item: TStatementContentItem;
}

const ContentItem = ({ item }: ContentItemProps) => {
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE_NEW]);

  const alternativeLayOdds = useSelector(getDesktopSettingsAlternativeLayOdds);
  const walletIntegrationType = useSelector(getWalletIntegrationType);
  const accountProducts = useSelector(getAccountProductsByBetsType(BetsType.ALL_ACCOUNT_STATEMENT));

  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  const isAsianViewTable = accountProducts?.asianView && cookies.EXCHANGE_TYPE_NEW === ExchangeTypes.AsianView;

  const avgBackOddsTooltip = () => {
    if (item.side) {
      const avgPrice =
        item.side === EBetSide.Back
          ? item.avgPrice
            ? item.avgPrice
            : ''
          : item.alternativeBackOdds
          ? item.alternativeBackOdds
          : '';
      if (avgPrice) return t('betslip.tooltip.avgPrice', { N: floatFormat(avgPrice) });
      return '';
    }
    return '';
  };

  const avgLayOddsTooltip = () => {
    if (item.side) {
      const avgPrice = item.side === EBetSide.Lay ? (item.avgPrice ? item.avgPrice : '') : '';
      if (avgPrice) return t('betslip.tooltip.avgPrice', { N: floatFormat(avgPrice) });
      return '';
    }
    return '';
  };

  const avgOddsTooltip = item.avgPriceRounded ? floatFormat(item.avgPrice) : '';

  const avgBackOddsValue =
    item.side === EBetSide.Back
      ? item.avgPriceRounded
        ? item.avgPriceRounded
        : ''
      : item.alternativeBackOddsRounded
      ? item.alternativeBackOddsRounded
      : '';

  const avgLayOddsValue = item.side === EBetSide.Lay ? (item.avgPriceRounded ? item.avgPriceRounded : '') : '';

  const oddsTooltipValue = isAsianViewTable
    ? `${floatFormat(item.avgPriceRounded)} ${t(`asianView.labels.oddsType.short.${OddsType.DEC}`)}`
    : unescape(t('betslip.tooltip.avgPrice', { N: avgOddsTooltip }));

  const oddsValue =
    isAsianViewTable && item.asian
      ? convertOdds(+(item.avgPriceRounded || 0), item.oddsType ?? OddsType.DEC)
      : +item.avgPriceRounded;

  const statusValue =
    item.status === 'WON'
      ? t('account.statement.labels.won')
      : item.status === 'LOST'
      ? t('account.statement.labels.lost')
      : '';

  const { date: parsedPlacedDate } = useParseMyBetsDates({
    dateToParse: item.placedDate
  });
  const { date: parsedSettledDate } = useParseMyBetsDates({
    dateToParse: item.settledDate
  });

  const currencySettings = {
    noRounding: true,
    isCheckIndian: true,
    noSymbol: !isMultiCurrencySupported
  };

  const { noFormattedAmount: balance } = useFormatCurrency(item.balance || 0, item.currency, currencySettings);
  const { noFormattedAmount: stake } = useFormatCurrency(item.size || 0, item.currency, currencySettings);
  const { noFormattedAmount: debit } = useFormatCurrency(item.debit || 0, item.currency, currencySettings);
  const { noFormattedAmount: credit } = useFormatCurrency(item.credit || 0, item.currency, currencySettings);
  const { noFormattedAmount: liability } = useFormatCurrency(item.liability || 0, item.currency, currencySettings);

  const oddsTypeLabel = item.oddsType && t(`asianView.labels.oddsType.short.${item.oddsType}`);

  return (
    <div className={classNames('biab_my-bets-table-body', styles.AccountStatementTableBody)}>
      <div className={styles.AccountStatementTableLeftSide}>
        <MyBetsStandardCell classname={styles.statementTableCell} value={parsedSettledDate} />
        <MyBetsStandardCell classname={styles.statementTableCell} value={item.refId} />
        <MyBetsStandardCell classname={styles.statementTableCell} value={item.placedDate ? parsedPlacedDate : '--'} />
      </div>
      <div className={styles.AccountStatementDescription}>
        <DescriptionCell bet={item} />
      </div>
      <div className={styles.AccountStatementRightSide}>
        {!isAsianViewTable && alternativeLayOdds ? (
          <>
            <MyBetsStandardCell
              classname={styles.AccountStatementRightSideContent}
              isWithTooltip
              tooltipValue={unescape(avgBackOddsTooltip())}
              value={+avgBackOddsValue}
            />
            <MyBetsStandardCell
              classname={styles.AccountStatementRightSideContent}
              isWithTooltip
              tooltipValue={unescape(avgLayOddsTooltip())}
              value={+avgLayOddsValue}
            />
          </>
        ) : (
          <MyBetsStandardCell
            classname={styles.AccountStatementRightSideContent}
            isWithTooltip
            tooltipValue={oddsTooltipValue}
            value={oddsValue}
          />
        )}

        {isAsianViewTable && (
          <MyBetsStandardCell classname={styles.AccountStatementRightSideContent} value={oddsTypeLabel} />
        )}
        <MyBetsStandardCell classname={styles.AccountStatementRightSideContent} value={item.size && stake} />
        {isAsianViewTable && (
          <MyBetsStandardCell classname={styles.AccountStatementRightSideContent} value={item.liability && liability} />
        )}
        <MyBetsStandardCell classname={styles.AccountStatementRightSideContent} value={statusValue} />
        <MyBetsStandardCell classname={styles.AccountStatementRightSideContent} value={debit} />
        <MyBetsStandardCell classname={styles.AccountStatementRightSideContent} value={credit} />
        {walletIntegrationType === SEPARATE_WALLET && (
          <MyBetsStandardCell classname={styles.AccountStatementRightSideContent} value={balance} />
        )}
      </div>
    </div>
  );
};

export default ContentItem;
