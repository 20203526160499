import { all, call, put, takeLatest } from 'redux-saga/effects';

import { SEARCH_CONTENT_SIZE } from 'constants/search';
import api from 'redux/api/methods';
import { IMarketRules } from 'types/markets';

import {
  failureFetchSearch,
  failureFetchSearchMarketRules,
  fetchSearch,
  fetchSearchMarketRules,
  successFetchSearch,
  successFetchSearchMarketRules
} from './index';
import { SearchResponse } from './type';

function* getSearchResultsWorker(action: ReturnType<typeof fetchSearch>) {
  try {
    const { query, size = SEARCH_CONTENT_SIZE, offset, newQuery = false } = action.payload;

    const response: SearchResponse = yield call(api.search, query, offset, size);

    yield put(successFetchSearch({ searchResult: response, newQuery }));
  } catch (error: any) {
    yield put(failureFetchSearch(error));
  }
}

function* getSearchMarketRules(action: ReturnType<typeof fetchSearchMarketRules>) {
  try {
    const response: IMarketRules = yield call(api.app.marketRules, action.payload);
    yield put(successFetchSearchMarketRules({ marketId: action.payload, rules: response }));
  } catch (error: any) {
    yield put(failureFetchSearchMarketRules(error.data));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchSearch.type, getSearchResultsWorker),
    takeLatest(fetchSearchMarketRules.type, getSearchMarketRules)
  ]);
}
