import { MarketCashOut } from 'redux/modules/cashOut/type';
import { IMarket } from 'types/markets';

export const getContentCashOutPage = (
  stateValue: MarketCashOut,
  payload: { markets: MarketCashOut; isUpdated?: boolean }
) => {
  const {
    isUpdated,
    markets: { content = [] }
  } = payload;
  if (!isUpdated) {
    return content;
  }
  let newContent: Record<string, IMarket> = {};

  stateValue.content.forEach(item => {
    newContent = {
      ...newContent,
      [item.marketId]: {
        ...item,
        closedDate: 1
      }
    };
  });
  content.forEach((item: IMarket) => {
    newContent = {
      ...newContent,
      [item.marketId]: item
    };
  });
  return Object.values(newContent);
};
