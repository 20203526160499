import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ViewByDropdown from 'components/ViewByDropdown';
import useMobileMarketNavigationOptions from 'hooks/useMobileMarketNavigationOptions';
import { FancyMarketTypes } from 'redux/modules/fancyMarket/type';
import { DropdownItem } from 'types';

import styles from './styles.module.scss';

const MobileFancyNavigation = () => {
  const navigate = useNavigate();
  const { fancyId = '', eventId = '' } = useParams();

  const [fancyMobileCurrentPage, setFancyMobileCurrentPage] = useState<string>(fancyId);

  const navigationOptions = useMobileMarketNavigationOptions(eventId);

  const handleSelectMobileFancyNavigation = ({ id, value }: DropdownItem) => {
    navigate(value);

    if (id === FancyMarketTypes.FIRST_INNINGS_FANCY || id === FancyMarketTypes.SECOND_INNINGS_FANCY) {
      setFancyMobileCurrentPage(id);
    }
  };

  return (
    <ViewByDropdown
      viewBy={fancyMobileCurrentPage}
      onSelectViewBy={handleSelectMobileFancyNavigation}
      options={navigationOptions}
      classes={{ dropdown: styles.dropdown }}
      findByKey="id"
    />
  );
};

export default MobileFancyNavigation;
