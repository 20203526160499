import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

import { AsianPopularLinkEntryType } from './type';

export const getAsianViewPopularLinks = (state: AppState) => state.asianViewPopularLinks.asianViewPopularLinks;
export const getIsOpenedAsianViewCustomPopUpLink = (state: AppState) =>
  state.asianViewPopularLinks.isOpenedCustomPopUpLink;
export const getAsianViewCustomPopUpPopularLink = createSelector(getAsianViewPopularLinks, links =>
  links.find(({ entryType }) => entryType === AsianPopularLinkEntryType.CUSTOM_POP_UP)
);
export const getIsAsianViewPopularLinksLoaded = (state: AppState) =>
  state.asianViewPopularLinks.asianViewPopularLinksLoaded;
