import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MarketSection from 'components/MarketSection';
import styles from 'components/MarketSport/styles.module.scss';
import PopularMarket from 'components/PopularMarket';
import RacingSport from 'components/RacingSport';
import SEO from 'components/SEO';
import SportPageModule from 'components/SportPageModule';
import SportsSection from 'components/SportsSection';
import { useEnabledView } from 'hooks/useEnabledView';
import { getAppDevice, getIsShowMarketOdd } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { MetaPage } from 'redux/modules/meta/type';
import { fetchPopularMarkets } from 'redux/modules/popularMarket';
import { SportPageContextFilter, SportPageType } from 'redux/modules/sportPage/type';
import { fetchSports } from 'redux/modules/sports';
import { getSportsList } from 'redux/modules/sports/selectors';
import { PlacementPage, SportId } from 'types';

interface SportPageProps {
  hidePopularMarket?: boolean;
}

const SportPage = ({ hidePopularMarket }: SportPageProps) => {
  const dispatch = useDispatch();
  const { sportId } = useParams();

  const device = useSelector(getAppDevice);
  const sportList = useSelector(getSportsList);
  const isShowMarketOdds = useSelector(getIsShowMarketOdd);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const { isListViewEnabled } = useEnabledView(true);

  let content = null;

  useEffect(() => {
    if (!sportList.length) {
      dispatch(fetchSports());
    }
  }, []);

  useEffect(() => {
    if (isShowMarketOdds) {
      dispatch(fetchPopularMarkets());
    }
  }, [isLoggedIn, dispatch, isShowMarketOdds]);

  if (device === Devices.MOBILE) {
    return (
      <>
        <SEO page={MetaPage.SPORT} id={sportId} />
        <PopularMarket page={PlacementPage.SPORT_ODDS_BANNER} />
        <SportsSection />
      </>
    );
  }

  if (sportId === SportId.HORSE_RACING || sportId === SportId.GRAY_HOUND_RACING) {
    content = <RacingSport />;
  }

  if (isListViewEnabled) {
    content = <MarketSection />;
  }

  return (
    <>
      <SEO page={MetaPage.SPORT} id={sportId} />
      {device === Devices.DESKTOP && !hidePopularMarket && (
        <PopularMarket className={styles.popularMarket} page={PlacementPage.SPORT_ODDS_BANNER} />
      )}
      {content || (
        <SportPageModule
          pageDetailsId={sportId ?? ''}
          contextFilter={SportPageContextFilter.EVENT_TYPE}
          pageType={SportPageType.SPORT}
          placementPage={isListViewEnabled ? PlacementPage.LIST_VIEW : PlacementPage.SPORT}
        />
      )}
    </>
  );
};

export default SportPage;
