import { useSelector } from 'react-redux';

import PokerStarsFooter from 'components/PokerStarsFooter';
import { POKER_STAR } from 'constants/icons';
import { getOperator } from 'redux/modules/appConfigs/selectors';
import { iosBundleRedirect } from 'utils';

import Footer from '..';

import styles from './styles.module.scss';

const AsianFooter = () => {
  const operator = useSelector(getOperator);
  const showPokerstarFooter = operator === POKER_STAR && iosBundleRedirect;
  return <div className={styles.asianFooterWrapper}>{showPokerstarFooter ? <PokerStarsFooter /> : <Footer />}</div>;
};

export default AsianFooter;
