import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';
import { ResponsibleBettingDisplayedContent } from 'types/responsibleBetting';

const getResponsibleBetting = (state: AppState) => state.responsibleBetting;
export const getResponsibleBettingSettings = createSelector(
  getResponsibleBetting,
  responsibleBetting => responsibleBetting.settings
);
export const getIsResponsibleBettingLoaded = ({ responsibleBetting }: AppState) => responsibleBetting.isLoaded;
export const getResponsibleBettingLoading = ({ responsibleBetting }: AppState) => responsibleBetting.loading;
export const getResponsibleBettingReminderUnit = ({ responsibleBetting }: AppState) =>
  responsibleBetting.settings?.rgTools?.reminderUnit;
export const getIsResponsibleBettingDayLimitEnabled = ({ responsibleBetting }: AppState) =>
  !!responsibleBetting.settings?.rgTools?.dayLimitEnabled;
export const getResponsibleBettingDayLimit = ({ responsibleBetting }: AppState) =>
  responsibleBetting.settings?.rgTools?.dayLimit;
export const getResponsibleBettingDayLimitStartDate = ({ responsibleBetting }: AppState) =>
  responsibleBetting.settings?.rgTools?.dayLimitStartDate;
export const getResponsibleBettingReminderEnabled = ({ responsibleBetting }: AppState) =>
  !!responsibleBetting.settings?.rgTools?.reminderEnabled;
export const getResponsibleBettingReminderInterval = ({ responsibleBetting }: AppState) =>
  responsibleBetting.settings?.rgTools?.reminderInterval;
export const getResponsibleBettingLoadingByType =
  (type: ResponsibleBettingDisplayedContent) =>
  ({ responsibleBetting }: AppState) =>
    responsibleBetting.loadingByType[type];
export const getReportsAvailability = ({ responsibleBetting }: AppState) => responsibleBetting.reportsAvailability;
export const getIsReportsAvailableToExport = ({ responsibleBetting }: AppState): boolean => {
  if (responsibleBetting.reportsAvailability) {
    const { myCurrentBetsReport, accountStatementReport, bettingProfitAndLossReport, myPastBetsReport } =
      responsibleBetting.reportsAvailability;
    return myCurrentBetsReport || accountStatementReport || bettingProfitAndLossReport || myPastBetsReport;
  }

  return false;
};
export const getIsSelfExclusionEnabled = ({ responsibleBetting }: AppState): boolean => {
  const selfExclusionEnabled = responsibleBetting?.settings?.rgTools?.selfExclusionEnabled ?? false;
  const selfExclusionEndDate = responsibleBetting?.settings?.rgTools?.selfExclusionEndDate ?? 0;
  const selfExcludedThroughApi = responsibleBetting?.settings?.selfExcludedThroughApi ?? false;
  const selfExcludedThroughApiUntil = responsibleBetting?.settings?.selfExcludedThroughApiUntil ?? 0;

  if (
    selfExclusionEndDate === 0 &&
    !selfExclusionEnabled &&
    !selfExcludedThroughApi &&
    selfExcludedThroughApiUntil === 0
  ) {
    return false;
  }

  return (selfExclusionEnabled && !selfExcludedThroughApi) || selfExcludedThroughApi || selfExclusionEnabled;
};
export const getCustomerSelfExclusionStartDate = ({ responsibleBetting }: AppState): number | null =>
  responsibleBetting.customerSelfExclusionStartDate;
export const getShowTimeLimitReminder = ({ responsibleBetting }: AppState): boolean =>
  responsibleBetting.showTimeLimitReminder;
export const getShowReminder = ({ responsibleBetting }: AppState): boolean => responsibleBetting.showReminder;
export const getIsTimeOutEnabled = ({ responsibleBetting }: AppState): boolean => {
  const timeLimitAvailable = responsibleBetting?.settings?.rgTools?.timeLimitAvailable ?? null;
  return timeLimitAvailable !== null && timeLimitAvailable <= 0;
};
