import { useSearchParams } from 'react-router-dom';

import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM,
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_OUTRIGHT_MARKET_ID,
  ASIAN_POPULAR_LINK_COMPETITION_ID,
  ASIAN_POPULAR_LINK_SPORT_ID,
  ASIAN_SINGLE_VIEW_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID
} from 'constants/asianView';

const useDeleteAsianViewSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const removeAsianViewSearchParams = () => {
    const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
    const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
    const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);
    const popularLinkCompetitionId = searchParams.get(ASIAN_POPULAR_LINK_COMPETITION_ID);
    const popularLinkSportId = searchParams.get(ASIAN_POPULAR_LINK_SPORT_ID);
    const outrightMarketId = searchParams.get(ASIAN_OUTRIGHT_MARKET_ID);
    const outrightCompetitionId = searchParams.get(ASIAN_OUTRIGHT_COMPETITION_ID);
    const singleViewCompetitionId = searchParams.get(ASIAN_SINGLE_VIEW_COMPETITION_ID);
    const singleViewEventId = searchParams.get(ASIAN_SINGLE_VIEW_EVENT_ID);
    const singleViewSportId = searchParams.get(ASIAN_SINGLE_VIEW_SPORT_ID);

    if (favouriteType) {
      searchParams.delete(ASIAN_FAVOURITE_TYPE_PARAM);
    }

    if (favouriteId) {
      searchParams.delete(ASIAN_FAVOURITE_ID_PARAM);
    }

    if (favouriteSportId) {
      searchParams.delete(ASIAN_FAVOURITE_SPORT_ID_PARAM);
    }

    if (popularLinkCompetitionId) {
      searchParams.delete(ASIAN_POPULAR_LINK_COMPETITION_ID);
    }

    if (popularLinkSportId) {
      searchParams.delete(ASIAN_POPULAR_LINK_SPORT_ID);
    }

    if (outrightMarketId) {
      searchParams.delete(ASIAN_OUTRIGHT_MARKET_ID);
    }

    if (outrightCompetitionId) {
      searchParams.delete(ASIAN_OUTRIGHT_COMPETITION_ID);
    }

    if (singleViewCompetitionId) {
      searchParams.delete(ASIAN_SINGLE_VIEW_COMPETITION_ID);
    }

    if (singleViewEventId) {
      searchParams.delete(ASIAN_SINGLE_VIEW_EVENT_ID);
    }

    if (singleViewSportId) {
      searchParams.delete(ASIAN_SINGLE_VIEW_SPORT_ID);
    }

    setSearchParams(searchParams);
  };

  return { removeAsianViewSearchParams };
};

export default useDeleteAsianViewSearchParams;
