import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { asianViewBetslipBranding } from 'constants/branding';
import { setSettings } from 'redux/modules/user';

import styles from './MobilePLTableSwipeMessage.module.scss';

const MobilePLTableSwipeMessage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(
      setSettings({
        settings: {
          asianViewShowPLTableSwipeMessage: false
        }
      })
    );
  };

  return (
    <div className={classNames(styles.swipeMessage, asianViewBetslipBranding.INFO_MSG)}>
      <div className={styles.swipeMessage__left}>
        <i className={classNames('av-icon av-icon-swipe biab_av-swipe-icon', styles.swipeMessage__icon)} />
        <p className={styles.swipeMessage__text}>{t('asianView.labels.plTable.swipeMessage')}</p>
      </div>
      <button className={styles.swipeMessage__closeIcon__wrapper} onClick={handleClose}>
        <i className={classNames('biab_tour-icon-close', styles.swipeMessage__closeIcon)} />
      </button>
    </div>
  );
};

export default MobilePLTableSwipeMessage;
