import { IEventViewSettings } from 'redux/modules/appConfigs/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { EventViewType, SportId } from 'types';
import { BettingType } from 'types/markets';

const isCricketMarket = (betItem: TCurrentBet) => betItem.eventTypeId.toString() === SportId.CRICKET;
const isLineMarket = (betItem: TCurrentBet) => betItem.bettingType === BettingType.LINE;

export const isUnderOverLabel = (betItem: TCurrentBet, replaceBackLayWithUnderOver: boolean) =>
  replaceBackLayWithUnderOver && !isCricketMarket(betItem) && isLineMarket(betItem);

const isFancyMarketEnabled = (
  betItem: TCurrentBet,
  eventViewSettings: IEventViewSettings[],
  fancyMarketsOnCricket: boolean
) => {
  const eventSettings = eventViewSettings.find(event => event.eventTypeId === betItem.eventTypeId.toString());

  return fancyMarketsOnCricket && eventSettings?.viewType !== EventViewType.TOP_5_VIEW;
};

export const isFancyMarket = (
  betItem: TCurrentBet,
  eventViewSettings: IEventViewSettings[],
  fancyMarketsOnCricket: boolean
) => !!betItem.fancyView && isFancyMarketEnabled(betItem, eventViewSettings, fancyMarketsOnCricket);

export const isYesNoLabel = (
  betItem: TCurrentBet,
  lineBackLayToNoYes: boolean,
  eventViewSettings: IEventViewSettings[],
  fancyMarketsOnCricket: boolean
) =>
  lineBackLayToNoYes &&
  (isFancyMarket(betItem, eventViewSettings, fancyMarketsOnCricket) ||
    (isCricketMarket(betItem) && isLineMarket(betItem)));
