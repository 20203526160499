import { useSelector } from 'react-redux';
import classNames from 'classnames';

import GameResultsPlayersStatusesTable from 'components/GameResultsPlayersStatusesTable';
import GamesCommunitySection from 'components/GamesCommunitySection';
import GamePlayers from 'components/GameSection/components/PokerAndOmahaGames/components/GamePlayers';
import GamesResultsDate from 'components/GamesResultsDate';
import GamesResultsPagination from 'components/GamesResultsPagination';
import { getSelectedIndex } from 'redux/modules/games/selectors';
import { THistory } from 'redux/modules/games/type';

import styles from '../../styles.module.scss';

interface PokerProps {
  isPopup?: boolean;
  resultsHistory: THistory;
}

const PokerAndOmaha = ({ resultsHistory, isPopup = false }: PokerProps) => {
  const selectedIndex = useSelector(getSelectedIndex);

  const resultGame = resultsHistory.content[selectedIndex];
  const { gameType, gameData, startDate, markets } = resultGame;

  return (
    <>
      <div
        className={classNames(styles.pokerContainer, {
          [styles.pokerContainer__popup]: isPopup
        })}
      >
        <GamesResultsDate startDate={startDate} />
        <GamesCommunitySection game={resultGame} isResult />
        <GamePlayers gameData={gameData} gameType={gameType} isResult />
      </div>
      {!isPopup && (
        <>
          <GamesResultsPagination history={resultsHistory} />
          <hr className={styles.hr} />
        </>
      )}
      <GameResultsPlayersStatusesTable markets={markets} />
    </>
  );
};

export default PokerAndOmaha;
