import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MiddleSectionPageSkeleton from 'components/Skeleton/MiddleSectionPageSkeleton';
import NavSkeleton from 'components/Skeleton/NavSkeleton';
import { getAppDevice, getLanguage, getTimezone, getTranslation } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { resetSportData, setRace } from 'redux/modules/competitions';
import { getTodayCardPageLoading } from 'redux/modules/competitions/selectors';
import { fetchSports } from 'redux/modules/sports';
import { getSportsList } from 'redux/modules/sports/selectors';

import ListItem from '../partials/ListItem';

const AllSportsView = () => {
  const { sportId } = useParams();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const domainLanguage = useSelector(getTranslation);
  const sportList = useSelector(getSportsList);
  const todayCardPageLoading = useSelector(getTodayCardPageLoading);
  const device = useSelector(getAppDevice);

  useEffect(() => {
    if (!sportId) {
      dispatch(fetchSports());
    }
  }, [sportId, timezone, isLoggedIn, language]);

  useEffect(() => {
    return () => {
      dispatch(setRace(null));
      dispatch(resetSportData());
    };
  }, []);

  if (todayCardPageLoading) {
    if (device === Devices.MOBILE) {
      return <MiddleSectionPageSkeleton itemsCount={12} withDelay={150} />;
    }

    return <NavSkeleton withDelay={150} itemsCount={40} />;
  }

  return (
    <>
      {sportList.map(sport => (
        <ListItem key={sport.id} item={sport} domainLanguage={domainLanguage} />
      ))}
    </>
  );
};

export default AllSportsView;
