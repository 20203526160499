import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { failureGetLeagues, fetchLeagues, successGetLeagues } from './index';
import { TAsianViewLeaguesFilterData } from './type';

export function* getAsianViewLeaguesWorker(action: ReturnType<typeof fetchLeagues>) {
  try {
    const response: Array<TAsianViewLeaguesFilterData> = yield call(api.asianView.competitions, action.payload);

    yield put(successGetLeagues(response));
  } catch (error: any) {
    yield put(failureGetLeagues(error.data));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchLeagues.type, getAsianViewLeaguesWorker)]);
}
