import { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import EventHeaderHandler from 'components/EventHeaderHandler';
import EventMarket from 'components/EventMarket';
import Loader, { CircleColors } from 'components/Loader';
import useAddHeightForFooter from 'hooks/useAddHeightForFooter';
import { getAppDevice, getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchEventDetails, fetchEventMarketRules } from 'redux/modules/event';
import {
  getEventInfo,
  getEventLoading,
  getEventMarkets,
  getRules,
  getRulesLoading,
  getStartDate
} from 'redux/modules/event/selectors';
import { CookieNames, LiveWidgetHeaderType, PageBlocks, PlacementPage } from 'types';
import { getCollapsedCookie } from 'utils/cookie';
import { serializeCollapsedCookies } from 'utils/market';

import styles from './styles.module.scss';

interface SportEventProps {
  id?: string;
  page: PlacementPage;
}

const SportEvent = ({ id, page }: SportEventProps) => {
  const dispatch = useDispatch();
  const { eventId = id || '', sportId = '' } = useParams();
  const [cookies, setCookie] = useCookies([CookieNames.MARKETS_COLLAPSE]);

  const eventInfo = useSelector(getEventInfo);
  const loading = useSelector(getEventLoading);
  const startDate = useSelector(getStartDate);
  const marketCatalogues = useSelector(getEventMarkets);
  const rulesLoading = useSelector(getRulesLoading);
  const rules = useSelector(getRules);
  const popularMarketsHeight = useSelector(getElementHeightByName(EElementNames.POPULAR_MARKETS_HEIGHT));
  const device = useSelector(getAppDevice);
  const loggedIn = useSelector(getLoggedInStatusState);
  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const collapsedCookies = cookies[CookieNames.MARKETS_COLLAPSE];
  const collapsedMarkets = collapsedCookies?.length ? serializeCollapsedCookies(collapsedCookies.split(',')) : {};

  const { target: marketWrapperRef, targetHeight, addedHeight } = useAddHeightForFooter();

  const hasMinHeightStyle = !(device == Devices.MOBILE && isIframeEnabled);

  const { leftMarkets, rightMarkets } = useMemo(() => {
    const h = Math.ceil(marketCatalogues.length / 2);
    const l = marketCatalogues.slice(0, h);
    const r = marketCatalogues.slice(h);
    return {
      leftMarkets: l,
      rightMarkets: r
    };
  }, [marketCatalogues]);

  const setMarketCollapse = (marketId: string, value: boolean) => {
    setCookie(CookieNames.MARKETS_COLLAPSE, getCollapsedCookie(marketId, value, collapsedCookies));
  };

  const handleFetchMarketRules = (marketId: string) => {
    dispatch(fetchEventMarketRules(marketId));
  };

  useEffect(() => {
    dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: targetHeight + popularMarketsHeight }));
  }, [targetHeight, popularMarketsHeight]);

  useEffect(() => {
    if (eventId) {
      dispatch(fetchEventDetails(eventId));
    }
  }, [loggedIn, eventId, dispatch]);

  useEffect(() => {
    if (collapsedCookies) {
      if (collapsedCookies.indexOf('=') === -1) {
        setCookie(CookieNames.MARKETS_COLLAPSE, '');
      }
    }

    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <>
      <div ref={marketWrapperRef} className="biab_market">
        {!marketCatalogues || device === Devices.MOBILE || eventId !== eventInfo?.id ? (
          <>{loading && <Loader circleColor={CircleColors.DARK_GRAY} />}</>
        ) : (
          <>
            <EventHeaderHandler
              title={eventInfo.name}
              eventId={eventId}
              sportId={sportId}
              inPlay={marketCatalogues[0]?.inPlay}
              startDate={startDate}
              type={LiveWidgetHeaderType.EVENT}
              closedDate={marketCatalogues[0]?.closedDate}
              videoStreamingEnabled={marketCatalogues[0]?.event.videoStreamingEnabled}
              matchStatEnabled={marketCatalogues[0]?.event.matchStatEnabled}
            />
            <div
              className={classNames('eventPage__wrapper', styles.eventPage__wrapper, {
                [styles.eventPage__divided]: marketCatalogues.length > 1
              })}
            >
              <div className={styles.eventPage__markets} data-side="left">
                {leftMarkets.map((market, index) => (
                  <EventMarket
                    marketIndex={index}
                    market={market}
                    key={market.marketId}
                    collapsedMarkets={collapsedMarkets}
                    setMarketCollapse={setMarketCollapse}
                    onFetchMarketRules={handleFetchMarketRules}
                    rulesLoading={rulesLoading}
                    pageBlock={PageBlocks.EVENT}
                    marketsRules={rules}
                    doubleCellsWidth
                    page={page}
                  />
                ))}
              </div>
              <div className={classNames('eventPage__markets__rightSide', styles.eventPage__markets)} data-side="right">
                {rightMarkets.map((market, index) => (
                  <EventMarket
                    marketIndex={index}
                    market={market}
                    key={market.marketId}
                    collapsedMarkets={collapsedMarkets}
                    setMarketCollapse={setMarketCollapse}
                    onFetchMarketRules={handleFetchMarketRules}
                    rulesLoading={rulesLoading}
                    pageBlock={PageBlocks.EVENT}
                    marketsRules={rules}
                    doubleCellsWidth
                    page={page}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      {hasMinHeightStyle && addedHeight > 0 && <div style={{ height: addedHeight }} />}
    </>
  );
};

export default SportEvent;
