import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

export const getSportPageMarketsRules = ({ sportPage }: AppState) => sportPage.rules;
export const getSportPageSportInfo = ({ sportPage }: AppState) => sportPage.sportInfo;
export const getSportPageLoading = ({ sportPage }: AppState) => sportPage.loading;
export const getSportPageRulesLoading = ({ sportPage }: AppState) => sportPage.rulesLoading;
export const getSportPageMarketsInfo = ({ sportPage }: AppState) => sportPage.marketCatalogueList;

export const getSportPageMarkets = ({ sportPage }: AppState) => sportPage.marketCatalogueList?.content ?? null;
export const getSportPageMarketEventIds = createSelector(getSportPageMarketsInfo, marketCatalogueList =>
  marketCatalogueList?.content.map(({ event }) => event.id)
);
