import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { asianViewMiddleSection as branding, asianViewPopUps } from 'constants/branding';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { getIsLandscapeAsianView, getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { getAsianViewLeaguesFilter, getLeaguesLoading } from 'redux/modules/asianViewLeaguesFilter/selectors';
import { TAsianViewLeaguesFilterData } from 'redux/modules/asianViewLeaguesFilter/type';
import { mapAsianViewLeaguesByColumns } from 'utils/asianView';

import LeagueItem from '../LeagueItem';
import LeaguesFooter from '../LeaguesFooter';
import LeaguesSelectAll from '../LeaguesSelectAll';
import LoadingSkeleton from '../LoadingSkeleton';

import styles from './styles.module.scss';

const LeaguesContent = () => {
  const { t } = useTranslation();

  const domainLanguage = useSelector(getTranslation);
  const loading = useSelector(getLeaguesLoading);
  const leagues = useSelector(getAsianViewLeaguesFilter);
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);

  const columnsNumber = isMobileAsianView && !isLandscapeAsianView ? 1 : 2;

  const leaguesColumns = mapAsianViewLeaguesByColumns({ domainLanguage, columnsNumber, leagues: leagues });

  return (
    <div
      className={classNames(styles.content, branding.LEAGUES_DROPDOWN, {
        [styles.content__mobile]: isMobileAsianView
      })}
    >
      <div
        className={classNames(styles.header, asianViewPopUps.POP_UP_HEADER, {
          [styles.header__mobile]: isMobileAsianView
        })}
      >
        <span
          className={classNames(styles.header__title, {
            [styles.header__title__mobile]: isMobileAsianView
          })}
        >
          {t('asianView.labels.leaguesFilter.selectLeagues')}
        </span>
        {isMobileAsianView && <LeaguesSelectAll />}
      </div>
      <div
        className={classNames(styles.inner, asianViewPopUps.POP_UP_BODY, { [styles.inner__mobile]: isMobileAsianView })}
      >
        {loading ? (
          <>
            {leaguesColumns.map((column, index) => (
              <div className={styles.inner__column} key={`leagues_${index}`}>
                <LoadingSkeleton />
              </div>
            ))}
          </>
        ) : (
          <>
            {leaguesColumns.map((column: TAsianViewLeaguesFilterData[], index) => (
              <div className={styles.inner__column} key={`leagues_${index}`}>
                <ul>
                  {column.map(league => (
                    <LeagueItem league={league} key={`league_${league.id}`} />
                  ))}
                </ul>
              </div>
            ))}
          </>
        )}
      </div>
      <LeaguesFooter />
    </div>
  );
};

export default LeaguesContent;
