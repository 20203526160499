import { TFunction } from 'react-i18next';

import { FAVORITES_TYPES, OPERATOR, ROUTER_BASE_URL } from 'constants/app';
import {
  COMPETITION_BASE_URL,
  COUNTRY_BASE_URL,
  EVENT_BASE_URL,
  GROUP_BASE_URL,
  MARKET_BASE_URL,
  SPORT_BASE_URL
} from 'constants/locations';
import { oddsTypes } from 'constants/oddsTypes';
import { timezones } from 'constants/timezones';
import { TFavorite } from 'redux/modules/favorites/type';
import { DropdownItem, Periods, TimeFormats } from 'types';

export const formatValue = (number: number) => {
  return number < 10 ? '0' + number : number;
};

export const convertTimezoneToDropdown = (): DropdownItem[] => {
  const timezonesKeys = Object.keys(timezones).sort(function (a, b) {
    return timezones[b] - timezones[a];
  });

  return timezonesKeys.map((key, index) => ({
    id: index,
    value: `${timezones[key]}`, // 240
    label: `GMT ${key}`, // GMT -04:00,
    valInNum: timezones[key]
  }));
};

export const convertOddsTypeToDropdown = (t: TFunction): DropdownItem[] => {
  const oddsTypesKeys = Object.keys(oddsTypes);

  return oddsTypesKeys.map((key, index) => ({
    id: index,
    value: key,
    label: t(`asianView.labels.oddsType.short.${key}`) + ' (' + t(`asianView.labels.oddsType.full.${key}`) + ')'
  }));
};

export const convertTimeFormatToDropdown = (t: TFunction, tooltipTranslationKey?: string): DropdownItem[] => {
  const timeFormats = Object.values(TimeFormats);

  return timeFormats.map((key, index) => ({
    id: index,
    value: key,
    tooltip: TimeFormats.BETTING_DAY === key ? t(tooltipTranslationKey ?? '') : '',
    label: t(`asianView.labels.account.timeFormat.${key}`)
  }));
};

export const convertPeriodsToDropdown = (t: TFunction): DropdownItem[] => {
  const periods = Object.values(Periods);

  return periods.map((key, index) => ({
    id: index,
    value: key,
    label: t(`account.labels.period.${key}`)
  }));
};

export const addCommasToNumber = (value: string | number) => {
  const stringVal = value + '';
  const x = stringVal.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? '.' + x[1] : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
};

export const getBooleanValue = (value = '', defaultValue = false) => {
  return value?.toString()?.toLowerCase() === 'true' || defaultValue;
};

export const replaceBRtoBreakLine = (text: string) => {
  return text.replace(/\n/g, '<br />');
};

const isInt = (n: number) => {
  return n % 1 === 0;
};

const hasHalf = (n: number) => {
  return !isInt(n) && n % 0.5 === 0;
};

export const getLinePlusNumber = (handicap: number, hasPlus: boolean) => {
  let result: string;
  const plusSymbol = hasPlus ? '+' : '';

  if (isInt(handicap) || hasHalf(handicap)) {
    result = plusSymbol + handicap.toFixed(1);
  } else {
    const firstNumber = handicap - 0.25;
    const secondNumber = handicap + 0.25;
    result = `${plusSymbol}${firstNumber.toFixed(1)} & ${plusSymbol}${secondNumber.toFixed(1)}`;
  }

  return result;
};

export const getCleanMarketId = (id?: string | null) => (id ? (id.includes('tc') ? id.slice(3) : id) : '');

export const getFavoritesIds = (favorites: TFavorite[]): Record<string, boolean> => {
  const ids: Record<string, boolean> = {};

  favorites.forEach(favorite => {
    if (favorite.starred) {
      ids[favorite.entryId] = favorite.starred;
    }
    favorite.children?.forEach(sportItem => {
      if (sportItem.starred) {
        ids[sportItem.entryId] = sportItem.starred;
      }
    });
  });

  return ids;
};

export const getFavoriteNavigation = (favorite: TFavorite) => {
  const { sportId, entryId, entryType } = favorite;

  let baseUrl = '';
  const favoriteId = sportId === 'null' ? entryId : sportId;

  switch (entryType) {
    case FAVORITES_TYPES.event:
      baseUrl = `${EVENT_BASE_URL}/${entryId}`;
      break;
    case FAVORITES_TYPES.market:
      baseUrl = `${MARKET_BASE_URL}/${entryId}`;
      break;
    case FAVORITES_TYPES.competition:
      baseUrl = `${COMPETITION_BASE_URL}/${entryId}`;
      break;
    case FAVORITES_TYPES.group:
      baseUrl = `${GROUP_BASE_URL}/${entryId}`;
      break;
    case FAVORITES_TYPES.country:
      baseUrl = `${COUNTRY_BASE_URL}/${entryId}`;
      break;
    case FAVORITES_TYPES.sport:
      baseUrl = '';
      break;
  }

  return `${SPORT_BASE_URL}/${favoriteId}${baseUrl}`;
};

export const calculateCommission = (value: string | number, commission = 0): string => {
  return ((+value * (100 - commission)) / 100).toFixed(2);
};

export const isGamePage = (path: string) => {
  return path.includes('/games');
};

export const clearAllTimers = () => {
  // This way we get last interval id and remove all intervals from app.
  const highestTimerId = window.setInterval(() => {}, Number.MAX_SAFE_INTEGER);

  for (let i = 1; i <= highestTimerId; i++) {
    window.clearInterval(i);
  }
};

export const iosBundleRedirect =
  window.environmentConfig?.iosBundleRedirect || process.env.REACT_APP_IOS_BUNDLE_REDIRECT === 'true';

export const getOperatorLanguage = (cookieLang: string) => {
  if (window.environmentConfig?.operator === OPERATOR.POKER_STAR) {
    const locales = {
      en: '',
      de_de: 'de',
      fi_fi: 'fi',
      fr_fr: 'fr',
      hr_hr: 'hr',
      hu_hu: 'hu',
      lt_lt: 'lt',
      lv_lv: 'lv',
      pl_pl: 'pl',
      ru_ru: 'ru',
      sl_sl: 'sl',
      es_419: 'es-419',
      it_it: 'it',
      ja_jp: 'ja',
      no_no: 'no',
      pt_br: 'pt-BR',
      uk_ua: 'uk',
      zh_cn: 'zh-CN',
      zh_tw: 'zh-TW'
    };

    return locales[cookieLang as keyof typeof locales] || '';
  }

  return cookieLang;
};

export const getOperatorDomain = (operatorLanguage: string) => {
  let operatorDomain = window.environmentConfig?.operatorDomain;

  if (window.environmentConfig?.operator === OPERATOR.POKER_STAR && operatorDomain) {
    const domainParts = operatorDomain.split('//');

    if (domainParts.length > 1) {
      const parts = domainParts[1].split('/');

      if (operatorLanguage !== '') {
        parts[0] = parts[0] + '/' + operatorLanguage;
      }

      domainParts[1] = parts.join('/');
    }

    operatorDomain = domainParts.join('//');
  }

  return operatorDomain;
};

export const redirectToOperator = (operatorDomain?: string) => {
  if (operatorDomain) {
    if (window.environmentConfig?.operator === OPERATOR.AB) {
      const pathnameWithoutCustomer = location.pathname.replace(ROUTER_BASE_URL, '');
      location.href = operatorDomain + pathnameWithoutCustomer.replaceAll('/', '%2F');
    } else if (
      window.environmentConfig?.operator === OPERATOR.A2F ||
      window.environmentConfig?.operator === OPERATOR.NEXTSPRINT
    ) {
      location.href = operatorDomain + location.pathname.replace(ROUTER_BASE_URL, '');
    } else {
      location.href = operatorDomain + location.pathname;
    }
  }
};
