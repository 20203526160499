import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import {
  failureFetchProfitLossMarketNames,
  failureFetchProfitLossTableData,
  fetchProfitLossMarketNames,
  fetchProfitLossTableData,
  setProfitLossTableLoading,
  successFetchProfitLossMarketNames,
  successFetchProfitLossTableData
} from './index';
import { getStringifiedProfitLossTableData } from './selectors';
import { ProfitLossMarketNames, ProfitLossTablePopUpData } from './type';

export function* profitLossTableDataWorker({ payload }: ReturnType<typeof fetchProfitLossTableData>) {
  try {
    let response: ProfitLossTablePopUpData;

    if (payload.isAsianView) {
      response = yield call(api.profitLossTablePopUp.avProfitLossTableData, payload.eventId);
    } else {
      response = yield call(api.profitLossTablePopUp.getPLTablePopUpData, payload.eventId);
    }

    const prevPLTableData: string = yield select(getStringifiedProfitLossTableData);

    if (JSON.stringify(response) !== prevPLTableData) {
      yield put(successFetchProfitLossTableData(response));
    } else {
      yield put(setProfitLossTableLoading(false));
    }
  } catch (error: any) {
    yield put(failureFetchProfitLossTableData(error));
  }
}

export function* profitLossTableMarketNamesWorker({ payload }: ReturnType<typeof fetchProfitLossMarketNames>) {
  try {
    let response: ProfitLossMarketNames;

    if (payload.isAsianView) {
      response = yield call(api.profitLossTablePopUp.avProfitLossTableMarketNames, payload.eventId);
    } else {
      response = yield call(api.profitLossTablePopUp.profitLossTableMarketNames, payload.eventId);
    }

    yield put(
      successFetchProfitLossMarketNames({
        plFTMarkets: response.asianViewPLFTMarkets,
        plHTMarkets: response.asianViewPLHTMarkets
      })
    );
  } catch (error: any) {
    yield put(failureFetchProfitLossMarketNames(error));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchProfitLossTableData.type, profitLossTableDataWorker),
    takeLatest(fetchProfitLossMarketNames.type, profitLossTableMarketNamesWorker)
  ]);
}
