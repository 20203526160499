import { ReactNode } from 'react';
import classNames from 'classnames';
import { unescape } from 'lodash';

import styles from './styles.module.scss';

interface IInfo {
  message: string | ReactNode;
  isLockIcon?: boolean;
  customClassName?: string;
}

const Info = ({ message, isLockIcon = false, customClassName = '' }: IInfo) => {
  return (
    <div className={classNames(styles.info, customClassName)}>
      {isLockIcon ? (
        <i className={classNames('biab_lock-icon fa2 fa2-lock', styles.lockIcon)} />
      ) : (
        <span className={styles.icon}>
          <i className="av-icon av-icon-info">
            <span className="path1" />
            <span className="path2" />
          </i>
        </span>
      )}
      {typeof message === 'string' ? (
        <span className={styles.text} dangerouslySetInnerHTML={{ __html: unescape(message || '') }} />
      ) : (
        message
      )}
    </div>
  );
};

export default Info;
