import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import {
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_OUTRIGHT_MARKET_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  AsianViewMarketLink,
  AsianViewTimeFilter
} from 'constants/asianView';
import { ASIAN_BASE_URL, GAMES_BASE_URL, SPORT_BASE_URL } from 'constants/locations';
import { MARKET_TYPES } from 'constants/marketTypes';
import { BetStatuses } from 'constants/myBets';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import {
  getDisplayCurrencySymbol,
  getIsCurrencySymbolAfterAmount,
  getMobileSettingsLineMarketsSwitchBackLayOnCricket,
  getMobileSettingsReplaceBackLayWithUnderOver,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { EBetSide, TMyBetsContentItem } from 'redux/modules/myBets/type';
import { getUserInfo } from 'redux/modules/user/selectors';
import { BettingType } from 'types/markets';
import { parseMillisecondsToTime } from 'utils/date';
import { betsSideValue, roundedPriceLineBet, shouldSwapColors } from 'utils/myBetsValues';
import convertOdds from 'utils/oddsConverter';

import styles from './styles.module.scss';

interface MobileContentItemProps {
  bet: TMyBetsContentItem;
  currentStatus?: BetStatuses;
  openMobileBetDetails: (bet: TMyBetsContentItem) => void;
}

const MobileContentItem = ({ bet, currentStatus, openMobileBetDetails }: MobileContentItemProps) => {
  const { t } = useTranslation();

  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const timezone = useSelector(getTimezone);
  const displayCurrencySymbol = useSelector(getDisplayCurrencySymbol);
  const currencySymbolAfterAmount = useSelector(getIsCurrencySymbolAfterAmount);
  const lineMarketsSwitchBackLayOnCricket = useSelector(getMobileSettingsLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getMobileSettingsReplaceBackLayWithUnderOver);
  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();

  const userInfo = useSelector(getUserInfo);

  const dateToShow = bet.settledDate || bet.cancelledDate || bet.placedDate;

  const { date } = useParseMyBetsDates({
    dateToParse: dateToShow
  });
  const { isMultiCurrencySupported, currencies } = useMultiCurrencySupporting();

  let currencySymbol: string;

  if (isMultiCurrencySupported) {
    currencySymbol = currencies.find(({ currencyCode }) => currencyCode === bet.currency)?.symbol ?? '';
  } else {
    currencySymbol = displayCurrencySymbol ? userInfo?.symbol || '$' : '';
  }

  const formattedCurrency = (amount: string) => {
    const currency = currencySymbolAfterAmount ? `${amount}${currencySymbol}` : `${currencySymbol}${amount}`;
    return currency.replace('-', '');
  };

  const { Cancelled, Unmatched, Matched, Pending, Settled } = BetStatuses;
  const isCurrentStatus = currentStatus === Matched || currentStatus === Unmatched;
  const isGame = bet.betType === BettingType.GAME;

  const sportUrl = `${SPORT_BASE_URL}/${bet.eventTypeId}/${
    bet.raceName ? `market/${bet.marketId}` : `event/${bet.eventId}`
  }`;

  const marketUrl = `${SPORT_BASE_URL}/${bet.eventTypeId}/market/${bet!.marketId}`;
  const gameUrl = `${GAMES_BASE_URL}/${bet.eventTypeId}`;
  const betUrl = (param: string) =>
    `${SPORT_BASE_URL}/${bet.eventTypeId}/market/${bet.marketId}?action=${param}&offerId=${bet.offerId}`;

  const asianViewEventUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilter.Today
  }/marketLink/${AsianViewMarketLink.HDP_AND_OU}?${ASIAN_SINGLE_VIEW_EVENT_ID}=${
    bet!.eventId
  }&${ASIAN_SINGLE_VIEW_SPORT_ID}=${bet.eventTypeId}`;
  const asianViewOutrightUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilter.Today
  }/marketLink/${AsianViewMarketLink.OUTRIGHT}?${ASIAN_OUTRIGHT_COMPETITION_ID}=${bet!.competitionId}&page=0`;
  const asianViewOutrightMarketUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilter.Today
  }/marketLink/${AsianViewMarketLink.OUTRIGHT}?${ASIAN_OUTRIGHT_COMPETITION_ID}=${
    bet!.competitionId
  }&${ASIAN_OUTRIGHT_MARKET_ID}=${bet!.marketId}&page=0`;

  const isUrlEnabled = isGame || bet.raceName || bet.eventId;
  const navUrl = isGame ? gameUrl : sportUrl;
  const asianUrl = bet?.outright ? asianViewOutrightUrl : asianViewEventUrl;

  const backColors = shouldSwapColors(bet, swapColorsFancyMarketsOnCricket)
    ? bet.side === EBetSide.Lay
    : bet.side === EBetSide.Back;
  const layColors = shouldSwapColors(bet, swapColorsFancyMarketsOnCricket)
    ? bet.side === EBetSide.Back
    : bet.side === EBetSide.Lay;

  const oddsTypeLabel = bet.oddsType ? t(`asianView.labels.oddsType.short.${bet.oddsType}`) : '';
  const placementLabel = t(`account.mybets.labels.${bet.inPlay ? 'live' : 'prePlay'}Placement`);
  const roundedOdds = roundedPriceLineBet(bet);
  const score = bet.marketType === MARKET_TYPES.asianHandicap && bet.score ? ` (${bet.score})` : '';

  const getBetStatusLabel = () => {
    if (currentStatus === Cancelled) {
      return t('account.mybets.labels.cancelled');
    } else if (currentStatus !== Unmatched && currentStatus !== Matched && currentStatus !== Pending) {
      return t('account.mybets.labels.settled');
    } else {
      return t('account.mybets.labels.placed');
    }
  };

  const getMarketName = () => {
    if (bet.raceName) {
      return (
        <>
          {bet.raceName && (
            <>{` ${parseMillisecondsToTime(bet.marketStartDate, timezone, timezoneCookieEnabled)} ${
              bet.raceName
            } - `}</>
          )}
          {bet.marketName}
        </>
      );
    }
    return <>{bet.marketName}</>;
  };

  return (
    <tr onClick={() => openMobileBetDetails(bet)}>
      <td
        className={classNames(styles.mobileAccountItem, {
          [styles.back]: backColors,
          'biab_mobile-account-item biab_bet-indicator biab_bet-side-back': backColors,
          [styles.lay]: layColors,
          'biab_mobile-account-item biab_bet-indicator biab_bet-side-lay': layColors
        })}
      >
        {currentStatus === Unmatched && (
          <div className={classNames(styles.betActions, 'biab_actions js-actions')}>
            <Link to={betUrl('edit')} className="biab_edit-bet">
              {t('account.mybets.btns.updateBet')}
            </Link>
            <Link to={betUrl('cancel')} className="biab_cancel-bet">
              {t('account.mybets.btns.cancelBet')}
            </Link>
          </div>
        )}
        <div className={styles.betDetailsContainer}>
          <div className={classNames('biab_selectionName', styles.selectionName)}>
            <span>{t(betsSideValue(bet, lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver))}&nbsp;</span>
            <span>
              {bet.selectionName}
              {score} {t('account.mybets.labels.for')} {formattedCurrency(bet.size)}
            </span>
            <span>&nbsp;@{bet.asian ? convertOdds(roundedOdds, bet.oddsType) : roundedOdds}&nbsp;</span>
            {bet.asian && <span>{`(${oddsTypeLabel})`}&nbsp;</span>}
            {currentStatus === Settled && (
              <span>
                {t(`account.mybets.labels.${bet.offerState.toLowerCase()}`)} {formattedCurrency(bet.profit)}
              </span>
            )}
          </div>
          <div className={styles.eventNameContainer}>
            {isCurrentStatus ? (
              <>
                <Link className={classNames('biab_eventName', styles.eventName)} to={bet.asian ? asianUrl : navUrl}>
                  <strong>{bet.eventName}</strong>
                </Link>

                {(isUrlEnabled && !bet.asian) || bet.outright ? (
                  <Link to={bet.asian && bet.outright ? asianViewOutrightMarketUrl : marketUrl}>{getMarketName()}</Link>
                ) : (
                  getMarketName()
                )}
              </>
            ) : (
              <span className={classNames('biab_eventName', styles.eventName)}>
                <strong>
                  {bet.eventName}
                  &nbsp;
                </strong>
                <span className={classNames('biab_marketName', styles.marketName)}>{getMarketName()}</span>
              </span>
            )}
          </div>
          <div className={classNames('biab_groupName', styles.groupName)}>
            {bet.asian && `${placementLabel} | `}
            {getBetStatusLabel()} : {date}
            {bet.triggeredByCashOut && ` | ${t('account.mybets.labels.triggeredByCashOut')}`}
            {bet.cancelledByOperator && ` | ${t('account.mybets.labels.cancelledByOperator')}`}
          </div>
          <i
            onClick={() => openMobileBetDetails(bet)}
            className={classNames('biab_arrow-icon fa2 fa2-arrow-right', styles.expand)}
          />
        </div>
      </td>
    </tr>
  );
};

export default MobileContentItem;
