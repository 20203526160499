import { AsianViewMarketLink } from 'constants/asianView';
import {
  AsianViewSizeValidationType,
  TAsianPlaceBet,
  TAsianViewPlaceBetErrorMessageId
} from 'redux/modules/asianViewBetslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { OddsType } from 'types';
import { BetTypes, THandicap, TPrice, TProfit, TSize } from 'types/bets';

export type TAsianViewQuickBettingState = {
  quickBets: TAsianQuickBets;
  placedBets: TAsianQuickPlacedBets;
  activePlacedBetIndex: number;
  isPlacedBetsLoading: boolean;
};

export type TAsianQuickBet = {
  identifier: string;
  sportId?: string;
  competitionId: string;
  eventId: string;
  marketId: string;
  selectionId: number;
  handicap?: THandicap | null;
  marketType?: string;
  betType: BetTypes;
  price: TPrice | undefined;
  size?: TSize;
  profit?: TProfit;
  numberOfWinners?: number;
  competitionName?: string;
  eventName?: string;
  marketName?: string;
  selectionName?: string;
  currency?: string;
  order?: number;
  validationMessage?: string;
  sizeValidationType?: AsianViewSizeValidationType;
  validationMessageId?: null | TAsianViewPlaceBetErrorMessageId;
  validSizeValue?: TSize;
  score?: string;
  oddsType?: OddsType;
  marketLink: AsianViewMarketLink;
  action?: EAsianBettingActions;
  offerId?: number | null;
  placementStatus?: TAsianPlacementStatus;
  placementError?: string | null;
  betUuid?: string;
  isInPlay?: boolean;
  adjHandicap?: THandicap | null;
  runnerIndex?: number;
  initPrice?: TPrice;
  isLowerPrice?: boolean;
  isValid?: boolean;
  isExpandedCoupon?: boolean;
};

export enum EAsianBettingActions {
  PLACE = 'PLACE',
  CONFIRM = 'CONFIRM',
  PLACEMENT = 'PLACEMENT',
  PROGRESS = 'PROGRESS',
  PLACED = 'PLACED',
  HIDDEN = 'HIDDEN',
  VALIDATION = 'VALIDATION'
}

export enum EAsianPlacedQuickBettingStatus {
  PLACE = 'PLACE',
  CREATED = 'CREATED',
  PLACED = 'PLACED',
  ERROR = 'ERROR'
}

export const AsianPlacementStatus = {
  OK: 'OK',
  FAIL: 'FAIL'
} as const;

export type TAsianPlacementStatus = typeof AsianPlacementStatus[keyof typeof AsianPlacementStatus];

export type TAsianMessagePayload = {
  identifier?: string;
  message?: string;
};

export type TAsianQuickBets = Record<string, TAsianQuickBet>;

export type TAsianQuickPlacedBets = Record<string, TAsianQuickPlacedBet>;

export type TAsianQuickBetIdentifierParams = {
  marketId: string;
  selectionId: number;
  handicap?: THandicap | null;
  betType: BetTypes;
};

export type TAsianPlaceQuickBetPayload = {
  [marketId: string]: TAsianPlaceBet[];
};

export type TAsianPlacedQuickBetPayload = TPlacedQuickBetsByMarket;

export type TAsianQuickPlacedBet = {
  identifier: string;
  offerId?: number | null;
  price?: TPrice;
  placedPrice?: TPrice;
  status?: EAsianPlacedQuickBettingStatus;
  placementError?: string | null;
  competitionName?: string;
  eventName?: string;
  marketName?: string;
  selectionName?: string;
  betType: BetTypes;
  oddsType?: OddsType;
  score?: string;
  order?: number;
  isLoading?: boolean;
  offerStatus?: BetsStatusesTypes;
  isInPlay?: boolean;
};

export type TPlacedQuickBetsByMarket = {
  [marketId: string]: TPlacedQuickBetInfo;
};

export type TPlacedQuickBetInfo = {
  identifier?: string;
  offerIds?: Record<string, number>;
  status: TAsianPlacementStatus;
  error: string | null;
  exception?: {
    id: TAsianViewPlaceBetErrorMessageId;
    code: string;
    title: null | string;
    message: string;
  };
};

export type TAsianFailurePlaceQuickBetPayload = {
  [marketId: string]: { betUuid?: string }[];
};

export type TAsianQuickUpdatedBet = {
  price?: TPrice;
  prevPrice?: TPrice;
  size?: TSize;
  profit?: TProfit;
  isValid?: boolean;
  validationMessage?: string;
  sizeValidationType?: AsianViewSizeValidationType;
  validationMessageId?: null | TAsianViewPlaceBetErrorMessageId;
  validSizeValue?: TSize;
  action?: EAsianBettingActions;
};
