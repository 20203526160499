import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import Modal from 'components/Modal';
import { BIAB_CUSTOM_POPUP } from 'constants/app';
import { getAppDevice, getIsCustomPopUpEnabled, getLanguage } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchPopularLinks } from 'redux/modules/popular';
import { getPopularLinks } from 'redux/modules/popular/selectors';
import { setShowingCustomPopularLink } from 'redux/modules/popup';
import { getIsShowingCustomLinkPopup } from 'redux/modules/popup/selectors';
import { PopularEventType } from 'types';

import PopularLink from './components/PopularLink';
import PopularLinksHeader from './components/PopularLinksHeader';

import styles from './styles.module.scss';

type PopularLinksProps = {
  listClassName?: string;
};

const PopularLinks = ({ listClassName = '' }: PopularLinksProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { gameId } = useParams();

  const device = useSelector(getAppDevice);
  const language = useSelector(getLanguage);
  const customPopUpEnabled = useSelector(getIsCustomPopUpEnabled);
  const isShowingCustomLinkPopup = useSelector(getIsShowingCustomLinkPopup);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const popularLinks = useSelector(getPopularLinks);

  const isMobile = device === Devices.MOBILE;
  const popupPrefix = isMobile ? 'mobile.' : '';
  const customPopup = popularLinks.find(item => item.type === PopularEventType.CUSTOM_POP_UP);

  useEffect(() => {
    dispatch(fetchPopularLinks());
  }, [isLoggedIn, language, isMobile, dispatch]);

  useEffect(() => {
    const isCustomPopup = localStorage.getItem(BIAB_CUSTOM_POPUP);
    dispatch(setShowingCustomPopularLink(!!isCustomPopup));
  }, [dispatch]);

  const handlerPopup = () => {
    localStorage.removeItem(BIAB_CUSTOM_POPUP);
    dispatch(setShowingCustomPopularLink(false));
  };

  return (
    <>
      {!!popularLinks.length && !gameId && (
        <div id={classNames({ biab_popularLinks: !isMobile })}>
          <PopularLinksHeader />
          <ul
            className={classNames(
              styles.container,
              {
                'biab_popular-links-block': isMobile,
                [styles.mobileText]: isMobile,
                'biab_selected-sports-list biab_prioritized-sports-list': !isMobile
              },
              listClassName
            )}
          >
            {popularLinks.map((item, index) => (
              <PopularLink value={item} key={`${index}${item.id}`} />
            ))}
          </ul>
        </div>
      )}
      {customPopUpEnabled && isShowingCustomLinkPopup && (
        <Modal
          open={customPopUpEnabled && isShowingCustomLinkPopup}
          onClose={handlerPopup}
          title={t(`${popupPrefix}custom.pop.up.title`)}
          clickableBackground
        >
          <div dangerouslySetInnerHTML={{ __html: unescape(t(`${popupPrefix}custom.pop.up.body`)) }} />
          {customPopup?.settings.customPopUpCloseButtonEnabled && (
            <div className={classNames('biab_btns', styles.modal__btnGroup)}>
              <div onClick={handlerPopup} className={classNames('biab_btn-continue', styles.modal__btnClose)}>
                {t(`${popupPrefix}custom.pop.up.button`)}
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default PopularLinks;
