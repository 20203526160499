import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { ASIAN_BASE_URL, GAMES_BASE_URL, HOME_BASE_URL, RESPONSIBLE_BETTING_BASE_URL } from 'constants/locations';
import {
  RESPONSIBLE_BETTING_SETTINGS_REQUEST_INTERVAL,
  SELF_EXCLUSION_REMINDER_MINUTES
} from 'constants/responsibleBetting';
import { logoutUser } from 'redux/modules/auth';
import { fetchResponsibleBettingSettings, setShowTimeLimitReminder } from 'redux/modules/responsibleBetting';
import { getResponsibleBettingSettings, getShowTimeLimitReminder } from 'redux/modules/responsibleBetting/selectors';

import ResponsibleBettingModalTemplate from '../ResponsibleBettingModalTemplate';

import styles from './styles.module.scss';

const ResponsibleBettingTimeLimitModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = useLocation();
  const [isVisible, setVisible] = useState(true);

  const isResponsibleBettingPage = location.pathname.includes(RESPONSIBLE_BETTING_BASE_URL);

  const showTimeLimitReminder = useSelector(getShowTimeLimitReminder);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const rbSettings = useSelector(getResponsibleBettingSettings);
  const { timeLimitAvailable = null } = rbSettings?.rgTools || {};
  const isTimeAvailableLess10 =
    !!timeLimitAvailable && timeLimitAvailable <= SELF_EXCLUSION_REMINDER_MINUTES && timeLimitAvailable >= 0;
  const timeLeft = isTimeAvailableLess10 ? timeLimitAvailable : SELF_EXCLUSION_REMINDER_MINUTES;
  const isClosing = timeLeft === 0;

  const isAsianViewPage = pathname.includes(ASIAN_BASE_URL);
  const isGamesPage = pathname.includes(GAMES_BASE_URL);

  const exchangePage = (isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || HOME_BASE_URL;

  const upadteTime = () => {
    if (!isResponsibleBettingPage) {
      dispatch(fetchResponsibleBettingSettings());
    }
  };

  useEffect(() => {
    if (showTimeLimitReminder && isClosing) {
      dispatch(setShowTimeLimitReminder(false));
      setVisible(true);
    }
  }, [isClosing]);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    if (showTimeLimitReminder) {
      dispatch(fetchResponsibleBettingSettings());
      intervalRef.current = setInterval(upadteTime, RESPONSIBLE_BETTING_SETTINGS_REQUEST_INTERVAL);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [showTimeLimitReminder, isResponsibleBettingPage]);

  return (
    <ResponsibleBettingModalTemplate
      open={showTimeLimitReminder && isTimeAvailableLess10 && isVisible}
      title={t('responsibleBetting.titles.timeLimitWarning')}
      onContinue={() => dispatch(setShowTimeLimitReminder(false))}
      onLogout={() => {
        dispatch(setShowTimeLimitReminder(false));
        dispatch(logoutUser(exchangePage));
      }}
      onClose={() => setVisible(false)}
    >
      <div>
        <div className={styles.rbSessionModal__alert}>
          <i className={classNames(styles.rbSessionModal__icon, 'ion ion-android-time')} />
          <div className={styles.rbSessionModal__messageContainer}>
            <span className={styles.rbSessionModal__message}>
              {t('responsibleBetting.messages.restrictedBettingSoon')}
            </span>
            <span className={styles.rbSessionModal__time}>
              {t('responsibleBetting.messages.timeLimitWarning', { N: timeLeft })}
            </span>
          </div>
        </div>
      </div>
    </ResponsibleBettingModalTemplate>
  );
};

export default ResponsibleBettingTimeLimitModal;
