import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { asianViewIcons } from 'constants/branding';

import styles from './styles.module.scss';

interface RulesProps {
  isHideText?: boolean;
  isSearch?: boolean;
  onClick?: (event?: SyntheticEvent) => void;
}
const Rules = ({ isHideText, isSearch, onClick = () => {} }: RulesProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.search]: isSearch
      })}
      title={t('market.rules')}
      onClick={onClick}
    >
      <i className={classNames('fa2 fa2-info-square', styles.icon)}>
        <span className={classNames('path1', asianViewIcons.RULES_ICON_BG)} />
        <span className={classNames('path2', styles.infoIcon, asianViewIcons.RULES_ICON)} />
      </i>
      {!isHideText && <span>{t('market.rules')}</span>}
    </div>
  );
};

export default Rules;
