import { RefObject, useEffect, useRef, useState } from 'react';

type Params<T> = {
  ref: RefObject<T>;
};

const useCustomResizeObserver = <T extends HTMLElement>({ ref }: Params<T>) => {
  const [elementSizes, setElementSizes] = useState({ width: 0, height: 0 });

  const heightRef = useRef<number>(0);

  useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      const entryHeight = entry.target.clientHeight;
      const entryWidth = entry.target.clientWidth;

      if (entryHeight !== heightRef.current) {
        heightRef.current = entryHeight;
        setElementSizes({ height: entryHeight, width: entryWidth });
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return elementSizes;
};

export default useCustomResizeObserver;
