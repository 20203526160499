import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useLongDateFormat from 'hooks/useLongDateFormat';
import { getResponsibleBettingSettings } from 'redux/modules/responsibleBetting/selectors';
import { ResponsibleBettingAlertTypes } from 'types/responsibleBetting';

import ResponsibleBettingAlert from '../ResponsibleBettingAlert';

const ResponsibleBettingTimeOutAlert = () => {
  const { t } = useTranslation();

  const rbSettings = useSelector(getResponsibleBettingSettings);

  const timeLimitEndDate = rbSettings?.rgTools?.timeLimitEndDate ?? 0;

  const formattedDate = useLongDateFormat({
    time: timeLimitEndDate,
    hasYear: true,
    hasTime: true,
    hasSeconds: true
  });

  const description = t('responsibleBetting.message.TIME_LIMIT_TIMEOUT', {
    date: `<strong>${formattedDate}</strong>`,
    interpolation: { escapeValue: false }
  });

  return (
    <ResponsibleBettingAlert type={ResponsibleBettingAlertTypes.Info}>
      <span dangerouslySetInnerHTML={{ __html: unescape(description) }} />
    </ResponsibleBettingAlert>
  );
};

export default ResponsibleBettingTimeOutAlert;
