import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/Modal';
import { ASIAN_SELECTED_BETS_AMOUNT_LIMIT } from 'constants/asianView';
import { setIsSelectedBetsLimitNotification } from 'redux/modules/asianViewBetslip';
import { getIsSelectedBetsLimitNotification } from 'redux/modules/asianViewBetslip/selectors';

const LimitPopup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAmountLimitReached = useSelector(getIsSelectedBetsLimitNotification);

  const closePopup = () => dispatch(setIsSelectedBetsLimitNotification(false));

  return (
    <Modal
      clickableBackground
      open={isAmountLimitReached}
      onClose={closePopup}
      title={t('asianView.labels.betslip.tabs.betSlip')}
    >
      <span>{t('asianView.labels.betslip.limitPopup', { n: ASIAN_SELECTED_BETS_AMOUNT_LIMIT })}</span>
    </Modal>
  );
};

export default LimitPopup;
