import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Verdict = () => {
  const { t } = useTranslation();

  return (
    <>
      <i className={classNames('biab_icon-timeform-logo', styles.timeForm__logo)} />
      <span className={classNames('biab_timeform-header-verdict', styles.timeForm__verdict)}>
        {t('timeform.verdict')}
      </span>
    </>
  );
};

export default Verdict;
