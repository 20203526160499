import { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { useDebounceValue } from 'usehooks-ts';

import { GeneralWebSocketSubscriptionTypes } from 'constants/app';
import { ASIAN_BASE_URL, IN_PLAY_BASE_URL } from 'constants/locations';
import useSocketClient from 'hooks/useSocketClient';
import { urls } from 'redux/api/urls';
import { updateProperties } from 'redux/modules/appConfigs';
import {
  getArePropertiesFromWebSocketLoaded,
  getDisabledLayOddsSportIds,
  getGeneralWsEnabled,
  getIsPropertiesLoaded
} from 'redux/modules/appConfigs/selectors';
import {
  successFetchAsianBetSlipAutoCashOutMarkets,
  successFetchAsianBetSlipCashOutQuotes
} from 'redux/modules/asianViewBetSlipCashOut';
import {
  successFetchAsianViewAutoCashOutMarkets,
  successFetchAsianViewCashOutQuotes
} from 'redux/modules/asianViewCashOut';
import { setCashOutCounters } from 'redux/modules/asianViewCashOutCounter';
import { CashOutCounter } from 'redux/modules/asianViewCashOutCounter/type';
import { successGetAsianCurrentBets } from 'redux/modules/asianViewCurrentBets';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { successAutoCashOutMarkets, successFetchCashOutQuotes } from 'redux/modules/cashOut';
import { AutoCashOut, CashOutQuote } from 'redux/modules/cashOut/type';
import { successGetCurrentBets } from 'redux/modules/currentBets';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { successFetchEventAutoCashOutMarkets, successFetchEventCashOutQuotes } from 'redux/modules/eventCashOut';
import { marketPricesUpdated } from 'redux/modules/inPlay';
import { setMarketPrices, successFetchEventsUpdatedData } from 'redux/modules/marketsPrices';
import { getMarketsPricesSocketParams } from 'redux/modules/marketsPrices/selectors';
import { successFetchBalance } from 'redux/modules/user';
import { BalanceResponse } from 'redux/modules/user/type';
import {
  setIsConnectedAsianViewGeneralWebSocket,
  setIsConnectedGeneralWebSocket,
  setSubscribeToAsianViewGeneralMessages,
  setSubscribeToGeneralMessages
} from 'redux/modules/webSocket';
import { setWhatIfCurrentValues } from 'redux/modules/whatIf';
import { TEventUpdatedData, TSocketMarketParams, WebSocketProperties } from 'types';
import { IMarketPrices } from 'types/markets';

type MessageResponse =
  | { [GeneralWebSocketSubscriptionTypes.currentBets]: TCurrentBet[] }
  | { [GeneralWebSocketSubscriptionTypes.balance]: BalanceResponse }
  | { [GeneralWebSocketSubscriptionTypes.autoCashOut]: AutoCashOut[] }
  | { [GeneralWebSocketSubscriptionTypes.autoCashOutAsianBetSlip]: AutoCashOut[] }
  | { [GeneralWebSocketSubscriptionTypes.autoCashOutAsianEvent]: AutoCashOut[] }
  | { [GeneralWebSocketSubscriptionTypes.cashOutQuote]: CashOutQuote[] }
  | { [GeneralWebSocketSubscriptionTypes.cashOutQuoteAsianBetSlip]: CashOutQuote[] }
  | { [GeneralWebSocketSubscriptionTypes.cashOutQuoteAsianEvent]: CashOutQuote[] }
  | { [GeneralWebSocketSubscriptionTypes.eventUpdates]: TEventUpdatedData[] }
  | { [GeneralWebSocketSubscriptionTypes.eventInfoUpdates]: TEventUpdatedData[] }
  | { [GeneralWebSocketSubscriptionTypes.eventUpdatesPL]: TEventUpdatedData[] }
  | { [GeneralWebSocketSubscriptionTypes.cashOutCounter]: CashOutCounter[] }
  | { [GeneralWebSocketSubscriptionTypes.properties]: WebSocketProperties };

const WebSocketsSubscriptionsInjection = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const arePropertiesFromWebSocketLoaded = useSelector(getArePropertiesFromWebSocketLoaded);

  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);

  const setGeneralMessagesCallback = (message: MessageResponse) => {
    if (GeneralWebSocketSubscriptionTypes.currentBets in message) {
      dispatch(setWhatIfCurrentValues(message[GeneralWebSocketSubscriptionTypes.currentBets]));
      dispatch(successGetCurrentBets({ bets: message[GeneralWebSocketSubscriptionTypes.currentBets] }));
    } else if (
      GeneralWebSocketSubscriptionTypes.eventUpdates in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.eventUpdates])
    ) {
      dispatch(
        successFetchEventsUpdatedData({
          data: message[GeneralWebSocketSubscriptionTypes.eventUpdates],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.eventInfoUpdates in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.eventInfoUpdates])
    ) {
      dispatch(
        successFetchEventsUpdatedData({
          data: message[GeneralWebSocketSubscriptionTypes.eventInfoUpdates],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.eventUpdatesPL in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.eventUpdatesPL])
    ) {
      dispatch(
        successFetchEventsUpdatedData({
          data: message[GeneralWebSocketSubscriptionTypes.eventUpdatesPL],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.balance in message &&
      typeof message[GeneralWebSocketSubscriptionTypes.balance] === 'object'
    ) {
      dispatch(
        successFetchBalance({
          balances: message[GeneralWebSocketSubscriptionTypes.balance],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.cashOutQuote in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.cashOutQuote])
    ) {
      dispatch(
        successFetchCashOutQuotes({
          quotes: message[GeneralWebSocketSubscriptionTypes.cashOutQuote],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.autoCashOut in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.autoCashOut])
    ) {
      dispatch(
        successAutoCashOutMarkets({
          autoCashOuts: message[GeneralWebSocketSubscriptionTypes.autoCashOut],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.properties in message &&
      typeof message[GeneralWebSocketSubscriptionTypes.properties] === 'object'
    ) {
      dispatch(updateProperties(message[GeneralWebSocketSubscriptionTypes.properties].properties));
    }
  };

  const setAsianViewGeneralMessagesCallback = (message: MessageResponse) => {
    if (
      GeneralWebSocketSubscriptionTypes.currentBets in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.currentBets])
    ) {
      dispatch(setWhatIfCurrentValues(message[GeneralWebSocketSubscriptionTypes.currentBets]));
      dispatch(
        successGetAsianCurrentBets({
          bets: message[GeneralWebSocketSubscriptionTypes.currentBets],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.cashOutQuote in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.cashOutQuote])
    ) {
      dispatch(
        successFetchAsianViewCashOutQuotes({
          quotes: message[GeneralWebSocketSubscriptionTypes.cashOutQuote],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.cashOutQuoteAsianBetSlip in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.cashOutQuoteAsianBetSlip])
    ) {
      dispatch(
        successFetchAsianBetSlipCashOutQuotes({
          quotes: message[GeneralWebSocketSubscriptionTypes.cashOutQuoteAsianBetSlip],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.cashOutQuoteAsianEvent in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.cashOutQuoteAsianEvent])
    ) {
      dispatch(
        successFetchEventCashOutQuotes({
          quotes: message[GeneralWebSocketSubscriptionTypes.cashOutQuoteAsianEvent],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.autoCashOut in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.autoCashOut])
    ) {
      dispatch(
        successFetchAsianViewAutoCashOutMarkets({
          autoCashOuts: message[GeneralWebSocketSubscriptionTypes.autoCashOut],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.autoCashOutAsianBetSlip in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.autoCashOutAsianBetSlip])
    ) {
      dispatch(
        successFetchAsianBetSlipAutoCashOutMarkets({
          autoCashOuts: message[GeneralWebSocketSubscriptionTypes.autoCashOutAsianBetSlip],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.autoCashOutAsianEvent in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.autoCashOutAsianEvent])
    ) {
      dispatch(
        successFetchEventAutoCashOutMarkets({
          autoCashOuts: message[GeneralWebSocketSubscriptionTypes.autoCashOutAsianEvent],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.eventUpdates in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.eventUpdates])
    ) {
      dispatch(
        successFetchEventsUpdatedData({
          data: message[GeneralWebSocketSubscriptionTypes.eventUpdates],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.eventInfoUpdates in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.eventInfoUpdates])
    ) {
      dispatch(
        successFetchEventsUpdatedData({
          data: message[GeneralWebSocketSubscriptionTypes.eventInfoUpdates],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.eventUpdatesPL in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.eventUpdatesPL])
    ) {
      dispatch(
        successFetchEventsUpdatedData({
          data: message[GeneralWebSocketSubscriptionTypes.eventUpdatesPL],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.balance in message &&
      typeof message[GeneralWebSocketSubscriptionTypes.balance] === 'object'
    ) {
      dispatch(
        successFetchBalance({
          balances: message[GeneralWebSocketSubscriptionTypes.balance],
          isWebSocketResponse: true
        })
      );
    } else if (
      GeneralWebSocketSubscriptionTypes.cashOutCounter in message &&
      Array.isArray(message[GeneralWebSocketSubscriptionTypes.cashOutCounter])
    ) {
      dispatch(setCashOutCounters(message[GeneralWebSocketSubscriptionTypes.cashOutCounter]));
    }
  };

  const setCashOutCounterMessagesCallback = (cashOutCounters: CashOutCounter[]) => {
    dispatch(setCashOutCounters(cashOutCounters));
  };

  const { subscribeToMessages: subscribeToGeneralMessages, isConnected: isConnectedGeneral } =
    useSocketClient<MessageResponse>({
      setData: setGeneralMessagesCallback,
      url: urls.ws.general,
      enabled: !isAsianViewPage && generalWsEnabled && arePropertiesLoaded
    });

  const { subscribeToMessages: subscribeToAsianViewGeneralMessages, isConnected: isConnectedAsianViewGeneral } =
    useSocketClient<MessageResponse>({
      setData: setAsianViewGeneralMessagesCallback,
      url: urls.ws.asianGeneral,
      enabled: isAsianViewPage && generalWsEnabled && arePropertiesLoaded
    });

  const { subscribeToMessages: subscribeToCashOutCounterMessages, isConnected: isConnectedCashOutCounter } =
    useSocketClient<CashOutCounter[]>({
      setData: setCashOutCounterMessagesCallback,
      url: urls.ws.cashOutCounter,
      enabled: isAsianViewPage && arePropertiesLoaded && !generalWsEnabled && isLoggedIn
    });

  useEffect(() => {
    dispatch(setIsConnectedGeneralWebSocket(isConnectedGeneral));

    if (isConnectedGeneral) {
      dispatch(setSubscribeToGeneralMessages(subscribeToGeneralMessages));
    } else {
      dispatch(setSubscribeToGeneralMessages(null));
    }
  }, [isConnectedGeneral]);

  useEffect(() => {
    dispatch(setIsConnectedAsianViewGeneralWebSocket(isConnectedAsianViewGeneral));

    if (isConnectedAsianViewGeneral) {
      dispatch(setSubscribeToAsianViewGeneralMessages(subscribeToAsianViewGeneralMessages));
    } else {
      dispatch(setSubscribeToAsianViewGeneralMessages(null));
    }
  }, [isConnectedAsianViewGeneral]);

  useEffect(() => {
    if (isConnectedAsianViewGeneral) {
      subscribeToAsianViewGeneralMessages({ [GeneralWebSocketSubscriptionTypes.cashOutCounter]: { subscribe: true } });
    }
  }, [isConnectedAsianViewGeneral]);

  useEffect(() => {
    if (isConnectedGeneral) {
      subscribeToGeneralMessages({ [GeneralWebSocketSubscriptionTypes.properties]: { subscribe: true } });
    }
  }, [isConnectedGeneral]);

  useEffect(() => {
    if (isConnectedCashOutCounter) {
      subscribeToCashOutCounterMessages({});
    }
  }, [isConnectedCashOutCounter]);

  if (!arePropertiesLoaded || (generalWsEnabled && !arePropertiesFromWebSocketLoaded && !isAsianViewPage)) {
    return null;
  }

  return <MarketPricesInjection />;
};

export default memo(WebSocketsSubscriptionsInjection);

function MarketPricesInjection() {
  const dispatch = useDispatch();
  const socketParams = useSelector(getMarketsPricesSocketParams);

  const disabledLayOddsSportIds = useSelector(getDisabledLayOddsSportIds);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const stringifiedSocketParams = JSON.stringify(socketParams);

  const visibleMarkets = useMemo(() => {
    return uniqBy(
      Object.values(socketParams).reduce<TSocketMarketParams[]>((acc, sectionValues) => {
        const params: TSocketMarketParams[] = Object.entries(sectionValues)
          .filter(([, { isVisible }]) => isVisible)
          .map(([marketId, { eventId }]) => {
            return { marketId, eventId };
          });

        return [...acc, ...params];
      }, []),
      'marketId'
    );
  }, [stringifiedSocketParams]);

  const [debouncedVisibleMarkets, setDebouncedVisibleMarkets] = useDebounceValue(visibleMarkets, 300);

  const { subscribeToMessages: subscribeToMarketPricesMessages, isConnected: isConnectedMarketPricesWs } =
    useSocketClient<IMarketPrices>({
      setData: (marketPrices: IMarketPrices) => {
        dispatch(setMarketPrices(marketPrices));
        if (location.pathname.includes(IN_PLAY_BASE_URL) && marketPrices.marketDefinition) {
          dispatch(marketPricesUpdated(marketPrices));
        }
      },
      url: urls.ws.marketPrices,
      reconnectDependencies: [disabledLayOddsSportIds]
    });

  useEffect(() => {
    if (isConnectedMarketPricesWs) {
      subscribeToMarketPricesMessages([]);
    }
  }, [location.pathname, isConnectedMarketPricesWs]);

  useEffect(() => {
    setDebouncedVisibleMarkets(visibleMarkets);
  }, [visibleMarkets]);

  useEffect(() => {
    if (isConnectedMarketPricesWs) {
      subscribeToMarketPricesMessages<TSocketMarketParams[]>(debouncedVisibleMarkets);
    }
  }, [isConnectedMarketPricesWs, isLoggedIn, debouncedVisibleMarkets]);

  return null;
}
