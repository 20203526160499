import { useCallback, useRef } from 'react';

interface ObserverParams {
  callback: () => void;
  root?: Element | null;
  rootMargin?: string;
  threshold?: number;
  setVisibility?: (val: boolean) => void;
}

function useInfiniteScroll<T extends HTMLElement>({
  callback,
  root = null,
  rootMargin = '0px',
  threshold = 0,
  setVisibility = () => {}
}: ObserverParams) {
  const observer = useRef<IntersectionObserver>();

  const lastElementRef = useCallback(
    (node: T) => {
      if (observer.current) {
        setVisibility(false);
        observer.current?.disconnect();
      }
      observer.current = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setVisibility(true);
            callback();
          } else {
            setVisibility(false);
          }
        },
        { root, rootMargin, threshold }
      );

      if (node) {
        observer.current?.observe(node);
      }
    },
    [root, rootMargin, threshold, callback]
  );

  return { lastElementRef, observer };
}

export default useInfiniteScroll;
