import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import useDevice from 'hooks/useDevice';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { getDomain, getLanguage } from 'redux/modules/appConfigs/selectors';
import { Game, Sport } from 'redux/modules/myBets/type';
import { SelectDropdownValue } from 'types/myBets';

import styles from './Select.module.scss';

interface SelectProps {
  defaultValues: { translationKey: string; name: string }[];
  sportValues?: Sport[];
  gameValues?: Game[];
  name?: {
    translationKey?: string;
    id?: string;
    name?: string;
    value?: string;
    translations?: { [key: string]: string };
  };
  handleChange: (value: SelectDropdownValue) => void;
}
const Select = ({ sportValues, gameValues, name, handleChange, defaultValues }: SelectProps) => {
  const { t } = useTranslation();

  const language = useSelector(getLanguage);
  const domain = useSelector(getDomain);

  const [openSelect, setOpenSelect] = useState<boolean>(false);

  const plDropdownRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useDevice();

  const handleChangeDropdownValue = (value: any) => {
    handleChange(value);
    setOpenSelect(false);
  };

  useOnClickOutside(plDropdownRef, () => setOpenSelect(false));

  const getName = () => {
    if (name?.translationKey) {
      return t(name.translationKey);
    }
    if (name?.translations) {
      return name?.translations[`${domain.toLowerCase()}|${language}`];
    }
    return name?.name;
  };

  return (
    <div ref={plDropdownRef}>
      <div
        className={classNames(styles.selectControl, 'biab_time-range-holder', { [styles.mobileSelect]: isMobile })}
        onClick={() => setOpenSelect(prevState => !prevState)}
      >
        <span className={styles.selectValue}>{getName()}</span>
        {openSelect ? <i className="fa2 fa2-arrow-up" /> : <i className="fa2 fa2-arrow-down" />}
      </div>
      {openSelect && (
        <div className="biab_account-page ui-selectmenu-menu">
          <ul className={classNames(styles.listOfValues, { [styles.mobileSelect]: isMobile })}>
            {defaultValues.map(item => (
              <li key={item.name} onClick={() => handleChangeDropdownValue(item)}>
                {t(item.translationKey)}
              </li>
            ))}
            {sportValues?.map(item => (
              <li key={item.id} onClick={() => handleChangeDropdownValue(item)}>
                {item.translations![`${domain.toLowerCase()}|${language}`]}
              </li>
            ))}
            {gameValues?.map(item => (
              <li key={item.gameId} onClick={() => handleChangeDropdownValue(item)}>
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Select;
