import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ReactComponent as Question } from 'assets/icons/question-circle-mark.svg';
import { ReactComponent as Reload } from 'assets/icons/reload.svg';
import CashOutRulesModal from 'components/CashOutRulesModal';
import useElementSize from 'hooks/useElementSize';
import styles from 'pages/CashOutPages/styles.module.scss';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { fetchCashOutMarkets } from 'redux/modules/cashOut';
import { getMarketCashOut } from 'redux/modules/cashOut/selectors';
import { PagesFromBackend } from 'redux/modules/pages/types';

const CashOutHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const device = useSelector(getAppDevice);
  const { size, totalPages } = useSelector(getMarketCashOut);

  const [openCashOutModal, setOpenCashoutModal] = useState(false);

  const isMobile = device === Devices.MOBILE;
  const isDesktop = device === Devices.DESKTOP;

  const onReloadHandler = () => {
    //TODO change this in pagination ticket
    dispatch(fetchCashOutMarkets({ page: totalPages ? totalPages - 1 : 0, size, withLoader: true, resetPrev: true }));
  };

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.CASHOUT_HEADER_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CASHOUT_HEADER_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <div ref={componentRef} className="biab_cash-out-page-wrapper">
      <div className={classNames('biab_cash-out-page-header', styles.header)}>
        <div className={classNames('biab_cash-out-page-title', styles.title)}>
          <div>
            {isMobile && <i className={'fa fa-stop-circle'} />}
            <span>{t('cashout.page.labels.cashOut')}</span>
          </div>
          <div className={classNames('biab_cash-out-page-info', styles.iconsWrapper)}>
            {isDesktop && (
              <span
                data-button="rules"
                className={classNames('biab_cash-out-page-info-button', styles.questionMark)}
                onClick={() => setOpenCashoutModal(true)}
              >
                <Question width={16} height={18} />
              </span>
            )}
            <span
              data-button="refresh"
              onClick={onReloadHandler}
              className={classNames('biab_cash-out-page-info-button', styles.questionMark)}
            >
              <Reload />
            </span>
          </div>
        </div>
      </div>
      <CashOutRulesModal
        link={PagesFromBackend.CASH_OUT_RULES}
        openModal={openCashOutModal}
        setOpenModal={setOpenCashoutModal}
      />
    </div>
  );
};

export default CashOutHeader;
