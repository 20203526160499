import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useDevice from 'hooks/useDevice';
import useMinMaxSize from 'hooks/useMinMaxSize';

import styles from './styles.module.scss';

interface IBetMin {
  marketId: string;
  displayType?: 'column' | 'row';
}

const BetMin = ({ marketId, displayType = 'row' }: IBetMin) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  const { minSizeFormatted } = useMinMaxSize({ marketId });

  const isRow = displayType === 'row';

  return (
    <span className={classNames(styles.betMin, { [styles.betMin__col]: !isRow })}>
      <span className={styles.betMin__label}>{t('asianView.labels.betslip.betMin')}:</span>
      <span className={classNames(styles.betMin__value, { [styles.betMin__value__mobile]: isMobile })}>
        {minSizeFormatted}
      </span>
    </span>
  );
};

export default BetMin;
