import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { PLBetType } from 'constants/myBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import MultiCurrencyDropdown from 'pages/MyBetsPages/components/MultiCurrencyDropdown';
import { getMyBetsMultiCurrencyValue, getPLTotalData } from 'redux/modules/myBets/selectors';
import { TGetPLData } from 'types/myBets';

import styles from './styles.module.scss';

type FiltersContainerMobileProps = {
  activePLTab: PLBetType;
  dataExists: boolean;
  fetchPLData: (params: TGetPLData) => void;
};

const FiltersContainerMobile = ({ activePLTab, dataExists, fetchPLData }: FiltersContainerMobileProps) => {
  const { t } = useTranslation();

  const total = useSelector(getPLTotalData);
  const multiCurrencyValue = useSelector(getMyBetsMultiCurrencyValue);

  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  const currencyCode = isMultiCurrencySupported && multiCurrencyValue ? multiCurrencyValue : '';

  const { noFormattedAmount: totalValue } = useFormatCurrency(total, currencyCode, {
    noRounding: true,
    isCheckIndian: true
  });

  return (
    <>
      {isMultiCurrencySupported && (
        <div className={styles.dateRangeResults__andMultiCurrencyDropdown}>
          <MultiCurrencyDropdown
            activePLTab={activePLTab}
            fetchDataWithCurrencyHandlers={{ fetchPLDataHandler: fetchPLData }}
          />
        </div>
      )}
      <div className={classNames('biab_table-form-holder', styles.formHolder)}>
        <div className={classNames(styles.formHolder__totalBlock, 'biab_total-block')}>
          {dataExists && (
            <span className={styles.formHolder__totalBlock__label}>
              {t('account.profitandloss.labels.totalPL')}: <strong>{totalValue}</strong>
            </span>
          )}
        </div>
      </div>
    </>
  );
};
export default FiltersContainerMobile;
