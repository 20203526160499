import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getBetsStatusesRequestInterval } from 'redux/modules/appConfigs/selectors';
import { fetchAsianViewBetStatuses, setPlaceBetsState } from 'redux/modules/asianViewBetslip';
import {
  getAsianViewBetsStatusesLoading,
  getAsianViewOfferIdsToGetBetStatuses
} from 'redux/modules/asianViewBetslip/selectors';
import { EPlaceBetsStates } from 'redux/modules/betslip/type';

const AsianViewBetSlipBetsStatusesInjection = () => {
  const dispatch = useDispatch();

  const betStatusesRequestInterval = useSelector(getBetsStatusesRequestInterval);
  const avStatusesOfferIds = useSelector(getAsianViewOfferIdsToGetBetStatuses);
  const avStatusesLoading = useSelector(getAsianViewBetsStatusesLoading);

  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  const stringifiedOfferIds = JSON.stringify(avStatusesOfferIds);

  useEffect(() => {
    if (avStatusesOfferIds.length) {
      const callback = () => {
        dispatch(setPlaceBetsState(EPlaceBetsStates.SELECT));
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = null;
        }
      };

      if (!avStatusesLoading) {
        dispatch(
          fetchAsianViewBetStatuses({
            offerIds: avStatusesOfferIds,
            onErrorCallback: callback,
            onSuccessCallback: callback
          })
        );
      }

      interval.current = setInterval(() => {
        if (!avStatusesLoading) {
          dispatch(
            fetchAsianViewBetStatuses({
              offerIds: avStatusesOfferIds,
              onErrorCallback: callback,
              onSuccessCallback: callback
            })
          );
        }
      }, Number(betStatusesRequestInterval));
    }

    if (!avStatusesOfferIds.length && interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  }, [stringifiedOfferIds, betStatusesRequestInterval]);

  return null;
};

export default AsianViewBetSlipBetsStatusesInjection;
