import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { cashOut as branding } from 'constants/branding';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { CashOutStatuses } from 'redux/modules/cashOut/type';

import styles from './styles.module.scss';

type BetSlipCashOutMessageProps = {
  status: string;
  onClose: (params: { isSuccess: boolean }) => void;
};

const BetSlipCashOutMessage = ({ status, onClose }: BetSlipCashOutMessageProps) => {
  const { t } = useTranslation();

  const isMobile = useSelector(getIsMobileAsianView);

  const isSuccess = status === CashOutStatuses.SUCCESS || status === CashOutStatuses.SUCCESS_WITH_BETTER_VALUE;
  const isError = status !== CashOutStatuses.SUCCESS && status !== CashOutStatuses.SUCCESS_WITH_BETTER_VALUE;

  return (
    <div
      className={classNames(styles.message, branding.CASH_OUT_MSG, {
        [styles.message__success]: isSuccess,
        [styles.message__error]: isError,
        [styles.message__mobileTab]: isMobile,
        [branding.SUCCESS]: isSuccess,
        [branding.ERROR]: isError
      })}
    >
      <div className={styles.message__text__container}>
        {isMobile && status === CashOutStatuses.SUCCESS ? (
          <div className={styles.message__mobileTab__left}>
            <i className={classNames('av-icon av-icon-tick', styles.tickIcon)} />
            <p
              className={classNames(styles.message__text, styles.message__mobileTab__left__text)}
              dangerouslySetInnerHTML={{ __html: t(`cashout.statuses.${status}`) }}
            />
          </div>
        ) : (
          <p
            className={classNames(styles.message__text, {
              [styles.message__mobileTab__left__text]: isMobile
            })}
            dangerouslySetInnerHTML={{ __html: t(`cashout.statuses.${status}`) }}
          />
        )}
        <i
          onClick={() => onClose({ isSuccess })}
          className={classNames('biab_tour-icon-close', styles.message__closeIcon)}
        />
      </div>
    </div>
  );
};

export default BetSlipCashOutMessage;
