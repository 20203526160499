import useBetLabels from 'hooks/useBetLabels';
import { BetTypes, THandicap, TPrice, TSize } from 'types/bets';
import { getCalculatedParams } from 'utils/betLabels';

import BetLabelsTpl from '../BetLabelsTpl';

type AHSingleLineLabelProps = {
  price: TPrice;
  size: TSize;
  handicap?: THandicap | null;
  betType: BetTypes;
  eventTypeId: string;
  currency?: string;
};

const AHSingleLineLabel = ({ price, size, handicap, betType, eventTypeId, currency }: AHSingleLineLabelProps) => {
  const { getAHSingleLineLabels } = useBetLabels();

  const { labelW, labelL } = getAHSingleLineLabels(eventTypeId, handicap);

  const { odds, stake, coefficient } = getCalculatedParams({ price, size, handicap, betType });

  const profitLossW = price && size ? (odds - 1) * stake * coefficient : null;
  const profitLossL = price && size ? -stake * coefficient : null;

  return (
    <BetLabelsTpl
      betType={betType}
      labelW={labelW}
      labelL={labelL}
      profitLossW={profitLossW}
      profitLossL={profitLossL}
      currency={currency}
    />
  );
};

export default AHSingleLineLabel;
