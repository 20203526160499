import { TooltipProvider } from 'contexts/tooltipContext';
import useTooltip from 'hooks/useTooltip';

import AccountLink from '../AccountLink';

interface AccountLinkProps {
  url: string;
  title: string;
  isIframeEnabled: boolean;
  openInNewTab?: boolean;
  externalLink?: boolean;
  tooltipKey: string;
}

const AccountLinkWithTooltip = ({
  url,
  title,
  openInNewTab,
  externalLink,
  tooltipKey,
  isIframeEnabled
}: AccountLinkProps) => {
  const { isEnabled, translationKey } = useTooltip(tooltipKey);
  return (
    <TooltipProvider value={{ isEnabled, translationKey, tooltipKey }}>
      <AccountLink
        isIframeEnabled={isIframeEnabled}
        url={url}
        title={title}
        openInNewTab={openInNewTab}
        externalLink={externalLink}
      />
    </TooltipProvider>
  );
};

export default AccountLinkWithTooltip;
