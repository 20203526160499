import { NavigationItemType } from './app';
import {
  ACCOUNT_BASE_URL,
  ACCOUNT_STATEMENT_BASE_URL,
  BETTING_PROFIT_LOSS_BASE_URL,
  MY_BETS_BASE_URL,
  RESPONSIBLE_BETTING_BASE_URL
} from './locations';
import { MY_BETS_LINK, MY_BETS_PL_LINK, RESPONSIBLE_BETTING, STATUS_LINK } from './tooltip';

export const ACCOUNT_NAVIGATION_SIDEBAR = (isPNCEnabled: boolean) => {
  return [
    {
      link: `${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/${isPNCEnabled ? 'matched' : 'unmatched'}/1`,
      title: 'accountNavigation.mybets',
      isShowInfo: true,
      code: MY_BETS_LINK,
      infoText: 'tooltip.accountMenu.myBetsLink',
      type: NavigationItemType.MY_BETS
    },
    {
      link: `${ACCOUNT_BASE_URL}${BETTING_PROFIT_LOSS_BASE_URL}/1`,
      title: 'accountNavigation.profitandloss',
      isShowInfo: true,
      code: MY_BETS_PL_LINK,
      infoText: 'tooltip.accountMenu.myBetsPLLink'
    },
    {
      link: `${ACCOUNT_BASE_URL}${ACCOUNT_STATEMENT_BASE_URL}/1`,
      title: 'accountNavigation.statement',
      isShowInfo: true,
      code: STATUS_LINK,
      infoText: 'tooltip.accountMenu.statusLink'
    }
  ];
};

export const RESPONSIBLE_BETTING_MENU_ITEM = {
  link: `${ACCOUNT_BASE_URL}/${RESPONSIBLE_BETTING_BASE_URL}`,
  title: 'responsibleBetting.labels.responsibleBetting',
  isShowInfo: false,
  code: RESPONSIBLE_BETTING
};

export const ACCOUNT_SUB_HEADER_NAVIGATION = (isPNCEnabled: boolean) => [
  {
    link: `${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/${isPNCEnabled ? 'matched' : 'unmatched'}/1`,
    title: 'sidebar.nav.myBets',
    icon: 'biab_custom-icon-myBets'
  },
  {
    link: `${ACCOUNT_BASE_URL}${BETTING_PROFIT_LOSS_BASE_URL}/1`,
    title: 'sidebar.nav.profitAndLoss',
    icon: 'biab_custom-icon-currency'
  },
  {
    link: `${ACCOUNT_BASE_URL}${ACCOUNT_STATEMENT_BASE_URL}/1`,
    title: 'sidebar.nav.statement',
    icon: 'biab_custom-icon-list'
  },
  {
    link: `${ACCOUNT_BASE_URL}${RESPONSIBLE_BETTING_BASE_URL}`,
    title: 'responsibleBetting.labels.responsibleBetting',
    icon: 'fa2 fa2-hand-paper-o'
  }
];
