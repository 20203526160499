import { MobilePlacementTypes } from '../constants/inlinePlacement';

export enum Statuses {
  NEW = 'NEW',
  EDIT = 'EDIT',
  PLACED = 'PLACED',
  CONFIRM = 'CONFIRM',
  CONFIRMED = 'CONFIRMED',
  CONFIRMED_ENTER = 'CONFIRMED_ENTER',
  HIDDEN = 'HIDDEN',
  TIMEOUT_HIDDEN = 'TIMEOUT_HIDDEN',
  PROGRESS = 'PROGRESS',
  PLACED_EDIT = 'PLACED_EDIT'
}

export enum Actions {
  SELECT = 'SELECT',
  CONFIRM = 'CONFIRM',
  PLACE = 'PLACE',
  PLACED = 'PLACED',
  EDIT = 'EDIT',
  CANCEL = 'CANCEL',
  TAKE = 'TAKE',
  UPDATE = 'UPDATE',
  PROGRESS = 'PROGRESS',
  LONG_PROGRESS = 'LONG_PROGRESS',
  CANCELLED = 'CANCELLED',
  REMOVED = 'REMOVED'
}

export enum InputTypes {
  ODDS = 'ODDS',
  STAKE = 'STAKE'
}

export enum ButtonActionTypes {
  PLACE = 'PLACE',
  CONFIRM = 'CONFIRM',
  EDIT = 'EDIT',
  UPDATE = 'UPDATE',
  TAKE_OFFER = 'TAKE_OFFER',
  CANCEL = 'CANCEL',
  EDIT_UNMATCHED = 'EDIT_UNMATCHED',
  CANCEL_UNMATCHED = 'CANCEL_UNMATCHED',
  CANCEL_EDIT = 'CANCEL_EDIT'
}

export type MobilePlacementType = typeof MobilePlacementTypes[keyof typeof MobilePlacementTypes];
