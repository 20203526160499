import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { TEventUpdatedData } from 'types';

import {
  failureFetchEventsUpdatedData,
  fetchEventsUpdatedData,
  fetchEventsUpdatedDataByEventId,
  setEventsUpdatedDataLoading,
  successFetchEventsUpdatedData
} from './index';
import { getStringifiedEventUpdatedData } from './selectors';

export function* getInPlayEventsUpdatedData(action: ReturnType<typeof fetchEventsUpdatedData>) {
  try {
    const response: TEventUpdatedData[] = yield call(api.app.eventUpdates, action.payload);
    const prevStringifiedEventUpdatedData: string = yield select(getStringifiedEventUpdatedData);
    const stringifiedResponse = JSON.stringify(response);

    if (prevStringifiedEventUpdatedData !== stringifiedResponse) {
      yield put(
        successFetchEventsUpdatedData({
          data: response,
          isWebSocketResponse: false,
          stringifiedEventsUpdatedData: stringifiedResponse
        })
      );
    } else {
      yield put(setEventsUpdatedDataLoading(false));
    }
  } catch (error: any) {
    yield put(failureFetchEventsUpdatedData(error.data));
  }
}

export function* getInPlayEventsUpdatedDataById(action: ReturnType<typeof fetchEventsUpdatedDataByEventId>) {
  try {
    const response: TEventUpdatedData = yield call(api.app.eventUpdatesById, action.payload);
    yield put(
      successFetchEventsUpdatedData({
        data: [response],
        isWebSocketResponse: false
      })
    );
  } catch (error: any) {
    yield put(failureFetchEventsUpdatedData(error.data));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchEventsUpdatedData.type, getInPlayEventsUpdatedData),
    takeLatest(fetchEventsUpdatedDataByEventId.type, getInPlayEventsUpdatedDataById)
  ]);
}
