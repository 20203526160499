import { ReactNode, useEffect, useState } from 'react';

interface IDelayedItem {
  item: ReactNode;
  delay: number;
}
const DelayedItem = ({ item, delay }: IDelayedItem) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return <div style={{ opacity: isVisible ? 1 : 0, transition: 'opacity 0.5s' }}>{item}</div>;
};

export default DelayedItem;
