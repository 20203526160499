import { createSlice } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';

import { TCouponSettings } from './type';

const initialState: TCouponSettings = {
  isModalOpen: false
};

const slice = createSlice({
  name: SLICES_NAMES.COUPON_SETTINGS,
  initialState,
  reducers: {
    setModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    }
  }
});

export const { setModalOpen } = slice.actions;

export default slice.reducer;
