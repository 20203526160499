import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface MyBetsStandartCellProps {
  value?: string | number | null | boolean;
  capitalise?: boolean;
  classname?: string;
  isWithTooltip?: boolean;
  tooltipValue?: string;
  entity?: string;
}

const MyBetsStandardCell = ({
  value,
  capitalise,
  classname,
  isWithTooltip,
  tooltipValue,
  entity
}: MyBetsStandartCellProps) => {
  const { t } = useTranslation();
  return (
    <div
      data-type={entity}
      className={classNames('myBetsTableCell', styles.myBetsTableCell, classname, {
        [styles.capitalise]: capitalise,
        [styles.won]: value === t('account.mybets.labels.won'),
        [styles.lost]: value === t('account.mybets.labels.lost')
      })}
    >
      {isWithTooltip ? (
        <span data-tooltip-id={'tooltip'} data-tooltip-html={tooltipValue}>
          {value || '--'}
        </span>
      ) : (
        <>{value || '--'}</>
      )}
    </div>
  );
};

export default MyBetsStandardCell;
