import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LINE_MARKET_ODDS } from 'constants/placement';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { getCashOutPlacedId } from 'redux/modules/cashOut/selectors';
import { ECurrentBetActions, TCurrentBet } from 'redux/modules/currentBets/type';
import { getIsMarketClosed } from 'redux/modules/marketsPrices/selectors';
import { BettingType } from 'types/markets';

export type TBetslipMessage = {
  isMessage: boolean;
  isTriggeredByCashOut?: boolean;
};

// Get placed message after bet was instantly placed, matched (fully or partially) or cancelled.
const useBetMessages = ({
  bet,
  isUnmatched,
  isUnmatchedBetDisabled,
  isFullyMatched
}: {
  bet: TCurrentBet;
  isUnmatched: boolean;
  isUnmatchedBetDisabled: boolean;
  isFullyMatched: boolean;
}) => {
  const { t } = useTranslation();

  const cashOutPlacedId = useSelector(getCashOutPlacedId(bet.marketId));
  const isMarketClosed = useSelector(getIsMarketClosed(bet.marketId));

  const { formattedAmount: sizeMatchedFormatted } = useFormatCurrency(bet.sizeMatched, bet.currency ?? '', {
    noCommas: true,
    noRounding: true
  });

  const messageParams = {
    sizeMatched: sizeMatchedFormatted,
    odds: bet.bettingType === BettingType.LINE ? LINE_MARKET_ODDS : bet.averagePriceRounded || bet.price
  };

  let betslipMessage: TBetslipMessage = { isMessage: false };
  let betMessage = '';

  // Bet is instantly fully matched
  if (
    (bet.sizeMatched > 0 && bet.sizeRemaining === 0) ||
    (bet.offerState === BetsStatusesTypes.MATCHED && bet.sizeRemaining === 0)
  ) {
    betslipMessage = {
      isMessage: true,
      isTriggeredByCashOut: bet.triggeredByCashOut && +(cashOutPlacedId || 0) === +bet.offerId
    };
  } else if (bet.sizeMatched > 0 && bet.sizeRemaining > 0 && bet.action === ECurrentBetActions.PARTIALLY_MATCHED) {
    betslipMessage = { isMessage: true };
  }

  if (isUnmatched && (isMarketClosed || bet.offerState === BetsStatusesTypes.LAPSED)) {
    betMessage = t('betslip.labels.wasLapsed');
  } else if (isUnmatchedBetDisabled) {
    betMessage = t('betslip.labels.unavailableSelectionNotification');
  } else if (+bet.sizeCancelled > 0 && +bet.sizeRemaining === 0 && bet.action === ECurrentBetActions.CANCELLING) {
    // Bet was cancelled
    betMessage = t('betslip.messages.cancelledPlacedBet');
  } else if (isUnmatched && isFullyMatched) {
    // Unmatched bet was matched
    betMessage = t('openBets.messages.matchedUnmatchedBet', messageParams);
  } else if (isUnmatched && +bet.sizeMatched > 0 && +bet.sizeRemaining > 0) {
    // Unmatched bet was partially matched
    betMessage = t('openBets.messages.matchedPartiallyUnmatchedBet', {
      ...messageParams,
      sizeMatched: sizeMatchedFormatted
    });
  }

  return {
    betMessage,
    betslipMessage
  };
};

export default useBetMessages;
