import { entries, keys } from 'lodash';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { checkSportsMarkets, setCheckMarketsError, setSportsSelectedBetsAndNotificationsFromStorage } from './index';

function* getIsActiveMarketsWorker(action: ReturnType<typeof checkSportsMarkets>) {
  try {
    const marketIds = keys(action.payload.selectedBets).map(marketId => marketId);

    const response: Record<string, boolean> = yield call(api.checkSportsMarkets, marketIds);

    const selectedBets = entries(action.payload.selectedBets)
      .filter(([marketId]) => response[marketId])
      .reduce((acc, [marketId, selectedMarket]) => {
        return {
          ...acc,
          [marketId]: selectedMarket
        };
      }, {});

    const placementNotifications = entries(action.payload.placementNotifications)
      .filter(([_, { marketId }]) => response[marketId])
      .reduce((acc, [betUuid, notification]) => {
        return {
          ...acc,
          [betUuid]: notification
        };
      }, {});

    if (keys(selectedBets).length || keys(placementNotifications).length) {
      yield put(setSportsSelectedBetsAndNotificationsFromStorage({ selectedBets, placementNotifications }));
    }
  } catch (error: any) {
    yield put(setCheckMarketsError(error));
  }
}

export default function* saga() {
  yield all([takeEvery(checkSportsMarkets.type, getIsActiveMarketsWorker)]);
}
