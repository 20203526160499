import { SOCCER_EXTRA_PERIOD_TIME, SOCCER_NUMBER_OF_PENALTIES, SOCCER_PERIOD_TIME } from 'constants/app';
import { EventScorePeriod, MatchStatus, TEventExtraData } from 'types';

export const soccerCards = ['RedCard', 'YellowCard'];
export const soccerGoal = 'Goal';
export const soccerPenalty = 'Penalty';

export const calculate = {
  first: (time: number) => (time >= SOCCER_PERIOD_TIME ? 100 : (100 / SOCCER_PERIOD_TIME) * time),
  second: (time: number) => (time <= SOCCER_PERIOD_TIME ? 0 : (100 / SOCCER_PERIOD_TIME) * (time - SOCCER_PERIOD_TIME)),
  extra_first: (time: number) => (time >= SOCCER_EXTRA_PERIOD_TIME ? 100 : (100 / SOCCER_EXTRA_PERIOD_TIME) * time),
  extra_second: (time: number) =>
    time <= SOCCER_EXTRA_PERIOD_TIME ? 0 : (100 / SOCCER_EXTRA_PERIOD_TIME) * (time - SOCCER_EXTRA_PERIOD_TIME)
};

export const getDistance = (time: number, period: 'first' | 'second' | 'extra_first' | 'extra_second') =>
  calculate[period](period.includes('extra') ? time - 2 * SOCCER_PERIOD_TIME : time);

export const getEventPosition = (events: TEventExtraData[]) => {
  const answer: {
    first: TEventExtraData[];
    second: TEventExtraData[];
    extra_first: TEventExtraData[];
    extra_second: TEventExtraData[];
  } = {
    first: [],
    second: [],
    extra_first: [],
    extra_second: []
  };

  events.forEach(item => {
    if ([soccerGoal, ...soccerCards].includes(item.updateType)) {
      const prefix = item.period === EventScorePeriod.EXTRA ? `${EventScorePeriod.EXTRA.toLowerCase()}_` : '';
      const match =
        +item.elapsedRegularTime <= SOCCER_PERIOD_TIME ||
        item.inPlayMatchStatus === MatchStatus.KICK_OFF ||
        item.inPlayMatchStatus === MatchStatus.HALF_TIME
          ? 'first'
          : 'second';
      const period = `${prefix}${match}` as keyof typeof answer;
      answer[period].push({
        ...item,
        footballWidth: getDistance(item.elapsedRegularTime, period)
      });
    }
  });

  return answer;
};

export const getEventPenaltyData = (events: TEventExtraData[]) => {
  const result: {
    home: string[];
    away: string[];
  } = {
    home: [],
    away: []
  };

  events.forEach(item => {
    if (soccerPenalty === item.updateType && item.period === EventScorePeriod.EXTRA) {
      const key = item.team as keyof typeof result;
      result[key].push(item.penaltyResult);
    }
  });

  const hasPenalty = !!result.home.length || !!result.away.length;

  result.home = result.home.concat(
    result.home.length < SOCCER_NUMBER_OF_PENALTIES
      ? new Array(SOCCER_NUMBER_OF_PENALTIES - result.home.length).fill(null)
      : []
  );
  result.away = result.away.concat(
    result.away.length < SOCCER_NUMBER_OF_PENALTIES
      ? new Array(SOCCER_NUMBER_OF_PENALTIES - result.away.length).fill(null)
      : []
  );

  const homeScore = result.home.filter(penaltyResult => penaltyResult === 'scored').length;
  const awayScore = result.away.filter(penaltyResult => penaltyResult === 'scored').length;
  const score = `${homeScore} - ${awayScore}`;

  return { ...result, score, hasPenalty };
};

export const convertTeam = (team?: string | null) => (team ?? '').toLowerCase();

export const getGoalsEvents = (events: TEventExtraData[]) => {
  const homeGoals: TEventExtraData[] = [];
  const awayGoals: TEventExtraData[] = [];

  events.forEach(item => {
    if (item.updateType === 'Goal') {
      if (convertTeam(item.team) === 'home') {
        homeGoals.push(item);
      } else {
        awayGoals.push(item);
      }
    }
  });

  return { homeGoals, awayGoals };
};
