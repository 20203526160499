import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { IMarket } from 'types/markets';

import { failurePopularMarkets, fetchPopularMarkets, successGetPopularMarkets } from './index';

function* getNextRacesWorker() {
  try {
    const response: IMarket[] = yield call(api.marketOdds.getMarketOdds);
    yield put(successGetPopularMarkets(response));
  } catch (error: any) {
    yield put(failurePopularMarkets(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchPopularMarkets.type, getNextRacesWorker)]);
}
