import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAppDevice, getLanguage, getPropertiesTours } from 'redux/modules/appConfigs/selectors';
import { Tour } from 'redux/modules/appConfigs/type';
import { fetchStepData, setTourList } from 'redux/modules/tours';
import { getTourList, isToursLoading } from 'redux/modules/tours/selectors';

const useWebTours = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isToursLoading);
  const tourList = useSelector(getTourList);
  const device = useSelector(getAppDevice);
  const tours = useSelector(getPropertiesTours);
  const language = useSelector(getLanguage);

  const [hideTours, setHideTours] = useState(false);
  const [openTourModal, setOpenTourModal] = useState(false);
  const [selectedTour, setSelectedTour] = useState<Tour>();
  const [tourChanged, setTourChanged] = useState<number | null>(null);
  const [tourLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(setTourList(tours));
  }, [dispatch, tours]);

  const firstStep = tourList.find(item => item.id === selectedTour?.id)?.steps?.filter(step => step.enabled)[0];

  useEffect(() => {
    if (selectedTour) {
      if (firstStep?.content && !openTourModal) {
        setOpenTourModal(true);
      }
    }
  }, [selectedTour, tourList]);

  useEffect(() => {
    if (tourChanged) {
      setIsLoading(true);
      setSelectedTour(tours.find(item => item.id === tourChanged)!);
      if (firstStep?.content && !isLoading) {
        setTourChanged(null);
        setIsLoading(false);
      }
    }
  }, [tourChanged, tourList, isLoading]);

  const changeSelectedTour = (id: number) => {
    const firstStepOfChanged = tours.find(item => item.id === id)?.steps?.filter(step => step.enabled)[0].id;
    dispatch(fetchStepData({ id: firstStepOfChanged!, platform: device, locale: language }));
    setTourChanged(id);
  };

  const handleStartTour = (id?: number) => {
    setHideTours(true);
    setSelectedTour(id ? tours.find(item => item.id === id)! : tours[0]);
    const tour = id ? tours.find(item => item.id === id) : tours[0];
    const firstStepOfStarted = tour?.steps?.filter(step => step.enabled)[0].id;

    dispatch(fetchStepData({ id: firstStepOfStarted!, platform: device, locale: language }));
  };

  return {
    selectedTour,
    setSelectedTour,
    openTourModal,
    setOpenTourModal,
    changeSelectedTour,
    handleStartTour,
    tourLoading,
    hideTours,
    setHideTours
  };
};

export default useWebTours;
