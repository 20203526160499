import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { SPORT_BASE_URL } from 'constants/locations';
import { SportId } from 'types';

import styles from './styles.module.scss';

interface LiveScoreNameProps {
  isSearchType: boolean;
  eventTypeId: number;
  eventId: string;
  name?: string;
  truncate?: boolean;
}

const LiveScoreName = forwardRef<HTMLDivElement, LiveScoreNameProps>(
  ({ isSearchType, eventTypeId, eventId, name, truncate }, ref) => {
    const isDoubleMatch = !!name?.includes('/');
    const splittedDoubleMatchName = name?.split('/');

    const nameToRender = (
      <>
        {isDoubleMatch && !!splittedDoubleMatchName?.length ? (
          <div className={styles.bigName}>
            <div>{splittedDoubleMatchName[0]}</div>
            <span>/</span>
            <div>{splittedDoubleMatchName[1]}</div>
          </div>
        ) : (
          name
        )}
      </>
    );

    return (
      <div
        ref={ref}
        className={classNames(styles.teamNameHolder, {
          [styles.teamNameHolder__criket]: SportId.CRICKET === eventTypeId.toString(),
          [styles.teamNameHolder__tennis]: SportId.TENNIS === eventTypeId.toString(),
          [styles.truncateName]: truncate,
          [styles.doubleMatch]: isDoubleMatch
        })}
      >
        {isSearchType ? (
          <Link to={`${SPORT_BASE_URL}/${eventTypeId}/event/${eventId}`}>{nameToRender}</Link>
        ) : (
          <>{nameToRender}</>
        )}
      </div>
    );
  }
);

export default LiveScoreName;
