import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { BET_RADAR_PROVIDER, BET_RADAR_VIDEO_PLAYER_HEIGHT, BET_RADAR_VIDEO_PLAYER_WIDTH } from 'constants/videoStream';
import { TFailureActionPayload } from 'types';

import api from '../../api/methods';

import { HLSUrlResponse, TVideoStream, VideoStreamUrlsPayload } from './type';

const initialState: TVideoStream = {
  hlsPlayerData: {
    eventId: 0,
    hlsUrl: '',
    statusCode: 0
  },
  betRadarPlayerUrl: '',
  error: null,
  loading: false
};

const slice = createSlice({
  name: SLICES_NAMES.VIDEO_STREAM,
  initialState,
  reducers: {
    failureFetchVideoStreamUrls: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
    },
    successFetchVideoHLSUrl: (state, { payload }: PayloadAction<HLSUrlResponse>) => {
      state.loading = false;
      state.hlsPlayerData = payload;
    },
    failureFetchVideoHLSUrl: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
    },
    setVideoStreamBetRadarUrl: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.betRadarPlayerUrl = payload;
    },
    fetchVideoStreamHLSUrl: (_, __: PayloadAction<string>) => {}
  }
});

export const { setVideoStreamBetRadarUrl, successFetchVideoHLSUrl, fetchVideoStreamHLSUrl, failureFetchVideoHLSUrl } =
  slice.actions;

export const fetchVideoStreamUrls = createAsyncThunk(
  '@responsibleBetting/UPDATE_RESPONSIBLE_BETTING_REMINDER_LAST_DATE',
  async ({ eventId, isMobile, onError }: VideoStreamUrlsPayload, { dispatch, rejectWithValue }) => {
    try {
      const {
        data: { streamUrl, playerUrl },
        providerName
      } = await api.videoStream.getVideoStreamUrls(eventId);

      const isBetRadar = providerName === BET_RADAR_PROVIDER;

      if (isMobile) {
        if (isBetRadar) {
          if (playerUrl) {
            dispatch(setVideoStreamBetRadarUrl(playerUrl));
          }
        } else {
          if (streamUrl) {
            dispatch(fetchVideoStreamHLSUrl(streamUrl));
          }
        }
      } else {
        if (playerUrl) {
          window.open(
            playerUrl,
            '_blank',
            `toolbar=yes,scrollbars=yes,resizable=yes,width=${isBetRadar ? BET_RADAR_VIDEO_PLAYER_WIDTH : 500},height=${
              isBetRadar ? BET_RADAR_VIDEO_PLAYER_HEIGHT : 400
            }`
          );
        }
      }
    } catch (err: any) {
      dispatch(failureFetchVideoHLSUrl(err));
      onError?.();
      rejectWithValue(err);
    }
  }
);

export default slice.reducer;
