import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import BackButton from 'components/Header/components/BackButton';
import { STARTING_SHORT_MONTH } from 'constants/date';
import { IconsConfig } from 'constants/iconsConfig';
import useBackButtonInIFrame from 'hooks/useBackButtonInIFrame';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { RACE_SEARCH_PARAM } from 'pages/mobile/MobileTodayCardPage';
import { getMobileSettingsSwipeMenu } from 'redux/modules/appConfigs/selectors';
import { TNavigationResponse } from 'redux/modules/competitions/type';
import { getSingleMarketInfo } from 'redux/modules/market/selectors';

import EventInfo from '../EventInfo';

import styles from './styles.module.scss';

interface RaceCardHeaderInfoProps {
  race: TNavigationResponse;
}

const RaceCardHeaderInfo = ({ race }: RaceCardHeaderInfoProps) => {
  const { sportId = '' } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const swipeMenu = useSelector(getMobileSettingsSwipeMenu);
  const market = useSelector(getSingleMarketInfo);

  const showIFrameBackButton = useBackButtonInIFrame();
  const marketTime = useLongDateFormat({
    time: race.marketStartTime ?? 0,
    hasWeekDay: true
  });

  const date = new Date(race.marketStartTime ?? 0);
  const selectedRaceId = searchParams.get(RACE_SEARCH_PARAM);

  return (
    <div
      className={classNames('biab_market-title', styles.raceHeaderInfo, {
        [styles.raceHeaderInfo__swipeMenu]: swipeMenu
      })}
    >
      <div className={styles.raceHeaderInfo__content}>
        {selectedRaceId === `tc-${market?.parents?.at(-1)?.id}` && selectedRaceId === race.id ? (
          <EventInfo />
        ) : (
          <>
            {showIFrameBackButton ? (
              <BackButton classes={{ container: styles.raceHeaderInfo__backButton }} />
            ) : (
              <i className={classNames('biab_br-sport-icon', IconsConfig.SPORT_ICONS[sportId])} />
            )}
            <div>
              <p className={styles.raceHeaderInfo__title}>
                {race.venue} {date.getDate()} {t(`${STARTING_SHORT_MONTH}${date.getMonth()}`)}
              </p>
              <p className={classNames('biab_market-date', styles.raceHeaderInfo__time)}>{marketTime}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RaceCardHeaderInfo;
