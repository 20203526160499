import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { failureFetchVideoHLSUrl, fetchVideoStreamHLSUrl, successFetchVideoHLSUrl } from './index';
import { HLSUrlResponse } from './type';

export function* getHLSUrlWorker(action: ReturnType<typeof fetchVideoStreamHLSUrl>) {
  try {
    const hlsUrlData: HLSUrlResponse = yield call<typeof api.videoStream.getVideoStreamHlsUrl>(
      api.videoStream.getVideoStreamHlsUrl,
      action.payload
    );

    yield put(successFetchVideoHLSUrl(hlsUrlData));
  } catch (error: any) {
    yield put(failureFetchVideoHLSUrl(error.message));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchVideoStreamHLSUrl.type, getHLSUrlWorker)]);
}
