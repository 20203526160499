import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AUTO_CASH_OUT_GET_MARKETS_INTERVAL, GeneralWebSocketSubscriptionTypes } from 'constants/app';
import {
  getAutoCashOutEnabled,
  getAutoCashOutWsEnabled,
  getCashOutGetQuotesInterval,
  getCashOutQuoteWsEnabled,
  getGeneralWsEnabled,
  getIsPropertiesLoaded,
  getLanguage,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import { fetchAsianViewAutoCashOutMarkets, fetchAsianViewCashOutQuotes } from 'redux/modules/asianViewCashOut';
import { getAsianViewCashOutMarketsContentMarketIds } from 'redux/modules/asianViewCashOut/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchEventAutoCashOutMarkets, fetchEventCashOutQuotes } from 'redux/modules/eventCashOut';
import { getEventCashOutMarketsContentMarketIds } from 'redux/modules/eventCashOut/selectors';
import {
  getIsConnectedAsianViewGeneral,
  getSubscribeToAsianViewGeneralMessages
} from 'redux/modules/webSocket/selectors';

type CashOutSectionIntervalRequestsInjectionProps = {
  isTab?: boolean;
};

const CashOutSectionIntervalRequestsInjection = ({ isTab }: CashOutSectionIntervalRequestsInjectionProps) => {
  const dispatch = useDispatch();

  const cashOutGetQuotesInterval = useSelector(getCashOutGetQuotesInterval);
  const autoCashOutEnabled = useSelector(getAutoCashOutEnabled);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const marketIds = useSelector(
    isTab ? getEventCashOutMarketsContentMarketIds : getAsianViewCashOutMarketsContentMarketIds
  );
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const cashOutQuoteWsEnabled = useSelector(getCashOutQuoteWsEnabled);
  const autoCashOutWsEnabled = useSelector(getAutoCashOutWsEnabled);
  const isConnectedAsianViewGeneralWebSocket = useSelector(getIsConnectedAsianViewGeneral);
  const subscribeAsianViewGeneralWebSocketMessages = useSelector(getSubscribeToAsianViewGeneralMessages);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const cashOutQuotesInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const generalWsDataRef = useRef<{
    subscribeAsianViewGeneralWebSocketMessages: (<F>(params: F) => void) | null;
    isCashOutQuotesSubscriptionAvailable: boolean;
    isAutoCashOutSubscriptionAvailable: boolean;
    isCashOutQuotesUnsubscriptionAvailable: boolean;
    isAutoCashOutUnsubscriptionAvailable: boolean;
    isCashOutQuotesSubscribed: boolean;
    isAutoCashOutSubscribed: boolean;
  }>({
    subscribeAsianViewGeneralWebSocketMessages,
    isAutoCashOutSubscriptionAvailable: false,
    isCashOutQuotesSubscriptionAvailable: false,
    isAutoCashOutUnsubscriptionAvailable: false,
    isCashOutQuotesUnsubscriptionAvailable: false,
    isCashOutQuotesSubscribed: false,
    isAutoCashOutSubscribed: false
  });

  const isCashOutAvailable = isLoggedIn && !!marketIds.length && arePropertiesLoaded;
  const isCashOutQuoteIntervalAvailable = isCashOutAvailable && (!generalWsEnabled || !cashOutQuoteWsEnabled);
  const isCashOutQuotesSubscriptionAvailable =
    isCashOutAvailable &&
    cashOutQuoteWsEnabled &&
    generalWsEnabled &&
    isConnectedAsianViewGeneralWebSocket &&
    !!subscribeAsianViewGeneralWebSocketMessages;
  const isAutoCashOutAvailable = isCashOutAvailable && autoCashOutEnabled;
  const isAutoCashOutIntervalAvailable = isAutoCashOutAvailable && (!generalWsEnabled || !autoCashOutWsEnabled);
  const isAutoCashOutSubscriptionAvailable =
    isAutoCashOutAvailable &&
    autoCashOutWsEnabled &&
    generalWsEnabled &&
    isConnectedAsianViewGeneralWebSocket &&
    !!subscribeAsianViewGeneralWebSocketMessages;

  generalWsDataRef.current = {
    ...generalWsDataRef.current,
    subscribeAsianViewGeneralWebSocketMessages,
    isAutoCashOutSubscriptionAvailable,
    isCashOutQuotesSubscriptionAvailable,
    isAutoCashOutUnsubscriptionAvailable:
      isLoggedIn &&
      arePropertiesLoaded &&
      autoCashOutEnabled &&
      autoCashOutWsEnabled &&
      generalWsEnabled &&
      isConnectedAsianViewGeneralWebSocket,
    isCashOutQuotesUnsubscriptionAvailable:
      isLoggedIn &&
      arePropertiesLoaded &&
      cashOutQuoteWsEnabled &&
      generalWsEnabled &&
      isConnectedAsianViewGeneralWebSocket
  };

  useEffect(() => {
    if (isCashOutQuoteIntervalAvailable) {
      if (isTab) {
        dispatch(fetchEventCashOutQuotes({ ids: marketIds, firstLoading: true }));
      } else {
        dispatch(fetchAsianViewCashOutQuotes({ ids: marketIds, firstLoading: true }));
      }

      cashOutQuotesInterval.current = setInterval(() => {
        if (isTab) {
          dispatch(fetchEventCashOutQuotes({ ids: marketIds }));
        } else {
          dispatch(fetchAsianViewCashOutQuotes({ ids: marketIds }));
        }
      }, cashOutGetQuotesInterval);
    }

    return () => {
      if (cashOutQuotesInterval.current) {
        clearInterval(cashOutQuotesInterval.current);
      }
    };
  }, [marketIds, cashOutGetQuotesInterval, language, timezone, isCashOutQuoteIntervalAvailable]);

  useEffect(() => {
    if (isAutoCashOutIntervalAvailable) {
      const getAutoCashOutMarkets = () => {
        if (isTab) {
          dispatch(fetchEventAutoCashOutMarkets(marketIds));
        } else {
          dispatch(fetchAsianViewAutoCashOutMarkets(marketIds));
        }
      };

      getAutoCashOutMarkets();
      const interval = setInterval(getAutoCashOutMarkets, AUTO_CASH_OUT_GET_MARKETS_INTERVAL);

      return () => clearInterval(interval);
    }
  }, [marketIds, language, timezone, isAutoCashOutIntervalAvailable]);

  useEffect(() => {
    if (isCashOutQuotesSubscriptionAvailable && marketIds.length) {
      const subscriptionType = isTab
        ? GeneralWebSocketSubscriptionTypes.cashOutQuoteAsianEvent
        : GeneralWebSocketSubscriptionTypes.cashOutQuote;

      generalWsDataRef.current.isCashOutQuotesSubscribed = true;

      subscribeAsianViewGeneralWebSocketMessages({
        [subscriptionType]: {
          subscribe: true,
          marketIds
        }
      });
    }
  }, [isCashOutQuotesSubscriptionAvailable, marketIds]);

  useEffect(() => {
    if (isAutoCashOutSubscriptionAvailable && marketIds.length) {
      const subscriptionType = isTab
        ? GeneralWebSocketSubscriptionTypes.autoCashOutAsianEvent
        : GeneralWebSocketSubscriptionTypes.autoCashOut;

      generalWsDataRef.current.isAutoCashOutSubscribed = true;

      subscribeAsianViewGeneralWebSocketMessages({
        [subscriptionType]: {
          subscribe: true,
          marketIds
        }
      });
    }
  }, [isAutoCashOutSubscriptionAvailable, marketIds]);

  useEffect(() => {
    return () => {
      const {
        subscribeAsianViewGeneralWebSocketMessages: subscribeFunc,
        isCashOutQuotesUnsubscriptionAvailable,
        isAutoCashOutUnsubscriptionAvailable,
        isCashOutQuotesSubscribed,
        isAutoCashOutSubscribed
      } = generalWsDataRef.current;

      if (subscribeFunc && isCashOutQuotesUnsubscriptionAvailable && isCashOutQuotesSubscribed) {
        const subscriptionType = isTab
          ? GeneralWebSocketSubscriptionTypes.cashOutQuoteAsianEvent
          : GeneralWebSocketSubscriptionTypes.cashOutQuote;

        generalWsDataRef.current.isCashOutQuotesSubscribed = false;

        subscribeFunc({ [subscriptionType]: { subscribe: false } });
      }

      if (subscribeFunc && isAutoCashOutUnsubscriptionAvailable && isAutoCashOutSubscribed) {
        const subscriptionType = isTab
          ? GeneralWebSocketSubscriptionTypes.autoCashOutAsianEvent
          : GeneralWebSocketSubscriptionTypes.autoCashOut;

        generalWsDataRef.current.isAutoCashOutSubscribed = false;

        subscribeFunc({ [subscriptionType]: { subscribe: false } });
      }
    };
  }, [marketIds]);

  return null;
};

export default CashOutSectionIntervalRequestsInjection;
