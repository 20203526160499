import { useSelector } from 'react-redux';

import {
  getAsianViewCashOutMarketsByCompetitions,
  getAsianViewCashOutQuotesMarketsIds
} from 'redux/modules/asianViewCashOut/selectors';

import DesktopCashOutListItem from '../DesktopCashOutListItem';

const DesktopCashOutList = () => {
  const content = useSelector(getAsianViewCashOutMarketsByCompetitions);
  const quotesMarketsIds = useSelector(getAsianViewCashOutQuotesMarketsIds);

  return (
    <>
      {Object.entries(content).map(([key, markets]) => {
        const cashOutMarkets = markets.filter(({ marketId }) => quotesMarketsIds.includes(marketId));

        if (cashOutMarkets.length) {
          return <DesktopCashOutListItem key={key} id={key} markets={cashOutMarkets} />;
        }

        return null;
      })}
    </>
  );
};

export default DesktopCashOutList;
