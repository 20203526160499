import { flatten, values } from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { BetsStatusesTypes, TBetStatus } from '../betsStatuses/type';

import {
  failureFetchAsianViewBetStatuses,
  failurePlaceAllSelectedBets,
  fetchAsianViewBetStatuses,
  placeAllSelectedBets,
  successFetchAsianViewBetsStatuses,
  successPlaceAllSelectedBets
} from './index';
import { AsianViewPlacedBetsByMarket } from './type';

export function* asianViewPlaceSelectedBetsWorker(action: ReturnType<typeof placeAllSelectedBets>) {
  try {
    const response: AsianViewPlacedBetsByMarket = yield call(api.asianPlacement.placeBets, action.payload.data);

    yield put(successPlaceAllSelectedBets(response));

    action.payload.onSuccessCallback(flatten(values(response).map(({ offerIds }) => values(offerIds))));
  } catch (error: any) {
    yield put(failurePlaceAllSelectedBets(error.data));
    action.payload.onErrorCallback();
  }
}

export function* getAsianViewBetsStatusesWorker(action: ReturnType<typeof fetchAsianViewBetStatuses>) {
  try {
    const betStatuses: TBetStatus = yield call(api.placement.getBetsStatuses, action.payload.offerIds.join());

    if (
      !values(betStatuses).some(status => status === BetsStatusesTypes.PENDING) &&
      action.payload?.onSuccessCallback
    ) {
      action.payload.onSuccessCallback();
    }
    yield put(successFetchAsianViewBetsStatuses(betStatuses));
  } catch (error: any) {
    yield put(failureFetchAsianViewBetStatuses({ offersIds: action.payload.offerIds, error: error?.response?.data }));
    action.payload.onErrorCallback();
  }
}

export default function* saga() {
  yield all([
    takeLatest(placeAllSelectedBets.type, asianViewPlaceSelectedBetsWorker),
    takeLatest(fetchAsianViewBetStatuses.type, getAsianViewBetsStatusesWorker)
  ]);
}
