import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GeneralWebSocketSubscriptionTypes } from 'constants/app';
import {
  getCashOutGetQuotesInterval,
  getCashOutQuoteWsEnabled,
  getGeneralWsEnabled,
  getIsPropertiesLoaded,
  getLanguage,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchCashOutQuotes } from 'redux/modules/cashOut';
import { getFetchCashOutLoading } from 'redux/modules/cashOut/selectors';
import { fetchGetSports } from 'redux/modules/competitions';
import { isMarketHasOffers } from 'redux/modules/currentBets/selectors';
import { getIsConnectedGeneral, getSubscribeToGeneralMessages } from 'redux/modules/webSocket/selectors';

type MobileTodayCardPageIntervalRequestsInjectionProps = {
  marketId?: string;
  sportId: string;
  cashOutEnabled?: boolean;
};

const MobileTodayCardPageIntervalRequestsInjection = ({
  marketId,
  sportId,
  cashOutEnabled
}: MobileTodayCardPageIntervalRequestsInjectionProps) => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isMarketHasOffer = useSelector(isMarketHasOffers(marketId));
  const language = useSelector(getLanguage);
  const timezone = useSelector(getTimezone);
  const fetchCashOutLoading = useSelector(getFetchCashOutLoading);
  const cashOutQuoteWsEnabled = useSelector(getCashOutQuoteWsEnabled);
  const isConnectedGeneralWebSocket = useSelector(getIsConnectedGeneral);
  const subscribeGeneralWebSocketMessages = useSelector(getSubscribeToGeneralMessages);
  const cashOutGetQuotesInterval = useSelector(getCashOutGetQuotesInterval);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const cashOutInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const fetchCashOutLoadingRef = useRef(fetchCashOutLoading);
  const generalWsDataRef = useRef<{
    isCashOutQuoteSubscriptionAvailable: boolean;
    subscribeGeneralWebSocketMessages: (<F>(params: F) => void) | null;
  }>({
    isCashOutQuoteSubscriptionAvailable: false,
    subscribeGeneralWebSocketMessages: null
  });

  const isCashOutQuoteAvailable = isLoggedIn && !!cashOutEnabled && !!isMarketHasOffer && arePropertiesLoaded;
  const isCashOutQuoteIntervalAvailable = isCashOutQuoteAvailable && (!generalWsEnabled || !cashOutQuoteWsEnabled);
  const isCashOutQuoteSubscriptionAvailable =
    isCashOutQuoteAvailable &&
    cashOutQuoteWsEnabled &&
    generalWsEnabled &&
    isConnectedGeneralWebSocket &&
    !!subscribeGeneralWebSocketMessages;

  fetchCashOutLoadingRef.current = fetchCashOutLoading;
  generalWsDataRef.current = { subscribeGeneralWebSocketMessages, isCashOutQuoteSubscriptionAvailable };

  useEffect(() => {
    if (sportId) {
      dispatch(fetchGetSports({ sportId }));

      const timer = setInterval(() => {
        dispatch(fetchGetSports({ sportId, noReset: true }));
      }, 15000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [sportId, language, timezone]);

  useEffect(() => {
    if (isCashOutQuoteIntervalAvailable && marketId) {
      dispatch(fetchCashOutQuotes({ ids: [marketId] }));

      cashOutInterval.current = setInterval(() => {
        if (!fetchCashOutLoadingRef.current) {
          dispatch(fetchCashOutQuotes({ ids: [marketId] }));
        }
      }, cashOutGetQuotesInterval);
    }

    return () => {
      if (cashOutInterval.current) {
        clearInterval(cashOutInterval.current);
      }
    };
  }, [cashOutGetQuotesInterval, isCashOutQuoteIntervalAvailable, marketId, language, timezone]);

  useEffect(() => {
    if (isCashOutQuoteSubscriptionAvailable && marketId) {
      subscribeGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.cashOutQuote]: {
          subscribe: true,
          marketIds: [marketId]
        }
      });
    }
  }, [isCashOutQuoteSubscriptionAvailable, marketId, language, timezone]);

  useEffect(() => {
    return () => {
      if (
        generalWsDataRef.current.isCashOutQuoteSubscriptionAvailable &&
        generalWsDataRef.current.subscribeGeneralWebSocketMessages
      ) {
        generalWsDataRef.current.subscribeGeneralWebSocketMessages({
          [GeneralWebSocketSubscriptionTypes.cashOutQuote]: { subscribe: false }
        });
      }
    };
  }, []);

  return null;
};

export default MobileTodayCardPageIntervalRequestsInjection;
