import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';

import Loader, { CircleColors } from 'components/Loader';
import { TYPE_TO_URL } from 'constants/competitions';
import { SPORT_BASE_URL } from 'constants/locations';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { fetchGetEvents } from 'redux/modules/competitions';
import { getEventData, getEventDataLoading } from 'redux/modules/competitions/selectors';

import styles from './styles.module.scss';

const AllMarkets = () => {
  const dispatch = useDispatch();
  const { sportId = '', eventId = '' } = useParams();

  const eventData = useSelector(getEventData);
  const loading = useSelector(getEventDataLoading);
  const translation = useSelector(getTranslation);

  useEffect(() => {
    if (eventId) {
      dispatch(fetchGetEvents({ eventId }));
    }
  }, [eventId]);

  if (loading) {
    return <Loader circleColor={CircleColors.BLACK} />;
  }

  return (
    <>
      {eventData.map(({ id, translations, type }) => (
        <p key={id} className={classNames('biab_all-markets-link', styles.allMarkets__link__wrapper)}>
          <Link to={`${SPORT_BASE_URL}/${sportId}/${TYPE_TO_URL[type]}/${id}`} className={styles.allMarkets__link}>
            <span>{translations[translation]}</span>
            <i className={classNames('biab_arrow-icon fa2 fa2-arrow-right', styles.allMarkets__link__arrow)} />
          </Link>
        </p>
      ))}
    </>
  );
};

export default AllMarkets;
