import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  getEventUpdatedDataTennisAwayGames,
  getEventUpdatedDataTennisAwaySets,
  getEventUpdatedDataTennisHomeGames,
  getEventUpdatedDataTennisHomeSets
} from 'redux/modules/marketsPrices/selectors';

import styles from './styles.module.scss';

type TennisMarketScoreProps = {
  isCashOut?: boolean;
  eventId: string;
};

const TennisMarketScore = ({ isCashOut, eventId }: TennisMarketScoreProps) => {
  const homeScoreSets = useSelector(getEventUpdatedDataTennisHomeSets(eventId));
  const homeScoreGames = useSelector(getEventUpdatedDataTennisHomeGames(eventId));
  const awayScoreSets = useSelector(getEventUpdatedDataTennisAwaySets(eventId));
  const awayScoreGames = useSelector(getEventUpdatedDataTennisAwayGames(eventId));

  return isCashOut ? (
    <table className={classNames('biab_tennis', styles.tennisCashOut)}>
      <tbody>
        <tr>
          <td className={classNames('biab_tennis-sets biab_home-tennis-sets', styles.tennisCashOut__cell)}>
            {homeScoreSets}
          </td>
          <td className={classNames('biab_tennis-games biab_home-tennis-games', styles.tennisCashOut__cell)}>
            {homeScoreGames}
          </td>
        </tr>
        <tr>
          <td className={classNames('biab_tennis-sets biab_home-tennis-sets', styles.tennisCashOut__cell)}>
            {awayScoreSets}
          </td>
          <td className={classNames('biab_tennis-games biab_home-tennis-games', styles.tennisCashOut__cell)}>
            {awayScoreGames}
          </td>
        </tr>
      </tbody>
    </table>
  ) : (
    <div className={classNames('biab_tennis', styles.tennis)}>
      <div className={classNames('biab_tennis-sets', styles.tennis__sets)}>
        <span className={styles.tennis__cell}>{homeScoreSets}</span>
        <span className={styles.tennis__cell}>{awayScoreSets}</span>
      </div>
      <div className={classNames('biab_tennis-games', styles.tennis__games)}>
        <span className={styles.tennis__cell}>{homeScoreGames}</span>
        <span className={styles.tennis__cell}>{awayScoreGames}</span>
      </div>
    </div>
  );
};

export default TennisMarketScore;
