import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import BetslipSkeleton from 'components/Skeleton/BetslipSkeleton';
import { betslipBranding, componentsBranding } from 'constants/branding';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getHasPlacementNotifications, getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { closeAllCurrentBetsCanBeRemoved, removeAllUnmatchedOffersIdsToShowLapsed } from 'redux/modules/currentBets';
import {
  getCurrentBetsLengthByType,
  getCurrentBetsLoading,
  getIsAtLeastOneCanBeRemovedCurrentBet
} from 'redux/modules/currentBets/selectors';
import { MatchTypes } from 'types/bets';

import OpenedBetsMatched from '../OpenedBetsMatched/OpenedBetsMatched';
import OpenedBetsTabEmpty from '../OpenedBetsTabEmpty/OpenedBetsTabEmpty';
import OpenedBetsUnmatched from '../OpenedBetsUnmatched/OpenedBetsUnmatched';

import styles from './styles.module.scss';

const tabs = [
  { label: 'betslip.openBets.tabs.unmatched', id: 'unmatched' },
  { label: 'betslip.openBets.tabs.matched', id: 'matched' }
] as const;
const tabsContent = { unmatched: <OpenedBetsUnmatched />, matched: <OpenedBetsMatched /> } as const;

const OpenedBetsTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hasPlacementNotifications = useSelector(getHasPlacementNotifications);
  const isGameBetslip = useSelector(getIsGameBetSlip);
  const isLoading = useSelector(getCurrentBetsLoading);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const unmatchedBetsCount = useSelector(
    getCurrentBetsLengthByType(
      isPNCEnabled && !isGameBetslip
        ? undefined
        : {
            type: MatchTypes.UNMATCHED,
            isGameType: isGameBetslip,
            ignoreCancelled: true,
            ignoreFullyMatchedAction: true
          }
    )
  );
  const unmatchedBetsCountWithCancelled = useSelector(
    getCurrentBetsLengthByType(
      isPNCEnabled && !isGameBetslip
        ? undefined
        : { type: MatchTypes.UNMATCHED, isGameType: isGameBetslip, anyCancelled: true, showLapsed: true }
    )
  );
  const matchedBetsCount = useSelector(
    getCurrentBetsLengthByType({ type: MatchTypes.MATCHED, isGameType: isGameBetslip, ignoreCancelled: true })
  );
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isAtLeastOneCanBeRemovedBet = useSelector(getIsAtLeastOneCanBeRemovedCurrentBet);

  const [selectedTab, setSelectedTab] = useState<'unmatched' | 'matched'>('unmatched');
  const [isTabSelectedByUser, setIsTabSelectedByUser] = useState(false);

  const isAtLeastOneCanBeRemovedBetRef = useRef(isAtLeastOneCanBeRemovedBet);

  isAtLeastOneCanBeRemovedBetRef.current = isAtLeastOneCanBeRemovedBet;

  const handleSelectTab = (id: 'unmatched' | 'matched') => {
    if (id !== selectedTab) {
      setSelectedTab(id);

      if (!isTabSelectedByUser) {
        setIsTabSelectedByUser(true);
      }
    }
  };

  useEffect(() => {
    if (
      !isTabSelectedByUser &&
      unmatchedBetsCountWithCancelled === 0 &&
      matchedBetsCount > 0 &&
      !isAtLeastOneCanBeRemovedBetRef.current
    ) {
      setSelectedTab('matched');
    }
  }, [unmatchedBetsCountWithCancelled, matchedBetsCount]);

  useEffect(() => {
    if (!isLoggedIn) {
      setIsTabSelectedByUser(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (selectedTab === 'matched' && isAtLeastOneCanBeRemovedBetRef.current) {
      dispatch(closeAllCurrentBetsCanBeRemoved());
      dispatch(removeAllUnmatchedOffersIdsToShowLapsed());
    }
  }, [selectedTab]);

  const getContent = () => {
    if (isLoggedIn) {
      if (isPNCEnabled && !isGameBetslip) {
        return <OpenedBetsMatched />;
      }

      return (
        <>
          <div className={classNames(styles.tabs, componentsBranding.TAB_CONTAINER)}>
            {tabs.map(({ id, label }) => {
              return (
                <button
                  key={id}
                  className={classNames(styles.tab, componentsBranding.TAB, {
                    [componentsBranding.ACTIVE]: id === selectedTab,
                    [styles.tab__selected]: id === selectedTab,
                    [styles.tab__notSelected]: id !== selectedTab
                  })}
                  onClick={() => handleSelectTab(id)}
                >
                  <span>{t(label)}</span>
                  {id === 'unmatched' ? (
                    <>
                      {unmatchedBetsCount > 0 && (
                        <span
                          className={classNames(
                            styles.tab__count,
                            styles.tab__count__unmatched,
                            betslipBranding.UNMATCHED_COUNTER
                          )}
                        >
                          {unmatchedBetsCount}
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {matchedBetsCount > 0 && (
                        <span
                          className={classNames(
                            styles.tab__count,
                            styles.tab__count__matched,
                            betslipBranding.MATCHED_COUNTER
                          )}
                        >
                          {matchedBetsCount}
                        </span>
                      )}
                    </>
                  )}
                </button>
              );
            })}
          </div>
          {tabsContent[selectedTab]}
        </>
      );
    }

    return <OpenedBetsTabEmpty type="login" />;
  };

  return (
    <div
      className={classNames('skeleton_page_wrapper', styles.openedBetsWrap, {
        [styles.disableScroll]: isLoading
      })}
    >
      {isLoading && !hasPlacementNotifications && <BetslipSkeleton isAbsolute itemsCount={unmatchedBetsCount ?? 4} />}
      {getContent()}
    </div>
  );
};

export default OpenedBetsTab;
