import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import MyBetsHeaderCell from 'components/MyBetsHeaderCell';
import useDevice from 'hooks/useDevice';
import { TPLContentItem } from 'redux/modules/myBets/type';

import ContentItem from './components/ContentItem';
import MobileDescription from './components/MobileDescription';
import MobileProfit from './components/MobileProfit';

import styles from './styles.module.scss';

interface PLTableProps {
  content: TPLContentItem[];
  setRowHighLighted: Dispatch<SetStateAction<number | undefined>>;
  rowHighlighted?: number;
}

const PLTable = ({ content, rowHighlighted, setRowHighLighted }: PLTableProps) => {
  const { t } = useTranslation();

  const { isDesktop, isMobile } = useDevice();

  const highlightRow = (index: number) => {
    setRowHighLighted(index);
  };

  return (
    <>
      {content?.length ? (
        <div className="biab_table-holder">
          {isDesktop ? (
            <div className={classNames('biab_table biab_mybets', styles.table)}>
              <div className={classNames('biab_my-bets-table-header', styles.tableHeader)} role="rowheader">
                <MyBetsHeaderCell className={styles.marketCol} value={t('account.profitandloss.labels.market')} />
                <MyBetsHeaderCell className={styles.middleCol} value={t('account.profitandloss.labels.start')} />
                <MyBetsHeaderCell className={styles.middleCol} value={t('account.profitandloss.labels.settled')} />
                <MyBetsHeaderCell className={styles.plCol} value={t('account.profitandloss.labels.profitandloss')} />
              </div>
              {content.map((el: TPLContentItem) => (
                <ContentItem key={el.marketId + el.profit} item={el} />
              ))}
            </div>
          ) : (
            <>
              <div className={classNames(styles.mobileTableHeader, 'biab_account-table-header')}>
                <span>{t('account.profitandloss.labels.description')}</span>
                <span>{t('account.profitandloss.labels.pl')}</span>
              </div>
              {content.map((el: TPLContentItem, index: number) => (
                <div
                  key={el.marketId + el.profit}
                  onClick={() => highlightRow(index)}
                  className={classNames(styles.mobileAccountItem, { [styles.highlighted]: index === rowHighlighted })}
                >
                  <MobileDescription item={el} />
                  <MobileProfit profit={el.profit} />
                </div>
              ))}
            </>
          )}
        </div>
      ) : (
        <div
          className={classNames(styles.noData, {
            [styles.noData__mobile]: isMobile
          })}
        >
          {t('account.mybets.messages.noData')}
        </div>
      )}
    </>
  );
};

export default PLTable;
