import classNames from 'classnames';

import { IMarket } from 'types/markets';

import styles from '../../styles.module.scss';

interface MobileListHiddenProps {
  markets: IMarket[];
  onClick: (marketId: string) => void;
}

const MobileListHidden = ({ markets, onClick }: MobileListHiddenProps) => {
  return (
    <div
      className={classNames(
        'biab_list-view-tabs-wrapper',
        styles.mobile__listViewWrapper,
        styles.mobile__listViewWrapper__hidden
      )}
    >
      <ul
        className={classNames(
          'biab_list-view-tabs',
          styles.listView,
          styles.mobile__listView,
          styles.mobile__listView__hidden
        )}
      >
        {markets &&
          markets.map(({ marketId, marketName }) => (
            <li key={marketId} className={classNames(styles.listView__tab, styles.mobile__listView__tab)}>
              <span
                className={classNames(styles.listView__tab__links, styles.mobile__listView__tab__links)}
                onClick={() => onClick(marketId)}
              >
                {marketName}
              </span>
              <i className="biab_arrow-icon fa2 fa2-arrow-right" />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default MobileListHidden;
