import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import Image from 'components/Image/Image';
import { SPORT_BASE_URL } from 'constants/locations';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { TRace } from 'redux/modules/racing/type';
import { SportId } from 'types';

import styles from './styles.module.scss';

interface TabsProps {
  tabs: TRace[];
  timeRange: string;
  sportId: string;
}

const getIconCode = (item: TRace, sportId: string) =>
  sportId === SportId.HORSE_RACING ? item.name : item.id.split('_')[0];

const Tabs = ({ tabs, timeRange, sportId }: TabsProps) => {
  const navigate = useNavigate();

  const domainLanguage = useSelector(getTranslation);

  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const activeEvent = tabs.find(item => item.id === activeTab);

  useEffect(() => {
    if (!activeEvent) {
      setActiveTab(tabs[0].id);
    }
  }, [timeRange, tabs]);

  const testedEvents = [...(activeEvent?.events || [])];
  testedEvents.sort((a, b) => a.markets[0].startTime - b.markets[0].startTime);

  return (
    <>
      <div className="biab_horse-racing-page-content">
        <div
          className={classnames(
            'biab_horse-racing-page-groups-list js-groups-list-region',
            styles.racing__tabsContainer
          )}
        >
          {tabs.map(item => (
            <div
              key={item.id}
              onClick={() => setActiveTab(item.id)}
              className={classnames(
                'biab_horse-racing-page-groups-item biab_item-width-6 js-group',
                styles.racing__tab,
                {
                  [`biab_active ${styles.active}`]: activeTab === item.id
                }
              )}
            >
              <Image
                className={styles.racing__svgIcon}
                name={item.translations[domainLanguage]}
                path={`horse-racing/${getIconCode(item, sportId)}.svg`}
              />
              <div>{`${item.name} (${item.events.length})`}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="biab_horse-racing-page-group-content">
        <div className="biab_horse-racing-page-events-list">
          <div className={classnames('js-events-list', styles.racing__contentContainer)}>
            {testedEvents.map(event => (
              <div
                key={event.id}
                className={classnames('biab_horse-racing-page-events-item', styles.racing__containerItem)}
              >
                <div className={classnames('biab_horse-racing-page-event-name', styles.racing__itemName)}>
                  {event.name}
                </div>
                <div
                  className={classnames(
                    'biab_horse-racing-page-races-list js-race-markets-list',
                    styles.racing__itemTimeContainer
                  )}
                >
                  {event.markets.map(market => (
                    <div
                      key={market.id}
                      className={classnames('biab_horse-racing-page-race-item', styles.racing__itemTime)}
                      onClick={() => navigate(`${SPORT_BASE_URL}/${sportId}/market/${market.id}`)}
                    >
                      {
                        <div
                          className={classnames({
                            [styles.racing__inPlay]: market.inPlay,
                            ['biab_horse-racing-indicator']: market.inPlay
                          })}
                        />
                      }
                      {market.timeLabel}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tabs;
