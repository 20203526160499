import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';

import { TPopular, TPopularLinks } from './type';

const initialState: TPopular = {
  links: [],
  loading: false,
  error: null
};

const slice = createSlice({
  name: SLICES_NAMES.POPULAR,
  initialState,
  reducers: {
    fetchPopularLinks: state => {
      state.loading = true;
    },
    successFetchPopularLinks: (state, { payload }: PayloadAction<TPopularLinks[]>) => {
      state.loading = false;
      state.links = payload;
    },
    failureFetchPopularLinks: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export const { failureFetchPopularLinks, fetchPopularLinks, successFetchPopularLinks } = slice.actions;

export default slice.reducer;
