import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

i18n.use(initReactI18next).init({
  lng: 'en',
  maxRetries: 0,
  defaultNS: 'translation',
  debug: false,
  partialBundledLanguages: true,
  interpolation: {
    prefix: '{',
    suffix: '}'
  }
});
export default i18n;
