import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import ResponsibleBettingConfirmLimit from 'components/ResponsibleBetting/components/ResponsibleBettingConfirmLimit';
import ResponsibleBettingScale from 'components/ResponsibleBetting/components/ResponsibleBettingScale';
import commonStyles from 'components/ResponsibleBetting/styles.module.scss';
import { componentsBranding, mobileComponents } from 'constants/branding';
import { MINUTES_IN_AN_HOUR } from 'constants/date';
import useDevice from 'hooks/useDevice';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { cancelResponsibleBettingTimeLimit } from 'redux/modules/responsibleBetting';
import { getResponsibleBettingSettings } from 'redux/modules/responsibleBetting/selectors';
import { ResponsibleBettingTimeUnits, UpdatedResponsibleBettingDisplayedContent } from 'types/responsibleBetting';
import { addDay } from 'utils/date';
import { getTimeUnitKey, parseMinutesToHoursAndMinutes } from 'utils/responsibleBetting';

interface ResponsibleBettingTimeLimitViewProps {
  onEdit: () => void;
  onRemove: () => void;
}

const ResponsibleBettingTimeLimitView = ({ onEdit, onRemove }: ResponsibleBettingTimeLimitViewProps) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const dispatch = useDispatch();

  const rbSettings = useSelector(getResponsibleBettingSettings);

  const {
    newTimeLimit = null,
    timeLimit = 0,
    timeLimitStartDate = null,
    timeLimitEndDate = null,
    timeLimitTimeUnit = null,
    timeLimitSpent = 0,
    timeLimitAvailable = 0,
    newTimeLimitEnabled,
    newTimeLimitStartDate = null,
    newTimeLimitTimeUnit = null
  } = rbSettings?.rgTools || {};

  const newTimeLimitDate = addDay(new Date(timeLimitEndDate ?? 0)).getTime();
  const formattedNewLimitStartDate = useLongDateFormat({
    time: newTimeLimitDate,
    hasYear: true,
    hasTime: false,
    hasComma: true
  });

  const formattedTimeLimitStartDate = useLongDateFormat({
    time: timeLimitStartDate ?? 0,
    hasYear: true,
    hasTime: false,
    hasComma: true
  });

  const formattedNewTimeLimitEndDate = useLongDateFormat({
    time: timeLimitEndDate ?? 0,
    hasYear: true,
    hasTime: false,
    hasComma: true
  });

  const formattedTimeLimitPeriod = `${formattedTimeLimitStartDate} - ${formattedNewTimeLimitEndDate}`;

  const handleOnCancel = () => {
    if (rbSettings) {
      dispatch(cancelResponsibleBettingTimeLimit({ type: UpdatedResponsibleBettingDisplayedContent.TimeLimit }));
    }
  };

  const availableLimitLabel =
    timeLimitAvailable > 0
      ? parseMinutesToHoursAndMinutes(timeLimitAvailable, t)
      : `${t('responsibleBetting.labels.noTime')}`;

  return (
    <div
      className={classNames(commonStyles.lossLimitContainer, {
        [commonStyles.lossLimitContainer__mobile]: isMobile
      })}
    >
      <ResponsibleBettingScale
        availableTimeLimit={timeLimitAvailable}
        currentLimit={timeLimit && timeLimit * MINUTES_IN_AN_HOUR}
        availableLimitValue={availableLimitLabel}
        spentLimit={timeLimitSpent}
        availableLabel={t('responsibleBetting.labels.remaining')}
        shouldDisplayPeriod={timeLimitTimeUnit !== ResponsibleBettingTimeUnits.DAY}
        limitPeriod={formattedTimeLimitPeriod}
        type={UpdatedResponsibleBettingDisplayedContent.TimeLimit}
      />
      <div className={commonStyles.lossLimitControllsContainer}>
        <div className={commonStyles.limitView__container}>
          <div
            className={classNames(commonStyles.limitView__wrapper, {
              [commonStyles.limitView__wrapper__mobile]: isMobile
            })}
          >
            <span
              className={classNames(commonStyles.limitView__label, {
                [commonStyles.limitView__label__mobile]: isMobile
              })}
            >
              {t('responsibleBetting.labels.currentLimit')}:
            </span>
            <span className={commonStyles.limitView__value}>{`${timeLimit} ${t('responsibleBetting.labels.hours')} ${t(
              getTimeUnitKey(timeLimitTimeUnit)
            )}`}</span>
          </div>
          <div className={commonStyles.limitView__controls}>
            {newTimeLimitEnabled || !newTimeLimitStartDate ? (
              <button
                className={classNames(commonStyles.limitView__controlIcon, {
                  [componentsBranding.SECONDARY_BTN]: !isMobile,
                  [mobileComponents.BUTTON]: isMobile,
                  [mobileComponents.SECONDARY]: isMobile
                })}
                onClick={onRemove}
              >
                <i className="fa2 fa2-trash-icon biab_fav-manage-icon" />
              </button>
            ) : null}
            <button
              className={classNames(commonStyles.limitView__controlIcon, {
                [componentsBranding.SECONDARY_BTN]: !isMobile,
                [mobileComponents.BUTTON]: isMobile,
                [mobileComponents.SECONDARY]: isMobile
              })}
              onClick={onEdit}
            >
              <i className="fa2 fa2-edit-icon biab_fav-manage-icon" />
            </button>
          </div>
        </div>
        {newTimeLimitStartDate && (
          <>
            <div className={classNames(commonStyles.divider, commonStyles.limitView__divider)} />
            <ResponsibleBettingConfirmLimit
              isMobile={isMobile}
              onCancel={handleOnCancel}
              confirmationLimitTypeLabel={t('responsibleBetting.labels.newLimit')}
              confirmationLimitTypeIcon={'fa2-success-icon'}
              confirmationLimitTypeValue={
                newTimeLimitEnabled && newTimeLimit
                  ? `${newTimeLimit} ${t('responsibleBetting.labels.hours')} ${t(getTimeUnitKey(newTimeLimitTimeUnit))}`
                  : `${t('responsibleBetting.labels.limitRemoved')}`
              }
              confirmationLimitDateLabel={t('responsibleBetting.labels.starting')}
              confirmationLimitDateValue={formattedNewLimitStartDate}
              operatorsLossLimit={undefined}
              showConfirmationBtn={false}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ResponsibleBettingTimeLimitView;
