import { KeyboardEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useResizeObserver } from 'usehooks-ts';

import { KEY_CODES } from 'constants/betslip';
import { asianViewHeader as branding, asianViewIcons, asianViewMiddleSection } from 'constants/branding';
import { SEARCH_INPUT_MAX_LENGTH } from 'constants/search';
import { PARAMS_ASIAN_SEARCH_KEY } from 'constants/urlParams';
import { getDisplayIconsInSubHeader } from 'redux/modules/appConfigs/selectors';
import { getIsLandscapeAsianView, getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { setSearchExpanded } from 'redux/modules/asianViewSearch';
import { getSearchExpanded } from 'redux/modules/asianViewSearch/selectors';
import { setAsianSearchWidth } from 'redux/modules/headerNavigation';

import styles from './styles.module.scss';

const ASIAN_SEARCH_CLEAR_ID = 'ASIAN_SEARCH_CLEAR_ID';

type AsianSearchProps = {
  observeWidth?: boolean;
};

const AsianSearch = ({ observeWidth = false }: AsianSearchProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);
  const expanded = useSelector(getSearchExpanded);
  const displayIconsInSubHeader = useSelector(getDisplayIconsInSubHeader);

  const displayIcon = displayIconsInSubHeader || isMobileAsianView;

  const firstRender = useRef(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);

  useResizeObserver({
    ref: componentRef,
    box: 'border-box',
    onResize: ({ width }) => {
      dispatch(setAsianSearchWidth(Math.ceil(width ?? 0)));
    }
  });

  const query = searchParams.get(PARAMS_ASIAN_SEARCH_KEY) || '';
  let placeholder = '';

  if (!isMobileAsianView) {
    placeholder = t('asianView.labels.search');
  } else if (expanded) {
    placeholder = t('asianView.labels.placementActions.searchForEvents');
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    handleClose();
    return () => {
      dispatch(setSearchExpanded(false));
    };
  }, [location.pathname]);

  useEffect(() => {
    if (query) {
      dispatch(setSearchExpanded(true));
    }

    return () => {
      dispatch(setAsianSearchWidth(0));
    };
  }, []);

  const handleClose = () => {
    dispatch(setSearchExpanded(false));
    searchParams.delete(PARAMS_ASIAN_SEARCH_KEY);
    setSearchParams(searchParams);
  };

  const handleOpen = () => {
    if (!expanded && inputRef.current) {
      dispatch(setSearchExpanded(true));
      inputRef.current.focus();
    }
  };

  const handleClear = () => {
    searchParams.delete(PARAMS_ASIAN_SEARCH_KEY);
    setSearchParams(searchParams);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleChange = (value: string) => {
    if (value === '') {
      searchParams.delete(PARAMS_ASIAN_SEARCH_KEY);
      setSearchParams(searchParams);
    } else {
      searchParams.set(PARAMS_ASIAN_SEARCH_KEY, value);
      setSearchParams(searchParams);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === KEY_CODES.ESCAPE) {
      handleClose();
      inputRef?.current?.blur();
    }
  };

  return (
    <div
      ref={observeWidth ? componentRef : undefined}
      className={classNames(styles.search__wrapper, { [styles.search__wrapper__mobile]: isMobileAsianView })}
    >
      <div
        onClick={handleOpen}
        className={classNames(styles.search, branding.SEARCH_BOX, {
          [styles.expanded]: expanded,
          'cursor-pointer': !expanded,
          [branding.ACTIVE]: !isMobileAsianView && expanded,
          [asianViewMiddleSection.MOBILE_SEARCH]: isMobileAsianView,
          [asianViewMiddleSection.MOBILE_SUB_HEADER_BTN]: isMobileAsianView && !expanded,
          [asianViewMiddleSection.MOBILE_SEARCH_EXPANDED]: isMobileAsianView && expanded,
          [styles.mobileView]: isMobileAsianView,
          [styles.expandedMobileView]: isMobileAsianView && expanded,
          [styles.expandedMobileEmpty]: isMobileAsianView && expanded && !query
        })}
      >
        {displayIcon && <i className={classNames('fa2 fa2-search', styles.searchIcon, asianViewIcons.SEARCH_ICON)} />}
        {isMobileAsianView && !expanded && isLandscapeAsianView && <span>{t('asianView.labels.search')}</span>}
        {!isMobileAsianView && !expanded && (
          <span
            className={classNames(styles.placeholder, {
              [styles.placeholder__withIcon]: displayIconsInSubHeader && !isMobileAsianView
            })}
          >
            {placeholder}
          </span>
        )}
        <input
          value={query}
          onChange={e => handleChange(e.target.value)}
          ref={inputRef}
          type="text"
          autoComplete="off"
          maxLength={SEARCH_INPUT_MAX_LENGTH}
          placeholder={placeholder}
          className={classNames(asianViewMiddleSection.SEARCH_INPUT, {
            'cursor-pointer': !expanded,
            [styles.input__withIcon]: displayIconsInSubHeader && !isMobileAsianView,
            [styles.input__hidden]: !isMobileAsianView && !expanded
          })}
          onKeyDown={handleKeyDown}
        />
        {expanded && (
          <div className={styles.actions}>
            {query && (
              <span id={ASIAN_SEARCH_CLEAR_ID} className={styles.clear} onClick={handleClear}>
                {t('asianView.labels.search.clear')}
              </span>
            )}
            {!isMobileAsianView && (
              <i
                className={classNames('biab_tour-icon-close', styles.closeIcon, asianViewIcons.SEARCH_ICON)}
                onClick={handleClose}
              />
            )}
          </div>
        )}
      </div>
      {isMobileAsianView && expanded && (
        <i
          className={classNames('biab_tour-icon-close', styles.closeIcon, styles.closeIcon__mobile)}
          onClick={handleClose}
        />
      )}
    </div>
  );
};

export default AsianSearch;
