import styles from './styles.module.scss';

const MatchStatisticSoon = () => {
  return (
    <div className={styles.emptyMatchStatistic__container}>
      <p className={styles.emptyMatchStatistic__text}>Statistics will be displayed here soon…</p>
    </div>
  );
};

export default MatchStatisticSoon;
