import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { IMarketRules } from 'types/markets';

import {
  failureFetchSportPageDetails,
  failureFetchSportPageMarketRules,
  fetchSportPageDetails,
  fetchSportPageMarketRules,
  successFetchSportPageDetails,
  successFetchSportPageMarketRules
} from './index';
import { TSportPageDetailsResponse } from './type';

export function* getSportPageDetails(action: ReturnType<typeof fetchSportPageDetails>) {
  try {
    const response: TSportPageDetailsResponse = yield call(api.sportPage.getDetails, action.payload);
    const concatMarketsObj = action.payload.concatMarkets ? { concatMarkets: action.payload.concatMarkets } : {};

    yield put(successFetchSportPageDetails({ ...response, ...concatMarketsObj }));
  } catch (error: any) {
    yield put(failureFetchSportPageDetails(error.data));
  }
}

export function* getSportPageMarketRules(action: ReturnType<typeof fetchSportPageMarketRules>) {
  try {
    const response: IMarketRules = yield call(api.app.marketRules, action.payload);
    yield put(successFetchSportPageMarketRules({ marketId: action.payload, rules: response }));
  } catch (error: any) {
    yield put(failureFetchSportPageMarketRules(error.data));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchSportPageDetails.type, getSportPageDetails),
    takeEvery(fetchSportPageMarketRules.type, getSportPageMarketRules)
  ]);
}
