import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import {
  AutoCashOut,
  CashOutMarket,
  CashOutQuote,
  CashOutStatuses,
  MarketCashOutResponse,
  TResponseCashOutStatus
} from 'redux/modules/cashOut/type';
import { IMarketRules } from 'types/markets';

import {
  asianBetSlipCashOutRemovePendingCashOut,
  asianBetSlipCreateAutoCashOut,
  deleteAsianBetSlipAutoCashOut,
  failureAsianBetSlipCreateAutoCashOut,
  failureDeleteAsianBetSlipAutoCashOut,
  failureFetchAsianBetSlipAutoCashOutMarkets,
  failureFetchAsianBetSlipCashOutMarket,
  failureFetchAsianBetSlipCashOutMarkets,
  failureFetchAsianBetSlipCashOutQuotes,
  failureFetchAsianViewBetSlipCashOutMarketRules,
  failureFetchAsianViewBetSlipCashOutStatus,
  fetchAsianBetSlipAutoCashOutMarkets,
  fetchAsianBetSlipCashOutMarket,
  fetchAsianBetSlipCashOutMarkets,
  fetchAsianBetSlipCashOutQuotes,
  fetchAsianViewBetSlipCashOutMarketRules,
  fetchAsianViewBetSlipCashOutStatus,
  successAsianBetSlipCreateAutoCashOut,
  successDeleteAsianBetSlipAutoCashOut,
  successFetchAsianBetSlipAutoCashOutMarkets,
  successFetchAsianBetSlipCashOutMarket,
  successFetchAsianBetSlipCashOutMarkets,
  successFetchAsianBetSlipCashOutQuotes,
  successFetchAsianViewBetSlipCashOutMarketRules,
  successFetchAsianViewBetSlipCashOutStatus
} from './index';
import { getAsianViewBetSlipStringifiedAutoCashOutMarkets } from './selectors';

function* fetchAsianBetSlipCashOutQuotesWorker(action: ReturnType<typeof fetchAsianBetSlipCashOutQuotes>) {
  try {
    const response: CashOutQuote[] = yield call(api.cashOut.getCashOut, { ids: action.payload.ids, isAsianView: true });

    yield put(
      successFetchAsianBetSlipCashOutQuotes({
        quotes: response,
        firstLoaded: action.payload.firstLoading,
        isWebSocketResponse: false
      })
    );
  } catch (error: any) {
    yield put(failureFetchAsianBetSlipCashOutQuotes(error.data));
  }
}

function* fetchAsianBetSlipCashOutMarketsWorker(action: ReturnType<typeof fetchAsianBetSlipCashOutMarkets>) {
  try {
    let response: MarketCashOutResponse = yield call(api.cashOut.getMarkets, { ...action.payload, isAsianView: true });

    // This condition needs if page from payload is bigger than last available page (totalPages) in response, so we need to do one more request with valid page
    if (
      action.payload.isPaginationEnabled &&
      action.payload.page > 0 &&
      action.payload.changePage &&
      action.payload.page + 1 > response.markets.totalPages
    ) {
      response = yield call(api.cashOut.getMarkets, {
        ...action.payload,
        page: response.markets.totalPages - 1,
        isAsianView: true
      });
      action.payload.changePage(response.markets.totalPages - 1);
    }

    yield put(
      successFetchAsianBetSlipCashOutMarkets({
        ...response,
        withLoader: action.payload.withLoader,
        isUpdated: !action.payload.isPaginationEnabled
      })
    );
  } catch (error: any) {
    yield put(
      failureFetchAsianBetSlipCashOutMarkets({
        error: error.data,
        withLoader: action.payload.withLoader
      })
    );
  }
}

function* asianBetSlipCreateAutoCashOutWorker(action: ReturnType<typeof asianBetSlipCreateAutoCashOut>) {
  try {
    const response: AutoCashOut = yield call(api.cashOut.createAutoCashOut, action.payload, true);
    yield put(successAsianBetSlipCreateAutoCashOut(response));
  } catch (error: any) {
    yield put(failureAsianBetSlipCreateAutoCashOut(error.data));
  }
}

function* asianBetSlipDeleteAutoCashOutWorker(action: ReturnType<typeof deleteAsianBetSlipAutoCashOut>) {
  try {
    yield call(api.cashOut.deleteAutoCashOut, action.payload.id, true);
    yield put(successDeleteAsianBetSlipAutoCashOut(action.payload.marketId));
  } catch (error: any) {
    yield put(failureDeleteAsianBetSlipAutoCashOut(error));
  }
}

function* asianBetSlipAutoCashOutMarketsWorker(action: ReturnType<typeof fetchAsianBetSlipAutoCashOutMarkets>) {
  try {
    const response: AutoCashOut[] = yield call(api.cashOut.getAutoCashOutMarkets, action.payload, true);
    const prevStringifiedAutoCashOutMarkets: string = yield select(getAsianViewBetSlipStringifiedAutoCashOutMarkets);
    const stringifiedResponse = JSON.stringify(response);

    if (prevStringifiedAutoCashOutMarkets !== stringifiedResponse) {
      yield put(
        successFetchAsianBetSlipAutoCashOutMarkets({
          autoCashOuts: response,
          isWebSocketResponse: false,
          stringifiedAutoCashOuts: stringifiedResponse
        })
      );
    }
  } catch (error: any) {
    yield put(failureFetchAsianBetSlipAutoCashOutMarkets(error.data));
  }
}

function* fetchAsianBetSlipCashOutMarketWorker(action: ReturnType<typeof fetchAsianBetSlipCashOutMarket>) {
  try {
    const response: CashOutMarket = yield call(api.cashOut.setCashOut, action.payload, true);
    if (response.offers?.length && response.id) {
      yield put(successFetchAsianBetSlipCashOutMarket({ ...response, marketId: action.payload.marketId }));
    } else {
      yield put(
        asianBetSlipCashOutRemovePendingCashOut({
          marketId: action.payload.marketId,
          status: response.status
        })
      );
    }
  } catch (error: any) {
    yield put(failureFetchAsianBetSlipCashOutMarket(error.data));
  }
}

function* fetchAsianBetSlipCashOutStatusWorker(action: ReturnType<typeof fetchAsianViewBetSlipCashOutStatus>) {
  try {
    const response: TResponseCashOutStatus = yield call(api.cashOut.getCashOutStatus, action.payload.statusId, true);
    yield put(
      successFetchAsianViewBetSlipCashOutStatus({
        statusId: action.payload.statusId,
        status: response.status,
        marketId: response.marketId
      })
    );

    if (
      (response.status === CashOutStatuses.SUCCESS || response.status === CashOutStatuses.SUCCESS_WITH_BETTER_VALUE) &&
      action.payload.onSuccessStatus
    ) {
      action.payload.onSuccessStatus();
    }
  } catch (error: any) {
    yield put(failureFetchAsianViewBetSlipCashOutStatus({ error: error, statusId: action.payload.statusId }));
  }
}

function* fetchAsianBetSlipCashOutMarketRulesWorker(
  action: ReturnType<typeof fetchAsianViewBetSlipCashOutMarketRules>
) {
  try {
    const response: IMarketRules = yield call(api.asianView.marketRules, action.payload);
    yield put(successFetchAsianViewBetSlipCashOutMarketRules({ marketId: action.payload, rules: response }));
  } catch (error: any) {
    yield put(failureFetchAsianViewBetSlipCashOutMarketRules(error.data));
  }
}

export default function* saga() {
  yield all([
    takeEvery(fetchAsianBetSlipCashOutQuotes.type, fetchAsianBetSlipCashOutQuotesWorker),
    takeEvery(fetchAsianBetSlipCashOutMarkets.type, fetchAsianBetSlipCashOutMarketsWorker),
    takeEvery(asianBetSlipCreateAutoCashOut.type, asianBetSlipCreateAutoCashOutWorker),
    takeEvery(deleteAsianBetSlipAutoCashOut.type, asianBetSlipDeleteAutoCashOutWorker),
    takeEvery(fetchAsianBetSlipAutoCashOutMarkets.type, asianBetSlipAutoCashOutMarketsWorker),
    takeEvery(fetchAsianBetSlipCashOutMarket.type, fetchAsianBetSlipCashOutMarketWorker),
    takeEvery(fetchAsianViewBetSlipCashOutStatus.type, fetchAsianBetSlipCashOutStatusWorker),
    takeLatest(fetchAsianViewBetSlipCashOutMarketRules.type, fetchAsianBetSlipCashOutMarketRulesWorker)
  ]);
}
