import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PostMessageTypes } from 'constants/app';
import useDevice from 'hooks/useDevice';
import { getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { dispatchEvent, sendIframeMessages } from 'utils/iframe';

const PageHeightInjection = () => {
  const iframeEnabled = useSelector(getIsIframeEnabled);
  const headerHeight = useSelector(getElementHeightByName(EElementNames.HEADER_HEIGHT));
  const howToSectionHeight = useSelector(getElementHeightByName(EElementNames.HOW_TO_SECTION_HEIGHT));
  const navigationAllSportItem = useSelector(getElementHeightByName(EElementNames.NAVIGATION_ALL_SPORT_ITEM));
  const contextualHelp = useSelector(getElementHeightByName(EElementNames.CONTEXTUAL_HELP));
  const minNavHeight = useSelector(getElementHeightByName(EElementNames.MIN_NAV_HEIGHT));
  const howToMenuTitleHeight = useSelector(getElementHeightByName(EElementNames.HOW_TO_MENU_TITLE_HEIGHT));
  const favoritesMenuTitleHeight = useSelector(getElementHeightByName(EElementNames.FAVORITES_MENU_TITLE_HEIGHT));
  const favoritesContentHeight = useSelector(getElementHeightByName(EElementNames.FAVORITES_CONTENT_HEIGHT));
  const activeMenuTitleHeight = useSelector(getElementHeightByName(EElementNames.ACTIVE_MENU_TITLE_HEIGHT));
  const activeMarketsContentHeight = useSelector(getElementHeightByName(EElementNames.ACTIVE_MARKETS_CONTENT_HEIGHT));
  const navigationAllSportsContentHeight = useSelector(
    getElementHeightByName(EElementNames.NAVIGATION_ALL_SPORTS_CONTENT_HEIGHT)
  );
  const bannerHeight = useSelector(getElementHeightByName(EElementNames.BANNER));
  const searchInputHeight = useSelector(getElementHeightByName(EElementNames.SEARCH_INPUT_HEIGHT));
  const contentHeightFromStore = useSelector(getElementHeightByName(EElementNames.CONTENT_HEIGHT));
  const footerHeight = useSelector(getElementHeightByName(EElementNames.FOOTER_HEIGHT));
  const mobileFooterNavHeight = useSelector(getElementHeightByName(EElementNames.MOBILE_FOOTER_NAV_HEIGHT));
  const mobileSwipeMenuHeight = useSelector(getElementHeightByName(EElementNames.MOBILE_SWIPE_MENU_HEIGHT));
  const popularMarketsHeight = useSelector(getElementHeightByName(EElementNames.POPULAR_MARKETS_HEIGHT));
  const mobilePageHeight = useSelector(getElementHeightByName(EElementNames.MOBILE_PAGE_HEIGHT));
  const mobileHeaderHeight = useSelector(getElementHeightByName(EElementNames.MOBILE_HEADER_HEIGHT));
  const sidebarHeight = useSelector(getElementHeightByName(EElementNames.SIDEBAR_HEIGHT));

  const { isMobile } = useDevice();

  const navigationHeight =
    headerHeight +
    minNavHeight +
    contextualHelp +
    howToMenuTitleHeight +
    howToSectionHeight +
    favoritesMenuTitleHeight +
    favoritesContentHeight +
    activeMenuTitleHeight +
    activeMarketsContentHeight +
    navigationAllSportItem +
    navigationAllSportsContentHeight;
  const contentHeight =
    headerHeight +
    bannerHeight +
    searchInputHeight +
    contentHeightFromStore +
    footerHeight +
    mobileFooterNavHeight +
    mobileSwipeMenuHeight;

  const pageHeight = Math.max(
    navigationHeight,
    contentHeight,
    headerHeight + bannerHeight + searchInputHeight + popularMarketsHeight + footerHeight,
    isMobile ? mobilePageHeight : 0,
    isMobile ? contentHeight + mobileHeaderHeight : 0,
    isMobile ? popularMarketsHeight + navigationHeight + mobileHeaderHeight + footerHeight + mobileFooterNavHeight : 0,
    sidebarHeight + headerHeight
  );

  useEffect(() => {
    if (iframeEnabled) {
      sendIframeMessages({ type: PostMessageTypes.HEIGHT_CHANGE, height: pageHeight });
    } else {
      dispatchEvent({ type: PostMessageTypes.HEIGHT_CHANGE, height: pageHeight });
    }
    // console.log('postMessage', { type: PostMessageTypes.HEIGHT_CHANGE, height: pageHeight });
  }, [pageHeight, iframeEnabled]);

  return null;
};

export default PageHeightInjection;
