export const POKER_STAR = 'ps';
export const AB = 'ab';
export const DEFAULT = 'default';
export const NONE = 'none';
export const DEFAULT_SPORT_ICON = 'defaultSport';
export const CLASSIC_NAV_CLASSES = 'classicNavigation';
export const SPORT_ICON = 'sportIcon';
export const FA_ICON = 'fontAwesomeIcon';
export const IN_PLAY = '/inplay';
export const CASH_OUT = '/cashout';
export const HOW_TO = '/how-to';
export const FAVOURITE = '/favourites';
export const ASIAN_VIEW = '/asian-view';

export const HOME_PAGE = 'Home';
export const SPORTS_PAGE = 'Sports';
export const CASH_OUT_PAGE = 'Cash Out';
export const MY_BETS_PAGE = 'My Bets';
export const SEARCH_PAGE = 'Search';
export const GAMES_PAGE = 'Games';
export const ACCOUNT_PAGE = 'Account';

export const CDN_IMAGE_FEED_PATH = 'http://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/';
