import { combineReducers } from 'redux';
import appReducer from 'redux/modules/appConfigs';
import { AppConfigs } from 'redux/modules/appConfigs/type';
import appSettingsReducer from 'redux/modules/appSettings';
import { AppSettings } from 'redux/modules/appSettings/type';
import asianSportsNavigationReducer from 'redux/modules/asianSportsNavigation';
import { IAsianSports } from 'redux/modules/asianSportsNavigation/type';
import asianViewReducer from 'redux/modules/asianView';
import { TAsianView } from 'redux/modules/asianView/type';
import asianViewBetslipReducer from 'redux/modules/asianViewBetslip';
import { TAsianViewBetslipState } from 'redux/modules/asianViewBetslip/type';
import asianViewBetSlipCashOutReducer from 'redux/modules/asianViewBetSlipCashOut';
import { AsianViewBetSlipCashOutState } from 'redux/modules/asianViewBetSlipCashOut/type';
import asianViewCashOutReducer from 'redux/modules/asianViewCashOut';
import { AsianViewCashOutState } from 'redux/modules/asianViewCashOut/type';
import asianViewCashOutCounterReducer from 'redux/modules/asianViewCashOutCounter';
import { CashOutCounterState } from 'redux/modules/asianViewCashOutCounter/type';
import asianViewCurrentBetsReducer from 'redux/modules/asianViewCurrentBets';
import { TAsianCurrentBetsState } from 'redux/modules/asianViewCurrentBets/type';
import asianViewFavouritesReducer from 'redux/modules/asianViewFavourites';
import { AsianViewFavouritesState } from 'redux/modules/asianViewFavourites/type';
import asianViewLeaguesFilterReducer from 'redux/modules/asianViewLeaguesFilter';
import { TAsianViewLeaguesFilterState } from 'redux/modules/asianViewLeaguesFilter/type';
import asianViewMobileBetslipReducer from 'redux/modules/asianViewMobileBetslip';
import { TAsianViewMobileBetslipState } from 'redux/modules/asianViewMobileBetslip/type';
import asianViewPopularLinksReducer from 'redux/modules/asianViewPopularLinks';
import { AsianViewPopularLinksState } from 'redux/modules/asianViewPopularLinks/type';
import asianViewQuickBettingReducer from 'redux/modules/asianViewQuickBetting';
import { TAsianViewQuickBettingState } from 'redux/modules/asianViewQuickBetting/type';
import asianViewSearchReducer from 'redux/modules/asianViewSearch';
import { TAsianViewSearchState } from 'redux/modules/asianViewSearch/type';
import asianViewWidgetsReducer from 'redux/modules/asianViewWidgets';
import { TAsianViewWidgetsState } from 'redux/modules/asianViewWidgets/type';
import authReducer from 'redux/modules/auth';
import { TAuth } from 'redux/modules/auth/type';
import bannersReducer from 'redux/modules/banners';
import { TBanners } from 'redux/modules/banners/type';
import betslipReducer from 'redux/modules/betslip';
import { TBetslipState } from 'redux/modules/betslip/type';
import betsStatusesReducer from 'redux/modules/betsStatuses';
import { TBetsStatuses } from 'redux/modules/betsStatuses/type';
import cancelActionsReducer from 'redux/modules/cancelActions';
import { TCancelActionsState } from 'redux/modules/cancelActions/type';
import cashOutReducer from 'redux/modules/cashOut';
import { TCashOutInitValue } from 'redux/modules/cashOut/type';
import collapseReducer from 'redux/modules/collapse';
import { TCollapse } from 'redux/modules/collapse/type';
import competitionReducer from 'redux/modules/competitions';
import { TCompetition } from 'redux/modules/competitions/type';
import couponSettingsReducer from 'redux/modules/couponSettings';
import { TCouponSettings } from 'redux/modules/couponSettings/type';
import currentBetsReducer from 'redux/modules/currentBets';
import { TCurrentBetsState } from 'redux/modules/currentBets/type';
import eventDetailsReducer from 'redux/modules/event';
import { TEvent } from 'redux/modules/event/type';
import eventCashOutReducer from 'redux/modules/eventCashOut';
import { EventCashOutState } from 'redux/modules/eventCashOut/type';
import fancyMarketReducer from 'redux/modules/fancyMarket';
import { TFancyMarket } from 'redux/modules/fancyMarket/type';
import favoritesReducer from 'redux/modules/favorites';
import { TFavorites } from 'redux/modules/favorites/type';
import featuredMarketsReducer from 'redux/modules/featuredMarkets';
import { TFeaturedMarkets } from 'redux/modules/featuredMarkets/type';
import gamesReducer from 'redux/modules/games';
import { TGames } from 'redux/modules/games/type';
import headerNavigationReducer from 'redux/modules/headerNavigation';
import { THeaderNavigation } from 'redux/modules/headerNavigation/type';
import homeReducer from 'redux/modules/home';
import { THome } from 'redux/modules/home/type';
import inlinePlacementReducer from 'redux/modules/inlinePlacement';
import { TInlinePlacementState } from 'redux/modules/inlinePlacement/type';
import inPlayReducer from 'redux/modules/inPlay';
import { TInPlay } from 'redux/modules/inPlay/type';
import listViewTabsReducer from 'redux/modules/listViewTabs';
import { TListView } from 'redux/modules/listViewTabs/type';
import marketReducer from 'redux/modules/market';
import { TMarket } from 'redux/modules/market/type';
import marketsPricesReducer from 'redux/modules/marketsPrices';
import { TMarketsPrices } from 'redux/modules/marketsPrices/type';
import metaReducer from 'redux/modules/meta';
import { TMeta } from 'redux/modules/meta/type';
import multiMarketReducer from 'redux/modules/multiMarket';
import { TMultiMarket } from 'redux/modules/multiMarket/type';
import myBetsReducer from 'redux/modules/myBets';
import { MyBets } from 'redux/modules/myBets/type';
import backendPageReducer from 'redux/modules/pages';
import { TPage } from 'redux/modules/pages/types';
import placementReducer from 'redux/modules/placement';
import { TPlacedBetsState } from 'redux/modules/placement/type';
import plTableReducer from 'redux/modules/plTable';
import { TPLTable } from 'redux/modules/plTable/type';
import pokerstarReducer from 'redux/modules/pokerstar';
import { TPokerstars } from 'redux/modules/pokerstar/types';
import popularReducer from 'redux/modules/popular';
import { TPopular } from 'redux/modules/popular/type';
import popularMarketsReducer from 'redux/modules/popularMarket';
import { TPopularMarkets } from 'redux/modules/popularMarket/type';
import popupReducer from 'redux/modules/popup';
import { TPopup } from 'redux/modules/popup/type';
import profitLossTablePopUpReducer from 'redux/modules/profitLossTablePopUp';
import { ProfitLossTablePopUpState } from 'redux/modules/profitLossTablePopUp/type';
import nextRacesReducer from 'redux/modules/races';
import { TNextRaces } from 'redux/modules/races/type';
import racingReducer from 'redux/modules/racing';
import { TRaces } from 'redux/modules/racing/type';
import resbonsibleBettingReducer from 'redux/modules/responsibleBetting';
import { TResponsibleBettingState } from 'redux/modules/responsibleBetting/type';
import searchReducer from 'redux/modules/search';
import { TSearches } from 'redux/modules/search/type';
import sportPageReducer from 'redux/modules/sportPage';
import { TSportPage } from 'redux/modules/sportPage/type';
import sportsReducer from 'redux/modules/sports';
import { TSports } from 'redux/modules/sports/type';
import tooltipReducer from 'redux/modules/tooltip';
import { TTooltip } from 'redux/modules/tooltip/type';
import top5ViewReducer from 'redux/modules/top5View';
import { TTop5View } from 'redux/modules/top5View/type';
import toursReducer from 'redux/modules/tours';
import { TTours } from 'redux/modules/tours/type';
import userReducer from 'redux/modules/user';
import { TUser } from 'redux/modules/user/type';
import videoStreamReducer from 'redux/modules/videoStream';
import { TVideoStream } from 'redux/modules/videoStream/type';
import webSocketReducer from 'redux/modules/webSocket';
import { WebSocketInitState } from 'redux/modules/webSocket/type';
import whatIfReducer from 'redux/modules/whatIf';
import { TWhatIf } from 'redux/modules/whatIf/type';

export interface AppState {
  auth: TAuth;
  asianView: TAsianView;
  asianViewBetslip: TAsianViewBetslipState;
  asianViewLeaguesFilter: TAsianViewLeaguesFilterState;
  asianViewFavourites: AsianViewFavouritesState;
  asianViewCashOut: AsianViewCashOutState;
  asianViewCashOutCounter: CashOutCounterState;
  asianViewCurrentBets: TAsianCurrentBetsState;
  asianViewMobileBetslip: TAsianViewMobileBetslipState;
  asianViewPopularLinks: AsianViewPopularLinksState;
  asianViewQuickBetting: TAsianViewQuickBettingState;
  asianViewBetSlipCashOut: AsianViewBetSlipCashOutState;
  asianViewWidgets: TAsianViewWidgetsState;
  eventCashOut: EventCashOutState;
  user: TUser;
  appConfigs: AppConfigs;
  sports: TSports;
  games: TGames;
  collapse: TCollapse;
  home: THome;
  nextRaces: TNextRaces;
  competitions: TCompetition;
  search: TSearches;
  popularMarkets: TPopularMarkets;
  racing: TRaces;
  profitLossTablePopUp: ProfitLossTablePopUpState;
  placement: TPlacedBetsState;
  currentBets: TCurrentBetsState;
  inlinePlacement: TInlinePlacementState;
  inPlay: TInPlay;
  betslip: TBetslipState;
  betsStatuses: TBetsStatuses;
  featuredMarkets: TFeaturedMarkets;
  myBets: MyBets;
  appSettings: AppSettings;
  event: TEvent;
  market: TMarket;
  sportPage: TSportPage;
  listViewTabs: TListView;
  fancyMarket: TFancyMarket;
  top5View: TTop5View;
  popular: TPopular;
  cancelActions: TCancelActionsState;
  multiMarket: TMultiMarket;
  plTable: TPLTable;
  tooltip: TTooltip;
  favorites: TFavorites;
  tours: TTours;
  responsibleBetting: TResponsibleBettingState;
  cashOut: TCashOutInitValue;
  videoStream: TVideoStream;
  meta: TMeta;
  backendPage: TPage;
  pokerstar: TPokerstars;
  banners: TBanners;
  popUp: TPopup;
  marketsPrices: TMarketsPrices;
  whatIf: TWhatIf;
  asianSportsNavigation: IAsianSports;
  asianViewSearch: TAsianViewSearchState;
  headerNavigation: THeaderNavigation;
  couponSettings: TCouponSettings;
  webSocket: WebSocketInitState;
}

export const rootReducer = combineReducers<AppState>({
  appConfigs: appReducer,
  appSettings: appSettingsReducer,
  asianSportsNavigation: asianSportsNavigationReducer,
  asianView: asianViewReducer,
  asianViewCashOut: asianViewCashOutReducer,
  asianViewCurrentBets: asianViewCurrentBetsReducer,
  asianViewBetslip: asianViewBetslipReducer,
  asianViewBetSlipCashOut: asianViewBetSlipCashOutReducer,
  asianViewLeaguesFilter: asianViewLeaguesFilterReducer,
  asianViewMobileBetslip: asianViewMobileBetslipReducer,
  asianViewFavourites: asianViewFavouritesReducer,
  asianViewCashOutCounter: asianViewCashOutCounterReducer,
  asianViewPopularLinks: asianViewPopularLinksReducer,
  asianViewQuickBetting: asianViewQuickBettingReducer,
  asianViewWidgets: asianViewWidgetsReducer,
  eventCashOut: eventCashOutReducer,
  auth: authReducer,
  banners: bannersReducer,
  betslip: betslipReducer,
  betsStatuses: betsStatusesReducer,
  cancelActions: cancelActionsReducer,
  cashOut: cashOutReducer,
  collapse: collapseReducer,
  competitions: competitionReducer,
  user: userReducer,
  sports: sportsReducer,
  games: gamesReducer,
  search: searchReducer,
  currentBets: currentBetsReducer,
  event: eventDetailsReducer,
  fancyMarket: fancyMarketReducer,
  favorites: favoritesReducer,
  featuredMarkets: featuredMarketsReducer,
  home: homeReducer,
  inlinePlacement: inlinePlacementReducer,
  inPlay: inPlayReducer,
  listViewTabs: listViewTabsReducer,
  market: marketReducer,
  marketsPrices: marketsPricesReducer,
  meta: metaReducer,
  multiMarket: multiMarketReducer,
  myBets: myBetsReducer,
  placement: placementReducer,
  profitLossTablePopUp: profitLossTablePopUpReducer,
  plTable: plTableReducer,
  pokerstar: pokerstarReducer,
  popular: popularReducer,
  racing: racingReducer,
  responsibleBetting: resbonsibleBettingReducer,
  nextRaces: nextRacesReducer,
  popularMarkets: popularMarketsReducer,
  sportPage: sportPageReducer,
  backendPage: backendPageReducer,
  popUp: popupReducer,
  tooltip: tooltipReducer,
  top5View: top5ViewReducer,
  tours: toursReducer,
  videoStream: videoStreamReducer,
  whatIf: whatIfReducer,
  asianViewSearch: asianViewSearchReducer,
  headerNavigation: headerNavigationReducer,
  couponSettings: couponSettingsReducer,
  webSocket: webSocketReducer
});
