import { useSelector } from 'react-redux';
import classNames from 'classnames';

import GameCartsDerbyRacing from 'components/GameCartsDerbyRacing';
import GameCartsTableBlackjack from 'components/GameCartsTableBlackjack';
import GameCartsTableHiLo from 'components/GameCartsTableHiLo';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getCurrentGameData } from 'redux/modules/games/selectors';
import { EGameTypes } from 'redux/modules/games/type';

import Baccarat from './components/Baccarat';
import PokerAndOmahaGames from './components/PokerAndOmahaGames';

import styles from './styles.module.scss';

const GameSection = () => {
  const currentGameData = useSelector(getCurrentGameData);
  const device = useSelector(getAppDevice);

  const isMobile = device === Devices.MOBILE;

  const getGameSectionView = () => {
    const gameType = currentGameData?.gameType;

    if (gameType) {
      switch (gameType) {
        case EGameTypes.BACCARAT:
          return <Baccarat gameData={currentGameData.gameData} />;
        case EGameTypes.POKER:
          return <PokerAndOmahaGames game={currentGameData} />;
        case EGameTypes.OMAHA_POKER:
          return <PokerAndOmahaGames game={currentGameData} />;
        case EGameTypes.BLACKJACK:
          return <GameCartsTableBlackjack gameData={currentGameData.gameData} />;
        case EGameTypes.HILO:
          return <GameCartsTableHiLo game={currentGameData.gameData[0]} />;
        case EGameTypes.CARD_RACER:
          return <GameCartsDerbyRacing gameData={currentGameData.gameData} />;
        default:
          return null;
      }
    }
  };

  return (
    <div className={classNames('biab_games-wrapper', styles.gamesWrapper)}>
      <div
        className={classNames(styles.cardsSection, {
          [styles.autoWidth]: currentGameData?.gameType === EGameTypes.CARD_RACER,
          [styles.derbyMobileWrapper]: currentGameData?.gameType === EGameTypes.CARD_RACER && isMobile
        })}
      >
        {getGameSectionView()}
      </div>
    </div>
  );
};

export default GameSection;
