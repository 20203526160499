import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import useDevice from 'hooks/useDevice';
import { setAsianBetSlipCashOutPossibleProfit } from 'redux/modules/asianViewBetSlipCashOut';
import { getAsianBetSlipCashOutPossibleProfitByMarketId } from 'redux/modules/asianViewBetSlipCashOut/selectors';
import { setAsianViewCashOutPossibleProfit } from 'redux/modules/asianViewCashOut';
import { getAsianViewCashOutPossibleProfitByMarketId } from 'redux/modules/asianViewCashOut/selectors';
import { setPossibleProfit } from 'redux/modules/cashOut';
import { getAutoCashOutProfitDataByMarketId } from 'redux/modules/cashOut/selectors';
import { ProfitPayload } from 'redux/modules/cashOut/type';
import { setEventCashOutPossibleProfit } from 'redux/modules/eventCashOut';
import { getEventCashOutPossibleProfitByMarketId } from 'redux/modules/eventCashOut/selectors';

import styles from './styles.module.scss';

interface IWarning {
  marketId: string;
  isAsianBetSlip?: boolean;
  isEvent?: boolean;
  isAVMobileCashOut?: boolean;
  isAsianView?: boolean;
}

const Warning = ({
  marketId,
  isAsianBetSlip = false,
  isEvent = false,
  isAVMobileCashOut = false,
  isAsianView = false
}: IWarning) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const profitSelector = isAsianBetSlip
    ? getAsianBetSlipCashOutPossibleProfitByMarketId
    : isEvent
    ? getEventCashOutPossibleProfitByMarketId
    : isAsianView
    ? getAsianViewCashOutPossibleProfitByMarketId
    : getAutoCashOutProfitDataByMarketId;

  const autoCashOutProfitData = useSelector(profitSelector(marketId));

  const { isMobile } = useDevice();

  const onCloseWarning = () => {
    const payload: ProfitPayload = { marketId, hideError: true };

    if (isAsianBetSlip) {
      dispatch(setAsianBetSlipCashOutPossibleProfit(payload));
    } else if (isEvent) {
      dispatch(setEventCashOutPossibleProfit(payload));
    } else if (isAsianView) {
      dispatch(setAsianViewCashOutPossibleProfit(payload));
    } else {
      dispatch(setPossibleProfit(payload));
    }
  };

  return autoCashOutProfitData?.error && !autoCashOutProfitData?.hideError ? (
    <div
      className={classNames('biab_cash-out-button', {
        [styles.containerBetSlip]: isAsianBetSlip,
        [styles.containerMobCOPage]: isAVMobileCashOut
      })}
    >
      <div
        className={classNames('biab_cash-out-message biab_warning', styles.warning, {
          'biab_alert-bet-msg biab_error-msg biab_cash-out-error-message': isMobile,
          [styles.warning__betSlip_coMobPage]: isAsianBetSlip || isAVMobileCashOut
        })}
      >
        {t('cashout.page.labels.maxProfitNotification')}
        <i onClick={onCloseWarning} className={classNames('fa fa-times biab_close-icon', styles.close)} />
      </div>
    </div>
  ) : null;
};

export default Warning;
