import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';
import { IMarket } from 'types/markets';

import { TPopularMarkets } from './type';

const initialState: TPopularMarkets = {
  markets: [],
  loading: false,
  error: null
};

const slice = createSlice({
  name: SLICES_NAMES.POPULAR_MARKETS,
  initialState,
  reducers: {
    fetchPopularMarkets: state => {
      state.loading = true;
    },
    successGetPopularMarkets: (state, action: PayloadAction<IMarket[]>) => {
      state.markets = action.payload;
      state.loading = false;
    },
    failurePopularMarkets: (state, action: PayloadAction<TFailureActionPayload>) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { fetchPopularMarkets, successGetPopularMarkets, failurePopularMarkets } = slice.actions;

export default slice.reducer;
