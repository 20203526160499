import { useSelector } from 'react-redux';
import classnames from 'classnames';

import CellBox from 'components/GameMarketTable/partials/CellBox';
import { PricesCellsType } from 'constants/games';
import { getCurrentGameIsLayOddsDisabled } from 'redux/modules/games/selectors';
import { TAvailablePrice } from 'redux/modules/games/type';
import { generateArray } from 'utils/general';

import styles from '../../styles.module.scss';

interface IPricesCells {
  selectionId: number;
  type: PricesCellsType;
  prices: TAvailablePrice[];
  name: string;
  isFirstSelection?: boolean;
}

const PricesCells = ({ selectionId, type, prices, name, isFirstSelection }: IPricesCells) => {
  const isDisabledLayOdds = useSelector(getCurrentGameIsLayOddsDisabled(true));

  const skeleton = generateArray(3);

  if (!prices || (isDisabledLayOdds && type === PricesCellsType.LAY)) {
    return null;
  }

  return (
    <div
      className={classnames({
        [styles.backPrices]: type === PricesCellsType.BACK,
        [styles.layPrices]: type === PricesCellsType.LAY,
        [styles.withoutLay]: isDisabledLayOdds
      })}
    >
      {skeleton.map((num, index) => {
        return (
          <CellBox
            isFirstSelection={isFirstSelection}
            type={type}
            selectionId={selectionId}
            name={name}
            index={index}
            key={index}
            value={prices[index]?.value}
            unmatched={prices[index]?.amountUnmatched}
          />
        );
      })}
    </div>
  );
};

export default PricesCells;
