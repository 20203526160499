import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { IconsConfig } from 'constants/iconsConfig';
import { getLoggedInLoading, getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchNextRaces } from 'redux/modules/races';
import { getNextRaces } from 'redux/modules/races/selectors';
import { SportId } from 'types';

import NextRace from './components/NextRace/NextRace';
import NextRacesIntervalRequestInjection from './components/NextRacesIntervalRequestInjection/NextRacesIntervalRequestInjection';

import styles from './styles.module.scss';

const NextRaces = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const races = useSelector(getNextRaces);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const loading = useSelector(getLoggedInLoading);

  useEffect(() => {
    if (!loading) {
      dispatch(fetchNextRaces());
    }
  }, [loading, isLoggedIn, dispatch]);

  if (!races.length) {
    return null;
  }

  return (
    <div className={styles.nextRacesWrapper}>
      <NextRacesIntervalRequestInjection />
      <div className={classNames('biab_next-race', styles.nextRacesWrapper__title)}>
        <i className={IconsConfig.SPORT_ICONS[SportId.HORSE_RACING]} />
        <span>{t('nextRace.labels.nextRace')}</span>
      </div>
      <div className={styles.nextRacesList}>
        {races.map(race => (
          <NextRace race={race} key={race.eventId + race.marketId} racesCount={races?.length} />
        ))}
      </div>
    </div>
  );
};

export default NextRaces;
