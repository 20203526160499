import Skeleton from 'components/Skeleton';

import styles from './styles.module.scss';

const SkeletonBetslip = () => {
  return (
    <div className={styles.skeletonBetslip}>
      <Skeleton className={styles.skeletonBetslip__firstLine} />
      <Skeleton className={styles.skeletonBetslip__secondLine} />

      <div className={styles.skeletonBetslip__lineWrapper}>
        <Skeleton className={styles.skeletonBetslip__thirsLine} />
        <Skeleton className={styles.skeletonBetslip__squery} />
      </div>
    </div>
  );
};

export default SkeletonBetslip;
