import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { failureGetAppSettings, fetchAppSettings, successAppSettings } from './index';
import { TAppSettingsResponse } from './type';

export function* getAppSettingsWatcher(action: ReturnType<typeof fetchAppSettings>) {
  try {
    const response: TAppSettingsResponse[] = yield call(api.settings.getSettings, action.payload);

    yield put(successAppSettings(response));
  } catch (error: any) {
    yield put(failureGetAppSettings(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchAppSettings.type, getAppSettingsWatcher)]);
}
