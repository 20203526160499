import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { VIDEO_STREAMING } from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';

import styles from '../../VideoStream/styles.module.scss';

interface IVideoIcon {
  onClick?: () => void;
  isLeftSpace?: boolean;
}
const VideoIcon = ({ onClick = () => {}, isLeftSpace }: IVideoIcon) => {
  const { t } = useTranslation();
  const { translationKey, isEnabled } = useTooltip(VIDEO_STREAMING);
  return (
    <span
      data-tooltip-id={'tooltip'}
      data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
      className={classNames(styles.videoStream__tooltip, {
        'cursor-help': isEnabled,
        'cursor-pointer': !isEnabled,
        [styles.leftSpace]: isLeftSpace
      })}
      onClick={onClick}
    >
      <i
        onClick={onClick}
        className={classNames('biab_video-streaming-icon fa2 fa2-youtube-play', styles.videoStream__icon)}
      >
        <span className={classNames('path1', 'biab_icon-bg')} />
        <span className="path2" />
      </i>
    </span>
  );
};

export default VideoIcon;
