import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { getAsianViewLayBettingEnabled } from 'redux/modules/appConfigs/selectors';
import { getIsUserAccountSettings, getUserAsianViewLayBetting } from 'redux/modules/user/selectors';
import { CookieNames } from 'types';
import { getBooleanValue } from 'utils';

const useAsianLayBetting = () => {
  const isEnabled = useSelector(getAsianViewLayBettingEnabled);
  const isAsianViewLayBetting = useSelector(getUserAsianViewLayBetting);
  const isAccountSettings = useSelector(getIsUserAccountSettings);

  const [cookies] = useCookies([CookieNames.ASIAN_VIEW_LAY_BETTING]);

  const isChecked = getBooleanValue(cookies.ASIAN_VIEW_LAY_BETTING);

  return isAccountSettings ? isAsianViewLayBetting && isEnabled : isChecked;
};

export default useAsianLayBetting;
