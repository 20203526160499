import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import useConfirmBets from 'hooks/useConfirmBets';
import { useInlineSelectedBetView, useIsDisabledInlineSelectedBet } from 'hooks/useInlinePlacement';
import { updateInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { Actions, ButtonActionTypes } from 'types/inlinePlacement';
import { getBetIdentityData, getInlinePlacedOfferStatuses } from 'utils/inlinePlacement';

import InlineBetButton from '../InlineBetButton';

import styles from './styles.module.scss';

type TInlineBetActionsProps = {
  bet: TInlineSelectedBet;
};
const InlineBetActions = ({ bet }: TInlineBetActionsProps) => {
  const dispatch = useDispatch();

  const { isConfirmBetsBeforePlacement } = useConfirmBets();
  const { isShortView } = useInlineSelectedBetView(bet);
  const isDisabled = useIsDisabledInlineSelectedBet(bet);

  const isSelectState = bet.action === Actions.SELECT;
  const isConfirmState = bet.action === Actions.CONFIRM;
  const isPlacedState = bet.action === Actions.PLACED;
  const isEditState = bet.action === Actions.EDIT;

  const { isUnmatched } = getInlinePlacedOfferStatuses(bet.placedBet);

  const isPlacedUnmatched = isPlacedState && isUnmatched;

  const betIdData = getBetIdentityData(bet);

  const onPlaceBtnClick = () => {
    dispatch(
      updateInlineSelectedBet({
        ...betIdData,
        action: isConfirmBetsBeforePlacement ? Actions.CONFIRM : isPlacedUnmatched ? Actions.UPDATE : Actions.PLACE
      })
    );
  };

  const onConfirmBtnClick = () => {
    dispatch(updateInlineSelectedBet({ ...betIdData, action: isUnmatched ? Actions.UPDATE : Actions.PLACE }));
  };

  const onEditBtnClick = () => {
    dispatch(updateInlineSelectedBet({ ...betIdData, action: isUnmatched ? Actions.EDIT : Actions.SELECT }));
  };

  const onTakeOfferBtnClick = () => {
    dispatch(updateInlineSelectedBet({ ...betIdData, action: Actions.TAKE }));
  };

  const onEditUnmatchedBtnClick = () => {
    dispatch(updateInlineSelectedBet({ ...betIdData, action: Actions.EDIT }));
  };

  const onCancelUnmatchedBtnClick = () => {
    dispatch(updateInlineSelectedBet({ ...betIdData, action: Actions.CANCEL }));
  };

  const onUpdateUnmatchedBtnClick = () => {
    dispatch(
      updateInlineSelectedBet({ ...betIdData, action: isConfirmBetsBeforePlacement ? Actions.CONFIRM : Actions.UPDATE })
    );
  };

  return (
    <div className={classNames(styles.wrap, { [styles.wrap__shortView]: isShortView })}>
      {isSelectState && (
        <InlineBetButton bet={bet} buttonType={ButtonActionTypes.PLACE} onClickCallback={onPlaceBtnClick} />
      )}
      {isConfirmState && (
        <>
          <InlineBetButton bet={bet} buttonType={ButtonActionTypes.CONFIRM} onClickCallback={onConfirmBtnClick} />
          {!isDisabled && !isShortView && (
            <InlineBetButton bet={bet} buttonType={ButtonActionTypes.EDIT} onClickCallback={onEditBtnClick} />
          )}
        </>
      )}
      {isEditState && (
        <InlineBetButton bet={bet} buttonType={ButtonActionTypes.UPDATE} onClickCallback={onUpdateUnmatchedBtnClick} />
      )}
      {isPlacedUnmatched && (
        <>
          <InlineBetButton
            bet={bet}
            buttonType={ButtonActionTypes.CANCEL_UNMATCHED}
            onClickCallback={onCancelUnmatchedBtnClick}
          />
          <InlineBetButton bet={bet} buttonType={ButtonActionTypes.TAKE_OFFER} onClickCallback={onTakeOfferBtnClick} />
          <InlineBetButton
            bet={bet}
            buttonType={ButtonActionTypes.EDIT_UNMATCHED}
            onClickCallback={onEditUnmatchedBtnClick}
          />
        </>
      )}
    </div>
  );
};

export default InlineBetActions;
