import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import InternetSpeedMeter from 'components/InternetSpeedMeter';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import {
  getDesktopSettingsConnectionChecks,
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled
} from 'redux/modules/appConfigs/selectors';
import { getAVBalance, getBalance, getUserInfo } from 'redux/modules/user/selectors';

import styles from './styles.module.scss';

export default function UserInfo() {
  const { t } = useTranslation();

  const connectionChecks = useSelector(getDesktopSettingsConnectionChecks);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const userInfo = useSelector(getUserInfo);
  const balance = useSelector(getBalance);
  const avBalance = useSelector(getAVBalance);

  const { noFormattedAmount: userBalance } = useFormatCurrency(balance, '', {
    noRounding: true,
    isCheckIndian: true
  });

  const { noFormattedAmount: userAVBalance } = useFormatCurrency(avBalance, '', {
    noRounding: true,
    isCheckIndian: true
  });

  const hasSportsBalance = isExchangeGamesEnabled || isExchangeSportsEnabled;
  const isSingleBalance = !(avBalance && isAsianViewEnabled && hasSportsBalance);
  const balanceLabel =
    avBalance && isAsianViewEnabled ? t('account.labels.balance.sportsGames') : t('account.statement.labels.balance');
  const avBalanceLabel = !isSingleBalance
    ? t('account.labels.balance.asianView')
    : t('account.statement.labels.balance');

  return (
    <div className={classNames('biab_login-form', styles.userInfo)}>
      <div
        className={classNames(styles.usernameAndStatus, {
          [styles.usernameAndStatus__singleBalance]: isSingleBalance
        })}
      >
        <p className="biab_username">{userInfo?.username}</p>
        {connectionChecks && <InternetSpeedMeter />}
      </div>
      {!!avBalance && isAsianViewEnabled && (
        <p className={classNames('biab_balance userBalance', styles.balance)}>
          <span
            className={classNames({
              [styles.balance__single]: isSingleBalance,
              [styles.balance__label]: !isSingleBalance
            })}
          >
            {avBalanceLabel}:
          </span>
          <span>{userAVBalance}</span>
        </p>
      )}
      {balance && hasSportsBalance && (
        <p className={classNames('biab_balance userBalance', styles.balance)}>
          <span
            className={classNames({
              [styles.balance__single]: isSingleBalance,
              [styles.balance__label]: !isSingleBalance
            })}
          >
            {balanceLabel}:
          </span>
          <span>{userBalance}</span>
        </p>
      )}
    </div>
  );
}
