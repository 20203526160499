import { useLocation } from 'react-router-dom';

import {
  ASIAN_BASE_URL,
  ASIAN_MOBILE_BET_LIST_URL,
  ASIAN_MOBILE_FAVOURITES_URL,
  ASIAN_VIEW_CASH_OUT_URL
} from 'constants/locations';

const useAsianView = () => {
  const location = useLocation();
  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);
  const isCashOutUrl = location.pathname?.includes(ASIAN_VIEW_CASH_OUT_URL);
  const isAsianBetList = location.pathname?.includes(ASIAN_MOBILE_BET_LIST_URL);
  const isAsianFavouritesPage = location.pathname?.includes(ASIAN_MOBILE_FAVOURITES_URL);

  return { isAsianViewPage, isCashOutUrl, isAsianBetList, isAsianFavouritesPage };
};

export default useAsianView;
