import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RACE_UPDATE_INTERVAL } from 'constants/app';
import { getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { fetchNextRaces } from 'redux/modules/races';
import { getIsRacesLoading } from 'redux/modules/races/selectors';

const NextRacesIntervalRequestInjection = () => {
  const dispatch = useDispatch();

  const racingLoading = useSelector(getIsRacesLoading);
  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);

  const racingLoadingRef = useRef(racingLoading);
  racingLoadingRef.current = racingLoading;

  useEffect(() => {
    const raceInterval = setInterval(() => {
      if (!racingLoadingRef.current) {
        dispatch(fetchNextRaces());
      }
    }, RACE_UPDATE_INTERVAL);

    return () => clearInterval(raceInterval);
  }, [language, timezone]);

  return null;
};

export default NextRacesIntervalRequestInjection;
