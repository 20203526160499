import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Loader, { CircleColors } from 'components/Loader';
import Modal from 'components/Modal';
import Rules from 'components/Rules';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { fetchFancyMarketRules } from 'redux/modules/fancyMarket';
import { getFancyMarketData } from 'redux/modules/fancyMarket/selectors';
import { CookieNames, PageBlocks } from 'types';
import { IMarket } from 'types/markets';
import { getEnvironmentRootPath } from 'utils/navigation';

import FancyMarketItem from '../FancyMarketItem';
import ViewAllLines from '../ViewAllLines';

import styles from './styles.module.scss';

interface FancyMarketSectionProps {
  /**
   * Is multi market view enabled or not
   */
  isMultiMarketView?: boolean;

  /**
   * Section collapse title
   */
  title: string;

  /**
   * List of markets
   */
  markets: IMarket[];

  /**
   * Market start date for rules modal
   */
  startDate?: number;

  /**
   * Determines how much markets should be displayed by default (without clicking 'View all Lines')
   */
  defaultDisplayedCount?: number;
  /**
   * Full width for section
   */
  sectionFullWidth?: boolean;
  /**
   * Is twoColumnView turned on on multi market page
   */
  twoColumnView?: boolean;
  /**
   * Whether lay column is disabled
   */
  isLayColumnDisabled: boolean;
  /**
   * Whether market depth is enabled
   */
  isDepthEnabled: boolean;
  /**
   * Hide rules and collapse icons from collapse row
   */
  hideRulesAndCollapseAbility?: boolean;
  /**
   * Page block for betting
   */
  pageBlock: PageBlocks;
}

const FancyMarketSection = ({
  isMultiMarketView,
  title,
  markets,
  startDate,
  defaultDisplayedCount = 1,
  sectionFullWidth = false,
  twoColumnView = false,
  isLayColumnDisabled,
  isDepthEnabled,
  hideRulesAndCollapseAbility = false,
  pageBlock
}: FancyMarketSectionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([CookieNames.FANCY_SECTIONS_COLLAPSED]);

  const { rulesLoading, rules } = useSelector(getFancyMarketData);
  const device = useSelector(getAppDevice);

  const lastMarket = markets[markets.length - 1];
  const collapsedList: string[] = cookies.BIAB_FANCY_SECTIONS_COLLAPSED?.split(',') ?? [];
  const collapsedTitleName = title.replaceAll(' ', '_').toUpperCase();
  const isNotCollapsed = !collapsedList.includes(collapsedTitleName);

  const marketTime = useLongDateFormat({
    time: startDate ?? 0,
    hasYear: true,
    divider: ', '
  });

  const [openRulesModal, setOpenRulesModal] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(!isNotCollapsed);
  const [showAllLines, setShowAllLines] = useState(false);

  const isMobile = device === Devices.MOBILE;
  const displayedMarkets = showAllLines ? markets : markets.slice(0, defaultDisplayedCount + 1);

  const handleCollapse = () => {
    let newList: string[];

    if (isNotCollapsed) {
      newList = [...collapsedList, collapsedTitleName];
    } else {
      newList = collapsedList.filter((item: string) => item !== collapsedTitleName);
    }

    setCookie(CookieNames.FANCY_SECTIONS_COLLAPSED, newList.join(), { path: '/' });
    setIsCollapsed(prevState => !prevState);
  };

  const handleFetchMarketRules = () => {
    setOpenRulesModal(true);
    if (!rules?.[lastMarket.marketId]?.rules) {
      dispatch(fetchFancyMarketRules(lastMarket.marketId));
    }
  };

  return (
    <div
      className={classNames(styles.fancyMarketSection, { [styles.fancyMarketSection__fullWidth]: sectionFullWidth })}
    >
      <div
        className={classNames(
          'biab_fancy-markets-sections-title biab_fancy-batsman-title',
          styles.fancyMarketSection__header
        )}
      >
        <span>{title}</span>
        {!hideRulesAndCollapseAbility && (
          <div className={styles.fancyMarketSection__icons}>
            {!isMobile ? (
              <>
                <span onClick={handleFetchMarketRules}>
                  <Rules isHideText />
                </span>
                <i
                  onClick={handleCollapse}
                  className={classNames('biab_collapse-toggler fa2', styles.fancyMarketSection__arrow, {
                    'fa2-arrow-up': !isCollapsed,
                    'fa2-arrow-down': isCollapsed
                  })}
                />
              </>
            ) : (
              <i
                className={classNames('fa2 fa2-info-square', styles.fancyMarketSection__mobileInfoIcon)}
                onClick={handleFetchMarketRules}
              >
                <span className="path1" />
                <span className="path2" />
              </i>
            )}
          </div>
        )}
      </div>
      {!hideRulesAndCollapseAbility && (
        <Modal
          clickableBackground
          open={openRulesModal}
          onClose={() => setOpenRulesModal(false)}
          title={
            rulesLoading
              ? t('marketRules.loading')
              : `${rules?.[lastMarket.marketId]?.marketName || ''} - ${t('market.rules')}`
          }
        >
          {rulesLoading ? (
            <Loader circleColor={CircleColors.BLACK} />
          ) : (
            <>
              {t('marketRules.eventStartTime')}:
              <br />
              {marketTime}
              <br />
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    rules?.[lastMarket.marketId]?.rules.replace(/CONTEXT_ROOT_PATH/g, getEnvironmentRootPath()) ?? ''
                }}
              />
            </>
          )}
        </Modal>
      )}
      {(isMobile || !isCollapsed || hideRulesAndCollapseAbility) && (
        <>
          {displayedMarkets.map((market, index) => (
            <FancyMarketItem
              isMultiMarketView={isMultiMarketView}
              key={market.marketId}
              name={market.marketName}
              market={market}
              isLastItem={index === displayedMarkets.length - 1}
              isFirstItem={index === 0}
              noHeader={isMobile}
              hasMarketDepth={isMobile}
              sectionFullWidth={sectionFullWidth}
              twoColumnView={twoColumnView}
              isLayColumnDisabled={isLayColumnDisabled}
              isDepthEnabled={isDepthEnabled}
              pageBlock={pageBlock}
            />
          ))}
          {markets.length > defaultDisplayedCount && (
            <ViewAllLines showAllLines={showAllLines} setShowAllLines={setShowAllLines} />
          )}
        </>
      )}
    </div>
  );
};

export default FancyMarketSection;
