import classNames from 'classnames';

import { TGameData } from 'redux/modules/games/type';
import { generateArray } from 'utils/general';

import styles from './styles.module.scss';

interface ISegmentLine {
  lineClassName?: string;
  data: TGameData;
}

const SegmentLine = ({ lineClassName, data }: ISegmentLine) => {
  const segments = generateArray(32);
  const [segment] = data.properties;
  const currentIndex = parseInt(segment?.value);

  return (
    <div className={classNames(styles.main, lineClassName)}>
      {segments.map((s, index) => (
        <div key={`${s}-${index}`} className={'biab_segment'}>
          <div
            className={classNames(styles.segment, {
              [`biab_past-segment ${styles.pastSegment}`]: currentIndex && currentIndex > index + 1,
              biab_current: currentIndex === index + 1
            })}
          />
        </div>
      ))}
    </div>
  );
};

export default SegmentLine;
