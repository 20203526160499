import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

import { getTranslation } from '../appConfigs/selectors';

const getCompetitionsAndCountries = (state: AppState) => state.competitions;
export const getEventData = ({ competitions }: AppState) => competitions.eventData;
export const getEventDetails = ({ competitions }: AppState) => competitions.eventDetails;
export const getEventDataLoading = ({ competitions }: AppState) => competitions.eventLoading;
export const getCompetitionsLoader = ({ competitions }: AppState) => competitions.loading;
export const getEventParents = ({ competitions }: AppState) => competitions.eventParents;
export const getCompetitionsNameTranslations = ({ competitions }: AppState) => competitions.competitionNameTranslations;
export const getSportData = ({ competitions }: AppState) => competitions.sportData;
export const getIsAllSoccer = ({ competitions }: AppState) => competitions.isAllSoccer;
export const getSportNameTranslations = ({ competitions }: AppState) => competitions.sportNameTranslations;
export const getSportNameHighlighted = ({ competitions }: AppState) => competitions.sportNameHighlighted;
export const getSportParents = ({ competitions }: AppState) => competitions.sportParents;
export const getSportMore = ({ competitions }: AppState) => competitions.sportMore;
export const getSportLoading = ({ competitions }: AppState) => competitions.sportLoading;
export const getRaceLoading = ({ competitions }: AppState) => competitions.raceLoading;
export const getSportTopCountries = ({ competitions }: AppState) => competitions.sportTopCountries;
export const getSportTopCompetitions = ({ competitions }: AppState) => competitions.sportTopCompetitions;
export const getSportAvailableFilters = ({ competitions }: AppState) => competitions.sportAvailableFilters;
export const getGroupName = ({ competitions }: AppState) => competitions.groupName;
export const getGroupParents = ({ competitions }: AppState) => competitions.groupParents;
export const getCompetitions = ({ competitions }: AppState) => competitions.competitions;
export const getTodayCardPageLoading = ({ competitions }: AppState) => competitions.todayCardPageLoading;
export const getGroupLoading = ({ competitions }: AppState) => competitions.groupLoading;
export const getMarketLoading = ({ competitions }: AppState) => competitions.marketLoading;
export const getCompetitionLoading = ({ competitions }: AppState) => competitions.competitionLoading;
export const getCountryLoading = ({ competitions }: AppState) => competitions.countryLoading;
export const getEventNameTranslations = ({ competitions }: AppState) => competitions.eventNameTranslations;
export const getEventNameHighlighted = ({ competitions }: AppState) => competitions.eventNameHighlighted;
export const getRace = ({ competitions }: AppState) => competitions.race;
export const getCompetitionMarket = ({ competitions }: AppState) => competitions.market;
export const getCompetitionMarketParents = ({ competitions }: AppState) => competitions.marketParents;
export const getCompetitionData = ({ competitions }: AppState) => competitions.competitionData;
export const getCompetitionParents = ({ competitions }: AppState) => competitions.competitionParents;
export const getCompetitionNameHighlighted = ({ competitions }: AppState) => competitions.competitionNameHighlighted;
export const getCompetitionDetails = ({ competitions }: AppState) => competitions.competitionDetails;
export const getCountryParents = ({ competitions }: AppState) => competitions.countryParents;
export const getCountryData = ({ competitions }: AppState) => competitions.countryData;
export const getCountryNameTranslations = ({ competitions }: AppState) => competitions.countryNameTranslations;
export const getCountryNameHighlighted = ({ competitions }: AppState) => competitions.countryNameHighlighted;
export const getGroupData = ({ competitions }: AppState) => competitions.groupData;
export const getGroupNameTranslations = ({ competitions }: AppState) => competitions.groupNameTranslations;
export const getGroupNameHighlighted = ({ competitions }: AppState) => competitions.groupNameHighlighted;
export const getEventTabs = createSelector(
  getCompetitionsAndCountries,
  getTranslation,
  ({ eventData = [] }, translation) => {
    return eventData.map(({ id, translations }) => ({ id, name: translations[translation] }));
  }
);
