import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import LoginForm from 'components/forms/LoginForm';
import Localization from 'components/Localization/Localization';
import MobileAccountNavigation from 'components/MobileAccountNavigation';
import Timezone from 'components/Timezone/Timezone';
import useElementSize from 'hooks/useElementSize';
import { getMobileLangDropdownNoAuth, getMobileSettingsLoginHeaderButtons } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';

import styles from './styles.module.scss';

const MobileLogin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoginHeaderButtons = useSelector(getMobileSettingsLoginHeaderButtons);
  const showDropdown = useSelector(getMobileLangDropdownNoAuth);

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <>
      <MobileAccountNavigation hasDropdownMenu={false} hasBalance={false} />
      <div ref={componentRef} className="biab_login-container">
        <div className={classNames(styles.mobileLoginItem, 'biab_timezone-container')}>
          <label>{t('timezone.label')}</label>
          <Timezone />
        </div>
        {showDropdown && (
          <div className={classNames(styles.mobileLoginItem, 'biab_locale-container')}>
            <label>{t('locale.label')}</label>
            <Localization title />
          </div>
        )}
        {isLoginHeaderButtons && (
          <div className={styles.mobileLoginFormContainer}>
            <LoginForm />
          </div>
        )}
      </div>
    </>
  );
};

export default MobileLogin;
