import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import GameDerbyRacingCartItem from 'components/GameDerbyRacingCartItem';
import GameDerbyRacingFirstSection from 'components/GameDerbyRacingFirstSection';
import GameResultsPlayersStatusesTable from 'components/GameResultsPlayersStatusesTable';
import PlayerBox from 'components/GamesDerbyRacingResultsContent/partials/PlayerBox';
import GamesResultsPagination from 'components/GamesResultsPagination';
import { MyBetsLocations } from 'constants/myBets';
import {
  getIsAmericanDateFormatEnabled,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { getSelectedIndex } from 'redux/modules/games/selectors';
import { THistory } from 'redux/modules/games/type';
import { timeStampToStartDate } from 'utils/date';
import { dropToChunks } from 'utils/games';

import styles from './styles.module.scss';

interface IGamesDerbyRacingResultsContent {
  resultsHistory: THistory;
}

const GamesDerbyRacingResultsContent = ({ resultsHistory }: IGamesDerbyRacingResultsContent) => {
  const { t } = useTranslation();
  const location = useLocation();

  const selectedIndex = useSelector(getSelectedIndex);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);

  const currentItem = resultsHistory.content[selectedIndex];
  const [cards, spades, hearts, clubs, diamonds] = currentItem.gameData;
  const raceCards = cards.properties.find(s => s.name === 'Used')?.value?.split(',') || [];
  const chunks = dropToChunks(raceCards, 3);
  const startedParsedDate = timeStampToStartDate(
    currentItem.startDate,
    americanDateFormatEnabled,
    timezoneCookieEnabled,
    timezone
  );
  const isMyBetPage = location.pathname.includes(MyBetsLocations.MyBets);

  return (
    <>
      <div className={styles.resultFirstSection}>
        <div className={styles.startDate}>
          {t('games.results.started')}: {startedParsedDate}
        </div>
        <GameDerbyRacingFirstSection data={cards} />
        <p className={styles.raceTitle}>{t('games.results.raceCards')}</p>
        <div className={styles.rounds}>
          {chunks.map((chunk, index) => (
            <div className={styles.round} key={index}>
              <div className={styles.roundCards}>
                {chunk.map(v => (
                  <GameDerbyRacingCartItem key={v} value={v} />
                ))}
              </div>
              {`${t('games.round')} ${index + 1}`}
            </div>
          ))}
        </div>
        <div className={styles.playerBoxes}>
          <PlayerBox item={spades} />
          <PlayerBox item={hearts} />
          <PlayerBox item={clubs} />
          <PlayerBox item={diamonds} />
        </div>
      </div>
      <div>
        {!isMyBetPage && (
          <>
            <GamesResultsPagination history={resultsHistory} />
            <hr />
          </>
        )}

        <GameResultsPlayersStatusesTable markets={currentItem.markets} />
      </div>
    </>
  );
};

export default GamesDerbyRacingResultsContent;
