import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import GameResultsPlayersStatusesTable from 'components/GameResultsPlayersStatusesTable';
import Baccarat from 'components/GameSection/components/Baccarat';
import GamesResultsPagination from 'components/GamesResultsPagination';
import { MyBetsLocations } from 'constants/myBets';
import { getSelectedIndex } from 'redux/modules/games/selectors';
import { THistory } from 'redux/modules/games/type';

import styles from './styles.module.scss';

interface IGamesBaccaratResultsContent {
  resultsHistory: THistory;
}

const GamesBaccaratResultsContent = ({ resultsHistory }: IGamesBaccaratResultsContent) => {
  const selectedIndex = useSelector(getSelectedIndex);
  const currentItem = resultsHistory.content[selectedIndex];
  const location = useLocation();
  const isMyBetPage = location.pathname.includes(MyBetsLocations.MyBets);

  return (
    <>
      <Baccarat gameData={currentItem.gameData} startDate={currentItem.startDate} />
      {!isMyBetPage && (
        <>
          <GamesResultsPagination history={resultsHistory} />
          <hr />
        </>
      )}

      <div className={classNames({ [styles.resultsTablesWrapper]: !isMyBetPage })}>
        <GameResultsPlayersStatusesTable markets={currentItem.markets} />
      </div>
    </>
  );
};

export default GamesBaccaratResultsContent;
