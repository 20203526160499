import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useCashOutProgressBar from 'hooks/useCashOutProgressBar';

import styles from './styles.module.scss';

type AsianViewCashOutProgressBarProps = {
  className?: string;
};

const AsianViewCashOutProgressBar = ({ className = '' }: AsianViewCashOutProgressBarProps) => {
  const { t } = useTranslation();

  const { width } = useCashOutProgressBar();

  return (
    <div className={classNames(styles.progressBar, className)}>
      <div className={styles.progressBar__fill} style={{ width: `${width}%` }} />
      <p className={styles.progressBar__text}>{t('cashout.statuses.PENDING')}</p>
    </div>
  );
};

export default AsianViewCashOutProgressBar;
