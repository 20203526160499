import Skeleton from 'components/Skeleton';

import styles from './styles.module.scss';

const ResponsibleBettingSkeleton = () => (
  <div className={styles.skeletonBetslip}>
    <Skeleton className={styles.skeletonBetslip__firstLine} />
    <Skeleton className={styles.skeletonBetslip__secondLine} />
    <Skeleton className={styles.skeletonBetslip__thirdLine} />
    <Skeleton className={styles.skeletonBetslip__fourthLine} />
    <Skeleton className={styles.skeletonBetslip__fifthLine} />
  </div>
);

export default ResponsibleBettingSkeleton;
