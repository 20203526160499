import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { failureGetNextRaces, fetchNextRaces, successGetNextRaces } from './index';
import { TNextRace } from './type';

function* getNextRacesWorker() {
  try {
    const response: TNextRace[] = yield call(api.races.getNextRaces);
    yield put(successGetNextRaces(response));
  } catch (error: any) {
    yield put(failureGetNextRaces(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchNextRaces.type, getNextRacesWorker)]);
}
