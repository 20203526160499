import { NavigationItemType } from '../constants/app';

export interface TAccountNavigation {
  link: string;
  title: string;
  isShowInfo: boolean;
  code: string;
  infoText?: string;
  type?: NavigationItemType;
}

export enum SettingsDisplayedContent {
  QUICK_BETS = 'QUICK_BETS',
  PREFERENCES = 'PREFERENCES',
  DEFAULT_STAKE = 'DEFAULT_STAKE',
  NO_ITEMS = 'NO_ITEMS'
}

export enum SettingsBettingInlinePlacementType {
  BETSLIP = 'BETSLIP',
  MIDDLE_SECTION = 'MIDDLE_SECTION',
  DEFAULT = 'DEFAULT'
}

export enum SettingsBettingCheckbox {
  CONFIRM_BETS_FOR_PLACEMENT = 'confirmBetsBeforePlacement',
  PLACE_BET_WITH_ENTER_KEY = 'placeBetWithEnterKey',
  DEFAULT_STAKE = 'defaultStake',
  AUTO_REFRESH = 'asianViewAutoRefresh',
  FILL_OR_KILL = 'fillOrKillEnabled'
}

export type DefaultStakeState = {
  value: string;
  error: string;
  index: number;
  key: 'first' | 'second' | 'third';
  defaultValue: boolean;
  isFocused: boolean;
};

export type DefaultStakeStates = {
  first: DefaultStakeState;
  second: DefaultStakeState;
  third: DefaultStakeState;
};

export enum NavigationId {
  FANCY = '#date_Fancy Markets',
  MORE = '#date_More'
}
