import { useEffect, useRef, useState } from 'react';

type Params = {
  options?: {
    root?: Element | Document | null;
    rootMargin?: string;
    threshold?: number | number[];
  };
};

export function useIsVisible<T extends Element>(params?: Params) {
  const [isIntersecting, setIntersecting] = useState(false);

  const ref = useRef<T>(null);

  const options = {
    root: params?.options?.root ?? null,
    rootMargin: params?.options?.rootMargin ?? '0%',
    threshold: params?.options?.threshold ?? 0
  };

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), options);
    const element = ref.current;

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return { ref, isIntersecting };
}
