import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useResizeObserver } from 'usehooks-ts';

import DesktopNavigationMyAccount from 'components/HeaderNavigation/components/DesktopNavigation/components/DesktopNavigationMyAccount';
import DesktopNavigationSettings from 'components/HeaderNavigation/components/DesktopNavigation/components/DesktopNavigationSettings';
import DesktopNavigationItem from 'components/HeaderNavigation/components/DesktopNavigationItem';
import { ACCOUNT_BASE_URL, ASIAN_BASE_URL, CUSTOM_PAGES_BASE_URL, GAMES_BASE_URL } from 'constants/locations';
import { ACCOUNT_NAVIGATION_SIDEBAR, RESPONSIBLE_BETTING_MENU_ITEM } from 'constants/navigation';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { getIsResponsibleBettingMenuEnabled, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getSearchExpanded } from 'redux/modules/asianViewSearch/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setRightMenuWidth } from 'redux/modules/headerNavigation';

import styles from './styles.module.scss';

const HeaderAccountNavigation = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    headerNavigation,
    loginHeaderButtons,
    fillOrKill,
    quickstakeBetslip,
    inlineBetting,
    defStake,
    confirmBetsBeforePlace,
    placeBetWithEnterKey
  } = useDeviceSettings();

  const expanded = useSelector(getSearchExpanded);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const showResponsibleBetting = useSelector(getIsResponsibleBettingMenuEnabled);

  const headerRightNavigation = headerNavigation.filter(link => link.placeOnTheRight);
  const isAsianPage = location.pathname.includes(ASIAN_BASE_URL);
  const isAccountPage = location.pathname.includes(ACCOUNT_BASE_URL);
  const isCustomPage = location.pathname.includes(CUSTOM_PAGES_BASE_URL);

  const accountNavigation = showResponsibleBetting
    ? [...ACCOUNT_NAVIGATION_SIDEBAR(isPNCEnabled), RESPONSIBLE_BETTING_MENU_ITEM]
    : ACCOUNT_NAVIGATION_SIDEBAR(isPNCEnabled);

  const componentRef = useRef<HTMLDivElement>(null);
  useResizeObserver({
    ref: componentRef,
    box: 'border-box',
    onResize: ({ width }) => {
      dispatch(setRightMenuWidth(Math.ceil(width ?? 0)));
    }
  });

  const isSettingsEnabled =
    (isPNCEnabled ||
      quickstakeBetslip ||
      fillOrKill ||
      inlineBetting ||
      placeBetWithEnterKey ||
      confirmBetsBeforePlace ||
      defStake) &&
    !isAccountPage;
  const displayContent = headerRightNavigation.length || isLoggedIn;

  return (
    <div
      ref={componentRef}
      className={classNames(styles.headerAccountNavigation, {
        [styles.noPadding]: expanded,
        hidden: !displayContent
      })}
    >
      {displayContent && (
        <>
          <div className={styles.headerAccountNavigation__content} id="biab_headerAccountNavigation">
            {!!headerRightNavigation.length && (!location.pathname.includes(GAMES_BASE_URL) || isAccountPage) && (
              <nav className={classNames('biab_navigation-links', styles.headerAccountNavigation__links)}>
                {headerRightNavigation.map(navigationType => (
                  <DesktopNavigationItem key={navigationType.orderNumber} navigationType={navigationType} />
                ))}
              </nav>
            )}
            {isLoggedIn && (
              <div className={styles.headerAccountNavigation__links}>
                <DesktopNavigationMyAccount
                  rightIcon="biab_navigation-submenu-icon fa2 fa2-person"
                  title="header.myAccount"
                  data={accountNavigation}
                  isShowLogout={loginHeaderButtons}
                  isSettingsEnabled={isSettingsEnabled}
                />
                {!isAsianPage && !isCustomPage && isSettingsEnabled && <DesktopNavigationSettings />}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderAccountNavigation;
