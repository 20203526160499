import { extend, groupBy, map, sortBy } from 'lodash';

import { CHANNELS, GAMES_TITLES, GROUPED_SORT_ORDER } from 'constants/games';
import { GAMES_BASE_URL } from 'constants/locations';
import {
  TAvailablePrice,
  TCurrentGameData,
  TCurrentGameMarket,
  TGame,
  TGameList,
  TGameNavigation,
  TSelection,
  TSerializedGames
} from 'redux/modules/games/type';

const groupGamesByKey = (games: TGame[]) => {
  return groupBy(games, game => game.gameType);
};

const sortGamesByOrder = (groupedGames: TSerializedGames[]): TSerializedGames[] => {
  return sortBy(groupedGames, 'order');
};

const sortGamesListByOrder = (groupedGames: TGameList[]): TGameList[] => {
  return sortBy(groupedGames, 'order');
};

export const findWhere = (games: TGameList[], isTurbo: boolean) => {
  return games.find(game => {
    if (game.isTurbo === isTurbo) {
      return game;
    }
  });
};

export const serializeGamesData = (gamesList: TGame[]): TSerializedGames[] => {
  const groupedGames = groupGamesByKey(gamesList);

  const serializedGroupGames: TSerializedGames[] = map(groupedGames, (game, key) => ({
    gameType: key,
    name: GAMES_TITLES[key],
    order: GROUPED_SORT_ORDER[key] || 999,
    list: sortGamesListByOrder(game.map(item => extend(CHANNELS[item.channelId] || {}, item)))
  }));

  return sortGamesByOrder(serializedGroupGames);
};

export const getGamesNavigationLinks = (groupedGames: TSerializedGames[]): TGameNavigation[] => {
  return groupedGames.map(game => {
    const firstGame = game.list[0];
    const turboGame = findWhere(game.list, true);
    const nonTurboGame = findWhere(game.list, false);
    let name = firstGame.name;
    let gameId = firstGame.channelId;

    if (turboGame) {
      gameId = turboGame.channelId;
    }

    if (nonTurboGame) {
      name = nonTurboGame.name;
    }

    return {
      name,
      id: gameId,
      isActive: false,
      gameType: game.gameType,
      url: `${GAMES_BASE_URL}/${gameId}`
    };
  });
};

/**
 * Map market game data to common market data structure
 */
export const mapMarketGameData = (game: TCurrentGameData | null, gameMarket: TCurrentGameMarket | null) =>
  gameMarket
    ? {
        ...gameMarket,
        gameName: game?.name ?? '',
        gameType: game?.gameType ?? '',
        round: game?.round ?? 0,
        marketId: String(gameMarket.id),
        description: {
          marketType: gameMarket?.marketType ?? '',
          bettingType: '',
          eachWayDivisor: null,
          lineRangeInfo: null,
          priceLadderDescription: null
        },
        eventType: { id: String(gameMarket.gameId) },
        runners: gameMarket.selections.map(mapGameMarketSelections) || []
      }
    : null;

/**
 * Map market game prices data structure
 * @param game
 * @param gameMarket
 */
export const mapMarketGamePrices = (game: TCurrentGameData | null, gameMarket: TCurrentGameMarket | null) =>
  gameMarket
    ? {
        ...gameMarket,
        marketDefinition: {
          marketType: gameMarket?.marketType ?? '',
          runners: gameMarket.selections.map(mapGameMarketSelections) || [],
          status: gameMarket.marketStatus
        },
        rc:
          gameMarket.selections.map(selection => {
            return {
              id: selection.id,
              bdatb: selection.bestAvailableToBackPrices.map(mapGameMarketSelectionPrices),
              bdatl: selection.bestAvailableToLayPrices
                ? selection.bestAvailableToLayPrices.map(mapGameMarketSelectionPrices)
                : []
            };
          }) || []
      }
    : null;

const mapGameMarketSelections = (selection: TSelection) => {
  return { selectionId: selection.id, runnerName: selection.name, handicap: 0, status: selection.status };
};

const mapGameMarketSelectionPrices = (prices: TAvailablePrice, index: number) => {
  return { index: index, odds: prices.value, amount: prices.amountUnmatched };
};

export const arrayRange = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

export const dropToChunks = (array: string[], chunkLength: number) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkLength) {
    result.push(array.slice(i, i + chunkLength));
  }
  return result;
};
