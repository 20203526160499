import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useFormatCurrency } from 'hooks/useFormatCurrency';

import styles from '../../styles.module.scss';

interface CashOutButtonProps {
  liability: number;
  currency: string;
  className?: string;
  isAsianEventTab?: boolean;
  ignoreFlexibleDisplayFormat?: boolean;
}

const Liability = ({
  liability,
  currency,
  className = '',
  isAsianEventTab = false,
  ignoreFlexibleDisplayFormat
}: CashOutButtonProps) => {
  const { t } = useTranslation();

  const { formattedAmount: liabilityValue } = useFormatCurrency(liability, currency, {
    noRounding: true,
    ignoreFlexibleDisplayFormat
  });

  return (
    <div className="biab_cash-out-liability-wrapper">
      <div className={classNames(styles.liability, className)}>
        <p>
          {t('cashout.page.button.liability')}
          {isAsianEventTab ? ':' : ''}
        </p>
        <p className={styles.liability__value}>{liability ? liabilityValue : '--'}</p>
      </div>
    </div>
  );
};

export default Liability;
