import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';

import { TAsianViewSearchState } from './type';

const initialState: TAsianViewSearchState = {
  expanded: false
};

const slice = createSlice({
  name: SLICES_NAMES.ASIAN_VIEW_SEARCH,
  initialState,
  reducers: {
    setSearchExpanded: (state, action: PayloadAction<boolean>) => {
      state.expanded = action.payload;
    }
  }
});

export const { setSearchExpanded } = slice.actions;

export default slice.reducer;
