import { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import Modal from 'components/Modal';
import { VIDEO_STREAMING } from 'constants/tooltip';
import useDevice from 'hooks/useDevice';
import useTooltip from 'hooks/useTooltip';
import { useAppThunkDispatch } from 'redux/configureStore';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchVideoStreamUrls } from 'redux/modules/videoStream';
import { EventWidgetTab } from 'types';

import styles from './styles.module.scss';

interface VideoStreamProps {
  /**
   * Whether component in specific Event header
   */
  isInHeader?: boolean;
  /**
   * In some places (ex. MarketsTableRow) it should still be rendered (ex. because right space will be different for
   * matched cell), but should be hidden by styles
   */
  visible: boolean;
  /**
   * If need extra space from left (ex. mobile Featured Markets)
   */
  isLeftSpace?: boolean;
  /**
   * Event id to make request for playerUrl (url for new window on Desktop) and streamUrl (url for mobile player)
   */
  eventId: string;
  /**
   * Handler only for icon without button wrapper
   */
  onClickIcon?: () => void;
  /**
   * Add 'Live' label next to icon (ex. Search page)
   */
  isLiveLabel?: boolean;
  hideLabel?: boolean;
  openedWidgetTab?: EventWidgetTab;
  setOpenedWidgetTab?: Dispatch<SetStateAction<EventWidgetTab>>;
  hideCollapseIcon?: boolean;
  buttonClassName?: string;
  iconClassName?: string;
  tooltipClassName?: string;
  bgClassName?: string;
}

const VideoStream = ({
  isInHeader = false,
  visible,
  isLeftSpace = false,
  onClickIcon,
  isLiveLabel = false,
  eventId,
  openedWidgetTab,
  setOpenedWidgetTab,
  hideLabel = false,
  hideCollapseIcon = false,
  buttonClassName = '',
  iconClassName = '',
  tooltipClassName = '',
  bgClassName = ''
}: VideoStreamProps) => {
  const { t } = useTranslation();

  const { translationKey, isEnabled } = useTooltip(VIDEO_STREAMING);
  const { isMobileAsianView, isAsianViewPage, isMobile, isDesktop } = useDevice();

  const isCompactIcon = isMobileAsianView && isAsianViewPage;

  const handleClickIcon = () => {
    if (onClickIcon && visible) {
      onClickIcon();
    }
  };

  const videoIcon = (
    <i
      onClick={handleClickIcon}
      className={classNames(
        styles.videoStream__icon,
        {
          'av-icon-streaming av-icon': isCompactIcon,
          'biab_video-streaming-icon fa2 fa2-youtube-play': !isCompactIcon,
          [styles.videoStream__icon__hidden]: !visible,
          [styles.videoStream__icon__clickableWithoutTooltip]: !!onClickIcon && visible,
          [styles.videoStream__icon__leftSpace]: isLeftSpace && !isInHeader
        },
        iconClassName
      )}
    >
      {!isCompactIcon && (
        <>
          <span className={classNames('path1', 'biab_icon-bg', bgClassName)} />
          <span className="path2" />
        </>
      )}
    </i>
  );

  const contentWithTooltipWrapper = (
    <span
      data-tooltip-id="tooltip"
      data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
      className={classNames(
        styles.videoStream__tooltip,
        {
          'cursor-help': isEnabled
        },
        tooltipClassName
      )}
    >
      {isLiveLabel ? (
        <div className={styles.videoStream__iconAndLabel}>
          {videoIcon}
          <span className={styles.videoStream__liveText}>{t('video.streaming.live')}</span>
        </div>
      ) : (
        videoIcon
      )}
    </span>
  );

  if (isInHeader) {
    return (
      <ButtonWrapper
        eventId={eventId}
        openedTab={openedWidgetTab}
        setOpenedTab={setOpenedWidgetTab}
        hideLabel={hideLabel}
        hideCollapseIcon={hideCollapseIcon}
        buttonClassName={buttonClassName}
        bgClassName={bgClassName}
        isMobile={isMobile}
        isDesktop={isDesktop}
      >
        {contentWithTooltipWrapper}
      </ButtonWrapper>
    );
  }

  return contentWithTooltipWrapper;
};

interface ButtonWrapperProps {
  eventId: string;
  openedTab?: EventWidgetTab;
  setOpenedTab?: Dispatch<SetStateAction<EventWidgetTab>>;
  hideLabel: boolean;
  hideCollapseIcon: boolean;
  buttonClassName: string;
  bgClassName?: string;
  isMobile: boolean;
  isDesktop: boolean;
}

function ButtonWrapper({
  children,
  eventId,
  openedTab,
  setOpenedTab,
  hideLabel,
  hideCollapseIcon,
  buttonClassName,
  bgClassName,
  isMobile,
  isDesktop
}: PropsWithChildren<ButtonWrapperProps>) {
  const { t } = useTranslation();
  const thunkDispatch = useAppThunkDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);

  const [desktopModal, setDesktopModal] = useState<{ visible: boolean; content: string }>({
    visible: false,
    content: ''
  });

  const isPlayerOpened = openedTab === 'video-stream';

  const handleClickVideoStreaming = () => {
    if (isLoggedIn) {
      if (isDesktop) {
        thunkDispatch(
          fetchVideoStreamUrls({
            eventId,
            isMobile,
            onError: () => {
              setDesktopModal({ visible: true, content: t('video.streaming.messages.unavailable') });
            }
          })
        );
      } else if (isMobile) {
        if (!isPlayerOpened) {
          thunkDispatch(fetchVideoStreamUrls({ eventId, isMobile }));
        }
        setOpenedTab?.(isPlayerOpened ? null : 'video-stream');
      }
    } else {
      if (isDesktop) {
        setDesktopModal({ visible: true, content: t('video.streaming.messages.login') });
      } else {
        setOpenedTab?.(isPlayerOpened ? null : 'video-stream');
      }
    }
  };

  return (
    <>
      <button
        onClick={handleClickVideoStreaming}
        className={classNames(
          styles.videoStream__button,
          {
            'biab_video-streaming': !isMobile,
            [styles.videoStream__button__mobileHeader]: isMobile
          },
          buttonClassName,
          bgClassName
        )}
      >
        {children}

        {!hideLabel && (
          <span className={classNames(styles.videoStream__liveText, 'biab_video-streaming-label')}>
            {t('video.streaming.live')}
          </span>
        )}
        {isMobile && !hideCollapseIcon && (
          <i
            className={classNames(
              `biab_collapse-icon fa2 fa2-arrow-${isPlayerOpened ? 'up' : 'down'}`,
              styles.videoStream__arror
            )}
          />
        )}
      </button>
      {isDesktop && desktopModal.visible && (
        <Modal
          open={desktopModal.visible}
          onClose={() => setDesktopModal({ visible: false, content: '' })}
          customClassNames={{ container: styles.videoStream__modal, body: styles.videoStream__modal__content }}
        >
          {desktopModal.content}
        </Modal>
      )}
    </>
  );
}

export default VideoStream;
