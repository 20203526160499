import { BannerNamesKeys } from 'constants/app';
import { Devices } from 'redux/modules/appConfigs/type';
import { TFailureActionPayload } from 'types';

export enum BannerSections {
  HOME_PAGE_BANNER = 'HOME_PAGE_BANNER',
  SUB_PAGE_CONTENT_BANNER = 'SUB_PAGE_CONTENT_BANNER',
  BADGE_BANNER = 'BADGE_BANNER',
  BADGE_BANNER_SKYSCRAPER = 'BADGE_BANNER_SKYSCRAPER',
  PROMOTION_BANNER = 'PROMOTION_BANNER',
  LOGO = 'LOGO',
  FOOTER_LOGO = 'FOOTER_LOGO',
  FAVICON = 'FAVICON',
  NOT_FOUND_BACKGROUND = 'NOT_FOUND_BACKGROUND'
}

export type BannerImage = {
  id: number;
  title: string;
  link: string;
  openInNewTab: boolean;
  absolutePathEnabled: boolean;
  displayOrder: number;
  imageUrl: string;
  retinaImageUrl: string | null;
};

export enum BannersContentOptions {
  IMAGES = 'IMAGES',
  HTML = 'HTML'
}

export type TBannerPayload = {
  name: BannerNamesKeys;
  platform: Devices;
  section: BannerSections;
  locale: string;
};

export type TSuccessFetchBanner = {
  name: BannerNamesKeys;
  data: BannerData;
};

export type TBanners = Record<BannerNamesKeys, BannerData | null> & {
  error: TFailureActionPayload;
};

export type BannerData = {
  id: number;
  localeCode: string;
  platform: string;
  contentSection: BannerSections;
  activeContentOption: BannersContentOptions;
  imageSize: {
    width: number;
    maxHeight: number;
  };
  bannerImages: BannerImage[];
  bannerHtmlContent: {
    content: string;
  };
  lastModified: number;
};
