import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import NavSkeleton from 'components/Skeleton/NavSkeleton';
import AllSportsButton from 'components/SportsSection/partials/AllSportsButton';
import { GAME_BETTING } from 'constants/app';
import { TYPE_TO_URL } from 'constants/competitions';
import {
  getAppDevice,
  getDesktopSettingsCompetitionViewSports,
  getIsCompetitionViewEnabled,
  getLanguage,
  getTimezone,
  getTranslation
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchGetGroup, resetSportData, setRace } from 'redux/modules/competitions';
import {
  getGroupData,
  getGroupLoading,
  getGroupName,
  getGroupNameHighlighted,
  getGroupNameTranslations,
  getGroupParents
} from 'redux/modules/competitions/selectors';
import { SportId } from 'types';
import { filterCompetitionViewParents } from 'utils/navigation';

import ListItem from '../partials/ListItem';
import NavItemName from '../partials/NavItemName';
import ParentItem from '../partials/ParentItem';

import styles from './styles.module.scss';

const GroupView = () => {
  const { sportId, groupId } = useParams();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const domainLanguage = useSelector(getTranslation);
  const language = useSelector(getLanguage);
  const timezone = useSelector(getTimezone);
  const device = useSelector(getAppDevice);
  const isCompetitionViewEnabled = useSelector(getIsCompetitionViewEnabled);
  const competitionViewSports = useSelector(getDesktopSettingsCompetitionViewSports);
  const groupParents = useSelector(getGroupParents);
  const groupData = useSelector(getGroupData);
  const groupNameTranslations = useSelector(getGroupNameTranslations);
  const groupLoading = useSelector(getGroupLoading);
  const groupNameHighlighted = useSelector(getGroupNameHighlighted);
  const groupName = useSelector(getGroupName);

  const isTennis = sportId === SportId.TENNIS;
  const isMobile = device === Devices.MOBILE;
  const parents = filterCompetitionViewParents(groupParents, competitionViewSports, sportId, isCompetitionViewEnabled);
  const isGamesBettingTennisPage =
    TYPE_TO_URL[parents[parents.length - 1]?.type] === TYPE_TO_URL.EVENT && isTennis && isMobile;
  const isGameBettingGroup =
    TYPE_TO_URL[parents[parents.length - 1]?.type] === TYPE_TO_URL.EVENT && isTennis && groupName === GAME_BETTING;

  useEffect(() => {
    if (groupId) {
      dispatch(fetchGetGroup({ eventId: groupId, showGroups: isTennis }));
    }
  }, [groupId, timezone, isLoggedIn, language]);

  useEffect(() => {
    return () => {
      dispatch(setRace(null));
      dispatch(resetSportData());
    };
  }, []);

  const getHeaderName = () => {
    if (isTennis && isMobile && !isGamesBettingTennisPage) {
      return `${parents[parents.length - 1]?.translations?.[domainLanguage]} - ${
        typeof groupNameTranslations === 'string' ? groupNameTranslations : groupNameTranslations[domainLanguage]
      }`;
    }
    return groupNameTranslations;
  };

  return (
    <>
      {groupLoading && <NavSkeleton withDelay={150} itemsCount={40} />}
      <div className={classNames(styles.removeScroll, { hidden: groupLoading })}>
        {!(isTennis && isMobile && !isGamesBettingTennisPage) && (
          <>
            {isMobile && !groupLoading && <AllSportsButton />}
            {parents.map(parent => (
              <ParentItem key={parent.id} item={parent} />
            ))}
          </>
        )}
        {groupNameTranslations && (
          <NavItemName
            isSubheader={isTennis && isMobile && !isGamesBettingTennisPage}
            type={TYPE_TO_URL.GROUP}
            highlighted={groupNameHighlighted}
            nameTranslations={getHeaderName()}
            hasFavoritesIcon={!isGameBettingGroup}
          />
        )}
        {groupData.map(d => (
          <ListItem item={d} domainLanguage={domainLanguage} key={d.id} isGamesBettingChild={isGameBettingGroup} />
        ))}
      </div>
    </>
  );
};

export default GroupView;
