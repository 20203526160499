import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { asianViewIcons, asianViewMiddleSection as branding } from 'constants/branding';
import { STARTING_SOON, STARTING_TODAY, STARTING_TOMORROW } from 'constants/date';
import { ASIAN_VIEW_LIMITED_OFFERING } from 'constants/tooltip';
import useConvertTimeToString from 'hooks/useConvertTimeToString';
import useTooltip from 'hooks/useTooltip';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import {
  getAwayScoreByEventId,
  getEventsUpdatedDataAsianViewElapsedRegularTime,
  getEventsUpdatedDataTimeElapsed,
  getHomeScoreByEventId,
  getIsMarketInPlayByMarketPricesId,
  getMarketPricesAsianViewElapsedRegularTime,
  getMarketPricesAwayScore,
  getMarketPricesHomeScore,
  getMarketPricesId,
  getMarketPricesRegularTime
} from 'redux/modules/marketsPrices/selectors';
import { addZero } from 'utils/session';

import LiveScore from './components/LiveScore';

import styles from './styles.module.scss';

interface AsianViewInPlayCellProps {
  marketStartTime: number;
  marketId: string;
  containerClassName?: string;
  hideTime?: boolean;
  hideIcon?: boolean;
  isEventUpdatesData?: boolean;
  eventId?: string;
  isInPlay?: boolean;
  highlightGoal?: boolean;
  isMobileBetListPage?: boolean;
  noInPlayContainerClassName?: string;
  periodLabelClassName?: string;
}

const AsianViewInPlayCell = ({
  marketStartTime,
  marketId,
  containerClassName = '',
  hideTime,
  hideIcon,
  isEventUpdatesData = false,
  eventId,
  isInPlay = false,
  highlightGoal = false,
  isMobileBetListPage = false,
  noInPlayContainerClassName = '',
  periodLabelClassName
}: AsianViewInPlayCellProps) => {
  const { t } = useTranslation();

  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const marketPricesId = useSelector(getMarketPricesId(marketId));
  const homeScore = useSelector(
    isEventUpdatesData && eventId ? getHomeScoreByEventId(eventId) : getMarketPricesHomeScore(marketId)
  );
  const awayScore = useSelector(
    isEventUpdatesData && eventId ? getAwayScoreByEventId(eventId) : getMarketPricesAwayScore(marketId)
  );
  const regularTime = useSelector(
    isEventUpdatesData && eventId ? getEventsUpdatedDataTimeElapsed(eventId) : getMarketPricesRegularTime(marketId)
  );
  const elapsedRegularTime = useSelector(
    isEventUpdatesData && eventId
      ? getEventsUpdatedDataAsianViewElapsedRegularTime(eventId)
      : getMarketPricesAsianViewElapsedRegularTime(marketId)
  );
  const isMarketsPricesInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketId));

  const startTimeInfo = useConvertTimeToString({ startDate: marketStartTime, isAsianView: true });

  const { translationKey: tooltipKey, isEnabled: isTooltipEnabled } = useTooltip(ASIAN_VIEW_LIMITED_OFFERING);

  const isLiveScoreData =
    (isInPlay || isMarketsPricesInPlay) &&
    homeScore !== undefined &&
    awayScore !== undefined &&
    (elapsedRegularTime !== undefined || regularTime !== undefined);

  const getContent = () => {
    if (isLiveScoreData) {
      return (
        <LiveScore
          hideTime={hideTime}
          marketId={marketId}
          isEventUpdatesData={isEventUpdatesData}
          eventId={eventId}
          highlightGoal={highlightGoal}
          periodLabelClassName={periodLabelClassName}
        />
      );
    }

    if (!isMarketsPricesInPlay && !isInPlay) {
      return (
        <div
          className={classNames(
            'biab_asian-view-event-score',
            styles.asianViewInPlayCell__noInPlay,
            {
              [styles.asianViewInPlayCell__noInPlay__mobile__notToday]:
                startTimeInfo.showDescription && !startTimeInfo.isNotToday && isMobileAsianView,
              [styles.asianViewInPlayCell__noInPlay__mobile]: isMobileAsianView
            },
            noInPlayContainerClassName
          )}
        >
          {startTimeInfo.showDescription && (
            <span
              className={classNames({
                [styles.asianViewInPlayCell__noInPlay__description]: !startTimeInfo.isNotToday,
                [styles.asianViewInPlayCell__noInPlay__description__mobile__notStarting]:
                  isMobileAsianView &&
                  !startTimeInfo.isNotToday &&
                  startTimeInfo.key !== STARTING_SOON &&
                  startTimeInfo.key !== STARTING_TODAY &&
                  startTimeInfo.key !== STARTING_TOMORROW,
                [styles.asianViewInPlayCell__noInPlay__description__mobile__starting]:
                  isMobileAsianView &&
                  !startTimeInfo.isNotToday &&
                  (startTimeInfo.key === STARTING_SOON ||
                    startTimeInfo.key === STARTING_TODAY ||
                    startTimeInfo.key === STARTING_TOMORROW),
                [branding.LIVE_SCORE_CONTAINER]: !startTimeInfo.isNotToday
              })}
            >
              {startTimeInfo.isNotToday
                ? `${startTimeInfo.monthDay}/${addZero(startTimeInfo.month + 1)}${startTimeInfo.month + 1}`
                : t(startTimeInfo.key, '', { min: startTimeInfo.time })}
            </span>
          )}
          {startTimeInfo.showHoursAndMinutes && (
            <span
              className={classNames({
                [styles.asianViewInPlayCell__noInPlay__time]: isMobileAsianView
              })}
            >
              {startTimeInfo.hoursAndMinutes}
            </span>
          )}
        </div>
      );
    }

    return (
      <div
        className={classNames(styles.asianViewInPlayCell__noScore, {
          [styles.asianViewInPlayCell__noScore__minimized]: hideTime
        })}
      >
        <span>{t('asianView.labels.timeFilter.live')}</span>
        {!hideIcon && isTooltipEnabled && !isMobileBetListPage && (
          <span data-tooltip-id="tooltip" data-tooltip-html={t(tooltipKey)}>
            <i
              className={classNames(
                'av-icon av-icon-timer',
                styles.asianViewInPlayCell__timerIcon,
                asianViewIcons.LIMITED_OFFERING_ICON
              )}
            />
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className={classNames(
        styles.asianViewInPlayCell,
        branding.MAIN_CONTAINER_BORDER,
        {
          [styles.asianViewInPlayCell__liveScore]: isLiveScoreData || isMarketsPricesInPlay || isInPlay,
          [styles.asianViewInPlayCell__liveScore__mobile]:
            (isLiveScoreData || isMarketsPricesInPlay || isInPlay) && isMobileAsianView,
          [branding.LIVE_SCORE_CONTAINER]:
            isLiveScoreData || isMarketsPricesInPlay || isInPlay || startTimeInfo.key === STARTING_SOON,
          [branding.TIME_CONTAINER]: !(
            isLiveScoreData ||
            isMarketsPricesInPlay ||
            isInPlay ||
            startTimeInfo.key === STARTING_SOON
          ),
          [styles.asianViewInPlayCell__mobileBetList]:
            isMobileBetListPage && startTimeInfo.showDescription && startTimeInfo.isNotToday,
          [styles.asianViewInPlayCell__mobileBetList__noBorder]:
            isMobileBetListPage && (startTimeInfo.key === STARTING_SOON || isInPlay)
        },
        containerClassName
      )}
    >
      {(isEventUpdatesData || marketPricesId) && getContent()}
    </div>
  );
};

export default AsianViewInPlayCell;
