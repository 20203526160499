import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import MarketSection from 'components/MarketSection';
import PopularMarket from 'components/PopularMarket';
import RacingSport from 'components/RacingSport';
import SportPageModule from 'components/SportPageModule';
import SportsSection from 'components/SportsSection';
import { GAME_BETTING } from 'constants/app';
import SportEvent from 'pages/SportEvent';
import { getAppDevice, getIsShowMarketOdd } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getGroupName, getGroupParents } from 'redux/modules/competitions/selectors';
import { fetchEventGroup } from 'redux/modules/event';
import { fetchPopularMarkets } from 'redux/modules/popularMarket';
import { SportPageContextFilter, SportPageType } from 'redux/modules/sportPage/type';
import { PlacementPage, SportId } from 'types';

const GroupPage = () => {
  const dispatch = useDispatch();
  const { sportId } = useParams();

  const device = useSelector(getAppDevice);
  const groupName = useSelector(getGroupName);
  const groupParents = useSelector(getGroupParents);
  const isShowMarketOdds = useSelector(getIsShowMarketOdd);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const competition = groupParents.find(({ type }) => type === SportPageType.COMPETITION);
  const eventId = groupParents.find(({ type }) => type === SportPageType.EVENT)?.id || '';
  const isTennis = sportId === SportId.TENNIS;

  let content = null;

  useEffect(() => {
    if (isTennis && groupName === GAME_BETTING && groupParents.length && eventId) {
      dispatch(fetchEventGroup(eventId));
    }
  }, [dispatch, groupName, groupParents, groupParents.length, sportId]);

  useEffect(() => {
    if (isShowMarketOdds) {
      dispatch(fetchPopularMarkets());
    }
  }, [isLoggedIn, dispatch, isShowMarketOdds]);

  if (device === Devices.MOBILE) {
    return <SportsSection />;
  }

  if (sportId === SportId.HORSE_RACING || sportId === SportId.GRAY_HOUND_RACING) {
    content = <RacingSport />;
  } else if (isTennis && groupName === GAME_BETTING && eventId) {
    content = <SportEvent id={eventId} page={PlacementPage.GROUP} />;
  } else if (isTennis && groupParents.length) {
    const { id } = competition || groupParents[groupParents.length - 1];

    content = (
      <SportPageModule
        pageDetailsId={id}
        contextFilter={SportPageContextFilter.COMPETITION}
        pageType={SportPageType.COMPETITION}
        placementPage={PlacementPage.GROUP}
      />
    );
  } else if (groupParents.length) {
    content = <MarketSection />;
  }

  return (
    <>
      <PopularMarket page={PlacementPage.GROUP_ODDS_BANNER} />
      {content}
    </>
  );
};

export default GroupPage;
