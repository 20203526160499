import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { MobilePlacementTypes } from 'constants/inlinePlacement';
import { setCurrentBetAction } from 'redux/modules/currentBets';
import { ECurrentBetActions, TCurrentBet } from 'redux/modules/currentBets/type';
import { setMobilePlacementNotification } from 'redux/modules/inlinePlacement';
import { PageBlocks } from 'types';
import { getUniqSelectedBetKey } from 'utils/betslip';

type Params = {
  bet: TCurrentBet | null;
  pageBlock: PageBlocks;
  onMatched?: () => void;
};

export default function useMobilePlacementNotificationMatchedBySystem({ bet, pageBlock, onMatched }: Params) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !!bet &&
      (bet.action === ECurrentBetActions.FULLY_MATCHED || bet.action === ECurrentBetActions.PARTIALLY_MATCHED) &&
      !Number(bet.sizeCancelled)
    ) {
      dispatch(
        setMobilePlacementNotification({
          pageBlock,
          data: {
            marketId: bet.marketId,
            offerId: bet.offerId,
            betUuid: getUniqSelectedBetKey({
              handicap: bet.handicap,
              selectionId: bet.selectionId ?? 0,
              marketId: bet.marketId
            })
          },
          placementType: MobilePlacementTypes.MatchedBySystem
        })
      );

      if (onMatched) {
        onMatched();
      }

      dispatch(setCurrentBetAction({ offerId: bet.offerId, action: null }));
    }
  }, [bet?.action]);
}
