import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { unescape } from 'lodash';

import { getLanguage } from 'redux/modules/appConfigs/selectors';
import { fetchPSFooter } from 'redux/modules/pokerstar';
import { getPSFooter } from 'redux/modules/pokerstar/selectors';
import { CookieNames } from 'types';
import { getPSLocale } from 'utils/getPSLocale';

import './footer.scss';

const PokerStarsFooter = () => {
  const [cookies] = useCookies([CookieNames.LICENSE]);
  const dispatch = useDispatch();

  const footerContent = useSelector(getPSFooter);
  const language = useSelector(getLanguage);

  useEffect(() => {
    dispatch(fetchPSFooter({ license: cookies.BIAB_LICENSE, locale: getPSLocale(language, cookies.BIAB_LICENSE) }));
  }, []);

  return (
    <div id="biab_footer" className="psFooter" dangerouslySetInnerHTML={{ __html: unescape(footerContent || '') }} />
  );
};

export default PokerStarsFooter;
