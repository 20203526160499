import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { getUserAsianViewViewType } from 'redux/modules/user/selectors';
import { CookieNames } from 'types';
import { ELineViewTypes } from 'types/asianView';

const useAsianViewLineType = () => {
  const asianViewViewType = useSelector(getUserAsianViewViewType);
  const [cookies] = useCookies([CookieNames.ASIAN_VIEW_LINE_VIEW_FILTER]);

  return (
    (asianViewViewType as ELineViewTypes) ||
    (cookies[CookieNames.ASIAN_VIEW_LINE_VIEW_FILTER] as ELineViewTypes) ||
    ELineViewTypes.DOUBLE
  );
};

export default useAsianViewLineType;
