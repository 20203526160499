import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ASIAN_BASE_URL } from 'constants/locations';
import { getAppDevice, getIsLandscape } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getIsLandscapeAsianView, getIsMobileAsianView } from 'redux/modules/asianView/selectors';

/**
 * Use this hook in components where both views (Asian and Sports) are used to identify device, because for Asian view ->
 * mobile view should be enabled when the screen size is less than 1225px, and for Sports view - should be enabled when
 * the screen size is less than 1000px
 */
const useDevice = () => {
  const location = useLocation();

  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const device = useSelector(getAppDevice);
  const isAsianViewLandscapeView = useSelector(getIsLandscapeAsianView);
  const isSportsGamesLandscapeView = useSelector(getIsLandscape);

  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);

  let currentDevice = Devices.DESKTOP;

  if (isAsianViewPage) {
    if (isMobileAsianView) {
      currentDevice = Devices.MOBILE;
    }
  } else if (device === Devices.MOBILE) {
    currentDevice = Devices.MOBILE;
  }

  return {
    currentDevice,
    isMobile: currentDevice === Devices.MOBILE,
    isDesktop: currentDevice === Devices.DESKTOP,
    isAsianViewPage,
    isMobileAsianView,
    isLandscape: isAsianViewPage ? isAsianViewLandscapeView : isSportsGamesLandscapeView
  };
};

export default useDevice;
