import classNames from 'classnames';

import { ModalClasses } from 'types';

import MarketRules from '../MarketRules';

import styles from './styles.module.scss';

interface ColumnNameProps {
  name: string;
  marketId?: string | null;
  modalClasses?: ModalClasses;
}

const ColumnName = ({ name, marketId, modalClasses }: ColumnNameProps) => {
  if (marketId) {
    return (
      <div className={classNames(styles.name, styles.name__hasRules)}>
        <div className={styles.name__value}>{name}</div>
        <MarketRules marketId={marketId} modalClasses={modalClasses} />
      </div>
    );
  }

  return <div className={styles.name}>{name}</div>;
};

export default ColumnName;
