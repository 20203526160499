import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentBetsRequestInterval, getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { cleanPLTable, fetchPLTable } from 'redux/modules/plTable';
import { getPLTableLoading } from 'redux/modules/plTable/selectors';
import { BettingType } from 'types/markets';

type PLTableIntervalRequestInjectionProps = {
  marketId: string;
  bettingType: BettingType;
};

const PLTableIntervalRequestInjection = ({ marketId, bettingType }: PLTableIntervalRequestInjectionProps) => {
  const dispatch = useDispatch();

  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const currentBetsRequestInterval = useSelector(getCurrentBetsRequestInterval) || '3000';
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const loading = useSelector(getPLTableLoading);

  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  useEffect(() => {
    const interval = setInterval(async () => {
      if (marketId && isLoggedIn) {
        if (!loadingRef.current) {
          dispatch(fetchPLTable({ marketId, isLine: bettingType === BettingType.LINE }));
        }
      }
    }, parseInt(currentBetsRequestInterval));

    return () => {
      clearInterval(interval);
      dispatch(cleanPLTable());
    };
  }, [currentBetsRequestInterval, isLoggedIn, marketId, language, timezone]);

  return null;
};

export default PLTableIntervalRequestInjection;
