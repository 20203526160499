import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { setSelectedGamePage } from 'redux/modules/games';
import {
  getCurrentGameName,
  getCurrentGameSelectedMarketId,
  getIsCurrentGame,
  getSelectedGamePage
} from 'redux/modules/games/selectors';
import { EGamePages } from 'redux/modules/games/type';

import styles from './styles.module.scss';

const SingleGamePageHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedGamePage = useSelector(getSelectedGamePage);
  const selectedMarketId = useSelector(getCurrentGameSelectedMarketId);
  const isCurrentGame = useSelector(getIsCurrentGame);
  const currentGameName = useSelector(getCurrentGameName);

  if (!isCurrentGame) {
    return null;
  }

  const handleHeaderButtonClick = (buttonType: EGamePages) => {
    dispatch(setSelectedGamePage(buttonType));
  };

  return (
    <div className={classNames(styles.header, 'biab_games-title')}>
      <span>
        {currentGameName} <span> - {t('games.gameId')}: </span>
        <span>{selectedMarketId}</span>
      </span>
      <div className="biab_games-results-nav">
        <a
          onClick={() => handleHeaderButtonClick(EGamePages.GAME)}
          className={classNames(styles.button, 'biab_games-results-nav-item', {
            biab_active: selectedGamePage === EGamePages.GAME
          })}
        >
          {t('games.results.buttons.game')}
        </a>
        <a
          onClick={() => handleHeaderButtonClick(EGamePages.RESULTS)}
          className={classNames(styles.button, 'biab_games-results-nav-item', {
            biab_active: selectedGamePage === EGamePages.RESULTS
          })}
        >
          {t('games.results.buttons.results')}
        </a>
      </div>
    </div>
  );
};

export default SingleGamePageHeader;
