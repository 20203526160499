import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { EXTRA_SMALL_SCREE_SIZE } from 'constants/app';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { PageBlocks, PlacementPage } from 'types';
import { BetTypes } from 'types/bets';
import { IMarket, TMarketRunner } from 'types/markets';

import BetContentCell from '../BetContentCell';
import BetContentGroup from '../BetContentGroup';
import StatusOverlay from '../StatusOverlay';

import styles from './styles.module.scss';

interface BetContentCellsProps {
  /**
   * Data for the market (marketId, runners, event data, startTime, etc.)
   */
  market: IMarket;

  /**
   * Count of bet groups (1 group = back and lay cells) for prices
   */
  betGroupsCount: number;

  /**
   * Width in percents for all price column groups in a row
   */
  betContentWidth: string;

  /**
   * Place where component was added (Home, Market odds, Competition, Event)
   */
  pageBlock?: PageBlocks;

  /**
   * Show bet cells for mobile
   */
  mobileBettingMode?: boolean;

  /**
   * Swipe mode for mobile (when window height more than window width)
   */
  mobileBettingModeSwipe?: boolean;
  /**
   * Whether lay column is enabled
   */
  isLayColumnEnabled: boolean;
  /**
   * Cell width for mobile
   */
  mobileCellWidth: number;
  page?: PlacementPage;
  showStatus: boolean;
  status?: string;
  showLockIcon: boolean;
  displayStatus: boolean;
  showCellTooltip: boolean;
  isPopularMarketsOdds?: boolean;
}

const BetContentCells = ({
  market,
  betContentWidth,
  betGroupsCount,
  pageBlock,
  mobileBettingMode = false,
  mobileBettingModeSwipe = false,
  isLayColumnEnabled,
  mobileCellWidth,
  page,
  showStatus,
  status,
  showLockIcon,
  displayStatus,
  showCellTooltip,
  isPopularMarketsOdds = false
}: BetContentCellsProps) => {
  const device = useSelector(getAppDevice);

  const isMobile = device === Devices.MOBILE;
  const hasThreeGroupsButTwoRunners = !!(betGroupsCount && betGroupsCount === 3 && market.runners.length === 2);
  const [first, second, draw] = market.runners;
  let marketRunners: Array<TMarketRunner | null> = [];

  if (hasThreeGroupsButTwoRunners) {
    /**
     * We need to check if there are three columns at least for one market but we only have two runners we need to add
     * one more element in the middle of the runners to be able display hyphen instead of any data
     */
    marketRunners = [first, null, second];
  } else if (market.runners.length === 2) {
    /**
     * If the first condition is false - then we need to check how many runners we have and if there are two of them then
     * we just return them
     */
    marketRunners = market.runners;
  } else if (first !== undefined && second !== undefined && draw !== undefined) {
    /**
     * And if the previous conditions are false and we have three runners, we need to swap the second and third elements
     * of the runners, because from the backend we always receive a draw as the last element but we need it to be in the
     * middle
     */
    marketRunners = [first, draw, second];
  }
  const mobileSwipeModeBets = isLayColumnEnabled ? [BetTypes.BACK, BetTypes.LAY] : [BetTypes.BACK];

  const widthFull = mobileCellWidth + 3; // 3 = border width + horizontal padding
  const space = betGroupsCount * widthFull - widthFull / 2 + (window.innerWidth <= EXTRA_SMALL_SCREE_SIZE ? 10 : 0);

  return (
    <div
      className={styles.betContent__wrapper}
      style={{
        width: mobileBettingMode ? 'auto' : betContentWidth,
        minWidth: mobileBettingMode ? 'auto' : betContentWidth
      }}
    >
      {mobileBettingModeSwipe ? (
        <div
          style={isLayColumnEnabled ? { marginLeft: -space } : {}}
          className={classNames({
            [styles.betContent__swipeMode]: isLayColumnEnabled,
            [styles.betContent__swipeMode__disabledLay]: !isLayColumnEnabled
          })}
        >
          <div
            style={isLayColumnEnabled ? { transform: `translateX(${space}px)` } : {}}
            className={styles.betContent__swipeWrapper}
          >
            {mobileSwipeModeBets.map(type =>
              marketRunners.map((runner, index) => {
                return (
                  <BetContentCell
                    marketStatusSettings={{ showStatus, status, showLockIcon, displayStatus }}
                    key={type + (runner?.selectionId || index)}
                    isLayColumnHidden={!isLayColumnEnabled}
                    mobileBettingMode={mobileBettingMode}
                    betType={type}
                    market={market}
                    runner={runner}
                    pageBlock={pageBlock}
                    mobileCellWidth={mobileCellWidth}
                    redirectToSingleMarketPage={pageBlock === PageBlocks.SPORT && isMobile}
                    page={page}
                    isPopularMarketsOdds={isPopularMarketsOdds}
                  />
                );
              })
            )}
          </div>
        </div>
      ) : (
        marketRunners.map((runner, index: number) => {
          return (
            <BetContentGroup
              marketStatusSettings={{ showStatus, status, showLockIcon, displayStatus }}
              key={runner?.selectionId ?? index}
              runner={runner}
              market={market}
              betGroupsCount={betGroupsCount}
              pageBlock={pageBlock}
              mobileBettingMode={mobileBettingMode}
              mobileCellWidth={mobileCellWidth}
              insideTableRow
              hideLayColumn={!isLayColumnEnabled}
              page={page}
              showCellTooltip={index === 0 && showCellTooltip}
              isPopularMarketsOdds={isPopularMarketsOdds}
            />
          );
        })
      )}
      {showStatus && !mobileBettingMode && <StatusOverlay status={status} />}
    </div>
  );
};

export default BetContentCells;
