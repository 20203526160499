import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isInteger } from 'lodash';

import { BETSLIP_LINE_INT_ODDS } from 'constants/betslip';
import { mobileBetslip as branding, mobileComponents, mobileIcons } from 'constants/branding';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMobilePlacementNotificationMatchedBySystem from 'hooks/useMobilePlacementNotificationMatchedBySystem';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { getIsMarketClosed } from 'redux/modules/marketsPrices/selectors';
import { PageBlocks, PlacementPage } from 'types';
import { BetTypes, MatchTypes } from 'types/bets';
import { BettingType } from 'types/markets';

import MobileUnmatchedBetActions from '../MobileUnmatchedBetActions/MobileUnmatchedBetActions';

import styles from './styles.module.scss';

interface MobileOpenBetProps {
  /**
   * Bet
   */
  bet: TCurrentBet;

  /**
   * Match type
   */
  matchType: MatchTypes;

  /**
   * Is Open bets enabled flag
   */
  isOpenBetsEnabled: boolean;

  /**
   * Is PNC enabled flag
   */
  isPNCEnabled: boolean;

  /**
   * Place where component was added.
   */
  pageBlock: PageBlocks;

  /**
   * Currency
   */
  currency: string;

  /**
   * Hide function
   */
  onHide: (matchType: MatchTypes, offerId: number) => void;
  page?: PlacementPage;
  noHeader: boolean;
}

function MobileOpenBet({
  bet,
  matchType,
  isOpenBetsEnabled,
  isPNCEnabled,
  pageBlock,
  currency,
  onHide,
  page,
  noHeader
}: MobileOpenBetProps) {
  const { t } = useTranslation();

  const isMarketClosed = useSelector(getIsMarketClosed(bet.marketId));

  const isGame = pageBlock === PageBlocks.GAME;

  const isLineMarket = bet.bettingType === BettingType.LINE;

  const priceByMatchType = matchType === MatchTypes.UNMATCHED ? bet.price : bet.averagePriceRounded;
  const minUnitValue = (bet as TCurrentBet).minUnitValue || 0;

  const price =
    !isInteger(priceByMatchType) && isLineMarket && isInteger(bet.interval) && isInteger(minUnitValue)
      ? Math.floor(+(priceByMatchType || 0)) + 1
      : priceByMatchType;

  const isLapsed = bet.offerState === BetsStatusesTypes.LAPSED;
  const showLapsedNotification = matchType === MatchTypes.UNMATCHED && (isLapsed || isMarketClosed);

  let messageKey: string;

  if (matchType === MatchTypes.MATCHED) {
    if (isPNCEnabled && !isGame) {
      if (bet.side === BetTypes.BACK ? bet.averagePrice > bet.price : bet.averagePrice < bet.price) {
        messageKey = 'betslip.labels.pnc.placedWithBetterOdds';
      } else {
        messageKey = 'betslip.labels.pnc.placed';
      }
    } else {
      messageKey = 'placement.openBets.matched';
    }
  } else {
    messageKey = 'betslip.labels.unmatchedBet';
  }

  const size =
    matchType === MatchTypes.UNMATCHED
      ? (showLapsedNotification ? Number(bet.sizeLapsed) : Number(bet.sizeRemaining)) || 0
      : bet.size || bet.sizeMatched || bet.sizeCancelled || 0;

  const { noFormattedAmount: formattedSize } = useFormatCurrency(size, bet.currency, {
    isCheckIndian: true,
    noRounding: true
  });

  useMobilePlacementNotificationMatchedBySystem({ bet, pageBlock });

  const renderIcon = () => {
    if (matchType === MatchTypes.MATCHED) {
      if (
        isPNCEnabled && !isGame && bet.side === BetTypes.BACK
          ? bet.averagePrice > bet.price
          : bet.averagePrice < bet.price
      ) {
        return (
          <i
            className={classNames('biab_custom-icon-success-star-circle', styles.openBet__icon, mobileIcons.STAR_ICON)}
          >
            <span className={classNames('path1', mobileIcons.BG_COLOR)} />
            <span className="path2" />
          </i>
        );
      }

      return (
        <i
          className={classNames('biab_custom-icon-success-circle', styles.openBet__icon, mobileIcons.CHECKMARK_ICON)}
        />
      );
    }

    if (showLapsedNotification) {
      return <i className={classNames('biab_custom-icon-info-circle', styles.openBet__icon, mobileIcons.INFO_ICON)} />;
    }

    return (
      <i
        className={classNames(
          'fa2 fa2-clock-icon',
          styles.openBet__icon__unmatched,
          styles.openBet__icon,
          mobileIcons.CLOCK_ICON,
          mobileIcons.ERROR
        )}
      />
    );
  };

  return (
    <div
      className={classNames(
        matchType.toLocaleLowerCase(),
        'biab_' + bet.side.toLocaleLowerCase(),
        styles.openBet,
        matchType === MatchTypes.UNMATCHED ? styles.openBet__unmatched : styles.openBet__matched,
        {
          [styles.openBet__topBorder]: noHeader,
          [branding.OPENED_BET]: !showLapsedNotification,
          [mobileComponents.NOTIFICATION]: showLapsedNotification,
          [mobileComponents.INFO_SECONDARY]: showLapsedNotification
        }
      )}
    >
      <div className={styles.openBet__header}>
        {renderIcon()}
        <div>
          <p className={styles.openBet__title}>
            {t(`inlinePlacement.labels.${bet.side?.toLowerCase()}`)}:{' '}
            <span className={styles.bold}>
              {bet.selectionName} {isLineMarket && !isPNCEnabled ? ` @${BETSLIP_LINE_INT_ODDS}` : ''}
            </span>{' '}
            – {isGame ? bet.eventName : bet.marketName}
          </p>
          <p className={styles.openBet__title}>
            <span className={styles.bold}>
              {formattedSize} @{price}
            </span>{' '}
            – {showLapsedNotification ? t('inlinePlacement.messages.lapsed') : messageKey && t(messageKey)}
          </p>
        </div>
        {!isOpenBetsEnabled && (
          <i
            onClick={() => onHide(matchType, bet.offerId)}
            className={classNames('biab_custom-icon-close', styles.closeIcon)}
          />
        )}
      </div>
      {matchType === MatchTypes.UNMATCHED && !showLapsedNotification && (
        <MobileUnmatchedBetActions bet={bet} page={page} pageBlock={pageBlock} currency={currency} />
      )}
    </div>
  );
}

export default MobileOpenBet;
