import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import DropdownItem from 'components/EventHeaderMobileInPlay/partials/DropdownItem';
import marketHeaderStyles from 'components/EventHeaderMobileInPlay/styles.module.scss';
import Dropdown from 'components/SportsSection/Dropdown';
import { useEnabledView } from 'hooks/useEnabledView';
import {
  getDesktopSettingsCompetitionViewSports,
  getIsCompetitionViewEnabled
} from 'redux/modules/appConfigs/selectors';
import { getScrollContentHeight } from 'redux/modules/appSettings/selectors';
import { getFancyDropdownMarkets } from 'redux/modules/fancyMarket/selectors';
import { getMarketsDropdownList } from 'redux/modules/market/selectors';
import { getTop5DropdownMarkets } from 'redux/modules/top5View/selectors';

const MarketDropdown = ({ topPosition, isSoccerInPlay }: { topPosition?: number; isSoccerInPlay?: boolean }) => {
  const { marketId, sportId = '', eventId = '' } = useParams();

  const competitionViewSports = useSelector(getDesktopSettingsCompetitionViewSports);
  const isCompetitionViewEnabled = useSelector(getIsCompetitionViewEnabled);
  const scrollContentHeight = useSelector(getScrollContentHeight);
  const dropdownMarketView = useSelector(getMarketsDropdownList);
  const dropdownTop5View = useSelector(getTop5DropdownMarkets);
  const dropdownFancyView = useSelector(getFancyDropdownMarkets);

  const { isTop5View, isFancyView } = useEnabledView();

  const heightOtherEl = 230 + (isSoccerInPlay ? 80 : 0);

  const dropdownList = () => {
    if (marketId) {
      return dropdownMarketView;
    } else if (eventId) {
      if (isTop5View) return dropdownTop5View;
      if (isFancyView) return dropdownFancyView;
    }

    return [];
  };

  const isDropdownEnabled =
    !!dropdownList()?.length && competitionViewSports.includes(sportId) && isCompetitionViewEnabled;

  return isDropdownEnabled ? (
    <div className={marketHeaderStyles.headerRightSide}>
      <Dropdown topPosition={topPosition} icon={<i className={'fas fa-ellipsis-h'} />}>
        <ul
          className={classNames('biab_event-dropdown-container', marketHeaderStyles.marketDropdown)}
          style={{
            maxHeight:
              scrollContentHeight < 800
                ? `${scrollContentHeight - heightOtherEl < 525 ? scrollContentHeight - heightOtherEl : 525}px`
                : '525px'
          }}
        >
          {dropdownList()?.map(item => (
            <DropdownItem key={item.marketId} {...item} />
          ))}
        </ul>
      </Dropdown>
    </div>
  ) : null;
};

export default MarketDropdown;
