import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';
import { getFavoritesIds } from 'utils';

import { TFavorite, TFavorites, TSportCollapse } from './type';

const initialState: TFavorites = {
  loading: false,
  favoritesList: [],
  favoritesIds: {},
  collapsableSports: {},
  error: null
};

const slice = createSlice({
  name: SLICES_NAMES.FAVOURITES,
  initialState,
  reducers: {
    fetchFavorites: state => {
      state.loading = true;
    },
    successFetchFavorites: (state, { payload }: PayloadAction<TFavorite[]>) => {
      state.loading = false;
      state.favoritesList = payload;
      state.favoritesIds = getFavoritesIds(payload);
    },
    successSaveRemoveFavorite: (state, { payload }: PayloadAction<TFavorite[]>) => {
      state.loading = false;
      state.favoritesList = payload || [];
      state.favoritesIds = getFavoritesIds(payload || []);
    },
    setSportCollapse: (state, { payload }: PayloadAction<TSportCollapse>) => {
      const { sportId, collapse } = payload;

      state.collapsableSports[sportId] = collapse;
    },
    cleanFavorites: () => initialState,
    failureFetchFavorites: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
    },
    saveRemoveFavorite: (_, __: PayloadAction<TFavorite>) => {}
  }
});

export const {
  fetchFavorites,
  failureFetchFavorites,
  successFetchFavorites,
  successSaveRemoveFavorite,
  saveRemoveFavorite,
  cleanFavorites,
  setSportCollapse
} = slice.actions;

export default slice.reducer;
