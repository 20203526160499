import classNames from 'classnames';

import { EActionTypes } from 'types/betslip';

import styles from './styles.module.scss';

const ButtonArrow = ({
  type,
  isArrowEnabled,
  onClickHandler
}: {
  type: EActionTypes;
  isArrowEnabled: boolean;
  onClickHandler: (type: EActionTypes) => void;
}) => {
  return (
    <button
      className={classNames(styles.arrow, {
        [styles.arrow__up]: type === EActionTypes.ADD,
        [styles.arrow__down]: type === EActionTypes.SUBSTR,
        [styles.arrow__disabled]: !isArrowEnabled
      })}
      disabled={!isArrowEnabled}
      onClick={() => onClickHandler(type)}
      tabIndex={-1}
    >
      <i className="fa2 fa2-sort-up" aria-hidden="true" />
    </button>
  );
};

export default ButtonArrow;
