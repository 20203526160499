import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { PARAMS_SEARCH_KEY } from 'constants/urlParams';
import { getSearchNumberOfElements, getSearchSize, getSearchTotalElements } from 'redux/modules/search/selectors';
import { replaceBRtoBreakLine } from 'utils';

import styles from './styles.module.scss';

function SearchResult() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const size = useSelector(getSearchSize);
  const totalElements = useSelector(getSearchTotalElements);
  const numberOfElements = useSelector(getSearchNumberOfElements);

  const query = searchParams.get(PARAMS_SEARCH_KEY);

  const resultMessage =
    totalElements === 0
      ? t('search.noResults', {
          keyword: query
        })
      : replaceBRtoBreakLine(
          t('search.resultsInfo', {
            keyword: query,
            total: totalElements,
            start: 1,
            end: numberOfElements < size ? numberOfElements : size
          })
        );

  return totalElements === 0 ? (
    <div className="js-search-results-container biab_search-results-container biab_hide">
      <div
        className={classNames('biab_search-results-info js-search-results-info', styles.searchText)}
        dangerouslySetInnerHTML={{ __html: unescape(resultMessage) }}
      />
    </div>
  ) : null;
}

export default SearchResult;
