import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import GamesCardItem from 'components/GamesCardItem';
import { GAMES_STATUSES } from 'constants/games';
import { TGameData } from 'redux/modules/games/type';

import styles from './styles.module.scss';

interface GamePlayerProps {
  /**
   * Game type
   */
  gameType: string;
  /**
   * Selected game data (Blackjack, Poker)
   */
  gameData: TGameData[];
  /**
   * Is component used on result page
   */
  isResult?: boolean;
}

const GamePlayers = ({ gameType, gameData, isResult = false }: GamePlayerProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.player}>
      {gameData.map(
        ({ name, status, properties, description }, index) =>
          index !== gameData.length - 1 && (
            <div className={classNames('biab_hand-wrapper', styles.player__wrapper)} key={name}>
              <div
                className={classNames(
                  `biab_games-player biab_hand biab_${gameType.toLowerCase() || ''}`,
                  [styles[`player__biab_${gameType.toLowerCase()}`]],
                  styles.player__hand,
                  {
                    [styles.player__hand__result]: isResult
                  }
                )}
              >
                <div
                  className={classNames(
                    `biab_games-poker-player-status biab_${status.toLowerCase()}`,
                    styles.player__status
                  )}
                >
                  {GAMES_STATUSES.includes(status) && <>-{t(`games.statuses.poker.${status}`)}-</>}
                </div>
                <div className={classNames('biab_games-player-name', styles.player__name)}>{name}</div>
                <div className={classNames('biab_games-player-cards', styles.player__cards)}>
                  <GamesCardItem
                    properties={properties}
                    type={gameType}
                    classes={{ cardItem: styles.player__cardItem, cardUnavailable: styles.player__unavailableCard }}
                  />
                </div>
                <div className={classNames('biab_games-player-description', styles.player__description)}>
                  {description}
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default GamePlayers;
