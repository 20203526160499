export enum IFrameMessageType {
  AUTHENTICATION = 'authentication',
  LOGGED_IN = 'loggedIn',
  CHANGE_LOCALE = 'changeLocale',
  CHANGE_TIMEZONE = 'changeTimezone',
  LOGGED_OUT = 'loggedOut',
  LOGIN_TO_PLACE_BET = 'loginToPlaceBet',
  CHECK_ORIGIN = 'checkOrigin',
  USER_BALANCE = 'userBalance'
}
