import { ROOT_URL } from 'constants/locations';
import { NavigationType } from 'redux/modules/appConfigs/type';
import { getDefaultLinkTarget } from 'utils/navigation';

interface PageLinksProps {
  /**
   * Page links which should be rendered
   */
  data: NavigationType[];
  isIframeEnabled?: boolean;
}

const PageLinks = ({ data, isIframeEnabled = false }: PageLinksProps) => {
  return (
    <div className="notFoundPage-linksWrapper">
      <ul className="notFoundPage-links">
        {data.map(({ orderNumber, url, openInNewTab, label, absolutePath }: NavigationType) => (
          <li key={orderNumber} className="notFoundPage-links__item">
            <a
              href={`${!absolutePath ? ROOT_URL : ''}${url}`}
              target={getDefaultLinkTarget({
                url: `${!absolutePath ? ROOT_URL : ''}${url}`,
                absolutePath,
                isIframeEnabled,
                currentTarget: openInNewTab ? '_blank' : '',
                openInNewTab
              })}
            >
              {label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PageLinks;
