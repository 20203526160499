import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  getMobileSettingsLineMarketsSwitchBackLayOnCricket,
  getMobileSettingsReplaceBackLayWithUnderOver
} from 'redux/modules/appConfigs/selectors';
import { TStatementContentItem } from 'redux/modules/myBets/type';
import { statementSideValue } from 'utils/myBetsValues';

import styles from '../../styles.module.scss';

interface MobileStatementDescriptionProps {
  item: TStatementContentItem;
}

const MobileStatementDescription = ({ item }: MobileStatementDescriptionProps) => {
  const { t } = useTranslation();

  const lineMarketsSwitchBackLayOnCricket = useSelector(getMobileSettingsLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getMobileSettingsReplaceBackLayWithUnderOver);

  const placementLabel = t(`account.mybets.labels.${item.inPlay ? 'live' : 'prePlay'}Placement`);

  return (
    <>
      {t('account.mybets.labels.description')}
      <div className={classNames(styles.descriptionFieldStatement, 'biab_description-field-value')}>
        <>
          <div>
            {item.description}
            {item.triggeredByCashOut ? ` | ${t('account.statement.labels.triggeredByCashOut')}` : ''}
          </div>
          <div className={styles.betTypeRow}>
            {item.side && (
              <strong className={classNames(styles.betTypeRow__strong, styles.betTypeRow__capitalise)}>
                {item.side
                  ? t(statementSideValue(item, lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver))
                  : ''}
              </strong>
            )}
            {item.side && item.asian && ` | ${placementLabel}`}
          </div>
        </>
      </div>
    </>
  );
};

export default MobileStatementDescription;
