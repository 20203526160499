import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { asianViewBetslipBranding } from 'constants/branding';
import { ERROR_BET_IS_EXPIRED, VALIDATION_ERROR_BET_OUTDATED_ODDS } from 'constants/placement';
import { removeMobilePlacedBet } from 'redux/modules/asianViewMobileBetslip';
import { AsianViewMobilePlacedBetStatuses, TAsianMobilePlacedBet } from 'redux/modules/asianViewMobileBetslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { OddsType } from 'types';
import { BetTypes } from 'types/bets';
import { getMarketTypes } from 'utils/market';
import convertOdds from 'utils/oddsConverter';

import styles from './styles.module.scss';

interface IAsianViewMobilePlacedBetMessage {
  bet: TAsianMobilePlacedBet;
  offer: TCurrentBet | undefined;
}
const AsianViewMobilePlacedBetMessage = ({ bet, offer }: IAsianViewMobilePlacedBetMessage) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isBack = (offer?.side ?? bet.betType) === BetTypes.BACK;
  const marketName = offer?.marketName ?? bet.marketName;
  const selectionName = offer?.selectionName ?? bet.selectionName;
  const score = offer?.score ?? bet.score;
  const oddsType = offer?.oddsType ?? OddsType.DEC;
  const oddsValue = convertOdds(offer?.averagePrice || bet.price || '', oddsType);
  const oddsTypeLabel = t(`asianView.labels.betslip.oddsType.${oddsType}`);
  const { isAsianHandicap } = getMarketTypes(offer?.marketType ?? '', offer?.bettingType ?? '');

  const isPlaced =
    bet.offerStatus === BetsStatusesTypes.MATCHED &&
    offer &&
    ((+offer.sizeMatched > 0 && +offer.sizeRemaining === 0) ||
      (offer.offerState === BetsStatusesTypes.MATCHED && +offer.sizeRemaining === 0));

  const isPlacedWithBetterOdds =
    isPlaced && (isBack ? offer?.averagePrice > offer?.price : offer?.averagePrice < offer?.price);

  const isCancelled = bet.offerStatus === BetsStatusesTypes.CANCELLED;
  const isError = bet.status === AsianViewMobilePlacedBetStatuses.ERROR;
  const isExpired = bet.offerStatus === BetsStatusesTypes.EXPIRED;

  const messageParams = { odds: oddsValue, oddsType: oddsTypeLabel };

  let placedMessageText = '';

  if (isExpired) {
    placedMessageText = t(ERROR_BET_IS_EXPIRED);
  } else if (isError) {
    placedMessageText = bet.placementError ?? '';
  } else if (isCancelled) {
    placedMessageText = t(VALIDATION_ERROR_BET_OUTDATED_ODDS);
  } else if (isPlacedWithBetterOdds) {
    placedMessageText = t('asianView.labels.betslip.successPlacedBetWithBetterOdds', messageParams);
  } else if (isPlaced) {
    placedMessageText = t('asianView.labels.betslip.successPlacedBet', messageParams);
  }

  const onClose = () => dispatch(removeMobilePlacedBet(bet.offerId || bet.identifier));

  return (
    <div
      className={classNames(styles.message, {
        [asianViewBetslipBranding.ERROR_MSG]: isError || isCancelled,
        [asianViewBetslipBranding.PLACED_MSG]: isPlaced,
        [asianViewBetslipBranding.PLACED_BETTER_MSG]: isPlacedWithBetterOdds,
        [styles.message__success]: isPlaced,
        [styles.message__error]: isError || isCancelled
      })}
    >
      <i
        className={classNames(styles.message__icon, {
          'av-icon av-icon-checkmark': isPlaced,
          'biab_fav-icons-active': isPlacedWithBetterOdds,
          'fa2 fa2-removed': isError || isCancelled,
          [styles.message__icon__success]: isPlaced || isPlacedWithBetterOdds,
          [styles.message__icon__error]: isError || isCancelled
        })}
      >
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
      </i>
      <div className={styles.message__content}>
        <h4 className={styles.message__title}>{offer?.eventName ?? bet.eventName}</h4>
        <div>
          <span>{t(isBack ? 'asianView.labels.betslip.backBetFor' : 'asianView.labels.betslip.layBetAgainst')}:</span>
          <span className={styles.message__marketName}>
            {marketName} - {selectionName}
          </span>
          {isAsianHandicap && score && <span className={styles.message__score}>({score})</span>}
        </div>
        {placedMessageText && (
          <div className={styles.message__text} dangerouslySetInnerHTML={{ __html: unescape(placedMessageText) }} />
        )}
      </div>
      <i onClick={onClose} className={classNames('biab_tour-icon-close', styles.message__close)} />
    </div>
  );
};

export default AsianViewMobilePlacedBetMessage;
