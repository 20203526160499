import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { PROGRESS_BAR_INTERVAL } from 'constants/app';
import { betslipBranding } from 'constants/branding';
import { setUnmatchedBetProgress } from 'redux/modules/betslip';
import { getUnmatchedBetProgress } from 'redux/modules/betslip/selectors';

import styles from './ProgressBar.module.scss';

type ProgressBarProps = {
  label: string;
  containerClassName?: string;
  offerId: number;
};

const UnmatchedBetProgressBar = ({ label, containerClassName = '', offerId }: ProgressBarProps) => {
  const dispatch = useDispatch();

  const progress = useSelector(getUnmatchedBetProgress(offerId));

  const progressInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const progressValueRef = useRef(progress);

  progressValueRef.current = progress;

  useEffect(() => {
    if (!progressInterval.current && progress !== 90) {
      progressInterval.current = setInterval(() => {
        dispatch(setUnmatchedBetProgress({ offerId, progress: progressValueRef.current + 10 }));
      }, PROGRESS_BAR_INTERVAL);
    }

    return () => {
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    if (progressInterval.current && progress === 90) {
      clearInterval(progressInterval.current);
    }
  }, [progress]);

  return (
    <div className={classNames(styles.progress__container, containerClassName, betslipBranding.UNMATCHED_PROGRESS)}>
      <div
        className={classNames(styles.progress__bar, betslipBranding.UNMATCHED_PROGRESS_BAR)}
        style={{ width: `${progress}%` }}
      />
      <p className={styles.progress__label}>{label}</p>
    </div>
  );
};

export default UnmatchedBetProgressBar;
