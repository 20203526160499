import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import GameHiloCardItem from 'components/GameHiloCardItem';
import GameHiLoCounter from 'components/GameHiLoCounter';
import GameHiLoPrediction from 'components/GameHiLoPrediction';
import GameLoader from 'components/GameLoader';
import { getCurrentGameRoundPercent } from 'redux/modules/games/selectors';
import { TGameData } from 'redux/modules/games/type';
import { generateArray } from 'utils/general';

import styles from './styles.module.scss';

interface IGameCartsTableHiLo {
  game: TGameData;
}

const GameCartsTableHiLo = ({ game }: IGameCartsTableHiLo) => {
  const { t } = useTranslation();
  const percent = useSelector(getCurrentGameRoundPercent);
  const cartsPlaces = generateArray(13);

  return (
    <div className={classNames(styles.hiloMain, 'biab_hilo-game-wrapper')}>
      <div className={classNames(styles.description, 'biab_game-description')}>{t('games.hilo.game_description')}</div>
      <GameLoader classes={[styles.always100]} loaderPercentage={percent || 0} />
      <div className={styles.cards}>
        {cartsPlaces.map((item, index) => (
          <GameHiloCardItem index={index} info={game.properties[index]} key={index} />
        ))}
      </div>
      <GameHiLoPrediction status={game.description} />
      <GameHiLoCounter properties={game.properties} />
    </div>
  );
};

export default GameCartsTableHiLo;
