import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface GamesRoundsListProps {
  gamesRounds: (string | number)[];
  currentRound: number;
}

const GamesRoundsList = ({ gamesRounds, currentRound }: GamesRoundsListProps) => {
  const { t } = useTranslation();
  const isNumbersRounds = gamesRounds.every(r => typeof r === 'number');

  return (
    <ul className={classNames('biab_games-rounds-list', styles.roundsList)}>
      {isNumbersRounds && <li className={'biab_games-round-label'}>{t('games.round')}</li>}
      {gamesRounds.map((round, index) => (
        <li
          className={classNames({
            biab_active: index + 1 === currentRound
          })}
          key={round}
        >
          {isNumbersRounds ? round : t('games.rounds.' + round)}
        </li>
      ))}
    </ul>
  );
};

export default GamesRoundsList;
