import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { SPORT_BASE_URL } from 'constants/locations';
import { TNavigationResponse } from 'redux/modules/competitions/type';
import { setMarketShowAll } from 'redux/modules/market';

import RaceContent from '../RaceContent';

import styles from './styles.module.scss';

interface RaceCardContentProps {
  raceData: TNavigationResponse | null;
}

const RaceCardContent = ({ raceData }: RaceCardContentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { marketId: selectedMarketId = '', sportId = '' } = useParams();

  const selectedMarketRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (selectedMarketRef.current) {
      selectedMarketRef.current?.scrollIntoView({ inline: 'center', block: 'nearest' });
    }
  }, [selectedMarketId]);

  const handlerClick = (marketId: string) => {
    dispatch(setMarketShowAll(false));
    navigate(
      { pathname: `${SPORT_BASE_URL}/${sportId}/market/${marketId}`, search: location.search },
      { replace: true }
    );
  };

  return (
    <>
      <div
        className={classNames('biab_list-view-tabs-wrapper', styles.raceCardContent__tabs__wrapper)}
        onTouchStart={e => {
          e?.stopPropagation();
          e?.preventDefault();
        }}
        onPointerDown={e => {
          e?.stopPropagation();
          e?.preventDefault();
        }}
      >
        <ul className={classNames('biab_list-view-tabs', styles.raceCardContent__tabs)}>
          {raceData?.children.map(({ id: marketId, name }) => {
            return (
              <li
                key={marketId}
                ref={marketId === selectedMarketId ? selectedMarketRef : undefined}
                className={classNames(styles.raceCardContent__tabs__item, {
                  biab_active: marketId === selectedMarketId,
                  [styles.raceCardContent__tabs__item__active]: marketId === selectedMarketId
                })}
                onClick={() => handlerClick(marketId)}
              >
                {name}
              </li>
            );
          })}
        </ul>
      </div>
      <RaceContent />
    </>
  );
};

export default RaceCardContent;
