import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';

import { TNavigationResponse } from '../competitions/type';

import { TSports } from './type';

const initialState: TSports = {
  sportList: [],
  loading: false,
  error: null
};

const slice = createSlice({
  name: SLICES_NAMES.SPORTS,
  initialState,
  reducers: {
    fetchSports: state => {
      state.loading = true;
    },
    successGetSports: (state, { payload }: PayloadAction<TNavigationResponse[]>) => {
      state.sportList = payload;
      state.loading = false;
    },
    failureGetSports: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
    }
  }
});

export const { fetchSports, successGetSports, failureGetSports } = slice.actions;

export default slice.reducer;
