import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import styles from 'components/EventHeaderMobileInPlay/styles.module.scss';
import FavoriteStar from 'components/FavoriteStar';
import { FAVORITES_TYPES } from 'constants/app';
import { SPORT_BASE_URL } from 'constants/locations';
import { MARKET_TYPES } from 'constants/marketTypes';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { IDropdownMarketItem } from 'redux/modules/market/type';

const DropdownItem = (item: IDropdownMarketItem) => {
  const navigate = useNavigate();
  const { sportId } = useParams();
  const { t } = useTranslation();

  const isFavorite = useSelector(getFavoriteById(item.marketId));
  const domainLanguage = useSelector(getTranslation);

  const isShowFavorites = item.marketType !== MARKET_TYPES.dailyGoals;

  const favoriteData = {
    entryId: item.marketId,
    starred: !isFavorite,
    entryName: item.name,
    entryType: FAVORITES_TYPES.market,
    sportId: sportId as string
  };

  const handleItemClick = (marketId: string) => {
    navigate(`${SPORT_BASE_URL}/${sportId}/market/${marketId}`);
  };

  const formattedDate = useLongDateFormat({
    time: item.marketStartTime,
    hasWeekDay: true
  });

  return (
    <li
      onClick={() => handleItemClick(item.marketId)}
      className={classNames(styles.dropdownItem, 'biab_event-dropdown-item biab_nav-filters-dropdown-item')}
    >
      <span className={styles.nameHolder}>
        {item.translations[domainLanguage] || item.name}
        {isShowFavorites && (
          <div className={styles.nameHolder__favouriteIcon}>
            <FavoriteStar entryData={favoriteData} className={styles.eventMarket__starIcon} />
          </div>
        )}
      </span>
      {item.inPlay ? (
        <div className="biab_market-in-play-icon">
          <i className="fa2 fa2-inplay" title={t('header.inPlay')} />
          <span className={classNames('biab_market-in-play-label', styles.eventHeader__inPlay)}>
            {t('competition.view.tabs.inPlay')}
          </span>
        </div>
      ) : (
        <span className="biab_market-date">{formattedDate}</span>
      )}
    </li>
  );
};

export default DropdownItem;
