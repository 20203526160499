import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { FAVORITES_TYPES } from 'constants/app';
import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM,
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_POPULAR_LINK_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_COMPETITION_ID,
  ASIAN_VIEW_LEAGUES_FILTER
} from 'constants/asianView';
import { getLeaguesCurParams, getSavedSelectedLeagues } from 'redux/modules/asianViewLeaguesFilter/selectors';

const useLeaguesParam = () => {
  const [searchParams] = useSearchParams();

  const curLeaguesParam = useSelector(getLeaguesCurParams);
  // TODO should be used instead of savedLeagues from localStorage, need to rewrite some logic for leagues filter
  useSelector(getSavedSelectedLeagues);

  const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
  const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
  const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);
  const isFavouriteView = !!(favouriteType && favouriteId && favouriteSportId);
  const savedLeagues = localStorage.getItem(ASIAN_VIEW_LEAGUES_FILTER);
  const popularLinkCompetitionId = searchParams.get(ASIAN_POPULAR_LINK_COMPETITION_ID);
  const singleViewCompetitionId = searchParams.get(ASIAN_SINGLE_VIEW_COMPETITION_ID);
  const isPopularLinkView = !!popularLinkCompetitionId;
  const outrightCompetitionId = searchParams.get(ASIAN_OUTRIGHT_COMPETITION_ID);
  const isSingleOutrightView = !!outrightCompetitionId;

  return useMemo(() => {
    if (singleViewCompetitionId) {
      return [singleViewCompetitionId];
    }

    if (isPopularLinkView) {
      return [popularLinkCompetitionId];
    }

    if (isFavouriteView) {
      if (favouriteType === FAVORITES_TYPES.competition) {
        return [favouriteId];
      }

      return [];
    }

    if (isSingleOutrightView) {
      return [outrightCompetitionId];
    }

    return savedLeagues ? JSON.parse(savedLeagues)?.[curLeaguesParam]?.ids ?? [] : [];
  }, [
    curLeaguesParam,
    savedLeagues,
    isFavouriteView,
    favouriteId,
    favouriteType,
    isPopularLinkView,
    popularLinkCompetitionId,
    singleViewCompetitionId,
    isSingleOutrightView,
    outrightCompetitionId
  ]);
};

export default useLeaguesParam;
