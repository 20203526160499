import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { MARKET_TABS_BACCARAT, MARKET_TABS_MAIN } from 'constants/games';
import { setSelectedMarket } from 'redux/modules/games';
import { getCurrentGameType, getSelectedMarket } from 'redux/modules/games/selectors';
import { EGameTypes } from 'redux/modules/games/type';

import styles from './styles.module.scss';

const MarketsTabs = () => {
  const selectedMarket = useSelector(getSelectedMarket);
  const gameType = useSelector(getCurrentGameType);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSelectedMarket(0));
    };
  }, []);

  return (
    <div className={classNames('biab_games-markets-list', styles.marketTabsWrapper)}>
      {(gameType === EGameTypes.BACCARAT || gameType === EGameTypes.OMAHA_POKER
        ? MARKET_TABS_BACCARAT
        : MARKET_TABS_MAIN
      ).map(({ translationKey }, index) => (
        <div
          key={translationKey}
          onClick={() => dispatch(setSelectedMarket(index))}
          className={classNames('biab_games-markets-item', styles.marketTab, {
            ['biab_active']: selectedMarket === index
          })}
        >
          {t(translationKey)}
        </div>
      ))}
    </div>
  );
};

export default MarketsTabs;
