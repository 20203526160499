import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ASIAN_VIEW_LEAGUES_FILTER } from 'constants/asianView';
import { asianViewDropdown as branding, asianViewMiddleSection } from 'constants/branding';
import { getIsLandscapeAsianView, getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { setIsLeaguesOpened } from 'redux/modules/asianViewLeaguesFilter';
import {
  getLeaguesAmounts,
  getLeaguesCurParams,
  getLeaguesOpened
} from 'redux/modules/asianViewLeaguesFilter/selectors';

import styles from './styles.module.scss';

const LeaguesPlaceholder = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isOpened = useSelector(getLeaguesOpened);
  const { selectedInit, total } = useSelector(getLeaguesAmounts);
  const curParam = useSelector(getLeaguesCurParams);
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);

  const savedLeaguesToStore = JSON.parse(localStorage.getItem(ASIAN_VIEW_LEAGUES_FILTER) ?? '{}') ?? {};
  const savedLeaguesByCurParams = savedLeaguesToStore?.[curParam]?.ids ?? [];
  const isSavedLeagues = savedLeaguesByCurParams.length > 0;

  const isLeaguesSelected = !!selectedInit || !isSavedLeagues;
  const isAllLeaguesSelected = selectedInit === total || !isSavedLeagues;
  const isActive = !isMobileAsianView && isOpened;

  return (
    <div
      className={classNames({
        [styles.placeholder]: !isMobileAsianView,
        [styles.placeholder__active]: isActive,
        [styles.mobilePlaceholder]: isMobileAsianView,
        [styles.mobilePlaceholder__active]: isMobileAsianView && !isAllLeaguesSelected,
        [branding.dropdownBtn]: !isMobileAsianView,
        [branding.active]: isActive,
        [asianViewMiddleSection.MOBILE_SUB_HEADER_BTN]: isMobileAsianView,
        [asianViewMiddleSection.MOBILE_SUB_HEADER_ACTIVE_BTN]: !isAllLeaguesSelected
      })}
      onClick={() => dispatch(setIsLeaguesOpened(!isOpened))}
    >
      {!isMobileAsianView ? (
        <>
          {isLeaguesSelected && <i className="av-icon av-icon-tick" />}
          <span>
            {isAllLeaguesSelected
              ? t('asianView.labels.leaguesFilter.allLeagues')
              : `(${selectedInit}/${total}) ${t('asianView.labels.leaguesFilter.leagues')}`}
          </span>
        </>
      ) : (
        <>
          <i
            className={classNames('av-icon av-icon-competition', styles.competitionIcon, {
              [styles.competitionIcon__active]: !isAllLeaguesSelected
            })}
          />
          {isLandscapeAsianView && (
            <span>
              {isAllLeaguesSelected
                ? t('asianView.labels.leaguesFilter.allLeagues')
                : `(${selectedInit}/${total}) ${t('asianView.labels.leaguesFilter.leagues')}`}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default LeaguesPlaceholder;
