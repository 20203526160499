export const STARTING_SOON = 'market.startingSoon';
export const STARTING_ONE_MIN = 'market.startingIn1Min';
export const STARTING_IN_MIN = 'market.startingInMin';
export const STARTING_TODAY = 'dates.today';
export const STARTING_TOMORROW = 'dates.tomorrow';
export const STARTING_DAY = 'dates.day.';
export const STARTING_SHORT_DAY = 'dates.shortDay.';
export const STARTING_MONTH = 'dates.month.';
export const STARTING_SHORT_MONTH = 'dates.shortMonth.';

export const PERIOD_TABS = [
  { title: STARTING_TODAY, id: 'TODAY' },
  { title: STARTING_TOMORROW, id: 'TOMORROW' },
  { title: `${STARTING_DAY}${new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).getDay()}`, id: 'AFTER_TOMORROW' }
];

export const ONE_SECOND = 1000;
export const ONE_MINUTE_IN_MS = 60 * 1000;
export const ONE_HOUR_IN_MS = 60 * 60 * 1000;
export const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

export const MINUTES_IN_AN_HOUR = 60;
const HOURS_IN_A_DAY = 24;
const DAYS_IN_A_WEEK = 7;
const DAYS_IN_A_MONTH = 30;
const DAYS_IN_A_YEAR = 365;

export const MINUTES_IN_A_DAY = MINUTES_IN_AN_HOUR * HOURS_IN_A_DAY;
export const MINUTES_IN_A_WEEK = MINUTES_IN_A_DAY * DAYS_IN_A_WEEK;
export const MINUTES_IN_A_MONTH = MINUTES_IN_A_DAY * DAYS_IN_A_MONTH;
export const MINUTES_IN_A_YEAR = MINUTES_IN_A_DAY * DAYS_IN_A_YEAR;
