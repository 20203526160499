import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  BetStatuses,
  BetTypes,
  currentTabButtons,
  ExchangeTypes,
  pastTabButtons,
  Period,
  periodTabButtons
} from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import MultiCurrencyDropdown from 'pages/MyBetsPages/components/MultiCurrencyDropdown';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { setEndDate, setPageNumber, setStartDate } from 'redux/modules/myBets';
import { betDetails, getStartDate } from 'redux/modules/myBets/selectors';
import { CookieNames } from 'types';
import { TGetBetAndPageDataParams } from 'types/myBets';
import { getThreeMonthsAgo, getTodayDate } from 'utils/date';

import Tab from '../Tab';

import styles from './styles.module.scss';

interface TabsProps {
  setActivePeriodTab: (period: Period) => void;
  setActiveStatusTab: (tab: BetStatuses) => void;
  activePeriodTab?: string;
  activeStatusTab?: string;
  getBetData: (options: TGetBetAndPageDataParams) => void;
}

const Tabs = ({ setActivePeriodTab, setActiveStatusTab, activePeriodTab, activeStatusTab, getBetData }: TabsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE_NEW]);

  const startDate = useSelector(getStartDate);
  const mobileBetDetails = useSelector(betDetails);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);

  const { isMultiCurrencySupported } = useMultiCurrencySupporting();
  const { isDesktop, isMobile } = useDevice();

  const isAsianViewTab = cookies.EXCHANGE_TYPE_NEW === ExchangeTypes.AsianView;
  const showTabs = !mobileBetDetails || isDesktop;
  const showMultiCurrencyDropdown = isMultiCurrencySupported && !isDesktop && !isAsianViewTab;

  const changePeriodTab = (tab: Period) => {
    const threeMonthsAgo = getThreeMonthsAgo();
    dispatch(setPageNumber(0));
    setActivePeriodTab(tab);
    if (tab !== Period.Current && startDate < threeMonthsAgo) {
      dispatch(setStartDate(threeMonthsAgo));
      dispatch(setEndDate(getTodayDate()));
    }
  };

  const changeTab = (tab: BetStatuses) => {
    setActiveStatusTab(tab);
  };

  useEffect(() => {
    if (activeStatusTab) {
      getBetData({ number: 0, betsType: activeStatusTab.toLocaleLowerCase() as BetTypes });
      dispatch(setPageNumber(0));
    }
  }, [activeStatusTab]);

  return (
    <>
      {showTabs && (
        <div
          className={classNames('biab_table-tabs', styles.tabs, {
            [styles.tabs__column]: (showMultiCurrencyDropdown || activePeriodTab === Period.Past) && isMobile,
            [styles.tabs__mobile]: isMobile
          })}
        >
          <div
            className={classNames({ [styles.tabs__multiCurrencyAndPeriods]: showMultiCurrencyDropdown && isMobile })}
          >
            <ul
              className={classNames(styles.tabButtons, 'biab_tab-btns', {
                [styles.tabButtons__mobile]: isMobile
              })}
            >
              {periodTabButtons.map((tab, index) => (
                <Tab
                  key={tab}
                  tab={tab}
                  tabName={t(`account.mybets.btns.${tab.toLowerCase()}`)}
                  setActiveTabButton={changePeriodTab}
                  activeTabButton={activePeriodTab}
                  classname={index === 0 ? styles.firstTab : styles.lastTab}
                />
              ))}
            </ul>
            {showMultiCurrencyDropdown && (
              <MultiCurrencyDropdown fetchDataWithCurrencyHandlers={{ fetchBetDataHandler: getBetData }} />
            )}
          </div>
          <ul
            className={classNames(styles.tableContentTabs, 'biab_table-content biab_tab-btns', {
              [styles.tableContentTabs__mobile]: isMobile
            })}
          >
            {activePeriodTab === Period.Current ? (
              <>
                {!isPNCEnabled &&
                  !isAsianViewTab &&
                  currentTabButtons.map((tab, index) => (
                    <Tab
                      key={tab}
                      tab={tab}
                      tabName={t(`account.mybets.btns.${tab.toLowerCase()}`)}
                      setActiveTabButton={changeTab}
                      activeTabButton={activeStatusTab}
                      classname={classNames({
                        [styles.firstTab]: index === 0,
                        [styles.lastTab]: index === currentTabButtons.length - 1
                      })}
                    />
                  ))}
              </>
            ) : (
              <>
                {pastTabButtons.map(
                  (tab, index) =>
                    (!isAsianViewTab || tab !== 'Lapsed') && (
                      <Tab
                        key={tab}
                        tab={tab}
                        tabName={t(`account.mybets.btns.${tab.toLowerCase()}`)}
                        setActiveTabButton={changeTab}
                        activeTabButton={activeStatusTab}
                        classname={classNames({
                          [styles.firstTab]: index === 0,
                          [styles.lastTab]: index === pastTabButtons.length - 1
                        })}
                      />
                    )
                )}
              </>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default Tabs;
