import { useSelector } from 'react-redux';

import { getCurrencies, getCurrency, getMultiCurrencySupported } from 'redux/modules/appConfigs/selectors';

export const useCurrency = (currencyCode = 'USD') => {
  const currency = useSelector(getCurrency);
  const currencies = useSelector(getCurrencies);
  const multiCurrencySupported = useSelector(getMultiCurrencySupported);

  return multiCurrencySupported ? currencies.find(item => currencyCode === item.currencyCode) || currency : currency;
};
