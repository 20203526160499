import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { MOBILE_PLACEMENT_PROGRESS_INTERVAL } from 'constants/asianView';
import { asianViewBetslipBranding } from 'constants/branding';

import styles from './styles.module.scss';

const MobileProgress = () => {
  const { t } = useTranslation();

  const [progress, setProgress] = useState(0);

  const progressInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (!progressInterval.current) {
      progressInterval.current = setInterval(
        () => setProgress(prevState => prevState + 10),
        MOBILE_PLACEMENT_PROGRESS_INTERVAL
      );
    }

    return () => {
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    if (progressInterval.current && progress === 90) {
      clearInterval(progressInterval.current);
    }
  }, [progress]);

  return (
    <div className={classNames(styles.wrap, asianViewBetslipBranding.PROGRESS_BAR)}>
      <div
        className={classNames(styles.progress, asianViewBetslipBranding.PROGRESS_BAR_INDICATOR)}
        style={{ width: `${progress}%` }}
      />
      <span className={styles.text}>{t('asianView.labels.betslip.progressMessage')}</span>
    </div>
  );
};

export default MobileProgress;
