import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import AsianViewMobileMenuButton from 'components/AsianViewMobileHeader/partials/AsianViewMobileMenuButton';
import CouponSettings from 'components/AsianViewPageModule/components/CouponSettings';
import MobileToolbar from 'components/MobileToolbar';
import { ASIAN_SINGLE_VIEW_SPORT_ID } from 'constants/asianView';
import { asianViewMiddleSection } from 'constants/branding';
import { IconsConfig } from 'constants/iconsConfig';
import {
  getAsianViewMobileNavigationMenu,
  getPositionOfAsianViewMobileNavigationMenu,
  getTranslation
} from 'redux/modules/appConfigs/selectors';
import { getSportBySportId } from 'redux/modules/asianSportsNavigation/selectors';
import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import { SportId } from 'types';

import styles from './styles.module.scss';

const AsianViewMobileSingleViewHeader = () => {
  const [searchParams] = useSearchParams();
  const { sportId = SportId.SOCCER } = useParams();

  const domainLanguage = useSelector(getTranslation);
  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);
  const positionOfAsianViewMobileNavigationMenu = useSelector(getPositionOfAsianViewMobileNavigationMenu);
  const asianViewMobileNavigationMenu = useSelector(getAsianViewMobileNavigationMenu);
  const isLandscapeToolbar = isLandscapeAsianView && asianViewMobileNavigationMenu;
  const isMobileToolbar = !isLandscapeAsianView && asianViewMobileNavigationMenu;

  const singleViewSportId = searchParams.get(ASIAN_SINGLE_VIEW_SPORT_ID);
  const isLeftNav = positionOfAsianViewMobileNavigationMenu && asianViewMobileNavigationMenu;

  const sport = useSelector(getSportBySportId(singleViewSportId ?? (sportId || '')));

  return (
    <div className={styles.singleViewHeader__wrapper}>
      <div className={classNames(styles.singleViewHeader__container, asianViewMiddleSection.MOBILE_SUB_HEADER)}>
        {isLandscapeToolbar && (
          <div className={styles.singleViewHeader__mobileToolbar}>
            <MobileToolbar noActiveItems />
          </div>
        )}
        <div
          className={classNames(styles.singleViewHeader, {
            [styles.singleViewHeader__withLeftNav]: isLandscapeToolbar,
            [styles.singleViewHeader__withMenuButton]: isLeftNav && !isLandscapeToolbar
          })}
        >
          <div className={styles.singleViewHeader__left}>
            <AsianViewMobileMenuButton />
            <i
              className={classNames(
                'biab_br-sport-icon',
                IconsConfig.SPORT_ICONS[sportId],
                styles.singleViewHeader__icon
              )}
            />
            <p className={styles.singleViewHeader__label}>{sport?.translations?.[domainLanguage]}</p>
          </div>
          <CouponSettings />
        </div>
      </div>
      {isMobileToolbar && (
        <div className={styles.singleViewHeader__mobileToolbar}>
          <MobileToolbar noActiveItems />
        </div>
      )}
    </div>
  );
};

export default AsianViewMobileSingleViewHeader;
