import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { PopupTypes } from 'constants/app';
import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM
} from 'constants/asianView';
import { asianViewIcons as branding } from 'constants/branding';
import { removeAsianViewFavourite, saveAsianViewFavourite } from 'redux/modules/asianViewFavourites';
import { getIsFavouriteById } from 'redux/modules/asianViewFavourites/selectors';
import { AsianViewFavouritePayload, AsianViewFavouriteResponse } from 'redux/modules/asianViewFavourites/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setLoginPopup } from 'redux/modules/popup';

import styles from './styles.module.scss';

interface AsianViewFavouriteStarProps {
  containerClassName?: string;
  iconClassName?: string;
  favourite: AsianViewFavouriteResponse;
  onRemoveFavorite?: (favourite?: AsianViewFavouritePayload) => void;
}

const AsianViewFavouriteStar = ({
  containerClassName = '',
  iconClassName = '',
  favourite,
  onRemoveFavorite
}: AsianViewFavouriteStarProps) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isStarred = useSelector(getIsFavouriteById(favourite.entryId));

  const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
  const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
  const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);
  const isFavouriteView = !!(favouriteType && favouriteId && favouriteSportId);

  const handleToggleFavourite = () => {
    if (isLoggedIn) {
      if (isStarred) {
        dispatch(
          removeAsianViewFavourite({
            favourite: {
              ...favourite,
              starred: false
            },
            onSuccess: onRemoveFavorite
          })
        );

        if (isFavouriteView && favouriteId === favourite.entryId) {
          searchParams.delete(ASIAN_FAVOURITE_ID_PARAM);
          searchParams.delete(ASIAN_FAVOURITE_TYPE_PARAM);
          searchParams.delete(ASIAN_FAVOURITE_SPORT_ID_PARAM);
          setSearchParams(searchParams, { replace: true });
        }
      } else {
        dispatch(
          saveAsianViewFavourite({
            favourite: {
              ...favourite,
              starred: true
            }
          })
        );
      }
    } else {
      dispatch(setLoginPopup({ type: PopupTypes.FAVORITE }));
    }
  };

  return (
    <div className={classNames(styles.asianViewFavouriteStar, containerClassName)} onClick={handleToggleFavourite}>
      <i
        className={classNames(iconClassName, branding.FAVOURITE_ICON, {
          'biab_fav-icons-inactive': !isStarred,
          'biab_fav-icons-active biab_active': isStarred,
          [branding.ENABLED]: isStarred
        })}
        aria-hidden="true"
      />
    </div>
  );
};

export default AsianViewFavouriteStar;
