import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Loader, { CircleColors } from 'components/Loader';
import SEO from 'components/SEO';
import { GeneralWebSocketSubscriptionTypes } from 'constants/app';
import { IconsConfig } from 'constants/iconsConfig';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import EventsUpdatesInjection from 'injections/EventsUpdatesInjection';
import { getAppDevice, getInPlayPaginationSize, getTranslation } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { fetchInPlayMarkets, removeInPlayMarketsData } from 'redux/modules/inPlay';
import {
  getInPlayMarkets,
  getInPlayMarketsEventIds,
  getInPlayMarketsInfoLast,
  getInPlayMarketsSportIds,
  getLoading,
  getSportListLoading
} from 'redux/modules/inPlay/selectors';
import { resetSuspendedOrClosedMarketPrices } from 'redux/modules/marketsPrices';
import { getClosedMarketPrices } from 'redux/modules/marketsPrices/selectors';
import { MetaPage } from 'redux/modules/meta/type';
import { fetchSports } from 'redux/modules/sports';
import { getSportsList, getSportsLoading } from 'redux/modules/sports/selectors';
import { CookieNames, ViewBy } from 'types';
import { InPlayFilter } from 'types/inPlay';

import InPlaySportSection from '../InPlaySportSection';

import styles from './styles.module.scss';

interface InPlaySportsSectionsProps {
  /**
   * Tab that is passed as an id parameter to get markets on the InPlay page from the backend, can only be 'highlights'
   * or 'now'
   */
  tab: InPlayFilter.HIGHLIGHTS | InPlayFilter.NOW;

  /**
   * If the current filter on the InPlay page is a sport (not 'All' or 'Highlights') we must submit that sport's ID to
   * get markets from the backend
   */
  sportIdObj: { sportId?: string };

  /**
   * 'View by' sort value
   */
  viewBy: ViewBy;

  /**
   * Submit page number to get more markets for InPlay page
   */
  page: number;

  /**
   * Set state action for page number
   */
  setPage: Dispatch<SetStateAction<number>>;
}

const InPlaySportsSections = ({ tab, sportIdObj, viewBy, page, setPage }: InPlaySportsSectionsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [cookies, setCookie] = useCookies([CookieNames.IN_PLAY_COLLAPSED_SPORTS]);

  const device = useSelector(getAppDevice);
  const translation = useSelector(getTranslation);
  const inPlayPaginationSize = useSelector(getInPlayPaginationSize);
  const markets = useSelector(getInPlayMarkets);
  const sportList = useSelector(getSportsList);
  const sportListLoading = useSelector(getSportsLoading);
  const inPlaySportListLoading = useSelector(getSportListLoading);
  const loading = useSelector(getLoading);
  const last = useSelector(getInPlayMarketsInfoLast);
  const marketEventsIds = useSelector(getInPlayMarketsEventIds);
  const sportIds = useSelector(getInPlayMarketsSportIds);
  const closedMarketPrices = useSelector(getClosedMarketPrices);

  const isVisible = useRef(false);

  const stringifiedMarkets = JSON.stringify(markets);
  const collapsedList: string[] = cookies.BIAB_INPLAY_COLlAPSED_SPORTS?.split(',') ?? [];

  const infiniteScrollCallback = useCallback(() => {
    if (typeof last === 'boolean' && !last) {
      setPage(prevPage => prevPage + 1);
    }
  }, [last]);

  const setVisibility = (val: boolean) => {
    isVisible.current = val;
  };

  useEffect(() => {
    if (!loading && isVisible.current) {
      infiniteScrollCallback();
    }
  }, [loading]);

  const { lastElementRef } = useInfiniteScroll<HTMLDivElement>({ callback: infiniteScrollCallback, setVisibility });

  useEffect(() => {
    if (!sportList.length) {
      dispatch(fetchSports());
    }

    dispatch(
      fetchInPlayMarkets({
        id: tab,
        page: 0,
        size: inPlayPaginationSize,
        payload: { from: new Date().getTime(), viewBy, ...sportIdObj }
      })
    );
  }, []);

  useEffect(() => {
    if (page !== 0) {
      dispatch(
        fetchInPlayMarkets({
          id: tab,
          page,
          size: inPlayPaginationSize,
          payload: { from: new Date().getTime(), viewBy, ...sportIdObj },
          concatMarkets: true
        })
      );
    }
  }, [page]);

  useEffect(() => {
    if (closedMarketPrices && markets?.length) {
      const updatedMarkets = markets.filter(({ marketId }) => marketId !== closedMarketPrices.id);
      dispatch(resetSuspendedOrClosedMarketPrices());
      dispatch(removeInPlayMarketsData(updatedMarkets));
    }
  }, [closedMarketPrices, stringifiedMarkets]);

  const handleCollapse = useCallback(
    (isOpened: boolean, sportId: string) => {
      let newList: string[];

      if (isOpened) {
        newList = collapsedList.filter((item: string) => item !== sportId);
      } else {
        newList = [...collapsedList, sportId];
      }

      setCookie(CookieNames.IN_PLAY_COLLAPSED_SPORTS, newList.join(), { path: '/' });
    },
    [collapsedList]
  );

  const sportSections = useMemo(() => {
    return sportIds.map(sportId => {
      const sectionMarkets = markets?.filter(({ eventType }) => eventType.id === sportId) ?? [];
      const sport = sportList?.find(sportItem => sportItem.id === sportId);
      const isNotCollapsed = !collapsedList.includes(sportId);
      return {
        sectionMarkets,
        sport,
        isNotCollapsed,
        sportId
      };
    });
  }, [stringifiedMarkets, sportIds, sportList, collapsedList]);

  if ((loading || sportListLoading || inPlaySportListLoading) && page === 0) {
    return <Loader circleColor={CircleColors.BLACK} />;
  } else if (!markets?.length) {
    return (
      <>
        <SEO page={MetaPage.IN_PLAY} />
        <p className={styles.noMarkets}>{t('inplay.labels.noData')}</p>
      </>
    );
  }

  return (
    <>
      <EventsUpdatesInjection
        eventIds={marketEventsIds}
        subscriptionType={GeneralWebSocketSubscriptionTypes.eventInfoUpdates}
      />
      <div className="biab_inplay-sports-container">
        <div className="biab_group-markets biab_inplay-tab-content">
          {sportSections.map(({ sectionMarkets, sport, isNotCollapsed, sportId }) => {
            return (
              <InPlaySportSection
                key={`${sportId}${sectionMarkets.length}`}
                markets={sectionMarkets}
                viewBy={viewBy}
                onCollapse={(isOpened: boolean) => handleCollapse(isOpened, sportId)}
                defaultIsOpened={isNotCollapsed}
                title={sport?.translations?.[translation] ?? ''}
                className={device === Devices.DESKTOP ? 'biab_inplay-sport-item-title' : 'biab_inplay-sport-title'}
                collapseClasses={{
                  container: styles.collapse__container,
                  toggle: styles.collapse__toggle,
                  arrow: classNames('biab_expand', styles.collapse__toggle__arrow)
                }}
                mobileCollapseIcon={
                  <i className={classNames('biab_br-sport-icon', IconsConfig.SPORT_ICONS[sportId])} />
                }
              />
            );
          })}
          <div ref={lastElementRef} />
        </div>
      </div>
      {loading && page !== 0 && <Loader circleColor={CircleColors.BLACK} />}
    </>
  );
};

export default InPlaySportsSections;
