import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import PopularMarket from 'components/PopularMarket';
import RacingSport from 'components/RacingSport';
import SEO from 'components/SEO';
import MiddleSectionPageSkeleton from 'components/Skeleton/MiddleSectionPageSkeleton';
import SportPageModule from 'components/SportPageModule';
import SportsSection from 'components/SportsSection';
import { RACING_SPORT } from 'constants/app';
import { SPORT_BASE_URL } from 'constants/locations';
import useElementSize from 'hooks/useElementSize';
import { useEnabledView } from 'hooks/useEnabledView';
import FancyMarketPage from 'pages/FancyMarketPage';
import SportEvent from 'pages/SportEvent';
import Top5View, { ALL_MARKETS_TAB } from 'pages/Top5View';
import { getAppDevice, getIsShowMarketOdd } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  getCompetitionLoading,
  getCountryLoading,
  getEventDataLoading,
  getGroupLoading,
  getMarketLoading,
  getRaceLoading,
  getSportLoading,
  getTodayCardPageLoading
} from 'redux/modules/competitions/selectors';
import { fetchEventGroup } from 'redux/modules/event';
import { getEventGroup } from 'redux/modules/event/selectors';
import { getFancyMarketLoading } from 'redux/modules/fancyMarket/selectors';
import { getSingleMarketEventId } from 'redux/modules/market/selectors';
import { MetaPage } from 'redux/modules/meta/type';
import {
  fetchMultiMarketTopDetails,
  resetMultiMarketTopDetails,
  setMultiMarketRedirection
} from 'redux/modules/multiMarket';
import { getMultiMarketDirection, getMultiMarketTop } from 'redux/modules/multiMarket/selectors';
import { fetchPopularMarkets } from 'redux/modules/popularMarket';
import { getRacingSportLoading } from 'redux/modules/racing/selectors';
import { SportPageContextFilter, SportPageType } from 'redux/modules/sportPage/type';
import { getTop5ViewLoader } from 'redux/modules/top5View/selectors';
import { EventViewType, PlacementPage, SportId } from 'types';

const EventPage = () => {
  const dispatch = useDispatch();
  const { sportId, eventId = '' } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const device = useSelector(getAppDevice);
  const topMultiMarket = useSelector(getMultiMarketTop);
  const eventGroup = useSelector(getEventGroup);
  const isFromMultiMarketPage = useSelector(getMultiMarketDirection);
  const isShowMarketOdds = useSelector(getIsShowMarketOdd);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const eventIdOfMarket = useSelector(getSingleMarketEventId);
  const isRaceTabLoading = useSelector(getRacingSportLoading);
  const isFancyMarketLoading = useSelector(getFancyMarketLoading);
  const isTop5Loading = useSelector(getTop5ViewLoader);
  const todayCardPageLoading = useSelector(getTodayCardPageLoading);
  const sportLoading = useSelector(getSportLoading);
  const raceLoading = useSelector(getRaceLoading);
  const eventLoading = useSelector(getEventDataLoading);
  const groupLoading = useSelector(getGroupLoading);
  const marketLoading = useSelector(getMarketLoading);
  const competitionLoading = useSelector(getCompetitionLoading);
  const countryLoading = useSelector(getCountryLoading);

  const isLoading =
    todayCardPageLoading ||
    sportLoading ||
    raceLoading ||
    eventLoading ||
    groupLoading ||
    marketLoading ||
    competitionLoading ||
    countryLoading ||
    isRaceTabLoading ||
    isFancyMarketLoading ||
    isTop5Loading;

  const { isDefaultView, isFancyView, isTop5View, eventSettings, isMultiMarketView } = useEnabledView();

  const showEvent = searchParams.get('showEvent');
  const top5Tab = searchParams.get('tab');
  const isAllMarketsTab = top5Tab === ALL_MARKETS_TAB;
  const isMobile = device === Devices.MOBILE;
  const isNotRealTennis = sportId === SportId.TENNIS && !eventGroup?.realEvent;
  const parents = eventGroup?.parents || [];

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height }));
    }
  });

  useEffect(() => {
    if (isFromMultiMarketPage) {
      dispatch(setMultiMarketRedirection(false));
    }

    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (isAllMarketsTab && eventSettings && !isTop5View) {
      searchParams.delete('tab');
      setSearchParams(searchParams);
    }
  }, [isAllMarketsTab, eventSettings, isTop5View]);

  useEffect(() => {
    if (isMultiMarketView && !showEvent) {
      dispatch(fetchMultiMarketTopDetails(eventId));
    }
  }, [isMultiMarketView, eventId, showEvent, dispatch]);

  useEffect(() => {
    if (
      topMultiMarket?.topMarketId &&
      isMultiMarketView &&
      !showEvent &&
      !isFromMultiMarketPage &&
      (eventIdOfMarket === undefined || eventIdOfMarket === eventId)
    ) {
      navigate(`${SPORT_BASE_URL}/${sportId}/market/${topMultiMarket.topMarketId}`, { replace: true });
      dispatch(resetMultiMarketTopDetails());
      dispatch(setMultiMarketRedirection(true));
    }
  }, [isMultiMarketView, showEvent, sportId, topMultiMarket?.topMarketId]);

  useEffect(() => {
    if (eventId) {
      dispatch(fetchEventGroup(eventId));
    }
  }, [dispatch, eventId]);

  useEffect(() => {
    if (isShowMarketOdds) {
      dispatch(fetchPopularMarkets());
    }
  }, [isLoggedIn, dispatch, isShowMarketOdds]);

  const getContent = () => {
    if (
      (isDefaultView && isMobile) ||
      ((isMultiMarketView || isTop5View) &&
        isMobile &&
        (showEvent || topMultiMarket?.viewType === EventViewType.DEFAULT))
    ) {
      return <SportsSection />;
    }

    if (isTop5View && eventSettings) {
      if (isNotRealTennis && parents.length) {
        return device === Devices.MOBILE ? (
          <SportsSection />
        ) : (
          <SportPageModule
            pageDetailsId={parents[1]?.id ?? ''}
            contextFilter={SportPageContextFilter.COMPETITION}
            pageType={SportPageType.COMPETITION}
            isNotRealEvent
            placementPage={PlacementPage.EVENT}
          />
        );
      }

      return <Top5View eventSettings={eventSettings} />;
    }

    if (isFancyView) {
      return <FancyMarketPage />;
    }

    if (sportId && RACING_SPORT[sportId]) {
      return <RacingSport />;
    }

    if (isNotRealTennis) {
      const competition = parents.find(parent => parent.type === SportPageType.COMPETITION);
      return (
        <SportPageModule
          pageDetailsId={competition?.id ?? ''}
          contextFilter={SportPageContextFilter.COMPETITION}
          pageType={SportPageType.COMPETITION}
          isNotRealEvent
          placementPage={PlacementPage.EVENT}
        />
      );
    }

    return <SportEvent page={PlacementPage.EVENT} />;
  };

  return (
    <div ref={componentRef} className="skeleton_page_wrapper">
      {isLoading && <MiddleSectionPageSkeleton itemsCount={12} withDelay={150} />}
      <SEO page={MetaPage.EVENT} id={eventId} />
      {!isMobile && !isFancyView && <PopularMarket page={PlacementPage.EVENT_ODDS_BANNER} />}
      {getContent()}
    </div>
  );
};

export default EventPage;
