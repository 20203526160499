import classNames from 'classnames';

import GamesCardItem from 'components/GamesCardItem';
import { TContentItem, TCurrentGameData } from 'redux/modules/games/type';

import styles from './styles.module.scss';

interface GamesCommunitySectionProps {
  /**
   * Selected game data (Poker)
   */
  game: TCurrentGameData | TContentItem;
  /**
   * Is component used on result page
   */
  isResult?: boolean;
}

const GamesCommunitySection = ({ game, isResult }: GamesCommunitySectionProps) => {
  const { gameData, gameType } = game;
  const properties = gameData[gameData.length - 1].properties;

  return (
    <div
      className={classNames('biab_games-player biab_community-cards', styles.communitySection, {
        [styles.communitySection__result]: isResult
      })}
    >
      <GamesCardItem
        properties={properties}
        type={gameType}
        classes={{ cardUnavailable: styles.communitySection__unavailableCardItem }}
      />
    </div>
  );
};

export default GamesCommunitySection;
