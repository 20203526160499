import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AUTO_CASH_OUT_GET_MARKETS_INTERVAL, GeneralWebSocketSubscriptionTypes } from 'constants/app';
import {
  getAutoCashOutEnabled,
  getAutoCashOutWsEnabled,
  getGeneralWsEnabled,
  getIsPropertiesLoaded,
  getLanguage,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchAutoCashOutMarkets } from 'redux/modules/cashOut';
import { getFetchAutoCashOutLoading } from 'redux/modules/cashOut/selectors';
import { isMarketHasOffers } from 'redux/modules/currentBets/selectors';
import { getIsConnectedGeneral, getSubscribeToGeneralMessages } from 'redux/modules/webSocket/selectors';

type MultiMarketViewIntervalRequestInjectionProps = {
  marketId: string;
  cashOutEnabled: boolean;
};

const MultiMarketViewIntervalRequestInjection = ({
  marketId,
  cashOutEnabled
}: MultiMarketViewIntervalRequestInjectionProps) => {
  const dispatch = useDispatch();

  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const autoCashOutEnabled = useSelector(getAutoCashOutEnabled);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isMarketHasOffer = useSelector(isMarketHasOffers(marketId));
  const autoCashOutLoading = useSelector(getFetchAutoCashOutLoading);
  const autoCashOutWsEnabled = useSelector(getAutoCashOutWsEnabled);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const isConnectedGeneralWebSocket = useSelector(getIsConnectedGeneral);
  const subscribeGeneralWebSocketMessages = useSelector(getSubscribeToGeneralMessages);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const autoCashOutLoadingRef = useRef(autoCashOutLoading);
  const generalWsDataRef = useRef<{
    isAutoCashOutSubscriptionAvailable: boolean;
    subscribeGeneralWebSocketMessages: (<F>(params: F) => void) | null;
  }>({
    isAutoCashOutSubscriptionAvailable: false,
    subscribeGeneralWebSocketMessages: null
  });

  autoCashOutLoadingRef.current = autoCashOutLoading;

  const isAutoCashOutAvailable =
    autoCashOutEnabled && cashOutEnabled && !!isMarketHasOffer && isLoggedIn && arePropertiesLoaded;
  const isAutoCashOutIntervalAvailable = isAutoCashOutAvailable && (!generalWsEnabled || !autoCashOutWsEnabled);
  const isAutoCashOutSubscriptionAvailable =
    isAutoCashOutAvailable &&
    autoCashOutWsEnabled &&
    generalWsEnabled &&
    isConnectedGeneralWebSocket &&
    !!subscribeGeneralWebSocketMessages;

  generalWsDataRef.current = {
    isAutoCashOutSubscriptionAvailable,
    subscribeGeneralWebSocketMessages
  };

  useEffect(() => {
    if (isAutoCashOutIntervalAvailable && marketId) {
      dispatch(fetchAutoCashOutMarkets([marketId]));

      intervalRef.current = setInterval(() => {
        if (!autoCashOutLoadingRef.current) {
          dispatch(fetchAutoCashOutMarkets([marketId]));
        }
      }, AUTO_CASH_OUT_GET_MARKETS_INTERVAL);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [language, timezone, isAutoCashOutIntervalAvailable, marketId]);

  useEffect(() => {
    if (isAutoCashOutSubscriptionAvailable && marketId) {
      subscribeGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.autoCashOut]: {
          subscribe: true,
          marketIds: [marketId]
        }
      });
    }
  }, [isAutoCashOutSubscriptionAvailable, marketId, language, timezone]);

  useEffect(() => {
    return () => {
      const {
        isAutoCashOutSubscriptionAvailable: isAutoCashOutWsAvailable,
        subscribeGeneralWebSocketMessages: subscribeFunc
      } = generalWsDataRef.current;

      if (isAutoCashOutWsAvailable && subscribeFunc) {
        subscribeFunc({
          [GeneralWebSocketSubscriptionTypes.autoCashOut]: { subscribe: false }
        });
      }
    };
  }, []);

  return null;
};

export default MultiMarketViewIntervalRequestInjection;
