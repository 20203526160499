import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import {
  ASIAN_BASE_URL,
  ASIAN_MOBILE_BET_LIST_URL,
  ASIAN_MOBILE_FAVOURITES_URL,
  GAMES_BASE_URL,
  HOME_BASE_URL
} from 'constants/locations';
import {
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled
} from 'redux/modules/appConfigs/selectors';
import { getAccountPageReferer } from 'redux/modules/appSettings/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';

import styles from './styles.module.scss';

interface UpdatedMobileAccountBackBtnProps {
  classes?: {
    container?: string;
    icon?: string;
  };
}

const UpdatedMobileAccountBackBtn = ({ classes }: UpdatedMobileAccountBackBtnProps) => {
  const { pathname } = useLocation();

  // TODO: This logic copied from src/components/MobileAccountBackBtn, MobileAccountBackBtn should be deprecated after RG tools redisign finished
  const accountPageReferer = useSelector(getAccountPageReferer);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isGamesEnabled = useSelector(getIsExchangeGamesEnabled);

  const isAsianViewPage = pathname.includes(ASIAN_BASE_URL) || (!isSportsEnabled && !isGamesEnabled);
  const isGamesPage = pathname.includes(GAMES_BASE_URL) || (!isSportsEnabled && !isAsianViewEnabled);
  const isBetListReferer = accountPageReferer.includes(ASIAN_MOBILE_BET_LIST_URL);
  const isFavReferer = accountPageReferer.includes(ASIAN_MOBILE_FAVOURITES_URL);

  const basePage = (isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || HOME_BASE_URL;
  const isValidPageReferer = !(!isLoggedIn && (isBetListReferer || isFavReferer));
  const url = (isValidPageReferer && accountPageReferer) || basePage;

  return (
    <Link className={classNames(styles.goBack__button, classes?.container ?? '')} to={url}>
      <i className={classNames('biab_left-arrow fa2 fa2-arrow-left', styles.goBack__icon, classes?.icon ?? '')} />
    </Link>
  );
};

export default UpdatedMobileAccountBackBtn;
