export type TLimitError = {
  show: boolean;
  label: string;
  options: { N?: number };
};

export enum ResponsibleBettingTitle {
  ExposureLimit = 'exposureLimit',
  LossLimit = 'lossLimit',
  SelfExclusion = 'selfExclusion',
  Reminder = 'reminder',
  DayLimit = 'dayLimit'
}

export enum ResponsibleBettingCalendarsContainerType {
  ExposureLimit = 'exposure-limit',
  LossLimit = 'loss-limit',
  SelfExclusion = 'self-exclusion'
}

export enum ResponsibleBettingDeactivateButtonLabel {
  ExposureLimit = 'deactivateExposureLimit',
  LossLimit = 'deactivateLossLimit',
  Reminder = 'deactivateReminder',
  DayLimit = 'deactivateDayLimit'
}

export enum ResponsibleBettingLimitIsLabel {
  ExposureLimit = 'exposureLimitIs',
  LossLimit = 'lossLimitIs'
}

export enum ResponsibleBettingLimitInputId {
  ExposureLimit = 'exposure-limit-input',
  LossLimit = 'loss-limit-input',
  Reminder = 'reminder-input',
  DayLimit = 'day-limit-input'
}

export enum ResponsibleBettingOperatorLimit {
  ExposureLimit = 'operatorsExposureLimit',
  LossLimit = 'operatorsLossLimit',
  SelfExcluded = 'selfExcludedThroughApi'
}

export enum ResponsibleBettingStartDate {
  ExposureLimit = 'exposureStartDate',
  LossLimit = 'lossLimitStartDate',
  SelfExclusion = 'selfExclusionStartDate'
}

export enum ResponsibleBettingEndDate {
  ExposureLimit = 'exposureEndDate',
  LossLimit = 'lossLimitEndDate',
  SelfExclusion = 'selfExclusionEndDate'
}

export enum ResponsibleBettingLimit {
  ExposureLimit = 'exposureLimit',
  LossLimit = 'lossLimit',
  Reminder = 'reminderInterval',
  DayLimit = 'dayLimit'
}

export enum ResponsibleBettingLimitEnabled {
  ExposureLimit = 'exposureLimitEnabled',
  LossLimit = 'lossLimitEnabled',
  SelfExclusion = 'selfExclusionEnabled',
  Reminder = 'reminderEnabled',
  DayLimit = 'dayLimitEnabled'
}

export enum ResponsibleBettingDisplayedContent {
  ExposureLimit = 'EXPOSURE_LIMIT',
  LossLimit = 'LOSS_LIMIT',
  SelfExclusion = 'SELF_EXCLUSION',
  Reminder = 'REMINDER',
  DayLimit = 'DAY_LIMIT'
}

export const UpdatedResponsibleBettingDisplayedContent = {
  LossLimit: 'LOSS_LIMIT',
  SelfExclusion: 'SELF_EXCLUSION',
  Reminder: 'REMINDER',
  TimeLimit: 'TIME_LIMIT'
};

export type UpdatedResponsibleBettingDisplayedContentKeys =
  typeof UpdatedResponsibleBettingDisplayedContent[keyof typeof UpdatedResponsibleBettingDisplayedContent];

export const ResponsibleBettingReportTypes = {
  CurrentBetsReport: 'current-bets',
  PastBetsReport: 'past-bets',
  ProfitAndLossReport: 'profit-and-loss',
  AccountStatementReport: 'account-statement'
};

export type ResponsibleBettingReportTypeKeys =
  typeof ResponsibleBettingReportTypes[keyof typeof ResponsibleBettingReportTypes];

export type ResponsibleBettingTab = {
  labelTranslationKey: string;
  btnLabelTranslationKey?: string;
  name: string;
  isVisible: boolean;
  descriptionTranslationKey?: string;
};

export enum ResponsibleBettingLossLimitTimeUnit {
  Day = 'DAY',
  Custom = 'CUSTOM'
}

export enum ResponsibleBettingSelfExclusionTimeUnit {
  Hour = 'HOUR',
  Day = 'DAY',
  Custom = 'CUSTOM'
}

export enum ResponsibleBettingReminderTimeUnit {
  Minute = 'MINUTE',
  Hour = 'HOUR'
}

export type ResponsibleBettingCalendarsContainerClasses = {
  container?: string;
};

export enum BettingDisplaySettingEnabled {
  ExposureLimit = 'exposureLimitEnabled',
  LossLimit = 'lossLimitEnabled',
  SelfExclusion = 'selfExclusionEnabled',
  Reminder = 'reminderEnabled',
  DayLimit = 'dayLimitEnabled'
}

export const ResponsibleBettingTimeUnits = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH'
} as const;

export type ResponsibleBettingTimeUnitKeys =
  typeof ResponsibleBettingTimeUnits[keyof typeof ResponsibleBettingTimeUnits];

export const ResponsibleBettingSelfExclusionTimeUnits = {
  Day: 'DAY',
  Week: 'WEEK',
  Month: 'MONTH',
  HalfYear: 'HALF_YEAR',
  Year: 'YEAR',
  Years3: 'YEARS_3',
  Years5: 'YEARS_5'
} as const;

export type ResponsibleBettingSelfExclusionTimeUnitKeys =
  typeof ResponsibleBettingSelfExclusionTimeUnits[keyof typeof ResponsibleBettingSelfExclusionTimeUnits];

export const ResponsibleBettingButtonTypes = {
  Primary: 'Primary',
  Secondary: 'Secondary',
  Applied: 'Applied',
  Outlined: 'Outlined'
} as const;

export type ResponsibleBettingButtonTypeKeys =
  typeof ResponsibleBettingButtonTypes[keyof typeof ResponsibleBettingButtonTypes];

export const ResponsibleBettingAlertTypes = {
  Error: 'Error',
  Info: 'Info',
  Outline: 'Outline'
} as const;

export type ResponsibleBettingAlertTypeKeys =
  typeof ResponsibleBettingAlertTypes[keyof typeof ResponsibleBettingAlertTypes];

export const ResponsibleBettingMobileDropdownTypes = {
  Vertical: 'Vertical',
  Horizontal: 'Horizontal'
} as const;

export type ResponsibleBettingMobileDropdownTypeKeys =
  typeof ResponsibleBettingMobileDropdownTypes[keyof typeof ResponsibleBettingMobileDropdownTypes];

export type ResponsibleBettingeReportInitialState = {
  loading?: boolean;
  error?: Error | null;
  disabled?: boolean;
};
