import { TFailureActionPayload } from 'types';
import { IMarket, IMarketRules, TMarketSportInfo } from 'types/markets';

export type TFetchFancyEventDetailsPayload = {
  eventId: string;
  fancyMarketTypes: FancyMarketTypes;
};

export enum FancyMarketTypes {
  FIRST_INNINGS_FANCY = 'FIRST_INNINGS_FANCY',
  SECOND_INNINGS_FANCY = 'SECOND_INNINGS_FANCY'
}

export type TFetchFancyEventDetailsResponse = {
  inningsFancy: IMarket[];
  batsmen: IMarket[];
  fallOfWicket: IMarket[];
  inningsFours: IMarket[];
  inningsSixes: IMarket[];
  totalInningsRuns: IMarket[];
  otherLineMarkets: IMarket[];
  startDate: number;
  eventInfo: TMarketSportInfo;
  dropdownMarkets: FancyDropdownMarkets[];
};

export type TFancyMarket = {
  loading: boolean;
  error: TFailureActionPayload;
  inningsFancy: IMarket[];
  batsmen: IMarket[];
  dropdownMarkets: FancyDropdownMarkets[] | null;
  fallOfWicket: IMarket[];
  inningsFours: IMarket[];
  inningsSixes: IMarket[];
  otherLineMarkets: IMarket[];
  totalInningsRuns: IMarket[];
  startDate: number;
  eventInfo: TMarketSportInfo;
  rulesLoading: boolean;
  rules: Record<string, IMarketRules>;
  rulesError: TFailureActionPayload;
};

export type FancyDropdownMarkets = {
  marketId: string;
  eventId: string;
  pageView: FancyMarketTypes;
  name: string;
  marketStartTime: number;
  inPlay: boolean;
  translations: Record<string, string>;
};
