import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useConvertTimeToString from 'hooks/useConvertTimeToString';

import styles from './styles.module.scss';

interface CollapseTimeProps {
  date: number;
  className?: string;
}

const CollapseTime = ({ date, className }: CollapseTimeProps) => {
  const { t } = useTranslation();
  const { hoursAndMinutes, monthDay, weekDay, month } = useConvertTimeToString({ startDate: date });

  return (
    <span className={classNames(styles.time, className)}>
      {`${t(`dates.shortDay.${weekDay}`)}, ${t(`dates.shortMonth.${month}`)} ${monthDay} - `}
      <span className={styles.hours}> {hoursAndMinutes}</span>
    </span>
  );
};

export default CollapseTime;
