import MatchStatistics from 'components/MatchStatistics';

import styles from './styles.module.scss';

const AsianViewMatchStatistics = ({ eventId, matchStatEnabled }: { eventId: string; matchStatEnabled: boolean }) => {
  return (
    <MatchStatistics
      hideLabel
      isSoccer
      visible={matchStatEnabled}
      isInHeader
      eventId={eventId}
      iconClassName={styles.icon}
      bgClassName={styles.bg}
    />
  );
};

export default AsianViewMatchStatistics;
