import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import {
  failureFetchAsianViewPopularLinks,
  fetchAsianViewPopularLinks,
  successFetchAsianViewPopularLinks
} from './index';
import { AsianViewPopularLink } from './type';

export function* getAsianViewPopularLinksWorker() {
  try {
    const response: AsianViewPopularLink[] = yield call(api.asianView.popularLinks);

    yield put(successFetchAsianViewPopularLinks(response));
  } catch (error: any) {
    yield put(failureFetchAsianViewPopularLinks(error.data));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchAsianViewPopularLinks.type, getAsianViewPopularLinksWorker)]);
}
