import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

export const getToolTip = (state: AppState) => state.tooltip;
export const getToolTips = createSelector(getToolTip, tooltip => tooltip.tooltips);

export const getContextualHelp = createSelector(getToolTip, tooltip => tooltip.isContextualHelpEnabled || false);
export const getIsShowTooltipHelper = createSelector(getToolTips, tooltips =>
  Object.values(tooltips).some(value => value)
);

export const isShowTooltipHelper = (tooltip: string) => createSelector(getToolTips, tooltips => tooltips[tooltip]);
