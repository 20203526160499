import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';

import { TAsianViewWidgetsState } from './type';

const initialState: TAsianViewWidgetsState = {
  matchStatistics: {},
  liveVideo: {}
};

const slice = createSlice({
  name: SLICES_NAMES.ASIAN_VIEW_WIDGETS,
  initialState,
  reducers: {
    setMatchStatistics: (state, { payload }: PayloadAction<string>) => {
      state.liveVideo = {};
      state.matchStatistics = { [payload]: true };
    },
    setLiveVideo: (state, { payload }: PayloadAction<string>) => {
      state.matchStatistics = {};
      state.liveVideo = { [payload]: true };
    },
    closeMatchStatistics: (state, { payload }: PayloadAction<string>) => {
      delete state.matchStatistics[payload];
    },
    closeLiveVideo: (state, { payload }: PayloadAction<string>) => {
      delete state.liveVideo[payload];
    },
    closeAllMobileWidgets: state => {
      state.matchStatistics = {};
      state.liveVideo = {};
    }
  }
});

export const { closeLiveVideo, closeMatchStatistics, setMatchStatistics, setLiveVideo, closeAllMobileWidgets } =
  slice.actions;

export default slice.reducer;
