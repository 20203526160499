import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { Tour, TourStep } from 'redux/modules/appConfigs/type';
import { TFailureActionPayload } from 'types';

import { IGetStepPayload, TTours } from './type';

const initialState: TTours = {
  tourList: [],
  loading: false,
  error: null
};

const slice = createSlice({
  name: SLICES_NAMES.TOURS,
  initialState,
  reducers: {
    fetchStepData: (state, _: PayloadAction<IGetStepPayload>) => {
      state.loading = true;
    },
    successFetchStepData: (state, { payload }: PayloadAction<TourStep>) => {
      state.loading = false;
      state.tourList = state.tourList.map(tour =>
        tour.id === payload.tourId
          ? {
              ...tour,
              steps: tour.steps.map(step =>
                step.id === payload.id
                  ? {
                      ...step,
                      content: payload.content,
                      imageFile: payload.imageFile
                    }
                  : step
              )
            }
          : tour
      );
    },
    failureFetchTourData: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
    },
    setTourList: (state, { payload }: PayloadAction<Tour[]>) => {
      state.tourList = payload;
    }
  }
});

export const { fetchStepData, successFetchStepData, failureFetchTourData, setTourList } = slice.actions;

export default slice.reducer;
