import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import AsianViewBackLayLabels from 'components/AsianViewPageModule/components/Event/MarketCell/AsianViewBackLayLabels';
import SelectionOdds from 'components/AsianViewPageModule/components/Event/MarketCell/SelectionOdds';
import SelectionOddsSkeleton from 'components/AsianViewPageModule/components/Event/MarketCell/SelectionOddsSkeleton';
import { CORRECT_SCORE_ANY_OTHER_LABELS, CORRECT_SCORE_RUNNERS_LABELS } from 'constants/asianView';
import { asianViewMiddleSection as branding } from 'constants/branding';
import useAsianLayBetting from 'hooks/useAsianLayBetting';
import { getLanguage } from 'redux/modules/appConfigs/selectors';
import { getAsianViewEventIndex } from 'redux/modules/asianView/selectors';
import { TAsianViewRunner } from 'redux/modules/asianView/type';
import { getIsMarketInPlayByMarketPricesId, getMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { ECorrectScoreAnyOther, ECorrectScoreRunners } from 'types/asianView';

import styles from './styles.module.scss';

interface CorrectScoreRunnersProps {
  competitionId: string;
  eventId: string;
  marketId: string;
  type: ECorrectScoreRunners;
  runners: TAsianViewRunner[];
  showBackLayLabels: boolean;
}

const CorrectScoreRunners = ({
  competitionId,
  eventId,
  marketId,
  type,
  runners,
  showBackLayLabels
}: CorrectScoreRunnersProps) => {
  const { t } = useTranslation();

  const language = useSelector(getLanguage);
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketId));
  const marketPricesId = useSelector(getMarketPricesId(marketId));
  const eventIndex = useSelector(getAsianViewEventIndex(eventId));

  const isLayBetting = useAsianLayBetting();

  const isOtherType = type === ECorrectScoreRunners.OTHER;
  const isAnyOtherType = type === ECorrectScoreRunners.ANY_OTHER;

  const isBackLayLabelsEnabled = (index: number) => {
    if (isAnyOtherType && index > 0) {
      return false;
    }
    return showBackLayLabels && isLayBetting && (runners.length > 3 ? Math.floor(runners.length / 2) > index : true);
  };

  const brandingStyles = {
    [branding.IN_PLAY_EVENT_1]: isInPlay && eventIndex % 2 === 0,
    [branding.IN_PLAY_EVENT_2]: isInPlay && eventIndex % 2 !== 0,
    [branding.NON_PLAY_EVENT_1]: !isInPlay && eventIndex % 2 === 0,
    [branding.NON_PLAY_EVENT_2]: !isInPlay && eventIndex % 2 !== 0
  };

  return (
    <div
      className={classNames(styles.runnersType, branding.MAIN_CONTAINER_BORDER, {
        [styles.runnersType__inPlay]: isInPlay,
        ...brandingStyles
      })}
    >
      <div
        className={classNames(styles.runnersType__name, branding.MAIN_CONTAINER_BORDER, branding.SELECTION_TYPE_HEADER)}
      >
        {!isAnyOtherType && t(CORRECT_SCORE_RUNNERS_LABELS[type])}
      </div>
      {isAnyOtherType && (
        <div className={classNames(styles.runner__name, branding.MAIN_CONTAINER_BORDER)}>
          {t(CORRECT_SCORE_RUNNERS_LABELS[type])}
        </div>
      )}
      <div
        className={classNames({
          [styles.runnersType__anyOther]: isAnyOtherType,
          [styles.runnersType__list]: !isAnyOtherType,
          [branding.MAIN_CONTAINER_BORDER_BG]: !isAnyOtherType,
          [styles.runnersType__list__border]: !isOtherType && !isAnyOtherType,
          [branding.MAIN_CONTAINER_BORDER]: !isOtherType && !isAnyOtherType,
          [styles.runnersType__list__col1]: isOtherType || isAnyOtherType,
          [styles.runnersType__list__col2]: [2, 4].includes(runners.length)
        })}
      >
        {runners.map((runner, index) => (
          <div key={`${type}_${runner.id}_${runner.handicap}`} className={styles.runnersType__item}>
            {!isAnyOtherType && (
              <div
                className={classNames(styles.runner__name, branding.MAIN_CONTAINER_BORDER, branding.SELECTION_HEADER)}
              >
                {runner.translations[language]}
              </div>
            )}
            <div
              className={classNames(styles.runner__content, {
                [styles.runner__content__fixHeight]: !isAnyOtherType,
                [styles.runner__content__inPlay]: isInPlay,
                [styles.runner__content__padding]: isAnyOtherType && index === 0,
                ...brandingStyles
              })}
            >
              <div className={styles.runner__content__inner}>
                {isBackLayLabelsEnabled(index) && <AsianViewBackLayLabels />}
                <div className={styles.runner__wrap}>
                  {isAnyOtherType && (
                    <>
                      {!isLayBetting && <div className={styles.runner__leftPadding} />}
                      <div
                        data-tooltip-id={'tooltip'}
                        data-tooltip-html={runner.translations[language] || runner.name}
                        className={styles.runner__shortName}
                      >
                        {t(CORRECT_SCORE_ANY_OTHER_LABELS[runner.name as ECorrectScoreAnyOther])}
                      </div>
                    </>
                  )}
                  {marketPricesId ? (
                    <SelectionOdds
                      competitionId={competitionId}
                      eventId={eventId}
                      marketId={marketId}
                      runner={runner}
                    />
                  ) : (
                    <SelectionOddsSkeleton />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CorrectScoreRunners;
