import { useSelector } from 'react-redux';

import { getIsAmericanDateFormatEnabled } from 'redux/modules/appConfigs/selectors';

import useConvertTimeToString from './useConvertTimeToString';

export const useParseMyBetsDates = ({
  dateToParse,
  yearHyphen,
  hideHoursMinutes,
  isBettingDay
}: {
  dateToParse: number;
  yearHyphen?: boolean;
  hideHoursMinutes?: boolean;
  isBettingDay?: boolean;
}) => {
  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);

  const { hoursAndMinutes, monthDay, year, month } = useConvertTimeToString({
    startDate: dateToParse,
    isBettingDay
  });

  const date = americanDateFormatEnabled
    ? `${month + 1 < 10 ? 0 : ''}${month + 1}-${monthDay < 10 ? 0 : ''}${monthDay}${yearHyphen ? '-' : ' '}${year} ${
        hideHoursMinutes ? '' : hoursAndMinutes
      }`
    : `${monthDay < 10 ? 0 : ''}${monthDay}-${month + 1 < 10 ? 0 : ''}${month + 1}${yearHyphen ? '-' : ' '}${year} ${
        hideHoursMinutes ? '' : hoursAndMinutes
      }`;

  return { date };
};
