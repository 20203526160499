import classNames from 'classnames';

import styles from '../../../../styles.module.scss';

interface SlideArrowProps {
  handleSlide: () => void;
  showCondition: boolean;
  classname: string;
  iconClass: string;
}
const SlideArrow = ({ handleSlide, showCondition, classname, iconClass }: SlideArrowProps) => (
  <div
    onClick={handleSlide}
    className={classNames(classname, {
      [styles.arrowFadeIn]: showCondition,
      [styles.arrowFadeOut]: !showCondition
    })}
  >
    <i className={`biab_arrow-icon ${iconClass}`} />
  </div>
);

export default SlideArrow;
