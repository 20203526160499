import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { LINE_MARKET_ODDS } from 'constants/placement';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useInlineSelectedBetView } from 'hooks/useInlinePlacement';
import { getLineMarketsSwitchBackLayOnCricket } from 'redux/modules/appConfigs/selectors';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { SportId } from 'types';
import { BetTypes } from 'types/bets';
import { Actions } from 'types/inlinePlacement';
import { BettingType } from 'types/markets';

import styles from './styles.module.scss';

type TInlineBetInfo = { bet: TInlineSelectedBet };

const InlineBetInfo = ({ bet }: TInlineBetInfo) => {
  const { t } = useTranslation();

  const lineBackLayToNoYes = useSelector(getLineMarketsSwitchBackLayOnCricket);
  const { replaceBackLayWithUnderOver } = useDeviceSettings();

  const { isShortView } = useInlineSelectedBetView(bet);

  const isCricket = bet.sportId === SportId.CRICKET;
  const isLineMarket = bet.bettingType === BettingType.LINE;

  let label = t('betslip.labels.type.' + bet.type?.toLocaleLowerCase());

  if (isCricket && isLineMarket && lineBackLayToNoYes) {
    label = t('betslip.labels.type.' + (bet.type === BetTypes.BACK ? 'no' : 'yes'));
  }

  if (!isCricket && isLineMarket && replaceBackLayWithUnderOver) {
    label = t('betslip.labels.type.' + (bet.type === BetTypes.BACK ? 'under' : 'over'));
  }

  const isPlacedState = bet.action === Actions.PLACED;
  const isCancelledState = bet.action === Actions.CANCELLED;
  const isRemovedState = bet.action === Actions.REMOVED;
  const isProgressState = bet.action === Actions.PROGRESS || bet.action === Actions.LONG_PROGRESS;

  const isLineView = isShortView || isPlacedState || isCancelledState || isProgressState || isRemovedState;

  return (
    <div className={classNames(styles.inlineBetInfo, { [styles.inlineBetInfo__line]: isLineView })}>
      {!isRemovedState && <span>{`${label}${isLineView ? ':' : ''}`}</span>}
      <span>
        <span className={styles.selectionName}>
          {bet.selectionName}
          {isLineMarket ? ` @${LINE_MARKET_ODDS}` : ''}
        </span>{' '}
        &ndash; {bet.marketName}
      </span>
    </div>
  );
};

export default InlineBetInfo;
