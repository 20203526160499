import { useTranslation } from 'react-i18next';
import { Link, To, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { SPORT_BASE_URL } from 'constants/locations';
import { ALL_MARKETS_TAB } from 'pages/Top5View';

import styles from './styles.module.scss';

interface MobileMarketsTabsProps {
  isAllMarketsTab: boolean;
}

const MobileMarketsTabs = ({ isAllMarketsTab }: MobileMarketsTabsProps) => {
  const { sportId = '', eventId = '' } = useParams();
  const { t } = useTranslation();

  const tabs: { id: string; label: string; url: To }[] = [
    {
      id: 'top-markets',
      label: 'market.topFive.topMarketsTab',
      url: `${SPORT_BASE_URL}/${sportId}/event/${eventId}`
    },
    {
      id: 'all-markets',
      label: 'market.topFive.allMarketsTab',
      url: { pathname: `${SPORT_BASE_URL}/${sportId}/event/${eventId}`, search: `tab=${ALL_MARKETS_TAB}` }
    }
  ];

  return (
    <div
      className={classNames('biab_multi-market-tabs', styles.mobileMarketTabs, {
        [styles.mobileMarketTabs__allMarkets]: isAllMarketsTab
      })}
    >
      <div className={styles.mobileMarketTabs__list}>
        {tabs.map(({ id, label, url }, index) => (
          <Link
            key={id}
            id={id}
            to={url}
            className={classNames('biab_market-tab', styles.mobileMarketTabs__link, {
              biab_active: index === 0 ? !isAllMarketsTab : isAllMarketsTab
            })}
          >
            {t(label)}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MobileMarketsTabs;
