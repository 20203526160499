import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import FavoriteStar from 'components/FavoriteStar';
import ViewByDropdown from 'components/ViewByDropdown';
import { FAVORITES_TYPES } from 'constants/app';
import { SPORT_BASE_URL } from 'constants/locations';
import { dontRaceRequest, MARKET_TYPES } from 'constants/marketTypes';
import useMobileMarketNavigationOptions from 'hooks/useMobileMarketNavigationOptions';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { fetchGetEvents } from 'redux/modules/competitions';
import { getEventData, getEventDetails } from 'redux/modules/competitions/selectors';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { DropdownItem, SportId } from 'types';
import { IMarket } from 'types/markets';
import { getCleanMarketId } from 'utils';

import styles from './styles.module.scss';

interface MarketTitleRowProps {
  /**
   * Market
   */
  market: IMarket;
  marketId: string;
  sportId: string;
  isMarketClosed: boolean;
  isRacing?: boolean;
}

const MarketTitleRow = ({ market, marketId, sportId, isMarketClosed, isRacing }: MarketTitleRowProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { marketName, event } = market;

  const device = useSelector(getAppDevice);
  const eventData = useSelector(getEventData);
  const eventDetails = useSelector(getEventDetails);
  const navigationOptions = useMobileMarketNavigationOptions(event.id);
  const isShowRacingOption = [
    SportId.GRAY_HOUND_RACING,
    SportId.HORSE_RACING,
    SportId.TODAY_HORSE_RACING,
    SportId.TODAY_GRAY_HOUND_RACING
  ].some(item => sportId === item);
  let options = navigationOptions;
  let content = null;
  const isFavorite = useSelector(getFavoriteById(getCleanMarketId(marketId)));
  const showContent = !isRacing || (isRacing && isMarketClosed);
  const favoriteData = {
    starred: !isFavorite,
    entryId: marketId,
    entryName: marketName,
    entryType: FAVORITES_TYPES.market,
    sportId: sportId
  };

  if (market.description.marketType === MARKET_TYPES.dailyGoals || isMarketClosed) {
    options = [
      {
        id: '',
        value: market.marketName,
        label: market.marketName
      }
    ];
  }

  if (isShowRacingOption) {
    options = [];
  }

  useEffect(() => {
    if (
      device === Devices.MOBILE &&
      !dontRaceRequest.includes(market?.description?.marketType as typeof dontRaceRequest[number]) &&
      event.id &&
      (!eventData.length || event.id !== eventDetails.id) &&
      !isMarketClosed
    ) {
      dispatch(fetchGetEvents({ eventId: event.id }));
    }
  }, [device, dispatch, event.id, eventDetails, eventData.length, isMarketClosed, market.description.marketType]);

  const handleSelectViewBy = ({ value }: DropdownItem) => {
    if (isShowRacingOption) {
      navigate(`${SPORT_BASE_URL}/${sportId}/market/${value}?showGroups=true`);
    } else {
      navigate(value);
    }
  };

  if (device === Devices.DESKTOP && showContent) {
    content = (
      <div className={classNames('biab_market-name', styles.marketTitleRow__name)}>
        {!isMarketClosed && (
          <div className={styles.marketTitleRow__wrapper}>
            <FavoriteStar entryData={favoriteData} />
          </div>
        )}

        <span>{marketName}</span>
      </div>
    );
  }

  if (device === Devices.MOBILE) {
    content = (
      <div className="biab_market-tabs">
        {!!options.length && (
          <ViewByDropdown
            disabled={isMarketClosed}
            viewBy={marketId}
            onSelectViewBy={handleSelectViewBy}
            options={options}
            classes={{ dropdown: styles.marketTitleRow__dropdown }}
            findByKey="id"
          />
        )}
      </div>
    );
  }

  return <div className={styles.marketTitleRow}>{content}</div>;
};

export default memo(MarketTitleRow);
