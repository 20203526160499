import { Dispatch, SetStateAction } from 'react';
import { chunk, maxBy, uniqBy } from 'lodash';

import { BETTING_TYPES, LONG_MARKETS_SCROLL_NUMBER } from 'constants/app';
import { LARGE_PLACEMENTS_CELL_WIDTH, MATCHED_CELL_WIDTH, SMALL_PLACEMENTS_CELL_WIDTH } from 'constants/marketsTable';
import { longSelectionsList, MARKET_TYPES, NumberOfPairs } from 'constants/marketTypes';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { MarketNameDivider, MarketStatus } from 'types';
import {
  BetContentGroupType,
  IMarket,
  ITimeFormComments,
  MarketTypeEnding,
  TMarketPricesRunner,
  TMarketRunner,
  ViewType
} from 'types/markets';

export const getIsOddsChanged = (prevOdds: number | null | undefined, currentOdds: number | undefined) => {
  return typeof prevOdds === 'number' && currentOdds !== undefined && currentOdds !== 0 && prevOdds !== currentOdds;
};

export const highlightBet = (isOddsChanged: boolean, setIsHighlighted: Dispatch<SetStateAction<boolean>>) => {
  if (isOddsChanged) {
    setIsHighlighted(true);
  }
};

const getCellsWidth = (row: Element, viewType: ViewType, isLoggedIn: boolean, isBetIndicatorsRendered: boolean) => {
  const participantsNamesContainerWidth = row.children?.[1]?.clientWidth;
  const participantsNamesWidth = row.children?.[1]?.children?.[0]?.clientWidth + 20;
  const placementsCellWidth = row.children?.[2]?.clientWidth;
  const matchedWidth =
    viewType === ViewType.SMALL || viewType === ViewType.MEDIUM || viewType === ViewType.EXTRA_SMALL
      ? 0
      : MATCHED_CELL_WIDTH;
  let placementsWidth = 0;

  if (isLoggedIn && isBetIndicatorsRendered) {
    placementsWidth = viewType === ViewType.SMALL || viewType === ViewType.EXTRA_SMALL ? 0 : placementsCellWidth;
  }

  const totalWidth = participantsNamesContainerWidth + matchedWidth + placementsWidth;

  return { totalWidth, participantsNamesWidth };
};

export const getTableViewType = (
  rows: Element[],
  viewType: ViewType,
  isLoggedIn: boolean,
  isBetIndicatorsRendered: boolean
): ViewType => {
  const isExtraSmall = rows.some(row => {
    const { totalWidth, participantsNamesWidth } = getCellsWidth(row, viewType, isLoggedIn, isBetIndicatorsRendered);

    return totalWidth <= participantsNamesWidth;
  });

  if (isExtraSmall) {
    return ViewType.EXTRA_SMALL;
  }

  const isSmall = rows.some(row => {
    const { totalWidth, participantsNamesWidth } = getCellsWidth(row, viewType, isLoggedIn, isBetIndicatorsRendered);
    const placementWidth = isLoggedIn && isBetIndicatorsRendered ? SMALL_PLACEMENTS_CELL_WIDTH : 0;

    return totalWidth < participantsNamesWidth + placementWidth;
  });

  if (isSmall) {
    return ViewType.SMALL;
  }

  const isMedium = rows.some(row => {
    const { totalWidth, participantsNamesWidth } = getCellsWidth(row, viewType, isLoggedIn, isBetIndicatorsRendered);
    const placementWidth = isLoggedIn && isBetIndicatorsRendered ? SMALL_PLACEMENTS_CELL_WIDTH : 0;

    return totalWidth < participantsNamesWidth + MATCHED_CELL_WIDTH + placementWidth;
  });

  if (isMedium) {
    return ViewType.MEDIUM;
  }

  const isLarge = rows.some(row => {
    const { totalWidth, participantsNamesWidth } = getCellsWidth(row, viewType, isLoggedIn, isBetIndicatorsRendered);
    const placementWidth = isLoggedIn && isBetIndicatorsRendered ? LARGE_PLACEMENTS_CELL_WIDTH : 0;

    return totalWidth < participantsNamesWidth + MATCHED_CELL_WIDTH + placementWidth;
  });

  if (isLarge) {
    return ViewType.LARGE;
  }

  return ViewType.DEFAULT;
};

export const serializeCollapsedCookies = (collapsedList: []) => {
  const markets: Record<string, boolean> = {};

  collapsedList.forEach((cookie: string) => {
    const parts = cookie.split('=');

    markets[parts[0]] = parts[1] === 'true';
  });

  return markets;
};

export const getMarketTypes = (marketType: string, bettingType: string) => {
  return {
    isHandicapMarket: marketType === MARKET_TYPES.handicap,
    isHandicap:
      bettingType === BETTING_TYPES.asianHandicapDoubleLine || bettingType == BETTING_TYPES.asianHandicapSingleLine,
    isHandicapSingleLine: bettingType === BETTING_TYPES.asianHandicapSingleLine,
    isAsianHandicap: marketType === MARKET_TYPES.asianHandicap,
    isLineMarket: bettingType === BETTING_TYPES.line,
    isCombinedTotal: marketType === MARKET_TYPES.combinedTotal,
    isEachWay: marketType === MARKET_TYPES.eachWay,
    isTotalGoals: marketType === MARKET_TYPES.altTotalGoals,
    isHandicapDoubleLine: bettingType === BETTING_TYPES.asianHandicapDoubleLine,
    isTotalPointsLine: marketType === MARKET_TYPES.totalPointsLine,
    isUndifferentiated: marketType === MARKET_TYPES.undifferentiated
  };
};

export const formatPercentages = (value: number, digits = 1) => (Math.round(value * 100) / 100).toFixed(digits) + '%';

export const groupRunners = (runners: TMarketRunner[], groupCount: number) => {
  return chunk(runners, groupCount);
};

export const getTopMarket = (markets: IMarket[], key: string): IMarket => {
  return maxBy(markets, key) || markets[0];
};

export const marketScrollData = (marketType: string) => {
  const longListStep = longSelectionsList.includes(marketType) && LONG_MARKETS_SCROLL_NUMBER;

  const { pairs, group, step } = NumberOfPairs[marketType as keyof typeof NumberOfPairs] || NumberOfPairs.default;

  return {
    pairs,
    group,
    step: longListStep || step,
    isLongList: !!longListStep
  };
};

export const splitMarketNameIntoParticipants = (marketName: string) => {
  if (marketName.includes(MarketNameDivider.SHORT_VERSUS)) {
    return marketName.split(MarketNameDivider.SHORT_VERSUS);
  } else if (marketName.includes(MarketNameDivider.VERSUS)) {
    return marketName.split(MarketNameDivider.VERSUS);
  } else if (marketName.includes(MarketNameDivider.AT)) {
    return marketName.split(MarketNameDivider.AT);
  }

  return marketName.split('');
};

export const getParticipantsBatsmenFancyMarkets = (batsmen: IMarket[]) => {
  const leftBatsmen: IMarket[] = [];
  const rightBatsmen: IMarket[] = [];

  batsmen.forEach(market => {
    if (market.description.marketType.endsWith(MarketTypeEnding.A)) {
      leftBatsmen.push(market);
    } else if (market.description.marketType.endsWith(MarketTypeEnding.B)) {
      rightBatsmen.push(market);
    }
  });

  return { leftBatsmen, rightBatsmen };
};

export const getMarketUnitTranslation = (marketUnit = '', number?: number) => {
  const marketUnitKey = marketUnit.replace(' ', '').toLowerCase();
  const numberOfUnits = number === 1 ? '_1' : '';

  return 'market.units.' + marketUnitKey + numberOfUnits;
};

export const roundRangeParams = (value = 0, step = 1) => {
  const roundedValue = Math.ceil(value / step) * step;

  return roundedValue <= 0 ? 1 : roundedValue;
};

export const getBetGroups = (showDeepPrices: boolean, isLayColumnEnabled: boolean, isLineMarket: boolean) => {
  return showDeepPrices
    ? isLayColumnEnabled || isLineMarket
      ? [BetContentGroupType.DEEP_BACK, BetContentGroupType.DEFAULT, BetContentGroupType.DEEP_LAY]
      : [BetContentGroupType.DEEP_BACK, BetContentGroupType.DEFAULT]
    : [BetContentGroupType.DEFAULT];
};

export const getSerializedComments = (timeformComments: ITimeFormComments[]) => {
  const rowAmount = Math.ceil(timeformComments.length / 2);

  return {
    left: timeformComments.slice(0, rowAmount),
    right: timeformComments.slice(rowAmount)
  };
};

export const getFilteredMarket = (runners: TMarketRunner[], marketPricesRunners: TMarketPricesRunner[]) => {
  return runners.filter(runner => {
    const foundSelection = marketPricesRunners?.find(marketRunner => runner.selectionId === marketRunner.id);

    return foundSelection?.status !== MarketStatus.HIDDEN;
  });
};

export const getFilteredCurrentBets = (bets: TCurrentBet[]) => {
  return uniqBy(bets, function (e) {
    return e.marketId;
  });
};

export const isWinner = (first = 0, second = 0): string => (first > second ? 'biab_winner' : 'biab_loser');
