import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import FavoriteStar from 'components/FavoriteStar';
import { FAVORITES_TYPES, SPORTS_NAME } from 'constants/app';
import { PERIOD_TABS } from 'constants/date';
import { getFavoriteById } from 'redux/modules/favorites/selectors';

import styles from './styles.module.scss';

interface HeaderProps {
  timeRange: string;
  setTimeRange: (id: string) => void;
}

const Header = ({ setTimeRange, timeRange }: HeaderProps) => {
  const { t } = useTranslation();
  const { sportId = '' } = useParams();
  const sportName = SPORTS_NAME[sportId];

  const sportNameTranslation = t(`horseRacingPage.${sportName}`);

  const isFavorite = useSelector(getFavoriteById(sportId));

  const favoriteData = {
    entryId: sportId,
    entryName: sportNameTranslation,
    entryType: FAVORITES_TYPES.sport,
    starred: !isFavorite,
    sportId
  };

  return (
    <div className={classNames('biab_horse-racing-page-header', styles.racing__headerContainer)}>
      <div className={classNames('biab_horse-racing-page-title', styles.racing__headerTitle)}>
        {sportNameTranslation}
      </div>
      <div className={styles.racing__typeWrapper}>
        <div className={classNames('biab_horse-racing-page-periods', styles.racing__periodsBtnContainer)}>
          {PERIOD_TABS.map(({ id, title }) => (
            <div
              key={id}
              onClick={() => setTimeRange(id)}
              className={classNames(styles.racing__headerBtn, {
                [styles.racing_active]: timeRange === id
              })}
            >
              {t(title)}
            </div>
          ))}
        </div>

        <div className={styles.racing__favouriteIcon}>
          <FavoriteStar entryData={favoriteData} />
        </div>
      </div>
    </div>
  );
};

export default Header;
