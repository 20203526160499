import { KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Warning from 'components/CashOutButton/components/Warning';
import CashOutRulesModal from 'components/CashOutRulesModal';
import { KEY_CODES } from 'constants/betslip';
import { asianViewComponents as branding } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import {
  asianBetSlipAutoCashOutAutoDelete,
  asianBetSlipCreateAutoCashOut,
  setAsianBetSlipCashOutPossibleProfit
} from 'redux/modules/asianViewBetSlipCashOut';
import {
  getAsianBetSlipCashOutPossibleProfitByMarketId,
  getAsianBetSlipCashOutQuoteByMarketId
} from 'redux/modules/asianViewBetSlipCashOut/selectors';
import {
  asianViewAutoCashOutAutoDelete,
  asianViewCreateAutoCashOut,
  setAsianViewCashOutPossibleProfit
} from 'redux/modules/asianViewCashOut';
import {
  getAsianViewCashOutPossibleProfitByMarketId,
  getAsianViewCashOutQuoteByMarketId
} from 'redux/modules/asianViewCashOut/selectors';
import { autoCashOutAutoDeleted, createAutoCashOut, setPossibleProfit } from 'redux/modules/cashOut';
import { getAutoCashOutProfitDataByMarketId, getCashOutByMarketId } from 'redux/modules/cashOut/selectors';
import {
  eventAutoCashOutAutoDelete,
  eventCreateAutoCashOut,
  setEventCashOutPossibleProfit
} from 'redux/modules/eventCashOut';
import {
  getEventCashOutPossibleProfitByMarketId,
  getEventCashOutQuoteByMarketId
} from 'redux/modules/eventCashOut/selectors';
import { PagesFromBackend } from 'redux/modules/pages/types';

import VirtualKeyboard from '../VirtualKeyboard';

import styles from '../../styles.module.scss';
import newStyles from './styles.module.scss';

interface INewCashOutState {
  marketId: string;
  isAsianBetSlip?: boolean;
  isEvent?: boolean;
  isAVMobileCashOutPage?: boolean;
  isAsianView?: boolean;
  isAVMobileBetList?: boolean;
}

const NewCashOutState = ({
  marketId,
  isAsianBetSlip = false,
  isEvent = false,
  isAVMobileCashOutPage = false,
  isAsianView = false,
  isAVMobileBetList = false
}: INewCashOutState) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const profitSelector = isAsianBetSlip
    ? getAsianBetSlipCashOutPossibleProfitByMarketId
    : isEvent
    ? getEventCashOutPossibleProfitByMarketId
    : isAsianView
    ? getAsianViewCashOutPossibleProfitByMarketId
    : getAutoCashOutProfitDataByMarketId;
  const cashOutSelector = isAsianBetSlip
    ? getAsianBetSlipCashOutQuoteByMarketId
    : isEvent
    ? getEventCashOutQuoteByMarketId
    : isAsianView
    ? getAsianViewCashOutQuoteByMarketId
    : getCashOutByMarketId;

  const autoCashOutProfitData = useSelector(profitSelector(marketId));
  const currentCashOut = useSelector(cashOutSelector(marketId));

  const [openAutoCashoutModal, setOpenAutoCashoutModal] = useState(false);

  const { isDesktop, isMobile } = useDevice();

  const profitValue = autoCashOutProfitData?.value ?? '';

  const handleCreateAutoCashOut = () => {
    if (!autoCashOutProfitData?.value || autoCashOutProfitData?.error) {
      return;
    }

    const payload = {
      marketId,
      profit: autoCashOutProfitData?.value ? parseFloat(autoCashOutProfitData?.value) : 0
    };

    if (isAsianBetSlip) {
      dispatch(asianBetSlipCreateAutoCashOut(payload));
    } else if (isEvent) {
      dispatch(eventCreateAutoCashOut(payload));
    } else if (isAsianView) {
      dispatch(asianViewCreateAutoCashOut(payload));
    } else {
      dispatch(createAutoCashOut(payload));
    }
  };

  const handleProfitInput = (value: string) => {
    const val = value?.replace(/[^0-9\.]/g, '');
    const payload = {
      marketId,
      error: parseFloat(val || '0') > currentCashOut?.possibleProfit,
      value: val,
      hideError: false
    };

    if (isAsianBetSlip) {
      dispatch(setAsianBetSlipCashOutPossibleProfit(payload));
    } else if (isEvent) {
      dispatch(setEventCashOutPossibleProfit(payload));
    } else if (isAsianView) {
      dispatch(setAsianViewCashOutPossibleProfit(payload));
    } else {
      dispatch(setPossibleProfit(payload));
    }
  };

  const handleKeyClick = (key: string) => {
    if (key === '.' && profitValue.split('.').length === 2) {
      return;
    }
    const val = profitValue + key;
    handleProfitInput(val);
  };

  const handleBackSpace = () => {
    handleProfitInput(profitValue.slice(0, -1));
  };

  const onEnter = (e: KeyboardEvent) => {
    if (e.key === KEY_CODES.ENTER) {
      handleCreateAutoCashOut();
    }
  };

  useEffect(() => {
    if (isAsianBetSlip) {
      dispatch(asianBetSlipAutoCashOutAutoDelete(marketId));
    } else if (isEvent) {
      dispatch(eventAutoCashOutAutoDelete(marketId));
    } else if (isAsianView) {
      dispatch(asianViewAutoCashOutAutoDelete(marketId));
    } else {
      dispatch(autoCashOutAutoDeleted(marketId));
    }
  }, [dispatch, marketId]);

  if (isAsianBetSlip) {
    return (
      <>
        <div className={classNames(styles.main, newStyles.betslip)}>
          <div className={newStyles.betslip__title}>{t('cashout.page.labels.ifProfitReaches')}</div>

          <div className={classNames(styles.inputWrapper, newStyles.betslip__inputWrapper)}>
            <input
              className={classNames(newStyles.betslip__input, branding.INPUT)}
              readOnly={isMobile}
              value={profitValue}
              onChange={e => handleProfitInput(e.target.value)}
              onKeyDown={onEnter}
              autoFocus
              type="text"
              placeholder={t('cashout.page.button.profit')}
            />
            <button
              onClick={handleCreateAutoCashOut}
              className={classNames('biab_cash-out-auto-btn biab_save-btn', {
                [`${styles.disabled} biab_disabled`]: autoCashOutProfitData?.error || !autoCashOutProfitData?.value,
                [newStyles.betslip__button]: isAsianBetSlip
              })}
            >
              {t('cashout.page.button.saveAutoCashOut')}
            </button>
            <i
              className={classNames('fa fas fa-question-circle biab_help-icon', styles.questionIcon)}
              onClick={() => setOpenAutoCashoutModal(true)}
            />
          </div>
        </div>
        <CashOutRulesModal
          link={PagesFromBackend.AUTO_CASH_OUT}
          openModal={openAutoCashoutModal}
          setOpenModal={setOpenAutoCashoutModal}
        />
      </>
    );
  }

  const isAsianMobile = isAVMobileCashOutPage || isAVMobileBetList || (isEvent && isAsianView && isMobile);

  return (
    <div
      className={classNames(styles.main, {
        [newStyles.coMobPage]: isAsianMobile,
        [newStyles.avContainer]: isAsianView
      })}
    >
      <div
        className={classNames(styles.cashoutFormWrapper, {
          [newStyles.coMobPage__form]: isAsianMobile,
          [styles.cashoutFormWrapper__mobile]: isMobile && !isAsianView,
          [styles.cashoutFormWrapper__mobile__av]: isMobile && isAsianView
        })}
      >
        <CashOutRulesModal
          link={PagesFromBackend.AUTO_CASH_OUT}
          openModal={openAutoCashoutModal}
          setOpenModal={setOpenAutoCashoutModal}
        />
        <div className={classNames({ [newStyles.coMobPage__ifProfit]: isAsianMobile })}>
          {t('cashout.page.labels.ifProfitReaches')}
        </div>
        <div
          className={classNames(styles.inputWrapper, {
            [newStyles.coMobPage__inputWrap]: isAsianMobile,
            [newStyles.inputWrapper__avMobile]: isMobile && isAsianView
          })}
        >
          <input
            readOnly={isMobile}
            value={profitValue}
            onChange={e => handleProfitInput(e.target.value)}
            onKeyDown={onEnter}
            autoFocus
            type="text"
            placeholder={t('cashout.page.button.profit')}
            className={classNames({
              [newStyles.coMobPage__input]: isAsianMobile,
              [newStyles.profitInput__avMobile]: isMobile && isAsianView,
              [branding.INPUT]: isAsianView
            })}
          />
          {isMobile && (
            <i
              className={classNames('fa fas fa-question-circle biab_help-icon', styles.questionIcon)}
              onClick={() => setOpenAutoCashoutModal(true)}
            />
          )}
        </div>
      </div>
      {isDesktop && (
        <button
          onClick={handleCreateAutoCashOut}
          className={classNames('biab_cash-out-auto-btn biab_save-btn', {
            [`${styles.disabled} biab_disabled`]: autoCashOutProfitData?.error || !autoCashOutProfitData?.value
          })}
        >
          {t('cashout.page.button.saveAutoCashOut')}
        </button>
      )}
      {isDesktop && (
        <i
          className={classNames('fa fas fa-question-circle biab_help-icon', styles.questionIcon)}
          onClick={() => setOpenAutoCashoutModal(true)}
        />
      )}
      {isMobile && (
        <>
          {isAsianMobile && <Warning marketId={marketId} isAVMobileCashOut={isAsianMobile} isAsianView={isAsianView} />}
          <VirtualKeyboard
            onKeyClick={handleKeyClick}
            onBackSpace={handleBackSpace}
            isWidthBorder={isAsianView}
            isAsianView={isAsianView}
          />
          <div
            className={classNames(styles.cashOutBtnWrapper, {
              [newStyles.coMobPage__btn__container]: isAsianMobile,
              [newStyles.av__btn__container]: isAsianView
            })}
          >
            <button
              onClick={handleCreateAutoCashOut}
              className={classNames({
                'biab_cash-out-auto-btn biab_save-btn': !isAVMobileCashOutPage,
                [`${styles.disabled} biab_disabled`]: autoCashOutProfitData?.error || !autoCashOutProfitData?.value,
                [newStyles.coMobPage__btn]: isAVMobileCashOutPage,
                [newStyles.coMobPage__btn__disabled]:
                  isAVMobileCashOutPage && (autoCashOutProfitData?.error || !autoCashOutProfitData?.value),
                [newStyles.autoCOBtn__avMobile]: isMobile && isAsianView
              })}
            >
              {t('cashout.page.button.saveAutoCashOut')}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default NewCashOutState;
