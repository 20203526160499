import classNames from 'classnames';

import { iconsBranding } from 'constants/branding';

import styles from './DesktopSettingsToggleableHeader.module.scss';

type DesktopSettingsToggleableHeaderProps = {
  isChecked: boolean;
  onToggle: (value: boolean) => void;
  title: string;
};

const DesktopSettingsToggleableHeader = ({ isChecked, onToggle, title }: DesktopSettingsToggleableHeaderProps) => {
  return (
    <div className={styles.toggleableHeader__header}>
      <h4 className={styles.toggleableHeader__title} onClick={() => onToggle(!isChecked)}>
        {title}
      </h4>
      <label className={styles.toggleableHeader__toggleSwitch}>
        <input
          type="checkbox"
          className={styles.toggleableHeader__toggleSwitch__input}
          checked={isChecked}
          onChange={e => onToggle(e.target.checked)}
        />
        <span
          className={classNames(styles.toggleableHeader__toggleSwitch__switch, iconsBranding.TOGGLE, {
            [iconsBranding.ACTIVE]: isChecked
          })}
        />
      </label>
    </div>
  );
};

export default DesktopSettingsToggleableHeader;
