import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import {
  getEventUpdatedDataCricketAwayFirstInningRuns,
  getEventUpdatedDataCricketAwayFirstInningWickets,
  getEventUpdatedDataCricketHomeFirstInningRuns,
  getEventUpdatedDataCricketHomeFirstInningWickets
} from 'redux/modules/marketsPrices/selectors';

import styles from './styles.module.scss';

type CricketMarketScoreProps = {
  isCashOut?: boolean;
  eventId: string;
};

const CricketMarketScore = ({ isCashOut, eventId }: CricketMarketScoreProps) => {
  const homeInningRuns = useSelector(getEventUpdatedDataCricketHomeFirstInningRuns(eventId));
  const homeInningWickets = useSelector(getEventUpdatedDataCricketHomeFirstInningWickets(eventId));
  const awayInningRuns = useSelector(getEventUpdatedDataCricketAwayFirstInningRuns(eventId));
  const awayInningWickets = useSelector(getEventUpdatedDataCricketAwayFirstInningWickets(eventId));

  const homeInner =
    isUndefined(homeInningRuns) || isUndefined(homeInningWickets)
      ? '-'
      : `${homeInningRuns}${!isNaN(parseInt(homeInningWickets)) ? `/${homeInningWickets}` : ''}`;
  const awayInner =
    isUndefined(awayInningRuns) || isUndefined(awayInningWickets)
      ? '-'
      : `${awayInningRuns}${!isNaN(parseInt(awayInningWickets)) ? `/${awayInningWickets}` : ''}`;

  return isCashOut ? (
    <table className={classNames('biab_cricket', styles.cricketCashOut)}>
      <tbody>
        <tr>
          <td>{homeInner}</td>
        </tr>
        <tr>
          <td>{awayInner} </td>
        </tr>
      </tbody>
    </table>
  ) : (
    <>
      <span>{homeInner}</span>
      <span>{awayInner}</span>
    </>
  );
};

export default CricketMarketScore;
