import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ReactComponent as Reload } from 'assets/icons/reload.svg';
import {
  PROFIT_LOSS_TABLE_POP_UP_COUNT_DOWN_INTERVAL,
  PROFIT_LOSS_TABLE_POP_UP_FETCH_DATA_INTERVAL,
  PROFIT_LOSS_TABLE_POP_UP_REFRESH_DATA_INIT_SECONDS
} from 'constants/app';
import { componentsBranding } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import { fetchProfitLossTableData } from 'redux/modules/profitLossTablePopUp';
import {
  getIsProfitLossTableDataFirstLoaded,
  getProfitLossTableDataLoading,
  getProfitLossTablePopUpEventId,
  getProfitLossTablePopUpMarketId
} from 'redux/modules/profitLossTablePopUp/selectors';

import styles from './ProfitLossTablePopUpRefreshBtn.module.scss';

type ProfitLossTablePopUpRefreshBtnProps = {
  isAsianView?: boolean;
};

const ProfitLossTablePopUpRefreshBtn = ({ isAsianView = true }: ProfitLossTablePopUpRefreshBtnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const eventId = useSelector(getProfitLossTablePopUpEventId);
  const loading = useSelector(getProfitLossTableDataLoading);
  const isFirstLoaded = useSelector(getIsProfitLossTableDataFirstLoaded);
  const marketId = useSelector(getProfitLossTablePopUpMarketId);

  const { isMobile, isLandscape } = useDevice();

  const [refreshCountDown, setRefreshCountDown] = useState(PROFIT_LOSS_TABLE_POP_UP_REFRESH_DATA_INIT_SECONDS);

  const refetchTableDataInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const reduceCountDownInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  const clearIntervals = () => {
    setRefreshCountDown(PROFIT_LOSS_TABLE_POP_UP_REFRESH_DATA_INIT_SECONDS);

    if (refetchTableDataInterval.current) {
      clearInterval(refetchTableDataInterval.current);
      refetchTableDataInterval.current = null;
    }

    if (reduceCountDownInterval.current) {
      clearInterval(reduceCountDownInterval.current);
      reduceCountDownInterval.current = null;
    }
  };

  const createIntervals = () => {
    refetchTableDataInterval.current = setInterval(() => {
      if (eventId && marketId) {
        dispatch(fetchProfitLossTableData({ eventId, marketId, isAsianView }));
      }
      setRefreshCountDown(PROFIT_LOSS_TABLE_POP_UP_REFRESH_DATA_INIT_SECONDS);
    }, PROFIT_LOSS_TABLE_POP_UP_FETCH_DATA_INTERVAL);

    reduceCountDownInterval.current = setInterval(() => {
      setRefreshCountDown(prevState => prevState - 1);
    }, PROFIT_LOSS_TABLE_POP_UP_COUNT_DOWN_INTERVAL);
  };

  const handleRefresh = () => {
    if (eventId && marketId && !loading) {
      dispatch(fetchProfitLossTableData({ eventId, marketId, isAsianView }));
    }
  };

  useEffect(() => {
    return () => {
      clearIntervals();
    };
  }, []);

  useEffect(() => {
    if (isFirstLoaded) {
      if (loading) {
        clearIntervals();
      } else {
        createIntervals();
      }
    }
  }, [loading, isFirstLoaded]);

  return (
    <button
      onClick={handleRefresh}
      className={classNames(styles.refresh, componentsBranding.TERTIARY_BTN, {
        [styles.refresh__mobile]: isMobile,
        [styles.refresh__noFirstLoaded]: !isFirstLoaded
      })}
      disabled={loading}
    >
      <Reload
        className={classNames(styles.refresh__icon, {
          [styles.refresh__icon__animation]: loading,
          [styles.refresh__icon__mobile]: isMobile,
          [styles.refresh__noFirstLoaded__icon]: !isFirstLoaded
        })}
      />
      <span
        className={classNames(styles.refresh__text, {
          [styles.refresh__text__mobile]: isMobile,
          [styles.refresh__noFirstLoaded__label]: !isFirstLoaded
        })}
      >
        {(!isMobile || isLandscape) && t('asianView.labels.plTable.refresh')} ({refreshCountDown})
      </span>
    </button>
  );
};

export default memo(ProfitLossTablePopUpRefreshBtn);
