import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';
import { IMarket, TFetchMarketRulesResponse } from 'types/markets';

import { TEvent, TEventDetails, TEventGroup } from './type';

const initialState: TEvent = {
  eventInfo: {
    id: '',
    name: '',
    eventTypeId: '',
    availableTimeFilters: null
  },
  eventGroup: {} as TEventGroup,
  marketCatalogues: [],
  viewByOptions: [],
  nestedEvents: [],
  nestedGroups: [],
  startDate: 0,
  loading: false,
  error: null,
  rules: {},
  rulesLoading: false,
  rulesError: null,
  eventDetailLoading: false
};

const slice = createSlice({
  name: SLICES_NAMES.EVENT,
  initialState,
  reducers: {
    fetchEventDetails: (state, _: PayloadAction<string>) => {
      state.loading = true;
      state.eventDetailLoading = true;
    },
    fetchEventGroup: (state, _: PayloadAction<string>) => {
      state.loading = true;
    },
    successGetEventDetails: (state, action: PayloadAction<TEventDetails>) => {
      const { eventInfo, marketCatalogues, nestedEvents, nestedGroups, startDate } = action.payload;
      state.eventInfo = eventInfo;
      state.marketCatalogues = marketCatalogues;
      state.nestedEvents = nestedEvents;
      state.nestedGroups = nestedGroups;
      state.startDate = startDate;
      state.viewByOptions = marketCatalogues.map((item: IMarket) => ({
        id: item.marketId,
        value: item.marketId,
        label: item.marketName
      }));
      state.loading = false;
      state.eventDetailLoading = false;
    },
    successGetEventGroup: (state, action: PayloadAction<TEventGroup>) => {
      state.eventGroup = action.payload;
      state.loading = false;
    },
    failureGetEventDetails: (state, action: PayloadAction<TFailureActionPayload>) => {
      state.error = action.payload;
      state.loading = false;
      state.eventDetailLoading = false;
    },
    failureGetEventGroup: (state, action: PayloadAction<TFailureActionPayload>) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchEventMarketRules: (state, _: PayloadAction<string>) => {
      state.rulesLoading = true;
    },
    successFetchEventMarketRules: (state, action: PayloadAction<TFetchMarketRulesResponse>) => {
      state.rulesLoading = false;
      state.rules[action.payload.marketId] = action.payload.rules;
    },
    failureFetchEventMarketRules: (state, action: PayloadAction<TFailureActionPayload>) => {
      state.rulesLoading = false;
      state.rulesError = action.payload;
    },
    cleanEvent: () => initialState
  }
});

export const {
  failureGetEventDetails,
  fetchEventDetails,
  failureGetEventGroup,
  fetchEventGroup,
  successGetEventDetails,
  successGetEventGroup,
  fetchEventMarketRules,
  successFetchEventMarketRules,
  failureFetchEventMarketRules,
  cleanEvent
} = slice.actions;

export default slice.reducer;
