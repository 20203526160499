import { ChangeEvent, MouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { pagination as branding } from 'constants/branding';
import { MAX_DESKTOP_PAGES_TO_SHOW, MAX_MOBILE_PAGES_TO_SHOW, pageSizes } from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import { TMyBetsResponse, TPLResponse, TStatementResponse } from 'redux/modules/myBets/type';
import { generateArray } from 'utils/general';

import styles from './styles.module.scss';

interface PaginationProps {
  source: TStatementResponse | TMyBetsResponse | TPLResponse;
  pageSize: number;
  pageNumber: number;
  setPageNumber: (number: number) => void;
  setPageSize: (size: number) => void;
  setPageSizeAndNumber: (number: number, size: number) => void;
  pageLink: string;
}

const Pagination = ({
  pageSize,
  pageNumber,
  setPageNumber,
  setPageSize,
  setPageSizeAndNumber,
  source
}: PaginationProps) => {
  const { t } = useTranslation();

  const { totalElements, totalPages } = source;

  const { isDesktop } = useDevice();
  const maxPages = isDesktop ? MAX_DESKTOP_PAGES_TO_SHOW : MAX_MOBILE_PAGES_TO_SHOW;

  const pagesList = useMemo(() => {
    const defaultPaginationArr = generateArray(totalPages, 1);
    if (defaultPaginationArr.length < maxPages + 1) {
      return defaultPaginationArr;
    }
    if (pageNumber < maxPages / 2 + 1) {
      return defaultPaginationArr.slice(0, maxPages);
    }

    return defaultPaginationArr.slice(
      pageNumber - Math.floor(maxPages / 2),
      pageNumber + Math.ceil(maxPages / 2) > totalPages ? totalPages : pageNumber + Math.ceil(maxPages / 2)
    );
  }, [pageNumber, totalPages]);

  const pageSizesToShow = pageSizes.filter(size => size < totalElements);

  const handleChangeSelectPageSize = (e: ChangeEvent<HTMLSelectElement>) => {
    const ifPageExceedsNumberOfElements = parseInt(e.target.value) * pageNumber > totalElements;
    const calculateMaxPage = Math.floor(totalElements / parseInt(e.target.value));

    if (e.target.value && ifPageExceedsNumberOfElements) {
      setPageSizeAndNumber(calculateMaxPage, parseInt(e.target.value));
    } else {
      setPageSize(parseInt(e.target.value));
    }
  };

  const blurLastCondition =
    totalPages > maxPages && pageNumber !== undefined && pageNumber < totalPages - Math.ceil(maxPages / 2);
  const blurFirstCondition = totalPages > maxPages && pageNumber && pageNumber > Math.floor(maxPages / 2);

  const handleClickPrevPage = (e: MouseEvent<HTMLDivElement>) => {
    if (source.first) {
      return e.preventDefault();
    }
    setPageNumber(pageNumber - 1);
    window.scrollTo(0, 0);
  };

  const handleClickNextPage = (e: MouseEvent<HTMLDivElement>) => {
    if (source.last) {
      return e.preventDefault();
    }
    setPageNumber(pageNumber + 1);
    window.scrollTo(0, 0);
  };

  const handleClickPage = (page: number) => {
    setPageNumber(page - 1);
    window.scrollTo(0, 0);
  };

  return (
    <div className={classNames('biab_table-widget', styles.pagination, branding.PAGINATION)}>
      <div className={styles.topBlockWrapper}>
        <div
          onClick={handleClickPrevPage}
          className={classNames(styles.paginationBtn, styles.btnPrev, {
            [styles.disabled]: source.first,
            [branding.INACTIVE_LINK]: source.first
          })}
        >
          <div
            className={classNames(styles.navBtnLink, {
              [styles.disabled]: source.first,
              [branding.INACTIVE_LINK]: source.first
            })}
            title={t('tableWidget.btns.prev')}
          >
            <i className="biab_arrow-icon fa2 fa2-arrow-left" />
            {t('tableWidget.btns.prev')}
          </div>
        </div>
        <div
          className={classNames(styles.paginationPagesWrapper, {
            [styles.pagination__blurLast]: blurLastCondition,
            [styles.pagination__blurFirst]: blurFirstCondition
          })}
        >
          {pagesList.map(page => (
            <div
              key={page}
              className={classNames(styles.paginationPagesWrapper__page, branding.PAGINATION_BTN, {
                [styles.paginationPagesWrapper__page__selected]: page === pageNumber + 1,
                [branding.SELECTED]: page === pageNumber + 1
              })}
              onClick={() => handleClickPage(page)}
            >
              <div>{page}</div>
            </div>
          ))}
        </div>
        <div
          onClick={handleClickNextPage}
          className={classNames(styles.paginationBtn, styles.btnNext, {
            [styles.disabled]: source.last,
            [branding.INACTIVE_LINK]: source.last
          })}
        >
          <div
            className={classNames(styles.navBtnLink, {
              [styles.disabled]: source.last,
              [branding.INACTIVE_LINK]: source.last
            })}
            title={t('tableWidget.btns.next')}
          >
            {t('tableWidget.btns.next')}
            <i className={'biab_arrow-icon fa2 fa2-arrow-right'} />
          </div>
        </div>
      </div>
      <div className={styles.paginationInfo}>
        <div className={styles.viewPerPage}>
          <span className={classNames(styles.paginationInfo__resultsTxt, 'biab_view-per-page-txt')}>
            {t('tableWidget.labels.perPage')}
          </span>
          <div>
            <div className={classNames(styles.currentPageSize, { [styles.shiftRight]: pageSize === 100 })}>
              <span>{pageSize}</span>
            </div>
            <select className="biab_view-per-page" value={pageSize} onChange={handleChangeSelectPageSize}>
              {pageSizesToShow.map(size => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
            <i className={classNames('fa2 fa2-arrow-down', { [styles.shiftRight]: pageSize === 100 })} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
