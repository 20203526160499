import { useTranslation } from 'react-i18next';

import { BetStatuses } from 'constants/myBets';
import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import { TMyBetsContentItem } from 'redux/modules/myBets/type';
import { BettingType } from 'types/markets';

interface GameInfoRowProps {
  bet: TMyBetsContentItem;
  currentStatus?: BetStatuses;
}

const GameInfoRow = ({ bet, currentStatus }: GameInfoRowProps) => {
  const { t } = useTranslation();

  const { betType, groupName, marketId, offerId, triggeredByCashOut, cancelledByOperator } = bet;
  const isPastStatus = currentStatus !== BetStatuses.Matched && currentStatus !== BetStatuses.Unmatched;
  const { date: placedDate } = useParseMyBetsDates({
    dateToParse: bet!.placedDate
  });
  const placementLabel = t(`account.mybets.labels.${bet.inPlay ? 'live' : 'prePlay'}Placement`);

  return (
    <>
      {bet.asian && `${placementLabel} | `}
      {betType === BettingType.GAME ? (
        <>
          {groupName && `${groupName} | `} {t('games.gameId')}: {`${marketId} | `}
          {t('account.mybets.labels.betId')}: {`${offerId} `}
          {isPastStatus && ` | ${t('account.mybets.labels.placed')}: ${placedDate}`}
        </>
      ) : (
        <>
          {groupName && `${groupName} | `} {`${t('account.mybets.labels.betId')}`}: {`${offerId} `}
          {isPastStatus && ` | ${t('account.mybets.labels.placed')}: ${placedDate}`}
          {triggeredByCashOut && ` | ${t('account.mybets.labels.triggeredByCashOut')}`}
          {cancelledByOperator && ` | ${t('account.mybets.labels.cancelledByOperator')}`}
        </>
      )}
    </>
  );
};

export default GameInfoRow;
