import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './styles.module.scss';

const POSSIBLE_STATUSES_HILO = ['lower', 'higher'];

interface IGameHiLoPrediction {
  status: string;
}

const GameHiLoPrediction = ({ status }: IGameHiLoPrediction) => {
  const { t } = useTranslation();

  if (!status || !POSSIBLE_STATUSES_HILO.includes(status.toLowerCase())) {
    return null;
  }

  return (
    <div className={classNames(styles.predictionMain, 'biab_hilo-prediction')}>
      <div>{t('games.hilo.prediction')}</div>
      <img src={require(`assets/images/games/hilo_${status.toLowerCase()}.gif`)} alt="" />
      <div
        className={styles.status}
        dangerouslySetInnerHTML={{ __html: t(`games.hilo.prediction.${status.toLowerCase()}`) }}
      />
    </div>
  );
};

export default GameHiLoPrediction;
