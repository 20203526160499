import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import SearchContent from 'components/SearchContent';
import SearchResult from 'components/SearchResult';
import useAddHeightForFooter from 'hooks/useAddHeightForFooter';
import useShowMarketOddOnPage from 'hooks/useShowMarketOddOnPage';
import { getAppDevice, getIsShowMarketOdd } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { handlingShowingSearch, setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getPopularLinks } from 'redux/modules/popular/selectors';
import { fetchPopularMarkets } from 'redux/modules/popularMarket';
import {
  getCollapsedSports,
  getSearchContentByHeadersKeys,
  getSearchFinishLoaded,
  getSearchResultBySportsKeys
} from 'redux/modules/search/selectors';

import styles from './styles.module.scss';

function Search() {
  const dispatch = useDispatch();

  const isShowMarketOdds = useSelector(getIsShowMarketOdd);
  const isSearchFinishLoaded = useSelector(getSearchFinishLoaded);
  const device = useSelector(getAppDevice);
  const popularLinks = useSelector(getPopularLinks);
  const contentSports = useSelector(getSearchContentByHeadersKeys);
  const resultSports = useSelector(getSearchResultBySportsKeys);
  const collapsedSports = useSelector(getCollapsedSports);

  const isMarketOdds = useShowMarketOddOnPage();

  const isMobile = device === Devices.MOBILE;
  const lastSportHeader = contentSports.at(-1);
  const showEmptySpace =
    resultSports.length === contentSports.length &&
    lastSportHeader &&
    collapsedSports.sportIds.includes(lastSportHeader.trim());

  const { target, targetHeight, addedHeight } = useAddHeightForFooter();

  useEffect(() => {
    dispatch(handlingShowingSearch(true));

    return () => {
      dispatch(handlingShowingSearch(false));
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (isSearchFinishLoaded && isShowMarketOdds) {
      dispatch(fetchPopularMarkets());
    }
  }, [isSearchFinishLoaded, isShowMarketOdds, dispatch]);

  useEffect(() => {
    dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: targetHeight }));
  }, [targetHeight]);

  return (
    <>
      <div
        className={classNames(styles.searchContentWrapper, {
          [styles.emptySpace]: showEmptySpace && (!isMobile || (!isMarketOdds && !popularLinks.length))
        })}
        ref={target}
      >
        {isSearchFinishLoaded && <SearchResult />}
        <SearchContent />
      </div>
      {addedHeight > 0 && <div style={{ height: addedHeight }} />}
    </>
  );
}

export default Search;
