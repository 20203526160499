import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SelectionOdds from 'components/AsianViewPageModule/components/Event/MarketCell/SelectionOdds';
import { MARKET_TYPES } from 'constants/marketTypes';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { TAsianViewMarket } from 'redux/modules/asianView/type';
import {
  getIsMarketInPlayByMarketPricesId,
  getIsMarketPricesKeyLineDefinition,
  getMarketPricesAwayScore,
  getMarketPricesFirstKeyLineAdjustedHandicap,
  getMarketPricesFirstKeyLineHandicap,
  getMarketPricesFirstKeyLineId,
  getMarketPricesHomeScore,
  getMarketPricesSecondKeyLineAdjustedHandicap,
  getMarketPricesSecondKeyLineHandicap,
  getMarketPricesSecondKeyLineId
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { getHandicapLine } from 'utils/avMarketCell';

interface MobileSelectionOddsProps {
  market: TAsianViewMarket | undefined;
  competitionId: string;
  eventId: string;
  selectionIndex: number;
  runnerIndex?: number;
  betslipId?: string;
}

const MobileSelectionOdds = ({
  market,
  competitionId,
  eventId,
  selectionIndex,
  runnerIndex = 0,
  betslipId
}: MobileSelectionOddsProps) => {
  const { t } = useTranslation();

  const isGoalLines = market?.marketType === MARKET_TYPES.altTotalGoals;
  const isAsianHandicap = market?.marketType === MARKET_TYPES.asianHandicap;
  const goalLines = isGoalLines ? market : null;
  const asianHandicap = isAsianHandicap ? market : null;

  const asianHandicapFirstKeyLineHandicap = useSelector(getMarketPricesFirstKeyLineHandicap(asianHandicap?.id));
  const asianHandicapFirstKeyLineId = useSelector(getMarketPricesFirstKeyLineId(asianHandicap?.id));
  const asianHandicapFirstKeyLineAdjHandicap = useSelector(
    getMarketPricesFirstKeyLineAdjustedHandicap(asianHandicap?.id)
  );
  const asianHandicapSecondKeyLineId = useSelector(getMarketPricesSecondKeyLineId(asianHandicap?.id));
  const asianHandicapSecondKeyLineHandicap = useSelector(getMarketPricesSecondKeyLineHandicap(asianHandicap?.id));
  const asianHandicapSecondKeyLineAdjHandicap = useSelector(
    getMarketPricesSecondKeyLineAdjustedHandicap(asianHandicap?.id)
  );
  const isAsianHandicapKeyLine = useSelector(getIsMarketPricesKeyLineDefinition(asianHandicap?.id));
  const goalLinesFirstKeyLineHandicap = useSelector(getMarketPricesFirstKeyLineHandicap(goalLines?.id));
  const goalLinesFirstKeyLineId = useSelector(getMarketPricesFirstKeyLineId(goalLines?.id));
  const goalLinesSecondKeyLineId = useSelector(getMarketPricesSecondKeyLineId(goalLines?.id));
  const isGoalLinesKeyLine = useSelector(getIsMarketPricesKeyLineDefinition(goalLines?.id));

  const homeScore = useSelector(getMarketPricesHomeScore(market?.id));
  const awayScore = useSelector(getMarketPricesAwayScore(market?.id));
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(market?.id ?? ''));

  const hasScore = isInPlay && homeScore !== undefined && awayScore !== undefined;
  const isVisible = !(!market || (market && isAsianHandicap && isInPlay && !hasScore));
  const hideMarket = !isVisible && !hasScore && isAsianHandicap;

  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: market?.id,
    eventId,
    observerOptions: { rootMargin: '200px' },
    section: MarketsPricesSocketParamsSections.AsianViewMiddleSection
  });

  const getRunners = (index = 0) => {
    const runners = market?.runners ? [...market.runners] : [];

    if (isAsianHandicap || isGoalLines) {
      const isKeyLine = isAsianHandicap ? isAsianHandicapKeyLine : isGoalLinesKeyLine;

      if (isKeyLine) {
        const homeHandicap = (isAsianHandicap ? asianHandicapFirstKeyLineHandicap : goalLinesFirstKeyLineHandicap) || 0;
        const firstKeyLineId = isAsianHandicap ? asianHandicapFirstKeyLineId : goalLinesFirstKeyLineId;
        const secondKeyLineId = isAsianHandicap ? asianHandicapSecondKeyLineId : goalLinesSecondKeyLineId;
        const hasNextLine = !!getHandicapLine({
          runners,
          handicap: homeHandicap + 0.25,
          isAsianHandicap,
          firstKeyLineId,
          secondKeyLineId
        }).length;
        const hasPrevLine = !!getHandicapLine({
          runners,
          handicap: homeHandicap - 0.25,
          isAsianHandicap,
          firstKeyLineId,
          secondKeyLineId
        }).length;

        const lineIndex =
          (index === 1 && !hasNextLine) || (index === 2 && hasPrevLine && hasNextLine)
            ? -1
            : index === 2 && !hasNextLine
            ? -2
            : index;

        const runnersGroup = getHandicapLine({
          runners,
          handicap: homeHandicap + 0.25 * lineIndex,
          isAsianHandicap,
          firstKeyLineId,
          secondKeyLineId
        });

        if (isGoalLines) {
          runnersGroup.reverse();
        }
        return runnersGroup;
      }

      return runners.slice(0, 2);
    }

    if (market?.marketType.includes(MARKET_TYPES.firstHalfGoals)) {
      runners.reverse();
    }

    return runners;
  };

  const getOddsLabel = (index: number, isOverUnder: boolean, handicap: string | number) => {
    let label = t(`asianView.labels.selection.${['H', 'A', 'D'][index]}`);

    if (isAsianHandicap) {
      let hc = +handicap;
      const isKeyLineByIndex =
        index < 2 ? (index === 0 ? asianHandicapFirstKeyLineId : asianHandicapSecondKeyLineId) : false;

      if (isAsianHandicapKeyLine && isKeyLineByIndex) {
        const ahc = index === 0 ? asianHandicapFirstKeyLineAdjHandicap : asianHandicapSecondKeyLineAdjHandicap;

        if (ahc !== undefined && ahc !== null) {
          const keyLineHandicap =
            (index === 0 ? asianHandicapFirstKeyLineHandicap : asianHandicapSecondKeyLineHandicap) || 0;

          hc = hc + (ahc - keyLineHandicap);
        }
      }

      return `${label} (${hc && Number.isInteger(hc) ? hc.toFixed(1) : hc})`;
    }

    if (isOverUnder) {
      label = t(`asianView.labels.selection.${['O', 'U'][index]}`);
      const numberOfGoals = isGoalLines
        ? handicap && Number.isInteger(handicap)
          ? parseFloat(handicap.toString()).toFixed(1)
          : handicap
        : parseFloat(market?.marketType.replace(`${MARKET_TYPES.firstHalfGoals}_`, '').replace('.', '') || '') * 0.1;
      return `${label} (${numberOfGoals})`;
    }

    return label;
  };

  const runners = market ? getRunners(runnerIndex) : [];
  const isOverUnder = !isAsianHandicap && runners.length < 3;
  const oddsLabel = getOddsLabel(selectionIndex, isOverUnder, runners[selectionIndex]?.handicap);

  return market?.id && runners[selectionIndex] && !hideMarket ? (
    <div
      ref={ref}
      data-market-id={market.id}
      data-market-type={market.marketType}
      data-event-id={eventId}
      data-market-prices="true"
    >
      <SelectionOdds
        competitionId={competitionId}
        eventId={eventId}
        marketId={market?.id}
        runner={runners[selectionIndex]}
        hasSkeleton
        oddsLabel={oddsLabel}
        betslipId={betslipId}
        runnerIndex={selectionIndex}
      />
    </div>
  ) : (
    <div />
  );
};

export default MobileSelectionOdds;
