import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Loader, { CircleColors } from 'components/Loader';
import Modal from 'components/Modal';
import { mobileSidebarItemHeight } from 'constants/webTours';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { useOverLayFadeOutTimer } from 'hooks/useOverlayFadeOutTimer';
import useWindowSize from 'hooks/useWindowSize';
import { getAppDevice, getPropertiesTours } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';

import TourModalComponent from '../TourModalComponent';

import HeaderSidebar from './components/SidebarHeader';
import SidebarItem from './components/SidebarItem';

import styles from './styles.module.scss';

interface TourModalContainerProps {
  tourHeader?: string;
  tourId: number;
  openTourModal: boolean;
  setOpenTourModal: Dispatch<SetStateAction<boolean>>;
  changeSelectedTour: (id: number) => void;
  onClose: () => void;
  fromWelcomePopup?: boolean;
  noFadeIn?: boolean;
  tourLoading?: boolean;
}
const TourModalContainer = ({
  tourHeader,
  tourId,
  openTourModal,
  setOpenTourModal,
  changeSelectedTour,
  fromWelcomePopup = false,
  onClose,
  noFadeIn = false,
  tourLoading
}: TourModalContainerProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const tours = useSelector(getPropertiesTours);

  const [rightMenu, setRightMenu] = useState<boolean>(false);
  const [showHowToSectionOnly, setShowHowToSectionOnly] = useState<boolean>(false);
  const [closeTourModal, setCloseTourModal] = useState<boolean>(false);

  const { isLandscape } = useWindowSize(100);
  const { showOverlay: showSidebarOverlay, setShowOverLay: setShowSidebarOverlay } = useOverLayFadeOutTimer(1500);

  const sidebarRef = useRef<HTMLDivElement>(null);

  const isShowOverlay = rightMenu || showSidebarOverlay;
  const isMobile = device === Devices.MOBILE;
  const isShowLandscapeWarning = isLandscape && isMobile;

  useOnClickOutside(sidebarRef, () => {
    if (!showHowToSectionOnly) {
      setRightMenu(false);
    }
  });

  useEffect(() => {
    if (fromWelcomePopup) {
      setRightMenu(true);
      setShowHowToSectionOnly(true);
    }
  }, [fromWelcomePopup]);

  useEffect(() => {
    if (closeTourModal) {
      const timer = setTimeout(() => {
        setOpenTourModal(false);
        onClose();
        setCloseTourModal(false);
      }, 400);

      return () => clearTimeout(timer);
    }
  }, [closeTourModal]);

  const openRightMenu = () => {
    if (showHowToSectionOnly) {
      return;
    }
    if (rightMenu) {
      setShowSidebarOverlay(true);
    }
    setRightMenu(!rightMenu);
  };

  const handleCloseModal = () => {
    setCloseTourModal(true);
  };

  const changeTour = (id: number) => {
    if (id === tourId && !showHowToSectionOnly) {
      return setRightMenu(false);
    }
    setRightMenu(false);
    setShowHowToSectionOnly(false);
    changeSelectedTour(id);
  };

  return (
    <Modal
      isDisableScroll
      clickableBackground
      open={openTourModal}
      onClose={handleCloseModal}
      title={showHowToSectionOnly ? '' : tourHeader}
      customClassNames={{
        container: styles.tourModalContainer,
        dialog: styles.tourModalDialog,
        content: classNames(
          { [styles.tourModalFadeout]: closeTourModal, [styles.tourModalFadein]: !closeTourModal },
          styles.tourModal
        ),
        headerContent: styles.tourModalHeaderContent,
        header: styles.tourModalHeader,
        body: styles.tourModalBody,
        backdrop: noFadeIn ? '' : styles.openModalAnimation
      }}
    >
      <>
        <div ref={sidebarRef}>
          <HeaderSidebar openRightMenu={openRightMenu} showHowToSectionOnly={showHowToSectionOnly} />
          {!isMobile ? (
            <div
              className={classNames(styles.rightMenu, {
                [styles.slide]: rightMenu,
                [styles.slideRight]: !rightMenu
              })}
            >
              {tours.map(({ tourName: name, id }) => (
                <SidebarItem key={id} id={id} tourId={tourId} name={name} changeTour={changeTour} />
              ))}
            </div>
          ) : (
            <div
              className={classNames(styles.mobileMenu, {
                [styles.slide]: rightMenu,
                [styles.slideRight]: !rightMenu,
                [styles.roundBottomBorder]: showHowToSectionOnly
              })}
              style={{ height: rightMenu ? `${tours.length * mobileSidebarItemHeight}px` : '0' }}
            >
              {tours.map(({ tourName: name, id }) => (
                <SidebarItem
                  key={id}
                  id={id}
                  tourId={tourId}
                  name={name}
                  changeTour={changeTour}
                  showHowToSectionOnly={showHowToSectionOnly}
                />
              ))}
            </div>
          )}
        </div>
        {!showHowToSectionOnly && (
          <>
            {isShowLandscapeWarning ? (
              <div className={styles.landscapeWarning}>
                <div className={styles.landscapeWarning__text}>{t('tours.mobile.switchToVerticalView')}</div>
                <i className="biab_tour-icon-rotate" />
              </div>
            ) : (
              <>
                {tourLoading ? (
                  <div className={styles.webToursLoading}>
                    <Loader circleColor={CircleColors.BLACK} />
                  </div>
                ) : (
                  <TourModalComponent tourId={tourId} />
                )}
              </>
            )}
          </>
        )}
        {isShowOverlay && (
          <div
            onClick={handleCloseModal}
            className={classNames(styles.howToOverlay, {
              [styles.fadeIn]: rightMenu,
              [styles.fadeOut]: showSidebarOverlay
            })}
          />
        )}
      </>
    </Modal>
  );
};

export default TourModalContainer;
