import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { componentsBranding } from 'constants/branding';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';

import InlineBetInfo from '../InlineBetInfo';
import InlineBetRemove from '../InlineBetRemove';
import InlinePlacedBetIcon from '../InlinePlacedBetIcon';

import styles from './styles.module.scss';

type TInlineUnavailableBetProps = { bet: TInlineSelectedBet };

const InlineUnavailableBet = ({ bet }: TInlineUnavailableBetProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.inlineSelectedBetWrap}>
      <div className={classNames(styles.inlineSelectedBet, componentsBranding.NOTIFICATION)}>
        <div className={styles.inlineSelectedBet__inner}>
          <InlinePlacedBetIcon bet={bet} />
          <div className={styles.inlineSelectedBet__info}>
            <InlineBetInfo bet={bet} />
            <p className={styles.inlineSelectedBet__note}>{t('inlinePlacement.messages.removedDueToMarketClosure')}</p>
          </div>
          <InlineBetRemove bet={bet} />
        </div>
      </div>
    </div>
  );
};

export default InlineUnavailableBet;
