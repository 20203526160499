import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { mapPLTabButtonsToAccountProductKey, PLBetType, PLTabButtons } from 'constants/myBets';
import Tab from 'pages/MyExchangeBetsPage/components/Tab';
import { TAccountProduct } from 'redux/modules/myBets/type';

import styles from './styles.module.scss';

interface PLTabsProps {
  setActivePLTab: (tabValue: PLBetType) => void;
  activePLTab?: string;
  accountProducts: TAccountProduct;
  isMobile: boolean;
}
const PLTabs = ({ setActivePLTab, activePLTab, accountProducts, isMobile }: PLTabsProps) => {
  const { t } = useTranslation();

  const availableTabs = PLTabButtons.filter(tab => accountProducts[mapPLTabButtonsToAccountProductKey[tab]]);

  return availableTabs.length > 1 ? (
    <div className={classNames(styles.tabs, 'biab_game-types biab_table-tabs', { [styles.mobileTabs]: isMobile })}>
      {availableTabs.map((el, index: number) => (
        <ul key={el} className={classNames(styles.tabButtons, 'biab_tab-btns')}>
          <Tab
            tabName={t(`account.profitandloss.labels.${el.toLowerCase()}`)}
            tab={el}
            setActiveTabButton={() => setActivePLTab(el)}
            activeTabButton={activePLTab}
            classname={{ [styles.firstTab]: index === 0, [styles.lastTab]: index === PLTabButtons.length - 1 }}
          />
        </ul>
      ))}
    </div>
  ) : null;
};

export default PLTabs;
