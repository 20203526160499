import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { asianViewMiddleSection } from 'constants/branding';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';

import styles from './styles.module.scss';

const NoResultsForSearch = () => {
  const { t } = useTranslation();

  const isMobileAsianView = useSelector(getIsMobileAsianView);

  return (
    <div
      className={classNames(styles.main, {
        [styles.mobile]: isMobileAsianView
      })}
    >
      {isMobileAsianView ? (
        <div className={classNames(styles.mobileEmpty, asianViewMiddleSection.EMPTY_MESSAGE)}>
          <span className={styles.header}>{t('asianView.labels.placementActions.searchNoResults')}</span>
          <span className={classNames(styles.description, asianViewMiddleSection.SECONDARY_TEXT)}>
            {t('asianView.labels.search.noResults')}
          </span>
        </div>
      ) : (
        t('asianView.labels.search.noResults')
      )}
    </div>
  );
};

export default NoResultsForSearch;
