import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import LoginForm from 'components/forms/LoginForm';
import TwoFactorAuthForm from 'components/forms/TwoFactorAuthForm';
import Modal from 'components/Modal';
import { PopupTypes, PostMessageTypes } from 'constants/app';
import { getAppDevice, getPostLoginNotificationEnabled } from 'redux/modules/appConfigs/selectors';
import { setAuthenticatedUser } from 'redux/modules/auth';
import { getLoggedInLoading, getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setLoginPopup } from 'redux/modules/popup';
import { getLoginPopup } from 'redux/modules/popup/selectors';
import { CookieNames } from 'types';

import styles from './styles.module.scss';

const LogInPopup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies([CookieNames.BIAB_LOGIN_POP_UP_SHOWN]);

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const loading = useSelector(getLoggedInLoading);
  const device = useSelector(getAppDevice);
  const postLoginNotificationEnabled = useSelector(getPostLoginNotificationEnabled);
  const { type, params } = useSelector(getLoginPopup);

  const isLoginPopupDisabled = cookies[CookieNames.BIAB_LOGIN_POP_UP_SHOWN] !== 'true';
  const isLoginPopupEnabled = isLoggedIn && postLoginNotificationEnabled && isLoginPopupDisabled;

  const onLoginPopupClose = () => {
    setCookie(CookieNames.BIAB_LOGIN_POP_UP_SHOWN, true, { path: '/' });
  };

  const onCloseVerificationPopup = () => {
    dispatch(setLoginPopup({ type: '' }));
    dispatch(setAuthenticatedUser(false));
  };

  useEffect(() => {
    if (!loading && !isLoggedIn && !isLoginPopupDisabled) {
      removeCookie(CookieNames.BIAB_LOGIN_POP_UP_SHOWN, {
        path: '/'
      });
    }
  }, [isLoggedIn, isLoginPopupDisabled, loading, removeCookie]);

  useEffect(() => {
    dispatch(setLoginPopup({ type: '' }));
  }, [device, dispatch]);

  return (
    <>
      {!isLoggedIn && (
        <>
          {type === PopupTypes.FAVORITE && (
            <Modal
              clickableBackground
              open={type === PopupTypes.FAVORITE}
              onClose={() => dispatch(setLoginPopup({ type: '' }))}
              title={t('navigation.favouritesModalTitle')}
            >
              <div className={styles.loginMessage}>{t('favourites.labels.login')}</div>
            </Modal>
          )}

          {type === PostMessageTypes.LOGIN_TO_PLACE_BET && (
            <Modal
              isDisableScroll
              clickableBackground
              open={type === PostMessageTypes.LOGIN_TO_PLACE_BET}
              onClose={() => dispatch(setLoginPopup({ type: '' }))}
              customClassNames={{ content: styles.loginToPlaceBet }}
              title={t('login.pop.up.title')}
            >
              <div className={styles.loginMessage}>{t('betslip.messages.logInToPlaceBets')}</div>
            </Modal>
          )}

          {type === PopupTypes.LOGIN_POPUP && (
            <Modal
              isDisableScroll
              clickableBackground
              open={type === PopupTypes.LOGIN_POPUP}
              onClose={() => dispatch(setLoginPopup({ type: '' }))}
              title={t('login.pop.up.title')}
            >
              <div className={styles.loginMessage}>{t('account.messages.login')}</div>
              <LoginForm isModal />
            </Modal>
          )}

          {type === PopupTypes.TWO_FACTOR_AUTH && (
            <Modal
              clickableBackground
              open={type === PopupTypes.TWO_FACTOR_AUTH}
              onClose={onCloseVerificationPopup}
              customClassNames={{ content: styles.twoFactorAuthPopup }}
              title={t('authentication.labels.authenticationCode')}
            >
              {params?.username && <TwoFactorAuthForm username={params.username} />}
            </Modal>
          )}
        </>
      )}
      {isLoginPopupEnabled && (
        <Modal
          open={isLoginPopupEnabled}
          onClose={onLoginPopupClose}
          title={t('notification.login.title')}
          customClassNames={{ content: styles.postLoginPopup }}
          clickableBackground
        >
          <div
            className={styles.loginMessage}
            dangerouslySetInnerHTML={{ __html: unescape(t('notification.login.body')) }}
          />
          <div className={classNames('biab_btns', styles.closePopup)}>
            <button className="biab_btn-continue" onClick={onLoginPopupClose}>
              {t('notification.login.button')}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default LogInPopup;
