import { TFailureActionPayload } from 'types';

export type AsianViewPopularLinksState = {
  asianViewPopularLinks: AsianViewPopularLink[];
  error: TFailureActionPayload;
  loading: boolean;
  isOpenedCustomPopUpLink: boolean;
  asianViewPopularLinksLoaded: boolean;
};

export type AsianViewPopularLink = {
  id?: number;
  walletGroupId: number;
  sportId?: null | string;
  entryId?: string;
  entryName: string;
  entryType: AsianPopularLinkEntryType | null;
  context: AsianPopularLinkContext;
  enabled: boolean;
  cashOutEnabled: boolean;
  promotionOrder?: number;
  iconName?: null | string;
  localizedNames: Record<string, string>;
  settings?: {
    customPopUpCloseButtonEnabled: boolean;
  };
};

export enum AsianPopularLinkContext {
  ASIAN_VIEW_COMPETITION = 'ASIAN_VIEW_COMPETITION',
  ASIAN_VIEW_POPULAR_LINK = 'ASIAN_VIEW_POPULAR_LINK'
}

export enum AsianPopularLinkEntryType {
  COMPETITION = 'COMPETITION',
  CUSTOM_POP_UP = 'CUSTOM_POP_UP'
}
