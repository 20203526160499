import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TNavigationResponse } from 'redux/modules/competitions/type';
import { TFailureActionPayload } from 'types';
import { TFetchMarketRulesResponse } from 'types/markets';

import { IGetMarketPayload, TMarket, TMarketLines, TMarketResponse } from './type';

const initialState: TMarket = {
  market: null,
  dropdownMarkets: [],
  lines: {
    from: 0,
    to: 0,
    currentLine: 1,
    defaultLine: 0,
    totalLines: 0
  },
  linesText: '',
  scrollWidth: 0,
  showAll: undefined,
  reset: false,
  loading: false,
  error: null,
  rules: null,
  rulesLoading: false,
  rulesError: null,
  raceOptions: null
};

const slice = createSlice({
  name: SLICES_NAMES.MARKET,
  initialState,
  reducers: {
    fetchMarket: (state, __: PayloadAction<IGetMarketPayload>) => {
      state.loading = true;
    },
    successFetchMarket: (state, { payload }: PayloadAction<TMarketResponse>) => {
      state.loading = false;
      state.market = payload as any;
      state.dropdownMarkets = payload.dropdownMarkets;
      state.error = null;
    },
    failureFetchMarket: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
    },
    cleanMarket: () => initialState,
    setMarketReset: (state, { payload }: PayloadAction<boolean>) => {
      state.reset = payload;
    },
    setMarketLines: (state, { payload }: PayloadAction<TMarketLines>) => {
      state.lines = payload;
    },
    setMarketLinesText: (state, { payload }: PayloadAction<string>) => {
      state.linesText = payload;
    },
    setMarketShowAll: (state, { payload }: PayloadAction<boolean>) => {
      state.showAll = payload;
    },
    fetchMarketRules: (state, _: PayloadAction<string>) => {
      state.rulesLoading = true;
    },
    successFetchMarketRules: (state, { payload }: PayloadAction<TFetchMarketRulesResponse>) => {
      state.rulesLoading = false;

      if (state.rules === null) {
        state.rules = {};
      }

      state.rules[payload.marketId] = payload.rules;
    },
    failureFetchMarketRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.rulesLoading = false;
      state.rulesError = payload;
    },
    setMarketScroll: (state, { payload }: PayloadAction<number>) => {
      state.scrollWidth = payload;
    },
    fetchRaces: (_, __: PayloadAction<string>) => {},
    successFetchRaces: (state, { payload }: PayloadAction<TNavigationResponse>) => {
      state.raceOptions = payload;
    },
    cleanRaces: state => {
      state.raceOptions = null;
    },
    failureFetchRaces: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
    }
  }
});

export const {
  failureFetchMarketRules,
  failureFetchRaces,
  failureFetchMarket,
  successFetchMarketRules,
  successFetchRaces,
  fetchMarketRules,
  setMarketLines,
  setMarketLinesText,
  setMarketReset,
  setMarketScroll,
  cleanRaces,
  successFetchMarket,
  setMarketShowAll,
  fetchMarket,
  cleanMarket,
  fetchRaces
} = slice.actions;

export default slice.reducer;
