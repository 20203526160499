import { Dispatch, SetStateAction, useRef } from 'react';
import { useResizeObserver } from 'usehooks-ts';

import RaceContent from '../RaceContent';

import styles from './LandscapeRaceContent.module.scss';

type LandscapeRaceContentProps = {
  setHeight: Dispatch<SetStateAction<number | string>>;
};

const LandscapeRaceContent = ({ setHeight }: LandscapeRaceContentProps) => {
  const raceContentRef = useRef<HTMLDivElement>(null);

  useResizeObserver({
    ref: raceContentRef,
    box: 'border-box',
    onResize: ({ height = 0 }) => {
      setHeight(height);
    }
  });

  return (
    <div className={styles.container} ref={raceContentRef}>
      <RaceContent />
    </div>
  );
};

export default LandscapeRaceContent;
