import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { asianViewBetslipBranding, asianViewIcons } from 'constants/branding';
import useAsianOdds from 'hooks/useAsianOdds';
import useAsianViewPlacementHighlighting from 'hooks/useAsianViewPlacementHighlighting';
import useOddsDisplayFormat from 'hooks/useOddsDisplayFormat';
import { getOddsType } from 'redux/modules/appSettings/selectors';
import { updateMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip';
import { AsianViewMobileBetActions, TAsianMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip/type';
import { getMarketPricesStatusById } from 'redux/modules/marketsPrices/selectors';
import { getUserAsianViewAutoRefresh } from 'redux/modules/user/selectors';
import { MarketStatus } from 'types';
import { BetTypes } from 'types/bets';
import convertOdds from 'utils/oddsConverter';

import styles from './styles.module.scss';

const MobileSelectedOdds = ({ bet, isDisabledBet }: { bet: TAsianMobileSelectedBet; isDisabledBet: boolean }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userAutoRefresh = useSelector(getUserAsianViewAutoRefresh);
  const oddsType = useSelector(getOddsType);
  const status = useSelector(getMarketPricesStatusById(bet.marketId));

  const odds = useOddsDisplayFormat(bet);
  const betPrice = useAsianOdds({ bet });

  const isBack = bet.betType === BetTypes.BACK;
  const isSuspended = status === MarketStatus.SUSPENDED;
  const isProgress = bet.action === AsianViewMobileBetActions.PROGRESS;
  const isConfirm = bet.action === AsianViewMobileBetActions.CONFIRM;
  const isPrevOddsLower = !!(bet.initPrice && +bet.initPrice < (odds || 0));
  const isGoingUp = isBack ? isPrevOddsLower : !isPrevOddsLower;
  const hasAutoRefresh = userAutoRefresh && !!odds && odds != bet.initPrice && !isSuspended && !isConfirm;
  const convertedOdds = convertOdds(
    (!isProgress && bet.action !== AsianViewMobileBetActions.CONFIRM ? betPrice : bet.price) || ' ',
    oddsType
  );
  const isOddsUp = hasAutoRefresh && isGoingUp && !isDisabledBet && !isProgress;
  const isOddsDown = hasAutoRefresh && !isGoingUp && !isDisabledBet && !isProgress;

  const { areOddsHighlighted } = useAsianViewPlacementHighlighting({ odds, isDisabledBet, hasAutoRefresh });

  useEffect(() => {
    if (isOddsDown !== bet.isLowerPrice && bet.betslipId) {
      dispatch(updateMobileSelectedBet({ betslipId: bet.betslipId, data: { isLowerPrice: isOddsDown } }));
    }
  }, [isOddsDown]);

  return (
    <div
      className={classNames(styles.odds__root, {
        [asianViewBetslipBranding.ODDS_UP]: isOddsUp && areOddsHighlighted,
        [asianViewBetslipBranding.ODDS_DOWN]: isOddsDown && areOddsHighlighted,
        [styles.odds]: !isProgress && hasAutoRefresh && areOddsHighlighted,
        [styles.odds__up]: isOddsUp && areOddsHighlighted,
        [styles.odds__down]: isOddsDown && areOddsHighlighted
      })}
    >
      @<span className={styles.odds__bold}>{convertedOdds}</span> ({t(`asianView.labels.betslip.oddsType.${oddsType}`)})
      {!isProgress && hasAutoRefresh && !isDisabledBet && areOddsHighlighted && (
        <i
          className={classNames(`fa2 fa2-sort-${isGoingUp ? 'up' : 'down'} biab_indicator`, {
            [asianViewIcons.PRICE_MORE_ICON]: isGoingUp,
            [asianViewIcons.PRICE_LESS_ICON]: !isGoingUp
          })}
        />
      )}
    </div>
  );
};

export default MobileSelectedOdds;
