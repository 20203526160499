import { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import Modal from 'components/Modal';
import { MATCH_STATISTIC_SOON } from 'constants/locations';
import { MATCH_STATISTICS } from 'constants/tooltip';
import useDevice from 'hooks/useDevice';
import useTooltip from 'hooks/useTooltip';
import { getMatchStatUrl, getNonAuthMatchStatisticsEnabled } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { EventWidgetTab } from 'types';

import styles from './styles.module.scss';

interface MatchStatisticsProps {
  isInHeader?: boolean;
  isLabel?: boolean;
  isSoccer: boolean;
  noBrandingLabel?: boolean;
  onClickIcon?: () => void;
  visible: boolean;
  isLeftSpace?: boolean;
  openedWidgetTab?: EventWidgetTab;
  setOpenedWidgetTab?: Dispatch<SetStateAction<EventWidgetTab>>;
  eventId: string;
  hideLabel?: boolean;
  hideCollapseIcon?: boolean;
  buttonClassName?: string;
  iconClassName?: string;
  tooltipClassName?: string;
  bgClassName?: string;
}

const MatchStatistics = ({
  isSoccer,
  isLabel = false,
  isInHeader = false,
  onClickIcon,
  visible,
  isLeftSpace = false,
  openedWidgetTab,
  noBrandingLabel = false,
  setOpenedWidgetTab,
  eventId,
  hideLabel = false,
  hideCollapseIcon = false,
  buttonClassName = '',
  iconClassName = '',
  tooltipClassName = '',
  bgClassName = ''
}: MatchStatisticsProps) => {
  const { t } = useTranslation();

  const { translationKey, isEnabled } = useTooltip(MATCH_STATISTICS);
  const { isMobileAsianView, isAsianViewPage, isMobile, isDesktop } = useDevice();

  const isCompactIcon = isMobileAsianView && isAsianViewPage;

  const handleClickIcon = () => {
    if (onClickIcon && visible) {
      onClickIcon();
    }
  };

  const matchStatisticsIcon = (
    <i
      onClick={handleClickIcon}
      className={classNames(
        {
          'av-icon av-icon-statistics': isCompactIcon,
          'fa2 fa2-match-statistics-icon': isSoccer && !isCompactIcon,
          'fa2 fa2-statistics': !isSoccer && !isCompactIcon,
          'biab_match-statistics-icon': !isInHeader && !isCompactIcon,
          'biab_match-statistics-toggle-icon': isInHeader && !isCompactIcon,
          biab_soccer: isSoccer,
          [styles.matchStatistics__icon__clickableWithoutTooltip]: !!onClickIcon && visible,
          [styles.matchStatistics__icon__leftSpace]: isLeftSpace && !isInHeader
        },
        iconClassName
      )}
    >
      {!isCompactIcon && (
        <>
          <span className={classNames('path1', 'biab_icon-bg', bgClassName)} />
          <span
            className={classNames('path2', {
              'biab_icon-bg': isSoccer,
              [bgClassName]: bgClassName && isSoccer
            })}
          />
          <span className="path3" />
          <span className="path4" />
          <span
            className={classNames('path5', {
              'biab_icon-bg': isSoccer,
              [bgClassName]: bgClassName && isSoccer
            })}
          />
          <span className="path6" />
          <span className="path7" />
          <span className="path8" />
          <span className="path9" />
          <span className="path10" />
        </>
      )}
    </i>
  );

  const contentWithTooltipWrapper = (
    <span
      data-tooltip-id="tooltip"
      data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
      className={classNames(
        styles.matchStatistics__tooltip,
        {
          'cursor-help': isEnabled
        },
        tooltipClassName
      )}
    >
      {isLabel ? (
        <div className={styles.matchStatistics__iconAndLabel}>
          {matchStatisticsIcon}
          <span
            className={classNames(styles.matchStatistics__text, {
              'biab_match-statistics-label': !noBrandingLabel
            })}
          >
            {t('match.statistics')}
          </span>
        </div>
      ) : (
        matchStatisticsIcon
      )}
    </span>
  );

  if (isInHeader) {
    return (
      <ButtonWrapper
        isLeftSpace={isLeftSpace}
        openedTab={openedWidgetTab}
        setOpenedTab={setOpenedWidgetTab}
        eventId={eventId}
        hideLabel={hideLabel}
        hideCollapseIcon={hideCollapseIcon}
        buttonClassName={buttonClassName}
        bgClassName={bgClassName}
        isMobile={isMobile}
        isDesktop={isDesktop}
      >
        {contentWithTooltipWrapper}
      </ButtonWrapper>
    );
  }

  return contentWithTooltipWrapper;
};

interface ButtonWrapperProps {
  isLeftSpace: boolean;
  openedTab?: EventWidgetTab;
  setOpenedTab?: Dispatch<SetStateAction<EventWidgetTab>>;
  eventId: string;
  hideLabel: boolean;
  hideCollapseIcon: boolean;
  buttonClassName: string;
  bgClassName?: string;
  isMobile: boolean;
  isDesktop: boolean;
}

function ButtonWrapper({
  children,
  isLeftSpace,
  openedTab,
  eventId,
  setOpenedTab,
  hideLabel,
  hideCollapseIcon,
  buttonClassName,
  bgClassName = '',
  isMobile,
  isDesktop
}: PropsWithChildren<ButtonWrapperProps>) {
  const { t } = useTranslation();

  const nonAuthMatchStatisticsEnabled = useSelector(getNonAuthMatchStatisticsEnabled);
  const matchStatUrl = useSelector(getMatchStatUrl);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const [desktopModal, setDesktopModal] = useState<{ visible: boolean; content: string }>({
    visible: false,
    content: ''
  });

  const isMatchStatisticsOpened = openedTab === 'match-statistics';

  const handleClickMatchStatistics = () => {
    if (isMobile) {
      setOpenedTab?.(isMatchStatisticsOpened ? null : 'match-statistics');
    } else {
      if (nonAuthMatchStatisticsEnabled || isLoggedIn) {
        const url = matchStatUrl
          ? `${matchStatUrl}${eventId}`
          : `${window.location.origin}/customer${MATCH_STATISTIC_SOON}`;

        window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,width=650,height=500');
      } else {
        setDesktopModal({ visible: true, content: t('match.statistics.messages.login') });
      }
    }
  };

  return (
    <>
      <button
        onClick={handleClickMatchStatistics}
        className={classNames(
          'biab_match-statistics-toggle',
          styles.matchStatistics__button,
          {
            [styles.matchStatistics__button__leftSpace]: isLeftSpace,
            [styles.matchStatistics__button__mobileHeader]: isMobile
          },
          buttonClassName,
          bgClassName
        )}
      >
        {children}
        {!hideLabel && <span className="biab_match-statistics-label">{t('match.statistics')}</span>}
        {isMobile && !hideCollapseIcon && (
          <i
            className={classNames(
              `biab_collapse-icon fa2 fa2-arrow-${isMatchStatisticsOpened ? 'up' : 'down'}`,
              styles.matchStatistics__arror
            )}
          />
        )}
      </button>
      {isDesktop && desktopModal.visible && (
        <Modal
          open={desktopModal.visible}
          onClose={() => setDesktopModal({ visible: false, content: '' })}
          customClassNames={{ container: styles.matchStatistics__modal, body: styles.matchStatistics__modal__content }}
        >
          {desktopModal.content}
        </Modal>
      )}
    </>
  );
}

export default MatchStatistics;
