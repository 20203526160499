import { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { PARAMS_SEARCH_KEY } from 'constants/urlParams';
import useElementSize from 'hooks/useElementSize';
import { handlingShowingSearch, setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { resetCollapsedSports, resetSearch } from 'redux/modules/search';

import styles from './styles.module.scss';

function SearchInput() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.get(PARAMS_SEARCH_KEY) || '';

  function cleanSearch(doResetSearch = true) {
    searchParams.delete(PARAMS_SEARCH_KEY);
    setSearchParams(searchParams);
    if (doResetSearch) {
      dispatch(resetSearch());
    }
  }

  const handleChangeSearchTerm = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    dispatch(resetSearch());
    dispatch(resetCollapsedSports());

    if (value === '') {
      cleanSearch(false);
    } else {
      searchParams.set(PARAMS_SEARCH_KEY, value);
      setSearchParams(searchParams);
    }
  };

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.SEARCH_INPUT_HEIGHT, height }));
    }
  });

  useEffect(() => {
    dispatch(handlingShowingSearch(true));

    return () => {
      dispatch(handlingShowingSearch(false));
      dispatch(setElementHeight({ name: EElementNames.SEARCH_INPUT_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <div ref={componentRef} className="js-search-region biab_search biab_hidden-xs">
      <div className="biab_search-container">
        <div className={classNames('biab_seach-field-wrapper', styles.searchContainer)}>
          <input
            className={classNames('biab_search-input', styles.searchContainer__input)}
            type="text"
            name="search"
            autoComplete="off"
            maxLength={127}
            value={query}
            onChange={handleChangeSearchTerm}
            placeholder={t('search.placeholder')}
          />
          <i className={classNames('fa2 fa2-search', styles.searchContainer__icon)} />
          {query.length > 0 && (
            <i
              onClick={() => cleanSearch()}
              className={classNames(
                'fa fas fa-times biab_clear-search-icon',
                styles.searchContainer__icon,
                styles.searchContainer__cross__icon
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchInput;
