import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AUTO_CASH_OUT_GET_MARKETS_INTERVAL, GeneralWebSocketSubscriptionTypes } from 'constants/app';
import {
  getAutoCashOutEnabled,
  getAutoCashOutWsEnabled,
  getCashOutGetQuotesInterval,
  getCashOutQuoteWsEnabled,
  getGeneralWsEnabled,
  getIsPropertiesLoaded,
  getLanguage,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchAutoCashOutMarkets, fetchCashOutQuotes } from 'redux/modules/cashOut';
import { getFetchAutoCashOutLoading } from 'redux/modules/cashOut/selectors';
import { isMarketHasOffers } from 'redux/modules/currentBets/selectors';
import { getIsConnectedGeneral, getSubscribeToGeneralMessages } from 'redux/modules/webSocket/selectors';

type CashOutQuotesAndAutoCashOutsIntervalsInjectionProps = {
  marketId: string;
  cashOutEnabled: boolean;
};

const CashOutQuotesAndAutoCashOutsIntervalsInjection = ({
  marketId,
  cashOutEnabled
}: CashOutQuotesAndAutoCashOutsIntervalsInjectionProps) => {
  const dispatch = useDispatch();

  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const autoCashOutEnabled = useSelector(getAutoCashOutEnabled);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isMarketHasOffer = useSelector(isMarketHasOffers(marketId));
  const autoCashOutLoading = useSelector(getFetchAutoCashOutLoading);
  const autoCashOutWsEnabled = useSelector(getAutoCashOutWsEnabled);
  const cashOutQuoteWsEnabled = useSelector(getCashOutQuoteWsEnabled);
  const cashOutGetQuotesInterval = useSelector(getCashOutGetQuotesInterval);
  const isConnectedGeneralWebSocket = useSelector(getIsConnectedGeneral);
  const subscribeGeneralWebSocketMessages = useSelector(getSubscribeToGeneralMessages);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const quotesIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const autoCashOutsIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const autoCashOutLoadingRef = useRef(autoCashOutLoading);
  const generalWsDataRef = useRef<{
    isAvailableCashOutQuoteSubscription: boolean;
    isAvailableAutoCashOutSubscription: boolean;
    subscribeGeneralWebSocketMessages: (<F>(params: F) => void) | null;
  }>({
    isAvailableAutoCashOutSubscription: false,
    isAvailableCashOutQuoteSubscription: false,
    subscribeGeneralWebSocketMessages: null
  });

  autoCashOutLoadingRef.current = autoCashOutLoading;

  const isAvailableCashOutQuote =
    cashOutEnabled && isLoggedIn && !!marketId && !!isMarketHasOffer && arePropertiesLoaded;
  const isAvailableAutoCashOut =
    autoCashOutEnabled && cashOutEnabled && !!isMarketHasOffer && isLoggedIn && !!marketId && arePropertiesLoaded;
  const isAvailableCashOutQuoteInterval = isAvailableCashOutQuote && (!generalWsEnabled || !cashOutQuoteWsEnabled);
  const isAvailableAutoCashOutInterval = isAvailableAutoCashOut && (!generalWsEnabled || !autoCashOutWsEnabled);
  const isAvailableCashOutQuoteSubscription =
    isAvailableCashOutQuote &&
    cashOutQuoteWsEnabled &&
    generalWsEnabled &&
    isConnectedGeneralWebSocket &&
    !!subscribeGeneralWebSocketMessages;
  const isAvailableAutoCashOutSubscription =
    isAvailableAutoCashOut &&
    autoCashOutWsEnabled &&
    generalWsEnabled &&
    isConnectedGeneralWebSocket &&
    !!subscribeGeneralWebSocketMessages;

  generalWsDataRef.current = {
    isAvailableCashOutQuoteSubscription,
    isAvailableAutoCashOutSubscription,
    subscribeGeneralWebSocketMessages
  };

  useEffect(() => {
    if (isAvailableCashOutQuoteInterval) {
      dispatch(fetchCashOutQuotes({ ids: [marketId] }));

      quotesIntervalRef.current = setInterval(
        () => dispatch(fetchCashOutQuotes({ ids: [marketId] })),
        cashOutGetQuotesInterval
      );
    }

    return () => {
      if (quotesIntervalRef.current) {
        clearInterval(quotesIntervalRef.current);
      }
    };
  }, [cashOutGetQuotesInterval, isAvailableCashOutQuoteInterval, language, timezone]);

  useEffect(() => {
    if (isAvailableAutoCashOutInterval) {
      dispatch(fetchAutoCashOutMarkets([marketId]));

      autoCashOutsIntervalRef.current = setInterval(() => {
        if (!autoCashOutLoadingRef.current) {
          dispatch(fetchAutoCashOutMarkets([marketId]));
        }
      }, AUTO_CASH_OUT_GET_MARKETS_INTERVAL);
    }

    return () => {
      if (autoCashOutsIntervalRef.current) {
        clearInterval(autoCashOutsIntervalRef.current);
      }
    };
  }, [language, timezone, isAvailableAutoCashOutInterval]);

  useEffect(() => {
    if (isAvailableCashOutQuoteSubscription && marketId) {
      subscribeGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.cashOutQuote]: {
          subscribe: true,
          marketIds: [marketId]
        }
      });
    }
  }, [isAvailableCashOutQuoteSubscription, marketId, language, timezone]);

  useEffect(() => {
    if (isAvailableAutoCashOutSubscription && marketId) {
      subscribeGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.autoCashOut]: {
          subscribe: true,
          marketIds: [marketId]
        }
      });
    }
  }, [isAvailableAutoCashOutSubscription, marketId, language, timezone]);

  useEffect(() => {
    return () => {
      const {
        isAvailableAutoCashOutSubscription: isAvailableAutoCashOutWs,
        isAvailableCashOutQuoteSubscription: isAvailableCashOutQuoteWs,
        subscribeGeneralWebSocketMessages: subscribeFunc
      } = generalWsDataRef.current;

      if (isAvailableCashOutQuoteWs && subscribeFunc) {
        subscribeFunc({
          [GeneralWebSocketSubscriptionTypes.cashOutQuote]: { subscribe: false }
        });
      }

      if (isAvailableAutoCashOutWs && subscribeFunc) {
        subscribeFunc({
          [GeneralWebSocketSubscriptionTypes.autoCashOut]: { subscribe: false }
        });
      }
    };
  }, []);

  return null;
};

export default CashOutQuotesAndAutoCashOutsIntervalsInjection;
