import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { groupBy, keys, sortBy } from 'lodash';

import CashOutButton from 'components/CashOutButton';
import { MARKET_TYPES } from 'constants/marketTypes';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getAsianViewCashOutQuoteByMarketId } from 'redux/modules/asianViewCashOut/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';

import AVBetListMobileMarketContainerBet from '../AVBetListMobileMarketContainerBet/AVBetListMobileMarketContainerBet';
import AVBetListMobilePriceAndOddsType from '../AVBetListMobilePriceAndOddsType/AVBetListMobilePriceAndOddsType';
import AVBetListMobileSideIndicator from '../AVBetListMobileSideIndicator/AVBetListMobileSideIndicator';

import styles from './AVBetListMobileMarketsContainer.module.scss';

type AVBetListMobileMarketsContainerProps = {
  betsGroup: TCurrentBet[];
  marketId: string;
};

const formatCurrencySettings = { noRounding: true, isCheckIndian: true };

const AVBetListMobileMarketsContainer = ({ betsGroup, marketId }: AVBetListMobileMarketsContainerProps) => {
  const { t } = useTranslation();

  const {
    marketName: firstBetMarketName,
    side: firstBetSide,
    averagePriceRounded: firstBetAveragePriceRounded,
    oddsType: firstBetOddsType,
    currency: firstBetCurrency,
    partialCashOutEnabled: firstPartialCashOutEnabled,
    cashOutEnabled: isFirstCashOutEnabled,
    selectionName: firstBetSelectionName,
    score: firstBetScore,
    marketType: firstBetMarketType,
    eventInPlay: isFirstBetEventInPlay,
    marketLiability: totalLiability,
    marketProfit: potentialProfit
  } = betsGroup[0];

  const cashOutQuote = useSelector(getAsianViewCashOutQuoteByMarketId(marketId));

  const [isExpanded, setIsExpanded] = useState(false);

  const { betSides, sortedBetsGroup } = useMemo(() => {
    const betSidesVal = keys(groupBy(betsGroup, ({ side }) => side));
    const sortedBetsGroupVal = sortBy(betsGroup, 'placedDate').reverse();

    return {
      betSides: betSidesVal,
      sortedBetsGroup: sortedBetsGroupVal
    };
  }, [betsGroup]);

  const { noFormattedAmount: noFormattedTotalLiability } = useFormatCurrency(
    totalLiability,
    firstBetCurrency,
    formatCurrencySettings
  );
  const { noFormattedAmount: noFormattedPotentialProfit } = useFormatCurrency(
    potentialProfit,
    firstBetCurrency,
    formatCurrencySettings
  );

  const multipleBets = betsGroup.length > 1;
  const displayScoreSnapshot =
    firstBetScore && isFirstBetEventInPlay && firstBetMarketType === MARKET_TYPES.asianHandicap;

  return (
    <div className={styles.marketContainer}>
      <div
        className={styles.marketContainer__header}
        onClick={multipleBets ? () => setIsExpanded(prevState => !prevState) : undefined}
      >
        <div className={styles.marketContainer__header__left}>
          <AVBetListMobileSideIndicator betSides={betSides} betGroupsCount={betsGroup.length} isExpanded={isExpanded} />
          <h3 className={styles.marketContainer__marketName}>
            {firstBetMarketName}
            {multipleBets ? '' : ` - ${firstBetSelectionName}`}
            {displayScoreSnapshot ? ` (${firstBetScore})` : ''}
          </h3>
        </div>
        {multipleBets ? (
          <div className={styles.marketContainer__betsCounter}>
            <div className={styles.marketContainer__betsCounter__left}>
              <p className={styles.marketContainer__betsCounter__left__top}>{t('asianView.labels.betList.bets')}</p>
              <p className={styles.marketContainer__betsCounter__left__bottom}>{betsGroup.length}</p>
            </div>
            <i
              className={classNames('av-icon', styles.marketContainer__betsCounter__arrow, {
                'av-icon-arrow-down': !isExpanded,
                'av-icon-arrow-up': isExpanded
              })}
            />
          </div>
        ) : (
          <AVBetListMobilePriceAndOddsType
            price={firstBetAveragePriceRounded}
            side={firstBetSide}
            oddsType={firstBetOddsType}
          />
        )}
      </div>
      {multipleBets && !isExpanded ? (
        <div
          className={classNames(styles.marketContainer__total__container, {
            [styles.marketContainer__total__container__withCashOut]: isFirstCashOutEnabled
          })}
        >
          <div className={styles.marketContainer__total}>
            <p className={styles.marketContainer__total__top}>{t('asianView.labels.betList.totalLiability')}</p>
            <p className={styles.marketContainer__total__bottom}>{noFormattedTotalLiability}</p>
          </div>
          <div className={styles.marketContainer__total}>
            <p className={styles.marketContainer__total__top}>{t('asianView.labels.betList.potentialProfit')}</p>
            <p className={styles.marketContainer__total__bottom}>{noFormattedPotentialProfit}</p>
          </div>
        </div>
      ) : (
        <>
          {sortedBetsGroup.map(bet => {
            return <AVBetListMobileMarketContainerBet key={bet.offerId} bet={bet} hideHeader={!multipleBets} />;
          })}
        </>
      )}
      {isFirstCashOutEnabled && !!cashOutQuote && (
        <CashOutButton
          marketId={marketId}
          partialCashOutEnabled={firstPartialCashOutEnabled}
          isAsianView
          isAVMobileBetList
          buttonWrapperClassName={styles.cashOut__btn__wrapper}
          buttonClassName={styles.cashOut__btn}
          cashOutTextClassName={styles.cashOut__btn__cashOutText}
          settingsBtnClassName={styles.cashOut__settingsBtn}
          settingsActiveBtnClassName={styles.cashOut__settingsBtn__active}
          tabsContainerClassName={styles.cashOut__tabs__container}
          ignoreFlexibleDisplayFormat
        />
      )}
    </div>
  );
};

export default AVBetListMobileMarketsContainer;
