import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

const getTop5ViewDetails = (state: AppState) => state.top5View;

export const getTop5ViewLoader = ({ top5View }: AppState) => top5View.loading;
export const getTop5ViewEventRules = createSelector(getTop5ViewDetails, multiMarket => multiMarket.eventRules);
export const getTop5DropdownMarkets = createSelector(getTop5ViewDetails, top5View => top5View.dropdownMarkets);

export const getAllTop5ViewMarkets = createSelector(
  getTop5ViewDetails,
  ({ topMatchedMarket, otherTopMatchedMarkets }) => {
    if (topMatchedMarket) {
      return [topMatchedMarket, ...otherTopMatchedMarkets];
    }

    return otherTopMatchedMarkets ?? [];
  }
);

export const getTop5Markets = createSelector(getTop5ViewDetails, ({ topMatchedMarket, otherTopMatchedMarkets }) => {
  const top4OtherMarkets = otherTopMatchedMarkets.slice(0, 4);

  if (topMatchedMarket) {
    return [topMatchedMarket, ...top4OtherMarkets];
  }

  return top4OtherMarkets ?? [];
});

export const getTop5ViewTopMatchedMarket = ({ top5View }: AppState) => top5View.topMatchedMarket;
export const getTop5ViewRules = ({ top5View }: AppState) => top5View.rules;
export const getTop5ViewRulesLoading = ({ top5View }: AppState) => top5View.rulesLoading;
export const getTop5ViewOtherTopMatchedMarkets = ({ top5View }: AppState) => top5View.otherTopMatchedMarkets;
export const getTop5ViewLines = ({ top5View }: AppState) => top5View.lines;
export const getTop5ViewLinesText = ({ top5View }: AppState) => top5View.linesText;
export const getTop5ViewShowAll = ({ top5View }: AppState) => top5View.showAll;
export const getTop5ViewReset = ({ top5View }: AppState) => top5View.reset;
