import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import {
  failureFetchCurrentGameData,
  failureFetchGameRules,
  failureFetchResultHistory,
  failureGetGames,
  fetchCurrentGameData,
  fetchGameRules,
  fetchGames,
  fetchResultsHistory,
  successFetchCurrentGameData,
  successFetchGameRules,
  successFetchResultHistory,
  successGetGames
} from './index';
import { TCurrentGameData, TGame, TGameRules, THistory } from './type';

function* getGamesWorker() {
  try {
    const response: TGame[] = yield call(api.games.getGames);
    yield put(successGetGames(response));
  } catch (error: any) {
    yield put(failureGetGames(error));
  }
}

function* getCurrentGameDataWorker(action: ReturnType<typeof fetchCurrentGameData>) {
  try {
    const response: TCurrentGameData = yield call(api.games.getCurrentGameData, action.payload);
    yield put(successFetchCurrentGameData(response));
  } catch (error: any) {
    yield put(failureFetchCurrentGameData(error));
  }
}

function* getGameRulesWorker(action: ReturnType<typeof fetchGameRules>) {
  try {
    const response: TGameRules = yield call(api.games.getGameRules, action.payload);
    yield put(successFetchGameRules(response));
  } catch (error: any) {
    yield put(failureFetchGameRules(error));
  }
}

function* getResultsHistoryWorker(action: ReturnType<typeof fetchResultsHistory>) {
  try {
    const response: THistory = yield call(api.games.getResultsHistory, action.payload);
    yield put(successFetchResultHistory({ ...response, actionType: action.payload.actionType }));
  } catch (error: any) {
    yield put(failureFetchResultHistory(error));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchGames.type, getGamesWorker),
    takeLatest(fetchCurrentGameData.type, getCurrentGameDataWorker),
    takeLatest(fetchGameRules.type, getGameRulesWorker),
    takeLatest(fetchResultsHistory.type, getResultsHistoryWorker)
  ]);
}
