import { ReactNode } from 'react';
import { CalendarContainer } from 'react-datepicker';
import classNames from 'classnames';

import useDevice from 'hooks/useDevice';

import styles from './styles.module.scss';

interface CalendarCustomContainerProps {
  className: string;
  children: ReactNode;
}

const CalendarCustomContainer = ({ className, children }: CalendarCustomContainerProps) => {
  const { isMobile } = useDevice();

  return (
    <div className="biab_account-page ui-datepicker">
      <div
        className={classNames('ui-datepicker-calendar', styles.calendarContainerWrapper, {
          [styles.calendarContainerWrapper__mobile]: isMobile
        })}
      >
        <CalendarContainer className={className}>
          <table>
            <tbody>
              <tr>
                <td>
                  <div className={styles.calendarCustomContainer}>{children}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </CalendarContainer>
      </div>
    </div>
  );
};

export default CalendarCustomContainer;
