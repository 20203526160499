import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BALANCE_UPDATE_INTERVAL, GeneralWebSocketSubscriptionTypes } from 'constants/app';
import {
  getBalanceWsEnabled,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled,
  getIsPropertiesLoaded,
  getLanguage,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchBalance } from 'redux/modules/user';
import { getUserBalanceLoading } from 'redux/modules/user/selectors';
import {
  getIsConnectedAsianViewGeneral,
  getIsConnectedGeneral,
  getSubscribeToAsianViewGeneralMessages,
  getSubscribeToGeneralMessages
} from 'redux/modules/webSocket/selectors';

const BalanceInjection = () => {
  const dispatch = useDispatch();

  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const loading = useSelector(getUserBalanceLoading);
  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const isConnectedGeneralWebSocket = useSelector(getIsConnectedGeneral);
  const subscribeGeneralWebSocketMessages = useSelector(getSubscribeToGeneralMessages);
  const isConnectedAsianViewGeneralWebSocket = useSelector(getIsConnectedAsianViewGeneral);
  const subscribeAsianViewGeneralWebSocketMessages = useSelector(getSubscribeToAsianViewGeneralMessages);
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  const isSubscriptionAvailable =
    isLoggedIn && !isOperatorBalanceEnabled && balanceWsEnabled && arePropertiesLoaded && generalWsEnabled;
  const isSportViewSubscriptionAvailable =
    isSubscriptionAvailable && !!subscribeGeneralWebSocketMessages && isConnectedGeneralWebSocket;
  const isAsianViewSubscriptionAvailable =
    isSubscriptionAvailable && !!subscribeAsianViewGeneralWebSocketMessages && isConnectedAsianViewGeneralWebSocket;
  const isIntervalAvailable =
    isLoggedIn && !isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled) && arePropertiesLoaded;

  useEffect(() => {
    if (isIntervalAvailable) {
      const getBalanceInterval = setInterval(() => {
        if (!loadingRef.current) {
          dispatch(fetchBalance());
        }
      }, BALANCE_UPDATE_INTERVAL);

      return () => clearInterval(getBalanceInterval);
    }
  }, [isIntervalAvailable, language, timezone]);

  useEffect(() => {
    if (isSportViewSubscriptionAvailable) {
      subscribeGeneralWebSocketMessages({ [GeneralWebSocketSubscriptionTypes.balance]: { subscribe: true } });
    }
  }, [isSportViewSubscriptionAvailable]);

  useEffect(() => {
    if (isAsianViewSubscriptionAvailable) {
      subscribeAsianViewGeneralWebSocketMessages({ [GeneralWebSocketSubscriptionTypes.balance]: { subscribe: true } });
    }
  }, [isAsianViewSubscriptionAvailable]);

  return null;
};

export default BalanceInjection;
