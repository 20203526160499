import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import BackButton from 'components/Header/components/BackButton';
import HeaderDetails from 'components/TimeForm/components/HeaderDetails';
import { IconsConfig } from 'constants/iconsConfig';
import useBackButtonInIFrame from 'hooks/useBackButtonInIFrame';
import useLongDateFormat from 'hooks/useLongDateFormat';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { getSingleMarketInfo } from 'redux/modules/market/selectors';
import { getIsMarketInPlayByMarketPricesId, getStatusByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { MarketStatus, SportId } from 'types';

import styles from './styles.module.scss';

const EventInfo = () => {
  const { sportId = '' } = useParams();
  const { t } = useTranslation();

  const marketInfo = useSelector(getSingleMarketInfo);
  const marketPricesStatus = useSelector(getStatusByMarketPricesId(marketInfo?.marketId ?? ''));
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketInfo?.marketId ?? ''));

  const showIFrameBackButton = useBackButtonInIFrame();
  const marketTime = useLongDateFormat({
    time: marketInfo?.marketStartTime ?? 0,
    hasWeekDay: true
  });

  // TODO should be replaced with event-updates
  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: marketInfo?.marketId,
    eventId: marketInfo?.event?.id,
    section: MarketsPricesSocketParamsSections.SportsMiddleSection,
    observerOptions: { rootMargin: '200px' }
  });

  const isHorseRacing = sportId === SportId.TODAY_HORSE_RACING;
  const isRaceDetails = isHorseRacing && !!marketInfo?.timeformStatistics?.raceDetails && !marketInfo?.closedDate;
  const isMarketClosed = marketPricesStatus === MarketStatus.CLOSED;

  const inPlayStatus = (
    <div className="biab_market-in-play-icon">
      <i className="fa2 fa2-inplay" title={t('header.inPlay')} />
      <span className={classNames('biab_market-in-play-label', styles.eventInfo__inPlay)}>{t('header.inPlay')}</span>
    </div>
  );

  return (
    <div
      ref={ref}
      className={styles.eventInfo}
      data-market-id={marketInfo?.marketId}
      data-event-id={marketInfo?.event.id}
      data-market-prices={true}
    >
      {showIFrameBackButton ? (
        <BackButton classes={{ container: styles.eventInfo__backButton }} />
      ) : (
        <i className={classNames('biab_br-sport-icon', IconsConfig.SPORT_ICONS[sportId])} />
      )}
      <div>
        <p className={styles.eventInfo__title}>{marketInfo?.event.name}</p>
        {isRaceDetails ? (
          <div className={classNames('biab_timeform-event-info', styles.eventInfo__raceDetails)}>
            {!isMarketClosed && (
              <>
                {isInPlay ? (
                  inPlayStatus
                ) : (
                  <div className={classNames('biab_market-date biab_visible-xs', styles.eventInfo__date)}>
                    {marketTime}
                  </div>
                )}
              </>
            )}
            <HeaderDetails details={marketInfo?.timeformStatistics!.raceDetails} isCloseMarket={isMarketClosed} />
          </div>
        ) : !isInPlay ? (
          <div className="biab_market-title">
            <div className="biab_market-date">{marketTime}</div>
          </div>
        ) : (
          inPlayStatus
        )}
      </div>
    </div>
  );
};

export default EventInfo;
