import { MarketStatus } from 'types';

import { BETTING_TYPES } from './app';

export const BET_GROUPS_WITH_DRAW = ['1', 'X', '2'];
export const BET_GROUPS_WITHOUT_DRAW = ['1', '2'];
export const DEFAULT_CELL_WIDTH = 8;
export const NOT_DISPLAYING_STATUSES = [MarketStatus.OPEN, MarketStatus.IN_PLAY];
export const MATCHED_CELL_WIDTH = 120;
export const LARGE_PLACEMENTS_CELL_WIDTH = 70;
export const SMALL_PLACEMENTS_CELL_WIDTH = 40;
export const MAX_MARKETS_COUNT_ON_SEARCH = 3;

export const PLTableType = [
  BETTING_TYPES.asianHandicapSingleLine,
  BETTING_TYPES.asianHandicapDoubleLine,
  BETTING_TYPES.line
];
