import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { uniq, uniqBy } from 'lodash';

import LiabilityByMarket from 'components/AsianViewBetslip/components/LiabilityByMarket';
import { TOTAL_LIABILITY_PRECISION } from 'constants/placement';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getAsianSelectedBetsList, getLiabilityByMarket } from 'redux/modules/asianViewBetslip/selectors';
import { roundFloat } from 'utils/liability';

import styles from './styles.module.scss';

const TotalLiability = () => {
  const { t } = useTranslation();

  const selectedBets = useSelector(getAsianSelectedBetsList);
  const liabilityByMarket = useSelector(getLiabilityByMarket);

  const marketsAndEventsIds = uniqBy(
    selectedBets.map(({ marketId, eventId }) => {
      return { marketId, eventId };
    }),
    'marketId'
  );
  const marketsIds = uniq(selectedBets.map(({ marketId }) => marketId));

  const totalLiability = useMemo(() => {
    let total = 0;

    Object.entries(liabilityByMarket).forEach(([marketId, liability]) => {
      if (marketsIds.includes(marketId)) {
        total += liability;
      }
    });

    return total;
  }, [marketsIds, liabilityByMarket]);

  const { noFormattedAmount: liabilityFormatted } = useFormatCurrency(
    roundFloat(totalLiability, TOTAL_LIABILITY_PRECISION),
    '',
    {
      isCheckIndian: true,
      noRounding: true
    }
  );

  return (
    <>
      <div className={styles.markets}>
        {marketsAndEventsIds.map(({ marketId, eventId }) => (
          <LiabilityByMarket key={marketId} marketId={marketId} eventId={eventId} />
        ))}
      </div>
      <div className={styles.totalLiability}>
        <p className={styles.totalLiability__label}>{t('asianView.labels.betSlip.totalLiability')}</p>
        <p className={styles.totalLiability__value}>{liabilityFormatted}</p>
      </div>
    </>
  );
};

export default TotalLiability;
