import classNames from 'classnames';

import { componentsBranding } from 'constants/branding';

import styles from './DesktopSettingsError.module.scss';

type DesktopSettingsErrorProps = {
  error: string;
  onClose?: () => void;
  containerClassName?: string;
};

const DesktopSettingsError = ({ error, onClose, containerClassName = '' }: DesktopSettingsErrorProps) => {
  return (
    <div
      className={classNames(
        styles.error,
        containerClassName,
        componentsBranding.NOTIFICATION,
        componentsBranding.ERROR
      )}
    >
      <i className={classNames('biab_custom-icon-warning-circle', styles.error__icon)} />
      {error.includes('<span>') || error.includes('<br') ? (
        <p className={styles.error__label} dangerouslySetInnerHTML={{ __html: error }} />
      ) : (
        <p className={styles.error__label}>{error}</p>
      )}
      {onClose && (
        <button onClick={onClose} className={styles.error__closeBtn}>
          <i className="biab_custom-icon-close" />
        </button>
      )}
    </div>
  );
};

export default DesktopSettingsError;
