import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import {
  MarketsPricesSocketParamsSections,
  MarketsPricesSocketParamsSectionsKeys,
  TMarketsPrices
} from 'redux/modules/marketsPrices/type';
import { MarketStatus, TEventUpdatedData, TFailureActionPayload } from 'types';
import { AsianViewMarketPrices, IMarketPrices } from 'types/markets';

const initialState: TMarketsPrices = {
  marketsPrices: {},
  inPlayMarketsIds: [],
  closedMarketsIds: [],
  eventsUpdatedData: {},
  suspendedOrClosedMarketPrices: null,
  error: null,
  eventsUpdatedDataLoading: false,
  stringifiedEventsUpdatedData: '',
  marketsPricesSocketParams: {
    [MarketsPricesSocketParamsSections.AsianViewMiddleSection]: {},
    [MarketsPricesSocketParamsSections.AsianViewDesktopBetSlip]: {},
    [MarketsPricesSocketParamsSections.AsianViewEventCashOut]: {},
    [MarketsPricesSocketParamsSections.SportsMiddleSection]: {},
    [MarketsPricesSocketParamsSections.SportsDesktopBetSlip]: {},
    [MarketsPricesSocketParamsSections.SportsDesktopSelectedBets]: {},
    [MarketsPricesSocketParamsSections.SportsNextRaces]: {},
    [MarketsPricesSocketParamsSections.SportsPopularMarkets]: {},
    [MarketsPricesSocketParamsSections.AsianViewMiddleSectionHDPEventTabs]: {}
  }
};

const slice = createSlice({
  name: SLICES_NAMES.MARKETS_PRICES,
  initialState,
  reducers: {
    setMarketPrices: (state, action: PayloadAction<IMarketPrices | AsianViewMarketPrices>) => {
      state.marketsPrices[action.payload.id] = {
        ...action.payload,
        marketDefinition: action.payload.marketDefinition || state.marketsPrices?.[action.payload.id]?.marketDefinition,
        overround: action.payload.overround || state.marketsPrices?.[action.payload.id]?.overround,
        underround: action.payload.underround || state.marketsPrices?.[action.payload.id]?.underround,
        currency: action.payload.currency || state.marketsPrices?.[action.payload.id]?.currency,
        commission: action.payload?.commission || state.marketsPrices?.[action.payload.id]?.commission || 0
      };

      if (!state.inPlayMarketsIds.includes(action.payload.id) && action.payload.marketDefinition?.inPlay) {
        state.inPlayMarketsIds.push(action.payload.id);
      }

      const isSuspended = action.payload.marketDefinition?.status === MarketStatus.SUSPENDED;
      const isClosedStatus = action.payload.marketDefinition?.status === MarketStatus.CLOSED;

      if (!state.closedMarketsIds.includes(action.payload.id) && isClosedStatus) {
        state.closedMarketsIds.push(action.payload.id);
      }

      if (isSuspended || isClosedStatus) {
        state.suspendedOrClosedMarketPrices = action.payload;
      }
    },
    fetchEventsUpdatedData: (state, _: PayloadAction<string[]>) => {
      state.eventsUpdatedDataLoading = true;
    },
    successFetchEventsUpdatedData: (
      state,
      {
        payload
      }: PayloadAction<{
        data: TEventUpdatedData[];
        isWebSocketResponse: boolean;
        stringifiedEventsUpdatedData?: string;
      }>
    ) => {
      payload.data.forEach(event => {
        state.eventsUpdatedData[event.eventId] = event;
      });

      state.eventsUpdatedDataLoading = false;

      if (!payload.isWebSocketResponse && payload.stringifiedEventsUpdatedData) {
        state.stringifiedEventsUpdatedData = payload.stringifiedEventsUpdatedData;
      }
    },
    setEventsUpdatedDataLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.eventsUpdatedDataLoading = payload;
    },
    failureFetchEventsUpdatedData: (state, action: PayloadAction<TFailureActionPayload>) => {
      state.error = action.payload;
      state.eventsUpdatedDataLoading = false;
    },
    removeEventsUpdatedDataByEventIds: (state, { payload }: PayloadAction<string[]>) => {
      payload.forEach(eventId => {
        delete state.eventsUpdatedData[eventId];
      });
    },
    removeEventUpdatedDataByEventId: (state, { payload }: PayloadAction<string>) => {
      delete state.eventsUpdatedData[payload];
    },
    fetchEventsUpdatedDataByEventId: (state, _: PayloadAction<string>) => {
      state.eventsUpdatedDataLoading = true;
    },
    resetSuspendedOrClosedMarketPrices: state => {
      state.suspendedOrClosedMarketPrices = null;
    },
    resetMarketsPricesByMarket: (state, { payload }: PayloadAction<string>) => {
      delete state.marketsPrices[payload];
    },
    resetEventsUpdatedData: state => {
      state.eventsUpdatedData = initialState.eventsUpdatedData;
      state.eventsUpdatedDataLoading = initialState.eventsUpdatedDataLoading;
      state.stringifiedEventsUpdatedData = initialState.stringifiedEventsUpdatedData;
    },
    setMarketsPricesSocketParam: (
      state,
      {
        payload
      }: PayloadAction<{
        marketId: string;
        eventId: string;
        isVisible: boolean;
        section: MarketsPricesSocketParamsSectionsKeys;
      }>
    ) => {
      if (state.marketsPricesSocketParams[payload.section][payload.marketId]) {
        state.marketsPricesSocketParams[payload.section][payload.marketId].eventId = payload.eventId;
        state.marketsPricesSocketParams[payload.section][payload.marketId].isVisible = payload.isVisible;
      } else {
        state.marketsPricesSocketParams[payload.section][payload.marketId] = {
          eventId: payload.eventId,
          isVisible: payload.isVisible
        };
      }
    },
    removeMarketPricesSocketParam: (
      state,
      { payload }: PayloadAction<{ marketId: string; section: MarketsPricesSocketParamsSectionsKeys }>
    ) => {
      delete state.marketsPricesSocketParams[payload.section][payload.marketId];
    },
    removeMarketPricesSocketParamsByMarketIds: (
      state,
      { payload }: PayloadAction<{ marketIds: string[]; section: MarketsPricesSocketParamsSectionsKeys }>
    ) => {
      payload.marketIds.forEach(marketId => {
        delete state.marketsPricesSocketParams[payload.section][marketId];
      });
    }
  }
});

export const {
  setMarketPrices,
  resetSuspendedOrClosedMarketPrices,
  successFetchEventsUpdatedData,
  resetMarketsPricesByMarket,
  fetchEventsUpdatedData,
  failureFetchEventsUpdatedData,
  setEventsUpdatedDataLoading,
  resetEventsUpdatedData,
  setMarketsPricesSocketParam,
  removeMarketPricesSocketParam,
  fetchEventsUpdatedDataByEventId,
  removeEventsUpdatedDataByEventIds,
  removeEventUpdatedDataByEventId,
  removeMarketPricesSocketParamsByMarketIds
} = slice.actions;

export default slice.reducer;
