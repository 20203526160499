import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getGeneralWsEnabled,
  getIsAsianViewEnabled,
  getIsCurrentBetsWebSocketEnabled,
  getIsPropertiesLoaded
} from 'redux/modules/appConfigs/selectors';
import { fetchAsianCurrentBets } from 'redux/modules/asianViewCurrentBets';
import { loadPlacedQuickBetsFromStorage } from 'redux/modules/asianViewQuickBetting';
import {
  getIsAsianQuickPlacedBets,
  getIsAsianQuickPlacedBetsEmpty,
  getIsAsianQuickProgressBets
} from 'redux/modules/asianViewQuickBetting/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';

import PlacedQuickBetPopover from './components/PlacedQuickBetPopover';
import ProgressQuickBetList from './components/ProgressQuickBetList';

const AsianViewQuickBetsPlaced = () => {
  const dispatch = useDispatch();

  const isQuickPlacedBets = useSelector(getIsAsianQuickPlacedBets);
  const isQuickProgressBets = useSelector(getIsAsianQuickProgressBets);
  const isQuickPlacedBetsEmpty = useSelector(getIsAsianQuickPlacedBetsEmpty);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const isCurrentBetsWebSocketsEnabled = useSelector(getIsCurrentBetsWebSocketEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const isEnabled = isLoggedIn && isAsianViewEnabled;

  useEffect(() => {
    if (isLoggedIn && isAsianViewEnabled && isQuickPlacedBetsEmpty && arePropertiesLoaded) {
      dispatch(loadPlacedQuickBetsFromStorage());

      if (!generalWsEnabled || !isCurrentBetsWebSocketsEnabled) {
        dispatch(fetchAsianCurrentBets());
      }
    }
  }, [isAsianViewEnabled, isLoggedIn, isQuickPlacedBetsEmpty, arePropertiesLoaded]);

  return (
    <>
      {isEnabled && isQuickPlacedBets && <PlacedQuickBetPopover />}
      {isEnabled && isQuickProgressBets && <ProgressQuickBetList />}
    </>
  );
};

export default AsianViewQuickBetsPlaced;
