import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { GAMES_BASE_URL } from 'constants/locations';
import { TSerializedGames } from 'redux/modules/games/type';

import styles from './styles.module.scss';

interface IGameBox {
  game: TSerializedGames;
}

const GameBoxItem = ({ game }: IGameBox) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.gameBoxItem, 'biab_games-tile-wrap')}>
      <div className={classNames(styles.gameTitle, 'biab_games-tile')}>
        <div className="biab_games-tile-name">
          <span>{t(game.name)}</span>
        </div>
        <a className={classNames(styles.image, `biab_games-tile-img game-image-${game.gameType.toLowerCase()}`)} />
        {game.list.map(o => {
          return (
            <NavLink
              key={o.gameId}
              to={`${GAMES_BASE_URL}/${o.channelId}`}
              className={classNames(
                styles.gameBtn,
                `biab_games-tile-btn biab_games-${o.isTurbo ? 'turbo' : 'standard'}`
              )}
            >
              {o.isTurbo ? t('games.labels.playTurbo') : t('games.labels.playStandard')}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default GameBoxItem;
