import { useSelector } from 'react-redux';

import { getSelectedMarketsEvents } from 'redux/modules/betslip/selectors';

import BetslipMarket from './componets/BetslipMarket';

const BetslipMarkets = () => {
  const selectedMarkets = useSelector(getSelectedMarketsEvents);

  return (
    <>
      {selectedMarkets.map(({ marketId, eventId }) => (
        <BetslipMarket key={`b-event-market-${marketId}`} marketId={marketId} eventId={eventId} />
      ))}
    </>
  );
};

export default BetslipMarkets;
