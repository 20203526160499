import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import PlacementNotifications from 'components/PlacementNotifications';
import { betslipBranding } from 'constants/branding';
import {
  getBetslipLoading,
  getHasPlacementNotifications,
  getSelectedBetsAmountToPlace
} from 'redux/modules/betslip/selectors';
import { getAreCurrentBetsLoaded } from 'redux/modules/currentBets/selectors';

import LimitPopup from '../LimitPopup';
import SelectedMarkets from '../SelectedMarkets';

import styles from './styles.module.scss';

const PlaceBetsTab = () => {
  const { t } = useTranslation();

  const isLoading = useSelector(getBetslipLoading);
  const isSelectedBets = useSelector(getSelectedBetsAmountToPlace);
  const hasPlacementNotifications = useSelector(getHasPlacementNotifications);
  const areCurrentBetsLoaded = useSelector(getAreCurrentBetsLoaded);

  return (
    <div
      className={classNames(styles.placeBetsWrap, {
        [styles.disableScroll]: isLoading
      })}
    >
      {hasPlacementNotifications && areCurrentBetsLoaded && <PlacementNotifications />}
      {isSelectedBets ? (
        <SelectedMarkets />
      ) : (
        <div
          className={classNames(styles.emptyMessage, betslipBranding.EMPTY_BETSLIP)}
          dangerouslySetInnerHTML={{ __html: t('betslip.messages.noBets') }}
        />
      )}
      <LimitPopup />
    </div>
  );
};

export default PlaceBetsTab;
