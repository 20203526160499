import { reduce } from 'lodash';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { MobilePlacementTypes } from 'constants/inlinePlacement';
import api from 'redux/api/methods';
import {
  setPlacedBets,
  setPlacementNotificationsError,
  setUpdatedBets,
  updatePlacementNotifications
} from 'redux/modules/betslip';
import {
  setMobilePlacementNotificationsError,
  updateMobilePlacementNotifications
} from 'redux/modules/inlinePlacement';
import { EditCancelBetResponseByMarket, TPlacedBetsByMarket } from 'redux/modules/placement/type';
import { PageBlocks } from 'types';

import {
  cancelAllBets,
  cancelBets,
  editBets,
  failureCancelAllBets,
  failureCancelBets,
  failureEditBets,
  failurePlaceBets,
  placeBets,
  successCancelAllBets,
  successCancelBets,
  successEditBets,
  successPlaceBets
} from './index';

function* getPlaceBetsWorker(action: ReturnType<typeof placeBets>) {
  try {
    const response: TPlacedBetsByMarket = yield call(api.placement.placeBets, action.payload.data);

    const offerIds = reduce(
      response,
      (result: number[], marketData) => result.concat(Object.values(marketData.offerIds || {}) || []),
      []
    );

    Object.keys(response ?? {}).forEach(marketId => {
      response[marketId].betUuids = action.payload.data[marketId].map(({ betUuid }) => betUuid);
    });

    yield put(successPlaceBets(response));

    if (action.payload.isMobilePlacement) {
      yield put(updateMobilePlacementNotifications({ data: response, placementType: MobilePlacementTypes.Place }));
    } else {
      yield put(updatePlacementNotifications(response));
    }

    yield put(setPlacedBets(offerIds));

    if (action.payload.successCallback) {
      action.payload.successCallback({ response, offerIds });
    }
  } catch (error: any) {
    if (action.payload.errorCallback) {
      action.payload.errorCallback(error);
    }

    let betUuids: string[] = [];

    Object.keys(action.payload.data).forEach(marketId => {
      betUuids = [...betUuids, ...action.payload.data[marketId].map(({ betUuid }) => betUuid ?? '')];
    });

    yield put(failurePlaceBets({ error, betUuids }));

    if (action.payload.isMobilePlacement) {
      yield put(
        setMobilePlacementNotificationsError({
          error,
          bets: action.payload.data,
          pageBlock: action.payload.pageBlock || PageBlocks.HOME,
          placementType: MobilePlacementTypes.Place
        })
      );
    } else {
      yield put(setPlacementNotificationsError({ error, bets: action.payload.data }));
    }
  }
}

function* getCancelBetsWorker(action: ReturnType<typeof cancelBets>) {
  try {
    const response: EditCancelBetResponseByMarket = yield call(api.placement.cancelBets, action.payload.data);

    yield put(successCancelBets(response));

    if (action.payload.successCallback) {
      action.payload.successCallback();
    }
  } catch (error: any) {
    yield put(failureCancelBets(error));

    if (action.payload.isMobilePlacement) {
      yield put(
        setMobilePlacementNotificationsError({
          error,
          bets: action.payload.data,
          pageBlock: action.payload.pageBlock || PageBlocks.HOME,
          placementType: MobilePlacementTypes.Cancel
        })
      );
    }

    if (action.payload.errorCallback) {
      action.payload.errorCallback(error);
    }
  }
}

function* getEditBetsWorker(action: ReturnType<typeof editBets>) {
  try {
    const response: EditCancelBetResponseByMarket = yield call(api.placement.editBets, action.payload.data);

    yield put(successEditBets(response));

    const isSuccessUpdate = Object.entries(response)[0][1].status === 'OK';

    if (isSuccessUpdate) {
      yield put(setUpdatedBets(action.payload.offersIds ?? []));

      if (action.payload.successCallback) {
        action.payload.successCallback(response);
      }
    }
  } catch (error: any) {
    let betUuids: string[] = [];

    Object.keys(action.payload.data).forEach(marketId => {
      betUuids = [...betUuids, ...action.payload.data[marketId].map(({ betUuid }) => betUuid ?? '')];
    });

    yield put(failureEditBets({ error, betUuids }));

    if (action.payload.isMobilePlacement) {
      yield put(
        setMobilePlacementNotificationsError({
          error,
          bets: action.payload.data,
          pageBlock: action.payload.pageBlock || PageBlocks.HOME,
          placementType: MobilePlacementTypes.Edit
        })
      );
    }

    if (action.payload.errorCallback) {
      action.payload.errorCallback(error);
    }
  }
}

function* getCancelAllBetsWorker(action: ReturnType<typeof cancelAllBets>) {
  try {
    const response: number = yield call(api.placement.cancelAllBets, action.payload.data);

    yield put(successCancelAllBets(response));

    if (action.payload.successCallback) {
      action.payload.successCallback(response);
    }
  } catch (error: any) {
    yield put(failureCancelAllBets(error));

    if (action.payload.errorCallback) {
      action.payload.errorCallback(error);
    }
  }
}

export default function* saga() {
  yield all([
    takeEvery(placeBets.type, getPlaceBetsWorker),
    takeEvery(editBets.type, getEditBetsWorker),
    takeEvery(cancelBets.type, getCancelBetsWorker),
    takeLatest(cancelAllBets.type, getCancelAllBetsWorker)
  ]);
}
