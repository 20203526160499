import { useTranslation } from 'react-i18next';

/**
 * Translate market unit in dependence of number of units and existence of translation
 * @param marketUnit marketUnit field received from the request
 * @param number number of units (0, 1, N)
 */
export const useMarketUnits = (marketUnit: string, number?: number) => {
  const { t } = useTranslation();

  const marketUnitKey = marketUnit.replace(' ', '').toLowerCase();
  const translationKey = `market.units.${marketUnitKey}${number === 1 ? '_1' : ''}`;

  return t(translationKey);
};
