import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { unescape } from 'lodash';

import { getBackendPageData } from 'redux/modules/pages/selectors';

import styles from './styles.module.scss';

interface PageContentProps {
  /**
   * Link for content page
   */
  isShowTitle?: boolean;
}

const BackendContent = ({ isShowTitle }: PageContentProps) => {
  const data = useSelector(getBackendPageData);

  const contentStyle = isShowTitle ? classnames('biab_scrollable-content', styles.content) : '';

  return (
    <div className="biab_static-content">
      {isShowTitle && (
        <div className={classnames('biab_static-content-title', styles.title)}>
          {data?.name && <h1>{data.name}</h1>}
        </div>
      )}
      <div className={contentStyle} dangerouslySetInnerHTML={{ __html: unescape(data?.content ?? '') }} />
    </div>
  );
};

export default BackendContent;
