import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import Dropdown from 'components/SportsSection/Dropdown';
import styles from 'components/SportsSection/styles.module.scss';
import { TIME_FILTERS } from 'constants/app';
import { SPORT_BASE_URL } from 'constants/locations';
import useTimeFiltersSortingAndTranslation from 'hooks/useTimeFiltersSortingAndTranslation';
import { getSportAvailableFilters } from 'redux/modules/competitions/selectors';
import { SportId } from 'types';

const TennisAndNonSingleCMPDropdown = () => {
  const navigate = useNavigate();
  const { sportId = '', competitionId = '', timeFilter = '' } = useParams();

  const sportAvailableFilters = useSelector(getSportAvailableFilters);

  const isSingleCompetitionPage = !TIME_FILTERS.includes(competitionId);
  const modifiedFilters = useTimeFiltersSortingAndTranslation(
    sportAvailableFilters,
    !isSingleCompetitionPage || !!timeFilter
  );

  const handleSelect = (filter: string) => {
    navigate(
      isSingleCompetitionPage
        ? `${SPORT_BASE_URL}/${sportId}/competition/${competitionId}/${filter.toLowerCase()}`
        : `${SPORT_BASE_URL}/${sportId}/competition/${filter.toLowerCase()}`
    );
  };

  return (
    <>
      {((isSingleCompetitionPage && sportId === SportId.TENNIS) || !isSingleCompetitionPage) &&
        !!modifiedFilters?.length && (
          <Dropdown icon={<i className={'fas fa-ellipsis-h'} />}>
            <ul className={'biab_nav-filters-dropdown-list'}>
              {modifiedFilters.map(f => (
                <li
                  key={f.value}
                  onClick={() => handleSelect(f.value)}
                  className={classNames(styles.dropdownItem, 'biab_nav-filters-dropdown-item')}
                >
                  <a>{f.label}</a>
                </li>
              ))}
            </ul>
          </Dropdown>
        )}
    </>
  );
};

export default TennisAndNonSingleCMPDropdown;
