import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GameMarketTable from 'components/GameMarketTable';
import GameSection from 'components/GameSection';
import Loader, { CircleColors } from 'components/Loader';
import BackendContentPage from 'pages/BackendContentPage';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchGames } from 'redux/modules/games';
import {
  getCurrentGameChannelId,
  getGameList,
  getIsCurrentGame,
  getIsCurrentGameHasMarkets
} from 'redux/modules/games/selectors';

const GameScreen = () => {
  const dispatch = useDispatch();

  const gameList = useSelector(getGameList);
  const currentGameChannelId = useSelector(getCurrentGameChannelId);
  const isCurrentGameHasMarkets = useSelector(getIsCurrentGameHasMarkets);
  const isCurrentGame = useSelector(getIsCurrentGame);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const isGameAvailable = (gameList && gameList.find(game => game.channelId === currentGameChannelId)) || true;

  useEffect(() => {
    dispatch(fetchGames());
  }, [dispatch, isLoggedIn]);

  if (!isCurrentGame) {
    return <Loader circleColor={CircleColors.BLACK} />;
  }

  if (!isGameAvailable) {
    return <BackendContentPage />;
  }

  return (
    <>
      <GameSection />
      {isCurrentGameHasMarkets && <GameMarketTable />}
    </>
  );
};

export default GameScreen;
