import { useCallback, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import AsianViewPopularLinks from 'components/AsianViewPopularLinks';
import BannerContent from 'components/BannersContainer/components/BannerContent';
import { BannerNames } from 'constants/app';
import { DESKTOP_NAVIGATION_HEIGHT } from 'constants/asianView';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { getIsIframeEnabled, getLanguage } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getScrollContentHeight } from 'redux/modules/appSettings/selectors';
import { setIsCollapsedRightSide } from 'redux/modules/asianView';
import {
  getAsianViewAutoCollapsedRightSide,
  getAsianViewIsCollapsedRightSide
} from 'redux/modules/asianView/selectors';
import { fetchAsianViewPopularLinks } from 'redux/modules/asianViewPopularLinks';
import { getAsianViewPopularLinks } from 'redux/modules/asianViewPopularLinks/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchBanner } from 'redux/modules/banners';
import { getIsBannerImagesByName } from 'redux/modules/banners/selectors';
import { BannerSections } from 'redux/modules/banners/type';
import { getIsShowingCustomLinkPopup } from 'redux/modules/popup/selectors';
import { getIsUserInfoLoaded } from 'redux/modules/user/selectors';
import { CookieNames } from 'types';

import styles from './styles.module.scss';

const AsianRightNavigation = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([CookieNames.ASIAN_VIEW_RIGHT_SIDE_IS_CLOSED]);
  const { t } = useTranslation();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const language = useSelector(getLanguage);
  const isBadgeBannerImages = useSelector(getIsBannerImagesByName(BannerNames.badge));
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const popularLinks = useSelector(getAsianViewPopularLinks);
  const isAutoCollapsed = useSelector(getAsianViewAutoCollapsedRightSide);
  const isCollapsed = useSelector(getAsianViewIsCollapsedRightSide);
  const isShowingCustomLinkPopup = useSelector(getIsShowingCustomLinkPopup);
  const height = useSelector(getScrollContentHeight);
  const isUserInfoLoaded = useSelector(getIsUserInfoLoaded);

  const [isDisplayed, setIsDisplayed] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const clickOutsideHandler = useCallback(() => {
    if (isAutoCollapsed && !isCollapsed && !isShowingCustomLinkPopup) {
      dispatch(setIsCollapsedRightSide(true));
    }
  }, [isAutoCollapsed, isCollapsed, isShowingCustomLinkPopup]);
  useOnClickOutside(ref, clickOutsideHandler);

  const isClosed = isAutoCollapsed ? isCollapsed : cookies[CookieNames.ASIAN_VIEW_RIGHT_SIDE_IS_CLOSED] === 'true';

  useEffect(() => {
    if (language && isUserInfoLoaded) {
      dispatch(fetchAsianViewPopularLinks());
      dispatch(
        fetchBanner({
          name: BannerNames.badge,
          platform: Devices.DESKTOP,
          section: BannerSections.BADGE_BANNER,
          locale: language
        })
      );
    }
  }, [isLoggedIn, language, isUserInfoLoaded]);

  useEffect(() => {
    if (isAutoCollapsed && !isCollapsed) {
      dispatch(setIsCollapsedRightSide(true));
    }
  }, [isAutoCollapsed]);

  const handleHideMenuClick = (val: boolean) => {
    if (isAutoCollapsed) {
      dispatch(setIsCollapsedRightSide(val));
    } else {
      setCookie(CookieNames.ASIAN_VIEW_RIGHT_SIDE_IS_CLOSED, val);
    }
  };

  const handleTransitionEnd = () => {
    if (isDisplayed && isClosed) {
      setIsDisplayed(false);
    }
  };

  const handleClickMinimizedView = () => {
    if (isAutoCollapsed) {
      setIsDisplayed(true);
    }
    setTimeout(() => {
      handleHideMenuClick(false);
    }, 10); // 10 - because animation doesn't work in Safari if less than 10
  };

  const fullViewContent = (
    <>
      <button onClick={() => handleHideMenuClick(true)} className={styles.hideButton}>
        <i className="fa2 fa-xs fa2-arrow-right" />
        <span>{t('asianView.labels.hideMenu')}</span>
      </button>
      <AsianViewPopularLinks />
      <div className={styles.bannerSection}>
        <BannerContent bannerName={BannerNames.badge} isIframeEnabled={isIframeEnabled} />
      </div>
    </>
  );

  if (!isBadgeBannerImages && !popularLinks.length) {
    return null;
  }

  return (
    <aside
      ref={isAutoCollapsed && isDisplayed ? ref : undefined}
      className={classNames(styles.asianRightBar__wrapper, {
        [styles.asianRightBar__wrapper__minimized]: isClosed,
        [styles.asianRightBar__wrapper__collapsible]: isAutoCollapsed && !isClosed,
        [styles.asianRightBar__wrapper__collapsible__expanded]: isAutoCollapsed && isDisplayed
      })}
      style={{ height: isAutoCollapsed && isDisplayed ? 'auto' : height - DESKTOP_NAVIGATION_HEIGHT }}
    >
      {isClosed ? (
        <div className={styles.asianRightBar__minimized} onClick={handleClickMinimizedView}>
          <i className={classNames('fa2 fa2-arrow-left', styles.asianRightBar__minimized__arrowIcon)} />
          <i className={classNames('av-icon av-icon-link', styles.asianRightBar__minimized__linkIcon)} />
        </div>
      ) : (
        !isAutoCollapsed && <div className={styles.asianRightBar}>{fullViewContent}</div>
      )}
      {isAutoCollapsed && isDisplayed && (
        <div
          className={classNames(styles.asianRightBar, styles.asianRightBar__autoCollapse, {
            [styles.transform__show]: !isClosed,
            [styles.transform__hide]: isClosed
          })}
          onTransitionEnd={handleTransitionEnd}
        >
          <div
            style={{ height: height - DESKTOP_NAVIGATION_HEIGHT }}
            className={styles.asianRightBar__autoCollapse__contentWrapper}
          >
            {fullViewContent}
          </div>
        </div>
      )}
    </aside>
  );
};

export default AsianRightNavigation;
