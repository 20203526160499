import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getAVBetsPlacedCounter } from 'redux/modules/asianViewCurrentBets/selectors';

import styles from './styles.module.scss';

interface BetsPlacedCounterProps {
  marketId: string;
  containerClassName?: string;
}

const BetsPlacedCounter = ({ marketId, containerClassName = '' }: BetsPlacedCounterProps) => {
  const { t } = useTranslation();

  const counter = useSelector(getAVBetsPlacedCounter(marketId));

  return (
    <span className={containerClassName}>
      <span className={styles.betPlacedCounter__count}>{`${counter} `}</span>
      {t('asianView.labels.cashout.placedMarkets')}
    </span>
  );
};

export default BetsPlacedCounter;
