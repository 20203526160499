import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import useDevice from 'hooks/useDevice';
import { setTimezone } from 'redux/modules/appConfigs';
import { DropdownItem } from 'types';
import { convertTimezoneToDropdown } from 'utils';
import { getDefaultTimezoneOffset, getTimezoneCookieName } from 'utils/date';

import styles from './styles.module.scss';

const Timezone = () => {
  const dispatch = useDispatch();

  const timezoneCookieName = getTimezoneCookieName();
  const [cookies, setCookie] = useCookies([timezoneCookieName]);

  const { isMobile } = useDevice();

  const timezoneCookie = cookies[timezoneCookieName] || getDefaultTimezoneOffset();
  const convertedData = convertTimezoneToDropdown();
  const selectedOffset = convertedData.find(item => item.value === `${timezoneCookie}`);

  const onSelectTimezone = useCallback((timezone: DropdownItem) => {
    setCookie(timezoneCookieName, timezone.value, {
      path: '/'
    });
    dispatch(setTimezone(timezone.value));
    window.location.reload();
  }, []);

  return (
    <Dropdown
      isInHeader
      data={convertedData}
      defaultValue={selectedOffset}
      onSelectOption={onSelectTimezone}
      classes={{ placeholder: classNames({ [styles.timezone__placeholder]: isMobile }) }}
    />
  );
};

export default Timezone;
