import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { AsianViewMarketLink } from 'constants/asianView';
import { asianViewMiddleSection } from 'constants/branding';
import { getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { EAsianMobileNav } from 'types/asianView';
import { getDayLabel } from 'utils/asianView';
import { addDays, applyTimezone } from 'utils/date';

import styles from './styles.module.scss';

interface IViewByPlaceholderProps {
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
}
const ViewByPlaceholder = ({ isOpened, setIsOpened }: IViewByPlaceholderProps) => {
  const { t } = useTranslation();
  const { timeFilter = 'today', timeFrame, marketLink } = useParams();
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);

  const isOutright = marketLink === AsianViewMarketLink.OUTRIGHT;
  const date = applyTimezone(new Date(), timezone, timezoneCookieEnabled);
  const allDatesLabel =
    !isOutright && timeFilter === EAsianMobileNav.EARLY ? ` - ${t('asianView.labels.timeFilter.allDates')}` : '';

  return (
    <div
      className={classNames(styles.viewByPlaceholder, asianViewMiddleSection.MOBILE_SUB_HEADER_BTN)}
      onClick={() => setIsOpened(!isOpened)}
    >
      <span className={classNames(styles.viewByLabel, { [styles.viewByLabel__active]: isOpened })}>
        {timeFrame ? (
          getDayLabel(addDays(date, +timeFrame?.replace(/^\D+/g, '')), t)
        ) : (
          <>
            {isOutright ? t('asianView.labels.marketLinks.outright') : t(`asianView.labels.timeFilter.${timeFilter}`)}
            {allDatesLabel}
          </>
        )}
      </span>
    </div>
  );
};

export default ViewByPlaceholder;
