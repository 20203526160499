import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const NoFavorites = () => {
  const { t } = useTranslation();

  return <p className={styles.favouritesEmptyMessage}>{t('navigation.noFavourites')}</p>;
};

export default NoFavorites;
