import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { GeneralWebSocketSubscriptionTypes } from 'constants/app';
import useCellConfigs from 'hooks/useCellConfigs';
import EventsUpdatesInjection from 'injections/EventsUpdatesInjection';
import { getAppDevice, getHomePageColumnsNumber, getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchFeatureMarkets } from 'redux/modules/featuredMarkets';
import { getFeaturedMarkets } from 'redux/modules/featuredMarkets/selectors';
import { SportId } from 'types';

import FeaturedMarket from './components/FeaturedMarket/FeaturedMarket';
import TitleMarketMobile from './components/TitleMarketMobile';
import WidgetTitle from './components/WidgetTitle';

import styles from './styles.module.scss';

const FeaturedMarkets = () => {
  const dispatch = useDispatch();

  const markets = useSelector(getFeaturedMarkets);
  const loggedIn = useSelector(getLoggedInStatusState);
  const language = useSelector(getLanguage);
  const timezone = useSelector(getTimezone);
  const device = useSelector(getAppDevice);
  const homePageColumnsNumber = useSelector(getHomePageColumnsNumber);

  const eventIds = useMemo(
    () =>
      markets
        .map(({ event, eventType }) => (eventType.id === SportId.SOCCER ? event.id : ''))
        .filter(eventId => eventId !== ''),
    [markets]
  );

  const { desktopCellsContainerWidth, mobileCellsContainerWidth, isLandscape, isDepthEnabled } = useCellConfigs({
    cellGroupsCount: homePageColumnsNumber === 1 ? 3 : 1,
    isMarketView: true,
    isHomePage: true,
    showDepth: homePageColumnsNumber === 1,
    doubleWidth: homePageColumnsNumber === 2,
    addMobileExtraSpace: true
  });

  const isMobile = device === Devices.MOBILE;

  useEffect(() => {
    dispatch(fetchFeatureMarkets());

    const interval = setInterval(() => {
      dispatch(fetchFeatureMarkets());
    }, 15000);

    return () => clearInterval(interval);
  }, [loggedIn, language, timezone]);

  if (isMobile) {
    return (
      <div className="biab_featured-markets biab_featured-markets-content">
        {!!eventIds.length && (
          <EventsUpdatesInjection
            eventIds={eventIds}
            subscriptionType={GeneralWebSocketSubscriptionTypes.eventInfoUpdates}
          />
        )}
        <WidgetTitle isMobile={isMobile} />
        {markets.map(market => (
          <TitleMarketMobile key={market.marketId} market={market} />
        ))}
      </div>
    );
  }

  return (
    <div className="biab_featured-markets biab_featured-markets-content">
      {!!eventIds.length && (
        <EventsUpdatesInjection
          eventIds={eventIds}
          subscriptionType={GeneralWebSocketSubscriptionTypes.eventInfoUpdates}
        />
      )}
      <WidgetTitle />
      <div
        id="a-featuredMarkets"
        className={classNames({
          [styles.contentContainerSingle]: homePageColumnsNumber === 1,
          [`contentContainerDouble ${styles.contentContainerDouble}`]: homePageColumnsNumber === 2
        })}
      >
        {markets.map(market => (
          <FeaturedMarket
            key={market.marketId}
            market={market}
            isDepthEnabled={isDepthEnabled}
            isLandscape={isLandscape}
            desktopCellsContainerWidth={desktopCellsContainerWidth}
            mobileCellsContainerWidth={mobileCellsContainerWidth}
          />
        ))}
      </div>
    </div>
  );
};

export default FeaturedMarkets;
