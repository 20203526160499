import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ASIAN_BASE_URL, CUSTOM_PAGES_BASE_URL } from 'constants/locations';
import { setIsCollapsedRightSide } from 'redux/modules/asianView';
import { AsianViewPopularLink } from 'redux/modules/asianViewPopularLinks/type';

import AsianViewPopularLinkContent from '../AsianViewPopularLinkContent';

import styles from '../../styles.module.scss';

type AsianViewRegularPopularLinkProps = {
  link: AsianViewPopularLink;
  linkClassName?: string;
  arrowClassName?: string;
  noTruncate?: boolean;
};

const AsianViewRegularPopularLink = ({
  link,
  linkClassName = '',
  arrowClassName = '',
  noTruncate = false
}: AsianViewRegularPopularLinkProps) => {
  const dispatch = useDispatch();

  const isCustomPage = link.entryName.includes(CUSTOM_PAGES_BASE_URL);

  const handleCLick = () => {
    dispatch(setIsCollapsedRightSide(true));
  };

  if (link.entryName.startsWith('http')) {
    return (
      <a href={link.entryName} className={classNames(styles.link, linkClassName)} onClick={handleCLick}>
        <AsianViewPopularLinkContent link={link} arrowClassName={arrowClassName} noTruncate={noTruncate} />
      </a>
    );
  }

  const linkUrl = isCustomPage ? `${ASIAN_BASE_URL}/${link.entryName}` : link.entryName;

  return (
    <Link to={linkUrl} className={classNames(styles.link, linkClassName)} onClick={handleCLick}>
      <AsianViewPopularLinkContent link={link} arrowClassName={arrowClassName} noTruncate={noTruncate} />
    </Link>
  );
};

export default AsianViewRegularPopularLink;
