import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { SPORT_BASE_URL } from 'constants/locations';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { getEventData } from 'redux/modules/competitions/selectors';
import { FancyMarketTypes } from 'redux/modules/fancyMarket/type';
import { DropdownItem } from 'types';

const useMobileMarketNavigationOptions = (eventId: string): DropdownItem[] => {
  const { sportId = '' } = useParams();

  const eventData = useSelector(getEventData);
  const domainLanguage = useSelector(getTranslation);

  return useMemo(
    () =>
      eventData
        .filter(({ id }) => !id.includes('#date'))
        .map(({ id, translations }) => {
          const sportBaseUrl = `${SPORT_BASE_URL}/${sportId}`;
          const isFancyId = id === FancyMarketTypes.FIRST_INNINGS_FANCY || id === FancyMarketTypes.SECOND_INNINGS_FANCY;
          const itemValue = isFancyId ? `${sportBaseUrl}/fancyEvent/${eventId}/${id}` : `${sportBaseUrl}/market/${id}`;
          const itemLabel = isFancyId ? `navigation.fancy.markets.${id}` : translations[domainLanguage];

          return {
            id,
            value: itemValue,
            label: itemLabel,
            isLabelTranslatable: isFancyId
          };
        }),
    [eventData, domainLanguage, eventId, sportId]
  );
};

export default useMobileMarketNavigationOptions;
