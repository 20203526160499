import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { TNavigationResponse } from 'redux/modules/competitions/type';
import { IMarketRules } from 'types/markets';

import {
  failureFetchMarket,
  failureFetchMarketRules,
  failureFetchRaces,
  fetchMarket,
  fetchMarketRules,
  fetchRaces,
  successFetchMarket,
  successFetchMarketRules,
  successFetchRaces
} from './index';
import { TMarketResponse } from './type';

function* getMarketWorker(action: ReturnType<typeof fetchMarket>) {
  try {
    const response: TMarketResponse = yield call(api.market.getMarketDetails, action.payload);

    yield put(successFetchMarket(response));
  } catch (error: any) {
    yield put(failureFetchMarket(error));
  }
}

function* getMarketRulesWorker(action: ReturnType<typeof fetchMarketRules>) {
  try {
    const response: IMarketRules = yield call(api.app.marketRules, action.payload);
    yield put(successFetchMarketRules({ marketId: action.payload, rules: response }));
  } catch (error: any) {
    yield put(failureFetchMarketRules(error.data));
  }
}

function* getMarketRacesWorker(action: ReturnType<typeof fetchMarketRules>) {
  try {
    const response: TNavigationResponse = yield call(api.competitions.getRace, action.payload);
    yield put(successFetchRaces(response));
  } catch (error: any) {
    yield put(failureFetchRaces(error.data));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchMarket.type, getMarketWorker),
    takeLatest(fetchMarketRules.type, getMarketRulesWorker),
    takeLatest(fetchRaces.type, getMarketRacesWorker)
  ]);
}
