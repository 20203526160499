import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { failureFeatureMarkets, fetchFeatureMarkets, successFeatureMarkets } from './index';
import { FeaturedMarket } from './type';

export function* fetchFeatureMarketsWorker() {
  try {
    const result: FeaturedMarket[] = yield call(api.featuredMarkets.getFeaturedMarkets);
    yield put(successFeatureMarkets(result));
  } catch (error: any) {
    yield put(failureFeatureMarkets(error.message));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchFeatureMarkets.type, fetchFeatureMarketsWorker)]);
}
